import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import store from '../../../../store/index';
import { setSettingGroupStoreSettings } from '../../../../store/settingGroupStore/settingGroupStoreDetailSlice';

import settingGroupStoreGetSpecifiedAreaCodeApi from '../../../../apis/settingGroupStore/settingGroupStoreGetSpecifiedAreaCodeApi';

import SelectStoreWindow from '../../../../components/setStoreGroup/selectStoreWindow/selectStoreWindow';

function SelectStoreWindowContainers(props) {
  const dispatch = useDispatch();
  const { open, setOpen, areaCode, setNextRegsit, storeChange, order,
    initialStore = false } = props;

  const [divisions, setDivisions] = useState([]);

  const selectedStoreInitial = initialStore ? { divisionSubName2: initialStore } : {};
  const [selectedStore, setSelectedStore] = useState(selectedStoreInitial);

  const { settingGroupStoreDetail } = store.getState();
  const { storeGroupId, settings: storeList } = settingGroupStoreDetail.settingGroupStoreDetail;

  const getDivisions = async () => {
    await settingGroupStoreGetSpecifiedAreaCodeApi(areaCode, { storeGroupId })
      .then((res) => setDivisions(res.data.divisions))
      .catch((err) => console.log(err));
  };

  const setStoreList = (settings) => dispatch(setSettingGroupStoreSettings(settings));

  useEffect(() => {
    if (areaCode && open) getDivisions();
  }, [areaCode, open]);

  return (
    <SelectStoreWindow
      open={open}
      setOpen={setOpen}
      storeList={storeList}
      setStoreList={setStoreList}
      divisions={divisions}
      selectedStore={selectedStore}
      setSelectedStore={setSelectedStore}
      setNextRegsit={setNextRegsit}
      storeChange={storeChange}
      order={order}
    />
  );
}

export default SelectStoreWindowContainers;
