import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import WeekTable from './weekTable';
import ActRegistWindow from '../../pages/actRegist/actRegistWindow';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      overflowY: 'scroll',
      height: 'calc(100vh - 146px)',
      background: '#F3F3F3',
      paddingBottom: 58,
    },
  },
  tableHead: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 1,
      background: '#fff',
    },
  },
  th: {
    borderBottom: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 4px 8px',
    },
    '&:first-child': {
      [commonTheme.breakpoints.down('sm')]: {
        width: 67,
        fontSize: 10,
        fontWeight: 700,
        textAlign: 'right',
        color: '#8C8C8C',
        padding: '0 2px 2px 0',
        verticalAlign: 'bottom',
      },
    },
    '&:not(:first-child)': {
      [commonTheme.breakpoints.up('md')]: {
        width: 210,
      },
    },
    '&:not(:last-child)': {
      [commonTheme.breakpoints.up('md')]: {
        borderRight: '1px solid #C8C8C8',
      },
    },
    '& > span': {
      display: 'block',
      textAlign: 'center',
    },
    '&.today': {
      '& > span': {
        '&:nth-child(1)': {
          color: theme.palette.primaryColor,
        },
        '&:nth-child(2)': {
          position: 'relative',
          '&:after': {
            content: '""',
            width: 32,
            height: 32,
            background: theme.palette.primaryColor,
            borderRadius: '50%',
            position: 'absolute',
            zIndex: 1,
            top: '-7px',
            right: 0,
            left: 0,
            margin: '0 auto',
          },
          '& span': {
            position: 'relative',
            zIndex: 2,
            color: '#fff',
          },
        },
        '&:nth-child(3)': {
          color: theme.palette.primaryColor,
          [commonTheme.breakpoints.down('sm')]: {
            borderBottom: `2px solid ${theme.palette.primaryColor}`,
          },
        },
      },
    },
  },
  dayOfWeek: {
    color: '#8C8C8C',
    marginBottom: 6,
  },
  guidance: {
    color: '#8C8C8C',
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 10,
      borderLeft: '1px solid #C8C8C8',
      paddingBottom: 6,
    },
    [commonTheme.breakpoints.up('md')]: {
      marginTop: 6,
    },
  },
  label: {
    background: '#F3F3F3',
    borderTop: '2px solid #C8C8C8',
    borderBottom: '2px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  labelTd: {
    position: 'relative',
    padding: 8,
    borderBottom: 'none',
    '& > span': {
      marginLeft: 20,
    },
    '& > span > span': {
      fontSize: 13,
    },
  },
  labelBtn: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    '& .MuiIconButton-label': {
      display: 'block',
      textAlign: 'left',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: theme.palette.primaryColor,
      margin: '2px 0 0 10px',
    },
  },
  contentsTd: {
    padding: 0,
    borderBottom: 'none',
  },
  contentsBox: {
    margin: 0,
  },
  button: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: 1101,
      minWidth: 'auto',
      padding: '0',
      position: 'fixed',
      bottom: '84px',
      right: '24px',
      width: '56px',
      height: '56px',
      backgroundColor: '#fff',
      borderRadius: '50%',
      boxShadow:
        '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  addIcon: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      color: theme.palette.primaryColor,
    },
  },
}));

function Row(props) {
  const {
    setOpenCustomertree,
    classes,
    baseClasses,
    countUpChildWeekDay,
    dates,
    searchInput,
    targetDivisionTree,
    actionsWeeks,
  } = props;
  const [open, setOpen] = useState(true);

  // 案内件数（親組織単位）
  const [num, setNum] = useState(0);
  let numCount = 0;
  const countUpParent = (c) => {
    numCount += c;
  };
  useEffect(() => {
    setNum(numCount);
  }, [searchInput.searchCount, targetDivisionTree, actionsWeeks.divisions]);

  return (
    <>
      <TableRow className={`${classes.label} rndWeek-label`}>
        {(targetDivisionTree && Object.keys(targetDivisionTree).length !== 0) && (
          <TableCell
            colSpan={27}
            className={`${baseClasses.title3} ${classes.labelTd}`}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              className={classes.labelBtn}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <span>{targetDivisionTree.groupName}<span>（案内 : {num}）</span></span>
          </TableCell>
        )}
      </TableRow>
      <TableRow className={classes.contents}>
        {(targetDivisionTree && Object.keys(targetDivisionTree).length !== 0) && (
          <TableCell colSpan={27} className={classes.contentsTd}>
            <Collapse in={open} timeout={0} unmountOnExit>
              <Box margin={1} className={classes.contentsBox}>
                <WeekTable
                  setOpenCustomertree={setOpenCustomertree}
                  countUpParent={countUpParent}
                  countUpChildWeekDay={countUpChildWeekDay}
                  dates={dates}
                />
              </Box>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </>
  );
}

export default function Week(props) {
  const {
    setOpenCustomertree,
  } = props;

  const {
    actionScheduleDate,
    searchInput,
    targetDivisionTree,
    setReacquisitionFlg,
    functions,
    actionsWeeks,
    dateConversion,
  } = useActionSchedule();

  const classes = useStyles();
  const baseClasses = commonStyles();

  const windowWidth = window.innerWidth;

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // 開閉用
  const [openNewActRegist, setOpenNewActRegist] = useState(false);
  // 目標
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });
  const [selectDivision, setSelectDivision] = useState({
    divId: 0,
    divisionName: '-',
  });

  const [initialData, setInitialData] = useState({});

  // 日付、曜日、案内件数を設定
  const th = [];
  const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
  const today = new Date(actionScheduleDate);
  const dates = [];

  // 案内件数（曜日単位）
  const [weekDayNum, setWeekDayNum] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const numCountArray = [0, 0, 0, 0, 0, 0, 0, 0, 0];
  const countUpChildWeekDay = (c, w) => {
    numCountArray[w] += c;
  };
  useEffect(() => {
    setWeekDayNum(numCountArray);
  }, [searchInput.searchCount, targetDivisionTree, actionsWeeks.divisions]);

  if (actionsWeeks.date.length > 0) {
    for (let i = 0; i < actionsWeeks.date.length; i += 1) {
      const dateItem = new Date(actionsWeeks.date[i]);

      // 今日かどうかの判定
      let todayFlg = false;
      if (dateConversion(dateItem) === dateConversion(today)) {
        todayFlg = true;
      }

      th.push(
        {
          date: dateItem,
          num: weekDayNum[i],
          weekDay: weekDay[dateItem.getDay()],
          today: todayFlg,
        },
      );
      dates.push(dateItem);
    }
  }

  let lblWidth;
  if (isSp) {
    lblWidth = (windowWidth - 67) / 7;
  } else {
    lblWidth = 210;
  }

  return (
    <TableContainer component={Paper} className={`${classes.tableContainer} rndWeek-tableContainer`}>
      <Table aria-label="collapsible table" className="rndWeek-table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {isSp ? (
              <TableCell className={classes.th}>案内</TableCell>
            ) : (
              <TableCell className={`${classes.th} rndWeek-thLbl`} />
            )}
            {th.map((cell) => (
              <TableCell
                key={cell.date}
                className={`${classes.th} ${cell.today && 'today'} rndWeek-th`}
                style={{ width: lblWidth }}
              >
                <span className={`${baseClasses.title5} ${classes.dayOfWeek}`}>
                  {cell.weekDay}
                </span>
                <span className={`${baseClasses.title3} ${classes.day}`}>
                  <span>{cell.date.getDate()}</span>
                </span>
                {isSp ? (
                  <span className={`${baseClasses.title5} ${classes.guidance} rndWeek-guidance`}>
                    {cell.num}
                  </span>
                ) : (
                  <span className={`${baseClasses.title4} ${classes.guidance} rndWeek-guidance`}>
                    案内 : {cell.num}
                  </span>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            setOpenCustomertree={setOpenCustomertree}
            classes={classes}
            baseClasses={baseClasses}
            countUpChildWeekDay={countUpChildWeekDay}
            dates={dates}
            searchInput={searchInput}
            targetDivisionTree={targetDivisionTree}
            actionsWeeks={actionsWeeks}
          />
        </TableBody>
      </Table>
      <Button className={`${classes.button} rndWeek-button`} onClick={() => { setOpenNewActRegist(true); }}>
        <AddIcon className={classes.addIcon} />
      </Button>
      {/* [m06-4]画面 新規登録 */}
      {openNewActRegist && (
        <ActRegistWindow
          open={openNewActRegist}
          onClose={() => { setOpenNewActRegist(false); }}
          setReacquisitionFlg={setReacquisitionFlg}
          values={initialData}
          setUpdateData={setInitialData}
          setOpenCustomertree={setOpenCustomertree}
          actRegistFlag={Boolean(true)}
          editableFlag={Boolean(false)}
          targetObj={targetObj}
          setTargetObj={setTargetObj}
          archieveObj={archieveObj}
          setArchieveObj={setArchieveObj}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          isSource={Boolean(false)}
          createUpdateFunc={functions.schedulesPostApiFunction}
          date={actionScheduleDate}
        />
      )}
    </TableContainer>
  );
}
