const omit = (text, len) => {
  if (typeof text !== 'string') return '';
  const ellipsis = '...';
  let omittedText = '';
  text.length > len
    ? omittedText = text.slice(0, len - ellipsis.length) + ellipsis
    : omittedText = text;
  return omittedText;
};

export default omit;
