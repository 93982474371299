import { useState } from 'react';
import { InputAdornment, makeStyles, Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { TextBaseField } from '../../eleCommon/validation';
import { validateFormString1 } from '../../../commonFunction/validations';

const useStyles = makeStyles({
  tableSearchRowCell: {
    margin: '0px',
    padding: '4px',
  },
  iconAdornment: {
    padding: '0px',
  },
  searchIcon: {
    height: '1rem',
  },
  textArea: {
    margin: '0px',
    backgroundColor: '#FFFFFF',
    border: '0px solid #FFFFFF',
  },
  textInput: {
    padding: '5px',
    fontSize: '13px',
    '&.MuiSelect-select:focus': { background: 'none' },
  },
  searchButton: {
    padding: '6px 0',
    minWidth: 'auto',
  },
});

export default function SearchCell(props) {
  const { id, filter, setFilter } = props;
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');

  const doFilter = (e) => {
    setFilter({ ...filter, [id]: e.target.value });
  };

  return (
    <TextBaseField
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
      onKeyDown={e => e.key === 'Enter' && doFilter(e)}
      className={classes.textArea}
      InputProps={{
        classes: {
          input: classes.textInput,
          adornedStart: classes.iconAdornment,
        },
        startAdornment: (
          <InputAdornment position="start">
            <Button
              onClick={() => {
                setFilter({ ...filter, [id]: searchValue });
              }}
              className={classes.searchButton}
            >
              <Search className={classes.searchIcon} />
            </Button>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      size="small"
      validator={validateFormString1({
        maxLengthInt: 20,
      })}
    />
  );
}
