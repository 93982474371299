import client from '../httpClient';

/**
 * @typedef { import(
 *   './putDivisionMastersApiTypes'
 * ).DivisionMastersPutRequestBody } DivisionMastersPutRequestBody
 * @typedef { import(
 *   './putDivisionMastersApiTypes'
 * ).DivisionMastersPutResponse } DivisionMastersPutResponse
 * @type { (requestBody: DivisionMastersPutRequestBody) => Promise<DivisionMastersPutResponse> }
 */

const putDivisionMastersApi = async (requestBody) => {
  const result = await client.put('/division-masters/', JSON.stringify(requestBody), { isShowMessage: true, isShowLoading: true });
  return result;
};

export default putDivisionMastersApi;
