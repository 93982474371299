import React, { useState, useEffect } from 'react';
import localStyles from '../localStyle';
import commonStyles from '../../../../styles';

export const CalendarInputOnlyTime = React.forwardRef((props, ref) => {
  const style = localStyles();
  const common = commonStyles();
  const val = props;

  // ローカルステート
  const key = Object.keys(val.date)[0];
  let defaultDate = val.date[key];
  let defaultValue = val.date[key];

  if (defaultDate.indexOf('/') !== -1 && defaultDate.indexOf(':') !== -1) {
    defaultDate = defaultDate.substr(0, 16);
    defaultValue = defaultValue.replace(/\//g, '-').replace(/ /g, 'T');
  }
  if (defaultDate.indexOf('-') !== -1 && defaultDate.indexOf('T') !== -1) {
    defaultDate = defaultDate.substr(0, 16).replace(/-/g, '/').replace(/T/g, ' ');
  }
  const [local, setLocal] = useState({
    dist: defaultDate,
    value: defaultValue,
  });

  const valChange = (e) => {
    const { value } = e.target;
    setLocal({
      dist: value.replace(/-/g, '/').replace(/T/g, ' '),
      value,
    });

    val.setDate(e.target.value);
  };

  const onClick = (e, dateVal) => {
    if (!val.notConvert) {
      const d = new Date(dateVal);
      e.target.value = val.convertDateTimeToIso(d);
    }
  };

  useEffect(() => {
    setLocal({
      dist: defaultDate,
      value: defaultValue,
    });
  }, [val]);

  return (
    <label className={style.label} htmlFor={val.id}>
      <span className={`${common.strong} ${style.txt}`}>{local.dist}</span>
      <input
        className={style.input}
        require={val.require.toString()}
        id={val.id}
        type={val.type}
        value={local.value}
        ref={ref}
        onChange={valChange}
        readOnly={val.readonly ? 'readOnly' : ''}
        onClick={(e) => onClick(e, local.value)}
        min={val.min ? val.min : null}
      />
    </label>
  );
});

export default CalendarInputOnlyTime;
