import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List, Typography,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';
import MailCreateWindowSpAfter from '../../sp/afterCallWindowMail';
import { changeAlertMessage } from '../../../../store/eleCommon/customAlertMessage';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    '& .MuiDialog-paper': {
      width: '100%',
      margin: '0 8px',
    },
  },
  zIndex: { zIndex: '1301 !important' },
  list: {
    paddingLeft: '16px',
  },
  btn: {
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    fontFamily: 'Roboto',
    padding: '16px',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& > p': {
        fontSize: '13px',
      },
    },
  },
  label: {
    marginRight: '8px',
  },
  icon: {
    fill: theme.palette.primaryColor,
    marginLeft: '10px',
  },
  disable: {
    pointerEvents: 'none',
    background: '#f8f8f8',
  },
  listitem: {
    width: '100%',
    padding: '8px 4px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
}));

export default function MailSelectDrawerInAfterCall(props) {
  const {
    user,
    open,
    setOpen,
    afterClose,
    selectType,
    setSelectType,
    meta,
    setMeta,
    setting,
    afterCallFunctions,
    responseHeader,
    libraries,
    mailParam,
    setMailParam,
    editorState,
    setEditorState,
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();

  const values = {
    label: 'To',
    require: true,
    style: { width: '100%' },
    check: meta.tocheck,
    setCheck: (e) => { return setMeta({ ...meta, toCheck: e }); },
    list: meta.to,
    setList: (e) => { return setMeta({ ...meta, to: e }); },
  };

  const [state, setState] = useState(values.check);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    const sendData = () => {
      const list = Object.keys(state).filter(s => {
        return s === event.target.name;
      });
      let result = '';
      for (let j = 0; j < list.length; j += 1) {
        if (j !== 0) { result += ', '; }
        result += user[list[j]];
      }
      return result;
    };
    if (sendData().length === 0) {
      dispatch(changeAlertMessage({ msgList: ['・送信先のメールアドレスを１つ以上チェックしてください。'] }));
    } else {
      const parseLimitAt = () => {
        const before = new Date();
        before.setDate(before.getDate() + 7);
        const Y = before.getFullYear();
        const M = before.getMonth() + 1 < 10 ? `0${before.getMonth() + 1}` : before.getMonth() + 1;
        const D = before.getDate() < 10 ? `0${before.getDate()}` : before.getDate();
        const H = before.getHours() < 10 ? `0${before.getHours()}` : before.getHours();
        const m = before.getMinutes() < 10 ? `0${before.getMinutes()}` : before.getMinutes();
        const s = before.getSeconds() < 10 ? `0${before.getSeconds()}` : before.getSeconds();
        return `${Y}/${M}/${D} ${H}:${m}:${s}`;
      };
      const param = {
        updateType: 1,
        isMailSend1: state.mail1 ? 1 : 0,
        isMailSend2: state.mail2 ? 1 : 0,
        isMailSend3: state.mail3 ? 1 : 0,
        isMailSend4: state.mail4 ? 1 : 0,
        isNotCheckUpdate: 0,
        getDataAt: parseLimitAt(),
      };
      afterCallFunctions.updateAddress(user.customerId, param);
    }
  };
  const checkboxList = () => {
    const list = [];
    for (let i = 1; i < 5; i += 1) {
      list.push(
        <FormControlLabel
          key={`mail${i}`}
          className={classes.listitem}
          control={
            <Checkbox
              color="primary"
              onChange={handleChange}
              checked={state['mail' + i]}
              name={`mail${i}`}
            />
          }
          label={
            <>
              <Typography className={common.title4}>Mail{i}</Typography>
              <Typography className={common.small}>{user['mail' + i]}</Typography>
            </>
          }
        />,
      );
    }
    return list;
  };
  const [drawerOpen, setDrawerOpen] = useState(true);

  return (
    <CustomSimpleDrawer
      className={`${classes.dialog} ${drawerOpen ? classes.zIndex : null}`}
      open={open}
      onClose={() => { setOpen(false); }}
      list={
        <>
          <List className={classes.list}>
            {checkboxList()}
          </List>
          <Grid container justifyContent="center">
            <Button
              className={common.buttonsSecondary}
              onClick={() => { setOpen(false); }}
            >
              キャンセル
            </Button>
            <MailCreateWindowSpAfter
              open={open}
              setOpen={setOpen}
              afterClose={afterClose}
              user={user}
              btnLabel={<span>OK</span>}
              type={1}
              selectType={selectType}
              setSelectType={setSelectType}
              setting={setting}
              meta={meta}
              setMeta={setMeta}
              afterCallFunctions={afterCallFunctions}
              responseHeader={responseHeader}
              libraries={libraries}
              mailParam={mailParam}
              setMailParam={setMailParam}
              setDrawerOpen={setDrawerOpen}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Grid>
        </>
      }
    />
  );
}
