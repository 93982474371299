import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, makeStyles, List, ListItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';
// eslint-disable-next-line max-len
import commonStyles from '../../../styles';
import { sortOrderSelect, sortItemSelect } from '../../../../constants/customerMain';
import CustomerTree from '../../../common/customerTree/customerTree';
import { updateObjSearchItems } from '../../../../store/search/searchItemsSlice';
import { sortCustomerList } from '../../../../commonFunction/getSortAssociativeArray';

const useStyles = makeStyles((theme) => ({
  // selectButton用
  selectRoot: {
    background: '#F3F3F3',
    padding: '16px 8px 8px',
  },
  selectList: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    padding: 0,
  },
  selectItem: {
    padding: 0,
    width: 'auto',
    marginBottom: 6,
    '&:not(:last-child)': {
      marginRight: 8,
    },
  },
  selectBtn: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 4px 4px 12px',
    fontSize: 12,
    borderRadius: 60,
  },
  arrowIcon: {
    color: theme.palette.primaryColor,
    fontSize: 18,
  },
  // ここからDrawer用のスタイル
  list: {
    padding: 0,
  },
  item: {
    padding: 0,
    '&.Mui-selected': {
      background: 'transparent',
      '& .MuiButton-label': {
        color: theme.palette.primaryColor,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  selectBtnTxt: {
    width: '6.4em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

function DrawerList(props) {
  const { initial, parentKey, localValues, setLocalValues, onClose } = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    initial.map((obj) => (
      <List className={classes.list} key={obj.key}>
        {Object.keys(obj.menu).map((o) => (
          <ListItem
            key={o}
            className={classes.item}
            selected={localValues[parentKey] === obj.menu[o]}
          >
            <Button
              className={`${common.title3} ${classes.btn}`}
              onClick={(e) => {
                setLocalValues({ ...localValues, [parentKey]: e.target.innerText });
                obj.setState(o);
                onClose();
              }}
            >
              {obj.menu[o]}
            </Button>
          </ListItem>
        ))}
      </List>
    ))
  );
}

const GetSelectHeader = React.memo((props) => {
  const {
    initLoginData,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    selectList,
    searchHeader,
    setSearchHeader,
    // selectCustomer,
    setSelectCustomer,
    allCustomer,
    setAllCustomer,
    // handleClearFunction,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState({}); // 開閉用ローカルステート
  const [isOpenOrgTree, setIsOpenOrgTree] = useState(false); // 担当者選択開閉用ローカルステート
  const [selectTreeUser, setSelectTreeUser] = useState({ userId: 0, userName: '' });
  const [selectTreeDivision, setSelectTreeDivision] = useState({ divId: 0, divisionName: '' });
  const [selectTreeCustomer, setSelectTreeCustomer] = useState([]);

  const options = {
    selectTreeUser,
    selectTreeDivision,
    selectTreeCustomer,
  };
  // 選択された値のセット用
  const [localValues, setLocalValues] = useState({
    order: selectList[0].menus[0],
    filter: selectList[1].menus[0],
    userId: 0,
  });

  // ステートを更新
  const set = (key, val) => {
    setSearchHeader({ ...searchHeader, [key]: val });
  };

  // 顧客の表示のSort -- 画面上一番左の反響日の新しい順など
  const sortCustomerMain = (e) => {
    set('displayOrderCode', Number(e));
    // dispatch(updateObjSearchItems({ displayOrderCode: Number(e) }));
    setAllCustomer(sortCustomerList(e, allCustomer));
  };

  const getAllDisplayCustomer = () => {
    const copyCustomers = [...allCustomer];
    for (const key of copyCustomers) {
      if ('isHide' in key) delete key.isHide;
    }
    return copyCustomers;
  };

  const searchLevel = (level) => {
    const copyCustomers = [...allCustomer];
    for (const key of copyCustomers) {
      if (key.userLevel !== Number(level)) key.isHide = true;
      if (key.userLevel === Number(level)) delete key.isHide;
      if (!key.userLevel && level === '0') delete key.isHide;
    }
    return copyCustomers;
  };

  const searchCustomerList = (key) => {
    if (key) {
      const arr = searchLevel(key);
      setAllCustomer(arr);
    } else {
      setAllCustomer(getAllDisplayCustomer());
    }
  };

  // 絞り込み条件の初期化
  const resetParams = () => {
    const copyObjParams = Object.assign({}, searchHeader);
    const deleteTarget = ['userLevel', 'searchType2', 'searchType3'];
    for (const i in copyObjParams) {
      // displayOrderCode以外のパラメーターをpropsから削除
      if (deleteTarget.includes(i)) {
        delete copyObjParams[i];
      }
    }
    // Reset
    setAllCustomer(getAllDisplayCustomer());
    // setAllCustomer(sortCustomerList(String(searchHeader?.displayOrderCode), allCustomer));
    return copyObjParams;
  };

  // 【1週間以上対応なし】の日時セット用
  // const handleSetSearch2 = () => {
  //   // 一週前の日時取得
  //   const lastWeek = getOfBeforeAfterDays(-7);
  //   setSearchHeader({ ...resetParams(), searchType2: lastWeek });
  // };
  // 【3日以内にWebページ閲覧】の日時セット用
  // const handleSetSearch3 = () => {
  //   // 3日前の日時取得
  //   const threeDaysBefore = getOfBeforeAfterDays(-3);
  //   setSearchHeader({ ...resetParams(), searchType3: threeDaysBefore });
  // };

  const data = [
    {
      key: 'order',
      type: 'drawer',
      title: '並び順',
      menu: selectList[0].menus,
      state: localValues.order,
      setState: (e) => { setLocalValues({ ...localValues, order: Number(e) }); },
      handleClick: () => { setOpen((prev) => ({ ...prev, open0: true })); },
      data: [
        {
          key: 'displayOrderCode',
          menu: selectList[0].menus,
          state: searchHeader.displayOrderCode,
          setState: (e) => { sortCustomerMain(e); },
        },
      ],
    },
    {
      key: 'filter',
      type: 'drawer',
      title: '絞り込み',
      menu: selectList[1].menus,
      state: localValues.filter,
      setState: (e) => { setLocalValues({ ...localValues, filter: Number(e) }); },
      handleClick: () => { setOpen((prev) => ({ ...prev, open1: true })); },
      data: [
        {
          key: 'reset',
          menu: {
            0: '絞り込みなし',
          },
          state: '',
          setState: () => {
            setSearchHeader(resetParams());
            searchCustomerList(false);
          },
        },
        {
          key: 'userLevel',
          menu: {
            5: '★5',
            4: '★4',
            3: '★3',
            2: '★2',
            1: '★1',
            0: '★なし',
          },
          state: searchHeader.userLevelCode,
          setState: (e) => {
            setSearchHeader({ ...resetParams(), userLevel: [e] });
            searchCustomerList(e);
          },
        },
      ],
    },
    {
      key: 'selectUser',
      type: 'modal',
      state: selectUser.userId,
      handleClick: () => { setIsOpenOrgTree(true); },
    },
  ];

  const getUserName = () => {
    let name;
    if (selectUser.userId) {
      if (selectUser.userId === initLoginData.userId) {
        name = '自分が担当';
      } else {
        name = `${selectUser.userName}が担当`;
      }
    } else if (selectDivision.divId) {
      name = `${selectDivision.divisionName}`;
    } else {
      name = 'ツリー選択';
    }
    return name;
  };

  // CustomerTreeでOK押下時
  const executionSelectTree = () => {
    const searchParameterObj = {};
    setSelectUser(selectTreeUser);
    setSelectDivision(selectTreeDivision);
    setSelectCustomer(selectTreeCustomer);
    if (selectTreeUser.userId) {
      searchParameterObj.currentUserId = selectTreeUser.userId;
      searchParameterObj.currentUser = selectTreeUser.userName;
      searchParameterObj.divisionId = '';
    } else if (selectTreeDivision.divId) {
      searchParameterObj.currentUserId = '';
      searchParameterObj.currentUser = '';
      searchParameterObj.divisionId = selectTreeDivision.divId;
    }
    dispatch(updateObjSearchItems(searchParameterObj));
  };

  // CustomerTreeで選択解除押下時
  const executionCancelTree = () => {
    setSelectUser(selectTreeUser);
    setSelectDivision(selectTreeDivision);
    setSelectCustomer(selectTreeCustomer);
  };

  const getDefaultDivisionId = useCallback(() => {
    return selectDivision?.divId && !selectUser?.userId ? selectDivision.divId : '';
  }, [selectDivision, selectUser]);

  const getDefaultUserId = useCallback(() => {
    if (getDefaultDivisionId()) {
      return false;
    }
    return selectUser?.userId ? selectUser?.userId : initLoginData.userId;
  }, [selectDivision, selectUser]);

  useEffect(() => {
    // console.log({ selectUser, selectDivision, selectTreeUser, selectTreeDivision });
    if (isOpenOrgTree) {
      setSelectTreeUser(selectUser);
      setSelectTreeDivision(selectDivision);
    }
  }, [isOpenOrgTree]);

  return (
    data.map((d, index) => (
      <ListItem key={d.key} className={classes.selectItem}>
        <Grid>
          <Button onClick={d.handleClick} className={classes.selectBtn}>
            <span className={classes.selectBtnTxt}>
              {d.key === 'selectUser' ? (
                getUserName()
                // eslint-disable-next-line max-len
                // <span>{selectUser.userId === initLoginData.userId ? '自分' : selectUser.userName}が担当</span>
              ) : d.state}
            </span>
            <ArrowDropDownIcon className={classes.arrowIcon} />
          </Button>
          {d.type === 'drawer' ? (
            <CustomSimpleDrawer
              index={index}
              open={open[`open${index}`]}
              onClose={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: false })); }}
              title={d.title}
              list={<DrawerList initial={d.data} parentKey={d.key} localValues={localValues} setLocalValues={setLocalValues} onClose={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: false })); }} />}
            />
          ) : (
            <CustomerTree
              isOpen={isOpenOrgTree}
              setIsOpen={setIsOpenOrgTree}
              setSelectCustomer={setSelectTreeCustomer}
              setSelectUser={setSelectTreeUser}
              setSelectDivision={setSelectTreeDivision}
              setHandleComfirm={executionSelectTree}
              setHandleCancel={executionCancelTree}
              titleHeader="担当者選択"
              isModal
              searchForm
              options={options}
              targetFlgList={{
                userFlg: true,
                divisionFlg: true,
                customerFlg: true,
              }}
              defaultUserId={getDefaultUserId()}
              defaultDivisionId={getDefaultDivisionId()}
            />
          )}
        </Grid>
      </ListItem>
    ))
  );
});

function UserListSelectBoxSp(props) {
  const {
    initLoginData,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    searchHeader,
    setSearchHeader,
    handleClearFunction,
    selectCustomer,
    setSelectCustomer,
    allCustomer,
    setAllCustomer,
  } = props;
  const selectList = [sortOrderSelect, sortItemSelect];
  const classes = useStyles();

  return (
    <Grid className={`${classes.selectRoot} customerSelect`}>
      <List className={classes.selectList}>
        <GetSelectHeader
          initLoginData={initLoginData}
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          selectList={selectList}
          searchHeader={searchHeader}
          setSearchHeader={setSearchHeader}
          handleClearFunction={handleClearFunction}
          selectCustomer={selectCustomer}
          setSelectCustomer={setSelectCustomer}
          allCustomer={allCustomer}
          setAllCustomer={setAllCustomer}
        />
      </List>
    </Grid>
  );
}

export default UserListSelectBoxSp;
