import InstructionListContainers from '../../containers/instructionList/instructionListContainers';

function InstructionList(props) {
  const { selectCustomer } = props;
  return (
    <div>
      <InstructionListContainers selectCustomer={selectCustomer} />
    </div>
  );
}

export default InstructionList;
