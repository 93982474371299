import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 9px 0 -2px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginRight: 5,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  lbl: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
  },
}));

export default function InstructionsWindowCheckBox(props) {
  const { values } = props;

  const classes = useStyles();

  const initial = () => {
    switch (values.state) {
      case 1:
        return true;
      default:
        return false;
    }
  };

  const [checked, setChecked] = useState({
    checkedA: initial(),
  });

  const handleChange = (e) => {
    setChecked({ ...checked, [e.target.name]: e.target.checked });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            checked={checked.checkedA}
            onChange={(e) => {
              values.setState(e.target.checked);
              handleChange(e);
            }}
            name="checkedA"
          />
        }
        label={values.label}
      />
    </FormGroup>
  );
}
