import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import layoutStyle from '../../styles/layout';
import DivisionDateRow from './divisionDateRow';

const useStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginLeft: 8,
    },
  },
  content: {
    marginTop: 16,
  },
  box: {
    padding: 8,
  },
  th: {
    color: '#8C8C8C',
    padding: '4px 0',
    textAlign: 'center',
    borderBottom: '1px solid #000',
    '& > span': {
      display: 'block',
    },
    '&:not(:last-child) > span': {
      borderRight: '1px solid #C8C8C8',
    },
  },
});

const headCells = [
  { id: 'areaName', numeric: false, disablePadding: true, label: 'エリア' },
  { id: 'editLimitAt', numeric: true, disablePadding: false, label: '自店舗内課編成期日' },
  { id: 'updateAt', numeric: true, disablePadding: false, label: '最終更新日' },
  { id: 'updateUserName', numeric: true, disablePadding: false, label: '最終更新者' },
];

export default function DivisionDate(props) {
  const { functions, data } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const lCommon = layoutStyle();

  const [selected, setSelected] = useState([]);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const updateFunction = () => {
    const targetData = data.param.divisionMasterList.filter(
      item => selected.includes(item.areaCode),
    );
    functions.putSettingFunction({ divisionMasterList: targetData });
  };

  useEffect(() => {
    functions.getAllSettingFunction();
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid className={baseClasses.header}>
        <Grid className={lCommon.inner}>
          <Typography className={baseClasses.title2}>
            課編成日付設定
          </Typography>
        </Grid>
      </Grid>
      <Grid className={`${baseClasses.bodyType2} ${lCommon.scrollToRow3}`}>
        <Grid container className={lCommon.inner}>
          <Grid container className={classes.content}>
            <Box className={baseClasses.box}>
              <TableContainer>
                <Table
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th}>
                        <span>　</span>
                      </TableCell>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          className={`${classes.th} ${baseClasses.title4}`}
                        >
                          <span>{headCell.label}</span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {data.divisionMasterList.length > 0 && (
                    <TableBody>
                      {data.divisionMasterList.map((row, index) => {
                        const isItemSelected = isSelected(row.areaCode);
                        const lalbelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <DivisionDateRow
                            data={data}
                            index={index}
                            row={row}
                            isItemSelected={isItemSelected}
                            lalbelId={lalbelId}
                            handleClick={handleClick}
                          />
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={baseClasses.footer}>
        <Button
          className={baseClasses.buttonsPrimary}
          onClick={updateFunction}
          disabled={selected.length === 0 || !data.hasPerMission}
        >
          確定
        </Button>
      </Grid>
    </Grid>
  );
}
