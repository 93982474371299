import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const actGetTodayGuidancesSlice = createSlice({
  name: 'actGetTodayGuidances',
  initialState: { actGetTodayGuidances: initialState },
  reducers: {
    actGetTodayGuidancesApi: (state, data) => {
      state.actGetTodayGuidances = data.payload;
    },
  },
});

export const { actGetTodayGuidancesApi } = actGetTodayGuidancesSlice.actions;

export default actGetTodayGuidancesSlice.reducer;
