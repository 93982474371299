import { useMemo, useCallback } from 'react';
import FavoritesTableRow from './favoritesTableRow';
import CustomTable from './customTable';
import { MEMBERS_REDUCER_KEYS } from '../../../containers/browsingHistory/browsingHistoryReducer';

const headCells = [
  { id: 'accessedAt', label: '日時' },
  { id: 'homepage', label: <span>自社<br />HP</span>, style: { textAlign: 'center' } },
  { id: 'registaUrl', label: <span>レジ<br />スタ</span>, style: { textAlign: 'center' } },
  { id: 'price', label: '種別', style: { textAlign: 'center' } },
  { id: 'buildingType', label: '価格(万円)', style: { textAlign: 'center' } },
  { id: 'address1', label: '物件情報', style: { textAlign: 'center' } },
  { id: 'railway', label: '交通', style: { textAlign: 'center' } },
  { id: 'InformationProvider', label: '情報提供', style: { textAlign: 'center' } },
];

export default function FavoritesTable(props) {
  const {
    data,
    getApiDataFromListApi,
    isSP,
    adjustPriceText,
    handleOpenModal = null,
  } = props;
  const favoritesTablelimit = localStorage.getItem('favoritesTablelimit') ? localStorage.getItem('favoritesTablelimit') : 20;
  const setFavoritesTablelimit = useCallback((current) => {
    localStorage.setItem('favoritesTablelimit', current);
  }, []);
  const tableHeadCells = useMemo(() => {
    // お気に入りにレジスタが、モバイルのみこちら不要
    return isSP ? headCells.filter((item) => item.id !== 'registaUrl') : headCells;
  }, [isSP]);

  return (
    <CustomTable
      hideHeader={isSP}
      title="お気に入りリスト"
      headCells={tableHeadCells}
      total={data.total}
      list={data.favorites}
      rowRender={(list) => list.map((row, i) => (<FavoritesTableRow
        key={String(i)}
        row={row}
        isSP={isSP}
        adjustPriceText={adjustPriceText}
      />))}
      onPageChange={(pageInfo) => {
        getApiDataFromListApi(MEMBERS_REDUCER_KEYS.favorites, pageInfo);
      }}
      divider
      sortable
      contentHeight={(isSP || handleOpenModal === null) ? null : 250}
      propLimit={favoritesTablelimit}
      setPropLimit={setFavoritesTablelimit}
      isNoSlice
      handleOpenModal={handleOpenModal}
    />
  );
}
