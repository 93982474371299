// import { useState } from 'react';
import { List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import commonStyles from '../../../styles';

import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  point: {
    marginRight: '1em',
  },
  list: {
    padding: '16px 0 16px 16px',
  },
  listItem: {
    padding: '18.5px 16px',
    borderBottom: '1px solid #F3F3F3',
    '& *': {
      fontWeight: 'bold',
    },
  },
  gutters: {
    margin: '0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      '&.Mui-selected': {
        background: '#fff',
        color: theme.palette.primaryColor,
      },
      '& > .MuiListItemText-root': {
        margin: 0,
      },
    },
  },
  addButtonIcon: {
    fontSize: '16px',
    marginRight: '8px',
  },
  new: {
    color: theme.palette.primaryColor,
    fontWeight: 'bold',
  },
}));

export default function SmsDrawerNameChangeList(props) {
  const { values, setOpenDrawer, setHandleOpenEditWindow, updateFunction } = props;

  const classes = useStyles();
  const common = commonStyles();

  return (
    <List className={classes.list}>
      {values.list.map((item, index) => {
        if (item === '') { return false; }
        return (
          <ListItem
            button
            key={item}
            className={classes.listItem}
            classes={{ gutters: classes.gutters }}
            selected={values.state === item}
            disabled={item === '0'}
            onClick={() => {
              values.setState(item);
              updateFunction(item);
              setOpenDrawer(false);
            }}
          >
            <Typography className={classes.point}>{index === 0 ? '主' : '副'}</Typography>
            <ListItemText
              primary={item}
              className={classes.listItemText}
            />
          </ListItem>
        );
      })}

      <ListItem
        button
        className={`${classes.listItem} ${classes.new}`}
        onClick={() => {
          setHandleOpenEditWindow(true);
        }}
      >
        <AddIcon className={`${classes.addButtonIcon} ${common.icon}`} />
        新規入力
      </ListItem>
    </List>
  );
}
