import { useState } from 'react';

import simulatorGetJISCodeApi from '../../apis/simulator/simulatorGetJISCodeApi';
import simulatorJudgementApi from '../../apis/simulator/simulatorJudgementApi';
import simulatorUploadApi from '../../apis/simulator/simulatorUploadApi';
import simulatorDownLoadSampleApi from '../../apis/simulator/simulatorDownLoadSampleApi';
import simulatorDownLoadApi from '../../apis/simulator/simulatorDownLoadApi';

import { useStoreGroupsSettingsAreas } from '../../components/combinationSetting/common/useStoreGroupsSettingsAreas';

import { dateFormatter } from '../../commonFunction';
import { downloadCSV } from '../../commonFunction/csv';

import SimulatorWrapper from '../../components/simulator/simulator';

function SimulatorContainers() {
  // /store-groups/settings/areas
  // 【settingGroupStore008】エリアリスト取得
  const [cities, setCities] = useState([]);

  const { areaList: areas } = useStoreGroupsSettingsAreas();

  // /simulator/judgement
  // 【simulation003】判定シミュレーション実行
  const [storesStores, setStoresStores] = useState([]);
  const [storeScores, setStoreScores] = useState([]);

  const clickAreaTabFunc = (code) => {
    const isIncluded = cities.some((c) => c.id === code);
    if (!isIncluded) {
      simulatorGetJISCodeApi(code).then((res) => {
        setCities([...cities, { id: code, val: res.data.cities }]);
      });
    }
  };

  const judgementStart = (param) => {
    simulatorJudgementApi(param)
      .then((res) => {
        setStoresStores(res.data.storesStores);
        setStoreScores(res.data.storeScores);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadFunc = (code) => {
    const downLoadFileName = `反響自動処理シミュレーション_${dateFormatter(
      new Date(),
      'YYYYMMDDhhmmss',
    )}.csv`;
    simulatorDownLoadSampleApi({ areaCode: code })
      .then((res) => {
        downloadCSV(res.data, downLoadFileName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReusultFunc = (receptionId) => {
    const downLoadFileName = `反響自動処理シミュレーション_結果_${dateFormatter(
      new Date(),
      'YYYYMMDDhhmmss',
    )}.csv`;
    simulatorDownLoadApi(receptionId)
      .then((res) => downloadCSV(res.data, downLoadFileName))
      .catch((err) => console.error(err));
  };

  const uploadFunc = (e, areaCode, callback) => {
    simulatorUploadApi(e, areaCode)
      .then((res) => {
        // downloadCSV(res.data, downLoadFileName);
        callback(res.data.receptionId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SimulatorWrapper
      areas={areas}
      clickAreaTabFunc={clickAreaTabFunc}
      cities={cities}
      storesStores={storesStores}
      setStoresStores={setStoresStores}
      storeScores={storeScores}
      setStoreScores={setStoreScores}
      judgementStart={judgementStart}
      downloadFunc={downloadFunc}
      uploadFunc={uploadFunc}
      downloadReusultFunc={downloadReusultFunc}
    />
  );
}

export default SimulatorContainers;
