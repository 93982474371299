import { TablePagination } from '@material-ui/core';
import { useLocalStorage } from '../../../hooks';

export default function UnsentPagenation(props) {
  const {
    rowsPerPageOptions,
    rows,
    limit,
    setOffset,
    setLimit,
    mailCount,
    pageCount,
    setPageCount,
  } = props;
  const { addLocalStorage } = useLocalStorage();

  const handleChangePage = (event, newPage) => {
    setPageCount(newPage);
    setOffset(limit * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    addLocalStorage(+event.target.value);
    setOffset(0);
    setPageCount(0);
  };
  return (
    <TablePagination
      count={rows === 0 ? mailCount.individuallyMailCount : mailCount.broadCastMailCount}
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={limit}
      page={pageCount} // offset
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="表示行数"
      component="div"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
    />
  );
}
