import {
  Grid,
  TextField,
  Checkbox,
  Typography,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import TextBaseField from '../../eleCommon/validation/TextBaseField';
import { validateFormInt1, validateFormString1 } from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '8px',
    margin: 0,
    height: '100%',
  },
  disable: {
    background: '#F3F3F3',
  },
  checkbox: {
    paddingLeft: '4px',
    '& .Mui-disabled': {
      color: '#333333',
    },
  },
  textField: {
    paddingLeft: '4px',
    width: '100%',
    '& .Mui-disabled': {
      color: '#333333',
    },
  },
  label: {
    '& span.required': {
      color: '#D83420',
    },
  },
});

function BaseComponent(props) {
  const { initial, classes, children } = props;
  return (
    <Grid
      className={`${classes.content} ${initial.contentClass} ${
        initial.disable ? classes.disable : ''
      }`}
    >
      <Typography className={`${classes.label} ${initial.labelClass}`}>
        {initial.label}
        {initial.required ? <span className="required">＊</span> : null}
      </Typography>
      <Grid className={initial.inputWrapClass}>{children}</Grid>
    </Grid>
  );
}

// initial 以下keyとvalue
// label: ラベル名
// type: select or text (defaultはtext)
// required: 必須アスタリスクの有無
// disable: 無効化
// menus: selectの選択肢
// contentClass: 包むGridのClass
// labelClass: labelのClass
// inputClass: inputのClass
// inputWrapClass: inputを囲む親のClass
// onBlur: フォーカスが外れた時の処理
// defaultValue: デフォルト値

function CustomInputStickyLabel(props) {
  const { initial } = props;
  initial.defaultValue = initial.defaultValue ? initial.defaultValue : '';
  const classes = useStyles();
  const common = commonStyles();

  initial.onChange = initial.onChange ? initial.onChange : () => {};
  initial.onBlur = initial.onBlur ? initial.onBlur : () => {};

  switch (initial.type) {
    case 'select':
      return (
        <BaseComponent classes={classes} initial={initial}>
          <TextField
            select
            className={`${classes.textField} ${initial.inputClass}`}
            defaultValue={initial.defaultValue ? initial.defaultValue : 0}
            onChange={(e) => initial.onChange(e)}
            onBlur={(e) => initial.onBlur(e)}
            disabled={initial.disable}
            required={initial.required}
          >
            <MenuItem
              disabled
              value={0}
            >
              未選択
            </MenuItem>
            {Object.keys(initial.menus).map((data) => (
              <MenuItem value={data} key={data} size="small">
                {initial.menus[data]}
              </MenuItem>
            ))}
          </TextField>
        </BaseComponent>
      );
    case 'checkbox':
      return (
        <BaseComponent classes={classes} initial={initial}>
          <Checkbox
            className={`${classes.checkbox}`}
            checked={initial.defaultValue}
            onChange={(e) => initial.onChange(e)}
            onBlur={(e) => initial.onBlur(e)}
            inputProps={{ classes: { disabled: classes.label } }}
            disabled={initial.disable}
            required={initial.required}
          />
        </BaseComponent>
      );

    case 'order':
      return (
        <BaseComponent classes={classes} initial={initial}>
          <TextBaseField
            className={`${common.textField} ${classes.textField} ${initial.inputClass}`}
            defaultValue={initial.defaultValue}
            onChange={(e) => initial.onChange(e)}
            onBlur={(e) => initial.onBlur(e)}
            inputProps={{ classes: { disabled: classes.label } }}
            disabled={initial.disable}
            required={initial.required}
            validator={validateFormInt1({
              minFloat: -999,
              maxFloat: 999,
            })}
            convertor={convertFormNumber}
            name={initial.name}
            errorCallback={initial.errorCallback}
          />
        </BaseComponent>
      );
    default:
      return (
        <BaseComponent classes={classes} initial={initial}>
          <TextBaseField
            className={`${common.textField} ${classes.textField} ${initial.inputClass}`}
            defaultValue={initial.defaultValue}
            onChange={(e) => initial.onChange(e)}
            onBlur={(e) => initial.onBlur(e)}
            inputProps={{ classes: { disabled: classes.label } }}
            disabled={initial.disable}
            required={initial.required}
            validator={validateFormString1({
              maxLengthInt: 50,
            })}
            name={initial.name}
            errorCallback={initial.errorCallback}
          />
        </BaseComponent>
      );
  }
}

export default CustomInputStickyLabel;
