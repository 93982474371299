import BulkChargeChangeWindowContainers from './bulkChargeChangeWindow';

function BulkChargeChangeWindow(props) {
  const {
    open,
    onClose,
    choice,
    selectedCustomerId,
    updateStart,
  } = props;

  return (
    <BulkChargeChangeWindowContainers
      open={open}
      onClose={onClose}
      choice={choice}
      selectedCustomerId={selectedCustomerId}
      updateStart={updateStart}
    />
  );
}

export default BulkChargeChangeWindow;
