const changeBtnText = (key) => {
  let reWord = '';
  switch (key) {
    // ランク
    case '':
      reWord = 'なし';
      break;
    case '★1':
      reWord = '★';
      break;
    case '★2':
      reWord = '★★';
      break;
    case '★3':
      reWord = '★★★';
      break;
    case '★4':
      reWord = '★★★★';
      break;
    case '★5':
      reWord = '★★★★★';
      break;
    // 共通
    case '1~7':
      reWord = '1～7日';
      break;
    case '8~14':
      reWord = '8～14日';
      break;
    case '15～21':
      reWord = '15～21日';
      break;
    case '22~31':
      reWord = '22日～最終日';
      break;
    case 'claim':
      reWord = 'クレーム';
      break;
    case 'contracted':
      reWord = '契約済';
      break;
    case 'no_talked':
      reWord = '通話なし';
      break;
    case 'no_mailed':
      reWord = '送信なし';
      break;
    case '～today':
      reWord = '今日';
      break;
    case '～last_week':
      reWord = '～1週間前';
      break;
    case '～last_month':
      reWord = '～1ヶ月前';
      break;
    case '～last_3months':
      reWord = '～3ヶ月前';
      break;
    case 'last_3months～':
      reWord = '3ヶ月より前';
      break;
    default:
      reWord = key;
      break;
  }

  return reWord;
};

export default changeBtnText;
