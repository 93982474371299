import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    margin: '0 2px 2px 0',
  },
  text: {
    fontFamily: 'Roboto',
    color: theme.palette.primaryColor,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
}));

export default function MemberStatusCode(props) {
  const {
    onClick,
  } = props;

  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      onClick={onClick}
    >
      <UpdateIcon className={classes.icon} />
      <Typography className={classes.text}>
        更新
      </Typography>
    </Button>
  );
}
