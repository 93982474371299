/* eslint-disable import/prefer-default-export */
import imageCompression from 'browser-image-compression';

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
};

export const compressImage = async (file, additionalOptions = {}) => {
  const compressedFile = await imageCompression(file, {
    ...options,
    ...additionalOptions,
  });

  return compressedFile;
};
