import client from '../httpClient';

/**
 * 【webRes004】未処理反響登録
 * content-type: multipart/form-data
 * @module responsePostNewResponseApi
 * @param {*} params - 必須：bodyパラメータ
 * @returns axios post method
 */
const responsePostNewResponseApi = (params) => {
  const data = new FormData();

  Object.keys(params).forEach(key => {
    /**
   * {
   *   id: number,
   *   fileName: string, // 表示名
   *   base64: string,
   *   mimeType: string,
   * }
   */
    if (key === 'attachmentFiles') {
      params[key].forEach(async (item) => {
        if (item?.file instanceof Blob) data.append('attachmentFiles[]', item.file, item.fileName);
      });
    } else if (params[key] !== null) {
      // nullが対象外にする、Stringの'null'にされないため
      data.append(key, params[key]);
    }
  });

  const headers = { 'Content-Type': 'multipart/form-data' };

  return client.post('/responses', data, { headers });
};

export default responsePostNewResponseApi;
