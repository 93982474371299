import ResponseTypeContainers from '../../../containers/common/responseType/responseTypeContainers';

function ResponseType(props) {
  const {
    state,
    setState,
    open,
    setOpen,
    defaultResponse,
    resetData,
    set,
    isSearch,
    addResponseType,
    parentOpen,
  } = props;

  return (
    <ResponseTypeContainers
      state={state}
      setState={setState}
      open={open}
      setOpen={setOpen}
      defaultResponse={defaultResponse}
      resetData={resetData}
      set={set}
      isSearch={isSearch}
      addResponseType={addResponseType}
      parentOpen={parentOpen}
    />
  );
}

export default ResponseType;
