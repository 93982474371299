import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: 'message',
  description: 'description',
  data: {
    userId: 0,
    total: 0,
    unregists: [
      // {
      //   guidanceId: 1,
      //   guidanceStartAt: '2020/12/23 12:00:00',
      //   guidanceEndAt: '2020/12/23 13:00:00',
      //   customerId: 1111,
      //   customerName: 'テスト顧客名0',
      //   guidanceStatusCode: 1,
      //   guidanceGetUserId: 1111,
      //   guidanceGetUserName: 'テスト案内獲得者0',
      //   memo: 'メモテスト0',
      // },
    ],
  },
};

export const schedulesGetUnregistGuidancesSlice = createSlice({
  name: 'schedulesGetUnregistGuidances',
  initialState: { schedulesGetUnregistGuidances: initialState },
  reducers: {
    schedulesGetUnregistGuidances: (state, data) => {
      state.schedulesGetUnregistGuidances = data.payload;
    },
  },
});

export const { schedulesGetUnregistGuidances } = schedulesGetUnregistGuidancesSlice.actions;

export default schedulesGetUnregistGuidancesSlice.reducer;
