/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Popper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import mokuhyoSetDeleteApi from '../../../../apis/mokuhyoSet/mokuhyoSetDeleteApi';
import commonStyles from '../../../styles';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';

import { GROUP_PERSONAL_TH, MAN_YEN } from '../../../../constants';
import { USER_CLASS_SELECT } from '../../../../constants/users';
import { toNumber } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  color: { color: theme.palette.primaryColor },
  commonBorderBottom: {
    borderRight: '1px solid #C8C8C8',
    padding: '3px 10px',
    cursor: 'pointer',
  },
  popper: {
    background: '#fff',
    display: 'flex',
    left: '0',
    borderRadius: '3px',
    marginLeft: '10px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
  },
  customTable: {
    border: '1px solid #C8C8C8',
    boxShadow: 'none',
    overflow: 'visible',
  },
  customGrey: {
    color: '#8C8C8C',
  },
  customRow: {
    position: 'relative',
    '&:nth-child(odd)': { backgroundColor: '#f3f3f3' },
    '&:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
    },
  },
  tableWrap: {
    width: '100%',
  },
  dltBtn: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '20px',
    fontSize: '16px',
  },
}));

function PersonalTable(props) {
  const { reload, setReload } = props;
  const classes = useStyles();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectTarget, setSelectTarget] = useState('');
  const mokuhyoList = useSelector((state) => state.mokuhyoList.mokuhyoList);
  const common = commonStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState('');

  const getTargetCell = (data) => {
    const tdList = [
      data.year,
      data.month,
      toNumber(data.buildingCount).toFixed(1),
      data.sales / MAN_YEN,
      data.divisionName,
      USER_CLASS_SELECT.menus[data.positionCode],
      data.updatedDate,
    ];
    return tdList.map((val, index) => (
      <TableCell
        key={String(data.IDivisionID) + String(index) + String(tdList[index])}
        align="right"
        className={classes.commonBorderBottom}
      >
        {val}
      </TableCell>
    ));
  };

  // クリックevent
  const selectRow = (e, data) => {
    e.preventDefault();
    console.log(e.target.offsetParent.childNodes[5]);
    setAnchorEl(e.target.offsetParent.childNodes[5]);
    setIsDeleteOpen(true);
    setSelectTarget(data.targetId);
    // setCoordinate({ x: e.clientX, y: e.offsetY - 10 });
  };

  // 削除
  const deleteMokuhyo = async () => {
    setIsDeleteOpen(false);
    await mokuhyoSetDeleteApi(selectTarget)
      .then(() => {
        setReload(reload + 1);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(selectTarget);
  };

  const getTargetPersonal = () => {
    if ('targets' in mokuhyoList) {
      if (mokuhyoList.targets.length > 0) {
        return mokuhyoList.targets.map((data) => (
          <TableRow
            key={String(data.dvisionID) + String(data.targetId)}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className={classes.customRow}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onMouseEnter={(e) => {
              selectRow(e, data);
            }}
          >
            {getTargetCell(data)}
          </TableRow>
        ));
      }
    }
    return <p style={{ paddingLeft: 8 }}>データがありません</p>;
  };

  // 削除モーダル
  const confirmOpen = () => {
    dispatch(
      changeConfirmMessage({
        title: '選択項目を削除',
        msgList: ['削除してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: (() => console.log('cancel')),
            classes: common.buttonsSecondary,
          },
          {
            label: 'OK',
            set: deleteMokuhyo,
            classes: common.buttonsPrimary,
          },
        ],
      }),
    );
  };

  return (
    <Grid container className={classes.tableWrap}>
      <TableContainer component={Paper} className={classes.customTable} onMouseLeave={() => { setIsDeleteOpen(false); }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {GROUP_PERSONAL_TH.map((th) => (
                <TableCell
                  key={th}
                  align="left"
                  className={`${classes.commonBorderBottom} ${classes.customGrey}`}
                >
                  {th}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getTargetPersonal()}
          </TableBody>
        </Table>
        {Boolean(anchorEl) && (
        <Popper
          open={isDeleteOpen}
          anchorEl={anchorEl}
          id={`popper-${selectTarget}`}
          className={classes.popper}
          placement="right"
          transition
          disablePortal
        >
          <IconButton
            onClick={() => confirmOpen()}
            className={classes.dltBtn}

          >
            <DeleteOutlineIcon className={classes.color} />
            <div className={classes.color}>削除</div>
          </IconButton>
        </Popper>
        )}
      </TableContainer>
    </Grid>
  );
}

export default PersonalTable;
