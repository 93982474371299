import { useMemo } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import commonStyles from '../../styles';

function DivisionUpdateRow(props) {
  const baseClasses = commonStyles();

  const {
    row: user,
    index,
    currentPageNumber,
  } = props;

  const no = useMemo(() => {
    return (index + 1) + currentPageNumber * 20;
  }, [currentPageNumber, index]);

  return (
    <TableRow>
      <TableCell style={{ padding: 4 }}>{no}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.userId}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.userFullName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.oldPositionName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.newPositionName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.oldGradeName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.newGradeName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.oldUserRoleGroupName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.newUserRoleGroupName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.mainDivisionName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.oldDivisionName}</TableCell>
      <TableCell style={{ padding: 4 }}>{user.newDivisionName}</TableCell>
      <TableCell style={{ padding: 4 }}><Typography className={baseClasses.large}>{user.isNewManager ? '〇' : '―'}</Typography></TableCell>
      <TableCell style={{ padding: 4 }}><Typography className={baseClasses.large}>{user.isCustomerMove ? '〇' : '✕'}</Typography></TableCell>
    </TableRow>
  );
}

export default DivisionUpdateRow;
