import React, { useEffect } from 'react';
import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import positionChange from '../../commonFunction/positionChange';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
  placeholder: { color: '#c8c8c8', textAlign: 'left' },
}));

function CustomMainSelectPlaceholder(props) {
  const classes = useStyles();
  const { initial } = props;
  const menusKeys = Object.keys(initial.obj.menus);

  const [val, setVal] = React.useState(initial.state);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setVal(newValue);
    initial.onChange?.(newValue, initial);
    initial.change?.(e, initial);
  };

  useEffect(() => {
    if (initial.state !== val) setVal(initial.state ?? '');
  }, [initial.state]);

  return (
    <FormControl className={`${classes.formControl} ${classes.formControl2}`}>
      <Select
        value={val === null || val === undefined ? '' : val}
        displayEmpty
        name={initial.name}
        className={`${classes.selectEmpty} ${val === null ? classes.placeholder : ''}
          ${val === '' ? classes.placeholder : ''} ${val === undefined ? classes.placeholder : ''}`}
        inputProps={{ 'aria-label': 'Without label' }}
        onChange={handleChange}
        onBlur={(e) => {
          initial.set?.(e, initial);
        }}
        onOpen={positionChange}
      >
        <MenuItem
          value=""
          disabled
        >
          {initial.placeholder}
        </MenuItem>
        {menusKeys.map((data) => (
          <MenuItem
            key={data}
            value={data}
          >
            {initial.obj.menus[data]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default React.memo(CustomMainSelectPlaceholder);
