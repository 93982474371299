import {
  Grid,
  Typography,
  Link,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import commonTheme from '../../../styles/theme';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '10px 8px 10px 16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '8px 8px 7px',
    },
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  ttl: {
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    letterSpacing: 0,
    fontWeight: '700',
    [commonTheme.breakpoints.down('sm')]: {
      lineHeight: 1.2,
      // width: 110,
      marginRight: 'auto',
      flexShrink: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      lineHeight: 1.6,
      width: 85,
    },
    '& span': {
      color: '#D83420',
    },
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  link: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
  },
  icon: {
    fontSize: 14,
    margin: '0px 0 -2px 8px',
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 10,
  },
  history: {
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      marginLeft: 'auto',
      whiteSpace: 'nowrap',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function InfoRegisterChoice1(props) {
  const { values } = props;
  const classes = useStyles();

  return (
    <Grid
      className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
    >
      <Typography className={classes.ttl}>
        {values.label}
        {values.required && <span>＊</span>}
      </Typography>
      <Grid className={classes.name}>
        <Link
          href={`${URL_MAPPER.customer}?customerId=${values.customerId}`}
          target="_blank"
          className={classes.link}
        >
          {values.state}
          <LaunchIcon className={classes.icon} />
        </Link>
        <Typography className={classes.txt}>様</Typography>
      </Grid>
      <Button
        className={classes.history}
        onClick={values.openHistory}
      >
        接触履歴
      </Button>
    </Grid>
  );
}

export default InfoRegisterChoice1;
