/* eslint-disable max-len */
import { IconButton, makeStyles, Grid } from '@material-ui/core';
import { DeleteOutline, Launch, GetApp } from '@material-ui/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import commonStyles from '../../styles';

import { getEnvVariable } from '../../../commonFunction/getEnv';
import copyToClipboard from '../../../commonFunction/clipboard';
import NormalTooltip from '../../styles/tooltip';

const useStyles = makeStyles((theme) => ({
  popper: {
    background: '#fff',
    borderRadius: '3px',
    pointerEvents: 'none',
    position: 'absolute',
    top: 10,
    right: 16,
  },
  btn: { color: theme.palette.primaryColor, padding: 0 },
  downloadBtn: {
    width: '24px',
    color: '#8C8C8C',
    padding: '2px 4px',
    fontSize: '14px',
    pointerEvents: 'all',
    '&:hover': { cursor: 'pointer' },
  },
  launchIcon: {
    fontSize: '18px',
  },
  deleteBtn: {
    width: '24px',
    color: '#D83420',
    padding: '2px 4px',
    pointerEvents: 'all',
    '&:hover': { cursor: 'pointer' },
  },
  deleteIcon: {
    fontSize: '20px',
  },
  copyarea: { fontSize: 0, border: 0, width: 0, height: 0, outline: 0, display: 'none' },
}));

export default function FilePopper(props) {
  const { row, setshowAlert, updateFiles, downloadFiles } = props;
  const common = commonStyles();
  const classes = useStyles();

  const dispatch = useDispatch();

  const [disable, setDisable] = useState(false);
  const downloadInterval = () => setDisable(false);

  const clearAlertAfterSeconds = window.setTimeout(setshowAlert, 4000, false);

  const downloadBtnClickHandler = () => {
    setDisable(true);
    downloadFiles(row);
    // ボタン連打防止
    setTimeout(downloadInterval, 1000);
  };

  const copyBtnClickHandler = () => {
    copyToClipboard(`${getEnvVariable('LASTA_BASE_URL')}filedownload${row.url}`);
    window.clearTimeout(clearAlertAfterSeconds);
    setshowAlert(row.shareFileStorageAttachmentId);
    window.clearAlertAfterSeconds;
  };
  const onDelete = () => {
    updateFiles({ fileStorages: [{
      shareFileStorageAttachmentId: row.shareFileStorageAttachmentId,
      deletedReasonCode: 2,
    }] });
  };
  const onClickDelete = () => {
    dispatch(changeConfirmMessage({
      msgList: ['削除されます'], // 必須
      title: '削除しますか？', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'CANCEL', // ボタンラベル
        set: () => { return false; }, // ボタンをクリックしたときの処理
        classes: common.buttonsSecondary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }, {
        label: 'OK', // ボタンラベル
        set: onDelete, // ボタンをクリックしたときの処理
        classes: common.buttonsPrimary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
    }));
  };
  return (
    <Grid
      className={classes.popper}
    >
      <NormalTooltip title="ダウンロード">
        <IconButton
          className={classes.downloadBtn}
          onClick={downloadBtnClickHandler}
          size="small"
          disabled={disable}
        >
          <GetApp />
        </IconButton>
      </NormalTooltip>
      <NormalTooltip title="ダウンロードURLコピー">
        <IconButton
          className={classes.downloadBtn}
          onClick={copyBtnClickHandler}
          size="small"
        >
          <Launch className={classes.launchIcon} />
        </IconButton>
      </NormalTooltip>
      <NormalTooltip title="削除">
        <IconButton
          onClick={onClickDelete}
          className={classes.deleteBtn}
          size="small"
        >
          <DeleteOutline className={classes.deleteIcon} />
        </IconButton>
      </NormalTooltip>
      <textarea
        className={classes.copyarea}
        id={`span${row.shareFileStorageAttachmentId}`}
        value={row.url}
      />
    </Grid>
  );
}
