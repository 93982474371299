import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import UnregisteredRecordTable from './parts/unregisteredRecordTable';
import AllPagenation from './parts/allPagenation';
import InfoRegister from '../common/infoRegister';
import { useLocalStorage } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: 'none',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordion: {
    '& .MuiAccordionDetails-root': {
      display: 'block',
      padding: 0,
    },
  },
  toggleButton: {
    padding: 0,
    textAlign: 'left',
    display: 'block',
    minWidth: 10,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5,
    '& .MuiSelect-icon': {
      color: theme.palette.primaryColor,
    },
    '& .MuiIconButton-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: '#C8C8C8',
    },

  },
  accordingDetails: {
    maxHeight: 230,
    overflow: 'auto',
  },
  buttonFlex: {
    display: 'flex',
    flexDirection: 'flex-start',
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
      '& .MuiIconButton-label': {
        color: theme.palette.primaryColor,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
}));

export default function UnregisteredRecordComponent(props) {
  const {
    dataList,
    getCustomerFunction,
    guidanceId,
    customer,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  // ページネーション
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const { getLocalStorage, addLocalStorage } = useLocalStorage();
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  // モーダルを閉じるsetState
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const displayLimit = getLocalStorage();
    if (!displayLimit) {
      setRowsPerPage(20);
      addLocalStorage(20);
    } else if (rowsPerPage !== Number(displayLimit)) {
      setRowsPerPage(Number(displayLimit));
    }
  }, []);

  useEffect(() => {
    if (!open && customer.customerId) {
      handleOpen();
    }
  }, [customer]);

  // const provisionalCustomerData = {
  //   abodeNowBorrowingAmount: '3500',
  //   abodeNowBankName: 'テスト銀行',
  //   abodeNowBuildingType: '賃貸UR',
  //   abodeNowCarCount: '1',
  //   abodeNowCarType: 'プリウス',
  //   abodeNowCurrentMaturity: '5',
  //   abodeNowFloorPlanCode: 2,
  //   abodeNowLivingSpace: '50',
  //   abodeNowPriceAtTime: 4000,
  //   abodeNowPurchaseAt: '2018/05/10',
  //   abodeNowRent: '10',
  //   abodeNowReplaceType: '買替種類',
  //   abodeNowReplacementCode: 1,
  //   abodeNowResidencePeriod: '5',
  //   abodeNowSalePrice: 4000,
  //   acquisitionCode: 1,
  //   acquisitionId: 1,
  //   acquisitionName: '田中太郎',
  //   address1: null,
  //   address2: null,
  //   adultCount: '2',
  //   age: 31,
  //   annualIncome: '600',
  //   assistAmount: '500',
  //   birthday: '1990/10/01',
  //   bonusPayment: '30',
  //   building: null,
  //   checkedProductCount: '4',
  //   checkedProductType: '戸建て、マンション',
  //   childAge: 5,
  //   childCount: '1',
  //   company: null,
  //   companyAddress: null,
  //   companyRailway: null,
  //   companyRailwayId: null,
  //   companyStation: null,
  //   companyStationId: null,
  //   companyWorkingYear: null,
  //   convenientTime: '13時～15時/15時～18時',
  //   customerId: 1,
  //   customerStatusCode: 11,
  //   deposit: '400',
  //   detachedHouseBudgetMax: 6000,
  //   detachedHouseBudgetMin: 4000,
  //   directId: 1,
  //   directStatusCode: 7,
  //   divisionId: 1,
  //   divisionSubName: '赤羽営業センター',
  //   driveUrl1: 'example1.com',
  //   driveUrl2: 'example2.com',
  //   driveUrl3: 'example3.com',
  //   effectAt: '2021/08/23 14:00:00',
  //   emailStatusCode: 0,
  //   expireAt: '2022/01/10 00:00:00',
  //   familyTotalCount: '3',
  //   familyWho: '家族',
  //   fax: null,
  //   fineResponseTypeId: 4,
  //   fineResponseTypeName: '反響媒体細1',
  //   firstName: '太郎',
  //   firstNameKana: 'ジュン2',
  //   lastName: 'サンプル',
  //   lastNameKana: 'カトウ1',
  //   floorPlanCode: 4,
  //   genderCode: 1,
  //   guidanceScheduleId: 34,
  //   guidanceStartAt: '2022/09/07 11:29:47',
  //   guidanceStatusCode: 2,
  //   holidayWeekday: '土曜日/日曜日',
  //   importantPoint: '駅の近さ、セキュリティ',
  //   isCarPark: 0,
  //   leaderMemo: '上長メモテスト1',
  // };

  return (
    <Grid className={classes.container}>
      <Accordion
        className={classes.accordion}
      >
        <Grid className={classes.headerContainer}>
          <Grid className={classes.buttonFlex}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.toggleButton} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            />
            <Grid>
              <Typography className={baseClasses.title2}>
                実績未登録({dataList.data.unregists.length})
              </Typography>
            </Grid>
          </Grid>
          <AllPagenation
            rowsPerPageOptions={[20, 40, 60]}
            rows={dataList.data.unregists.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            className={classes.allPageNation}
          />
        </Grid>
        <AccordionDetails className={classes.accordingDetails}>
          <UnregisteredRecordTable
            dataList={dataList}
            rowsPerPage={rowsPerPage}
            page={page}
            getCustomerFunction={getCustomerFunction}
            customer={customer}
          />
          <InfoRegister
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            // guidanceId="40" // 検証のためにisReserved=1のguidanceを取得
            guidanceId={guidanceId}
            customer={customer}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
