import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorage } from '../../commonFunction';

export const CACHE_KEY_STORE_AREA_LIST = 'CACHE_KEY_STORE_AREA_LIST';

const initialState = getLocalStorage(CACHE_KEY_STORE_AREA_LIST, []);

export const settingGroupStoreAreasSlice = createSlice({
  name: 'settingGroupStoreAreas',
  initialState: { areaList: initialState },
  reducers: {
    setAreas: (state, data) => {
      state.areaList = data.payload;
    },
  },
});

export const { setAreas } = settingGroupStoreAreasSlice.actions;

export default settingGroupStoreAreasSlice.reducer;
