import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, Grid } from '@material-ui/core';
import commonStyles from '../../../styles';
import UserTreeSelectBox from './userTreeSelectBox';
import UserTreeList from './treeList';
import UserTreeSearch from './userTreeSearch';
import UserSortNameTree from './userSortNameTree';

import { useUserTreeContext } from '../context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    // maxWidth: '364px',
    height: '100%',
    // ローディング前は非表示
    opacity: 0,
    visibility: 'hidden',
    '&.isTypeDialog': {
      maxWidth: '100%',
      borderRadius: 4,
      border: '#c8c8c8 1px solid',
    },
    // ローディング後
    '&.isShow': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  list: {
    width: '100%',
    maxHeight: '100%',
    minHeight: '30%',
    overflow: 'scroll',
  },
  summary: {
    backgroundColor: '#F3F3F3',
    height: '24px',
    lineHeight: 1.8,
    minHeight: 'auto !important',
    '& .MuiAccordionSummary-expandIcon svg': {
      fill: theme.palette.primaryColor,
    },
  },
  icon: {
    width: '15px',
    marginRight: '8px',
  },
  accordionDetails: {
    height: '100%',
    alignItems: 'flex-start',
    padding: '8px',
  },
  innerGridContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
}));

function UserTreeIndex(props) {
  const {
    list,
    setList,
    noAffiliList,
    // setNoAffiliList,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    treeHeaderSelect,
    getObjectSortFunction,
    headValue,
    showOnlyDivisions,
    clickableUserAndDivision,
    setIsOpenTree,
    isDialog,
    isMulti,
    isDuplicate,
    isSelectUserWindow,
    userDivListFunction,
    setTargetDivisionTree,
    isNotSearch,
    isUserMaster,
    doubleSearch,
  } = props;
  const [searchObj, setSearchObj] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');

  const { isLoading } = useUserTreeContext();

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Accordion square defaultExpanded className={`${classes.root} ${isDialog && 'isTypeDialog'} ${'isShow'}`}>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container wrap="wrap" className={`${classes.innerGridContainer} ${isLoading ? baseClasses.loading : ''}`}>
          {treeHeaderSelect ? (
            <UserTreeSelectBox
              setList={setList}
              treeHeaderSelect={treeHeaderSelect}
            />
          ) : null}
          {isNotSearch ? null : (
            <UserTreeSearch
              headValue={headValue}
              getObjectSortFunction={getObjectSortFunction}
              list={list}
              noAffiliList={noAffiliList}
              searchObj={searchObj}
              setSearchObj={setSearchObj}
              setSearchKeyword={setSearchKeyword}
              showOnlyDivisions={showOnlyDivisions}
              isSelectUserWindow={isSelectUserWindow}
              isDialog={isDialog}
              doubleSearch={doubleSearch}
              isUserMaster={isUserMaster}
            />
          )}
          {headValue === 1 ? (
            <Grid className={classes.list}>
              <UserTreeList
                list={list}
                noAffiliList={noAffiliList}
                selectUser={selectUser}
                setSelectUser={setSelectUser}
                selectDivision={selectDivision}
                setSelectDivision={setSelectDivision}
                searchObj={searchObj}
                setSearchObj={setSearchObj}
                setIsOpenTree={setIsOpenTree}
                showOnlyDivisions={showOnlyDivisions}
                clickableUserAndDivision={clickableUserAndDivision}
                isSelectUserWindow={isSelectUserWindow}
                userDivListFunction={userDivListFunction}
                setTargetDivisionTree={setTargetDivisionTree}
                isDuplicate={isDuplicate}
                isUserMaster={isUserMaster}
                isMulti={isMulti}
              />
            </Grid>
          ) : (
            <UserSortNameTree
              list={list}
              setSelectUser={setSelectUser}
              searchKeyword={searchKeyword}
            />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default UserTreeIndex;
