import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [],
  total: 0,
};

export const getProductsSlice = createSlice({
  name: 'getProducts',
  initialState: { getProducts: initialState },
  reducers: {
    setGetProductsApi: (state, data) => {
      state.getProducts = data.payload;
    },
    addGetProductsApi: (state, { payload }) => {
      const { products: adding = [], total } = payload;
      state.getProducts.total = total;
      state.getProducts.products = [
        ...state.getProducts.products,
        ...adding,
      ];
    },
    initializeProducts: (state) => {
      state.getProducts = initialState;
    },
  },
});

export const {
  setGetProductsApi,
  addGetProductsApi,
  initializeProducts,
} = getProductsSlice.actions;

export default getProductsSlice.reducer;
