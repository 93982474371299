import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import TaiouRirekiSearchCheckBoxAll from './taiouRirekiSearchCheckBoxAll';
import TaiouRirekiSearchCheckBox from './taiouRirekiSearchCheckBox';
import { ACTION_CODE, ACTION_DETAIL_CODE } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 'auto 0 0 0',
        borderRadius: '12px 12px 0 0',
      },
    },
  },
  paper: {
    [commonTheme.breakpoints.up('md')]: {
      width: 212,
      background: '#fff',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    borderBottom: '1px solid #C8C8C8',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#fff',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 22px',
  },
  btnClear: {
    color: theme.palette.primaryColor,
  },
  content: {
    padding: '25px 19px',
    [commonTheme.breakpoints.down('sm')]: {
      flexGrow: 1,
      overflowY: 'auto',
      height: 440,
      display: 'block',
    },
  },
  all: {
    marginBottom: 12,
  },
  items: {
    marginLeft: 28,
  },
  item: {
    marginBottom: 12,
  },
  checkBox: {
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    '& .MuiCheckbox-root': {
      padding: '0',
      marginRight: 5,
    },
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: 0,
      marginTop: 2,
    },
    '& .Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const initialList = [
  {
    actionCode: 5,
    actionCodeLabel: ACTION_CODE[5],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[10], val: 10 },
      { label: ACTION_DETAIL_CODE[11], val: 11 },
      { label: ACTION_DETAIL_CODE[12], val: 12 },
      { label: ACTION_DETAIL_CODE[121], val: 121 },
      { label: ACTION_DETAIL_CODE[122], val: 122 },
      { label: ACTION_DETAIL_CODE[123], val: 123 },
      { label: ACTION_DETAIL_CODE[124], val: 124 },
    ],
  },
  {
    actionCode: 6,
    actionCodeLabel: ACTION_CODE[6],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[1], val: 1 },
      { label: ACTION_DETAIL_CODE[3], val: 3 },
      { label: ACTION_DETAIL_CODE[4], val: 4 },
      { label: ACTION_DETAIL_CODE[5], val: 5 },
      { label: ACTION_DETAIL_CODE[2], val: 2 },
      { label: ACTION_DETAIL_CODE[131], val: 131 },
      { label: ACTION_DETAIL_CODE[132], val: 132 },
      { label: ACTION_DETAIL_CODE[133], val: 133 },
    ],
  },
  {
    actionCode: 7,
    actionCodeLabel: ACTION_CODE[7],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[6], val: 6 },
      { label: ACTION_DETAIL_CODE[7], val: 7 },
      { label: ACTION_DETAIL_CODE[41], val: 41 },
      { label: ACTION_DETAIL_CODE[42], val: 42 },
      { label: ACTION_DETAIL_CODE[43], val: 43 },
      { label: ACTION_DETAIL_CODE[44], val: 44 },
      { label: ACTION_DETAIL_CODE[99], val: 99 },
    ],
  },
  {
    actionCode: 21,
    actionCodeLabel: ACTION_CODE[21],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[8], val: 8 },
    ],
  },
  {
    actionCode: 19,
    actionCodeLabel: ACTION_CODE[19],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[143], val: 143 },
      { label: ACTION_DETAIL_CODE[144], val: 144 },
    ],
  },
  {
    actionCode: 20,
    actionCodeLabel: ACTION_CODE[20],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[141], val: 141 },
      { label: ACTION_DETAIL_CODE[142], val: 142 },
    ],
  },
  {
    actionCode: 1,
    actionCodeLabel: ACTION_CODE[1],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[19], val: 19 },
      { label: ACTION_DETAIL_CODE[18], val: 18 },
    ],
  },
  {
    actionCode: 2,
    actionCodeLabel: ACTION_CODE[2],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[19], val: 19 },
      { label: ACTION_DETAIL_CODE[18], val: 18 },
    ],
  },
  {
    actionCode: 9,
    actionCodeLabel: ACTION_CODE[9],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[19], val: 19 },
      { label: ACTION_DETAIL_CODE[18], val: 18 },
    ],
  },
  {
    actionCode: 10,
    actionCodeLabel: ACTION_CODE[10],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[13], val: 13 },
    ],
  },
  {
    actionCode: 12,
    actionCodeLabel: ACTION_CODE[12],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[15], val: 15 },
      { label: ACTION_DETAIL_CODE[20], val: 20 },
      { label: ACTION_DETAIL_CODE[151], val: 151 },
    ],
  },
  {
    actionCode: 16,
    actionCodeLabel: ACTION_CODE[16],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[18], val: 18 },
    ],
  },
  {
    actionCode: 17,
    actionCodeLabel: ACTION_CODE[17],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[19], val: 19 },
      { label: ACTION_DETAIL_CODE[18], val: 18 },
      { label: ACTION_DETAIL_CODE[114], val: 114 },
    ],
  },
  {
    actionCode: 18,
    actionCodeLabel: ACTION_CODE[18],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[19], val: 19 },
    ],
  },
  {
    actionCode: 91,
    actionCodeLabel: ACTION_CODE[91],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[112], val: 112 },
      { label: ACTION_DETAIL_CODE[111], val: 111 },
      { label: ACTION_DETAIL_CODE[113], val: 113 },
    ],
  },
  {
    actionCode: 14,
    actionCodeLabel: ACTION_CODE[14],
    detailCodes: [
      { label: ACTION_DETAIL_CODE[16], val: 16 },
    ],
  },
];

export default function TaiouRirekiSearch(props) {
  const {
    open,
    onClose,
    search,
    setSearch,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [list, setList] = useState(JSON.parse(JSON.stringify(initialList)));
  const searchConditionsRef = useRef(search);

  const updateList = useCallback((newItem, index) => {
    const newList = [...list];
    newList[index] = newItem;
    setList(newList);
  }, [list]);

  const setSearchParam = () => {
    const actions = [];
    list.forEach((item) => {
      if (item.checked) {
        actions.push({
          actionCode: item.actionCode,
          detailCodes: item.detailCodes.filter((v) => v.checked).map((v) => v.val),
        });
      }
    });
    searchConditionsRef.current = {
      ...search,
      limit: 20,
      offset: 0,
      actions: actions.length ? actions : undefined,
    };
    setSearch(searchConditionsRef.current);
    onClose();
  };

  const clearSearchParam = () => {
    setList(JSON.parse(JSON.stringify(initialList)));
  };

  // 他の一覧で検索条件を変更した場合
  useEffect(() => {
    if (searchConditionsRef.current === search) return;
    const { actions } = search;
    if (!actions) {
      clearSearchParam();
    } else {
      const actionsData = {};
      actions.forEach((action) => {
        actionsData[action.actionCode] = action.detailCodes;
      });
      const newList = [...list];
      newList.forEach((item) => {
        if (actionsData[item.actionCode]) {
          item.checked = true;
          item.detailCodes.forEach((detail) => {
            detail.checked = actionsData[item.actionCode].includes(detail.val);
          });
        } else {
          item.checked = false;
          item.detailCodes.forEach((detail) => {
            detail.checked = false;
          });
        }
      });
      setList(newList);
    }
  }, [search]);

  const body = (
    <Grid className={classes.paper}>
      {isSp ? (
        <Grid
          id="draggable-dialog-title"
          className={`${baseClasses.title3} ${classes.header} ${baseClasses.drawerHeader}`}
        >
          種類/反応
        </Grid>
      ) : (
        <Grid
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          className={`${baseClasses.title3} ${classes.header}`}
        >
          <Button
            className={`${classes.btnClear} ${classes.btn}`}
            onClick={clearSearchParam}
          >
            クリア
          </Button>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.btn}`}
            onClick={setSearchParam}
          >
            選択
          </Button>
        </Grid>
      )}
      <Grid className={classes.content}>
        {list.map((item, i) => (
          <Grid key={`action${item.actionCode}`} className={classes.list}>
            <Grid className={`${classes.all} ${classes.checkBox}`}>
              <TaiouRirekiSearchCheckBoxAll
                item={item}
                onChange={(newItem) => {
                  updateList(newItem, i);
                }}
              />
            </Grid>
            <Grid className={classes.items}>
              {item.detailCodes.map((lable, j) => (
                <Grid key={`detail${lable.val}`} className={`${classes.item} ${classes.checkBox}`}>
                  <TaiouRirekiSearchCheckBox
                    item={lable}
                    onChange={(newChild) => {
                      const newItem = { ...item };
                      newItem.detailCodes[j] = newChild;
                      newItem.checked = newItem.detailCodes.some(v => v.checked);
                      updateList(newItem, i);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
      {isSp ? (
        <Grid
          className={`${baseClasses.title3} ${baseClasses.footer}`}
        >
          <Button
            className={`${classes.btnClear} ${classes.btn}`}
            onClick={clearSearchParam}
          >
            クリア
          </Button>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.btn}`}
            onClick={setSearchParam}
          >
            選択
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className={classes.dialog}
      >
        {body}
      </Dialog>
    </div>
  );
}
