export const MEMBERS_REDUCER_KEYS = {
  searchConditions: 'searchConditions',
  oneclickSearchConditions: 'oneclickSearchConditions',
  contentsHistories: 'contentsHistories',
  productsHistories: 'productsHistories',
  favorites: 'favorites',
  loan: 'loan',
  histories: 'histories',
  notifications: 'notifications',
  keywords: 'keywords',
  member: 'member',
  webs: 'webs',
  // モバイルで関連WEB会員を変更する際に使用される
  currentMemberId: 'currentMemberId',
};

// API名とレスポンス配列キー情報
export const MEMBERS_API_LIST_KEYS = {
  searchConditions: 'conditions',
  oneclickSearchConditions: 'conditions',
  contentsHistories: 'histories',
  productsHistories: 'histories',
  favorites: 'favorites',
  loan: 'loans',
  histories: 'histories',
  notifications: 'notifications',
  keywords: 'keywords',
};

/**
 * APIレスポンス初期化データ
 * @type {
 * Record<string, {
 * total: number;
 * [typeof MEMBERS_API_LIST_KEYS[keyof typeof MEMBERS_API_LIST_KEYS]]: [];
 * }>
 * }
 */
export const initialResponseDataState = Object.keys(MEMBERS_API_LIST_KEYS)
  .reduce((prev, key) => {
    prev[key] = {
      total: 0,
      [MEMBERS_API_LIST_KEYS[key]]: [],
    };
    return prev;
  }, {
    // 会員情報
    member: {},
    // 関連WEB会員情報
    webs: [],
    currentMemberId: null,
  });

export const reducer = (state, { type, payload = null }) => {
  if (MEMBERS_REDUCER_KEYS[type]) {
    // 該当typeの値が変更されてない場合には、
    // stateそのまま返す
    if (state[type] === payload) return state;
    return {
      ...state,
      [type]: payload,
    };
  } else {
    throw new Error(`${type}のキーが存在しません!`);
  }
};
