import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import libraryGetApi from '../../../apis/library/libraryGetApi';
// import { getUserListApi } from '../../../store/user/userListSlice';
import librarydirGetDirectlyTreeApi from '../../../apis/librarydir/librarydirGetDirectlyTreeApi';
import { getlibrarydirDirectlyTreeApi } from '../../../store/librarydir/librarydirGetDirectlyTreeSlice';
import libraryGetSpecifyDirctoryApi from '../../../apis/library/libraryGetSpecifyDirctoryApi';
import {
  insertLibraries,
  initializeLibraries,
} from '../../../store/library/libraryGetSpecifyDirctorySlice';
import LibraryTreeWrapper from '../../../components/common/libraryTree/libraryTree';
import { useLoading } from '../../../hooks';

import {
  LIBRARY_DIRECTORY_TREE,
  LIBRARY_DIRECTORY_TREE_LIBRRARY,
} from '../../../constants/loading';

const initialLimitOffset = { offset: 0, limit: 10 };
function LibraryTreeContainer(props) {
  const {
    setIsOpenTree, // isDialog = true時: 期待値=ダイアログ開閉のsetState
    selectDir, // 選んだディレクトリ: 期待値=Object
    setSelectDir, // selectDirのsetState
    selectLibrary, // 選んだライブラリ: 期待値=Object
    setSelectLibrary, // selectLibraryのsetState
    onClickOk,
    isPreview, // 選んだライブラリをプレビューする
  } = props;
  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  // 【library002】ライブラリ情報取得（ディレクトリ指定）用オプション
  const limitOffsetRef = useRef(initialLimitOffset);

  // tree GET librarydir001
  // グローバルステートに格納
  const getTreeListFunction = () => {
    librarydirGetDirectlyTreeApi()
      .then((res) => dispatch(getlibrarydirDirectlyTreeApi(res.data)))
      .catch((err) => console.error(err));
  };

  // GET /libraries/{libraryId} 【library001】ライブラリ情報取得
  const getLibraryFunction = (id) => {
    addLoading(LIBRARY_DIRECTORY_TREE);
    libraryGetApi(id)
      .then((res) => setSelectLibrary(res.data))
      .catch((err) => console.error(err))
      .finally(() => removeLoading(LIBRARY_DIRECTORY_TREE));
  };

  // GET /libraries/from-directories/{libraryDirectoryId} 【library002】ライブラリ情報取得（ディレクトリ指定）
  const libraryGetSpecifyDirctoryFunction = (libraryDirectoryId) => {
    if (libraryDirectoryId === undefined) return;
    addLoading(LIBRARY_DIRECTORY_TREE_LIBRRARY);
    libraryGetSpecifyDirctoryApi(libraryDirectoryId, limitOffsetRef.current)
      .then((res) => dispatch(insertLibraries(res.data)))
      .catch((err) => console.log(err))
      .finally(() => removeLoading(LIBRARY_DIRECTORY_TREE_LIBRRARY));
  };

  const mergeFunctions = {
    getLibraryFunction,
    libraryGetSpecifyDirctoryFunction,
    onClickOk,
  };

  useEffect(() => {
    limitOffsetRef.current = initialLimitOffset;
    dispatch(initializeLibraries());
  }, [selectDir]);

  // 初回表示
  useEffect(() => {
    getTreeListFunction();
    return () => {
      limitOffsetRef.current = initialLimitOffset;
      dispatch(initializeLibraries());
    };
  }, []);

  return (
    <LibraryTreeWrapper
      functions={mergeFunctions}
      selectDir={selectDir}
      setSelectDir={setSelectDir}
      selectLibrary={selectLibrary}
      onClose={() => setIsOpenTree(false)}
      limitOffsetRef={limitOffsetRef}
      isPreview={isPreview}
    />
  );
}

export default LibraryTreeContainer;
