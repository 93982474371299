import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 5px 0 0',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginRight: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
}));

function CheckBoxLabel(props) {
  const { label, initial, set } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState(initial);

  const handleChange = (value) => {
    setChecked(value);
  };

  useEffect(() => {
    setChecked(initial);
  }, [initial]);

  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => {
            handleChange(e.target.checked);
            set(e.target.checked);
          }}
        />
      }
      label={label}
    />
  );
}

export default CheckBoxLabel;
