import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initializeLibraries } from '../../store/library/libraryGetSpecifyDirctorySlice';

const LibraryContext = createContext({});
export const useLibraryContext = () => {
  return useContext(LibraryContext);
};
export function LibraryProvider(props) {
  const { children } = props;
  // 新規追加などの変更が起こったかどうかを管理するステート
  const [isDirAdd, setIsDirAdd] = useState(false);
  const [isParentUpdate, setIsParentUpdate] = useState(false);
  const [ownDir, setOwnDir] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // FIXME: ディレクトリ情報をうまく保持できていないためunmount時にリセット、仮対応
      dispatch(initializeLibraries());
    };
  }, []);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isDirAdd,
    setIsDirAdd,
    isParentUpdate,
    setIsParentUpdate,
    ownDir,
    setOwnDir,
  };
  return (
    <LibraryContext.Provider value={value}>
      {children}
    </LibraryContext.Provider>
  );
}
