import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import commonStyles from '../../styles';
import { DIRECT_ACTION } from '../../../constants/loading';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '150000!important',
    '& .MuiDialog-paper': {
      margin: 0,
      maxWidth: 'none',
      width: '100%',
      height: '100%',
      maxHeight: 'none',
      borderRadius: 0,
      // bottom: 58,
    },
  },
  paper: {
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderRadius: 0,
    padding: 0,
  },
  paperHeader: {
    borderBottom: '1px solid #C8C8C8',
    padding: '19px 0 8px',
  },
  paperTtl: {
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
  paperBody1: {
    padding: '40px 16px 0',
    '&.small': {
      padding: '24px',
    },
  },
  paperBody2: {
    padding: '16px 0 0 16px',
  },
  paperBtn: {
    display: 'block',
    margin: '32px auto 0',
  },
  pagerIcon: {
    fontSize: 16,
    zoom: 0.88,
    color: theme.palette.primaryColor,
  },
}));

// 【決定】ボタンを押したタイミングで独自の関数を実行したい場合、setに任意の関数を設定。
function CustomEditDialog(props) {
  const { type, size, open, setOpen,
    setHandleClose, // CANCEL・Closeボタンを押した場合に、画面単位で関数を実行したい場合に設定
    set, lbl, content,
    arrow, // 戻るボタンを表示させる場合に指定
    style,
  } = props;

  const { hasLoading } = useLoading();

  const classes = useStyles();
  const baseClasses = commonStyles();

  // 文字列の改行
  const brLbl = lbl.split(/(\n)/).map((item) => {
    return (
      <React.Fragment key={item}>
        { item.match(/\n/) ? <br /> : item }
      </React.Fragment>
    );
  });

  const getButton = () => {
    switch (type) {
      case 'button2':
        return (
          <Grid container justifyContent="center" className={baseClasses.fixedFooter}>
            <Button
              className={
                `${baseClasses.buttonsSecondary} ${classes.fixedFooterBtn}`
              }
              onClick={() => {
                setOpen(!open);
                if (setHandleClose !== undefined) {
                  setHandleClose();
                }
              }}
            >
              CANCEL
            </Button>
            <Button
              className={
                `${baseClasses.buttonsPrimary} ${classes.fixedFooterBtn}`
              }
              onClick={() => {
                set !== undefined ? set() : null;
                setOpen(!open);
              }}
              disabled={hasLoading(DIRECT_ACTION)}
            >
              OK
            </Button>
          </Grid>
        );
      case 'buttonNone':
        return (
          <Grid />
        );
      default:
        return (
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.paperBtn}`}
            onClick={() => {
              setOpen(!open);
              set !== undefined ? set() : null;
            }}
            disabled={hasLoading(DIRECT_ACTION)}
          >
            決定
          </Button>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(!open);
      }}
      className={`${classes.dialog} ${style !== undefined ? style : ''}`}
    >
      <Grid className={classes.paper}>
        <Grid className={classes.paperHeader}>
          <Typography
            className={`${baseClasses.title2} ${classes.paperTtl}`}
          >
            {brLbl}
          </Typography>
          {arrow !== undefined ? (
            <Button
              className={baseClasses.arrowButton}
              onClick={() => {
                setOpen(!open);
                if (setHandleClose !== undefined) {
                  setHandleClose();
                }
              }}
            >
              <ArrowBackIosIcon className={classes.pagerIcon} />
            </Button>
          ) : (
            <Button
              className={baseClasses.closeButton}
              onClick={() => {
                setOpen(!open);
                if (setHandleClose !== undefined) {
                  setHandleClose();
                }
              }}
            />
          )}
        </Grid>
        <Grid className={`${classes.paperBody1} ${size !== undefined ? size : ''}`}>
          {content}
        </Grid>
        {/* ボタンが2つ存在する場合、propsでtype="button2"と設定 */}
        {/* ボタンの表示が不要の場合、propsでtype="buttonNone"と設定 */}
        {getButton()}
      </Grid>
    </Dialog>
  );
}

export default CustomEditDialog;
