import { createSlice } from '@reduxjs/toolkit';

export const initialResponseTreeState = [];

export const divisionsGetResponseTreeSlice = createSlice({
  name: 'divisionsGetResponseTree',
  initialState: { divisionsGetResponseTree: initialResponseTreeState },
  reducers: {
    getDivisionsResponseTreeApi: (state, data) => {
      state.divisionsGetResponseTree = data.payload;
    },
  },
});

export const { getDivisionsResponseTreeApi } = divisionsGetResponseTreeSlice.actions;

export default divisionsGetResponseTreeSlice.reducer;
