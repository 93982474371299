import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const autoRegistResponseControlUpdateSlice = createSlice({
  name: 'autoRegistResponseControlUpdate',
  initialState: { autoRegistResponseControlUpdate: initialState },
  reducers: {
    autoRegistResponseControlUpdateApi: (state, data) => {
      state.autoRegistResponseControlUpdate = data.payload;
    },
  },
});

export const { autoRegistResponseControlUpdateApi } = autoRegistResponseControlUpdateSlice.actions;

export default autoRegistResponseControlUpdateSlice.reducer;
