/**
 * 数字か、または数字ストリングか
 * @param {*} input
 * @returns {boolean}
 */
export const isNumberLike = (input) => {
  if (typeof input === 'number') return true;
  return typeof input === 'string' && /^-?\d+(\.\d+)?$/.test(input);
};

/**
 * Objectか {}/Map/Set
 * @param {*} o
 * @returns {boolean}
 */
export const isObject = (o) => {
  return !!o && typeof o === 'object' && !Array.isArray(o);
};

/**
 * isArray
 * @param {*} arr
 * @returns {boolean}
 */
export const isArray = (arr) => {
  return Array.isArray(arr);
};

/**
 * 同じオブジェクトか
 * @param {object} obj1
 * @param {object} obj2
 * @returns {boolean}
 */
export const isEqualObject = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (isObject(obj1) && isObject(obj2) && Object.keys(obj1).length === Object.keys(obj2).length) {
    const keys = Object.keys(obj1);
    let key;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= keys.length; i++) {
      key = keys[i];
      if (isObject(obj1[key])) {
        if (!isEqualObject(obj1[key], obj2[key])) {
          return false;
        }
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }
  return false;
};

/**
 * undefined, null, '', []を判定する関数(一致する場合falseを返却)
 * @param {object} input
 * @returns {boolean}
 */
export const isNone = (input) => {
  if (!input) return true;
  if (Array.isArray(input) && input.length === 0) return true;
  return false;
};

/**
 * 正しい電話番号の入力形式かを判定する関数(正しい場合falseを返却)
 * @param {string} value
 * @returns {boolean}
 */
export const isNotPhoneNumber = (value) => {
  if (value) {
    const regex = /^[0-9-]+$/;
    if (!regex.test(value)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
