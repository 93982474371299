import React, { useMemo } from 'react';
import TaiouRirekiContainers from '../../../containers/common/taiouRireki/taiouRirekiContainers';

function TaiouRireki(props) {
  const {
    setOpenAlert,
    customerId,
    customer,
    getActionHistoryList,
    createHistory,
    updateHistory,
    deleteHistory,
    downloadAttachment,
    search,
    setSearch,
    actionHistory,
    customerMail,
    refreshing,
    onRefresh,
    rerenderKeySearch,
    rerenderSearch,
  } = props;
  return useMemo(() => {
    if (actionHistory) {
      return (
        <TaiouRirekiContainers
          setOpenAlert={setOpenAlert}
          customerId={customerId}
          customer={customer}
          getActionHistoryList={getActionHistoryList}
          createHistory={createHistory}
          updateHistory={updateHistory}
          deleteHistory={deleteHistory}
          downloadAttachment={downloadAttachment}
          search={search}
          setSearch={setSearch}
          actionHistory={actionHistory}
          customerMail={customerMail}
          refreshing={refreshing}
          onRefresh={onRefresh}
          rerenderKeySearch={rerenderKeySearch}
          rerenderSearch={rerenderSearch}
        />
      );
    }
    return (
      <TaiouRirekiContainers
        setOpenAlert={setOpenAlert}
        customerId={customerId}
        customer={customer}
        getActionHistoryList={getActionHistoryList}
        createHistory={createHistory}
        updateHistory={updateHistory}
        deleteHistory={deleteHistory}
        downloadAttachment={downloadAttachment}
        search={search}
        setSearch={setSearch}
        actionHistory={{ total: 0, histories: [] }}
        customerMail={customerMail}
        refreshing={refreshing}
        onRefresh={onRefresh}
        rerenderKeySearch={rerenderKeySearch}
        rerenderSearch={rerenderSearch}
      />
    );
  }, [actionHistory]);
}

export default React.memo(TaiouRireki);
