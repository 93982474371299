import { createSlice } from '@reduxjs/toolkit';
import { getNowDate } from '../../commonFunction';

const END_DATETIME = '2099/12/31 23:59';
// 組織登録の形
const initial = {
  divisionGroupName: '',
  divisionSubName: '',
  areaCode: '',
  isHide: 0,
  levelCode: '',
  parentId: '',
  displayOrder: null,
  phoneNumber: '',
  fax: '',
  mailingListAddress: '',
  isSalesDivision: 1,
  isDisplaySalesResponseTree: 0,
  zip: '',
  address: '',
  building: '',
  latitude: null,
  longitude: null,
  divisionTypeCode: 5,
  integrationAreaCode: '',
  responseAutomationStartAt: '',
  responseAutomationEndAt: '',
  phoneNumberAreaCode: '',
  startAt: getNowDate('YYYY/MM/DD'),
  endAt: END_DATETIME,
  googleChatWebhookUrl: '',
};

export const divisionsGetDetailSlice = createSlice({
  name: 'divisionsGetDetail',
  initialState: { divisionDetail: initial },
  reducers: {
    // Update
    setDivisions: (state, data) => {
      state.divisionDetail = data.payload;
    },
    // Stateの新規追加
    resetInitialDivision: (state) => {
      state.divisionDetail = initial;
    },
    setParentId: (state, data) => {
      state.divisionDetail.parentId = data.payload;
    },
    setLevelCode: (state, data) => {
      state.divisionDetail.levelCode = data.payload;
    },
  },
});

export const {
  setDivisions,
  resetInitialDivision,
  setParentId,
  setLevelCode,
} = divisionsGetDetailSlice.actions;

export default divisionsGetDetailSlice.reducer;
