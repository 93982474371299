import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import DoNotChaseReasonRadio from './parts/doNotChaseReasonRadio';
import DoNotChaseReasonText from './parts/doNotChaseReasonText';
import DoNotChaseReasonTextSp from './parts/sp/doNotChaseReasonTextSp';
import DoNotChaseReasonCalendar from './parts/doNotChaseReasonCalendar';
import DoNotChaseReasonCalendarSp from './parts/sp/doNotChaseReasonCalendarSp';
import DoNotChaseReasonButton from './parts/sp/doNotChaseReasonButton';
import CommonDialog from '../../common/modal';

const useStyles = makeStyles({
  txt: {
    padding: 16,
    lineHeight: 1.6,
  },
  item: {
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 8,
    },
    [commonTheme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      background: '#fff',
    },
  },
});

export default function DoNotChaseReasonWrapper(props) {
  const {
    open,
    onClose,
    setStatus,
    userData,
    setUserData,
    // userData = {}, // 顧客情報のローカルステート※つないだらイコール以降を消してください
    // setUserData = () => { console.log('set'); }, // 顧客情報のsetState※つないだらイコール以降を消してください
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const initial = {
    noContactDetailReasonCode: 0,
    searchArea: '',
    reasonNotOhd: '',
    searchStartAt: '',
    reasonNotSearch: '',
    reasonNotBuy: '',
  };

  // const [userData, setReason] = useState(initial);

  // ラジオボタン
  const [value, setValue] = useState('');

  // エリアの表示制御
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  // ボタンの必須制御
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // エリアの表示制御
    switch (Number(value)) {
      case 4:
        setActive1(false);
        setActive2(false);
        setActive3(true);
        break;
      case 6:
        setActive1(false);
        setActive2(true);
        setActive3(false);
        break;
      case 7:
        setActive1(true);
        setActive2(false);
        setActive3(false);
        break;
      default:
        setActive1(false);
        setActive2(false);
        setActive3(false);
        break;
    }
    // ボタンの必須制御
    if (value === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  // ステートを更新
  const set = (key, val) => {
    setUserData({ ...userData, [key]: val });
  };

  // 登録
  const handleRegister = () => {
    console.log('登録');
    onClose();
    setStatus(userData);
    // 初期化
    setUserData({ ...userData, ...initial });
    setValue('');
  };

  // キャンセル
  const handleCancel = () => {
    onClose();
    // 初期化
    setUserData({ ...userData, ...initial });
    setValue('');
  };

  // 背景
  const onCloseBg = () => {
    onClose();
    // 初期化
    setUserData({ ...userData, ...initial });
    setValue('');
  };

  const box = [
    {
      type: 'text',
      key: 'searchArea',
      input: {
        label: '要望がエリア外の場合、\nどこか？',
        active: active1,
        state: userData.searchArea,
        setState: (e) => { return set('searchArea', e); },
      },
    },
    {
      type: 'text',
      key: 'reasonNotOhd',
      input: {
        label: '他のはまらない理由',
        active: active1,
        state: userData.reasonNotOhd,
        setState: (e) => { return set('reasonNotOhd', e); },
      },
    },
    {
      type: 'calendar',
      key: 'searchStartAt',
      input: {
        label: '再開時期\n（いつ頃探し始めそうですか？）',
        active: active2,
        state: userData.searchStartAt,
        setState: (e) => { return set('searchStartAt', e); },
      },
    },
    {
      type: 'text',
      key: 'reasonNotSearch',
      input: {
        label: '今探していない理由',
        active: active2,
        state: userData.reasonNotSearch,
        setState: (e) => { return set('reasonNotSearch', e); },
      },
    },
    {
      type: 'text',
      key: 'reasonNotBuy',
      input: {
        label: '購入できない理由',
        active: active3,
        state: userData.reasonNotBuy,
        setState: (e) => { return set('reasonNotBuy', e); },
      },
    },
  ];

  const switchType = (val) => {
    const returnType = [];
    switch (val.type) {
      case 'text':
        return (
          isSp ? (
            <DoNotChaseReasonTextSp values={val.input} />
          ) : (
            <DoNotChaseReasonText values={val.input} />
          )
        );
      case 'calendar':
        return (
          isSp ? (
            <DoNotChaseReasonCalendarSp values={val.input} />
          ) : (
            <DoNotChaseReasonCalendar values={val.input} />
          )
        );
      default:
        break;
    }
    return returnType;
  };

  const body = (
    <>
      {isSp ? (
        <>
          <DoNotChaseReasonButton
            setValue={setValue}
            setState={(e) => { return set('noContactDetailReasonCode', Number(e)); }}
          />
          <Typography className={`${baseClasses.title6} ${classes.txt}`}>
            ※ 連絡先（電話・メール）が異なる場合は「連絡不可・カマシ」を選択してください。
          </Typography>
        </>
      ) : (
        <DoNotChaseReasonRadio
          value={value}
          setValue={setValue}
          setState={(e) => { return set('noContactDetailReasonCode', Number(e)); }}
        />
      )}
      {box.map((item) => (
        <Grid key={item.key} className={classes.item}>
          {switchType(item)}
        </Grid>
      ))}
    </>
  );

  return (
    <CommonDialog
      open={open}
      onClose={onCloseBg}
      title={isSp ? '追わない理由' : '追わない理由ウィンドウ'}
      width={640}
      buttons={[
        {
          label: 'キャンセル',
          onClick: handleCancel,
          className: baseClasses.buttonsSecondary,
        },
        {
          label: '登録',
          onClick: handleRegister,
          disabled,
        },
      ]}
    >
      {body}
    </CommonDialog>
  );
}
