import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  ListItem,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Icon from '@material-ui/core/Icon';

import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

import commonStyles from '../../styles';
import ManagementTalkRoom from './managementTalkRoom';

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #C8C8C8',
    transition: 'all 0.5s ease important',
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
      '& .buttonContainer': {
        opacity: 1,
      },
    },
  },
  linkTxt: {
    color: theme.palette.primaryColor,
    fontWeight: 700,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    marginLeft: '5px',
    opacity: 0,
  },
  buttonTxt: {
    color: theme.palette.primaryColor,
  },
  detailButton: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  deleteButton: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  detailButtonIcon: {
    color: theme.palette.primaryColor,
    fontSize: '20px',
  },
  deleteIcon: {
    color: theme.palette.primaryColor,
    width: '16px',
    height: '20px',
  },
  cancelButton: {
    color: theme.palette.primaryColor,
  },
}));

const LINE_WORKS_URL = 'https://line.worksmobile.com/message/send?';

function DetailTalkRoomItems(props) {
  const {
    element,
    functions,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const updateLineTalkRoom = (param) => {
    setOpen(false);
    // /lineRoom/updateAPIを呼び出す
    functions.updateLineRoome(element.lineRoomLinkId, param);
  };

  const deleteRoom = () => functions.deleteLineRooms(element.lineRoomLinkId);

  const onClickDelete = () => {
    dispatch(changeConfirmMessage({
      msgList: ['本当に削除してよろしいでしょうか？'], // 必須
      title: '', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'CANCEL', // ボタンラベル
        set: '', // ボタンをクリックしたときの処理
        classes: classes.cancelButton, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }, {
        label: 'OK', // ボタンラベル
        set: deleteRoom, // ボタンをクリックしたときの処理
        classes: baseClasses.buttonsPrimary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }],
    }));
  };
  const lineID = Array.isArray(element.customerLineIds) && element.customerLineIds.length === 1
    ? element.customerLineIds[0]
    : '';

  return (
    <>
      <ListItem className={`${baseClasses.small} ${classes.listItemContainer}`} key={element.lineRoomLinkId}>
        <Grid style={{ marginRight: 10 }} className={baseClasses.small}>
          <Link
            href={
              element.isUseResourceId
                ? `${LINE_WORKS_URL}version=18&message=&worksAtResourceIdList=${lineID}&callbackMainYn=N`
                : `${LINE_WORKS_URL}version=26&message=&channelId=${lineID}`
            }
            className={classes.linkTxt}
            target="_blank"
            rel="noopener"
          >
            {element.lineRoomName}
          </Link>
        </Grid>
        <Grid className={`${classes.buttonContainer} buttonContainer`}>
          <Button
            onClick={() => setOpen(true)}
            className={classes.detailButton}
          >
            <Icon className={classes.detailButtonIcon}>drive_file_rename_outline</Icon>
            <Typography className={`${baseClasses.smallStrong} ${classes.buttonTxt}`}>
              詳細
            </Typography>
          </Button>
          <Button className={classes.deleteButton} onClick={onClickDelete}>
            <DeleteOutlineIcon className={classes.deleteIcon} />
            <Typography
              className={`${baseClasses.smallStrong} ${classes.buttonTxt}`}
            >
              削除
            </Typography>
          </Button>
        </Grid>
      </ListItem>
      <ManagementTalkRoom
        open={open}
        onClose={() => setOpen(false)}
        line={element}
        onRegist={updateLineTalkRoom}
      />
    </>
  );
}

export default DetailTalkRoomItems;
