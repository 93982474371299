import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import UserTree from '../../userTreeNew';
import inputNumber from '../../../../commonFunction/inputNumber';
import infoRegisterStyles from './infoRegisterStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #C8C8C8',
      background: '#fff',
      padding: '8px 8px 7px',
    },
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  box: {
    width: 730,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 0,
    width: 'auto',
    flexGrow: 1,
    textAlign: 'left',
    minHeight: 20,
    borderRadius: 0,
    '& .MuiButton-label': {
      display: 'block',
    },
  },
  input: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 40,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
      minHeight: 20,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    whiteSpace: 'nowrap',
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
    '& span': {
      margin: '0 16px',
    },
  },
}));

function InfoRegisterChoiceText1(props) {
  const { values } = props;
  const classes = useStyles();
  const infoRegisterClasses = infoRegisterStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  const [manageWindowOpener, setManageWindowOpener] = useState(false);

  const initialUser = { userId: 0, userName: '' };
  const initialDivision = { divId: 0, divisionName: '' };
  // 組織ツリーユーザー選択
  const [selectUser, setSelectUser] = useState(initialUser);
  // 組織ツリー選択
  const [selectDivision, setSelectDivision] = useState(initialDivision);

  const onClickHandler = () => {
    setManageWindowOpener(!manageWindowOpener);
  };

  const [text, setText] = useState(values.state2);
  const handleChange = (e) => {
    setText(e.target.value);
    // PCで文字を入力すう度に再レンダリングされるのでコメントアウト
    // values.setState2(e.target.value);
  };

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  const handleSelectFunction = () => {
    if (selectUser.userId !== 0) {
      values.setState1(Object.assign({}, selectUser, selectDivision));
    } else {
      values.setState1(Object.assign({}, values.selectUser, selectDivision));
    }
  };
  const handleClearFunction = () => {
    values.setState1(Object.assign({}, initialUser, initialDivision));
  };

  // useEffect(() => {
  //   if (!manageWindowOpener && selectUser.userName) {
  //     values.setState1(Object.assign({}, selectUser, selectDivision));
  //   }
  // }, [manageWindowOpener, selectUser]);

  if (isSp) {
    contents = (
      <Grid className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}>
        <List className={infoRegisterClasses.list}>
          <ListItem className={classes.listItem}>
            <Button
              onClick={onClickHandler}
              className={classes.listBtn}
            >
              <Typography className={infoRegisterClasses.listTtl}>
                {values.label}
                {values.required && <span>＊</span>}
              </Typography>
              <Typography className={classes.listTxt}>
                {values.state1 || ''}
              </Typography>
            </Button>
          </ListItem>
          <ListItem className={infoRegisterClasses.listItem}>
            <Typography className={infoRegisterClasses.listTtl}>
              対応時間
            </Typography>
            <TextField
              className={infoRegisterClasses.input}
              onChange={handleChange}
              onBlur={(e) => { return values.setState2(e.target.value); }}
              value={text}
              type="number"
              placeholder="-"
            />
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <Typography className={classes.ttl}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <Grid className={classes.box}>
          <Button
            onClick={onClickHandler}
            className={classes.btn}
          >
            {values.state1 || ''}
          </Button>
          <TextField
            className={classes.input}
            onChange={handleChange}
            onBlur={(e) => { return values.setState2(e.target.value); }}
            value={text}
            defaultValue={0}
            type="number"
            inputProps={{ min: '0' }}
          />
          <Typography className={classes.txt}>
            分間対応
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <UserTree
        isOpenTree={manageWindowOpener}
        setIsOpenTree={setManageWindowOpener}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        selectUser={selectUser.userName !== '' ? selectUser : values.selectUser}
        setSelectUser={setSelectUser}
        handleSelectFunction={handleSelectFunction}
        handleClearFunction={handleClearFunction}
        isDialog
        isSelectUserWindow
      />
    </Grid>
  );
}

export default InfoRegisterChoiceText1;
