import { makeStyles } from '@material-ui/core';

const gensenStyle = makeStyles((theme) => ({
  blockWrap: {
    background: '#fff',
    alignItems: 'center',
  },
  icon: { fontSize: 20 },
  addBtn: {
    display: 'flex',
    margin: '8px 8px 8px auto',
    '&:disabled > span': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  display: {
    position: 'relative',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '8px 16px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '&:not(:last-child)': { borderBottom: '1px #F3F3F3 solid' },
  },
  ttl: {
    width: '25%',
    textAlign: 'left',
    '& span': { color: '#D83420' },
  },
  ttl2: {
    width: '30%',
    textAlign: 'left',
    '& span': { color: '#D83420' },
  },
  ttl3: {
    width: '27%',
    textAlign: 'left',
    '& span': { color: '#D83420' },
  },
  inputArea: {
    width: '75%',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputArea2: {
    width: '70%',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputArea3: {
    width: '75%',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    height: 48,
  },
  title: {
    textAlign: 'center',
  },
  itemTitle: {
    width: '25%',
  },
  closeIcon: {
    color: theme.palette.primaryColor,
    position: 'absolute',
    right: 10,
    top: 13,
  },
  txtfield: {
    '& > .MuiInput-underline': {
      '&::before': { display: 'none' },
      '&::after': { display: 'none' },
      '& > .MuiInputBase-input': {
        textAlign: 'right',
        '&::placeholder': {
          opacity: 1,
        },
      },
    },
  },
  readOnlyTxtField: {
    '& > .MuiInput-underline': {
      '& > .MuiInputBase-input': {
        fontSize: 14,
        fontWeight: 600,
      },
    },
  },
  button: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    padding: '16px 16px 14px',
  },
  draggableDialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      padding: 16,
    },
  },
}));

export default gensenStyle;
