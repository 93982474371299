import { useSelector } from 'react-redux';
import { Grid, Drawer, Typography } from '@material-ui/core';
import SmsContainers from '../../containers/sms/smsContainers';
import commonStyles from '../styles';
import CommonDialog from '../common/modal';

export default function SmsWindow(props) {
  const { open, onClose, customer } = props;
  const common = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  if (isSp) {
    return (
      <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        className={common.drawer}
      >
        <Grid
          className={`${common.header} ${common.drawerHeaderSp} ${common.onlySp}`}
        >
          <Typography className={common.title3}>SMS送信先選択</Typography>
        </Grid>
        <SmsContainers customer={customer} />
      </Drawer>
    );
  }
  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="SMS送信先選択"
      width={700}
    >
      <SmsContainers customer={customer} />
    </CommonDialog>
  );
}
