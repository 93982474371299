import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../../styles';

const useStyles = makeStyles({
  ttl: {
    marginRight: '0.8rem',
  },
  reqired: {
    color: '#D83420',
    marginRight: 'auto',
  },
});

function CalendarLabel(props) {
  const val = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    <Typography className={`${common.title6} ${classes.ttl} ${val.className || null}`}>
      {val.txt}
      {val.require
        && <span className={classes.reqired}>＊</span>}
    </Typography>
  );
}

export default CalendarLabel;
