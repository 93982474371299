export const getEnvVariable = (variableName, defaultVal) => {
  const envVariableName = /REACT_APP_/.test(variableName)
    ? variableName
    : `REACT_APP_${variableName}`;
  return process.env[envVariableName] || defaultVal;
};

export default function getEnv() {
  const envName = getEnvVariable('OMIT_ENV_NAME');
  switch (envName) {
    case 'local': return 'LOCAL';
    case 'dev': return 'DEVELOP';
    case 'stg': return 'STAGING';
    case 'stg2': return 'TRAINING';
    default: return 'PRODUCTION';
  }
}

export function getOrigin() {
  const url = new URL(window.location.href);
  return url.origin;
}
