import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import customerUpdateCallApi from '../../apis/customer/customerUpdateApi';
import libraryGetInterruptedApi from '../../apis/library/libraryGetInterruptedApi';
import postSendMails from '../../apis/sendmail/sendMailsPostApi';
import sendmailTestApi from '../../apis/sendmail/sendmailTestApi';
import smsPostApi from '../../apis/sms/postSmsApi';
import AfterCallWindowComponent from '../../components/mail/afterCallWindowComponent';
import store from '../../store';
import { setLastUpdateSignature } from '../../store/sendmail/sendmailLastUpdateSignatureSlice';
import { isOpen } from '../../store/common/apiMessageSlice';
import { getFromChoice } from '../../commonFunction/mail';
import smsHistoryApi from '../../apis/sms/smsHistoryApi';
import { getSmsHistoryApi } from '../../store/sms/smsHistorySlice';
import librarydirGetDirectlyTreeApi from '../../apis/librarydir/librarydirGetDirectlyTreeApi';
import { librarydirOwnApi } from '../../store/librarydir/librarydirOwnSlice';
import libraryGetSignitureApi from '../../apis/library/libraryGetSignitureApi';
import { libraryGetSigniture } from '../../store/library/libraryGetSignitureSlice';
import { MAIL_FROM_SELF } from '../../constants';
import sendmailGetDefaultApi from '../../apis/sendmail/sendmailGetDefaultApi';

export default function AfterCallWindowContainer(props) {
  const {
    open,
    user,
    handleClose,
    successSendCallBack,
  } = props;

  const { responseHeader } = store.getState();
  const dispatch = useDispatch();
  // 最後に選ばれた署名を選択する / https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3790#comment-172404437
  const lastUpdateSignature = useSelector(
    (state) => state.sendmailLastUpdateSignature.lastUpdateSignature,
  );
  // 署名一覧を格納
  const signature = useSelector(
    (state) => state.libraryGetSigniture.libraryGetSigniture,
  );
  // info/sign/libraryはAPIで取得し、オブジェクト化するためこちらに記載
  const [mailParam, setMailParam] = useState({});
  const [mailSetting, setMailSetting] = useState({
    type: {
      constants: {
        menus: {
          0: 'SMS',
          1: 'メール',
        },
      },
    },
    kind: {
      // eslint-disable-next-line max-len
      // ここの文言は/libraries/management/{libraryId}のlibraryNameと対応する（本番ではlibraryNameから引用するが、WIPの状態なので定数参照）
      constants: {
        menus: {},
      },
    },
    kindSms: {
      // eslint-disable-next-line max-len
      // ここの文言は/libraries/management/{libraryId}のlibraryNameと対応する（本番ではlibraryNameから引用するが、WIPの状態なので定数参照）
      constants: {
        menus: {},
      },
    },
    from: {
      constants: {
        // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4066#comment-183540960
        menus: getFromChoice(responseHeader.roleIds),
        title: 'From',
      },
    },
    sign: {
      constants: {
        menus: {
          0: '署名１',
          1: '署名２',
        },
        title: 'sign',
      },
    },
    info: {
      constants: {
        menus: {
          1: '@INFO',
          2: 'オープンハウス事務局',
        },
        title: 'info',
      },
    },
  });
  const [mailMeta, setMailMeta] = useState({});
  const [smsMeta, setSmsMeta] = useState({});
  const [signMeta, setSignMeta] = useState({});
  const getDefaultTo = () => {
    let result = '';
    if (user.isMailSend1) {
      result += user.mail1;
    }
    if (user.isMailSend2) {
      if (result !== '') { result += ', '; }
      result += user.mail2;
    }
    if (user.isMailSend3) {
      if (result !== '') { result += ', '; }
      result += user.mail3;
    }
    if (user.isMailSend4) {
      if (result !== '') { result += ', '; }
      result += user.mail4;
    }
    return result;
  };
  const [meta, setMeta] = useState({
    type: 0,
    from: 1,
    info: 0,
    kind: 0,
    kindSms: 0,
    sign: 0,
    title: '',
    to: getDefaultTo(),
    tocheck: {
      mail1: user.isMailSend1 === 1,
      mail2: user.isMailSend2 === 1,
      mail3: user.isMailSend3 === 1,
      mail4: user.isMailSend4 === 1,
    },
    sms: '',
    smsId: '',
    content: '',
  });
  // APIでとってきたライブラリをもっておく
  const [mailLibrary, setMailLibrary] = useState({});
  const [smsLibrary, setSmsLibrary] = useState({});
  const [signLibrary, setSignLibrary] = useState({});
  const [libraries, setLibraries] = useState({
    mail: mailLibrary,
    sms: smsLibrary,
    sign: signLibrary,
  });
  const ownDir = useSelector((state) => state.librarydirOwn.librarydirOwn);

  useEffect(() => {
    setMeta({
      ...meta,
      kind: Object.keys(mailLibrary)[0],
      kindSms: Object.keys(smsLibrary)[0],
      content: smsLibrary[Object.keys(smsLibrary)[0]] !== undefined ? smsLibrary[Object.keys(smsLibrary)[0]].mailBody : '',
    });
    setLibraries({
      mail: mailLibrary,
      sms: smsLibrary,
      sign: signLibrary,
    });
  }, [mailLibrary, smsLibrary, signLibrary]);

  useEffect(() => {
    setMailSetting({
      ...mailSetting,
      kind: { constants: { menus: mailMeta } },
      kindSms: { constants: { menus: smsMeta } },
      sign: { constants: { menus: signMeta }, title: 'sign' },
    });
  }, [mailMeta, smsMeta, signMeta]);

  const [editorState, setEditorState] = useState('');

  const setting = {
    type: {
      label: '送信種別',
      state: meta.type,
      setState: (e) => {
        const id = Number(e.target.value);
        if (id === 0) {
          // SMSの場合
          if (Object.keys(libraries.sms).length) {
            // ライブラリが存在する場合
            setMeta({
              ...meta,
              type: id,
              content: libraries.sms[meta.kindSms].mailBody,
            });
          } else {
            setMeta({
              ...meta,
              type: id,
            });
          }
        } else if (id === 1) {
          // メールの場合
          if (Object.keys(libraries.mail).length) {
            // ライブラリが存在する場合
            setMeta({
              ...meta,
              type: id,
              title: libraries.mail[meta.kind].mailSubject,
              content: libraries.mail[meta.kind].mailBody,
            });
            setEditorState(libraries.mail[meta.kind].mailBody);
          } else {
            setMeta({
              ...meta,
              type: id,
            });
          }
        }
      },
      constants: mailSetting.type.constants,
      style: { width: '24.5%' },
    },
    kind: {
      label: '種類',
      state: meta.kind,
      setState: (e) => {
        setMailParam({ ...mailParam, libraryId: Number(e) });
        return setMeta({ ...meta, kind: Number(e) });
      },
      constants: mailSetting.kind.constants,
      style: { width: '50%' },
    },
    kindSms: {
      label: '種類',
      state: meta.kindSms,
      setState: (e) => { return setMeta({ ...meta, kindSms: Number(e) }); },
      constants: mailSetting.kindSms.constants,
      style: { width: '74.5%' },
    },
    from: {
      label: 'From',
      state: meta.from,
      setState: (e) => { return setMeta({ ...meta, from: Number(e.target.value) }); },
      constants: mailSetting.from.constants,
      style: { width: '24.5%' },
    },
    info: {
      label: 'info',
      state: meta.info,
      setState: (e) => {
        setMailParam({ ...mailParam, infoCode: Number(e.target.value) });
        return setMeta({ ...meta, info: Number(e.target.value) });
      },
      constants: mailSetting.info.constants,
      style: { width: '24.5%' },
    },
    sign: {
      // 署名は画面上では表示されないが、裏側で処理する
      label: '署名',
      state: meta.sign,
      setState: (e) => {
        dispatch(setLastUpdateSignature(Number(e.target.value)));
        setMailParam({ ...mailParam,
          signatureLibraryId: Number(e.target.value),
          signature: Object.values(signLibrary).find(s => {
            return s.libraryId === Number(e.target.value);
          })?.mailBody,
        });
        return setMeta({ ...meta, sign: Number(e.target.value) });
      },
      constants: mailSetting.sign.constants,
      style: { width: '24.5%' },
    },
    to: {
      label: 'To',
      require: true,
      style: { width: '74.5%' },
      check: meta.tocheck,
      setCheck: (e) => { return setMeta({ ...meta, toCheck: e }); },
      list: meta.to,
      setList: (e) => { return setMeta({ ...meta, to: e }); },
    },
    tosms: {
      label: 'To',
      require: true,
      style: { width: '100%' },
      check: meta.smscheck,
      setCheck: (e) => { return setMeta({ ...meta, smsCheck: e }); },
      number: meta.sms,
      id: meta.smsId,
      setList: (e, h) => { return setMeta({ ...meta, sms: e, smsId: h }); },
    },
    title: {
      label: '件名',
      state: meta.title,
      setState: (e) => { return setMeta({ ...meta, title: e }); },
      style: { width: '100%' },
      require: true,
      maxLengthInt: 250,
    },
    content: {
      state: meta.content,
      setState: (e) => { return setMeta({ ...meta, content: e }); },
      wrapper: 'small',
      toolbar: true,
    },
    contentSms: {
      state: meta.content,
      setState: (e) => { return setMeta({ ...meta, content: e }); },
      wrapper: 'full',
      toolbar: false,
    },
  };

  // 署名を選択
  const selectUpdateSignature = (signList, libId = 0) => {
    if (!lastUpdateSignature
      || !Object.keys(signList).map(id => Number(id)).includes(lastUpdateSignature)) {
      // lastUpdateSignatureが初期値の0の場合
      // またはlastUpdateSignatureが新しく取得したsignListに含まれていない場合
      dispatch(setLastUpdateSignature(libId));
    } else {
      libId = lastUpdateSignature;
    }
    // 署名を選択
    setMailParam({
      ...mailParam,
      signatureLibraryId: libId,
      signature: Object.values(signList).find(s => { return s.libraryId === libId; })?.mailBody,
    });
    setMeta({ ...meta, sign: libId });
  };

  // 署名を初期化
  const resetLastUpdateSignature = () => {
    dispatch(setLastUpdateSignature(0));
  };

  // メール送信先選択
  const updateAddress = async (customerId, params) => {
    await customerUpdateCallApi(customerId, params)
      .then(() => { })
      .catch(err => console.error(err));
  };

  // 署名取得
  const getSignature = async (params) => {
    if (meta.from === MAIL_FROM_SELF) {
      const thenFunc = (data) => {
        const copyData = structuredClone(data);
        const addObj = {};
        const librariesList = {};
        const libs = copyData.libraries.sort((a, b) => a.libraryId - b.libraryId);
        const firstLibId = libs?.length ? libs[0].libraryId : 0;
        // 先頭から3件のみ
        for (let i = 0; i < 3 && i < libs.length; i += 1) {
          const item = libs[i];
          addObj[item.libraryId] = item.libraryName;
          librariesList[item.libraryId] = item;
        }
        setSignLibrary(librariesList);
        setSignMeta(addObj);
        selectUpdateSignature(librariesList, firstLibId);
        if (!signature?.libraries?.[0]?.libraryId) dispatch(libraryGetSigniture(copyData));
      };

      // 署名を選択
      if (signature?.libraries?.[0]?.libraryId) {
        thenFunc(signature);
      } else {
        await libraryGetSignitureApi()
          .then((res) => thenFunc(res.data))
          .catch(err => console.error(err));
      }
    } else {
      await sendmailGetDefaultApi(params)
        .then((res) => {
          const addObj = {};
          const librariesList = {};
          const libs = res.data;
          const firstLibId = libs?.length ? libs[0].libraryId : 0;
          // 先頭から3件のみ
          for (let i = 0; i < 3 && i < libs.length; i += 1) {
            const item = libs[i];
            addObj[item.libraryId] = item.libraryName;
            librariesList[item.libraryId] = item;
          }
          setSignLibrary(librariesList);
          setSignMeta(addObj);
          selectUpdateSignature(librariesList, firstLibId);
        })
        .catch(err => console.error(err));
    }
  };

  // 不通時メールライブラリ取得
  const getLibraryMail = async (userId) => {
    await libraryGetInterruptedApi(userId, 1)
      .then(async (res) => {
        const addObj = {};
        const librariesList = {};
        if (res.data.total !== 0) {
          const libs = res.data.libraries;
          // 先頭から3件のみ
          for (let i = 0; i < 3 && i < libs.length; i += 1) {
            const item = libs[i];
            addObj[item.libraryId] = item.libraryName;
            librariesList[item.libraryId] = item;
          }
        }
        setMailLibrary(librariesList);
        setMailMeta(addObj);
      })
      .catch(err => console.error(err));
  };

  // SMSの種類を取得
  // AfterCallWindowComponentにて初期読み込み時に実行
  const getLibrarySms = async (userId) => {
    await libraryGetInterruptedApi(userId, 0)
      .then(async (res) => {
        const addObj = {};
        const librariesList = {};
        if (res.data.total !== 0) {
          const libs = res.data.libraries;
          // 先頭から3件のみ
          for (let i = 0; i < 3 && i < libs.length; i += 1) {
            const item = libs[i];
            addObj[item.libraryId] = item.libraryName;
            librariesList[item.libraryId] = item;
          }
        }
        setSmsLibrary(librariesList);
        setSmsMeta(addObj);
      })
      .catch(err => console.error(err));
  };

  // GET SMS送信履歴 取得用(リスト初期表示で使用)
  const getSmsHistory = async (customerId) => {
    await smsHistoryApi(customerId)
      .then(async (res) => {
        await dispatch(getSmsHistoryApi(res.data));
        const list = res.data.info.filter(
          l => l.isSuspension !== 1 && l.isDeletedPhoneNumber !== 1,
        );
        if (list.length) {
          // 電話番号が登録されていない場合エラーになるための追加処理
          setting.tosms.setList(list[0].phoneNumber, list[0].smsCustomerTelId);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // テストメール送信
  const sendTestMail = (param) => {
    let result = false;
    sendmailTestApi(param)
      .then(() => {
        result = true;
      })
      .catch(err => {
        console.error(err);
        result = false;
      });
    return result;
  };

  // SMS送信
  const sendSms = async (param) => {
    await smsPostApi(param)
      .then(() => successSendCallBack?.())
      .catch(err => console.error(err));
  };

  // メール送信
  const sendMail = (param) => {
    let result = '';
    postSendMails(param)
      .then(async (res) => {
        result = res.message;
        const { message, description } = res;
        const msg = { message, description, status: 'success' };
        successSendCallBack?.();
        dispatch(isOpen(msg));
      })
      .catch(err => {
        console.error({ err });
      });
    return result;
  };

  // tree GET librarydir001
  // ログインユーザーのルートライブラリを取得
  const getOwnLibrary = async () => {
    // 取得済みならスキップ
    if (Object.keys(ownDir).length) return;
    await librarydirGetDirectlyTreeApi()
      .then((res) => {
        // eslint-disable-next-line max-len
        const targetDir = Object.values(res.data.libraryDirectories).find((e) => e.userId === responseHeader.userId);
        dispatch(librarydirOwnApi(targetDir));
      })
      .catch((err) => console.error(err));
  };

  const afterCallFunctions = {
    updateAddress,
    getLibrarySms,
    getLibraryMail,
    getSmsHistory,
    getSignature,
    sendTestMail,
    sendSms,
    sendMail,
    resetLastUpdateSignature,
    getOwnLibrary,
  };

  return (
    <AfterCallWindowComponent
      open={open}
      user={user}
      handleClose={handleClose}
      setting={setting}
      meta={meta}
      setMeta={setMeta}
      setMailSetting={setMailSetting}
      afterCallFunctions={afterCallFunctions}
      responseHeader={responseHeader}
      libraries={libraries}
      mailParam={mailParam}
      setMailParam={setMailParam}
      editorState={editorState}
      setEditorState={setEditorState}
      ownDir={ownDir}
    />
  );
}
