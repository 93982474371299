import { useSelector, useDispatch } from 'react-redux';

import { AppBar, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import LibrarySearch from './parts/librarySearch';
import LibraryTree from './parts/libraryTree';

import { libraryPageSelector, setTabType } from '../../store/libraryPage';

import { useLoading } from '../../hooks';
import { LIBRARY_SIDEBAR } from '../../constants/loading';
import commonStyles from '../styles';

const useStyles = makeStyles({
  root: {
    background: '#F3F3F3',
    height: '100%',
  },
  tabpanel: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '8px',
    boxSizing: 'border-box',
    '& > .MuiGrid-root': {
      height: '100%',
      '& > .MuiGrid-root': {
        height: '100%',
      },
    },
  },
});

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.tabpanel}
    >
      {value === index && (
        <Grid p={3}>
          <Grid>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default function MailLibrarySidebar(props) {
  const {
    functions,
    selectDir,
    setSelectDir,
  } = props;

  const { hasLoading } = useLoading();

  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();
  const { tabType } = useSelector(libraryPageSelector);

  // searchが1(true)
  const tabIndex = +(tabType === 'search');
  const onChange = (_, idx) => dispatch(setTabType(idx ? 'search' : 'tree'));

  return (
    <Grid className={`${classes.root} ${hasLoading(LIBRARY_SIDEBAR) ? common.loading : ''}`}>
      <AppBar position="relative">
        <Tabs onChange={onChange} value={tabIndex}>
          <Tab label="ライブラリーツリー" {...a11yProps(0)} className={classes.tab} />
          <Tab label="検索" {...a11yProps(1)} className={classes.tab} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0} className={classes.tabPanel}>
        <LibraryTree
          functions={functions}
          selectDir={selectDir}
          setSelectDir={setSelectDir}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} className={classes.tabPanel}>
        <LibrarySearch functions={functions} />
      </TabPanel>
    </Grid>
  );
}
