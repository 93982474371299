import React from 'react';
import SetStoreGroup from '../setStoreGroup';

function SetStoreGroupTest() {
  return (
    <SetStoreGroup />
  );
}

export default SetStoreGroupTest;
