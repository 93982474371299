import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  txt: {
    fontFamily: 'Roboto',
    color: '#333',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
});

export default function DirectTypeCode(props) {
  const {
    code,
  } = props;

  const classes = useStyles();

  const getAction = () => {
    switch (code) {
      case 0:
        return (
          <span>指示</span>
        );
      case 1:
        return (
          <span>更新</span>
        );
      case 2:
        return (
          <span>対応予定</span>
        );
      case 3:
        return (
          <span>報告</span>
        );
      case 4:
        return (
          <span>対応無し</span>
        );
      case 5:
        return (
          <span>取り消し</span>
        );
      case 6:
        return (
          <span>コメント</span>
        );
      case 7:
        return (
          <span>催促</span>
        );
      case 8:
        return (
          <span>承認</span>
        );
      case 9:
        return (
          <span>やり直し</span>
        );
      case 10:
        return (
          <span>引き戻し</span>
        );
      default:
        return (
          <span>該当なし</span>
        );
    }
  };

  return (
    <span className={classes.txt}>
      {getAction()}
    </span>
  );
}
