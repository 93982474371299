import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../styles/theme';
import FlyerSalesTreeResponse from './flyerSalesTreeResponse';

const useStyles = makeStyles({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '100%',
        background: '#F3F3F3',
      },
      '& .MuiDialog-paper': {
        maxWidth: 'none',
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'initial',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
        borderRadius: 0,
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      '& .MuiDialog-paperWidthSm': {
        padding: 0,
        minWidth: 400,
      },
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '24px 24px 8px 24px',
      backgroundColor: '#fff',
      '& > p': {
        textAlign: 'center',
      },
    },
  },
  body: {
    [commonTheme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      padding: 0,
      height: '100%',
    },
  },
  inner: {
    background: '#fff',
    border: '1px #C8C8C8 solid',
    padding: 8,
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
    '& > * + *': {
      marginLeft: 16,
    },
  },
});

export default function FlyerSalesTree(props) {
  const {
    setSelectUser, // 任意：選択されたユーザー情報のsetState(setState)
    setSelectDivision, // 任意：選択された組織情報のsetState(setState)
    setSelectFlyer, // 任意：選択されたFlyer情報のsetState(setState)
    levelCode,
    divisionId,
    reloadHanlder, // ツリーのAPIを叩く関数
  } = props;

  // const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const classes = useStyles();

  // 元コンポーネントからuser, division, customerのstateが渡されなかった時の為、仮のState用意
  const [localUser, setLocalUser] = useState({ userId: 0, userName: '' });
  const [localDivision, setLocalDivision] = useState({
    divId: 0,
    divisionName: '',
  });
  const [localFlyer, setLocalFlyer] = useState([]);

  useEffect(() => {
    console.log(localFlyer);
    console.log(localUser);
    console.log(localDivision);
  }, []);

  // 源泉未処理一覧で使うツリー
  /**
   * @params {setState} setSelectFlyer(array)
   * @params {levelCode} integer
   * @params {divisionId} integer
   */
  const displayDivisionTree = () => {
    return (
      <Grid className={`${classes.body}`}>
        <Grid className={`${classes.inner}`}>
          <FlyerSalesTreeResponse
            setSelectUser={
              'setSelectUser' in props ? setSelectUser : setLocalUser
            }
            setSelectDivision={
              'setSelectDivision' in props
                ? setSelectDivision
                : setLocalDivision
            }
            setSelectFlyer={
              'setSelectFlyer' in props
                ? setSelectFlyer
                : setLocalFlyer
            }
            levelCode={levelCode}
            divisionId={divisionId}
            reloadHanlder={reloadHanlder}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid>
      {displayDivisionTree()}
      {/* {isSp ? (
        <Dialog
          className={`${classes.dialog}`}
          open={isOpen}
          onClose={() => onCancel()}
        >
          <Grid className={`${common.header} ${classes.header}`}>
            <Typography className={`${isSp ? common.title2 : common.title3}`}>
              顧客選択
            </Typography>
            <Button className={common.closeButton} onClick={() => onCancel()} />
          </Grid>
          <Grid className={`${common.body} ${classes.body}`}>
            <FlyerSalesTreeResponse
              setSelectUser={setSelectUser}
              searchForm={Boolean(true)}
            />
          </Grid>
          <Grid className={`${common.footer} ${classes.footer}`}>
            <Grid item className={classes.btn}>
              <Button
                className={common.buttonsSecondary}
                onClick={() => onCancel()}
                type="reset"
              >
                選択解除
              </Button>
              <Button
                className={common.buttonsPrimary}
                onClick={() => onClickSelect()}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      ) : (
        displayDivisionTree()
      )} */}
    </Grid>
  );
}
