import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DirectTypeCode from '../directTypeCode';
import commonStyles from '../../../../styles';

const useStyles = makeStyles({
  item: {
    padding: '8px 16px',
    borderBottom: '1px solid #C8C8C8',
  },
  itemDate: {
    marginRight: 8,
    lineHeight: 1.6,
    fontWeight: 400,
  },
  itemContent: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    marginRight: 6,
  },
  itemIcon: {
    fontSize: 14,
    color: '#8C8C8C',
    marginRight: 6,
  },
});

function InstructionReportItemListSp(props) {
  const {
    detail,
  } = props;

  const classes = useStyles();
  const common = commonStyles();

  return (
    <Grid className={classes.bottom}>
      {detail.timelines && detail.timelines.map((timeline) => (
        <Grid key={timeline.directTimelineId} className={classes.item}>
          <Typography className={`${common.title5} ${classes.itemDate}`}>
            {timeline.createdAt}
          </Typography>
          <Typography className={classes.itemContent}>
            {[timeline.createdUserLastName, timeline.createdUserFirstName].filter(Boolean).join(' ')}
            [<DirectTypeCode code={timeline.reportCode} />]
          </Typography>
          <Typography className={classes.itemContent}>
            {timeline.content}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default InstructionReportItemListSp;
