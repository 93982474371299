import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../../calendar';
// import commonStyles from '../../../../styles';

const useStyles = makeStyles({
  contentIpt: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    boxSizing: 'border-box',
  },
  contentIptDate: {
    marginTop: '-2px',
    '& div': {
      border: 'none',
      padding: 0,
      '& .MuiTypography-root': {
        marginRight: '45px',
      },
      '& label': {
        width: 440,
      },
    },
    '& span': {
      padding: 0,
    },
  },
});

function ResDateField(props) {
  const { date, setDate, inquiredAt, addFlag } = props;
  console.log(inquiredAt);
  const classes = useStyles();
  // const baseClasses = commonStyles();

  useEffect(() => {
    const dt = new Date();
    const y = dt.getFullYear();
    const m = ('00' + (dt.getMonth() + 1)).slice(-2);
    const d = ('00' + dt.getDate()).slice(-2);
    const result = y + '/' + m + '/' + d;
    if (addFlag === 1) {
      setDate(result);
    } else {
      setDate(inquiredAt);
    }
  }, []);
  const assignAtOnchange = (value) => {
    setDate(value);
  };

  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '問合せ日', // String - ラベル
      require: true, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: date, // state
      setState: assignAtOnchange, // setState
    },
  ];

  return (
    <Grid className={classes.contentIpt}>
      {datalist.map((array) => {
        return (
          <Grid className={classes.contentIptDate} key={array.id}>
            <Calendar data={array} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ResDateField;
