import client from '../httpClient';

/**
 * 【hpInfo001】WEB会員情報取得
 * http://10.22.2.48:8080/docs/#/members/get-members
 * customerId/memberId/hashCustomerIdいずれか1つのパラメータを指定
 * @param {{
 *   customerId?: number;
 *   memberId?: number;
 *   hashCustomerId?: string;
 * }} params パラメータ
 * @returns {Promise<{total: number; members: T[]}>}
 */
const hpinfoGetInfoApi = async (params) => {
  try {
    const res = await client.get('/members', { params });
    return res.data;
  } catch (e) {
    console.error(e);
    return {
      total: 0,
      members: [],
    };
  }
};

export default hpinfoGetInfoApi;
