import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import UserTree from '../../common/userTreeNew';
// import store from '../../../store';

const useStyles = makeStyles({
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #C8C8C8',
  },
  searchIcon: {
    color: '#000',
  },
  searchButton: {
    width: '100%',
    textAlign: 'left',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
});

function SearchBox(props) {
  const { query = {}, setQuery } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  // モーダル用のローカルステート
  const [open, setOpen] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [selectDivision, setSelectDivision] = useState({});
  // const { responseHeader } = store.getState();

  const getDisplayName = () => {
    if (query.displayName) return query.displayName;
    return '選択してください';
  };

  const handleSelectFunction = () => {
    const tmp = {
      userId: undefined,
      divisionId: selectDivision.divId,
      displayName: selectDivision.divisionName,
    };
    if (selectUser.userId) {
      tmp.userId = selectUser.userId;
      tmp.displayName = `${tmp.displayName}/${selectUser.userName}`;
    }
    return setQuery(tmp);
  };

  useEffect(() => {
    if (query?.userId) {
      setSelectUser({
        userId: query?.userId,
        userName: query?.displayName,
      });
    } else if (query?.divisionId) {
      setSelectDivision({
        divId: query?.divisionId,
        divisionName: query?.divisionName,
      });
    }
  }, [query]);

  return (
    <>
      <Grid className={classes.searchContainer}>
        <Button onClick={() => setOpen(true)} className={classes.searchButton}>
          <SearchIcon className={classes.searchIcon} />
          <Typography className={baseClasses.verySmallStrong}>
            {getDisplayName()}
          </Typography>
        </Button>
      </Grid>
      <UserTree
        isOpenTree={open}
        setIsOpenTree={setOpen}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        handleSelectFunction={handleSelectFunction}
        isDialog
        clickableUserAndDivision
      />
    </>
  );
}

export default SearchBox;
