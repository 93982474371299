import {
  useDispatch,
} from 'react-redux';
import userDetailApi from '../../apis/user/userDetailApi';
import userCreateApi from '../../apis/user/userCreateApi';
import userUpdateApi from '../../apis/user/userUpdateApi';
import userHistoryApi from '../../apis/user/userHistory';
import userListApi from '../../apis/user/userListApi';
import divisionsGetTreeApi from '../../apis/divisions/divisionsGetTreeApi';
import userDeleteApi from '../../apis/user/userDeleteApi';

import {
  setUserApi,
  setUserIdApi,
  initialState,
} from '../../store/user/userSlice';
import { getUserListApi } from '../../store/user/userListSlice';
import { getUserDivListApi } from '../../store/user/userDivListSlice';
import Users from '../../components/user';
import { useLoading } from '../../hooks';
import { USER_ORG_SETTING } from '../../constants/loading';

function UsersContainer() {
  const dispatch = useDispatch();

  const { addLoading, removeLoading } = useLoading();

  const userDivParams = {
    isIgnoreExpire: 1,
    userStatusCode: [1, 2],
  };

  // Set State
  const setUser = (modifyObj) => {
    dispatch(setUserApi(modifyObj));
  };

  // set Division State
  const setDivision = async (modifyObj, index) => {
    await userHistoryApi(modifyObj, index)
      .catch((err) => {
        console.log(err);
      });
  };

  // GET User List
  const userListFunction = async (status) => {
    await userListApi(status)
      .then((res) => {
        dispatch(getUserListApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // userDivListFunction
  const userDivListFunction = async (params) => {
    await divisionsGetTreeApi(params)
      .then((res) => {
        dispatch(getUserDivListApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // GET User
  const userDetailFunction = async (userId) => {
    await userDetailApi(userId)
      .then(async (res) => {
        await dispatch(setUserApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // POST Create User
  const userCreateFunction = async (modifyObj) => {
    await userCreateApi(modifyObj)
      .then(async (res) => {
        await dispatch(setUserIdApi(res.data.userId));
        userDetailFunction(res.data.userId);
        userDivListFunction(userDivParams);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // PUT Update User
  const userUpdateFunction = async (userId, modifyObj) => {
    addLoading(USER_ORG_SETTING);
    await userUpdateApi(userId, modifyObj)
      .then(async (res) => {
        await dispatch(setUserIdApi(res.data.userId));
        userDetailFunction(res.data.userId);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => removeLoading(USER_ORG_SETTING));
  };

  // GET User
  const userResetFunction = () => {
    dispatch(setUserApi(initialState));
  };

  // PUT Update UserDivisionHistory
  const userDivisionUpdateFunction = async (modifyObj, userId) => {
    const paramsObj = {
      positionCode: modifyObj.positionCode,
      isMainDivision: modifyObj.isMainDivision,
      gradeCode: modifyObj.gradeCode,
      startAt: modifyObj.startAt,
      endAt: modifyObj.endAt,
    };
    await userHistoryApi(paramsObj, Number(modifyObj.userDivisionId))
      .then(() => {
        userDetailFunction(userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteUserDivision = async (userDivisionID, modifyObj) => {
    await userDeleteApi(userDivisionID, modifyObj)
      .then((res) => {
        userDetailFunction(res.data.userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Users
      setUser={setUser}
      setDivision={setDivision}
      getUserFunction={userDetailFunction}
      createUserFunction={userCreateFunction}
      updateUserFunction={userUpdateFunction}
      resetUserFunction={userResetFunction}
      updateDivisionFunction={userDivisionUpdateFunction}
      userListFunction={userListFunction}
      userDivListFunction={userDivListFunction}
      deleteUserDivision={deleteUserDivision}
    />
  );
}

export default UsersContainer;
