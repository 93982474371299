import { Button, makeStyles, Popper, Typography } from '@material-ui/core';
import { SendOutlined, DeleteOutline } from '@material-ui/icons';
import SendTimeUpdate from './sendTimeUpdate';
import commonStyles from '../../styles';

const useStyles = makeStyles(() => ({
  popper: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: { transform: 'scale(0.9)' },
  icon: { width: '16px', marginRight: '4px' },
}));

export default function UnsentMenuPopper(props) {
  const {
    open,
    anchorEl,
    row,
    id,
    setOpenDialog,
    setOpenDialog2,
    setOpenSendTimeDialog,
    setSendTime,
  } = props;
  const common = commonStyles();
  const classes = useStyles();
  const onClickSendBtn = () => {
    // setOpenDialog({ confirmMessage: '今すぐ送信開始します。\n送信が多い時間帯は、送信開始まで時間がかかる場合があります。',
    //  type: 'send' });
    setOpenDialog(true);
  };

  const onClickCancelBtn = () => {
    // setOpenDialog({ confirmMessage: '送信予定のメールを削除します。\nよろしいですか？', type: 'cancel' });
    setOpenDialog2(true);
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="left"
      className={classes.popper}
      transition
      // disablePortal
    >
      <Button
        className={`${classes.btn} ${common.buttonsSecondary}`}
        onClick={onClickSendBtn}
      >
        <SendOutlined className={classes.icon} />
        <Typography className={common.smallStrong}>今すぐ送信</Typography>
      </Button>
      <SendTimeUpdate
        row={row}
        setOpenSendTimeDialog={setOpenSendTimeDialog}
        setSendTime={setSendTime}
      />
      <Button
        className={`${classes.btn} ${common.buttonsSecondary}`}
        onClick={onClickCancelBtn}
      >
        <DeleteOutline className={classes.icon} />
        <Typography className={common.smallStrong}>送信の取り消し</Typography>
      </Button>
    </Popper>
  );
}
