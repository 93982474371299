import { createSlice } from '@reduxjs/toolkit';

// TODO: 正直CreateにSliceは不要そうだが念のため作成。
const initialState = {};

export const userCreateSlice = createSlice({
  name: 'userCreate',
  initialState: { userCreate: initialState },
  reducers: {
    createUserApi: (state, data) => {
      state.userCreate = data.payload;
    },
  },
});

export const { createUserApi } = userCreateSlice.actions;

export default userCreateSlice.reducer;
