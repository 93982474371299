import {
  Button,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import commonStyles from '../../styles';

import { RESPONSE_TYPE_TH, RESPONSE_TYPE_ID } from '../../../constants/responseTypeMaster';
import { RESPONSE_TYPE_LEVEL_CODE } from '../../../constants';
import modifyArray from '../../../commonFunction/modifyArray';

import SearchCell from './searchCell';

const objs = modifyArray(RESPONSE_TYPE_LEVEL_CODE, '', true);

const useStyles = makeStyles({
  tableTop: {
    height: '32px',
    padding: '0px',
  },
  tableTopCell: {
    border: '0px none',
    padding: '10px',
  },
  tableHead: {
    padding: '4px 0px',
    borderBottom: '2px solid',
    color: '#F3F3F3',
  },
  tableHeadCell: {
    margin: '0px',
    padding: '0px',
    color: '#8C8C8C',
  },
  pagenation: {
    '& .MuiTablePagination-input': { display: 'none' },
    '& .MuiTablePagination-caption:first-of-type': { display: 'none' },
  },
});

function TableHeaders(props) {
  const {
    setSearchParm,
    openModalFun,
    order,
    orderBy,
    onRequestSort,
    setIsNewEntry,
  } = props;

  const commonClasses = commonStyles();
  const classes = useStyles();

  const searchObjs = [
    {
      key: 'responseTypeName',
      type: 'text',
    },
    {
      title: objs.title,
      required: false,
      key: 'responseTypeLevelCode',
      type: 'select',
      menus: objs.menus,
    },
    {
      key: 'isAllowSales',
      type: 'sales',
    },
    {
      key: 'displayOrder',
      type: 'order',
    },
    {
      key: 'responseTypeParentName',
      type: 'text',
    },
  ];

  const newEntryBtnClick = () => {
    openModalFun();
    setIsNewEntry(true);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow className={classes.tableTop}>
        <TableCell style={{ top: 0 }} className={classes.tableTopCell} colSpan={2}>
          <Button
            className={commonClasses.buttonsPrimary}
            type="button"
            size="small"
            onClick={() => newEntryBtnClick()}
          >
            <AddIcon fontSize="small" />
            新規追加
          </Button>
        </TableCell>
        <TableCell
          style={{ top: 0 }}
          className={classes.tableTopCell}
          colSpan={4}
        />
      </TableRow>
      <TableRow className={classes.tableHead}>
        {RESPONSE_TYPE_TH.map((th, index) => (
          <TableCell
            style={{ top: 50.75 }}
            className={classes.tableHeadCell}
            key={th}
          >
            <TableSortLabel
              active={orderBy === RESPONSE_TYPE_ID[index]}
              direction={orderBy === RESPONSE_TYPE_ID[index] ? order : 'asc'}
              onClick={createSortHandler(RESPONSE_TYPE_ID[index])}
              className={classes.icon}
            >
              {th}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      <TableRow className={classes.tableSearchRow}>
        <TableCell style={{ top: 75.75 }} />
        {searchObjs.map((obj) => (
          <SearchCell
            style={{ top: 75.75 }}
            key={obj.key}
            initial={obj}
            setSearchParm={setSearchParm}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeaders;
