const getDeviceType = () => {
  let spFlg = false;
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
  ) {
    spFlg = true;
  } else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
    // iOS12 まで
    spFlg = true;
  } else if (
    ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document)
  ) {
    // iOS13 以降
    spFlg = true;
  } else {
    spFlg = false;
  }
  return spFlg;
};

export default getDeviceType;
