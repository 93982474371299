import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import ClaimRegisterText from './parts/claimRegisterText';
import ClaimRegisterButton from './parts/claimRegisterButton';
import ClaimRegisterChoice from './parts/claimRegisterChoice';
import PageNation from '../../eleCommon/pageNation';
import { CLAIM_OPERATION_TYPES } from '../../../constants/apiParameterCode';
import Modal from '../modal';
import { useBrowser, useStore } from '../../../hooks';

const useStyles = makeStyles({
  box: {
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 24,
    },
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  ttl: {
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 8,
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  childwrap: {
    width: '100%',
  },
  itemwrap: {
    width: '100%',
    '&:not(&.smallBtn)': {
      [commonTheme.breakpoints.down('sm')]: {
        padding: '0 16px',
        background: '#fff',
      },
    },
    '&:not(:last-child)': {
      [commonTheme.breakpoints.up('md')]: {
        marginBottom: 8,
      },
    },
  },
  caption: {
    color: '#D83420',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  pageNation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [commonTheme.breakpoints.up('md')]: {
      marginTop: -20,
    },
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
});

const initialClaim = {
  claimId: '',
  customerId: '',
  customerLastName: '',
  customerFirstName: '',
  cause: '',
  approach: '',
  result: '',
  responsibleUserId: '',
  responsibleUserLastName: '',
  responsibleUserFirstName: '',
  targetUserId: '',
  targetUserLastName: '',
  targetUserFirstName: '',
  subTargetUserId: '',
  subTargetUserLastName: '',
  subTargetUserFirstName: '',
  managerUserId: '',
  managerUserLastName: '',
  managerUserFirstName: '',
  targetUserFullName: '',
  subTargetUserFullName: '',
  managerUserFullName: '',
};

export default function ClaimRegisterWrapper(props) {
  const {
    open,
    onClose,
    setStatus,
    registerClaim,
    putClaim,
    list,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const { isSP } = useBrowser();

  // ボタンの必須制御
  const [disabled, setDisabled] = useState(false);
  const [index, setIndex] = useState(0);
  const [claim, setClaim] = useState(list[index] || { ...initialClaim });

  const [isUpdatedClaim, setIsUpdatedClaim] = useState(false);
  const { responseHeader } = useStore();

  useEffect(() => {
    let newIndex = index;
    // モダールが閉じる際
    if (!open) {
      newIndex = 0;
      setIndex(0);
    }

    const newClaim = (() => {
      if (list[newIndex]) {
        const newList = list[newIndex];

        // 既にtargetUserFullNameが定義済みである場合
        if (newList?.targetUserFullName) return newList;

        // targetUserFullNameが存在しない場合: 親コンポーネントがgetClaimで取得した値にはtargetUserFullNameが存在しない為、結合で対応
        const targetUserFullName = newList.targetUserLastName + newList.targetUserFirstName;
        const subTargetUserFullName = (
          newList.subTargetUserLastName + newList.subTargetUserFirstName
        );
        const managerUserFullName = newList.managerUserLastName + newList.managerUserFirstName;
        return {
          ...list[newIndex],
          targetUserFullName,
          subTargetUserFullName,
          managerUserFullName,
        };
      }
      return { ...initialClaim };
    })();

    setClaim(newClaim);
    if (list[newIndex]?.claimId) setIsUpdatedClaim(true);
  }, [list, index, open]);

  useEffect(() => {
    if (claim) {
      if (claim.cause === '') {
        setDisabled(true);
      } else if (claim.approach === '') {
        setDisabled(true);
      } else if (claim.result === '') {
        setDisabled(true);
      } else if (claim.targetUserId === '') {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [claim]);

  // 登録処理
  const registerStart = async () => {
    if (disabled) return;
    setDisabled(true);
    const sendParam = {
      customerId: Number(claim.customerId),
      cause: claim.cause,
      approach: claim.approach,
      result: claim.result,
      targetUserId: Number(claim.targetUserId),
      type: CLAIM_OPERATION_TYPES.REGISTER_OR_UPDATE, // 登録/更新
    };
    if (claim.responsibleUserId) {
      sendParam.responsibleUserId = Number(claim.responsibleUserId);
    }
    if (claim.subTargetUserId) {
      sendParam.subTargetUserId = Number(claim.subTargetUserId);
    }
    if (claim.managerUserId) {
      sendParam.managerUserId = Number(claim.managerUserId);
    }
    try {
      if (!claim.claimId) {
        await registerClaim(sendParam);
      } else {
        sendParam.claimId = claim.claimId;
        await putClaim(sendParam);
      }
      onClose();
      setStatus?.();
    } finally {
      setDisabled(false);
    }
  };

  const box = [
    {
      label: 'クレームの原因、対処、結果',
      key: 'key1',
      items: [
        {
          type: 'text',
          key: 'cause',
          input: {
            label: '原因',
            required: true,
            state: claim && claim.cause,
            setState: (e) => {
              setClaim({
                ...claim,
                cause: e,
              });
            },
          },
        },
        {
          type: 'text',
          key: 'approach',
          input: {
            label: '対処方法',
            required: true,
            state: claim && claim.approach,
            setState: (e) => {
              setClaim({
                ...claim,
                approach: e,
              });
            },
          },
        },
        {
          type: 'text',
          key: 'result',
          input: {
            label: '対処結果',
            required: true,
            state: claim && claim.result,
            setState: (e) => {
              setClaim({
                ...claim,
                result: e,
              });
            },
          },
        },
        {
          type: 'button',
          key: 'shortening',
          input: {
            label: '短縮入力',
            setState: ([cause, approach, result]) => {
              setClaim({
                ...claim,
                cause,
                approach,
                result,
              });
            },
          },
        },
      ],
    },
    {
      label: '対応者と責任者',
      key: 'key2',
      items: [
        {
          type: 'choice',
          key: 'targetUserId',
          input: {
            label: 'クレーム対象者',
            required: true,
            userId: claim && claim.targetUserId,
            users: claim.targetUserFullName || '',
            setState: (e) => {
              setClaim((prevClaim) => ({
                ...prevClaim,
                targetUserId: e.userId,
                targetUserFirstName: '',
                targetUserLastName: '',
                targetUserFullName: e.userName,
              }));
            },
          },
        },
        {
          type: 'choice',
          key: 'subTargetUserId',
          input: {
            label: 'クレーム対象者2',
            required: false,
            userId: claim && claim.subTargetUserId,
            users: claim.subTargetUserFullName || '',
            setState: (e) => {
              setClaim((prevClaim) => ({
                ...prevClaim,
                subTargetUserId: e.userId,
                subTargetUserLastName: '',
                subTargetUserFirstName: '',
                subTargetUserFullName: e.userName,
              }));
            },
          },
        },
        {
          type: 'choice',
          key: 'managerUserId',
          input: {
            label: '対応責任者',
            required: false,
            userId: claim && claim.managerUserId,
            users: claim.managerUserFullName || '',
            setState: (e) => {
              setClaim((prevClaim) => ({
                ...prevClaim,
                managerUserId: e.userId,
                managerUserLastName: '',
                managerUserFirstName: '',
                managerUserFullName: e.userName,
              }));
            },
          },
        },
      ],
    },
  ];

  const switchType = (val) => {
    const returnType = [];
    const userName = claim[val.key.replace('Id', 'LastName')] + claim[val.key.replace('Id', 'FirstName')];
    // 親コンポーネントでgetClaimして値が更新された場合、ユーザー名を表示させる
    const values = isUpdatedClaim
      ? { ...val.input, users: userName }
      : { ...val.input, userId: val.input.userId || responseHeader.userId };
    if (isUpdatedClaim) {
      setIsUpdatedClaim(false);
    }

    if (isSP) {
      switch (val.type) {
        case 'text':
          return <ClaimRegisterText values={values} />;
        case 'choice':
          return <ClaimRegisterChoice values={values} />;
        case 'button':
          return <ClaimRegisterButton values={values} />;
        default:
          break;
      }
    } else {
      switch (val.type) {
        case 'text':
          return <ClaimRegisterText values={values} />;
        case 'button':
          return <ClaimRegisterButton values={values} />;
        case 'choice':
          return <ClaimRegisterChoice values={values} />;
        default:
          break;
      }
    }
    return returnType;
  };

  const items = (val) => {
    const itemchild = [];
    val.map(i => itemchild.push(
      <Grid
        key={i.key}
        className={`${classes.itemwrap} ${i.type === 'button' ? 'smallBtn' : ''}`}
      >
        {switchType(i)}
      </Grid>,
    ));
    return itemchild;
  };

  function Boxlist() {
    const arr = [];
    box.map(b => arr.push(
      <Grid key={b.key} className={classes.box}>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>{b.label}</Typography>
        <Grid container className={classes.childwrap}>{items(b.items)}</Grid>
      </Grid>,
    ));
    return arr;
  }

  const onPageChange = (params) => {
    setIndex(params.offset);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={['クレーム登録ウィンドウ', 'クレーム登録']}
      buttons={{
        label: '登録',
        disabled,
        onClick: registerStart,
      }}
      width={640}
    >
      <Grid className={classes.pageNation}>
        {!list.length ? null
          : <PageNation
              hideReloadButton
              count={list.length}
              limit={1}
              getApi={onPageChange}
          />}
      </Grid>
      <Boxlist />
      <Typography className={classes.caption}>
        ※ クレーム登録をすると、対象のお客様が「電話禁止」「メール禁止」になります。<br />
        ※ クレームの解除はSFAの顧客メイン画面から「解除申請」をしてください。
      </Typography>
    </Modal>
  );
}
