import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';
import InstructionsWindow from '../../common/taiouRireki/instructionsWindow';
import { DIRECT_STATUS_CODE } from '../../../constants';
import { useUpdate } from '../../../containers/customerMain';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover .MuiButton-label': { backgroundColor: `${theme.palette.primaryColor}29` },
  },
  button: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    width: '100%',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    marginBottom: 6,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '6px 8px',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    width: 256,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

function CustomerInstructions(props) {
  const { input } = props;
  const { customer: current } = input;
  const {
    update,
  } = useUpdate(input.customer);

  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル開閉用のローカルステート(指示登録)
  const [instOpen, setInstOpen] = useState(false);
  // モーダルを開くsetState
  const handleInstOpen = () => {
    setInstOpen(true);
  };
  // モーダルを閉じるsetState
  const closeInst = () => {
    setInstOpen(false);
  };

  const updateDirect = (val) => {
    update({
      directTimeLineContent: val.content,
      directStatusCode: val.directReportStatusCode,
      expireAt: val.expireAt,
    });
  };

  const getBox = () => {
    return (
      <Button className={classes.button} onClick={handleInstOpen}>
        <Typography className={baseClasses.title6}>
          指示
        </Typography>
        <Typography className={classes.txt}>
          {`${current.directStatusCode ? current.expireAt : ''} ${current.directStatusCode ? DIRECT_STATUS_CODE[current.directStatusCode] : ''}  ${current.directTimeLineContent || ''}`}
        </Typography>
      </Button>
    );
  };

  return (
    <Grid className={classes.root}>
      {(current.directStatusCode || current.expireAt) ? (
        <NormalTooltip
          title={`${current.directStatusCode ? current.expireAt : ''} ${current.directStatusCode ? DIRECT_STATUS_CODE[current.directStatusCode] : ''} ${current.directTimeLineContent}`}
        >
          {getBox()}
        </NormalTooltip>
      ) : (
        <>
          {getBox()}
        </>
      )}
      <InstructionsWindow
        open={instOpen}
        setOpen={setInstOpen}
        onClose={closeInst}
        directId=""
        newInstructionFlg
        customerId={current.customerId}
        divisionId={current.divisionId}
        userId={current.userId}
        userFirstName={current.userFirstName}
        userLastName={current.userLastName}
        updatePage={updateDirect}
      />
    </Grid>
  );
}

export default React.memo(CustomerInstructions);
