import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';
import { useActionSchedule } from '../../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles({
  button: {
    minWidth: 108,
  },
});

function ResetButton(props) {
  const { txt } = props;

  const {
    searchInput,
  } = useActionSchedule();

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Button
      className={`${baseClasses.buttonsSecondary} ${classes.button}`}
      type="button"
      onClick={searchInput.resteStart}
    >
      {txt}
    </Button>
  );
}
export default ResetButton;
