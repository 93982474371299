import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerGetBrowsingHistorySlice = createSlice({
  name: 'customerGetBrowsingHistory',
  initialState: { customerGetBrowsingHistory: initialState },
  reducers: {
    customerGetBrowsingHistory: (state, data) => {
      state.customerGetBrowsingHistory = data.payload;
    },
  },
});

export const { customerGetBrowsingHistory } = customerGetBrowsingHistorySlice.actions;

export default customerGetBrowsingHistorySlice.reducer;
