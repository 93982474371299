import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import commonStyles from '../../styles';
import AllocationSettingHead from './allocationSettingHead';
import AllocationSettingRow from './allocationSettingRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: '#fff',
    marginBottom: 8,
    display: 'none',
    '&.active': {
      display: 'block',
    },
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-16px',
    padding: '0 8px',
  },
  control: {
    position: 'absolute',
    zIndex: 9,
    top: 57,
    left: 136,
  },
  body: {
    border: '1px solid #C8C8C8',
    padding: 8,
    width: 'calc(100% - 16px)',
  },
  btnWrap: {
    marginBottom: 8,
    display: 'none',
    '&.active': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  btn1: {
    marginRight: 8,
    padding: '8px 22px',
  },
  btn2: {
    color: theme.palette.primaryColor,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '8px 22px',
  },
  tableContainer: {
    overflowY: 'auto',
  },
  tablePagination: {
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-selectRoot': {
      margin: '0 40px 0 0',
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
    '& .MuiSelect-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
    '& .MuiIconButton-root': {
      padding: 5,
      color: theme.palette.primaryColor,
      '&.Mui-disabled': {
        color: '#C8C8C8',
      },
      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
    '& .MuiTablePagination-input': {
      display: 'none',
    },
    '& .MuiTablePagination-actions': {
      marginLeft: 15,
    },
  },
  table: {
    height: 1,
    tableLayout: 'fixed',
  },
}));

export default function ProcessTable(props) {
  const {
    tableData,
    activeBtn,
    setFile,
    getAllocationSetting,
    uploadCsv,
    deleteAllocationSetting,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const headerRow = tableData.length > 0 ? tableData[0].settings : [];

  const tableContainerHeight = (window.innerHeight - 240) + 'px';
  const tableWith = headerRow.length * 120 + 112 + 'px';

  const onSave = () => {
    uploadCsv();
    setFile(null);
  };
  // クリアボタン
  const handleClear = () => {
    deleteAllocationSetting();
    setFile(null);
    getAllocationSetting();
  };

  return (
    <Grid className={`${classes.root} active`}>
      <Paper className={classes.paper}>
        <Grid className={classes.body}>
          <Grid className={`${classes.btnWrap} ${activeBtn && 'active'}`}>
            <Button
              className={`${baseClasses.buttonsPrimary} ${classes.btn1}`}
              onClick={() => onSave()}
            >
              アップロードした内容で保存
            </Button>
            <Button className={classes.btn2} onClick={handleClear}>
              クリア
            </Button>
          </Grid>
          <TableContainer
            className={classes.tableContainer}
            style={{ height: tableContainerHeight }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              style={{ width: tableWith }}
            >
              <AllocationSettingHead headerRow={headerRow} />
              <TableBody id="instructionListTablebody">
                {tableData.map((row, rowNum) => {
                  return (
                    <AllocationSettingRow
                      key={row.id}
                      row={row}
                      rowNum={rowNum}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
    </Grid>
  );
}
