import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import FlyerSalesTree from '../common/flyerSalesTree/flyerSalesTree';

export default function FlyerSalesTreeTest() {
  // 反響ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  // // 反響ツリー選択組織
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });
  // 反響ツリー選択顧客
  const [selectFlyer, setSelectFlyer] = useState([]);
  const levelCode = null;
  const divisionId = null;

  useEffect(() => {
    console.log('---------反響ツリー取得内容-------');
    console.log('selectUser----');
    console.log(selectUser);
    console.log('selectDivision----');
    console.log(selectDivision);
    console.log('selectFlyer----');
    console.log(selectFlyer);
  }, [selectFlyer]);

  return (
    <Grid container>
      <Grid item xs={3} md={3} lg={3} xl={3}>
        <FlyerSalesTree
          setSelectUser={setSelectUser}
          setSelectDivision={setSelectDivision}
          setSelectFlyer={setSelectFlyer}
          levelCode={levelCode}
          divisionId={divisionId}
        />
      </Grid>
    </Grid>
  );
}
