import 'date-fns';
import ja from 'date-fns/locale/ja';
import React, { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  calendar: {
    width: 240,
    maxHeight: 276,
    overflow: 'hidden',
    '& .MuiPickersDatePickerRoot-toolbar': {
      display: 'none',
    },
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      minWidth: 'auto',
    },
    '& .MuiPickersBasePicker-pickerView': {
      minWidth: 'auto',
      minHeight: 'auto',
    },
    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: 'auto',
      margin: '0 0 8px 0',
      '& > div': {
        position: 'relative',
      },
    },
    '& .MuiPickersDay-day': {
      width: 32,
      height: 32,
      margin: 0,
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      width: 32,
      margin: 0,
      fontSize: 13,
      fontWeight: 700,
      color: 'rgba(51, 51, 51, 0.6)',
    },
    '& .MuiPickersDay-daySelected': {
      background: theme.palette.primaryColor,
    },
    '& .MuiTypography-body1': {
      fontSize: 16,
      fontWeight: 700,
    },
    '& .MuiTypography-body2': {
      fontSize: 13,
    },
    '& .MuiPickersCalendarHeader-switchHeader': {
      padding: '0 18px',
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      padding: '8px 11px 8px 10px',
      '&:last-child': {
        transform: 'rotate(180deg)',
      },
      '& .MuiIconButton-label': {
        width: 0,
        height: 0,
        borderWidth: '5px 5px 5px 0',
        borderStyle: 'solid',
        borderColor: 'transparent #333 transparent transparent',
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
    },
  },
}));

export default function SortCalendar() {
  const {
    actionScheduleDate,
    setActionScheduleDate,
  } = useActionSchedule();

  const classes = useStyles();

  const targetHeader = document.getElementsByClassName('MuiPickersCalendarHeader-transitionContainer');

  const ymConversion = () => {
    const targetLbl = document.getElementsByClassName('MuiTypography-alignCenter');
    for (let i = 0; i < targetLbl.length; i += 1) {
      const text = targetLbl[i].textContent;
      const year = text.substr(-4);
      const month = text.replace((' ' + year), '');
      targetLbl[i].textContent = year + '年' + month;
    }

    for (let j = 0; j < targetHeader.length; j += 1) {
      targetHeader[j].style.opacity = '1';
    }
  };

  // 初回のみ
  const [id] = useState(0);
  useEffect(() => {
    // 年月の表示形式を調整
    ymConversion();
    // 年月変更時
    const targetBtn = document.getElementsByClassName('MuiPickersCalendarHeader-iconButton');
    for (let i = 0; i < targetBtn.length; i += 1) {
      targetBtn[i].addEventListener('click', () => {
        for (let j = 0; j < targetHeader.length; j += 1) {
          targetHeader[j].style.opacity = '0';
        }
        setTimeout(ymConversion, 100);
      }, false);
    }
  }, [id]);

  return (
    <Grid className={classes.calendar}>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={ja}
      >
        <DatePicker
          autoOk
          variant="static"
          openTo="date"
          value={actionScheduleDate}
          onChange={setActionScheduleDate}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
