import { useDispatch } from 'react-redux';
import smsTalkApi from '../../apis/sms/getSmsTalkApi';
import SmsTalkBody from '../../components/sms/parts/smsTalkBody';
import { getSmsTalkApi } from '../../store/sms/smsTalkSlice';

export default function SmsTalkBodyContainer(props) {
  const { smsCustomerTelId } = props;
  const dispatch = useDispatch();
  // GET
  const getSmsTalk = async (id) => {
    await smsTalkApi(id)
      .then(async (res) => {
        await dispatch(getSmsTalkApi(res.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <SmsTalkBody getSmsTalk={getSmsTalk} smsCustomerTelId={smsCustomerTelId} />
  );
}
