import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../common/calendar';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  textFieldContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    backgroundColor: '#fff',
    padding: 8,
    marginBottom: 8,
    '& .MuiGrid-container': {
      width: 200,
      justifyContent: 'flex-start',
      display: 'flex',
      flexWrap: 'nowrap',
      // alignItems: 'center',
      border: 'none',
      padding: 0,
      '& h6': {
        display: 'none',
        '& .makeStyles-reqired-67': {
          display: 'none',
        },
      },
      '& .makeStyles-ttl-114': {
      },
    },
  },
  label: {
    marginRight: 20,
  },
  textField: {
    '& .MuiInputBase-root': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '160%',
      '& input': {
        padding: 3,
      },
    },
  },
});

function DivisionMultipleDate(props) {
  const { dateMultiple } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const initialStyle = {
    width: dateMultiple.width ? dateMultiple.width : '100%',
  };
  const dataList = [
    {
      id: dateMultiple.id,
      label: ['', ''], // typeが'multiple'のときのみ配列
      require: dateMultiple.require,
      type: 'multiple',
      startState: dateMultiple.state.startAt,
      endState: dateMultiple.state.endAt,
      setState: dateMultiple.setState,
      readonly: false,
      inputType: 'date',
    },
  ];

  return (
    <Grid className={classes.textFieldContainer}>
      <span className={`${baseClasses.title6} ${classes.label}`}>{dateMultiple.label}</span>
      {dataList.map((array) => {
        return <Calendar key="divisionCalendar" data={array} style={initialStyle} />;
      })}
    </Grid>
  );
}

export default DivisionMultipleDate;
