import { useState, useEffect } from 'react';
import { Checkbox, makeStyles, Button, TableCell, TableRow, Typography } from '@material-ui/core';
import commonStyles from '../../styles';
import FilePopper from './filePopper';

const useStyles = makeStyles((theme) => ({
  row: {
    '&:hover': { background: `${theme.palette.primaryColor}29` },
  },
  cell: {
    padding: '4px 8px',
    position: 'relative',
    '&:not(:last-child)::after': {
      content: '""',
      display: 'inline-block',
      width: '1px',
      height: 'calc(100% - 8px)',
      background: '#C8C8C8',
      position: 'absolute',
      right: 0,
      top: '4px',
    },
  },
  checkbox: { padding: 0 },
  alertSuccess: {
    position: 'absolute',
    right: '0',
    top: '0',
    width: '300px',
  },
}));
export default function FileTableRow(props) {
  const { row, cells, checkedList, setCheckedList,
    updateFiles, downloadFiles, setshowAlert } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [isNotification, setIsNotification] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [nowhover, setNowhover] = useState({});

  const onMouse = (e, r) => {
    if (!e || !r) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(e);
    setNowhover(r);
  };

  const open = Boolean(anchorEl);

  const onMouseFunc = (e) => onMouse(e.currentTarget, row);

  const leaveMouseFunc = () => {
    onMouse(null, null);
  };

  const handleRowCheck = (e) => {
    setCheckedList((state) => {
      let tmp = [...state];
      if (e.target.checked) {
        tmp.push(row.shareFileStorageAttachmentId);
      } else {
        tmp = state.filter((v) => v !== row.shareFileStorageAttachmentId);
      }
      return tmp;
    });
  };

  const handleNotificationCheck = (e) => {
    const isNotification1 = e.target.checked;
    setIsNotification(isNotification1);
    updateFiles({
      fileStorages: [{
        shareFileStorageAttachmentId: row.shareFileStorageAttachmentId,
        isNotification: Number(isNotification1),
      }],
    });
  };

  useEffect(() => {
    setIsNotification(Boolean(row.isNotification));
  }, [row]);

  const handleClickFileName = () => {
    downloadFiles(row, true);
  };

  return (
    <TableRow className={classes.row} onMouseEnter={onMouseFunc} onMouseLeave={leaveMouseFunc}>
      <TableCell className={classes.cell}>
        <Checkbox
          color="primary"
          className={classes.checkbox}
          checked={checkedList.includes(row.shareFileStorageAttachmentId)}
          onChange={handleRowCheck}
        />
      </TableCell>
      {cells.map((c) => {
        let result = row[c.id];
        const day1 = new Date(new Date(row.createdAt).toDateString());
        const day2 = new Date(row.limitAt);
        let tmp;
        switch (c.id) {
          case 'isNotification':
            result = (
              <Checkbox
                color="primary"
                checked={isNotification}
                className={classes.checkbox}
                onChange={handleNotificationCheck}
              />
            );
            break;
          case 'directoryName':
            tmp = row.directoryName.match(/([^/]*)[^/]+$/);
            result = (
              <>
                <Button onClick={(event) => handleClickFileName(event)}>
                  {tmp ? tmp[0] : row.directoryName}
                </Button>
                {open && (
                  <FilePopper
                    row={nowhover}
                    setshowAlert={setshowAlert}
                    updateFiles={updateFiles}
                    downloadFiles={downloadFiles}
                  />
                )}
              </>
            );
            break;
          case 'fileSize':
            tmp = result / 1000000;
            result = `${tmp > 0.1 ? tmp.toFixed(1) : 0.1}MB`;
            break;
          case 'ratention':
            result = Math.round((day2 - day1) / 86400000) + '日間';
            break;
          default:
            break;
        }
        return (
          <TableCell className={classes.cell}>
            <Typography className={common.small}>{result}</Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}
