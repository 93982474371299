import React from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  alertTitleColor: {
    color: '#f44336',
    textAlign: 'center',
  },
  noticeTitleColor: {
    textAlign: 'center',
  },
  alertBack: {
    backgroundColor: 'rgb(253, 236, 234)',
  },
  userCustomButtonSave: {
    padding: '8px 22px',
    width: '74px',
    height: '40px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '12px 16px',
  },
  root: {
    backdropFilter: 'blur(5px)',
  },
  ttl: {
    textAlign: 'center',
  },
  body: {
    paddingBottom: 16,
  },
}));

// TODO: Use Alert -> 以下三行が必要です
// import { useDispatch } from 'react-redux';
// import { cantCloseAlertMessage } from 'store/eleCommon/customAlertMessage';
// エラーを出したい時に
// dispatch(cantCloseAlertMessage({
//   msgList: ['メッセージ'], // 必須
//   title: 'タイトル', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
// }));
function CantCloseMessage() {
  const classes = useStyles();
  const openAlert = useSelector((state) => state.cantCloseMessage.cantCloseState);

  return (
    <Dialog
      disableEscapeKeyDown
      open={openAlert.open}
      fullWidth
      maxWidth="sm"
      classes={{
        root: classes.root,
      }}
    >
      <DialogTitle className={classes.ttl}>
        {openAlert.title
          ? openAlert.title
          : '入力された内容に誤りがあります'}
      </DialogTitle>
      <DialogContent className={classes.body}>
        <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
          {openAlert.msgList.map((data) => (
            <div key={data}>
              <span style={{ width: '100%' }}>{data}</span>
              <br />
            </div>
          ))}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default CantCloseMessage;
