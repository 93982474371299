import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

export const simulatorRunningStatus = createSlice({
  name: 'simulatorRunningStatus',
  initialState: { isRunning: initialState },
  reducers: {
    setIsRunning: (state, data) => {
      state.isRunning = data.payload;
    },
  },
});

export const { setIsRunning } = simulatorRunningStatus.actions;

export default simulatorRunningStatus.reducer;
