import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Rating from '@material-ui/lab/Rating';
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import {
  rankSelect,
} from '../../../constants/customerMain';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { useUpdate } from '../../../containers/customerMain';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px 4px 8px',
    marginBottom: 6,
    height: 'auto',
    '& label': {
      fontSize: 22,
      display: 'flex',
      alignItems: 'center',
    },
  },
  lbl: {
    fontSize: 13,
    margin: '0 10px 0 0',
  },
  star: {
    position: 'relative',
    width: 118,
    height: '100%',
    [commonTheme.breakpoints.up('md')]: {
      minHeight: 20,
    },
  },
  starLbl: {
    background: '#fff',
    pointerEvents: 'none',
    height: '100%',
    minHeight: 23,
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [commonTheme.breakpoints.up('md')]: {
      position: 'absolute',
      zIndex: '2',
      top: '-2px',
      left: 0,
      width: '100%',
    },
  },
  starSelect: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&:nth-child(3n)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '13px 0 11px',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

const rating = [
  { no: 5 },
  { no: 4 },
  { no: 3 },
  { no: 2 },
  { no: 1 },
  { no: 0 },
];

function CustomerRank(props) {
  const { input } = props;
  const { current, update, isSp } = useUpdate(input.customer, [
    'customerId',
    'userLevel',
    'isSubPermanentResidency',
    'emailStatusCode',
  ]);
  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  let contents;

  // モーダル用
  const [open, setOpen] = useState(false);

  // const selectChange = (e) => {
  //   // setStar(Number(e.target.value));
  // };

  const selectChange = (e) => {
    const value = Number(e.target.value);
    const { name } = e.target;

    if (current[name] !== value) {
      const array = [0, 5];
      switch (value) {
        case 2:
          if (!array.includes(input.customerInfo.emailStatusCode)) {
            // 画面制御用のステート
            input.setCustomerInfo({ ...input.customerInfo, userLevel: value, emailStatusCode: 5 });
            // 更新処理
            update({ [name]: value, emailStatusCode: 5 }, true);
            dispatch(changeAlertMessage({
              msgList: [], // 必須
              title: 'メール許可を「代行もすべてOK」に変更しました。', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
              isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
            }));
          } else {
            // 画面制御用のステート
            input.setCustomerInfo({ ...input.customerInfo, userLevel: value });
            // 更新処理
            update({ [name]: value }, true);
          }
          break;
        case 3:
          if (!array.includes(input.customerInfo.emailStatusCode)) {
            dispatch(changeConfirmMessage({
              title: 'メール許可を「代行もすべてOK」に変更しますか？',
              msgList: ['「代行もすべてOK」に変更すると、自動で追客されます。'],
              buttons: [
                {
                  label: '代行も全てOK にする',
                  set: () => {
                    // 更新処理
                    update({ [name]: value, emailStatusCode: 5 }, true);
                    // 画面制御用のステート
                    input.setCustomerInfo({
                      ...input.customerInfo,
                      userLevel: value,
                      emailStatusCode: 5,
                    });
                  },
                  classes: baseClasses.buttonsSecondary,
                },
                {
                  label: 'しない',
                  set: () => {
                    // 更新処理
                    update({ [name]: value }, true);
                    // 画面制御用のステート
                    input.setCustomerInfo({
                      ...input.customerInfo,
                      userLevel: value,
                    });
                  },
                  classes: baseClasses.buttonsPrimary,
                },
              ],
            }));
          } else {
            // 更新処理
            update({ [name]: value }, true);
            // 画面制御用のステート
            input.setCustomerInfo({ ...input.customerInfo, userLevel: value });
          }
          break;
        default:
          // 更新処理
          update({ [name]: value }, true);
          // 画面制御用のステート
          input.setCustomerInfo({ ...input.customerInfo, userLevel: value });
          break;
      }
    }
  };

  const customerSelectList = {
    rankItem: {
      type: 'selectBack',
      name: 'userLevel',
      obj: rankSelect,
      state: current.userLevel,
      change: selectChange,
      // set: selectBlur,
    },
  };

  // SP 項目選択時に更新
  const onSelect = (val) => {
    selectChange({
      target: {
        name: 'userLevel',
        value: val,
      },
    });
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getUserLevelSp = () => {
    return (
      <Grid>
        {rating.map((data) => {
          return (
            <Button
              className={`${baseClasses.title3} ${classes.selectBtn} ${(Number(current.isSubPermanentResidency) === Number(data.no)) && 'select'}`}
              key={data.no}
              onClick={() => {
                onSelect(data.no);
                handleClose();
              }}
            >
              <Rating
                name={uuid()}
                value={data.no}
                size="large"
                className={classes.starLbl}
              />
            </Button>
          );
        })}
      </Grid>
    );
  };

  // SP 表示用
  const list = [
    {
      type1: 'button',
      type2: 'select',
      lbl: 'ランク',
      click: handleOpen,
      opn: open,
      onClose: handleClose,
      content: getUserLevelSp,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          <ListItem
            className={classes.listItem}
          >
            <Button
              className={classes.listBtn}
              onClick={handleOpen}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                ランク
              </span>
              <Rating
                name={uuid()}
                value={current.userLevel}
                size="large"
                className={classes.starLbl}
              />
            </Button>
            {input.editDialog(list[0])}
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={`${baseClasses.title6} ${classes.lbl}`}>ランク</Typography>
        <Grid className={classes.star}>
          <Rating
            name={uuid()}
            value={current.userLevel}
            size="large"
            className={classes.starLbl}
          />
          <Grid className={classes.starSelect}>
            {input.component(customerSelectList.rankItem)}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerRank);
