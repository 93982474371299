import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  Grid,
  InputLabel,
} from '@material-ui/core';
import commonStyles from '../../styles';
import InstructionListTableRow from './instructionListTableRow';
import SearchCell from './searchCell';
import CustomTablePagination from '../../eleCommon/customTablePagenation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
  },
  control: {
    position: 'absolute',
    top: '-16px',
    right: 0,
    display: 'flex',
    alignItems: 'center',
  },
  controlLbl: {
    color: 'rgba(51, 51, 51, 0.6)',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginRight: 8,
  },
  tablePagination: {
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-selectRoot': {
      margin: '0 40px 0 0',
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
      color: 'rgba(51, 51, 51, 0.6)',
    },
    '& .MuiSelect-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
      color: 'rgba(51, 51, 51, 0.6)',
    },
    '& .MuiIconButton-root': {
      padding: '0 5px',
    },
  },
  tableContainer: {
    width: 'calc(100vw - 240px - 70px)',
    height: 'calc(100vh - 190px)',
    overflow: 'auto',
    position: 'relative',
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  tableCellTh: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: 0,
    textAlign: 'left',
    borderBottom: '2px solid #8C8C8C',
    '&::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: 1,
      height: 24,
      position: 'absolute',
      top: 6,
      right: 0,
    },
    '& .MuiTextField-root': {
      borderTop: '2px solid #8C8C8C',
      borderRight: '1px solid #C8C8C8',
      boxSizing: 'borderBox',
    },
    '&:nth-child(1)': {
      width: 44,
      textAlign: 'left',
      '&::after': {
        content: 'none',
      },
      '& > .MuiGrid-root': {
        marginBottom: '-1px',
      },
    },
    '&:nth-child(2)': {
      width: 120,
    },
    '&:nth-child(3)': {
      width: 120,
      fontWeight: '700',
      textAlign: 'left',
    },
    '&:nth-child(4)': {
      width: 82,
    },
    '&:nth-child(8)': {
      textAlign: 'left',
    },
    '&:nth-child(9)': {
      width: 120,
      '&::after': {
        content: 'none',
      },
      '& p': {
        borderRight: 'none',
      },
      '& .MuiTextField-root': {
        borderRight: 'none',
      },
    },
    '& .MuiTableSortLabel-root': {
      padding: '6px 8px',
      '& .MuiSvgIcon-root': {
        fontSize: 12,
        color: `${theme.palette.primaryColor}!important`,
      },
    },
    '& > p': {
      background: '#F3F3F3',
      borderTop: '2px solid #8C8C8C',
      borderRight: '1px solid #C8C8C8',
      margin: 0,
      padding: '5px 8px',
    },
  },
  button: {
    background: '#F3F3F3',
    borderTop: '2px solid #8C8C8C',
    padding: '11px 0 12px 6px',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonIcon: {
    color: '#333',
    fontSize: 14,
    margin: '0 2px 0 0',
  },
  buttonTxt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  tableSortLabel: {
    display: 'block',
    padding: '9px 8px',
  },
  checkBox1: {
    padding: '4px 8px',
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTableHead(props) {
  const {
    classes,
    // tab,
    onSelectAllClick,
    numSelected,
    rowCount,
    initialParams,
  } = props;

  const baseClasses = commonStyles();
  const ascOrDesc = [
    { value: '', text: '　' },
    { value: 'asc', text: '昇順' },
    { value: 'desc', text: '降順' },
  ];

  const UPDATED_AT_SELECT = {
    menus: {
      0: '　',
      1: '昇順',
      2: '降順',
    },
    sort: ['0', '1', '2'],
  };

  const [headCells] = useState({
    cells: [
      { id: 'expiredAt', label: '期日', type: 'select', menus: ascOrDesc },
      { id: 'customerName', label: '顧客名', type: 'text', menus: {} },
      { id: 'importantCode', label: '重要度', type: 'select', menus: ascOrDesc },
      { id: 'directReportStatusCode', label: '状態', type: 'select', menus: ascOrDesc },
      { id: 'directUserName', label: '指示者', type: 'text', menus: {} },
      { id: 'directedUserName', label: '担当者', type: 'text', menus: {} },
      { id: 'content', label: '最終コメント', type: 'text', menus: {} },
      { id: 'updatedAt', label: '更新日', type: 'selectUpdatedAt', menus: {}, UPDATED_AT_SELECT },
    ],
  });

  const getHeader = () => {
    return (
      headCells.cells.map((headCell) => (
        <TableCell
          key={headCell.id}
          className={`${baseClasses.title4} ${classes.tableCellTh}`}
        >
          <InputLabel
            className={classes.tableSortLabel}
          >
            {headCell.label}
          </InputLabel>
          <SearchCell
            initial={headCell}
            initialParams={initialParams}
          />
        </TableCell>
      ))
    );
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell className={classes.tableCellTh}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            className={classes.checkBox1}
          />
          <Grid className={classes.button}>
            <Typography className={classes.buttonTxt}>検索</Typography>
          </Grid>
        </TableCell>
        {getHeader()}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const {
    tab,
    list,
    count,
    setChoice,
    resetRender,
    setResetRender,
    selected,
    setSelected,
    page,
    setPage,
    rowsPerPage,
    setRowPages,
  } = props;

  const classes = useStyles();
  const initialParams = useSelector((state) => state.directList.initialRequestParams);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.directId);
      setSelected(newSelecteds);
      // 選択件数
      setChoice(list.length);
      return;
    }
    setSelected([]);
    // 選択件数
    setChoice(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);

    // 選択件数
    setChoice(newSelected.length);
  };

  // 期日
  const now = new Date();
  const year = now.getFullYear();
  const month = ('00' + (now.getMonth() + 1)).slice(-2);
  const day = ('00' + now.getDate()).slice(-2);
  const currentDate = year + '/' + month + '/' + day;
  const date1 = new Date(currentDate);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid className={classes.control}>
          <Typography className={classes.controlLbl}>表示行数</Typography>
          <CustomTablePagination
            rowsPerPageOptions={[20, 40, 60]}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowPages}
            totalCount={count ?? 20}
          />
        </Grid>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              tab={tab}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={list.length}
              initialParams={initialParams}
            />
            <TableBody id="instructionListTablebody">
              {list
                .map((row, index) => {
                  const isItemSelected = isSelected(row.directId);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <InstructionListTableRow
                      key={row.directId}
                      row={row}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      date1={date1}
                      handleClick={handleClick}
                      tab={tab}
                      resetRender={resetRender}
                      setResetRender={setResetRender}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
