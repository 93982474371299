import { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core';
import useBaseValidation from './useBaseValidation';
import ErrorTooltip from './ErrorTooltip';
import commonStyles from '../../../styles';
import { classNames } from '../../../../commonFunction';

const useStyles = makeStyles({
  textField: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '160%',
      '& input': {
        padding: 3,
      },
    },
  },
});

/**
 * validation付き入力フィールドの基本形
 * @param {ReactNode} children - TextField
 * @param {function(value): any} [convertor=(value)=>value]
 *   - 入力値から余計な値を除く関数
 * @param {function(event): void} [onBlur=null]
 *   - 単項目チェック後に実行される関数
 * @param {function(event): void} [onFocus=null]
 *   - フォーカスイン時(入力前)に発火する関数
 * @param {function(value): Array<string>} [validator=null]
 *   - 現在の値が正しいかを評価する関数
 * @param {function(boolean): void} [errorCallback=null]
 *   - エラーメッセージの有無を引数に取るコールバック関数
 * @param {Object} - TextFieldに渡される追加のprops
 */
function BaseValidationField(props) {
  const {
    children,
    className = null,
    convertor = null,
    onBlur = null,
    onFocus = null,
    validator = null,
    errorCallback = null,
    inputRef = null,
    ...TextFieldProps
  } = props;
  const { ref, handleBlur, handleFocus, errorMessages } = useBaseValidation(
    convertor,
    onBlur,
    onFocus,
    validator,
    errorCallback,
    inputRef,
  );

  const baseClasses = commonStyles();
  const classes = useStyles();

  const textFieldWithCustomProps = cloneElement(children, {
    inputRef: ref,
    onBlur: handleBlur,
    onFocus: handleFocus,
    className:
      className || classNames(baseClasses.textField, classes.textField),
    ...TextFieldProps,
  });

  return (
    <ErrorTooltip
      open={errorMessages.length !== 0}
      errorMessages={errorMessages}
    >
      {textFieldWithCustomProps}
    </ErrorTooltip>
  );
}

export default BaseValidationField;
