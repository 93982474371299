import { useState, useEffect } from 'react';
import {
  AppBar,
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import PhraseListComponent from '../../components/cautionPhraseList/cautionPhraseList';
import commonStyles from '../../components/styles';
import layoutStyle from '../../components/styles/layout';

import getProhibitWordsApi from '../../apis/prohibitedWord/getProhibitedWords';
import registProhibitWordsApi from '../../apis/prohibitedWord/registProhibitWords';
import updateProhibitWordsApi from '../../apis/prohibitedWord/updateProhibitWords';
import deleteProhibitWordsApi from '../../apis/prohibitedWord/deleteProhibitWords';

const useStyles = makeStyles({
  inner: { maxWidth: '1200px', margin: 'auto', width: '100%' },
  header: {
    background: '#fff',
    padding: '16px calc(50% - 600px) 0',
  },
  body: {
    padding: '16px calc(50% - 600px) 0',
  },
  btn: { marginLeft: '16px' },
  appbar: {
    boxShadow: 'none',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
}

const getSafeValInObj = (obj, key, defaultValue) => {
  if (key in obj) return obj[key];
  console.log(obj, key);
  return defaultValue;
};

export default function CautionPhraseListContainer() {
  const common = commonStyles();
  const lCommon = layoutStyle();
  const classes = useStyles();

  const [wordObj, setWordObj] = useState({});
  const [tab, setTab] = useState(0); // 0: 注意語句 1: ホワイト語句

  // 一覧取得
  const getProhibitedWordFunc = (isWhiteWord = tab, isForce = false) => {
    if (isWhiteWord in wordObj && !isForce) return;
    getProhibitWordsApi({ isWhiteWord })
      .then((res) => {
        setWordObj({ ...wordObj, [isWhiteWord]: res.data.words });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRefresh = () => {
    getProhibitedWordFunc(tab, true);
    console.log('更新');
  };

  // 注意語句追加
  const registProhibitWordsFunc = (word) => {
    registProhibitWordsApi(word)
      .then((res) => {
        console.log(res);
        onRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 注意語句更新
  const updateProhibitWordFunc = (word) => {
    const { wordId } = word;
    updateProhibitWordsApi(wordId, word)
      .then((res) => {
        console.log(res);
        onRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 注意語句削除
  const deleteProhibitWordFunc = (word) => {
    const { wordId } = word;
    deleteProhibitWordsApi(wordId)
      .then((res) => {
        console.log(res);
        onRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabCahge = async (e, val) => {
    getProhibitedWordFunc(val);
    setTab(val);
  };

  useEffect(() => {
    getProhibitedWordFunc();
  }, []);

  return (
    <Grid>
      <Grid className={common.header}>
        <Grid container alignItems="center" className={lCommon.inner}>
          <Typography className={common.title2}>
            注意語句の設定
          </Typography>
          <Button
            className={`${common.buttonsSecondary} ${classes.btn}`}
            onClick={onRefresh}
          >
            <Refresh style={{ width: '16px' }} />
            更新
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.header}>
        <AppBar position="relative" className={classes.appbar}>
          <Tabs value={tab} onChange={handleTabCahge}>
            <Tab label="注意語句" {...a11yProps(0)} />
            <Tab label="ホワイト語句" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </Grid>
      <TabPanel value={tab} index={0} className={classes.body}>
        <PhraseListComponent
          words={getSafeValInObj(wordObj, tab, [])}
          onRegister={registProhibitWordsFunc}
          onUpdate={updateProhibitWordFunc}
          onDelete={deleteProhibitWordFunc}
        />
      </TabPanel>
      <TabPanel value={tab} index={1} className={classes.body}>
        <PhraseListComponent
          words={getSafeValInObj(wordObj, tab, [])}
          onRegister={registProhibitWordsFunc}
          onUpdate={updateProhibitWordFunc}
          onDelete={deleteProhibitWordFunc}
          isWhiteWord={1}
        />
      </TabPanel>
    </Grid>
  );
}
