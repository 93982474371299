/* eslint-disable import/prefer-default-export */
import { Checkbox } from '@material-ui/core';

export const getChildField = (data, checked = false) => {
  switch (data.inputType) {
    case 'checkbox':
      return <Checkbox disabled checked={checked} onChange={() => {}} />;
    case 'textarea':
      return (
        <textarea
          disabled
          value={data.childContent}
          style={{
            width: '100%',
            height: '100%',
            background: 'transparent',
            border: 'none',
            resize: 'none',
          }}
        />
      );
    case 'text':
      return (
        <input
          disabled
          value={data.childContent}
          style={{
            width: '100%',
            background: 'transparent',
            border: 'none',
          }}
        />
      );
    default:
      if (data.label === '担当者') {
        if (data.childDisplayName === 0) {
          return null;
        }
      }
      return data.childDisplayName !== undefined
        ? data.childDisplayName
        : data.childContent;
  }
};
