import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InstructionReport from '../../../components/common/instructionReport/instructionReport';
import directTupdateApi from '../../../apis/direct/directTupdateApi';
import directInfo from '../../../apis/direct/directGetInforApi';
import getHeaderNumberApi from '../../../apis/common/commonGetHeaderNumberApi';
import { commonGetHeaderNumberApi } from '../../../store/common/commonGetHeaderNumberSlice';

function InstructionReportContainers(props) {
  const { customer } = props;
  const dispatch = useDispatch();

  // 【direct006】指示情報取得（顧客単位）
  const [informations, setInformations] = useState({
    totalDirects: 0,
    totalReports: 0,
    totalCompleate: 0,
    directs: [],
  });
  const [directTabCode, setTabCode] = useState(1);
  const [directId, setDirectId] = useState(null);

  /**
   * @module getDirects - 対象の顧客の指示一覧を取得
   * @param {integer} requestTabCode - directTabCode
   */
  const getDirects = async (requestTabCode) => {
    // 一旦指示一覧をReset
    setInformations({
      totalDirects: 0,
      totalReports: 0,
      totalCompleate: 0,
      directs: [],
    });

    const params = {
      /* eslint no-unneeded-ternary: 'error' */
      directTabCode: requestTabCode ? requestTabCode : directTabCode,
      directId,
    };

    // 【direct006】指示情報取得（顧客単位）
    await directInfo(customer.customerId, params)
      .then(async (res) => {
        setInformations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 顧客IDが変わる毎に指示取得
  useEffect(() => {
    getDirects();
  }, [customer]);

  // タイムライン登録 【direct005】
  const createTimeline = async (directIdParam, params) => {
    await directTupdateApi(directIdParam, params)
      .then(() => getDirects())
      .catch((err) => {
        console.log(err);
      });
  };

  // ヘッダー数字再取得
  const headerReload = async () => {
    await getHeaderNumberApi()
      .then((res) => dispatch(commonGetHeaderNumberApi(res.data)))
      .catch((err) => console.error(err));
  };

  return (
    <InstructionReport
      informations={informations}
      createTimeline={createTimeline}
      setTabCode={setTabCode}
      setDirectId={setDirectId}
      getDirects={getDirects}
      headerReload={headerReload}
    />
  );
}

export default InstructionReportContainers;
