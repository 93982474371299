import ProspectsListContainers from '../../containers/prospectsList/prospectsListContainers';

function ProspectsList() {
  return (
    <div>
      <ProspectsListContainers />
    </div>
  );
}

export default ProspectsList;
