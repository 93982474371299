import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, List, Typography,
} from '@material-ui/core';
import CustomerInfoSp from '../../../common/customer/customerInfo/customerInfoSp';
import InstructionListBlockItemSp from './instructionListBlockItemSp';
import InstructionReportWindowSp from '../../../common/instructionReport/sp/instructionReportWindowSp/index';

const useStyles = makeStyles({
  list: {
    padding: 0,
  },
  noneItem: {
    padding: '60px 16px',
    textAlign: 'center',
  },
});

export default function InstructionListBlockSp(props) {
  const {
    tab,
    id1,
    id2,
    list,
    resetRender,
    setResetRender,
  } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);

  // 表示するレコードを絞り込み
  const rows = list.filter((item) => {
    return item.directReportStatusCode === id1 || item.directReportStatusCode === id2;
  });
  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  // 顧客詳細呼び出し用
  const [openCustomer, setOpenCustomer] = useState(false);

  // 顧客詳細画面Close
  const onCloseCustomer = () => {
    setOpenCustomer(false);
  };

  const displayCustomerDetail = (customerId) => {
    return (
      <CustomerInfoSp
        customerId={customerId}
        open={openCustomer}
        setOpen={setOpenCustomer}
        onClose={onCloseCustomer}
      />
    );
  };

  // 期日
  const now = new Date();
  const year = now.getFullYear();
  const month = ('00' + (now.getMonth() + 1)).slice(-2);
  const day = ('00' + now.getDate()).slice(-2);
  const currentDate = year + '/' + month + '/' + day;
  const date1 = new Date(currentDate);

  return (
    <>
      <List className={classes.list} id="instructionListTablebody">
        {rows.length === 0 ? (
          <Grid className={classes.noneItem}>
            <Typography><p>現在、指示はありません</p></Typography>
          </Grid>
        ) : (
          rows.map((row) => {
            return (
              <InstructionListBlockItemSp
                key={row.directId}
                row={row}
                // isItemSelected={isItemSelected}
                date1={date1}
                handleClick={handleClick}
                tab={tab}
                open={open}
                setOpen={setOpen}
                setSelectRow={setSelectRow}
              />
            );
          })
        )}
      </List>
      {open ? (<InstructionReportWindowSp
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        customerId={selectRow.customerId}
        tab={tab}
        row={selectRow}
        setRow={setSelectRow}
        resetRender={resetRender}
        setResetRender={setResetRender}
        getDirects={() => {
          // console.log('getDirects');
          // 変更して戻った場合に、更新が必要なため以下で強制レンダリング
          setResetRender(resetRender + 1);
        }}
        displayCustomerDetail={displayCustomerDetail}
        openCustomer={openCustomer}
        setOpenCustomer={setOpenCustomer}
        visibleFooter
      />) : null}
    </>
  );
}
