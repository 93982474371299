import { createSlice } from '@reduxjs/toolkit';

export const membersGetNotificationsSlice = createSlice({
  name: 'membersGetNotifications',
  initialState: { total: 0, notifications: [] },
  reducers: {
    setMembersGetNotifications: (state, data) => {
      const { total, notifications } = data.payload;
      state.total = total;
      state.notifications = [...state.notifications, ...notifications];
    },
    resetMembersGetNotifications: (state, data) => {
      const { total, notifications } = data.payload;
      state.total = total;
      state.notifications = notifications;
    },
  },
});

export const { setMembersGetNotifications } = membersGetNotificationsSlice.actions;
export const { resetMembersGetNotifications } = membersGetNotificationsSlice.actions;
export default membersGetNotificationsSlice.reducer;
