import { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid, Tabs, Tab, makeStyles } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import commonTheme from '../../styles/theme';
import { useCityJisCodesContext } from './context';

import SearchInput from '../common/searchInput';
import CheckboxList from '../common/checkboxList';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
    '& .MuiTabs-flexContainer': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontSize: 13,
    },
  },
  panel: {
    background: '#fff',
    position: 'absolute',
    top: 123,
    bottom: 24,
    left: 24,
    right: 24,
    overflowY: 'auto',
    padding: 20,
  },
  icon: {
    fill: '#666',
    fontSize: 15,
  },
}));

export default function Content() {
  const {
    areaList, cityJisCodes, onChange, defaultSelected,
  } = useCityJisCodesContext();

  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const [focusedCities, setFocusedCities] = useState(defaultSelected);

  const areaCode = useMemo(() => {
    return areaList[index]?.areaCode;
  }, [areaList, index]);

  const handleChange = useCallback((e, val) => {
    setIndex(val);
  }, []);

  const cityJisList = cityJisCodes[areaCode];

  // 検索時、対象が一番上になるようにスクロール
  useEffect(() => {
    if (focusedCities !== defaultSelected) {
      const target = document.getElementById(focusedCities[0].id);
      target?.scrollIntoView();
    }
  }); [focusedCities];

  return (
    <>
      <Grid>
        <SearchInput list={cityJisList} setSearchResult={setFocusedCities} />
      </Grid>
      <Grid>
        <Tabs value={index} onChange={handleChange} className={classes.root}>
          {areaList.map((item) => (
            <Tab key={item.areaCode} label={item.areaName} />
          ))}
        </Tabs>
        <Grid className={classes.panel}>
          <CheckboxList
            list={cityJisList}
            onChange={onChange}
            icon={<Room className={classes.icon} />}
            defaultSelected={focusedCities}
            clearCount={0}
          />
        </Grid>
      </Grid>
    </>
  );
}
