import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormGroup-root': {
      '& label': {
        marginLeft: 0,
        marginRight: 8,
      },
      '& .MuiTypography-root': {
        fontFamily: 'Roboto',
        fontSize: 13,
        lineHeight: 1.6,
        letterSpacing: 0,
      },
      '& .MuiButtonBase-root': {
        padding: 4,
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& .MuiIconButton-label .MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
  },
}));

export default function CustomRadioGroup(props) {
  const { initial } = props;
  const menusKeys = Object.keys(initial.obj.menus);

  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.root}>
      <RadioGroup
        name="radios"
        value={initial.state}
        onChange={(e) => {
          initial.change(e, initial);
        }}
      >
        {menusKeys.map((data) => (
          <FormControlLabel
            checked={data === String(initial.state)}
            key={data}
            value={data}
            control={<Radio />}
            label={initial.obj.menus[data]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
