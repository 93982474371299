import { useMemo } from 'react';
import {
  makeStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import { joinBySlash } from '../../../commonFunction';
import FloatButtonGroup from './floatButtonGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .floatButtonGroupHook': {
        opacity: 1,
      },
    },
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
  },
}));

export default function Row(props) {
  const {
    row,
    index,
    onDelete,
    onEdit,
  } = props;

  const classes = useStyles();

  const buttonItems = useMemo(() => {
    const arr = [
      {
        label: '編集',
        icon: <EditOutlinedIcon />,
        onClick: (e) => onEdit(e, row),
      },
    ];
    // if (row.isDelete) {
    arr.push({
      label: '削除',
      icon: <DeleteOutlineIcon />,
      onClick: (e) => onDelete(e, row),
    });
    // }
    return arr;
  }, [onEdit, onDelete, row]);

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.root}
    >
      <TableCell>
        {index + 1}
      </TableCell>
      <TableCell>
        {row.targetCityName}
      </TableCell>
      <TableCell>
        {row.patternName}
      </TableCell>
      <TableCell>
        {joinBySlash(row.storeGroupNamesPatternTypeCode1)}
      </TableCell>
      <TableCell align="center">
        {row.pricePatternTypeCode2}
      </TableCell>
      <TableCell>
        {joinBySlash(row.storeGroupNamesPatternTypeCode2)}
      </TableCell>
      <TableCell align="center">
        {row.pricePatternTypeCode3}
      </TableCell>
      <TableCell>
        {joinBySlash(row.storeGroupNamesPatternTypeCode3)}
      </TableCell>
      <TableCell>
        {joinBySlash(row.storeGroupNamesPatternTypeCode4)}
      </TableCell>
      <TableCell>
        {row.displayOrder}
        <FloatButtonGroup
          items={buttonItems}
        />
      </TableCell>
    </TableRow>
  );
}
