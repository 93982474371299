import { Select, MenuItem, makeStyles } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import positionChange from '../../commonFunction/positionChange';

const useStyles = makeStyles((theme) => ({
  select: {
    height: 20,
  },
  openEvent: {
    '&>.MuiSelect-select.Mui-disabled': {
      cursor: 'pointer',
    },
    '&.MuiInput-underline.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      cursor: 'pointer',
    },
    '&:hover.MuiInput-underline.Mui-disabled:before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '&>.MuiSelect-icon.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

function CustomSidebarSelect(props) {
  const classes = useStyles();
  const { initial } = props;
  const menusKeys = Object.keys(initial.obj.menus);

  const controlDisabled = (data) => {
    let result = false;

    // rankMin/rankMax(ランク)
    if (initial.key === 'rankMin' && initial.searchConditions.rankMax) {
      result = Boolean(Number(data) > Number(initial.searchConditions.rankMax));
    }
    if (initial.key === 'rankMax' && initial.searchConditions.rankMin) {
      return Boolean(Number(data) < Number(initial.searchConditions.rankMin));
    }
    // userLevelMin/userLevelMax(ユーザーランク)
    if (initial.key === 'userLevelMin' && initial.searchConditions.userLevelMax) {
      result = Boolean(Number(data) > Number(initial.searchConditions.userLevelMax));
    }
    if (initial.key === 'userLevelMax' && initial.searchConditions.userLevelMin) {
      return Boolean(Number(data) < Number(initial.searchConditions.userLevelMin));
    }

    // 案内回数
    if (initial.key === 'guidanceCountMin' && initial.searchConditions.guidanceCountMax) {
      result = Boolean(Number(data) > Number(initial.searchConditions.guidanceCountMax));
    }
    if (initial.key === 'guidanceCountMax' && initial.searchConditions.guidanceCountMin) {
      result = Boolean(Number(data) < Number(initial.searchConditions.guidanceCountMin));
    }

    // 予算
    if (initial.key === 'budgetMin' && initial.searchConditions.budgetMax) {
      result = Boolean(Number(data) > Number(initial.searchConditions.budgetMax));
    }
    if (initial.key === 'budgetMax' && initial.searchConditions.budgetMin) {
      result = Boolean(Number(data) < Number(initial.searchConditions.budgetMin));
    }

    return result;
  };

  switch (`${initial.set.name}`) {
    case 'handleClick':
      return (
        <Select
          className={`${classes.select} ${classes.openEvent}`}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={initial.state}
          defaultValue=""
          disabled
          onClick={() => {
            initial.set(initial);
          }}
          label={initial.obj.title}
          onOpen={positionChange}
        />
      );
    default:
      switch (`${initial.key}`) {
        case 'guidanceType':
          return (
            <Select
              className={`${classes.select} ${classes.openEvent}`}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={initial.state}
              defaultValue=""
              disabled
              onClick={() => {
                initial.set(initial);
              }}
              label={initial.obj.title}
              onOpen={positionChange}
            />
          );
        default:
          return (
            <Select
              className={classes.select}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={initial.state}
              defaultValue=""
              onChange={(e) => {
                initial.set(e, initial);
              }}
              label={initial.obj.title}
              onOpen={positionChange}
            >
              <MenuItem
                value=""
              >
                &nbsp;
              </MenuItem>
              {menusKeys.map((data) => (
                <MenuItem
                  value={data}
                  defaultValue=""
                  key={data}
                  disabled={controlDisabled(data)}
                >
                  {initial.obj.menus[data]}
                </MenuItem>
              ))}
            </Select>
          );
      }
  }
}

export default CustomSidebarSelect;
