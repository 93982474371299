import client from '../httpClient';

// 【customer010】顧客反響履歴一覧取得
const customerResponsesApi = async (customerId, skipAuthorityCheck) => {
  /**
   * @param {string} customerId
   */
  const params = { skipAuthorityCheck };
  const result = await client.get(`/customers/responses/${customerId}`, { params });
  return result;
};

export default customerResponsesApi;
