import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '../../../commonFunction';
import useInterval from '../../../hooks/useInterval';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { setIsRunning } from '../../../store/simulator/simulatorRunningStatusSlice';
import commonStyles from '../../styles';
import simulatorCSVUploadStatusApi from '../../../apis/simulator/simulatorCSVUploadStatusApi';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    width: 'auto',
  },
  headerTtl: {
    color: theme.palette.primaryColor,
    fontWeight: 'bold',
    padding: '16px 16px 8px',
  },
}));

function SimulatorCheckFile(props) {
  const { downloadFunc } = props;

  const classes = useStyles();
  // ファイルアップロード後10秒毎にAPIを叩く仕様
  const delay = useRef(10000);
  const dispatch = useDispatch();
  const common = commonStyles();
  // 下記で現在ステータス待機中か判定
  const isRunning = useSelector(
    (state) => state.simulatorRunningStatus.isRunning,
  );

  useEffect(() => {
    const isDownloadNow = getLocalStorage(
      'IS_DOWNLOADING_SIMULATOR_FILE',
      false,
    );
    if (isDownloadNow === true) dispatch(setIsRunning(true));
  }, [isRunning]);

  const checkFileStatus = async () => {
    // eslint-disable-next-line max-len
    // FIXME:下記を「成功、失敗、待機中」の三つで分岐必要。成功と失敗ならsetLocalStorage('IS_DOWNLOADING_SIMULATOR_FILE', false);
    const receptionId = getLocalStorage('SIMULATOR_FILE_RECEPTION_ID');
    const status = await simulatorCSVUploadStatusApi(receptionId)
      .then((res) => {
        switch (res.data.result) {
          case 0: // failure
            removeLocalStorage('SIMULATOR_FILE_RECEPTION_ID');
            setLocalStorage('IS_DOWNLOADING_SIMULATOR_FILE', false);
            dispatch(setIsRunning(false));
            return {
              msgList: ['反響シミュレーションが失敗しました。'],
              title: '結果',
              buttons: [
                {
                  label: '閉じる',
                  set: () => {},
                  classes: common.buttonsSecondary,
                  onBackdropClick: null,
                },
              ],
            };
          case 1: // success
            removeLocalStorage('SIMULATOR_FILE_RECEPTION_ID');
            setLocalStorage('IS_DOWNLOADING_SIMULATOR_FILE', false);
            dispatch(setIsRunning(false));
            return {
              msgList: ['反響シミュレーションが完了しました。'],
              title: '結果',
              buttons: [
                {
                  label: '結果ダウンロード',
                  set: () => downloadFunc(receptionId),
                  classes: common.buttonsSecondary,
                  onBackdropClick: null,
                },
              ],
            };
          case 2:
          default:
            return null;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return status;
  };

  // 10秒毎に下記hooksが発火 -- 成功なら文言を完了に、失敗なら文言を失敗に変更する必要あり。
  useInterval(
    async () => {
      if (getLocalStorage('IS_DOWNLOADING_SIMULATOR_FILE', false) === true) {
        const checkStatus = await checkFileStatus();
        if (checkStatus) {
          dispatch(changeConfirmMessage(checkStatus));
        }
      }
    },
    isRunning ? delay.current : null,
  );

  return (
    <Grid className={classes.root}>
      <Typography className={classes.headerTtl}>
        {isRunning ? 'シミュレーション中...' : ''}
      </Typography>
    </Grid>
  );
}

export default SimulatorCheckFile;
