import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  section: {
    width: '100%',
    backgroundColor: '#fff',
    border: 'solid 1px #C8C8C8',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 30,
    padding: 8,
    marginBottom: 8,
    boxSizing: 'border-box',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  label: {
    minWidth: 60,
    whiteSpace: 'nowrap',
  },
  button: {
    '&:disabled': {
      opacity: 0.5,
    },
  },
  text: {
    color: theme.palette.primaryColor,
    textAlign: 'center',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  block: {
    display: 'block',
  },
}));

export default useStyles;
