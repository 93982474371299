import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { useUpdate } from '../../../containers/customerMain';
import inputNumber from '../../../commonFunction/inputNumber';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { validateFormInt1, validateFormFloat1 } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 6,
  },
  textField: {
    display: 'block',
    '& label': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [commonTheme.breakpoints.up('md')]: {
        fontFamily: 'Roboto',
        color: '#333',
        right: 0,
        margin: '0 auto',
        textAlign: 'center',
        transformOrigin: 'top center',
        fontSize: 13,
        lineHeight: 1.2,
        transform: 'translate(0, 2px) scale(1)',
        position: 'relative',
        letterSpacing: 0,
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'center',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      [commonTheme.breakpoints.down('sm')]: {
        textAlign: 'right',
      },
    },
    '& div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
});

const inputs = [
  {
    label: '本人年収（万円）',
    name: 'annualIncome',
    validator: validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 }),
    convertor: convertFormNumber,
  },
  {
    label: '合算者（万円）',
    name: 'subAnnualIncome',
    validator: validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 }),
    convertor: convertFormNumber,
  },
  {
    label: '頭金（万円）',
    name: 'deposit',
    validator: validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 }),
    convertor: convertFormNumber,
  },
  {
    label: '援助（万円）',
    name: 'assistAmount',
    validator: validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 }),
    convertor: convertFormNumber,
  },
  {
    label: '月々支払（万円）',
    name: 'monthlyPayment',
    validator: validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 }),
    convertor: convertFormNumber,
  },
  {
    label: 'ボーナス（万円）',
    name: 'bonusPayment',
    validator: validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 }),
    convertor: convertFormNumber,
  },
  {
    label: '現在の家賃（万円）',
    name: 'abodeNowRent',
    validator: validateFormFloat1({
      minFloat: 0.0,
      maxFloat: 99999.0,
      precisionInt: 4,
    }),
    convertor: convertFormNumber,
  },
];

function CustomerInfo1(props) {
  const { input } = props;
  const {
    current,
    original,
    update,
    isSp,
  } = useUpdate(input.customer);

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  let contents;

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    const { name, value } = e.target;
    if ((original[name] || '') === value) return;
    // 更新処理
    update({ [name]: value });
  };
  const getTextField = (field, validator, convertor) => {
    return (
      <TextBaseField
        defaultValue={current[field]}
        className={`${classes.textField}`}
        name={field}
        onBlur={onBlur}
        placeholder="-"
        validator={validator}
        convertor={convertor}
      />
    );
  };
  // SP 表示用
  const list = inputs.map((item) => {
    return {
      ...item,
      lbl: item.label,
      type1: 'noModal',
      type2: 'number',
      val: current[item.name],
      fontSize: '16px',
      content: () => { return getTextField(item.name, item.validator, item.convertor); },
    };
  });

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          予算情報
        </Typography>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.name}
                className={classes.listItem}
              >
                {input.parts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        {inputs.map((item) => (
          <Grid className={classes.box} key={item.name}>
            <TextBaseField
              label={item.label}
              defaultValue={current[item.name]}
              className={classes.textField}
              type="number"
              inputProps={{ min: '0' }}
              name={item.name}
              onBlur={onBlur}
              validator={item.validator}
              convertor={item.convertor}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerInfo1);
