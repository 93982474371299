import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserTree from '../../common/userTreeNew';

import store from '../../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '4px 8px',
    width: 136,
    '& .MuiInput-root:hover': { backgroundColor: `${theme.palette.primaryColor}29` },
  },
  textField: {
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: '5px 5px 0',
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, 0.54) transparent',
      position: 'absolute',
      top: 24,
      right: 6,
      pointerEvents: 'none',
    },
    '&>.MuiInputLabel-root': {
      fontSize: 13,
      top: '-12%',
      color: '#333',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 7px) scale(0.85)',
      },
    },
    '& .MuiInput-input': {
      height: 20,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: 13,
      pointerEvents: 'none',
    },
  },
}));

function SearchButton(props) {
  const { initial } = props;
  const { divisionId, userId } = initial.state;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { responseHeader } = store.getState();

  const [selectUser, setSelectUser] = useState({
    userId: responseHeader.userId,
    userName: responseHeader.userName,
  });
  const [selectDivision, setSelectDivision] = useState({});

  const handleSelectFunction = () => {
    if (!selectUser?.userId && !selectDivision?.divId) return;
    // 組織
    if (!selectUser?.userId && selectDivision?.divId) {
      initial.setState({
        ...initial.state,
        divisionId: selectDivision.divId,
        userId: '',
      });
      initial.setDisplayName(selectDivision.divisionName);
      return;
    }
    // 担当者
    if (selectUser?.userId) {
      initial.setState({
        ...initial.state,
        divisionId: '',
        userId: selectUser.userId,
      });
      initial.setDisplayName(selectUser.userName);
    }
  };

  const handleClearFunction = () => {
    initial.setState({
      ...initial.state,
      divisionId: '',
      userId: '',
    });
  };

  useEffect(() => {
    if (divisionId || userId) {
      initial.search();
    }
    if (!divisionId && !userId) {
      initial.setDisplayName('');
    }
  }, [divisionId, userId]);

  useEffect(() => {
    setSelectUser({
      userId: responseHeader.userId,
      userName: responseHeader.userName,
      divisionId: responseHeader?.mainDivision.divisionId,
      divisionName: responseHeader?.mainDivision.divisionName,
    });
  }, [open]);

  return (
    <>
      <Grid className={classes.root} onClick={() => setOpen(true)}>
        <TextField
          className={classes.textField}
          fullWidth
          label={initial.title}
          value={divisionId || userId ? initial.displayName : ''}
        />
      </Grid>
      <UserTree
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        isOpenTree={open}
        setIsOpenTree={setOpen}
        clickableUserAndDivision
        handleSelectFunction={handleSelectFunction}
        handleClearFunction={handleClearFunction}
        isDialog
      />
    </>
  );
}

export default SearchButton;
