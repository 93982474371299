import React from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import omit from '../../../commonFunction/omit';
import EmptyAlert from './emptyAlert';
// eslint-disable-next-line import/no-cycle
import Customer from './customer';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: '#F3F3F3',
    '& .MuiAccordionDetails-root': {
      display: 'block',
      padding: 0,
    },
  },
  bodyContent: {
    '& .MuiTable-root': {
      background: '#fff',
      border: 'solid 1px C8C8C8',
    },
  },
  tableContainer: {
    maxHeight: 147,
    border: 'solid 1px #C8C8C8',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .material-icons': {
      color: '#D83420',
      fontSize: 18,
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  headerLinkTxt: {
    color: theme.palette.primaryColor,
  },
  buttonFlex: {
    display: 'flex',
    flexDirection: 'flex-start',
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
      '& .MuiIconButton-label': {
        color: theme.palette.primaryColor,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  toggleButton: {
    padding: 0,
    textAlign: 'left',
    display: 'block',
    minWidth: 10,
  },
  tableHead: {
    '& th': {
      backgroundColor: '#fff',
    },
  },
  headerCell: {
    color: '#8C8C8C',
    textAlign: 'center',
    borderBottom: 'solid 2px #C8C8C8',
    padding: '12px 0 6px 0',
  },
  bodyTableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '& td': {
      fontSize: 13,
      textAlign: 'center',
      padding: '5px 0',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
  },
  telAndMailCell: {
    width: '4%',
  },
}));

const headerCells = [
  { id: 'number', label: 'No' },
  { id: 'telCount', label: '架電' },
  { id: 'mailCount', label: 'メール' },
  { id: 'lastGuidedA', label: '前回案内' },
  { id: 'nextGuidedA', label: '次回案内' },
  { id: 'customer', label: '顧客' },
  { id: 'effectAt', label: '反響日' },
  { id: 'resPerson', label: '担当' },
  { id: 'largeResponseTypeName', label: '媒体(大)' },
  { id: 'mediumResponseTypeName', label: '媒体(中)' },
  { id: 'smallResponseTypeName', label: '媒体(小)' },
  { id: 'fineResponseTypeName', label: '媒体(細)' },
];

const largeResStyles = {
  width: '6%',
  textAlign: 'left',
};

function CorrespondenceList(props) {
  const { dataList } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { customers = [], total = 0 } = dataList.data || {};

  const tableBody = (
    <TableBody>
      {customers.map((data, index) => {
        let lastGuidedAt = '';
        if (data.lastGuidedAt !== null) {
          const lastGuidedAtParts = data.lastGuidedAt.split(':');
          lastGuidedAt = lastGuidedAtParts[0] + ':' + lastGuidedAtParts[1];
        }
        let nextGuidedAt = '';
        if (data.nextGuidedAt !== null) {
          const nextGuidedAtParts = data.nextGuidedAt.split(':');
          nextGuidedAt = nextGuidedAtParts[0] + ':' + nextGuidedAtParts[1];
        }

        let effectAt = '';
        if (data.effectAt !== null) {
          const effectAtParts = data.effectAt.split(' ');
          effectAt += effectAtParts[0];
        }
        return (
          <TableRow
            className={classes.bodyTableRow}
            style={data.telCount === 0 || data.mailCount === 0 ? { backgroundColor: 'rgba(216, 52, 32, 0.08)' } : {}}
            key={String(index)}
            hover
          >
            <TableCell style={{ width: '3%' }}>{index + 1}</TableCell>
            <TableCell style={data.telCount === 0 ? { color: '#FF0000', width: '3%' } : { width: '3%' }} className={classes.telAndMailCell}>{data.telCount}</TableCell>
            <TableCell style={data.mailCount === 0 ? { color: '#FF0000', width: '3%' } : { width: '3%' }} className={classes.telAndMailCell}>{data.mailCount}</TableCell>
            <TableCell style={{ width: '6%' }}>{lastGuidedAt}</TableCell>
            {/* <TableCell style={{ width: '6%' }}>{data.lastGuidedAt}</TableCell> */}
            <TableCell style={{ width: '6%' }}>{nextGuidedAt}</TableCell>
            <Customer
              id={data.customerId}
              fullName={`${[data.customerLastName, data.customerFirstName].filter(Boolean).join(' ')}`}
              rating={data.userLevel}
              status={data.memberStatusCode}
            />
            <TableCell>{effectAt}</TableCell>
            <Tooltip title={`${data.divisionSubName} ${data.userLastName}${data.userFirstName}`}>
              <TableCell style={{ width: '15%' }}>
                {omit(data.divisionSubName, 8)} {data.userLastName}{data.userFirstName}
              </TableCell>
            </Tooltip>
            <Tooltip title={data.largeResponseTypeName}>
              <TableCell style={largeResStyles}>{omit(data.largeResponseTypeName, 7)}</TableCell>
            </Tooltip>
            <Tooltip title={data.mediumResponseTypeName}>
              <TableCell style={largeResStyles}>{omit(data.mediumResponseTypeName, 7)}</TableCell>
            </Tooltip>
            <Tooltip title={data.smallResponseTypeName}>
              <TableCell style={largeResStyles}>{omit(data.smallResponseTypeName, 7)}</TableCell>
            </Tooltip>
            <Tooltip title={data.fineResponseTypeName}>
              <TableCell style={largeResStyles}>{omit(data.fineResponseTypeName, 7)}</TableCell>
            </Tooltip>
          </TableRow>
        );
      })}
    </TableBody>
  );

  const body = (
    <TableContainer className={`${classes.tableContainer} dashbordScrollArea`}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headerCells.map((headerCell) => {
              return (
                <TableCell
                  className={`${baseClasses.title4} ${classes.headerCell} nowrap`}
                  key={headerCell.id}
                  colSpan={headerCell.id === 'customer' ? 3 : 1}
                >
                  {headerCell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
      {!total && <EmptyAlert />}
    </TableContainer>
  );
  return (
    <Accordion
      className={classes.accordion}
      defaultExpanded
    >
      <Grid className={classes.headerContainer}>
        <Grid className={classes.buttonFlex}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.toggleButton} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          />
          <Grid className={classes.buttonContainer}>
            <Typography className={baseClasses.title3}>
              直近反響対応状況 ({total})
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Link href={`${URL_MAPPER.customer}`}>
            <Typography className={`${baseClasses.smallStrong} ${classes.headerLinkTxt}`}>
              顧客メインで表示
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <AccordionDetails>
        <Grid className={classes.bodyContent}>
          {body}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default CorrespondenceList;
