import React, { useState } from 'react';
import localStyles from './localStyle';
import commonStyles from '../../../styles';

function CalendarInputMultiTime(props) {
  const style = localStyles();
  const common = commonStyles();
  const val = props;

  // // ローカルステート
  let defaultDate = val.start;
  let defaultValue = defaultDate;
  let defaultStartTime = defaultDate;
  let defaultEndTime = val.end;
  if (defaultDate.indexOf('/') !== -1 && defaultDate.indexOf(':') !== -1) {
    defaultDate = defaultDate.substr(0, 10);
    defaultValue = defaultValue.substr(0, 10).replace(/\//g, '-').replace(/ /g, 'T');
    defaultStartTime = defaultStartTime.substr(11, 5);
    defaultEndTime = defaultEndTime.substr(11, 5);
  }
  if (defaultDate.indexOf('-') !== -1 && defaultDate.indexOf('T') !== -1) {
    defaultDate = defaultDate.substr(0, 10).replace(/-/g, '/');
    defaultValue = defaultValue.substr(0, 10).replace(/-/g, '/').replace(/T/g, ' ');
    defaultStartTime = defaultStartTime.substr(11, 5);
    defaultEndTime = defaultEndTime.substr(11, 5);
  }
  const [local, setLocal] = useState({
    dist: defaultDate,
    value: defaultValue,
    start: defaultStartTime,
    end: defaultEndTime,
  });

  // id
  const valChange = (e) => {
    const DateInput = document.getElementById(`${val.id}d`);
    const StartInput = document.getElementById(`${val.id}t1`);
    const EndInput = document.getElementById(`${val.id}t2`);
    const dateValue = DateInput.value;
    let startValue = StartInput.value;
    let endValue = EndInput.value;
    const stateValueStart = `${dateValue}T${startValue}`;
    const stateValueEnd = `${dateValue}T${endValue}`;
    if (e.target === EndInput) {
      StartInput.setAttribute('max', `${endValue}:00`);
      if (EndInput.validity.rangeUnderflow) {
        endValue = '無効な時間です';
      }
    }
    if (e.target === StartInput) {
      EndInput.setAttribute('min', `${startValue}:00`);
      if (StartInput.validity.rangeOverflow) {
        startValue = '無効な時間です';
      }
    }
    setLocal({
      dist: dateValue.replace(/-/g, '/'),
      value: dateValue,
      start: startValue,
      end: endValue,
    });
    val.setDate(stateValueStart, stateValueEnd);
  };

  const onClickDate = (e, dateVal) => {
    const d = new Date(dateVal);
    e.target.value = val.convertDateToIso(d);
  };

  const onClickTime = (e, dateVal) => {
    const d = new Date(dateVal);
    e.target.value = val.convertDateTimeToIso(d);
  };

  return (
    <div className={style.dateContainer}>
      <label className={style.label} htmlFor={`${val.id}d`}>
        <span className={`${common.strong} ${style.txt} ${local.dist ? '' : style.placeholderTxt}`}>
          {
        // Calendarコンポーネントのpropsにplaceholderを追加した場合、placeholderが表示されるようにしました
          // eslint-disable-next-line no-nested-ternary
          local.dist ? (local.dist) : (val.placeholder.dist ? val.placeholder.dist : '')
          }
        </span>
        <input
          className={style.input}
          require={val.require.toString()}
          id={`${val.id}d`}
          type="date"
          value={local.value}
          onChange={valChange}
          readOnly={val.readonly ? 'readOnly' : ''}
          onClick={(e) => onClickDate(e, local.value)}
        />
      </label>
      <label className={style.label} htmlFor={`${val.id}t1`}>
        <span className={`${common.strong} ${style.txt} ${local.start ? '' : style.placeholderTxt}`}>
          {
        // Calendarコンポーネントのpropsにplaceholderを追加した場合、placeholderが表示されるようにしました
          // eslint-disable-next-line no-nested-ternary
          local.start ? (local.start) : (val.placeholder.start ? val.placeholder.start : '')
          }
        </span>
        <input
          className={style.input}
          require={val.require.toString()}
          id={`${val.id}t1`}
          type="time"
          value={local.start}
          onChange={valChange}
          readOnly={val.readonly ? 'readOnly' : ''}
          onClick={(e) => onClickTime(e, local.start)}
        />
      </label>
      <span> - </span>
      <label className={`${style.label}`} htmlFor={`${val.id}t2`}>
        <span className={`${common.strong} ${style.txt} ${local.end ? '' : style.placeholderTxt}`}>
          {
        // Calendarコンポーネントのpropsにplaceholderを追加した場合、placeholderが表示されるようにしました
          // eslint-disable-next-line no-nested-ternary
          local.end ? (local.end) : (val.placeholder.end ? val.placeholder.end : '')
          }
        </span>
        <input
          className={style.input}
          require={val.require.toString()}
          id={`${val.id}t2`}
          type="time"
          value={local.end}
          onChange={valChange}
          readOnly={val.readonly ? 'readOnly' : ''}
          onClick={(e) => onClickTime(e, local.end)}
        />
      </label>
    </div>
  );
}

export default CalendarInputMultiTime;
