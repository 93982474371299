import { Button, Dialog, DialogContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DriveFileRenameIcon from '../../icons/fileRename';
import commonStyles from '../../styles';
import { TextBaseField } from '../../eleCommon/validation';
import { validateFormString1 } from '../../../commonFunction/validations';

const useStyles = makeStyles((theme) => ({
  btn: {
    transition: 'opacity 0.3s ease',
    opacity: 0,
    marginLeft: 8,
    minWidth: 'auto',
  },
  editing: {
    color: 'rgb(104,179,113)',
  },
  header: {
    background: '#fff',
    borderBottom: '1px solid #C8C8C8',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: 8,
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '&:last-child': {
      marginLeft: 16,
    },
  },
  editArea: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transition: 'background-color 0.3s ease',
    '& > p': {
      flexGrow: 1,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
      '& button': {
        opacity: 1,
      },
    },
  },
  nameTxt: {
    width: '80%',
  },
  memoTxt: {
    width: '100%',
  },
  omitOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nameLabel: {
    minHeight: '1.3rem',
    display: 'flex',
    wordBreak: 'break-all',
  },
  nameOptionsDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '10rem',
    paddingTop: '0',
    padding: '0',
    '&:first-child': {
      paddingTop: '5px',
    },
  },
  nameOptionBtn: {
    padding: '0 20px',
    height: '2.8rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  nameOption: {
    flexGrow: '1',
  },
  prefix: {
    marginRight: '10px',
    marginLeft: '0',
    fontWeight: 'bold',
  },
  newEntryBtn: {
    padding: '8px 20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.primaryColor,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& .MuiSvgIcon-root': {
        marginRight: '0.8rem',
      },
    },
  },
  divider: {
    width: '100%',
  },
  nameOptionsDialogFooter: {
    justifyContent: 'center',
  },
  telNum: {
    marginTop: 8,
    paddingLeft: 8,
  },
  icon: { fill: theme.palette.primaryColor },
}));

function EditField(props) {
  const { name, edit, setEdit, data, setTxtState, updateFunction } = props;
  const classes = useStyles();
  const targetRef = useRef(null);

  const onBlurHandler = () => {
    if (edit[name]) {
      // TODO: APIに名前などをPUTして編集を閉じる
      updateFunction();
      setEdit({ ...edit, [name]: false });
    } else {
      setEdit({ ...edit, [name]: true });
    }
  };

  // ボタンを押すたびにレンダリングが走る = focusイベントを走らせるためのuseEffect
  useEffect(() => {
    const inputElement = targetRef.current;
    // 編集ボタンを押した際にレンダリングが走るため、
    // メモか名前なのかの判定は無しでref.currentへ直フォーカス
    inputElement.focus();
  }, []);
  return (
    <>
      <TextBaseField
        name={name}
        defaultValue={data}
        onBlur={onBlurHandler}
        onChange={e => setTxtState(e.target.value)}
        validator={validateFormString1({ maxLengthInt: 50 })}
        inputRef={targetRef}
        className={name === 'name' ? classes.nameTxt : classes.memoTxt}
      />
      {name === 'name'
        ? <span>&emsp;様</span>
        : null}
    </>
  );
}

export default function SmsHeader(props) {
  const {
    data,
    customer,
    nameState,
    setNameState,
    memoState,
    setMemoState,
    updateFunction,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [edit, setEdit] = useState({
    name: false,
    memo: false,
  });
  // 名前選択ダイアログの表示非表示state
  const [openNameOptions, setOpenNameOptions] = useState(false);

  const onClick = (type) => {
    if (edit[type]) {
      // TODO: APIに名前などをPUTして編集を閉じる
      // updateFunction();
      setEdit({ ...edit, [type]: false });
    } else {
      setEdit({ ...edit, [type]: true });
      if (type === 'name' && !nameState) setOpenNameOptions(true);
    }
  };

  const handleCloseNameOptions = () => {
    setOpenNameOptions(false);
  };

  const onClickName = (name) => {
    setNameState(name);
    setOpenNameOptions(false);
  };

  const onClickEnterNewName = () => {
    setOpenNameOptions(false);
    setEdit({ ...edit, name: true });
  };

  const switchBtnType = (val) => {
    const returnType = [];
    switch (val) {
      case 'name':
        return (
          <Button
            onClick={() => { onClick('name'); }}
            className={`${classes.btn} ${edit.name ? classes.editing : ''}`}
          >
            <DriveFileRenameIcon className={classes.icon} />
          </Button>
        );
      case 'memo':
        return (
          <Button
            onClick={() => { onClick('memo'); }}
            className={`${classes.btn} ${edit.memo ? classes.editing : ''}`}
          >
            <DriveFileRenameIcon className={classes.icon} />
          </Button>
        );
      default:
        break;
    }
    return returnType;
  };

  return (
    <Grid
      container
      alignItems="center"
      className={classes.header}
    >
      <Grid container className={classes.headerInner}>
        <Grid className={classes.editArea}>
          <Typography
            className={common.title2}
          >
            {edit.name
              ? (
                <EditField data={nameState} name="name" setTxtState={setNameState} edit={edit} setEdit={setEdit} updateFunction={updateFunction} />
              )
              : <Typography className={`${common.title2} ${classes.nameLabel}`}>{`${nameState} 様`}</Typography>}
          </Typography>
          {switchBtnType('name')}
        </Grid>
      </Grid>
      <Grid container className={classes.headerInner}>
        <Grid className={classes.editArea}>
          {edit.memo
            ? (
              <EditField data={memoState} name="memo" setTxtState={setMemoState} edit={edit} setEdit={setEdit} updateFunction={updateFunction} />
            )
            : (
              <Typography className={`${common.title6} ${classes.omitOverflow}`}>
                <span className={common.title6}>メモ：</span>{memoState}
              </Typography>
            )}
          {switchBtnType('memo')}
        </Grid>
      </Grid>
      <Typography className={`${common.title6} ${classes.telNum}`}>{data.phoneNumber}</Typography>
      <Dialog
        open={openNameOptions}
        onClose={handleCloseNameOptions}
      >
        <DialogContent className={classes.nameOptionsDialogContent}>
          <Button
            className={classes.nameOptionBtn}
            onClick={() => onClickName(`${customer.lastName} ${customer.firstName}`)}
          >
            {/* 主顧客名 */}
            <span className={classes.prefix}>主</span><Typography className={classes.nameOption}>{`${customer.lastName} ${customer.firstName}`}</Typography>
          </Button>
          <Button
            className={classes.nameOptionBtn}
            onClick={() => onClickName(`${customer.subLastName} ${customer.subFirstName}`)}
          >
            {/* サブ顧客名（TODO:現在は仮で主顧客名を表示。APIを叩いてデータ取得＆表示） */}
            <span className={classes.prefix}>副</span><Typography className={classes.nameOption}>{`${customer.subLastName} ${customer.subFirstName}`}</Typography>
          </Button>
          <Grid container className={classes.nameOptionsDialogFooter}>
            <Divider className={classes.divider} />
            <Button
              className={classes.newEntryBtn}
              onClick={onClickEnterNewName}
            >
              <AddIcon xs={{}} />新規入力
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
