import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomMainSelectPlaceholder from '../../eleCommon/customPlaceholderSelect';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  select: {
    // minWidth: '160px',
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiSelect-select:focus': { backgroundColor: '#fff' },
    '& .MuiSelect-icon': {
      width: '17px',
    },
    '&::after': { display: 'none' },
  },
  wrap: {
    flexGrow: 1,
    marginLeft: '24px',
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
  },
  itemWrap: {
    border: '1px #c8c8c8 solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 8px',
    marginBottom: '8px',
    flexWrap: 'nowrap',
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  label: {
    flexShrink: 0,
    marginRight: 8,
  },
}));

function MailSelect(props) {
  const { values, disable } = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    <Grid container className={`${classes.itemWrap} ${disable ? classes.disable : ''}`} style={values.style}>
      <Typography className={`${classes.label} ${common.title4}`}>
        {values.label}
        {values.require ? <span style={{ color: '#D83420' }}>＊</span> : null}
      </Typography>
      <CustomMainSelectPlaceholder
        initial={{
          placeholder: '未選択',
          obj: values.constants,
          state: values.state,
          // changeに統一: eventオブジェクトを返却するのでe.target.valueで値を取得する
          change: values.setState,
        }}
      />
    </Grid>
  );
}

export default MailSelect;
