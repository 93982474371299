import React from 'react';
import {
  Dialog,
  Paper,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderRadius: 4,
    [commonTheme.breakpoints.up('md')]: {
      width: 640,
    },
  },
  ttl: {
    padding: '24px 24px 8px',
    [commonTheme.breakpoints.up('md')]: {
      cursor: 'move',
      borderBottom: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 20,
      textAlign: 'center',
    },
  },
  txt: {
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px solid #C8C8C8',
      padding: 24,
    },
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '0 24px 16px',
    },
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 0',
  },
  btn1: {
    width: 89,
    padding: '8px 0',
  },
  btn2: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 89,
    padding: '8px 0',
    marginLeft: 16,
  },
}));

const windowWidth = window.innerWidth;
const breakPoint = 768;
const isSP = (windowWidth < breakPoint);

function PaperComponent(props) {
  return (
    isSP ? (
      <Paper {...props} />
    ) : (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    )
  );
}

function MemberChangeConfirm(props) {
  const {
    open,
    onClose,
    data,
    onSubmit,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={`${baseClasses.cancelIndex} ${classes.dialog}`}
    >
      <Grid className={classes.paper}>
        <Typography
          id="draggable-dialog-title"
          className={`${baseClasses.title3} ${classes.ttl}`}
        >
          {data.ttl}
        </Typography>
        <Typography className={classes.txt}>
          {data.txt}
        </Typography>
        <Grid className={classes.btnWrap}>
          <Button
            className={classes.btn1}
            onClick={onClose}
          >
            いいえ
          </Button>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.btn2}`}
            onClick={onSubmit}
          >
            はい
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default MemberChangeConfirm;
