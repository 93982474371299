import React, { useState, useEffect } from 'react';
import {
  Button,
} from '@material-ui/core';
import commonStyles from '../../../styles';

function SaveButton(props) {
  const {
    selectUser, // 選択されたUserのUserIdとUserName: 期待値={userId: 0, userName: ''}のstate
    selectDivision, // 選択されたDivisionのDivisionId: 期待値=組織IDのsetState
    showOnlyDivisions,
    onClickSelect,
    isMulti,
    clickableUserAndDivision,
    isAcquirer,
  } = props;
  const baseClasses = commonStyles();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (isAcquirer) {
      setIsDisabled(!selectUser?.userName);
    } else if (showOnlyDivisions || clickableUserAndDivision) {
      setIsDisabled(!selectDivision?.divId);
    } else if (isMulti) {
      setIsDisabled(!selectUser[0]);
    } else {
      setIsDisabled(!selectUser?.userId);
    }
  }, [selectUser, selectDivision]);

  return (
    <Button
      className={baseClasses.buttonsPrimary}
      onClick={onClickSelect}
      disabled={isDisabled}
    >
      選択
    </Button>
  );
}
export default SaveButton;
