import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import ClaimCreateCancelApi from '../../../apis/claim/claimCreateCancelApi';
// import GetContractInfoApi from '../../../apis/keymalis/getContractInfoApi';
import customerKeymalisContractsApi from '../../../apis/customer/customerKeymalisContracts';
import { claimCreateCancelApi } from '../../../store/claim/claimCreateCancelSlice';
// import { getContractInfoApi } from '../../../store/keymalis/getContractInfoSlice';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import {
  customerStatusCodeSelect,
} from '../../../constants/customerMain';
import ContractInformation from '../contractInformation';
import ClaimRegister from '../../common/claimRegister';
import DoNotChaseReason from '../../common/doNotChaseReason';
import {
  LEAVE_STATUS,
  NO_CONTACT_DETAIL_REASON_CODE,
} from '../../../constants';
import { useUpdate } from '../../../containers/customerMain';
import { useCustomerMain } from '../../../containers/customerMain/customerMainContext';

import { getEnvVariable } from '../../../commonFunction/getEnv';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    width: 209,
    marginBottom: 6,
  },
  statusWrap: {
    padding: '0 0 0 16px',
    background: '#fff',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      justifyContent: 'space-between',
      borderBottom: '1px solid #F3F3F3',
    },
    '& .MuiButton-label': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
    },
  },
  status1: {
    [commonTheme.breakpoints.up('md')]: {
      color: theme.palette.primaryColor,
      padding: '4px 10px',
    },
  },
  status2: {
    [commonTheme.breakpoints.up('md')]: {
      color: '#D83420',
      padding: '10px',
    },
  },
  status3: {
    [commonTheme.breakpoints.up('md')]: {
      color: '#D83420',
      padding: '0 15px',
    },
  },
  status4: {
    [commonTheme.breakpoints.up('md')]: {
      color: '#27AE60',
      padding: '9px',
    },
  },
  statusBtn: {
    display: 'inline-flex',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  statusBtn1: {
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
      width: '100%',
    },
  },
  statusBtn2: {
    [commonTheme.breakpoints.down('sm')]: {
      color: '#D83420',
      width: '100%',
    },
  },
  statusBtn3: {
    [commonTheme.breakpoints.down('sm')]: {
      color: '#D83420',
      width: '72%',
    },
  },
  statusBtn4: {
    [commonTheme.breakpoints.down('sm')]: {
      color: '#27AE60',
      width: '72%',
    },
  },
  statusBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: 92,
  },
  statusLbl: {
    background: '#fff',
    position: 'absolute',
    zIndex: '2',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 23,
  },
  statusSelect: {
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  statusBox1: {
    height: 20,
  },
  statusBox2: {
    width: '100%',
    height: 46,
  },
  statusBox3: {
    height: 20,
  },
  statusBox4: {
    height: 46,
  },
  statusIcon: {
    fontSize: 24,
  },
  statusVal: {
    whiteSpace: 'nowrap',
  },
  statusTtl: {
    fontSize: 52,
    lineHeight: 1.2,
    fontWeight: '700',
  },
  statusTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: '100%',
    textAlign: 'left',
    color: '#333',
    [commonTheme.breakpoints.down('sm')]: {
      width: '60%',
      marginLeft: 24,
    },
  },
  button1: {
    background: '#D83420',
    color: '#fff',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    padding: '4px 10px',
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
    },
  },
  button2: {
    color: '#fff',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    padding: '4px 10px',
    minWidth: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
    },
  },
  paper: {
    position: 'absolute',
    width: 515,
    backgroundColor: '#fff',
    boxShadow: '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperTtl2: {
    fontWeight: '700',
    fontFamily: 'Roboto',
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: 0,
    margin: '0 0 8px',
  },
  paperTxtWrap: {
    padding: '24px 0',
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    marginBottom: 16,
  },
  paperTxt: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 0 43px',
  },
  paperTxt2: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: 0,
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 91px',
  },
  paperBtn2: {
    padding: '8px 24px',
    margin: '0 8px',
  },
  paperBtn3: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 8px',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&:nth-child(1)': {
      color: theme.palette.primaryColor,
    },
    '&:nth-child(2)': {
      color: '#D83420',
    },
    '&:nth-child(3)': {
      color: '#27AE60',
    },
    '&:nth-child(4)': {
      color: '#D83420',
    },
    '& .MuiSvgIcon-root': {
      marginRight: 1,
    },
    '& .material-icons': {
      marginRight: 1,
    },
    '& .MuiButton-label': {
      paddingLeft: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
}));

function CustomerStatus(props) {
  const { input } = props;
  const {
    functions,
  } = useCustomerMain();
  const { current, update, updateCurrent, isSp } = useUpdate(input.customer, [
    'customerId',
    'customerStatusCode',
    'noContactDetailReasonCode',
    'userId',
  ]);

  const classes = useStyles();
  const baseClasses = commonStyles();

  const menus = Object.keys(LEAVE_STATUS);

  const [productName, setProductName] = useState('');

  const dispatch = useDispatch();

  // POST claimCreateCancel
  const claimCreateCancelFunction = async (customerId) => {
    await ClaimCreateCancelApi(customerId)
      .then((res) => {
        console.log(res.data);
        dispatch(claimCreateCancelApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // GET getKeymalisContracts
  const getKeymalisContracts = (customerId) => {
    customerKeymalisContractsApi(customerId)
      .then((res) => {
        const contractId = Math.max(...res.data.map((p) => p.contractId));
        for (let i = 0; i < res.data.length; i += 1) {
          if (Number(res.data[i].contractId) === Number(contractId)) {
            setProductName(res.data[i].productName);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // GET ContractInfo
  // const contractInfoFunction = async (customerId) => {
  //   await GetContractInfoApi(customerId)
  //     .then((res) => {
  //       dispatch(getContractInfoApi(res.data));
  //       const contractId = Math.max(...res.data.map((p) => p.contractId));
  //       for (let i = 0; i < res.data.length; i += 1) {
  //         if (Number(res.data[i].contractId) === Number(contractId)) {
  //           setProductName(res.data[i].productName);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    // ステータスが契約済みの時、物件情報を取得
    if (current.customerStatusCode === 10 && input.isFirstRender?.current) {
      getKeymalisContracts(current.customerId);
      // contractInfoFunction(current.customerId);
    }
  }, []);

  let contents;

  // 追わない理由ウインドウ用
  const initial = {
    noContactDetailReasonCode: 0,
    searchArea: '',
    reasonNotOhd: '',
    searchStartAt: '',
    reasonNotSearch: '',
    reasonNotBuy: '',
  };
  const [userData, setUserData] = useState(initial);

  // 確認ウインドウ 解除申請
  const handleClick1 = () => {
    console.log('CANCEL');
  };
  const handleClick2 = () => {
    claimCreateCancelFunction(current.customerId);
  };
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: 'クレーム解除を申請しますか？',
      msgList: ['顧客満足室へ連絡をいたします。'],
      buttons: [
        {
          label: 'CANCEL',
          set: handleClick1,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: handleClick2,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // 確認ウインドウ 契約
  const handleClick3 = () => {
    console.log('CANCEL');
  };
  const handleClick4 = async () => {
    const newData = { customerStatusCode: 10 };
    // 更新処理
    await update(newData, true)
      .then((res) => {
        // 更新成功時のみ実行
        if (res) {
          // キマリス
          const url = `${getEnvVariable('KEYMALIS_BASE_URL')}contract/add?marketingCustomerId=${current.customerId}`;
          window.open(url, '_blank');
        }
      });
  };
  const confirmOpen2 = () => {
    dispatch(changeConfirmMessage({
      title: '',
      msgList: ['顧客ステータスを契約にして保存すると、', '契約済み顧客となりますが、本当によろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: handleClick3,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: handleClick4,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // モーダル用 追わない
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const handleOpen5 = () => { setOpen5(true); };
  const handleOpen6 = () => { setOpen6(true); };
  const handleClose5 = () => { setOpen5(false); };
  const handleClose6 = () => { setOpen6(false); };

  // 確認ウインドウ 追わない
  const handleClick7 = () => {
    handleOpen5();
  };
  const handleClick8 = () => {
    handleOpen6();
  };
  const confirmOpen4 = () => {
    dispatch(changeConfirmMessage({
      title: 'クレームはありませんでしたか？',
      msgList: ['クレームをいただいた場合はクレーム登録を行ってください。'],
      buttons: [
        {
          label: 'クレーム登録',
          set: handleClick7,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'はい',
          set: handleClick8,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };
  const handleClick5 = async () => {
    const newData = { emailStatusCode: 5 };
    // メールステータスを変更
    // 更新処理
    update(newData, true);
    // 顧客メインを再読み込み
    await functions.updatePage();
  };
  const handleClick6 = () => {
    confirmOpen4();
  };
  const confirmOpen3 = () => {
    dispatch(changeConfirmMessage({
      title: 'まずは自動追客対象にしませんか？',
      msgList: ['メールの許可を「代行も全てOK」に変更すると設定できます。'],
      buttons: [
        {
          label: '代行も全てOK にする',
          set: handleClick5,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'しない',
          set: handleClick6,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // モーダル用 履歴
  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  // モーダル用 SP
  const [open7, setOpen7] = useState(false);
  const handleOpen7 = () => {
    setOpen7(true);
  };
  const handleClose7 = () => {
    setOpen7(false);
  };

  const selectChange = (e) => {
    const { name, value: val } = e.target;
    const value = Number(val);
    if (value === 2) {
      // 追わない
      confirmOpen3();
    } else if (value === 10) {
      // 契約
      confirmOpen2();
    } else if (value === 11) {
      // クレーム
      handleOpen5();
    } else {
      // 追客中
      update({ [name]: value }, true);
    }
  };

  // 追わない customerStatusCode:2
  const customerStatusSet2 = () => {
    const newData = {
      customerStatusCode: 2,
      noContactDetailReasonCode: userData.noContactDetailReasonCode,
    };

    switch (userData.noContactDetailReasonCode) {
      case 4:
        newData.reasonNotBuy = userData.reasonNotBuy;
        break;
      case 6:
        if (userData.searchStartAt === '') {
          newData.searchStartAt = userData.searchStartAt;
        } else {
          newData.searchStartAt = userData.searchStartAt + ' 00:00:00';
        }
        newData.reasonNotSearch = userData.reasonNotSearch;
        break;
      case 7:
        newData.searchArea = userData.searchArea;
        newData.reasonNotOhd = userData.reasonNotOhd;
        break;
      default:
        break;
    }
    // 更新処理
    update(newData, true);
  };

  // クレーム customerStatusCode:11
  const customerStatusSet11 = () => {
    // input.setCustomer({ ...current, customerStatusCode: 11 });
    updateCurrent('customerStatusCode', 11);
    // クレーム登録側で更新されるためステータスの更新は不要
    // 顧客情報を再取得
    if (isSp) {
      input.update();
    } else {
      functions.updatePage();
    }
  };

  const selectBlur = (e) => {
    // if (e.target.value !== current.customerStatusCode) {
    //   input.setCustomer({ ...current, customerStatusCode: e.target.value });
    // }
    updateCurrent('customerStatusCode', Number(e.target.value));
  };

  const customerSelectList = {
    customerStatusCodeItem: {
      type: 'selectBack',
      obj: customerStatusCodeSelect,
      state: current.customerStatusCode,
      name: 'customerStatusCode',
      change: selectChange,
      set: selectBlur,
    },
  };

  const getStatusPc = () => {
    switch (current.customerStatusCode) {
      case 1:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            <Grid className={`${classes.statusBox} ${classes.statusBox1}`}>
              <Grid className={classes.statusLbl}>
                <DirectionsRunIcon className={classes.statusIcon} />
                <Typography className={baseClasses.title3}>
                  追客中
                </Typography>
              </Grid>
              <Grid className={classes.statusSelect}>
                {input.component(customerSelectList.customerStatusCodeItem)}
              </Grid>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid className={`${classes.status} ${classes.status2}`}>
            <Grid className={`${classes.statusBox} ${classes.statusBox2}`}>
              <Grid className={classes.statusLbl}>
                <BlockIcon className={classes.statusIcon} />
                <Typography className={baseClasses.title3}>
                  追わない
                </Typography>
                <Typography className={classes.statusTxt}>
                  {NO_CONTACT_DETAIL_REASON_CODE[current.noContactDetailReasonCode]}
                </Typography>
              </Grid>
              <Grid className={classes.statusSelect}>
                {input.component(customerSelectList.customerStatusCodeItem)}
              </Grid>
            </Grid>
          </Grid>
        );
      case 10:
        return (
          <Grid className={`${classes.status} ${classes.status4}`}>
            <Grid className={`${classes.statusBox} ${classes.statusBox4}`}>
              <Grid className={classes.statusLbl}>
                <Icon className={classes.statusIcon}>verified</Icon>
                <Typography className={baseClasses.title3}>
                  契約済み
                </Typography>
                <Typography className={classes.statusTxt}>
                  {productName}
                </Typography>
              </Grid>
              <Grid className={classes.statusSelect}>
                {input.component(customerSelectList.customerStatusCodeItem)}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className={`${baseClasses.buttonsPrimary} ${classes.button2}`}
              onClick={handleOpen4}
            >
              履歴
            </Button>
          </Grid>
        );
      case 11:
        return (
          <Grid className={`${classes.status} ${classes.status3}`}>
            <Grid className={`${classes.statusBox} ${classes.statusBox3}`}>
              <Grid className={classes.statusLbl}>
                <FlashOnIcon className={classes.statusIcon} />
                <Typography className={baseClasses.title3}>
                  クレーム
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className={classes.button1}
              onClick={confirmOpen}
            >
              解除申請
            </Button>
          </Grid>
        );
      default:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            {/* その他 */}
          </Grid>
        );
    }
  };

  // SP 項目選択時に更新
  const onSelect = (val) => {
    const value = Number(val);
    if (current.customerStatusCode !== value) {
      if (value === 2) {
        // 追わない
        confirmOpen3();
      } else if (value === 10) {
        // 契約
        confirmOpen2();
      } else if (value === 11) {
        // クレーム
        handleOpen5();
      } else {
        // 追客中
        // input.setCustomer({ ...current, customerStatusCode: Number(val) });
        const newData = { customerStatusCode: value };
        update(newData);
      }
    }
  };

  const getIcon = (val) => {
    switch (Number(val)) {
      case 1:
        return (
          <DirectionsRunIcon className={classes.statusIcon} />
        );
      case 2:
        return (
          <BlockIcon className={classes.statusIcon} />
        );
      case 10:
        return (
          <Icon className={classes.statusIcon}>verified</Icon>
        );
      case 11:
        return (
          <FlashOnIcon className={classes.statusIcon} />
        );
      default:
        return (
          <span>該当なし</span>
        );
    }
  };

  const getCustomerStatusCodeSp = () => {
    return (
      <Grid>
        {menus.map((data) => {
          return (
            <Button
              className={`${baseClasses.title3} ${classes.selectBtn} ${(Number(current.customerStatusCode) === Number(data)) && 'select'}`}
              key={data}
              onClick={() => {
                onSelect(data);
                handleClose7();
              }}
            >
              {getIcon(data)}
              {LEAVE_STATUS[data]}
            </Button>
          );
        })}
      </Grid>
    );
  };

  const getStatusSp = () => {
    switch (current.customerStatusCode) {
      case 1:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            <Button
              className={`${classes.statusBtn} ${classes.statusBtn1}`}
              onClick={handleOpen7}
            >
              <DirectionsRunIcon className={classes.statusIcon} />
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                追客中
              </span>
            </Button>
          </Grid>
        );
      case 2:
        return (
          <Grid className={`${classes.status} ${classes.status2}`}>
            <Button
              className={`${classes.statusBtn} ${classes.statusBtn2}`}
              onClick={handleOpen7}
            >
              <BlockIcon className={classes.statusIcon} />
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                追わない
              </span>
              <Typography className={classes.statusTxt}>
                {NO_CONTACT_DETAIL_REASON_CODE[current.noContactDetailReasonCode]}
              </Typography>
            </Button>
          </Grid>
        );
      case 10:
        return (
          <Grid className={`${classes.status} ${classes.status4}`}>
            <Button
              className={`${classes.statusBtn} ${classes.statusBtn4}`}
              onClick={handleOpen7}
            >
              <Icon className={classes.statusIcon}>verified</Icon>
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                契約済み
              </span>
              <span className={classes.statusTxt}>
                {productName}
              </span>
            </Button>
            <Button
              variant="contained"
              className={`${baseClasses.buttonsPrimary} ${classes.button2}`}
              onClick={handleOpen4}
            >
              履歴
            </Button>
          </Grid>
        );
      case 11:
        return (
          <Grid className={`${classes.status} ${classes.status3}`}>
            <Grid
              className={`${classes.statusBtn} ${classes.statusBtn3}`}
            >
              <FlashOnIcon className={classes.statusIcon} />
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                クレーム
              </span>
            </Grid>
            <Button
              variant="contained"
              className={classes.button1}
              onClick={confirmOpen}
            >
              解除申請
            </Button>
          </Grid>
        );
      default:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            該当なし
          </Grid>
        );
    }
  };

  // SP 表示用
  const list = [
    {
      type1: 'button',
      type2: 'select',
      lbl: '状態',
      click: handleOpen5,
      fontSize: '16px',
      opn: open7,
      onClose: handleClose7,
      content: getCustomerStatusCodeSp,
    },
  ];

  if (isSp) {
    contents = (
      <Grid className={classes.statusWrap}>
        {getStatusSp()}
        {input.editDialog(list[0])}
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        {getStatusPc()}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <ContractInformation
        open={open4}
        onClose={handleClose4}
        customerId={input.customer.customerId}
      />
      <ClaimRegister
        open={open5}
        onClose={handleClose5}
        id=""
        setStatus={customerStatusSet11}
        customerId={current.customerId}
        responsibleUserId={current.userId}
      />
      <DoNotChaseReason
        open={open6}
        onClose={handleClose6}
        setStatus={customerStatusSet2}
        userData={userData}
        setUserData={setUserData}
      />
    </Grid>
  );
}

export default React.memo(CustomerStatus);
