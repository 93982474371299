import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const webResUpdateAfterMargedSlice = createSlice({
  name: 'webResUpdateAfterMarged',
  initialState: { webResUpdateAfterMarged: initialState },
  reducers: {
    webResUpdateAfterMargedApi: (state, data) => {
      state.webResUpdateAfterMarged = data.payload;
    },
  },
});

export const { webResUpdateAfterMargedApi } = webResUpdateAfterMargedSlice.actions;

export default webResUpdateAfterMargedSlice.reducer;
