import { useCallback } from 'react';
import KeywordsTableRow from './keywordsTableRow';
import CustomTable from './customTable';
import { MEMBERS_REDUCER_KEYS } from '../../../containers/browsingHistory/browsingHistoryReducer';

const headCells = [
  { id: 'no', label: '' },
  { id: 'inflowAt', label: '流入日時', sortable: true },
  { id: 'keyword', label: 'キーワード' },
];

export default function KeywordsTable(props) {
  const {
    data,
    getApiDataFromListApi,
    isSP,
    handleOpenModal = null,
  } = props;
  const keywordTablelimit = localStorage.getItem('keywordTablelimit') ? localStorage.getItem('keywordTablelimit') : 20;
  const setKeywordTablelimit = useCallback((current) => {
    localStorage.setItem('keywordTablelimit', current);
  }, []);
  return (
    <CustomTable
      hideHeader={isSP}
      title="検索・流入キーワード"
      headCells={headCells}
      total={data.total}
      list={data.keywords}
      rowRender={(list) => list.map((row, i) => (<KeywordsTableRow
        key={String(i)}
        row={row}
        index={i}
      />))}
      onPageChange={(pageInfo) => {
        getApiDataFromListApi(MEMBERS_REDUCER_KEYS.keywords, pageInfo);
      }}
      divider
      contentHeight={(isSP || handleOpenModal === null) ? null : 121}
      propLimit={keywordTablelimit}
      setPropLimit={setKeywordTablelimit}
      isNoSlice
      handleOpenModal={handleOpenModal}
    />
  );
}
