import InfoChangeHistoryContainers from '../../../containers/common/infoChangeHistory/infoChangeHistoryContainers';

function InfoChangeHistory(props) {
  return (
    <div>
      <InfoChangeHistoryContainers {...props} />
    </div>
  );
}

export default InfoChangeHistory;
