import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Checkbox,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import {
  EMAIL_STATUS_CODE,
} from '../../../constants';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { useUpdate } from '../../../containers/customerMain';
import { checkMailVaildation } from '../../../commonFunction/mail';
import { convertForm1 } from '../../../commonFunction/convertors';
import { validateFormString1, validateFormEmail } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 6,
  },
  mailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mailItem2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  lbl1: {
    width: '48%',
    marginBottom: 4,
  },
  lbl2: {
    width: '37%',
    marginBottom: 4,
  },
  lbl3: {
    width: '7%',
    marginBottom: 4,
  },
  lbl4: {
    width: '25%',
    marginRight: 8,
  },
  textField: {
    display: 'block',
    '& input': {
      fontFamily: 'Roboto',
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      fontSize: 16,
      zoom: 0.81,
      padding: 0,
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  textField1: {
    [commonTheme.breakpoints.up('md')]: {
      width: '48%',
    },
  },
  textField2: {
    [commonTheme.breakpoints.up('md')]: {
      width: '37%',
    },
  },
  checked: {
    padding: 0,
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  mailSelect: {
    width: '30%',
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&:nth-child(3n)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  none: {
    display: 'none',
  },
}));

const menus = Object.keys(EMAIL_STATUS_CODE);

function CustomerMail(props) {
  const { input } = props;
  const {
    current,
    original,
    update,
    isSp,
  } = useUpdate(input.customer);

  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  // 再レンダリング用
  const [reset, setReset] = useState(false);

  let contents;

  // チェックボックス
  const [isMailSendState, setIsMailSendState] = useState({
    isMailSend1: Boolean(current.isMailSend1),
    isMailSend2: Boolean(current.isMailSend2),
    isMailSend3: Boolean(current.isMailSend3),
    isMailSend4: Boolean(current.isMailSend4),
  });

  // メールアドレス 表示変更 更新
  const updateMail = (n, v) => {
    const mailIndex = n.slice(-1);
    const isMailSendLabel = `isMailSend${mailIndex}`;
    let isMailSendFlg = false;
    if (checkMailVaildation(v)) {
      isMailSendFlg = true;
    }
    // 表示変更
    setIsMailSendState({
      ...isMailSendState,
      [isMailSendLabel]: isMailSendFlg,
    });
    // 更新処理
    const newData = {
      [n]: v,
      [isMailSendLabel]: isMailSendFlg ? 1 : 0,
    };
    update(newData);
    // 画面制御用のステート
    input.setCustomerInfo({ ...input.customerInfo, [isMailSendLabel]: +isMailSendFlg });
  };

  // メールアドレス 確認ウインドウ
  const confirmOpenMail = (n, v) => {
    dispatch(changeConfirmMessage({
      title: '変更確認ウィンドウ',
      msgList: ['変更前のメールアドレス宛に送信予約されているメールは全てキャンセルされます'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {
            // 変更前の表示に戻す
            setReset(!reset);
          },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => {
            if (['mail1', 'mail2', 'mail3'].includes(n)) {
              // 更新処理
              updateMail(n, v);
            } else {
              setIsMailSendState({
                ...isMailSendState,
                [n]: v,
              });
              // 更新処理
              update({ [n]: +v });
              // 画面制御用のステート
              input.setCustomerInfo({ ...input.customerInfo, [n]: +v });
            }
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // フォーカスが外れた時に更新
  const onBlur = (e) => {
    const { name, value } = e.target;
    if ((original[name] || '') === value) return;
    if (['mail1', 'mail2', 'mail3'].includes(name)) {
      if (original[name]) {
        confirmOpenMail(name, value);
      } else {
        updateMail(name, value);
      }
    } else {
      // 更新処理
      update({ [name]: value });
    }
  };

  /**
   * 項目選択時に更新
   * @returns {Boolean} cancelUpdate - 強制的に変更させないようの場合trueを返す
   */
  const updateEmailStatusCode = (value) => {
    const newData = { emailStatusCode: Number(value) };
    if (input.customerInfo.emailStatusCode === newData.emailStatusCode) return false;
    if (Number(input.customerInfo.userLevel) === 2) {
      switch (newData.emailStatusCode) {
        case 0:
        case 5:
          update(newData);
          // 画面制御用のステート
          input.setCustomerInfo({ ...input.customerInfo, emailStatusCode: Number(value) });
          break;
        default:
          dispatch(changeAlertMessage({ msgList: ['メール許可を「個別のみOK」「個別・一斉OK」にする場合は、', 'ランクを変更してください。'] }));
          return true;
      }
    } else {
      update(newData);
      // 画面制御用のステート
      input.setCustomerInfo({ ...input.customerInfo, emailStatusCode: Number(value) });
    }
    return false;
  };

  // メールステータス
  const selectChange = (e) => {
    const { value } = e.target;
    return updateEmailStatusCode(value);
  };

  // フォーカスが外れた時に更新
  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (!checked) {
      // チェックを外した時
      confirmOpenMail(name, checked);
    } else {
      setIsMailSendState({
        ...isMailSendState,
        [name]: checked,
      });
      // 更新処理
      update({ [name]: +checked });
      // 画面制御用のステート
      input.setCustomerInfo({ ...input.customerInfo, [name]: +checked });
    }
  };

  const customerSelectList = {
    emailStatusCodeItem: {
      type: 'select',
      obj: {
        menus: EMAIL_STATUS_CODE,
      },
      state: input.customerInfo.emailStatusCode,
      name: 'emailStatusCode',
      change: selectChange,
    },
  };

  const getTextField1 = (field) => {
    return (
      <TextBaseField
        defaultValue={current[field]}
        className={`${classes.textField} ${classes[/Memo/.test(field) ? 'textField2' : 'textField1']}`}
        name={field}
        onBlur={onBlur}
        placeholder="-"
        type="email"
        validator={validateFormEmail()}
      />
    );
  };

  const getTextField2 = (field) => {
    return (
      <TextBaseField
        defaultValue={current[field]}
        className={`${classes.textField} ${classes[/Memo/.test(field) ? 'textField2' : 'textField1']}`}
        name={field}
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormString1({ maxLengthInt: 10 })}
        convertor={convertForm1}
      />
    );
  };

  const getCheckbox = (name) => {
    const mailNo = name.slice(-1);
    const mailAddress = current[`mail${mailNo}`];
    return (
      <Checkbox
        checked={isMailSendState[name]}
        name={name}
        onChange={onCheckboxChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        className={classes.checked}
        disabled={!mailAddress}
      />
    );
  };

  const getEmailStatusCodePc = () => {
    return (
      <Grid className={classes.mailItem2}>
        <Typography className={`${baseClasses.title6} ${classes.lbl4}`}>
          メール許可範囲
        </Typography>
        <Grid className={classes.mailSelect}>
          {input.component(customerSelectList.emailStatusCodeItem)}
        </Grid>
      </Grid>
    );
  };

  const getEmailStatusCodeSp = () => {
    return (
      <Select
        className={classes.residency}
        value={input.customerInfo.emailStatusCode}
        defaultValue={input.customerInfo.emailStatusCode !== undefined
          ? input.customerInfo.emailStatusCode : 99}
      >
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                updateEmailStatusCode(data);
              }}
            >
              {EMAIL_STATUS_CODE[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'Mail1',
      val: current.mail1,
      keys: 'mail1',
      content: () => { return getTextField1('mail1'); },
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足1',
      val: current.mailMemo1,
      keys: 'mailMemo1',
      content: () => { return getTextField2('mailMemo1'); },
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '送信1',
      val: Boolean(current.isMailSend1),
      keys: 'isMailSend1',
      content: getCheckbox.bind(null, 'isMailSend1'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'Mail2',
      val: current.mail2,
      keys: 'mail2',
      content: () => { return getTextField1('mail2'); },
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足2',
      val: current.mailMemo2,
      keys: 'mailMemo2',
      content: () => { return getTextField2('mailMemo2'); },
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '送信2',
      val: Boolean(current.isMailSend2),
      keys: 'isMailSend2',
      content: getCheckbox.bind(null, 'isMailSend2'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'Mail3',
      val: current.mail3,
      keys: 'mail3',
      content: () => { return getTextField1('mail3'); },
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足3',
      val: current.mailMemo3,
      keys: 'mailMemo3',
      content: () => { return getTextField2('mailMemo3'); },
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '送信3',
      val: Boolean(current.isMailSend3),
      keys: 'isMailSend3',
      content: getCheckbox.bind(null, 'isMailSend3'),
    },
    {
      type1: 'noModal',
      type2: 'select',
      lbl: 'メール許可範囲',
      val: EMAIL_STATUS_CODE[current.emailStatusCode],
      keys: 'emailStatusCode',
      content: getEmailStatusCodeSp,
    },
  ];

  const getStatusPc = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Typography className={baseClasses.title6}>
              Mail
            </Typography>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (current.emailStatusCode) {
          case 0:
            return (
              <Grid>
                <Typography className={baseClasses.title6}>
                  Mail
                </Typography>
                <Typography className={classes.claim}>
                  全部NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid key={reset} className={classes.mailList}>
                <Grid className={classes.mailItem}>
                  <Typography className={`${baseClasses.title6} ${classes.lbl1}`}>
                    Mail
                  </Typography>
                  <Typography className={`${baseClasses.title6} ${classes.lbl2}`}>
                    補足
                  </Typography>
                  <Typography className={`${baseClasses.title6} ${classes.lbl3}`}>
                    送信
                  </Typography>
                </Grid>
                <Grid className={classes.mailItem}>
                  {getTextField1('mail1')}
                  {getTextField2('mailMemo1')}
                  {getCheckbox('isMailSend1')}
                </Grid>
                <Grid className={classes.mailItem}>
                  {getTextField1('mail2')}
                  {getTextField2('mailMemo2')}
                  {getCheckbox('isMailSend2')}
                </Grid>
                <Grid className={classes.mailItem}>
                  {getTextField1('mail3')}
                  {getTextField2('mailMemo3')}
                  {getCheckbox('isMailSend3')}
                </Grid>
                {getEmailStatusCodePc()}
              </Grid>
            );
        }
    }
  };

  const getStatusSp = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              Mail
            </Typography>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (current.emailStatusCode) {
          case 0:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  Mail
                </Typography>
                <Typography className={classes.claim}>
                  全部NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  Mail
                </Typography>
                <List className={classes.list}>
                  {list.map((item) => {
                    return (
                      <ListItem
                        key={item.lbl}
                        className={classes.listItem}
                      >
                        {input.parts(item)}
                        {input.editDialog(item)}
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            );
        }
    }
  };

  if (isSp) {
    contents = (
      <Grid>
        {getStatusSp()}
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        {getStatusPc()}
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerMail);
