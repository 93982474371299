import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { dateFormatter } from '../../../../../commonFunction';

import { CUSTOMER_COLUNM_MAP } from '../../../../../constants/infoChangeHistory';

const useStyles = makeStyles((theme) => ({
  history: {
    fontSize: '13px',
    flexShrink: 0,
  },
  historyTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  historyWrap: {
    paddingLeft: '16px',
  },
  historyItem: {
    position: 'relative',
    padding: '13px 8px',
    borderBottom: '1px solid #F3F3F3',
    display: 'flex',
  },
  historyContent: {
    width: 'calc(100% - 66px)',
  },
  historyTxt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
  },
  historyTime: {
    marginRight: 16,
    fontSize: 16,
    fontWeight: 'bold',
  },
  historyTel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    fontSize: '15px',
    fontWeight: 'bold',
    '& .MuiSvgIcon-root': {
      fontSize: 11,
      color: theme.palette.primaryColor,
      marginRight: 5,
    },
  },
  columnName: {
    wordBreak: 'break-all',
  },
  historyBottom: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
  },
  historyBottomAll: {
    background: '#F3F3F3',
    padding: '8px',
    marginTop: 16,
    '&.active': {
      display: 'block',
    },
  },
  historyArrow: {
    display: 'block',
    fontSize: 10,
    color: '#8C8C8C',
    margin: '0 6px -1px 0',
  },
  historyPrev: {
    marginRight: 6,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '50%',
    '&.all': {
      overflow: 'auto',
      whiteSpace: 'normal',
      maxWidth: 'initial',
    },
  },
  historyRearWrap: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  historyRear: {
    fontWeight: 700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&.all': {
      overflow: 'auto',
      whiteSpace: 'normal',
    },
  },
  historyBtn: {
    width: 18,
    height: 18,
    marginLeft: 5,
    minWidth: 'auto',
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&::before': {
      width: 2,
      height: 14,
      transition: 'all 0.5s ease',
    },
    '&::after': {
      width: 14,
      height: 2,
    },
    '&.active::before': {
      opacity: 0,
    },
  },
}));

function InfoChangeHistoryItemSp(props) {
  const {
    history,
  } = props;

  const classes = useStyles();

  const [active, setActive] = useState(false);
  const classToggle = () => setActive(!active);

  const getLabel = (historyObj) => {
    return historyObj.columnName in CUSTOMER_COLUNM_MAP
      ? CUSTOMER_COLUNM_MAP[historyObj.columnName]
      : historyObj.columnName;
  };

  const beforeValue = history.conversionBeforeValue
    ? String(history.conversionBeforeValue)
    : String(history.beforeValue);
  const afterValue = history.conversionAfterValue
    ? String(history.conversionAfterValue)
    : String(history.afterValue);

  return (
    <Grid className={classes.historyWrap}>
      <Grid className={classes.historyItem}>
        <Typography className={`${classes.historyTxt} ${classes.historyTime}`}>
          {dateFormatter(new Date(history.createdAt), 'hh:mm')}
        </Typography>
        <Grid className={classes.historyContent}>
          <Grid className={classes.historyTop}>
            <Typography className={`${classes.historyTel}`}>
              <span className={`${classes.columnName}`}>
                {getLabel(history)}
              </span>
            </Typography>
            <Typography className={`${classes.history}`}>
              {history.userLastName} {history.userFirstName}
            </Typography>
          </Grid>
          <Grid className={`${classes.historyBottom}`}>
            {/* APIの項目がないので追加待ち */}
            <Typography
              className={`${classes.historyTxt} ${classes.historyPrev}`}
            >
              {beforeValue}
            </Typography>
            <Grid className={classes.historyRearWrap}>
              <ArrowForwardIcon className={classes.historyArrow} />
              <Typography
                className={`${classes.historyTxt} ${classes.historyRear}`}
              >
                {afterValue}
              </Typography>
            </Grid>
            {afterValue.length > 32 && (
              <Button
                className={`${classes.historyBtn} ${active ? 'active' : ''}`}
                onClick={classToggle}
              />
            )}
          </Grid>
          {/* 隠し要素 */}
          {active && (
            <Grid className={`${classes.historyBottomAll}`}>
              {/* APIの項目がないので追加待ち */}
              <Typography
                className={`${classes.historyTxt} ${classes.historyPrev} all`}
              >
                {beforeValue}
              </Typography>
              <Grid className={classes.historyRearWrap}>
                <ArrowForwardIcon className={classes.historyArrow} />
                <Typography
                  className={`${classes.historyTxt} ${classes.historyRear} all`}
                >
                  {afterValue}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InfoChangeHistoryItemSp;
