import LibrarySignatureContainer from '../../../containers/mailLibrary/librarySignatureContainer';

export default function LibrarySignature(props) {
  const { open, onClose } = props;

  if (open) {
    return (
      <LibrarySignatureContainer
        onClose={onClose}
        open={open}
      />
    );
  } else {
    return false;
  }
}
