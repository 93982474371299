/**
 * @description - パスパラメータを削除する関数
 */
const deletePathParameter = () => {
  if (window.location.href.includes('?')) {
    const url = window.location.href.split('?');
    window.history.pushState(null, null, url[0]);
  }
};

export default deletePathParameter;
