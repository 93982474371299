import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '1301!important',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        margin: 16,
      },
    },
  },
  paper: {
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 24,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 515,
      padding: '24px 24px 18px',
    },
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 6,
    },
  },
  paperTtl2: {
    fontWeight: '700',
    fontFamily: 'Roboto',
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: 0,
    margin: '0 0 8px',
  },
  paperTxtWrap: {
    padding: '24px 0',
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    marginBottom: 16,
  },
  paperTxt: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 0 43px',
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 40,
    },
  },
  paperTxt2: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: 0,
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 91px',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '0 32px',

    },
    '& .MuiButton-label': {
      [commonTheme.breakpoints.down('sm')]: {
        padding: 8,
      },
    },
  },
  paperBtn2: {
    padding: '8px 24px',
    margin: '0 8px',
  },
  paperBtn3: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 8px',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function LiftWindow(props) {
  const {
    open,
    onClose,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const body = (
    <Grid className={classes.paper}>
      <h2
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
        className={`${baseClasses.title2} ${classes.paperTtl}`}
      >
        クレーム解除を申請しますか？
      </h2>
      <p
        id="simple-modal-description"
        className={classes.paperTxt}
      >
        顧客満足室へ連絡をいたします。
      </p>
      <Grid className={classes.paperBox}>
        <Button
          className={classes.paperBtn}
          onClick={onClose}
        >
          いいえ
        </Button>
        <Button
          className={classes.paperBtn}
          onClick={onClose}
        >
          はい
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className={classes.dialog}
      >
        {body}
      </Dialog>
    </div>
  );
}
