import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  Paper,
  Grid,
  Typography,
  Button,
  Input,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import { setLocalStorage } from '../../../commonFunction';
import { setIsRunning } from '../../../store/simulator/simulatorRunningStatusSlice';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    width: 640,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderRadius: 0,
  },
  header: {
    padding: '24px 24px 8px',
  },
  content: {
    padding: 24,
    background: '#F3F3F3',
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 18px 8px 4px',
    background: '#fff',
    border: '1px solid #C8C8C8',
    '& input': {
      color: '#8C8C8C',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: '7px 0',
    },
  },
  inputWrap: {
    position: 'relative',
    width: 232,
    height: 28,
    marginTop: '-10px',
    '& .MuiInput-root': {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
  },
  boxLbl: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 0 5px',
    width: 108,
    textAlign: 'center',
    pointerEvents: 'none',
  },
  btnWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: 1,
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 129,
    },
  },
  btn1: {
    padding: '5px 10px 3px',
    fontSize: 13,
    marginRight: 16,
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      marginRight: 3,
    },
  },
  btn2: {
    padding: '5px 10px 3px',
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      marginRight: 3,
    },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function CsvUpload(props) {
  const { open, onClose, downloadFunc, uploadFunc, areaCode } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [filepath, setFilepath] = useState('');
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const pathChange = (event) => {
    setFilepath(event.target.files[0]);
  };

  // 下記でステータス待機中の挙動を制御
  const resultUploadFile = (receptionId = null) => {
    if (!receptionId) return;
    setLocalStorage('SIMULATOR_FILE_RECEPTION_ID', receptionId);
    setLocalStorage('IS_DOWNLOADING_SIMULATOR_FILE', true);
    dispatch(setIsRunning(true));
  };

  const handleUpload = () => {
    onClose();
    uploadFunc(filepath, areaCode, resultUploadFile);
    setFilepath('');
    console.log('アップロード');
  };

  const handleDownload = () => {
    onClose();
    downloadFunc(areaCode);
    console.log('ダウンロード');
  };

  useEffect(() => {
    if (filepath === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={classes.dialog}
    >
      <Grid className={classes.paper}>
        <Grid id="draggable-dialog-title" className={classes.header}>
          <Typography className={baseClasses.title3}>
            {' '}
            反響データ取込（CSV）
          </Typography>
        </Grid>
        <Grid className={classes.content}>
          <Grid className={classes.box}>
            <Grid className={classes.inputWrap}>
              <Input type="file" onChange={pathChange} />
              <Typography className={classes.boxLbl}> ファイル選択</Typography>
            </Grid>
            <Grid className={classes.btnWrap}>
              <Button
                className={`${baseClasses.buttonsPrimary} ${classes.btn1} ${
                  disabled && 'disabled'
                }`}
                onClick={handleUpload}
              >
                <PublishIcon />
                アップロード
              </Button>
              <Button className={classes.btn2} onClick={handleDownload}>
                <InfoIcon />
                ダウンロード
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default CsvUpload;
