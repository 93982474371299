import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store';
import {
  addLoadingId,
  removeLoadingId,
  clearLoadingIds,
  loadingSelector,
} from '../store/loading';

import { FULLSCREEN_LOADING } from '../constants/loading';

/**
 * 10秒間
 */
const SECONDS = 1000;
const TIME_TOUT = 10000 * SECONDS;

/**
 * ローディングhook
 * @returns {{
 *   addLoading: (seconds?: number) => string;
 *   removeLoading: (loadingId: string) => void;
 *   clearLoading: () => void;
 *   hasLoading: (oadingId: string) => boolean;
 * }}
 */
function useLoading() {
  const [timer, setTimer] = useState(null);
  const dispatch = useDispatch();
  const { list } = useSelector(loadingSelector);

  /**
   * 指定されたローディングIDを削除する
   * @param {string} id ローディングID
   */
  const removeLoading = (id = FULLSCREEN_LOADING) => {
    dispatch(removeLoadingId(id));
    const { currentList } = store.getState().loading;
    if (Array.isArray(currentList) && !currentList.length && timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  };

  /**
   * 全てのローディングIDを削除する
   */
  const clearLoading = () => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    const { currentList } = store.getState().loading;
    if (currentList.length) {
      dispatch(clearLoadingIds());
    }
  };

  /**
   * ローディングIDを追加する
   * @param {number} seconds
   * @returns string ローディングID
   */
  const addLoading = (id = FULLSCREEN_LOADING, seconds = 0) => {
    if (timer) clearTimeout(timer);
    // loading timeout
    setTimer(setTimeout(() => {
      removeLoading(id);
    }, seconds * SECONDS || TIME_TOUT));
    dispatch(addLoadingId(id));
  };

  /**
   * ローディングIDが存在するか
   */
  const hasLoading = useCallback((id = FULLSCREEN_LOADING) => {
    return list.includes(id);
  }, [list]);

  useEffect(() => {
    // destroy
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return {
    addLoading,
    removeLoading,
    clearLoading,
    hasLoading,
  };
}

export default useLoading;
