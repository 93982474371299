/* eslint-disable max-len */
import { useState } from 'react';
// import { useState } from 'react';
import ChangeManageWindowContainer from '../../../../containers/common/customer/changeManagerWindow/changeManageWindowContainer';

function ChangeManageWindow(props) {
  const {
    componentLabel,
    opener,
    setOpener,
    useCustomOpener,
    useCustomBtn,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    handleSelectFunction,
    clickableUserAndDivision,
  } = props;

  // **useCustomOpener === true : ChangeManageWindowの外側からopen, setOpenの操作をしたいときのflg
  // **useCustomBtn === true : ChangeManageWindowのデフォルトボタンを消したい時のflg

  const [open, setOpen] = useState(false);

  return (
    <ChangeManageWindowContainer
      componentLabel={componentLabel}
      open={useCustomOpener ? opener : open}
      setOpen={useCustomOpener ? setOpener : setOpen}
      useCustomBtn={useCustomBtn}
      selectUser={selectUser}
      setSelectUser={setSelectUser}
      selectDivision={selectDivision}
      setSelectDivision={setSelectDivision}
      handleSelectFunction={handleSelectFunction}
      clickableUserAndDivision={clickableUserAndDivision}
    />
  );
}

export default ChangeManageWindow;
