import { useState, useEffect } from 'react';

import allocationSettingGetApi from '../../apis/allocationSetting/allocationSettingGetApi';
import allocationSettingCsvUploadApi from '../../apis/allocationSetting/allocationSettingCsvUploadApi';
import allocationSettingCsvDownloadApi from '../../apis/allocationSetting/allocationSettingCsvDownloadApi';
import allocationSettingGetOnAreaCodeApi from '../../apis/allocationSetting/allocationSettingGetOnAreaCodeApi';
import allocationSettingDeleteApi from '../../apis/allocationSetting/allocationSettingDeleteApi';
import areaWishCityCountUpdateApi from '../../apis/allocationSetting/areaWishCityCountUpdateApi';

import AllocationSettingWrapper from '../../components/allocationSetting/allocationSetting';

import { downloadCSV as downloadCSVFunc } from '../../commonFunction/csv';

function AllocationSettingContainers() {
  const [selectedAreaCode, setSelectedAreaCode] = useState();
  const [allocationTabeData, setAllocationTabeData] = useState([]);

  const [file, setFile] = useState(null);

  const getAllocationSetting = () => {
    if (selectedAreaCode) {
      allocationSettingGetApi({
        areaCode: selectedAreaCode,
      })
        .then((res) => {
          setAllocationTabeData(Array.isArray(res.data) ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAllocationTabeData([]);
    }
  };

  const getTempAllocationSetting = () => {
    if (selectedAreaCode) {
      allocationSettingGetOnAreaCodeApi(selectedAreaCode)
        .then((res) => {
          setAllocationTabeData(Array.isArray(res.data) ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAllocationTabeData([]);
    }
  };

  const deleteAllocationSetting = () => {
    allocationSettingDeleteApi()
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const updateWishCityCount = (param) => {
    areaWishCityCountUpdateApi(param)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const downloadCsv = () => {
    allocationSettingCsvDownloadApi({ areaCode: selectedAreaCode })
      .then((res) => downloadCSVFunc(res.data))
      .catch((err) => console.log(err));
  };

  const uploadCsv = (isTest = false) => {
    const formData = new FormData();
    formData.append('csvFile', file);
    formData.append('isTest', isTest ? 1 : 0);
    formData.append('areaCode', selectedAreaCode);

    allocationSettingCsvUploadApi(formData)
      .then(() => {
        if (isTest) {
          getTempAllocationSetting();
        } else {
          getAllocationSetting();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (file) {
      getTempAllocationSetting();
    } else {
      getAllocationSetting();
    }
  }, [selectedAreaCode]);

  return (
    <AllocationSettingWrapper
      selectedAreaCode={selectedAreaCode}
      setSelectedAreaCode={setSelectedAreaCode}
      tableData={allocationTabeData}
      getAllocationSetting={getAllocationSetting}
      file={file}
      setFile={setFile}
      uploadCsv={uploadCsv}
      downloadCsv={downloadCsv}
      deleteAllocationSetting={deleteAllocationSetting}
      updateWishCityCount={updateWishCityCount}
    />
  );
}

export default AllocationSettingContainers;
