import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const libraryCopySlice = createSlice({
  name: 'libraryCopy',
  initialState: { libraryCopy: initialState },
  reducers: {
    libraryCopyApi: (state, data) => {
      state.libraryCopy = data.payload;
    },
  },
});

export const { libraryCopyApi } = libraryCopySlice.actions;

export default libraryCopySlice.reducer;
