import { useState, useEffect } from 'react';
import ResHouse from '../../../components/common/resHouse/resHouse';
// 問合せ物件登録API
import resHouseRegisterApi from '../../../apis/resHouse/resHouseRegisterApi';
// 問合せ物件削除API
import resHouseDeleteApi from '../../../apis/resHouse/resHouseDeleteApi';
import { useLoading } from '../../../hooks';
import resHouseGetApi from '../../../apis/resHouse/resHouseGetApi';

function ResHouseContainers(props) {
  const { refreshing, onRefresh, customerId, customer } = props;
  const { addLoading, removeLoading } = useLoading();
  const [houseList, setHouseList] = useState([]);

  // 物件登録
  const registerResHouseFunction = async (params) => {
    await resHouseRegisterApi(params)
      .then(async (res) => {
        console.log({ registeredRes: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 物件削除
  const deleteResHouseFunction = async (inquiryProductId) => {
    await resHouseDeleteApi(inquiryProductId)
      .then(async (res) => {
        console.log({ deletedResHouseRes: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 物件リスト取得
  const getResHouseFunction = (customerID) => {
    resHouseGetApi(customerID)
      .then((res) => setHouseList(res.data.products))
      .catch((err) => console.log(err));
  };

  // 物件削除＋物件リストの更新
  const deleteAndUpdateHouseList = async (inquiryProductId) => {
    addLoading('reHouse');
    await deleteResHouseFunction(inquiryProductId);
    await getResHouseFunction(customerId);
    removeLoading('reHouse');
  };

  const registerNewProdAndUpdateHouseList = async (newProdObj) => {
    addLoading('reHouse');
    await registerResHouseFunction(newProdObj);
    await getResHouseFunction(customerId);
    removeLoading('reHouse');
  };

  useEffect(async () => {
    // 問合せ物件リスト取得
    addLoading('reHouse');
    await getResHouseFunction(customerId);
    removeLoading('reHouse');
  }, [customer, refreshing]);

  return (
    <ResHouse
      deleteAndUpdateHouseList={deleteAndUpdateHouseList}
      registerNewProdAndUpdateHouseList={registerNewProdAndUpdateHouseList}
      refreshing={refreshing}
      onRefresh={onRefresh}
      customerId={customerId}
      customer={customer}
      houseList={houseList}
    />
  );
}

export default ResHouseContainers;
