import client from '../httpClient';

/**
 * 【hpInfo005】コンテンツ閲覧履歴取得API
 * http://10.22.2.48:8080/docs/#/members/get-members-contents-histories-member-id
 * @param {{
 * memberId: number;
 * hashCustomerId?: string;
 * customerId?: number;
 * offset: number;
 * limit: number;
 * }} params
 * @returns {Promise<{total: number; histories: *[]}>}
 */
const hpinfoGetContentsHistoriesApi = async (params) => {
  try {
    const res = await client.get('/members/contents-histories', { params });
    return res.data;
  } catch (e) {
    console.error(e);
    return {
      total: 0,
      histories: [],
    };
  }
};

export default hpinfoGetContentsHistoriesApi;
