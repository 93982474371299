import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ClaimRegister from '../../common/claimRegister';
import claimUpdateApi from '../../../apis/claim/claimUpdateApi';
import claimGetListApi from '../../../apis/claim/claimGetListApi';
import ButtonGroup from './buttonGroup';
import { CLAIM_OPERATION_TYPES } from '../../../constants/apiParameterCode';
import { crmPageSelector } from '../../../store/crmPage';
import { useLoading } from '../../../hooks';

function Claim(props) {
  const { customerId, searchStart } = props;
  const { selectedCustomerRow } = useSelector(crmPageSelector);
  const { addLoading, removeLoading } = useLoading();

  const [state, setState] = useState({
    // クレーム登録ウィンドウを表示するか
    isOpenDialog: false,
    // 表示/編集か
    isEdit: false,
  });

  // クレームが登録されているか
  const [hasClaims, setHasClaims] = useState(false);

  // GET(取得)
  const getClaim = useCallback(async (cid) => {
    addLoading();
    const res = await claimGetListApi(cid);
    // クレームが登録されているか
    setHasClaims(res.data.claims?.length > 0);
    removeLoading();
  }, []);

  // クレーム解除
  const cancelClaim = useCallback(async () => {
    addLoading();
    await claimUpdateApi({
      type: CLAIM_OPERATION_TYPES.CANCEL,
      customerId,
    });
    removeLoading();
    searchStart();
  }, [customerId]);

  useEffect(() => {
    customerId && getClaim(customerId);
  }, [customerId]);

  const data = {
    label: ['クレーム', '慎重に操作してください。'],
    items: [
      {
        label: '表示/編集',
        disabled: !hasClaims,
        onClick: () => setState({
          ...state,
          isOpenDialog: true,
          isEdit: true,
        }),
      },
      {
        label: '登録',
        // disabled: !!state.claimId,
        onClick: () => setState({
          ...state,
          isOpenDialog: true,
          isEdit: false,
        }),
      },
      {
        label: '解除',
        disabled: !hasClaims,
        onClick: cancelClaim,
      },
    ],
  };

  return (
    <>
      <ButtonGroup data={data} />
      <ClaimRegister
        open={state.isOpenDialog}
        onClose={() => {
          setState({
            ...state,
            isOpenDialog: false,
          });
        }}
        setStatus={searchStart}
        isEdit={state.isEdit}
        customerId={customerId}
        responsibleUserId={selectedCustomerRow?.userId}
      />
    </>
  );
}

export default Claim;
