import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DetailSearch from '.';
// 反響ツリーのパラメータsetのdispatch関数
import { updateObjSearchItems } from '../../../store/search/searchItemsSlice';
import CustomerTree from '../../common/customerTree/customerTree';

const useStyles = makeStyles({
  searchTestRoot: {
    '& .MuiButtonBase-root[class*="searchButton"]': {
      zIndex: '4444!important',
    },
  },
});

function SearchTest() {
  // ここでGlobalStateを定義
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);

  // ツリー表示/非表示
  const [isOpen, setIsOpen] = useState(isSP); // 初期ロード時：PCはfalse / SPはtrue
  // 反響ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  // 反響ツリー選択組織
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });
  // 反響ツリー選択顧客
  const [selectCustomer, setSelectCustomer] = useState([]);
  // [division005]パラメータ（typeCode, unitCodeは画面から選択する為不要）
  // Dialog形式かどうか
  const [isModal] = useState(false);
  // 検索ボックスの表示/非表示
  const [searchForm] = useState(true);
  // 検索と選択対象
  const [targetFlgList] = useState({
    userFlg: true,
    divisionFlg: true,
    customerFlg: true,
  });
  const defaultUserId = null;

  // 反響ツリーのパラメータsetは下記
  useEffect(() => {
    dispatch(updateObjSearchItems({
      userLevelMin: 1, // 重要情報 - ランク
      userLevelMax: 5, // 重要情報 - ランク
    }));
    console.log(selectUser);
    console.log(selectDivision);
    console.log(selectCustomer);
    console.log(selectUser);
    console.log(selectUser);
    console.log({ isOpen });
  }, []);

  useEffect(() => {
    console.log({ isOpen });
    setIsOpen(isSP);
  }, [isSP]);

  return (
    <Grid className={classes.searchTestRoot}>
      <DetailSearch />
      <CustomerTree
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setSelectUser={setSelectUser}
        setSelectDivision={setSelectDivision}
        setSelectCustomer={setSelectCustomer}
        isModal={isModal}
        searchForm={searchForm}
        targetFlgList={targetFlgList}
        defaultUserId={defaultUserId}
      />
      {/* <div>
        {search.map((s, i) => (
          <p>{`${i}:${s}`}</p>
        ))}
      </div> */}
    </Grid>
  );
}

export default SearchTest;
