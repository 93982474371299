import InstructionsWindowContainers from '../../../../containers/common/taiouRireki/instructionsWindow/instructionsWindowContainers';

function InstructionsWindow(props) {
  const {
    open,
    setOpen,
    onClose,
    direct,
    newInstructionFlg,
    getDetailDirect,
    getDirects,
    customerId, // 登録時のみ
    divisionId, // 登録時のみ
    userId, // 登録時のみ
    userFirstName, // 登録時のみ
    userLastName, // 登録時のみ
    customer, // SPのみ
    isSp, // SPのみ
    updatePage = () => {},
    onSubmit = () => {},
  } = props;

  const resultComponent = () => {
    // 指示更新
    if (direct) {
      return (
        <InstructionsWindowContainers
          open={open}
          setOpen={setOpen}
          onClose={onClose}
          direct={direct}
          newInstructionFlg={newInstructionFlg}
          getDetailDirect={getDetailDirect}
          getDirects={getDirects}
          isSp={isSp}
        />
      );
    }
    return null;
  };

  const resultNewComponent = () => {
    // 現在の年月日時分秒を定義
    const now = new Date();
    const year = now.getFullYear();
    const month = ('00' + (now.getMonth() + 1)).slice(-2);
    const day = ('00' + now.getDate()).slice(-2);
    const dateInitial = year + '/' + month + '/' + day + ' 21:00';

    const createState = {
      isManager: 1,
      isCenterManager: 1,
      reportCode: 0,
      directCode: 1,
      isDirectMail: 1, // 「する（1）」で決め打ち
      isReportMail: 1, // 「する（1）」で決め打ち
      approveCode: 1,
      isRemind: 1, // 「する（1）」で決め打ち
      customerId,
      directedUserId: userId,
      directedDivisionId: divisionId,
      expireAt: dateInitial,
      directReportStatusCode: 1,
      importantCode: 2,
      content: null,
      others: [],
      timelines: [],
      directUserLastName: userLastName,
      directUserFirstName: userFirstName,
    };
    // 指示登録
    return (
      <InstructionsWindowContainers
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        direct={createState}
        newInstructionFlg={newInstructionFlg}
        getDetailDirect={() => console.log('新規指示')}
        getDirects={() => console.log('新規指示')}
        createState={createState}
        customer={customer}
        updatePage={updatePage}
        customerId={customerId}
        onSubmit={onSubmit}
      />
    );
  };

  return (
    newInstructionFlg ? resultNewComponent() : resultComponent()
  );
}

export default InstructionsWindow;
