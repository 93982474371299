import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import commonStyles from '../../styles';
// import CustomAlertMessage from '../../eleCommon/customAlertMessage';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import CommonDialog from '../../common/modal';

const useStyles = makeStyles((theme) => ({
  blue: {
    color: theme.palette.primaryColor,
    // marginRight: '16px',
  },
  itemWrap: {
    border: '1px #c8c8c8 solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 8px',
    marginBottom: '8px',
    flexWrap: 'nowrap',
    '& > *:first-child': { flexShrink: 0 },
    '& > *:nth-child(3)': { flexShrink: 0, marginLeft: 8 },
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  text: {
    marginRight: 'auto',
    marginLeft: '32px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  box: {
    marginTop: 8,
    '& > .MuiFormControl-root': {
      width: '100%',
    },
  },
  boxItem: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 10,
    marginLeft: 0,
    marginRight: 0,
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  checkbox: {
    marginRight: 10,
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  mailTitle: {
    marginRight: 16,
  },
}));

export default function ChangeTo(props) {
  const { values, user, updateFunction } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const common = commonStyles();
  const [state, setState] = useState(values.check);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };

  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  const closeandchange = () => {
    const sendData = () => {
      const list = Object.keys(state).filter(s => {
        return state[s] === true;
      });
      let result = '';
      for (let j = 0; j < list.length; j += 1) {
        if (j !== 0) { result += ', '; }
        result += user[list[j]];
      }
      return result;
    };
    if (sendData().length === 0) {
      dispatch(changeAlertMessage({ msgList: ['・送信先のメールアドレスを１つ以上チェックしてください。'] }));
    } else {
      const parseLimitAt = () => {
        const before = new Date();
        before.setDate(before.getDate() + 7);
        const Y = before.getFullYear();
        const M = before.getMonth() + 1 < 10 ? `0${before.getMonth() + 1}` : before.getMonth() + 1;
        const D = before.getDate() < 10 ? `0${before.getDate()}` : before.getDate();
        const H = before.getHours() < 10 ? `0${before.getHours()}` : before.getHours();
        const m = before.getMinutes() < 10 ? `0${before.getMinutes()}` : before.getMinutes();
        const s = before.getSeconds() < 10 ? `0${before.getSeconds()}` : before.getSeconds();
        return `${Y}/${M}/${D} ${H}:${m}:${s}`;
      };
      const param = {
        updateType: 1,
        isMailSend1: state.mail1 ? 1 : 0,
        isMailSend2: state.mail2 ? 1 : 0,
        isMailSend3: state.mail3 ? 1 : 0,
        isMailSend4: state.mail4 ? 1 : 0,
        isNotCheckUpdate: 0,
        getDataAt: parseLimitAt(),
      };
      updateFunction(user.customerId, param);
      values.setCheck(state);
      values.setList(sendData());
      handleClose();
    }
  };

  const checkboxList = () => {
    const list = [];
    for (let i = 1; i < 5; i += 1) {
      list.push(
        <FormControlLabel
          key={i}
          className={classes.boxItem}
          control={
            <Checkbox
              color="primary"
              onChange={handleChange}
              className={classes.checkbox}
              checked={state['mail' + i]}
              disabled={!user['mail' + i]}
              name={`mail${i}`}
            />
          }
          label={
            <span className={classes.checkboxLabel}>
              <Typography className={`${common.title6} ${classes.mailTitle}`}>Mail{i}</Typography>
              <Typography className={common.title3}>{user['mail' + i] ? user['mail' + i] : '-'}</Typography>
            </span>
          }
        />,
      );
    }
    return list;
  };

  return (
    <Grid container className={`${classes.itemWrap} ${values.disabled ? classes.disable : ''}`} style={values.style}>
      <Typography className={common.title4}>
        {values.label}
        <span style={{ color: '#D83420' }}>＊</span>
      </Typography>
      <Typography className={`${common.small} ${classes.text}`}>{values.list}</Typography>
      <Button
        type="button"
        onClick={handleOpen}
        disabled={values.disabled}
      >
        <Typography className={`${common.strong} ${classes.blue}`}>変更</Typography>
      </Button>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title="送信先変更ウィンドウ"
        maxWidth={450}
        buttons={[
          {
            label: 'キャンセル',
            onClick: handleClose,
            type: 'secondary',
          },
          {
            label: '送信先を変更',
            onClick: closeandchange,
          },
        ]}
      >
        <Grid item className={classes.middle}>
          <Typography className={classes.bass}>送信先を選択してください。</Typography>
          <Grid className={classes.box}>
            <FormControl component="change">
              {checkboxList()}
            </FormControl>
          </Grid>
        </Grid>
      </CommonDialog>
    </Grid>
  );
}
