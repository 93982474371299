import React from 'react';
import AddAndEditDivisonContainers from '../../containers/addAndEditDivision/addAndEditDivisonContainers';

function AddAndEditDivision(props) {
  const { selectDivision, userDivListFunction, registerHandler } = props;
  return (
    <AddAndEditDivisonContainers
      selectDivision={selectDivision}
      userDivListFunction={userDivListFunction}
      registerHandler={registerHandler}
    />
  );
}

export default AddAndEditDivision;
