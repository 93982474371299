import React, { useState, useMemo } from 'react';
import {
  Grid,
  Typography,
  Link,
  ListItem,
  makeStyles,
  List,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import commonStyles from '../../../styles';
import dashboardStyle from './dashBoardStyles';
import InstructionReportWindowSp from '../../../common/instructionReport/sp/instructionReportWindowSp';
import DirectReportStatusCode from '../../../common/instructionReport/parts/directReportStatusCode';
import MemberStatusCode from '../../../eleCommon/memberStatusCode';
import DateExpiredWrap from './dateExpiredWrap';
import { sortByDate } from '../../../../commonFunction';
import { URL_MAPPER } from '../../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  headerLinkTxt: {
    color: theme.palette.primaryColor,
  },
  wrap: {
    textAlign: 'center',
    padding: '40px 0',
  },
  nowrap: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'Ellipsis', width: 305 },
  itemWrap: { borderBottom: '1px #c8c8c8 solid', padding: '0 16px', overflow: 'hidden', background: '#fff' },
  txtWrap: { flexGrow: 1, padding: '16px 0', marginLeft: 8 },
  status: {
    marginLeft: 8,
    '& > span': {
      background: '#333',
      color: '#fff',
      fontSize: 10,
      padding: '4px 6px',
      minWidth: 52,
      display: 'block',
      textAlign: 'center',
    },
  },
  name: {
    width: '8.1em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  user: {
    width: '14em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  importance: {
    marginRight: 8,
  },
}));

function DistDate(props) {
  const { data, today } = props;
  const baseClasses = commonStyles();

  const getexpireAttime = new Date(data.expireAt);
  const objTime = {
    year: getexpireAttime.getFullYear(),
    month: getexpireAttime.getMonth() + 1 < 10 ? `0${getexpireAttime.getMonth() + 1}` : getexpireAttime.getMonth() + 1,
    date: getexpireAttime.getDate() < 10 ? `0${getexpireAttime.getDate()}` : getexpireAttime.getDate(),
    hour: getexpireAttime.getHours() < 10 ? `0${getexpireAttime.getHours()}` : getexpireAttime.getHours(),
    minute: getexpireAttime.getMinutes() < 10 ? `0${getexpireAttime.getMinutes()}` : getexpireAttime.getMinutes(),
  };
  return (
    <DateExpiredWrap date={data.expireAt}>
      {today.year !== objTime.year ? (
        <span className={baseClasses.title5}>{objTime.year}</span>
      ) : null}
      <span className={baseClasses.title4}>{`${getexpireAttime.getMonth() + 1}/${getexpireAttime.getDate()}`}</span>
      <span className={baseClasses.title5}>{`-${getexpireAttime.getHours()}:${objTime.minute}`}</span>
    </DateExpiredWrap>
  );
}

const judgeImportance = (importantCode) => {
  let result = '';
  switch (importantCode) {
    case 1:
      result = '低';
      break;
    case 2:
      result = '中';
      break;
    case 3:
      result = '高';
      break;
    default:
      result = '';
  }
  return result;
};

function ListItemComponent(props) {
  const { data, today } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const dCommon = dashboardStyle();

  const [reportWinVisible, setReportWinVisible] = useState(false);

  const onReportWinClose = () => {
    setReportWinVisible(false);
  };

  return (
    <>
      <ListItem
        onClick={() => setReportWinVisible(true)}
        className={classes.itemWrap}
      >
        <DistDate today={today} data={data} />
        <Grid className={classes.status}>
          <DirectReportStatusCode code={data.directStatusCode} />
        </Grid>
        <Grid className={classes.txtWrap}>
          <Grid container alignItems="center">
            <Typography className={`${baseClasses.strong} ${classes.name}`}>
              {data.customerLastName} {data.customerFirstName}
            </Typography>
            <Rating
              value={data.userLevel}
              size="small"
              className={dCommon.stars}
              readOnly
            />
            <MemberStatusCode
              code={data.memberStatusCode}
              hideNonMemberIcon
              thin
            />
          </Grid>
          <Grid container alignItems="center">
            <Typography className={classes.importance}>
              <span className={baseClasses.verySmall}>重要度 </span>
              <span className={baseClasses.small}>{judgeImportance(data.importantCode)}</span>
            </Typography>
            <Typography className={classes.user}>
              <span className={baseClasses.verySmall}>担当 </span>
              <span className={baseClasses.small}>
                {data.divisionSubName}/{data.userLastName} {data.userFirstName}
              </span>
            </Typography>
          </Grid>
          <Typography className={`${classes.nowrap} ${baseClasses.small}`}>
            {data.content}
          </Typography>
        </Grid>
      </ListItem>
      {/* 指示・報告確認画面 */}
      <InstructionReportWindowSp
        open={reportWinVisible}
        setOpen={setReportWinVisible}
        onClose={onReportWinClose}
        tab={0}
        row={data}
        customerId={data.customerId}
        getDirects={() => console.log('getDirects')}
        setResetRender={() => console.log('setResetRender')}
        customer={data}
      />
    </>
  );
}

function IncompleteListSp(props) {
  const { dataList } = props;
  const { total = 0, directs = [] } = dataList.data;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const objToday = useMemo(() => {
    const today = new Date();
    return {
      year: today.getFullYear(),
      month: today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1,
      date: today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
      hour: today.getHours() < 10 ? `0${today.getHours()}` : today.getHours(),
      minute: today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes(),
    };
  }, []);

  // 順番がバラバラので、昇順でソートする
  const list = useMemo(() => {
    return sortByDate(directs, 'expireAt');
  }, [directs]);

  return (
    <Grid>
      {!total ? (
        <Grid className={classes.wrap}>
          <Typography className={`${baseClasses.strong}`}>0 件</Typography>
        </Grid>
      ) : (
        <List>
          {list.map(l => (
            <ListItemComponent
              key={`key${l.directId}`}
              data={l}
              today={objToday}
            />
          ))}
        </List>
      )}
      <Grid container justifyContent="center">
        <Link
          className={baseClasses.buttonsSecondary}
          href={URL_MAPPER.direct}
        >
          <Typography className={`${baseClasses.smallStrong} ${classes.headerLinkTxt}`}>
            指示一覧で確認
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default IncompleteListSp;
