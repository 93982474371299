import { useState, useEffect, useRef } from 'react';
import {
  FormControl,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  // FormControlLabel,
  TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    flexDirection: 'row',
  },
  wrap: {
    marginLeft: '24px.',
    width: '100%',
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
  },
  itemWrap: {
    border: '1px #c8c8c8 solid',
    // justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 8px',
    marginBottom: '8px',
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  // モーダル
  paper2: {
    width: 515,
    backgroundColor: '#fff',
    boxShadow:
      '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0',
  },
  DialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '1.2rem',
  },
  confirmationModalDialog: {
    display: 'flex',
    justifyContent: 'center',
  },
  libNameLabel: {
    width: 'calc(33% - 3.5px)',
    // '& .MuiFormControlLabel-label': {
    //   fontSize: '0.8rem',
    //   overflow: 'hidden',
    //   whiteSpace: 'nowrap',
    //   textOverflow: 'ellipsis',
    // },
  },
  libNameLabelText: {
    fontSize: 16,
  },
  libNameEditable: {
    width: 'calc(33% - 3.5px)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  libNameTxtField: {
    width: '80%',
  },
}));

function MailRadio(props) {
  // eslint-disable-next-line max-len
  const {
    values,
    updateParam,
    setUpdateParam,
    setEditorState,
    meta,
    setMeta,
    watchEdit = false,
    setWatchEdit = false,
    libraries,
    updateLibrary,
    setUploadedList,
    libNameEditable,
  } = props;
  const dispatch = useDispatch();
  const menusKeys = Object.keys(values.constants.menus);
  const classes = useStyles();
  const common = commonStyles();

  // libraryName
  const [libName, setLibName] = useState('');
  const oldState = useRef(0);

  // API結合時に調整
  const onTxtFieldChangeHandler = (ev) => {
    setLibName(ev.target.value);
  };

  const changeLibrary = (id) => {
    if (meta.type === 1) {
      if (setUpdateParam !== undefined) {
        setUpdateParam({
          ...updateParam,
          libraryName: libraries.mail[id].libraryName,
          mailSubject: libraries.mail[id].mailSubject,
          attachments: libraries.mail[id].attachments,
          mailBody: libraries.mail[id].mailBody,
        });
      }
      if (setEditorState !== undefined) {
        setEditorState(libraries.mail[id].mailBody);
      }
      setMeta({
        ...meta,
        kind: id,
        title: libraries.mail[id].mailSubject,
        content: libraries.mail[id].mailBody,
      });
      setUploadedList(libraries.mail[id].attachments);
      if (setWatchEdit) {
        return setTimeout(() => {
          setWatchEdit(false);
        }, 500);
      }
      return false;
    }
    if (setUpdateParam !== undefined) {
      setUpdateParam({
        ...updateParam,
        libraryName: libraries.sms[id].libraryName,
        mailBody: libraries.sms[id].mailBody,
      });
    }
    setMeta({
      ...meta,
      kindSms: id,
      content: libraries.sms[id].mailBody,
    });
    if (setWatchEdit) {
      return setTimeout(() => {
        setWatchEdit(false);
      }, 500);
    }
    return false;
  };

  const updateAction = async (id) => {
    await updateLibrary();
    changeLibrary(id);
  };

  // FIXME: ラジオボタンを押したときの処理は親コンポーネントで定義したい
  const onRadioBtnChangeHandler = (ev) => {
    const id = Number(ev.target.value);
    const isChangeLibName = values.constants.menus[oldState.current] !== libName;
    if (libNameEditable && (watchEdit || isChangeLibName)) {
      // 編集していた場合
      dispatch(changeConfirmMessage({
        msgList: ['変更を保存しますか？'], // 必須
        title: '不通時メールの内容が更新されています。', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
        buttons: [{
          label: 'CANCEL', // ボタンラベル
          set: () => changeLibrary(id), // ボタンをクリックしたときの処理
          classes: common.buttonsSecondary, // ボタンのクラス
        }, // エラーではない場合。設定しなければ文字色、背景が赤くなる
        {
          label: 'OK', // ボタンラベル
          set: () => updateAction(id), // ボタンをクリックしたときの処理
          classes: common.buttonsPrimary, // ボタンのクラス
        }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
      }));
    } else {
      changeLibrary(id);
    }
  };

  useEffect(() => {
    setLibName(values.constants.menus[values.state]);
    oldState.current = values.state;
  }, [values.state]);

  return (
    <Grid container className={classes.itemWrap} style={values.style}>
      <Typography className={`${classes.label} ${common.title4}`}>{values.label}</Typography>

      <FormControl className={classes.wrap}>
        <RadioGroup
          label={values.label}
          value={Number(values.state)}
          onChange={(ev) => {
            onRadioBtnChangeHandler(ev);
          }}
          className={classes.radioGroup}
        >
          {menusKeys.map(e => {
            if (Number(values.state) === Number(e)) {
              return (
                <Grid className={classes.libNameEditable} key={`select${e}`}>
                  <Radio color="primary" value={Number(e)} />
                  <TextField
                    required
                    id="standard-required"
                    defaultValue={values.constants.menus[e]}
                    variant="standard"
                    onChange={(ev) => onTxtFieldChangeHandler(ev, e)}
                    onBlur={() => {
                      // setWatchEdit(true);
                      setUpdateParam?.({ ...updateParam, libraryName: libName });
                    }}
                    className={classes.libNameTxtField}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid className={classes.libNameEditable} key={`select${e}`}>
                  <Radio color="primary" value={Number(e)} />
                  <span className={classes.libNameLabelText}>{values.constants.menus[e]}</span>
                </Grid>
              );
            }
          })}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export default MailRadio;
