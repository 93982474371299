import client from '../httpClient';

/**
 * 【hpInfo007】お気に入り物件情報取得API
 * http://10.22.2.48:8080/docs/#/members/get-members-favorites
 * @param {{
 * memberId: number;
 * hashCustomerId?: string;
 * customerId?: number;
 * offset: number;
 * limit: number;
 * }} params
 * @returns {Promise<{total: number; favorites: *[]}>}
 */
const hpinfoGetFavoriteApi = async (params) => {
  try {
    const res = await client.get('/members/favorites', { params });
    return res.data;
  } catch (e) {
    console.error(e);
    return {
      total: 0,
      favorites: [],
    };
  }
};

export default hpinfoGetFavoriteApi;
