import { useState, useContext, createContext } from 'react';

const Context = createContext({});

export function useUserTreeContext() {
  return useContext(Context);
}

export function UserTreeContextProvider(props) {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isLoading,
    setIsLoading,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}
