import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  divisionMasterList: [],
};

export const getAllSettingSlice = createSlice({
  name: 'getAllSetting',
  initialState: { getAllSetting: initialState },
  reducers: {
    getAllSettingApi: (state, data) => {
      state.getAllSetting = data.payload;
    },
  },
});

export const { getAllSettingApi } = getAllSettingSlice.actions;

export default getAllSettingSlice.reducer;
