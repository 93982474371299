import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const areaWishCityCountUpdateSlice = createSlice({
  name: 'areaWishCityCountUpdate',
  initialState: { areaWishCityCountUpdate: initialState },
  reducers: {
    areaWishCityCountUpdateApi: (state, data) => {
      state.areaWishCityCountUpdate = data.payload;
    },
  },
});

export const { areaWishCityCountUpdateApi } = areaWishCityCountUpdateSlice.actions;

export default areaWishCityCountUpdateSlice.reducer;
