import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Checkbox, List, ListItem } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import HolidayTel from '../../holidayTel';
import ContactTime from '../../contactTime';
import NormalTooltip from '../../../styles/tooltip';
import inputNumber from '../../../../commonFunction/inputNumber';
import { checkMailVaildation } from '../../../../commonFunction/mail';
import { TextBaseField } from '../../../eleCommon/validation';
import { validateFormTelNum, validateFormString1, validateFormEmail } from '../../../../commonFunction/validations';
import { convertForm1 } from '../../../../commonFunction/convertors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 8,
    [commonTheme.breakpoints.up('md')]: {
      padding: 8,
    },
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // お休み
  holiday: {
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '6px 8px',
      margin: '8px 0',
      border: '1px solid #C8C8C8',
    },
  },
  holidayButton: {
    width: '100%',
    textAlign: 'left',
    borderRadius: 0,
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
      borderBottom: '1px solid #F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 0,
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 0,
    },
    '&:hover': {
      background: '#fff',
    },
  },
  holidayTxt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 986,
  },
  // 連絡がつきやすい時間帯
  time: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 8,
  },
  timeButton1: {
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    width: 860,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  timeButton2: {
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    width: 100,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  timeButton2Txt: {
    whiteSpace: 'nowrap',
  },
  timeButton3: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 0',
    width: 59,
  },
  timeTxt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  // TEL
  tel: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    width: 452,
  },
  telItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  telLbl: {
    width: 198,
    marginBottom: 4,
  },
  telTextField: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 198,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  telTextField1: {
    marginRight: 8,
    '& input': {
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 13,
        lineHeight: 1.6,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: 1.2,
      },
    },
  },
  telTextField2: {
    '& input': {
      fontSize: 13,
      lineHeight: 1.6,
    },
  },
  telClaim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  // Mail
  mail: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    width: 452,
  },
  mailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mailItem2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  mailLbl1: {
    width: 198,
    marginBottom: 4,
  },
  mailLbl2: {
    width: 198,
    marginBottom: 4,
  },
  mailLbl3: {
    width: 26,
    marginBottom: 4,
  },
  mailTextField: {
    display: 'block',
    '& input': {
      fontFamily: 'Roboto',
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      fontSize: 13,
      padding: 0,
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  mailTextField1: {
    [commonTheme.breakpoints.up('md')]: {
      width: 198,
    },
  },
  mailTextField2: {
    [commonTheme.breakpoints.up('md')]: {
      width: 198,
    },
  },
  mailChecked: {
    width: 26,
    padding: 0,
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  mailClaim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  // FAX
  fax: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    width: 160,
  },
  faxItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  faxLbl1: {
    margin: '0 0 6px 0',
  },
  faxLbl2: {
    margin: '0 8px 0 0',
  },
  faxTextField: {
    display: 'block',
    marginTop: '-3px',
    '& input': {
      fontFamily: 'Roboto',
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
      fontSize: 13,
      lineHeight: 1.6,
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  faxClaim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  list2: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 4,
  },
  listItem1: {
    position: 'relative',
    padding: 0,
    '&:nth-child(even)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listItem2: {
    position: 'relative',
    padding: 0,
    '&:nth-child(3n)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listItem3: {
    position: 'relative',
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    marginRight: 24,
  },
  listVal: {
    textTransform: 'none',
  },
  button2: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 0',
    width: 59,
    [commonTheme.breakpoints.down('sm')]: {
      minWidth: 59,
    },
  },
}));

export default function CustomerDetailContact(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // チェックボックス
  const [isMailSendState, setIsMailSendState] = useState({
    isMailSend1: Boolean(input.detail.isMailSend1),
    isMailSend2: Boolean(input.detail.isMailSend2),
    isMailSend3: Boolean(input.detail.isMailSend3),
    isMailSend4: Boolean(input.detail.isMailSend4),
  });

  // お休み / TEL時間帯 ウィンドウ
  const selectHolidayTel = {
    holidayWeekday: input.detail.holidayWeekday,
    otherHolidayWeekday: input.detail.otherHolidayWeekday,
    convenientTime: input.detail.convenientTime,
    otherConvenientTime: input.detail.otherConvenientTime,
  };
  const clickSelectBoxFunc = (data) => {
    // 変更した値をセット
    input.setDetail(
      {
        ...input.detail,
        holidayWeekday: data.holidayWeekday,
        otherHolidayWeekday: data.otherHolidayWeekday,
        convenientTime: data.convenientTime,
        otherConvenientTime: data.otherConvenientTime,
      },
    );
    // 更新用の値をセット
    input.dataInput.setData(
      {
        ...input.dataInput.data,
        holidayWeekday: data.holidayWeekday,
        otherHolidayWeekday: data.otherHolidayWeekday,
        convenientTime: data.convenientTime,
        otherConvenientTime: data.otherConvenientTime,
      },
    );
  };

  // フォーカスが外れた時
  const onBlur = (e) => {
    const { name, value } = e.target;
    if (['mail1', 'mail2', 'mail3', 'mail4'].includes(name)) {
      const mailIndex = name.slice(-1);
      const isMailSendLabel = `isMailSend${mailIndex}`;
      let isMailSendFlg = false;
      if (checkMailVaildation(value)) {
        isMailSendFlg = true;
      }
      // 表示変更
      setIsMailSendState({
        ...isMailSendState,
        [isMailSendLabel]: isMailSendFlg,
      });
      // 更新用
      input.setDetail({
        ...input.detail,
        [name]: value,
        [isMailSendLabel]: isMailSendFlg ? 1 : 0,
      });
      input.dataInput.setData({
        ...input.dataInput.data,
        [name]: value,
        [isMailSendLabel]: isMailSendFlg ? 1 : 0,
      });
    } else {
      input.setDetail({ ...input.detail, [name]: value });
      input.dataInput.setData({ ...input.dataInput.data, [name]: value });
    }
  };

  const onBlurPhoneNumber = (e) => {
    const { name, value } = e.target;
    input.setDetail({ ...input.detail, [name]: value });
    input.dataInput.setData({ ...input.dataInput.data, [name]: value });
  };

  // チェックボックス
  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setIsMailSendState({
      ...isMailSendState,
      [name]: checked,
    });
    // 変更した値をセット
    input.setDetail({ ...input.detail, [name]: checked ? 1 : 0 });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, [name]: checked ? 1 : 0 });
  };

  const getTextFieldPhoneNumber = (name) => {
    return (
      <TextBaseField
        type="tel"
        defaultValue={input.detail[name]}
        className={`${classes.telTextField} ${classes.telTextField1}`}
        name={name}
        onBlur={onBlurPhoneNumber}
        placeholder="-"
        validator={validateFormTelNum()}
        errorCallback={input.errorCallback(name)}
      />
    );
  };

  const getTextFieldTel = (field) => {
    return (
      <TextBaseField
        placeholder="-"
        defaultValue={input.detail[field]}
        className={`${classes.telTextField} ${classes.telTextField2}`}
        name={field}
        onBlur={onBlur}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 10 })}
        errorCallback={input.errorCallback(field)}
      />
    );
  };

  const getTextFieldMail = (field) => {
    return (
      <TextBaseField
        placeholder="-"
        defaultValue={input.detail[field]}
        className={`${classes.mailTextField} ${classes[/Memo/.test(field) ? 'mailTextField2' : 'mailTextField1']}`}
        name={field}
        onBlur={onBlur}
        validator={validateFormEmail()}
        errorCallback={input.errorCallback(field)}
      />
    );
  };

  const getTextFieldMailMemo = (field) => {
    return (
      <TextBaseField
        placeholder="-"
        defaultValue={input.detail[field]}
        className={`${classes.mailTextField} ${classes[/Memo/.test(field) ? 'mailTextField2' : 'mailTextField1']}`}
        name={field}
        onBlur={onBlur}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 10 })}
        errorCallback={input.errorCallback(field)}
      />
    );
  };

  const getCheckbox = (name) => {
    const mailNo = name.slice(-1);
    const mailAddress = input.detail[`mail${mailNo}`];
    return (
      <Checkbox
        checked={isMailSendState[name]}
        name={name}
        onChange={onCheckboxChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        className={classes.mailChecked}
        disabled={!mailAddress}
      />
    );
  };

  const getFax = () => {
    return (
      <TextBaseField
        placeholder="-"
        type="number"
        inputProps={{ min: '0' }}
        defaultValue={input.detail.fax}
        className={classes.faxTextField}
        name="fax"
        onBlur={onBlur}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 20 })}
        errorCallback={input.errorCallback('fax')}
      />
    );
  };

  // モーダル用
  // 連絡がつきやすい時間帯
  const [open1, setOpen1] = useState(false);
  // グラフ
  const [open2, setOpen2] = useState(false);
  // SP
  const [open20, setOpen20] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleOpen20 = () => {
    setOpen20(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose20 = () => {
    setOpen20(false);
  };

  const list1 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'TEL1',
      val: input.detail.phoneNumber1,
      fontSize: '20px',
      content: getTextFieldPhoneNumber.bind(null, 'phoneNumber1'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足1',
      val: input.detail.phoneNumberMemo1,
      content: getTextFieldTel.bind(null, 'phoneNumberMemo1'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'TEL2',
      val: input.detail.phoneNumber2,
      fontSize: '20px',
      content: getTextFieldPhoneNumber.bind(null, 'phoneNumber2'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足2',
      val: input.detail.phoneNumberMemo2,
      content: getTextFieldTel.bind(null, 'phoneNumberMemo2'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'TEL3',
      val: input.detail.phoneNumber3,
      fontSize: '20px',
      content: getTextFieldPhoneNumber.bind(null, 'phoneNumber3'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足3',
      val: input.detail.phoneNumberMemo3,
      content: getTextFieldTel.bind(null, 'phoneNumberMemo3'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'TEL4',
      val: input.detail.phoneNumber4,
      fontSize: '20px',
      content: getTextFieldPhoneNumber.bind(null, 'phoneNumber4'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足4',
      val: input.detail.phoneNumberMemo4,
      content: getTextFieldTel.bind(null, 'phoneNumberMemo4'),
    },
  ];

  const list2 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'Mail1',
      val: input.detail.mail1,
      content: getTextFieldMail.bind(null, 'mail1'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足1',
      val: input.detail.mailMemo1,
      content: getTextFieldMailMemo.bind(null, 'mailMemo1'),
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '送信1',
      val: isMailSendState.isMailSend1,
      content: getCheckbox.bind(null, 'isMailSend1'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'Mail2',
      val: input.detail.mail2,
      content: getTextFieldMail.bind(null, 'mail2'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足2',
      val: input.detail.mailMemo2,
      content: getTextFieldMailMemo.bind(null, 'mailMemo2'),
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '送信2',
      val: isMailSendState.isMailSend2,
      content: getCheckbox.bind(null, 'isMailSend2'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'Mail3',
      val: input.detail.mail3,
      content: getTextFieldMail.bind(null, 'mail3'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足3',
      val: input.detail.mailMemo3,
      content: getTextFieldMailMemo.bind(null, 'mailMemo3'),
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '送信3',
      val: isMailSendState.isMailSend3,
      content: getCheckbox.bind(null, 'isMailSend3'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'Mail4',
      val: input.detail.mail4,
      content: getTextFieldMail.bind(null, 'mail4'),
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足4',
      val: input.detail.mailMemo4,
      content: getTextFieldMailMemo.bind(null, 'mailMemo4'),
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '送信4',
      val: isMailSendState.isMailSend4,
      content: getCheckbox.bind(null, 'isMailSend4'),
    },
  ];

  const list3 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'FAX',
      val: input.detail.fax,
      content: getFax,
    },
  ];

  // TEL
  const getTelPc = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid className={classes.tel}>
            <Typography className={baseClasses.title6}>
              TEL
            </Typography>
            <Typography className={classes.telClaim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (input.detail.telStatusCode) {
          case 0:
            return (
              <Grid className={classes.tel}>
                <Typography className={baseClasses.title6}>
                  TEL
                </Typography>
                <Typography className={classes.telClaim}>
                  電話NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid className={classes.tel}>
                <Grid className={classes.telItem}>
                  <Typography className={`${baseClasses.title6} ${classes.telLbl}`}>
                    TEL
                  </Typography>
                  <Typography className={`${baseClasses.title6} ${classes.telLbl}`}>
                    補足
                  </Typography>
                </Grid>
                <Grid className={classes.telItem}>
                  {getTextFieldPhoneNumber('phoneNumber1')}
                  {getTextFieldTel('phoneNumberMemo1')}
                </Grid>
                <Grid className={classes.telItem}>
                  {getTextFieldPhoneNumber('phoneNumber2')}
                  {getTextFieldTel('phoneNumberMemo2')}
                </Grid>
                <Grid className={classes.telItem}>
                  {getTextFieldPhoneNumber('phoneNumber3')}
                  {getTextFieldTel('phoneNumberMemo3')}
                </Grid>
                <Grid className={classes.telItem}>
                  {getTextFieldPhoneNumber('phoneNumber4')}
                  {getTextFieldTel('phoneNumberMemo4')}
                </Grid>
              </Grid>
            );
        }
    }
  };
  const getTelSp = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              TEL
            </Typography>
            <Typography className={classes.telClaim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (input.detail.telStatusCode) {
          case 0:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  TEL
                </Typography>
                <Typography className={classes.telClaim}>
                  電話NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  TEL
                </Typography>
                <List className={classes.list}>
                  {list1.map((item) => {
                    return (
                      <ListItem
                        key={item.lbl}
                        className={classes.listItem1}
                      >
                        {input.dataInput.parts(item)}
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            );
        }
    }
  };

  // Mail
  const getMailPc = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid className={classes.mail}>
            <Typography className={baseClasses.title6}>
              Mail
            </Typography>
            <Typography className={classes.mailClaim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (input.detail.emailStatusCode) {
          case 0:
            return (
              <Grid className={classes.mail}>
                <Typography className={baseClasses.title6}>
                  Mail
                </Typography>
                <Typography className={classes.mailClaim}>
                  全部NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid className={classes.mail}>
                <Grid className={classes.mailItem}>
                  <Typography className={`${baseClasses.title6} ${classes.mailLbl1}`}>
                    Mail
                  </Typography>
                  <Typography className={`${baseClasses.title6} ${classes.mailLbl2}`}>
                    補足
                  </Typography>
                  <Typography className={`${baseClasses.title6} ${classes.mailLbl3}`}>
                    送信
                  </Typography>
                </Grid>
                <Grid className={classes.mailItem}>
                  {getTextFieldMail('mail1')}
                  {getTextFieldMailMemo('mailMemo1')}
                  {getCheckbox('isMailSend1')}
                </Grid>
                <Grid className={classes.mailItem}>
                  {getTextFieldMail('mail2')}
                  {getTextFieldMailMemo('mailMemo2')}
                  {getCheckbox('isMailSend2')}
                </Grid>
                <Grid className={classes.mailItem}>
                  {getTextFieldMail('mail3')}
                  {getTextFieldMailMemo('mailMemo3')}
                  {getCheckbox('isMailSend3')}
                </Grid>
                <Grid className={classes.mailItem}>
                  {getTextFieldMail('mail4')}
                  {getTextFieldMailMemo('mailMemo4')}
                  {getCheckbox('isMailSend4')}
                </Grid>
              </Grid>
            );
        }
    }
  };
  const getMailSp = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              Mail
            </Typography>
            <Typography className={classes.mailClaim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (input.detail.emailStatusCode) {
          case 0:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  Mail
                </Typography>
                <Typography className={classes.mailClaim}>
                  全部NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  Mail
                </Typography>
                <List className={classes.list}>
                  {list2.map((item) => {
                    return (
                      <ListItem
                        key={item.lbl}
                        className={classes.listItem2}
                      >
                        {input.dataInput.parts(item)}
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            );
        }
    }
  };

  // FAX
  const getFaxPc = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid className={classes.fax}>
            <Typography className={baseClasses.title6}>
              他の連絡手段
            </Typography>
            <Typography className={classes.faxClaim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid className={classes.fax}>
            <Grid className={classes.faxItem}>
              <Typography className={`${baseClasses.title6} ${classes.faxLbl1}`}>
                他の連絡手段
              </Typography>
            </Grid>
            <Grid className={classes.faxItem}>
              <Typography className={`${baseClasses.title6} ${classes.faxLbl2}`}>
                FAX
              </Typography>
              {getFax()}
            </Grid>
          </Grid>
        );
    }
  };
  const getFaxSp = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              他の連絡先
            </Typography>
            <Typography className={classes.faxClaim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              他の連絡先
            </Typography>
            <List className={classes.list}>
              {list3.map((item) => {
                return (
                  <ListItem
                    key={item.lbl}
                    className={classes.listItem1}
                  >
                    {input.dataInput.parts(item)}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        );
    }
  };

  const getSticsTels = () => {
    let text = '';
    for (let i = 0; i < input.detail.statisticsTels.length; i += 1) {
      const parts = input.detail.statisticsTels[i].split(':');
      const newTime = parts[0] + ':' + parts[1];
      if (text === '') {
        text = newTime;
      } else {
        text = text + '/' + newTime;
      }
    }
    return text;
  };

  const getStatusPc = () => {
    return (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          連絡先
        </Typography>
        <Grid className={classes.holiday}>
          <NormalTooltip title={`${input.detail.holidayWeekday ? input.detail.holidayWeekday + ' ' : ''}${input.detail.otherHolidayWeekday ? input.detail.otherHolidayWeekday : ''}`}>
            <Button
              className={classes.holidayButton}
              onClick={handleOpen1}
            >
              <Typography className={baseClasses.title6}>
                お休み
              </Typography>
              <Typography
                className={classes.holidayTxt}
              >
                {input.detail.holidayWeekday} {input.detail.otherHolidayWeekday}
              </Typography>
            </Button>
          </NormalTooltip>
        </Grid>
        <Grid className={classes.time}>
          <NormalTooltip title={`${input.detail.convenientTime ? input.detail.convenientTime + ' ' : ''}${input.detail.otherConvenientTime ? input.detail.otherConvenientTime : ''}`}>
            <Button className={classes.timeButton1} onClick={handleOpen1}>
              <Typography className={baseClasses.title6}>
                連絡がつきやすい時間帯
              </Typography>
              <Typography className={classes.timeTxt}>
                {input.detail.convenientTime} {input.detail.otherConvenientTime}
              </Typography>
            </Button>
          </NormalTooltip>
          <HolidayTel
            initialParam={selectHolidayTel}
            onClickSelectButton={clickSelectBoxFunc}
            open={open1}
            onClose={handleClose1}
            category="time"
          />
          <NormalTooltip title={getSticsTels()}>
            <Button
              className={classes.timeButton2}
              onClick={handleOpen2}
            >
              <Typography className={`${baseClasses.title6} ${classes.timeButton2Txt}`}>
                統計
              </Typography>
              <Typography className={classes.timeTxt}>
                {getSticsTels()}
              </Typography>
            </Button>
          </NormalTooltip>
          <Button
            variant="contained"
            className={`${baseClasses.buttonsPrimary} ${classes.timeButton3}`}
            onClick={handleOpen2}
          >
            グラフ
          </Button>
          <ContactTime
            open={open2}
            onClose={handleClose2}
            customerId={input.detail.customerId}
          />
        </Grid>
        <Grid className={classes.box}>
          {getTelPc()}
          {getMailPc()}
          {getFaxPc()}
        </Grid>
      </Grid>
    );
  };

  const getStatusSp = () => {
    return (
      <Grid className={classes.root}>
        {getTelSp()}
        <List className={classes.list2}>
          <ListItem
            className={classes.listItem3}
          >
            <Button
              className={classes.listBtn}
              onClick={handleOpen1}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                連絡がつきやすい時間帯
              </span>
              <span
                className={`${baseClasses.title4} ${classes.listVal}`}
              >
                {(input.detail.convenientTime ? input.detail.convenientTime : '') + ' ' + (input.detail.otherConvenientTime ? input.detail.otherConvenientTime : '')}
              </span>
            </Button>
            <HolidayTel
              initialParam={selectHolidayTel}
              onClickSelectButton={clickSelectBoxFunc}
              open={open1}
              onClose={handleClose1}
              category="time"
            />
          </ListItem>
        </List>
        <List className={classes.list}>
          <ListItem
            className={classes.listItem3}
          >
            <Button
              className={classes.listBtn}
              onClick={input.graph}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                統計
              </span>
              <span
                className={`${baseClasses.title4} ${classes.listVal}`}
              >
                {getSticsTels()}
              </span>
            </Button>
            <Button
              variant="contained"
              className={`${baseClasses.buttonsPrimary} ${classes.button2}`}
              onClick={input.graph}
            >
              グラフ
            </Button>
          </ListItem>
        </List>
        {getMailSp()}
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          お休み
        </Typography>
        <Grid className={classes.holiday}>
          <Button
            className={classes.holidayButton}
            onClick={handleOpen20}
          >
            <Typography
              className={classes.holidayTxt}
            >
              {input.detail.holidayWeekday} {input.detail.otherHolidayWeekday}
            </Typography>
          </Button>
          <HolidayTel
            initialParam={selectHolidayTel}
            onClickSelectButton={clickSelectBoxFunc}
            open={open20}
            onClose={handleClose20}
            category="holiday"
          />
        </Grid>
        {getFaxSp()}
      </Grid>
    );
  };

  if (isSp) {
    contents = (
      <Grid>
        {getStatusSp()}
      </Grid>
    );
  } else {
    contents = (
      <Grid>
        {getStatusPc()}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
