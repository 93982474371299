import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import commonStyles from '../../../../styles';
import SelectProduct, { SELECT_PRODUCT_TYPES } from '../../../../pages/selectProduct/selectProduct';

const useStyles = makeStyles({
  textField: {
    border: 'solid 1px #C8C8C8',
    backgroundColor: '#ffffff',
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
  },
  contentIpt: {
    textAlign: 'right',
  },
  buttonTypography: {
    border: 'none',
    fontSize: 13,
    '&:focus': {
      outline: 'none',
    },
  },
  productButton: { marginLeft: 'auto' },
});

function ResHouseField(props) {
  const { setHouse } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState('');

  useEffect(() => {
    if (product) {
      setHouse((state) => {
        state = product;
        return state;
      });
    }
  }, [product, setProduct]);

  const onClickHandler = () => setOpen(true);
  const onCloseHandler = () => setOpen(false);

  return (
    <Grid className={classes.contentIpt}>
      <Button
        className={`${baseClasses.smallStrong} ${baseClasses.buttonsPrimary} ${classes.productButton}`}
        onClick={() => onClickHandler()}
      >
        <Typography
          className={`${baseClasses.smallStrong} ${classes.buttonTypography}`}
        >
          物件番号・物件名を検索
        </Typography>
      </Button>
      <SelectProduct
        open={open}
        setOpen={setOpen}
        onClose={onCloseHandler}
        product={product}
        setProduct={setProduct}
        showTypes={[
          SELECT_PRODUCT_TYPES.OHD,
          SELECT_PRODUCT_TYPES.HAWK_ONE,
          SELECT_PRODUCT_TYPES.MAISOKU,
          SELECT_PRODUCT_TYPES.NO_HOUSE,
        ]}
      />
    </Grid>
  );
}

export default ResHouseField;
