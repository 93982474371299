import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const divisionMasterUpdateSectionSlice = createSlice({
  name: 'divisionMasterUpdateSection',
  initialState: { divisionMasterUpdateSection: initialState },
  reducers: {
    divisionMasterUpdateSectionApi: (state, data) => {
      state.divisionMasterUpdateSection = data.payload;
    },
  },
});

export const { divisionMasterUpdateSectionApi } = divisionMasterUpdateSectionSlice.actions;

export default divisionMasterUpdateSectionSlice.reducer;
