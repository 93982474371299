import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  areas: [
    {
      id: '10',
      code: 2,
      prefecture: '神奈川',
      city: '町名2',
      isAllocation: 1,
      memo: 'test',
      displayOrder: 1,
      isWishArea: 1,
      wishRoughAreaCode: 1,
      wishDisplayOrder: 1,
    },
    {
      id: '20',
      code: 2,
      prefecture: '静岡',
      city: '町名2',
      isAllocation: 1,
      memo: 'test2',
      displayOrder: 1,
      isWishArea: 1,
      wishRoughAreaCode: 1,
      wishDisplayOrder: 2,
    },
  ],
};

export const areaListSlice = createSlice({
  name: 'areaList',
  initialState: { areaList: initialState },
  reducers: {
    getAreaListApi: (state, data) => {
      state.areaList = data.payload;
    },
  },
});

export const { getAreaListApi } = areaListSlice.actions;

export default areaListSlice.reducer;
