import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef { import(
 *   '../../apis/divisionMaster/getUserListApi'
 * ).UserListGetResponse } UserListGetResponse
 * @type { UserListGetResponse }
 */
const initialState = {
  total: 0,
  users: [
    // {
    //   userId: 0,
    //   userFullName: '',
    //   gradeCode: 0,
    //   gradeName: '',
    //   positionCode: 0,
    //   userRoleGroupId: 1,
    //   userRoleGroupName: '',
    //   isMainDivision: 1,
    //   mainDivisionName: '',
    //   oldDivisionId: 4,
    //   oldDivisionName: '',
    //   newDivisionId: 5,
    //   newDivisionName: '',
    //   isNewManager: 0,
    //   isCustomerMove: 0,
    // },
  ],
};

export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    updateUserList: (state, { payload }) => {
      state.total = payload.total;
      state.users = payload.users;
    },
  },
});

export const { updateUserList } = userListSlice.actions;

export default userListSlice.reducer;
