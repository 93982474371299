import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  List,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DetailTalkRoomItems from './detailTalkRoomItems';
import DetailTalkRoomItemsSp from './sp/detailTalkRoomItemsSp';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  talkListContainer: {
    overflowY: 'auto',
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      marginTop: '10px',
      border: '1px solid #C8C8C8',
      padding: '0 8px',
      maxHeight: 214,
    },
  },
  talkList: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
});

function TalkRoomList(props) {
  const {
    dataList,
    functions,
  } = props;
  const data = dataList.lines;
  const classes = useStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [swipe, setSwipe] = useState(null); // スワイプ用

  return (
    <Grid className={classes.talkListContainer}>
      <List className={classes.talkList}>
        {data.map((element, index) => {
          return (
            isSp ? (
              <DetailTalkRoomItemsSp
                key={element.lineRoomLinkId}
                index={index}
                element={element}
                swipe={swipe}
                setSwipe={setSwipe}
                functions={functions}
              />
            ) : (
              <DetailTalkRoomItems
                key={element.lineRoomLinkId}
                element={element}
                functions={functions}
              />
            )
          );
        })}
      </List>
    </Grid>
  );
}

export default TalkRoomList;
