import client from '../httpClient';

/**
 * @module directDeleteApi - 【direct007】指示削除 (複数選択可)
 * @param {object} param = {directIds: []}
 */
const directDeleteApi = (params) => {
  return client.delete('/directs/', { params }, { isShowLoading: false });
};

export default directDeleteApi;
