import { useState } from 'react';
import {
  MenuItem,
  TextField,
  makeStyles,
  InputAdornment,
  Grid,
  Button,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { setRequestParams } from '../../../store/direct/directGetListSlice';
import toUnderscoreCase from '../../../commonFunction/camelToSnakeCase';
import CustomOriginalSelect from '../../eleCommon/customOriginalSelect';

const useStyles = makeStyles((theme) => ({
  iconAdornment: {
    padding: 4,
    background: '#F3F3F3',
    borderRadius: 0,
  },
  textArea: {
    margin: '0px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    border: '0px solid #FFFFFF',
    '& fieldset': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
    },
  },
  select: {
    display: 'flex',
    margin: '0px',
    padding: '4px',
    width: '100%',
    border: '0px solid #FFFFFF',
    backgroundColor: '#F3F3F3',
    borderTop: '2px solid #8C8C8C',
    '& fieldset': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
    },
    '& > button': {
      minHeight: '30.47px',
      maxHeight: '30.47px',
      backgroundColor: '#FFFFFF',
      borderRadius: '0 4px 4px 0',
      borderTop: '1px solid rgba(0, 0, 0, 0.23)',
      borderRight: '1px solid rgba(0, 0, 0, 0.23)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    },
    '& > .MuiInputAdornment-root': {
      minHeight: '30.47px',
    },
  },
  textInput: {
    fontSize: '13px',
    height: 20,
    padding: '4px',
    backgroundColor: '#FFFFFF',
    borderRadius: '0 4px 4px 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    borderRight: '1px solid rgba(0, 0, 0, 0.23)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  searchIconWrap: {
    backgroundColor: '#FFFFFF',
    height: '30.47px',
    marginRight: 0,
    borderRadius: '4px 0 0 4px',
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  searchIcon: {
    height: '1rem',
  },
  searchButton: {
    padding: '6px 0',
    minWidth: 'auto',
  },
}));

function SearchCell(props) {
  const { initial, initialParams } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');

  const filterFunc = (val, key) => {
    const copyObj = { ...initialParams };
    if (initial.type === 'select' || initial.type === 'selectUpdatedAt') {
      copyObj.order = val;
      copyObj.orderBy = val ? toUnderscoreCase(initial.id) : '';
      dispatch(setRequestParams(copyObj));
    } else if (initialParams[key] !== val) {
      copyObj[key] = val;
      dispatch(setRequestParams(copyObj));
    }
  };

  const changeValue = async (value) => {
    let orderValue;
    switch (Number(value)) {
      case 0:
        orderValue = '';
        break;
      case 1:
        orderValue = 'asc';
        break;
      case 2:
        orderValue = 'desc';
        break;
      default:
        break;
    }
    filterFunc(orderValue);
    setSearchValue(orderValue);
  };

  switch (initial.type) {
    case 'select': {
      const { menus } = initial;
      return (
        <TextField
          select
          defaultValue={initial.state}
          onChange={(e) => {
            if (e.target.value !== 0) {
              filterFunc(e.target.value);
              setSearchValue(e.target.value);
            } else {
              filterFunc('');
              setSearchValue('');
            }
          }}
          className={classes.textArea}
          margin="none"
          InputProps={{
            classes: {
              input: classes.textInput,
              adornedStart: classes.iconAdornment,
            },
            startAdornment: (
              <InputAdornment position="start" className={classes.searchIconWrap}>
                <Button
                  onClick={() => {
                    filterFunc(searchValue, initial.id);
                  }}
                  className={classes.searchButton}
                >
                  <SearchIcon className={classes.searchIcon} />
                </Button>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        >
          {menus.map((data) => (
            <MenuItem value={data.value} key={data.value} size="small">
              {data.text}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    case 'selectUpdatedAt': {
      const { UPDATED_AT_SELECT } = initial;
      return (
        <Grid
          className={classes.select}
        >
          <InputAdornment position="start" className={classes.searchIconWrap}>
            <Button
              onClick={() => {
                filterFunc(searchValue, initial.id);
              }}
              className={classes.searchButton}
            >
              <SearchIcon className={classes.searchIcon} />
            </Button>
          </InputAdornment>
          <CustomOriginalSelect
            data={{
              key: 'updatedAt',
              val: '',
              index: 0,
              type: 'select',
              select: UPDATED_AT_SELECT.menus,
              menuKeys: Object.keys(UPDATED_AT_SELECT.menus),
            }}
            changeValue={changeValue}
          />
        </Grid>
      );
    }
    default:
      return (
        <TextField
          className={classes.textArea}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') filterFunc(searchValue, initial.id);
          }}
          margin="none"
          InputProps={{
            classes: {
              input: classes.textInput,
              adornedStart: classes.iconAdornment,
            },
            startAdornment: (
              <InputAdornment position="start" className={classes.searchIconWrap}>
                <Button
                  onClick={() => {
                    filterFunc(searchValue, initial.id);
                  }}
                  className={classes.searchButton}
                >
                  <SearchIcon className={classes.searchIcon} />
                </Button>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          placeholder="-"
        />
      );
  }
}

export default SearchCell;
