import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../../styles';
import commonTheme from '../../../../../styles/theme';
import SelectProduct, { SELECT_PRODUCT_TYPES } from '../../../../../pages/selectProduct/selectProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '10px 8px 9px',
    },
  },
  ttl: {
    width: 120,
    marginRight: 8,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
    [commonTheme.breakpoints.up('md')]: {
      minHeight: 20,
      width: 428,
      marginLeft: 16,
      padding: 0,
      '& .MuiButton-label': {
        display: 'block',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
      borderBottom: '1px solid #F3F3F3',
      borderRadius: 0,
      width: '100%',
      padding: '16px',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
}));

function ResisterHouseWindowButton(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル用
  const [open, setOpen] = useState(false);

  // 物件選択用
  const [product, setProduct] = useState('');

  const onCloseHandler = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    console.log({ product });
    if (product) values.setState(product);
  }, [product]);

  return (
    <Grid className={classes.root}>
      <Button className={classes.btn} onClick={handleClick}>
        <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
          {values.label}<span>＊</span>
        </Typography>
        <Typography className={`${baseClasses.title6}`}>{values.state === '' ? '-' : values.state.productName}</Typography>
      </Button>

      <SelectProduct
        open={open}
        setOpen={setOpen}
        onClose={onCloseHandler}
        product={product}
        setProduct={setProduct}
        showTypes={[
          SELECT_PRODUCT_TYPES.OHD,
          SELECT_PRODUCT_TYPES.HAWK_ONE,
          SELECT_PRODUCT_TYPES.MAISOKU,
          SELECT_PRODUCT_TYPES.NO_HOUSE,
        ]}
      />
    </Grid>
  );
}

export default ResisterHouseWindowButton;
