/* eslint import/prefer-default-export: 0 */

export const SYSTEM_CODE_SFA = 1;
export const SYSTEM_CODE_KEYMALIS = 2;
export const SYSTEM_CODE_DAIJIN = 3;

// SFA用権限
export const BANNED = 1;
export const PLAYER = 11;
export const PLAYER_NOT_MOBILE = 12;
export const MANAGER = 13;
export const MANAGER_NOT_MOBILE = 14;
export const SUBCENTER = 15;
export const SUBCENTER_NOT_MOBILE = 16;
export const CENTER = 17;
export const CENTER_NOT_MOBILE = 18;
export const SALES_MANAGER = 21;
export const SALES_MANAGER_NOT_MOBILE = 22;
export const PROMOTION = 41;
export const PROMOTION_NOT_MOBILE = 42;
export const CC = 43;
export const PROMOTION_SUPPORT = 44;
export const PROPERTY = 51;
export const PROPERTY_PART = 52;
export const CUSTOMER_SATISFACTION = 53;
export const MARKETING = 54;
export const SYSTEM = 55;
export const AUDIT = 56;

export const ROLE_GROUP_MAPPER = {
  1: '利用禁止',
  11: 'プレイヤー',
  12: 'プレーヤー_モバイル使用不可',
  13: 'マネージャ',
  14: 'マネージャ_モバイル使用不可',
  15: 'サブセンター長',
  16: 'サブセンター長_モバイル使用不可',
  17: 'センター長',
  18: 'センター長_モバイル使用不可',
  21: '営業部長',
  22: '営業部長_モバイル使用不可',
  41: '営業推進部',
  42: '営業推進_モバイル使用不可',
  43: 'CC（閲覧のみ）',
  44: '推進支援',
  51: '物件広告社員',
  52: '物件広告アルバイト',
  53: '顧客満足室',
  54: 'マーケ',
  55: '情報システム部',
  56: '内部監査・法務',
};

// 営業権限
export const SALES_GROUPS = [
  PLAYER,
  PLAYER_NOT_MOBILE,
  MANAGER,
  MANAGER_NOT_MOBILE,
  SUBCENTER,
  SUBCENTER_NOT_MOBILE,
  CENTER,
  CENTER_NOT_MOBILE,
  SALES_MANAGER,
  SALES_MANAGER_NOT_MOBILE,
];

// プレイヤー～センター長
export const PLAYER_TO_CENTER_GROUPS = [
  PLAYER,
  PLAYER_NOT_MOBILE,
  MANAGER,
  MANAGER_NOT_MOBILE,
  SUBCENTER,
  SUBCENTER_NOT_MOBILE,
  CENTER,
  CENTER_NOT_MOBILE,
];

// マーケ権限
export const MARKETING_GROUPS = [
  PROPERTY,
  PROPERTY_PART,
  MARKETING,
];

// 課編成日付設定権限
export const DATE_TIME_GROUPS = [
  PROMOTION,
  PROMOTION_NOT_MOBILE,
  SYSTEM,
];

// 組織情報一括更新権限
export const DIVISION_MASTER_GROUPS = [
  PROMOTION,
  PROMOTION_NOT_MOBILE,
  PROPERTY,
  SYSTEM,
];

// 営業自店舗課編成権限
export const USER_MOVE_HISTORY_GROUPS = [
  PROMOTION,
  PROMOTION_NOT_MOBILE,
  MANAGER,
  MANAGER_NOT_MOBILE,
  CENTER,
  CENTER_NOT_MOBILE,
  SYSTEM,
];

// DAIJIN用権限
export const D_BANNED = 201;
export const D_ADMIN = 202;
export const D_CC = 203;
export const D_CS = 204;
export const D_HC = 205;
export const D_SALES = 206;
export const D_IBNET = 207;
export const D_IBNET_NEW = 208;

export const DAIJIN_ROLE_GROUP_MAPPER = {
  201: '利用禁止',
  202: 'ダイジンADMIN',
  203: 'CC',
  204: 'CS',
  205: 'HC推進室',
  206: '営業',
  207: 'IBNet',
  208: 'IBNet(新規申請部)',
};

// キマリス用権限
export const K_BANNED = 101;
export const K_ADMIN = 102;
export const K_CC = 103;
export const K_CS = 104;
export const K_HC = 105;
export const K_SALES = 106;
export const K_IBNET = 107;

export const KEYMALIS_ROLE_GROUP_MAPPER = {
  101: '利用禁止',
  102: '閲覧',
  103: '営業',
  104: 'CC',
  105: 'CC(管理者)',
  106: 'CS',
  107: 'CS(管理者)',
};
