import { createSlice } from '@reduxjs/toolkit';

import { getLocalStorage, setLocalStorage } from '../../commonFunction';
import { LIMIT_INSTRUCTION_LIST } from '../../constants/localStorage';

const initailLibrarySearchParam = {
  libraryDirectoryId: undefined, // ライブラリディレクトリID
  libraryDirectoryName: undefined, // ライブラリディレクトリ名
  libraryName: undefined, // ライブラリ名
  mailSubject: undefined, // 件名
  libraryMemo: undefined, // ライブラリメモ
  updatedBy: undefined, // 更新者ID
  updatedByName: undefined, // 更新者名 - ディビジョン名 ユーザー名
  isWithin7Days: undefined, // 更新7日以内のライブラリに絞る
  limit: getLocalStorage(LIMIT_INSTRUCTION_LIST, 20), // 表示数
  offset: 0, // オフセット
  orderBy: 'updateDate',
  order: 'desc',
};

export const libraryPageSlice = createSlice({
  name: 'libraryPage',
  initialState: {
    // ライブラリ検索のAPIのパラメータ
    librarySearchParam: initailLibrarySearchParam,
    // タブの状態
    tabType: 'tree',
  },
  reducers: {
    // ライブラリ検索
    setLibrarySearchParamWhole: (state, { payload }) => {
      const tmpPayload = { ...payload };
      const { limit, offset, orderBy, order } = state.librarySearchParam;
      state.librarySearchParam = {
        limit,
        offset,
        orderBy,
        order,
        ...tmpPayload,
      };
    },
    setLibrarySearchParamPartial: (state, { payload }) => {
      state.librarySearchParam = { ...state.librarySearchParam, ...payload };
    },
    resetLibrarySearchParam: (state) => {
      state.librarySearchParam = { ...initailLibrarySearchParam };
    },
    changeLibrarySearchLimit: (state, { payload }) => {
      if ((state.librarySearchParam.limit === payload)) {
        setLocalStorage(LIMIT_INSTRUCTION_LIST, payload);
      }
      state.librarySearchParam.limit = payload;
    },
    moveLibrarySearchPagenation: (state, { payload: page }) => {
      const { limit } = state.librarySearchParam;
      const offset = page * limit;
      state.librarySearchParam = { ...state.librarySearchParam, offset };
    },
    changeOrderAndOrderBy: (state, { payload: newOrderBy }) => {
      const { order, orderBy } = state.librarySearchParam;
      state.librarySearchParam.order = orderBy === newOrderBy && order === 'desc'
        ? 'asc'
        : 'desc';
      state.librarySearchParam.orderBy = newOrderBy;
    },
    // タブ
    setTabType: (state, { payload }) => {
      state.tabType = payload;
    },
  },
});

export const libraryPageSelector = (state) => state.libraryPage;
export const {
  setLibrarySearchParamWhole,
  setLibrarySearchParamPartial,
  changeLibrarySearchLimit,
  moveLibrarySearchPagenation,
  changeOrderAndOrderBy,
  setTabType,
} = libraryPageSlice.actions;
export default libraryPageSlice.reducer;
