import { useState, useRef, useLayoutEffect } from 'react';

/**
 * Intersection Observer APIを使用するためのCustom Hooks
 * 主な使用方法: コンポーネントの遅延レンダリング実現
 * @param {*} ref - 遅延レンダリングしたいコンポーネントを参照可能なref。
 * @param {*} options - Intersection Observer APIに渡されるoptions。公式参照。
 * @param {boolean} [triggerOnce=true]
 *  - 一度交差したタイミングで監視を切断するかのフラグ。画面外にコンポーネントが出た際、unmountしたければfalseにする。
 * @returns {boolean} isIntersecting - 画面内に入ったか
 */
export default function useIntersectionObserver(
  ref,
  options,
  triggerOnce = true,
) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useRef(null);

  useLayoutEffect(() => {
    if (!ref?.current) return undefined;
    observer?.current?.disconnect?.();
    observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (!triggerOnce) {
          setIsIntersecting(isElementIntersecting);
        } else if (triggerOnce && !isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting);
          observer?.current?.disconnect?.();
        }
      },
      { ...options },
    );

    observer.current.observe(ref?.current);
    return () => {
      observer?.current?.disconnect?.();
    };
  }, [options]);

  return isIntersecting;
}
