import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  makeStyles,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@material-ui/core';
import SendTimeChangeWindowSp from './changeTime';
import ChangeTestCC from './changeTestCC';
import commonStyles from '../../../styles';
import CustomAlertComplete from '../../../eleCommon/sp/customAlertComplete';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles({
  dialog: {
    width: '100%',
    '& .MuiDialog-paper': {
      width: '100%',
      margin: '0 8px',
    },
  },
  list: {
    paddingLeft: '16px',
  },
  item: {
    padding: 0,
    borderBottom: '1px solid #F3F3F3',
    '& > div': {
      width: '100%',
    },
  },
  btn: {
    padding: '16px',
    width: '100%',
    '& .MuiListItemText-root': {
      margin: 0,
      '& > span': {
        fontWeight: 'bold',
        textAlign: 'left',
      },
    },
  },
  icon: {
    marginRight: '6px',
    width: '20px',
    '&.customOutline': {
      fill: 'none',
      stroke: '#333',
      strokeWidth: '2px',
    },
  },
});

function ModalComponent(props) {
  const { values, type, state } = props;
  let component;

  switch (type) {
    case 'testCC':
      component = (
        <ChangeTestCC
          open={state.open}
          setOpen={state.setOpen}
          values={values.tcc}
        />
      );
      break;
    case 'sendTime':
      component = (
        <SendTimeChangeWindowSp
          open={state.open}
          setOpen={state.setOpen}
          values={values.sendTime}
        />
      );
      break;
    case 'delete':
      component = '';
      break;
    default:
      return '';
  }
  return component;
}

export default function SettingWindow(props) {
  const { handleClose, values, open, setOpen } = props;
  const common = commonStyles();
  const classes = useStyles();

  const dispatch = useDispatch();

  const [openChangeTime, setOpenChangeTime] = useState(false); // 送信日時を設定用モーダルの制御
  const [openChangeTestCC, setOpenChangeTestCC] = useState(false); // 送信日時を設定用モーダルの制御
  const [deleteConfirm, setDeleteConfirm] = useState(false); // 削除確認用モーダルの制御

  const list = [
    {
      id: 5,
      type: 'testCC',
      text: 'テストメールCC 変更',
      setting: values.tcc,
      state: {
        type: 'ChangeTestCC',
        open: openChangeTestCC,
        setOpen: setOpenChangeTestCC,
      },
    },
    {
      id: 6,
      type: 'sendTime',
      text: '送信日時を設定',
      setting: values.sendTime,
      state: {
        type: 'ChangeTime',
        open: openChangeTime,
        setOpen: setOpenChangeTime,
      },
    },
    {
      id: 7,
      type: 'delete',
      text: '破棄',
      state: {
        type: 'deleteConfirm',
        open: deleteConfirm,
        setOpen: setDeleteConfirm,
      },
    },
  ];

  // 確認モーダルからOKボタンを押した時の処理
  const customHandleOk = () => {
    setDeleteConfirm(true);

    setTimeout(() => {
      setDeleteConfirm(false);
    }, 2000);
  };
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '確認',
      msgList: ['本当に削除してよろしいでしょうか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: '',
          classes: common.buttonsSecondary,
        },
        {
          label: 'OK',
          set: customHandleOk,
          classes: common.buttonsPrimary,
        },
      ],
    }));
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(!open);
      }}
      className={`${common.drawer} ${classes.dialog}`}
    >
      <List className={classes.list}>
        {list.map((data, index) => (
          <ListItem
            key={data.id}
            className={`${common.title4} ${classes.item}`}
          >
            <Button
              className={classes.btn}
              onClick={() => {
                if (data.type === 'delete') {
                  confirmOpen();
                } else if (data.state.open !== '') {
                  data.state.setOpen(!data.state.open);
                }
              }}
            >
              <ListItemText primary={data.text} />
            </Button>
            <ModalComponent
              handleClose={handleClose}
              index={index}
              type={data.type}
              values={values}
              state={data.state}
            />
            <CustomAlertComplete
              open={deleteConfirm}
              setOpen={setDeleteConfirm}
              text="メールを削除しました。"
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
