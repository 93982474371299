import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Rating from '@material-ui/lab/Rating';
import MemberStatusIcon from '../../../memberStatusIcon';
import commonStyles from '../../../../styles';
import DirectReportStatusCode from '../directReportStatusCode';
import ImportantCode from '../importantCode';

import judgeMemberStatus from '../../../../../commonFunction/judgeMemberStatus';

const useStyles = makeStyles(() => ({
  informationItem: {
    background: '#fff',
    padding: '8px 16px',
    width: '100%',
    display: 'flex',
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)',
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 9,
    },
  },
  row: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:nth-child(1)': {
      textAlign: 'center',
    },
    '&:nth-child(2)': {
      wordBreak: 'keep-all',
    },
    '&:nth-child(3)': {
      textAlign: 'left',
      width: 'calc(100% - 118px)',
      '& > div': {
        width: '100%',
      },
    },
    '&:not(:first-child)': {
      marginLeft: 16,
    },
  },
  expiredIcon: {
    display: 'none',
    fontSize: 20,
    color: '#D83420',
    '&.expired': {
      display: 'inline-block',
    },
  },
  dateWrap: {
    '&.expired': {
      color: '#D83420',
    },
  },
  date: {
    '& > span': {
      display: 'block',
      textAlign: 'center',
    },
  },
  status: {
    '& > span': {
      background: '#333',
      color: '#fff',
      fontSize: 10,
      padding: '4px 6px',
      minWidth: 52,
      display: 'block',
      textAlign: 'center',
    },
  },
  starLbl: {
    color: '#FCE034',
    '& label:not(:last-of-type)': {
      marginRight: 2,
    },
    '& .MuiRating-icon svg': {
      stroke: '#333',
      fontSize: 10,
    },
  },
  userStatus: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    '& > svg': {
      marginRight: 8,
    },
  },
  name: {
    fontSize: 15,
    fontWeight: '700',
  },
  dl: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  dd: {
    marginLeft: 4,
  },
  workPlace4Button: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

function InstructionReportItemHeadSp(props) {
  const { detail, tab, displayCustomerDetail, openCustomer, setOpenCustomer } = props;

  const classes = useStyles();
  const common = commonStyles();

  // 期日
  const now = new Date();
  const year = now.getFullYear();
  const month = ('00' + (now.getMonth() + 1)).slice(-2);
  const day = ('00' + now.getDate()).slice(-2);
  const currentDate = year + '/' + month + '/' + day;
  const date1 = new Date(currentDate);
  const date2 = new Date(detail?.expireAt?.substr(0, 10));

  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (tab === 0) {
      if (date1 > date2) {
        setExpired(true);
      } else {
        setExpired(false);
      }
    }
  });

  return (
    <>
      <Grid
        className={`${classes.informationItem} tab-${tab} ${
          expired ? 'expired' : ''
        }`}
      >
        <Grid className={classes.row}>
          <Grid className={`${classes.dateWrap} ${expired ? 'expired' : ''}`}>
            <Icon
              className={`${classes.expiredIcon} ${expired ? 'expired' : ''}`}
            />
            <Typography className={`${common.title5} ${classes.date}`}>
              <span>{detail?.expireAt?.substr(0, 4)}</span>
              <span className={common.title4}>
                {detail?.expireAt?.substr(5, 5)}
              </span>
              <span>-{detail?.expireAt?.substr(10, 6)}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.status}>
            <DirectReportStatusCode code={detail.directReportStatusCode} />
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <Grid className={classes.rowInner}>
            <Grid container className={classes.flex}>
              {/* TODO(sato): 以下のname値は結合時に遷移元からマージして修正 */}
              {/* FIXKAI: ここはクリックされたら当モーダルを閉じて、タブを顧客情報に変更する必要あり */}
              <Grid
                item
                style={{ width: '40%' }}
                className={classes.workPlace4Button}
                // NOTE: 顧客詳細->指示報告->指示詳細でさらに顧客詳細に飛べないようにする
                // グローバルステートが更新される関係でエラーなどが発生する可能性あり
                onClick={() => setOpenCustomer?.(true)}
              >
                <Typography className={classes.name}>
                  {detail.customerLastName} {detail.customerFirstName}
                </Typography>
              </Grid>
              <Grid item style={{ width: '25%' }}>
                <Rating
                  name="size-large"
                  value={detail.userLevel}
                  className={classes.starLbl}
                />
              </Grid>
              <Grid
                item
                style={{ width: '25%' }}
                className={classes.userStatus}
              >
                <MemberStatusIcon code={detail.memberStatusCode} />
                <Typography className={`${common.title4}`}>
                  {judgeMemberStatus(detail.memberStatusCode)}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.flex}>
              <dl className={classes.dl}>
                <dt className={`${common.title5} ${classes.dt}`}>重要度</dt>
                <dd className={classes.dd}>
                  <ImportantCode code={detail.importantCode} />
                </dd>
              </dl>
              <dl className={classes.dl}>
                <dt className={`${common.title5} ${classes.dt}`}>担当者</dt>
                <dd className={classes.dd}>
                  {detail.directedLastName} {detail.directedFirstName}
                </dd>
              </dl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openCustomer ? displayCustomerDetail(detail.customerId) : null}
    </>
  );
}

export default InstructionReportItemHeadSp;
