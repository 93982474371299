import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const actDeleteHistorySlice = createSlice({
  name: 'actDeleteHistory',
  initialState: { actDeleteHistory: initialState },
  reducers: {
    actDeleteHistory: (state, data) => {
      state.actDeleteHistory = data.payload;
    },
  },
});

export const { actDeleteHistory } = actDeleteHistorySlice.actions;

export default actDeleteHistorySlice.reducer;
