import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';

import { useStoreGroupsSettingsAreas } from '../combinationSetting/common/useStoreGroupsSettingsAreas';

import modifyObject from '../../commonFunction/modifyObject';
import { convertForm1, convertFormNumber } from '../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormPostalCodeHyphen,
  validateFormFloat1,
} from '../../commonFunction/validations';

const useStyles = makeStyles({
  container: {
    marginBottom: 30,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
});

function Place(props) {
  const { division, setDivision, getComponent } = props;

  const { areaList } = useStoreGroupsSettingsAreas();

  const baseClasses = commonStyles();
  const classes = useStyles();

  const handleChange = (e, data) => {
    const val = e.target ? e.target.value : e;
    switch (data.key) {
      case 'areaCode':
        setDivision({ ...division, areaCode: Number(val) });
        break;
      case 'zip':
        setDivision({ ...division, zip: String(val) });
        break;
      case 'address':
        setDivision({ ...division, address: String(val) });
        break;
      case 'building':
        setDivision({ ...division, building: String(val) });
        break;
      case 'latitude':
        setDivision({ ...division, latitude: Number(val) });
        break;
      case 'longitude':
        setDivision({ ...division, longitude: Number(val) });
        break;
      default:
        break;
    }
  };

  const areaMap = {};
  areaList.forEach((area) => {
    areaMap[area.areaCode] = area.areaName;
  });

  const basicField = [
    {
      label: '地域',
      require: false,
      type: 'select',
      obj: modifyObject(areaMap, '地域'),
      key: 'areaCode',
      state: division.areaCode,
      set: handleChange,
    },
    {
      label: '郵便番号(ハイフンあり)',
      require: true,
      type: 'str',
      key: 'zip',
      state: division.zip,
      set: handleChange,
      validator: validateFormPostalCodeHyphen(),
    },
    {
      label: '住所',
      require: false,
      type: 'str',
      key: 'address',
      state: division.address,
      set: handleChange,
      validator: validateFormString1({ maxLengthInt: 250 }),
      convertor: convertForm1,
    },
    {
      label: 'マンション',
      require: false,
      type: 'str',
      key: 'building',
      state: division.building,
      set: handleChange,
      validator: validateFormString1({ maxLengthInt: 50 }),
      convertor: convertForm1,
    },
    {
      label: '緯度',
      require: false,
      type: 'number',
      key: 'latitude',
      width: '49.5%',
      state: division.latitude,
      set: handleChange,
      validator: validateFormFloat1({
        minFloat: -999.0,
        maxFloat: 999.0,
        precisionInt: 6,
      }),
      convertor: convertFormNumber,
    },
    {
      label: '経度',
      require: false,
      type: 'number',
      key: 'longitude',
      width: '49.5%',
      state: division.longitude,
      set: handleChange,
      validator: validateFormFloat1({
        minFloat: -999.0,
        maxFloat: 999.0,
        precisionInt: 6,
      }),
      convertor: convertFormNumber,
    },
  ];
  return (
    <>
      <Typography style={{ marginBottom: 10 }} className={baseClasses.title3}>所在地</Typography>
      <Grid className={classes.container}>
        {basicField.map((data) => (
          getComponent(data)
        ))}
      </Grid>
    </>
  );
}

export default Place;
