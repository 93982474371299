// import React, { useState, useEffect } from 'react';
// import React, { useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function AreaSentakuCheckBoxAll(props) {
  const {
    label,
    index,
    areas,
    onChangeChecked,
    checked,
  } = props;

  const updateAreas = (chk) => {
    areas.forEach(area => {
      onChangeChecked({
        id: area.id,
        index,
        label: area.city,
        cityJisCode: area.cityJisCode,
        checked: chk,
      });
    });
  };

  const handleChange = (e) => updateAreas(e.target.checked);

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
