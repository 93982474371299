import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '& td:nth-child(1)': {
      textAlign: 'center',
      [commonTheme.breakpoints.up('md')]: {
        width: '12%',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '16.5%',
      },
    },
    '& td:nth-child(2)': {
      textAlign: 'center',
      [commonTheme.breakpoints.up('md')]: {
        width: '12%',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '16.5%',
      },
    },
    '& td:nth-child(3)': {
      paddingLeft: 14,
      [commonTheme.breakpoints.up('md')]: {
        width: '76%',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '67%',
      },
    },
  },
  tableTxt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    borderBottom: '1px solid #8C8C8C',
    padding: 8,
    '& span': {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  tableNum: {
    borderBottom: '1px solid #8C8C8C',
    padding: 8,
  },
  contactCount: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    color: 'rgba(51,51,51,0.8)',
  },
  contactCountLeft: {
    borderRight: '1px solid #C8C8C8',
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 196px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&.contactCountBg1': {
      background: '#fff',
    },
    '&.contactCountBg2': {
      background: 'rgba(51, 51, 51, 0.04)',
    },
    '&.contactCountBg3': {
      background: `${theme.palette.primaryColor}29`,
    },
    '&.contactCountBg4': {
      background: 'rgba(6, 121, 191, 0.8)',
      color: '#fff',
    },
    '&.contactCountBg5': {
      background: theme.palette.primaryColor,
      color: '#fff',
    },
    '&.contactCountWidth0': {
      padding: '0 4px',
      [commonTheme.breakpoints.down('sm')]: {
        width: '2.5%',
      },
    },
    '&.contactCountWidth1': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 6px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: 'calc(5% + 3px)',
      },
    },
    '&.contactCountWidth2': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 16px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '10%',
      },
    },
    '&.contactCountWidth3': {
      color: '#fff',
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 26px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '15%',
      },
    },
    '&.contactCountWidth4': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 36px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
    },
    '&.contactCountWidth5': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 46px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '25%',
      },
    },
    '&.contactCountWidth6': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 56px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '30%',
      },
    },
    '&.contactCountWidth7': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 66px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '35%',
      },
    },
    '&.contactCountWidth8': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 76px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '40%',
      },
    },
    '&.contactCountWidth9': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 86px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '45%',
      },
    },
    '&.contactCountWidth10': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 96px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
    '&.contactCountWidth11': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 106px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '55%',
      },
    },
    '&.contactCountWidth12': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 116px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '60%',
      },
    },
    '&.contactCountWidth13': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 126px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '65%',
      },
    },
    '&.contactCountWidth14': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 136px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '70%',
      },
    },
    '&.contactCountWidth15': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 146px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '75%',
      },
    },
    '&.contactCountWidth16': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 156px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '80%',
      },
    },
    '&.contactCountWidth17': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 166px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '85%',
      },
    },
    '&.contactCountWidth18': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 176px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    '&.contactCountWidth19': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 186px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
    '&.contactCountWidth20': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '0 196px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: 'calc(100% - 26px)',
      },
    },
  },
  contactCountRight: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 4px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 2px',
      width: '2.5%',
    },
  },
}));

export default function AreaSentakuWrapper(props) {
  const { label, tels = [] } = props;

  const classes = useStyles();

  const contactCount = tels.reduce((sum, e) => sum + e.contactCount, 0);
  const nonContactCount = tels.reduce((sum, e) => sum + e.nonContactCount, 0);
  const totalContactCount = contactCount + nonContactCount;
  const rate = totalContactCount === 0
    ? 0
    : (contactCount / totalContactCount) * 100;

  let bg;
  if (rate >= 80) {
    bg = 'contactCountBg5';
  } else if (rate >= 60) {
    bg = 'contactCountBg4';
  } else if (rate >= 40) {
    bg = 'contactCountBg3';
  } else if (rate >= 20) {
    bg = 'contactCountBg2';
  } else {
    bg = 'contactCountBg1';
  }

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableTxt} scope="row">
        {label.substr(0, 2)}
        <span>時〜</span>
      </TableCell>
      <TableCell className={classes.tableTxt}>
        {Math.round(rate)}
        <span>%</span>
      </TableCell>
      <TableCell className={classes.tableNum}>
        <span className={classes.contactCount}>
          <span
            className={`${classes.contactCountLeft} ${bg} contactCountWidth${contactCount}`}
          >
            {contactCount}
          </span>
          <span className={classes.contactCountRight}>{totalContactCount}</span>
        </span>
      </TableCell>
    </TableRow>
  );
}
