import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  List,
  ListItem,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../styles';
import SwipeOutButton from '../../../../eleCommon/swipeOutButton';
import { changeConfirmMessage } from '../../../../../store/eleCommon/customConfirmMessage';
// import ConfirmModal from '../../../../common/modal/confirmModal';
// import ResHouseDeleteDialog from './resHouseDeleteDialog';
// import ResisterHouseEditWindowSp from '../../resisterHouseWindow/sp/resisterHouseEditWindowSp';
import { getEnvVariable } from '../../../../../commonFunction/getEnv';

const useStyles = makeStyles({
  item: {
    borderBottom: '1px solid #F3F3F3',
    padding: '14.5px 8px',
  },
  list: {
    overflow: 'hidden',
  },
  dateBlock: {
    textAlign: 'center',
  },
  date: {
    display: 'block',
  },
  main: {
    marginLeft: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  contactList: {
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    '& > li': {
      padding: 0,
      fontSize: 13,
      width: 'auto',
      '&:not(:last-child)': {
        marginRight: 8,
      },
    },
  },
  link: {
    color: '#333',
  },
  number: {
    fontWeight: 'bold',
  },
  numberBlock: {
    marginLeft: 33,
    flexShrink: 0,
    textAlign: 'center',
  },
  buildingNumber: {
    display: 'block',
    textAlign: 'center',
    lineHeight: '1.2',
  },
});

function ResHouseListItem(props) {
  const { row, index, swipe, setSwipe, deleteAndUpdateHouseList } = props;
  const [open2, setOpen2] = useState(false); // 削除用
  // const [open3, setOpen3] = useState(false); // 編集設定用
  // const [reData, setReData] = useState({
  //   date: row.inquiredAt, house: row.productName });

  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();

  // 確認モーダル（キャンセルクリック時の処）
  const handleClickCancel = () => {
    setOpen2(!open2);
    setSwipe(false);
  };
  // 確認モーダル（OKクリック時の処理）
  const handleClickOk = (rowObj) => {
    setOpen2(!open2);
    setSwipe(false);
    deleteAndUpdateHouseList(rowObj.inquiryProductId);
  };

  const openDeleteModal = () => {
    dispatch(changeConfirmMessage({
      title: '問合せ物件の削除確認',
      msgList: ['選択した問合せ物件を削除してもよろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: handleClickCancel,
          classes: common.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => { handleClickOk(row); },
          classes: common.buttonsPrimary,
        },
      ],
    }));
  };

  const children = (
    <ListItem className={classes.item}>
      <Grid>
        <Typography className={classes.dateBlock}>
          <span className={`${common.title4}`}>{row.inquiredAt.slice(0, 4)}</span>
          <span className={`${common.title3} ${classes.date}`}>{row.inquiredAt.slice(5, 12)}</span>
        </Typography>
      </Grid>
      <Grid className={classes.main}>
        <Grid className={classes.content}>
          <Grid className={classes.iconFlex}>
            <Link className={`${common.title4} ${classes.link}`} href={`${getEnvVariable('REGISTER_BASE_URL')}bukken/search/tou?table_kind=latest&bukken_code=${row.siteNumber}`} target="_blank">{row.productName}</Link>
          </Grid>
          <Grid>
            <List className={classes.contactList}>
              <ListItem className={classes.contactItem}>{row.largeResponseTypeName}</ListItem>
              <ListItem className={classes.contactItem}>{row.mediumResponseTypeName}</ListItem>
              <ListItem className={classes.contactItem}>{row.smallResponseTypeName}</ListItem>
              <ListItem className={classes.contactItem}>{row.fineResponseTypeName}</ListItem>
            </List>
          </Grid>
        </Grid>
        {row.buildingNumber && (
          <Grid className={classes.numberBlock}>
            <Typography className={`${common.verySmall} ${classes.number}`}>
              <span className={`${common.large} ${classes.buildingNumber}`}>{row.buildingNumber}</span>区画
            </Typography>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );

  return (
    <SwipeOutButton swipe={swipe} setSwipe={setSwipe} index={index} label="削除" color="red" handleClick={openDeleteModal}>
      {children}
    </SwipeOutButton>
  );
}

export default ResHouseListItem;
