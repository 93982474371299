import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const responseGetTypeSlice = createSlice({
  name: 'responseGetType',
  initialState: { responseGetType: initialState },
  reducers: {
    responseGetTypeApi: (state, data) => {
      state.responseGetType = data.payload;
    },
  },
});

export const { responseGetTypeApi } = responseGetTypeSlice.actions;

export default responseGetTypeSlice.reducer;
