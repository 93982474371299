import { useState, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CommonDialog from '../../components/common/modal';
import commonStyles from '../styles';
import { TextBaseField } from '../eleCommon/validation';
import { validateFormString1 } from '../../commonFunction/validations';

const useStyles = makeStyles((theme) => ({
  cell: { padding: 2 },
  wrap: {
    padding: '4px 8px',
    marginBottom: 8,
    background: '#fff',
    alignItems: 'center',
    border: '1px #c8c8c8 solid',
    flexWrap: 'nowrap',
  },
  textArea: {
    width: '100%',
  },
  radioWrap: {
    flexDirection: 'row',
  },
  require: {
    marginRight: 16,
    flexShrink: 0,
    '& span': { color: '#D83420' },
  },
  hover: { background: `${theme.palette.primaryColor}29` },
  opacity: { opacity: 0 },
}));

export default function EditCautionPhrase(props) {
  const {
    wordObj,
    setWordObj,
    open,
    setOpen,
    onRegister,
    isRegist,
    isWhiteWord,
  } = props;

  const [isCreate, setIsCreate] = useState(false);

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };
  const common = commonStyles();
  const classes = useStyles();

  const CheckRangeField = (
    <Grid container className={classes.wrap}>
      <Typography className={`${common.title6} ${classes.require}`}>
        制限範囲<span>＊</span>
      </Typography>
      <FormControl>
        <RadioGroup
          value={wordObj.controlRangeCode}
          onChange={(e) => {
            setWordObj({
              ...wordObj,
              controlRangeCode: Number(e.target.value),
            });
          }}
          className={classes.radioWrap}
        >
          <FormControlLabel control={<Radio value={0} />} label="SFA全体" />
          <FormControlLabel
            control={<Radio value={1} />}
            label="メール機能のみ"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  useEffect(() => {
    if (
      (!isWhiteWord && wordObj.word !== '' && wordObj.controlRangeCode !== '')
      || (isWhiteWord && wordObj.word !== '')
    ) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [wordObj.word, wordObj.controlRangeCode]);

  return (
    <CommonDialog
      open={open}
      onClose={() => {
        return setOpen(false);
      }}
      title={`${isWhiteWord ? 'ホワイト' : '注意'}語句の${isRegist ? '新規追加' : '編集'}`}
      width={480}
      buttons={{
        label: '登録',
        disabled: !isCreate || errorSet.size !== 0,
        onClick: onRegister,
      }}
    >
      <Grid className={common.body}>
        <Grid container className={classes.wrap}>
          <Typography className={`${common.title6} ${classes.require}`}>
            登録語句<span>＊</span>
          </Typography>
          <TextBaseField
            value={wordObj.word}
            className={`${common.textField} ${classes.textArea}`}
            onChange={(e) => {
              setWordObj({ ...wordObj, word: e.target.value });
            }}
            validator={validateFormString1({ maxLengthInt: 250 })}
            name="phrase"
            errorCallback={errorCallback('phrase')}
          />
        </Grid>
        {!isWhiteWord && CheckRangeField}
      </Grid>
    </CommonDialog>
  );
}
