import React, { useState, useEffect } from 'react';
import Rnd from 'react-rnd';
import { makeStyles } from '@material-ui/core/styles';
import NormalTooltip from '../../styles/tooltip';
import {
  ACTION_TYPE,
  ACTION_TYPE_FLYER_SALES,
  USER_LEVEL_NO,
  GUIDANCE_STATUS_CODE,
} from '../../../constants';
import InfoRegisterOnlyPost from '../../common/infoRegisterOnlyPost';
import EditableWindow from '../../pages/actRegist/editableWindow';
import ActRegistWindow from '../../pages/actRegist/actRegistWindow';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  rnd: {
    position: 'relative',
    border: '1px solid #C8C8C8',
    background: '#fff',
    borderRadius: '0 4px 4px 4px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    maxWidth: 97,
    '&.single': {
      '& > span': {
        whiteSpace: 'normal',
      },
    },
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: '14px 14px 0 0',
      borderStyle: 'solid',
      position: 'absolute',
      top: 0,
      left: 0,
      borderColor: 'transparent',
    },
    '&.actionScheduleCode-1::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-2::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-3::before': {
      borderColor: `${theme.palette.primaryColor} transparent transparent`,
    },
    '&.actionScheduleCode-4::before': {
      borderColor: '#F2994A transparent transparent',
    },
    '&.actionScheduleCode-5::before': {
      borderColor: '#DEDEDE transparent transparent',
    },
    '&.actionScheduleCode-6::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-7::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-999': {
      background: '#FCE034',
      '&::before': {
        borderColor: 'transparent',
      },
      '&.isResultRegister-1::before': {
        borderColor: '#D83420 transparent transparent',
      },
      '&.customerStatusCode-10::before': {
        borderColor: '#27AE60 transparent transparent',
      },
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '1.3',
    letterSpacing: '0',
    display: 'block',
    padding: '0 0 0 8px',
    whiteSpace: 'nowrap',
  },
}));

export default function WeekRnd(props) {
  const {
    no,
    item,
    index,
    count,
    data,
  } = props;

  const {
    getSchedulesWeek,
    setReacquisitionFlg,
    functions,
    selectDivision,
  } = useActionSchedule();

  const classes = useStyles();

  const [open2, setOpen2] = useState(false); // GA004モーダルの開閉
  const onClose2 = () => {
    setOpen2(false);
    // 再取得
    getSchedulesWeek();
  };

  // GA-108 行動登録画面
  const [editableOpen, setEditableOpen] = useState(false);
  const onCloseEditable = () => {
    setEditableOpen(false);
    // 再取得
    getSchedulesWeek();
  };
  const [openActRegist, setOpenActRegist] = useState(false);
  const onCloseActRegist = () => {
    setOpenActRegist(false);
    // 再取得
    getSchedulesWeek();
  };
  // 目標
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });
  const [actRegistSelectDivision, setActRegistSelectDivision] = useState(selectDivision);

  // 日付に変換
  const startDate = new Date(item.startAt);
  // const endDate = new Date(item.endAt);

  // 開始時刻
  const [startTime] = useState(startDate.getHours() + ':' + String(startDate.getMinutes()).padStart(2, '0'));
  // 終了時刻
  // const [endTime] = useState(endDate.getHours() + ':' +
  // String(endDate.getMinutes()).padStart(2, '0'));

  // 開始位置 X
  let positionX;
  if ((new Date(item.startAt)).getHours() < 12) {
    positionX = (no * 210);
  } else {
    positionX = (no * 210) + 105;
  }
  const [startX, setStartX] = useState(positionX);

  const [reset, setReset] = useState(false);

  useEffect(() => {
    if ((new Date(item.startAt)).getHours() < 12) {
      positionX = (no * 210);
    } else {
      positionX = (no * 210) + 105;
    }
    setStartX(positionX);
    setReset(!reset);
  }, [item]);

  const onDrag = async () => {
    // actionScheduleCodeがnullの場合、GA-004画面を開く
    if (item.actionScheduleCode === null) {
      setOpen2(true);
    } else {
      // グローバルステート(schedulesGetActionDetail)の更新
      await functions.getSchedulesDetailFunction(item.scheduleId);
      if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
        setEditableOpen(true);
      } else {
        setOpenActRegist(true);
      }
    }
  };

  const onClick = async () => {
    // actionScheduleCodeがnullの場合、GA-004画面を開く
    if (item.actionScheduleCode === null) {
      setOpen2(true);
    } else {
      // グローバルステート(schedulesGetActionDetail)の更新
      await functions.getSchedulesDetailFunction(item.scheduleId);
      if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
        setEditableOpen(true);
      } else {
        setOpenActRegist(true);
      }
    }
  };

  const moldingParameter = (resultDivision) => {
    const copyObj = Object.assign({}, resultDivision);
    return copyObj;
  };

  const getTooltipTxt = () => {
    let txt;
    if (item.actionScheduleCode) {
      txt = `${startTime}-${item.customerName ? ` ${item.customerName} 様、` : '、'}${ACTION_TYPE[item.actionScheduleCode]}${item.memo ? `、${item.memo}` : ''}`;
    } else {
      txt = `${startTime}-${item.customerName ? ` ${item.customerName} 様、` : '、'}${item.customerStatusCode === 10 ? `(契)${GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}` : GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}${item.userLevel ? `、${USER_LEVEL_NO[item.userLevel]}` : '、★なし'}${item.isPairCheck ? '、揃い' : ''}${item.siteName ? `、${item.siteName}` : ''}`;
    }
    return txt;
  };

  return (
    <>
      <NormalTooltip
        title={getTooltipTxt()}
      >
        <Rnd
          key={reset}
          className={`${classes.rnd} actionScheduleCode-${item.actionScheduleCode || '999'} customerStatusCode-${item.customerStatusCode} isResultRegister-${item.isResultRegister} ${count === 1 && 'single'}`}
          // default={{
          //   x: getPositionX(),
          //   y: index * 24,
          //   width: '97px',
          //   height: count === 1 ? '38px' : '22px',
          // }}
          size={{
            width: '97px',
            height: count === 1 ? '38px' : '22px',
          }}
          position={{
            x: startX,
            y: index * 24,
          }}
          dragAxis="x"
          enableResizing={{
            top: false,
            right: true,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          bounds="parent"
          resizeGrid={[105, 0]}
          dragGrid={[105, 0]}
          minWidth="97"
          onDrag={onDrag}
          onClick={onClick}
        >
          {item.actionScheduleCode ? (
            <span className={classes.txt}>
              {startTime}-
              {item.customerName ? ` ${item.customerName} 様、` : '、'}
              {ACTION_TYPE[item.actionScheduleCode]}
              {item.memo ? `、${item.memo}` : ''}
            </span>
          ) : (
            <span className={classes.txt}>
              {startTime}-
              {item.customerName ? ` ${item.customerName} 様、` : '、'}
              {item.customerStatusCode === 10 ? `(契)${GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}` : GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}
              {item.userLevel ? `、${USER_LEVEL_NO[item.userLevel]}` : '、★なし'}
              {item.isPairCheck ? '、揃い' : ''}
              {item.siteName ? `、${item.siteName}` : ''}
            </span>
          )}
        </Rnd>
      </NormalTooltip>
      {/* GA-108 行動登録画面（源泉） */}
      <EditableWindow
        open={editableOpen}
        onClose={onCloseEditable}
        setReacquisitionFlg={setReacquisitionFlg}
        localUpdateData={data.localUpdateData}
        setLocalUpdateData={data.setLocalUpdateData}
        setOpenCustomertree={data.setOpenCustomertree}
        actRegistFlag={Boolean(false)}
        editableFlag={Boolean(true)}
        moldingParameter={moldingParameter}
        schedulesUpdateApiFunction={functions.schedulesUpdateApiFunction}
        schedulesPostApiFunction={functions.schedulesPostApiFunction}
        schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
      />
      {/* GA-108 行動登録画面（源泉以外） */}
      {openActRegist && (
        <ActRegistWindow
          open={openActRegist}
          onClose={onCloseActRegist}
          setReacquisitionFlg={setReacquisitionFlg}
          values={data.localUpdateData}
          setUpdateData={data.setLocalUpdateData}
          setOpenCustomertree={data.setOpenCustomertree}
          actRegistFlag={Boolean(false)}
          editableFlag={Boolean(true)}
          targetObj={targetObj}
          setTargetObj={setTargetObj}
          archieveObj={archieveObj}
          setArchieveObj={setArchieveObj}
          selectDivision={actRegistSelectDivision}
          setSelectDivision={setActRegistSelectDivision}
          isSource={Boolean(false)}
          isEdit={Boolean(true)}
          createUpdateFunc={(obj) => {
            functions.schedulesUpdateApiFunction(data.localUpdateData.scheduleId, obj);
          }}
          schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
        />
      )}
      {/* GA-004 */}
      <InfoRegisterOnlyPost
        open={open2}
        onOpen={() => { setOpen2(true); }}
        onClose={onClose2}
        // newRegistrationFlgがtrueの場合、infoRegisterにguidanceIdを渡さない===新規登録になる
        guidanceId={item.guidanceId}
        // setClickInfoRegister={setClickInfoRegister}
        customer={item}
        // user={user}
        reacquisition={getSchedulesWeek}
      />
    </>
  );
}
