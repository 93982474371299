/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import {
  GUIDANCE_STATUS_CODE,
} from '../../../../constants';

// 案内履歴リストAPI
import { setGuidancesByCustomerIdApi } from '../../../../store/schedules/schedulesGetGuidancesByCustomerIdSlice';
import schedulesGetGuidancesByCustomerIdApi from '../../../../apis/schedules/schedulesGetGuidancesByCustomerIdApi';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '9px',
    },
  },
  ttl: {
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
      padding: '19px 8px 8px',
    },
  },
  history: {
    [commonTheme.breakpoints.up('md')]: {
      maxHeight: 500,
      overflowY: 'scroll',
    },
  },
  historyAchievement: {
    [commonTheme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 50px)',
    },
  },
  historyList: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  historyHeader: {
    padding: '12px 0 4px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '2px solid #C8C8C8',
    [commonTheme.breakpoints.up('md')]: {
      maxHeight: '100vh',
      overflowY: 'scroll',
      position: 'sticky',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1,
      background: '#F3F3F3',
    },
  },
  historyHeaderLbl1: {
    color: '#8C8C8C',
    width: 110,
  },
  historyHeaderLbl2: {
    color: '#8C8C8C',
    width: 40,
    textAlign: 'center',
    marginRight: 6,
  },
  historyHeaderLbl3: {
    color: '#8C8C8C',
    width: 90,
  },
  historyHeaderLbl4: {
    color: '#8C8C8C',
    width: 80,
  },
  historyHeaderLbl5: {
    color: '#8C8C8C',
    width: 80,
  },
  historyHeaderLbl6: {
    color: '#8C8C8C',
  },
  historyItem: {
    width: '100%',
    borderBottom: '1px solid #C8C8C8',
    borderRadius: '0',
    display: 'flex',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 8,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 4,
    },
    '&:nth-child(even)': {
      [commonTheme.breakpoints.up('md')]: {
        background: '#F3F3F3',
      },
    },
    // '&:hover': {
    //   background: `${theme.palette.primaryColor}29`,
    // },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
      [commonTheme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
  },
  historyItemTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '& > span': {
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 10,
        fontWeight: 700,
        marginRight: 4,
      },
    },
  },
  historyItem1: {
    width: 110,
  },
  historyItem2: {
    width: 40,
    textAlign: 'center',
    marginRight: 6,
  },
  historyItem3: {
    width: 90,
  },
  historyItem4: {
    width: 80,
  },
  historyItem5: {
    width: 80,
  },
  historyItem6: {
    marginRight: 4,
  },
  historyItemBox1: {
    width: 70,
  },
  historyItemBox2: {
    width: 78,
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
  historyItemBox3: {
    width: 94,
  },
});

function InfoRegisterHistory(props) {
  const {
    clickHistoryCard,
    openHistory,
    customer,
    display1 = false,
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const getReserve = (code) => {
    switch (code) {
      case 0:
        return (
          <span>なし</span>
        );
      default:
        return (
          <span>あり</span>
        );
    }
  };

  // 顧客メインと行動予定でカスタマー情報が違うため、名前の情報を取得を振り分け取得
  const getCustomerName = () => {
    return customer.customerName ?? [customer.lastName, customer.firstName].filter(Boolean).join(' ');
  };

  // 接触履歴の有無でリストの表示を変更するためのstate
  const [hasHistory, setHasHistory] = useState(false);

  // get: 顧客毎の案内履歴リスト
  const infoList = useSelector((state) => state.schedulesGetGuidancesByCustomerId);
  const getGuidancesByCustomerIdFunction = async (customerId) => {
    await schedulesGetGuidancesByCustomerIdApi(customerId)
      .then(async (res) => {
        await dispatch(setGuidancesByCustomerIdApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clickHandler = (id) => {
    clickHistoryCard(id);
  };

  // PC screenでの接触履歴の更新
  useEffect(() => {
    if ((Number(customer.customerId)) && !isSP) getGuidancesByCustomerIdFunction(customer.customerId);
  }, []);

  // SP screenでの接触履歴の更新
  useEffect(() => {
    if (isSP && Number(customer.customerId)) getGuidancesByCustomerIdFunction(customer.customerId);
  }, [openHistory]);

  // infoList.schedulesGetGuidancesByCustomerId.guidancesのデータ有無でhasHistoryを更新（接触履歴が存在しているか）
  useEffect(() => {
    const guidancesArr = infoList.schedulesGetGuidancesByCustomerId.guidances;
    if (guidancesArr) {
      if (guidancesArr.length > 0) {
        setHasHistory(true);
      }
      if (guidancesArr.length < 1) {
        setHasHistory(false);
      }
    }
  }, [infoList]);

  const contents = () => {
    if (isSP) {
      if (!hasHistory) {
        return (
          <Grid className={classes.root}>
            <Typography
              className={`${baseClasses.title2} ${classes.ttl}`}
            >
              {getCustomerName()} 様の接触履歴
            </Typography>
            <Grid className={classes.history}>
              <Grid className={classes.historyList}>
                <Typography>
                  接触履歴はありません
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid className={classes.root}>
          <Typography
            className={`${baseClasses.title2} ${classes.ttl}`}
          >
            {getCustomerName()} 様の接触履歴（{infoList.schedulesGetGuidancesByCustomerId.contactCount}回）
          </Typography>
          <Grid className={classes.history}>
            <Grid className={classes.historyList}>
              {infoList.schedulesGetGuidancesByCustomerId.guidances.map((guidance) => (
                <Button
                  key={guidance.guidanceId}
                  className={classes.historyItem}
                  onClick={() => { clickHandler(guidance.guidanceId); }}
                >
                  <Typography className={`${classes.historyItemTxt} ${classes.historyItemBox1}`}>
                    {guidance.startAt.substr(0, 16)}
                  </Typography>
                  <Grid className={classes.historyItemBox2}>
                    <Typography className={classes.historyItemTxt}>
                      <span>予約</span>
                      {getReserve(guidance.isReserved)}
                    </Typography>
                    <Typography className={classes.historyItemTxt}>
                      {GUIDANCE_STATUS_CODE[guidance.statusCode]}
                    </Typography>
                  </Grid>
                  <Grid className={classes.historyItemBox3}>
                    <Typography className={classes.historyItemTxt}>
                      <span>獲得</span>
                      {guidance.guideGetUserName}
                    </Typography>
                    <Typography className={classes.historyItemTxt}>
                      <span>接客</span>
                      {guidance.serviceContactAccountName}
                    </Typography>
                    <Typography className={classes.historyItemTxt}>
                      <span>案内</span>
                      {guidance.guideContactAccountName}
                    </Typography>
                  </Grid>
                </Button>
              ))}
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      if (!hasHistory) {
        return (
          <Grid className={classes.root}>
            <Typography
              className={`${baseClasses.title2} ${classes.ttl}`}
            >
              {getCustomerName()} 様の接触履歴
            </Typography>
            <Grid className={classes.history}>
              <Grid className={classes.historyList}>
                <Typography>
                  接触履歴はありません
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid className={classes.root}>
          <Typography
            className={`${baseClasses.title3} ${classes.ttl}`}
          >
            {getCustomerName()} 様の接触履歴（接触回数：{infoList.schedulesGetGuidancesByCustomerId.contactCount}）
          </Typography>
          <Typography
            className={`${baseClasses.title2} ${baseClasses.onlySp} ${classes.ttl}`}
          >
            {getCustomerName()} 様の接触履歴（{infoList.schedulesGetGuidancesByCustomerId.contactCount}回）
          </Typography>
          <Grid className={`${classes.history} ${display1 ? classes.historyAchievement : ''}`}>
            <Grid className={classes.historyHeader}>
              <Typography className={`${baseClasses.title4} ${classes.historyHeaderLbl1}`}>
                日時
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.historyHeaderLbl2}`}>
                予約
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.historyHeaderLbl3}`}>
                案内種別
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.historyHeaderLbl4}`}>
                獲得者
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.historyHeaderLbl5}`}>
                接客者
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.historyHeaderLbl6}`}>
                案内者
              </Typography>
            </Grid>
            <Grid className={classes.historyList}>
              {infoList.schedulesGetGuidancesByCustomerId.guidances.map((guidance) => (
                <Button
                  key={guidance.guidanceId}
                  className={classes.historyItem}
                  onClick={() => clickHandler(guidance.guidanceId)}
                >
                  <Typography className={`${classes.historyItemTxt} ${classes.historyItem1}`}>
                    {guidance.startAt.substr(0, 16)}
                  </Typography>
                  <Typography className={`${classes.historyItemTxt} ${classes.historyItem2}`}>
                    {getReserve(guidance.isReserved)}
                  </Typography>
                  <Typography className={`${classes.historyItemTxt} ${classes.historyItem3}`}>
                    {GUIDANCE_STATUS_CODE[guidance.statusCode]}
                  </Typography>
                  <Typography className={`${classes.historyItemTxt} ${classes.historyItem4}`}>
                    {guidance.guideGetUserName}
                  </Typography>
                  <Typography className={`${classes.historyItemTxt} ${classes.historyItem5}`}>
                    {guidance.serviceContactAccountName}
                  </Typography>
                  <Typography className={`${classes.historyItemTxt} ${classes.historyItem6}`}>
                    {guidance.guideContactAccountName}
                  </Typography>
                </Button>
              ))}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Grid>
      {contents()}
    </Grid>
  );
}

export default InfoRegisterHistory;
