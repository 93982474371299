import client from '../httpClient';

const filestorageGetApi = (offset, limit, keyword) => {
  const params = {
    offset,
    limit,
  };

  if (keyword) {
    params.fileName = keyword;
  }

  return client.get('/file-storages', { params });
};

export default filestorageGetApi;
