import { Tooltip, makeStyles } from '@material-ui/core';
import { classNames } from '../../commonFunction';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(51, 51, 51, 0.6)',
    color: '#FFFFFF',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: '160%',
    padding: '2px 8px',
    borderRadius: '4px',
    textAlign: 'left',
    // whiteSpace: 'nowrap',
  },
}));

function NormalTooltip(props) {
  const { children, classes: propsClasses, ...others } = props;
  const classes = useStyles(props);

  return (
    <Tooltip
      classes={{
        ...propsClasses,
        tooltip: classNames(classes.tooltip, propsClasses?.tooltip),
      }}
      {...others}
    >
      {children}
    </Tooltip>
  );
}

export default NormalTooltip;
