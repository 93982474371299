import { Grid, makeStyles } from '@material-ui/core';
import EasyRegisterContainer from '../../containers/easyRegister/easyRegisterContainer';

const useStyles = makeStyles(() => ({
  root: { padding: 16 },
}));

export default function EasyRegister() {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <EasyRegisterContainer />
    </Grid>
  );
}
