import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoGetLoanSlice = createSlice({
  name: 'hpinfoGetLoan',
  initialState: { hpinfoGetLoan: initialState },
  reducers: {
    hpinfoGetLoanApi: (state, data) => {
      state.hpinfoGetLoan = data.payload;
    },
  },
});

export const { hpinfoGetLoanApi } = hpinfoGetLoanSlice.actions;

export default hpinfoGetLoanSlice.reducer;
