import { createSlice } from '@reduxjs/toolkit';

/**
 * @type { import("../../apis/divisionMaster/getStoreDivisionsApiTypes").StoreDivisionsGetResponse }
 */
const initialState = {
  message: '',
  description: '',
  data: {
    areaCode: 0,
    divisions: [
      {
        fromDivisionId: 0,
        fromDivisionName: '',
        toDivisionId: 0,
        toDivisionName: '',
        isMove: 0,
        isEdit: 0,
      },
    ],
  },
};

export const storeDivisionsSlice = createSlice({
  name: 'storeDivisions',
  initialState,
  reducers: {
    updateStoreDivisions: (state, action) => {
      state.message = action.payload.message;
      state.description = action.payload.description;
      state.data = action.payload.data;
    },
  },
});

export const { updateStoreDivisions } = storeDivisionsSlice.actions;

export default storeDivisionsSlice.reducer;
