import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { isSaturday, isSunday } from 'date-fns';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import commonStyles from '../../styles';
import { validateDate } from '../../../commonFunction/validations';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';

registerLocale('ja', ja);

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
  },
  datepicker: {
    border: 'none',
    width: '100%',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '&:not(.disabled):hover': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '&.disabled': {
      background: '#dcdcdc',
    },
  },
  sunday: {
    color: 'red',
  },
  saturday: {
    color: 'blue',
  },
}));

function HistoryDate(props) {
  const { data, row, startAtArray } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  /**
   * react-datepickerは基本的にDate型しか受け取れない
   * 用途(datepicker, API)によって使い分け
   * @typedef {Date} selectedDate
   * @typedef {string} formattedDate
  */
  const [selectedDate, setSelectedDate] = useState(new Date());
  const formattedDate = moment(selectedDate).format('YYYY/MM/DD');

  const minDate = data.key === 'endAt' ? moment(row.startAt).toDate() : null;
  let maxDate = null;
  let prevDivStartDate = null;

  if (data.key === 'startAt') {
    const endAt = moment(row.endAt).toDate();
    const today = new Date();
    maxDate = endAt > today ? today : endAt;

    // ひとつ前の所属組織のstartAtを取得
    if (row.isMainDivision === 1) {
      for (let i = 0; i < startAtArray.length; i += 1) {
        if (startAtArray[i] === row.startAt) {
          if (i + 1 < startAtArray.length) {
            prevDivStartDate = moment(startAtArray[i + 1]);
          }
          break;
        }
      }
    }
  }

  const disabled = row.isMainDivision === 1 && data.key === 'endAt';
  const datePickerRef = useRef();

  // 初期設定(はじめはnullで入ってくるので、dataが入ってきたら更新する)
  useEffect(() => {
    if (!data.state || data.state === formattedDate) return;
    setSelectedDate(moment(data.state).toDate());
  }, [data]);

  // カレンダーのボタンを押したとき or フォーカスが外れたとき発火
  const handleBlur = () => {
    const inputDate = datePickerRef.current.input.value;
    const isOverPrevDate = prevDivStartDate && moment(inputDate).isSameOrBefore(prevDivStartDate);
    if (!validateDate(inputDate) || inputDate !== formattedDate || isOverPrevDate) {
      setSelectedDate(moment(data.state).toDate());
      if (isOverPrevDate) dispatch(changeAlertMessage({ msgList: ['開始日は既に登録されている所属の開始日より後の日付を指定してください。'] }));
      return;
    }
    if (data.state === formattedDate) return;
    if (data.key === 'endAt' && new Date(formattedDate) < new Date(row.startAt)) return;
    data.setDate(formattedDate, data.index, data.key, data.type);
  };

  // カレンダーの土日に色を付ける
  const isWeekendHighlight = (date) => {
    let css = '';
    isSunday(date) ? css = classes.sunday : '';
    isSaturday(date) ? css = classes.saturday : '';
    return css;
  };

  // カレンダーの日付表示切替
  const renderDay = (day, date) => {
    let selectable = true;
    if ((minDate && minDate > date) || (maxDate && maxDate < date)) selectable = false;
    return (
      <div className={selectable ? isWeekendHighlight(date) : ''}>
        {day}
      </div>
    );
  };

  return (
    <ReactDatePicker
      className={`${baseClasses.textField} ${classes.datepicker} ${disabled && 'disabled'}`}
      wrapperClassName={classes.wrap}
      selected={selectedDate}
      placeholderText="日付を入力..."
      onChange={(d) => (d ? setSelectedDate(d) : null)}
      onCalendarClose={handleBlur}
      locale="ja"
      dateFormat="yyyy/MM/dd"
      dateFormatCalendar="yyyy年 MM月"
      maxDate={maxDate}
      minDate={minDate}
      renderDayContents={(day, date) => renderDay(day, date)}
      disabled={disabled}
      ref={datePickerRef}
    />
  );
}

export default HistoryDate;
