import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import AreaSentaku from '../../areaSentaku';
import SelectRailwaysAndStationsContainer from '../../../../containers/search/railwayAndStations/selectRailwayAndStations';
import {
  FLOOR_PLAN,
} from '../../../../constants';
import {
  priceSelect,
} from '../../../../constants/customerMain';
import NormalTooltip from '../../../styles/tooltip';
import inputNumber from '../../../../commonFunction/inputNumber';
import { TextBaseField } from '../../../eleCommon/validation';
import { convertForm1, convertFormNumber } from '../../../../commonFunction/convertors';
import { validateFormString1, validateFormFloat1, validateFormInt1 } from '../../../../commonFunction/validations';
import RangeSelect from '../../rangeSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  hope: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  // 希望地域
  hopeArea: {
    width: '100%',
  },
  hopeAreaBtn: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    minWidth: 'auto',
    width: '100%',
    '& > span': {
      display: 'block',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  hopeAreaBtnTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  // 沿線
  hopeRoute: {
    width: '100%',
  },
  hopeRouteBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  hopeRouteBox1: {
    width: 254,
  },
  hopeRouteBox2: {
    width: 524,
  },
  hopeRouteButton: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    minWidth: 'auto',
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minWidth: 42,
      minHeight: 21,
    },
    '&:hover': {
      background: '#fff',
    },
  },
  hopeRouteButton1: {
    width: '100%',
  },
  hopeRouteButtonTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 4px',
  },
  hopeRouteButtonWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  // 価格
  hopePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  hopePriceBlock: {
    width: 200,
  },
  hopePriceBox: {
    display: 'flex',
    alignItems: 'center',
  },
  hopePriceSelect: {
    width: 80,
    '& .MuiSelect-select': {
      fontWeight: 700,
    },
  },
  hopePriceLbl: {
    margin: '0 4px',
  },
  // textFieldのみ
  hopeTf: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  hopeTf1: {
    display: 'flex',
    alignItems: 'center',
    width: 838,
    marginRight: 8,
  },
  hopeTf2: {
    display: 'flex',
    alignItems: 'center',
    width: 218,
  },
  hopeTf3: {
    display: 'flex',
    alignItems: 'center',
    width: 534,
    marginRight: 8,
  },
  hopeTf4: {
    display: 'flex',
    alignItems: 'center',
    width: 534,
  },
  hopeTfLbl: {
    whiteSpace: 'nowrap',
  },
  hopeTfInput: {
    margin: '-3px 0 0 8px',
    width: '100%',
  },
  textField: {
    display: 'block',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  // チェックボックス
  hopeExamination: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  formGroup: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 5px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    '&:not(:last-child)': {
      marginBottom: 4,
    },
  },
  listItem1: {
    position: 'relative',
    padding: 0,
  },
  listItem2: {
    position: 'relative',
    padding: 0,
    '&:nth-child(even)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listItem3: {
    position: 'relative',
    padding: 0,
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 8px 16px 16px',
      borderBottom: '1px solid #F3F3F3',
      width: '100%',
      margin: 0,
      '& .MuiIconButton-root': {
        order: 2,
      },
      '& .MuiTypography-root': {
        order: 1,
        fontSize: 13,
        marginLeft: 0,
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function CustomerDetailHope(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  const menus = Object.keys(priceSelect.menus);
  const menus2 = Object.keys(FLOOR_PLAN);

  // 値からkeyを取得（初期値）
  let initial1 = '';
  let initial2 = '';
  let initial3 = '';
  let initial4 = '';
  if (input.detail.detachedHouseBudgetMin) {
    initial1 = Object.keys(priceSelect.menus).filter((key) => {
      return priceSelect.menus[key] === Number(input.detail.detachedHouseBudgetMin);
    });
  }
  if (input.detail.detachedHouseBudgetMax) {
    initial2 = Object.keys(priceSelect.menus).filter((key) => {
      return priceSelect.menus[key] === Number(input.detail.detachedHouseBudgetMax);
    });
  }
  if (input.detail.landBudgetMin) {
    initial3 = Object.keys(priceSelect.menus).filter((key) => {
      return priceSelect.menus[key] === Number(input.detail.landBudgetMin);
    });
  }
  if (input.detail.landBudgetMax) {
    initial4 = Object.keys(priceSelect.menus).filter((key) => {
      return priceSelect.menus[key] === Number(input.detail.landBudgetMax);
    });
  }

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // 希望地域開閉用のローカルステート
  const [openArea, setOpenArea] = useState(false);

  // 希望地域パラメーターを設定
  const [initialParamArea, setInitialParamArea] = useState({});

  // 希望地域を開く
  const handleAreaOpen = () => {
    setInitialParamArea({
      id: 1,
      wishAreaCode: input.detail.wishAreaCode,
      wishAreaIds: input.detail.wishAreaIds,
    });
    setOpenArea(true);
  };

  // 希望地域を閉じる
  const handleCloseArea = () => {
    setOpenArea(false);
  };

  // 希望地域を更新
  const handleUpdateArea = (params) => {
    // 変更した値をセット
    input.setDetail(
      {
        ...input.detail,
        wishArea: params.wishArea,
        wishAreaCode: params.wishAreaCode,
        wishAreaIds: params.wishAreaIds,
      },
    );
    // 更新用の値をセット
    input.dataInput.setData(
      {
        ...input.dataInput.data,
        wishArea: params.wishArea,
        wishAreaCode: params.wishAreaCode,
        wishAreaIds: params.wishAreaIds,
      },
    );
  };

  // 沿線開閉用のローカルステート
  const [openRailway, setOpenRailway] = useState(false);

  // 沿線パラメーターを設定
  const [initialParamRailway, setInitialParamRailway] = useState({});

  // 沿線を開く
  const handleOpenRailway = (wishID) => {
    // 沿線の第1希望, 第2希望のパラメータをセット
    setInitialParamRailway({
      id: wishID,
      wishRailwayAreaCodeId: input.detail[`wishRailwayAreaCodeId${wishID}`],
      wishRailwayId: input.detail[`wishRailwayId${wishID}`],
      wishRailway: input.detail[`wishRailway${wishID}`],
      wishStationIdFrom: input.detail[`wishStationIdFrom${wishID}`],
      wishStationFrom: input.detail[`wishStationFrom${wishID}`],
      wishStationIdTo: input.detail[`wishStationIdTo${wishID}`],
      wishStationTo: input.detail[`wishStationTo${wishID}`],
    });
    setOpenRailway(true);
  };

  // 沿線を閉じる
  const handleCloseRailway = () => {
    setOpenRailway(false);
  };

  // 沿線を更新
  const handleUpdateRailway = (data) => {
    console.log('handleUpdateRailway', data);
    // 変更した値をセット
    input.setDetail(
      {
        ...input.detail,
        [`wishRailwayAreaCodeId${data.id}`]: data.areaCode,
        [`wishRailwayId${data.id}`]: data.railway.railwayId,
        [`wishRailway${data.id}`]: data.railway.railwayName,
        [`wishStationIdFrom${data.id}`]: data.fromStation.stationId,
        [`wishStationFrom${data.id}`]: data.fromStation.name,
        [`wishStationIdTo${data.id}`]: data.toStation.stationId,
        [`wishStationTo${data.id}`]: data.toStation.name,
      },
    );
    // 更新用の値をセット
    input.dataInput.setData(
      {
        ...input.dataInput.data,
        [`wishRailwayAreaCodeId${data.id}`]: data.areaCode,
        [`wishRailwayId${data.id}`]: data.railway.railwayId,
        [`wishRailway${data.id}`]: data.railway.railwayName,
        [`wishStationIdFrom${data.id}`]: data.fromStation.stationId,
        [`wishStationFrom${data.id}`]: data.fromStation.name,
        [`wishStationIdTo${data.id}`]: data.toStation.stationId,
        [`wishStationTo${data.id}`]: data.toStation.name,
      },
    );
  };

  // フォーカスが外れた時
  const onBlur = (e) => {
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  // 項目選択時に更新 SP
  const onSelect1 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, detachedHouseBudgetMin: priceSelect.menus[val] });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, detachedHouseBudgetMin: priceSelect.menus[val],
    });
  };
  const onSelect2 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, detachedHouseBudgetMax: priceSelect.menus[val] });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, detachedHouseBudgetMax: priceSelect.menus[val],
    });
  };
  const onSelect3 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, landBudgetMin: priceSelect.menus[val] });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, landBudgetMin: priceSelect.menus[val],
    });
  };
  const onSelect4 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, landBudgetMax: priceSelect.menus[val] });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, landBudgetMax: priceSelect.menus[val],
    });
  };
  const onSelect5 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, floorPlanCode: val });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, floorPlanCode: val });
  };

  // 戸建て価格 土地価格 間取り PC
  const selectChange1 = (e) => {
    console.log(e.target.value);
    // 変更した値をセット
    input.setDetail({
      ...input.detail, detachedHouseBudgetMin: priceSelect.menus[e.target.value],
    });
  };
  const selectChange2 = (e) => {
    // 変更した値をセット
    input.setDetail({
      ...input.detail, detachedHouseBudgetMax: priceSelect.menus[e.target.value],
    });
  };
  const selectChange3 = (e) => {
    // 変更した値をセット
    input.setDetail({
      ...input.detail, landBudgetMin: priceSelect.menus[e.target.value],
    });
  };
  const selectChange4 = (e) => {
    // 変更した値をセット
    input.setDetail({
      ...input.detail, landBudgetMax: priceSelect.menus[e.target.value],
    });
  };
  const selectChange5 = (e) => {
    // 変更した値をセット
    input.setDetail({
      ...input.detail, floorPlanCode: Number(e.target.value),
    });
  };
  const selectBlur1 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, detachedHouseBudgetMin: priceSelect.menus[e.target.value],
    });
  };
  const selectBlur2 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, detachedHouseBudgetMax: priceSelect.menus[e.target.value],
    });
  };
  const selectBlur3 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, landBudgetMin: priceSelect.menus[e.target.value],
    });
  };
  const selectBlur4 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, landBudgetMax: priceSelect.menus[e.target.value],
    });
  };
  const selectBlur5 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data, floorPlanCode: Number(e.target.value),
    });
  };

  const initial = {
    detachedHouseBudgetMin: initial1,
    detachedHouseBudgetMax: initial2,
    landBudgetMin: initial3,
    landBudgetMax: initial4,
  };
  const customerSelectList = {
    detachedHouseBudgetMinItem: {
      key: 'detachedHouseBudgetMin',
      type: 'selectMulti',
      obj: priceSelect,
      vals: initial,
      state: initial1,
      set: selectBlur1,
      change: selectChange1,
    },
    detachedHouseBudgetMaxItem: {
      key: 'detachedHouseBudgetMax',
      type: 'selectMulti',
      obj: priceSelect,
      vals: initial,
      state: initial2,
      set: selectBlur2,
      change: selectChange2,
    },
    landBudgetMinItem: {
      key: 'landBudgetMin',
      type: 'selectMulti',
      obj: priceSelect,
      vals: initial,
      state: initial3,
      set: selectBlur3,
      change: selectChange3,
    },
    landBudgetMaxItem: {
      key: 'landBudgetMax',
      type: 'selectMulti',
      obj: priceSelect,
      vals: initial,
      state: initial4,
      set: selectBlur4,
      change: selectChange4,
    },
    floorPlanCodeItem: {
      type: 'select',
      obj: {
        menus: FLOOR_PLAN,
      },
      state: input.detail.floorPlanCode,
      set: selectBlur5,
      change: selectChange5,
    },
  };

  // チェックボックス
  const isThinkSaleInitial = () => {
    switch (input.detail.isThinkSale) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const isThinkApartmentInitial = () => {
    switch (input.detail.isThinkApartment) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const isThinkWholeHouseInvestmentInitial = () => {
    switch (input.detail.isThinkWholeHouseInvestment) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const isThinkDivisionInvestmentInitial = () => {
    switch (input.detail.isThinkDivisionInvestment) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const isThinkRebuildingInitial = () => {
    switch (input.detail.isThinkRebuilding) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const [checked, setChecked] = useState({
    isThinkSale: isThinkSaleInitial(),
    isThinkApartment: isThinkApartmentInitial(),
    isThinkWholeHouseInvestment: isThinkWholeHouseInvestmentInitial(),
    isThinkDivisionInvestment: isThinkDivisionInvestmentInitial(),
    isThinkRebuilding: isThinkRebuildingInitial(),
  });
  const {
    isThinkSale,
    isThinkApartment,
    isThinkWholeHouseInvestment,
    isThinkDivisionInvestment,
    isThinkRebuilding,
  } = checked;

  const examinationChange = (e) => {
    setChecked({ ...checked, [e.target.name]: e.target.checked });
    switch (e.target.checked) {
      case true:
        // 変更した値をセット
        input.setDetail({ ...input.detail, [e.target.name]: 1 });
        // 更新用の値をセット
        input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: 1 });
        break;
      default:
        // 変更した値をセット
        input.setDetail({ ...input.detail, [e.target.name]: 0 });
        // 更新用の値をセット
        input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: 0 });
    }
  };

  // ツールチップ用のテキスト
  const message1 = () => {
    return (
      <Grid>
        <Typography className={baseClasses.small}>対象：資産整理などで不動産の売却に興味がある顧客</Typography>
        <Typography className={baseClasses.small}>関連：開発事業部</Typography>
        <Typography className={baseClasses.small}>戸建用地の買取をサポート</Typography>
      </Grid>
    );
  };
  const message2 = () => {
    return (
      <Grid>
        <Typography className={baseClasses.small}>対象：新築マンションに興味がある顧客</Typography>
        <Typography className={baseClasses.small}>関連：マンション開発事業部</Typography>
        <Typography className={baseClasses.small}>都心部を中心に1LDK~3LDKの新築マンションを販売</Typography>
      </Grid>
    );
  };
  const message3 = () => {
    return (
      <Grid>
        <Typography className={baseClasses.small}>対象：高額帯の収益物件に興味がある顧客</Typography>
        <Typography className={baseClasses.small}>関連：ソリューション事業部、WM事業部</Typography>
        <Typography className={baseClasses.small}>2億円以上の1棟物の収益物件を販売</Typography>
        <Typography className={baseClasses.small}>節税を目的とした4,000万円前後の海外不動産を販売</Typography>
      </Grid>
    );
  };
  const message4 = () => {
    return (
      <Grid>
        <Typography className={baseClasses.small}>対象：投資用1Rマンションに興味がある顧客</Typography>
        <Typography className={baseClasses.small}>関連：プレサンスコーポレーション</Typography>
        <Typography className={baseClasses.small}>3,000万円前後の1R投資用マンションを販売</Typography>
      </Grid>
    );
  };
  const message5 = () => {
    return (
      <Grid>
        <Typography className={baseClasses.small}>対象：戸建の建て替えに興味がある顧客</Typography>
        <Typography className={baseClasses.small}>関連：オープンハウス・アーキテクト</Typography>
        <Typography className={baseClasses.small}>戸建の建て替えを提案</Typography>
      </Grid>
    );
  };

  const getBox5 = () => {
    return (
      <TextBaseField
        label="学区（第1希望）"
        defaultValue={input.detail.wishSchoolDistrict1}
        className={classes.textField}
        name="wishSchoolDistrict1"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 255 })}
        errorCallback={input.errorCallback('wishSchoolDistrict1')}
      />
    );
  };
  const getBox6 = () => {
    return (
      <TextBaseField
        label="学区（第2希望）"
        defaultValue={input.detail.wishSchoolDistrict2}
        className={classes.textField}
        name="wishSchoolDistrict2"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 255 })}
        errorCallback={input.errorCallback('wishSchoolDistrict2')}
      />
    );
  };
  const getBox7 = () => {
    return (
      <Grid>
        <Select defaultValue={0}>
          {menus.map((data) => {
            return (
              <MenuItem
                key={data}
                value={data}
                onClick={() => {
                  onSelect1(data);
                }}
                disabled={
                  Number(priceSelect.menus[data]) > Number(input.detail.detachedHouseBudgetMax)
                }
              >
                {priceSelect.menus[data]}
              </MenuItem>
            );
          })}
        </Select>
        <span>～</span>
        <Select defaultValue={0}>
          {menus.map((data) => {
            return (
              <MenuItem
                key={data}
                value={data}
                onClick={() => {
                  onSelect2(data);
                }}
                disabled={
                  Number(priceSelect.menus[data]) < Number(input.detail.detachedHouseBudgetMin)
                }
              >
                {priceSelect.menus[data]}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    );
  };
  const getBox9 = () => {
    return (
      <Grid>
        <Select defaultValue={0}>
          {menus.map((data) => {
            return (
              <MenuItem
                key={data}
                value={data}
                onClick={() => {
                  onSelect3(data);
                }}
                disabled={Number(priceSelect.menus[data]) > Number(input.detail.landBudgetMax)}
              >
                {priceSelect.menus[data]}
              </MenuItem>
            );
          })}
        </Select>
        <sapn>～</sapn>
        <Select defaultValue={0}>
          {menus.map((data) => {
            return (
              <MenuItem
                key={data}
                value={data}
                onClick={() => {
                  onSelect4(data);
                }}
                disabled={Number(priceSelect.menus[data]) < Number(input.detail.landBudgetMin)}
              >
                {priceSelect.menus[data]}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    );
  };
  const getBox11 = () => {
    return (
      <TextBaseField
        label="戸建て広さ（平米）"
        defaultValue={input.detail.livingSpace}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="livingSpace"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormFloat1({ minFloat: 0.0, maxFloat: 999999.0, precisionInt: 5 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('livingSpace')}
      />
    );
  };
  const getBox12 = () => {
    return (
      <Select>
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus2.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect5(data);
              }}
            >
              {FLOOR_PLAN[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox13 = () => {
    return (
      <TextBaseField
        label="土地広さ（平米）"
        defaultValue={input.detail.wishLandArea}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="wishLandArea"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormFloat1({ minFloat: 0.0, maxFloat: 999999.0, precisionInt: 5 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('wishLandArea')}
      />
    );
  };
  const getBox14 = () => {
    return (
      <TextBaseField
        className={`${classes.hopeTfInput} ${classes.textField}`}
        defaultValue={input.detail.checkedProductType}
        name="checkedProductType"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('checkedProductType')}
      />
    );
  };
  const getBox15 = () => {
    return (
      <TextBaseField
        type="number"
        inputProps={{ min: '0' }}
        className={`${classes.hopeTfInput} ${classes.textField}`}
        defaultValue={input.detail.checkedProductCount}
        name="checkedProductCount"
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('checkedProductCount')}
      />
    );
  };
  const getBox16 = () => {
    return (
      <TextBaseField
        className={`${classes.hopeTfInput} ${classes.textField}`}
        defaultValue={input.detail.reason}
        name="reason"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 100 })}
        errorCallback={input.errorCallback('reason')}
      />
    );
  };
  const getBox17 = () => {
    return (
      <TextBaseField
        className={`${classes.hopeTfInput} ${classes.textField}`}
        defaultValue={input.detail.wishMoveInDay}
        name="wishMoveInDay"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('wishMoveInDay')}
      />
    );
  };
  const getBox18 = () => {
    return (
      <TextBaseField
        className={`${classes.hopeTfInput} ${classes.textField}`}
        defaultValue={input.detail.importantPoint}
        name="importantPoint"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 200 })}
        errorCallback={input.errorCallback('importantPoint')}
      />
    );
  };
  const getBox19 = () => {
    return (
      <TextBaseField
        className={`${classes.hopeTfInput} ${classes.textField}`}
        defaultValue={input.detail.purchasePurpose}
        name="purchasePurpose"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('purchasePurpose')}
      />
    );
  };

  const getCheck1 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkSale} onChange={examinationChange} name="isThinkSale" />}
        label="売却"
      />
    );
  };
  const getCheck2 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkApartment} onChange={examinationChange} name="isThinkApartment" />}
        label="マンション"
      />
    );
  };
  const getCheck3 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkWholeHouseInvestment} onChange={examinationChange} name="isThinkWholeHouseInvestment" />}
        label="一棟物投資"
      />
    );
  };
  const getCheck4 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkDivisionInvestment} onChange={examinationChange} name="isThinkDivisionInvestment" />}
        label="区分投資"
      />
    );
  };
  const getCheck5 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkRebuilding} onChange={examinationChange} name="isThinkRebuilding" />}
        label="建替"
      />
    );
  };

  // SP 表示用
  const list1 = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '沿線（第1希望）',
      val: input.detail.wishRailway1 || '',
      click: (() => {
        handleOpenRailway(1);
      }),
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '駅（第1希望）',
      val: (() => {
        if (
          input.detail.wishStationFrom1 === null && input.detail.wishStationTo1 === null
        ) {
          return '';
        }
        return (input.detail.wishStationFrom1 || '') + ' ～ ' + (input.detail.wishStationTo1 || '');
      })(),
      click: (() => {
        handleOpenRailway(1);
      }),
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '沿線（第2希望）',
      val: input.detail.wishRailway2 || '',
      click: (() => {
        handleOpenRailway(2);
      }),
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '駅（第2希望）',
      val: (() => {
        if (
          input.detail.wishStationFrom2 === null && input.detail.wishStationTo2 === null
        ) {
          return '';
        }
        return (input.detail.wishStationFrom2 || '') + ' ～ ' + (input.detail.wishStationTo2 || '');
      })(),
      click: (() => {
        handleOpenRailway(2);
      }),
    },
  ];
  const list2 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '学区（第1希望）',
      val: input.detail.wishSchoolDistrict1,
      content: getBox5,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '学区（第2希望）',
      val: input.detail.wishSchoolDistrict2,
      content: getBox6,
    },
  ];

  const list3 = [
    {
      type1: 'noModal',
      type2: 'multiSelect',
      lbl: '戸建て価格（万円）',
      val1: input.detail.detachedHouseBudgetMin,
      val2: input.detail.detachedHouseBudgetMax,
      fontSize: '16px',
      content: getBox7,
    },
    {
      type1: 'noModal',
      type2: 'multiSelect',
      lbl: '土地価格（万円）',
      val1: input.detail.landBudgetMin,
      val2: input.detail.landBudgetMax,
      fontSize: '16px',
      content: getBox9,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '戸建て広さ（平米）',
      val: input.detail.livingSpace,
      fontSize: '16px',
      content: getBox11,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '間取り',
      val: FLOOR_PLAN[input.detail.floorPlanCode],
      fontSize: '16px',
      content: getBox12,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '土地広さ（平米）',
      val: input.detail.wishLandArea,
      fontSize: '16px',
      content: getBox13,
    },
  ];

  const list4 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'これまで見た物件の種類',
      val: input.detail.checkedProductType,
      content: getBox14,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'これまで見た物件の数',
      val: input.detail.checkedProductCount,
      fontSize: '16px',
      content: getBox15,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '検討のきっかけ',
      val: input.detail.reason,
      content: getBox16,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '入居希望時期',
      val: input.detail.wishMoveInDay,
      content: getBox17,
    },
  ];

  const list5 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '重視するポイント',
      val: input.detail.importantPoint,
      content: getBox18,
    },
  ];

  const list6 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '購入目的',
      val: input.detail.purchasePurpose,
      content: getBox19,
    },
  ];

  const list7 = [
    {
      lbl: '売却',
      val: isThinkSale,
      content: getCheck1,
    },
    {
      lbl: 'マンション',
      val: isThinkApartment,
      content: getCheck2,
    },
    {
      lbl: '一棟物投資',
      val: isThinkWholeHouseInvestment,
      content: getCheck3,
    },
    {
      lbl: '区分投資',
      val: isThinkDivisionInvestment,
      content: getCheck4,
    },
    {
      lbl: '建替',
      val: isThinkRebuilding,
      content: getCheck5,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          希望
        </Typography>
        <List className={classes.list}>
          <ListItem
            className={classes.listItem1}
          >
            <Button
              className={classes.listBtn}
              onClick={handleAreaOpen}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                希望地域
              </span>
              <span
                className={`${baseClasses.title4} ${classes.listVal}`}
              >
                {input.detail.wishArea}
              </span>
            </Button>
          </ListItem>
        </List>
        <List className={classes.list}>
          {list1.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                <Button
                  className={classes.listBtn}
                  onClick={item.click}
                >
                  <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                    {item.lbl}
                  </span>
                  <span
                    className={`${baseClasses.title4} ${classes.listVal}`}
                  >
                    {item.val}
                  </span>
                </Button>
              </ListItem>
            );
          })}
          {list2.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem2}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <List className={classes.list}>
          {list3.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem1}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <List className={classes.list}>
          {list4.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem2}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <List className={classes.list}>
          {list5.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem1}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <List className={classes.list}>
          {list6.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem1}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          検討
        </Typography>
        <List className={classes.list}>
          {list7.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem3}
              >
                {item.content()}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          希望
        </Typography>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeArea}>
            <Button
              className={classes.hopeAreaBtn}
              onClick={handleAreaOpen}
            >
              <Typography className={baseClasses.title6}>
                希望地域
              </Typography>
              <Typography className={classes.hopeAreaBtnTxt}>
                {input.detail.wishArea}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeRoute}>
            <Grid className={classes.hopeRouteBox}>
              <Grid className={classes.hopeRouteBox1}>
                <Typography className={baseClasses.title6}>
                  沿線（第1希望）
                </Typography>
                <Grid className={classes.hopeRouteButtonWrap}>
                  <Button
                    className={`${classes.hopeRouteButton} ${classes.hopeRouteButton1}`}
                    onClick={() => {
                      handleOpenRailway(1);
                    }}
                  >
                    {input.detail.wishRailway1}
                  </Button>
                </Grid>
              </Grid>
              <Grid className={classes.hopeRouteBox1}>
                <Typography className={baseClasses.title6}>
                  駅（第1希望）
                </Typography>
                <Grid className={classes.hopeRouteButtonWrap}>
                  <Button
                    className={classes.hopeRouteButton}
                    onClick={() => {
                      handleOpenRailway(1);
                    }}
                  >
                    {input.detail.wishStationFrom1}
                  </Button>
                  <Typography className={classes.hopeRouteButtonTxt}>
                    ～
                  </Typography>
                  <Button
                    className={classes.hopeRouteButton}
                    onClick={() => {
                      handleOpenRailway(1);
                    }}
                  >
                    {input.detail.wishStationTo1}
                  </Button>
                </Grid>
              </Grid>
              <Grid className={classes.hopeRouteBox1}>
                <Typography className={baseClasses.title6}>
                  沿線（第2希望）
                </Typography>
                <Grid className={classes.hopeRouteButtonWrap}>
                  <Button
                    className={`${classes.hopeRouteButton} ${classes.hopeRouteButton1}`}
                    onClick={() => {
                      handleOpenRailway(2);
                    }}
                  >
                    {input.detail.wishRailway2}
                  </Button>
                </Grid>
              </Grid>
              <Grid className={classes.hopeRouteBox1}>
                <Typography className={baseClasses.title6}>
                  駅（第2希望）
                </Typography>
                <Grid className={classes.hopeRouteButtonWrap}>
                  <Button
                    className={classes.hopeRouteButton}
                    onClick={() => {
                      handleOpenRailway(2);
                    }}
                  >
                    {input.detail.wishStationFrom2}
                  </Button>
                  <Typography className={classes.hopeRouteButtonTxt}>
                    ～
                  </Typography>
                  <Button
                    className={classes.hopeRouteButton}
                    onClick={() => {
                      handleOpenRailway(2);
                    }}
                  >
                    {input.detail.wishStationTo2}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.hopeRouteBox}>
              <Grid className={classes.hopeRouteBox2}>
                {getBox5()}
              </Grid>
              <Grid className={classes.hopeRouteBox2}>
                {getBox6()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopePrice}>
            <Grid className={classes.hopePriceBlock}>
              <Typography className={baseClasses.title6}>
                戸建て価格（万円）
              </Typography>
              <RangeSelect
                defaultValue={[
                  input.detail.detachedHouseBudgetMin,
                  input.detail.detachedHouseBudgetMax,
                ]}
                options={priceSelect.menus}
                onChange={(e) => {
                  input.dataInput.setData({
                    ...input.dataInput.data,
                    detachedHouseBudgetMin: e[0],
                    detachedHouseBudgetMax: e[1],
                  });
                }}
                equalable
                selectClassName={classes.hopePriceSelect}
              />
            </Grid>
            <Grid className={classes.hopePriceBlock}>
              <Typography className={baseClasses.title6}>
                土地価格（万円）
              </Typography>
              <RangeSelect
                defaultValue={[
                  input.detail.landBudgetMin,
                  input.detail.landBudgetMax,
                ]}
                options={priceSelect.menus}
                onChange={(e) => {
                  input.dataInput.setData({
                    ...input.dataInput.data,
                    landBudgetMin: e[0],
                    landBudgetMax: e[1],
                  });
                }}
                equalable
                selectClassName={classes.hopePriceSelect}
              />
            </Grid>
            <Grid className={classes.hopePriceBlock}>
              {getBox11()}
            </Grid>
            <Grid className={classes.hopePriceBlock}>
              <Typography className={baseClasses.title6}>
                間取り
              </Typography>
              {input.dataInput.component(customerSelectList.floorPlanCodeItem)}
            </Grid>
            <Grid className={classes.hopePriceBlock}>
              {getBox13()}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeTf1}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              これまで見た物件の種類
            </Typography>
            {getBox14()}
          </Grid>
          <Grid className={classes.hopeTf2}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              これまで見た物件の数
            </Typography>
            {getBox15()}
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeTf3}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              検討のきっかけ
            </Typography>
            {getBox16()}
          </Grid>
          <Grid className={classes.hopeTf4}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              入居希望時期
            </Typography>
            {getBox17()}
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeTf}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              重視するポイント
            </Typography>
            {getBox18()}
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeTf}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              購入目的
            </Typography>
            {getBox19()}
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeTf}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              概要メモ
            </Typography>
            <TextBaseField
              className={`${classes.hopeTfInput} ${classes.textField}`}
              defaultValue={input.detail.summary}
              name="summary"
              onBlur={onBlur}
              errorCallback={input.errorCallback('summary')}
            />
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeTf}>
            <Typography className={`${classes.hopeTfLbl} ${baseClasses.title6}`}>
              メモ
            </Typography>
            <TextBaseField
              className={`${classes.hopeTfInput} ${classes.textField}`}
              defaultValue={input.detail.memo}
              multiline
              rows="3"
              name="memo"
              onBlur={onBlur}
              errorCallback={input.errorCallback('memo')}
            />
          </Grid>
        </Grid>
        <Grid className={classes.hope}>
          <Grid className={classes.hopeExamination}>
            <Typography className={`${classes.hopeExaminationLbl} ${baseClasses.title6}`}>
              検討
            </Typography>
            <FormGroup className={classes.formGroup}>
              <NormalTooltip title={message1()}>
                {getCheck1()}
              </NormalTooltip>
              <NormalTooltip title={message2()}>
                {getCheck2()}
              </NormalTooltip>
              <NormalTooltip title={message3()}>
                {getCheck3()}
              </NormalTooltip>
              <NormalTooltip title={message4()}>
                {getCheck4()}
              </NormalTooltip>
              <NormalTooltip title={message5()}>
                {getCheck5()}
              </NormalTooltip>
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <AreaSentaku
        initialParam={initialParamArea}
        open={openArea}
        onClose={handleCloseArea}
        onUpdate={handleUpdateArea}
      />
      <SelectRailwaysAndStationsContainer
        initialParam={initialParamRailway}
        open={openRailway}
        onUpdate={handleUpdateRailway}
        onClose={handleCloseRailway}
      />
    </Grid>
  );
}
