import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import commonStyles from '../../styles';

const useStyles = makeStyles(() => ({
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
  customButtonSpan: {
    color: '#ffffff',
    position: 'static',
    left: '32%',
    right: '16%',
    top: '13.79%',
    bottom: '13.79%',
  },
  createMode: {
    cursor: 'default',
  },
  createModeSpan: {
    color: '#808080',
  },
}));

function AddUserButton(props) {
  const {
    // userPrf,// TO DELETE:当コンポーネントで呼び出すためコメントアウト（後ほど削除）
    resetUserFunction,
    createMode,
    setCreateMode,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const userPrf = useSelector((state) => state.user.userDetail);

  useEffect(() => {
    setCreateMode(!userPrf.userId);
  }, [userPrf]);

  const handleClick = async () => {
    await resetUserFunction();
    setCreateMode(true);
    // getUserFunction();
  };

  const buttonComponent = () => {
    return (
      <Button
        variant="contained"
        disabled={createMode}
        className={
          createMode
            ? `${classes.userCustomButton} ${classes.createMode}`
            : `${baseClasses.buttonsPrimary} ${classes.userCustomButton}`
        }
        onClick={() => {
          handleClick();
        }}
      >
        <span
          className={
            createMode
              ? `${baseClasses.smallStrong} ${classes.customButtonSpan} ${classes.createModeSpan}`
              : `${baseClasses.smallStrong} ${classes.customButtonSpan}`
          }
        >
          {createMode ? '新規追加中' : '社員追加'}
        </span>
      </Button>
    );
  };

  return <div>{buttonComponent()}</div>;
}

export default AddUserButton;
