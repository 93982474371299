// @ts-check
import client from '../httpClient';

/**
 * @typedef { import(
 *   './getStoreDivisionsApiTypes'
 * ).StoreDivisionsGetResponse } StoreDivisionsGetResponse
 * @typedef { import('axios').AxiosResponse<StoreDivisionsGetResponse> } GetResponse
 *
 * @type { (areaCode: number) => Promise<GetResponse> }
 */
const getStoreDivisionsApi = async (areaCode) => {
  const result = await client.get(`/division-masters/store-division-list?areaCode=${areaCode}`);
  return result;
};

export default getStoreDivisionsApi;
