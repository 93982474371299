import { makeStyles } from '@material-ui/core';
import NormalTooltip from '../../../styles/tooltip';

const useStyles = makeStyles({
  tooltip: { maxWidth: 'none' },
});

function Label(props) {
  const { data } = props;
  const classes = useStyles();

  let label = '';
  if (data.key === 'isCanCallNow') {
    const message = `${new Date().getHours()}時に電話が繋がりやすい顧客の絞り込みはこちら`;
    label = (
      <NormalTooltip title={message} classes={{ tooltip: classes.tooltip }}>
        <span>{data.title}</span>
      </NormalTooltip>
    );
  } else if (data.key === 'isCalledAndNotMailed') {
    const message = '本日電話掛けした顧客の中で、まだメールやSMSを送信していない顧客に絞込';
    label = (
      <NormalTooltip title={message} classes={{ tooltip: classes.tooltip }}>
        <span>{data.title}</span>
      </NormalTooltip>
    );
  } else {
    label = (
      <span>{data.title}</span>
    );
  }
  return label;
}
export default Label;
