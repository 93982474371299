/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';

import MailTitleAndBody from './parts/titleAndBody';
import MailSelect from './parts/mailSelect';
import MailChangeBtn from './parts/changeBtn';
import MailTextfield from './parts/textfield';
import MailAppendFile from './parts/appendFile';
import ChangeTo from './parts/changeTo';
import AddressChangeBtn from './parts/changeAddress';
import ChangeScheduleModal from './parts/changeScheduleModal';
import Dialog from '../../components/common/modal';

import { useLoading } from '../../hooks';

import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import { changeAlertMessage } from '../../store/eleCommon/customAlertMessage';

import { setSessionStorage } from '../../commonFunction';

import {
  MAIL_FROM_SELF,
  MAIL_FROM_TEAM_MEMBER,
  MAIL_TYPE_INDIVIDUAL_TIMER,
  MAIL_TYPE_SIMULTANEOUS_TIMER,
} from '../../constants';
import {
  MAIL_SUBJECT,
  MAIL_BODY,
  MAIL_FROM_CODE,
  MAIL_SIGN,
  MAIL_ATTACHMENTS,
} from '../../constants/sessionStorage';
import { MAIL_SENDING, TESTMAIL_SENDING } from '../../constants/loading';
import {
  validateFalsyValues,
  isEmptyArray,
} from '../../commonFunction/validations';
import LibraryCreate from '../mailLibrary/libraryCreate';
import store from '../../store';
import librarydirGetDirectlyTreeApi from '../../apis/librarydir/librarydirGetDirectlyTreeApi';
import { librarydirOwnApi } from '../../store/librarydir/librarydirOwnSlice';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    padding: '24px 24px 8px 24px',
    backgroundColor: '#fff',
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    padding: '32px 24px 24px',
    justifyContent: 'space-between',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
  },
  middle: {
    padding: '32px',
  },
  blue: {
    color: theme.palette.primaryColor,
    // marginRight: '16px',
  },
  titleAndBody: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
}));

function MailCreateWindowComponent(props) {
  const {
    open,
    handleClose,
    user,
    mailFunctions,
    editorState,
    setEditorState,
    isTestSent,
    setIsTestSent,
    testMailParam,
    setTestMailParam,
    mailParam,
    setMailParam,
    meta,
    setMeta,
    setting,
    updateSendMail,
    cancelSendMail,
    allFlg,
    taioRirekiFlg,
    isLocalStorage,
    successSendMailCallBack,
    mailBodyKey,
    uploadedList,
    setUploadedList,
    isActiveSaveTemplate,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();

  const { addLoading, removeLoading, hasLoading } = useLoading();
  const { responseHeader } = store.getState();

  const [isOpenUnsentMail, setIsOpenUnsentMail] = useState(false);
  const [isLibraryOpen, setIsLibraryOpen] = useState({ create: false, edit: false });
  const ownDir = useSelector((state) => state.librarydirOwn.librarydirOwn);

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const isTimerMail = [
    MAIL_TYPE_INDIVIDUAL_TIMER,
    MAIL_TYPE_SIMULTANEOUS_TIMER,
  ].includes(mailParam.mailTypeCode);
  const isAlreadySend = taioRirekiFlg && new Date(meta.sendTime) < new Date();

  const successBaseMailCallBack = (param) => {
    if (!isLocalStorage) return;
    setSessionStorage(MAIL_SUBJECT, param.mailSubject);
    setSessionStorage(MAIL_BODY, param.mailBody);
    setSessionStorage(MAIL_FROM_CODE, param.mailFromCode);
    const signObj = {
      libraryId: param.signatureLibraryId,
    };
    setSessionStorage(MAIL_SIGN, signObj);
    setSessionStorage(MAIL_ATTACHMENTS, param.files);
  };
  const successTestMailCallBack = () => {
    setIsTestSent(true);
    successBaseMailCallBack(testMailParam);
  };
  const failureTestMailCallBack = () => setIsTestSent(false);

  const testSendHandler = () => {
    addLoading(TESTMAIL_SENDING);
    // 送信が成功したら送信ボタン活性
    if (testMailParam.mailSubject === '') {
      dispatch(
        changeAlertMessage({
          msgList: ['件名を入力してください'],
        }),
      );
    } else if (
      testMailParam.mailBody === ''
      || testMailParam.mailBody === '<p></p>\n'
    ) {
      dispatch(
        changeAlertMessage({
          msgList: ['本文を入力してください'],
        }),
      );
    } else {
      const param = { ...testMailParam };
      if (testMailParam.mailFromCode === MAIL_FROM_TEAM_MEMBER && allFlg) {
        const signIds = Object.keys(setting.sign.constants.menus);
        if (signIds.length > 0) {
          const [tmpId] = signIds;
          param.signatureLibraryId = Number(tmpId);
        }
      }
      mailFunctions.sendTestMail(
        param,
        successTestMailCallBack,
        failureTestMailCallBack,
      );
    }
    setTimeout(() => removeLoading(TESTMAIL_SENDING), 2000);
  };
  const successMailCallBack = () => {
    successSendMailCallBack();
    successBaseMailCallBack(mailParam);
  };
  const sendMail = () => {
    addLoading(MAIL_SENDING);
    // mailParam = "division005の検索パラメータ" + divisionIds
    allFlg
      ? mailFunctions.sendMails(
        validateFalsyValues(
          { ...mailParam },
          [],
          [0],
          isEmptyArray,
        ),
        successMailCallBack,
      )
      : mailFunctions.sendMail(
        { ...mailParam },
        successMailCallBack,
      );
    handleClose();
    setTimeout(() => removeLoading(MAIL_SENDING), 2000);
  };

  // tree GET librarydir001
  // ログインユーザーのルートライブラリを取得
  const getOwnLibrary = async () => {
    // 取得済みならスキップ
    if (Object.keys(ownDir).length) return;
    addLoading(MAIL_SENDING);
    await librarydirGetDirectlyTreeApi()
      .then((res) => {
        const targetDir = Object.values(res.data.libraryDirectories).find((e) => e.userId === responseHeader.userId);
        dispatch(librarydirOwnApi(targetDir));
      })
      .catch((err) => console.error(err))
      .finally(() => removeLoading(MAIL_SENDING));
  };

  const getButtons = () => {
    if (!taioRirekiFlg) {
      // 一斉/個別のメール作成画面
      return [
        {
          label: 'テスト送信',
          className: common.buttonsSecondary,
          onClick: testSendHandler,
          disabled: errorSet.size !== 0 || hasLoading(TESTMAIL_SENDING),
        },
        {
          label: '送信',
          className: common.buttonsPrimary,
          onClick: sendMail,
          disabled: !isTestSent || errorSet.size !== 0 || hasLoading(MAIL_SENDING),
        },
      ];
    }
    if (isAlreadySend || !isTimerMail) return [];
    // 未送信メールの場合
    return [
      {
        label: '今すぐ送信',
        className: common.buttonsPrimary,
        onClick: () => dispatch(changeConfirmMessage({
          title: '確認',
          msgList: [
            '今すぐ送信開始します。',
            '送信が多い時間帯は、送信開始まで時間がかかる場合があります。',
          ],
          buttons: [
            {
              label: 'CANCEL',
              set: () => {},
              classes: common.buttonsSecondary,
            },
            {
              label: 'OK',
              set: () => updateSendMail(),
              classes: common.buttonsPrimary,
            },
          ],
        })),
      },
      {
        label: '送信時間の変更',
        className: common.buttonsPrimary,
        onClick: () => setIsOpenUnsentMail(true),
      },
      {
        label: '送信の取り消し',
        className: common.buttonsSecondary,
        onClick: () => dispatch(changeConfirmMessage({
          title: '確認',
          msgList: ['送信予定のメールを削除します。', 'よろしいですか？'],
          buttons: [
            {
              label: 'CANCEL',
              set: () => {},
              classes: common.buttonsSecondary,
            },
            {
              label: 'OK',
              set: () => cancelSendMail(),
              classes: common.buttonsPrimary,
            },
          ],
        })),
      },
    ];
  };

  useEffect(() => {
    setTestMailParam?.({
      ...testMailParam,
      mailBody: editorState,
    });
    setMailParam?.({
      ...mailParam,
      mailBody: editorState,
    });
    setMeta?.({ ...meta, content: editorState });
  }, [editorState]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        title={
          allFlg
            ? '一斉メールを作成します'
            : `${user.lastName || ''} ${user.firstName || ''} 様宛 メール作成`
        }
        maxWidth="1160px"
        buttons={getButtons()}
        pcOnly
        buttonClose
      >
        <Grid container>
          <Grid
            container
            justifyContent="space-between"
          >
            <MailSelect values={setting.from} disable={taioRirekiFlg} />
            <MailSelect
              values={setting.info}
              disable={
                meta.from === MAIL_FROM_SELF
                || meta.from === MAIL_FROM_TEAM_MEMBER
                || taioRirekiFlg
              }
            />
            <MailChangeBtn values={setting.library} />
          </Grid>
          <ChangeTo
            values={setting.to}
            user={user}
            updateFunction={mailFunctions.updateMailAddress}
          />
          <Grid
            container
            justifyContent="space-between"
          >
            <AddressChangeBtn values={setting.cc} />
            <AddressChangeBtn values={setting.bcc} />
            <AddressChangeBtn values={setting.tcc} />
          </Grid>
          <Grid
            container
            justifyContent="space-between"
          >
            <MailTextfield values={setting.title} errorCallback={errorCallback} />
            <MailChangeBtn values={setting.sendTime} />
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            className={classes.titleAndBody}
          >
            <MailTitleAndBody
              // NOTE: ステート管理はしないので、keyを入れることで意図的にdefault値を更新させる
              // https://qiita.com/putan/items/8d976afab638ffb96acb
              key={mailBodyKey}
              initData={setting.content.state}
              setMailBody={setEditorState}
              disable={taioRirekiFlg}
              getOwnLibrary={getOwnLibrary}
              isLibraryOpen={isLibraryOpen}
              setIsLibraryOpen={setIsLibraryOpen}
              isActiveSaveTemplate={isActiveSaveTemplate}
            />
            <MailAppendFile
              uploadedList={uploadedList}
              setUploadedList={setUploadedList}
              disable={taioRirekiFlg}
            />
          </Grid>
        </Grid>
      </Dialog>
      <ChangeScheduleModal
        open={isOpenUnsentMail}
        onClose={() => setIsOpenUnsentMail(false)}
        onClickOK={updateSendMail}
      />
      {/*  ライブラリを保存 */}
      <LibraryCreate
        open={isLibraryOpen}
        onClose={() => { return setIsLibraryOpen({ ...isLibraryOpen, create: false }); }}
        selectDir={ownDir}
        initialMailTitle={setting.title.state}
        initialMailBody={setting.content.state}
      />
    </>
  );
}

export default MailCreateWindowComponent;
