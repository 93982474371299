import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import { isNotPhoneNumber } from '../../../commonFunction';

const useStyles = makeStyles(() => ({
  userCustomButtonSave: {
    padding: '8px 22px',
    width: '74px',
    height: '40px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '12px 16px',
  },
  customButtonSpan: {
    color: '#ffffff',
    position: 'static',
    left: '32%',
    right: '16%',
    top: '13.79%',
    bottom: '13.79%',
  },
}));

function SaveUserButton(props) {
  const {
    updateUserFunction,
    createUserFunction,
    setValidate,
    errorSet,
  } = props;
  const [isValid, setIsValid] = useState(false);
  const classes = useStyles();
  const baseClasses = commonStyles();

  const userPrf = useSelector((state) => state.user.userDetail);

  // 保存ボタン活性非活性制御
  useEffect(() => {
    if (userPrf.mobilePhoneNumber && isNotPhoneNumber(userPrf.mobilePhoneNumber)) {
      setIsValid(true);
    } else {
      const requireList = [
        userPrf.activeDirectoryAuthenticationId,
        userPrf.userCode,
        userPrf.firstName,
        userPrf.firstNameKana,
        userPrf.lastName,
        userPrf.lastNameKana,
        userPrf.mail,
        userPrf.startAt,
        userPrf.endAt,
        userPrf.isHide,
        userPrf.userTypeCode,
      ];
      setIsValid(requireList.includes(null || ''));
    }
  }, [userPrf]);

  // APICall前最終バリデーションチェック
  const checkNullValid = () => {
    const requireList = [
      userPrf.activeDirectoryAuthenticationId,
      userPrf.userCode,
      userPrf.firstName,
      userPrf.firstNameKana,
      userPrf.lastName,
      userPrf.lastNameKana,
      userPrf.mail,
      userPrf.startAt,
      userPrf.endAt,
      userPrf.isHide,
      userPrf.userTypeCode,
    ];
    return requireList.includes(null || '');
  };

  const handleClick = async () => {
    await setValidate(true);
    if (!checkNullValid()) {
      await setValidate(false);
      if (userPrf.userId) {
        const copyUserPrf = Object.assign({}, userPrf);
        delete copyUserPrf.divisionHistories;
        // FIXME: 以下は8/25以降不要 確認必須
        copyUserPrf.systems.length === 0 ? delete copyUserPrf.systems : null;
        copyUserPrf.userBusinesses.length === 0 ? delete copyUserPrf.userBusinesses : null;
        // --------------------------------
        copyUserPrf.divisions = [];
        // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4423
        copyUserPrf.genderCode = copyUserPrf.genderCode === 0 ? null : copyUserPrf.genderCode;
        updateUserFunction(userPrf.userId, copyUserPrf);
      } else {
        const copyUserPrf = Object.assign({}, userPrf);
        delete copyUserPrf.divisions;
        createUserFunction(copyUserPrf);
      }
    } else {
      await setValidate(true);
    }
  };

  return (
    <Button
      variant="contained"
      className={`${baseClasses.buttonsPrimary} ${classes.userCustomButtonSave}`}
      disabled={isValid || errorSet.size !== 0}
      onClick={handleClick}
    >
      <span
        className={`${baseClasses.smallStrong} ${classes.customButtonSpan}`}
      >
        保存
      </span>
    </Button>
  );
}

export default SaveUserButton;
