import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

export const sendmailLastUpdateSignatureSlice = createSlice({
  name: 'lastUpdateSignature',
  initialState: {
    lastUpdateSignature: initialState,
  },
  reducers: {
    setLastUpdateSignature: (state, data) => {
      state.lastUpdateSignature = data.payload;
    },
  },
});

export const { setLastUpdateSignature } = sendmailLastUpdateSignatureSlice.actions;

export default sendmailLastUpdateSignatureSlice.reducer;
