import {
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    position: 'absolute',
    // top: 0,
    bottom: 4,
    right: 10,
    margin: 'auto 0',
    opacity: 0,
    transition: 'all .5s ease',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 50,
    '& .MuiButton-text': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '160%',
      color: theme.palette.primaryColor,
    },
  },
  button: {
    width: 58,
    height: 24,
    marginRight: 5,
    backgroundColor: '#fff',
    color: theme.palette.primaryColor,
    ...theme.overrides.font.weightBold,
    ...theme.overrides.font.sizeSmall2,
    '& span': {
      '& .MuiButton-startIcon': {
        marginRight: 0,
        '& svg': {
          width: 24,
          height: 20,
        },
      },
    },
  },
  label: {
    borderRight: 'none',
    fontWeight: 700,
  },
}));

/**
 * hoverでポップオーバーボタンを表示する
 * @module object
 * @param {array} buttons - ボタンのデータ配列
 */
function ButtonPopover(props) {
  const { buttons } = props;
  const classes = useStyles();

  return (
    <Grid className={`${classes.buttonContainer} buttonContainer`}>
      {buttons.map(b => (
        <Button
          key={b.label}
          onClick={() => {
            b.set();
          }}
          className={classes.button}
          startIcon={b.icon}
        >
          {b.label}
        </Button>
      ))}
    </Grid>
  );
}

export default ButtonPopover;
