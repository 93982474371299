import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Paper,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import UserTreeContainers from '../../../containers/common/userTree/userTreeContainerNew';
import SaveButton from './parts/saveButton';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { randomId } from '../../../commonFunction';
import { USERS_TREE } from '../../../constants/customerTree';
import AcquisitionType from '../../pages/joinData/acquisitionType';
import AcquisitionName from '../../pages/joinData/acquisitionName';
import { USE_AQCUISITION_TREE_GROUP } from '../../../constants';

const useStyles = makeStyles(() => ({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '10000!important',
      '& .MuiPaper-root': {
        width: '100%',
        background: '#F3F3F3',
      },
      '& .MuiDialog-paper': {
        maxWidth: 'none',
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'initial',
        borderRadius: 0,
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
        borderRadius: 0,
      },
    },
  },
  header: {
    borderBottom: 'solid 1px #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '19px 0 8px',
      textAlign: 'center',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
    },
  },
  contentElement: {
    width: '100%',
    position: 'static',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    [commonTheme.breakpoints.up('md')]: {
      height: '500px',
      overflowY: 'scroll',
      left: '0px',
      top: '53px',
      padding: 24,
      background: '#F3F3F3',
      borderBottom: '#c8c8c8 1px solid',
      '& > div': {
        padding: 0,
        marginTop: 0,
        height: '100%',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0',
    },
    '&>*': {
      boxSizing: 'border-box',
      width: '100%',
    },
  },
  customTitle3: {
    boxSizing: 'border-box',
    width: '100%',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
  orgTitleClass: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      padding: '10px 5px',
      marginTop: '1%',
    },
    '&.isType1': {
      [commonTheme.breakpoints.up('md')]: {
        height: '100%',
      },
    },
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      [commonTheme.breakpoints.up('md')]: {
        marginLeft: 16,
      },
    },
  },
  footer: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 16,
    },
  },
}));

function PaperComponent(props) {
  const {
    'aria-labelledby': id,
  } = props;

  return (
    <Draggable
      handle={`[data-drag-id="${id}"]`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function UserTree(props) {
  const {
    isOpenTree, // isDialog = true時: 期待値Boolean
    setIsOpenTree, // isDialog = true時: 期待値=ダイアログ開閉のsetState
    selectUser, // 選択されたUserのUserIdとUserName: 期待値={userId: 0, userName: ''}のstate
    setSelectUser = () => {}, // 選択されたUserのUserIdとUserName: 期待値={userId: 0, userName: ''}のsetState
    selectDivision, // 選択されたDivisionのDivisionId: 期待値=組織IDのsetState
    setSelectDivision, // 選択されたDivisionのDivisionId: 期待値=組織IDのsetState
    treeHeaderSelect, // ツリーのヘッダー検索ボックスの内容： 期待値=[{menus:{}}]
    changeTitle, // 任意：ツリーのタイトルを変更したい場合、文字列を指定
    handleSelectFunction, // 任意：[選択]ボタンをクリック時に走らせたい処理を指定
    handleClearFunction, // 任意：[解除]ボタンをクリック時に走らせたい処理を指定
    showOnlyDivisions, // 組織だけのツリー表示フラグ： 期待値=Boolean
    clickableUserAndDivision = false, // 組織担当者どちらも選択するフラグ(SPは考慮してない)
    isMulti, // ユーザーを複数表示とするフラグ： 期待値=Boolean
    isDuplicate = false, // 同一画面で複数箇所にてツリーを使う場合。これを用いないとツリーが開かない。
    isDialog, // ダイアログ形式フラグ： 期待値=Boolean
    isNotSearch, // 検索が不要な場合: 期待値=Boolean
    setTargetDivisionTree, // 選択された組織配下全てのAPIパラメータのset: 期待値=setState
    isSelectUserWindow, // ユーザー選択の場合
    isUserMaster = false, // 組織マスターのツリーの場合
    isDefaultLoginUser, // ツリーが選択されてないとき、Openした後自動的にDefaultをログインUserに設定する
    doubleSearch, // 検索に組織も社員も引っかける
    isAcquirer = false, // 獲得者選択画面かどうかhttps://openhouse.backlog.jp/view/SFA_ASIAQUEST-3853
    initialAcquirer = '1', // 獲得者種類
    setInitialAcquirer = () => {}, // 獲得者種類のsetState
    setDisplayName = () => {},
    setFormValue = () => {},
    defaultDisplayName,
    isLineGroupSetting = false, // LINE GROUP作成画面経由であるかどうか SFA_ASIAQUEST-4696
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [id] = useState(randomId());
  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const initialUser = useRef({ userId: 0, userName: '' });
  const initialDivision = useRef({ divId: 0, divisionName: '' });

  let label = '担当者選択';
  if (showOnlyDivisions) label = '組織選択';
  if (clickableUserAndDivision) label = '担当・組織選択';
  if (isAcquirer) label = '獲得者選択';

  useEffect(() => {
    initialUser.current = selectUser ?? { userId: 0, userName: '' };
    initialDivision.current = selectDivision ?? { divId: 0, divisionName: '' };
  }, []);

  // 選択解除 ✖ボタン時にinitialの値にする場合 isInitial = true
  const handleClose = (isInitial = false) => {
    // 担当者複数選択の場合
    if (isMulti) {
      if (isInitial && isLineGroupSetting) {
        setSelectUser(initialUser.current);
      } else {
        setSelectUser([]);
      }
    } else {
      if (isInitial) {
        setSelectDivision(initialDivision.current);
      } else {
        setSelectDivision({ divId: 0, divisionName: '' });
      }
      if (!showOnlyDivisions) {
        if (isInitial) {
          setSelectUser(initialUser.current);
        } else {
          setSelectUser({ userId: 0, userName: '' });
        }
      } else if (setTargetDivisionTree) {
        if (!isInitial) {
          setTargetDivisionTree({});
        }
      }
    }
    // 解除する場合に、カスタムした処理を実行
    if (handleClearFunction) {
      handleClearFunction(isInitial);
    }
    setIsOpenTree(false);
  };

  const onClickSelect = () => {
    // ユーザー選択時、任意の処理
    if (handleSelectFunction) {
      handleSelectFunction();
    }
    setIsOpenTree(false);
  };

  if (isDialog) {
    return (
      <Dialog
        disableEscapeKeyDown
        open={isOpenTree}
        onClose={() => { setIsOpenTree(false); }}
        PaperComponent={PaperComponent}
        aria-labelledby={id}
        maxWidth="xs"
        fullWidth
        className={`${classes.dialog}`}
      >
        {isSp ? (
          <Grid
            className={`${baseClasses.title2} ${classes.header}`}
          >
            {changeTitle || (
              <span>{showOnlyDivisions ? '組織選択' : '担当者選択'}</span>
            )}
            <Button
              className={baseClasses.closeButton}
              onClick={() => handleClose(true)}
            />
          </Grid>
        ) : (
          <DialogTitle
            className={`${classes.header}`}
            data-drag-id={id}
          >
            <Typography className={`${baseClasses.title3} ${classes.customTitle3}`}>
              {changeTitle || (
                <span>{label}</span>
              )}
            </Typography>
          </DialogTitle>
        )}
        <DialogContent className={classes.contentElement}>
          <Grid container className={classes.orgTitleClass}>
            {isAcquirer ? <AcquisitionType acquisitionType={initialAcquirer} setAcquisitionType={setInitialAcquirer} setFormValue={setFormValue} /> : ''}
            {isAcquirer && !USE_AQCUISITION_TREE_GROUP.includes(Number(initialAcquirer)) ? (
              // eslint-disable-next-line max-len
              <AcquisitionName
                selectUser={selectUser}
                setSelectUser={setSelectUser}
                setSelectDivision={setSelectDivision}
                setDisplayName={setDisplayName}
                setFormValue={setFormValue}
                defaultDisplayName={defaultDisplayName}
              />
            ) : (
              <UserTreeContainers
                selectUser={selectUser}
                setSelectUser={setSelectUser}
                selectDivision={selectDivision}
                setSelectDivision={setSelectDivision}
                treeHeaderSelect={treeHeaderSelect}
                showOnlyDivisions={showOnlyDivisions}
                clickableUserAndDivision={clickableUserAndDivision}
                setTargetDivisionTree={setTargetDivisionTree}
                setIsOpenTree={setIsOpenTree}
                isMulti={isMulti}
                isDialog={isDialog}
                isSelectUserWindow={isSelectUserWindow}
                isDuplicate={isDuplicate}
                isNotSearch={isNotSearch}
                isUserMaster={isUserMaster}
                doubleSearch={doubleSearch}
              />
            )}
          </Grid>
        </DialogContent>
        {isSp ? (
          <Grid className={`${baseClasses.footer} ${classes.footer}`}>
            <Grid item className={classes.btn}>
              <Button
                className={baseClasses.buttonsSecondary}
                onClick={() => handleClose(false)}
                type="reset"
              >
                選択解除
              </Button>
              <Button
                className={baseClasses.buttonsPrimary}
                onClick={onClickSelect}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        ) : (
          <DialogActions className={classes.footer}>
            <Grid container justifyContent="center" className={classes.btn}>
              <>
                <Button
                  className={baseClasses.buttonsSecondary}
                  onClick={() => handleClose(false)}
                >
                  選択解除
                </Button>
                <SaveButton
                  isAcquirer={isAcquirer}
                  selectUser={selectUser}
                  selectDivision={selectDivision}
                  showOnlyDivisions={showOnlyDivisions}
                  onClickSelect={onClickSelect}
                  isMulti={isMulti}
                  clickableUserAndDivision={clickableUserAndDivision}
                />
              </>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    );
  } else {
    return (
      <UserTreeContainers
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        treeHeaderSelect={treeHeaderSelect && [USERS_TREE]}
        showOnlyDivisions={showOnlyDivisions}
        setIsOpenTree={false}
        isMulti={isMulti}
        isSelectUserWindow={isSelectUserWindow}
        setTargetDivisionTree={setTargetDivisionTree}
        isNotSearch={isNotSearch}
        isUserMaster={isUserMaster}
        isDefaultLoginUser={isDefaultLoginUser}
        doubleSearch={doubleSearch}
        clickableUserAndDivision={clickableUserAndDivision}
      />
    );
  }
}

export default UserTree;
