import React from 'react';
import { useDispatch } from 'react-redux';
import AimSetting from '../../components/aimSetting/aimSetting';
import {
  setKimarisTarget,
  updateKimarisTarget as updateKimarisTargetState,
} from '../../store/kimarisTargetSettings/kimarisTargetSettingsGetListSlice';
import getKimarisTarget from '../../apis/kimarisTargetSettings/kimarisTargetSettingsGetListApi';
import createKimarisTarget from '../../apis/kimarisTargetSettings/kimarisTargetSettingsRegistApi';
import updateKimarisTarget from '../../apis/kimarisTargetSettings/kimarisTargetSettingsUpdateApi';
import updateAllKimarisTarget from '../../apis/kimarisTargetSettings/kimarisTargetSettingsAllUpdateApi';
import deleteKimarisTarget from '../../apis/kimarisTargetSettings/kimarisTargetSettingsDeleteApi';

import { MAN_YEN } from '../../constants';

function AimSettingContainer() {
  const dispatch = useDispatch();

  // 目標全件取得
  const getKimarisTargetList = async () => {
    await getKimarisTarget()
      .then((res) => {
        dispatch(setKimarisTarget(res.data));
      });
  };

  // 目標新規追加
  const createKimaris = (params, callback, callbackClose) => {
    const requestBody = { data: params };
    createKimarisTarget(requestBody)
      .then(() => {
        // Stateのinitial化
        callback();
        // モーダルClose
        callbackClose();
      })
      .catch((err) => console.log(err));
  };

  // 役割 or 目標売上の更新
  const updateKimaris = (targetId, params) => {
    const tmpParams = structuredClone(params);
    if (tmpParams.sales !== undefined) tmpParams.sales *= MAN_YEN;
    updateKimarisTarget(targetId, tmpParams)
      .then(() => dispatch(updateKimarisTargetState({ targetId, params: tmpParams })))
      .catch((err) => console.log(err));
  };

  // 目標の一括更新
  const updateAllKimaris = (params) => {
    updateAllKimarisTarget(params)
      .then(() => getKimarisTargetList())
      .catch((err) => console.log(err));
  };

  // 目標の削除
  const deleteKimaris = (targetId) => {
    deleteKimarisTarget(targetId)
      .then(() => getKimarisTargetList())
      .catch((err) => console.log(err));
  };

  return (
    <AimSetting
      getKimarisTargetList={getKimarisTargetList}
      createKimaris={createKimaris}
      updateKimaris={updateKimaris}
      updateAllKimaris={updateAllKimaris}
      deleteKimaris={deleteKimaris}
    />
  );
}

export default AimSettingContainer;
