import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const responseGetMediumSlice = createSlice({
  name: 'responseGetMedium',
  initialState: { responseGetMedium: initialState },
  reducers: {
    responseGetMediumApi: (state, data) => {
      state.responseGetMedium = data.payload;
    },
  },
});

export const { responseGetMediumApi } = responseGetMediumSlice.actions;

export default responseGetMediumSlice.reducer;
