import { isObject, isArray } from '../../../../commonFunction';

// eslint-disable-next-line max-len
const getTreeObjectByUniqueId = (obj, word, targetFlgList, uniqueId) => {
  const targetObj = {};
  let count = 0;
  // 再帰関数 -- 各要素を組織名・ユーザー名でsearchNameに引っかかるか検証
  const getObject = (theObject) => {
    let result = null;
    // 対象が配列なら、その子要素を再度getObject()で処理
    if (Array.isArray(theObject)) {
      for (let i = 0; i < theObject.length; i += 1) {
        result = getObject(theObject[i], word);
        if (result) {
          break;
        }
      }
    } else {
      for (const prop in theObject) {
        if (prop === 'uniqueId' && theObject.uniqueId === uniqueId) {
          const key = 'customer';
          if (!targetObj[key]) {
            targetObj[key] = [];
          }
          targetObj[key].push({
            uniqueId: theObject.uniqueId,
            type: 'unique',
            treeObj: theObject,
            children: theObject.children,
          });
          count += 1;
        }
        if (prop === 'children' && targetFlgList.customerFlg) {
          if (isObject(theObject[prop])) {
            for (const key in theObject[prop]) {
              getObject(theObject[prop][key]);
            }
          } else if (isArray(theObject[prop])) {
            for (const data of theObject[prop]) {
              getObject(data);
            }
          }
        }
      }
    }

    return result;
  };
  getObject(obj);
  if (Object.keys(targetObj).length > 0) {
    targetObj.count = count;
  }
  return targetObj;
};

export default getTreeObjectByUniqueId;
