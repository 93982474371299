import client from '../httpClient';

/**
 * 【divisionMaster011】課編成全基本情報取得
 */
const getAllSettingApi = async () => {
  return client.get('/division-masters/all-setting');
};

export default getAllSettingApi;
