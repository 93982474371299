import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import commonStyles from '../../styles';
import Dialog from '../modal';
import { useLibraryDirectoryTreeContext } from './context';
import LibraryDirectoryTreeList from './parts/libraryDirectoryTreeList';
// import LibraryDirectoryTreeSearch from './parts/libraryDirectoryTreeSearch';
// https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4776#comment-188532605

import { useLoading } from '../../../hooks';

import { classNames } from '../../../commonFunction';

import { LIBRARY_DIRECTORY_TREE, LIBRARY_WINDOW } from '../../../constants/loading';
import { useLibraryContext } from '../../mailLibrary/mailLibraryContext';
import { libraryPageSelector } from '../../../store/libraryPage';

const useStyles = makeStyles({
  wrapper: {
    background: '#fff',
    padding: '8px',
    border: '1px #c8c8c8 solid',
    width: '100%',
    maxHeight: 500,
  },
  line: {
    display: 'block',
    width: '100%',
    height: '1px',
    background: '#c8c8c8',
    margin: '8px 0',
  },
});

function BaseLibraryDirectoryTreeList() {
  const common = commonStyles();
  const classes = useStyles();
  const { hasLoading } = useLoading();
  const { /* isSearch, https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4776#comment-188532605 */ modalOption } = useLibraryDirectoryTreeContext();

  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4776#comment-188532605
  // const searchComponent = isSearch ? (
  //   <div>
  //     <LibraryDirectoryTreeSearch />
  //     <div className={classes.line} />
  //   </div>
  // ) : null;

  const rootClasses = hasLoading(LIBRARY_DIRECTORY_TREE)
    ? [classes.wrapper, common.loading]
    : [classes.wrapper];
  return modalOption ? (
    <Grid className={classNames(rootClasses)}>
      {/* {searchComponent} https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4776#comment-188532605 */}
      <LibraryDirectoryTreeList />
    </Grid>
  ) : (
    <>
      {/* {searchComponent} https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4776#comment-188532605 */}
      <LibraryDirectoryTreeList />
    </>
  );
}

function LibraryTreeModal() {
  const common = commonStyles();
  const { ownDir } = useLibraryContext();
  const {
    type,
    modalOption,
    setSelectDir,
    activeDir,
    setActiveDir,
    targetDir,
    choiceDir,
  } = useLibraryDirectoryTreeContext();
  const { open, onClose, title, message } = modalOption;
  const { hasLoading } = useLoading();

  const { tabType } = useSelector(libraryPageSelector);

  // 移動先ライブラリを選択できるかチェック
  // 選択中のタブによって制御が変わる
  const checkDisabled = () => {
    if (['move', 'copy'].includes(type)) {
      if (tabType === 'search') {
        return choiceDir.some(items => items?.libraryDirectoryId === activeDir?.libraryDirectoryId);
      }
      return targetDir?.libraryDirectoryId === activeDir?.libraryDirectoryId;
    }
    return false;
  };
  const disabled = checkDisabled(); // boolean

  const onCancel = () => {
    setSelectDir({});
    setActiveDir({});
    onClose();
  };

  const onOK = () => {
    setSelectDir(activeDir);
    onClose();
  };

  // モーダルを開いた際に自分のディレクトリをセット
  useEffect(() => {
    if (open) {
      // 同時だと開かない
      setTimeout(() => setActiveDir(ownDir), 10);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      maxWidth={640}
      noScroll
      buttons={[
        {
          label: '選択解除',
          className: common.buttonsSecondary,
          onClick: onCancel,
        },
        {
          label: '選択',
          className: common.buttonsPrimary,
          onClick: onOK,
          disabled,
        },
      ]}
      bodyClassName={hasLoading(LIBRARY_WINDOW) ? common.loading : ''}
    >
      <Grid container>
        <Typography className={common.bass}>{message}</Typography>
        <BaseLibraryDirectoryTreeList />
      </Grid>
    </Dialog>
  );
}

export default function LibraryDirectoryTree() {
  const { modalOption } = useLibraryDirectoryTreeContext();

  const modal = modalOption?.open ? <LibraryTreeModal /> : null;

  return modalOption ? modal : <BaseLibraryDirectoryTreeList />;
}
