import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';
import Calendar from '../../calendar';

import { getAge } from '../../../../commonFunction';
import { toNumber } from '../../../../commonFunction/format';

import { PERMANENT_RESIDENCY, GENDER_CODE } from '../../../../constants';

import {
  convertForm1,
  convertFormKana,
  convertFormNumber,
} from '../../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormInt1,
} from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  block: {
    padding: 8,
    background: '#fff',
    border: '1px solid #C8C8C8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:first-child': {
      marginRight: 8,
    },
  },
  blockBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blockLbl: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    lineHeight: '15.6px',
    width: '18%',
  },
  blockLbl01: {
    marginRight: 10,
  },
  blockLbl02: {
    fontWeight: 700,
    display: 'block',
    width: 40,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: 10,
    '& > div::before': {
      borderBottom: '2px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
    },
    '& > div': {
      margin: 0,
    },
  },
  blockKana: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    lineHeight: '20.8px',
  },
  blockKanaTxt: {
    display: 'block',
    width: 90,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    marginRight: 10,
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      textAlign: 'left',
    },
  },
  blockName: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0 0',
    lineHeight: '24px',
    '& > span': {
      fontFamily: 'Roboto',
      color: '#D83420',
      fontSize: 20,
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      marginRight: 20,
      position: 'absolute',
      left: '-25px',
      bottom: '5px',
    },
  },
  blockNameTxt: {
    display: 'block',
    width: 80,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    '&:last-child': {
      marginRight: 10,
    },
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 20,
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
    },
  },
  blockAge: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 8px 0 0',
    width: 54,
  },
  blockAgeNum: {
    display: 'block',
    margin: '0 0 0 4px',
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      color: '#333',
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  blockBirthday: {
    '& div': {
      border: 'none',
      padding: 0,
    },
    '& span': {
      padding: 0,
      fontWeight: 700,
    },
  },
  blockBirthdayBox: {
    display: 'flex',
    alignItems: 'center',
  },
  blockList: {
    display: 'flex',
    alignItems: 'center',
  },
  blockItem: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '15.6px',
    '& :not(:first-child)': {
      marginLeft: 4,
    },
  },
  blockItemSelect: {
    width: 52,
    '& .MuiSelect-select': {
      '&:focus': {
        background: 'none',
      },
    },
  },
  blockItemVal: {
    display: 'block',
    margin: '0 0 0 4px',
    width: 17,
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      color: '#333',
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  blockNationalityTxt: {
    display: 'block',
    width: 20,
    margin: '-5px 8px 0 0',
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -8px) scale(0.6)',
      },
    },
  },
}));

/**
 * API情報の生年月日についてる時分秒を削除する
 * @param {*} input 年月日時分秒
 * @returns 年月日、またはnull
 */
const formatBirthday = (input) => {
  if (typeof input === 'string') {
    return input.replaceAll(' 00:00:00', '');
  }
  return null;
};

export default function ResponseRegisterName(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    user,
    detail,
    setDetail,
    getComponent,
    searchCustomerByKeyword,
    errorCallback,
  } = props;

  const initial = detail;

  const ageInputRef = useRef(null);
  const subAgeInputRef = useRef(null);

  const onBlur = (e) => {
    const label = e.target.name;
    if (e.target.value !== detail[label]) {
      initial[label] = e.target.value;
      setDetail({ ...detail });
      console.log(detail[label]);
    }
    let labelArray = [];
    switch (label) {
      case 'lastNameKana':
      case 'firstNameKana':
        labelArray = ['lastNameKana', 'firstNameKana'];
        break;
      case 'lastName':
      case 'firstName':
        labelArray = ['lastName', 'firstName'];
        break;
      case 'subLastNameKana':
      case 'subFirstNameKana':
        labelArray = ['subLastNameKana', 'subFirstNameKana'];
        break;
      case 'subLastName':
      case 'subFirstName':
        labelArray = ['subLastName', 'subFirstName'];
        break;
      case 'age':
        ageInputRef.current.value = convertFormNumber(e.target.value);
        break;
      case 'subAge':
        subAgeInputRef.current.value = convertFormNumber(e.target.value);
        break;
      default:
        break;
    }
    if (labelArray.length !== 0) searchCustomerByKeyword(detail, labelArray);
  };

  const onSelectChange = (val, { name }) => {
    if (val !== '') {
      val = toNumber(val);
    } else {
      val = null;
    }
    if (val !== detail[name]) {
      detail[name] = val;
      setDetail({ ...detail });
    }
  };

  // 永住権
  const kokyakuSelectList = {
    select: {
      type: 'selectBlank',
      obj: {
        menus: PERMANENT_RESIDENCY,
      },
      state: initial.isPermanentResidency,
      name: 'isPermanentResidency',
      onChange: onSelectChange,
    },
  };

  // 性別
  const genderSelectList = {
    select: {
      type: 'selectPlaceholder',
      obj: {
        menus: GENDER_CODE,
      },
      state: initial.genderCode,
      name: 'genderCode',
      onChange: onSelectChange,
      placeholder: '-',
    },
  };

  // 生年月日
  const [birthday, setBirthday] = useState(
    formatBirthday(user.birthday),
  );
  const birthdaySetState = (data) => {
    setBirthday(data);
    initial.birthday = data;
    if (data && !initial.age) {
      const tmpAget = getAge(data);
      initial.age = tmpAget;
      ageInputRef.current.value = tmpAget;
    }
    setDetail({ ...detail });
    console.log(detail.birthday);
  };
  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: birthday, // state
      setState: birthdaySetState,
      placeholder: '生年月日',
    },
  ];
  const [subBirthday, setSubBirthday] = useState(
    formatBirthday(user.subBirthday),
  );
  const subBirthdaySetState = (data) => {
    setSubBirthday(data);
    initial.subBirthday = data;
    if (data && !initial.subAge) {
      const tmpAget = getAge(data);
      initial.subAge = tmpAget;
      subAgeInputRef.current.value = tmpAget;
    }
    setDetail({ ...detail });
    console.log(detail.subBirthday);
  };
  const datalistsub = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: subBirthday, // state
      setState: subBirthdaySetState,
      placeholder: '生年月日',
    },
  ];

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>氏名</Typography>
      <Grid className={classes.box}>
        <Grid className={classes.block}>
          <Grid className={classes.blockLbl}>
            <Typography
              className={`${baseClasses.title6} ${classes.blockLbl01}`}
            >
              主
            </Typography>
            <Typography
              className={`${baseClasses.title6} ${classes.blockLbl02}`}
            >
              本人
            </Typography>
          </Grid>
          <Grid className={classes.blockInfo}>
            <Grid className={classes.blockBox}>
              <Grid className={classes.blockKana}>
                <TextBaseField
                  className={classes.blockKanaTxt}
                  defaultValue={user.lastNameKana}
                  name="lastNameKana"
                  onBlur={onBlur}
                  placeholder="セイ"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertFormKana}
                  errorCallback={errorCallback('lastNameKana')}
                />
                <TextBaseField
                  className={classes.blockKanaTxt}
                  defaultValue={user.firstNameKana}
                  name="firstNameKana"
                  onBlur={onBlur}
                  placeholder="メイ"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertFormKana}
                  errorCallback={errorCallback('firstNameKana')}
                />
              </Grid>
              <Grid className={classes.blockList}>
                <Grid className={classes.blockItem}>
                  <Typography
                    className={`${baseClasses.title6} ${classes.lbl}`}
                  >
                    国籍
                  </Typography>
                  <TextBaseField
                    className={classes.blockNationalityTxt}
                    defaultValue={user.nationality}
                    name="nationality"
                    onBlur={onBlur}
                    placeholder="-"
                    validator={validateFormString1({ maxLengthInt: 20 })}
                    convertor={convertForm1}
                    errorCallback={errorCallback('nationality')}
                  />
                </Grid>
                <Grid className={classes.blockItem}>
                  <Typography
                    className={`${baseClasses.title6} ${classes.lbl}`}
                  >
                    永住権
                  </Typography>
                  <Grid className={classes.blockItemSelect}>
                    {getComponent(kokyakuSelectList.select)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.blockBox}>
              <Grid className={classes.blockName}>
                <span>＊</span>
                <TextBaseField
                  className={classes.blockNameTxt}
                  defaultValue={user.lastName}
                  name="lastName"
                  onBlur={onBlur}
                  placeholder="姓"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertForm1}
                  errorCallback={errorCallback('lastName')}
                />
                <TextBaseField
                  className={classes.blockNameTxt}
                  defaultValue={user.firstName}
                  name="firstName"
                  onBlur={onBlur}
                  placeholder="名"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertForm1}
                  errorCallback={errorCallback('firstName')}
                />
              </Grid>
              <Grid className={classes.blockItem}>
                <Grid className={classes.blockItemSelect}>
                  {getComponent(genderSelectList.select)}
                </Grid>
              </Grid>
              <Grid className={classes.blockAge}>
                <TextBaseField
                  type="number"
                  className={classes.blockAgeNum}
                  defaultValue={user.age}
                  name="age"
                  inputRef={ageInputRef}
                  onBlur={onBlur}
                  placeholder="-"
                  convertor={convertFormNumber}
                  validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999.0 })}
                  errorCallback={errorCallback('age')}
                />
                <Typography className={baseClasses.title4}>才</Typography>
              </Grid>
              <Grid className={classes.blockBirthday}>
                {datalist.map((array) => {
                  return (
                    <Grid key={array.id} className={classes.blockBirthdayBox}>
                      <Typography className={baseClasses.title4}>(</Typography>
                      <Calendar data={array} />
                      <Typography className={baseClasses.title4}>)</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.block}>
          <Grid className={classes.blockLbl}>
            <Typography
              className={`${baseClasses.title6} ${classes.blockLbl01}`}
            >
              副
            </Typography>
            <TextBaseField
              className={classes.blockLbl02}
              defaultValue={user.relation}
              name="relation"
              onBlur={onBlur}
              placeholder="関係"
              validator={validateFormString1({ maxLengthInt: 50 })}
              convertor={convertForm1}
              errorCallback={errorCallback('relation')}
            />
          </Grid>
          <Grid className={classes.blockInfo}>
            <Grid className={classes.blockBox}>
              <Grid className={classes.blockKana}>
                <TextBaseField
                  className={classes.blockKanaTxt}
                  defaultValue={user.subLastNameKana}
                  name="subLastNameKana"
                  onBlur={onBlur}
                  placeholder="セイ"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertFormKana}
                  errorCallback={errorCallback('subLastNameKana')}
                />
                <TextBaseField
                  className={classes.blockKanaTxt}
                  defaultValue={user.subFirstNameKana}
                  name="subFirstNameKana"
                  onBlur={onBlur}
                  placeholder="メイ"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertFormKana}
                  errorCallback={errorCallback('subFirstNameKana')}
                />
              </Grid>
              <Grid className={classes.blockList}>
                <Grid className={classes.blockItem}>
                  <Typography
                    className={`${baseClasses.title6} ${classes.lbl}`}
                  >
                    国籍
                  </Typography>
                  <TextBaseField
                    className={classes.blockNationalityTxt}
                    defaultValue={user.subNationality}
                    name="subNationality"
                    onBlur={onBlur}
                    placeholder="-"
                    validator={validateFormString1({ maxLengthInt: 20 })}
                    convertor={convertForm1}
                    errorCallback={errorCallback('subNationality')}
                  />
                </Grid>
                <Grid className={classes.blockItem}>
                  <Typography
                    className={`${baseClasses.title6} ${classes.lbl}`}
                  >
                    永住権
                  </Typography>
                  <Grid className={classes.blockItemSelect}>
                    {getComponent({
                      ...kokyakuSelectList.select,
                      state: initial.isSubPermanentResidency,
                      name: 'isSubPermanentResidency',
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.blockBox}>
              <Grid className={classes.blockName}>
                <TextBaseField
                  className={classes.blockNameTxt}
                  defaultValue={user.subLastName}
                  name="subLastName"
                  onBlur={onBlur}
                  placeholder="姓"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertForm1}
                  errorCallback={errorCallback('subLastName')}
                />
                <TextBaseField
                  className={classes.blockNameTxt}
                  defaultValue={user.subFirstName}
                  name="subFirstName"
                  onBlur={onBlur}
                  placeholder="名"
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertForm1}
                  errorCallback={errorCallback('subFirstName')}
                />
              </Grid>
              <Grid className={classes.blockItem}>
                <Grid className={classes.blockItemSelect}>
                  {getComponent({
                    ...genderSelectList.select,
                    name: 'subGenderCode',
                    state: initial.subGenderCode,
                  })}
                </Grid>
              </Grid>
              <Grid className={classes.blockAge}>
                <TextBaseField
                  type="number"
                  className={classes.blockAgeNum}
                  defaultValue={user.subAge}
                  name="subAge"
                  inputRef={subAgeInputRef}
                  onBlur={onBlur}
                  placeholder="-"
                  convertor={convertFormNumber}
                  validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999.0 })}
                  errorCallback={errorCallback('subAge')}
                />
                <Typography className={baseClasses.title4}>才</Typography>
              </Grid>
              <Grid className={classes.blockBirthday}>
                {datalistsub.map((array) => {
                  return (
                    <Grid key={array.id} className={classes.blockBirthdayBox}>
                      <Typography className={baseClasses.title4}>(</Typography>
                      <Calendar data={array} />
                      <Typography className={baseClasses.title4}>)</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
