import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import ResizableWrapper from '../eleCommon/resizableWrapper';
import MailLibraryContainer from '../../containers/mailLibrary/mailLibraryContainer';
import MailLibrarySidebarContainer from '../../containers/mailLibrary/mailLibrarySidebarContainer';
import librarydirGetDirectlyTreeApi from '../../apis/librarydir/librarydirGetDirectlyTreeApi';
import { getlibrarydirDirectlyTreeApi } from '../../store/librarydir/librarydirGetDirectlyTreeSlice';
import { getLocalStorage, setLocalStorage } from '../../commonFunction';
import { LIMIT_INSTRUCTION_LIST } from '../../constants/localStorage';
import { useLoading } from '../../hooks';
import { LIBRARY_WINDOW } from '../../constants/loading';
import commonStyles from '../styles';

export default function MailLibrary(props) {
  const { dblclick } = props;
  const dispatch = useDispatch();
  const common = commonStyles();
  const { hasLoading } = useLoading();
  // ビューモード / tree or search
  const [viewMode, setViewMode] = useState('tree');

  const [selectDir, setSelectDir] = useState({});
  const [search, setSearch] = useState({
    selectDir: {},
    libraryDirectoryId: 0, // ライブラリディレクトリID
    libraryDirectoryName: '', // ライブラリディレクトリ名
    libraryName: '', // ライブラリ名
    mailSubject: '', // 件名
    libraryMemo: '', // ライブラリメモ
    updatedBy: 0, // 更新者ID
    updatedByName: '', // 更新者名 - ディビジョン名 ユーザー名
    isWithin7Days: 0, // 更新7日以内のライブラリに絞る
    limit: getLocalStorage(LIMIT_INSTRUCTION_LIST, 20), // 表示数
    offset: 0, // オフセット
    orderBy: 'updateDate',
    order: 'desc',
  });
  const setSearchWrapped = (s) => {
    if (typeof s === 'function') {
      setSearch((state) => {
        const newState = s(state);
        if (newState.limit) setLocalStorage(LIMIT_INSTRUCTION_LIST, newState.limit);
        return newState;
      });
    } else {
      // object
      if (s.limit) setLocalStorage(LIMIT_INSTRUCTION_LIST, s.limit);
      setSearch(s);
    }
  };

  // 設定されていないパラメータ削除
  const deleteDefaultSearchParam = (param) => {
    if (!param.libraryDirectoryId) {
      delete param.libraryDirectoryId;
    }
    if (!param.libraryDirectoryName) {
      delete param.libraryDirectoryName;
    }
    if (!param.libraryName) {
      delete param.libraryName;
    }
    if (!param.mailSubject) {
      delete param.mailSubject;
    }
    if (!param.libraryMemo) {
      delete param.libraryMemo;
    }
    if (!param.updatedBy) {
      delete param.updatedBy;
    }
    if (!param.updatedByName) {
      delete param.updatedByName;
    }
    if (!param.isWithin7Days) {
      delete param.isWithin7Days;
    }
    return param;
  };

  // tree GET librarydir001 グローバルステートに格納
  const getTreeList = async () => {
    await librarydirGetDirectlyTreeApi()
      .then((res) => {
        dispatch(getlibrarydirDirectlyTreeApi(res.data));
        if (!selectDir?.libraryDirectoryId) return;
        // ツリーの情報取得時に最新の選択しているディレクトリ情報を格納
        const tmpArray = [];
        const expandDirectory = (dir) => {
          tmpArray.push([dir.libraryDirectoryId, dir]);
          if (!Array.isArray(dir.children) || dir.children.length === 0) return;
          dir.children.forEach((childDir) => expandDirectory(childDir));
        };
        res.data.libraryDirectories.forEach((dir) => expandDirectory(dir));
        const dirMap = new Map(tmpArray);
        const newDir = dirMap.get(selectDir.libraryDirectoryId);
        if (newDir) setSelectDir(newDir);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Grid className={hasLoading(LIBRARY_WINDOW) ? common.loading : ''}>
      <ResizableWrapper
        defaultSize={{ width: 410 }}
        resize={
          <MailLibrarySidebarContainer
            getTreeList={getTreeList}
            setViewMode={setViewMode}
            selectDir={selectDir}
            setSelectDir={setSelectDir}
            deleteDefaultSearchParam={deleteDefaultSearchParam}
          />
        }
      >
        <MailLibraryContainer
          getTreeList={getTreeList}
          dblclick={dblclick}
          viewMode={viewMode}
          selectDir={selectDir}
          search={search}
          setSearch={setSearchWrapped}
          deleteDefaultSearchParam={deleteDefaultSearchParam}
        />
      </ResizableWrapper>
    </Grid>
  );
}
