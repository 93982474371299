import { Button, Checkbox, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import DriveFileRenameIcon from '../../icons/fileRename';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: '#fff',
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  cell: {
    padding: '0 8px',
    '&:not(:last-child)': { borderRight: '1px #c8c8c8 solid' },
  },
  member: {
    color: theme.palette.primaryColor,
    fill: theme.palette.primaryColor,
    width: '16px',
  },
  edit: {
    '&:hover': { background: '#fff' },
  },
  checkbox: { padding: 0 },
}));

export default function LibraryRow(props) {
  const { dblclick, row, cells, choice, setChoice, choiceDir, setChoiceDir, clickedit } = props;
  const classes = useStyles();
  const common = commonStyles();

  // チェックボックス変更時
  const handleChange = (e) => {
    if (choice.includes(row.libraryId)) {
      setChoice([...choice.filter(item => item !== e)]);
      // setSelected([...selected.filter(item => item !== mail)]);
    } else {
      setChoice([...choice, e]);
      // setSelected([...selected, mail]);
    }
    if (choiceDir.some(items => items?.libraryId === row.libraryId)) {
      setChoiceDir([...choiceDir.filter(items => items?.libraryId !== e)]);
    } else {
      setChoiceDir([
        ...choiceDir,
        { libraryId: row.libraryId, libraryDirectoryId: row.libraryDirectoryId },
      ]);
    }
  };

  // rowダブルクリック
  const handleDoubleClick = () => {
    if (dblclick) {
      // mailから開かれた場合
      return dblclick(row);
    } else {
      return clickedit(row);
    }
  };

  return (
    <TableRow
      className={classes.row}
      onDoubleClick={handleDoubleClick}
    >
      <TableCell className={classes.cell}>
        <Checkbox
          className={classes.checkbox}
          checked={choice.includes(row.libraryId)}
          onDoubleClick={(e) => { e.stopPropagation(); }}
          onChange={() => { return handleChange(row.libraryId); }}
        />
      </TableCell>
      {cells.map((c) => {
        return (
          <TableCell key={c.id} className={classes.cell}>
            <Typography className={common.small}>
              { c.id === 'libraryId' ? (
                <Button
                  onClick={() => { return clickedit(row); }}
                  onDoubleClick={(e) => { e.stopPropagation(); }}
                  className={classes.edit}
                >
                  <DriveFileRenameIcon className={classes.member} />
                </Button>) : (
                row[c.id]
              )}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}
