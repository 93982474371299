import { useSelector } from 'react-redux';
import LibraryTreeIndexSp from './parts/sp/libraryTreeIndexSp';

export default function LibraryTreeWrapper(props) {
  const {
    functions,
    selectDir,
    setSelectDir,
    selectLibrary,
    limitOffsetRef,
    onClose,
    isPreview,
  } = props;
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return isSp ? (
    <LibraryTreeIndexSp
      selectDir={selectDir}
      setSelectDir={setSelectDir}
      selectLibrary={selectLibrary}
      onClose={onClose}
      functions={functions}
      isPreview={isPreview}
      limitOffsetRef={limitOffsetRef}
    />
  ) : (
    // TODO: mailLibraryコンポーネント(PC)の記述がFIX次第、マージ
    <p>PC</p>
  );
}
