import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesDeleteApiSlice = createSlice({
  name: 'schedulesDeleteApi',
  initialState: { schedulesDeleteApi: initialState },
  reducers: {
    setSchedulesDeleteApi: (state, data) => {
      state.schedulesDeleteApi = data.payload;
    },
  },
});

export const { setSchedulesDeleteApi } = schedulesDeleteApiSlice.actions;

export default schedulesDeleteApiSlice.reducer;
