// import { useState } from 'react';
import { Drawer, Grid, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';
import SmsDrawerList from './smsDrawerListSp';
import SmsDrawerNameChangeList from './smsDrawerNameChangeList';

// const useStyles = makeStyles({

// });

export default function SmsDrawer(props) {
  const {
    type,
    openDrawer,
    setOpenDrawer,
    setOpenDrawer2,
    setHandleOpenEditWindow,
    values,
    updateFunction,
  } = props;

  const common = commonStyles();

  switch (type) {
    case 'edit':
      return (
        <Drawer
          anchor="bottom"
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(!openDrawer);
          }}
          className={common.drawer}
        >
          <Grid className={common.drawerHeaderSp}>
            <Typography
              className={common.title3}
            >
              お客様名
            </Typography>
          </Grid>
          <Grid>
            <SmsDrawerNameChangeList
              values={values}
              setOpenDrawer={setOpenDrawer}
              setHandleOpenEditWindow={setHandleOpenEditWindow}
              updateFunction={updateFunction}
            />
          </Grid>
        </Drawer>
      );
    default:
      return (
        <Drawer
          anchor="bottom"
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(!openDrawer);
          }}
          className={common.drawer}
        >
          <SmsDrawerList
            values={values}
            setOpenDrawer={setOpenDrawer}
            setOpenDrawer2={setOpenDrawer2}
            setHandleOpenEditWindow={setHandleOpenEditWindow}
          />
        </Drawer>
      );
  }
}
