import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesSubmissionSlice = createSlice({
  name: 'schedulesSubmission',
  initialState: { schedulesSubmission: initialState },
  reducers: {
    setSchedulesSubmissionApi: (state, data) => {
      state.schedulesSubmission = data.payload;
    },
  },
});

export const { setSchedulesSubmissionApi } = schedulesSubmissionSlice.actions;

export default schedulesSubmissionSlice.reducer;
