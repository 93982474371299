import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../../../styles';
import ManagementLine from '../../../common/managementLine';
import MailSelectDrawer from '../../../mail/parts/sp/mailSelectDrawer';
import SmsWindow from '../../../sms';
import TelWindow from './tel';
import { useStore } from '../../../../hooks';

const useStyles = makeStyles({
  list: {
    padding: 0,
  },
  item: {
    padding: 0,
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
});

// TODO(sato):スタイル微調整
export default function CustomerButtonIconSp(props) {
  const { customer, onClose } = props;
  // 電話
  const [telOpen, setTelOpen] = useState(false);
  // メール
  const [mailOpen, setMailOpen] = useState(false);
  // モーダル開閉用のローカルステート(SMS)
  const [smsOpen, setSmsOpen] = useState(false);
  // モーダル開閉用のローカルステート(LINE)
  const [lineOpen, setLineOpen] = useState(false);
  const { responseHeader } = useStore();
  const { roleIds } = responseHeader;
  const classes = useStyles();
  const common = commonStyles();

  const onCloseMail = () => {
    onClose();
    setMailOpen(false);
  };

  const getContent = (index) => {
    const returnType = [];
    switch (index) {
      case 1:
        return (
          <TelWindow
            open={telOpen}
            onClose={() => { setTelOpen(false); }}
            customer={customer}
          />
        );
      case 2:
        return (
          <MailSelectDrawer
            open={mailOpen}
            setOpen={setMailOpen}
            onClose={onCloseMail}
            user={customer}
          />
        );
      case 3:
        return (
          <SmsWindow
            open={smsOpen}
            onClose={() => { setSmsOpen(false); }}
            customer={customer}
          />
        );
      case 4:
        return (
          <ManagementLine
            open={lineOpen}
            setLineOpen={setLineOpen}
            onClose={() => { setLineOpen(false); }}
            customerId={customer.customerId}
          />
        );
      default:
        break;
    }
    return returnType;
  };
  const menu = [
    {
      ttl: '電話',
      startIcon: <CallIcon />,
      onClick: () => { return setTelOpen(true); },
      content: getContent(1),
      disabled: false,
    },
    {
      /* 支援権限のユーザーが、メールステータス個別のみの顧客を閲覧する場合 */
      /* 顧客メインのメール作成ボタンが失活 */
      ttl: 'メール',
      startIcon: <MailOutlineIcon />,
      onClick: () => { return setMailOpen(true); },
      content: getContent(2),
      // 「role=1(代理送信権限) と、29(全顧客編集権限) か9(全顧客管理)を持つなら、メールステータスが代行OKでない限り失活」
      // 「isMailSend1、isMailSend2、isMailSend3、isMailSend4が全て0」
      disabled: (roleIds.includes(1)
      && (roleIds.includes(29) || roleIds.includes(9))
      && customer.emailStatusCode !== 5)
        || (!customer.isMailSend1
          && !customer.isMailSend2
          && !customer.isMailSend3
          && !customer.isMailSend4),
    },
    {
      ttl: 'SMS',
      startIcon: <Icon>aod</Icon>,
      onClick: () => { return setSmsOpen(true); },
      content: getContent(3),
      disabled: false,
    },
    {
      ttl: 'LINE',
      startIcon: <ChatBubbleOutlineIcon />,
      onClick: () => { return setLineOpen(true); },
      content: getContent(4),
      disabled: false,
    },
  ];

  return (
    <List className={classes.list}>
      {menu.map((data) => (
        <ListItem
          key={data.ttl}
          className={`${common.title4} ${classes.item}`}
        >
          <Button
            className={classes.btn}
            startIcon={data.startIcon}
            onClick={data.onClick}
            disabled={data.disabled}
          >
            {data.ttl}
          </Button>
          {data.content}
        </ListItem>
      ))}
    </List>
  );
}
