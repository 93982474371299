import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import UnsentListTableRow from './unsentListTableRow';
import commonStyles from '../../styles';
// import UnsentMenuPopper from './unsentMenuPopper';
// import UnsentDialogConfirmForPC from './unsentDialogConfirmForPC';
// import UnsentAlertCompleteForPC from './unsentAlertCompleteForPC';
// import ChangeScheduledTimeToSend from './changeScheduledTimeToSend';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: '8px',
  },
  icon: {
    '& .MuiTableSortLabel-icon': { fontSize: '12px' },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':
      {
        color: theme.palette.primaryColor,
      },
  },
  contentText: {
    textAlign: 'center',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { cells, order, orderBy, onRequestSort } = props;
  const common = commonStyles();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {cells.map((c) => (
          <TableCell
            key={c.id}
            style={c.style}
            className={classes.cell}
            sortDirection={orderBy === c.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={createSortHandler(c.id)}
              className={classes.icon}
            >
              <Typography className={common.title4}>{c.label}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function UnsentListTable(props) {
  const {
    list, // 個別・一斉毎の実際のデータ typeof == Array
    single, // 個別の場合True
    // page, // 何ページ目か
    // rowsPerPage, // 表示件数
    functions, // UpdateAPIの関数など
    tabValue, // タブの値
    options,
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('deliveryAt');

  const [mailList, setMailList] = useState([]);
  const singleHeader = [
    { id: 'deliveryAt', label: '送信予定日時', style: { minWidth: '110px' } },
    // { id: 'deliveryAt', label: '送信予定日時', style: { minWidth: '110px' } },
    {
      id: 'mailStatusCode',
      label: '送信ステータス',
      style: { minWidth: '120px' },
    },
    { id: 'divisionSubName', label: '担当課', style: { minWidth: '120px' } },
    { id: 'fromName', label: '送信者', style: { minWidth: '100px' } },
    { id: 'lastName', label: '宛先', style: { minWidth: '100px' } },
    {
      id: 'mailSubject',
      label: 'メールタイトル',
      style: { minWidth: '500px' },
    },
  ];
  const allHeader = [
    { id: 'deliveryAt', label: '送信予定日時', style: { minWidth: '110px' } },
    // { id: 'deliveryAt', label: '送信予定日時', style: { minWidth: '110px' } },
    {
      id: 'mailStatusCode',
      label: '送信ステータス',
      style: { minWidth: '120px' },
    },
    { id: 'divisionSubName', label: '担当課', style: { minWidth: '120px' } },
    { id: 'fromName', label: '送信者', style: { minWidth: '100px' } },
    { id: 'reserveCount', label: '送信件数', style: { minWidth: '80px' } },
    {
      id: 'mailSubject',
      label: 'メールタイトル',
      style: { minWidth: '500px' },
    },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    console.log({ order, orderBy, isAsc });
  };

  useEffect(() => {
    // 更新する際は下記のsendTimeから取得→更新
    setMailList(list);
  }, [list]);

  return (
    <TableContainer>
      <Table>
        <EnhancedTableHead
          cells={single ? singleHeader : allHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {list.length === 0 ? (
          <div>
            <Typography style={{ padding: '3em 2em' }}>
              対象が存在しません。
            </Typography>
          </div>
        ) : (
          <TableBody>
            {stableSort(mailList, getComparator(order, orderBy)).map((row) => {
              return (
                <UnsentListTableRow
                  row={row}
                  cells={single ? singleHeader : allHeader}
                  key={row.deliveryNumber}
                  tabValue={tabValue}
                  functions={functions}
                  options={options}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
