import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import commonStyles from '../styles';
import PremiumIcon from '../icons/premiumIcon';
import judgeMemberStatus from '../../commonFunction/judgeMemberStatus';
import { classNames } from '../../commonFunction';

const useStyles = makeStyles((theme) => ({
  status: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      width: 20,
      height: 20,
      marginRight: 4,
    },
  },
  icon: {
    color: theme.palette.primaryColor,
  },
  hideNonMemberIcon: {
    '& svg': {
      display: 'none',
    },
  },
  textOnly: {
    '& svg': {
      display: 'none',
    },
  },
  thin: {
    '& > p': {
      fontWeight: 'normal',
    },
  },
}));

/**
 * 会員ステータス
 * @param {{
 * code: 0 | 1 | 2 | 3 | null;
 * hideNonMemberIcon?: boolean;
 * textOnly?: boolean;
 * thin?: boolean;
 * }} props
 * @returns ReactNode
 */
export default function MemberStatusCode(props) {
  const {
    code = null,
    hideNonMemberIcon, // 非会員の場合、アイコンを非表示する
    textOnly, // テキストのみを表示する
    thin = false, // テキストは太字にしない
    className,
    style,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid
      className={classNames(
        classes.status,
        {
          [classes.hideNonMemberIcon]: code === null && hideNonMemberIcon,
          [classes.textOnly]: textOnly,
          [classes.thin]: thin,
        },
        className,
      )}
      style={style}
    >
      {code ? <PremiumIcon /> : (
        code !== null && <LanguageIcon className={classes.icon} />
      )}
      <Typography className={baseClasses.title4}>
        {judgeMemberStatus(code)}
      </Typography>
    </Grid>
  );
}
