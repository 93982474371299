import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';
import { updateSearchItems } from '../../../../store/search/searchItemsSlice';

const useStyles = makeStyles({
  button: {
    padding: '4px 10px',
    minWidth: 10,
    '&>span': {
      fontSize: 13,
    },
  },
});

function SubmitButton(props) {
  const { txt, searchConditions } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const dispatch = useDispatch();

  const handleClick = async () => {
    // グローバルステート(簡易検索条件)の更新
    await dispatch(updateSearchItems(searchConditions));
  };

  return (
    <Button
      className={`${classes.button} ${baseClasses.buttonsPrimary}`}
      type="submit"
      onClick={() => {
        handleClick();
      }}
    >
      {txt}
    </Button>
  );
}
export default SubmitButton;
