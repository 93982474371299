import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 5px 0 0',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginRight: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
}));

function CheckBoxLabel(props) {
  const { label, initial, areaId, all, count, setCount, clickCheck } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState(initial);

  const handleChange = (e) => {
    const checked1 = e.target.checked;
    setChecked(checked1);
    clickCheck(areaId);
    if (checked1) {
      setCount(count + 1);
    } else {
      setCount(count - 1);
    }
  };

  const allCheckLift = () => {
    switch (all) {
      case 1:
        setChecked(true);
        break;
      case 2:
        setChecked(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    allCheckLift();
  });

  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      }
      label={label}
    />
  );
}

export default CheckBoxLabel;
