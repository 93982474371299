import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Icons
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import PersonSearchIcon from '../icons/personSearchIcon';
import { URL_MAPPER } from '../../constants/urls';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  tabs: {
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      color: '#333',
      fontSize: 10,
      lineHeight: 1.6,
      letterSpacing: 0,
      opacity: '1',
      flex: 1,
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
      '& svg': {
        fill: theme.palette.primaryColor,
      },
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
      top: '0',
      bottom: 'auto',
    },
  },
  tabPanel: {
    height: '100%',
    '& > div': {
      height: '100%',
    },
    '& > div > div': {
      height: '100%',
    },
    '& > div > div > div': {
      height: '100%',
    },
  },
  tabButton: {
    minHeight: 'auto',
    '& .MuiSvgIcon-root': {
      fill: '#8C8C8C',
    },
    '& .MuiTab-wrapper > *:first-child': {
      marginBottom: '2px',
    },
  },
  icon: {
    fill: '#8C8C8C',
  },
  appBar: {
    top: 'auto',
    bottom: '0',
  },
}));

export default function MainFootMenuTabs() {
  const classes = useStyles();

  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  // タブ（下部）メニュー options
  const footMenuTabsList = [
    { title: 'ホーム', icon: DashboardOutlinedIcon, href: URL_MAPPER.sp },
    { title: 'お客様情報', icon: PersonSearchIcon, href: URL_MAPPER.customer },
    { title: '行動', icon: DateRangeOutlinedIcon, href: URL_MAPPER.schedule },
    { title: '指示', icon: TouchAppOutlinedIcon, href: URL_MAPPER.direct },
  ];

  // 現在のLocation取得
  const location = useLocation();
  useEffect(() => {
    const firstLocate = location?.pathname;
    const firstIndex = footMenuTabsList.findIndex(path => path.href === firstLocate);
    setValue(firstIndex);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(footMenuTabsList[newValue].href);
  };
  return (
    <div className={`${classes.root} allFooter`}>
      <AppBar position="fixed" className={classes.appBar}>
        <Tabs value={value} onChange={handleChange} className={classes.tabs}>
          {footMenuTabsList.map((data, index) => (
            <Tab
              className={classes.tabButton}
              key={data.title}
              label={data.title}
              icon={<data.icon classnameprops={classes.icon} />}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
