import CustomerShareUpdateContainer from '../../../../containers/common/customer/shareupdate/customerShareupdateContainer';

function CustomerShareUpdate(props) {
  const { customerId, data, functions, shareAddDisabledFlg } = props;

  const dataList = data.shares;

  return (
    <CustomerShareUpdateContainer
      customerId={customerId}
      customerList={dataList}
      functions={functions}
      shareAddDisabledFlg={shareAddDisabledFlg}
    />
  );
}

export default CustomerShareUpdate;
