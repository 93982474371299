import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import CustomTable from '../../../browsingHistory/parts/customTable';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 8,
  },
  tr: {
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  td: {
    position: 'relative',
    padding: '4px 6px',
    border: 'none',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    textAlign: 'left',
    letterSpacing: 0,
    '&:not(:last-child)::after': {
      content: '""',
      width: 1,
      height: '80%',
      background: '#C8C8C8',
      position: 'absolute',
      top: '10%',
      right: 0,
    },
  },
  button: {
    padding: '0 4px',
    minWidth: 'auto',
    '& .MuiSvgIcon-root': {
      color: '#D83420',
      fontSize: 16,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
}));

const headCells = [
  { id: 'inquiredAt', label: '問合せ日' },
  { id: 'productName', label: '物件名' },
  { id: 'siteNumber', label: '物件番号' },
  { id: 'sample1', label: '駅名' },
  { id: 'sample2', label: '価格（万円）' },
  { id: 'delete', label: '' },
];

export default function ResponseRegisterTable1(props) {
  const {
    rows,
    onRemove,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomTable
        headCells={headCells}
        list={rows}
        hidePaging
        divider
        gray
        sortable
        empty="該当情報は存在しません"
        rowRender={(list) => list.map((row, i) => (
          <TableRow
            hover
            tabIndex={-1}
            key={String(i)}
            className={classes.tr}
          >
            <TableCell className={classes.td}>
              {row.inquiredAt && moment(row.inquiredAt, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD')}
            </TableCell>
            <TableCell className={classes.td}>{row.productName}</TableCell>
            <TableCell className={classes.td}>{row.siteNumber}</TableCell>
            <TableCell className={classes.td}>{row.stationName || ''}</TableCell>
            <TableCell className={classes.td}>{row.price || ''}</TableCell>
            <TableCell className={classes.td}>
              <Button
                className={classes.button}
                onClick={() => onRemove(row)}
              >
                <DeleteOutlineIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      />
    </div>
  );
}
