import { useMemo } from 'react';
import { Typography, Button, Link, makeStyles } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import RoomIcon from '@material-ui/icons/Room';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { base64ToBlob, createBlobURL } from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 6,
    right: 4,
    opacity: 0,
    transition: 'all 0.5s ease',
  },
  buttonWrap: {
    background: '#fff',
    borderRadius: 4,
    marginLeft: 4,
    overflow: 'hidden',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    background: '#fff',
    padding: '0 6px',
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#fff',
    padding: '0 6px',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
      textDecoration: 'none',
    },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
  txt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 3,
  },
}));

function ButtonList(props) {
  const { row, onClickRegister, onClickDelete } = props;
  const classes = useStyles();

  const map = 'https://www.google.com/maps?q=' + row.latitude + ',' + row.longitude;

  /**
   * 添付ファイルを加工する
   */
  const attachmentFiles = useMemo(() => {
    if (!Array.isArray(row.AttachmentFile)) return [];
    return row.AttachmentFile.map(item => {
      return {
        url: createBlobURL(base64ToBlob(item.base64, item.mimeType)),
        fileName: item.fileName,
      };
    }).filter(item => !!item.url);
  }, [row.AttachmentFile]);

  return (
    <span className={`${classes.root} flyerSalesBtn`}>
      <span className={classes.buttonWrap}>
        <Button
          className={`${classes.button} ${classes.blue}`}
          onClick={() => onClickRegister(row)}
        >
          <PersonAddIcon />
          <Typography className={classes.txt}>登録</Typography>
        </Button>
      </span>
      {attachmentFiles.map((url) => (
        <span key={url.url} className={classes.buttonWrap}>
          <Link
            href={url.url}
            target="_blank"
            className={`${classes.link} ${classes.blue}`}
          >
            <ImageOutlinedIcon />
            <Typography className={classes.txt}>{url.fileName}</Typography>
          </Link>
        </span>
      ))}
      {row.latitude && row.longitude && (
        <span className={classes.buttonWrap}>
          <Link
            href={map}
            target="_blank"
            className={`${classes.link} ${classes.blue}`}
          >
            <RoomIcon />
            <Typography className={classes.txt}>地図</Typography>
          </Link>
        </span>
      )}
      <span className={classes.buttonWrap}>
        <Button
          className={`${classes.button} ${classes.blue}`}
          onClick={() => onClickDelete(row)}
        >
          <DeleteOutlineIcon />
          <Typography className={classes.txt}>削除</Typography>
        </Button>
      </span>
    </span>
  );
}

export default ButtonList;
