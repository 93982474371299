import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import commonStyles from '../../../styles';
import {
  abodeNowBuildingTypeSelect,
  abodeNowFloorPlanSelect,
  isAbodeNowReplacementSelect,
} from '../../../../constants/customerMain';
import Calendar from '../../calendar';

import {
  convertForm1,
  convertFormNumber,
} from '../../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormInt1,
  validateFormFloat1,
} from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  presentCondition: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
  },
  presentConditionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  presentConditionBox1: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  presentConditionBlock1: {
    width: 166,
    '&:not(:last-child)': {
      marginRight: 16,
    },
  },
  presentConditionBlock2: {
    width: 400,
    marginRight: 16,
  },
  presentConditionBlock3: {
    width: 83,
    marginRight: 8,
  },
  presentConditionSelect: {
    width: '100%',
    '& .MuiSelect-select.MuiSelect-select': {
      fontWeight: 400,
      textAlign: 'left',
    },
    '& .MuiSelect-select': {
      '&:focus': {
        background: 'none',
      },
    },
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 5px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      fontWeight: '700',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
    '& span span': {
      fontSize: 10,
    },
  },
}));

export default function ResponseRegisterPresentCondition(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    user,
    detail,
    setDetail,
    getComponent,
    errorCallback,
  } = props;

  const initial = detail;

  const onBlur = (e) => {
    if (e.target.value !== detail[e.target.name]) {
      initial[e.target.name] = e.target.value;
      setDetail({ ...detail });
      console.log(detail[e.target.name]);
    }
  };

  const abodeNowBuildingTypenBlur = (e) => {
    // コードを文字列に変換
    const stringVal = (Number(e.target.value) !== 0)
      ? abodeNowBuildingTypeSelect.menus[e.target.value] : null;
    if (stringVal !== detail.abodeNowBuildingType) {
      initial.abodeNowBuildingType = stringVal;
      setDetail({ ...detail });
      console.log(detail.abodeNowBuildingType);
    }
  };
  const abodeNowFloorPlanBlur = (e) => {
    if (e.target.value !== detail.abodeNowFloorPlanCode) {
      initial.abodeNowFloorPlanCode = e.target.value;
      setDetail({ ...detail });
      console.log(detail.abodeNowFloorPlanCode);
    }
  };
  const isAbodeNowReplacementBlur = (e) => {
    if (e.target.value !== detail.abodeNowReplacementCode) {
      initial.abodeNowReplacementCode = e.target.value;
      setDetail({ ...detail });
      console.log(detail.abodeNowReplacementCode);
    }
  };

  // 現況:種類の初期値を取得
  // abodeNowBuildingTypeはstringのためstringで比較
  const abodeNowBuildingTypeCode = Object.keys(abodeNowBuildingTypeSelect.menus).find((key) => {
    return abodeNowBuildingTypeSelect.menus[key] === user.abodeNowBuildingType;
  }) || 0;

  const kokyakuSelectList = {
    abodeNowBuildingTypeItem: {
      type: 'select',
      placeholder: '-',
      obj: abodeNowBuildingTypeSelect,
      state: abodeNowBuildingTypeCode,
      set: abodeNowBuildingTypenBlur,
    },
    abodeNowFloorPlanItem: {
      type: 'selectPlaceholder',
      placeholder: '-',
      obj: abodeNowFloorPlanSelect,
      state: user.abodeNowFloorPlanCode,
      set: abodeNowFloorPlanBlur,
    },
    isAbodeNowReplacementItem: {
      type: 'selectPlaceholder',
      placeholder: '-',
      obj: isAbodeNowReplacementSelect,
      state: user.abodeNowReplacementCode,
      set: isAbodeNowReplacementBlur,
    },
  };
  console.log({ kokyakuSelectList });

  // チェックボックス
  const isCarParkInitial = () => {
    switch (user.isCarPark) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const [checked, setChecked] = React.useState(isCarParkInitial());
  const isCarParkChange = (e) => {
    setChecked(e.target.checked);
    switch (e.target.checked) {
      case true:
        initial[e.target.name] = 1;
        break;
      default:
        initial[e.target.name] = 0;
    }
    setDetail({ ...detail });
    console.log(detail[e.target.name]);
  };

  // 購入年月
  const abodeNowPurchaseAtCalendar = {
    id: 'abodeNowPurchaseAt', // String - labelとinputを紐づけます
    label: '', // String - ラベル
    require: false, // Boolean - 必須かどうか
    type: 'date', // String 'date'|'time'|'multitime'|'multiple'
    state: detail.abodeNowPurchaseAt?.substr(0, 7), // state
    setState: (abodeNowPurchaseAt) => {
      setDetail({ ...detail, abodeNowPurchaseAt });
    },
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>
        現況
      </Typography>
      <Grid className={classes.presentCondition}>
        <Grid className={classes.presentConditionBox1}>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              種類
            </Typography>
            <Grid className={classes.presentConditionSelect}>
              {getComponent(kokyakuSelectList.abodeNowBuildingTypeItem)}
            </Grid>
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              間取り
            </Typography>
            <Grid className={classes.presentConditionSelect}>
              {getComponent(kokyakuSelectList.abodeNowFloorPlanItem)}
            </Grid>
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              <span>面積<span>（㎡）</span></span>
            </Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowLivingSpace}
              className={classes.textField}
              name="abodeNowLivingSpace"
              onBlur={onBlur}
              validator={validateFormFloat1({
                minFloat: 0.0,
                maxFloat: 9999.0,
                precisionInt: 3,
              })}
              convertor={convertFormNumber}
              errorCallback={errorCallback('abodeNowLivingSpace')}
            />
          </Grid>
        </Grid>
        <Grid className={classes.presentConditionBox}>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              購入年月
            </Typography>
            <Calendar data={abodeNowPurchaseAtCalendar} noBorder onUnderline alignLeft />
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              <span>当時の価格<span>（万円）</span></span>
            </Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowPriceAtTime}
              className={classes.textField}
              type="number"
              name="abodeNowPriceAtTime"
              onBlur={onBlur}
              validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99999.0 })}
              convertor={convertFormNumber}
              errorCallback={errorCallback('abodeNowPriceAtTime')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              <span>居住期間<span>（年）</span></span>
            </Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowResidencePeriod}
              className={classes.textField}
              name="abodeNowResidencePeriod"
              onBlur={onBlur}
              validator={validateFormFloat1({
                minFloat: 0.0,
                maxFloat: 99.0,
                precisionInt: 4,
              })}
              convertor={convertFormNumber}
              errorCallback={errorCallback('abodeNowResidencePeriod')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              <span>希望売却価格<span>（万円）</span></span>
            </Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowSalePrice}
              className={classes.textField}
              type="number"
              name="abodeNowSalePrice"
              onBlur={onBlur}
              validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99999.0 })}
              convertor={convertFormNumber}
              errorCallback={errorCallback('abodeNowSalePrice')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            {/* 空行 */}
          </Grid>
        </Grid>
        <Grid className={classes.presentConditionBox}>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              買い替え有無
            </Typography>
            <Grid className={classes.presentConditionSelect}>
              {getComponent(kokyakuSelectList.isAbodeNowReplacementItem)}
            </Grid>
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>買い替え種類</Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowReplaceType}
              className={classes.textField}
              name="abodeNowReplaceType"
              onBlur={onBlur}
              convertor={convertForm1}
              validator={validateFormString1({ maxLengthInt: 30 })}
              errorCallback={errorCallback('abodeNowReplaceType')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              <span>借り入れ残年数<span>（年）</span></span>
            </Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowCurrentMaturity}
              className={classes.textField}
              name="abodeNowCurrentMaturity"
              onBlur={onBlur}
              validator={validateFormFloat1({
                minFloat: 0.0,
                maxFloat: 99.0,
                precisionInt: 4,
              })}
              convertor={convertFormNumber}
              errorCallback={errorCallback('abodeNowCurrentMaturity')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>借り入れ銀行</Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowBankName}
              className={classes.textField}
              name="abodeNowBankName"
              onBlur={onBlur}
              convertor={convertForm1}
              validator={validateFormString1({ maxLengthInt: 50 })}
              errorCallback={errorCallback('abodeNowBankName')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock1}>
            <Typography className={baseClasses.title4}>
              <span>借り入れ額<span>（万円）</span></span>
            </Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowBorrowingAmount}
              className={classes.textField}
              name="abodeNowBorrowingAmount"
              onBlur={onBlur}
              validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999999.0 })}
              convertor={convertFormNumber}
              errorCallback={errorCallback('abodeNowBorrowingAmount')}
            />
          </Grid>
        </Grid>
        <Grid className={classes.presentConditionBox1}>
          <Grid className={classes.presentConditionBlock2}>
            <Typography className={baseClasses.title4}>車種</Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowCarType}
              className={classes.textField}
              name="abodeNowCarType"
              onBlur={onBlur}
              convertor={convertForm1}
              validator={validateFormString1({ maxLengthInt: 30 })}
              errorCallback={errorCallback('abodeNowCarType')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock3}>
            <Typography className={baseClasses.title4}>台数<small>（台）</small></Typography>
            <TextBaseField
              placeholder="-"
              defaultValue={user.abodeNowCarCount}
              className={classes.textField}
              name="abodeNowCarCount"
              onBlur={onBlur}
              validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
              convertor={convertFormNumber}
              errorCallback={errorCallback('abodeNowCarCount')}
            />
          </Grid>
          <Grid className={classes.presentConditionBlock5}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={<Checkbox checked={checked} onChange={isCarParkChange} name="isCarPark" />}
              label="駐車場希望"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
