import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 187,
    background: '#fff',
    border: '1px solid #C8C8C8',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
  },
  title: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
    borderRadius: 0,
    paddingLeft: 8,
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '900',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

function CustomerAcquisition(props) {
  const { input } = props;
  const { acquisitionName, acquisitionMemo } = input.customer;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.root}>
      <Typography className={`${baseClasses.title6} ${classes.title}`}>
        獲得者
      </Typography>
      <Grid className={classes.txt}>
        {acquisitionName || acquisitionMemo}
      </Grid>
    </Grid>
  );
}

export default React.memo(CustomerAcquisition);
