const modifyArray = (key, title, isEmpty = false) => {
  if (isEmpty) {
    key[''] = '　';
    const changedArray = Object.entries(key);
    const element = changedArray.splice(changedArray.length - 1, 1);
    const orderedArray = [...element, ...changedArray];
    let newArray = orderedArray.map((array) => {
      const newObject = { value: array[0], text: array[1] };
      return newObject;
    });
    newArray = { menus: newArray, title };
    return newArray;
  }
  const changedArray = Object.entries(key);
  let newArray = changedArray.map((array) => {
    const newObject = { value: array[0], text: array[1] };
    return newObject;
  });
  newArray = { menus: newArray, title };
  return newArray;
};

export default modifyArray;
