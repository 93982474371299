import ExcelJS from 'exceljs';

import { createBlobURL, downloadFile } from './file';

const createCsv = async (fileName, columns, rows, excelFlg = false) => {
  const workbook = new ExcelJS.Workbook();
  workbook.addWorksheet('sheet1');
  const worksheet = workbook.getWorksheet('sheet1');

  worksheet.columns = columns;

  worksheet.addRows(rows);
  let uint8Array = null;
  if (excelFlg) {
    uint8Array = await workbook.xlsx.writeBuffer();
  } else {
    uint8Array = await workbook.csv.writeBuffer();
  }
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName + (excelFlg ? '.xlsx' : '.csv');
  a.click();
  a.remove();
};

export default createCsv;

/**
 * CSVファイルのダウンロード機能
 * apiで使うときはバイナリデータが返ってくるのでオプションにresponseType:'arraybuffer'を追加すること
 * @param {string} data - バイナリデータ(apiからの返り値を想定)
 * @param {string} fileName - 出力する際のファイル名(デフォルト値:download.csv)
 */
export const downloadCSV = (data, fileName = 'download.csv') => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = createBlobURL(blob);
  // BlobからオブジェクトURLを作成する
  downloadFile(url, fileName);
};
