import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import store from '../../store';
import { getlibrarydirDirectlyTreeApi } from '../../store/librarydir/librarydirGetDirectlyTreeSlice';
import librarydirGetDirectlyTreeApi from '../../apis/librarydir/librarydirGetDirectlyTreeApi';

import LibraryDirectoryTree from '../../components/common/libraryDirectoryTree/libraryDirectoryTree';
import { useLibraryDirectoryTreeContext } from '../../components/common/libraryDirectoryTree/context';
import { useLibraryContext } from '../../components/mailLibrary/mailLibraryContext';

export default function LibraryDirectoryTreeContainer() {
  const {
    isTopRootDirectory,
    isGetLatestData,
    setActiveDir,
  } = useLibraryDirectoryTreeContext();
  const { setOwnDir } = useLibraryContext();
  const dispatch = useDispatch();

  const getTreeList = (successCallBack = () => {}) => {
    librarydirGetDirectlyTreeApi()
      .then((res) => {
        dispatch(getlibrarydirDirectlyTreeApi(res.data));
        successCallBack(res.data);
      })
      .catch((err) => console.error(err));
  };

  // 初回表示時
  useEffect(() => {
    const { librarydirGetDirectlyTree: tmp } = store.getState();
    const { librarydirGetDirectlyTree } = tmp;
    const { libraryDirectories } = librarydirGetDirectlyTree;
    const clickOwnDirectory = isTopRootDirectory
      ? (data) => {
        if (data.libraryDirectories.length === 0) return;
        setActiveDir(data.libraryDirectories[0]);
        setOwnDir(data.libraryDirectories[0]);
      }
      : () => {};
    if (libraryDirectories.length !== 0 && !isGetLatestData) return;
    getTreeList(clickOwnDirectory);
    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(getlibrarydirDirectlyTreeApi({ libraryDirectories: [] }));
    };
  }, []);

  return <LibraryDirectoryTree />;
}
