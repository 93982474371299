import MailLibrary from '../mailLibrary';
import { LibraryProvider } from '../mailLibrary/mailLibraryContext';

export default function MailLibraryPage(props) {
  const { dblclick } = props;
  return (
    <LibraryProvider>
      <MailLibrary dblclick={dblclick} />
    </LibraryProvider>
  );
}
