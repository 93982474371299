import { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import CustomerTree from '../../../common/customerTree/customerTree';
import KanniKensaku from '../../../search/kanniKensaku';
import commonTheme from '../../../styles/theme';

import store from '../../../../store/index';
import { updateObjSearchItems } from '../../../../store/search/searchItemsSlice';
import { initialTreeState } from '../../../../containers/search/detailSearch/detailSearchContainers';

const useStyles = makeStyles({
  sideMenu: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 240,
    },
  },
});
export default function SideBarWrapper(props) {
  const { value } = props;
  const classes = useStyles();
  const { responseHeader } = store.getState();
  // 初期ユーザーの設定
  const [selectUser, setSelectUser] = useState({ userId: '', userName: '' });
  const [selectDivision, setSelectDivision] = useState({ divId: '', divisionName: '' });
  // ツリー表示/非表示
  const [isOpen, setIsOpen] = useState(false);
  // // [division005]パラメータ（typeCode, unitCodeは画面から選択する為不要）
  // const [searchParams, setSearchParams] = useState({});
  // Dialog形式かどうか
  const [isModal] = useState(false);
  // 検索ボックスの表示/非表示
  const [searchForm] = useState(false);
  // 検索と選択対象
  const [targetFlgList] = useState({
    userFlg: true,
    divisionFlg: true,
    customerFlg: true,
  });

  useEffect(() => {
    if (!responseHeader.userId) return;
    setSelectUser({
      userId: responseHeader.userId,
      userName: responseHeader.userName,
    });
  }, [responseHeader]);

  useEffect(() => {
    // 見込み案件管理表以外の簡易検索にあり、詳細検索にない項目を初期化する

    const { searchItems: { searchParams } } = store.getState();

    const initialSearchParam = {
      // 今TELが繋がりやすい顧客
      convenientTimes: initialTreeState.convenientTimes,
      isCanCallNow: initialTreeState.isCanCallNow,
      // 直近1週間の反響
      isEffectWeek: initialTreeState.isEffectWeek,
    };
    // ユーザが直接変更した場合は偽になる
    // 「直近1週間の反響」のチェックボックスを押した際に代入される値を初期化する
    if (searchParams?.isEffectWeek) {
      initialSearchParam.effectFrom = initialTreeState.effectFrom;
      initialSearchParam.effectTo = initialTreeState.effectTo;
    }
    store.dispatch(updateObjSearchItems(initialSearchParam));
  }, []);

  // 反響ツリーの高さ調整
  const [treeHeight, setTreeHeight] = useState('');
  const [openAccordionFilter, setOpenAccordionFilter] = useState(true);
  const [openAccordionSearch, setOpenAccordionSearch] = useState(true);
  const changeAccordionFilter = (d) => {
    setOpenAccordionFilter(d);
  };
  const changeAccordionSearch = (d) => {
    setOpenAccordionSearch(d);
  };
  useEffect(() => {
    const accordionFilterHeight = openAccordionFilter ? 211 : 24;
    const accordionSearchHeight = openAccordionSearch ? 284 : 24;
    const height = 144 + accordionFilterHeight + accordionSearchHeight;
    setTreeHeight(`calc(100vh - ${height}px)`);
  }, [openAccordionFilter, openAccordionSearch]);

  return (
    <Grid className={classes.sideMenu}>
      <KanniKensaku
        addOnlyFilterBlock
        changeAccordionFilter={changeAccordionFilter}
        changeAccordionSearch={changeAccordionSearch}
      />
      <CustomerTree
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setSelectCustomer={value.setSelectCustomer}
        setSelectUser={setSelectUser}
        setSelectDivision={setSelectDivision}
        isModal={isModal}
        searchForm={searchForm}
        targetFlgList={targetFlgList}
        defaultUserId={selectUser?.userId}
        defaultDivisionId={selectDivision?.divId && !selectUser?.userId ? selectDivision.divId : ''}
        sortType="rank"
        treeHeight={treeHeight}
      />
    </Grid>
  );
}
