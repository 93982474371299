import client from '../httpClient';
import { isObject } from '../../commonFunction';

/**
 * 【act003】対応履歴取得（顧客ID指定）
 * http://10.22.2.48:8080/docs/#/act/post-act-list-customer-id
 * @param {number} pathParameter
 * @param {{
 * customerIds: number[];
 * actions?: {
 *   actionCode: number;
 *   detailCodes: number[];
 * }[];
 * fromTime?: string;
 * toTime?: string;
 * limit: number;
 * offset: number;
 * sortField?: string;
 * sortOrder?: number;
 * }} param
 * @returns {Array<{
 * total: number;
 * histories: Array<*>;
 * id: number;
 * }> | {
 * data: {total: number; histories: Array<*>};
 * }}
 */
const actCreateCustomHistoryApi = async (customerId, param = {}) => {
  const isNewCoding = isObject(customerId);
  // 新旧APIの実装方かどうかチェック
  if (isNewCoding) {
    // 新実装方
    param = customerId;
    customerId = null;
  } else {
    // 古いAPIの実装方
    param.customerIds = [customerId];
  }
  const res = await client.post('/actions/histories/customer-list', JSON.stringify(param), { isShowLoading: false });
  const { customers } = res.data;
  // 古いAPIの実装方の場合, 既存処理に影響しないため
  // {data: { total: number, histories: [], id: number}}を返す
  if (!isNewCoding) {
    return { data: customers[0] || { histories: [], total: 0 } };
  }
  // paramを使用する場合, Array<{ total: number, histories: [], id: number}>を返す
  return customers;
};

export default actCreateCustomHistoryApi;
