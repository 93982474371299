import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAreas, CACHE_KEY_STORE_AREA_LIST } from '../../../store/settingGroupStore/settingGroupStoreAreaListSlice';
import settingGroupStoreGetAreaListApi from '../../../apis/settingGroupStore/settingGroupStoreGetAreaListApi';
import { getLocalStorage, setLocalStorage } from '../../../commonFunction';
import { useLoading } from '../../../hooks';

export const CACHE_KEY_STORE_AREA_LIST_EXPIRED = 'CACHE_KEY_STORE_AREA_LIST_EXPIRED';

// キャッシュチェック用
export const TODAY = new Date().getDate();

// リクエスト制御
let isRequesting = false;

export function useStoreGroupsSettingsAreas() {
  const dispatch = useDispatch();

  // 【settingGroupStore008】エリアリスト取得
  // { areaCode: number, areaName: string, wishCityCount: number }
  // TODO: 毎回リロードの際、データを更新したい場合、Reduxを使用ください
  const { areaList } = useSelector((s) => s.settingGroupStoreAreas);

  const { addLoading, removeLoading } = useLoading();

  const isSameDay = useMemo(() => {
    return getLocalStorage(CACHE_KEY_STORE_AREA_LIST_EXPIRED) === TODAY;
  }, []);

  /**
   * 【settingGroupStore008】エリアリスト取得
   */
  const getAreaList = useCallback(async () => {
    if (isRequesting) return;
    isRequesting = true;
    addLoading();
    try {
      const res = await settingGroupStoreGetAreaListApi();
      const { areas = [] } = res.data;
      const srotedArea = areas.sort((a, b) => (a.areaCode - b.areaCode > 0 ? 1 : -1));
      setLocalStorage(CACHE_KEY_STORE_AREA_LIST, srotedArea);
      setLocalStorage(CACHE_KEY_STORE_AREA_LIST_EXPIRED, TODAY);
      dispatch(setAreas(srotedArea));
    } finally {
      removeLoading();
      isRequesting = false;
    }
  }, []);

  // 初期化
  useEffect(() => {
    // エリア情報を1日キャッシュ、翌日に更新される
    if (!areaList.length || !isSameDay) getAreaList();
  }, []);

  return {
    areaList,
    isSameDay,
  };
}
