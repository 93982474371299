import { createSlice } from '@reduxjs/toolkit';

import {
  decodeUtf8,
  toNumber,
  setLocalStorage,
  getLocalStorage,
} from '../../commonFunction';
import becomeList from '../../commonFunction/becomeList';

import { USER_ROLE_IDS } from '../../constants/localStorage';

// http://10.22.2.48:8080/docs
const initialState = {
  status: 0,
  userName: '', // string (ログインuserの姓名)
  userId: 0, // number (ログインuserのid)
  email: '', //  string (ログインuserのメールアドレス)
  roleIds: getLocalStorage(USER_ROLE_IDS, []), // number[] (ログインuserの持つ権限IDの配列)
  roleGroupId: 0, // number (ログインuserの権限グループID)
  mainDivision: {}, // {} (ログインuserのメイン所属組織id)
  subDivisions: [], // number[] (ログインuserのサブ所属組織id複数)
  isHoliday: false, // Boolean (ログインuserが公休日にログインしているかのチェックフラグ)
  isHolidayAlert: true, // Boolean (ログインユーザの権限によって公休日にアラートを出す必要がない場合 1(true) アラートを出す場合は 0(false))
  isSkipCheckHoliday: false, // ?
};

// const initialState = {
//   status: 0,
//   userName: 'テスト 虎太郎', // string (ログインuserの姓名)
//   userId: 1, // number (ログインuserのid)
//   email: 'kotaro.test@openhouse-group.com', //  string (ログインuserのメールアドレス)
// eslint-disable-next-line max-len, max-len
//   roleIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 22, 29, 30, 32, 33, 34, 35, 36, 37, 101, 102, 103, 104, 105, 106, 107, 108, 111, 113, 116, 117, 120, 204, 211, 212, 213, 214, 215], // number[] (ログインuserの持つ権限IDの配列)
//   roleGroupId: 55, // number (ログインuserの権限グループID)
//   mainDivision: {
//     id: 1,
//     name: '組織枝名',
//   }, // {} (ログインuserのメイン所属組織id)
//   subDivisions: [], // number[] (ログインuserのサブ所属組織id複数)
//   isHoliday: true, // Boolean (ログインuserが公休日にログインしているかのチェックフラグ)
//   isHolidayAlert: true, // Boolean (ログインユーザの権限によって公休日にアラートを出す必要がない場合 1(true) アラートを出す場合は 0(false))
//   isSkipCheckHoliday: true, // ?
// };

export const responseHeaderSlice = createSlice({
  name: 'responseHeader',
  initialState,
  reducers: {
    setResponseHeader: (state, res) => {
      if (res.payload.status === 403) {
        state.status = res.payload.status;
        state.isHolidayAlert = !!toNumber(res.payload['is-holiday-alert']);
      } else {
        // 画面初期描画の権限による分岐のためにロールをローカルストレージに格納
        const roleIds = becomeList(res.payload['role-ids']);
        setLocalStorage(USER_ROLE_IDS, roleIds);
        state.roleIds = roleIds;
        state.userName = decodeUtf8(res.payload.username);
        state.userId = toNumber(res.payload.userid);
        state.email = res.payload.email;
        state.roleGroupId = toNumber(res.payload['role-group-id']);
        const mainDivision = decodeUtf8(res.payload.maindivision);
        state.mainDivision = {
          id: toNumber(mainDivision?.division_id),
          name: mainDivision?.name,
        };
        state.subDivisionIds = becomeList(res.payload.subdivisions);
        state.isHoliday = !!toNumber(res.payload['is-holiday']);
        state.isHolidayAlert = !!toNumber(res.payload['is-holiday-alert']);
        state.isSkipCheckHoliday = !!toNumber(res.payload['is-skip-check-holiday']);
      }
    },
    setIsTest: (state) => {
      // eslint-disable-next-line max-len, comma-spacing
      const roleIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 22, 29, 30, 32, 33, 34, 35, 36, 37, 101, 102, 103, 104, 105, 106, 107, 108, 111, 113, 116, 117, 120, 204, 211, 212, 213, 214, 215];
      setLocalStorage(USER_ROLE_IDS, roleIds);
      state.roleIds = roleIds;
      state.userName = 'テスト 虎太郎';
      state.userId = 25;
      state.email = 'kotaro.test@openhouse-group.com';
      state.roleGroupId = 55;
      state.mainDivision = {
        id: 5,
        name: 'グループB_1',
      };
      state.subDivisionIds = [];
      state.isHoliday = 1;
      state.isHolidayAlert = 1;
      state.isSkipCheckHoliday = 1;
    },
  },
});

export const { setResponseHeader, setIsTest } = responseHeaderSlice.actions;

export default responseHeaderSlice.reducer;
