/* eslint-disable max-len */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { cantCloseAlertMessage, initCantCloseAlertMessage } from '../../../store/eleCommon/cantCloseAlertMessage';
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '../../../commonFunction';
import logo from '../../images/ohLogoSp.svg';
import commonStyles from '../../styles';
import getEnv from '../../../commonFunction/getEnv';

const useStyles = makeStyles((theme) => ({
  header: {
    // position: 'sticky',
    // top: 0,
    // zIndex: 1101,
    display: 'none',
    '&.onlyAlert': {
      display: 'flex',
      flexDirection: 'column',
      height: 26,
    },
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
    // height: 80,
    background: '#fafafa',
    '&.onlyAlert': { display: 'none' },

    '&.STAGING': { background: '#fce76b' },
    '&.DEVELOP': { background: '#94d5f7' },
    '&.LOCAL': { background: '#d4fecd' },
  },
  logo: { width: 200 },
  txt: { fontSize: 16, fontWeight: 700, marginLeft: 16 },
  alert: {
    background: theme.palette.primaryColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    flexShrink: 0,
  },
  alertTxt: { color: '#fff' },
  home: {
    display: 'flex',
    flexDirection: 'column',
    height: 80,
  },
}));

// 公休日アラートのヘッダー
function Header(props) {
  const { diff } = props;
  const common = commonStyles();
  const classes = useStyles();
  const is1MinLeft = diff < 60000;
  const unit = is1MinLeft ? '秒' : '分';
  const value = Math.round(diff / (is1MinLeft ? 1000 : 60000));
  return (
    <Grid className={classes.alert}>
      <Typography className={`${classes.alertTxt} ${common.title4}`}>あと {value}{unit} 利用可能</Typography>
    </Grid>
  );
}

/**
 * 現在、公休日アラートは「公休日アラート」テストページからのみ起動されるようになっています。
 * @module CommonHeaderComponent - 共通ヘッダー **  本番ではpropsはない状態でデプロイしてください。koukyubiAlertTestFlgは検証用においているだけです**
 * @param {Boolean} props.koukyubiAlertTestFlg - 公休日アラートテストページからアラートを起動させるための値。FIXME: テスト完了段階で削除。
 * @returns {jsx} jsx
 */
export default function CommonHeaderComponent() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const nowRef = useRef(new Date());
  const threshhold = 1800000; // 1800000 ミリ秒 = 30分
  // const threshhold = 360000;// 検証用 6分ver
  // レスポンスヘッダー情報
  const { isHolidayAlert } = useSelector((state) => state.responseHeader);
  const [holidayFlg, setHolidayFlg] = useState(false);
  const is5MinAlert = useRef(false);
  const availableTimeRef = useRef(0);

  // 実装内容：
  // 1. localStorageに公休日での使用時間と使用した日付を保存
  // 2. 初回ロード時にlocalStorageに'timeSpentOnHoliday'がないか確認し、各localstateに格納(time, lastAccessDate)
  // 複数タブでSFAを展開する可能性も考えるとglobalStateで管理した方がいいかも。
  // 3. useEffect内、setTimeout(func, 1000)で毎秒事にstateとlocalStorageを更新

  // 使用時間(ミリ秒)
  const [time, setTime] = useState(0);

  // 最後に公休日にSFAにアクセスした日
  const [lastAccessDate, setLastAccessDate] = useState('');

  // 年月日のみを抽出する（引数はdateオブジェクト）
  const getDateStr = (arg) => {
    return `${arg.getFullYear()}/${arg.getMonth() + 1}/${arg.getDate()}`;
  };

  // 今日の日付
  const dateToday = getDateStr(nowRef.current);
  // 公休日にアクセスしたときの日付
  const dateAccessedLastTime = getDateStr(new Date(lastAccessDate));

  // アラートが出ているか
  const isAlertMsg = useSelector(state => state.cantCloseMessage.cantCloseState.open);

  // 初期化
  const initialize = () => {
    // isHolidayAlert: ログインユーザの権限によって公休日にアラートを出す必要がない場合 1(true) アラートを出す場合は 0(false)
    // デバッグで公休日アラートを出力したい場合は以下3行をコメントアウト
    if (isHolidayAlert) {
      return;
    }
    const localStorage = getLocalStorage('timeSpentOnHoliday');
    availableTimeRef.current = localStorage?.availableTime ?? threshhold;
    if (localStorage) {
      setTime(localStorage.milliseconds);
      setLastAccessDate(localStorage.accessDate);
    }
    // 初期ロード時にtimeSpentOnHolidayをローカルストレージに作成
    setLocalStorage('timeSpentOnHoliday', {
      accessDate: dateToday,
      milliseconds: localStorage?.time ?? time,
      availableTime: availableTimeRef.current,
    });
    if (availableTimeRef.current > 120000) {
      dispatch(changeAlertMessage({
        msgList: ['公休日・勤務時間外の使用は', '30分間に制限されます。', '制限時間は毎日0時にリセットされます。'], // 必須
        title: '利用時間に注意してください', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
        isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
      }));
    }
    // トリガー
    setHolidayFlg(true);
  };

  // 時間と日付をアップデート
  const updateTimeAndDate = () => {
    // 前回呼び出しのタイミングからの差分の時間をtimeに追加
    const now = new Date();
    const diffTime = now - nowRef.current;
    nowRef.current = now;
    setTime(time + diffTime);
    // setTime(time + diffTime + 1000000);
    setLastAccessDate(localStorage.accessDate);
  };

  useEffect(() => {
    // 公休日フラッグがtrueなら公休日アラート関連の処理を開始
    initialize();
  }, [isHolidayAlert]);

  useEffect(() => {
    if (!holidayFlg) {
      return;
    }
    // 公休日フラッグがtrueなら公休日アラート関連の処理
    const localStorage = getLocalStorage('timeSpentOnHoliday');
    availableTimeRef.current = threshhold - time;

    // console.log('---', { ...localStorage, time, availableTime: availableTimeRef.current });
    // ローカルストレージに1秒ごとに時間を追加
    setLocalStorage('timeSpentOnHoliday', {
      accessDate: dateToday,
      milliseconds: time,
      availableTime: availableTimeRef.current,
    });

    // ローカルストレージ内で保存されている利用時間(milliseconds)が30分を超えたらアラートを表示
    if (availableTimeRef.current <= 0) {
      dispatch(cantCloseAlertMessage({
        msgList: ['公休日・勤務時間外の使用は', '30分間に制限されます。', '制限時間は毎日0時にリセットされます。'], // 必須
        title: '本日は利用できません', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      }));
    } else if (isAlertMsg) { // 日付をまたいだ際にアラートが残存するため、消去
      dispatch(initCantCloseAlertMessage());
    }

    // 残り五分のタイミングで発火
    if (!is5MinAlert.current && (availableTimeRef.current < 330000 && availableTimeRef.current > 270000)) { // 5min30sec～4min30secでアラートでます
      is5MinAlert.current = true;
      dispatch(changeAlertMessage({
        msgList: ['公休日・勤務時間外の使用は', '30分間に制限されます。', '制限時間は毎日0時にリセットされます。'], // 必須
        title: '残り5分です', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
        isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
      }));
    }
    // 下記の条件を満たした場合、setTimeout内で1秒追加する
    // ＜条件リスト＞ローカルストレージの値がある && 経過時間が30分より下 && 利用可能残り時間が0以上 && 最後にアクセスした日付が本日
    if (localStorage && time <= threshhold && availableTimeRef.current >= 0 && new Date(dateToday) <= new Date(localStorage.accessDate)) {
      // setTimeoutで1秒ごとにsetTimeで1000ミリ秒を加算
      setTimeout(updateTimeAndDate, 1000);
    }
  }, [holidayFlg, time]);

  useEffect(() => {
    if (new Date(dateToday) > new Date(dateAccessedLastTime)) {
      // 日付をまたいでいた場合、localStorage内にあるデータを削除
      removeLocalStorage('timeSpentOnHoliday');
      setTime(0);
      setLastAccessDate(dateToday);
      initialize();
    }
  }, [lastAccessDate]);

  // ホーム画面のみヘッダー表示
  const location = useLocation();
  const [home, setHome] = useState(true);
  useEffect(() => { setHome(/\/sfa\/home/.test(location.pathname)); }, [location]);

  const envClass = getEnv();

  return (
    <Grid className={`${classes.header} ${home && classes.home} ${home && 'home'} ${!home && holidayFlg ? 'onlyAlert' : ''}`}>
      {holidayFlg && availableTimeRef.current > 0 && <Header diff={availableTimeRef.current} />}
      <Grid className={`${classes.logoWrap}  ${envClass} ${!home && holidayFlg ? 'onlyAlert' : ''}`}>
        <img src={logo} alt="OPEN HOUSE" className={classes.logo} />
        <Typography className={classes.txt}>戸建{envClass !== 'PRODUCTION' ? envClass : ''}</Typography>
      </Grid>
    </Grid>
  );
}
