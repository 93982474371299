import { makeStyles, Collapse, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  alert: {
    position: 'fixed',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    '& .MuiAlert-filledInfo': {
      background: '#27AE60',
    },
    '& .MuiAlert-icon': {
      padding: '8px 0',
    },
  },
  button: {
    '&::before, &::after': {
      background: '#fff',
    },
  },
});

export default function CustomAlertComplete(props) {
  const {
    text,
    open,
    setOpen,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Collapse
      in={open}
      className={`${baseClasses.cancelIndex} ${classes.alert}`}
      timeout={0}
    >
      <Alert
        variant="filled"
        severity="info"
        action={
          <Button className={`${baseClasses.closeButton} ${classes.button}`} onClick={() => { setOpen(false); }} />
        }
      >
        {text}
      </Alert>
    </Collapse>
  );
}
