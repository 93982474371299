import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid,
  Typography,
  Button,
  Drawer,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ContactsIcon from '@material-ui/icons/Contacts';
import TuneIcon from '@material-ui/icons/Tune';
import CalendarViewDayOutlinedIcon from '@material-ui/icons/CalendarViewDayOutlined';
import Icon from '@material-ui/core/Icon';
import ReactToPrint from 'react-to-print';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
// eslint-disable-next-line import/no-cycle
import Day from './day';
import Week from './week';
import Calendar from '../../common/calendar';
import CustomerTree from '../../common/customerTree/customerTree';
// eslint-disable-next-line import/no-cycle
import InfoRegisterOnlyPost from '../../common/infoRegisterOnlyPost';
import Search from '../search';
import ActRegistWindow from '../../pages/actRegist/actRegistWindow';
import store from '../../../store';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      background: '#fff',
      width: '100%',
      padding: '13px 0 13px 8px',
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 24,
      padding: '24px 16px 0',
    },
  },
  buttonWrap: {
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'center',
    borderRight: '1px solid #C8C8C8',
    borderLeft: '1px solid #C8C8C8',
  },
  button1: {
    marginRight: 16,
    padding: '6px 22px',
  },
  button2: {
    padding: '6px 22px',
  },
  button3: {
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  button4: {
    padding: '6px 22px',
  },
  appBar: {
    position: 'relative',
    color: '#333',
    background: 'none',
    boxShadow: 'none',
    zIndex: 0,
    [commonTheme.breakpoints.up('md')]: {
      width: 66,
      margin: '0 16px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  iconBox: {
    position: 'absolute',
    top: 8,
    left: 15,
    pointerEvents: 'none',
    '& .MuiSvgIcon-root': {
      display: 'block',
      marginBottom: 19,
      fontSize: 22,
    },
    '& .MuiIcon-root': {
      display: 'block',
      fontSize: 22,
    },
  },
  tabs: {
    minHeight: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
      marginBottom: 10,
      paddingBottom: 10,
    },
    [commonTheme.breakpoints.up('md')]: {
      borderRadius: 4,
    },
    '& .MuiTabs-flexContainer': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
      },
      '& button': {
        [commonTheme.breakpoints.down('sm')]: {
          display: 'block',
          width: '100%',
          padding: '10px 19px 10px 45px',
        },
        '& span': {
          [commonTheme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'left',
          },
        },
      },
    },
  },
  tab: {
    minWidth: 'auto',
    minHeight: 'auto',
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      maxWidth: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '50%',
      padding: '5px 0 3px',
      color: theme.palette.primaryColor,
    },
    '&.Mui-selected': {
      [commonTheme.breakpoints.down('sm')]: {
        color: theme.palette.primaryColor,
      },
      [commonTheme.breakpoints.up('md')]: {
        color: '#333333',
        background: '#C8C8C8',
      },
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '1.6',
      letterSpacing: '0',
    },
  },
  tabPanel: {
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 66,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 15px',
      width: 1680,
    },
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  tabPanel2: {
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 78,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 15px',
      width: 1680,
    },
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  pagerBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 14px',
  },
  pagerButton: {
    padding: 6,
    minWidth: 'auto',
    borderRadius: '50%',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '&:first-child .MuiSvgIcon-root': {
      transform: 'translateX(3px)',
    },
  },
  pagerIcon: {
    fontSize: 14,
    color: theme.palette.primaryColor,
  },
  headerMonth: {
    position: 'relative',
    width: 68,
    // overflow: 'hidden',
    '&::after': {
      top: 12,
      right: 6,
      width: 0,
      height: 0,
      content: '""',
      display: 'block',
      position: 'absolute',
      borderColor: `${theme.palette.primaryColor} transparent`,
      borderStyle: 'solid',
      borderWidth: '4px 4px 0',
      pointerEvents: 'none',
    },
    '& > .MuiGrid-root': {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      height: '100%',
      opacity: 0,
    },
  },
  headerMonthTxt: {
    position: 'relative',
    zIndex: 1,
  },
  buttonName: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc(100% - 122px)',
    padding: '0 8px',
    marginLeft: 8,
    '&::after': {
      top: 20,
      right: 8,
      width: 0,
      height: 0,
      content: '""',
      display: 'block',
      position: 'absolute',
      borderColor: `${theme.palette.primaryColor} transparent`,
      borderStyle: 'solid',
      borderWidth: '4px 4px 0',
      pointerEvents: 'none',
    },
    '& .MuiSvgIcon-root': {
      color: '#333',
      fontSize: 24,
    },
  },
  nameBox: {
    marginLeft: 6,
  },
  groupName: {
    fontSize: 13,
    textAlign: 'left',
    lineHeight: 1.6,
  },
  buttonTune: {
    position: 'absolute',
    top: 17,
    right: 0,
    padding: 11,
    minWidth: 'auto',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primaryColor,
      fontSize: 22,
    },
  },
  drawer: {
    position: 'relative',
    zIndex: '1201!important',
    '& .MuiDrawer-paper': {
      width: '84%',
      right: 0,
      left: 'auto',
    },
  },
  drawerHeader: {
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '24px',
      padding: '16px 16px 8px 16px',
      width: '100%',
      boxSizing: 'border-box',
      position: 'sticky',
      top: 0,
      background: '#fff',
      zIndex: 1,
      borderBottom: '1px #c8c8c8 solid',
    },
  },
}));

export default function ActionScheduleTabs(props) {
  const {
    targetFlgList,
  } = props;

  const {
    dateConversion2,
    selectDivision,
    getSchedulesDate,
    getSchedulesWeek,
    actionScheduleDate,
    setActionScheduleDate,
    setIsOpenTree,
    targetDivisionTree,
    setReacquisitionFlg,
    functions,
  } = useActionSchedule();

  const listTableRef = useRef();
  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();
  const { responseHeader } = store.getState();
  const { userId, roleIds } = responseHeader;

  const getPrintPageStyle = () => {
    return `@page {
              margin: 3rem 2rem 2rem 2rem !important; size: landscape;
            }
            ::-webkit-scrollbar-thumb {
              background-color: transparent;
            }
            .App.sp .allWrapper {
              height: auto!important;
            }
            .allWrapper {
              height: auto!important;
            }
            .rndDay-button {
              display: none!important;
            }
            .rndDay-tableContainer {
              height: auto!important;
              padding-top: 0!important;
              background-color: #fff !important;
            }
            .rndDay-table {
              display: table!important;
              zoom: 0.6!important;
            }
            .rndDay-thead {
              display: block!important;
              background: #fff!important;
              width: 100%!important;
            }
            .rndDay-theadTr {
              display: flex!important;
              width: 100%!important;
              background: #fff!important;
            }
            .rndDay-tbody {
              width: 100%!important;
              display: table-row-group!important;
            }
            .rndDay-th-first {
              width: 192px!important;
            }
            .rndDay-th-sp {
              display: none!important;
            }
            .rndDay-th {
              display: block!important;
              width: 56px!important;
              height: auto!important;
              padding: 6px 4px!important;
              text-align: left!important;
              border-right: none!important;
            }
            .rndDay-th:not(:last-child) {
              border-right: 1px solid #C8C8C8!important;
              box-sizing: border-box!important;
            }
            .rndDay-th span {
              transform: translateY(0)!important;
            }
            .rndDay-label {
              display: block!important;
            }
            .rndDay-table-table {
              display: block!important;
            }
            .rndDay-table-tbody {
              display: block!important;
            }
            .rndDay-table-label {
              display: block!important;
            }
            .rndDay-table-contents {
              display: table-row!important;
            }
            .rndDay-table-contentsTd {
              display: table-cell!important;
            }
            .rndDay-table-contentsList {
              display: block!important;
            }
            .rndDay-row-sub {
              display: flex!important;
            }
            .rndDay-row-sub:first-child > span > span {
              border-top: 1px solid #C8C8C8!important;
              box-sizing: border-box!important;
            }
            .rndDay-row-subLbl {
              position: relative!important;
              width: 176px!important;
              height: 42px!important;
              margin-left: 16px!important;
              border-right: 1px solid #C8C8C8!important;
              border-left: 1px solid #C8C8C8!important;
              border-bottom: 1px solid #C8C8C8!important;
              box-sizing: border-box!important;
            }
            .rndDay-row-subLbl > span {
              display: flex;
              align-items: center;
              padding-left: 10px;
            }
            .rndDay-row-subLbl::after {
              content: none;
            }
            .rndDay-row-subTime {
              display: flex!important;
              height: 42px!important;
            }
            .rndDay-row-subItem {
              width: 56px!important;
              height: 100%!important;
              border-bottom: 1px solid #C8C8C8!important;
              border-right: 1px solid #C8C8C8!important;
            }
            .rndDay-timeLine {
              display: none;
            }
            .rndWeek-tableContainer {
              height: auto!important;
            }
            .rndWeek-table {
              zoom: 0.5!important;
              width: auto!important;
            }
            .rndWeek-thLbl {
              width: auto!important;
              font-weight: 400!important;
              padding: 0 4px 8px!important;
              text-align: left!important;
              vertical-align: middle!important;
              width: 176px!important;
            }
            .rndWeek-th {
              padding: 0 4px 8px!important;
              width: 210px!important;
            }
            .rndWeek-th:not(:last-child) {
              border-right: 1px solid #C8C8C8!important;
              box-sizing: border-box!important;
            }
            .rndWeek-th.today > span:nth-child(3) {
              border-bottom: none;
            }
            .rndWeek-guidance {
              margin-top: 6px!important;
              border-left: none!important;
              padding-bottom: 0!important;
            }
            .rndWeek-label {
              display: table-row!important;
            }
            .rndWeek-button {
              display: none!important;
            }
            .rndWeek-table-label {
              border-top: none!important;
            }
            .rndWeek-table-labelTd {
              background: #fff!important;
              padding: 22px 8px 8px 18px!important;
            }
            .rndWeek-table-labelTd > span {
              font-size: 14px!important;
              margin-left: 20px!important;
            }
            .rndWeek-row-subLbl {
              width: 176px!important;
              min-height: 42px!important;
              padding-left: 16px!important;
            }
            .rndWeek-row-subLbl span {
              padding: 0 0 0 10px!important;
              font-size: 14px!important;
              font-weight: 400!important;
              min-height: 42px!important;
              border-right: none!important;
            }
            .rndWeek-row-subTime {
              width: auto!important;
              min-height: 42px!important;
            }
            .rndWeek-row-subItem {
              background: none!important;
              width: 210px!important;
              min-height: 42px!important;
              border-right: 1px solid #C8C8C8!important;
              border-bottom: 1px solid #C8C8C8!important;
              box-sizing: border-box!important;
            }
            .rndWeek-row-subItem:nth-child(odd) {
              background: #F3F3F3!important;
              border-left: 1px solid #C8C8C8!important;
              border-bottom: 2px solid #C8C8C8!important;
            }
            .rndWeek-row-subItem:last-child {
              border-right: 1px solid #C8C8C8!important;
              box-sizing: border-box!important;
            }
            `;
  };

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const [value, setValue] = useState(0);
  const [date, setDate] = useState(dateConversion2(new Date()) + ' 00:00:00');

  // GA108開閉用
  const [open, setOpen] = useState(false);
  // 目標（行動の新規登録画面で使用）
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績（行動の新規登録画面で使用）
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });

  // 日付のラベル
  const [dateLabel, setDateLabel] = useState('');
  const getDateLabel = (d, v) => {
    let year;
    let month;
    let day;
    let label;
    if (d !== undefined) {
      year = d.getFullYear();
      month = d.getMonth() + 1;
      day = d.getDate();
    }
    if (v === 0) {
      label = year + '年' + month + '月' + day + '日の行動予定';
    } else {
      label = year + '年' + month + '月の行動予定';
    }
    setDateLabel(label);
  };

  const [initialData, setInitialData] = useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // 案内情報を取得
    if (selectDivision.divId !== 0) {
      if (newValue === 0) {
        getSchedulesDate();
      } else {
        getSchedulesWeek();
      }
    }
    // ラベルを変更
    getDateLabel(actionScheduleDate, newValue);
  };
  // SP：案内する顧客ウィンドウ用
  const [openCustomertree, setOpenCustomertree] = useState(false); // 案内登録モーダルの表示・非表示判定用

  const dateChange = (data) => {
    setDate(data.replaceAll('-', '/'));
  };

  useEffect(() => {
    setActionScheduleDate(new Date(date));
  }, [date]);

  // ボタン 前へ
  const onClickPrev = () => {
    const d = new Date(actionScheduleDate);
    if (value === 0) {
      d.setDate(d.getDate() - 1);
    } else {
      d.setDate(d.getDate() - 7);
    }
    setActionScheduleDate(d);
  };

  // ボタン 次へ
  const onClickNext = () => {
    const d = new Date(actionScheduleDate);
    if (value === 0) {
      d.setDate(d.getDate() + 1);
    } else {
      d.setDate(d.getDate() + 7);
    }
    setActionScheduleDate(d);
  };

  // ボタン 今日
  const onClickToday = () => {
    setActionScheduleDate(new Date());
  };

  // 日付変更時
  useEffect(() => {
    // ラベルを変更
    getDateLabel(actionScheduleDate, value);
  }, [actionScheduleDate]);

  // 組織 or 日付変更時
  useEffect(() => {
    // 案内情報を再取得
    if (selectDivision.divId !== 0) {
      if (value === 0) {
        getSchedulesDate();
      } else {
        getSchedulesWeek();
      }
    }
  }, [selectDivision.divId, actionScheduleDate]);

  // 検索
  const [openSearch, setOpenSearch] = useState(false);
  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  const [infoOpen, setInfoOpen] = useState(false);

  // 反響ツリー選択組織
  const [selectDivisionAct, setSelectDivisionAct] = useState({ divId: 0, divisionName: '' });
  // 反響ツリー選択顧客
  const [selectCustomer, setSelectCustomer] = useState([]);

  // Dialog形式かどうか
  const [isModal] = useState(true);
  // 検索ボックスの表示/非表示
  const [searchForm] = useState(true);

  // 提出
  const handleClick1 = () => {
  };
  const handleClick2 = () => {
    functions.schedulesSubmissionApiFunction(
      {
        submitAt: dateConversion2(actionScheduleDate),
        divisionId: selectDivision.divId,
      },
    );
  };
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '提出しますか？',
      msgList: [],
      buttons: [
        {
          label: 'CANCEL',
          set: handleClick1,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: handleClick2,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };
  const handleSubmission = () => {
    if (selectDivision.divId !== 0) {
      confirmOpen();
    } else {
      dispatch(changeAlertMessage({ msgList: ['組織を選択してください。'] }));
    }
  };

  if (isSP) {
    contents = (
      <Grid className={classes.root}>
        <Drawer
          anchor="right"
          open={openSearch}
          onClose={handleCloseSearch}
          className={classes.drawer}
        >
          <Grid className={`${baseClasses.title2} ${classes.drawerHeader}`}>
            検索
            <Button
              className={baseClasses.closeButton}
              onClick={handleCloseSearch}
            />
          </Grid>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className={classes.tabs}
            >
              <Tab label="1日" {...a11yProps(0)} className={classes.tab} onClick={handleCloseSearch} />
              <Tab label="週" {...a11yProps(1)} className={classes.tab} onClick={handleCloseSearch} />
            </Tabs>
            <Grid className={classes.iconBox}>
              <CalendarViewDayOutlinedIcon />
              <Icon>calendar_view_week</Icon>
            </Grid>
          </AppBar>
          <Search handleCloseSearch={handleCloseSearch} />
        </Drawer>
        <Grid className={classes.header}>
          <Grid className={classes.headerMonth}>
            <Typography className={`${baseClasses.title1} ${classes.headerMonthTxt}`}>
              {new Date(date).getMonth() + 1}月
            </Typography>
            <Calendar
              data={{
                type: 'date',
                label: '',
                state: date,
                setState: dateChange,
              }}
            />
          </Grid>
          <Button
            className={classes.buttonName}
            onClick={() => {
              setIsOpenTree(true);
            }}
          >
            <ContactsIcon />
            <Grid className={classes.nameBox}>
              {targetDivisionTree && (
                <Typography className={classes.groupName}>
                  {targetDivisionTree.label}
                </Typography>
              )}
            </Grid>
          </Button>
          <Button className={classes.buttonTune} onClick={handleOpenSearch}>
            <TuneIcon />
          </Button>
        </Grid>
        {/* 日 */}
        <TabPanel value={value} index={0} className={classes.tabPanel}>
          <Day
            date={date}
            setOpenCustomertree={setOpenCustomertree}
          />
        </TabPanel>
        {/* 週 */}
        <TabPanel value={value} index={1} className={classes.tabPanel2}>
          <Week
            setOpenCustomertree={setOpenCustomertree}
          />
        </TabPanel>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <Typography className={`${baseClasses.title2} ${classes.ttl}`}>
            {dateLabel}
          </Typography>
          <Grid className={classes.pagerBox}>
            <Button className={classes.pagerButton} onClick={onClickPrev}>
              <ArrowBackIosIcon className={classes.pagerIcon} />
            </Button>
            <Button className={classes.pagerButton} onClick={onClickNext}>
              <ArrowForwardIosIcon className={classes.pagerIcon} />
            </Button>
          </Grid>
          <Grid className={classes.buttonWrap}>
            <Button className={`${classes.button1} ${baseClasses.buttonsPrimary}`} onClick={() => { setOpen(true); }}>
              新規登録
            </Button>
            {/* [GA108]画面 */}
            {open && (
              <ActRegistWindow
                open={open}
                onClose={() => { setOpen(false); }}
                setReacquisitionFlg={setReacquisitionFlg}
                values={initialData}
                setUpdateData={setInitialData}
                actRegistFlag={Boolean(true)}
                editableFlag={Boolean(false)}
                targetObj={targetObj}
                setTargetObj={setTargetObj}
                archieveObj={archieveObj}
                setArchieveObj={setArchieveObj}
                selectDivision={selectDivisionAct}
                setSelectDivision={setSelectDivisionAct}
                setOpenCustomertree={setOpenCustomertree}
                isSource={Boolean(false)}
                createUpdateFunc={functions.schedulesPostApiFunction}
                date={actionScheduleDate}
              />
            )}
            <Button
              className={`${classes.button2} ${baseClasses.buttonsPrimary}`}
              onClick={handleSubmission}
              disabled={!roleIds.includes(34)}
            >
              提出
            </Button>
          </Grid>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className={classes.tabs}
            >
              <Tab label="日" {...a11yProps(0)} className={classes.tab} />
              <Tab label="週" {...a11yProps(1)} className={classes.tab} />
            </Tabs>
          </AppBar>
          <Button className={`${classes.button3} ${baseClasses.buttonsSecondary}`} onClick={onClickToday}>
            今日
          </Button>
          <Grid className={classes.buttonWrap}>
            <ReactToPrint
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => (
                <Button className={`${classes.button4} ${baseClasses.buttonsPrimary}`}>
                  印刷
                </Button>
              )}
              content={() => listTableRef.current}
              documentTitle={value === 0 ? '行動予定一覧（日）' : '行動予定一覧（週）'}
            />
          </Grid>
        </Grid>
        <Grid innerRef={listTableRef}>
          <style type="text/css" media="print">{getPrintPageStyle()}</style>
          {/* 日 */}
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <Day
              date={date}
              setOpenCustomertree={setOpenCustomertree}
            />
          </TabPanel>
          {/* 週 */}
          <TabPanel value={value} index={1} className={classes.tabPanel2}>
            <Week
              setOpenCustomertree={setOpenCustomertree}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      {/* 顧客選択ウィンドウ */}
      {openCustomertree && (
        <CustomerTree
          isOpen={openCustomertree}
          setIsOpen={setOpenCustomertree}
          isModal={isModal}
          setSelectCustomer={setSelectCustomer}
          defaultUserId={userId}
          displayCustomer
          targetFlgList={targetFlgList}
          searchForm={searchForm}
          initialHeader={{ typeCode: 1, unitCode: 3 }} // ランク・センター
          setHandleComfirm={() => { setInfoOpen(true); }}
          titleHeader={isSP ? '顧客選択' : '顧客選択ウィンドウ'}
        />
      )}
      {/* 接触・案内登録ウィンドウ */}
      {infoOpen && (
        <InfoRegisterOnlyPost
          open={infoOpen}
          onOpen={() => { setInfoOpen(true); }}
          onClose={() => {
            setInfoOpen(false);
            setOpen(false);
          }}
          setClickInfoRegister={() => { setOpen(true); }}
          guidanceId={null}
          customer={selectCustomer[0]}
          reacquisition={value === 0 ? getSchedulesDate : getSchedulesWeek}
        />
      )}
    </Grid>
  );
}
