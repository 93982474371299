import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AfterCallWindowSpComponent from '../../../components/mail/sp/afterCallWindowComponent';
import customerUpdateCallApi from '../../../apis/customer/customerUpdateApi';
import libraryGetInterruptedApi from '../../../apis/library/libraryGetInterruptedApi';
import postSendMails from '../../../apis/sendmail/sendMailsPostApi';
import sendmailTestApi from '../../../apis/sendmail/sendmailTestApi';
import smsPostApi from '../../../apis/sms/postSmsApi';
import store from '../../../store';
import { setLastUpdateSignature } from '../../../store/sendmail/sendmailLastUpdateSignatureSlice';
import { isOpen } from '../../../store/common/apiMessageSlice';
import { getFromChoice } from '../../../commonFunction/mail';
import { libraryGetSigniture } from '../../../store/library/libraryGetSignitureSlice';
import libraryGetSignitureApi from '../../../apis/library/libraryGetSignitureApi';
import { MAIL_FROM_SELF } from '../../../constants';
import sendmailGetDefaultApi from '../../../apis/sendmail/sendmailGetDefaultApi';

export default function AfterCallWindowContainer(props) {
  const {
    user,
    open,
    setOpen,
    onClose,
  } = props;
  const { responseHeader } = store.getState();
  const dispatch = useDispatch();
  // 最後に選ばれた署名を選択する / https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3790#comment-172404437
  const lastUpdateSignature = useSelector(
    (state) => state.sendmailLastUpdateSignature.lastUpdateSignature,
  );
  // 署名一覧を格納
  const signature = useSelector(
    (state) => state.libraryGetSigniture.libraryGetSigniture,
  );
  // info/sign/libraryはAPIで取得し、オブジェクト化するためこちらに記載
  const [mailParam, setMailParam] = useState({});
  const [mailSetting, setMailSetting] = useState({
    type: {
      constants: {
        menus: {
          0: 'SMS',
          // 1: 'メール',
        },
      },
    },
    kind: {
      // eslint-disable-next-line max-len
      // ここの文言は/libraries/management/{libraryId}のlibraryNameと対応する（本番ではlibraryNameから引用するが、WIPの状態なので定数参照）
      constants: {
        menus: {},
      },
    },
    kindSms: {
      // eslint-disable-next-line max-len
      // ここの文言は/libraries/management/{libraryId}のlibraryNameと対応する（本番ではlibraryNameから引用するが、WIPの状態なので定数参照）
      constants: {
        menus: {},
      },
    },
    from: {
      constants: {
        // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4066#comment-183540960
        menus: getFromChoice(responseHeader.roleIds),
        title: 'From',
      },
    },
    sign: {
      constants: {
        menus: {
          0: '署名１',
          1: '署名２',
        },
        title: 'sign',
      },
    },
    info: {
      constants: {
        menus: {
          1: '@INFO',
          2: 'オープンハウス事務局',
        },
        title: 'info',
      },
    },
  });
  const [mailMeta, setMailMeta] = useState({});
  const [smsMeta, setSmsMeta] = useState({});
  const [signMeta, setSignMeta] = useState({});
  const [meta, setMeta] = useState({
    type: 0,
    from: 1,
    info: 0,
    kind: 0,
    kindSms: 0,
    sign: 0,
    title: '',
    to: user.mail1,
    tocheck: {
      mail1: user.isMailSend1 === 1,
      mail2: user.isMailSend2 === 1,
      mail3: user.isMailSend3 === 1,
      mail4: user.isMailSend4 === 1,
    },
    sms: user.phoneNumber1,
    smscheck: { phoneNumber1: true, phoneNumber2: false, phoneNumber3: false, phoneNumber4: false },
    content: '',
  });
  const [editorState, setEditorState] = useState('');

  // タグがない場合に補完（エディタに入れられないため）
  const transMailBody = (txt) => {
    let content = txt;
    const regex = /(<([^>]+)>)/gi;
    const found = content?.match(regex);
    // エディターへ選択したライブラリの情報を渡す
    if (!found) {
      content = `<p>${content}</p>`;
    }
    return content;
  };

  useEffect(() => {
    setMailSetting({
      ...mailSetting,
      kind: { constants: { menus: mailMeta } },
      kindSms: { constants: { menus: smsMeta } },
      sign: { constants: { menus: signMeta }, title: 'sign' },
    });
  }, [mailMeta, smsMeta, signMeta]);

  // APIでとってきたライブラリをもっておく
  const [mailLibrary, setMailLibrary] = useState({});
  const [smsLibrary, setSmsLibrary] = useState({});
  const [signLibrary, setSignLibrary] = useState({});
  const [libraries, setLibraries] = useState({
    mail: mailLibrary,
    sms: smsLibrary,
    sign: signLibrary,
  });
  useEffect(() => {
    setMeta({
      ...meta,
      kind: Object.keys(mailLibrary)[0],
      kindSms: Object.keys(smsLibrary)[0],
    });
    setLibraries({
      mail: mailLibrary,
      sms: smsLibrary,
      sign: signLibrary,
    });
  }, [mailLibrary, smsLibrary, signLibrary]);

  // 最後に選択した署名を選択
  const selectUpdateSignature = (signList, libId = 0) => {
    if (!lastUpdateSignature
      || !Object.keys(signList).map(id => Number(id)).includes(lastUpdateSignature)) {
      // lastUpdateSignatureが初期値の0の場合
      // またはlastUpdateSignatureが新しく取得したsignListに含まれていない場合
      dispatch(setLastUpdateSignature(libId));
    } else {
      libId = lastUpdateSignature;
    }
    // 署名を選択
    setMailParam({
      ...mailParam,
      signatureLibraryId: libId,
      signature: signature.libraries.find(s => { return s.libraryId === meta.sign; })?.mailBody,
    });
    setMeta({ ...meta, sign: libId });
  };

  // メール送信先選択
  const updateAddress = async (customerId, params) => {
    await customerUpdateCallApi(customerId, params)
      .then(() => { })
      .catch(err => console.error(err));
  };

  // 署名取得
  const getSignature = async (params) => {
    if (meta.from === MAIL_FROM_SELF) {
      const thenFunc = (data) => {
        const copyData = structuredClone(data);
        const addObj = {};
        const librariesList = {};
        const libs = copyData.libraries.sort((a, b) => a.libraryId - b.libraryId);
        const firstLibId = libs?.length ? libs[0].libraryId : 0;
        // 先頭から3件のみ
        for (let i = 0; i < 3 && i < libs.length; i += 1) {
          const item = libs[i];
          addObj[item.libraryId] = item.libraryName;
          librariesList[item.libraryId] = item;
        }
        setSignLibrary(librariesList);
        setSignMeta(addObj);
        selectUpdateSignature(librariesList, firstLibId);
        if (!signature?.libraries?.[0]?.libraryId) dispatch(libraryGetSigniture(copyData));
      };

      // 署名を選択
      if (signature?.libraries?.[0]?.libraryId) {
        thenFunc(signature);
      } else {
        await libraryGetSignitureApi()
          .then((res) => thenFunc(res.data))
          .catch(err => console.error(err));
      }
    } else {
      await sendmailGetDefaultApi(params)
        .then((res) => {
          const addObj = {};
          const librariesList = {};
          const libs = res.data;
          const firstLibId = libs?.length ? libs[0].libraryId : 0;
          // 先頭から3件のみ
          for (let i = 0; i < 3 && i < libs.length; i += 1) {
            const item = libs[i];
            addObj[item.libraryId] = item.libraryName;
            librariesList[item.libraryId] = item;
          }
          setSignLibrary(librariesList);
          setSignMeta(addObj);
          selectUpdateSignature(librariesList, firstLibId);
        })
        .catch(err => console.error(err));
    }
  };

  // 不通時メールライブラリ取得
  const getLibraryMail = async (userId) => {
    await libraryGetInterruptedApi(userId, 1)
      .then(async (res) => {
        const addObj = {};
        const librariesList = {};
        if (res.data.total !== 0) {
          const libs = res.data.libraries;
          // 先頭から3件のみ
          for (let i = 0; i < 3 && i < libs.length; i += 1) {
            const item = libs[i];
            addObj[item.libraryId] = item.libraryName;
            librariesList[item.libraryId] = item;
          }
        }
        setMailLibrary(librariesList);
        setMailMeta(addObj);
      })
      .catch(err => console.error(err));
  };

  // 不通時SMSライブラリ取得
  const getLibrarySms = async (userId) => {
    await libraryGetInterruptedApi(userId, 0)
      .then(async (res) => {
        const addObj = {};
        const librariesList = {};
        if (res.data.total !== 0) {
          res.data.libraries.map(l => {
            addObj[l.libraryId] = l.libraryName;
            librariesList[l.libraryId] = l;
            return false;
          });
        }
        setSmsLibrary(librariesList);
        setSmsMeta(addObj);
      })
      .catch(err => console.error(err));
  };
  // テストメール送信
  const sendTestMail = (param) => {
    let result = false;
    sendmailTestApi(param)
      .then(() => {
        result = true;
      })
      .catch(err => {
        console.error(err);
        result = false;
      });
    return result;
  };

  // SMS送信
  const sendSms = async (param) => {
    await smsPostApi(param)
      .then(() => { })
      .catch(err => console.error(err));
  };

  // メール送信
  const sendMail = (param) => {
    let result = '';
    postSendMails(param, false)
      .then(async (res) => {
        result = res.message;
        const { message, description } = res;
        const msg = { message, description, status: 'success' };
        dispatch(isOpen(msg));
      })
      .catch(err => {
        console.error({ err });
      });
    return result;
  };

  // From変更時に署名を読み込む(初期化時も実行)
  useEffect(async () => {
    // 署名リセット
    setMeta({
      ...meta,
      sign: 0,
    });
    if (meta.from === 3) {
      // infoの時
      dispatch(setLastUpdateSignature(0));
      return;
    }
    // リクエスト
    await getSignature({
      mailFromCode: meta.from,
      customerId: user.customerId,
    });
  }, [meta.from]);

  const setting = {
    kind: {
      label: '種類',
      state: meta.kind,
      setState: (e) => {
        const value = Number(e.target ? e.target.value : e);
        if (!libraries.mail[value]) {
          return;
        }
        setMailParam({ ...mailParam, libraryId: value });
        setEditorState(libraries.mail[value].mailBody);
        setMeta({
          ...meta,
          kind: value,
          title: libraries.mail[value].mailSubject,
          content: libraries.mail[value].mailBody,
        });
      },
      constants: mailSetting.kind.constants,
      style: { width: '50%' },
    },
    kindSms: {
      label: '種類',
      state: meta.kindSms,
      setState: (e) => {
        const value = Number(e.target ? e.target.value : e);
        if (!libraries.sms[value]) {
          return;
        }
        setEditorState(libraries.sms[value].mailBody);
        setMeta({
          ...meta,
          kindSms: value,
          content: libraries.sms[value].mailBody,
        });
      },
      constants: mailSetting.kindSms.constants,
      style: { width: '74.5%' },
    },
    from: {
      label: 'From',
      state: meta.from,
      setState: (e) => {
        const value = Number(e.target ? e.target.value : e);
        return setMeta({ ...meta, from: value });
      },
      constants: mailSetting.from.constants,
      style: { width: '24.5%' },
    },
    info: {
      label: 'info',
      state: meta.info,
      setState: (e) => {
        const value = Number(e.target ? e.target.value : e);
        setMailParam({ ...mailParam, infoCode: value });
        return setMeta({ ...meta, info: value });
      },
      constants: mailSetting.info.constants,
      style: { width: '24.5%' },
    },
    sign: {
      label: '署名',
      state: meta.sign,
      setState: (e) => {
        const value = Number(e.target ? e.target.value : e);
        dispatch(setLastUpdateSignature(value));
        setMailParam({ ...mailParam, signatureLibraryId: value });
        return setMeta({ ...meta, sign: value });
      },
      constants: mailSetting.sign.constants,
      style: { width: '24.5%' },
    },
    to: {
      label: 'To',
      require: true,
      check: meta.tocheck,
      setCheck: (e) => { return setMeta({ ...meta, toCheck: e }); },
      list: meta.to,
      setList: (e) => { return setMeta({ ...meta, to: e }); },
    },
    tosms: {
      label: 'To',
      require: true,
      style: { width: '100%' },
      check: meta.smscheck,
      setCheck: (e) => { return setMeta({ ...meta, smsCheck: e }); },
      list: meta.sms,
      setList: (e) => { return setMeta({ ...meta, sms: e }); },
    },
    title: {
      label: '件名',
      require: true,
      state: meta.title,
      setState: (e) => {
        setMailParam({ ...mailParam, mailSubject: e });
        return setMeta({ ...meta, title: e });
      },
    },
    content: {
      state: meta.content,
      setState: (e) => { return setMeta({ ...meta, content: e }); },
      wrapper: 'small',
      toolbar: true,
    },
    contentSms: {
      state: meta.content,
      setState: (e) => { return setMeta({ ...meta, content: e }); },
      wrapper: 'full',
      toolbar: false,
    },
  };
  const afterCallFunctions = {
    updateAddress,
    getLibrarySms,
    getLibraryMail,
    getSignature,
    sendTestMail,
    transMailBody,
    sendSms,
    sendMail,
  };

  return (
    <AfterCallWindowSpComponent
      user={user}
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      setting={setting}
      meta={meta}
      setMeta={setMeta}
      setMailSetting={setMailSetting}
      afterCallFunctions={afterCallFunctions}
      responseHeader={responseHeader}
      libraries={libraries}
      mailParam={mailParam}
      setMailParam={setMailParam}
      editorState={editorState}
      setEditorState={setEditorState}
    />
  );
}
