import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DivisionDate from '../../../components/division/divisionDate/divisionDate';
import allSettingApi from '../../../apis/divisionMaster/getAllSettingApi';
import putSettingApi from '../../../apis/divisionMaster/putSettingApi';
import { getAllSettingApi } from '../../../store/divisionMaster/getAllSettingSlice';
import { DATE_TIME_GROUPS } from '../../../constants/userRoleGroup';

export default function DivisionDateContainer() {
  const dispatch = useDispatch();

  const allSettingData = useSelector((state) => state.getAllSetting.getAllSetting);

  // レスポンスヘッダから、ログイン中ユーザに紐づくロールIDを取得
  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleGroupId } = responseHeader;

  // 【divisionMaster011】 課編成全基本情報取得
  // /division-masters/all-setting
  const getAllSettingFunction = useCallback(async () => {
    await allSettingApi()
      .then((res) => {
        dispatch(getAllSettingApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 【divisionMaster003】課編成基本情報更新
  // /division-masters/setting
  const [param, setParam] = useState({ divisionMasterList: [] });
  const putSettingFunction = useCallback((params) => {
    putSettingApi(params);
  }, []);

  useEffect(() => {
    if (allSettingData.divisionMasterList.length > 0) {
      const allSettingDataInitial = allSettingData.divisionMasterList.map((item) => ({
        areaCode: item.areaCode,
        editLimitAt: item.editLimitAt.replace(/-/g, '/'),
      }));
      setParam({ divisionMasterList: allSettingDataInitial });
    }
  }, [allSettingData]);

  const functions = {
    getAllSettingFunction,
    putSettingFunction,
  };

  const data = {
    divisionMasterList: allSettingData.divisionMasterList,
    param,
    setParam,
    roleGroupId,
    hasPerMission: DATE_TIME_GROUPS.includes(roleGroupId),
  };

  return (
    <DivisionDate
      functions={functions}
      data={data}
    />
  );
}
