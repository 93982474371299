import { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import {
  SFA_SYSTEM_ID,
  KYMALIS_SYSTEM_ID,
  DAIJIN_SYSTEM_ID,
  BUSINESS_KODATE,
} from '../../../constants/users';
import modifyObject from '../../../commonFunction/modifyObject';
import { IS_SALES, CUSTOMER_INTRODUCTION_STATUS1 } from '../../../constants/index';
import { ROLE_GROUP_MAPPER, DAIJIN_ROLE_GROUP_MAPPER, KEYMALIS_ROLE_GROUP_MAPPER } from '../../../constants/userRoleGroup';
import { getNowDate } from '../../../commonFunction';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 8,
    position: 'static',
    // width: '266.33px',
    width: '33%',
    left: '0px',
    top: '0px',
    background: '#FFFFFF',
    border: '1px solid #C8C8C8',
    boxSizing: 'border-box',
    margin: '0.5%',
  },
  customWidth: {
    // width: '30%',
    width: 'calc( 97% / 3 )',
  },
  customWidthFull: {
    width: '40%',
  },
  customTitle3: {
    position: 'static',
    width: '100%',
    height: '20px',
    left: '8px',
    top: '8px',
    display: 'flex',
    alignItems: 'center',
    /* OH-Black */
    color: '#333333',
    flex: 'none',
    order: 0,
    'flex-grow': 0,
    margin: '20px 4px 0',
  },
  customButtonSpan: {
    color: '#ffffff',
    position: 'static',
    left: '32%',
    right: '16%',
    top: '13.79%',
    bottom: '13.79%',
  },
  userCustomButton: {
    padding: '4px 16px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '0px 16px',
  },
}));

function InputFormAuthority(props) {
  const { getComponent, state, setState } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [grades, setGrades] = useState('');
  const [isLasta, setIsLasta] = useState('');
  const [localDate, setLocalDate] = useState('');

  useEffect(() => {
    setIsLasta(state.isLastaAutoInputName);
    const targetState = state.userBusinesses[
      state.userBusinesses.findIndex((v) => v.businessCode === BUSINESS_KODATE)
    ];
    if (targetState) {
      setGrades(targetState.isSalesTarget);
      setLocalDate(targetState.assignAt);
    } else {
      setGrades(0);
      setLocalDate('');
    }
    if (!state?.userId) setLocalDate(getNowDate('YYYY/MM/DD'));
  }, [state]);

  // 営業成績のevent handler
  const handleChangeBusinesses = (e) => {
    let newBusinessObj = Object.assign(
      {},
      state.userBusinesses[
        state.userBusinesses.findIndex(
          (v) => v.businessCode === BUSINESS_KODATE,
        )
      ],
    );
    if (!Object.keys(newBusinessObj).length) {
      newBusinessObj = {
        businessCode: BUSINESS_KODATE,
        assignAt: localDate,
        isSalesTarget: Number(e.target.value),
      };
    } else {
      newBusinessObj.isSalesTarget = Number(e.target.value);
    }
    setState({ ...state, userBusinesses: Array(newBusinessObj) });
    setGrades(Number(e.target.value));
  };

  // Lasta情報変更
  const changeLasta = (e) => {
    setState({ ...state, isLastaAutoInputName: Number(e.target.value) });
    setIsLasta(Number(e.target.value));
  };

  // SFA, キマリス, ダイジンのselect event handler
  const handleChange = (e, initial) => {
    const val = Number(e.target.value);
    const newStateArray = [];
    for (let i = 0; i < state.systems.length; i += 1) {
      newStateArray.push(Object.assign({}, state.systems[i]));
    }
    if (newStateArray.find((v) => v.systemCode === initial.systemCode)) {
      newStateArray[
        newStateArray.findIndex((v) => v.systemCode === initial.systemCode)
      ].roleGroupId = val;
    } else {
      newStateArray.push({
        systemCode: initial.systemCode,
        roleGroupId: val,
      });
    }
    const newState = newStateArray.filter((v) => v.roleGroupId > 0);
    setState({ ...state, systems: newState });
  };

  // 配属日のevent handler
  const setDate = (date) => {
    let newBusinessObj = Object.assign(
      {},
      state.userBusinesses[
        state.userBusinesses.findIndex(
          (v) => v.businessCode === BUSINESS_KODATE,
        )
      ],
    );
    if (!Object.keys(newBusinessObj).length) {
      newBusinessObj = {
        businessCode: BUSINESS_KODATE,
        assignAt: date,
        isSalesTarget: grades,
      };
    } else {
      newBusinessObj.assignAt = date;
    }
    setState({ ...state, userBusinesses: Array(newBusinessObj) });
    setLocalDate(date);
  };

  const field = [
    {
      title: 'SFA',
      required: false,
      key: 'sfa',
      systemCode: SFA_SYSTEM_ID,
      type: 'flg',
      color: '#e3f2fd',
      state: state.systems.find((v) => v.systemCode === SFA_SYSTEM_ID)
        ? state.systems[
          state.systems.findIndex((v) => v.systemCode === SFA_SYSTEM_ID)
        ].roleGroupId
        : 0,
      set: handleChange,
      obj: modifyObject(ROLE_GROUP_MAPPER, 'SFA'),
    },
    {
      title: 'キマリス',
      required: false,
      systemCode: KYMALIS_SYSTEM_ID,
      key: 'kymalis',
      type: 'flg',
      color: '#e8f5e9',
      state: state.systems.find((v) => v.systemCode === KYMALIS_SYSTEM_ID)
        ? state.systems[
          state.systems.findIndex((v) => v.systemCode === KYMALIS_SYSTEM_ID)
        ].roleGroupId
        : 0,
      set: handleChange,
      obj: modifyObject(KEYMALIS_ROLE_GROUP_MAPPER, 'キマリス'),
    },
    {
      title: 'ダイジン',
      required: false,
      systemCode: DAIJIN_SYSTEM_ID,
      key: 'daijin',
      type: 'flg',
      color: '#fff3e0',
      state: state.systems.find((v) => v.systemCode === DAIJIN_SYSTEM_ID)
        ? state.systems[
          state.systems.findIndex((v) => v.systemCode === DAIJIN_SYSTEM_ID)
        ].roleGroupId
        : 0,
      set: handleChange,
      obj: modifyObject(DAIJIN_ROLE_GROUP_MAPPER, 'ダイジン'),
    },
  ];

  const gradesObjList = [
    {
      title: '営業成績',
      required: false,
      key: 'isSalesTarget',
      type: 'flg',
      state: grades,
      set: handleChangeBusinesses,
      obj: modifyObject(IS_SALES, '営業成績'),
      parentWidth: `${97 / 3}%`,
      width: '100%',
    },
    {
      title: '配属日',
      required: false,
      key: 'assignAt',
      type: 'date',
      state: localDate,
      setDate,
      parentWidth: `${97 / 3}%`,
      width: '100%',
    },
  ];

  const lastaObj = {
    title: 'Lasta担当者氏名自動入力',
    required: false,
    key: 'isLastaAutoInputName',
    type: 'flg',
    state: isLasta,
    set: changeLasta,
    obj: modifyObject(CUSTOMER_INTRODUCTION_STATUS1, 'Lasta担当者氏名自動入力'),
    parentWidth: `${97 / 3}%`,
    width: '100%',
  };

  return (
    <>
      <Typography
        className={`${baseClasses.title3} ${classes.customTitle3}`}
      >
        戸建情報
      </Typography>
      {field.map((data) => (
        <Grid
          item
          key={data.key}
          className={`${classes.root} ${classes.customHeight} ${classes.customWidth} ${baseClasses.inputWrap}`}
        >
          {getComponent(data)}
        </Grid>
      ))}
      <Grid container>
        {gradesObjList.map((gradesObj) => (
          <Grid
            item
            key={gradesObj.key}
            style={{ width: gradesObj.parentWidth }}
            className={`${classes.root} ${classes.customHeight} ${classes.customWidthFull} ${baseClasses.inputWrap}`}
          >
            {getComponent(gradesObj)}
          </Grid>
        ))}
        <Grid
          item
          key="lasta-assign-user-key"
          style={{ width: lastaObj.parentWidth }}
          className={`${classes.root} ${classes.customHeight} ${classes.customWidthFull} ${baseClasses.inputWrap}`}
        >
          {getComponent(lastaObj)}
        </Grid>
      </Grid>
    </>
  );
}
export default InputFormAuthority;
