import client from '../httpClient';

/**
 * 【webres015】未処理反響情報取得
 * http://10.22.2.48:8080/docs/#/responses/get-responses-responseId
 * @module responseGetResponseByResponseIdApi
 * @param {*} responseId - 必須: responseId
 * @returns {Promise<{responses: {}}>} axios get method
 */
const responseGetResponseByResponseIdApi = async (responseId) => {
  const res = await client.get(`/responses/${responseId}`);
  return res.data;
};

export default responseGetResponseByResponseIdApi;
