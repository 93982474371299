import { useRef } from 'react';
import { isObject } from '../commonFunction';

export function updateObject(obj, key, value) {
  if (isObject(key)) {
    Object.keys(key).forEach(k => {
      obj[k] = key[k];
    });
  } else {
    obj[key] = value;
  }
}

export function useObjectUpdate(originalData = {}, keys = []) {
  // 必要な情報を取得する、
  // keysが指定されない場合はoriginalDataの全ての情報をコピーする
  const newOriginalData = keys.length ? keys.reduce((prev, key) => {
    prev[key] = originalData[key];
    return prev;
  }, {}) : originalData;

  // copy
  const newOriginalStr = JSON.stringify(newOriginalData);
  const currentRef = useRef(JSON.parse(newOriginalStr));
  const originalRef = useRef(JSON.parse(newOriginalStr));

  const updateCurrent = (key, value) => {
    updateObject(currentRef.current, key, value);
  };

  const updateOriginal = (key, value) => {
    updateObject(originalRef.current, key, value);
  };

  const updateAll = (key, value) => {
    updateCurrent(key, value);
    updateOriginal(key, value);
  };

  return {
    currentRef,
    originalRef,
    updateCurrent,
    updateOriginal,
    updateAll,
  };
}
