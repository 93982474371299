import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import SpEditDialog from './spEditDialog';
import { convertForm1 } from '../../../commonFunction/convertors';
import { validateFormString1, validateFormTelNum } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';
import { TEL_STATUS_CODE } from '../../../constants';
import NormalTooltip from '../../styles/tooltip';

const useStyles = makeStyles({
  root: {
    marginBottom: 6,
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '4px 8px',
  },
  telItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:first-child': {
      background: 'none',
    },
  },
  telItem2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  lbl: {
    width: '48%',
    marginBottom: 4,
  },
  lbl2: {
    width: '25%',
    marginRight: 8,
  },
  textField: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '48%',
    },
    '& input': {
      fontFamily: 'Roboto',
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  textField1: {
    '& input': {
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 16,
        zoom: 0.81,
        lineHeight: 1.6,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: 1.2,
      },
    },
  },
  textField2: {
    '& input': {
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
    },
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&:nth-child(even)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
});

const menus = Object.keys(TEL_STATUS_CODE);

function CustomerTel(props) {
  const { input } = props;
  const {
    current,
    original,
    update,
    isSp,
    editTargetRef,
  } = useUpdate(input.customer, [
    'customerId',
    'phoneNumber1',
    'phoneNumber2',
    'phoneNumber3',
    'phoneNumberMemo1',
    'phoneNumberMemo2',
    'phoneNumberMemo3',
    'customerStatusCode',
    'telStatusCode',
  ]);

  const classes = useStyles();
  const baseClasses = commonStyles();

  let contents;

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    const { name, value } = e.target;
    if (original[name] === value) return;
    // 更新処理
    update({ [name]: value });
  };

  const onBlurPhoneNumber = (e) => {
    const { name, value } = e.target;
    if (original[name] === value) return;
    // 更新処理
    update({ [name]: value });
  };

  // モーダル用
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen1 = (e, target) => {
    editTargetRef.current = target;
    setOpen1(true);
  };
  const handleOpen2 = (e, target) => {
    editTargetRef.current = target;
    setOpen2(true);
  };

  const onSpSubmit = (values) => {
    const { keys } = editTargetRef.current;
    const newData = Array.isArray(keys) ? keys.reduce((prev, key) => {
      prev[key] = values.shift();
      return prev;
    }, {}) : { [keys]: values };
    if (isChanged(newData, original)) {
      update(newData, true);
    }
  };

  const getTextField = (name, type) => {
    return (
      <TextBaseField
        type={type}
        defaultValue={current[name]}
        className={`${classes.textField} ${classes[type === 'number' ? 'textField1' : 'textField2']}`}
        name={name}
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormString1({ maxLengthInt: 10 })}
        convertor={convertForm1}
      />
    );
  };

  const getTextFieldPhoneNumber = (name) => {
    return (
      <TextBaseField
        defaultValue={current[name]}
        className={`${classes.textField} ${classes.textField1}`}
        name={name}
        onBlur={onBlurPhoneNumber}
        placeholder="-"
        type="tel"
        validator={validateFormTelNum()}
      />
    );
  };

  const getTel1 = () => {
    return getTextFieldPhoneNumber('phoneNumber1');
  };
  const getTel2 = () => {
    return getTextFieldPhoneNumber('phoneNumber2');
  };
  const getTel3 = () => {
    return getTextFieldPhoneNumber('phoneNumber3');
  };
  const getTel1Memo = () => {
    return getTextField('phoneNumberMemo1', 'textField1');
  };
  const getTel2Memo = () => {
    return getTextField('phoneNumberMemo2', 'textField1');
  };
  const getTel3Memo = () => {
    return getTextField('phoneNumberMemo3', 'textField1');
  };

  // 電話ステータス
  const updateTelStatusCode = (value) => {
    const newData = { telStatusCode: Number(value) };
    if (original.telStatusCode === newData.telStatusCode) return;
    update(newData);
  };

  const selectChange = (e) => {
    const { value } = e.target;
    updateTelStatusCode(value);
  };

  const customerSelectList = {
    telStatusCodeItem: {
      type: 'select',
      obj: {
        menus: TEL_STATUS_CODE,
      },
      state: input.customerInfo.telStatusCode,
      name: 'telStatusCode',
      change: selectChange,
    },
  };
  /**
   * ツールチップ用のテキスト
   * @param {string[]} lines チップ用のテキストの配列
   * @returns {ReactNode}
   */
  const createTips = useCallback((lines) => {
    return (
      <Grid>
        {lines.map((line, i) => (
          <Typography key={String(i)} className={baseClasses.small}>
            {line}
          </Typography>
        ))}
      </Grid>
    );
  }, [baseClasses]);

  const lines = [
    '電話NG：お客様から電話を明確に電話を拒否された場合に設定',
    '誤登録：電話番号が間違っている場合に設定',
    '制限あり：お客様から時間帯や曜日など架電条件が提示された場合に設定',
  ];
  const getTelStatusCodePc = () => {
    return (
      <Grid className={classes.telItem2}>
        <NormalTooltip title={createTips(lines)}>
          <Typography className={`${baseClasses.title6} ${classes.lbl2}`}>
            電話許可範囲
          </Typography>
        </NormalTooltip>
        <Grid className={classes.mailSelect}>
          {input.component(customerSelectList.telStatusCodeItem)}
        </Grid>
      </Grid>
    );
  };

  const getTelStatusCodeSp = () => {
    return (
      <Select
        className={classes.residency}
        value={input.customerInfo.telStatusCode}
        defaultValue={input.customerInfo.telStatusCode !== undefined
          ? input.customerInfo.telStatusCode : 99}
      >
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                updateTelStatusCode(data);
              }}
            >
              {TEL_STATUS_CODE[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'TEL1',
      val: current.phoneNumber1,
      keys: 'phoneNumber1',
      click: handleOpen2,
      fontSize: '20px',
      opn: false,
      content: getTel1,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足1',
      val: current.phoneNumberMemo1,
      keys: 'phoneNumberMemo1',
      click: handleOpen1,
      opn: false,
      content: getTel1Memo,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'TEL2',
      val: current.phoneNumber2,
      keys: 'phoneNumber2',
      click: handleOpen2,
      fontSize: '20px',
      opn: false,
      content: getTel2,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足2',
      val: current.phoneNumberMemo2,
      keys: 'phoneNumberMemo2',
      click: handleOpen1,
      opn: false,
      content: getTel2Memo,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'TEL3',
      val: current.phoneNumber3,
      keys: 'phoneNumber3',
      click: handleOpen2,
      fontSize: '20px',
      opn: false,
      content: getTel3,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '補足3',
      val: current.phoneNumberMemo3,
      keys: 'phoneNumberMemo3',
      click: handleOpen1,
      opn: false,
      content: getTel3Memo,
    },
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '電話許可範囲',
      val: TEL_STATUS_CODE[current.telStatusCode],
      keys: 'telStatusCode',
      content: getTelStatusCodeSp,
    },
  ];

  const getStatusPc = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Typography className={baseClasses.title6}>
              TEL
            </Typography>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (current.telStatusCode) {
          case 0:
            return (
              <Grid>
                <Typography className={baseClasses.title6}>
                  TEL
                </Typography>
                <Typography className={classes.claim}>
                  電話NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid>
                <Grid className={classes.telList}>
                  <Grid className={classes.telItem}>
                    <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
                      TEL
                    </Typography>
                    <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
                      補足
                    </Typography>
                  </Grid>
                  <Grid className={classes.telItem}>
                    {getTextFieldPhoneNumber('phoneNumber1')}
                    {getTextField('phoneNumberMemo1')}
                  </Grid>
                  <Grid className={classes.telItem}>
                    {getTextFieldPhoneNumber('phoneNumber2')}
                    {getTextField('phoneNumberMemo2')}
                  </Grid>
                  <Grid className={classes.telItem}>
                    {getTextFieldPhoneNumber('phoneNumber3')}
                    {getTextField('phoneNumberMemo3')}
                  </Grid>
                  {getTelStatusCodePc()}
                </Grid>
              </Grid>
            );
        }
    }
  };

  const getStatusSp = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              TEL
            </Typography>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        switch (current.telStatusCode) {
          case 0:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  TEL
                </Typography>
                <Typography className={classes.claim}>
                  電話NGの場合は閲覧不可
                </Typography>
              </Grid>
            );
          default:
            return (
              <Grid>
                <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                  TEL
                </Typography>
                <List className={classes.list}>
                  {list.map((item) => {
                    return (
                      <ListItem
                        key={item.lbl}
                        className={classes.listItem}
                      >
                        {input.parts(item)}
                        {/* {input.editDialog(item)} */}
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            );
        }
    }
  };

  if (isSp) {
    contents = (
      <Grid>
        {getStatusSp()}
        <SpEditDialog
          title={editTargetRef.current.lbl}
          open={open1}
          input={editTargetRef.current.val}
          onClose={handleClose1}
          onSubmit={onSpSubmit}
          fontSize={editTargetRef.current.fontSize}
        />
        <SpEditDialog
          title={editTargetRef.current.lbl}
          open={open2}
          input={editTargetRef.current.val}
          onClose={handleClose2}
          onSubmit={onSpSubmit}
          fontSize={editTargetRef.current.fontSize}
          keys="phoneNumber"
        />
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        {getStatusPc()}
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerTel);
