import { useState, useRef, useEffect, useCallback, memo } from 'react';
import {
  Button,
  Collapse,
  Grid,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import { Add, Folder, Remove } from '@material-ui/icons';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

import store from '../../../../store';

import { useLibraryDirectoryTreeContext } from '../context';
import { DIRECTORY_CODE_SYSTEM } from '../../../../constants';
import { LIBRARY_DIRECTORY_TREE } from '../../../../constants/loading';
import { classNames } from '../../../../commonFunction';
import { useLoading } from '../../../../hooks';
import { TextBaseField } from '../../../eleCommon/validation';
import { validateFormString1 } from '../../../../commonFunction/validations';
import librarydirGetDirectlyTreeApi from '../../../../apis/librarydir/librarydirGetDirectlyTreeApi';
import { useLibraryContext } from '../../../mailLibrary/mailLibraryContext';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#fff',
    border: '1px #C8C8C8 solid',
    // maxHeight: 400,
    overflow: 'auto',
    padding: 16,
    width: '100%',
    flex: '1 0 auto',
    height: 'calc(100% - 70px)',
    // maxHeight: 'calc(100% - 70px)', https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4776#comment-188532605
    '&.modal': { height: '100%' },
  },
  libraryName: {
    padding: '2px 4px',
    // margin: '0 4px',
    borderRadius: 4,
    minWidth: 'auto',
    whiteSpace: 'noWrap',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  libraryNameInput: {
    minWidth: 120,
  },
  icons: {
    marginLeft: -3,
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
  },
  icon: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
    flexShrink: 0,
    '&.tree-item__selectable-icon': {
      cursor: 'pointer',
    },
    '& svg': {
      maxWidth: '100%',
    },
  },
  checked: {
    background: 'linear-gradient(90deg, #A7F1FF 0%, #C2EEFF 100%)',
  },
  parent: {
    position: 'relative',
    padding: 0,
    '& .MuiList-root': {
      padding: 0,
    },
    '&:not(:last-child)::after': {
      content: '""',
      height: 'calc(100% - 25px)',
      position: 'absolute',
      backgroundColor: '#c8c8c8',
      width: '1px',
      left: '7.5px',
      top: '25px',
      zIndex: 1,
    },
  },
  child: {
    padding: '0 0 0 20px',
    position: 'relative',
    width: '100%',
    '&::after': {
      content: '""',
      height: '100%',
      // height: 'calc(100% - 33px)',
      position: 'absolute',
      backgroundColor: '#c8c8c8',
      width: '1px',
      left: '7.5px',
      top: 0,
      // top: '25px',
      zIndex: 1,
    },
  },
  li: {
    display: 'block',
    padding: 0,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    '&.isNotChild': {
      display: 'inline-flex',
      position: 'relative',
      width: 16,
      height: 16,
      background: '#fff',
      '&::before': {
        position: 'absolute',
        width: 1,
        height: 9,
        background: '#8C8C8C',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        content: '""',
      },
      '&::after': {
        position: 'absolute',
        height: 1,
        width: 8,
        background: '#8C8C8C',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        content: '""',
      },
      [commonTheme.breakpoints.up('md')]: {
        paddingLeft: 13,
      },
    },
  },
  minus: {
    '&:not(:last-child)::after': {
      content: '""',
      height: 'calc(100% - 25px)',
      position: 'absolute',
      backgroundColor: '#c8c8c8',
      width: '1px',
      left: '7.5px',
      top: '25px',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const createKey = (libraryDir, prefix = '', suffix = '') => `${prefix}${libraryDir.libraryDirectoryId}${suffix}`;

const isSame = (directory, target) => directory.libraryDirectoryId === target.libraryDirectoryId;
const isParent = (directory, target, parentChildrenMap = new Map()) => {
  const children = parentChildrenMap.get(directory.libraryDirectoryId);
  if (children && children.has(target.libraryDirectoryId)) return true;
  return false;
};
const isOwnRoot = (directory) => {
  const { responseHeader: { userId } } = store.getState();
  return directory.directoryCode === DIRECTORY_CODE_SYSTEM && directory.userId === userId;
};
const areEqual = (prev, next) => {
  // FIXME: null参照エラー防止 デフォルトの選択などがおかしくなるため要修正 2024/7/10
  if (!prev.activeDir || !next.activeDir) return false;
  // click前後で関連するディレクトリか否かを判定
  const isUnrelativeDirectory = isSame(prev.item, prev.activeDir) === false
    && isSame(next.item, next.activeDir) === false
    && isParent(prev.item, prev.activeDir, prev.parentChildrenMap) === false
    && isParent(next.item, next.activeDir, next.parentChildrenMap) === false;
  return (
    prev.item === next.item
    && prev.prefix === next.prefix
    && prev.isNameChange === next.isNameChange
    && isUnrelativeDirectory
    && !!prev.activeDir.libraryDirectoryId
  );
};

const LibraryDirectoryTree = memo((props) => {
  const {
    createContent,
    item,
    prefix,
    isRoot = false,
    isNameChange,
    parentDir = {},
  } = props;

  const { activeDir, setActiveDir } = useLibraryDirectoryTreeContext();

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(isOwnRoot(item));
  const isSelected = activeDir?.libraryDirectoryId === item.libraryDirectoryId;
  const [childrenList, setChildrenList] = useState(null);

  const {
    isDirAdd, setIsDirAdd,
    isParentUpdate, setIsParentUpdate,
  } = useLibraryContext();

  const listClasse = childrenList?.length && isOpen ? [classes.li, classes.minus] : [classes.li];

  const getChildrenList = () => {
    librarydirGetDirectlyTreeApi(item.libraryDirectoryId)
      .then((res) => {
        setChildrenList(res.data.libraryDirectories);
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  };
  const handleOpen = () => {
    getChildrenList(item.libraryDirectoryId);
    setIsOpen(true);
  };

  const prefixIcon = () => {
    if (!item.hasChildren) return null;
    return isOpen ? (
      <Remove
        className={classes.icons}
        onClick={() => setIsOpen(false)}
        style={{ cursor: 'pointer', zIndex: 10 }}
      />
    ) : (
      <Add
        className={classes.icons}
        onClick={handleOpen}
        style={{ cursor: 'pointer', zIndex: 10 }}
      />
    );
  };

  const listItemClasses = () => {
    const tmp = [classes.item];
    if (isRoot) tmp.push('isRoot');
    if (!isRoot && !item.hasChildren) tmp.push('isNotChild');
    return tmp;
  };

  const contentClasses = useCallback(() => {
    const tmp = [classes.content];
    if (isNameChange) return tmp;
    if (activeDir?.libraryDirectoryId === item.libraryDirectoryId) {
      tmp.push(classes.checked);
    }
    return tmp;
  }, [isSelected]);

  // 検索時にその子ディレクトを開く
  useEffect(() => {
    if (!isSelected) return;
    // 名称変更などがあった際に更新されないのでchildrenListがあるかどうかの条件分岐削除
    getChildrenList(item.libraryDirectoryId);
    setIsOpen(true);
  }, [item, activeDir]);
  useEffect(() => {
    if (!setIsDirAdd || !isSelected) return;
    if (isOpen) {
      getChildrenList(item.libraryDirectoryId);
      setIsDirAdd(false);
    }
  }, [isDirAdd, isOpen]);
  useEffect(() => {
    if (!isParentUpdate || !isSelected) return;
    if (isOpen) {
      setActiveDir(parentDir);
      setIsParentUpdate(false);
    }
  }, [isParentUpdate]);

  return (
    <>
      <ListItem
        id={createKey(item, prefix)}
        className={classNames(...listClasse)}
      >
        <div className={classNames(...listItemClasses())}>
          {prefixIcon()}
          <div className={classNames(...contentClasses())}>
            {createContent(item)}
          </div>
        </div>
      </ListItem>
      {item.hasChildren && isOpen && (
        <Collapse in={isOpen} className={classes.child} timeout={0}>
          <List>
            {childrenList?.map((child) => (
              <LibraryDirectoryTree
                key={createKey(child, prefix)}
                createContent={createContent}
                item={child}
                prefix={prefix}
                isNameChange={isNameChange}
                activeDir={activeDir}
                parentDir={item}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}, areEqual);

export default function LibraryDirectoryTreeList() {
  const {
    activeDir,
    setActiveDir,
    isNameChange,
    updatedirectoryName,
    prefix,
    list,
    modalOption,
  } = useLibraryDirectoryTreeContext();
  const classes = useStyles();
  const common = commonStyles();
  const { hasLoading } = useLoading();

  const rootRef = useRef(null);
  const treeListRef = useRef(null);

  // 表示するリストのコンポーネント
  const createContent = (libraryDir) => {
    // enter押下時に名称変更のapiを実行
    const setNameOnEnter = (e) => {
      if (e.key !== 'Enter') return;
      const error = validateFormString1({ maxLengthInt: 250 })(e.target.value);
      if (error.length) return;
      updatedirectoryName(e.target.value);
    };

    const content = libraryDir.libraryDirectoryId === activeDir?.libraryDirectoryId
      && isNameChange ? (
        <TextBaseField
          onBlur={(e) => updatedirectoryName(e.target.value)}
          defaultValue={libraryDir.directoryName}
          onKeyDown={setNameOnEnter}
          autoFocus
          className={classNames(common.small, classes.libraryName, classes.libraryNameInput)}
          validator={validateFormString1({ maxLengthInt: 250 })}
        />
      ) : (
        <Button
          className={classNames(common.small, classes.libraryName)}
          onClick={() => setActiveDir(libraryDir)}
        >
          {libraryDir.directoryName}
        </Button>
      );
    return (
      <>
        <Folder className={classes.icon} />
        {content}
        <span className={common.small}>
          {libraryDir.libraryCount !== null ? `(${libraryDir.libraryCount})` : ''}
        </span>
      </>
    );
  };

  return (
    <Grid container className={`${classes.container} ${hasLoading(LIBRARY_DIRECTORY_TREE) ? common.loading : ''} ${modalOption ? 'modal' : ''}`} ref={rootRef}>
      <List className={classes.parent} ref={treeListRef}>
        {list.map((item) => (
          <LibraryDirectoryTree
            key={createKey(item, prefix)}
            prefix={prefix}
            createContent={createContent}
            item={item}
            isNameChange={isNameChange}
            activeDir={activeDir}
            isRoot
          />
        ))}
      </List>
    </Grid>
  );
}
