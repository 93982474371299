import client from '../httpClient';

// user: /users/:id ユーザー更新
const userUpdateApi = (userId, modifyObj) => {
  const UPDATE_USER_URL = `/users/${String(userId)}`;
  // const params = {
  //   method: 'PUT',
  //   body: JSON.stringify(modifyObj),
  // };
  return client.put(UPDATE_USER_URL, JSON.stringify(modifyObj), { isShowMessage: true });
};

export default userUpdateApi;
