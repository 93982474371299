import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const webResUpdateSlice = createSlice({
  name: 'webResUpdate',
  initialState: { webResUpdate: initialState },
  reducers: {
    webResUpdateApi: (state, data) => {
      state.webResUpdate = data.payload;
    },
  },
});

export const { webResUpdateApi } = webResUpdateSlice.actions;

export default webResUpdateSlice.reducer;
