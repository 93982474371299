import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import commonTheme from '../styles/theme';
import commonStyles from '../styles';
// import MainHeadMenuTabs from './parts/mainHeadMenuTabs';
import CustomMainSelect from '../eleCommon/customMainSelect';
import CustomBlankSelect from '../eleCommon/customBlankSelect';
import CustomBackSelect from '../eleCommon/customBackSelect';
import CustomMainSelectMulti from '../eleCommon/customMainSelectMulti';
import ListBtn from './parts/listBtn';
import ListMultiButton from './parts/listMultiButton';
import ListCheckbox from './parts/listCheckbox';
import EditDialog1 from './parts/editDialog1';
import EditDialog2 from './parts/editDialog2';
import MainItem from './mainItem';
import CustomMainSelectPlaceholder from '../eleCommon/customPlaceholderSelect';
import { useLoading } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  sideMenu: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 240,
    },
  },
  customerListWrap: {
    display: 'grid',
    gap: 40,
    paddingBottom: 20,
    '&:first-child': {
      paddingTop: '16px',
    },
    '& > div': {
      display: 'flex',
      // boxShadow: '0 0px 10px 0 rgba(0, 0, 0, .2)',
    },
  },
  title: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  name: {
    display: 'flex',
    color: '#333',
  },
  name1: {
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 1,
    marginRight: 8,
  },
  name2: {
    fontSize: 13,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'left',
    marginBottom: 2,
    display: 'inline-flex',
    alignItems: 'center',
  },
  title4: {
    fontWeight: 400,
  },
  container: {
    background: '#F3F3F3',
  },
  root: {
    position: 'relative',
    background: '#F3F3F3',
    padding: '16px 16px 8px',
    border: '1px solid #C8C8C8',
    marginBottom: 40,
  },
  button: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 17px',
  },
  box: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 6,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 6,
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrow: {
    fontSize: 16,
    color: theme.palette.primaryColor,
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 'auto',
    padding: '16px',
    '& .MuiSvgIcon-root': {
      boxSizing: 'border-box',
      width: '16px',
      height: '24px',
    },
  },
  status: {
    textAlign: 'center',
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  emptyMessage: {
    [commonTheme.breakpoints.up('md')]: {
      textAlign: 'center',
      // width: 1234,
      paddingTop: 40,
    },
  },
}));

function Main() {
  const baseClasses = commonStyles();
  const classes = useStyles();
  const gsCustomer = useSelector((state) => state.customer.customer);
  // eslint-disable-next-line max-len
  const divisionsGetResponseTree = useSelector(
    (state) => state.divisionsGetResponseTree.divisionsGetResponseTree,
  );

  const { removeLoading } = useLoading();

  const listRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isCloesdCustomerDetail = useRef(false);

  useEffect(() => {
    const hasCustomerId = gsCustomer?.customers[0]?.customerId;

    if (hasCustomerId) {
      // 取得した顧客情報を表示するまでのローディングを停止
      setTimeout(removeLoading, 500);
      // スクロール位置を設定
      if (scrollPosition > 0 && isCloesdCustomerDetail.current) {
        listRef.current.scrollTop = scrollPosition;
        isCloesdCustomerDetail.current = false;
      }
    }

    return () => {
      if (hasCustomerId) {
        // スクロール位置を保存
        setScrollPosition(listRef.current?.scrollTop);
      }
    };
  }, [gsCustomer?.customers]);

  const getComponent = useCallback((data) => {
    switch (data?.type) {
      case 'select':
        return <CustomMainSelect initial={data} />;
      case 'selectBlank':
        return <CustomBlankSelect initial={data} />;
      case 'selectBack':
        return <CustomBackSelect initial={data} />;
      case 'selectMulti':
        return <CustomMainSelectMulti initial={data} />;
      case 'selectPlaceholder':
        return <CustomMainSelectPlaceholder initial={data} />;
      default:
        return <div>対応しない要素です</div>;
    }
  }, []);

  // SP モーダル
  const getEditDialog = useCallback((data) => {
    switch (data.type2) {
      case 'text':
        return (
          <EditDialog1
            open={data.opn ?? false}
            onClose={data.onClose}
            content={data.content}
            set={data.decision}
            lbl={data.lbl}
          />
        );
      case 'select':
        return (
          <EditDialog2
            open={data.opn ?? false}
            onClose={data.onClose}
            content={data.content}
            lbl={data.lbl}
          />
        );
      case 'multiSelect':
        return (
          <Grid>
            <EditDialog2
              open={data.opn1 ?? false}
              onClose={data.onClose1}
              content={data.content1}
              lbl={data.lbl}
            />
            <EditDialog2
              open={data.opn2 ?? false}
              onClose={data.onClose2}
              content={data.content2}
              lbl={data.lbl}
            />
          </Grid>
        );
      default:
        return <span style={{ display: 'none' }}>該当なし</span>;
    }
  }, []);

  // SP ボタン
  const getParts = useCallback((data) => {
    switch (data.type1) {
      case 'button':
        return (
          <ListBtn
            click={data.click}
            lbl={data.lbl}
            fontSize={data.fontSize}
            val={data.val}
          />
        );
      case 'multiButton':
        return (
          <ListMultiButton
            click1={data.click1}
            click2={data.click2}
            lbl={data.lbl}
            fontSize={data.fontSize}
            val1={data.val1}
            val2={data.val2}
          />
        );
      case 'checkbox':
        return <ListCheckbox control={data.content()} lbl={data.lbl} />;
      default:
        return <span style={{ display: 'none' }}>該当なし</span>;
    }
  }, []);

  return gsCustomer?.customers[0]?.customerId ? (
    <Grid
      ref={listRef}
      key={gsCustomer?.getDataAt}
      id="MainItem__Grid"
      style={{ height: '100%', overflow: 'auto', willChange: 'transform' }}
    >
      {gsCustomer?.customers.map((customer) => (
        <MainItem
          key={customer?.customerId}
          customer={customer}
          getComponent={getComponent}
          getParts={getParts}
          getEditDialog={getEditDialog}
          divisionsGetResponseTree={divisionsGetResponseTree}
          className={classes.customerListWrap}
          isCloesdCustomerDetail={isCloesdCustomerDetail}
        />
      ))}
    </Grid>
  ) : (
    <Typography className={`${baseClasses.title1} ${classes.emptyMessage}`}>
      対象のデータが見つかりません。
      <br />
      検索条件をご確認ください。
    </Typography>
  );
}

export default memo(Main);
