import {
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import Select from '../../division/select';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';
import RadioGroup from './radioGroup';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: 20,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
    },
    '&:nth-child(2)': {
      marginTop: 10,
      justifyContent: 'flex-end',
    },
  },
});

const radioItems = [
  { label: '単一のみ', value: 1 },
  { label: '複数込', value: 2 },
];

export default function ConditionSearch() {
  const {
    // select options
    areaOptions,
    cityJisCodes,
    // select state
    currentArea,
    currentPlace,
    setCurrentArea,
    setCurrentPlace,
    // radio state
    areaSearchType,
    setAreaSearchType,
    // functions
    onSearch,
    showEditDialog,
  } = useCombinationSettingContext();

  const common = commonStyles();
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={common.selectBox}>
        <Select
          row
          label="エリア"
          value={currentArea.areaCode ?? 'undefined'}
          options={areaOptions}
          labelKey="areaName"
          valueKey="areaCode"
          onChange={(val, item) => {
            setCurrentArea(item);
            // reset地域
            setCurrentPlace({});
          }}
          placeholder="未選択"
        />
      </Grid>
      <Grid className={`${common.selectBox} ${!currentArea.areaCode && 'disabled'}`}>
        <Select
          row
          label="地域"
          valueKey="id"
          value={currentPlace.id}
          options={cityJisCodes[currentArea.areaCode] || []}
          onChange={(val, item) => {
            item.jisCode = String(item.id);
            setCurrentPlace(item);
          }}
          disabled={!currentArea.areaCode}
        />
      </Grid>
      <RadioGroup
        items={radioItems}
        value={areaSearchType}
        onChange={(val) => setAreaSearchType(val)}
      />
      <Button
        className={common.buttonsPrimary}
        onClick={onSearch}
        disabled={!currentArea.areaCode}
      >
        検索
      </Button>
      <Button
        className={common.buttonsSecondary}
        onClick={() => showEditDialog(true)}
        disabled={!currentArea.areaCode}
      >
        追加
      </Button>
    </Grid>
  );
}
