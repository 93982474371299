import AfterCallWindowContainer from '../../containers/mail/afterCallWindowContainer';

function AfterCallWindow(props) {
  const { user, open, setOpen, onClose, successSendCallBack } = props;

  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <AfterCallWindowContainer
      open={open}
      user={user}
      handleClose={handleClose}
      successSendCallBack={successSendCallBack}
    />
  );
}

export default AfterCallWindow;
