import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const simulatorUploadSlice = createSlice({
  name: 'simulatorUpload',
  initialState: { simulatorUpload: initialState },
  reducers: {
    simulatorUploadApi: (state, data) => {
      state.simulatorUpload = data.payload;
    },
  },
});

export const { simulatorUploadApi } = simulatorUploadSlice.actions;

export default simulatorUploadSlice.reducer;
