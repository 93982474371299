import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const librarydirResisterSlice = createSlice({
  name: 'librarydirResister',
  initialState: { librarydirResister: initialState },
  reducers: {
    librarydirResisterApi: (state, data) => {
      state.librarydirResister = data.payload;
    },
  },
});

export const { librarydirResisterApi } = librarydirResisterSlice.actions;

export default librarydirResisterSlice.reducer;
