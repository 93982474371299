import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Grid, Typography, ListItem } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import ImportantCode from '../../../common/instructionReport/parts/importantCode';
import DirectReportStatusCode from '../../../common/instructionReport/parts/directReportStatusCode';
import commonStyles from '../../../styles';
import NormalTooltip from '../../../styles/tooltip';
import MemberStatusIcon from '../../../common/memberStatusIcon';

import judgeMemberStatus from '../../../../commonFunction/judgeMemberStatus';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: '0 0 0 8px',
    overflow: 'hidden',
    background: '#fff',
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
  },
  wrap: {
    borderBottom: '1px solid #F3F3F3',
    padding: '12px 0',
    width: '100%',
    display: 'flex',
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 9,
    },
  },
  row: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:nth-child(1)': {
      textAlign: 'center',
    },
    '&:nth-child(2)': {
      wordBreak: 'keep-all',
    },
    '&:nth-child(3)': {
      textAlign: 'left',
      width: 'calc(100% - 110px)',
      '& > div': {
        width: '100%',
      },
    },
    '&:not(:first-child)': {
      marginLeft: 8,
    },
  },
  expiredIcon: {
    display: 'none',
    fontSize: 20,
    color: '#D83420',
    '&.expired': {
      display: 'inline-block',
    },
  },
  dateWrap: {
    '&.expired': {
      color: '#D83420',
    },
  },
  date: {
    '& > span': {
      display: 'block',
      textAlign: 'center',
    },
  },
  starLbl: {
    color: '#FCE034',
    '& label:not(:last-of-type)': {
      marginRight: 2,
    },
    '& .MuiRating-icon svg': {
      stroke: '#333',
      fontSize: 10,
    },
  },
  status: {
    '& > span': {
      background: '#333',
      color: '#fff',
      fontSize: 10,
      padding: '4px 6px',
      minWidth: 52,
      display: 'block',
      textAlign: 'center',
    },
  },
  userStatus: {
    display: 'inline-flex',
    '& > svg': {
      marginRight: 8,
    },
  },
  name: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  dl: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  dt: {
    flexShrink: 0,
  },
  dd: {
    marginLeft: 4,
  },
  content: {
    '& > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 13,
    },
  },
  workPlace4Button: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  directName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default function InstructionListBlockItemSp(props) {
  const {
    row,
    // isItemSelected,
    date1,
    tab,
    open,
    setOpen,
    setSelectRow,
  } = props;

  // モーダルを開くsetState
  const handleOpen = () => {
    console.log('test');
    if (!open) {
      setSelectRow(row);
      setOpen(true);
    }
  };

  const classes = useStyles();
  const common = commonStyles();

  // 期日
  const getExpired = (date) => {
    if (tab === 0) {
      const date2 = new Date(date.substr(0, 10));
      if (date1 > date2) {
        return 'expired';
      }
    }
    return false;
  };

  const content = (
    <Grid className={classes.wrap}>
      <Grid className={classes.row}>
        <Grid className={`${classes.dateWrap} ${getExpired(row.expireAt)}`}>
          <Icon
            className={`${classes.expiredIcon} ${getExpired(row.expireAt)}`}
          >
            local_fire_department
          </Icon>
          <Typography className={`${common.title5} ${classes.date}`}>
            <span>{row.expireAt.substr(0, 4)}</span>
            <span className={common.title4}>{row.expireAt.substr(5, 5)}</span>
            <span>-{row.expireAt.substr(10, 6)}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Grid className={classes.status}>
          <DirectReportStatusCode code={row.directReportStatusCode} />
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Grid className={classes.rowInner}>
          <Grid container className={classes.flex}>
            <Grid
              item
              style={{ width: '40%' }}
              className={classes.workPlace4Button}
            >
              <NormalTooltip
                title={`${row.customerLastName}  ${row.customerFirstName}`}
              >
                <Typography className={classes.name}>
                  {row.customerLastName} {row.customerFirstName}
                </Typography>
              </NormalTooltip>
            </Grid>
            <Grid item style={{ width: '25%' }}>
              <Rating
                name="size-large"
                value={row.userLevel}
                className={classes.starLbl}
              />
            </Grid>
            <Grid item className={classes.userStatus} style={{ width: '25%' }}>
              <MemberStatusIcon code={row.memberStatusCode} />
              <Typography className={`${common.title4} ${classes.title4}`}>
                {judgeMemberStatus(row.memberStatusCode)}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.flex}>
            <dl className={classes.dl}>
              <dt className={`${common.title5} ${classes.dt}`}>重要度</dt>
              <dd className={classes.dd}>
                <ImportantCode code={row.importantCode} />
              </dd>
            </dl>
            <dl className={classes.dl}>
              <dt className={`${common.title5} ${classes.dt}`}>担当者</dt>
              <dd className={`${classes.dd} ${classes.directName}`}>
                {row.directedDivisionName} / {row.directedLastName} {row.directedFirstName}
              </dd>
            </dl>
          </Grid>
          <Grid className={classes.content}>
            <Typography>{row.content}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ListItem
      className={`${classes.item} ${getExpired(row.expireAt)}`}
      onClick={() => handleOpen(row)}
    >
      {content}
    </ListItem>
  );
}
