import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  select: {
    // minWidth: '160px',
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
    },
    '& .MuiSelect-icon': {
      width: '17px',
    },
  },
  wrap: {
    flexGrow: 1,
    [commonTheme.breakpoints.up('md')]: {
      marginLeft: '24px',
    },
    '& .MuiInput-formControl::before': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiInput-formControl::after': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      [commonTheme.breakpoints.up('md')]: {
        opacity: 0,
      },
    },
  },
  itemWrap: {
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px #c8c8c8 solid',
      padding: '2px 8px',
      marginBottom: '8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
      width: '100%',
      padding: '16px',
    },
    '& p': {
      whiteSpace: 'nowrap',
    },
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
}));

function MailIntfield(props) {
  const { values, errorCallback = () => {} } = props;
  const classes = useStyles();
  const common = commonStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const onBlurFunc = (e) => {
    return values.setState(e.target.value);
  };
  const onChangeFunc = (e) => {
    return values.setState(e.target.value);
  };
  return (
    <Grid container className={classes.itemWrap} style={values.style}>
      {!isSp && (
        <Typography className={common.title4}>
          {values.label}
          {values.require
            ? <span style={{ color: '#D83420' }}>＊</span>
            : null}
        </Typography>
      )}
      <TextBaseField
        value={values.state}
        onBlur={onBlurFunc}
        onChange={onChangeFunc}
        className={`${common.textField} ${classes.wrap}`}
        disabled={values.disable}
        placeholder={values.label}
        convertor={values?.convertor}
        validator={values.validator}
        name={values.label}
        errorCallback={errorCallback(values.label)}
      />
    </Grid>
  );
}

export default MailIntfield;
