import client from '../httpClient';

/**
 * @typedef { import(
 *   './getIncompleteListApiTypes'
 * ).IncompleteListGetResponse } IncompleteListGetResponse
 * @type { (query: {
 *   userId?: number;
 *   divisionId: number;
 * }) => Promise<IncompleteListGetResponse> }
 */
const getIncompleteListApi = async (params) => {
  const res = client.get('/directs/incomplete-list/', { params });
  return res;
};

export default getIncompleteListApi;
