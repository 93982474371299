const KEYMALIS_CONTRACT_STATUS = {
  1: 'セット中',
  2: '契約予定',
  3: 'セットオープン',
  4: '契約済',
  5: '解約予定',
  6: '解約済',
  7: '請負等',
  8: '仲介料増額',
  9: '仲介料減額',
  10: '削除',
};

export default KEYMALIS_CONTRACT_STATUS;
