import client from '../httpClient';

// 【resHouse001】問合せ物件検索
const resHouseGetApi = async (customerId, skipAuthorityCheck = 0) => {
  /**
   * @param {string} customerId
   */
  const params = { skipAuthorityCheck };
  const result = await client.get(`/inquiry-products/customers/${customerId}`, { params });
  return result;
};

export default resHouseGetApi;
