import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Add, ReportProblemOutlined } from '@material-ui/icons';
import AimSettingComponent from './aimSettingComponent';
import AddAimWindow from './parts/addAimWindow';
import ChangeAimWindow from './parts/changeAllWindow';
import commonStyles from '../styles';
import layoutStyle from '../styles/layout';

const useStyles = makeStyles({
  inner: { maxWidth: '1200px', margin: 'auto', width: '100%' },
  header: {
    background: '#fff',
    padding: '16px calc(50% - 600px) 0',
  },
  btn: {
    marginRight: 40,
    marginBottom: 24,
    '& svg': { fontSize: 20, marginRight: 4 },
  },
  appbar: {
    boxShadow: 'none',
  },
});

export default function AimSetting(props) {
  const {
    getKimarisTargetList,
    createKimaris,
    updateKimaris,
    updateAllKimaris,
    deleteKimaris,
  } = props;
  const common = commonStyles();
  const classes = useStyles();
  const lCommon = layoutStyle();
  const [open, setOpen] = useState({
    add: false,
    change: false,
  });
  const targetListsState = useSelector(
    (state) => state.kimarisTargetSettingsGetList.kimarisTargetList,
  );
  const [localList, setLocalList] = useState({ targets: [] });

  // 初回レンダリングで目標一覧取得
  useEffect(async () => {
    await getKimarisTargetList();
  }, []);

  // GlobalStateの目標設定一覧が変更されたらLocalStateに保持
  useEffect(() => {
    setLocalList(targetListsState);
  }, [targetListsState]);

  const rowDatas = localList.targets.map(row => {
    const copyObj = Object.assign({}, row);
    copyObj.divisionName = String(row.divisionName);
    copyObj.divisionId = String(row.divisionId);
    return copyObj;
  });

  // 登録
  const registerFunction = async (params, callback) => {
    const callbackClose = () => {
      setOpen({ ...open, add: false });
    };
    await createKimaris(params, callback, callbackClose);
  };

  // 更新
  const onUpdate = async (targetId, updateData) => {
    await updateKimaris(targetId, updateData);
  };

  // 一括変更
  const allChangeFunction = (params) => {
    setOpen({ ...open, change: false });
    updateAllKimaris(params);
  };

  // 削除
  const deleteFunction = async (targetId) => {
    await deleteKimaris(targetId);
  };

  return (
    <Grid>
      <Grid className={common.header}>
        <Grid className={lCommon.inner}>
          <Typography className={common.title2}>
            役割ごとの目標設定
          </Typography>
        </Grid>
      </Grid>
      <Grid className={common.bodyType2}>
        <Grid container className={lCommon.inner}>
          <Button
            className={`${common.buttonsPrimary} ${classes.btn}`}
            onClick={() => { setOpen({ ...open, add: true }); }}
          >
            <Add />
            目標追加
          </Button>
          <AddAimWindow
            registerFunction={registerFunction}
            open={open.add}
            onClose={() => { setOpen({ ...open, add: false }); }}
            getKimarisTargetList={getKimarisTargetList}
          />
          <Button
            className={`${common.buttonsPrimary} ${classes.btn}`}
            onClick={() => { setOpen({ ...open, change: true }); }}
          >
            <ReportProblemOutlined />
            目標一括変更
          </Button>
          <ChangeAimWindow
            allChangeFunction={allChangeFunction}
            open={open.change}
            onClose={() => { setOpen({ ...open, change: false }); }}
          />
          <AimSettingComponent
            list={rowDatas}
            deleteFunction={deleteFunction}
            onUpdate={onUpdate}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
