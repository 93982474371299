import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import KanniKensaku from '../search/kanniKensaku';
import CustomerTree from '../common/customerTree/customerTree';
import commonTheme from '../styles/theme';
import { useCustomerMain } from '../../containers/customerMain/customerMainContext';
import ChaseOption from './parts/chaseOption';

const useStyles = makeStyles({
  sideMenu: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 240,
      maxHeight: 'calc(100vh - 57px)',
      overflow: 'hidden',
      borderRight: '1px #C8C8C8 solid',
    },
    '& > div': {
      [commonTheme.breakpoints.up('md')]: {
        width: 240,
      },
    },
    '& > div:nth-child(2)': {
      [commonTheme.breakpoints.up('md')]: {
        marginRight: -1,
      },
    },
  },
});

function AsideCustomerTree(props) {
  const {
    treeHeight,
  } = props;
  const {
    setSelectCustomer,
    queryCustomerId,
    initLoginData,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    selectUniqueId,
    setSelectUniqueId,
  } = useCustomerMain();

  const searchParams = useSelector((state) => state.searchItems.searchParams);

  useEffect(() => {
    setSelectUser({
      userId: !queryCustomerId ? initLoginData.userId : undefined,
      userName: '',
    });
  }, []);

  return useMemo(() => {
    return (
      <CustomerTree
        setSelectCustomer={setSelectCustomer}
        setSelectUser={setSelectUser}
        setSelectDivision={setSelectDivision}
        setSelectUniqueId={setSelectUniqueId}
        targetFlgList={{
          userFlg: true,
          divisionFlg: true,
          customerFlg: true,
        }}
        defaultUserId={selectUser?.userId}
        // eslint-disable-next-line no-mixed-operators
        defaultDivisionId={selectDivision?.divId && !selectUser?.userId || selectDivision?.divId && selectUser?.userId === -1 ? selectDivision.divId : ''}
        defaultUniqueId={selectUniqueId}
        treeHeight={treeHeight}
      />
    );
  }, [searchParams, selectUser, treeHeight]);
}

// eslint-disable-next-line react/jsx-no-useless-fragment
const MemoAsideCustomerTree = React.memo(AsideCustomerTree) ?? (<></>);

function Aside() {
  const classes = useStyles();

  // 反響ツリーの高さ調整
  const [treeHeight, setTreeHeight] = useState('');
  const [openAccordion, setOpenAccordion] = useState(true);
  const changeAccordion = (d) => {
    setOpenAccordion(d);
  };
  useEffect(() => {
    const accordionHeight = openAccordion ? 464 : 24;
    const height = 202 + accordionHeight;
    setTreeHeight(`calc(100vh - ${height}px)`);
  }, [openAccordion]);

  return (
    <Grid className={classes.sideMenu}>
      <ChaseOption />
      <KanniKensaku changeAccordion={changeAccordion} />
      <MemoAsideCustomerTree treeHeight={treeHeight} />
    </Grid>
  );
}

export default React.memo(Aside);
