import React, { useCallback } from 'react';
import { TablePagination } from '@material-ui/core';
import { useLocalStorage } from '../../../hooks';

function AllPagenation(props) {
  const {
    rows,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
  } = props;

  const { addLocalStorage } = useLocalStorage();

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    addLocalStorage(+event.target.value);
    setPage(0);
  }, []);

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={rows}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="表示件数"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
    />
  );
}

export default AllPagenation;
