import BrowsingHistoryContainers from '../../containers/browsingHistory/browsingHistoryContainers';

function BrowsingHistory(props) {
  return (
    <div>
      <BrowsingHistoryContainers {...props} />
    </div>
  );
}

export default BrowsingHistory;
