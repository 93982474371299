import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  Icon,
  // Drawer,
} from '@material-ui/core';
import {
  Phone as PhoneIcon,
  PhoneIphone as PhoneIphoneIcon,
  MailOutline as MailOutlineIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
  TouchAppOutlined as TouchAppOutlinedIcon,
} from '@material-ui/icons';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import RegisterWindow from '../registerWindow';
import InstructionsWindow from '../instructionsWindow';
import InfoRegister from '../../infoRegister';
import { useUpdate } from '../../../../containers/customerMain';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F3F3F3',
    [commonTheme.breakpoints.up('md')]: {
      padding: 8,
    },
  },
  box: {
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {

    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #C8C8C8',
      padding: 8,
    },
  },
  buttonWrap: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 310,
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '32px 0 32px 16px',
    },
  },
  button: {
    background: 'none',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    margin: '0',
    boxShadow: 'none',
    minWidth: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      color: '#8C8C8C',
      width: 56,
      padding: '2px 0',
    },
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      padding: '16px',
      borderBottom: '1px solid #F3F3F3',
    },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      [commonTheme.breakpoints.up('md')]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    '& .MuiButton-startIcon': {
      display: 'block',
      margin: 0,
      order: 1,
    },
    '& .MuiButton-endIcon': {
      display: 'block',
      margin: 0,
      order: 2,
      textAlign: 'left',
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 14,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
      boxShadow: 'none',
    },
  },
  buttonTxt: {
    display: 'block',
    order: 3,
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginLeft: '6px',
    },
  },
  ttl: {
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: '20px',
      fontWeight: '700',
      textAlign: 'center',
      padding: '16px 0',
      borderBottom: '1px solid #F6F6F6',
    },
  },
}));

export default function TaiouRirekiType(props) {
  const {
    onClose,
    setOpenAlert,
    createHistory,
    customerId,
    customer,
    newRegistrationFlg,
    getActionHistoryList,
    actionHistorySearch,
    rerenderSearch,
  } = props;
  const {
    update,
  } = useUpdate(customer);

  const [type, setType] = useState(0);

  const [actionId, setActionId] = useState('');

  // モーダル開閉用のローカルステート
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  // モーダルを開くsetState
  const handleOpen1 = () => {
    setType(0);
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setType(1);
    setOpen2(true);
  };
  const handleOpen3 = () => {
    setType(2);
    setOpen3(true);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleOpen5 = () => {
    setOpen5(true);
  };
  // モーダルを閉じるsetState
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };

  const updateDirect = (val) => {
    update({
      directTimeLineContent: val.content,
      directStatusCode: val.directReportStatusCode,
      expireAt: val.expireAt,
    });
  };

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.box}>
        <Typography className={`${baseClasses.title6} ${classes.ttl}`}>登録種類</Typography>
        <Grid className={classes.buttonWrap}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<PhoneIcon />}
            endIcon={<PhoneIphoneIcon />}
            onClick={handleOpen1}
          >
            <span className={classes.buttonTxt}>電話</span>
          </Button>
          <RegisterWindow
            open={open1}
            onClose={handleClose1}
            type={type}
            setType={setType}
            infoRegisterOpen={handleOpen5}
            typeClose={onClose}
            setOpenAlert={setOpenAlert}
            createHistory={createHistory}
            customerId={customerId}
            customer={customer}
            setActionId={setActionId}
            getActionHistoryList={getActionHistoryList}
          />
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<MailOutlineIcon />}
            onClick={handleOpen2}
          >
            <span className={classes.buttonTxt}>メール/郵送</span>
          </Button>
          <RegisterWindow
            open={open2}
            onClose={handleClose2}
            type={type}
            setType={setType}
            infoRegisterOpen={handleOpen5}
            typeClose={onClose}
            setOpenAlert={setOpenAlert}
            createHistory={createHistory}
            customerId={customerId}
            customer={customer}
            setActionId={setActionId}
          />
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<Icon>aod</Icon>}
            endIcon={<ChatBubbleOutlineIcon />}
            onClick={handleOpen3}
          >
            <span className={classes.buttonTxt}>SMS/LINE</span>
          </Button>
          <RegisterWindow
            open={open3}
            onClose={handleClose3}
            type={type}
            setType={setType}
            infoRegisterOpen={handleOpen5}
            typeClose={onClose}
            setOpenAlert={setOpenAlert}
            createHistory={createHistory}
            customerId={customerId}
            customer={customer}
            setActionId={setActionId}
          />
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<DateRangeOutlinedIcon />}
            onClick={handleOpen5}
          >
            <span className={classes.buttonTxt}>案内/来社</span>
          </Button>
          <InfoRegister
            open={open5}
            onOpen={handleOpen5}
            onClose={handleClose5}
            // newRegistrationFlgがtrueの場合、infoRegisterにguidanceIdを渡さない===新規登録になる
            guidanceId={newRegistrationFlg ? null : customer.guidanceScheduleId}
            onCloseTypesDrawer={onClose}
            customer={customer}
            actionId={actionId}
            getActionHistoryListSp={getActionHistoryList}
            actionHistorySearchSp={actionHistorySearch}
            rerenderSearch={rerenderSearch}
          />
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<TouchAppOutlinedIcon />}
            onClick={handleOpen4}
          >
            <span className={classes.buttonTxt}>指示</span>
          </Button>
          <InstructionsWindow
            open={open4}
            setOpen={setOpen4}
            onClose={handleClose4}
            directId=""
            // update: 2023/03/22 - https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3818 以下追加
            newInstructionFlg
            customerId={customer.customerId}
            divisionId={customer.divisionId}
            userId={customer.userId}
            userFirstName={customer.userFirstName}
            userLastName={customer.userLastName}
            updatePage={updateDirect}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
