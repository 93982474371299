import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const sendmailCustomerMailSlice = createSlice({
  name: 'sendmailCustomerMail',
  initialState: { sendmailCustomerMail: initialState },
  reducers: {
    sendmailCustomerMailApi: (state, data) => {
      state.sendmailCustomerMail = data.payload;
    },
  },
});

export const { sendmailCustomerMailApi } = sendmailCustomerMailSlice.actions;

export default sendmailCustomerMailSlice.reducer;
