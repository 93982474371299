import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';

import {
  validateFormInt1,
  validateFormFloat1,
} from '../../../../commonFunction/validations';
import { convertFormNumber } from '../../../../commonFunction/convertors';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  budget: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  budgetBox: {
    width: 105,
  },
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      fontWeight: 700,
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'center',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      '& span span': {
        fontSize: 10,
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'center',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
}));

export default function ResponseRegisterBudget(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    user,
    detail,
    setDetail,
    errorCallback,
  } = props;

  const initial = detail;

  const onBlur = (e) => {
    if (e.target.value !== detail[e.target.name]) {
      initial[e.target.name] = e.target.value;
      setDetail({ ...detail });
    }
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>
        予算情報
      </Typography>
      <Grid className={classes.budget}>
        <Grid className={classes.budgetBox}>
          <TextBaseField
            label={<span>本人年収<span>（万円）</span></span>}
            defaultValue={user.annualIncome}
            className={classes.textField}
            name="annualIncome"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('annualIncome')}
          />
        </Grid>
        <Grid className={classes.budgetBox}>
          <TextBaseField
            label={<span>合算者<span>（万円）</span></span>}
            defaultValue={user.subAnnualIncome}
            className={classes.textField}
            name="subAnnualIncome"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('subAnnualIncome')}
          />
        </Grid>
        <Grid className={classes.budgetBox}>
          <TextBaseField
            label={<span>頭金<span>（万円）</span></span>}
            defaultValue={user.deposit}
            className={classes.textField}
            name="deposit"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('deposit')}
          />
        </Grid>
        <Grid className={classes.budgetBox}>
          <TextBaseField
            label={<span>援助<span>（万円）</span></span>}
            defaultValue={user.assistAmount}
            className={classes.textField}
            name="assistAmount"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('assistAmount')}
          />
        </Grid>
        <Grid className={classes.budgetBox}>
          <TextBaseField
            label={<span>月々支払<span>（万円）</span></span>}
            defaultValue={user.monthlyPayment}
            className={classes.textField}
            name="monthlyPayment"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('monthlyPayment')}
          />
        </Grid>
        <Grid className={classes.budgetBox}>
          <TextBaseField
            label={<span>ボーナス<span>（万円）</span></span>}
            defaultValue={user.bonusPayment}
            className={classes.textField}
            name="bonusPayment"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('bonusPayment')}
          />
        </Grid>
        <Grid className={classes.budgetBox}>
          <TextBaseField
            label={<span>現在の家賃<span>（万円）</span></span>}
            defaultValue={user.abodeNowRent}
            className={classes.textField}
            name="abodeNowRent"
            onBlur={onBlur}
            validator={validateFormFloat1({
              minFloat: 0.0,
              maxFloat: 99999.0,
              precisionInt: 4,
            })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('abodeNowRent')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
