import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesUpdateGuidancesSlice = createSlice({
  name: 'schedulesUpdateGuidances',
  initialState: { schedulesUpdateGuidances: initialState },
  reducers: {
    schedulesUpdateGuidancesApi: (state, data) => {
      state.schedulesUpdateGuidances = data.payload;
    },
  },
});

export const { schedulesUpdateGuidancesApi } = schedulesUpdateGuidancesSlice.actions;

export default schedulesUpdateGuidancesSlice.reducer;
