import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const resHouseGetSlice = createSlice({
  name: 'resHouseGetList',
  initialState: { resHouseGetList: initialState },
  reducers: {
    setResHouseGetList: (state, data) => {
      state.resHouseGetList = data.payload;
    },
  },
});

export const { setResHouseGetList } = resHouseGetSlice.actions;

export default resHouseGetSlice.reducer;
