import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  divisionName: '情シス',
  userDivisionId: '110',
};

export const customerResponsibleSlice = createSlice({
  name: 'customerResponsible',
  initialState: { customerResponsible: initialState },
  reducers: {
    getCustomerResponsibleApi: (state, data) => {
      state.customerResponsible = data.payload;
    },
  },
});

export const { getCustomerResponsibleApi } = customerResponsibleSlice.actions;

export default customerResponsibleSlice.reducer;
