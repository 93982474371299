import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const registerClaimSlice = createSlice({
  name: 'registerClaim',
  initialState: { registerClaim: initialState },
  reducers: {
    registerClaimApi: (state, data) => {
      state.registerClaim = data.payload;
    },
  },
});

export const { registerClaimApi } = registerClaimSlice.actions;

export default registerClaimSlice.reducer;
