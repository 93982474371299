const DICT = {
  NO: { NAME: 'NO.', KEY: 'no' },
  USER_ID: { NAME: '社員ID', KEY: 'userId' },
  USER_FULL_NAME: { NAME: '社員名', KEY: 'userFullName' },
  GRADE_CODE: { NAME: '役割ID', KEY: 'gradeCode' },
  OLD_GRADE_NAME: { NAME: '旧役割', KEY: 'oldGradeName' },
  NEW_GRADE_NAME: { NAME: '新役割', KEY: 'newGradeName' },
  POSITION_CODE: { NAME: '役職ID', KEY: 'positionCode' },
  OLD_POSITION_NAME: { NAME: '旧役職', KEY: 'oldPositionName' },
  NEW_POSITION_NAME: { NAME: '新役職', KEY: 'newPositionName' },
  USER_ROLE_GROUP_ID: { NAME: '権限ID', KEY: 'userRoleGroupId' },
  OLD_USER_ROLE_GROUP_NAME: { NAME: '旧権限', KEY: 'oldUserRoleGroupName' },
  NEW_USER_ROLE_GROUP_NAME: { NAME: '新権限', KEY: 'newUserRoleGroupName' },
  IS_MAIN_DIVISION: { NAME: 'メイン部署フラグ', KEY: 'isMainDivision' },
  MAIN_DIVISION_NAME: { NAME: '組織区分', KEY: 'mainDivisionName' },
  OLD_DIVISION_ID: { NAME: '旧組織ID', KEY: 'oldDivisionId' },
  OLD_DIVISION_NAME: { NAME: '旧組織', KEY: 'oldDivisionName' },
  NEW_DIVISION_ID: { NAME: '新組織ID', KEY: 'newDivisionId' },
  NEW_DIVISION_NAME: { NAME: '新組織', KEY: 'newDivisionName' },
  IS_NEW_MANAGER: { NAME: '新人MGR', KEY: 'isNewManager' },
  IS_CUSTOMER_MOVE: { NAME: '顧客移動', KEY: 'isCustomerMove' },
};

/** @type {{ NAME: string; KEY: string }[]} */
const LIST = [
  { ...DICT.NO },
  { ...DICT.USER_ID },
  { ...DICT.USER_FULL_NAME },
  { ...DICT.GRADE_CODE },
  { ...DICT.OLD_GRADE_NAME },
  { ...DICT.NEW_GRADE_NAME },
  { ...DICT.POSITION_CODE },
  { ...DICT.OLD_POSITION_NAME },
  { ...DICT.NEW_POSITION_NAME },
  { ...DICT.USER_ROLE_GROUP_ID },
  { ...DICT.OLD_USER_ROLE_GROUP_NAME },
  { ...DICT.NEW_USER_ROLE_GROUP_NAME },
  { NAME: '権限', KEY: 'roleGroupName' }, // 仮
  { ...DICT.IS_MAIN_DIVISION },
  { ...DICT.MAIN_DIVISION_NAME },
  { ...DICT.OLD_DIVISION_ID },
  { ...DICT.OLD_DIVISION_NAME },
  { ...DICT.NEW_DIVISION_ID },
  { ...DICT.NEW_DIVISION_NAME },
  { ...DICT.IS_NEW_MANAGER },
  { ...DICT.IS_CUSTOMER_MOVE },
];

/** @type {{ NAME: string; KEY: string }[]} */
const TABLE_COLUMN = [
  { ...DICT.NO },
  { ...DICT.USER_ID },
  { ...DICT.USER_FULL_NAME },
  { ...DICT.OLD_GRADE_NAME },
  { ...DICT.NEW_GRADE_NAME },
  { ...DICT.OLD_POSITION_NAME },
  { ...DICT.NEW_POSITION_NAME },
  { ...DICT.OLD_USER_ROLE_GROUP_NAME },
  { ...DICT.NEW_USER_ROLE_GROUP_NAME },
  { ...DICT.MAIN_DIVISION_NAME },
  { ...DICT.OLD_DIVISION_NAME },
  { ...DICT.NEW_DIVISION_NAME },
  { ...DICT.IS_NEW_MANAGER },
  { ...DICT.IS_CUSTOMER_MOVE },
];

const DIVISION_UPDATE_CONFIRM = { DICT, LIST, TABLE_COLUMN };

export default DIVISION_UPDATE_CONFIRM;
