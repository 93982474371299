import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  MEMBER_STATUS_CODE,
} from '../../../constants';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
    textAlign: 'left',
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px solid #8C8C8C',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #C8C8C8',
    },
    '&:nth-child(1)': {
      [commonTheme.breakpoints.up('md')]: {
        width: 108,
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: 128,
      },
    },
    '& > span': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  },
  link: {
    color: theme.palette.primaryColor,
  },
  icon: {
    display: 'block',
    fontSize: 16,
    color: theme.palette.primaryColor,
    margin: '0 auto',
  },
}));

export default function ProcessTableRow(props) {
  const {
    row,
  } = props;

  const classes = useStyles();
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.tableRow}
    >
      <TableCell className={classes.tableTd}>
        {isSP ? (
          <>
            <span>{row.createdAt.slice(0, 10)}</span>
            <span>{row.createdAt.slice(10, 16)}</span>
          </>
        ) : (
          <span>{row.createdAt}</span>
        )}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {MEMBER_STATUS_CODE[row.memberStatusCode]}
        <br />
        {row.memo}
      </TableCell>
    </TableRow>
  );
}
