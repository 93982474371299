import { Button, makeStyles, Typography } from '@material-ui/core';
import SendMailTimeChange from '../../icons/sendTimeChange';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  btn: { transform: 'scale(0.9)' },
  title: {
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: '16px',
      fontWeight: '700',
      color: '#333333',
    },
  },
  icon: {
    width: '16px',
    marginRight: '4px',
    fill: '#333333',
    [commonTheme.breakpoints.up('md')]: {
      fill: theme.palette.primaryColor,
    },
  },
}));

export default function SendTimeUpdate(props) {
  const { setOpenSendTimeDialog, setSendTime, row } = props;
  const common = commonStyles();
  const classes = useStyles();

  const onClickHandler = () => {
    setOpenSendTimeDialog(true);
    setSendTime({ deliveryAt: row.deliveryAt });
  };

  return (
    <Button
      className={`${classes.btn} ${common.buttonsSecondary}`}
      onClick={() => onClickHandler()}
    >
      <SendMailTimeChange classNameProps={classes.icon} />
      <Typography className={`${common.smallStrong} ${classes.title}`}>送信時間の変更</Typography>
    </Button>
  );
}
