import PropTypes from 'prop-types';
import { Grid, TableCell, makeStyles, TableSortLabel } from '@material-ui/core';

const useStyles = makeStyles({
  tableHead: {
    padding: '4px 0 6px 4px',
  },
  center: {
    textAlign: 'center',
  },
  rightBorder: {
    borderRight: '1px solid #C8C8C8',
    paddingRight: 4,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function HeaderCell(props) {
  const { obj, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const classes = useStyles();

  let className = !obj.lastCell ? classes.rightBorder : '';

  switch (obj.type) {
    case 'center':
      className += ` ${classes.center}`;
      break;
    default:
  }

  return (
    <TableCell className={classes.tableHead}>
      <Grid className={className}>
        <TableSortLabel
          active={orderBy === obj.key}
          direction={orderBy === obj.key ? order : 'asc'}
          onClick={createSortHandler(obj.key)}
        >
          {obj.title}
          {orderBy === obj.key ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </Grid>
    </TableCell>
  );
}

HeaderCell.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default HeaderCell;
