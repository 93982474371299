import client from '../httpClient';

const timeout = 2 * 60 * 1e-3;

const customerProductsRecommendationsApi = (id, band) => {
  const params = {
    customerId: id,
    isBand: band,
  };
  return client.get('/customers/products-recommendations', { params, timeout });
};

export default customerProductsRecommendationsApi;
