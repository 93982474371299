import MailAppendFileComponent from '../../../components/mail/parts/appendFileComponent';

export default function MailAppendFileContainer(props) {
  const { uploadedList, setUploadedList, disable } = props;

  return (
    <MailAppendFileComponent
      uploadedList={uploadedList}
      setUploadedList={setUploadedList}
      disable={disable}
    />
  );
}
