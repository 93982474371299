import React, { useState } from 'react';
import { Checkbox, Link, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { Language } from '@material-ui/icons';
import StarRateIcon from '@material-ui/icons/StarRate';
import PremiumIcon from '../../icons/premiumIcon';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';

import AllMailMenuPopper from './mailMenuPopper';

import { EMAIL_STATUS_CODE } from '../../../constants';
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  row: {
    '&:nth-of-type(even)': {
      background: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  cell: {
    padding: '8px',
    maxWidth: '600px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  btn: { color: theme.palette.primaryColor },
  icon: { width: '16px', marginRight: '4px' },
  member: { color: theme.palette.primaryColor, width: '16px' },
  starLbl: {
    pointerEvents: 'none',
    height: '100%',
    minHeight: 23,
  },
}));

function AllListTableRow(props) {
  const { row, cells, display, setSelectedCustomerIds, checked } = props;
  const common = commonStyles();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const onMouse = (e) => setAnchorEl(e.currentTarget);
  const onMouseLeave = () => setAnchorEl(null);

  const handleChange = (id) => {
    setSelectedCustomerIds((state) => {
      if (checked) return state.filter((i) => i !== id);
      return [...state, id];
    });
  };
  const displayFunc = (e) => {
    let beforeweek = '';
    let beforeday = '';
    let today = '';
    switch (String(e)) {
      case '0':
        beforeweek = row.before2To7DaysSendCount;
        beforeday = row.yesterdaySendCount;
        today = row.todaySendCount;
        break;
      case '1':
        beforeweek = row.mail7DayBulk + row.mail6DayBulk + row.mail5DayBulk
          + row.mail4DayBulk + row.mail3DayBulk + row.mail2DayBulk;
        beforeday = row.mail1DayBulk;
        today = row.mail0DayBulk;
        break;
      case '2':
        beforeweek = row.mail7DaySolo + row.mail6DaySolo + row.mail5DaySolo
          + row.mail4DaySolo + row.mail3DaySolo + row.mail2DaySolo;
        beforeday = row.mail1DaySolo;
        today = row.mail0DaySolo;
        break;
      default:
        beforeweek = row.before2To7DaysSendCount;
        beforeday = row.yesterdaySendCount;
        today = row.todaySendCount;
        break;
    }
    return { beforeweek, beforeday, today };
  };
  return (
    <TableRow className={classes.row} onMouseEnter={onMouse} onMouseLeave={onMouseLeave}>
      <TableCell className={classes.cell}>
        <Checkbox
          color="primary"
          checked={checked}
          onChange={() => { return handleChange(row.customerId); }}
        />
      </TableCell>
      {cells.map((c) => {
        let result = row[c.id];
        switch (c.id) {
          case 'memberStatusCode':
            // 0:通常会員
            // 1:プレミアム申請中
            // 2:プレミアム会員
            // 3:プレミアム否認
            if (row.webs.length > 0) {
              result = row.webs[0].memberStatusCode;
              if (!row.webs[0].memberStatusCode) {
                result = '';
              } else if (row.webs[0].memberStatusCode > 0) {
                result = (
                  <Link href={`${URL_MAPPER.web}?memberId=${row.webs[0].memberId}`}>
                    <PremiumIcon className={classes.member} />
                  </Link>
                );
              } else {
                result = (
                  <Link href={`web/${row.webs[0].memberId}`}>
                    <Language className={classes.member} />
                  </Link>
                );
              }
            } else {
              result = '';
            }
            break;
          case 'memberId':
            if (row.webs.length > 0) {
              result = row.webs[0].memberId;
            } else {
              result = '';
            }
            break;
          case 'emailStatusCode':
            result = EMAIL_STATUS_CODE[row[c.id]];
            break;
          case 'userLevel':
            result = row[c.id] ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StarRateIcon size="large" className={classes.starLbl} />
                {row[c.id]}
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StarRateIcon size="large" className={classes.starLbl} />
                なし
              </div>
            );
            break;
          case 'before2To7DaysSendCount':
            result = displayFunc(display).beforeweek;
            break;
          case 'yesterdaySendCount':
            result = displayFunc(display).beforeday;
            break;
          case 'todaySendCount':
            result = displayFunc(display).today;
            break;
          default:
            break;
        }
        return (
          <TableCell className={classes.cell} key={c.id} style={c.style}>
            <NormalTooltip
              title={result}
              className={classes.tooltip}
              classes={{ tooltip: NormalTooltip }}
            >
              <Typography className={common.small}>
                {result}
              </Typography>
            </NormalTooltip>
          </TableCell>
        );
      })}
      <AllMailMenuPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        row={row}
      />
    </TableRow>
  );
}

const areEqual = (prev, next) => {
  return prev.row.customerId === next.row.customerId
    && prev.checked === next.checked
    && prev.selected === next.selected
    && prev.display === next.display;
};

export default React.memo(AllListTableRow, areEqual);
