import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useEffect, useState } from 'react';

export default function CheckboxList(props) {
  const {
    list, // labelとidを含むオブジェクトの配列
    onChange,
    icon = null,
    defaultSelected,
    clearCount,
  } = props;

  const [checkedList, setCheckedList] = useState(() => {
    const ids = defaultSelected.map(item => Number(item.uniqueId ? item.uniqueId : item.id));
    const result = {};
    list.forEach(item => {
      result[`checkboxlist${item.id}`] = ids.includes(item.id);
    });
    return result;
  });

  const clickOnCheckbox = (event) => {
    const { name, checked } = event.target;
    setCheckedList({ ...checkedList, [name]: checked });
  };

  useEffect(() => {
    const result = list.filter(item => checkedList[`checkboxlist${item.id}`]);
    onChange(result);
  }, [checkedList]);

  // すべてクリアボタン押下時にrenderを発生させるためのstate
  const [clearEffectCount, setClearEffectCount] = useState(0);

  useEffect(() => {
    // 画面初回表示では、全て解除の処理は発動しない
    if (clearCount !== 0) {
      // checkedListをall falseにした後、resultを生成する
      const keys = Object.keys(checkedList);
      keys.forEach(key => {
        checkedList[key] = false;
      });
      const result = list.filter(item => checkedList[`checkboxlist${item.id}`]);
      onChange(result);
      // 全て解除の結果を画面にも反映させる
      setCheckedList(checkedList);
      setClearEffectCount(clearEffectCount + 1);
    }
  }, [clearCount]);

  useEffect(() => {
    // すべて解除ボタン押下時に画面にもチェックの解除を反映するため、renderをさせる
  }, [clearEffectCount]);

  return (
    <FormGroup>
      {list.map(l => (
        <FormControlLabel
          key={l.id}
          control={<Checkbox
            checked={checkedList[`checkboxlist${l.id}`]}
            id={l.id}
            name={`checkboxlist${l.id}`}
            value={JSON.stringify(l)}
            onChange={clickOnCheckbox}
          />}
          label={(
            <>
              {icon ?? icon}
              <span>{l.label}</span>
            </>
          )}
        />
      ))}
    </FormGroup>
  );
}
