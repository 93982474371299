import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../styles';
import SendTimeChangeWindow from './changeTime';
import TreeWindow from '../../common/commonTree/treeWindow';
import Modal from '../../common/modal';
import NormalTooltip from '../../styles/tooltip';
import MailLibraryPage from '../../pages/mailLibrary';

const useStyles = makeStyles((theme) => ({
  select: {
    // minWidth: '160px',
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
    },
    '& .MuiSelect-icon': {
      width: '17px',
    },
  },
  wrap: {
    // marginRight: '24px',
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
  },
  itemWrap: {
    border: '1px #c8c8c8 solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 8px',
    marginBottom: '8px',
    flexWrap: 'nowrap',
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
  bodyClass: { overflow: 'hidden' },
  labelWrap: {
    flexShrink: 0,
    marginRight: 8,
  },
  valueWrap: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
  },
}));

function DialogComponent(props) {
  const { values, open, setOpen } = props;
  let component;
  const classes = useStyles();

  switch (values.type) {
    case 'sendTime':
      component = <SendTimeChangeWindow open={open} setOpen={setOpen} values={values} />;
      break;
    case 'cc':
      component = <TreeWindow open={open} setOpen={setOpen} values={values.component} />;
      break;
    case 'library':
      // TODO: (PC)ライブラリ用のパーツを入れ込み
      // eslint-disable-next-line max-len
      component = (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          header={false}
          noPadding
          bodyClassName={classes.bodyClass}
        >
          <MailLibraryPage dblclick={(e) => { values.function(e); setOpen(false); }} />
        </Modal>);
      break;
    default:
      return '';
  }
  return component;
}

function MailChangeBtn(props) {
  const { values } = props;
  const classes = useStyles();
  const common = commonStyles();
  // Fix: Failed prop type:
  // Invalid prop `in` of type `number` supplied to `ForwardRef(Fade)`, expected `boolean`.
  const [open, setOpen] = useState(false);

  return (
    <Grid container className={`${values.disabled ? classes.disable : ''} ${classes.itemWrap}`} style={values.style}>
      <Typography className={`${common.title4} ${classes.labelWrap}`}>{values.label}</Typography>
      <NormalTooltip title={values.state}>
        <Typography className={`${common.title4} ${classes.valueWrap}`}>{values.state}</Typography>
      </NormalTooltip>
      <Button
        onClick={() => setOpen(!open)}
        type="button"
        disabled={values.disabled}
      >
        <Typography className={`${common.strong} ${classes.blue}`}>変更</Typography>
      </Button>
      <DialogComponent values={values} open={open} setOpen={setOpen} />
    </Grid>
  );
}

export default MailChangeBtn;
