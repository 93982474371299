import React, { useState, useRef, useEffect } from 'react';
import { MuiThemeProvider, makeStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  spinBtnVanished: {
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  input: {
    '& div': {
      '&::before': { transform: 'scaleX(0)' },
      '&:hover::before': { borderBottom: `2px solid ${theme.palette.primaryColor} !important`, transform: 'scaleX(1)' },
    },
  },
}));

function UserTextField(props) {
  const { initial, validate } = props;
  const textState = useRef();
  const [effect, setEffect] = useState(0);

  const initialStyle = {
    margin: initial.margin ? initial.margin : null,
    color: initial.fontColor ? initial.fontColor : null,
    fontWeight: initial.fontBold ? initial.fontBold : null,
    width: initial.width ? initial.width : null,
    fontSize: initial.fontSize ? initial.fontSize : null,
  };

  const classes = useStyles();

  useEffect(() => {
    textState.current = initial.state;
    setEffect(effect + 1);
  }, [initial.state]);

  const formLabelsTheme = createTheme({
    overrides: {
      MuiInputLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
          fontSize: '22px',
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={formLabelsTheme}>
      <TextBaseField
        inputProps={{
          style: initialStyle,
          className: initial.type === 'number' ? classes.spinBtnVanished : '',
        }}
        key={effect}
        error={!initial.state && initial.required && validate}
        required={initial.required}
        fullWidth={!initial.notfullwidth}
        label={initial.title}
        type={initial.type}
        defaultValue={textState.current}
        className={`${initial.type === 'number' ? classes.spinBtnVanished : ''} ${classes.input}`}
        onBlur={(e) => {
          initial.set(e.target.value, initial);
        }}
        InputLabelProps={{
          shrink: true,
          style: { color: '#333333', fontWeight: 'bold' },
          formlabelclasses: {
            asterisk: 'asterisk',
          },
        }}
        validator={initial.validator}
        convertor={initial.convertor}
        errorCallback={initial.errorCallback}
      />
    </MuiThemeProvider>
  );
}

export default React.memo(UserTextField);
