import { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import commonStyles from '../../styles';

import Modal from '../../common/modal';
import Calendar from '../../common/calendar';

import { dateFormatter } from '../../../commonFunction';

const useStyles = makeStyles({
  button: {
    '&::before, &::after': {
      background: '#fff',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& label': { width: '100%' },
    '& > .MuiGrid-root': { background: '#fff' },
  },
});

function ChangeScheduleModal(props) {
  const { open, onClose, onClickOK } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const [date, setDate] = useState('');

  const options = {
    require: false,
    type: 'time',
    state: [date],
    setState: (v) => setDate(v),
    min: dateFormatter(new Date(), 'YYYY-MM-DD hh:mm'),
    fromMail: true,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="送信時間の変更"
      width={360}
      buttons={[
        {
          onClick: () => onClose(),
          label: 'CANCEL',
          className: `${baseClasses.buttonsSecondary} ${classes.button}`,
        },
        {
          className: `${baseClasses.buttonsPrimary} ${classes.button}`,
          onClick: () => onClickOK(date),
          label: 'OK',
        },
      ]}
    >
      <Grid className={classes.root}>
        <p className={baseClasses.bass}>送信開始日時を設定してください</p>
        <Calendar data={options} />
      </Grid>
    </Modal>
  );
}

export default ChangeScheduleModal;
