import client from '../httpClient';

const responseDeleteMediumApi = (pathParameter, params) => {
  return client.delete(
    `/response-types/${pathParameter}`,
    JSON.stringify(params),
  );
};

export default responseDeleteMediumApi;
