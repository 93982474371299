import { useRef } from 'react';
import { Grid } from '@material-ui/core';
import CustomerMainItem from './parts/customerMainItem';
import { CustomerMainFunctionProvider } from '../../containers/customerMain/customerMainFunctionContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import SideMenuTabs from './parts/sideMenuTabs';

function MainItem({
  customer,
  getComponent,
  getParts,
  getEditDialog,
  divisionsGetResponseTree,
  className,
  isCloesdCustomerDetail,
}) {
  const ref = useRef(null);
  const isIntersecting = useIntersectionObserver(ref, {
    threshold: [0.01, 0.99],
  });

  return (
    <Grid
      ref={ref}
      className={className}
      style={{ minHeight: 780, width: '100%' }}
      key={customer.customerId}
    >
      {isIntersecting && (
        <Grid>
          <CustomerMainItem
            customer={customer}
            getComponent={getComponent}
            getParts={getParts}
            getEditDialog={getEditDialog}
            divisionsGetResponseTree={divisionsGetResponseTree}
            isCloesdCustomerDetail={isCloesdCustomerDetail}
          />
          <CustomerMainFunctionProvider>
            <SideMenuTabs customer={customer} />
          </CustomerMainFunctionProvider>
        </Grid>
      )}
    </Grid>
  );
}

export default MainItem;
