import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getSettingApi from '../../../apis/divisionMaster/getSettingApi';
import getDivisionMastersApi from '../../../apis/divisionMaster/getDivisionMastersApi';
import putDivisionMastersApi from '../../../apis/divisionMaster/putDivisionMastersApi';
import getOwnDivisionApi from '../../../apis/divisionMaster/getOwnDivisionApi';
import { updateDivisionMasters } from '../../../store/divisionMaster/divisionMasters';
import { updateSetting } from '../../../store/divisionMaster/setting';
import store from '../../../store';

import DivisionMaster from '../../../components/division/divisionMaster/divisionMaster';

import { useLoading } from '../../../hooks';

function DivisionMasterContainer() {
  const [divisions, setDivisions] = useState([]);
  const [currentDivision, setCurrentDivision] = useState(null);
  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();
  /**
   * @typedef { import(
   *   "../../../apis/divisionMaster/getSettingApiTypes"
   * ).SettingGetResponse } SettingGetResponse
   * @type { SettingGetResponse }
   */
  const setting = useSelector((state) => state.setting);

  /**
   * @typedef { import(
   *   '../../../apis/divisionMaster/divisionMasterGetSelectionApiTypes'
   * ).DivisionMastersGetResponse } DivisionMastersGetResponse
   * @typedef { import(
   *   '../../../apis/divisionMaster/divisionMasterGetSelectionApiTypes'
   * ).Division } Division
   * @typedef {{
   *   message: string;
   *   description: string;
   *   data: {
   *     divisions: Array<Division & { toDivisionId: number }>;
   *     toDivisions: DivisionMastersGetResponse['data']['toDivisions'];
   *   }
   * }} DivisionMasters
   * @type { DivisionMasters }
   */
  const divisionMasters = useSelector((state) => state.divisionMasters);

  /**
   * 「確定」ボタンクリックで「移動先の組織」を変更する
   * - PUT /division-masters に更新リクエストを送信
   *
   * @type { () => Promise<void> }
   */
  const onClickConfirmUpdateDivisionMasters = useCallback(async () => {
    /**
     * PUT /division-masters するときのbodyを生成
     * - 「移動先の組織」を変更していないユーザーを含めるとバリデーションエラーになるため除外
     *
     * @typedef { import(
     *   '../../../apis/divisionMaster/putDivisionMastersApiTypes'
     * ).DivisionMastersPutRequestBody } DivisionMastersPutRequestBody
     *
     * @type { DivisionMastersPutRequestBody }
     */
    const divisionMastersPutRequestBody = {
      divisions: divisionMasters.data.divisions.map((division) => {
        return {
          userId: division.userId,
          positionCode: division.positionCode,
          fromDivisionId: division.fromDivisionId,
          toDivisionId: division.toDivisionId,
        };
      })
        // 「移動先の組織」を変更していないユーザーを除外
        .filter((division) => division.fromDivisionId !== division.toDivisionId),
    };
    // 「移動先の組織」を全く変更していない場合はリクエストを送信しない
    if (divisionMastersPutRequestBody.divisions.length) {
      divisionMastersPutRequestBody.centerDivisionId = currentDivision.divisionId;
      await putDivisionMastersApi(divisionMastersPutRequestBody);
      // ローカルデータの更新
      const newDivisions = divisionMasters.data.divisions.map((division) => {
        return {
          ...division,
          fromDivisionId: division.toDivisionId,
        };
      });
      const newDivisionMasters = {
        ...divisionMasters,
        data: {
          ...divisionMasters.data,
          divisions: newDivisions,
        },
      };
      dispatch(updateDivisionMasters(newDivisionMasters));
    }
  }, [divisionMasters.data.divisions]);

  /**
   * 「移動先の組織」を変更する
   *
   * @typedef { import('react').ChangeEvent<HTMLInputElement> } Event
   * @type { (divisionId: number; divisionObj: DivisionMasters['data']['divisions'][0]) => void }
   */
  const onChangeUserDivisionId = useCallback((divisionId, divisionObj) => {
    /**
     * 「移動先の組織」を更新
     *
     * @type { DivisionMasters['data']['divisions'] }
     */
    const { divisionMasters: tmp } = store.getState();
    const newDivisions = tmp.data.divisions.map((division) => {
      if (
        divisionObj.userId === division.userId
        && divisionObj.positionCode === division.positionCode
        && divisionObj.fromDivisionId === division.fromDivisionId
      ) {
        return {
          ...division,
          toDivisionId: divisionId,
        };
      }
      return { ...division };
    });
    /**
     * グローバルステイトDivisionMastersを更新
     * @type { DivisionMasters }
     */
    const newDivisionMasters = {
      ...divisionMasters,
      data: {
        ...divisionMasters.data,
        divisions: newDivisions,
      },
    };
    dispatch(updateDivisionMasters(newDivisionMasters));
  }, [divisionMasters]);

  useEffect(() => {
    (async () => {
      addLoading();
      const ownDivisions = await getOwnDivisionApi({ divisionSearchCode: 1 });
      const tmpDivisions = ownDivisions.divisions;
      const divisionMap = new Map(tmpDivisions.map(d => [d.divisionId, d]));

      const { responseHeader: tmpResponseHeader } = store.getState();
      const { mainDivision } = tmpResponseHeader;
      const defaultDivision = tmpDivisions?.[0] || null;
      const initialDivision = divisionMap.has(mainDivision?.id)
        ? divisionMap.get(mainDivision.id)
        : defaultDivision;
      setDivisions(ownDivisions.divisions || []);
      setCurrentDivision(initialDivision);
      removeLoading();
    })();
  }, []);

  useEffect(async () => {
    if (!currentDivision || !currentDivision.divisionId) return;
    addLoading();
    // エリアが変わってない場合、settingのAPIを叩かない
    if (setting.data.areaCode !== currentDivision.areaCode && currentDivision.areaCode) {
      const settingGetResponse = await getSettingApi(currentDivision.areaCode)
        .catch(() => removeLoading());
      if (!settingGetResponse) return;
      dispatch(updateSetting(settingGetResponse));
    }

    const res = await getDivisionMastersApi(currentDivision.divisionId)
      .catch(() => removeLoading());
    if (!res) return;

    /**
     * DivisionMastersのGETレスポンスにtoDivisionIdを追加して「移動先の組織」を変更できるようにする
     *
     * @type { DivisionMasters['data']['divisions'] }
     */
    const newDivisions = res.data.divisions.map((division) => {
      return {
        toDivisionId: division.fromDivisionId,
        ...division,
      };
    });

    /**
     * @type { DivisionMasters['data']['toDivisions'] }
     */
    const newToDivisions = res.data.toDivisions.sort((a, b) => a.divisionId - b.divisionId);

    /** @type { DivisionMasters } */
    const newDivisionMasters = {
      ...res,
      data: {
        ...res.data,
        divisions: newDivisions,
        toDivisions: newToDivisions,
      },
    };
    dispatch(updateDivisionMasters(newDivisionMasters));
    removeLoading();
  }, [currentDivision]);

  return (
    <DivisionMaster
      divisions={divisions}
      currentDivision={currentDivision}
      setCurrentDivision={setCurrentDivision}
      setting={setting}
      divisionMasters={divisionMasters}
      calendarData={setting.data.editLimitAt}
      onClickConfirmUpdateDivisionMasters={onClickConfirmUpdateDivisionMasters}
      onChangeUserDivisionId={onChangeUserDivisionId}
    />
  );
}

export default DivisionMasterContainer;
