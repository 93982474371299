import { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 16px 16px 0',
      width: '100%',
    },
    '&.disabled': {
      [commonTheme.breakpoints.up('md')]: {
        // background: '#C8C8C8',
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
  },
  input: {
    width: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiFormLabel-root[data-shrink=true]': {
        color: theme.palette.primaryColor,
      },
    },
  },
}));

function SearchPartsText(props) {
  const { values } = props;
  const classes = useStyles();
  const [localValue, setLocalValue] = useState(values.state);
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  useEffect(() => {
    setLocalValue(values.state);
  }, [values.state]);

  return (
    <Grid className={`${classes.root} ${values.className} ${values.disabled && 'disabled'}`}>
      <TextField
        label={isSP && values.label}
        className={classes.input}
        checked={values.state}
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={() => values.setState(localValue)}
        name={values.label}
        placeholder={values.place}
        multiline={values?.multiline}
        inputProps={{
          readOnly: values.readOnly,
        }}
      />
    </Grid>
  );
}

export default SearchPartsText;
