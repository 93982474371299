import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const allocationSettingGetOnAreaCodeSlice = createSlice({
  name: 'allocationSettingGetOnAreaCode',
  initialState: { allocationSettingGetOnAreaCode: initialState },
  reducers: {
    allocationSettingGetOnAreaCodeApi: (state, data) => {
      state.allocationSettingGetOnAreaCode = data.payload;
    },
  },
});

export const { allocationSettingGetOnAreaCodeApi } = allocationSettingGetOnAreaCodeSlice.actions;

export default allocationSettingGetOnAreaCodeSlice.reducer;
