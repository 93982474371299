import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import NormalTooltip from '../../styles/tooltip';
import HolidayTel from '../../common/holidayTel';
import ContactTime from '../../common/contactTime';
import { useHolidayTimeContext } from './holidayTimeContext';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    marginBottom: 6,
    padding: '4px 8px',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  button1: {
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  button2: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 0',
    width: 59,
    [commonTheme.breakpoints.down('sm')]: {
      minWidth: 59,
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    width: 40,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  contactTime: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    marginBottom: 4,
    '&::after': {
      content: '""',
      display: 'block',
      background: '#F3F3F3',
      width: '150%',
      height: 4,
      position: 'absolute',
      zIndex: 1,
      right: 0,
      bottom: '-4px',
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    marginRight: 24,
  },
  listVal: {
    textTransform: 'none',
  },
});

function CustomerTime(props) {
  const { setTab } = props;
  const { current, selectHolidayTel, clickSelectBoxFunc, isSp } = useHolidayTimeContext();

  const classes = useStyles();
  const baseClasses = commonStyles();

  let contents;

  // モーダル 連絡がつきやすい時間帯
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  // モーダル グラフ
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const getSticsTels = () => {
    let text = '';
    for (let i = 0; i < current.statisticsTels?.length; i += 1) {
      if (text === '') {
        text = current.statisticsTels[i];
      } else {
        text = text + '/' + current.statisticsTels[i];
      }
    }
    return text;
  };

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          <ListItem
            className={classes.listItem}
          >
            <Button
              className={classes.listBtn}
              onClick={handleOpen1}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                連絡がつきやすい時間帯
              </span>
              <span
                className={`${baseClasses.title4} ${classes.listVal}`}
              >
                {(current.convenientTime ? current.convenientTime : '') + ' ' + (current.otherConvenientTime ? current.otherConvenientTime : '')}
              </span>
            </Button>
          </ListItem>
          <ListItem
            className={classes.listItem}
          >
            <Button
              className={classes.listBtn}
              onClick={() => setTab(6)}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                統計
              </span>
              <span
                className={`${baseClasses.title4} ${classes.listVal}`}
              >
                {getSticsTels()}
              </span>
            </Button>
            <Button
              variant="contained"
              className={`${baseClasses.buttonsPrimary} ${classes.button2}`}
              onClick={() => setTab(6)}
            >
              グラフ
            </Button>
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <NormalTooltip title={`${current.convenientTime ? current.convenientTime + ' ' : ''}${current.otherConvenientTime ? current.otherConvenientTime : ''}`}>
          <Button className={classes.button1} onClick={handleOpen1}>
            <Typography className={baseClasses.title6}>
              連絡がつきやすい時間帯
            </Typography>
            <Typography className={classes.txt}>
              {current.convenientTime} {current.otherConvenientTime}
            </Typography>
          </Button>
        </NormalTooltip>
        <NormalTooltip title={getSticsTels()}>
          <Button className={classes.button1} onClick={handleOpen2}>
            <Typography className={baseClasses.title6}>
              統計
            </Typography>
            <Typography className={classes.txt}>
              {getSticsTels()}
            </Typography>
          </Button>
        </NormalTooltip>
        <Button
          variant="contained"
          className={`${baseClasses.buttonsPrimary} ${classes.button2}`}
          onClick={handleOpen2}
        >
          グラフ
        </Button>
        <Grid className={classes.contactTime}>
          <ContactTime
            open={open2}
            onClose={handleClose2}
            customerId={current.customerId}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <HolidayTel
        initialParam={selectHolidayTel}
        onClickSelectButton={clickSelectBoxFunc}
        open={open1}
        onClose={handleClose1}
        category="time"
      />
    </Grid>
  );
}

export default React.memo(CustomerTime);
