import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
export const smsCustomerUpdateSlice = createSlice({
  name: 'smsCustomerUpdate',
  initialState: { smsCustomerUpdate: initialState },
  reducers: {
    updateSmsCustomerApi: (state, data) => {
      state.smsCustomerUpdate = data.payload;
    },
  },
});

export const { updateSmsCustomerApi } = smsCustomerUpdateSlice.actions;

export default smsCustomerUpdateSlice.reducer;
