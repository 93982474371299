import { useState } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SmsDrawer from './smsTalkDrawer';
import SmsTalkEditDialog from './smsTalkEditDialog';
import commonStyles from '../../../styles';

const useStyles = makeStyles({
  header: {
    background: '#fff',
    padding: '16px',
    width: '100%',
    top: 0,
    zIndex: 3,
    justifyContent: 'space-between',
  },
  pagerIcon1: {
    fontSize: 16,
  },
  btn: {
    minWidth: 'auto',
  },
  memo: {
    '& > p': {
      fontSize: '13px',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  typo: {
    margin: '0 8px',
    maxWidth: 'calc(100% - 88px)',
    '& > p': {
      textAlign: 'center',
      '&:not(:first-child)': {
        marginTop: '4px',
      },
    },
  },
  contentShorteningLblIcon: {
    marginLeft: 2,
  },
});

export default function SmsHeaderSp(props) {
  const {
    data,
    customer,
    nameState,
    setNameState,
    memoState,
    setMemoState,
    updateFunction,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [openDrawer1, setOpenDrawer1] = useState(false); // メニューの制御
  const [openDrawer2, setOpenDrawer2] = useState(false); // 顧客名選択画面の制御
  const [handleOpenEditWindowMemo, setHandleOpenEditWindowMemo] = useState(false); // メモ変更画面の制御
  const [handleOpenEditWindowName, setHandleOpenEditWindowName] = useState(false); // 顧客名変更画面の制御
  const TALK_MENU_SELECT = {
    menus: {
      0: 'お客様名の変更',
      1: 'メモの変更',
    },
  };

  const arrStates = [
    {
      type: 'text',
      key: 'customerName',
      label: 'お客様名',
      required: true,
      list: [
        `${customer.lastName !== null ? customer.lastName : ''}${customer.firstName !== null ? customer.firstName : ''}`,
        `${customer.subLastName !== null ? customer.subLastName : ''}${customer.subFirstName !== null ? customer.subFirstName : ''}`,
      ],
      state: nameState,
      setState: (e) => { return setNameState(e); },
    },
    {
      type: 'text',
      key: 'memo',
      label: 'メモ',
      required: true,
      state: memoState,
      setState: (e) => { return setMemoState(e); },
    },
  ];

  const onClose = (type) => {
    setOpenDrawer2(false);
    if (type === 'name') {
      setHandleOpenEditWindowName(false);
    } else {
      setHandleOpenEditWindowMemo(false);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      className={classes.header}
    >
      {/* 戻るボタン */}
      <Button
        onClick={() => { data.onCloseFunc(); }}
        className={classes.btn}
      >
        <ArrowBackIosIcon className={`${common.icon} ${classes.pagerIcon1}`} />
      </Button>
      <Grid className={classes.typo}>
        <Typography
          className={common.title2}
        >
          {`${nameState} 様`}
        </Typography>
        <Grid className={classes.memo}>
          <Typography>メモ：{memoState}</Typography>
          <Typography>{data.talkData.phoneNumber}</Typography>
        </Grid>
      </Grid>
      {/* Drawer起動用ボタン */}
      <Button
        onClick={() => { setOpenDrawer1(true); }}
        className={classes.btn}
      >
        <MoreHorizIcon className={common.icon} />
      </Button>
      {/* メニュー */}
      <SmsDrawer
        openDrawer={openDrawer1}
        setOpenDrawer={setOpenDrawer1}
        setOpenDrawer2={setOpenDrawer2}
        handleOpenEditWindow={handleOpenEditWindowMemo}
        setHandleOpenEditWindow={setHandleOpenEditWindowMemo}
        values={TALK_MENU_SELECT}
      />
      {/* お客様名選択画面 */}
      <SmsDrawer
        openDrawer={openDrawer2}
        setOpenDrawer={setOpenDrawer2}
        setOpenDrawer2={setOpenDrawer2}
        handleOpenEditWindow={setHandleOpenEditWindowName}
        setHandleOpenEditWindow={setHandleOpenEditWindowName}
        type="edit"
        values={arrStates[0]}
        updateFunction={updateFunction}
      />
      {/* お客様名編集画面 */}
      <SmsTalkEditDialog
        values={arrStates[0]}
        handleOpenEditWindow={handleOpenEditWindowName}
        setHandleOpenEditWindow={setHandleOpenEditWindowName}
        onClose={() => { onClose('name'); }}
        updateFunction={updateFunction}
      />
      {/* メモ編集画面 */}
      <SmsTalkEditDialog
        values={arrStates[1]}
        handleOpenEditWindow={handleOpenEditWindowMemo}
        setHandleOpenEditWindow={setHandleOpenEditWindowMemo}
        onClose={() => { onClose('memo'); }}
        updateFunction={updateFunction}
      />
    </Grid>
  );
}
