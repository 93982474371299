import React, { useCallback, useState } from 'react';
import DivisionInformationComponent from '../../components/divisionInformation/divisionInformationComponent';

export default function DivisionInformationContainer() {
  /** @type { [areaCode: number, setAreaCode: (areaCode: number) => void] } */
  const [selectedAreaCode, setSelectedAreaCode] = useState(30);

  /**
   * @typedef { import('react').ChangeEvent<{}> } E
   * @type { (_: E, nextTabAreaCode: number) => void }
   */
  const onChangeAreaTab = useCallback((selectedTabAreaCode) => {
    setSelectedAreaCode(Number(selectedTabAreaCode));
  }, []);

  return (
    <DivisionInformationComponent
      selectedAreaCode={selectedAreaCode}
      onChangeAreaTab={onChangeAreaTab}
    />
  );
}
