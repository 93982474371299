// crm
/**
 * データ結合のタイプ定数
 */
export const JOIN_DATA_TYPES = {
  INITIAL: 0,
  /**
   * データ結合 1
   */
  MERGE: 1,
  /**
   * WEB戻し登録 2
   */
  RETURN: 2,
  /**
   * WEB再反登録 3
   */
  REBELLION: 3,
};

/**
 * データ結合
 * どこからの反響情報タイプ定数
 */
export const JOIN_DATA_FROM_TYPES = {
  INITIAL: 0,
  /**
   * 検索一覧
   */
  SEARCH_LIST: 1,
  /**
   * 反響未処理（WEB）一覧
   */
  WEB_RESPONSE_LIST: 2,
  /**
   * 源泉未処理一覧
   */
  FLYER_SALES_LIST: 3,
};

// /**
//  * ①：推進権限＋その他
//  * →roleGroup=41、42、51、52、54、55
//  */
// export const AUTHORITIES_PROMOTION = [41, 42, 51, 52, 54, 55];
// /**
//  * ②：営業権限（再反基準日前）③：営業権限（配下）④：営業権限（非配下）
//  * →roleGroup=11、12、13、14、15、16、17、18、21、22、43、44
//  */
// export const AUTHORITIES_SALES = [11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 43, 44];
// /**
//  * ⑤：顧客満足権限
//  * →roleGroup=53、56
//  */
// export const AUTHORITIES_CUSTOMER_SATISFACTION = [53, 56];

/**
 * 表示するパネル
 */
export const DISPLAY_PANEL_LIST = {
  register: true,
  search: true,
  response: true,
  source: true,
  merge: true,
  height: 485,
};

/**
 * 反響振り分けアプリの接続先(URLのbase部分)
 */
export const SORT_APP_URL_BASES = {
  development: 'https://script.google.com/a/macros/openhouse-group.com/s/AKfycbzMWlYBUUC1nzLSJDK5QO6q5gr_TIC64NxcEpkPRCAG9KXXAMPUM3RYa1xjJMAW61Tu/exec?flg=1&resimpid=',
  production: 'https://script.google.com/a/macros/openhouse-group.com/s/AKfycbyOFIktdMZWY324cCk_jR44nOomGdYb-wYTuDHAtUdxmJmuyJu-VTjVsF6HlwnbMa9Y/exec?flg=1&resimpid=',
};

/**
 * 新SFA 環境名
 */
export const SORT_APP_ENV_NAME = {
  staging: 'STAGING',
  production: 'PRODUCTION',
};
