import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../styles';
import Modal from '../../common/modal';
import ChangeSmsComponent from './changeSmsComponent';

const useStyles = makeStyles((theme) => ({
  blue: {
    color: theme.palette.primaryColor,
    // marginRight: '16px',
  },
  itemWrap: {
    border: '1px #c8c8c8 solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 8px',
    marginBottom: '8px',
  },
  text: {
    marginRight: 'auto',
    marginLeft: '32px',
  },
}));

export default function ChangeSms(props) {
  const { values, user } = props;
  const classes = useStyles();
  const common = commonStyles();
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  const [beforeSend, setBeforeSend] = useState({ number: values.number, id: values.id });
  // モーダルを開くsetState
  const handleOpen = () => {
    console.log('GA-112-PC SMS連絡画面');
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  const closeandchange = () => {
    values.setList(beforeSend.number, beforeSend.id);
    handleClose();
  };
  return (
    <Grid container className={classes.itemWrap} style={values.style}>
      <Typography className={common.title4}>
        {values.label}
        <span style={{ color: '#D83420' }}>＊</span>
      </Typography>
      <Typography className={`${common.small} ${classes.text}`}>{values.number}</Typography>
      <Button
        type="button"
        onClick={handleOpen}
      >
        <Typography className={`${common.strong} ${classes.blue}`}>変更</Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        title="SMS送信先変更ウィンドウ"
        width={600}
        buttons={[
          { onClick: handleClose, label: 'キャンセル', className: common.buttonsSecondary },
          { onClick: closeandchange, label: '送信先を変更', className: common.buttonsPrimary },
        ]}
      >
        <ChangeSmsComponent
          values={values}
          customer={user}
          beforeSend={beforeSend}
          setBeforeSend={setBeforeSend}
        />
      </Modal>
    </Grid>
  );
}
