import React, {
  useState,
  useMemo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import UserTreeListSp from './userTreeListSp';
import UserTreeSearch from '../userTreeSearch';
import UserTreeMultipleListSp from './userTreeMultipleListSp';
import { createTreeList } from '../../commonFunc/helpers';

const useStyles = makeStyles((theme) => ({
  head: {
    margin: 16,
  },
  summary: {
    backgroundColor: '#F3F3F3',
    height: '24px',
    lineHeight: 1.8,
    minHeight: 'auto !important',
    '& .MuiAccordionSummary-expandIcon svg': {
      fill: theme.palette.primaryColor,
    },
  },
}));

function CustomerTreeWrapperSp(props) {
  const {
    list,
    noAffiliList,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    setTargetDivisionTree,
    getObjectSortFunction,
    headValue,
    showOnlyDivisions = false,
    setIsOpenTree,
    isMulti,
    isSelectUserWindow,
    hasLoading,
    clickableUserAndDivision,
  } = props;

  const classes = useStyles();
  // 表示する組織とユーザー
  const [displayDivision, setDisplayDivision] = useState([]);
  // 選択したデータのルート情報を格納
  const [displayDivisionRoot, setDisplayDivisionRoot] = useState([]);
  // 検索状態
  const [searchObj, setSearchObj] = useState('');

  // ツリー成形
  const treeList = useMemo(() => {
    return createTreeList(
      list,
      null,
      null,
      null,
      {
        divisionFlg: showOnlyDivisions,
        onlyUser: isSelectUserWindow,
      },
      {},
      isMulti,
      setTargetDivisionTree,
    );
  }, [list]);

  return (
    !hasLoading && (
      <Grid container wrap="wrap">
        <Grid className={classes.head} container justifyContent="flex-end" alignItems="center">
          <UserTreeSearch
            list={treeList}
            headValue={headValue}
            getObjectSortFunction={getObjectSortFunction}
            setSearchObj={setSearchObj}
            noAffiliList={noAffiliList}
            isSelectUserWindow={isSelectUserWindow}
            showOnlyDivisions={showOnlyDivisions}
          />
        </Grid>
        {isMulti ? (
          <UserTreeMultipleListSp
            list={treeList}
            selectUser={selectUser}
            setSelectUser={setSelectUser}
            displayDivision={displayDivision}
            setDisplayDivision={setDisplayDivision}
            displayDivisionRoot={displayDivisionRoot}
            setDisplayDivisionRoot={setDisplayDivisionRoot}
            searchObj={searchObj}
            showOnlyDivisions={showOnlyDivisions}
            clickableUserAndDivision={clickableUserAndDivision}
          />
        ) : (
          <UserTreeListSp
            list={treeList}
            noAffiliList={noAffiliList}
            selectUser={selectUser}
            setSelectUser={setSelectUser}
            selectDivision={selectDivision}
            setSelectDivision={setSelectDivision}
            displayDivision={displayDivision}
            setDisplayDivision={setDisplayDivision}
            displayDivisionRoot={displayDivisionRoot}
            setDisplayDivisionRoot={setDisplayDivisionRoot}
            searchObj={searchObj}
            setSearchObj={setSearchObj}
            setIsOpenTree={setIsOpenTree}
            setTargetDivisionTree={setTargetDivisionTree}
            showOnlyDivisions={showOnlyDivisions}
            isSelectUserWindow={isSelectUserWindow}
            clickableUserAndDivision={clickableUserAndDivision}
          />
        )}
      </Grid>
    )
  );
}

export default CustomerTreeWrapperSp;
