import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crmPageSelector, crmPageActions } from '../../store/crmPage';
import getResponseApi from '../../apis/webRes/webResGetApi';
import { webResGetApi } from '../../store/webRes/webResGetSlice';
import webResCreateCustomerApi from '../../apis/webRes/webResCreateCustomerApi';
import webResDeleteApi from '../../apis/webRes/webResDeleteApi';

import ResponseList from '../../components/responseList/responseList';

import { useLoading, useInterval } from '../../hooks';

import { CRM_RESPONSE_LIST } from '../../constants/loading';

function ResponseListContainer() {
  const dispatch = useDispatch();
  // 呼び出し元: src/components/pages/joinData/joinData.js
  const { isReady, forceReload } = useSelector(crmPageSelector);
  const { addLoading, removeLoading } = useLoading();

  const responsesObj = useSelector(
    /**
     * @param {import("../../store/type").Store} state
     */
    (state) => state.webResGet.webResGet,
  );

  /**
   * 直前のリクエストパラメーターを記録
   */
  const prevRequestRef = useRef({ offset: 0, limit: 25 });

  /**
   * 顧客検索画面でキーワード検索を走らせる
   * @param {string} searchWord 検索したいキーワード
   * @param {boolean} isForceSearch すでに同じキーワードが入っている場合でも再検索を走らせるか否か
   */
  const updateKeyword = useCallback((searchWord, isForceSearch = true) => {
    dispatch(crmPageActions.setSearchKeyword({ searchWord, isForceSearch }));
  }, []);

  /**
   * @param {{ offset: number; limit: number; } | undefined} params デフォルト値として直前のリクエストの値を再利用する
   */
  const getResponses = async (
    params = prevRequestRef.current,
    isShowLoading = true,
  ) => {
    if (isShowLoading) addLoading(CRM_RESPONSE_LIST);
    prevRequestRef.current = params;
    const response = await getResponseApi({ params })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (isShowLoading) removeLoading(CRM_RESPONSE_LIST);
      });
    if (!response && !response.data.responses) return;

    // 表示用の番号を採番する。responseIdは使わない。
    response.data.responses = response.data.responses.map((value, index) => {
      return { ...value, index: prevRequestRef.current.offset + index + 1 };
    });
    dispatch(webResGetApi(response.data));

    const wrapper = document.getElementById('responseList').parentNode;
    wrapper.scrollTo(0, 0);
  };

  /**
   * @param {number} responseId
   * @param {number} userId
   * @param {number} divisionId
   */
  const registerFunc = async (responseId, userId, divisionId) => {
    addLoading(CRM_RESPONSE_LIST);
    try {
      const res = await webResCreateCustomerApi({ responseId, userId, divisionId }, false);
      const customerId = res?.data.customerId;
      if (customerId) {
        const tmpResponse = responsesObj.responses.filter((r) => r.responseId === responseId);
        const {
          customerLastName,
          customerFirstName,
        } = tmpResponse.length !== 0 ? tmpResponse[0] : {};
        if (tmpResponse.length !== 0) updateKeyword(`${customerLastName || ''} ${customerFirstName || ''}`);
      }
      getResponses(undefined);
    } finally {
      removeLoading(CRM_RESPONSE_LIST);
    }
  };
  /**
   * @param {number} responseId
   */
  const deleteFunc = async (responseId) => {
    addLoading(CRM_RESPONSE_LIST);
    await webResDeleteApi(responseId, false)
      .then(() => {
        getResponses(undefined);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        removeLoading(CRM_RESPONSE_LIST);
      });
  };

  useEffect(() => {
    (async () => {
      getResponses();
    })();
    // eslint-disable-next-line
  }, []);

  // 呼び出し元: src/components/pages/joinData/joinData.js
  useEffect(() => {
    if (!isReady) {
      return;
    }
    (async () => {
      getResponses();
    })();
  }, [forceReload.responseList]);

  // 1分毎にAPIを実行
  useInterval(
    async () => {
      getResponses();
    },
    60000,
  );

  return (
    <ResponseList
      responsesObj={responsesObj}
      getResponses={getResponses}
      registerFunc={registerFunc}
      deleteFunc={deleteFunc}
      prevRequestRef={prevRequestRef}
    />
  );
}

export default ResponseListContainer;
