import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import MailSelectDrawerInAfterCall from '../parts/sp/mailSelectDrawerInafterCall';
import PhoneNumberSelectDrawerInAfterCall from '../parts/sp/phoneNumberSelectDrawerInafterCall';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-scrollPaper': {
      background: '#fff',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navList: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  navItem: {
    padding: 0,
    flex: 0,
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      flexWrap: 'wrap',
      whiteSpace: 'pre',
    },
    '&:not(:last-child)': {
      marginRight: '8px',
    },
  },
  navIcon: {
    padding: '4px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiButton-startIcon > svg': {
      fill: theme.palette.primaryColor,
      fontSize: '24px',
    },
    '&.isBook svg': {
      fontSize: '18px',
    },
    '&.isSend svg': {
      fontSize: '18px',
    },
  },
  button: {
    minWidth: 'auto',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    padding: '32px 24px 24px',
    justifyContent: 'space-between',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
  },
  middle: {
    background: '#fff',
    padding: 0,
    borderTop: 'none',
  },
  contents: {
    padding: '12px 16px',
  },
  blue: {
    color: theme.palette.primaryColor,
    marginRight: '16px',
  },
  fw400: { '& span': { fontWeight: 400 } },
  w100p: { width: '100%' },
}));

function AfterCallWindowSpComponent(props) {
  const {
    user,
    open,
    setOpen,
    onClose,
    meta,
    setMeta,
    setting,
    afterCallFunctions,
    responseHeader,
    libraries,
    mailParam,
    setMailParam,
    editorState,
    setEditorState,
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  // ライブラリ読み込み中
  const { addLoading, removeLoading } = useLoading();

  // モーダル開閉用のローカルステート
  const [openType, setOpenType] = useState({
    mail: false,
    sms: false,
  });

  // メールかSMSか 0: SMS, 1: メール
  const [selectType, setSelectType] = useState(-1);

  // SMSの選択ユーザーIDを保持
  const [defaultSmsUserId, setDefaultSmsUserId] = useState(0);

  const dispatch = useDispatch();
  const afterClose = () => {
    setOpen(false);
    onClose();
  };

  const mailOpenFunc = (boolean) => {
    setOpenType({ ...openType, mail: boolean });
  };

  const smsOpenFunc = (boolean) => {
    setOpenType({ ...openType, sms: boolean });
  };

  const loadLibraries = async (type) => {
    addLoading('getLibraryFromAfterCall');
    // ライブラリ取得
    await Promise.all([
      afterCallFunctions.getLibrarySms(responseHeader.userId),
      afterCallFunctions.getLibraryMail(responseHeader.userId),
    ]);
    removeLoading('getLibraryFromAfterCall');
    setSelectType(type);
  };

  useEffect(async () => {
    if (responseHeader.userId !== 0) {
      // 本当はダイアログの上に表示したい
      dispatch(changeConfirmMessage({
        title: '不通時のアフターフォロー',
        msgList: [`${user.lastName} ${user.firstName} 様にアフターフォローの`, 'SMSまたはメールを送りますか？'],
        buttons: (() => {
          const menus = [
            {
              label: '送らない',
              set: () => { afterClose(); },
              classes: `${common.buttonsSecondary} ${classes.fw400} ${classes.w100p}`,
            },
            {
              label: 'SMSを送る',
              set: () => { loadLibraries(0); },
              classes: `${common.buttonsSecondary} ${classes.w100p}`,
            },
          ];
          menus.push({
            label: 'メールを送る',
            set: () => { loadLibraries(1); },
            classes: `${common.buttonsSecondary} ${classes.w100p}`,
          });
          return menus;
        })(),
        onBackdropClick: () => {
          afterClose();
        },
      }));
    }
  }, [responseHeader.userId]);

  // SMS, メール変更時
  useEffect(() => {
    if (!open) {
      return;
    }
    if (selectType === 0) {
      setOpenType({ sms: true, mail: false });
      if (Object.keys(libraries.sms)) {
        // SMS: 種類の初期値を設定
        setting.kindSms.setState(meta.kindSms);
      }
    } else if (selectType === 1) {
      setOpenType({ mail: true, sms: false });
      if (Object.keys(libraries.mail)) {
        // メール: 種類の初期値を設定
        setting.kind.setState(meta.kind);
      }
    }
  }, [selectType]);

  return (
    <>
      <MailSelectDrawerInAfterCall
        open={openType.mail}
        setOpen={mailOpenFunc}
        afterClose={afterClose}
        user={user}
        selectType={selectType}
        setSelectType={setSelectType}
        setting={setting}
        meta={meta}
        setMeta={setMeta}
        afterCallFunctions={afterCallFunctions}
        responseHeader={responseHeader}
        libraries={libraries}
        mailParam={mailParam}
        setMailParam={setMailParam}
        editorState={editorState}
        setEditorState={setEditorState}
      />
      <PhoneNumberSelectDrawerInAfterCall
        open={openType.sms}
        setOpen={smsOpenFunc}
        afterClose={afterClose}
        user={user}
        selectType={selectType}
        setSelectType={setSelectType}
        setting={setting}
        meta={meta}
        setMeta={setMeta}
        afterCallFunctions={afterCallFunctions}
        responseHeader={responseHeader}
        libraries={libraries}
        mailParam={mailParam}
        setMailParam={setMailParam}
        editorState={editorState}
        setEditorState={setEditorState}
        defaultSmsUserId={defaultSmsUserId}
        setDefaultSmsUserId={setDefaultSmsUserId}
      />
    </>
  );
}

export default AfterCallWindowSpComponent;
