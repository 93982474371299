import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, List, ListItem, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import commonStyles from '../../../styles';
import GuidanceStatusCodeWindow from './guidanceStatusCodeWindow';
import CustomEditDialog from '../../../eleCommon/sp/customEditDialog';
import { GUIDANCE_STATUS_CODE, NUMBER_OF_GUIDES } from '../../../../constants';
import RangeSelect from '../../../common/rangeSelect';
import { useActionSchedule } from '../../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  centerMark: {
    width: 23,
    display: 'flex',
    marginBottom: '-27px',
    'justify-content': 'center',
    'align-items': 'flex-end',
    '&>p': {
      display: 'inline-block',
    },
  },
  item: {
    position: 'relative',
    marginBottom: 36,
    '&.shrink': {
      paddingTop: 16,
      '& .MuiInputLabel-formControl': {
        transform: 'translate(0, 3px) scale(0.7)',
        top: 0,
      },
      '& .MuiFormControl-root': {
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '100%',
        '& > .MuiInputBase-root': {
          height: '100%',
          minHeight: 32,
        },
      },
    },
  },
  selectWrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div > div': {
      width: 100,
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& > div > div > div': {
      width: 104,
    },
  },
  selectLbl: {
    position: 'absolute',
    top: 7,
    left: 0,
    pointerEvents: 'none',
  },
  select: {
    width: 100,
    '& .MuiFormLabel-root': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      top: '-10px',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 9px) scale(0.7)',
      },
    },
    '& .MuiSelect-root': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      textAlign: 'left',
    },
    '& .MuiSelect-select': {
      padding: '0 24px 0 0',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  guidanceList: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  guidanceItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `${theme.palette.primaryColor}29`,
    borderRadius: 30,
    padding: '0 6px 0 8px',
    margin: '0 4px 4px 0',
    position: 'relative',
    zIndex: 9,
  },
  guidanceTxt: {
    fontSize: 13,
    lineHeight: '1.6',
    marginRight: 6,
    paddingTop: 1,
  },
  guidanceBtn: {
    position: 'relative',
    display: 'block',
    minWidth: 'auto',
    width: 13,
    height: 13,
    padding: 0,
    background: theme.palette.primaryColor,
    borderRadius: '50%',
    '&:hover': {
      background: theme.palette.primaryColor,
    },
    '&::before': {
      content: '""',
      display: 'block',
      width: 7,
      height: 1,
      background: '#64CAF5',
      position: 'absolute',
      top: 6,
      left: 3,
      transform: 'rotate(45deg)',
    },
    '&::after': {
      content: '""',
      display: 'block',
      width: 7,
      height: 1,
      background: '#64CAF5',
      position: 'absolute',
      top: 6,
      left: 3,
      transform: 'rotate(-45deg)',
    },
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
  },
  listBtn: {
    borderBottom: '1px solid #F3F3F3',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    width: '100%',
    textAlign: 'left',
  },
  listBtn2: {
    borderBottom: '1px solid #F3F3F3',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    width: '100%',
    textAlign: 'left',
  },
  listTtl: {
    whiteSpace: 'nowrap',
    marginRight: 16,
  },
  listTxt: {
    '& span': {
      display: 'block',
      fontSize: 13,
    },
  },
  multiBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  multiBtn: {
    padding: '4px 0',
    minWidth: 80,
    minHeight: 24,
    // borderBottom: '1px solid #333',
    borderRadius: 0,
  },
  multiLbl: {
    textTransform: 'none',
  },
  multiVal: {
    textTransform: 'none',
  },
  multiTxt: {
    textTransform: 'none',
    margin: '0 10px',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  checkBox: {
    margin: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiTypography-root': {
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
    },
    '& .MuiCheckbox-root': {
      padding: 3,
    },
  },
  checkBoxMain: {
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  set: {
    transform: 'translate(0, -20px) scale(.7)',
    transformOrigin: 'left bottom',
  },
}));

function SortSelectMain(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();
  const {
    getComponent,
  } = props;

  const {
    searchInput,
    targetDivisionTree,
    setIsOpenTree,
  } = useActionSchedule();

  const menus = Object.keys(NUMBER_OF_GUIDES);

  const [guidanceType, setGuidanceType] = useState([]);
  const [numberOfGuidesMin, setNumberOfGuidesMin] = useState('');
  const [numberOfGuidesMax, setNumberOfGuidesMax] = useState('');

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  useEffect(() => {
    if (searchInput.searchConditionsRef.current.guidanceType.length === 0) {
      setGuidanceType([]);
    }
  }, [searchInput.searchConditionsRef.current.guidanceType]);

  // モーダル用
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const onNumberOfGuidesChange = (key, [min, max]) => {
    setNumberOfGuidesMin(min);
    setNumberOfGuidesMax(max);
    searchInput.searchConditionsRef.current[key + 'Min'] = min;
    searchInput.searchConditionsRef.current[key + 'Max'] = max;
  };

  const handleClick = (data) => {
    switch (data.key) {
      case 'guidanceType':
        // [GA009]画面を呼び出す
        handleOpen();
        break;
      default:
        break;
    }
  };

  // 選択押下時
  const selectionClick = (vals) => {
    setGuidanceType(vals);
    searchInput.searchConditionsRef.current.guidanceType = vals;
    // 揃いが選択されている場合
    if (vals.includes(9)) {
      searchInput.searchConditionsRef.current.isPairCheck = true;
    } else {
      searchInput.searchConditionsRef.current.isPairCheck = false;
    }
  };

  // 案内種別 ×押下時
  const closeClick = (code) => {
    let checks = searchInput.searchConditionsRef.current.guidanceType;
    checks = checks.filter((check) => {
      return check !== code;
    });
    setGuidanceType(checks);
    searchInput.searchConditionsRef.current.guidanceType = checks;
    // 揃いを削除した場合
    if (code === 9) {
      searchInput.searchConditionsRef.current.isPairCheck = false;
    }
  };

  // 揃い SP
  const isPairCheckChange = (event) => {
    // 揃いが選択されている場合
    if (event.target.checked) {
      searchInput.setChekedSearch({
        ...searchInput.chekedSearch,
        isPairCheck: true,
      });
      searchInput.searchConditionsRef.current.isPairCheck = true;
    } else {
      searchInput.setChekedSearch({
        ...searchInput.chekedSearch,
        isPairCheck: false,
      });
      searchInput.searchConditionsRef.current.isPairCheck = false;
    }
  };

  const sortSelectList = {
    guidanceType: {
      title: '案内種別',
      required: false,
      key: 'guidanceType',
      type: 'select',
      obj: {
        menus: NUMBER_OF_GUIDES,
      },
      state: guidanceType,
      tooltip: '',
      set: handleClick,
    },
  };

  const getLabel = (code) => {
    switch (Number(code)) {
      case 9:
        return (
          <span>揃い</span>
        );
      default:
        return (
          <span>
            {GUIDANCE_STATUS_CODE[code]}
          </span>
        );
    }
  };

  const onSelect1 = (val) => {
    if (numberOfGuidesMin !== val) {
      setNumberOfGuidesMin(val);
      searchInput.searchConditionsRef.current.numberOfGuidesMin = val;
    }
  };

  const onSelect2 = (val) => {
    if (numberOfGuidesMax !== val) {
      setNumberOfGuidesMax(val);
      searchInput.searchConditionsRef.current.numberOfGuidesMax = val;
    }
  };

  const getBox1 = (
    <Grid>
      {menus.map((data) => {
        return (
          <Button
            className={`${baseClasses.title3} ${classes.selectBtn} ${(numberOfGuidesMin === Number(data)) && 'select'}`}
            key={data}
            onClick={() => {
              onSelect1(data);
              handleClose2();
            }}
            disabled={Number(data) > Number(numberOfGuidesMax) && numberOfGuidesMax !== ''}
          >
            {NUMBER_OF_GUIDES[data]}
          </Button>
        );
      })}
    </Grid>
  );

  const getBox2 = (
    <Grid>
      {menus.map((data) => {
        return (
          <Button
            className={`${baseClasses.title3} ${classes.selectBtn} ${(numberOfGuidesMax === Number(data)) && 'select'}`}
            key={data}
            onClick={() => {
              onSelect2(data);
              handleClose3();
            }}
            disabled={Number(data) <= Number(numberOfGuidesMin && numberOfGuidesMin !== '')}
          >
            {NUMBER_OF_GUIDES[data]}
          </Button>
        );
      })}
    </Grid>
  );

  if (isSP) {
    contents = (
      <Grid>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={() => {
                setIsOpenTree(true);
              }}
            >
              <Typography className={`${baseClasses.title4} ${classes.listTtl}`}>
                担当者
              </Typography>
              {targetDivisionTree && (
                <Typography className={classes.listTxt}>
                  <span>{targetDivisionTree.groupName}</span>
                  <span>{targetDivisionTree.subName}</span>
                </Typography>
              )}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button className={classes.listBtn} onClick={handleOpen}>
              <Typography className={`${baseClasses.title4} ${classes.listTtl}`}>
                {sortSelectList.guidanceType.title}
              </Typography>
              <Grid className={classes.guidanceList}>
                {searchInput.searchConditionsRef.current.guidanceType.map((item) => (
                  <Grid key={item} className={classes.guidanceItem}>
                    <Typography className={classes.guidanceTxt}>
                      {getLabel(item)}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Grid className={classes.listBtn2}>
              <Typography className={baseClasses.title4}>
                案内回数
              </Typography>
              <Grid className={classes.multiBox}>
                <Button
                  className={classes.multiBtn}
                  onClick={handleOpen2}
                >
                  <span
                    className={`${baseClasses.title4} ${classes.multiVal}`}
                  >
                    {NUMBER_OF_GUIDES[numberOfGuidesMin] !== undefined ? NUMBER_OF_GUIDES[numberOfGuidesMin] : '-'}
                  </span>
                </Button>
                <Typography className={`${baseClasses.title6} ${classes.multiTxt}`}>
                  〜
                </Typography>
                <Button
                  className={classes.multiBtn}
                  onClick={handleOpen3}
                >
                  <span
                    className={`${baseClasses.title4} ${classes.multiVal}`}
                  >
                    {NUMBER_OF_GUIDES[numberOfGuidesMax] !== undefined ? NUMBER_OF_GUIDES[numberOfGuidesMax] : '-'}
                  </span>
                </Button>
                <CustomEditDialog
                  type="buttonNone"
                  open={open2}
                  setOpen={setOpen2}
                  content={getBox1}
                  lbl="案内回数"
                />
                <CustomEditDialog
                  type="buttonNone"
                  open={open3}
                  setOpen={setOpen3}
                  content={getBox2}
                  lbl="案内回数"
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.listItem}>
            <FormControlLabel
              className={classes.checkBox}
              size="small"
              control={
                <Checkbox
                  checked={searchInput.searchConditionsRef.current.isPairCheck}
                  name="matching"
                  color="primary"
                  className={classes.checkBoxMain}
                  onChange={isPairCheckChange}
                />
              }
              label="揃い"
            />
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid>
        <Grid className={`${classes.item} ${searchInput.searchConditionsRef.current.guidanceType.length && 'shrink'}`}>
          <Grid>
            {getComponent(sortSelectList.guidanceType)}
            <Grid className={classes.guidanceList}>
              {searchInput.searchConditionsRef.current.guidanceType.map((item) => (
                <Grid key={item} className={classes.guidanceItem}>
                  <Typography className={classes.guidanceTxt}>
                    {getLabel(item)}
                  </Typography>
                  <Button
                    className={classes.guidanceBtn}
                    onClick={() => {
                      closeClick(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.selectWrap}>
          <Typography className={`${baseClasses.title6} ${classes.selectLbl} ${numberOfGuidesMin ? classes.set : ''}`}>
            案内回数
          </Typography>
          <RangeSelect
            defaultValue={[
              searchInput.searchConditionsRef.current.numberOfGuidesMin,
              searchInput.searchConditionsRef.current.numberOfGuidesMax,
            ]}
            options={NUMBER_OF_GUIDES}
            onChange={(val) => onNumberOfGuidesChange('numberOfGuides', val)}
            center
            blod
            height={24}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      {open && (
        <GuidanceStatusCodeWindow
          open={open}
          onClose={handleClose}
          list={searchInput.searchConditionsRef.current.guidanceType}
          selectionClick={selectionClick}
        />
      )}
    </Grid>
  );
}

export default SortSelectMain;
