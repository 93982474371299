import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const libraryGetSlice = createSlice({
  name: 'libraryGet',
  initialState: { libraryGet: initialState },
  reducers: {
    libraryGetApi: (state, data) => {
      state.libraryGet = data.payload;
    },
  },
});

export const { libraryGetApi } = libraryGetSlice.actions;

export default libraryGetSlice.reducer;
