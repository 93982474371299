import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  root: {
    marginBottom: 24,
  },
  ttl: {
    marginBottom: 8,
  },
  box: {
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: 8,
    minHeight: 41,
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
  },
});

function SimulatorStore(props) {
  const { ttl, scores } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid
      className={classes.root}
    >
      <Typography className={`${baseClasses.title3} ${classes.ttl}`}>
        {ttl}
      </Typography>
      <Grid className={classes.box}>
        {scores.map((list) => (
          <Typography key={list.storeName} className={classes.txt}>
            {Number(list.score).toFixed(6)}：{list.storeName}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
}

export default SimulatorStore;
