/**
 * このファイルで各入力値のバリデーションを定義する.
 * バリデーション関数の返り値は以下の形.
 */

/**
 * 最大入力文字数チェック
 * @param {string} value - チェックしたい値
 * @param {Object} config - 設定用オブジェクト(最大桁数)
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateMaxLength = (value, { maxLengthInt } = {}) => {
  const errors = [];
  if (value === '') {
    return errors;
  }
  if (value.length <= maxLengthInt) {
    return errors;
  }
  errors.push(`${maxLengthInt}文字まで入力できます`);
  return errors;
};

/**
 * 数値チェック
 * @param {string} value - チェックしたい値
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateNumber = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }
  const pattern = /^-?[0-9]+(\.[0-9]*)?$/;
  if (!pattern.test(value)) errors.push('入力内容が不正です');
  return errors;
};

/**
 * 整数値チェック
 * @param {string} value - チェックしたい値
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateInteger = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }
  if (!Number.isSafeInteger(Number(value))) {
    errors.push('整数値を入力してください');
  }
  return errors;
};

const isFloat = (num) => {
  const floatValue = parseFloat(num);
  if (Number.isNaN(floatValue)) {
    return false;
  }
  return floatValue % 1 === 0 || floatValue % 1 !== 0; // 整数か小数かを判定
};

/**
 * 小数値チェック
 * @param {string} value - チェックしたい値
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateFloat = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }
  if (!isFloat(value)) {
    errors.push('小数値を入力してください');
  }
  return errors;
};

/**
 * 最大精度チェック
 * @param {string} value - チェックしたい値
 * @param {Object} config - 設定用オブジェクト(最大精度)
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateNumericPrecision = (value, { precisionInt = 0 } = {}) => {
  const errors = [];

  let v = value; // 入力値が文字列以外であれば変換
  if (typeof v !== 'string') {
    v = String(v);
  }

  if (v === '') {
    return errors;
  }
  if (!Number.isSafeInteger(Number(v)) && !isFloat(v)) {
    return errors;
  }
  if (Number.isSafeInteger(Number(v))) {
    // 入力値が整数の場合チェック不要
    return errors;
  }
  // 小数点以下の桁数を取得
  const decimalPart = v.split('.')[1];
  if (!decimalPart) {
    return errors;
  }
  if (decimalPart.length > precisionInt) {
    errors.push(`小数点第${precisionInt}位まで入力できます`);
  }
  return errors;
};

/**
 * 最大最小値チェック
 * @param {string} value - チェックしたい値
 * @param {Object} config - 設定用オブジェクト(最大値、最小値) 最大値と最小値は小数も指定可能
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateMaxMin = (value, { minFloat, maxFloat = 0 } = {}) => {
  const errors = [];
  if (value === '') {
    return errors;
  }
  if (!Number.isSafeInteger(Number(value)) && !isFloat(value)) {
    return errors;
  }
  const v = parseFloat(value);
  if (minFloat !== undefined && v < minFloat) {
    errors.push(`${minFloat}以上の数値を入力してください`);
  }
  if (maxFloat !== undefined && v > maxFloat) {
    errors.push(`${maxFloat}以下の数値を入力してください`);
  }
  return errors;
};

/**
 * 郵便番号のバリデーションチェック関数
 * @param {string} value - チェックしたい値
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validatePostalCode = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }

  const pattern1 = /^\d{3}-\d{4}$/;
  const pattern2 = /^\d{7}$/;

  if (!pattern1.test(value) && !pattern2.test(value)) {
    errors.push('入力内容が不正です');
  }
  return errors;
};
// ハイフンが必須のパターン
const validatePostalCodeHyphen = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }

  const pattern = /^\d{3}-\d{4}$/;

  if (!pattern.test(value)) {
    errors.push('入力内容が不正です');
  }
  return errors;
};

/**
 * 電話番号チェック
 * @param {string} value - チェックしたい値
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateTel = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }

  const pattern1 = /^0(\d{1}-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d{1})-\d{4}$/;
  // 市外局番あり
  const pattern2 = /^\d{1,4}-\d{4}$/;
  // 市外局番なし
  const pattern3 = /^0[5789]0-\d{4}-\d{4}$/;
  // 携帯電話
  const pattern4 = /^0120-\d{3}-\d{3}$/;
  // フリーダイヤル

  if (
    !pattern1.test(value)
    && !pattern2.test(value)
    && !pattern3.test(value)
    && !pattern4.test(value)
  ) {
    errors.push('入力内容が不正です');
  }
  return errors;
};
// ハイフンありなし両方入力可能なパターン
const validateTelNum = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }

  const pattern1 = /^0(\d{1}-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d{1})-\d{4}$/;
  // 市外局番あり
  const pattern2 = /^\d{1,4}-\d{4}$/;
  // 市外局番なし
  const pattern3 = /^0[5789]0-\d{4}-\d{4}$/;
  // 携帯電話
  const pattern4 = /^0120-\d{3}-\d{3}$/;
  // フリーダイヤル
  const pattern5 = /^\d{5,11}$/;
  // ハイフンなし

  if (
    !pattern1.test(value)
    && !pattern2.test(value)
    && !pattern3.test(value)
    && !pattern4.test(value)
    && !pattern5.test(value)
  ) {
    errors.push('入力内容が不正です');
  }
  return errors;
};

/**
 * メールアドレスチェック
 * @param {string} value - チェックしたい値
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateEmail = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }
  /*
  ●ユーザー名部分
  １．半角英字および記号（_.+-）で構成されていること
  ２．先頭と末尾のドットを禁止

  ●ドメイン部分
  １．トップレベルドメインは半角英字で構成されていること。また2文字以上であること。
  ２．トップレベルドメイン以外は数字(0-9)やハイフン(-)を利用可能。（ただしハイフンはラベルの先頭と最後では使用不可。また１文字以上であること。）
  */

  const pattern1 = /^[a-zA-Z0-9_+-]+(\.{1,}[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.?)+[a-zA-Z]{2,}$/;

  if (!pattern1.test(value)) {
    errors.push('入力内容が不正です');
  }
  return errors;
};

/**
 * URLチェック関数
 * @param {string} value - チェックしたい値
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateUrl = (value) => {
  const errors = [];
  if (value === '') {
    return errors;
  }

  // eslint-disable-next-line prefer-regex-literals
  const pattern1 = new RegExp('^https?://[\\w/:%#$&\\?\\()~.=+-]+$');

  if (!pattern1.test(value)) {
    errors.push('入力内容が不正です');
  }

  if (value.length > 1024) {
    errors.push('URLは1024文字以下で入力してください。');
  }
  return errors;
};

/**
 * Fileチェック処理
 * @param {File} value - チェックしたいFileオブジェクト
 * @param {Object} config - 設定用オブジェクト(最大値、最小値) 最大値と最小値は小数も指定可能
 * @returns {String[]} errors - バリデーションエラーのエラー内容
 */
const validateFile = (value, { maxLengthFilaName }) => {
  const errors = [];
  if (value.name && value.name.length > maxLengthFilaName) {
    errors.push(`ファイル名は${maxLengthFilaName}文字までです`);
  }
  return errors;
};

/**
 * JSONサイズチェック処理
 * @param {Object} data - チェックしたいrequest body
 * @returns {String} error - バリデーションエラーのエラー内容
 */
export const validateJsonSize = (data) => {
  let error = '';
  // リクエストを文字列に変換
  const jsonString = JSON.stringify(data);
  const sizeInBytes = new Blob([jsonString]).size;
  // リクエストの上限サイズ(15MBだがヘッダーサイズ（最大8kB）を考慮)
  // Base64変換時に133%にサイズが膨らむため、ユーザーに見えるエラーメッセージは10MBを表示して違和感を無くす
  const limit = 15720448;
  if (sizeInBytes >= limit) {
    error = 'データサイズが10MBを超えています';
  }
  return error;
};

/**
 * FormDataサイズチェック処理
 * @param {Object} data - チェックしたいrequest body
 * @returns {String} error - バリデーションエラーのエラー内容
 */
export const validateFormDataSize = (data) => {
  let error = '';
  const getFormDataSize = (formData) => [...formData].reduce((size, [, value]) => size + (typeof value === 'string' ? value.length : value.size), 0);
  const sizeInBytes = getFormDataSize(data);
  // リクエストの上限サイズ(20MBだがヘッダーサイズ（最大8kB）を考慮)
  // ファイルストレージの上限は20MB(https://openhouse.backlog.jp/view/OH_KDSFA-811)
  const limit = 20963328;
  if (sizeInBytes >= limit) {
    error = 'データサイズが20MBを超えています';
  }
  return error;
};

// 以下単項目チェック用関数
// 使用方法:
// onBlur={validateFormXX(チェック条件)}
export const validateFormString1 = ({ maxLengthInt = 0 } = {}) => (value) => {
  let errors = [];
  errors = errors.concat(validateMaxLength(value, { maxLengthInt }));
  return errors;
};
export const validateFormInt1 = ({ minFloat, maxFloat = 0 } = {}) => (value) => {
  if (validateNumber(value).length !== 0) return validateNumber(value);
  let errors = [];
  errors = errors.concat(validateInteger(value));
  errors = errors.concat(validateMaxMin(value, { minFloat, maxFloat }));
  return errors;
};
export const validateFormFloat1 = ({ minFloat, maxFloat, precisionInt = 0 } = {}) => (value) => {
  if (validateNumber(value).length !== 0) return validateNumber(value);
  let errors = [];
  errors = errors.concat(validateFloat(value));
  errors = errors.concat(validateMaxMin(value, { minFloat, maxFloat }));
  errors = errors.concat(validateNumericPrecision(value, { precisionInt }));
  return errors;
};
export const validateFormPostalCode = () => (value) => {
  let errors = [];
  errors = errors.concat(validatePostalCode(value));
  return errors;
};
export const validateFormPostalCodeHyphen = () => (value) => {
  let errors = [];
  errors = errors.concat(validatePostalCodeHyphen(value));
  return errors;
};
export const validateFormEmail = () => (value) => {
  let errors = [];
  errors = errors.concat(validateEmail(value));
  return errors;
};
export const validateFormTel = () => (value) => {
  let errors = [];
  errors = errors.concat(validateTel(value));
  return errors;
};
export const validateFormTelNum = () => (value) => {
  let errors = [];
  errors = errors.concat(validateTelNum(value));
  return errors;
};

export const validateFormUrl = () => (value) => {
  let errors = [];
  errors = errors.concat(validateUrl(value));
  return errors;
};
export const validateFormFile = ({ maxLengthFilaName }) => (value) => {
  let errors = [];
  errors = errors.concat(validateFile(value, { maxLengthFilaName }));
  return errors;
};

/**
 * Falsyな値を削除する(=validate)関数。
 * Falsy = ['false', '0', '-1', 'NaN', '0n', '""', 'null', 'undefined']
 *
 * @param {Object} object validateする対象。連想配列を想定。
 * @param {Array} includeValuesFromValidate validateする値の配列。この中に存在する値は除外される。
 * @param {Array} excludeValuesFromValidate validateされない値の配列。この中に存在する値は除外されない。
 * @returns {Object} object
 */

export const validateFalsyValues = (
  object,
  includeValuesFromValidate = [],
  excludeValuesFromValidate = [],
  callback = null,
) => {
  const validatedObject = structuredClone(object);
  Object.keys(validatedObject).forEach((key) => {
    if (includeValuesFromValidate.includes(object[key])) return;
    if (
      (!object[key] && !excludeValuesFromValidate.includes(object[key]))
      || (callback && callback(validatedObject[key]))
    ) {
      delete validatedObject[key];
    }
  });
  return validatedObject;
};

export const isEmptyArray = (value) => {
  return Array.isArray(value) && !value.length;
};

/**
 * 年月日チェック
 * @param {string} value - チェックしたい値
 */
export const validateDate = (value) => {
  const pattern = /^\d{4}\/\d{2}\/\d{2}$/;

  if (!pattern.test(value)) {
    return false;
  }
  const [year, month, day] = value.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};
