import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Button, Typography, makeStyles } from '@material-ui/core';
import commonStyles from '../../styles';
import layoutStyle from '../../styles/layout';
import DivisionMasterRow from './divisionMasterRow';
import Select from '../select';
import CustomTable from '../../browsingHistory/parts/customTable';
import { dateFormatter } from '../../../commonFunction';
import { USER_MOVE_HISTORY_GROUPS } from '../../../constants/userRoleGroup';

const useStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginLeft: 8,
    },
  },
  content: {
    marginTop: 16,
  },
  box: {
    padding: '13.5px 8px',
  },
  td: {
    padding: '4px 16px !important',
  },
  select: {
    '& > .MuiInput-underline::before, & > .MuiInput-underline::after': { display: 'none' },
  },
  date: {
    fontWeight: 700,
    marginLeft: 16,
  },
  disable: {
    pointerEvents: 'none',
  },
});

const headCells = [
  { id: 'userId', label: '社員ID', className: 'nowrap' },
  { id: 'userFullName', label: '社員氏名' },
  { id: 'userRoleGroupName', label: '役割' },
  { id: 'gradeName', label: '役職' },
  { id: 'userRoleGroupId', label: '権限' },
  { id: 'fromDivisionName', label: '現在の組織' },
  { id: 'arrow', label: '' },
  { id: 'toDivisions', label: '異動先の組織' },
  { id: 'subDivisions', label: '他の所属組織' },
];

function DivisionMaster(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();
  const lCommon = layoutStyle();

  const {
    divisions,
    currentDivision,
    setCurrentDivision,
    divisionMasters,
    calendarData,
    onClickConfirmUpdateDivisionMasters,
    onChangeUserDivisionId,
  } = props;

  /**
   * 「移動先の組織」の変更分を配列で取得
   */
  const filteredDivisions = useMemo(() => {
    return divisionMasters.data.divisions.filter(
      (division) => division.fromDivisionId !== division.toDivisionId,
    );
  }, [divisionMasters.data.divisions]);

  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleGroupId } = responseHeader;

  const [isDisableButton, setIsDisableButton] = useState(filteredDivisions.length === 0);

  const checkDisable = () => {
    // ワンライナーよりも可読性を優先し、条件分岐
    let isDisable = false;

    if (filteredDivisions.length === 0) isDisable = true; // 異動先の組織が全て同じ場合
    if (new Date(calendarData) < new Date()) isDisable = true; // 自店舗内課編成期限を超過している場合
    if (!(USER_MOVE_HISTORY_GROUPS.includes(roleGroupId))) isDisable = true; // 編集可能な権限ではない場合

    return isDisable;
  };

  const handleClick = () => {
    // 自店舗内課編成期限を超えていた場合は、ボタンを非活性化
    const isDisable = checkDisable();
    setIsDisableButton(isDisable);

    if (isDisable) return;

    onClickConfirmUpdateDivisionMasters();
  };

  useEffect(() => {
    const isDisable = checkDisable();
    setIsDisableButton(isDisable);
  }, [filteredDivisions]);

  return (
    <Grid container justifyContent="center">
      <Grid className={baseClasses.header}>
        <Grid className={lCommon.inner}>
          <Typography className={baseClasses.title2}>
            営業自店舗課編成
          </Typography>
        </Grid>
      </Grid>
      <Grid className={`${baseClasses.body} ${lCommon.scrollToRow3}`}>
        <Grid container className={lCommon.inner}>
          <Grid container className={lCommon.gridS}>
            <Grid item>
              <Box>
                <Grid className={baseClasses.selectBox}>
                  <Typography className={baseClasses.title4}>担当組織</Typography>
                  <Select
                    readOnly={divisions.length <= 1}
                    value={currentDivision?.divisionId}
                    labelKey="divisionName"
                    valueKey="divisionId"
                    options={divisions}
                    onChange={(id) => {
                      setCurrentDivision(divisions.find((item) => item.divisionId === id));
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box className={`${baseClasses.boxType2} ${classes.box}`}>
                <span>自店舗内課編成期限</span>
                <span className={classes.date}>{dateFormatter(new Date(calendarData), 'YYYY/MM/DD hh:mm')}</span>
              </Box>
            </Grid>
          </Grid>
          <Grid container className={classes.content}>
            <Box className={baseClasses.box}>
              <CustomTable
                headCells={headCells}
                hideHeader
                hidePaging
                list={divisionMasters.data.divisions}
                contentHeight={null}
                rowRender={(list) => list.map((item, i) => (
                  <DivisionMasterRow
                    key={`${item.userId}_${item.fromDivisionId}_${item.toDivisionId}_${i.toString()}`}
                    userDivision={item}
                    toDivisions={divisionMasters.data.toDivisions}
                    onChangeUserDivisionId={onChangeUserDivisionId}
                    className={classes}
                    authority={USER_MOVE_HISTORY_GROUPS}
                  />
                ))}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={baseClasses.footer}>
        <Button
          className={baseClasses.buttonsPrimary}
          onClick={handleClick}
          disabled={isDisableButton}
        >
          確定
        </Button>
      </Grid>
    </Grid>
  );
}

export default DivisionMaster;
