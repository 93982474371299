import React from 'react';
import { useDispatch } from 'react-redux';
import AddAndEditDivisonWrapper from '../../components/addAndEditDivision/addAndEditDivision';
import divisionsGetDetailApi from '../../apis/divisions/divisionsGetDetailApi';
import divisionsUpdateApi from '../../apis/divisions/divisionsUpdateApi';
import divisionsResisterApi from '../../apis/divisions/divisionsResisterApi';
import {
  setDivisions,
  resetInitialDivision,
  setParentId,
  setLevelCode,
} from '../../store/divisions/divisionsGetDetailSlice';

function AddAndEditDivisonContainers(props) {
  const { selectDivision, userDivListFunction, registerHandler } = props;
  const dispatch = useDispatch();

  // 選択組織詳細
  const getDivisionDetail = async (divId) => {
    await divisionsGetDetailApi(divId)
      .then((res) => {
        // FIXKAI: 20220906以降 16~19行目不要
        dispatch(setDivisions(res.data[0]));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 組織作成
  const createDivisionDetail = async (param, callback) => {
    await divisionsResisterApi(param)
      .then(async (res) => {
        await callback(res.data.divisionId);
        userDivListFunction();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 組織詳細Update
  const updateDivisionDetail = async (divId, params) => {
    await divisionsUpdateApi(divId, params)
      .then(() => {
        userDivListFunction();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 組織詳細リセット
  const resetDivision = async (division) => {
    await dispatch(resetInitialDivision());
    await dispatch(setParentId(division.divisionId));
    dispatch(setLevelCode(division.levelCode + 1));
  };

  return (
    <AddAndEditDivisonWrapper
      selectDivision={selectDivision}
      getDivisionDetail={getDivisionDetail}
      updateDivisionDetail={updateDivisionDetail}
      resetDivision={resetDivision}
      createDivisionDetail={createDivisionDetail}
      registerHandler={registerHandler}
    />
  );
}

export default AddAndEditDivisonContainers;
