import React, { useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  InputBase,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import commonTheme from '../../../styles/theme';
import CustomEditDialog from '../../../eleCommon/sp/customEditDialog';
import getTreeObject from '../commonFunc/getTreeObject';
import { useCustomerTree } from '../../../../containers/common/customerTree/context';
import { debounce } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #C8C8C8',
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 4px',
      width: '100%',
      borderRadius: 4,
    },
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '5px',
      borderRadius: '50px',
      width: 'auto',
      margin: '16px 16px 16px 8px',
    },
  },
  searchIcon: {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    [commonTheme.breakpoints.up('md')]: {
      marginRight: 4,
    },
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
    },
  },
  searchText: {
    fontSize: 13,
  },
  inputRoot: {
    color: 'inherit',
    [commonTheme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
}));

export default function TreeSearch() {
  const {
    list,
    setSearchObj,
    targetFlgList,
    setKeyword,
    options,
  } = useCustomerTree();
  const classes = useStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [open, setOpen] = useState(false); // 検索用のモーダル制御(SP)
  const [searchValue, setSearchValue] = useState(''); // テキストフィールドのバリュー値制御

  const initialSearchWord = useRef('');
  const initialSearchIndex = useRef(0);
  const firstGetData = useRef({});

  function formatSearchValues(values) {
    const word = values.replaceAll('　', ' ').split(' ').filter(Boolean);
    const re = word.join(',');
    return re;
  }

  const handleEnter = (e) => {
    let keys = Object.keys(firstGetData.current).filter((v) => v !== 'count');
    // 以下で検索ワードに合ったツリーの中身を部分一致
    if (initialSearchWord.current === e.target.value) {
      initialSearchIndex.current = firstGetData.current[keys[initialSearchIndex.current + 1]]
        ? (initialSearchIndex.current += 1)
        : 0;
    } else {
      // 初期検索時
      initialSearchWord.current = e.target.value;
      firstGetData.current = getTreeObject(list, e.target.value, targetFlgList);
      initialSearchIndex.current = 0;
      // 初回は明示的に再度実行
      keys = Object.keys(firstGetData.current).filter((v) => v !== 'count');
    }
    if (keys.length) setSearchObj(firstGetData.current[keys[initialSearchIndex.current]]);
  };

  const onKeywordChange = useMemo(() => {
    return debounce((e) => {
      const { value } = e.target;
      setKeyword(value);
    }, 200);
  }, []);

  // SP:決定ボタンを押した場合
  const handleSetSearchName = (keyword) => {
    setSearchObj({ keyword });
    // const searchObj = getTreeObject(list, keywords, targetFlgList);
    // if ('count' in searchObj || searchObj instanceof Array) {
    //   setSearchObj(searchObj);
    // }
    // else {
    //   setSearchObj({ count: 0 });
    // }
  };

  const content = (
    <TextField
      autoFocus
      className={classes.input}
      onChange={(e) => {
        return setSearchValue(e.target.value);
      }}
      name="顧客検索"
      value={searchValue}
    />
  );

  if (options) {
    return '';
  }

  return (
    <Grid container className={classes.wrapper}>
      <Grid className={classes.searchIcon}>
        <SearchIcon
          onClick={() => {
            isSp && setOpen(!open);
          }}
        />
      </Grid>
      {!isSp ? (
        <InputBase
          placeholder="顧客名"
          className={classes.inputRoot}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && e.target.value) {
              handleEnter(e);
            }
          }}
          onInput={onKeywordChange}
        />
      ) : (
        <>
          <CustomEditDialog
            open={open}
            setOpen={setOpen}
            content={content}
            set={() => {
              handleSetSearchName(formatSearchValues(searchValue));
            }}
            lbl="顧客検索"
          />
          <Typography className={classes.searchText}>
            {formatSearchValues(searchValue)}
          </Typography>
        </>
      )}
    </Grid>
  );
}
