import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const librarySearchSlice = createSlice({
  name: 'librarySearch',
  initialState: { librarySearch: initialState },
  reducers: {
    librarySearchApi: (state, data) => {
      state.librarySearch = data.payload;
    },
  },
});

export const { librarySearchApi } = librarySearchSlice.actions;

export default librarySearchSlice.reducer;
