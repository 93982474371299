/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import { CreateNewFolder } from '@material-ui/icons';
import DriveFileRenameIcon from '../../../icons/fileRename';
import {
  createTreeList,
  getTreeItemByUserId,
  getTreeItemByDivisionId,
} from '../commonFunc/helpers';
import { isArray } from '../../../../commonFunction';
import { Tree, TREE_TYPES } from '../../tree';
// import { useStore } from '../../../../hooks';
import AddAndEditDivision from '../../../addAndEditDivision';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    minHeight: '30%',
    overflow: 'scroll',
  },
  hoverNodesWrap: {
    color: theme.palette.primaryColor,
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 0',
    borderRadius: 2,
    '& > svg': {
      cursor: 'pointer',
      maxWidth: 20,
      height: 'auto',
      marginLeft: 5,
    },
  },
  renameIcon: { fill: theme.palette.primaryColor },
}));

function UserTreeList(props) {
  const classes = useStyles();
  const {
    list,
    noAffiliList,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision = () => {},
    searchObj,
    showOnlyDivisions,
    clickableUserAndDivision,
    // setIsOpenTree,
    userDivListFunction,
    isSelectUserWindow,
    setTargetDivisionTree,
    isDuplicate = false,
    isUserMaster,
    isMulti,
  } = props;

  const selectDivisionRef = useRef({});
  // 編集と新規ボタン
  const registerHandlerRef = useRef({});

  const isFirst = useRef(true);

  const treeRef = useRef(null);

  const isSearch = useRef(false);

  const multipleInitialUser = useRef([]);

  const treeList = useMemo(() => {
    isFirst.current = true;
    return createTreeList(
      list,
      noAffiliList,
      '',
      isDuplicate,
      {
        divisionFlg: showOnlyDivisions || clickableUserAndDivision,
        onlyDivision: showOnlyDivisions,
        onlyUser: isSelectUserWindow,
        isUserMaster,
      },
      {},
      isMulti,
      setTargetDivisionTree,
    );
  }, [list, noAffiliList]);

  // 初期オープン情報
  const [defaultSelected, setDefaultSelected] = useState([]);

  // 組織を選択した時の処理
  const handleSelectDivision = (division) => {
    // 組織をセット
    setSelectDivision({
      divId: division.divId ? division.divId : division.divisionId,
      divisionName: division.divisionName,
    });
    if (isSelectUserWindow || clickableUserAndDivision) {
      // すでに選択したユーザーの初期化
      setSelectUser({ userId: 0, userName: '' });
    }
    // 行動予定の組織ツリーの為
    if (setTargetDivisionTree) {
      setTargetDivisionTree(division?.divisions);
    }
  };
  // ユーザーを選択した時の処理
  const handleSelectUser = (item) => {
    // ユーザーをセット
    if (item?.parentId) {
      setSelectUser({ userId: item.id, userName: item.userName });
      setSelectDivision({
        divId: item.parentId,
        divisionName: item.parentName,
      });
    } else {
      setSelectUser({ userId: item.id, userName: item.name });
      // 担当者選択画面では、選択したユーザー情報をもとに組織も設定
      if (searchObj) {
        setSelectDivision({
          divId: searchObj.divisionId,
          divisionName: searchObj.divisionName,
        });
      }
    }

    // ダイアログ形式でのツリー表示ではない（常時表示）の場合、このsetIsOpenTree関数はpropsで渡されてこない
    // }
    // else if (setIsOpenTree) {
    //   // isSelectUserWindowではない場合、選択したらモーダルをそのまま閉じる
    //   setIsOpenTree(false);
    // }
  };

  // CheckBoxによる複数選択
  const handleSelectUserMultiple = (items) => {
    const userState = [];
    const divisionState = [];
    for (let i = 0; items.length > i; i += 1) {
      if (items[i]?.userId) {
        userState.push({
          userId: items[i].id,
          userName: items[i].userName,
          userDivisionId: items[i].parentId,
          userDivisionName: items[i].parentName,
        });
      } else {
        divisionState.push({
          divId: items[i].id,
          divisionName: items[i].name,
        });
      }
    }
    setSelectDivision(divisionState);
    setSelectUser(userState);
  };

  // ツリーの検索
  useEffect(() => {
    if (searchObj) {
      isSearch.current = true;
      if (searchObj.userId) {
        setDefaultSelected([
          {
            id: searchObj.userId,
            type: TREE_TYPES.user,
            uniqueId: searchObj.userIds,
          },
        ]);
        !isMulti ? handleSelectUser({
          id: searchObj.userId,
          name: searchObj.userName,
        }) : null;
      } else if (searchObj.divisionId) {
        setDefaultSelected([
          {
            id: searchObj.divisionId,
            type: TREE_TYPES.division,
          },
        ]);
        !isMulti ? handleSelectDivision({
          divId: searchObj.divisionId,
          divisionName: searchObj.divisionName,
        }) : null;
      }
    }
  }, [searchObj]);

  // デフォルトユーザー, デフォルト組織の選択
  useEffect(() => {
    if (selectUser?.userId) {
      // eslint-disable-next-line max-len
      setDefaultSelected([{ id: selectUser.userId, type: TREE_TYPES.user, isFirst: isFirst.current }]);
      const item = getTreeItemByUserId(treeList, selectUser.userId);
      if (item) handleSelectUser(item);
    }
    // eslint-disable-next-line max-len
    if ((selectDivision?.divId && showOnlyDivisions) || (selectDivision?.divId && !selectUser.userId)) {
      setDefaultSelected([{ id: selectDivision.divId, type: TREE_TYPES.division }]);
      handleSelectDivision(selectDivision);
      // eslint-disable-next-line max-len
      if (setTargetDivisionTree) setTargetDivisionTree(getTreeItemByDivisionId(treeList, selectDivision.divId));
    }
    if (isArray(selectUser)) {
      const selectedArray = [];
      const items = [];
      for (let i = 0; selectUser.length > i; i += 1) {
        selectedArray.push({ id: selectUser[i].userId, type: TREE_TYPES.user });
        const userInfo = getTreeItemByUserId(treeList, selectUser[i].userId);
        if (userInfo) items.push(userInfo);
      }
      setDefaultSelected(selectedArray);
      multipleInitialUser.current = items;
    }
  }, [list]);

  const onItemClick = (item) => {
    if (isArray(item)) {
      handleSelectUserMultiple(item);
    } else if (item.type === TREE_TYPES.division) {
      handleSelectDivision({
        divisionId: item.id,
        divisionName: item.name,
        divisions: item,
      });
    } else {
      handleSelectUser(item);
    }
  };

  const onHoverNodeClick = (target, type) => {
    const isUser = target.type === TREE_TYPES.user;
    selectDivisionRef.current = {
      divisionId: isUser ? target.parentId : target.id,
      subName: isUser ? target.parentName : target.name,
      levelCode: isUser ? null : target.levelCode,
    };
    // 所属なしではない場合
    if (selectDivisionRef.current.divisionId) {
      registerHandlerRef.current[type + 'Division']?.(
        selectDivisionRef.current,
      );
    }
  };

  return (
    <Grid ref={treeRef} className={`${classes.root}`}>
      <Tree
        options={{
          hoverNodes: {
            division: (!isSelectUserWindow && isUserMaster) ? (
              <Grid className={`${classes.hoverNodesWrap} hoverNodes`}>
                <CreateNewFolder
                  key="add"
                  onClick={(e, target) => onHoverNodeClick(target, 'add')}
                />
                <DriveFileRenameIcon
                  className={classes.renameIcon}
                  key="edit"
                  onClick={(e, target) => onHoverNodeClick(target, 'edit')}
                />
              </Grid>
            ) : null,
          },
        }}
        list={treeList}
        onChange={onItemClick}
        defaultSelected={defaultSelected}
        multiple={isMulti}
        checkbox={isMulti}
        accordion
        rootRef={treeRef}
        multipleInitialUser={multipleInitialUser.current}
        isUserTree
        isSearch={isSearch}
      />
      {/* 担当者選択ウィンドウの場合、組織名や内容を編集する機能は必要ないため、以下で条件分岐 */}
      {!isSelectUserWindow ? (
        <AddAndEditDivision
          selectDivision={selectDivisionRef.current}
          userDivListFunction={userDivListFunction}
          registerHandler={registerHandlerRef.current}
        />
      ) : null}
    </Grid>
  );
}

export default UserTreeList;
