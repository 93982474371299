import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, ListItem } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../../../../styles';
import DirectReportStatusCode from '../directReportStatusCode';
import ImportantCode from '../importantCode';
import InstructionReportWindowSp from '../../sp/instructionReportWindowSp/index';

const useStyles = makeStyles({
  item: {
    padding: '0 0 0 8px',
    background: '#fff',
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)',
    },
  },
  itemComplete: {
    '& .status > span': {
      background: '#8C8C8C',
    },
  },
  informationItem: {
    padding: '13px 8px',
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid #F3F3F3',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 9,
    },
  },
  row: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:nth-child(1)': {
      textAlign: 'center',
    },
    '&:nth-child(2)': {
      wordBreak: 'keep-all',
    },
    '&:nth-child(3)': {
      textAlign: 'left',
      width: 'calc(100% - 120px)',
      '& > div': {
        width: '100%',
      },
    },
    '&:not(:first-child)': {
      marginLeft: 16,
    },
  },
  expiredIcon: {
    display: 'none',
    fontSize: 20,
    color: '#D83420',
    '&.expired': {
      display: 'inline-block',
    },
  },
  dateWrap: {
    '&.expired': {
      color: '#D83420',
    },
  },
  date: {
    '& > span': {
      display: 'block',
      textAlign: 'center',
    },
  },
  status: {
    '& > span': {
      background: '#333',
      color: '#fff',
      fontSize: 10,
      padding: '4px 6px',
      minWidth: 52,
      display: 'block',
      textAlign: 'center',
    },
  },
  dl: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  dd: {
    marginLeft: 4,
  },
  content: {
    fontSize: 13,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

function InstructionReportItemSp(props) {
  const {
    information,
    tab,
    getDirects,
    // customer,
  } = props;

  const classes = useStyles();
  const common = commonStyles();

  // モーダル開閉用のローカルステート
  const [open1, setOpen1] = useState(false);
  // モーダルを開くsetState
  const handleOpen1 = () => {
    setOpen1(true);
  };
  // モーダルを閉じるsetState
  const handleClose1 = () => {
    setOpen1(false);
  };

  // 期日
  const now = new Date();
  const year = now.getFullYear();
  const month = ('00' + (now.getMonth() + 1)).slice(-2);
  const day = ('00' + now.getDate()).slice(-2);
  const currentDate = year + '/' + month + '/' + day;
  const date1 = new Date(currentDate);
  const date2 = new Date(information.expireAt.substr(0, 10));

  const [expired, setExpired] = useState(false);

  const { timelines = [] } = information;
  const initialTimeline = timelines.length > 0
    ? timelines[0] : undefined;

  useEffect(() => {
    if (tab === 0 || tab === 1) {
      if (date1 > date2) {
        setExpired(true);
      } else {
        setExpired(false);
      }
    }
  });

  const content = (
    <Grid className={`${classes.informationItem} ${expired ? 'expired' : ''}`}>
      <Grid className={classes.row}>
        <Grid className={`${classes.dateWrap} ${expired ? 'expired' : ''}`}>
          <Icon className={`${classes.expiredIcon} ${expired ? 'expired' : ''}`}>
            local_fire_department
          </Icon>
          <Typography className={`${common.title5} ${classes.date}`}>
            <span>{information.expireAt.substr(0, 4)}</span>
            <span className={common.title4}>{information.expireAt.substr(5, 5)}</span>
            <span>-{information.expireAt.substr(10, 6)}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Grid className={`${classes.status} status`}>
          <DirectReportStatusCode
            code={information.directReportStatusCode}
          />
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Grid className={classes.rowInner}>
          <Grid className={classes.flex}>
            <dl className={classes.dl}>
              <dt className={`${common.title5} ${classes.dt}`}>重要度</dt>
              <dd className={classes.dd}>
                <ImportantCode
                  code={information.importantCode}
                />
              </dd>
            </dl>
            <dl className={classes.dl}>
              <dt className={`${common.title5} ${classes.dt}`}>担当</dt>
              <dd className={classes.dd}>
                {information.directedDivisionName}
                /
                {information.directedLastName} {information.directedFirstName}
              </dd>
            </dl>
          </Grid>
          <Grid>
            <Typography className={classes.content}>{initialTimeline?.content}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const getListItem = () => {
    switch (tab) {
      case 2:
        return (
          <ListItem
            className={`${classes.item} ${classes.itemComplete} ${expired ? 'expired' : ''}`}
          >
            {content}
          </ListItem>
        );
      default:
        return (
          <ListItem
            className={`${classes.item} ${expired ? 'expired' : ''}`}
            onClick={handleOpen1}
          >
            {content}
            {/* 指示・報告確認画面 */}
            <InstructionReportWindowSp
              open={open1}
              setOpen={setOpen1}
              onClose={handleClose1}
              tab={tab}
              row={information}
              customerId={information.customerId}
              getDirects={getDirects}
              setResetRender={() => console.log('test')}
              // customer={customer}
              visibleFooter
            />
          </ListItem>
        );
    }
  };

  return (
    <>
      {getListItem()}
    </>
  );
}

export default InstructionReportItemSp;
