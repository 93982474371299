import React, { useState } from 'react';
import { Grid, Button, List, ListItem, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';
// import CustomSimpleDrawer, { CustomDrawerList } from '../../../eleCommon/sp/customSimpleDrawer';
import commonStyles from '../../../styles';
import { type, unit } from '../../../../constants/customerTree';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginTop: 8,
    '& > *': {
      marginBottom: 8,
      '&:not(:last-of-type)': {
        marginRight: 8,
      },
    },
  },
  selectBtn: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 4px 4px 12px',
    fontSize: 13,
    borderRadius: 60,
  },
  arrowIcon: {
    color: theme.palette.primaryColor,
    fontSize: 18,
  },
  // ここからDrawer用のスタイル
  list: {
    padding: 0,
  },
  item: {
    padding: 0,
    '&.Mui-selected': {
      background: 'transparent',
      '& .MuiButton-label': {
        color: theme.palette.primaryColor,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
}));

function DrawerList(props) {
  const { initial, onClose } = props;
  const classes = useStyles();
  const common = commonStyles();
  const list = initial.menu;

  return (
    <List className={classes.list}>
      {list.map((o, index) => (
        <ListItem
          key={o}
          className={classes.item}
          selected={initial.state !== undefined && initial.menu[initial.state].includes(o)}
        >
          <Button
            className={`${common.title3} ${classes.btn}`}
            onClick={() => {
              initial.setState(index);
              onClose();
            }}
          >
            {o}
          </Button>
        </ListItem>
      ))}
    </List>
  );
}

function GetTreeSelectHeader(props) {
  const { setList, code, setCode } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // ステートを更新
  const set = (key, val) => {
    setCode({ ...code, [key]: val });
  };

  const data = [
    {
      key: 'typeCode',
      obj: type,
      set: setList,
      state: code.typeCode,
      setState: (e) => { return set('typeCode', e); },
      menu: Object.values(type.menus),
    },
    {
      key: 'unitCode',
      obj: unit,
      set: setList,
      state: code.unitCode,
      setState: (e) => { return set('unitCode', e); },
      menu: Object.values(unit.menus),
    },
  ];

  return (
    <Grid className={classes.root}>
      {data.map((d, index) => (
        <Grid
          item
          key={d.key}
        >
          <Button onClick={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: true })); }} className={classes.selectBtn}>
            {d.state === undefined ? d.menu[0] : d.menu[d.state]}
            <ArrowDropDownIcon className={classes.arrowIcon} />
          </Button>
          <CustomSimpleDrawer
            index={index}
            open={open[`open${index}`]}
            onClose={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: false })); }}
            title={d.obj.title}
            list={<DrawerList initial={d} onClose={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: false })); }} />}
            // 共通コンポーネントを使いたいが、どこで呼ばれているコンポーネントかわからないため、一旦コメントアウトで対応
            // list={
            //   <CustomDrawerList
            //     menus={d.menu}
            //     state={d.state}
            //     setState={d.setState}
            //     onClose={() => {
            //       setOpen((prev) => ({ ...prev, [`open${index}`]: false }));
            //     }}
            //   />
            // }
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default function TreeSelectUnitSp(props) {
  const { setList, code, setCode } = props;

  return <GetTreeSelectHeader setList={setList} code={code} setCode={setCode} />;
}
