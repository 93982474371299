import { useState, useEffect } from 'react';
import { Button, makeStyles, Popper, Typography } from '@material-ui/core';
import { DirectionsRun } from '@material-ui/icons';

import customerCallApi from '../../../apis/customer/customerApi';
import { useLoading } from '../../../hooks';

import commonStyles from '../../styles';
import DriveFileRenameIcon from '../../icons/fileRename';
import ListWindow from '../../common/taiouRireki/listWindow';
import CustomerDetail from '../../common/customerDetail';

const useStyles = makeStyles((theme) => ({
  popper: { display: 'flex', alignItems: 'center' },
  btn: {
    color: '#0679BF',
    margin: '0 4px',
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: '#d3e5f1',
      boxShadow: 'none',
    },
  },
  icon: { height: 16, width: 16, fill: theme.palette.primaryColor, marginRight: 4 },
}));

export default function AllMailMenuPopper(props) {
  const { open, anchorEl, row } = props;
  const common = commonStyles();
  const classes = useStyles();

  const { addLoading, removeLoading } = useLoading();

  const [customerObj, setCustomerObj] = useState({});

  const [detailOpen, setDetailOpen] = useState(false);
  const [actionHistoryOpen, setActionHistoryOpen] = useState(false);

  const input = {
    component: () => {},
    editDialog: () => {},
    parts: () => {},
    customerDetail: customerObj,
  };

  const getCustomer = () => {
    addLoading();
    customerCallApi([row.customerId])
      .then((res) => setCustomerObj(res.data.customers[0]))
      .catch((err) => console.log(err))
      .finally(() => removeLoading());
  };

  useEffect(() => {
    if (!detailOpen && !actionHistoryOpen) setCustomerObj({});
    if (detailOpen || actionHistoryOpen) getCustomer();
  }, [detailOpen, actionHistoryOpen]);

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="left"
        className={classes.popper}
        transition
        disablePortal
      >
        <Button
          className={`${classes.btn} ${common.buttonsSecondary}`}
          onClick={() => setActionHistoryOpen(true)}
        >
          <DirectionsRun className={classes.icon} />
          <Typography className={common.smallStrong}>対応履歴</Typography>
        </Button>
        <Button className={`${classes.btn} ${common.buttonsSecondary}`} onClick={() => setDetailOpen(true)}>
          <DriveFileRenameIcon className={classes.icon} />
          <Typography className={common.smallStrong}>詳細</Typography>
        </Button>
      </Popper>
      {detailOpen && Object.keys(customerObj).length !== 0 ? (
        <CustomerDetail
          open={detailOpen}
          onClose={() => setDetailOpen(false)}
          input={input}
        />
      ) : null}
      {actionHistoryOpen ? (
        <ListWindow
          open
          onClose={() => setActionHistoryOpen(false)}
          customerId={row.customerId}
          customer={customerObj}
        />
      ) : null}
    </>
  );
}
