import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DivisionUpdateConfirm from '../../../components/division/division/divisionUpdateConfirm';
import getUserListApi from '../../../apis/divisionMaster/getUserListApi';

export default function DivisionUpdateConfirmContainer(props) {
  const {
    areaCode,
    setting,
  } = props;
  const dispatch = useDispatch();

  // 仮アップロードしたCSVのデータ
  const [tentativeUserList, setTentativeUserList] = useState();

  /**
   * ページネーションの現在のページの番号
   *
   * @type { [currentPageNumber: number, setCurrentPageNumber: (pageNumber: number) => void] }
   */
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  /**
   * divisionMaster005 - 課編成ユーザ異動一覧取得
   *
   * ページネーションの切り替え
   * ページ送り・戻りごとにリクエストが発生する
   *
   * @typedef { import('react').ChangeEvent<{}> } E
   * @type { (_: E; nextTabAreaCode: number;) => Promise<void> }
   */
  const onPageChange = useCallback(async (_, pageNumber) => {
    const userListRequestQuery = { areaCode, offset: pageNumber * 20, limit: 20, isCommitted: 0 };
    const userListGetResponse = await getUserListApi(userListRequestQuery);
    setTentativeUserList(userListGetResponse);
    setCurrentPageNumber(pageNumber);
  }, [areaCode]);

  const onRowsPerPageChange = useCallback(() => {
    setCurrentPageNumber(0);
  }, []);

  useEffect(() => {
    (async () => {
      /**
       * divisionMaster005 - 課編成ユーザ異動一覧取得
       * 「CSVアップロード画面」でアップロードしたデータを取得
       */
      const userListRequestQuery = { areaCode, offset: 0, limit: 20, isCommitted: 0 };
      const userListGetResponse = await getUserListApi(userListRequestQuery);
      setTentativeUserList(userListGetResponse);
    })();
  }, [areaCode, dispatch]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!tentativeUserList) return <></>;

  return (
    <DivisionUpdateConfirm
      setting={setting}
      tentativeUserList={tentativeUserList}
      currentPageNumber={currentPageNumber}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
}
