export const RESPONSE_TYPE = [
  {
    label: '反響媒体（大）',
    responseTypeLevelCode: 1,
    required: true,
  },
  {
    label: '反響媒体（中）',
    responseTypeLevelCode: 2,
    required: false,
  },
  {
    label: '反響媒体（小）',
    responseTypeLevelCode: 3,
    required: false,
  },
  {
    label: '反響媒体（細）',
    responseTypeLevelCode: 4,
    required: false,
  },
];

export const RESPONSE_TYPE_SEARCH = [
  {
    label: '反響媒体（大）',
    responseTypeLevelCode: 1,
    required: true,
  },
  {
    label: '反響媒体（中）',
    responseTypeLevelCode: 2,
    required: false,
  },
  {
    label: '反響媒体（小）',
    responseTypeLevelCode: 3,
    required: false,
  },
  {
    label: '反響媒体（細）',
    responseTypeLevelCode: 4,
    required: false,
  },
];

export const RESPONSE_TYPE_ID = [];

export const RESPONSE_TYPE_GENSEN = {
  id: 5,
  name: '源泉',
};

// 獲得者を編集できる反響媒体
export const RESPONSE_TYPE_ACQUISITION = [
  4, // チラシ
  5, // 源泉
  7, // 投函
  9, // 紹介
];
