import React, { useState, useEffect } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommonDialog from '../../common/modal';

import settingGroupStoreSearchHistoryApi from '../../../apis/settingGroupStore/settingGroupStoreSearchHistoryApi';

import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 640,
    minWidth: 640,
    background: '#fff',
  },
  closeButton: {
    width: 89,
    height: 40,
    color: theme.palette.primaryColor,
  },
  tableContainer: {
    backgroundColor: '#fff',
    padding: 8,
    boxSizing: 'border-box',
    border: '#C8C8C8',
  },
  table: {
    borderSpacing: 5,
  },
  tableHead: {
    borderBottom: 'solid 2px #8C8C8C',
    padding: 8,
  },
  tableHeadRow: {
    '& th': {
      padding: 8,
    },
  },
  tableBodyRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '& td': {
      borderTop: '#C8C8C8',
      padding: 8,
    },
  },
  cellTxt: {
    borderLeft: 'solid 1px #C8C8C8',
    paddingLeft: 5,
  },
}));

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title1"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

function StoreGroupHistory(props) {
  const { setting, openHistory, setOpenHistory } = props;
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const common = commonStyles();
  const onClose = () => {
    setOpenHistory(false);
  };

  const areaCode = setting && setting.areaCode ? setting.areaCode : null;
  const storeGroupId = setting && setting.storeGroupId ? setting.storeGroupId : null;

  const getHistories = () => {
    const params = { areaCode, storeGroupId };
    settingGroupStoreSearchHistoryApi(params)
      .then((res) => {
        setRows(res.data.histories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (storeGroupId && openHistory) {
      getHistories();
    } else {
      setRows([]);
    }
  }, [openHistory]);

  return (
    <CommonDialog
      open={openHistory}
      onClose={onClose}
      title="店舗グループ_履歴ウィンドウ"
      width={640}
      buttons={{
        label: '閉じる',
        className: common.buttonsSecondary,
        onClick: onClose,
      }}
    >
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell>
                <Typography className={common.title4}>
                  顧客ID
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={`${common.title4} ${classes.cellTxt}`}
                >
                  店舗
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={`${common.title4} ${classes.cellTxt}`}
                >
                  反響登録日時
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  key={row.customerId}
                  className={classes.tableBodyRow}
                >
                  <TableCell>
                    <Typography className={common.small}>
                      {row.customerId}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={`${common.small} ${classes.cellTxt}`}
                    >
                      {row.divisionSubName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={`${common.small} ${classes.cellTxt}`}
                    >
                      {row.createAt}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </CommonDialog>
  );
}

export default StoreGroupHistory;
