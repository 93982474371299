import React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  makeStyles,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { ExpandMore } from '@material-ui/icons';
import Logout from '../../../auth/logout';
import ChangeUser from '../../../auth/changeUser';
import commonStyles from '../../../styles';
import { COLOR_THEME, COLOR_CODE } from '../../../../constants';
import { getColorTheme } from '../../../../store/common/getTheme';

import getEnv from '../../../../commonFunction/getEnv';

const useStyles = makeStyles((theme) => ({
  wrap: {
    marginRight: '16px',
    flexShrink: 0,
    '& + div': { zIndex: 11 },
  },
  icon: {
    fill: theme.palette.primaryColor,
    width: '20px',
    marginLeft: '4px',
  },
  menuWrap: {
    backgroundColor: '#f3f3f3',
    width: '240px',
    maxHeight: '100%',
    flexWrap: 'nowrap',
  },
  logoWrap: {
    backgroundColor: '#fff',
    padding: '8px',
  },
  logo: {
    display: 'block',
    width: '75px',
  },
  level1: {
    background: '#fff',
    color: theme.palette.primaryColor,
    borderBottom: '1px #c8c8c8 solid',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  lists: {
    maxHeight: 'calc(100% - 10px)',
  },
  child: {
    color: theme.palette.primaryColor,
    marginLeft: '16px',
    marginBottom: '8px',
    width: 'auto',
    borderRadius: '20px 0 0 20px',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    color: '#33333399',
    paddingRight: 0,
    '&::after': {
      content: '""',
      background: '#c8c8c8',
      height: '1px',
      flexGrow: 1,
      marginLeft: '8px',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'stretch',
    padding: 4,
  },
  flexChild: {
    padding: '4px 8px',
    '&:not(:first-child)': {
      borderLeft: '1px #c8c8c8 solid',
    },
  },
  radioGroup: {
    '& .MuiRadio-root': {
      padding: 0,
      marginRight: 4,
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  ttl: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 8,
  },
  colorCode: {
    display: 'inline-block',
    height: '1em',
    width: '1em',
    marginLeft: 4,
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
}));

function ColorChanger() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies();
  const classes = useStyles();

  // ラジオボタン用のstate
  const [colorVal, setColorVal] = React.useState(cookies.color);

  // ラジオボタンで設定
  const onChangeColor = (e) => {
    const val = e.target.value;
    setColorVal(val);
    setCookie('color', val);
    dispatch(getColorTheme(val));
  };

  const distRadio = () => {
    const arr = [];
    Object.keys(COLOR_THEME).forEach((key) => {
      arr.push(
        <FormControlLabel
          key={key}
          value={key}
          control={<Radio size="small" />}
          label={
            <small>{COLOR_THEME[key]}
              <span className={classes.colorCode} style={{ background: COLOR_CODE[key] }} />
            </small>
          }
        />,
      );
    });
    return arr;
  };
  return (
    <FormControl>
      <p className={classes.ttl}>カラー変更</p>
      <RadioGroup name="colorTheme" value={colorVal} onChange={onChangeColor} className={classes.radioGroup}>
        {distRadio()}
      </RadioGroup>
    </FormControl>
  );
}

function MainmenuUser(props) {
  const { user } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const common = commonStyles();
  const classes = useStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        aria-label="split button"
        variant="text"
        className={classes.wrap}
      >
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <Typography className={common.title4}>{user[0].shozoku}</Typography>
          <Typography className={common.small}>
            &nbsp;|&nbsp;{user[0].name}
          </Typography>
          <ExpandMore className={classes.icon} />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div id="split-button-menu" className={classes.flex}>
                  <div className={classes.flexChild}>
                    <ColorChanger />
                  </div>
                  <div className={classes.flexChild}>
                    <Logout />
                    {getEnv() !== 'PRODUCTION' ? <ChangeUser /> : null}
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default MainmenuUser;
