import { Grid, Typography, makeStyles } from '@material-ui/core';

import PageNation from '../eleCommon/pageNation';
import commonStyles from '../styles';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '18px',
    paddingBottom: '10.5px',
    paddingLeft: '16px',
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 1,
    '& > .MuiButton-root:not(:last-child)': {
      position: 'absolute',
      right: 16,
    },
  },
  title: {
    marginRight: 20,
  },
});

function Header(props) {
  const { getResponses, count, onClickReload } = props;
  const classes = useStyles();
  const commonClasses = commonStyles();

  return (
    <Grid className={classes.header}>
      <Grid>
        <Typography className={`${commonClasses.title2} ${classes.title}`}>
          源泉未処理
        </Typography>
      </Grid>
      <PageNation onClickReload={onClickReload} getApi={getResponses} count={count} />
    </Grid>
  );
}

export default Header;
