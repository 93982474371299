import { Grid, List, makeStyles, Typography } from '@material-ui/core';
import { useRef, useState, useEffect, useCallback } from 'react';
import GensenRegister from '.';
import Dialog from '../common/modal';
import commonStyles from '../styles';
import PullRefComponent from '../common/pullRefComponent';
import { useLoading } from '../../hooks';
import { GENSEN_REGISTER_HISTORY } from '../../constants/loading';

const useStyles = makeStyles({
  itemWrap: {
    borderBottom: '1px #c8c8c8 solid',
    position: 'initial',
  },
  date: {
    background: '#F3F3F3',
    width: '100%',
    padding: 16,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  timeWrap: { padding: '16px 16px 16px 32px', backgroundColor: '#fff' },
  fullDialog: {
    zIndex: '1150!important',
    height: '100vh',
  },
  wrap: {
    '& .ptr': {
      height: 'auto',
    },
    '& .ptr__pull-down': {
      top: 0,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  noData: {
    paddingTop: '50px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  more: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.3)',
    fontSize: '0.9rem',
  },
  extraSpace: {
    height: 64,
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  nameTxt: {
    fontWeight: 'bold',
    marginLeft: 5,
  },
});
function ListItemComponent(props) {
  const {
    prev,
    data,
    onClick,
  } = props;
  const common = commonStyles();
  const classes = useStyles();
  let wrapperStart = '';
  const prevDay = !prev ? null : prev.responseDate.substr(0, 10);
  const dataDay = data.responseDate.split(' ');
  if (!prev || prevDay !== dataDay[0]) {
    const week = new Date(data.responseDate).getDay();
    const weekDay = ['日', '月', '火', '水', '木', '金', '土'][week];
    wrapperStart = (
      <Typography className={`${common.title4} ${classes.date}`}>
        {`${dataDay[0]} (${weekDay})`}
      </Typography>
    );
  }
  return (
    <Grid className={classes.container} onClick={() => onClick()}>
      {wrapperStart}
      <Grid className={classes.itemWrap}>
        <Grid container justifyContent="space-between" className={classes.timeWrap}>
          <Typography className={common.small}>{dataDay[1].substr(0, 5)}</Typography>
          <Typography className={common.small} style={{ marginLeft: 'auto' }}>
            担当 <span className={classes.nameTxt}>{`${data.responsibleDivisionName || ''} ${data.userLastName || ''} ${data.userFirstName || ''}`}</span>
          </Typography>
          <Typography className={common.title3} style={{ width: '100%' }}>
            {data.customerLastName} {data.customerFirstName}
          </Typography>
          <Typography className={common.bass} style={{ width: '100%', textAlign: 'left' }}>
            {data.memo}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default function GensenRegisterHistoryComponent(props) {
  const {
    getResponseListByUserId,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [fetchCount, setFetchCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const stateRef = useRef({
    total: 0,
    list: [],
  });
  const { hasLoading } = useLoading();
  const { total, list } = stateRef.current;

  // 履歴情報が全て取得されたのか
  const isNoMore = total <= list.length;

  const handleScroll = (e) => {
    if (isNoMore) return;
    const el = e.currentTarget;
    const scrollTopVal = el.scrollTop;
    const loadingElH = 64; // ローディングアイコン分の高さ
    const diff = el.scrollHeight - el.clientHeight;

    // TODO: APIのtotal追加待ち
    if (diff - scrollTopVal < loadingElH) {
      // if (diff - scrollTopVal < loadingElH && fetchCount < notificationsLocal.total / 10 - 1) {
      setTimeout(() => {
        setFetchCount(fetchCount + 1);
      }, 550);
    }
  };

  // 顧客カードのクリック
  const clickCardHandler = (data) => {
    setValue(data.responseId);
    setOpen(true);
  };

  // 更新時処理
  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    try {
      setFetchCount(fetchCount === 0 ? -1 : 0);
      setRefreshing(false);
      stateRef.current = {
        total: 0,
        list: [],
      };
    } catch (err) {
      console.error(err);
    }
  }, [refreshing]);

  // useEffect area
  // useEffect(() => {
  //   const initial = {
  //     limit: 10,
  //     offset: 0,
  //   };
  //   getResponseListByUserId(initial, list, setList);
  // }, []);

  useEffect(() => {
    if (fetchCount === -1) {
      setFetchCount(0);
      return;
    }
    // Only SP 下スクロールで更新時にチャンス通知を追加取得
    const params = {
      limit: 10,
      offset: fetchCount * 10,
    };
    getResponseListByUserId(params, stateRef);
  }, [fetchCount]);

  return (
    <Grid className={classes.wrap} onScroll={handleScroll}>
      <PullRefComponent
        refreshing={refreshing}
        onRefresh={handleRefresh}
        canFetchMore
      >
        <List className={classes.list}>
          {list.length > 0 && list.map((data, idx) => {
            return (
              <div key={`key${data.responseId}`}>
                <ListItemComponent
                  prev={idx !== 0 ? list[idx - 1] : false}
                  data={data}
                  onClick={() => clickCardHandler(data)}
                />
              </div>
            );
          })}
          {!list?.length && !hasLoading(GENSEN_REGISTER_HISTORY) && (
            <Typography className={classes.noData}>
              源泉登録履歴は存在しませんでした
            </Typography>
          )}
        </List>
        <Grid className={classes.extraSpace}>
          <Typography className={classes.more}>
            {isNoMore ? '' : '下スクロールでさらに取得する'}
          </Typography>
        </Grid>
      </PullRefComponent>
      <Dialog
        className={classes.fullDialog}
        fullScreen
        open={open}
        onClose={() => { return setOpen(false); }}
        title="源泉詳細"
      >
        <GensenRegister
          onClose={() => { return setOpen(false); }}
          responseID={value}
          title="源泉詳細"
        />
      </Dialog>
    </Grid>
  );
}
