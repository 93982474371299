import {
  Typography,
  Input,
  makeStyles,
  FormLabel,
} from '@material-ui/core';
import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import CommonDialog from '../../common/modal';
import { classNames } from '../../../commonFunction';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import commonStyles from '../../styles';

const csvMimeType = 'text/csv';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: 4,
    },
    '& input': {
      color: '#8C8C8C',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: '7px 0',
    },
  },
  inputWrap: {
    position: 'relative',
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '8px 8px 8px 24px',
    marginTop: '10px',
    height: '50px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
    },
    '& span': {
      color: '#8C8C8C',
      marginLeft: 10,
      fontWeight: 'normal',
    },
    '& input': {
      display: 'none',
    },
  },
  errorMessage: {
    margin: 5,
    fontSize: '0.75rem',
    opacity: 0,
    transition: 'opacity 0.2s',
    color: 'red',
    pointerEvents: 'none',
  },
  showError: {
    opacity: 1,
  },
}));

/**
 * ファイルをアップロードモダール
 * @param {{
 * open: boolean;
 * onClose?: () => void;
 * accept?: MimeType;
 * onSubmit: (file: File) => void;
 * children?: ReactNode;
 * }} props
 * @returns ReactNode
 */
export default function UploadDialog(props) {
  const {
    open,
    onClose,
    accept = 'text/csv',
    onSubmit,
    children,
  } = props;

  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const classes = useStyles();
  const baseClasses = commonStyles();

  const onFileChange = useCallback((e) => {
    const csvFile = e.target.files[0];
    setFile(csvFile);
  }, []);

  const handleSelect = () => {
    onSubmit?.(file);
    setFile(null);
  };

  const selectConfirm = () => {
    dispatch(changeConfirmMessage({
      title: '確認',
      msgList: [
        'CSVアップロードを実行しますか？',
        '実行前に現在の設定のバックアップの取得を',
        'お勧めします。',
      ],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: handleSelect,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // 有効なファイルではない場合
  const isError = useMemo(() => {
    return file && file.type !== csvMimeType;
  }, [file]);

  return (
    <CommonDialog
      title="CSVアップロード画面"
      className={classes.root}
      open={open}
      onClose={onClose}
      width={640}
      buttons={{
        label: 'アップロードして確認',
        onClick: selectConfirm,
        disabled: !file || isError,
      }}
    >
      {children}
      <FormLabel className={classes.inputWrap}>
        ファイルを選択
        <span>{file ? file.name : '選択されていません'}</span>
        <Input
          type="file"
          inputProps={{
            accept,
          }}
          onChange={onFileChange}
        />
      </FormLabel>
      <Typography
        className={classNames(
          classes.errorMessage,
          isError ? classes.showError : '',
        )}
      >
        CSVファイルを選択ください
      </Typography>
    </CommonDialog>
  );
}
