import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Icon from '@material-ui/core/Icon';
import EditWindow from '../editWindow';
import InfoRegister from '../../../common/infoRegister';
import CreateWindowContainer from '../../../../containers/mail/createWindowContainer';
import {
  ACTION_CODE,
  ACTION_DETAILE_MAIL_GROUP,
  ACTION_DETAIL_CODE,
  ACTION_DETAIL_TAIO_RIREKI_GROUP,
} from '../../../../constants';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > .MuiGrid-root > .MuiGrid-root': {
      overflowY: 'hidden',
      '& > .MuiGrid-root.active .historyDate': {
        transform: 'translateX(74px)',
        transition: 'all 1ms cubic-bezier(0.4, 0, 0.2, 1) ',
      },
    },
    '& .labelDate': {
      height: 'calc(100% - 36px)',
      top: 'auto',
      bottom: 0,
    },
  },
  historyRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '12px 0',
    transition: 'all 0.5s ease',
    borderBottom: '1px solid #F3F3F3',
    fontFamily: 'Roboto',
    color: '#333',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    '&:hover': {
      background: 'none!important',
      '& .historyContentBtn': {
        opacity: 1,
      },
    },
    '& .MuiTableRow-root.Mui-selected': {
      background: 'none',
    },
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: '9',
  },
  iconButton1: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primaryColor,
    padding: 2,
    minWidth: 'auto',
    margin: '0 auto',
    '& .material-icons': {
      fontSize: 16,
      marginRight: 2,
    },
  },
  historyDate: {
    display: 'block',
    position: 'relative',
    width: 'calc(100% + 8px)',
    color: '#8C8C8C!important',
    fontWeight: 700,
    border: 'none',
    background: '#fff',
    padding: '12px 0 4px!important',
    margin: '-13px 0 5px -8px',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) ',
    '& span': {
      display: 'inline-block',
      background: '#fff',
      position: 'relative',
      zIndex: '2',
      padding: '0 8px',
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: '100%',
      height: 1,
      position: 'absolute',
      zIndex: '1',
      top: 22,
      left: 0,
    },
  },
  historyTime: {
    position: 'relative',
    width: '28%',
    fontSize: '16px!important',
    fontWeight: 700,
    textAlign: 'center',
  },
  historyContent: {
    width: '72%',
  },
  historyContent1: {
    display: 'flex',
    '& span': {
      fontSize: 15,
      fontWeight: 700,
    },
    '& > span': {
      margin: '0 4px',
    },
  },
  historyContent2: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: 10,
      fontWeight: 700,
      marginRight: 4,
    },
  },
  historyContentBtn: {
    display: 'none',
    color: theme.palette.primaryColor,
    background: '#fff',
    minWidth: 'auto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 56,
    borderRadius: '4px',
    padding: '0 4px',
    marginLeft: 4,
    opacity: 0,
    transition: 'all 0.5s ease',
    '& .material-icons': {
      fontSize: 16,
      marginRight: 3,
    },
  },
  historyName: {
    width: 80,
  },
  historyeBtn: {
    width: 20,
    paddingRight: '16px!important',
  },
  historyMailSubject: {
    position: 'relative',
    width: '72%',
    marginLeft: 'auto',
    fontSize: '10px!important',
    display: 'flex!important',
    alignItems: 'center',
  },
  openedMailIcon: {
    background: '#27AE60',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center;',
    alignItems: 'center',
    marginRight: 4,
    '& .MuiSvgIcon-root': {
      fontSize: '10px!important',
      color: '#fff!important',
      margin: '0!important',
    },
  },
  clipIcon: {
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center;',
    alignItems: 'center',
    marginRight: 4,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: '20px!important',
      color: '#696969!important',
      margin: '0!important',
    },
  },
  historyMailSubjectBox: {
    width: '98%',
    display: 'block',
  },
  historyMailSubjectTxt: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  historyMailSubjectCount: {
    display: 'block',
  },
  btn: {
    minWidth: 60,
    padding: '5px 0',
    fontSize: 12,
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    flexShrink: 0,
  },
}));

export default function TaiouRirekiItem(props) {
  const {
    state,
    setState,
    row,
    index,
    labelId,
    updateHistory,
    deleteHistory,
    downloadAttachment,
    customerId,
    customer,
  } = props;

  const common = commonStyles();
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  // 案内・接触登録
  const [infoRegisterOpen, setInfoRegisterOpen] = useState(false);

  // メール作成ウィンドウ
  const [createWindowOpen, setCreateWindowOpen] = useState(false);
  const createWindowClose = () => {
    setCreateWindowOpen(false);
  };

  const getDetail = () => {
    if (row.guidanceId) {
      setInfoRegisterOpen(true);
    } else if (row.actionCode === 7 && ACTION_DETAILE_MAIL_GROUP.includes(row.detailCode)) {
      setCreateWindowOpen(true);
    } else {
      handleOpen();
    }
  };

  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  // 連打防止
  const isProcessing = useRef(false);

  const handleDownload = async () => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    await downloadAttachment(row.actionId);
    isProcessing.current = false;
  };

  const mailSubject = () => {
    return (
      <Grid className={classes.historyMailSubject}>
        {row.isOpenedMail === 1 && (
          <span className={classes.openedMailIcon}>
            <DraftsOutlinedIcon />
          </span>
        )}
        {row.isAttachment === 1 && (
          <Grid className={classes.clipIcon} onClick={handleDownload}>
            <AttachFileIcon />
          </Grid>
        )}
        <span className={classes.historyMailSubjectBox}>
          <span className={classes.historyMailSubjectTxt}>
            {row.mailSubject}
          </span>
          <span className={classes.historyMailSubjectCount}>
            閲覧{row.totalMailOpenedCount ? row.totalMailOpenedCount : 0}回：
            {row.latestMailOpenedAt && (row.latestMailOpenedAt).substr(0, 16).replaceAll('-', '/')}
          </span>
        </span>
      </Grid>
    );
  };
  const otherContent = () => {
    return (
      <Grid className={classes.historyMailSubject}>
        <span className={classes.historyMailSubjectTxt}>
          {row.content}
        </span>
      </Grid>
    );
  };

  const getGuidance = () => {
    if (row.getSupportGuidanceId) {
      return (
        <Grid className={classes.iconButton}>
          <Button
            className={classes.iconButton1}
            onClick={() => { setInfoRegisterOpen(true); }}
          >
            <Icon>date_range</Icon>
            案内情報
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid />
      );
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid
        onClick={(event) => handleClick(event, row.name)}
        tabIndex={-1}
        className={classes.historyRow}
      >
        <Grid id={labelId} className={`${classes.historyDate} historyDate`}>
          <span>{row.startAt.substr(0, 10).replaceAll('-', '/')}</span>
        </Grid>
        <Grid className={classes.historyTime}>
          {(row.startAt).substr(11, 5)}
          {getGuidance()}
        </Grid>
        <Grid className={classes.historyContent} container>
          <Grid>
            <Grid className={classes.historyContent1}>
              {ACTION_CODE[row.actionCode]}
              <span>/</span>
              {ACTION_DETAIL_CODE[row.detailCode]}
            </Grid>
            <Grid className={classes.historyContent2}>
              <span>担当</span>
              {row.userFullName}
            </Grid>
          </Grid>
          <Grid alignItems="center" className={classes.btnWrap}>
            <Button
              className={`${classes.btn} ${common.buttonsPrimary}`}
              onClick={getDetail}
            >
              編集
            </Button>
          </Grid>
          <EditWindow
            open={open}
            onClose={handleClose}
            index={index}
            row={row}
            state={state}
            setState={setState}
            actionCode={row.actionCode}
            detailCode={row.detailCode}
            updateHistory={updateHistory}
            deleteHistory={deleteHistory}
            customerId={customerId}
          />
          <InfoRegister
            open={infoRegisterOpen}
            onOpen={() => { setInfoRegisterOpen(true); }}
            onClose={() => { setInfoRegisterOpen(false); }}
            guidanceId={row.guidanceId || row.getSupportGuidanceId}
            customer={customer}
          />
          {createWindowOpen && (
            <CreateWindowContainer
              user={customer}
              handleClose={createWindowClose}
              isLocalStorage
              open={createWindowOpen}
              taioRirekiFlg
              actionId={row.actionId}
              mailQueueId={row.mailQueueId}
              mailAddress={row.mailAddress}
            />
          )}
        </Grid>
        {((row.actionCode === 7) && !ACTION_DETAIL_TAIO_RIREKI_GROUP.includes(row.detailCode))
          ? mailSubject()
          : otherContent()}
      </Grid>
    </Grid>
  );
}
