// 反永続的に残したいものはここに記載。
export const TREE_TYPECODE = 'typeCode';
export const TREE_UNITCODE = 'unitCode';
export const LIMIT_INSTRUCTION_LIST = 'limit_instruction_list';
export const TREE_DIVISION_ID = 'tree_divisionId';
export const USER_ROLE_IDS = 'userRoleIds';
export const CRM_DISPLAY = 'crmDisplay';
export const AFTER_CALL_CODE = 'afterCallCode';

// Logout時削除 -- 一日経ったら削除されたいものはここより下記に記載。

// Division系
export const ALL_DIVISION = 'ALL_DIVISION';

export const DAILY_SHOW_DASHBOARD = 'daily_show_dashboard';

export const CHANGE_USER = 'daily_changeUser'; // 開発環境用ユーザ切り替え情報

export const DAILY_LOCAL_STORAGE_ARR = [
  ALL_DIVISION,
  DAILY_SHOW_DASHBOARD,
  CHANGE_USER,
];
