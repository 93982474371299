import { useState } from 'react';
import ChangeManageComponent from '../../../../components/common/customer/changeManage/changeManege';
import customerGetShareApi from '../../../../apis/customer/customerGetShareApi';
import CustomerUpdateShareApi from '../../../../apis/customer/customerUpdateShareApi';
import customerDeleteShareApi from '../../../../apis/customer/customerDeleteShareApi';

function ChangeManageContainer(props) {
  const { initialParam, onClickSelectButton, open, setOpen, updateTree } = props;

  // GET
  const [shares, setShares] = useState({});
  const getCustomerShare = async (id) => {
    await customerGetShareApi(id)
      .then(async (res) => {
        await setShares(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // PUT [/customer/shareupdate]API
  const updateCustomerShare = async (id, params) => {
    await CustomerUpdateShareApi(id, params)
      .then(async () => {
        await getCustomerShare(initialParam.customerId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // DELETE : /customers/share/{customerId}
  const deleteCustomerShare = async (params) => {
    await customerDeleteShareApi(params)
      .then(async () => {
        await getCustomerShare(initialParam.customerId);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const functions = {
    getCustomerShare,
    updateCustomerShare,
    deleteCustomerShare,
  };

  return (
    <ChangeManageComponent
      initialParam={initialParam}
      onClickSelectButton={onClickSelectButton}
      shares={shares}
      open={open}
      setOpen={setOpen}
      functions={functions}
      updateTree={updateTree}
    />
  );
}

export default ChangeManageContainer;
