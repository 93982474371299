import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import commonStyles from '../../../styles';
import SearchZipsContainer from '../../../../containers/search/zips/searchZips';

import { PREFECTURES_CODE } from '../../../../constants';
import {
  convertForm1,
} from '../../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormPostalCode,
} from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  address: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
  },
  addressBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  address1: {
    width: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  address2: {
    width: 78,
    '& .MuiSelect-select': {
      '&:focus': {
        background: 'none',
      },
    },
  },
  address3: {
    width: 120,
  },
  address4: {
    width: 255,
  },
  address5: {
    width: 327,
  },
  addressLbl: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  formControl: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      paddingBottom: 0,
    },
    '& > div': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  addressButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 300,
  },
  button1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    marginTop: 8,
    minHeight: '20.8px',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  button2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    marginBottom: 8,
    color: theme.palette.primaryColor,
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  buttonTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 2px',
    textTransform: 'none',
  },
  buttonIcon: {
    fontSize: 16,
    marginBottom: 2,
  },
  textField: {
    display: 'block',
    height: 21,
    marginTop: '-6px',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
    },
    '& div': {
      margin: 0,
      width: '100%',
      textAlign: 'left',
    },
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
  },
}));

export default function ResponseRegisterAddress(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    detail,
    setDetail,
    getComponent,
    searchCustomerByKeyword,
    errorCallback,
  } = props;

  const initial = detail;

  const onBlur = (e) => {
    const label = e.target.name;
    if (e.target.value !== detail[label]) {
      initial[label] = e.target.value;
      setDetail({ ...detail });
      console.log(detail[label]);
    }
    let labelArray = [];
    switch (label) {
      case 'address1':
        labelArray = ['address1', 'address2'];
        break;
      case 'address2':
        labelArray = ['address2'];
        break;
      default:
        break;
    }
    if (labelArray.length !== 0) searchCustomerByKeyword(detail, labelArray);
  };
  const prefectureBlur = (e) => {
    if (e.target.value !== detail.prefecture) {
      initial.prefecture = e.target.value;
      setDetail({ ...detail });
      console.log(detail.prefecture);
    }
  };

  const kokyakuSelectList = {
    prefectureItem: {
      type: 'selectPlaceholder',
      placeholder: '-',
      obj: {
        menus: PREFECTURES_CODE,
        title: '都道府県',
      },
      state: detail.prefecture,
      set: prefectureBlur,
    },
  };

  console.log(kokyakuSelectList);

  const [addressOpen, setAddressOpen] = useState(false);
  const onAddressOpen = () => setAddressOpen(true);
  const onAddressClose = () => setAddressOpen(false);
  const [zipState, setZipState] = useState({});
  /**
   *
   * @param {import("./types").ZipWindowResponse} row
   */
  const testSearchZipsOnChangeFunc = (row) => {
    const { zip, city, town, prefecture } = row;
    initial.zip = zip;
    initial.address1 = city;
    initial.address2 = town;
    initial.prefecture = prefecture;

    setDetail({ ...detail });
  };

  const getAddress = () => {
    switch (detail.customerStatusCode) {
      case 11:
        return (
          <Grid className={classes.address}>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid className={classes.address}>
            <Grid className={classes.addressBox}>
              <Grid className={classes.addressButton}>
                <Button className={classes.button2} onClick={onAddressOpen}>
                  <Typography className={classes.buttonTxt}>
                    郵便番号検索
                  </Typography>
                </Button>
                <SearchZipsContainer
                  addressOpen={addressOpen}
                  onAddressClose={onAddressClose}
                  onClickTr={testSearchZipsOnChangeFunc}
                  initialParam={zipState}
                  setInitialParam={setZipState}
                />
              </Grid>
            </Grid>
            <Grid className={classes.addressBox}>
              <Grid className={classes.address1}>
                <Typography className={baseClasses.title4}>郵便番号</Typography>
              </Grid>
              <Grid className={classes.address2}>
                <Typography className={baseClasses.title4}>都道府県</Typography>
              </Grid>
              <Grid className={classes.address3}>
                <Typography className={baseClasses.title4}>市区町村</Typography>
              </Grid>
              <Grid className={classes.address4}>
                <Typography className={baseClasses.title4}>以下住所</Typography>
              </Grid>
              <Grid className={classes.address5}>
                <Typography className={baseClasses.title4}>
                  マンション、部屋番号
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.addressBox}>
              <Grid className={classes.address1}>
                <TextBaseField
                  placeholder="-"
                  key={`zip-${detail.zip}`}
                  defaultValue={detail.zip}
                  className={classes.textField}
                  name="zip"
                  onBlur={onBlur}
                  validator={validateFormPostalCode()}
                  errorCallback={errorCallback('zip')}
                />
              </Grid>
              <Grid className={classes.address2}>
                {getComponent(kokyakuSelectList.prefectureItem)}
              </Grid>
              <Grid
                className={classes.address3}
                onClick={() => searchCustomerByKeyword(detail, ['address1', 'address2'])}
              >
                <TextBaseField
                  placeholder="-"
                  key={`address1-${detail.address1}`}
                  defaultValue={detail.address1}
                  className={classes.textField}
                  name="address1"
                  onBlur={onBlur}
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertForm1}
                  errorCallback={errorCallback('address1')}
                />
              </Grid>
              <Grid
                className={classes.address4}
                onClick={() => searchCustomerByKeyword(detail, ['address2'])}
              >
                <TextBaseField
                  placeholder="-"
                  key={`address2-${detail.address2}`}
                  defaultValue={detail.address2}
                  className={classes.textField}
                  name="address2"
                  onBlur={onBlur}
                  validator={validateFormString1({ maxLengthInt: 250 })}
                  convertor={convertForm1}
                  errorCallback={errorCallback('address2')}
                />
              </Grid>
              <Grid className={classes.address5}>
                <TextBaseField
                  placeholder="-"
                  key={`building-${detail.building}`}
                  defaultValue={detail.building}
                  className={classes.textField}
                  name="building"
                  onBlur={onBlur}
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertForm1}
                  errorCallback={errorCallback('building')}
                />
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>現住所</Typography>
      {getAddress()}
    </Grid>
  );
}
