import React, { useState, useEffect } from 'react';
import { Grid, Typography, List, ListItem, Button } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import UserTree from '../../../common/userTreeNew';
import store from '../../../../store';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '8px',
  },
  listItems: {
    padding: '5px 10px',
    width: 'auto',
    marginRight: '5px',
    background: `${theme.palette.primaryColor}29`,
    borderRadius: '30px',
    marginBottom: 5,
  },
  deleteButton: {
    color: theme.palette.primaryColor,
    minWidth: 'auto',
    padding: '4px 0px',
    '& .material-icons': {
      fontSize: 14,
      marginLeft: 5,
    },
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  addButtonIcon: {
    color: theme.palette.primaryColor,
    fontSize: '16px',
  },
  addButtonTxt: {
    color: theme.palette.primaryColor,
  },
  grid3: {
    '& .MuiGrid-container': {
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      '& .MuiButton-label': {
        fontSize: 13,
      },
    },
  },
  customeTreeMulti: {
    '& button': {
      color: '#000',
      '&:hover': {
        color: '#000',
      },
    },
  },
  btn: {
    display: 'flex',
    fontSize: 13,
    padding: 0,
    border: 'none',
    '& svg': {
      fontSize: 18,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      border: 'none',
      boxShadow: 'none',
    },
  },
}));

function CreateResponsiblePerson(props) {
  const { addParam, setAddParam } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // 担当者選択ウィンドウ(customTreeMulti)の開閉ステータス
  const [open, setOpen] = useState(false);
  // 担当者（複数）選択
  const [responsiblePerson, setResponsiblePerson] = useState(addParam.users);
  // 担当者選択（複数）ツリー組織選択
  const [selectDivision, setSelectDivision] = useState([]);
  const deleteResponsiblePerson = (id) => {
    setResponsiblePerson(responsiblePerson.filter((p) => p.userId !== id));
  };
  const { responseHeader } = store.getState();

  useEffect(() => {
    setAddParam({ ...addParam, users: responsiblePerson });
  }, [responsiblePerson]);

  const isOpenTree = () => {
    setOpen(true);
    if (responsiblePerson?.length === 0) {
      setResponsiblePerson([{
        userId: responseHeader?.userId,
        userName: responseHeader?.userName,
      }]);
    }
  };

  return (
    <Grid className={classes.container}>
      <Grid style={{ width: '25%' }}>
        <Typography className={baseClasses.title4}>
          対応者<span style={{ color: '#D83420' }}>＊</span>
        </Typography>
      </Grid>
      <Grid className={baseClasses.small} style={{ width: '65%' }}>
        <List className={classes.list}>
          {responsiblePerson.map((p) => {
            return (
              <ListItem
                key={p.userId}
                className={`${baseClasses.small} ${classes.listItems}`}
              >
                {p.userName ? p.userName : `${p.userLastName}${p.userFirstName}`}
                <Button
                  className={classes.deleteButton}
                  onClick={() => deleteResponsiblePerson(p.userId)}
                >
                  <Icon className={classes.deleteButtonIcon}>cancel</Icon>
                </Button>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid className={classes.grid3} style={{ width: '10%' }}>
        <Button
          className={`${baseClasses.buttonsSecondary} ${classes.btn}`}
          onClick={() => isOpenTree()}
        >
          <Add />
          追加
        </Button>
        <UserTree
          isOpenTree={open}
          setIsOpenTree={setOpen}
          selectUser={responsiblePerson.map((u) => ({
            userId: u.userId,
            userName: u.userName
              ? u.userName
              : `${u.userLastName}${u.userFirstName}`,
          }))}
          setSelectUser={setResponsiblePerson}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          isMulti
          isDialog
          isSelectUserWindow
        />
      </Grid>
    </Grid>
  );
}

export default CreateResponsiblePerson;
