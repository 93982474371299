import React from 'react';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import SetStoreGroupInner from './parts/setStoreGroupInner';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  headerInnter: {
    paddingLeft: 20,
    margin: '0 auto',
  },
  content: {
    padding: '20px',
    backgorund: '#fff',
  },
});

function SetStoreGroup(props) {
  const { settings, areaCode, setAreaCode, deleteStoreGroup } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={baseClasses.header}>
        <Grid className={classes.headerInnter}>
          <Typography className={baseClasses.title2}>
            店舗グループ設定
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <SetStoreGroupInner
          settings={settings}
          areaCode={areaCode}
          setAreaCode={setAreaCode}
          deleteStoreGroup={deleteStoreGroup}
        />
      </Grid>
    </Grid>
  );
}

export default SetStoreGroup;
