import { useEffect, useState } from 'react';

import zipApi from '../../../apis/common/zips';

import SearchZips from '../../../components/search/zips/index';

function SearchZipsContainer(props) {
  const {
    onClickTr,
    initialParam = {},
    setInitialParam,
    addressOpen,
    onAddressClose,
  } = props;
  const [addressList, setAddressList] = useState([]);
  const [open2, setOpen2] = useState(true);
  const [openModal, setOpenModal] = useState(false); // 検索発火用モーダル
  const [isClickSearchButton, setIsClickSearch] = useState(false);

  const getAddressList = (params) => {
    zipApi(params).then((res) => {
      setAddressList(res.data.zips);
    });
  };

  // 全クリア
  const clickClearFunc = () => {
    setAddressList([]);
    setOpen2(false);
    setIsClickSearch(false);
    setInitialParam({
      code: null,
      address: '',
    });
  };
  // 完了
  const clickSelectFunc = () => {
    getAddressList(initialParam);
    setOpen2(false);
    setIsClickSearch(true);
  };
  // 検索結果の決定
  const clickCheckResultFunc = () => {
    setOpenModal(false);
    setOpen2(true);
  };

  // 検索結果の表示
  useEffect(() => {
    if (addressOpen && (initialParam.code || initialParam.address)) {
      getAddressList(initialParam);
    }
  }, [addressOpen]);

  return (
    <SearchZips
      resultList={addressList}
      onClickTr={onClickTr}
      getAddressList={getAddressList}
      isClickSearchButton={isClickSearchButton}
      addressOpen={addressOpen}
      onAddressClose={onAddressClose}
      open2={open2}
      setOpen2={setOpen2}
      openModal={openModal}
      setOpenModal={setOpenModal}
      clickClearFunc={clickClearFunc}
      clickSelectFunc={clickSelectFunc}
      clickCheckResultFunc={clickCheckResultFunc}
      initialParam={initialParam}
      setInitialParam={setInitialParam}
    />
  );
}

export default SearchZipsContainer;
