/* eslint import/prefer-default-export: 0 */
export const FULLSCREEN_LOADING = 'FULLSCREEN_LOADING';

// 顧客メイン情報変更履歴リスト
export const INFO_CHANGE_HISTORY_LOADING = 'INFO_CHANGE_HISTORY_LOADING';

// CRM顧客検索
export const CRM_CUSTOMER_SEARCH = 'CRM_CUSTOMER_SEARCH';
// CRM 反響未処理
export const CRM_RESPONSE_LIST = 'CRM_RESPONSE_LIST';
// CRM 源泉未処理
export const CRM_FLYER_SALES = 'CRM_FLYER_SALES';
// CRM 反響回しアプリ
export const CRM_SORTING_WINDOW = 'CRM_SORTING_WINDOW';

// ライブラリ作成ウィンドウ
export const LIBRARY_EDIT_WINDOW = 'LIBRARY_EDIT_WINDOW';

// 署名作成ウィンドウ
export const SIGNITURE_EDIT_WINDOW = 'SIGNITURE_EDIT_WINDOW';

// メール作成ウィンドウ
export const MAIL_EDIT_WINDOW = 'MAIL_EDIT_WINDOW';

// メールテスト送信・送信
export const MAIL_SENDING = 'MAIL_SENDING';
export const TESTMAIL_SENDING = 'TESTMAIL_SENDING';

// ライブラリツリー
export const LIBRARY_DIRECTORY_TREE = 'LIBRARY_DIRECTORY_TREE';
export const LIBRARY_DIRECTORY_TREE_LIBRRARY = 'LIBRARY_DIRECTORY_TREE';

// ライブラリテーブル
export const LIBRARY_TABLE = 'LIBRARY_TABLE';

// ライブラリ画面
export const LIBRARY_SIDEBAR = 'LIBRARY_SIDEBAR';
export const LIBRARY_WINDOW = 'LIBRARY_WINDOW';

// 組合せ設定
export const COMBINATION_SETTING = 'COMBINATION_SETTING';

// ファイルストレージ
export const FILE_STORAGE = 'FILE_STORAGE';

// 物件検索
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';

// 顧客一括管理
export const BULK_MANAGE = 'BULK_MANAGE';

// 物件メール送信ウインドウ
export const LIBRARY_SEND_WINDOW = 'LIBRARY_SEND_WINDOW';

export const USER_ORG_SETTING = 'USER_ORG_SETTING';

export const DIRECT_REGISTER = 'DIRECT_REGISTER';
export const DIRECT_ACTION = 'DIRECT_ACTION';

export const GENSEN_REGISTER_HISTORY = 'GENSEN_REGISTER_HISTORY';

export const GET_CHANCE_LIST = 'GET_CHANCE_LIST';
