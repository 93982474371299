import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textarea: {
    border: '1px #c8c8c8 solid',
    height: 300,
    lineHeight: '1.5',
    padding: 8,
    width: '100%',
    '&:focus-visible': {
      borderRadius: 5,
      outline: `2px solid ${theme.palette.primaryColor}`,
    },
  },
}));
export default function SmsBody(props) {
  const { values } = props;
  const classes = useStyles();
  const [txt, setTxt] = useState(values.state);
  const onBlurFunc = () => {
    values.setState(txt);
  };
  useEffect(() => {
    setTxt(values.state);
  }, [values.state]);
  return (
    <textarea
      className={classes.textarea}
      value={txt}
      onBlur={onBlurFunc}
      onChange={(e) => { setTxt(e.target.value); }}
    />
  );
}
