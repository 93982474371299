import { useState, useEffect } from 'react';
import UserTree from '../common/userTreeNew';

import store from '../../store';

function SelectWindow(props) {
  const { isOpen, setIsOpen = () => {}, onSelect } = props;

  const [selectUser, setSelectUser] = useState({});
  const [selectDivision, setSelectDivision] = useState({});

  const { responseHeader } = store.getState();

  const handleSelectFunction = () => {
    if (!selectUser?.userId && !selectDivision?.divId) return;
    // 組織
    if (!selectUser?.userId && selectDivision?.divId) {
      onSelect({
        userId: null,
        divisionId: selectDivision.divId,
        name: selectDivision.divisionName,
      });
      return;
    }
    // 担当者
    if (selectUser?.userId) {
      onSelect({
        userId: selectUser.userId,
        divisionId: selectDivision.divId,
        name: selectUser.userName,
      });
    }
  };

  const handleClearFunction = () => {
    setSelectUser({});
    setSelectDivision({});
  };

  useEffect(() => {
    if (!isOpen) return;
    // 初期条件の追加
    setSelectUser({
      userId: responseHeader.userId,
      userName: responseHeader.userName,
      divisionId: responseHeader?.mainDivision.divisionId,
      divisionName: responseHeader?.mainDivision.divisionName,
    });
  }, [isOpen]);

  return (
    <UserTree
      selectUser={selectUser}
      setSelectUser={setSelectUser}
      selectDivision={selectDivision}
      setSelectDivision={setSelectDivision}
      isOpenTree={isOpen}
      setIsOpenTree={setIsOpen}
      handleSelectFunction={handleSelectFunction}
      handleClearFunction={handleClearFunction}
      isDialog
      doubleSearch
      clickableUserAndDivision
    />
  );
}

export default SelectWindow;
