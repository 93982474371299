import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, makeStyles } from '@material-ui/core';
import Modal from '../../common/modal';
import commonStyles from '../../styles';
import CustomInput from '../parts/customInput';
import { LabelWrapGroup, LabelWrap } from '../../common/labelWrap';
import { classNames, joinBySlash } from '../../../commonFunction';
import CityJisCodesDialog from '../cityJisCodesDialog';
import StoreGroupsDialog from '../storeGroupsDialog';
import { useEditDialogContext } from './editDialogContext';
import inputNumber from '../../../commonFunction/inputNumber';
import { COMBINATION_SETTING } from '../../../constants/loading';
import { useLoading } from '../../../hooks';
import {
  validateFormString1,
  validateFormInt1,
} from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { TextBaseField } from '../../eleCommon/validation';

const useStyle = makeStyles({
  topWrap: {
    display: 'flex',
    '& > div': {
      flex: 1,
    },
  },
  footerButtonWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  offsetTop: {
    marginTop: '.5rem !important',
  },
  offsetLeft: {
    marginLeft: '1rem',
  },
  offsetRight: {
    marginRight: '1rem',
  },
  offsetBoth: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  wrap: {
    background: '#fff',
    padding: 8,
    border: '1px #ccc solid',
  },
});

export default function DialogBody() {
  const {
    isShowEditDialog,
    currentArea,
    showEditDialog,
    editTargetRef,
    formRef,
    onBlur,
    onInputChange,
    openAreaDialog,
    openShopDialog,
    onSubmit,
    disabled,
    openArea,
    initialParamArea,
    setOpenArea,
    onAreaSubmit,
    openStore,
    initialParamStore,
    setOpenStore,
    onStoreSubmit,
  } = useEditDialogContext();

  const { hasLoading } = useLoading();

  const baseClasses = commonStyles();
  const classes = useStyle();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  useEffect(() => {
    inputNumber();
  }, []);
  return (
    <Modal
      open={isShowEditDialog}
      onClose={() => showEditDialog(false)}
      title={`組合せ設定${!editTargetRef.current ? '新規' : '詳細'}画面`}
      maxWidth={800}
      buttons={[{
        className: baseClasses.buttonsPrimary,
        label: '実行',
        onClick: onSubmit,
        disabled: disabled || errorSet.size !== 0,
      }]}
      className={`${!isSp && hasLoading(COMBINATION_SETTING) ? baseClasses.loading : ''}`}
    >
      <fieldset className={classes.wrap}>
        <legend>選択地域</legend>
        <Grid className={classes.topWrap}>
          <CustomInput
            label="エリア"
            defaultValue={currentArea.areaName}
            disabled
            inline
            gap={10}
            name="areaName"
          />
          <TextBaseField
            label="組合せ名称"
            defaultValue={formRef.current.patternName ?? ''}
            required
            name="patternName"
            onBlur={onBlur}
            onChange={onInputChange}
            inline
            gap={10}
            validator={validateFormString1({ maxLengthInt: 20 })}
            errorCallback={errorCallback('patternName')}
          />
          <TextBaseField
            label="表示順序"
            defaultValue={formRef.current.displayOrder ?? ''}
            required
            type="number"
            name="displayOrder"
            onBlur={onBlur}
            onChange={onInputChange}
            inline
            gap={10}
            inputProps={{ min: '0' }}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('displayOrder')}
          />
        </Grid>
      </fieldset>
      <LabelWrapGroup labelWidth="120px">
        <LabelWrap label="選択地域" className={`${classes.offsetTop} ${classes.wrap}`} required>
          <Button
            className={`${baseClasses.buttonsSecondary} ${classes.offsetRight}`}
            onClick={openAreaDialog}
          >
            選択地域
          </Button>
          {formRef.current.targetCityName}
        </LabelWrap>
        <LabelWrap label="WEB閲覧履歴あり" className={`${classes.offsetTop} ${classes.wrap}`} required>
          <Button
            className={`${baseClasses.buttonsSecondary} ${classes.offsetRight}`}
            onClick={() => openShopDialog(1)}
          >
            店舗選択
          </Button>
          {joinBySlash(formRef.current.storeGroupNamesPatternTypeCode1)}
        </LabelWrap>
        <LabelWrap label="WEB閲覧履歴なし" top offsetTop="9px" className={`${classes.offsetTop} ${classes.wrap}`}>
          <Grid>
            <LabelWrap>
              <CustomInput
                defaultValue={formRef.current.pricePatternTypeCode2 ?? ''}
                required
                type="number"
                name="pricePatternTypeCode2"
                onBlur={onBlur}
                onChange={onInputChange}
                inline
                suffix="万円以上"
                errorCallback={errorCallback('pricePatternTypeCode2')}
              />
              <Button
                className={classNames(baseClasses.buttonsSecondary, classes.offsetBoth)}
                onClick={() => openShopDialog(2)}
              >
                店舗選択
              </Button>
              {joinBySlash(formRef.current.storeGroupNamesPatternTypeCode2)}
            </LabelWrap>
            <LabelWrap>
              <CustomInput
                defaultValue={formRef.current.pricePatternTypeCode3 ?? ''}
                required
                type="number"
                name="pricePatternTypeCode3"
                onBlur={onBlur}
                onChange={onInputChange}
                inline
                suffix="万円以下"
                errorCallback={errorCallback('pricePatternTypeCode3')}
              />
              <Button
                className={classNames(baseClasses.buttonsSecondary, classes.offsetBoth)}
                onClick={() => openShopDialog(3)}
              >
                店舗選択
              </Button>
              {joinBySlash(formRef.current.storeGroupNamesPatternTypeCode3)}
            </LabelWrap>
            <LabelWrap>
              設定なし
              <Button
                className={classNames(baseClasses.buttonsSecondary, classes.offsetBoth)}
                onClick={() => openShopDialog(4)}
              >
                店舗選択
              </Button>
              {joinBySlash(formRef.current.storeGroupNamesPatternTypeCode4)}
            </LabelWrap>
          </Grid>
        </LabelWrap>
      </LabelWrapGroup>
      <CityJisCodesDialog
        open={openArea}
        defaultSelected={initialParamArea}
        onClose={() => setOpenArea(false)}
        onSubmit={onAreaSubmit}
      />
      <StoreGroupsDialog
        open={openStore}
        defaultSelected={initialParamStore}
        onClose={() => setOpenStore(false)}
        onSubmit={onStoreSubmit}
        currentArea={currentArea}
      />
    </Modal>
  );
}
