import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import localStyles from './localStyle';
import commonStyles from '../../../styles';
import CalendarLabel from './CalendarLabel';

const useStyles = makeStyles({
  wrap: {
    alignItems: 'center',
    padding: '8px',
  },
  wrapMultiParallel: {
    alignItems: 'center',
    padding: '0px',
  },
  border: {
    border: '1px solid #C8C8C8',
  },
});

function CalendarInputMultiDate(props) {
  const classes = useStyles();
  const style = localStyles();
  const common = commonStyles();
  const val = props;
  const inputRef1 = useRef();
  const inputRef2 = useRef();

  // // ローカルステート
  let defaultStartValue = val.start ?? '';
  let defaultEndValue = val.end ?? '';
  let defaultStartTime = val.start ?? '';
  let defaultEndTime = val.end ?? '';
  if (defaultStartValue.indexOf('/') !== -1 && defaultStartValue.indexOf(':') !== -1) {
    defaultStartValue = defaultStartValue.substr(0, 16).replace(/\//g, '-').replace(/ /g, 'T');
    defaultEndValue = defaultEndValue.substr(0, 16).replace(/\//g, '-').replace(/ /g, 'T');
    defaultStartTime = defaultStartTime.substr(0, 16);
    defaultEndTime = defaultEndTime.substr(0, 16);
  }
  if (defaultStartValue.indexOf('-') !== -1 && defaultStartValue.indexOf('T') !== -1) {
    defaultStartValue = defaultStartValue.substr(0, 16).replace(/-/g, '/').replace(/T/g, ' ');
    defaultEndValue = defaultEndValue.substr(0, 16).replace(/-/g, '/').replace(/T/g, ' ');
    defaultStartTime = defaultStartTime.substr(0, 16).replace(/-/g, '/').replace(/T/g, ' ');
    defaultEndTime = defaultEndTime.substr(0, 16).replace(/-/g, '/').replace(/T/g, ' ');
  }
  const [local, setLocal] = useState({
    startDist: defaultStartValue,
    endDist: defaultEndValue,
    start: defaultStartTime,
    end: defaultEndTime,
  });

  // reset
  useEffect(() => {
    const newLocal = { ...local };
    let isChanged = false;

    // 日時が無効の場合、そのまま返す
    if (local.endDist === '無効な時間です' || local.startDist === '無効な時間です') {
      return;
    }

    if (defaultStartValue !== local.startDist) {
      newLocal.startDist = defaultStartValue;
      newLocal.start = defaultStartTime;
      isChanged = true;
    }

    if (defaultEndValue !== local.endDist) {
      newLocal.endDist = defaultEndValue;
      newLocal.end = defaultEndTime;
      isChanged = true;
    }

    if (isChanged) setLocal(newLocal);
  }, [defaultStartValue, defaultEndValue, defaultStartTime, defaultEndTime]);

  // id
  const valChange = (e) => {
    const StartInput = document.getElementById(`${val.id}t1`); // 開始日時のinput
    const EndInput = document.getElementById(`${val.id}t2`); // 終了日時のinput
    let startValue = StartInput.value;

    let endValue = EndInput.value;
    let getTimeStart;
    let getTimeEnd;
    let startDistValue = StartInput.value.replace(/-/g, '/').replace(/T/g, ' ');
    let endDistValue = EndInput.value.replace(/-/g, '/').replace(/T/g, ' ');

    EndInput.setAttribute('min', startValue);
    StartInput.setAttribute('max', endValue);

    if (e.target === EndInput) {
      // 最小期間が選択されてなく、最大期間が最小の日時を上回っている時は、入力値を【無効】とする
      // JST同士で比較するため変換
      getTimeStart = new Date(defaultStartValue.replace(/\//g, '-')).getTime();
      getTimeEnd = new Date(endValue.replace(/\//g, '-')).getTime();
      if (EndInput.validity.rangeUnderflow || (startValue === '' && getTimeStart > getTimeEnd)) {
        endDistValue = '無効な時間です';
        endValue = '';
      } else {
        endDistValue = endDistValue.replace(/-/g, '/').replace(/T/g, ' ');
      }
    }
    if (e.target === StartInput) {
      // 最大期間が選択されてなく、最小期間が最大の日時を下回っている時は、入力値を【無効】とする
      // JST同士で比較するため変換
      getTimeStart = new Date(startValue.replace(/\//g, '-')).getTime();
      getTimeEnd = new Date(defaultEndValue.replace(/\//g, '-')).getTime();
      if (StartInput.validity.rangeOverflow || (endValue === '' && getTimeStart > getTimeEnd)) {
        startDistValue = '無効な時間です';
        startValue = '';
      } else {
        startDistValue = startDistValue.replace(/-/g, '/').replace(/T/g, ' ');
      }
    }
    setLocal({
      startDist: e.target === StartInput ? startDistValue : local.startDist,
      endDist: e.target === EndInput ? endDistValue : local.endDist,
      start: e.target === StartInput ? startValue : local.start,
      end: e.target === EndInput ? endValue : local.end,
    });
    e.target === StartInput ? val.setDate(startValue, local.end)
      : val.setDate(local.start, endValue);
  };

  const onClick = (e, dateVal) => {
    const d = new Date(dateVal);
    if (val.type === 'datetime-local') e.target.value = val.convertDateTimeToIso(d);
    if (val.type === 'date') e.target.value = val.convertDateToIso(d);
  };

  // useEffect(() => {
  //   // 値がセットされたらカレンダー自体を閉じる
  //   inputRef1.current.blur();
  //   inputRef2.current.blur();
  // }, [val]);

  return (
    <Grid container className={classes.border}>
      {val.require ? <span style={{ color: '#D83420' }}>＊</span> : null}
      <Grid container className={classes.wrap}>
        {val.label[0] && <CalendarLabel txt={val.label[0]} require={!!val.require} />}
        <label className={style.label} htmlFor={`${val.id}t1`}>
          {!local.startDist ? <span className={`${common.strong} ${style.txt} ${style.placeholderTxt}`}>{val.placeholder && val.placeholder[0] ? val.placeholder[0] : '-'}</span> : <span className={`${common.strong} ${style.txt}`}>{local.startDist}</span>}
          <input
            className={style.input}
            require={val.require.toString()}
            id={`${val.id}t1`}
            type={val.type}
            value={local.start}
            onChange={valChange}
            readOnly={val.readonly ? 'readOnly' : ''}
            onClick={(e) => onClick(e, local.start)}
            ref={inputRef1}
          />
        </label>
      </Grid>
      <span style={{ marginRight: '5px' }}>～</span>
      <Grid container className={classes.wrap}>
        {val.label[1] && <CalendarLabel txt={val.label[1]} require={!!val.require} />}
        <label className={style.label} htmlFor={`${val.id}t2`}>
          {!local.endDist ? <span className={`${common.strong} ${style.txt} ${style.placeholderTxt}`}>{val.placeholder && val.placeholder[1] ? val.placeholder[1] : '-'}</span> : <span className={`${common.strong} ${style.txt}`}>{local.endDist}</span>}
          <input
            className={style.input}
            require={val.require.toString()}
            id={`${val.id}t2`}
            type={val.type}
            value={local.end}
            onChange={valChange}
            readOnly={val.readonly ? 'readOnly' : ''}
            onClick={(e) => onClick(e, local.end)}
            min={local.start}
            ref={inputRef2}
          />
        </label>
      </Grid>
    </Grid>
  );
}

export default CalendarInputMultiDate;
