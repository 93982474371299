import React, { useMemo, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { Tree, TREE_TYPES } from '../../tree';
import { createTreeList, getAllFlyer, findItemsByUserId } from '../commonFunc/helpers';
import { useStore } from '../../../../hooks';

const useStyles = makeStyles({
  root: {
    width: '100%',
    // maxHeight: '100%',
    // minHeight: '30%',
    // overflow: 'auto',
    maxHeight: 241,
    overflow: 'scroll',
  },
});

function FlyerSalesTreeListResponse(props) {
  const {
    list,
    setSelectUser,
    setSelectDivision,
    setSelectFlyer,
  } = props;
  const classes = useStyles();
  const rootRef = useRef(null);

  const { responseHeader } = useStore();

  const treeList = useMemo(() => createTreeList(list), [list]);

  // 初期オープン情報
  const [defaultSelected, setDefaultSelected] = useState([]);

  // 組織Click時 -- 選択された組織のID/Name and 対象組織以降全ての顧客をset
  const handleSelectDivision = async (divObj, flyerList) => {
    setSelectDivision({
      divId: divObj.id,
      divisionName: divObj.name,
      children: divObj.children,
    });
    setSelectFlyer(flyerList);
    setSelectUser({ userId: 0, userName: '' });
  };

  // ユーザーClick時 -- 選択されたユーザーのID/Name and 対象ユーザー以降全ての顧客をset
  const handleClickUser = async (userObj, flyerList) => {
    setSelectUser({ userId: userObj.userId, userName: userObj.name });
    setSelectDivision({ divId: userObj.parentId, divisionName: userObj.parentName });
    setSelectFlyer(flyerList);
  };

  useEffect(() => {
    const { userId, mainDivision } = responseHeader;
    // ログインユーザーと紐付き
    if (userId) {
      const initialItem = {
        id: userId,
        type: TREE_TYPES.user,
        parentId: mainDivision.id,
      };

      setDefaultSelected([initialItem]);
      // 源泉未処理一覧のAPIが走れるため
      const items = findItemsByUserId(userId, treeList);
      const item = (mainDivision.id
        ? items.filter((v) => v.parentId === mainDivision.id)
        : items)[0];
      // eslint-disable-next-line no-use-before-define
      if (item) onItemClick(item);
    }
  }, [treeList]);

  const onItemClick = (item) => {
    const flyerList = getAllFlyer(item);
    switch (item.type) {
      case 'division':
        handleSelectDivision(item, flyerList);
        break;
      default:
        handleClickUser(item, flyerList);
    }
  };

  return (
    <List className={`${classes.root}`} ref={rootRef}>
      <Tree
        list={treeList}
        onChange={onItemClick}
        defaultSelected={defaultSelected}
        defaultOpenTypes={['user', 'division']}
        rootRef={rootRef}
        accordion
      />
    </List>
  );
}

export default FlyerSalesTreeListResponse;
