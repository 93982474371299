import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../styles';
import InstructionReportItem from '../../common/instructionReport/parts/instructionReportItem';
import { setInitialState } from '../../../store/direct/directGetDetailSlice';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    width: 447,
    minWidth: 447,
    background: '#fff',
  },
  header: {
    padding: '24px 24px 8px',
    borderBottom: '1px solid #C8C8C8',
  },
  content: {
    background: '#F3F3F3',
    padding: 24,
  },
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function EditWindow(props) {
  const {
    open,
    setOpen,
    onClose,
    tab,
    row,
    getDetailDirect,
    createTimeline,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.directGetDetail.directDetail);
  // Loading
  const { removeLoading } = useLoading();

  const onCloseBg = () => {
    onClose();
  };

  useEffect(async () => {
    if (open) {
      await getDetailDirect(row.directId);
      removeLoading();
    } else {
      dispatch(setInitialState());
    }
  }, [open]);

  const body = (
    <Grid className={classes.paper}>
      <Typography
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
        className={`${baseClasses.title3} ${classes.header}`}
      >
        選択指示ウィンドウ
      </Typography>
      <Grid className={classes.content}>
        {detail.directId ? (
          <InstructionReportItem
            setOpen={setOpen}
            information={detail}
            tab={tab}
            getDetailDirect={getDetailDirect}
            createTimeline={createTimeline}
          />
        ) : (<div> </div>)}
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCloseBg}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className={classes.dialog}
      >
        {body}
      </Dialog>
    </div>
  );
}
