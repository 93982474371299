import { useState, useEffect } from 'react';
import DetailSearchModal from '../../../components/search/detailSearch/detailSearchModal';
import { removeSessionStorage } from '../../../commonFunction';
import { SALES_GROUPS } from '../../../constants/userRoleGroup';
import store from '../../../store';
import { EMAIL_STATUS_AUTO, EMAIL_STATUS_GROUP } from '../../../constants';

export const initialTreeState = {
  // customerTreeResponseContainers.jsのsearchHeader内で設定
  // typeCode: simple.typeCode ? simple.typeCode : '',
  // customerTreeResponseContainers.jsのsearchHeader内で設定
  // unitCode: simple.unitCode ? simple.unitCode : '',
  level: '',
  // divisionId: '',
  userLevelMin: '', // 重要情報 - ランク
  userLevelMax: '', // 重要情報 - ランク
  isWebHistoryExist: '', // 重要情報 - Web履歴あり
  isSendableMailExist: '', // 重要情報 - Mailあり
  isCallablePhoneNumberExist: '', // 重要情報 - TELあり
  isDirectExist: '', // 重要情報 - 指示あり
  customerStatusCode: 1, // 重要情報 - 追跡状況
  isReceiveShare: '', // 重要情報 - 共有された顧客のみ
  isSendShare: '', // 重要情報 - 共有してる顧客のみ
  actionFrom: '', // 対応履歴 - 対応日時From
  actionTo: '', // 対応履歴 - 対応日時To
  actions: [], // 対応履歴 - 種類/反応
  actionKeyWord: '', // 対応履歴 - キーワード
  actionSearchCode: [], // 対応履歴 - キーワード キーワードの検索の範囲
  responseFrom: '', // 反響情報 - 反響日From
  responseTo: '', // 反響情報 - 反響日To
  effectFrom: '', // 反響情報 - 反響基準日From
  effectTo: '', // 反響情報 - 反響基準日To
  responseTypeList: [], // 反響情報 - 媒体 表示用
  largeResponseTypeId: '', // 反響情報 - 媒体（大）
  mediumResponseTypeId: '', // 反響情報 - 媒体（中）
  smallResponseTypeId: '', // 反響情報 - 媒体（小）
  fineResponseTypeId: '', // 反響情報 - 媒体（細）
  memberStatusCode: '', // 反響情報 - 会員状態
  houseBudgetMin: '', // 希望条件 - 戸建価格Min
  houseBudgetMax: '', // 希望条件 - 戸建価格Max
  livingSpaceMin: '', // 希望条件 - 戸建広さMin
  livingSpaceMax: '', // 希望条件 - 戸建広さMax
  landBudgetMin: '', // 希望条件 - 土地価格Min
  landBudgetMax: '', // 希望条件 - 土地価格Max
  wishLandAreaMin: '', // 希望条件 - 土地広さMin
  wishLandAreaMax: '', // 希望条件 - 土地広さMax
  budgetMin: '', // 詳細検索では使わない
  budgetMax: '', // 詳細検索では使わない
  floorPlan: [], // 希望条件 - 間取り
  annualIncomeMin: '', // 希望条件 - 年収Min
  annualIncomeMax: '', // 希望条件 - 年収Max
  isThinkSale: '', // 希望条件 - 検討 - 売却
  isThinkApartment: '', // 希望条件 - 検討 - マンション
  isThinkWholeHouseInvestment: '', // 希望条件 - 検討 - 一棟物投資
  isThinkDivisionInvestment: '', // 希望条件 - 検討 - 区分投資
  isThinkRebuilding: '', // 希望条件 - 検討 - 建替
  wishArea: '', // 希望条件 - 地域
  wishAreaIds: [], // 希望条件 - 地域Id
  wishAreaCode: [], // 希望条件 - 地域コード
  isNoneWishArea: '', // 希望条件 - 希望地域無し
  wishAreaSearchTypeCode: 0, // 希望条件 - 地域 ～検索に含める
  wishRailwayId: '', // 希望条件 沿線ID
  wishRailway: '', // 希望条件 沿線名
  wishRailwayIdSearchTypeCode: 0, // 希望条件 沿線 ～検索に含める
  wishStationFrom: '', // 希望条件 駅FromId
  wishStationFromName: '', // 希望条件 駅FromName
  wishStationTo: '', // 希望条件 駅ToID
  wishStationToName: '', // 希望条件 駅ToName
  searchStationTypeCode: 0, // 希望条件 駅 ～検索に含める
  mailOpenHistoryCode: '', // 未実装
  isGuidanceHistorySearch: '', // 行動情報 - 案内実績
  isVisitHistorySearch: '', // 行動情報 - 来社実績
  holidayWeekdays: [], // 補足情報 - お休みの日
  holidayWeekdayOthers: '', // 詳細検索ではいらない
  telStatusCode: '', // 補足情報 - 電話許可
  phoneSupportStatusCode: '', // 詳細検索では使わない
  emailStatusCodes: [], // 補足情報 - メール許可
  mailSendStatusCode: '', // 詳細検索では使わない
  isAddressExist: '', //  // 補足情報 - 住所ありなし
  abodeNowBuildingType: '', //  // 補足情報 - 現況住居種類
  isWebSearchOption: '', //  // 補足情報 - WEB動向検索オプション
  // customerIntroductionStatus: '', // 不要
  isAllowDm: '', // 補足情報 - DM許可
  currentUser: '', // 補足情報 - 現担当Name
  currentUserId: '', // 補足情報 - 現担当ID
  formerUser: '', // 補足情報 - 旧担当Name
  formerUserId: '', // 補足情報 - 旧担当ID
  previousUser: '', // 補足情報 - 前担当Name
  previousUserId: '', // 補足情報 - 前担当ID
  thinkStartSeasonFrom: '', // 追わない - 検討再開時期From
  thinkStartSeasonTo: '', // 追わない - 検討再開時期To
  noContactDetailReasonCode: [], // 追わない - 追わない理由
  noneContactKeyword: '', // 追わない - キーワード
  noneContactKeywordCodes: [], // 追わない - キーワードの検索の範囲
  keyword: '', // キーワード - キーワード
  keywordSearch: [1, 2, 3, 4, 5, 6, 7, 8, 9], // キーワードの検索の範囲
  recommendStatus: '', // レコメンド - レコメンド状況
  customerIds: '', // 顧客ID - 顧客ID
  convenientTimes: '', // TELが繋がりやすい時間帯
  lastActionDate: '', // 詳細検索では使わない
  userLevel: [], // モバイルのみつかう
  lastAccessDate: '', // 詳細検索では使わない
  displayOrderCode: '',
  searchType2: '', // 詳細検索では使わない
  searchType3: '', // 詳細検索では使わない
  searchType4: '', // 詳細検索では使わない
  searchType5: '', // 詳細検索では使わない
  guidanceCountMin: '', // 案内回数 最小
  guidanceCountMax: '', // 案内回数 最大
  isContactOrReceptionSearch: '', // 詳細検索では使わない
  isAvoidSharedCustomer: '', // 詳細検索では使わない
};

function initialState(simple) {
  const state = {};
  Object.keys(initialTreeState).forEach((key) => {
    state[key] = simple[key] ?? initialTreeState[key];
  });
  return state;
}

function DetailSearchContainer(props) {
  const { simple, isAvoidSharedCustomer, setAllCustomer = () => {}, isFromAllmail } = props;
  const { responseHeader } = store.getState();
  const { roleGroupId } = responseHeader;
  const [isOpenArea, setIsOpenArea] = useState(false);
  const [isOpenStation, setIsOpenStation] = useState(false);
  const [isOpenUserTree, setIsOpenUserTree] = useState(false);
  // デフォルト
  const defaultParams = initialState(simple);
  const [search, setSearch] = useState(defaultParams);
  const [res, setRes] = useState(
    defaultParams.responseTypeList.length > 0
      ? defaultParams.responseTypeList.map(obj => ({ ...obj })) : [],
  );

  // 簡易検索にてsearchのGStateが変更された時に、同期するため
  useEffect(() => {
    setSearch(defaultParams);
    setRes(
      defaultParams.responseTypeList.length > 0
        ? defaultParams.responseTypeList.map(obj => ({ ...obj })) : [],
    );
  }, [simple]);

  const set = (key, value) => {
    setSearch({ ...search, [key]: value });
  };

  const setMulti = (obj) => {
    const copyObj = Object.assign({}, search);
    for (const property in obj) {
      copyObj[property] = obj[property];
    }
    setSearch(copyObj);
  };

  const [responseClearCount, setResponseClearCount] = useState(0);

  const reset = () => {
    for (const key in initialTreeState) {
      removeSessionStorage('daily_' + key);
    }
    if (isFromAllmail) {
      // 一斉メールの場合
      if (SALES_GROUPS.includes(roleGroupId)) {
        // 営業権限の場合
        setSearch({
          ...initialTreeState,
          isSendableMailExist: 1,
          isWebSearchOption: false,
          emailStatusCodes: [EMAIL_STATUS_GROUP, EMAIL_STATUS_AUTO],
        });
      } else {
        // 営業権限以外の場合
        setSearch({
          ...initialTreeState,
          isWebSearchOption: false,
          emailStatusCodes: [EMAIL_STATUS_AUTO],
        });
      }
    } else {
      // 一斉メール以外
      setSearch({ ...initialTreeState });
    }
    // 反響媒体のクリア処理
    setResponseClearCount(responseClearCount + 1);
    setRes([]);
  };

  useEffect(() => {}, [responseClearCount]);

  return (
    <DetailSearchModal
      search={search}
      setSearch={setSearch}
      set={set}
      setMulti={setMulti}
      reset={reset}
      isOpenArea={isOpenArea}
      setIsOpenArea={setIsOpenArea}
      isOpenStation={isOpenStation}
      setIsOpenStation={setIsOpenStation}
      isOpenUserTree={isOpenUserTree}
      setIsOpenUserTree={setIsOpenUserTree}
      isAvoidSharedCustomer={isAvoidSharedCustomer}
      setAllCustomer={setAllCustomer}
      divisionId={simple?.divisionId}
      responseClearCount={responseClearCount}
      res={res}
      setRes={setRes}
      isFromAllmail={isFromAllmail}
    />
  );
}

export default DetailSearchContainer;
