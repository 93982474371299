const getTargetFunction = (obj, divisionId, keywords) => {
  const targetObj = {};
  let countDiv = 0;
  // 処理実行
  const getTargetDivision = (theArr, searchDivId) => {
    let result = null;
    if (theArr instanceof Array) {
      for (let i = 0; i < theArr.length; i += 1) {
        result = getTargetDivision(theArr[i], divisionId);
        if (result) {
          break;
        }
      }
    } else {
      for (const prop in theArr) {
        if (prop === 'divisionId') {
          if (theArr[prop] === searchDivId) {
            const divisionKey = 'divisions';
            if (!targetObj[divisionKey]) {
              targetObj[divisionKey] = [];
            }
            targetObj[divisionKey].push({
              children: theArr.children,
              users: theArr.users,
              divisionId: theArr.divisionId,
              groupName: theArr.groupName,
              subName: theArr.subName,
              type: 'division',
            });
            countDiv += 1;
          }
        } else if (prop === 'userName') {
          if (keywords === '') {
            break;
          }
          if (theArr[prop].includes(keywords)) {
            const divisionKey = 'users';
            if (!targetObj[divisionKey]) {
              targetObj[divisionKey] = [];
            }
            targetObj[divisionKey].push({
              userId: theArr.userId,
              userDivisionId: theArr.userDivisionId,
              userName: theArr.userName,
              isMainDivision: theArr.isMainDivision,
              type: 'user',
            });
            countDiv += 1;
          }
        }
        if (theArr[prop] instanceof Object || theArr[prop] instanceof Array) {
          result = getTargetDivision(theArr[prop], divisionId);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  };
  getTargetDivision(obj, divisionId);
  if (Object.keys(targetObj).length > 0) {
    targetObj.count = countDiv;
  }
  return targetObj;
};

export default getTargetFunction;
