import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function HolidayTelCheckBox(props) {
  const {
    label,
    type,
    all,
    index,
    checkWeekday,
    checkTime,
    initialParam,
  } = props;

  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    if (type === 'weekday') {
      checkWeekday(e.target.name, e.target.checked);
    }
    if (type === 'time') {
      checkTime(e.target.name, e.target.checked);
    }
  };

  // SP クリア
  const allCheckLift = () => {
    switch (all) {
      case 2:
        setChecked(false);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    allCheckLift();
  });

  useEffect(() => {
    if (type === 'weekday') {
      setChecked(initialParam.holidayWeekday?.includes(label) ?? false);
    }
    if (type === 'time') {
      setChecked(initialParam.convenientTime?.includes(label) ?? false);
    }
  }, [initialParam]);

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            color="primary"
            name={`${index}`}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
