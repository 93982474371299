import {
  Grid,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Modal from '../../../common/modal';
import commonStyles from '../../../styles';
import CustomSelect from '../../../eleCommon/customSelect';
import CustomBlankSelect from '../../../eleCommon/customBlankSelect';
import Calendar from '../../../common/calendar';
import { AREA_CODE_HISTORY, POSITION_CODE, GRADE_CODE } from '../../../../constants/index';
import { ROLE_GROUP_MAPPER } from '../../../../constants/userRoleGroup';

const useStyles = makeStyles({
  root: {
    '& .divisionHistorySearchBody': {
      padding: 8,
    },
  },
  content: {
    background: '#fff',
    padding: 8,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '160%',
    marginRight: 12,
    whiteSpace: 'nowrap',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    padding: 10,
  },
  area: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 47,
    '& > .MuiFormControl-root': {
      width: 370,
      borderBottom: '1px solid #8C8C8C',
    },
    '& .MuiSelect-selectMenu': {
      fontSize: 13,
      paddingTop: 1,
      paddingBottom: 0,
    },
    '& .MuiInput-formControl': {
      minWidth: 'auto',
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
  },
  calendar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 520,
    '& > div': {
      position: 'relative',
      border: 'none',
      padding: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > div': {
        position: 'relative',
        width: '45%',
        padding: 0,
        '&::after': {
          content: '""',
          display: 'block',
          background: 'transparent center/cover no-repeat',
          backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23333333" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"/></svg>\')',
          width: 16,
          height: 16,
          position: 'absolute',
          top: 3,
          right: 0,
          pointerEvents: 'none',
        },
      },
      '& > p': { flexShrink: 0 },
      '& label': {
        width: '100%',
        borderBottom: '1px #8c8c8c solid',
        textAlign: 'left',
      },
      '& label > span': {
        fontFamily: 'Roboto',
        fontSize: 13,
        lineHeight: 1.6,
        fontWeight: 400,
        padding: 0,
      },
      '& > h6:empty': { display: 'none' },
    },
  },
  middle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    padding: 4,
  },
  text: {
    width: 1018,
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: 400,
    },
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 37,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    '& > .MuiFormControl-root': {
      width: 447,
    },
    '& .MuiSelect-selectMenu': {
      fontSize: 13,
      paddingTop: 1,
      paddingBottom: 0,
      borderBottom: '1px solid #8C8C8C',
    },
    '& .MuiInput-formControl': {
      minWidth: 'auto',
    },
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '160%',
    marginRight: 32,
    whiteSpace: 'nowrap',
  },
  icon: {
    marginLeft: 32,
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  itemText: {
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: 400,
    },
  },
});

export default function DivisionHistorySearch(props) {
  const {
    open,
    onClose,
    data,
    functions,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const set = (value1, value2) => {
    const date1 = value1.replace(/-/g, '/') || '';
    const date2 = value2.replace(/-/g, '/') || '';
    data.setSearchDisplay({
      ...data.searchDisplay,
      applyingStartDate: date1,
      applyingEndDate: date2,
    });

    if (date1 === '' && date2 === '') {
      const { applyingStartDate, applyingEndDate, ...newData } = data.searchParam;
      data.setSearchParam({ ...newData });
    } else if (date1 === '' && date2 !== '') {
      const { applyingStartDate, ...newData } = data.searchParam;
      data.setSearchParam({ ...newData, applyingEndDate: date2 });
    } else if (date2 === '' && date1 !== '') {
      const { applyingEndDate, ...newData } = data.searchParam;
      data.setSearchParam({ ...newData, applyingStartDate: date1 });
    } else {
      data.setSearchParam({
        ...data.searchParam,
        applyingStartDate: date1,
        applyingEndDate: date2,
      });
    }

    if (date1 === '' || date2 === '') {
      data.setDisabled(true);
    } else {
      data.setDisabled(false);
    }
  };

  const selectChange = (value, { name }) => {
    data.setSearchDisplay({ ...data.searchDisplay, [name]: Number(value) });

    if (value === '') {
      const { [name]: _, ...newData } = data.searchParam;
      data.setSearchParam({ ...newData });
    } else {
      data.setSearchParam({ ...data.searchParam, [name]: Number(value) });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    data.setSearchDisplay({ ...data.searchDisplay, [name]: value });

    if (value === '') {
      const { [name]: _, ...newData } = data.searchParam;
      data.setSearchParam({ ...newData });
    } else {
      data.setSearchParam({ ...data.searchParam, [name]: value });
    }
  };

  const areaData = {
    type: 'select',
    obj: {
      menus: AREA_CODE_HISTORY,
    },
    state: data.searchDisplay.areaCode,
    set: functions.selectChangeArea,
    name: 'areaCode',
  };

  const dateData = {
    label: [], // typeが'multiple'のときのみ配列
    require: false,
    type: 'multiple',
    inputType: 'date',
    startState: data.searchDisplay.applyingStartDate,
    endState: data.searchDisplay.applyingEndDate,
    setState: (start, end) => { return set(start, end); },
  };

  const oldList = [
    {
      label: '旧役割',
      data: {
        type: 'select',
        obj: {
          menus: POSITION_CODE,
        },
        state: data.searchDisplay.oldPositionCode,
        onChange: selectChange,
        name: 'oldPositionCode',
      },
    },
    {
      label: '旧役職',
      data: {
        type: 'select',
        obj: {
          menus: GRADE_CODE,
        },
        state: data.searchDisplay.oldGradeCode,
        onChange: selectChange,
        name: 'oldGradeCode',
      },
    },
    {
      label: '旧権限',
      data: {
        type: 'select',
        obj: {
          menus: ROLE_GROUP_MAPPER,
        },
        state: data.searchDisplay.oldUserRoleGroupId,
        onChange: selectChange,
        name: 'oldUserRoleGroupId',
      },
    },
  ];

  const newList = [
    {
      label: '新役割',
      data: {
        type: 'select',
        obj: {
          menus: POSITION_CODE,
        },
        state: data.searchDisplay.newPositionCode,
        onChange: selectChange,
        name: 'newPositionCode',
      },
    },
    {
      label: '新役職',
      data: {
        type: 'select',
        obj: {
          menus: GRADE_CODE,
        },
        state: data.searchDisplay.newGradeCode,
        onChange: selectChange,
        name: 'newGradeCode',
      },
    },
    {
      label: '新権限',
      data: {
        type: 'select',
        obj: {
          menus: ROLE_GROUP_MAPPER,
        },
        state: data.searchDisplay.newUserRoleGroupId,
        onChange: selectChange,
        name: 'newUserRoleGroupId',
      },
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="社員検索ウインドウ"
      maxWidth={1160}
      className={classes.root}
      headerClassName="divisionHistorySearchHeader"
      bodyClassName="divisionHistorySearchBody"
      buttons={[{
        label: 'リセット',
        onClick: functions.resetFunction,
        className: baseClasses.buttonsSecondary,
      }, {
        label: '検索',
        onClick: () => {
          functions.searchFunction();
          onClose();
        },
        className: baseClasses.buttonsPrimary,
        disabled: data.disabled,
      }]}
    >
      <Grid className={classes.content}>
        <Grid className={classes.top}>
          <Grid className={classes.area}>
            <Typography className={classes.title}>
              エリア
            </Typography>
            <CustomSelect initial={areaData} />
          </Grid>
          <Grid className={classes.date}>
            <Typography className={classes.title}>
              取り込み日
            </Typography>
            <Grid className={classes.calendar}>
              <Calendar key={data.key} data={dateData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.middle}>
          <Typography className={classes.title}>
            社員名
          </Typography>
          <TextField
            value={data.searchDisplay.userName}
            onChange={onChange}
            className={classes.text}
            name="userName"
          />
        </Grid>
        <Grid className={classes.bottom}>
          <Grid className={classes.list}>
            {oldList.map((item) => (
              <Grid className={classes.item}>
                <Typography className={classes.label}>
                  {item.label}
                </Typography>
                <CustomBlankSelect initial={item.data} />
                <Grid className={classes.icon}><ArrowForwardIcon /></Grid>
              </Grid>
            ))}
            <Grid className={classes.item}>
              <Typography className={classes.label}>
                旧組織
              </Typography>
              <TextField
                value={data.searchDisplay.oldDivision}
                onChange={onChange}
                className={classes.itemText}
                name="oldDivision"
              />
              <Grid className={classes.icon}><ArrowForwardIcon /></Grid>
            </Grid>
          </Grid>
          <Grid className={classes.list}>
            {newList.map((item) => (
              <Grid className={classes.item}>
                <Typography className={classes.label}>
                  {item.label}
                </Typography>
                <CustomBlankSelect initial={item.data} />
              </Grid>
            ))}
            <Grid className={classes.item}>
              <Typography className={classes.label}>
                新組織
              </Typography>
              <TextField
                value={data.searchDisplay.newDivision}
                onChange={onChange}
                className={classes.itemText}
                name="newDivision"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
