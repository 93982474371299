import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
// import { makeStyles } from '@material-ui/core/styles';

import { isClose } from '../store/common/apiMessageSlice';

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
//   alert: {
//     width: '100%',
//   },
// });

export default function ApiAlertDialog() {
  // const classes = useStyles();
  const apiMessage = useSelector((state) => state.apiMessage);

  const status = `${apiMessage.status}`;
  const title = `${apiMessage.title}`;
  const body = `${apiMessage.msg || ''}`;

  const dispatch = useDispatch();
  const onClose = () => dispatch(isClose());

  let autoHideDuration = 5000;
  switch (status) {
    case 'error':
    case 'info':
      autoHideDuration = null;
      break;
    default:
      break;
  }

  return (
    <Snackbar
      open={apiMessage.isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert
        severity={status}
        onClose={onClose}
        variant="filled"
      >
        <AlertTitle>{title}</AlertTitle>
        {body}
        {apiMessage.msgList && apiMessage.msgList.map((message) => (
          <Grid key={message}>
            {message}
            <br />
          </Grid>
        ))}
      </Alert>
    </Snackbar>
  );
}
