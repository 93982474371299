import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  Drawer,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import { InstructionsSelectList } from '../../../../constants/customerMain';
import InstructionsWindowButton from './parts/instructionsWindowButton';
import InstructionsWindowRadio from './parts/instructionsWindowRadio';
import InstructionsWindowSelectList from './parts/InstructionsWindowSelectList';
import InstructionsWindowCheckBox from './parts/instructionsWindowCheckBox';
// import InstructionsWindowCheckBoxButton from './parts/instructionsWindowCheckBoxButton';
// import InstructionsWindowCalendarSp from './parts/sp/instructionsWindowCalendar';
import InstructionsWindowCalendar from './parts/instructionsWindowCalendar';
import InstructionsWindowTextField from './parts/instructionsWindowTextField';
// import InstructionsWindowManager from './parts/sp/instructionsWindowManager';
// import InstructionsWindowTextFieldSp from './parts/sp/instructionsWindowTextFieldSp';
import UserTree from '../../userTreeNew';
import getInstructionsTime from '../../../../commonFunction/getInstructionsTime';

const useStyles = makeStyles({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '1400!important',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    background: '#FFF',
    [commonTheme.breakpoints.down('sm')]: {
      overflowY: 'auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 696,
      minWidth: 696,
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 16px 8px 16px',
      width: '100%',
      boxSizing: 'border-box',
      position: 'relative',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
    },
  },
  headerTtl: {
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '24px',
    },
  },
  content: {
    background: '#F3F3F3',
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '32px 16px 72px',
      maxHeight: 'calc(100vh - 72px)',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '32px 32px 24px',
    },
  },
  contentCheckbox: {
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: '8px',
    },
  },
  contentIpt: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    '&.edit': {
      pointerEvents: 'none',
      background: '#C8C8C8',
    },
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiFormLabel-root': {
        position: 'relative',
        transform: 'none',
      },
    },
  },
  contentIptBlock: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      background: 'transparent',
      border: 'none',
      marginBottom: 0,
      padding: 0,
    },
  },
  contentIpt2: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    '&.edit': {
      pointerEvents: 'none',
      background: '#C8C8C8',
    },
  },
  contentIptLbl: {
    [commonTheme.breakpoints.up('md')]: {
      width: 80,
    },
    [commonTheme.breakpoints.down('sm')]: {
      wordBreak: 'keep-all',
      display: 'inline-flex',
    },
    '& span': {
      color: '#D83420',
    },
  },
  contentIptBox: {
    [commonTheme.breakpoints.up('md')]: {
      width: 528,
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: 1,
      textAlign: 'right',
    },
  },
  contentIptBox2: {
    marginTop: 8,
    width: '100%',
    '& .MuiButtonBase-root': {
      width: '100%',
      '& .MuiButton-label': {
        justifyContent: 'flex-start',
      },
    },
  },
  contentIptBox3: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [commonTheme.breakpoints.down('sm')]: {
      '& > :not(.MuiFormGroup-row)': {
        width: '100%',
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 528,
    },
    '& .MuiFormGroup-row': {
      [commonTheme.breakpoints.down('sm')]: {
        marginBottom: '8px',
      },
    },
  },
  contentIptBoxTop: {
    width: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: 10,
      background: '#fff',
      padding: '9.5px 12px 9.5px 16px',
      border: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 20,
    },
  },
  selectButton: {
    color: '#8C8C8C',
    '& .MuiButton-label': {
      lineHeight: 1,
    },
  },
  label: {
    fontSize: '13px',
    color: '#8C8C8C',
    '&.MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        lineHeight: '20.8px',
      },
    },
  },
  drawer: {
    zIndex: '1405!important',
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
      minHeight: '50%',
    },
  },
  textFieldGrid: {
    padding: '32px 16px 16px 16px',
    '& > div': {
      width: '100%',
    },
  },
});

// 共通パーツ
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
function DrawerComponent(props) {
  const {
    type,
    openDrawer,
    setOpenDrawer,
    title,
    values,
    setDisabled,
    setTextFieldValue,
    handleOpenCalendarWindow,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  switch (type) {
    case 'textfield':
      return (
        <Drawer
          anchor="bottom"
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(!openDrawer);
          }}
          className={classes.drawer}
        >
          <Grid className={baseClasses.drawerHeader}>
            <Typography className={baseClasses.title3}>指示内容</Typography>
          </Grid>
          <Grid className={classes.textFieldGrid}>
            <InstructionsWindowTextField
              values={values}
              setDisabled={setDisabled}
              setOpenDrawer={setOpenDrawer}
              setTextFieldValue={setTextFieldValue}
            />
          </Grid>
        </Drawer>
      );
    default:
      return (
        <Drawer
          anchor="bottom"
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(!openDrawer);
          }}
          className={classes.drawer}
        >
          <Grid className={classes.drawerHeader}>
            <Typography className={baseClasses.title3}>{title}</Typography>
          </Grid>
          <InstructionsWindowSelectList
            values={values}
            setOpenDrawer={setOpenDrawer}
            handleOpenCalendarWindow={handleOpenCalendarWindow}
          />
        </Drawer>
      );
  }
}

export default function InstructionsWindow(props) {
  const {
    detail,
    open,
    setOpen,
    onClose,
    directId,
    newInstructionFlg,
    editState,
    setEditState,
    restoreEditState,
    updateDirect,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const [textFieldValue, setTextFieldValue] = useState('入力してください');
  const [disabled, setDisabled] = useState(true); // ボタンの必須制御
  const [labelValue1, setLabelValue1] = useState('選択してください'); // ラベル値
  const [labelValue2, setLabelValue2] = useState('選択してください'); // ラベル値
  const [labelValue3, setLabelValue3] = useState('選択してください'); // ラベル値
  const [inactive, setInactive] = useState(true); // カレンダーの表示制御
  const [openDrawer1, setOpenDrawer1] = useState(false); // 重要度
  const [openDrawer3, setOpenDrawer3] = useState(false); // 種類
  const [openDrawer4, setOpenDrawer4] = useState(false); // 指示内容(textField)
  const [openTree, setOpenTree] = useState(false);
  const [openMultiTree, setOpenMultiTree] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [selectMultiUser, setSelectMultiUser] = useState([]);
  const [selectDivision, setSelectDivision] = useState({
    divId: editState.directedDivisionId,
    divisionName: '',
  });
  const [selectMultiDivision, setSelectMultiDivision] = useState([]);
  const [register, setRegister] = useState({
    date: { assignAt: '' },
  });
  const [expireRadio, setExpireRadio] = useState(newInstructionFlg ? '0' : '3');

  // 初回 - othersの中身をTreeで使うselectMultiUser用にset
  useEffect(() => {
    if (detail.others.length > 0) {
      if (detail.others[0].userId) {
        const initialUsers = [];
        for (let i = 0; detail.others.length > i; i += 1) {
          const other = detail.others[i];
          const obj = {
            userId: other.userId,
            userName: other.userLastName + other.userFirstName,
            userDivisionId: other.divisionId,
          };
          initialUsers.push(obj);
        }
        setSelectMultiUser(initialUsers);
      }
    }
    if (detail.directedUserId) {
      setSelectUser({
        userId: editState.directedUserId,
        userName: detail.directedLastName + ' ' + detail.directedFirstName,
      });
    }

    // 重要度の表記String
    if (editState.importantCode) {
      let target;
      for (let i = 0; InstructionsSelectList.important.length > i; i += 1) {
        if (InstructionsSelectList.important[i].val === String(editState.importantCode)) {
          target = InstructionsSelectList.important[i].label;
        }
      }
      setLabelValue1(target);
    }
    console.log(labelValue2);
  }, []);

  useEffect(() => {
    if (editState.content !== '') {
      setDisabled(false);
    }
    // deadLineCodeがAPI項目にないため、初期値のラベルを設定
    setLabelValue2(InstructionsSelectList.deadLine[0].label);

    if (editState.directCode) {
      setLabelValue3(
        InstructionsSelectList.directKind[editState.directCode - 1].label,
      );
    }
    if (editState.customerId) {
      setTextFieldValue(editState.content);
      setRegister({
        date: { assignAt: editState.expireAt },
      });
    }
  }, [editState]);

  // 担当者選択後のsetEditState
  useEffect(() => {
    const copyObj = Object.assign({}, editState);
    copyObj.directedUserId = selectUser.userId;
    copyObj.directedDivisionId = selectDivision.divId;
    setEditState(copyObj);
  }, [selectUser, selectDivision]);

  // 担当者複数選択のeditStateとTree用State(selectMultiUser)の型合わせ
  const setMultiUserState = () => {
    const moldingOthersArr = [];
    for (let i = 0; selectMultiUser.length > i; i += 1) {
      moldingOthersArr.push({
        userId: selectMultiUser[i].userId,
        divisionId: selectMultiUser[i].userDivisionId,
      });
    }
    setEditState({ ...editState, others: moldingOthersArr });
  };

  // 担当者複数選択のsetEditState
  useEffect(() => {
    setMultiUserState();
  }, [selectMultiUser]);

  // 背景をクリックした時にモーダルを閉じる
  const onCloseBg = () => {
    restoreEditState();
    onClose();
  };

  // setEditStateの共通set関数
  const set = async (key, value) => {
    switch (value) {
      case true:
        setEditState({ ...editState, [key]: 1 });
        break;
      case false:
        setEditState({ ...editState, [key]: 0 });
        break;
      default:
        if (key === 'content') {
          setEditState({ ...editState, content: value });
        } else {
          setEditState({ ...editState, [key]: value });
        }
        break;
    }
    if (key === 'deadLineCode') {
      if (value === '3') {
        setInactive(false);
      } else {
        setInactive(true);
      }
    }
  };

  // 指示登録 or 更新
  const registerStart = async () => {
    if (newInstructionFlg) {
      // 指示更新
      await updateDirect(null, editState);
      setOpen(false);
    } else if (!newInstructionFlg) {
      // editStateでUpdate
      setOpen(false);
      await updateDirect(detail.directId, editState);
      console.log('指示更新');
    } else {
      // editStateでCreate
      console.log('指示登録');
      onClose();
    }
  };

  const button = {
    state: selectUser.userName,
    setState: (e) => {
      return set('directUserId', e);
    },
  };
  const checkedBox1 = {
    state: editState.isManager,
    setState: (e) => {
      return set('isManager', e);
    },
    label: 'MGR',
  };
  const checkedBox2 = {
    state: editState.isCenterManager,
    setState: (e) => {
      return set('isCenterManager', e);
    },
    label: 'センター長',
  };
  // const checkedBoxButton = {
  //   state: editState.others,
  // };
  const importantItem = {
    state: String(editState.importantCode),
    setState: (e) => {
      return set('importantCode', Number(e));
    },
    list: InstructionsSelectList.important,
    setLabelState: (e) => {
      setLabelValue1(e);
    },
  };
  // deadLineCodeがAPI項目にない
  const deadLineItem = {
    state: newInstructionFlg ? String(0) : String(3),
    setState: (e) => {
      // return set('deadLineCode', e);
      setExpireRadio(e);
      set('expireAt', getInstructionsTime(Number(e), 1, editState.expireAt));
      setRegister({
        date: {
          assignAt: getInstructionsTime(Number(e), 1, editState.expireAt),
        },
      });
    },
    list: newInstructionFlg ? InstructionsSelectList.deadLine : [
      InstructionsSelectList.deadLine[
        InstructionsSelectList.deadLine.length - 1
      ],
    ],
    setLabelState: (e) => {
      setLabelValue2(e);
    },
  };
  const directKindItem = {
    state: String(editState.directCode),
    setState: (e) => {
      return set('directCode', Number(e));
    },
    list: InstructionsSelectList.directKind,
    setLabelState: (e) => {
      setLabelValue3(e);
    },
  };
  const directTypeItem = {
    state: String(editState.approveCode),
    setState: (e) => {
      return set('approveCode', Number(e));
    },
    list: InstructionsSelectList.directType,
  };
  const calendar = {
    state: register.date,
    initial: editState.expireAt,
    setState: (e) => {
      set('expireAt', e.replace(/-/g, '/').replace(/T/g, ' '));
    },
    disabled: newInstructionFlg ? inactive : false,
  };
  const textField1 = {
    title: '指示内容',
    state: editState.content,
    setState: (e) => {
      return set('content', e);
    },
    label: 'content',
  };

  const body = (
    <Grid className={classes.paper}>
      <Grid className={classes.header}>
        <Typography
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          className={`${baseClasses.title3} ${classes.headerTtl}`}
        >
          指示編集
        </Typography>
        <Button className={baseClasses.closeButton} onClick={onClose} />
      </Grid>
      <Grid className={classes.content}>
        <Grid className={`${classes.contentIpt} ${classes.contentIptBlock}`}>
          <Typography
            className={`${baseClasses.title4} ${classes.contentIptLbl} ${baseClasses.onlyPc}`}
          >
            担当<span>＊</span>
          </Typography>
          <Grid className={classes.contentIptBox3}>
            <Grid className={classes.contentIptBoxTop}>
              <Typography
                className={`${baseClasses.title4} ${classes.contentIptLbl} ${baseClasses.onlySp}`}
              >
                担当<span>＊</span>
              </Typography>
              <InstructionsWindowButton
                setOpenDrawer={setOpenTree}
                labelValue={button.state}
              />
              {/* 担当者選択モーダル */}
            </Grid>
            <InstructionsWindowCheckBox values={checkedBox1} />
            <InstructionsWindowCheckBox values={checkedBox2} />
            <Grid className={classes.contentIpt}>
              <Typography
                className={`${baseClasses.title4} ${classes.contentIptLbl}`}
              >
                その他担当者（複数可）
              </Typography>
              <InstructionsWindowButton
                setOpenDrawer={setOpenMultiTree}
                labelValue={selectMultiUser}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.contentIpt}>
          <Typography
            className={`${baseClasses.title4} ${classes.contentIptLbl}`}
          >
            重要度
          </Typography>
          <Grid className={classes.contentIptBox}>
            <>
              <InstructionsWindowButton
                setOpenDrawer={setOpenDrawer1}
                labelValue={labelValue1}
              />
              <DrawerComponent
                openDrawer={openDrawer1}
                setOpenDrawer={setOpenDrawer1}
                title="重要度"
                values={importantItem}
              />
            </>
          </Grid>
        </Grid>
        <Grid className={classes.contentIpt}>
          <Typography
            className={`${baseClasses.title4} ${classes.contentIptLbl}`}
          >
            期日<span>＊</span>
          </Typography>
          <Grid className={classes.contentIptBox3}>
            <Grid container>
              <InstructionsWindowRadio values={deadLineItem} />
              <InstructionsWindowCalendar
                values={calendar}
                expireRadio={expireRadio}
                register={register}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.contentIpt} ${
            directId !== '' && !newInstructionFlg ? 'edit' : ''
          }`}
        >
          <Typography
            className={`${baseClasses.title4} ${classes.contentIptLbl}`}
          >
            種類<span>＊</span>
          </Typography>
          <Grid className={classes.contentIptBox}>
            <>
              <InstructionsWindowButton
                setOpenDrawer={setOpenDrawer3}
                labelValue={labelValue3}
              />
              <DrawerComponent
                openDrawer={openDrawer3}
                setOpenDrawer={setOpenDrawer3}
                title="種類"
                values={directKindItem}
              />
            </>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.contentIpt2} ${
            directId !== '' && !newInstructionFlg ? 'edit' : ''
          }`}
        >
          <Typography
            className={`${baseClasses.title4} ${classes.contentIptLbl}`}
          >
            指示内容<span>＊</span>
          </Typography>
          <Grid className={classes.contentIptBox2}>
            <>
              <InstructionsWindowButton
                setOpenDrawer={setOpenDrawer4}
                labelValue={textFieldValue}
              />
              <DrawerComponent
                type="textfield"
                openDrawer={openDrawer4}
                setOpenDrawer={setOpenDrawer4}
                values={textField1}
                setDisabled={setDisabled}
                setTextFieldValue={setTextFieldValue}
              />
            </>
          </Grid>
        </Grid>
        <Grid className={classes.contentIpt}>
          <Typography
            className={`${baseClasses.title4} ${classes.contentIptLbl}`}
          >
            報告後
          </Typography>
          <Grid className={classes.contentIptBox}>
            <InstructionsWindowRadio values={directTypeItem} />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={baseClasses.fixedFooter}>
        <Button
          className={`${baseClasses.buttonsPrimary} ${classes.fixedFooterBtn} ${
            disabled ? 'disabled' : ''
          }`}
          onClick={() => registerStart()}
          disabled={
            detail.directReportStatusCode === 5 || detail.directReportStatusCode === 6
          }
        >
          登録
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCloseBg}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className={`${baseClasses.dialog} ${baseClasses.maxWidthDialog} ${classes.dialog}`}
      >
        {body}
      </Dialog>
      {openTree ? (
        <UserTree
          isOpenTree={openTree}
          setIsOpenTree={setOpenTree}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          isDialog
          isSelectUserWindow
        />
      ) : null}
      {openMultiTree ? (
        <UserTree
          isOpenTree={openMultiTree}
          setIsOpenTree={setOpenMultiTree}
          selectDivision={selectMultiDivision}
          setSelectDivision={setSelectMultiDivision}
          selectUser={selectMultiUser}
          setSelectUser={setSelectMultiUser}
          isMulti
          isDialog
          isSelectUserWindow
        />
      ) : null}
    </div>
  );
}
