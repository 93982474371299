import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const sendmailGetUnsendSlice = createSlice({
  name: 'sendmailGetUnsend',
  initialState: { sendmailGetUnsend: initialState },
  reducers: {
    sendmailGetUnsendApi: (state, data) => {
      state.sendmailGetUnsend = data.payload;
    },
  },
});

export const { sendmailGetUnsendApi } = sendmailGetUnsendSlice.actions;

export default sendmailGetUnsendSlice.reducer;
