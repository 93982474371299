import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const directRegistSlice = createSlice({
  name: 'directRegist',
  initialState: { directRegist: initialState },
  reducers: {
    directRegistApi: (state, data) => {
      state.directRegist = data.payload;
    },
  },
});

export const { directRegistApi } = directRegistSlice.actions;

export default directRegistSlice.reducer;
