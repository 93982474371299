import React from 'react';
import { Typography } from '@material-ui/core';
import premiumicon from './../images/premiumicon.svg';

export default function PremiumIcon(props) {
  const { className } = props;
  return (
    <Typography className={className} style={{ verticalAlign: 'middle', marginRight: 2, lineHeight: 1 }}>
      <img src={premiumicon} alt="" />
    </Typography>
  );
}
