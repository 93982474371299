import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DashBoardComponent from '../../components/pages/dashBoard/dashBoardComponent';
import DashBoardSpComponent from '../../components/pages/dashBoard/sp/dashBoardSpComponent';
import divisionsGetDashboardInitalApi from '../../apis/divisions/divisionsGetDashboardInitalApi';
import getGuidanceApi from '../../apis/act/getActGuidancesApi';
import getIncompleteListApi from '../../apis/act/getIncompleteListApi';
import getUnregistListApi from '../../apis/act/getUnregistListApi';
import getResponsesRecentListApi from '../../apis/act/getResponsesRecentListApi';

import { updateActGuidances } from '../../store/act/actGuidances';
import { updateIncompleteList } from '../../store/act/incompleteList';
import { updateUnregistList } from '../../store/act/unregistList';
import { updateResponsesRecentList } from '../../store/act/responsesResentList';

export default function DashBoardContainer(props) {
  const { onClose, isSP, tab } = props;
  const dispatch = useDispatch();

  const [requestQuery, setQuery] = useState({});

  /** @type { import('../../apis/act/getActGuidancesApiTypes').ActGuidancesGetResponse } */
  const actGuidances = useSelector((state) => state.actGuidances);

  /** @type { import('../../apis/act/getIncompleteListApiTypes').IncompleteListGetResponse } */
  const incompleteList = useSelector((state) => state.incompleteList);

  /** @type { import('../../apis/act/getUnregistListApiTypes').UnregistListGetResponse } */
  const unregistList = useSelector((state) => state.unregistList);

  // eslint-disable-next-line max-len
  /** @type { import('../../apis/act/getResponsesRecentListApiTypes').ResponseRecentListGetResponse } */
  const responsesRecentList = useSelector((state) => state.responsesRecentList);

  const count = {
    today: actGuidances.data.total ?? 0, // actGuidances.data.total,
    incomp: incompleteList.data.total ?? 0, // incompleteList.data.total,
    unregister: unregistList.data.total ?? 0, // unregistList.data.total,
  };

  useEffect(() => {
    (async () => {
      const { divisionId, userId } = requestQuery;
      const requestParam = { divisionId, userId };
      if (requestParam.divisionId) {
        const [
          actGuidancesGetResponse,
          incompleteListGetResponse,
          unregistListGetResponse,
          responsesRecentListGetResponse,
        ] = await Promise.all([
          getGuidanceApi(requestParam),
          getIncompleteListApi(requestParam),
          getUnregistListApi(requestParam),
          getResponsesRecentListApi(requestParam),
        ]);
        // act007 - 本日の案内情報取得
        dispatch(updateActGuidances(actGuidancesGetResponse));
        // act008 - 未完了指示情報取得
        dispatch(updateIncompleteList(incompleteListGetResponse));
        // act009 - 実績未登録案内情報取得
        dispatch(updateUnregistList(unregistListGetResponse));
        // act010 - 直近反響対応状況取得
        dispatch(updateResponsesRecentList(responsesRecentListGetResponse));
      }
    })();
  }, [requestQuery]);

  // 初期表示
  useEffect(() => {
    divisionsGetDashboardInitalApi()
      .then((res) => {
        setQuery({
          userId: res.data.userId || undefined,
          divisionId: res.data.divisionId,
          displayName: res.data.userTree,
        });
      })
      .catch((err) => console.error(err));
  }, []);

  if (isSP) {
    return (
      <DashBoardSpComponent
        todaysGuidanceList={actGuidances}
        incompleteList={incompleteList}
        unregistList={unregistList}
        correspondenceList={responsesRecentList}
        onClose={onClose}
        count={count}
        tab={tab}
        setQuery={setQuery}
        requestQuery={requestQuery}
      />
    );
  }
  return (
    <DashBoardComponent
      actGuidances={actGuidances}
      incompleteList={incompleteList}
      unregistList={unregistList}
      responsesRecentList={responsesRecentList}
      setQuery={setQuery}
      query={requestQuery}
    />
  );
}
