import client from '../httpClient';

/**
 * 【combination-setting001】組合せ設定一覧取得（エリアコード指定）
 * http://10.22.2.48:8080/docs/#/combinationSetting/post-combination-settings-areaCode
 * @param {number} areaCode
 * @param {{
 * cityJisCodes: string[];
 * areaSearchType: number;
 * }} data
 * @returns Promise<*>
 */
const combinationSettingPostListApi = async (areaCode, data) => {
  const res = await client.post(`/combination-settings/${areaCode}`, JSON.stringify(data));
  return res.data;
};

export default combinationSettingPostListApi;
