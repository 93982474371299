import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ZipTextField from './parts/zipTextField';

const useStyles = makeStyles({
  searchBox: {
    /* Auto layout */
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px',
    position: 'static',
    width: '100%',
    height: '68px',
    left: '0px',
    top: '0px',
    /* Inside auto layout */
    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
    margin: '0px 0px',
  },
});

export default function SearchBox(props) {
  const classes = useStyles();

  const {
    getAddressList,
    initialParam,
    setInitialParam,
  } = props;

  const textFieldList = [
    { key: 'code', label: '郵便番号（前方一致）', md: 3, text: initialParam.code },
    { key: 'address', label: '住所（部分一致）', md: 9, text: initialParam.address },
  ];

  return (
    <Grid container className={classes.searchBox}>
      {textFieldList.map((textField) => (
        <ZipTextField
          key={textField.key}
          textField={textField}
          getAddressList={getAddressList}
          initialParam={initialParam}
          setInitialParam={setInitialParam}
        />
      ))}
    </Grid>
  );
}
