// Title: 詳細検索
// めちゃくちゃ嫌な実装だがこれで良いとの事。 下記コード値利用場所は 詳細検索の種類/反応であり、
// 絶対に初期値は入らないとのことなのでこのまま
// 詳細検索 - 対応履歴 - 種類/反応 , actionDetailCode
export const ACTION_DETAIL_CODE_CUSTOM = {
  来案: [10],
  訪案: [11],
  待案: [12],
  来店接客のみ: [121],
  資料渡し: [122],
  源泉接触: [123],
  ポーター接触: [124],
  会社から架電: [1],
  会社に受電: [3],
  会社から留守電: [4],
  会社から不通: [5],
  携帯から架電: [2],
  携帯に受電: [131],
  携帯から留守電: [132],
  携帯から不通: [133],
  送信: [6],
  受信: [7],
  個別送信: [41, 42, 46, 47, 51, 52, 56, 57, 61, 62, 66, 67],
  一斉送信: [43, 44, 48, 49, 53, 54, 58, 59, 63, 64, 68, 69],
  代行配信: [115],
  郵送: [8],
  LINE送信: [143],
  LINE受信: [144],
  SMS送信: [141],
  SMS受信: [142],
  手動: [19],
  WEB: [18],
  契約完了: [13],
  契約解除: [20],
  登録: [15],
  解除: [20],
  更新: [151],
  配信停止: [114],
  メールブロック: [112],
  メール不着: [111],
  URLクリック: [113],
  // エラー: [16],
};

// 本来は個別送信、一斉送信を一意のidにして、下記のように実装したい。
// export const ACTION_DETAIL_ID_CODE_CUSTOM = {
//   10: '来案',
//   11: '訪案',
//   12: '待案',
//   121: '来店接客のみ',
//   122: '資料渡し',
//   123: '源泉接触',
//   124: 'ポーター接触',
//   1:'会社から架電',
//   3:'会社から受電',
//   4:'会社から留守電',
//   5:'会社から不通',
//   2:'携帯から架電',
//   131:'携帯から受電',
//   132:'携帯から留守電',
//   133:'携帯から不通',
//   6:'送信',
//   7:'受信',
//   [41, 42, 46, 47, 51, 52, 56, 57, 61, 62, 66, 67]: '個別送信', ここは一意のKeyにしたい
//   [43, 44, 48, 49, 53, 54, 58, 59, 63, 64, 68, 69]: '一斉送信', ここは一意のKeyにしたい
//   115: '代行配信',
//   8: '郵送',
//   143: 'LINE送信',
//   144: 'LINE受信',
//   141: 'SMS送信',
//   142: 'SMS受信',
//   19: '手動',
//   18: 'WEB',
//   13: '契約完了',
//   20: '契約解除',
//   15: '登録',
//   20: '解除',
//   151: '更新',
//   114: '配信停止',
//   112: 'メールブロック',
//   111: 'メール不着',
//   113: 'URLクリック',
//   16: 'エラー',
// };

// 詳細検索 - 対応履歴 - 種類/反応 , actionCode
export const ACTION_CODE_CUSTOM = {
  新規: 1,
  再反: 2,
  来社: 3,
  案内予約: 4,
  案内: 5,
  電話: 6,
  メール: 7,
  DM: 8,
  戻し: 9,
  契約: 10,
  ダウンロード: 11,
  クレーム処理: 12,
  クリーニング: 13,
  メールエラー: 14,
  suumoネット: 15,
  退会のお知らせ: 16,
  情報の変更: 17,
  顧客の結合: 18,
  LINE: 19,
  SMS: 20,
  郵送: 21,
  システム: 91,
};

// 詳細検索 - 希望条件 - 間取り , floorPlan
export const FLOOR_PLAN_CODE = {
  4: '2LDK以下',
  8: '2SLDK・3LDK',
  16: '3SLDK・4LDK',
  32: '5LDK以上',
};

export const FLOOR_PLAN_NAME_CODE = {
  '2LDK以下': 4,
  '2SLDK・3LDK': 8,
  '3SLDK・4LDK': 16,
  '5LDK以上': 32,
};
