import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      sm: 767,
      md: 768,
    },
  },
});

export default theme;
