import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import commonStyles from '../../styles';
import SortCheckbox from './sortCheckbox';
import SortSelect from './sortSelect';
import SortButtons from './sortButtons';
import SortCalendar from './sortCalendar';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 120,
  },
  accordionDetails: {
    padding: 0,
  },
  checkboxGrid: {
    padding: '0 8px 8px 16px',
    margin: 0,
    borderBottom: 'solid 1px #C8C8C8',
  },
  selectGrid: {
    padding: '0 8px',
    marginBottom: 24,
  },
  buttonsGrid: {
    padding: '0 8px 8px',
  },
}));

function Search(props) {
  const {
    handleCloseSearch,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const [expanded, setExpanded] = useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (isSP) {
    contents = (
      <Grid className={classes.root}>
        <Grid>
          <SortSelect />
          <SortCheckbox />
        </Grid>
        <Grid className={classes.footer}>
          <SortButtons
            handleCloseSearch={handleCloseSearch}
          />
        </Grid>
      </Grid>
    );
  } else {
    contents = (
      <Accordion
        square
        className={baseClasses.sideAccordion}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={baseClasses.sideAccordionHeader}
        >
          <Box>
            <SearchIcon style={{ color: '#8C8C8C' }} fontSize="small" />
            <Typography>絞り込み/検索</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid>
            <Grid>
              <SortCalendar />
            </Grid>
            <Grid className={classes.checkboxGrid}>
              <SortCheckbox />
            </Grid>
            <Grid className={classes.selectGrid}>
              <SortSelect />
            </Grid>
            <Grid className={classes.buttonsGrid}>
              <SortButtons
                handleCloseSearch={handleCloseSearch}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
export default Search;
