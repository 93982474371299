import { useState } from 'react';
import CustomerShareUpdateAdd from '../../../../components/common/customer/shareupdateAdd/customerShareupdateAdd';

function CustomerShareupdateAddContainer(props) {
  const { customerId, label, functions, shareAddDisabledFlg } = props;

  const date = new Date();
  const Y = date.getFullYear();
  const M = ('00' + (date.getMonth() + 1)).slice(-2);
  const D = ('00' + date.getDate()).slice(-2);
  const h = ('00' + date.getHours()).slice(-2);
  const m = ('00' + date.getMinutes()).slice(-2);
  const start = Y + '/' + M + '/' + D + ' ' + h + ':' + m;

  let eM;
  let eD;
  switch (date.getMonth()) {
    case 0:
    case 1:
    case 2:
      eM = '03';
      eD = new Date(Y, 3, 0).getDate();
      break;
    case 3:
    case 4:
    case 5:
      eM = '06';
      eD = new Date(Y, 6, 0).getDate();
      break;
    case 6:
    case 7:
    case 8:
      eM = '09';
      eD = new Date(Y, 9, 0).getDate();
      break;
    case 9:
    case 10:
    case 11:
      eM = '12';
      eD = new Date(Y, 0, 0).getDate();
      break;
    default:
      break;
  }
  const end = Y + '/' + eM + '/' + eD + 'T00:00';

  const [multiple, setMultiple] = useState({ startAt: start, endAt: end });
  const multipleOnchange = (value1, value2) => {
    setMultiple({
      ...multiple,
      startAt: value1,
      endAt: value2,
    });
  };
  return (
    <CustomerShareUpdateAdd
      customerId={customerId}
      label={label}
      multiple={multiple}
      setMultiple={multipleOnchange}
      functions={functions}
      shareAddDisabledFlg={shareAddDisabledFlg}
    />
  );
}

export default CustomerShareupdateAddContainer;
