import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ListItem,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import baseClassesStyles from '../../../styles';
import SwipeOutButton from '../../../eleCommon/swipeOutButton';

import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';
import ManagementTalkRoom from '../managementTalkRoom';

const useStyles = makeStyles({
  item: {
    padding: 0,
    '& > div': {
      width: '100%',
    },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
});

function DetailTalkRoomItemsSp(props) {
  const {
    element,
    index,
    swipe,
    setSwipe,
    functions,
  } = props;
  const classes = useStyles();
  const baseClasses = baseClassesStyles();
  const [open, setOpen] = useState(false); // コンポーネント本体の開閉用

  const dispatch = useDispatch();

  const openDetailTalkRoom = () => setOpen(true);

  // 更新処理
  const updateLineTalkRoom = (param) => {
    setOpen(false);
    // /lineRoom/updateAPIを呼び出す
    functions.updateLineRoome(element.lineRoomLinkId, param);
  };

  // 確認モーダルからOKボタンを押した時の処理
  const customHandleOk = () => {
    functions.deleteLineRooms(element.lineRoomLinkId);
    setOpen(false);
    setSwipe(false);
  };
  const customHandleCancel = () => setSwipe(false);
  // 削除確認モーダル制御
  const handleClickSwipeBtn = () => {
    dispatch(changeConfirmMessage({
      title: '確認',
      msgList: ['本当に削除してよろしいでしょうか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: customHandleCancel,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: customHandleOk,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };
  return (
    <ListItem
      key={element.lineRoomLinkId}
      className={`${baseClasses.title4} ${classes.item}`}
    >
      <SwipeOutButton
        swipe={swipe}
        setSwipe={setSwipe}
        index={index}
        label="削除"
        color="red"
        handleClick={handleClickSwipeBtn}
      >
        <Button className={classes.btn} onClick={openDetailTalkRoom}>
          {element.lineRoomName}
        </Button>
      </SwipeOutButton>
      <ManagementTalkRoom
        open={open}
        onClose={() => setOpen(false)}
        line={element}
        onRegist={updateLineTalkRoom}
      />
    </ListItem>
  );
}

export default DetailTalkRoomItemsSp;
