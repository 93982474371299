import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
    textAlign: 'left',
    borderBottom: '1px solid #8C8C8C',
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #C8C8C8',
    },
    '&:not(:last-child)::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: 1,
      height: '88%',
      position: 'absolute',
      top: '6%',
      right: 0,
      [commonTheme.breakpoints.down('sm')]: {
        height: 'calc(100% - 8px)',
        top: '4px',
      },
    },
    '&:nth-child(1)': {
      width: 24,
      paddingLeft: 8,
    },
    '&:nth-child(2)': {
      width: 96,
      textAlign: 'center',
    },
    '&:nth-child(3)': {
      paddingLeft: 16,
    },
  },
  link: {
    color: theme.palette.primaryColor,
  },
}));

export default function KeywordsTableRow(props) {
  const {
    row,
    index,
  } = props;

  const classes = useStyles();

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.tableRow}
    >
      <TableCell className={classes.tableTd}>
        {index + 1}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.inflowAt.substr(0, 10)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        <Link
          href={row.image}
          target="_blank"
          className={classes.link}
        >
          {row.keyword1}
          {row.keyword2}
        </Link>
      </TableCell>
    </TableRow>
  );
}
