import { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, Grid, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
// セレクトボックス
import CustomerTreeResponseSelectUnitSp from './parts/sp/customerTreeResponseSelectUnitSp';
// 検索エリア
import CustomerTreeResponseSearch from './parts/customerTreeResponseSearch';
// リスト
// import TreeList from './parts/customerTreeListResponse'; // old
import TreeList from './parts/treeList2'; // new
import CustomerTreeListResponseSp from './parts/sp/customerTreeListResponseSp';
import { type, unit, TYPE_OF_DIRECT } from '../../../constants/customerTree';
import { useCustomerTree } from '../../../containers/common/customerTree/context';
import { setLocalStorage } from '../../../commonFunction';
import { TREE_TYPECODE, TREE_UNITCODE } from '../../../constants/localStorage';
import CustomerTreeSelectDivision from './customerTreeSelectDivision';

const useStyles = makeStyles({
  innerGridContainer: {
    display: 'flex',
    [commonTheme.breakpoints.up('md')]: {
      height: '100%',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  selectWrap: {
    borderBottom: '1px solid #C8C8C8',
    paddingTop: 0,
    marginTop: 8,
  },
  select: {
    width: '33%',
    marginBottom: 4,
    '& .MuiSelect-selectMenu': {
      fontSize: '13px',
      textAlign: 'left',
    },
  },
});

function SelectBox(data) {
  const { list, value, setValue } = data;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <FormControl className={`${baseClasses.inputWrap} ${classes.select}`}>
      <InputLabel>{list.title}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
      >
        {Object.keys(list.menus).map(key => (
          <MenuItem key={list.menus[key]} value={key}>
            {list.menus[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const SP = memo((props) => {
  const { searchForm } = props;
  // 表示する組織とユーザー
  const [displayDivision, setDisplayDivision] = useState({});
  // 選択したデータのルート情報を格納
  const [displayDivisionRoot, setDisplayDivisionRoot] = useState([]);

  return (
    <>
      {displayDivisionRoot.length === 0 && (
        <>
          {/* 選択した情報に従って[/division/customertree]APIを呼び出す */}
          <CustomerTreeResponseSelectUnitSp
            setDisplayDivision={setDisplayDivision}
            setDisplayDivisionRoot={setDisplayDivisionRoot}
          />
          {/* 検索 */}
          {searchForm && <CustomerTreeResponseSearch />}
        </>
      )}
      {/* リスト */}
      <CustomerTreeListResponseSp
        displayDivision={displayDivision}
        setDisplayDivision={setDisplayDivision}
        displayDivisionRoot={displayDivisionRoot}
        setDisplayDivisionRoot={setDisplayDivisionRoot}
      />
    </>
  );
});

function ConditionsWrap() {
  const {
    searchHeader,
    setSearchHeader,
    isDirect, // 指示ツリーかどうか
  } = useCustomerTree();

  const classes = useStyles();

  const setValues = (e, target) => {
    if (!isDirect && target) {
      target === 'typeCode' ? setLocalStorage(TREE_TYPECODE, Number(e)) : setLocalStorage(TREE_UNITCODE, Number(e));
    }
    setSearchHeader({
      ...searchHeader,
      [target]: Number(e),
    });
  };

  return (
    <Grid className={classes.selectWrap} container justifyContent="space-between">
      <SelectBox
        list={isDirect ? TYPE_OF_DIRECT : type}
        value={searchHeader.typeCode}
        setValue={(e) => setValues(e, 'typeCode')}
      />
      <CustomerTreeSelectDivision />
      <SelectBox
        list={unit}
        value={searchHeader.unitCode}
        setValue={(e) => setValues(e, 'unitCode')}
      />
    </Grid>
  );
}

function Wrapper(props) {
  const { children } = props;
  const {
    searchForm, // 検索項目があるか
    isMulti,
  } = useCustomerTree();

  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.innerGridContainer}>
      {isSp ? <SP searchForm={searchForm} /> : (
        <>
          {/* 検索 */}
          {searchForm && !isMulti && <CustomerTreeResponseSearch />}
          {children}
        </>
      )}
    </Grid>
  );
}

function CustomerTreeResponseWrapper() {
  return (
    <Wrapper>
      <ConditionsWrap />
      <TreeList />
    </Wrapper>
  );
}

export default CustomerTreeResponseWrapper;
