import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import commonStyles from '../../../../styles';
import UserTree from '../../../userTreeNew';
// import TreeWindow from '../../../commonTree/treeWindow';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
  },
  listWrap: {
    maxWidth: '50%',
  },
  list: {
    padding: 0,
  },
  listItems: {
    marginBottom: 5,
    padding: 0,
  },
  addButtonIcon: {
    color: theme.palette.primaryColor,
    fontSize: '16px',
  },
  addButtonTxt: {
    color: theme.palette.primaryColor,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  grid2: {
    '& .MuiGrid-container': {
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      '& .MuiButton-label': {
        fontSize: 13,
      },
    },
  },
  btn: {
    display: 'flex',
    fontSize: 13,
    padding: 0,
    border: 'none',
    minWidth: 'auto',
    margin: '0 8px',
    '& svg': {
      fontSize: 18,
    },
  },
}));

function ResponsiblePersonSp(props) {
  const {
    addParam,
    setAddParam,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [responsiblePerson, setResponsiblePerson] = useState(addParam.users);
  const [selectMultiDivision, setSelectMultiDivision] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setAddParam({ ...addParam, users: responsiblePerson });
  }, [responsiblePerson]);

  return (
    <Grid className={classes.container}>
      <Grid className={classes.flex}>
        <Typography className={baseClasses.title4}>
          対応者<span style={{ color: '#D83420' }}>＊</span>
        </Typography>
        <Grid className={classes.grid2}>
          <Button
            className={`${baseClasses.buttonsSecondary} ${classes.btn}`}
            onClick={() => { setOpen(!open); }}
          >
            <Add />
            追加
          </Button>
          <UserTree
            isOpenTree={open}
            setIsOpenTree={setOpen}
            selectUser={responsiblePerson}
            setSelectUser={setResponsiblePerson}
            selectDivision={selectMultiDivision}
            setSelectDivision={setSelectMultiDivision}
            isMulti
            isDialog
            isSelectUserWindow
            isLineGroupSetting={Boolean(true)}
          />
        </Grid>
      </Grid>
      <Grid className={classes.listWrap}>
        {responsiblePerson !== [] && (
          <List className={classes.list}>
            {responsiblePerson.map((p) => {
              return (
                <ListItem
                  className={`${baseClasses.small} ${classes.listItems}`}
                  key={p.userId}
                >
                  {p.userLastName}{p.userFirstName}
                  {p.userName}
                </ListItem>
              );
            })}
          </List>
        )}
      </Grid>
    </Grid>
  );
}

export default ResponsiblePersonSp;
