import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import commonStyles from '../../styles';
import DateField from './dateField';
import ResTable from './resTable';
import customerPutResponsibilitiesApi from '../../../apis/customer/customerPutResponsibilitiesApi';
import { useLoading } from '../../../hooks';
import Modal from '../../common/modal';

import { dateFormatter } from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '160%',
  },
}));

function ReturnResHistory(props) {
  const {
    responseHistories,
    open,
    setOpen,
    onClose,
    onCloseOperation,
    searchStart,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [date, setDate] = useState(dateFormatter(new Date(), 'YYYY/MM/DD'));
  const [selectedValue, setSelectedValue] = useState({});

  const { addLoading, removeLoading, hasLoading } = useLoading();

  const onChangeSelectedValue = e => setSelectedValue(JSON.parse(e.target.value));

  // ボタンの必須制御
  const disabled = !date
    || Object.keys(selectedValue).length === 0
    || hasLoading()
    || !responseHistories?.length;

  const onClickUpdateResponse = async () => {
    addLoading();
    const {
      responseId,
      customerId: selectedCustomerId,
      responsibleUserId: userId,
      divisionId,
    } = selectedValue;

    const currentTime = format(new Date(), 'HH:mm:ss') ?? '00:00:00';
    const effectAt = date ? `${date} ${currentTime}` : null; // 削除ボタン押下時考慮

    const responsibilities = [
      {
        responseId,
        customerId: selectedCustomerId,
        effectAt,
      },
    ];
    await customerPutResponsibilitiesApi({
      userId,
      divisionId,
      responsibilities,
    });
    setOpen(false);
    onCloseOperation();
    searchStart();
    removeLoading();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="以前の反響履歴に戻す"
      maxWidth={1000}
      buttons={[{
        label: 'キャンセル',
        onClick: onClose,
        className: classes.cancelButton,
      }, {
        label: '反響を切り替える',
        onClick: onClickUpdateResponse,
        className: `${baseClasses.buttonsPrimary} ${disabled ? 'disabled' : ''}`,
      },
      ]}
      bodyClassName={hasLoading('beforeTable') ? baseClasses.loading : ''}
    >
      <DateField
        date={date}
        setDate={setDate}
      />
      <ResTable
        responseHistories={responseHistories}
        selectedValue={selectedValue}
        onChangeSelectedValue={onChangeSelectedValue}
      />
    </Modal>
  );
}

export default ReturnResHistory;
