import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import AreaSentaku from '../../common/areaSentaku';
import SelectRailwaysAndStationsContainer from '../../../containers/search/railwayAndStations/selectRailwayAndStations';
import NormalTooltip from '../../styles/tooltip';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import inputNumber from '../../../commonFunction/inputNumber';
import { convertForm1, convertFormNumber } from '../../../commonFunction/convertors';
import { validateFormString1, validateFormInt1 } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  area1: {
    width: 120,
  },
  area2: {
    width: 720,
  },
  area3: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  area4: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  areaBox1: {
    width: 168,
  },
  areaBox2: {
    width: 120,
  },
  areaBox3: {
    width: 40,
  },
  areaBox4: {
    width: 338,
  },
  textField: {
    display: 'block',
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
    '& div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  button: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    minWidth: 'auto',
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minWidth: 42,
      minHeight: 21,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  lineButton: { width: '100%' },
  buttonTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 4px',
  },
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  areaButton: {
    width: '100%',
    minHeight: 42,
    '& .MuiButton-label': {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '3',
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&.-mb4': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
}));

function CustomerArea(props) {
  const { input } = props;
  const { current, update, original, isSp } = useUpdate(input.customer);

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  let contents;

  // 希望地域開閉用のローカルステート
  const [openArea, setOpenArea] = useState(false);

  // 希望地域パラメーターを設定
  const [initialParamArea, setInitialParamArea] = useState({});

  // 希望地域を開く
  const handleAreaOpen = () => {
    setInitialParamArea({
      id: 1,
      wishAreaCode: current.wishAreaCode,
      wishAreaIds: current.wishAreaIds,
    });
    setOpenArea(true);
  };

  // 希望地域を閉じる
  const handleCloseArea = () => {
    setOpenArea(false);
  };

  // 希望地域を更新
  const handleUpdateArea = (params) => {
    // console.log('handleUpdateArea', params);
    const newData = {
      wishArea: params.wishArea,
      wishAreaCode: params.wishAreaCode,
      wishAreaIds: params.wishAreaIds,
    };
    // 画面に表示（state更新）
    // 更新処理
    isChanged(newData, original) && update(newData);
  };

  // 沿線開閉用のローカルステート
  const [openRailway, setOpenRailway] = useState(false);

  // 沿線パラメーターを設定
  const [initialParamRailway, setInitialParamRailway] = useState({});

  // 沿線を開く
  const handleOpenRailway = (wishID) => {
    // 沿線の第1希望, 第2希望のパラメータをセット
    setInitialParamRailway({
      id: wishID,
      wishRailwayAreaCodeId: current[`wishRailwayAreaCodeId${wishID}`],
      wishRailwayId: current[`wishRailwayId${wishID}`],
      wishRailway: current[`wishRailway${wishID}`],
      wishStationIdFrom: current[`wishStationIdFrom${wishID}`],
      wishStationFrom: current[`wishStationFrom${wishID}`],
      wishStationIdTo: current[`wishStationIdTo${wishID}`],
      wishStationTo: current[`wishStationTo${wishID}`],
    });
    setOpenRailway(true);
  };

  // 沿線を閉じる
  const handleCloseRailway = () => {
    setOpenRailway(false);
  };

  // 沿線を更新
  const handleUpdateRailway = (data) => {
    console.log('handleUpdateRailway', data);
    const newData = {
      [`wishRailwayAreaCodeId${data.id}`]: data.areaCode,
      [`wishRailwayId${data.id}`]: data.railway.railwayId,
      [`wishRailway${data.id}`]: data.railway.railwayName,
      [`wishStationIdFrom${data.id}`]: data.fromStation.stationId,
      [`wishStationFrom${data.id}`]: data.fromStation.name,
      [`wishStationIdTo${data.id}`]: data.toStation.stationId,
      [`wishStationTo${data.id}`]: data.toStation.name,
    };
    // 画面に表示
    // 更新処理
    isChanged(newData, original) && update(newData);
  };

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    const { name, value } = e.target;
    if ((original[name] || '') === value) return;
    // 更新処理
    update({ [name]: value });
  };

  const getBox5 = () => {
    return (
      <TextBaseField
        label="学区（第1希望）"
        defaultValue={current.wishSchoolDistrict1}
        className={classes.textField}
        name="wishSchoolDistrict1"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormString1({ maxLengthInt: 255 })}
        convertor={convertForm1}
      />
    );
  };

  const getBox7 = () => {
    return (
      <TextBaseField
        label="きっかけ"
        defaultValue={current.reason}
        className={classes.textField}
        name="reason"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormString1({ maxLengthInt: 100 })}
        convertor={convertForm1}
      />
    );
  };

  const getBox8 = () => {
    return (
      <TextBaseField
        label="大人"
        defaultValue={current.adultCount}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="adultCount"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
      />
    );
  };

  const getBox9 = () => {
    return (
      <TextBaseField
        label="子供"
        defaultValue={current.childCount}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="childCount"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
      />
    );
  };
  const getBox10 = () => {
    return (
      <TextBaseField
        label="車種"
        defaultValue={current.abodeNowCarType}
        className={classes.textField}
        name="abodeNowCarType"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 30 })}
      />
    );
  };
  const getBox11 = () => {
    return (
      <TextBaseField
        label="台数（台）"
        defaultValue={current.abodeNowCarCount}
        className={classes.textField}
        name="abodeNowCarCount"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
      />
    );
  };
  const list1 = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '沿線（第1希望）',
      val: current.wishRailway1 || '',
      click: (() => {
        handleOpenRailway(1);
      }),
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '駅（第1希望）',
      val: (() => {
        if (current.wishStationFrom1 === null && current.wishStationTo1 === null) {
          return '';
        }
        return (current.wishStationFrom1 || '') + ' ～ ' + (current.wishStationTo1 || '');
      })(),
      click: (() => {
        handleOpenRailway(1);
      }),
      space: true,
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '沿線（第2希望）',
      val: current.wishRailway2 || '',
      click: (() => {
        handleOpenRailway(2);
      }),
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '駅（第2希望）',
      val: (() => {
        if (current.wishStationFrom2 === null && current.wishStationTo2 === null) {
          return '';
        }
        return (current.wishStationFrom2 || '') + ' ～ ' + (current.wishStationTo2 || '');
      })(),
      click: (() => {
        handleOpenRailway(2);
      }),
      space: true,
    },
  ];

  const list2 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '学区（第1希望）',
      val: current.wishSchoolDistrict1,
      keys: 'wishSchoolDistrict1',
      content: getBox5,
      space: true,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'きっかけ',
      val: current.reason,
      keys: 'reason',
      content: getBox7,
      space: true,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '車種',
      val: current.abodeNowCarType,
      keys: 'abodeNowCarType',
      content: getBox10,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '台数（台）',
      val: current.abodeNowCarCount,
      keys: 'abodeNowCarCount',
      content: getBox11,
    },
  ];

  const list3 = [
    {
      type1: 'noModal',
      type2: 'number',
      lbl: '大人',
      fontSize: '16px',
      val: current.adultCount,
      keys: 'adultCount',
      content: getBox8,
    },
    {
      type1: 'noModal',
      type2: 'number',
      lbl: '子供',
      fontSize: '16px',
      val: current.childCount,
      keys: 'childCount',
      content: getBox9,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list1.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={`${classes.listItem} ${item.space && '-mb4'}`}
              >
                <Button
                  className={classes.listBtn}
                  onClick={item.click}
                >
                  <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                    {item.lbl}
                  </span>
                  <span
                    className={`${baseClasses.title4} ${classes.listVal}`}
                  >
                    {item.val}
                  </span>
                </Button>
              </ListItem>
            );
          })}
          {list2.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={`${classes.listItem} ${item.space && '-mb4'}`}
              >
                {input.parts(item)}
                {/* {input.editDialog(item)} */}
              </ListItem>
            );
          })}
        </List>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          家族構成
        </Typography>
        <List className={classes.list}>
          {list3.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.parts(item)}
                {/* {input.editDialog(item)} */}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Grid className={classes.area1}>
          <Typography className={baseClasses.title6}>
            希望地域
          </Typography>
          <NormalTooltip title={current.wishArea ? current.wishArea : ''}>
            <Button
              className={`${classes.button} ${classes.areaButton}`}
              onClick={handleAreaOpen}
            >
              {current.wishArea}
            </Button>
          </NormalTooltip>
        </Grid>
        <Grid className={classes.area2}>
          <Grid className={classes.area3}>
            <Grid className={classes.areaBox1}>
              <Typography className={baseClasses.title6}>
                沿線（第1希望）
              </Typography>
              <Grid className={classes.buttonWrap}>
                <Button
                  className={`${classes.button} ${classes.lineButton}`}
                  onClick={() => {
                    handleOpenRailway(1);
                  }}
                >
                  {current.wishRailway1}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.areaBox1}>
              <Typography className={baseClasses.title6}>
                駅（第1希望）
              </Typography>
              <Grid className={classes.buttonWrap}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    handleOpenRailway(1);
                  }}
                >
                  {current.wishStationFrom1}
                </Button>
                <Typography className={classes.buttonTxt}>
                  ～
                </Typography>
                <Button
                  className={classes.button}
                  onClick={() => {
                    handleOpenRailway(1);
                  }}
                >
                  {current.wishStationTo1}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.areaBox1}>
              <Typography className={baseClasses.title6}>
                沿線（第2希望）
              </Typography>
              <Grid className={classes.buttonWrap}>
                <Button
                  className={`${classes.button} ${classes.lineButton}`}
                  onClick={() => {
                    handleOpenRailway(2);
                  }}
                >
                  {current.wishRailway2}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.areaBox1}>
              <Typography className={baseClasses.title6}>
                駅（第2希望）
              </Typography>
              <Grid className={classes.buttonWrap}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    handleOpenRailway(2);
                  }}
                >
                  {current.wishStationFrom2}
                </Button>
                <Typography className={classes.buttonTxt}>
                  ～
                </Typography>
                <Button
                  className={classes.button}
                  onClick={() => {
                    handleOpenRailway(2);
                  }}
                >
                  {current.wishStationTo2}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.area4}>
            <Grid className={classes.areaBox2}>
              {getBox5()}
            </Grid>
            <Grid className={classes.areaBox3}>
              {getBox8()}
            </Grid>
            <Grid className={classes.areaBox3}>
              {getBox9()}
            </Grid>
            <Grid className={classes.areaBox4}>
              {getBox7()}
            </Grid>
            <Grid className={classes.areaBox2}>
              {getBox10()}
            </Grid>
            <Grid className={classes.areaBox2}>
              {getBox11()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <AreaSentaku
        initialParam={initialParamArea}
        open={openArea}
        onClose={handleCloseArea}
        onUpdate={handleUpdateArea}
      />
      <SelectRailwaysAndStationsContainer
        initialParam={initialParamRailway}
        open={openRailway}
        onUpdate={handleUpdateRailway}
        onClose={handleCloseRailway}
      />
    </Grid>
  );
}

export default React.memo(CustomerArea);
