/* eslint-disable import/prefer-default-export */
/**
 * 物件種別
 */
export const SELECT_PRODUCT_TYPES = {
  OHD: 'OHD',
  HAWK_ONE: 'ホーク・ワン',
  MAISOKU: 'マイソク',
  NO_FAVORITE: '本命物件・案内物件無し',
  NO_HOUSE: '物件データ無し',
  ALL_PRODUCT: '全物件',
};

/**
 * 検索可能な物件種別
 */
export const SEARCHABLE_PRODUCT_TYPES = {
  OHD: 1,
  'ホーク・ワン': 2,
  マイソク: 3,
  全物件: 0,
};

// OHD以外の種別を選択した時、それぞれ決まったsiteIdを入れる必要がある。
// https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2593#comment-164601916
export const SITE_ID_MAP = {
  'ホーク・ワン': 1084479,
  マイソク: 5091,
  '本命物件・案内物件無し': 1084477,
  物件データ無し: 1084478,
};
