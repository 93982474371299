import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Button, TextField, List, ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DriveFileRenameIcon from '../../../../icons/fileRename';
import commonStyles from '../../../../styles';
// eslint-disable-next-line import/no-cycle
import InstructionReportItemHeadSp from '../../parts/sp/instructionReportItemHeadSp';
import InstructionReportItemListSp from '../../parts/sp/instructionReportItemListSp';
import CustomEditDialog from '../../../../eleCommon/sp/customEditDialog';
import CustomSimpleDrawer from '../../../../eleCommon/sp/customSimpleDrawer';
// eslint-disable-next-line max-len
// import CustomSimpleDrawer, { CustomDrawerList } from '../../../../eleCommon/sp/customSimpleDrawer';
import InstructionsWindowSp from '../../../taiouRireki/instructionsWindow/indexSp';

import { REGISTER_TYPE_MENU1_1, REGISTER_TYPE_MENU1_2, REGISTER_TYPE_MENU2_1, REGISTER_TYPE_MENU2_2 } from '../../../../../constants/instructionReport/instructionReportWindowSp';
import Modal from '../../../modal';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '100%',
      background: '#F3F3F3',
    },
    '& .MuiDialog-paper': {
      maxWidth: 'none',
      margin: 0,
      width: '100%',
      maxHeight: 'initial',
      boxShadow: 'none',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      borderRadius: 0,
    },
    '& .MuiBackdrop-root': { display: 'none' },
  },
  header: {
    borderBottom: '1px solid #F3F3F3',
  },
  headerTop: {
    background: '#fff',
    padding: '16px 8px 8px',
    position: 'relative',
  },
  headerTtl: {
    textAlign: 'center',
  },
  headerBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  backBtn: {
    padding: '16px 22px',
    color: theme.palette.primaryColor,
    left: 0,
    lineHeight: 1,
  },
  editBtn: {
    right: 0,
  },
  content: {
    borderTop: 'none',
    borderBottom: 'none',
    padding: '24px 0 120px 16px',
  },
  contentBtn: {
    background: `linear-gradient(90deg, ${theme.palette.primaryColor} 0%, ${theme.palette.gradLightColor} 100%)`,
    boxShadow: '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    padding: '15.5px 48px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    display: 'inline-block',
    borderRadius: 100,
    position: 'fixed',
    bottom: 24,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  // DrawerList内で使用
  list: {
    padding: 0,
  },
  item: {
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '&.Mui-selected': {
      color: theme.palette.primaryColor,
      background: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  icon: { fill: theme.palette.primaryColor },
}));

// 対応属性モーダル内容
function DrawerList(props) {
  const {
    status,
    setStatus,
    detail,
    tab,
    currentUserId,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const ChangeMenu1 = () => {
    if (currentUserId === detail.directUserId
      && currentUserId !== detail.directedUserId) {
      // 指示タブ - 指示者の場合 かつ 被支持者ではない場合
      return REGISTER_TYPE_MENU1_1;
    } else {
      // 指示タブ - 被指示者の場合 または 支持者でも被支持者でもない場合
      return REGISTER_TYPE_MENU1_2;
    }
  };

  const ChangeMenu2 = () => {
    if (currentUserId === detail.directUserId) {
      // 報告タブ - 指示者の場合 または 指示者 かつ 被支持者の場合
      return REGISTER_TYPE_MENU2_1;
    } else {
      // 報告タブ - 被指示者の場合 または 支持者でも被支持者でもない場合
      return REGISTER_TYPE_MENU2_2;
    }
    // switch (detail.directedUserId) {
    //   // Tab：報告 & 指示受け者 === CurrentUser
    //   case currentUserId:
    //     return REGISTER_TYPE_MENU2_2;
    //   // Tab：報告 & 指示者 === CurrentUser
    //   default:
    //     return REGISTER_TYPE_MENU2_1;
    // }
  };

  const getMiddle = () => {
    switch (tab) {
      case 0:
        return ChangeMenu1();
      case 1:
        return ChangeMenu2();
      default:
        return false;
    }
  };

  return (
    <List className={classes.list}>
      {
        Array.from(getMiddle().entries()).map(([key, value]) => {
          // console.log('--', key, value);
          const keyStr = key.toString();
          return (
            <ListItem
              key={keyStr}
              className={`${baseClasses.title3} ${classes.item}`}
              button
              selected={status.includes(keyStr)}
              onClick={() => {
                setStatus(keyStr);
              }}
            >
              {value}
            </ListItem>
          );
        })
      }
    </List>
  );
}

export default function InstructionReportWindowSp(props) {
  const {
    open,
    // setOpen,
    onClose,
    tab,
    row,
    detail,
    getDetailDirect,
    getDirects,
    // customer,
    createTimeline,
    displayCustomerDetail,
    openCustomer,
    setOpenCustomer,
    visibleFooter,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const currentUserId = useSelector((state) => state.responseHeader.userId);
  const [open1, setOpen1] = useState(false); // 編集用モーダルの制御
  const [contentOpen, setContentOpen] = useState(false); // 対応内容用モーダルの制御
  const [attributeOpen, setAttributeOpen] = useState(false); // 対応属性ドラワーの制御
  const [text, setText] = useState(''); // テキストフィールドのバリュー値制御
  const [status, setStatus] = useState(''); // ステータスの選択値

  useEffect(() => {
    if ('content' in detail) {
      setText(detail.content);
    }
  }, [detail]);

  useEffect(async () => {
    if (!open) return;
    await getDetailDirect(row.directId);
  }, [open]);

  const onCloseBg = () => {
    setTimeout(() => {
      onClose();
    }, 0);
  };

  // タイムラインモーダルの表示
  const setTimeline = () => {
    if (attributeOpen) {
      setText('');
      setContentOpen(true);
    } else {
      setAttributeOpen(true);
    }
  };

  // 対応属性の初期化
  const setHandleCancel = () => {
    setStatus('');
  };

  // 対応登録（タイムライン登録）
  const setHandleOk = async () => {
    await createTimeline(
      detail.directId,
      {
        reportCode: Number(status),
        content: text,
      },
    );
    if (Number(status) === 5) {
      // 取り消しの場合
      onCloseBg();
      getDirects();
    } else {
      await getDetailDirect(detail.directId);
      setContentOpen(false);
      setAttributeOpen(false);
      // リセット
      setStatus('');
    }
  };

  // 指示詳細画面閉会
  const openDetail = () => {
    setOpen1(true);
  };

  const content = (
    <TextField
      autoFocus
      multiline
      className={classes.input}
      onChange={(e) => { return setText(e.target.value); }}
      onBlur={(e) => { return setText(e.target.value); }}
      name="対応内容"
      value={text}
    />
  );

  // Drawerのフッター設定
  const foot = {
    buttons: [
      {
        label: 'CANCEL',
        classes: baseClasses.buttonsSecondary,
        handleEvent: setHandleCancel,
      },
      {
        label: '登録',
        classes: baseClasses.buttonsPrimary,
        handleEvent: setTimeline,
        disabled: !status,
      },
    ],
  };

  return (
    <Modal
      open={open}
      onClose={onCloseBg}
      scroll="paper"
      className={classes.dialog}
      headerClassName={baseClasses.onlyPc}
      visibleFooter={visibleFooter}
    >
      <Grid className={classes.header}>
        <Grid className={classes.headerTop}>
          <Button
            className={`${baseClasses.title4} ${classes.headerBtn} ${classes.backBtn}`}
            onClick={() => {
              onCloseBg();
              getDirects();
            }}
          >
            指示一覧
          </Button>
          <Typography className={`${baseClasses.title2} ${classes.headerTtl}`}>
            {row.directId ? '指示対応編集' : '指示対応登録'}
          </Typography>
          {/* {tab === 1 || tab === 2 ? ( */}
          {row.directReportStatusCode < 4 ? (
            <Button
              className={`${classes.headerBtn} ${classes.editBtn}`}
              onClick={() => {
                openDetail();
              }}
            >
              <DriveFileRenameIcon className={classes.icon} />
            </Button>
          ) : null}
          <InstructionsWindowSp
            open={open1}
            setOpen={setOpen1}
            onClose={() => {
              setOpen1(!open1);
            }}
            direct={detail}
            getDetailDirect={getDetailDirect}
            getDirects={getDirects}
            // customer={customer}
            isSp
          />
        </Grid>
        <InstructionReportItemHeadSp
          detail={detail}
          tab={tab}
          displayCustomerDetail={displayCustomerDetail}
          openCustomer={openCustomer}
          setOpenCustomer={setOpenCustomer}
        />
      </Grid>
      <DialogContent className={classes.content} dividers>
        <InstructionReportItemListSp detail={detail} />
      </DialogContent>
      <Button
        className={classes.contentBtn}
        onClick={() => {
          setStatus('');
          setAttributeOpen(!attributeOpen);
        }}
      >
        対応登録
      </Button>
      {/* 対応内容用モーダル */}
      <CustomEditDialog
        open={contentOpen}
        setOpen={setContentOpen}
        content={content}
        set={() => setHandleOk()}
        getDirects={getDirects}
        lbl="対応内容"
        arrow
      />
      {/* 対応属性ドラワー */}
      <CustomSimpleDrawer
        open={attributeOpen}
        setOpen={setAttributeOpen}
        title="対応属性"
        list={
          <DrawerList
            open={open}
            status={status}
            setStatus={setStatus}
            detail={detail}
            tab={tab}
            row={row}
            getDetailDirect={getDetailDirect}
            currentUserId={currentUserId}
          />
          // 共通コンポーネントを使いたいが、どこで呼ばれているコンポーネントかわからないため、一旦コメントアウトで対応
          // list={
          //   <CustomDrawerList
          //     menus={d.menu}
          //     state={d.state}
          //     setState={d.setState}
          //     onClose={() => {
          //       setOpen((prev) => ({ ...prev, [`open${index}`]: false }));
          //     }}
          //   />
          // }
        }
        foot={foot}
      />
    </Modal>
  );
}
