import InstructionReportWindowSpContainers from '../../../../../containers/common/instructionReport/sp/instructionWindowSpContainers';

function InstructionReportWindowSp(props) {
  const {
    open,
    setOpen,
    onClose,
    customerId,
    tab,
    row,
    getDirects,
    resetRender,
    setResetRender,
    // customer,
    displayCustomerDetail,
    openCustomer,
    setOpenCustomer,
    visibleFooter = false,
  } = props;

  return (
    <InstructionReportWindowSpContainers
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      customerId={customerId}
      tab={tab}
      row={row}
      getDirects={getDirects}
      resetRender={resetRender}
      setResetRender={setResetRender}
      // customer={customer}
      displayCustomerDetail={displayCustomerDetail}
      openCustomer={openCustomer}
      setOpenCustomer={setOpenCustomer}
      visibleFooter={visibleFooter}
    />
  );
}

export default InstructionReportWindowSp;
