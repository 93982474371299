import { useSelector } from 'react-redux';
import DetailSearchContainer from '../../../containers/search/detailSearch/detailSearchContainers';

function DetailSearch(props) {
  const { isAvoidSharedCustomer, setAllCustomer = () => {}, isFromAllmail } = props;
  // src/store/search/searchItemsSlice参照
  const searchParams = useSelector((state) => state.searchItems.searchParams);

  return (
    <DetailSearchContainer
      simple={searchParams}
      isAvoidSharedCustomer={isAvoidSharedCustomer}
      setAllCustomer={setAllCustomer}
      isFromAllmail={isFromAllmail}
    />
  );
}

export default DetailSearch;
