import client from '../httpClient';

// 【sendmail020】添付ファイルダウンロード
const sendmailAttachmentsDownloadApi = (actionId) => {
  return client.get('/send-mails/attachments-download', {
    params: { actionId },
    responseType: 'arraybuffer',
    headers: { Accept: 'application/zip' },
  });
};

export default sendmailAttachmentsDownloadApi;
