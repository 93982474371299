import store from '../../store/index';

import EasyRegisterComponent from '../../components/easyRegister/easyRegisterComponent';

export default function EasyRegisterContainer() {
  const { responseHeader } = store.getState();
  const { email = '' } = responseHeader;
  const userId = email.substr(0, email.indexOf('@'));
  return <EasyRegisterComponent userId={userId} />;
}
