import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableSortLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import StoreGroupTableRow from './storeGroupTableRow';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    overflowX: 'auto',
  },
  tableWrap: {
    marginTop: 16,
  },
  scrollArea: {
    paddingTop: 0,
  },
  cell: {
    padding: '4px 0',
    whiteSpace: 'break-spaces',
  },
  header: {
    borderBottom: 'solid 2px #C8C8C8',
  },
  headerTxt: {
    color: '#8C8C8C',
    wordBreak: 'keep-all',
    // borderLeft: 'solid 1px #C8C8C8',
  },
  icon: {
    '& .MuiTableSortLabel-icon': { fontSize: '12px' },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.primaryColor,
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { cells, order, orderBy, onRequestSort } = props;
  const common = commonStyles();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {cells.map(c => (
          <TableCell
            key={c.id}
            style={c.style}
            className={classes.cell}
            sortDirection={orderBy === c.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={createSortHandler(c.id)}
              className={classes.icon}
            >
              <Typography
                className={`${common.title4} ${classes.headerTxt}`}
              >
                {c.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function StoreGroupTable(props) {
  const { settings = [], deleteStoreGroup } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const classes = useStyles();
  const common = commonStyles();
  const { hasLoading } = useLoading();

  const tableContainerHeight = (window.innerHeight - 270) + 'px';

  const allHeader = [
    { id: 'displayOrder', label: '表示順', style: { width: '60px', paddingRight: '100px' } },
    { id: 'storeGroupName', label: '店舗グループ名', style: { width: '200px' } },
    { id: 'divisionSubName', label: '選択店舗' },
  ];

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer
      className={`${classes.tableWrap} ${common.box} ${classes.scrollArea}
        ${hasLoading('storeGroup') ? common.loading : ''}`}
      style={{ height: tableContainerHeight }}
    >
      <Table className={classes.table}>
        <EnhancedTableHead
          cells={allHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(settings, getComparator(order, orderBy))
            .map((row) => {
              return (
                <StoreGroupTableRow
                  row={row}
                  key={row.displayOrder}
                  deleteStoreGroup={deleteStoreGroup}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StoreGroupTable;
