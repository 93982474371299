import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '8px',
    display: 'none',
    '&.active': {
      display: 'block',
    },
  },
  ttl: {
    marginBottom: 8,
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
}));

function DoNotChaseReasonText(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const [text, setText] = useState(values.state);
  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <Grid className={`${classes.root} ${values.active && 'active'}`}>
      <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
        {values.label}
      </Typography>
      <TextField
        className={classes.input}
        onChange={handleChange}
        onBlur={(e) => { return values.setState(e.target.value); }}
        name={values.label}
        value={text}
      />
    </Grid>
  );
}

export default DoNotChaseReasonText;
