import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alongRailways: [
    {
      alongRailwayId: 10,
      jrCompanyCode: 120,
      railwayName: '横浜市営地下鉄ブルーライン',
      railwayShortName: 'YB',
      railwayKanaName: 'ヨコハマシエイチカテツブルーライン',
      isWishAlongRailway: 3,
      wishDisplayOrder: 3,
    },
  ],
};

export const alongRailwaysSlice = createSlice({
  name: 'alongRailways',
  initialState: { alongRailways: initialState },
  reducers: {
    getAlongRailwaysApi: (state, data) => {
      state.alongRailways = data.payload;
    },
  },
});

export const { getAlongRailwaysApi } = alongRailwaysSlice.actions;

export default alongRailwaysSlice.reducer;
