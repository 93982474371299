import DoNotChaseReasonContainers from './doNotChaseReason';

function DoNotChaseReason(props) {
  const {
    open,
    onClose,
    setStatus,
    userData,
    setUserData,
  } = props;
  return (
    <div>
      <DoNotChaseReasonContainers
        open={open}
        onClose={onClose}
        setStatus={setStatus}
        userData={userData}
        setUserData={setUserData}
      />
    </div>
  );
}

export default DoNotChaseReason;
