import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import Calendar from '../../calendar';

const useStyles = makeStyles({
  root: {
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '8px',
    display: 'none',
    '&.active': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  calendar: {
    '& div': {
      border: 'none',
      padding: 0,
    },
    '& span': {
      padding: 0,
      fontSize: 13,
      lineHeight: '1.6',
    },
  },
});

function DoNotChaseReasonCalendar(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: values.state, // state
      setState: values.setState,
    },
  ];

  return (
    <Grid className={`${classes.root} ${values.active && 'active'}`}>
      <Typography className={baseClasses.title6}>
        {values.label}
      </Typography>
      <Grid className={classes.calendar}>
        {datalist.map((array) => {
          return (
            <Calendar key={array.id} data={array} wideInput />
          );
        })}
      </Grid>
    </Grid>
  );
}

export default DoNotChaseReasonCalendar;
