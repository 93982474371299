import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import GuidanceStatusCodeCheckBox from './guidanceStatusCodeCheckBox';
import { GUIDANCE_STATUS_CODE } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '1353!important',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        borderRadius: 0,
        margin: 0,
        width: '100%',
        height: '100%',
        maxHeight: 'none',
      },
    },
  },
  paper: {
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      paddingBottom: 20,
      overflowY: 'auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 212,
    },
  },
  header: {
    display: 'flex',
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      position: 'fixed',
      zIndex: 1,
      left: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid rgba(51, 51, 51, 0.04)',
      padding: '24px 0',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      padding: 16,
      borderBottom: '1px solid #C8C8C8',
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 22px',
    [commonTheme.breakpoints.down('sm')]: {
      margin: '0 12px',
    },
  },
  btnClear: {
    color: theme.palette.primaryColor,
  },
  content: {
    padding: '25px 19px',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 90px 16px',
    },
  },
  all: {
    marginBottom: 12,
  },
  items: {
    marginLeft: 28,
  },
  item: {
    marginBottom: 12,
  },
  checkBox: {
    '& .MuiFormControlLabel-root': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: '1px solid #F3F3F3',
        padding: '15px 16px',
      },
    },
    '& .MuiCheckbox-root': {
      padding: '0',
      marginRight: 5,
    },
    '& .MuiButtonBase-root': {
      [commonTheme.breakpoints.down('sm')]: {
        order: 2,
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: 0,
      marginTop: 2,
      [commonTheme.breakpoints.down('sm')]: {
        order: 1,
        fontSize: 13,
        fontWeight: 700,
      },
    },
    '& .Mui-checked': {
      color: theme.palette.primaryColor,
      '& + span': {
        [commonTheme.breakpoints.down('sm')]: {
          color: theme.palette.primaryColor,
        },
      },
    },
  },
  ttl: {
    padding: '16px 0',
    textAlign: 'center',
    borderBottom: '1px solid #C8C8C8',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function GuidanceStatusCodeWindow(props) {
  const {
    open,
    onClose,
    list,
    selectionClick,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const menus = Object.keys(GUIDANCE_STATUS_CODE);

  const [checkList, setCheckList] = useState(list);

  // クリア
  const [all, setAll] = useState(0);
  const allLift = () => {
    setAll(1);
    setCheckList([]);
  };
  const allBlur = () => {
    setAll(0);
  };

  // 項目チェック時
  const set = (val1, val2) => {
    if (val1) {
      if (checkList.indexOf(val2) === -1) {
        checkList.push(Number(val2));
        setCheckList(checkList);
      }
    } else {
      setCheckList(checkList.filter((check) => {
        return Number(check) !== Number(val2);
      }));
    }
  };

  // 初期表示を設定
  const getFlg = (code) => {
    let flg = 0;
    for (let i = 0; i < list.length; i += 1) {
      if (Number(code) === Number(list[i])) {
        flg = 1;
      }
    }
    return flg;
  };

  const closeBg = () => {
    onClose();
    selectionClick(checkList);
  };

  const codes = menus.filter((code) => {
    return Number(code) !== 0 && Number(code) !== 4;
  });

  const getCheckBox = () => {
    return (
      <Grid className={classes.content}>
        {codes.map((code) => (
          <Grid key={code} className={classes.list}>
            <Grid className={`${classes.all} ${classes.checkBox}`}>
              <GuidanceStatusCodeCheckBox
                label={GUIDANCE_STATUS_CODE[code]}
                flg={getFlg(code)}
                code={code}
                all={all}
                set={set}
              />
            </Grid>
          </Grid>
        ))}
        {!isSP && (
          <Grid className={classes.list}>
            <Grid className={`${classes.all} ${classes.checkBox}`}>
              <GuidanceStatusCodeCheckBox
                label="揃い"
                flg={getFlg(9)}
                code={9}
                all={all}
                set={set}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  if (isSP) {
    contents = (
      <Grid className={classes.paper}>
        <Typography className={`${baseClasses.title2} ${classes.ttl}`}>
          案内種別
        </Typography>
        <Grid
          className={`${baseClasses.title3} ${classes.header}`}
        >
          <Button
            className={`${classes.btnClear} ${classes.btn}`}
            onClick={allLift}
            onBlur={allBlur}
          >
            クリア
          </Button>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.btn}`}
            onClick={() => {
              onClose();
              selectionClick(checkList);
            }}
          >
            選択
          </Button>
        </Grid>
        {getCheckBox()}
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.paper}>
        <Grid
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          className={`${baseClasses.title3} ${classes.header}`}
        >
          <Button
            className={`${classes.btnClear} ${classes.btn}`}
            onClick={allLift}
            onBlur={allBlur}
          >
            クリア
          </Button>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.btn}`}
            onClick={() => {
              onClose();
              selectionClick(checkList);
            }}
          >
            選択
          </Button>
        </Grid>
        {getCheckBox()}
      </Grid>
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeBg}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className={classes.dialog}
      >
        {contents}
      </Dialog>
    </div>
  );
}
