import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import AllListTableRow from './allListTableRow';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    overflowX: 'auto',
  },
  cell: {
    padding: '8px',
    whiteSpace: 'pre',
    textAlign: 'center',
    '& .MuiTypography-root': { textAlign: 'center' },
    '&.MuiTableCell-head': { background: '#fff' },
  },
  icon: {
    '& .MuiTableSortLabel-icon': { fontSize: '12px' },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.primaryColor,
    },
  },
  container: {
    maxHeight: 'calc(100vh - 206px)',
  },
}));

const allHeader = [
  { id: 'customerId', colName: 'customer_id', label: '顧客ID', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'responseAt', colName: 'response_at', label: '反響日', style: { minWidth: '100px', maxWidth: '100px' } },
  { id: 'before2To7DaysSendCount', colName: 'before_2_to_7_days_send_count', label: '前2-7日間\n送信回数', style: { minWidth: '90px', maxWidth: '90px' } },
  { id: 'yesterdaySendCount', colName: 'yesterday_send_count', label: '前日\n送信回数', style: { minWidth: '90px', maxWidth: '90px' } },
  { id: 'todaySendCount', colName: 'today_send_count', label: '今日\n送信回数', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'noMailDate', colName: 'no_mail_date', label: '前回送信\nしてから', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'memberStatusCode', colName: 'member_status_code', label: '会員', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'memberId', colName: 'member_id', label: '会員ID', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'lastName', colName: 'last_name', label: '姓', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'firstName', colName: 'first_name', label: '名', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'mail', colName: 'mail', label: 'Mail 1', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'emailStatusCode', colName: 'email_status_code', label: 'メール許可', style: { minWidth: '110px', maxWidth: '110px' } },
  { id: 'responsibleUserName', colName: 'responsible_user_name', label: '担当者', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'detachedHouseMax', colName: 'detached_house_max', label: '戸建上限', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'landMax', colName: 'land_max', label: '土地上限', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'wishArea', colName: 'wish_area', label: '希望地域', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'wishStationNameFrom', colName: 'wish_station_name_from', label: '希望路線', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'wishRailwayName', colName: 'wish_railway_name', label: '希望駅', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'noTelDate', colName: 'no_tel_date', label: '未TEL\n日数', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'annualIncome', colName: 'annual_income', label: '年収\n（万）', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'deposit', colName: 'deposit', label: '頭金\n（万）', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'monthlyPayment', colName: 'monthly_payment', label: '月々の\n返済', style: { minWidth: '80px', maxWidth: '80px' } },
  { id: 'userLevel', colName: 'user_level', label: 'ランク', style: { minWidth: '60px', maxWidth: '90px' } },
  { id: 'company', colName: 'company', label: '勤務先', style: { minWidth: '120px', maxWidth: '120px' } },
  { id: 'companyRailway', colName: 'company_railway', label: '路線', style: { minWidth: '120px', maxWidth: '120px' } },
  { id: 'companyStation', colName: 'company_station', label: '駅', style: { minWidth: '120px', maxWidth: '120px' } },
  { id: 'adultCount', colName: 'adult_count', label: '大人', style: { minWidth: '50px', maxWidth: '50px' } },
  { id: 'childCount', colName: 'child_count', label: '子供', style: { minWidth: '50px', maxWidth: '50px' } },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick } = props;
  const common = commonStyles();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.cell}>
          <Checkbox
            color="primary"
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {allHeader.map((c) => (
          <TableCell
            key={c.id}
            style={c.style}
            className={classes.cell}
            sortDirection={orderBy === c.colName ? order : false}
          >
            <TableSortLabel
              active={orderBy === c.colName}
              direction={orderBy === c.colName ? order : 'asc'}
              onClick={createSortHandler(c.colName)}
              className={classes.icon}
            >
              <Typography className={common.title4}>{c.label}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableHeadMemoed = React.memo(
  EnhancedTableHead,
  (prev, next) => (
    prev.numSelected === next.numSelected
    && prev.onSelectAllClick === next.onSelectAllClick
    && prev.order === next.order
    && prev.orderBy === next.orderBy
  ),
);

export default function AllListTable(props) {
  const {
    list,
    display,
    order,
    orderBy,
    handleRequestSort,
    totalCount,
    allCustomreIds,
    selectedCustomerIds,
    setSelectedCustomerIds,
  } = props;
  const classes = useStyles();

  const handleSelectAllClick = useCallback((e) => {
    if (e.target.checked) {
      setSelectedCustomerIds(allCustomreIds);
      return;
    }
    setSelectedCustomerIds([]);
  }, [allCustomreIds]);
  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader>
        <EnhancedTableHeadMemoed
          cells={allHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          numSelected={selectedCustomerIds.length}
          rowCount={totalCount}
          onSelectAllClick={handleSelectAllClick}
        />
        {list.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={31}>
                <Typography style={{ padding: '3em 2em' }}>
                  対象顧客が存在しません。
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {list.map((row) => (
              <AllListTableRow
                row={row}
                cells={allHeader}
                key={`${row.id}-${row.customerId}`}
                display={display}
                setSelectedCustomerIds={setSelectedCustomerIds}
                checked={selectedCustomerIds.includes(row.customerId)}
              />
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
