import { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deepEqual from 'deep-equal';

import CustomerUpdateApi from '../../apis/customer/customerUpdateApi';
import { updateGotDateTime } from '../../store/customer/gotDataTimeSlice';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import { getCustomerApi } from '../../store/customer/customerSlice';
import { getNowDate, isObject } from '../../commonFunction';
import { useRerender } from '../../hooks';
import commonStyles from '../../components/styles';

export * from './customerMainContext';

const OVERWRITE_MESSAGE = 'すでに顧客情報が変更されています。';

const COMMON_PARAMS = {
  updateType: 0,
  getDataAt: getNowDate(),
  isNotCheckUpdate: 0,
};

function updateObject(obj, key, value) {
  if (isObject(key)) {
    Object.keys(key).forEach(k => {
      obj[k] = key[k];
    });
  } else {
    obj[key] = value;
  }
}

/**
 * 顧客情報更新Hook
 * @param {object} originalData
 * @param {string[]} keys - 更新が必要なoriginalDataのキー
 * @returns {{
 * current: object;
 * original: object;
 * updateCurrent: (key: string | object, value?: any) => void;
 * updateOriginal: (key: string | object, value?: any) => void;
 * updateApi: (customerId: number, modifiedData: obj) => Promise<boolean>;
 * rerender: () => void;
 * rerenderKey: string;
 * update: (newData: object, rerender?: boolean) => Promise<boolean>;
 * isSp: boolean;
 * editTargetRef: React.Ref;
 * }}
 */
export function useUpdate(originalData = {}, keys = []) {
  const baseClasses = commonStyles();
  const dispatch = useDispatch();

  const { rerender, rerenderKey } = useRerender();

  // クリック（選択）された情報をキャッシュする
  // 編集モダールに渡すと「決定ボタン」を押した後の処理のため
  const editTargetRef = useRef({});

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const gotCustomerDataTimeObj = useSelector((s) => s.gotDataTime.gotDataTime);

  const gsCustomer = useSelector((state) => state.customer.customer);

  // TODO: useObjectUpdateで置き換え
  // 必要な情報を取得する、
  // keysが指定されない場合はoriginalDataの全ての情報をコピーする
  const newOriginalData = keys.length ? keys.reduce((prev, key) => {
    prev[key] = originalData[key];
    return prev;
  }, {}) : originalData;

  // copy
  const newOriginalStr = JSON.stringify(newOriginalData);
  const currentRef = useRef(JSON.parse(newOriginalStr));
  const originalRef = useRef(JSON.parse(newOriginalStr));

  const updateDataRef = useRef({}); // 排他制御に引っかかる場合に更新する

  // 項目保存時の別ユーザーによる項目更新と差分比較
  const cancel = useCallback(() => {
    console.log('編集画面に戻る');
  }, []);

  const overwrite = useCallback(() => {
    console.log('上書き保存する');
    const data = {
      ...updateDataRef.current,
      isNotCheckUpdate: 1,
    };
    // eslint-disable-next-line no-use-before-define
    return updateApi(updateDataRef.current.customerId, data);
  }, []);

  // TODO: use Promise
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '別のユーザーによって更新されています。',
      msgList: [
        <div style={{ margin: 'auto', textAlign: 'left', whiteSpace: 'pre-wrap', width: 'fit-content', display: 'inline-flex' }}>
          {[
            '最新の顧客データを取得し、再度編集しなおしてください。',
            '１．「編集画面に戻る」を押下し、ダイアログを閉じる。',
            `２．${isSp ? '下方向でのスワイプを行い' : '画面左上の更新ボタンを押下し'}、顧客データを再取得する。`,
            '※現在編集中の内容は破棄されます。',
            '',
            '無視してこのまま更新する場合は「上書き保存する」を押下してください。',
          ].join('\n')}
        </div>,
      ],
      buttons: [
        {
          label: '編集画面に戻る',
          set: cancel,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: '上書き保存する',
          set: overwrite,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  /**
   * PUT Update Customer
   * @param {number} customerId - 顧客ID
   * @param {*} modifyObj - 変更された情報
   * @returns {boolean}
   */
  const updateApi = async (customerId, modifyObj) => {
    // 上書きのため改修情報を一時キャッシュする
    updateDataRef.current = {
      ...COMMON_PARAMS,
      customerId, // パラメーターではないが、上書き時が使用されるためキャッシュする
      getDataAt: gotCustomerDataTimeObj[String(customerId)] || COMMON_PARAMS.getDataAt,
      ...modifyObj,
    };
    const params = {
      ...updateDataRef.current,
      customerId: undefined,
    };
    try {
      const { data, message } = await CustomerUpdateApi(customerId, params);
      if (data.updatedAt) {
        dispatch(updateGotDateTime({ customerId, gotDataTime: data.updatedAt }));

        const targetIndex = gsCustomer.customers.findIndex(item => item.customerId === customerId);
        if (targetIndex !== -1) {
          const updatedItem = { ...gsCustomer.customers[targetIndex], ...modifyObj };
          const newItems = [
            ...gsCustomer.customers.slice(0, targetIndex),
            updatedItem,
            ...gsCustomer.customers.slice(targetIndex + 1),
          ];
          dispatch(getCustomerApi({
            customers: newItems,
            getDataAt: gsCustomer.getDataAt,
          }));
        }
      }
      if (message === OVERWRITE_MESSAGE) confirmOpen();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const updateCurrent = (key, value) => {
    updateObject(currentRef.current, key, value);
  };

  const updateOriginal = (key, value) => {
    updateObject(originalRef.current, key, value);
  };

  const update = async (data, needRerender = false) => {
    updateOriginal(data);
    updateCurrent(data);
    // 更新処理
    const res = await updateApi(currentRef.current.customerId, data);
    // rerender
    needRerender && rerender();
    return res;
  };

  return {
    updateApi,
    current: currentRef.current,
    original: originalRef.current,
    updateCurrent,
    updateOriginal,
    update,
    rerender,
    rerenderKey,
    editTargetRef,
    isSp,
  };
}

export function isChanged(newObj, originalObj) {
  for (const [key, value] of Object.entries(newObj)) {
    if (value && typeof value === 'object') {
      if (!deepEqual(originalObj[key], value)) return true;
    } else if (originalObj[key] !== value) {
      return true;
    }
  }
  return false;
}
