import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: [],
};
export const smsTalkSlice = createSlice({
  name: 'smsTalk',
  initialState: { smsTalk: initialState },
  reducers: {
    getSmsTalkApi: (state, data) => {
      state.smsTalk = data.payload;
    },
    setSmsTalkInitial: (state) => {
      state.smsTalk = initialState;
    },
  },
});

export const { getSmsTalkApi, setSmsTalkInitial } = smsTalkSlice.actions;

export default smsTalkSlice.reducer;
