import { useState, useEffect } from 'react';
import {
  useSelector,
  // useDispatch,
} from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import CustomerTree from '../common/customerTree/customerTree';
// import { updateObjSearchItems } from '../../store/search/searchItemsSlice';

export default function CustomerTreeSelectTest() {
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // ここでGlobalStateを定義
  // const searchParams = useSelector((state) => state.searchItems.searchParams);
  // const dispatch = useDispatch();

  // 反響ツリーのパラメータsetは下記
  // useEffect(() => {
  //   dispatch(updateObjSearchItems({
  //     userLevelMin: 1, // 重要情報 - ランク
  //     userLevelMax: 5, // 重要情報 - ランク
  //   }));
  // }, []);

  // ツリー表示/非表示
  const [isOpen, setIsOpen] = useState(false);
  // 反響ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  // const [selectUser, setSelectUser] = useState({ userId: -1, userName: '担当なし ' });
  // const [selectUser, setSelectUser] = useState({ userId: 5192, userName: '渡邊界' });
  // 反響ツリー選択組織
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });
  // eslint-disable-next-line max-len
  // const [selectDivision, setSelectDivision] = useState({ divId: 1572, divisionName: '闇の組織営業センター' });
  // 反響ツリー選択顧客
  const [selectCustomer, setSelectCustomer] = useState([]);
  // Dialog形式かどうか
  const [isModal, setIsModal] = useState(true);
  // 検索ボックスの表示/非表示
  const [searchForm, setSearchForm] = useState(true);
  // 検索と選択対象
  const [targetFlgList] = useState({
    userFlg: true,
    divisionFlg: true,
    customerFlg: true,
  });
  // defaultUserId入れれば初期描画で勝手にこれが選択される
  // const defaultUserId = 5192;
  // const defaultDivisionId = 1572;

  useEffect(() => {
    console.log('---------反響ツリー取得内容-------');
    console.log('selectUser----');
    console.log(selectUser);
    console.log('selectDivision----');
    console.log(selectDivision);
    console.log('selectCustomer----');
    console.log(selectCustomer);
    console.log(isModal);
    console.log(isOpen);
  }, [selectUser, selectDivision, selectCustomer]);

  const onCheck = () => {
    console.log('selectUser----');
    console.log(selectUser);
    console.log('selectDivision----');
    console.log(selectDivision);
    console.log('selectCustomer----');
    console.log(selectCustomer);
  };

  return (
    <Grid container>
      <Grid item xs={3} md={3} lg={3} xl={3}>
        <CustomerTree
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSelectUser={setSelectUser}
          setSelectDivision={setSelectDivision}
          setSelectCustomer={setSelectCustomer}
          // isModal={isModal}
          // searchForm={searchForm}
          // targetFlgList={targetFlgList}
          isMulti
          targetFlgList={targetFlgList}
          defaultUserId={selectUser?.userId}
          // eslint-disable-next-line no-mixed-operators
          defaultDivisionId={selectDivision?.divId && !selectUser?.userId || selectDivision?.divId && selectUser?.userId === -1 ? selectDivision.divId : ''}
          // defaultCustomerId={11033519}
        />
      </Grid>
      <Grid item xs={1} md={1} lg={1} xl={1} />
      <Grid item xs={3} md={3} lg={3} xl={3}>
        <Button
          style={{ backgroundColor: 'lightSkyBlue', color: 'white' }}
          onClick={() => {
            setIsModal(true);
            setIsOpen(true);
          }}
        >
          ダイアログ
        </Button>
        {!isSp && (
          <Button
            style={{ backgroundColor: 'lightSkyBlue', color: 'white', marginLeft: '10px' }}
            onClick={() => {
              setSearchForm(!searchForm);
            }}
          >
            検索表示切り替え
          </Button>
        )}
      </Grid>
      <Button onClick={() => onCheck()}>Check</Button>
    </Grid>
  );
}
