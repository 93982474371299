import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';

const dSearchLocalStyle = makeStyles(() => ({
  detailSearchWrap: {
    // PC width
    [commonTheme.breakpoints.up('md')]: {
      '& .MuiInputLabel-formControl': {
        left: '7px',
      },
      '& .important': {
        // ここに重要情報関連のスタイル
        '& .important__selectContainer': {
          width: '40%',
          '& .important__rank__medium': {
            width: '45%',
            '& .MuiInputLabel-formControl': {
              left: '7px',
            },
          },
        },
        '& .important__customerStatusCode': {
          width: '40%',
          '& .MuiInputLabel-formControl': {
            left: '7px',
          },
        },
        '& .important__checkboxOption__md': {
          width: '11%',
        },
        '& .important__checkboxOption__lg': {
          width: '17%',
        },
      },
      '& .response': {
        // ここに反響情報のスタイル
        '& .response__inputContainer': {
          width: '50%',
        },
        '& .response__webMemberStatusContainer': {
          width: '50%',
          '& .MuiGrid-root': {
            width: '100%',
          },
        },
        '& .response__responseCode1Container': {
          width: '100%',
          marginTop: '13px',
        },
        '& .response__inputContainer__sm': {
          width: '33%',
          display: 'flex',
          '& .MuiInputLabel-formControl': {
            left: '7px',
          },
        },
        '& .response__calendarContainer': {
          width: '50%',
          '& label': {
            minHeight: '48px',
            display: 'flex',
            alignItems: 'flex-end',
          },
          '& .response__calendarFrom': {
            width: '47%',
            padding: 0,
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '& .response__calendarTo': {
            width: '47%',
          },
          '& .response__calendarLabel': {
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            position: 'absolute',
            top: '10px',
            left: '0',
          },
        },
      },
      '& .terms': {
        justifyContent: 'space-between',
        // ここに希望条件のスタイル
        // '& .terms__houseBudget': {
        //   width: '33%',
        // },
        '& .terms__inputContainer__sm': {
          width: '32%',
          '& .MuiInputLabel-formControl': {
            left: '7px',
          },
        },
        '& .terms__inputContainer__xs1': {
          width: '14%',
          '& .MuiInputLabel-formControl': {
            left: '7px',
          },
        },
        '& .terms__inputContainer__xs2': {
          width: '14%',
          marginRight: '35px',
          '& .MuiInputLabel-formControl': {
            left: '7px',
          },
        },
        '& .terms__inputContainer__xxs1': {
          width: '11%',
          '& .MuiInputLabel-formControl': {
            left: '7px',
          },
        },
        '& .terms__wishArea, .terms__wishRailway': {
          marginTop: '12px',
        },
        '& .terms__wishStationFrom': {
          marginTop: '13px',
        },
        '& .terms__searchStationTypeCode': {
          '& .MuiInput-underline': {
            minWidth: '100px',
          },
        },
        '& .terms__input__select': {
          minWidth: '150px',
        },

        '& .terms__floorPlan': {
          '& .MuiFormControlLabel-root': {
            marginLeft: '-4px',
          },
        },
      },
      '& .termsType': {
        padding: '10px 0 0 20px',
        // ここにtermsTypeのスタイル
        '& .termsType__item': {
          marginRight: '1.2rem',
        },
      },
      '& .achievement': {
        // 行動情報のスタイル
        '&>.MuiGrid-item': {
          marginRight: '1.2rem',
        },
      },
      '& .supplement': {
        // 補足情報のスタイル
        '& .supplement__holidayWeekdays': {
          width: '100%',
          marginLeft: '5px',
          '& .MuiFormControlLabel-root': {
            marginLeft: '-4px',
          },
        },

        '& .supplement__telStatusCode': {
          width: '25%',
        },

        '& .supplement__emailStatusCodes': {
          marginBottom: '0',
          width: '50%',
        },

        '& .supplement__isAllowDm': {
          width: '25%',
        },

        '& .supplement__isAddressExist': {
          width: '25%',
        },

        '& .supplement__abodeNowBuildingType': {
          width: '25%',
        },

        '& .terms__input__select': {
          minWidth: '90px',
        },

        '& .terms__wishStationFrom': {
          marginBottom: '0px',
        },

        '& .terms__input__text': {
          minWidth: '90px',
          marginTop: '13px',
        },

        '& .terms__input__text_right': {
          paddingRight: '5px',
        },

        '& .terms__input__text_left': {
          paddingLeft: '5px',
        },

        '& .supplement__currentUserId, .supplement__formerUserId, .supplement__previousUserId':
          {
            width: '0',
          },

        '& .supplement__currentUser, & .supplement__formerUser, & .supplement__previousUser': {
          marginTop: '10px',
          marginRight: '17px',
          width: '31.5%',
        },
      },
      '& .support': {
        // 対応履歴のスタイル
        '& .support__actionCalendar': {
          width: '50%',
          marginBottom: 0,
          '& > div': {
            justifyContent: 'space-between',
          },
          '& label': {
            minHeight: '48px',
            display: 'flex',
            alignItems: 'flex-end',
          },
          '& .support__calendarFrom': {
            width: '47%',
            padding: 0,
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '& .support__calendarTo': {
            width: '47%',
          },
          '& .support__calendarLabel': {
            transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            position: 'absolute',
            top: '10px',
            left: '0',
          },
        },

        '& .support__actionCode': {
          margin: 0,
          position: 'relative',
          // '&::after': {
          //   content: '""',
          //   display: 'block',
          //   background: '#8c8c8c',
          //   width: '100%',
          //   height: 1,
          //   position: 'absolute',
          //   left: 0,
          //   bottom: 8,
          //   pointerEvents: 'none',
          // },
          '& > .MuiButton-root': {
            height: 64,
            borderBottom: 'none',
          },
        },

        '& .support__actionKeyWord': {
          margin: 0,
          '& .support__actionKeyWord__input': {
            width: '100%',
            padding: '16px 0 8px',
          },
        },

        '& .support__actionSearchCode': {
          width: '100%',
          '& > .MuiFormGroup-root': {
            width: '100%',
            paddingLeft: '5px',
            '&::after': {
              paddingLeft: '5px',
            },
          },
          '& .MuiFormControlLabel-root': {
            marginLeft: '-4px',
          },
        },
      },
      '& .nocontact': {
        // 追わないのスタイル
        '& .nocontact__thinkStartSeasonFrom': {
          width: '50%',
          '& label': {
            minHeight: '48px',
            display: 'flex',
            alignItems: 'flex-end',
          },
          '& .nocontact__calendarFrom': {
            width: '47%',
            padding: 0,
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '& .nocontact__calendarTo': {
            width: '47%',
          },
          '& .nocontact__calendarLabel': {
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            position: 'absolute',
            top: '10px',
            left: '0',
          },
        },

        '& .nocontact__noneContactKeyword': {
          '& .nocontact__noneContactKeyword__input': {
            width: '100%',
          },
        },

        '& .nocontact__noneContactKeywordCodes': {
          width: '100%',
          marginLeft: '5px',
          '& .MuiFormControlLabel-root': {
            marginLeft: '-4px',
          },
        },
      },
      '& .keyword': {
        // キーワードのスタイル
        '& .keyword__keyword': {
          marginTop: '15px',
          width: '100%',
          marginLeft: '5px',
          '& .keyword__keyword__input': {
            width: '100%',
          },
        },

        '& .keyword__keywordSearch': {
          width: '100%',
          marginLeft: '5px',
          '& .MuiFormControlLabel-root': {
            marginLeft: '-4px',
          },
        },
      },
      '& .recommend': {
        // リコメンドのスタイル
        '& .recommend__recommendStatus': {
          marginTop: '10px',
          width: '100%',
          marginLeft: '5px',
          '& .recommend__recommendStatus__input': {
            width: '100%',
          },
        },
      },
      '& .customerId': {
        // 顧客IDのスタイル
        '& .customerId__customerIds': {
          marginTop: '10px',
          width: '100%',
          marginLeft: '10px',
          '& .customerId__customerIds__input': {
            width: '100%',
          },
        },
      },
      '& .tel': {
        // TELがつなりやすい時間帯のスタイル
        '& .tel__convenientTimes': {
          width: '40%',
          marginLeft: '10px',
          '& .tel__convenientTimes__input': {
            //
          },
        },
      },
    },
  },
}));

export default dSearchLocalStyle;
