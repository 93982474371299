import JoinDataContainers from '../../../containers/joinData/joinDataContainers';

function JoinData(props) {
  const { parentResponseDataId, childResponseDataId, operation } = props;
  return (
    <div>
      <JoinDataContainers
        parentResponseDataId={parentResponseDataId}
        childResponseDataId={childResponseDataId}
        operation={operation}
        // open={open}
        // setOpen={setOpen}
      />
    </div>
  );
}

export default JoinData;
