import { useSelector } from 'react-redux';
import ResponseTypeList from './responseTypeList';
import ResponseTypeListSp from './sp/responseTypeListSp';

function ResponseType(props) {
  const { data, setState, state, open, setOpen, defaultResponse,
    resetData, set, addResponseType, parentOpen } = props;
  const isSp = useSelector((globalState) => globalState.deviceTypeSlice.isSp);

  return (
    isSp ? (
      <ResponseTypeListSp
        data={data}
        state={state}
        setState={setState}
        open={open}
        setOpen={setOpen}
        defaultResponse={defaultResponse}
        resetData={resetData}
        set={set}
        addResponseType={addResponseType}
        parentOpen={parentOpen}
      />
    ) : (
      <ResponseTypeList
        data={data}
        state={state}
        setState={setState}
      />
    )
  );
}

export default ResponseType;
