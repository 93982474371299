import BulkChangeWindowContainers from './bulkChangeWindow';

function BulkChangeWindow(props) {
  const {
    open,
    onClose,
    choice,
    check,
    getComponent,
    selectedCustomerId,
    updateStart,
  } = props;
  return (
    <BulkChangeWindowContainers
      open={open}
      onClose={onClose}
      choice={choice}
      check={check}
      getComponent={getComponent}
      selectedCustomerId={selectedCustomerId}
      updateStart={updateStart}
    />
  );
}

export default BulkChangeWindow;
