import { useState, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Dialog, Slide, Typography, List, ListItem, ListItemText,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    '& .MuiDialog-paper': {
      width: '100%',
      margin: '0 8px',
    },
  },
  list: {
    paddingLeft: '16px',
  },
  btn: {
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    fontFamily: 'Roboto',
    padding: '16px',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& > p': {
        fontSize: '13px',
      },
    },
  },
  label: {
    marginRight: '8px',
  },
  icon: {
    fill: theme.palette.primaryColor,
    marginLeft: '10px',
  },
  disable: {
    pointerEvents: 'none',
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogComponent(props) {
  const { values, open, setOpen } = props;
  const menusKeys = Object.keys(values.constants.menus);

  const classes = useStyles();
  const common = commonStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={() => { setOpen(false); }}
      TransitionComponent={Transition}
    >
      <List className={classes.list}>
        {menusKeys.map((data) => (
          <ListItem
            button
            key={data}
            classes={{ gutters: common.gutters }}
            selected={values.state === data}
            onClick={() => {
              // select tagのonChangeに出力を合わせる。
              const e = { target: { value: data } };
              values.setState(e);
              setOpen(false);
            }}
          >
            <ListItemText
              primary={values.constants.menus[data]}
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

function MailSelect(props) {
  const { values, disable = false } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        type="button"
        className={`${classes.btn} ${disable ? classes.disable : ''}`}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography className={classes.label}>{values.label}</Typography>
        <Typography>{values.state === '' ? '選択してください' : values.constants.menus[values.state]}</Typography>
        <ArrowDropDownIcon className={classes.icon} />
      </Button>
      <DialogComponent values={values} open={open} setOpen={setOpen} />
    </>
  );
}

export default MailSelect;
