import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import commonStyles from '../styles';
import InputIdForm from './InputIdForm';
import RelatedMember from './RelatedMember';
import customerUpdateCallApi from '../../apis/customer/customerUpdateApi';
import { membersDeleteApi } from '../../apis/members';
import customerCallApi from '../../apis/customer/customerApi';
import { useLoading } from '../../hooks';
import { UPDATE_TYPE_CODE } from '../../constants/apiParameterCode';
import { toNumber } from '../../commonFunction';
import Modal from '../common/modal';
import { SALES_GROUPS } from '../../constants/userRoleGroup';

const useStyles = makeStyles((theme) => ({
  ableColor: {
    color: theme.palette.primaryColor,
  },
  disableColor: {
    color: '#AFAFAF',
  },
  addIcon: {
    width: 16,
    height: 16,
  },
  buttonTxt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
}));

function RelatedMemberWindow(props) {
  const {
    open,
    onClose,
    fullName,
    list = [],
    onChange,
    customerId,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [webs, setWebs] = useState([]);
  const { addLoading, removeLoading } = useLoading();
  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleGroupId } = responseHeader;
  // 営業権限かどうか
  const isSales = SALES_GROUPS.includes(roleGroupId);

  const onCloseBg = () => {
    onClose();
  };

  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };

  const resetWebs = useCallback(async () => {
    // 最新の顧客情報を取得する
    const res = await customerCallApi([customerId]);
    const newWebs = res.data.customers[0].webs || [];
    setWebs(newWebs);
    onChange?.(newWebs);
  }, [customerId]);

  // APIを
  const update = async (arr, callback) => {
    addLoading();
    // バリデーション
    arr.forEach((item) => {
      if (typeof item.memberStatusCode !== 'number') {
        item.memberStatusCode = toNumber(item.memberStatusCode);
      }
    });
    try {
      await customerUpdateCallApi(customerId, {
        updateType: UPDATE_TYPE_CODE.INITIAL,
        getDataAt: moment().format('YYYY/MM/DD hh:mm:ss'),
        isNotCheckUpdate: 1,
        webs: arr,
      });
      callback && callback();
      await resetWebs();
    } catch (e) {
      // ...
    }
    removeLoading();
  };

  // 追加
  const onAddMember = async (memberId, callback) => {
    const arr = [...webs];
    arr.push({
      memberId: toNumber(memberId),
      isMain: 0,
      memberStatusCode: 0,
    });
    await update(arr, callback);
  };

  // 削除
  const onDelete = async (memberId, callback) => {
    addLoading();
    await membersDeleteApi(memberId);
    callback && callback();
    await resetWebs();
    removeLoading();
  };

  // メインに変更
  const onMainChange = async (memberId, callback) => {
    const arr = [...webs];
    arr.forEach(item => {
      item.isMain = item.memberId === memberId ? 1 : 0;
    });
    await update(arr, callback);
  };

  useEffect(() => {
    if (!open) return;
    // Propsから取得する
    if (list && webs !== list) {
      setWebs(list);
    }
  }, [open, list]);

  return (
    <Modal
      open={open}
      onClose={onCloseBg}
      title="関連Web会員IDウインドウ"
      white
      width={640}
    >
      <Button
        onClick={handleShow}
        disabled={isSales}
        className={isSales ? classes.disableColor : classes.ableColor}
      >
        <AddIcon className={classes.addIcon} />
        <Typography className={`${baseClasses.smallStrong} ${classes.buttonTxt}`}>
          追加
        </Typography>
      </Button>
      <RelatedMember
        fullName={fullName}
        list={webs}
        onDelete={onDelete}
        onMainChange={onMainChange}
      />
      <InputIdForm
        show={show}
        setShow={setShow}
        onSubmit={onAddMember}
      />
    </Modal>
  );
}

export default RelatedMemberWindow;
