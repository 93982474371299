import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import SelectProduct from '../../selectProduct/selectProduct';

const useStyles = makeStyles({
  contentIpt: {
    width: '100%',
    background: '#fff',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 4,
      paddingLeft: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: 10,
      marginBottom: 8,
    },
  },
  contentBtn: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 8px 16px 16px',
      borderBottom: '1px solid #F3F3F3',
    },
  },
});

function Product(props) {
  const { editableFlag, values, setUpdateData } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const productShowTypes = ['OHD', 'ホーク・ワン', 'マイソク', '物件データ無し'];

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  useEffect(() => {
    // 編集時はAPIの返り値を画面に表示
    if (editableFlag) {
      if (Object.keys(values).length !== 0) {
        // 現場
        setProduct({ ...product, productName: values.siteName });
      }
    }
  }, []);
  // 現場IDの更新
  useEffect(() => {
    setUpdateData({ ...values, siteId: product.siteId });
  }, [product]);

  if (isSP) {
    contents = (
      <>
        <Grid className={classes.contentIpt}>
          <Button
            className={classes.contentBtn}
            onClick={() => setOpen(true)}
          >
            <Typography className={baseClasses.title6}>
              現場
            </Typography>
            <Typography className={baseClasses.smallStrong}>
              {product.productName ? product.productName : '選択してください'}
            </Typography>
          </Button>
        </Grid>
        <SelectProduct
          open={open}
          setOpen={setOpen}
          onClose={() => setOpen(false)}
          product={product}
          setProduct={setProduct}
          showTypes={productShowTypes}
        />
      </>
    );
  } else {
    contents = (
      <>
        <Grid className={classes.contentIpt}>
          <Typography style={{ width: '15%' }} className={baseClasses.title6}>
            現場
          </Typography>
          <Button
            onClick={() => setOpen(true)}
          >
            <Typography className={baseClasses.smallStrong}>
              {product.productName ? product.productName : '選択してください'}
            </Typography>
          </Button>
        </Grid>
        <SelectProduct
          open={open}
          setOpen={setOpen}
          onClose={() => setOpen(false)}
          product={product}
          setProduct={setProduct}
          showTypes={productShowTypes}
        />
      </>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default Product;
