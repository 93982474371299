import { useState, useEffect, useRef, useCallback } from 'react';
import InfoChangeHistoryWrapper from '../../../components/common/infoChangeHistory/infoChangeHistory';

import customerHistoriesApi from '../../../apis/customer/customersHistoriyApi';

import { dateFormatter, throttle } from '../../../commonFunction';

import { useRerender } from '../../../hooks';

const initial = {
  infoMultiple: { startAt: '', endAt: '' },
  infoItem: [],
  infoChanger: {
    userId: null,
    userName: '',
  },
  infoKeyword: '',
};

function InfoChangeHistoryContainers(props) {
  const { customer, customerId, refreshing, onRefresh } = props;
  const [histories, setHistories] = useState([]);

  const { rerender, rerenderKey } = useRerender();

  const [search, setSearch] = useState(initial);
  const searchRef = useRef(null);
  const limitOffsetParamRef = useRef({ limit: 100, offset: 0, isFull: false });

  const resetFunc = () => setSearch(initial);

  const getHistories = useCallback((isMoreLoad = false) => {
    // Default値反映の為コメントアウト
    // if (hasLoading('infoChangeHistory')) return;
    const { infoMultiple, infoItem, infoChanger, infoKeyword } = search;

    const { startAt, endAt } = infoMultiple;
    let searchStartAt;
    let searchEndAt;
    if (isMoreLoad) {
      searchStartAt = searchRef.current.infoMultiple.startAt
        ? dateFormatter(new Date(searchRef.current.infoMultiple.startAt), 'YYYY-MM-DD 00:00:00')
        : '';
      searchEndAt = searchRef.current.infoMultiple.endAt
        ? dateFormatter(new Date(searchRef.current.infoMultiple.endAt), 'YYYY-MM-DD 23:59:59')
        : '';
    } else {
      searchStartAt = startAt
        ? dateFormatter(new Date(startAt), 'YYYY-MM-DD 00:00:00')
        : '';
      searchEndAt = endAt
        ? dateFormatter(new Date(endAt), 'YYYY-MM-DD 23:59:59')
        : '';
    }

    const { limit, offset, isFull } = limitOffsetParamRef.current;
    let limitOffsetParam = { limit, offset };

    if (isMoreLoad) {
      if (isFull) return;
      limitOffsetParam = { limit, offset: offset + limit };
    }

    const searchParam = {
      searchStartAt,
      searchEndAt,
      keyword: isMoreLoad ? searchRef.current.infoKeyword : infoKeyword,
      userId: isMoreLoad ? searchRef.current.infoChanger?.userId : infoChanger?.userId,
      columnNames: isMoreLoad
        ? searchRef.current.infoItem.map((i) => i.columnNames).flat()
        : infoItem.map((i) => i.columnNames).flat(),
      ...limitOffsetParam,
    };

    Object.entries(searchParam).forEach(([key, val]) => {
      if (val === null || val === undefined || val === '') delete searchParam[key];
    });

    customerHistoriesApi(customerId, searchParam)
      .then((res) => {
        const newList = res.data.customerHistories;
        setHistories((prevList) => {
          const tmp = isMoreLoad
            ? [...prevList, ...newList]
            : newList;
          return tmp;
        });
        limitOffsetParamRef.current = {
          ...limitOffsetParam,
          isFull: newList.length < limit,
        };
      })
      .catch((err) => {
        console.log(err);
        limitOffsetParamRef.current = limitOffsetParam;
      });
    if (!isMoreLoad) searchRef.current = search;
  }, [search]);

  const tmpMoreLoadFunc = throttle(() => getHistories(true), 1000);
  const moreLoadFunc = useCallback(tmpMoreLoadFunc, [search]);

  const searchFunc = () => {
    limitOffsetParamRef.current = {
      limit: 100,
      offset: 0,
      isFull: false,
    };
    getHistories();
    rerender();
  };

  useEffect(() => {
    getHistories();
  }, [customer]);

  return (
    <InfoChangeHistoryWrapper
      histories={histories}
      getHistories={getHistories}
      moreLoadFunc={moreLoadFunc}
      search={search}
      setSearch={setSearch}
      resetFunc={resetFunc}
      refreshing={refreshing}
      onRefresh={onRefresh}
      searchFunc={searchFunc}
      rerenderKey={rerenderKey}
    />
  );
}

export default InfoChangeHistoryContainers;
