import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Add, Folder, Person } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import getTargetFunction from './commonTreeFunction';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  fadeContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '100%',
  },
  root: {
    width: '100%',
    maxHeight: '100%',
    // overflow: 'auto',
    padding: '0 0 16px 0',
  },
  parent: {
    position: 'relative',
    padding: 0,
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 1,
  },
  li: {
    padding: '0 0 0 16px',
    display: 'block',
    background: '#fff',
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    margin: 0,
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  countText: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
    color: '#A8A8A8',
  },
  checkedUser: {
    color: theme.palette.primaryColor,
  },
  // 戻るボタン用
  button: {
    padding: '18px 16px',
    width: '100%',
    background: '#fff',
    justifyContent: 'flex-start',
    margin: '16px 0',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  searchUserItem: {
    padding: 0,
  },
  searchUserWrap: {
    width: '100%',
  },
  searchUserTitle: {
    padding: 16,
  },
  searchUserList: {
    padding: 0,
  },
  result: {
    padding: '40px 16px',
    textAlign: 'center',
    color: '#8C8C8C',
    fontSize: 15,
  },
  allCheckBtn: {
    marginTop: 0,
    marginBottom: 16,
  },
}));

function TreeForMultiSp(props) {
  const common = commonStyles();
  const classes = useStyles();
  const {
    list, selectUser, setSelectUser, search, state, setState,
    checked, setChecked, displayDivision,
    setDisplayDivision, displayDivisionRoot, setDisplayDivisionRoot, searchedUserList,
  } = props;

  // ローカルステート
  const [checkedAll, setCheckedAll] = useState([]);
  const [resultDivisionIds, setResultDivisionIds] = useState([]);
  const [divHierarchy, setDivHierarchy] = useState({});
  const [countUser, setCountUser] = useState({});

  // RootDivisionから最下層までのDivisionをまとめる
  const getFromRootDivision = () => {
    const resultObj = {};
    let resultArray = [];

    const getDivisionIds = (arr = []) => {
      for (let i = 0; arr.length > i; i += 1) {
        resultArray.push(arr[i].divisionId);
        if ('children' in arr[i]) {
          if (arr[i].children.length > 0) {
            getDivisionIds(arr[i].children);
          }
        }
      }
    };

    for (let i = 0; list.length > i; i += 1) {
      const root = list[i].divisionId;
      resultArray.push(list[i].divisionId);
      getDivisionIds(list[i].children);
      resultObj[root] = resultArray;
      resultArray = [];
    }
    setDivHierarchy(resultObj);
  };

  // commonTree/treeWindow.jsにある”全クリア”が押されたとき、selectUser(result.multi)から値が消されるので、
  // こっちのチェックも外すための処理↓
  useEffect(() => {
    if (selectUser.length < 1) {
      setChecked([]); // チェックを外す
    }
  }, [selectUser]);

  useEffect(() => {
    // 既存のチェックされているユーザー
    const setList = selectUser.map(s => `${s.userId}_${s.userDivisionId}`);
    setChecked(setList);
    // 組織のdivisionIdをroot階層から並べる
    getFromRootDivision();
  }, []);

  useEffect(() => {
    const { result = {} } = search;
    // fix: Cannot read properties of undefined (reading 'count')
    if (result?.count && search.word) {
      const obj = result.list[search.index];
      setState({ ...state, [`divi${obj.userDivisionId}Open`]: true, [`divi${obj.isMainDivision}Open`]: true });
      setChecked([...checked, `${obj.userId}_${obj.userDivisionId}`]);
      setSelectUser([...selectUser, obj]);
    }
  }, [search]);

  // Root組織配下何人選択してるか判定
  useEffect(() => {
    const copyObj = {};
    for (let i = 0; checked.length > i; i += 1) {
      const targetDivision = checked[i].split('_')[1];
      if (targetDivision) {
        Object.keys(divHierarchy).forEach((key) => {
          if (divHierarchy[key].includes(Number(targetDivision))) {
            if (copyObj[key]) {
              copyObj[key] += 1;
            } else {
              copyObj[key] = 1;
            }
          }
        });
      }
    }
    setCountUser(copyObj);
  }, [checked]);

  const getFirstList = (allList) => {
    const firstList = [];
    for (let i = 0; i < allList.length; i += 1) {
      firstList.push({
        levelCode: allList[i].levelCode,
        divisionId: allList[i].divisionId,
        groupName: allList[i].groupName,
        subName: allList[i].subName,
        users: allList[i].users,
      });
    }
    return firstList;
  };

  const selectDivision = (divisionId, backVal) => {
    const resultDivision = getTargetFunction(list, divisionId, null);
    let result = [];
    if (resultDivision.divisions[0].children) {
      result = resultDivision.divisions[0].children.concat(resultDivision.divisions[0].users);
    } else if (resultDivision.divisions[0].users) {
      result = resultDivision.divisions[0].users;
    }

    if (backVal) {
      const resultArray = [];
      for (let i = 0; i < displayDivisionRoot.length; i += 1) {
        if (backVal.includes(displayDivisionRoot[i].divisionId)) {
          resultArray.push(displayDivisionRoot[i]);
        }
      }
      setDisplayDivisionRoot(resultArray);
    } else {
      setDisplayDivisionRoot([...displayDivisionRoot, resultDivision.divisions[0]]);
    }
    setDisplayDivision(result);
  };

  // フォルダー配下のuserを取得
  const getTargetUserId = (userArr, delFlg) => {
    const listA = checked.map((data) => data);
    const divIds = resultDivisionIds.map((data) => data);
    // userIdがCheckされたら以下
    function findId(userList, i) {
      for (const a in userList[i].users) {
        const id = `${userList[i].users[a].userId}_${userList[i].users[a].userDivisionId}`;
        const judgeDuplicate = listA.indexOf(id);
        if (listA.includes(id) && judgeDuplicate > -1 && delFlg) {
          listA.splice(judgeDuplicate, 1);
        } else if (!delFlg) {
          listA.push(id);
        }
      }
    }

    const getBottomLayerDivision = (childrenList) => {
      // 現在の組織を先にpush
      const thisDivision = displayDivisionRoot.slice(-1)[0];
      if (!divIds.includes(thisDivision.divisionId) && !delFlg) {
        divIds.push(thisDivision.divisionId);
      } else if (delFlg) {
        const index = divIds.indexOf(thisDivision.divisionId);
        divIds.splice(index, 1);
      }

      // 現在以降の組織をpush
      for (let i = 0; childrenList.length > i; i += 1) {
        const index = divIds.indexOf(childrenList[i].divisionId);
        delFlg ? divIds.splice(index, 1) : divIds.push(childrenList[i].divisionId);
        if (childrenList[i].children) {
          getBottomLayerDivision(childrenList[i].children);
        }
      }
      setResultDivisionIds(divIds);
    };

    if (divIds.includes(displayDivisionRoot.slice(-1)[0].divisionId) && delFlg) {
      const index = divIds.indexOf(displayDivisionRoot.slice(-1)[0].divisionId);
      divIds.splice(index, 1);
    } else if (!divIds.includes(displayDivisionRoot.slice(-1)[0].divisionId) && !delFlg) {
      divIds.push(displayDivisionRoot.slice(-1)[0].divisionId);
    }

    if (userArr instanceof Array) {
      for (let i = 0; i < userArr.length; i += 1) {
        if (userArr[i].children instanceof Array) {
          for (let e = 0; e < userArr[i].children.length; e += 1) {
            if (!divIds.includes(userArr[i].divisionId) && !delFlg) {
              divIds.push(userArr[i].divisionId);
            } else if (delFlg) {
              const index = divIds.indexOf(userArr[i].divisionId);
              divIds.splice(index, 1);
            }
            findId(userArr[i].children, e);
          }
          getBottomLayerDivision(userArr[i].children);
        } else if (userArr[i].users instanceof Array) {
          findId(userArr, i);
          const index = divIds.indexOf(userArr[i].divisionId);
          if (delFlg && index > -1) {
            divIds.splice(index, 1);
          } else if (!delFlg) {
            divIds.push(userArr[i].divisionId);
          }
        } else {
          const id = `${userArr[i].userId}_${userArr[i].userDivisionId}`;
          const judgeDuplicate = listA.indexOf(id);
          if (delFlg && listA.includes(id) && judgeDuplicate > -1) {
            listA.splice(judgeDuplicate, 1);
          } else if (!delFlg) {
            listA.push(id);
          }
        }
      }
      if (divIds) {
        setResultDivisionIds(divIds);
        setCheckedAll(divIds);
      }
    }
    setChecked(listA);
  };

  const handleSelectUser = (item) => {
    if (checked.includes(`${item.userId}_${item.userDivisionId}`)) {
      const newList = checked.filter(c => c !== `${item.userId}_${item.userDivisionId}`);
      const newListObject = selectUser.filter(s => `${s.userId}_${s.userDivisionId}` !== `${item.userId}_${item.userDivisionId}`);
      setChecked(newList);
      setSelectUser(newListObject);
    } else {
      setChecked([...checked, `${item.userId}_${item.userDivisionId}`]);
      setSelectUser([...selectUser, item]);
    }
  };

  // すべて選択をクリックした場合
  const handleChange = (e) => {
    getTargetUserId(displayDivision, !e.target.checked);
  };

  const getBackList = async () => {
    const hoge = [];
    for (let i = 0; displayDivisionRoot.length > i; i += 1) {
      if (hoge.includes(displayDivisionRoot[i])) {
        break;
      }
      hoge.push(displayDivisionRoot[i].divisionId);
    }
    if (hoge.length > 1) {
      const resetDivision = hoge[hoge.length - 2];
      if (hoge.includes(resetDivision)) {
        await hoge.splice(hoge.indexOf(resetDivision) + 1);
        await setDisplayDivisionRoot(hoge);
        selectDivision(hoge[hoge.length - 1], hoge);
      } else {
        selectDivision(resetDivision);
      }
    } else {
      setDisplayDivision([]);
      setDisplayDivisionRoot([]);
    }
  };

  // ユーザー用のボタン
  const userLabel = (item) => (
    <Grid
      key={`u${item.userId}`}
      className={`${common.small} ${classes.li} ${classes.person}`}
    >
      <FormControlLabel
        className={`${classes.btn} ${
          checked.includes(`${item.userId}_${item.userDivisionId}`) ? classes.checkedUser : null
        }`}
        control={
          <Checkbox
            color="primary"
            value={`${item.userId}_${item.userDivisionId}`}
            checked={checked.includes(`${item.userId}_${item.userDivisionId}`)}
            onChange={() => { handleSelectUser(item); }}
            className={classes.checkbox}
          />
        }
        label={
          <Grid container alignItems="center">
            <Person className={classes.icon} />
            <Typography className={common.title3}>
              {item.userName}
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
  // 組織選択用のボタン
  const diviButton = (item) => (
    <Grid className={classes.li}>
      <Button
        className={`${common.title3} ${classes.btn}`}
        onClick={() => {
          selectDivision(item.divisionId);
        }}
      >
        <Folder className={classes.icon} />
        <Typography
          className={common.title3}
        >
          {item.subName}
        </Typography>
        <Typography className={classes.countText}>
          {countUser[item.divisionId] ? `...${countUser[item.divisionId]}名選択中` : ''}
        </Typography>
        <Add className={classes.addButtonIcon} />
      </Button>
    </Grid>
  );

  const userList = (arr) => {
    const userlist = [];
    if ('userId' in arr) {
      userlist.push(
        userLabel(arr),
      );
    } else {
      arr.users.map((u) => {
        return userlist.push(
          userLabel(u),
        );
      });
    }
    return userlist;
  };

  const childList = (c) => {
    const childlist = [];
    childlist.push(
      <Grid key={`divi${c.divisionId}`} className={classes.parent}>
        {diviButton(c)}
      </Grid>,
    );
    return childlist;
  };

  const getListItem = (sortUserList, initFlg = false) => {
    let firstFlg = initFlg;
    let loopItems = null;
    if (displayDivision.length > 0) {
      loopItems = displayDivision;
    } else if (!sortUserList) {
      firstFlg = true;
      loopItems = getFirstList(list);
    } else {
      return <Typography className={classes.result}>0件</Typography>;
    }
    if (firstFlg) {
      return loopItems.map((data) => (
        <div key={data.subName} className={classes.parent}>
          {diviButton(data)}
        </div>
      ));
    // 検索を実行後、表示させるリスト
    } else if (sortUserList) {
      return (
        sortUserList.map((item) => {
          const initialData = item.value; // data
          const findUser = initialData.map((o) => {
            const result = o.data.divisions[0].users.filter(
              (u) => u.userId === o.userId,
            ); // userIdが一致するか
            return result;
          });

          return (
            <Grid className={classes.searchUserItem} key={uuid()}>
              <Grid className={classes.searchUserWrap}>
                <Typography
                  className={`${common.title3} ${classes.searchUserTitle}`}
                >
                  {initialData[0].data.divisions[0].groupName}
                </Typography>
                {findUser && (
                  <Grid className={classes.searchUserList}>
                    {findUser.map((data) => (
                      userLabel(data[0])
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })
      );
    } else {
      const resultList = loopItems.map((data) => (
        'userId' in data ? userList(data) : childList(data)
      ));

      return (
        <>
          {resultList.map((item) => (
            <Grid key={uuid()}>
              {item}
            </Grid>
          ))}
        </>
      );
    }
  };

  return (
    <>
      {displayDivisionRoot.length > 0 && (
        // 戻るボタン
        <Button
          className={classes.button}
          onClick={getBackList}
        >
          <ArrowBackIosIcon className={classes.backIcon} />
          <Typography className={`${classes.text} ${common.title3}`}>{displayDivisionRoot.slice(-1)[0].groupName}</Typography>
        </Button>
      )}
      <Grid className={`${classes.root}`}>
        {searchedUserList ? (
        // 検索後
          getListItem(displayDivision)
        // 検索前
        ) : (
          <>
            {displayDivision.length > 0 && (
              <Grid
                className={`${common.small} ${classes.li} ${classes.allCheckBtn}`}
              >
                <FormControlLabel
                  className={`${classes.btn}`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkedAll.includes(displayDivisionRoot.slice(-1)[0].divisionId)}
                      onChange={handleChange}
                      className={classes.checkbox}
                    />
                  }
                  label={
                    <Grid container alignItems="center">
                      <Folder className={classes.icon} />
                      <Typography
                        className={common.title3}
                      >
                        フォルダー配下 すべて
                      </Typography>
                    </Grid>
                  }
                />
              </Grid>
            )}
            {getListItem()}
          </>
        )}
      </Grid>
    </>
  );
}

export default TreeForMultiSp;
