import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles({
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  listCheckBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    margin: 0,
    '& .MuiIconButton-root': {
      order: 2,
    },
    '& .MuiTypography-root': {
      order: 1,
      fontSize: 13,
    },
  },
});

function EditDialog1(props) {
  const { control, lbl } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.listCheckBox}
      control={control}
      label={lbl}
    />
  );
}

export default EditDialog1;
