import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { CUSTOMER_COLUNM_MAP } from '../../../../constants/infoChangeHistory';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 16px 20px 24px',
    borderBottom: '1px solid #C8C8C8',
  },
  historyTop: {
    display: 'flex',
    alignItems: 'center',
  },
  historyItem: {
    position: 'relative',
    padding: '8px 8px 8px 20px',
    borderBottom: '1px solid #C8C8C8',
  },
  historyTxt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
  },
  historyDate: {
    marginRight: 16,
  },
  historyTel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    '& .MuiSvgIcon-root': {
      fontSize: 11,
      color: theme.palette.primaryColor,
      marginRight: 1,
    },
  },
  historyBottom: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  historyArrow: {
    display: 'block',
    fontSize: 10,
    color: '#8C8C8C',
    margin: '0 6px -1px 0',
  },
  historyPrev: {
    marginRight: 6,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&.active': {
      overflow: 'auto',
      whiteSpace: 'normal',
    },
  },
  historyRearWrap: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  historyRear: {
    fontWeight: 700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&.active': {
      overflow: 'auto',
      whiteSpace: 'normal',
    },
  },
  historyBtn: {
    position: 'absolute',
    top: 10,
    left: 4,
    width: 10,
    height: 10,
    minWidth: 'auto',
    padding: 0,
    borderRadius: 0,
    '&::before': {
      content: '""',
      display: 'block',
      background: theme.palette.primaryColor,
      width: 1,
      height: 7,
      position: 'absolute',
      top: 2,
      left: 4,
      transition: 'all 0.5s ease',
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: theme.palette.primaryColor,
      width: 7,
      height: 1,
      position: 'absolute',
      top: 5,
      left: 1,
    },
    '&.active::before': {
      opacity: 0,
    },
  },
}));

function InfoChangeHistoryItem(props) {
  const {
    history,
  } = props;

  const classes = useStyles();

  const [active, setActive] = useState(false);
  const classToggle = () => {
    setActive(!active);
  };

  const getLabel = (historyObj) => {
    return historyObj.columnName in CUSTOMER_COLUNM_MAP
      ? CUSTOMER_COLUNM_MAP[historyObj.columnName]
      : historyObj.columnName;
  };

  const beforeValue = history.conversionBeforeValue
    ? history.conversionBeforeValue
    : history.beforeValue;
  const afterValue = history.conversionAfterValue
    ? history.conversionAfterValue
    : history.afterValue;

  return (
    <Grid className={classes.historyItem}>
      <Grid className={classes.historyTop}>
        <Typography className={`${classes.historyTxt} ${classes.historyDate}`}>
          {history.createdAt.substr(0, 16)}
        </Typography>
        <Typography className={`${classes.historyTxt} ${classes.historyTel}`}>
          {getLabel(history)}
        </Typography>
        <Typography className={`${classes.historyTxt} ${classes.history}`}>
          {history.userLastName} {history.userFirstName}
        </Typography>
      </Grid>
      <Grid className={classes.historyBottom}>
        <Typography
          className={`${classes.historyTxt} ${classes.historyPrev} ${active ? 'active' : ''}`}
        >
          {beforeValue}
        </Typography>
        <Grid className={classes.historyRearWrap}>
          <ArrowForwardIcon className={classes.historyArrow} />
          <Typography
            className={`${classes.historyTxt} ${classes.historyRear} ${active ? 'active' : ''}`}
          >
            {afterValue}
          </Typography>
        </Grid>
      </Grid>
      {afterValue && afterValue.length > 32 && (
        <Button
          className={`${classes.historyBtn} ${active ? 'active' : ''}`}
          onClick={classToggle}
        >
          {/*  */}
        </Button>
      )}
    </Grid>
  );
}

export default InfoChangeHistoryItem;
