import client from '../httpClient';

/**
 * 【combination-setting003】組み合わせ設定削除
 * http://10.22.2.48:8080/docs/#/combinationSetting/delete-combination-settings
 * @param {{
 * areaCode: number;
 * patternId: number;
 * }} data
 * @returns Promise<*>
 */
const combinationSettingDeleteApi = async (params) => {
  const res = await client.delete('/combination-settings', { params });
  return res.data;
};

export default combinationSettingDeleteApi;
