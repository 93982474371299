import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerUpdateShareSlice = createSlice({
  name: 'customerUpdateShare',
  initialState: { customerUpdateShare: initialState },
  reducers: {
    updateCustomerShare: (state, data) => {
      state.customerUpdateShare = data.payload;
    },
  },
});

export const { updateCustomerShare } = customerUpdateShareSlice.actions;

export default customerUpdateShareSlice.reducer;
