import CustomerMain from '../customerMain';
import { CustomerMainProvider } from '../../containers/customerMain/customerMainContext';

function CustomerMainProviderWrap(props) {
  const { children } = props;

  return (
    <CustomerMainProvider>
      {children}
    </CustomerMainProvider>
  );
}

function CustomerMainPage() {
  return (
    <CustomerMainProviderWrap>
      <CustomerMain />
    </CustomerMainProviderWrap>
  );
}

export default CustomerMainPage;
