import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import customerCallApi from '../../apis/customer/customerApi';
import JoinDataWrapper from '../../components/pages/joinData/joinData';
import webResGetResponsesApi from '../../apis/webRes/webResGetResponsesApi';
import convertCustomerData from './convertCustomerData';
import { JOIN_DATA_TYPES } from '../../constants/crm';
import { CUSTOMER_STATUS_CODE } from '../../constants/apiParameterCode';
import { crmPageActions } from '../../store/crmPage';
import { useLoading } from '../../hooks';

function JoinDataContainers(props) {
  const {
    // open,
    // setOpen,
    parentResponseDataId,
    childResponseDataId,
    operation,
  } = props;
  const [customersDataParent, setCustomersDataParent] = useState();
  const [customersDataChild, setCustomersDataChild] = useState();
  const [getDataAt, setGetDataAt] = useState();
  const { addLoading, removeLoading } = useLoading();

  const dispatch = useDispatch();

  // customerDataをセットする。
  const getCustomerData = async () => {
    addLoading();
    if (operation === JOIN_DATA_TYPES.MERGE) {
      // 結合
      const customersData = await customerCallApi([
        parentResponseDataId,
        childResponseDataId,
      ]);
      customersData.data.customers.forEach((customerData) => {
        if (parentResponseDataId === customerData.customerId) {
          setCustomersDataParent(customerData);
        } else {
          setCustomersDataChild(customerData);
        }
      });
    } else {
      // 合わせ登録 戻し
      const [response, customerData] = await Promise.allSettled([
        webResGetResponsesApi(childResponseDataId),
        customerCallApi([parentResponseDataId]),
      ]);

      if (customerData.value?.data.customers.length !== 1 || !response.value) {
        // エラー表示
        dispatch(crmPageActions.setJoinData());
        removeLoading();
        return;
      }

      // webRes015からのresponseをcustomer001のレスポンスに合わせる
      const customerData2 = convertCustomerData(response.value.data.responses);
      // Parentデータ加工
      const parentData = customerData.value.data.customers[0];
      // Web再反登録時
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2345#comment-165084260
      if (operation === JOIN_DATA_TYPES.REBELLION) {
        // effectAtデフォルトで本日のづけを設定する
        parentData.effectAt = moment().format('YYYY/MM/DD');
        // 担当者はデフォルトで空欄
        // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-6059
        parentData.userId = 0;
        parentData.userLastName = ''; // full name
        parentData.userFirstName = '';
        parentData.divisionId = undefined;
        parentData.divisionSubName = '';
        // 追わない 2 → 追客中 1
        if (parentData.customerStatusCode === CUSTOMER_STATUS_CODE.NOT_CHASE) {
          parentData.customerStatusCode = CUSTOMER_STATUS_CODE.CHASING;
        }
      }
      setCustomersDataParent(parentData);
      setCustomersDataChild(customerData2);
    }
    removeLoading();
    setGetDataAt(moment().format('YYYY/MM/DD HH:mm:ss'));
  };
  useEffect(() => {
    if (parentResponseDataId && childResponseDataId) {
      getCustomerData();
    }
  }, [parentResponseDataId, childResponseDataId]);

  return (
    <JoinDataWrapper
      // open={open}
      // setOpen={setOpen}
      customersDataParent={customersDataParent}
      customersDataChild={customersDataChild}
      operation={operation}
      getDataAt={getDataAt}
    />
  );
}

export default JoinDataContainers;
