import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';
import { useActionSchedule } from '../../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles({
  button: {
    padding: '4px 10px',
    minWidth: 108,
  },
});

function SubmitButton(props) {
  const {
    txt,
    handleCloseSearch,
  } = props;

  const {
    searchInput,
  } = useActionSchedule();

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const handleClick = () => {
    if (isSP) {
      handleCloseSearch();
    }
  };

  return (
    <Button
      className={`${classes.button} ${baseClasses.buttonsPrimary}`}
      type="submit"
      onClick={() => {
        searchInput.searchStart();
        handleClick();
      }}
    >
      {
        txt
      }
    </Button>
  );
}
export default SubmitButton;
