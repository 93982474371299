import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.6,
    letterSpacing: 0,
    color: '#000',
    textAlign: 'left',
    padding: '6px 24px 7px 0',
    display: 'block',
    width: '100%',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  dialog: {
    '& > .MuiDialog-container': {
      display: 'block',
      '& > .MuiPaper-root': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'none',
        boxShadow: 'none',
        margin: 0,
        display: 'block',
        overflow: 'initial',
      },
    },
    '& > .MuiBackdrop-root': {
      background: 'none',
    },
  },
  list: {
    position: 'absolute',
    background: '#fff',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    borderRadius: 4,
  },
  listItem: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.6,
    letterSpacing: 0,
    color: '#000',
    textAlign: 'left',
    padding: '6px 37px 6px 16px',
    whiteSpace: 'nowrap',
  },
  listItemSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}));

function SimpleDialog(props) {
  const { onClose, selectedValue, open, data, dialogPosition, classes } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.dialog}
    >
      <List
        className={classes.list}
        style={{ top: dialogPosition.top, left: dialogPosition.left }}
      >
        {data.menuKeys.map((item) => (
          <ListItem
            button
            onClick={() => handleListItemClick(item)}
            key={item}
            className={`${classes.listItem} ${String(selectedValue) === item ? classes.listItemSelected : ''}`}
          >
            {data.select[item]}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function CustomOriginalSelect(props) {
  const { data, changeValue, hasClearBotton = false } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(data.val);
  const [dialogPosition, setDialogPosition] = useState({
    top: 0,
    left: 0,
  });
  const buttonRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    if (selectedValue !== value) {
      setSelectedValue(value);
      changeValue(value, data.index, data.key, data.type);
    }
  };

  useEffect(() => {
    if (open) {
      const buttonElement = buttonRef.current;
      const rect = buttonElement.getBoundingClientRect();
      setDialogPosition({
        ...dialogPosition,
        top: rect.y,
        left: rect.x,
      });
    }
  }, [open]);

  // クリア押下時に表示を消すため
  useEffect(() => {
    if (hasClearBotton && selectedValue !== data.val) setSelectedValue(data.val);
  }, [data.val]);

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClickOpen}
        ref={buttonRef}
      >
        {data.select[selectedValue]}
        <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z" /></svg>
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        data={data}
        dialogPosition={dialogPosition}
        classes={classes}
      />
    </>
  );
}
