// import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box, Grid, makeStyles, FormControlLabel, Checkbox, Toolbar } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import AreaSentakuItem from './areaSentakuItem';

import { useStoreGroupsSettingsAreas } from '../../../combinationSetting/common/useStoreGroupsSettingsAreas';
import { useLoading } from '../../../../hooks';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      minHeight: 229,
    },
  },
  bar: {
    boxShadow: 'none',
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      padding: '0 16px',
      '&.lower': {
        marginBottom: 18,
      },
    },
    '& .MuiToolbar-root': {
      padding: 0,
      minHeight: 'auto',
    },
    '& .MuiFormControlLabel-root': {
      whiteSpace: 'nowrap',
      '& .Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& .Mui-checked + .MuiTypography-root': {
        color: theme.palette.primaryColor,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22,
      },
    },
  },
  tabs: {
    width: '100%',
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
    '& .MuiTabs-flexContainer': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
    '& .MuiTab-root': {
      whiteSpace: 'nowrap',
      minWidth: 'auto',
      padding: 8,
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontSize: 13,
    },
  },
  checkbox1: {
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 90,
    },
  },
  checkbox2: {
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 140,
    },
  },
  content: {
    [commonTheme.breakpoints.up('md')]: {
      background: '#fff',
    },
    '& .MuiBox-root': {
      [commonTheme.breakpoints.down('sm')]: {
        padding: 0,
      },
      [commonTheme.breakpoints.up('md')]: {
        padding: 8,
      },
    },
  },
}));

function AreaSentakuTabs(props) {
  const classes = useStyles();
  const {
    areaTabIndex,
    areas,
    lower,
    setLower,
    onChangeArea,
    onChangeChecked,
    onSelectNoneChecked,
    isNoneWishArea,
    isFromSearch,
  } = props;
  const { hasLoading } = useLoading();
  const { areaList } = useStoreGroupsSettingsAreas();
  const areaMap = {};
  areaList.forEach((area) => {
    areaMap[area.areaCode] = area.areaName;
  });
  const common = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const handleChange = (event, newValue) => {
    // 下層の表示をリセット
    setLower(false);
    onChangeArea(newValue);
  };

  // チェックボックス単体のチェック状態を取得
  const isChecked = (param) => {
    const currentArea = areas[param.areaTabIndex];
    const index = currentArea.wish.findIndex(({ id }) => param.id === id);
    return index !== -1;
  };

  // チェックボックスのエリア単位のチェック状態を取得
  const isCheckedArea = (param) => {
    const currentArea = areas[param.areaTabIndex];
    const valAreas = currentArea.val[param.index].state.data.areas;
    const wishAreas = currentArea.wish.filter(({ index }) => param.index === index);
    return valAreas.length === wishAreas.length;
  };

  return (
    <Grid className={`${classes.root} ${!isSp && hasLoading('Area') ? common.loading : ''}`}>
      <AppBar position="static" className={`${classes.bar} ${isFromSearch ? classes.checkbox2 : classes.checkbox1} ${lower && 'lower'}`}>
        <Toolbar>
          <Tabs
            value={areaTabIndex}
            onChange={handleChange}
            className={classes.tabs}
          >
            {Object.entries(areaMap).map(([key, val], index) => (
              <Tab
                key={key}
                label={val}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {!isSp && isFromSearch && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNoneWishArea}
                  onChange={onSelectNoneChecked}
                  color="primary"
                />
              }
              label="希望地域無し"
            />
          )}
        </Toolbar>
      </AppBar>
      {areas.map((list, i) => (
        <TabPanel
          key={list.id}
          value={areaTabIndex}
          index={i}
          className={classes.content}
        >
          {list.val.map((list2, j) => (
            <AreaSentakuItem
              key={list2.id}
              list={list2}
              index={j}
              lower={lower}
              setLower={setLower}
              onChangeChecked={onChangeChecked}
              wish={list.wish}
              areaTabIndex={areaTabIndex}
              isChecked={isChecked}
              isCheckedArea={isCheckedArea}
            />
          ))}
        </TabPanel>
      ))}
    </Grid>
  );
}

export default AreaSentakuTabs;
