import { useEffect, useState } from 'react';
import libraryGetInterruptedApi from '../../apis/library/libraryGetInterruptedApi';
import libraryUpdateApi from '../../apis/library/libraryUpdateApi';
import sendmailTestApi from '../../apis/sendmail/sendmailTestApi';
import AfterCallSettingComponent from '../../components/mail/afterCallSettingComponent';
import store from '../../store';
import { validateJsonSize } from '../../commonFunction/validations';
import { isOpen } from '../../store/common/apiMessageSlice';

export default function AfterCallSettingContainer(props) {
  const { open, onClose } = props;
  const { responseHeader } = store.getState();
  const [uploadedList, setUploadedList] = useState([]); // アップロードした配列のAPIが返ってくるstate
  const [editorState, setEditorState] = useState('');
  // 各選択肢。ライブラリはGETしたものを入れる
  const [mailSetting, setMailSetting] = useState({
    type: {
      constants: {
        menus: {
          0: 'SMS',
          1: 'メール',
        },
      },
    },
    kind: {
      // eslint-disable-next-line max-len
      // ここの文言は/libraries/management/{libraryId}のlibraryNameと対応する（本番ではlibraryNameから引用するが、WIPの状態なので定数参照）
      constants: {
        menus: {},
      },
    },
    kindSms: {
      // eslint-disable-next-line max-len
      // ここの文言は/libraries/management/{libraryId}のlibraryNameと対応する（本番ではlibraryNameから引用するが、WIPの状態なので定数参照）
      constants: {
        menus: {},
      },
    },
    from: {
      constants: {
        menus: {
          1: '自分',
          2: '担当課員',
          3: 'info',
        },
        title: 'From',
      },
    },
    sign: {
      constants: {
        menus: {
          0: '署名１',
          1: '署名２',
        },
        title: 'sign',
      },
    },
    info: {
      constants: {
        menus: {
          1: '@INFO',
          2: 'オープンハウス事務局',
        },
        title: 'info',
      },
    },
  });
  const [mailMeta, setMailMeta] = useState({});
  const [smsMeta, setSmsMeta] = useState({});
  useEffect(() => {
    setMailSetting({
      ...mailSetting,
      kind: { constants: { menus: mailMeta } },
      kindSms: { constants: { menus: smsMeta } },
    });
  }, [mailMeta, smsMeta]);
  const [meta, setMeta] = useState({
    type: 0,
    kind: 0,
    kindSms: 0,
    title: '',
    content: '',
  });

  // ライブラリの変更を監視する
  const [watchEdit, setWatchEdit] = useState(false);

  // ライブラリ更新用パラメータ
  const [updateParam, setUpdateParam] = useState({});
  // APIでとってきたライブラリをもっておく
  const [mailLibrary, setMailLibrary] = useState({});
  const [smsLibrary, setSmsLibrary] = useState({});
  const [libraries, setLibraries] = useState({
    mail: mailLibrary,
    sms: smsLibrary,
  });

  // mailLibrary, smsLibrary変更時
  useEffect(() => {
    setLibraries({
      mail: mailLibrary,
      sms: smsLibrary,
    });
    setWatchEdit(false);
  }, [mailLibrary, smsLibrary]);

  // テスト送信パラメータ
  const [testMailParam, setTestMailParam] = useState({
    mailSubject: '',
    mailBody: editorState,
    isNotSetCustomer: 1,
    mailFromCode: 1,
  });

  // 各ミニコンポーネント用設定
  const setting = {
    type: {
      label: '送信種別',
      state: meta.type,
      setState: (e) => {
        const id = Number(e.target.value);
        if (id === 1) {
          setUpdateParam({
            libraryName: libraries.mail[meta.kind].libraryName,
            libraryDirectoryId: libraries.mail[meta.kind].libraryDirectoryId,
            mailSubject: libraries.mail[meta.kind].mailSubject,
            userId: responseHeader.userId,
            attachments: [],
          });
          if (libraries.mail[meta.kind].attachments !== '') {
            setUploadedList(libraries.mail[meta.kind].attachments);
          }
          setEditorState(libraries.mail[meta.kind].mailBody);
          setMeta({
            ...meta,
            type: id,
            title: libraries.mail[meta.kind].mailSubject,
            content: libraries.mail[meta.kind].mailBody,
          });
          return setTimeout(() => {
            setWatchEdit(false);
          }, 500);
        }
        setUploadedList([]);
        setUpdateParam({
          libraryName: libraries.sms[meta.kindSms].libraryName,
          libraryDirectoryId: libraries.sms[meta.kindSms].libraryDirectoryId,
          mailBody: libraries.sms[meta.kindSms].mailBody,
          userId: responseHeader.userId,
        });
        setMeta({
          ...meta,
          type: id,
          content: libraries.sms[meta.kindSms].mailBody,
        });
        return setTimeout(() => {
          setWatchEdit(false);
        }, 500);
      },
      constants: mailSetting.type.constants,
      style: { width: '24.5%' },
    },
    kind: {
      label: '種類',
      state: meta.kind,
      setState: (e) => {
        if (libraries.mail[e].attachments !== '') {
          setUploadedList(libraries.mail[e].attachments);
        }
        setTestMailParam({ ...testMailParam, libraryId: Number(e) });
        return setMeta({ ...meta, kind: Number(e) });
      },
      constants: mailSetting.kind.constants,
      style: { width: '74.5%' },
    },
    kindSms: {
      label: '種類',
      state: meta.kindSms,
      setState: (e) => {
        setUploadedList([]);
        return setMeta({ ...meta, kindSms: Number(e) });
      },
      constants: mailSetting.kindSms.constants,
      style: { width: '74.5%' },
    },
    title: {
      label: '件名',
      state: meta.title,
      setState: (e) => {
        setTestMailParam({ ...testMailParam, mailSubject: e });
        if (e !== updateParam.mailSubject) {
          setWatchEdit(true);
        }
        return setMeta({ ...meta, title: e });
      },
      style: { width: '100%' },
      maxLengthInt: 250,
      require: true,
    },
    content: {
      state: meta.content,
      setState: (e) => {
        if (e !== updateParam.mailBody) {
          setWatchEdit(true);
        }
        return setMeta({ ...meta, content: e });
      },
      wrapper: 'small',
      toolbar: true,
    },
    contentSms: {
      state: meta.content,
      setState: (e) => {
        if (e !== updateParam.mailBody) {
          setWatchEdit(true);
        }
        return setMeta({ ...meta, content: e });
      },
      wrapper: 'full',
      toolbar: false,
    },
  };

  // 不通時ライブラリ取得（SMS）
  const getLibrarySms = async (userId) => {
    let resultLibraries;
    await libraryGetInterruptedApi(userId, 0)
      .then((res) => {
        const addObj = {};
        const librariesList = {};
        if (res.data.total !== 0) {
          res.data.libraries.map((l, i) => {
            if (i === 0 && meta.kindSms === 0) {
              resultLibraries = l;
            }
            addObj[l.libraryId] = l.libraryName;
            librariesList[l.libraryId] = l;
            return false;
          });
        }
        setSmsLibrary(librariesList);
        setSmsMeta(addObj);
      })
      .catch(err => console.error(err));
    return resultLibraries;
  };

  // 不通時ライブラリ取得（メール）
  const getLibraryMail = async (userId) => {
    let resultLibraries;
    await libraryGetInterruptedApi(userId, 1)
      .then((res) => {
        const addObj = {};
        const librariesList = {};
        if (res.data.total !== 0) {
          res.data.libraries.map((l, i) => {
            if (i === 0 && meta.kind === 0) {
              resultLibraries = l;
            }
            addObj[l.libraryId] = l.libraryName;
            librariesList[l.libraryId] = l;
            return false;
          });
        }
        setMailLibrary(librariesList);
        setMailMeta(addObj);
      })
      .catch(err => console.error(err));
    return resultLibraries;
  };

  // テストメール送信
  const sendTestMail = (param) => {
    // サイズチェック
    const error = validateJsonSize(param);
    if (error) {
      store.dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      return;
    }
    sendmailTestApi(param)
      .catch(err => {
        console.error(err);
      });
  };

  // 更新
  const updateSetting = async (params) => {
    // サイズチェック
    const error = validateJsonSize(params);
    if (error) {
      store.dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      return;
    }
    await libraryUpdateApi(meta.type === 0 ? meta.kindSms : meta.kind, params)
      .then(() => {
        getLibraryMail(responseHeader.userId);
        getLibrarySms(responseHeader.userId);
      })
      .catch(err => console.error(err));
  };

  // functionsにまとめる
  const settingFunctions = {
    updateSetting,
    getLibraryMail,
    getLibrarySms,
    sendTestMail,
  };

  return (
    <AfterCallSettingComponent
      open={open}
      onClose={onClose}
      meta={meta}
      setMeta={setMeta}
      setting={setting}
      mailSetting={mailSetting}
      setMailSetting={setMailSetting}
      settingFunctions={settingFunctions}
      testMailParam={testMailParam}
      setTestMailParam={setTestMailParam}
      editorState={editorState}
      setEditorState={setEditorState}
      updateParam={updateParam}
      setUpdateParam={setUpdateParam}
      responseHeader={responseHeader}
      libraries={libraries}
      watchEdit={watchEdit}
      setWatchEdit={setWatchEdit}
      uploadedList={uploadedList}
      setUploadedList={setUploadedList}
    />
  );
}
