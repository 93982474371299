import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useState, useEffect } from 'react';
import CreateWindowContainer from '../../containers/mail/createWindowContainer';

import commonStyles from '../styles';

const useStyles = makeStyles({
  button: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 16px 4px 18px',
    margin: '0 8px',
    '& .MuiButton-startIcon': {
      marginRight: 6,
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 14,
    },
  },
});

function MailCreateWindow(props) {
  const {
    user,
    btnlabel,
    defaultAddressesObj,
    onOpen = () => {},
    onClose = () => {},
    disabled,
    isLocalStorage, // localStorageのデータを使用するかのフラグ
    customerMainFlg = false,
    refleshCallBack,
    isActiveSaveTemplate,
    ...args
  } = props;
  const common = commonStyles();
  const classes = useStyles();
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  const [openFlg, setOpenFlg] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    onOpen();
    if (customerMainFlg) {
      setOpenFlg(true);
    } else {
      setOpen(true);
    }
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    onClose();
    setOpen(false);
    if (customerMainFlg) {
      setOpenFlg(false);
    }
  };
  useEffect(() => {
    if (openFlg && customerMainFlg && user) {
      // 個人の顧客情報を取得後、モーダルを開く
      setOpen(true);
    }
  }, [user]);

  return (
    <>
      <Button
        variant="contained"
        className={`${common.buttonsPrimary} ${classes.button}`}
        onClick={handleOpen}
        disabled={disabled}
      >
        {btnlabel}
      </Button>
      {user && open && (
        <CreateWindowContainer
          user={user}
          handleClose={handleClose}
          defaultAddressesObj={defaultAddressesObj}
          isLocalStorage={isLocalStorage}
          open={open}
          refleshCallBack={refleshCallBack}
          isActiveSaveTemplate={isActiveSaveTemplate}
          {...args}
        />
      )}
    </>
  );
}

export default MailCreateWindow;
