import client from '../httpClient';

// http://10.22.2.48:8080/docs/#/members/get-members-oneclick-search-conditions-member-id
const hpinfoGetOneClickSearchConditionApi = async (params) => {
  try {
    const res = await client.get('/members/oneclick-search-conditions', { params });
    return res.data;
  } catch (e) {
    console.error(e);
    return {
      total: 0,
      conditions: [],
    };
  }
};

export default hpinfoGetOneClickSearchConditionApi;
