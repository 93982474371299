import { Grid, Typography, Button, makeStyles } from '@material-ui/core';
import { classNames } from '../../../commonFunction';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    flexGrow: 1,
    flexWrap: 'wrap',
    padding: '8px 16px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '&:not(:last-child)': { borderBottom: '1px #F3F3F3 solid' },
  },
  whiteBg: {
    background: '#fff',
  },
  label: {
    width: '25%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    '& span': { color: '#D83420' },
  },
  content: {
    width: '75%',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  readOnly: {
    pointerEvents: 'none',
  },
});

export default function ListItem(props) {
  const {
    label,
    required,
    onClick,
    children,
    readOnly,
    disabled,
    transparent,
    style,
    className,
    noBtn,
  } = props;

  const common = commonStyles();
  const classes = useStyles();

  return (
    <Grid
      className={classNames(
        classes.root,
        transparent ? null : classes.whiteBg,
        readOnly ? classes.readOnly : null,
        className,
      )}
      style={style}
    >
      <Typography className={classNames(common.title6, classes.label)}>
        {label}
        {required ? (<span>＊</span>) : null}
      </Typography>
      {noBtn ? children : (
        <Button
          onClick={onClick}
          className={classes.content}
          disabled={disabled}
        >
          {children}
        </Button>
      )}
    </Grid>
  );
}
