import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import commonTheme from '../../../styles/theme';
import TaiouRirekiItemSp from './taiouRirekiItemSp';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '17px 8px 124px 0',
    overflowY: 'auto',
    height: 'calc(100vh - 58px)',
    background: '#fff',
  },
  paper: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(2),
    [commonTheme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      borderRadius: 0,
      paddingBottom: 64,
    },
    '& .MuiTablePagination-root': {
      marginBottom: 12,
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: 'auto',
    },
    '& .MuiTablePagination-caption': {
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
    '& .MuiTablePagination-input': {
      display: 'none',
    },
    '& .MuiTablePagination-actions .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primaryColor,
    },
    '& .MuiTablePagination-actions .MuiIconButton-root.Mui-disabled': {
      color: '#C8C8C8',
    },
    '& .MuiTablePagination-actions .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
  box: {
    display: 'block',
    paddingLeft: 16,
    '& .MuiSvgIcon-root': {
      fontSize: 12,
      color: theme.palette.primaryColor,
      marginBottom: 2,
    },
  },
}));

export default function TaiouRirekiItemsSp(props) {
  const {
    state,
    setState,
    updateHistory,
    deleteHistory,
    getActionHistoryList,
    downloadAttachment,
    search,
    customerId,
    customer,
  } = props;

  const classes = useStyles();

  const [swipe, setSwipe] = useState(null); // スワイプ用
  const [loadingCount, setLoadingCount] = useState(0); // 読み込みした回数
  const [historiesArr, setHistoriesArr] = useState({ histories: [] });

  const element = useRef(null); // スクロール要素

  // コンテンツのスクロール量を検知し、下スクロールで更新カウントを取得
  const handleScroll = () => {
    const elH = element.current.clientHeight;
    const maxScroll = element.current.scrollHeight;
    const scrollTopVal = element.current.scrollTop;
    const loadingElH = 64; // ローディングアイコン分の高さ
    const diff = maxScroll - elH;

    if (diff - scrollTopVal < loadingElH && loadingCount < state.total / 10 - 1) {
      setTimeout(() => {
        const tmpLoadingCount = loadingCount + 1;
        setLoadingCount(tmpLoadingCount);
      }, 550);
    }
  };

  useEffect(() => {
    if (state.histories && state.histories.length > 0) {
      if (loadingCount === 0) setHistoriesArr({ histories: [...state.histories] });
      if (loadingCount > 0) {
        setHistoriesArr((prevState) => {
          return { histories: [...prevState.histories, ...state.histories] };
        });
      }
    }
  }, [state]);

  useEffect(() => {
    if (loadingCount > 0) {
      const { limit, offset, ...restSearch } = search;
      const tmpSearchParam = {
        ...restSearch,
        limit: 10,
        offset: (loadingCount) * 10,
      };
      getActionHistoryList(customerId, tmpSearchParam);
    }
  }, [loadingCount]);

  useEffect(() => {
    // 日付
    let date = '';
    const historyDate = document.getElementsByClassName('historyDate');
    for (let i = 0; i < historyDate.length; i += 1) {
      const id = 'enhanced-table-checkbox-' + i;
      if (date === historyDate[i].textContent) {
        document.getElementById(id).style.display = 'none';
      } else {
        // SP 編集ボタンのサイズ調整
        const target = document.getElementById(id).parentNode.parentNode.nextElementSibling;
        if (target !== null) {
          document.getElementById(id).parentNode.parentNode.nextElementSibling.classList.add('labelDate');
        }
      }
      date = historyDate[i].textContent;
    }
  });

  return (
    <div className={classes.root} ref={element} onScroll={handleScroll}>
      <Paper className={classes.paper}>
        <Grid
          className={classes.box}
        >
          <Grid className="ab">
            {historiesArr.histories.length > 0 && historiesArr.histories.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TaiouRirekiItemSp
                  key={row.actionId}
                  state={state}
                  setState={setState}
                  row={row}
                  index={index}
                  labelId={labelId}
                  swipe={swipe}
                  setSwipe={setSwipe}
                  updateHistory={updateHistory}
                  deleteHistory={deleteHistory}
                  downloadAttachment={downloadAttachment}
                  customerId={customerId}
                  customer={customer}
                />
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
