import { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InfoChangeHistoryItem from './infoChangeHistoryItem';
import commonTheme from '../../../styles/theme';
import commonStyles from '../../../styles';

import { useLoading } from '../../../../hooks';
import { INFO_CHANGE_HISTORY_LOADING } from '../../../../constants/loading';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '0 8px',
      height: 409,
      overflowY: 'scroll',
    },
  },
});

function InfoChangeHistoryItems(props) {
  const { histories, onScroll, rerenderKey } = props;
  const common = commonStyles();
  const classes = useStyles();

  const { hasLoading } = useLoading();
  const isLoading = hasLoading(INFO_CHANGE_HISTORY_LOADING);

  const elementRef = useRef(null);

  const handleScroll = () => {
    const elH = elementRef.current.offsetHeight;
    const maxScroll = elementRef.current.scrollHeight;
    const scrollTopVal = elementRef.current.scrollTop;
    const diff = maxScroll - elH;
    if (diff < scrollTopVal && !isLoading) onScroll();
  };

  return (
    <Grid
      key={rerenderKey}
      className={`${classes.root} ${isLoading ? common.loading : ''}`}
      ref={elementRef}
      onScroll={handleScroll}
    >
      {histories.map((history) => (
        <InfoChangeHistoryItem
          key={history.customerHistoryId}
          history={history}
        />
      ))}
    </Grid>
  );
}

export default InfoChangeHistoryItems;
