import { useState, useEffect } from 'react';
import { DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import MailSelect from '../parts/sp/mailSelect';
import CreateWindowNav from './createWindowNav';
import MailTextfield from '../parts/textfield';
import ChangeToSp from '../parts/sp/changeTo';
import MailTitleAndBody from '../parts/sp/titleAndBody';
import MailAppendFile from '../parts/sp/mailAppendFile';
import Modal from '../../common/modal';

import { setSessionStorage, randomId } from '../../../commonFunction';

import {
  MAIL_SUBJECT,
  MAIL_BODY,
  MAIL_FROM_CODE,
  MAIL_SIGN,
  MAIL_ATTACHMENTS,
} from '../../../constants/sessionStorage';
import { MAIL_SENDING } from '../../../constants/loading';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-scrollPaper': {
      background: '#fff',
    },
  },
  customHeader: {
    background: '#000',
    '& .MuiDialog-paper > .MuiGrid-root > .MuiButtonBase-root': {
      right: 'auto',
      left: 0,
    },
  },
  navList: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  navItem: {
    padding: 0,
    flex: 0,
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      flexWrap: 'wrap',
      whiteSpace: 'pre',
    },
    '&:not(:last-child)': {
      marginRight: '8px',
    },
  },
  navIcon: {
    padding: '4px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiButton-startIcon > svg': {
      fill: theme.palette.primaryColor,
      fontSize: '24px',
    },
    '&.isBook svg': {
      fontSize: '18px',
    },
    '&.isSend svg': {
      fontSize: '18px',
    },
  },
  button: {
    minWidth: 'auto',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    padding: '32px 24px 24px',
    justifyContent: 'space-between',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
  },
  middle: {
    background: '#fff',
    padding: 0,
    borderTop: 'none',
  },
  contents: {
    padding: '12px 16px',
    flex: 1,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  blue: {
    color: theme.palette.primaryColor,
    marginRight: '16px',
  },
}));

function MailCreateWindowComponentSp(props) {
  const {
    open,
    handleClose,
    user,
    mailFunctions,
    editorState,
    setEditorState,
    isTestSent,
    setIsTestSent,
    testMailParam,
    setTestMailParam,
    mailParam,
    setMailParam,
    meta,
    setMeta,
    setting,
    mailBodyKey,
    setMailBodyKey,
    uploadedList,
    setUploadedList,
    isLocalStorage,
    taioRirekiFlg = false,
  } = props;
  const { addLoading, removeLoading } = useLoading();
  const classes = useStyles();
  const common = commonStyles();

  const [isDisabled, setIsDisabled] = useState(true); // 送信先が設定されていない場合、テスト送信ボタンを非活性化
  // ライブラリ選択ツリー用
  const [selectDir, setSelectDir] = useState({});
  const [selectLibrary, setSelectLibrary] = useState({});

  useEffect(() => {
    setTestMailParam?.({
      ...testMailParam,
      mailBody: editorState,
    });
    setMailParam?.({
      ...mailParam,
      mailBody: editorState,
    });
    setMeta?.({ ...meta, content: editorState });
  }, [editorState]);

  // ライブラリ選択時の処理
  const handleSelectLibrary = (library) => {
    setSelectLibrary(library);
    if (Object.keys(library).length > 0) {
      // ライブラリが選択された場合
      setEditorState(library.mailBody);
      const setBaseMailParam = ((s) => ({
        ...s,
        mailSubject: library.mailSubject,
        mailBody: library.mailBody,
      }));
      setTestMailParam(setBaseMailParam);
      setMailParam(setBaseMailParam);
      setMeta((s) => ({
        ...s,
        title: library.mailSubject,
        content: library.mailBody,
      }));
      if (library.attachments[0]) {
        setUploadedList((s) => ([...s, ...library.attachments]));
      }
    } else {
      const content = '<p></p>';
      setEditorState(content);
      setMeta((s) => ({ ...s, title: '' }));
    }
    setMailBodyKey(randomId());
  };

  useEffect(() => {
    const files = uploadedList.map((d) => ({ file: d.file, fileName: d.fileName }));
    setTestMailParam({ ...testMailParam, files });
    setMailParam({ ...mailParam, files });
  }, [uploadedList]);

  const successBaseMailCallBack = (param) => {
    if (!isLocalStorage) return;
    setSessionStorage(MAIL_SUBJECT, param.mailSubject);
    setSessionStorage(MAIL_BODY, param.mailBody);
    setSessionStorage(MAIL_FROM_CODE, param.mailFromCode);
    const signObj = {
      libraryId: param.signatureLibraryId,
    };
    setSessionStorage(MAIL_SIGN, signObj);
    setSessionStorage(MAIL_ATTACHMENTS, param.files);
  };
  const successTesMailCallBack = () => {
    setIsTestSent(true);
    if (!isLocalStorage) return;
    successBaseMailCallBack(testMailParam);
  };
  const failureTestMailCallBack = () => setIsTestSent(false);
  const testSendHandler = () => {
    addLoading(MAIL_SENDING);
    // 送信が成功したら送信ボタン活性
    mailFunctions.sendTestMail(testMailParam, successTesMailCallBack, failureTestMailCallBack);
    // awaitがないとすぐに発火してしまうため2秒間ボタンの連打防止
    setTimeout(() => removeLoading(MAIL_SENDING), 2000);
  };
  const successMailCallBack = () => {
    if (isLocalStorage) return;
    successBaseMailCallBack(mailParam);
  };

  const sendMail = () => {
    addLoading(MAIL_SENDING);
    mailFunctions.sendMail(
      mailParam,
      successMailCallBack,
    );
    handleClose();
    removeLoading(MAIL_SENDING);
  };

  // test送信などの活性・非活性制御
  useEffect(() => {
    const requiredFields = [
      Boolean(setting.to.list),
      Boolean(setting.title.state),
    ];
    setIsDisabled(!requiredFields.every((i) => i));
  }, [setting.to.list, setting.title.state]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.customHeader}
      title={<CreateWindowNav
        handleClose={handleClose}
        values={setting}
        editorState={editorState}
        setEditorState={setEditorState}
        setMailBodyKey={setMailBodyKey}
        uploads={uploadedList}
        setUploads={setUploadedList}
        isDisabled={isDisabled}
        isTestSent={isTestSent}
        testSendHandler={testSendHandler}
        sendMail={sendMail}
        selectDir={selectDir}
        setSelectDir={setSelectDir}
        selectLibrary={selectLibrary}
        setSelectLibrary={handleSelectLibrary}
        taioRirekiFlg={taioRirekiFlg}
      />}
    >
      <DialogContent dividers className={`${common.dialogContent} ${classes.middle}`}>
        <Grid className={classes.dialogContent}>
          <ChangeToSp
            values={setting.to}
            user={user}
            updateFunction={mailFunctions.updateMailAddress}
            disable={taioRirekiFlg}
          />
          <MailSelect values={setting.from} disable={taioRirekiFlg} />
          {meta.from === 3 ? (
            <MailSelect
              values={setting.info}
              disable={taioRirekiFlg}
            />
          ) : ''}
          <MailTextfield values={setting.title} disable={taioRirekiFlg} />
          <Grid className={classes.contents}>
            <MailTitleAndBody
              key={mailBodyKey}
              initData={editorState}
              setMailBody={setEditorState}
              disable={taioRirekiFlg}
            />
          </Grid>
          {uploadedList.length > 0 ? (
            <MailAppendFile
              uploads={uploadedList}
              setUploads={setUploadedList}
              disable={taioRirekiFlg}
            />
          ) : null}
        </Grid>
      </DialogContent>
    </Modal>
  );
}

export default MailCreateWindowComponentSp;
