import { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, makeStyles } from '@material-ui/core';

import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { setLibrarySearchParamWhole } from '../../../store/libraryPage';

import LibraryDirectoryTree from '../../common/libraryDirectoryTree';
import commonStyles from '../../styles';

import { MAIL_TEMPLATE_ADMIN } from '../../../constants/userRole';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // maxWidth: 300,
    height: '100%',
    maxHeight: 'calc(100vh - 180px)',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  header: {
    flexWrap: 'nowrap',
    width: '100%',
    overflowX: 'auto',
    marginBottom: 16,
    flex: '0 0 auto',
  },
  btn: {
    color: theme.palette.primaryColor,
    background: '#fff',
    margin: '8px',
    whiteSpace: 'nowrap',
    padding: '3px 8px',
    minWidth: 'auto',
  },
  disabled: {
    pointerEvents: 'none',
    background: '#DEDEDE',
    '&>span': {
      color: '#AFAFAF',
    },
  },
}));

function LibraryTree(props) {
  const { functions, selectDir, setSelectDir } = props;
  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();

  // モーダルのライブラリツリー
  const [open, setOpen] = useState(false);

  const responseHeader = useSelector((s) => s.responseHeader);
  const isTemplateAdmin = responseHeader.roleIds.includes(MAIL_TEMPLATE_ADMIN);
  const isPrivateDir = selectDir.mailCode === 1;
  const isPublicDir = selectDir.mailCode === 2;
  const isOwnDir = isPrivateDir && selectDir.userId === responseHeader.userId;

  // ライブラリディレクトリ操作権限
  // https://docs.google.com/spreadsheets/d/1IAQ2Lf6_L6v1j1h0O_4Yg1sTpRBADmzyOqvJoVTuq3M/edit#gid=1267639574&range=C3
  // (補足) https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4159#comment-179149380
  const isEditable = (isTemplateAdmin && (isPublicDir || isPrivateDir)) || isOwnDir;

  // サイドバーのライブラリツリー
  const onClickDirectory = async (directory) => {
    const { libraryDirectoryId } = directory;
    if (libraryDirectoryId === undefined) return;
    setSelectDir(directory);
    dispatch(setLibrarySearchParamWhole({ libraryDirectoryId, offset: 0 }));
    functions.getLibraries();
  };

  // 新規追加
  const addNewDir = () => functions.registerNewDir();

  // 名称変更
  const [isNameChange, setIsNameChange] = useState(false);
  const updatedirectoryName = (dirName) => {
    const { libraryDirectoryId, parentId, mailCode, directoryCode } = selectDir;
    const updateParam = {
      libraryDirectoryId,
      parentId,
      mailCode,
      directoryCode,
      directoryName: dirName,
      isRemove: 0,
    };
    if (dirName !== selectDir.directoryName) functions.updateDir(updateParam);
    setIsNameChange(false);
  };

  // コピー、移動
  const [type, setType] = useState('');
  const setCopyDir = (targetDir) => {
    const copyParam = {
      copyFromLibraryDirectoryId: selectDir.libraryDirectoryId,
      copyToLibraryDirectoryId: targetDir.libraryDirectoryId,
    };
    functions.copyDir(copyParam, targetDir);
    setType('');
  };
  const setTranslateDir = (targetDir) => {
    const updateParam = {
      libraryDirectoryId: selectDir.libraryDirectoryId,
      parentId: targetDir.libraryDirectoryId,
      directoryName: selectDir.directoryName,
      directoryCode: selectDir.directoryCode,
      mailCode: targetDir.mailCode,
      isRemove: 0,
    };
    functions.updateDir(updateParam, targetDir);
  };

  // 削除
  const onClickDel = () => {
    if (selectDir.children.length > 0) {
      dispatch(changeAlertMessage({
        msgList: ['指定したフォルダには子フォルダがあるため、削除できません。'], // 必須
        title: '削除できません', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      }));
      return false;
    }
    if (selectDir.libraryCount > 0) {
      dispatch(changeAlertMessage({
        msgList: ['フォルダ内にメールライブラリが残っているため削除できません。'], // 必須
        title: '削除できません', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      }));
      return false;
    }
    const updateParam = {
      libraryDirectoryId: selectDir.libraryDirectoryId,
      parentId: selectDir.parentId,
      directoryName: selectDir.directoryName,
      directoryCode: selectDir.directoryCode,
      mailCode: selectDir.mailCode,
      isRemove: 1,
    };
    return functions.updateDir(updateParam);
  };

  // モーダルのライブラリツリー
  const onClickSelect = (targetDir) => {
    if (!targetDir.libraryDirectoryId) return;
    const updateFunc = type === 'copy' ? setCopyDir : setTranslateDir;
    updateFunc(targetDir);
    setOpen(false);
  };
  const modalMessageMap = {
    copy: 'コピー',
    move: '移動',
  };

  return (
    <Grid container className={classes.wrapper}>
      <Grid container className={classes.header}>
        <Button
          className={`${common.strong} ${classes.btn} ${isEditable ? '' : classes.disabled}`}
          onClick={addNewDir}
        >
          新規追加
        </Button>
        <Button
          className={`${common.strong} ${classes.btn} ${isEditable ? '' : classes.disabled}`}
          onClick={() => setIsNameChange(true)}
        >
          名称変更
        </Button>
        <Button
          className={`${common.strong} ${classes.btn} ${isEditable ? '' : classes.disabled}`}
          onClick={() => {
            setOpen(true);
            setType('copy');
          }}
        >
          コピー
        </Button>
        {isTemplateAdmin ? (
          <Button
            className={`${common.strong} ${classes.btn} ${isEditable ? '' : classes.disabled}`}
            onClick={() => {
              setOpen(true);
              setType('move');
            }}
          >
            移動
          </Button>
        ) : null }
        <Button
          className={`${common.strong} ${classes.btn} ${isEditable ? '' : classes.disabled}`}
          onClick={onClickDel}
        >
          削除
        </Button>
      </Grid>
      <LibraryDirectoryTree
        selectDir={selectDir}
        setSelectDir={onClickDirectory}
        isNameChange={isNameChange}
        updatedirectoryName={updatedirectoryName}
        isTopRootDirectory
      />
      <LibraryDirectoryTree
        setSelectDir={onClickSelect}
        prefix="selctWindowliburaryDirectoryId"
        isGetLatestData={false}
        isSearch
        isShowOnlyEditable
        type={type}
        modalOption={{
          open,
          onClose: () => setOpen(false),
          title: 'ライブラリのフォルダ選択ウィンドウ',
          message: `${modalMessageMap[type]}先のフォルダを選択してください。`,
        }}
      />
    </Grid>
  );
}

export default memo(LibraryTree);
