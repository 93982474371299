import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import CustomSelect from '../../../eleCommon/customSelect';
import UserTree from '../../../common/userTreeNew';
import { USER_CLASS_SELECT, USER_GRADE_SELECT_LIST } from '../../../../constants/users';

const useStyles = makeStyles((theme) => ({
  orgGridRow: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    marginTop: '1%',
    padding: '0px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    flexShrink: 0,
    marginRight: '4%',
  },
  box: {
    marginRight: 8,
    width: 'calc(25% - 8px)',
  },
  textButton: {
    color: theme.palette.primaryColor,
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primaryColor}`,
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
  userCustomButtonSave: {
    padding: '8px 22px',
    width: '74px',
    height: '40px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '12px 16px',
  },
  isMainClass: {
    backgroundColor: '#F3F3F3',
    width: '100%',
    padding: '0px 5px',
    border: '1px solid #C8C8C8',
  },
  formControlClass: {
    margin: '5px 5px 5px 0',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  validBorder: {
    borderBottom: '1px solid red',
  },
  asterisk: { color: '#db3131', marginLeft: 3 },
}));

function OrgSettingCreate(props) {
  const {
    userState,
    setCreateMode,
    setOpen,
    updateUserFunction,
    reloadUserTree,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const [isOpenTree, setIsOpenTree] = useState(false);
  const [selectDivision, setSelectDivision] = useState({
    divId: 0,
    divisionName: '-',
  });

  const [gradeId, setGradeId] = useState(0);
  const [positionId, setPositionId] = useState(false);
  const [valid, setValid] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const changePosition = (e) => {
    setPositionId(e.target.value);
  };

  const changeGrade = (e) => {
    setGradeId(e.target.value);
  };

  useEffect(() => {
    const validJudgeList = [
      gradeId,
      positionId,
      selectDivision.divId,
    ];
    if (validJudgeList.includes('') || validJudgeList.includes(null) || validJudgeList.includes(false) || !selectDivision.divId) {
      setIsCreate(false);
    } else {
      setIsCreate(true);
    }
  }, [gradeId, positionId, selectDivision]);

  const selectData = {
    title: '',
    required: true,
    key: 'orgPosition',
    type: 'flg',
    obj: USER_CLASS_SELECT,
    state: positionId,
    set: changePosition,
    width: '80%',
    underline: true,
    valid,
  };

  const selectRole = {
    title: '',
    required: true,
    key: 'orgRole',
    type: 'flg',
    obj: USER_GRADE_SELECT_LIST,
    state: gradeId,
    set: changeGrade,
    width: '80%',
    underline: true,
    valid,
    defaultValue: 0,
  };

  const openTree = () => setIsOpenTree((s) => !s);
  const clickTreeOk = () => {}; // 保存時にAPIのパラメータに格納しているのでOK時には処理をしない
  const clickTreeCancel = () => setSelectDivision({ divId: 0, divisionName: '-' });

  const clickCancel = () => {
    setCreateMode(false);
    // setIsMain(false);
    setGradeId(false);
    setPositionId(false);
  };

  const clickSave = async () => {
    setValid(false);
    const copyUserPrf = Object.assign({}, userState);
    // ValidationCheck
    const requiredList = [
      selectDivision.divId,
      positionId,
      gradeId,
    ].includes(null || '');

    // 保存処理
    if (!requiredList) {
      const d = new Date();
      const nowDate = `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
      const addDivisionValue = {
        isMainDivision: copyUserPrf.divisions.length > 0 ? 0 : 1,
        divisionId: selectDivision.divId,
        positionCode: Number(positionId),
        gradeCode: Number(gradeId),
        startAt: nowDate,
        endAt: '9999/12/31',
      };
      copyUserPrf.divisions = [addDivisionValue];
      // DIVISION_UPDATE_TYPE['1']
      copyUserPrf.divisionUpdateTypeCode = 1;
      // IS_SEND_SHARE['0']
      'isSendShare' in copyUserPrf ? null : copyUserPrf.isSendShare = 0;
      // IS_RECEIVE_SHARE['0']
      'isReceiveShare' in copyUserPrf ? null : copyUserPrf.isReceiveShare = 0;
      setOpen(false);
      await updateUserFunction(userState.userId, copyUserPrf);
      setCreateMode(false);
      reloadUserTree();
    } else {
      setValid(true);
    }
  };

  useEffect(() => {
    return () => {
      setSelectDivision({
        divId: 0,
        divisionName: '-',
      });
    };
  }, []);

  return (
    <Grid style={{ width: '100%' }}>
      <Grid container className={classes.orgGridRow}>
        <Grid item className={`${baseClasses.grid} ${classes.box}`}>
          <span className={`${baseClasses.title4} ${classes.label}`}>
            組織
            <span className={classes.asterisk}>＊</span>
          </span>
          <TextField
            style={{ width: '80%' }}
            className={`${valid ? classes.validBorder : ''} ${baseClasses.modalBtn}`}
            id="orgSetting-division-input"
            value={selectDivision.divisionName}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            onClick={() => openTree()}
          />
          {isOpenTree ? (
            <UserTree
              isOpenTree={isOpenTree}
              setIsOpenTree={setIsOpenTree}
              selectDivision={selectDivision}
              setSelectDivision={setSelectDivision}
              handleSelectFunction={clickTreeOk}
              handleClearFunction={clickTreeCancel}
              showOnlyDivisions
              isDuplicate
              isDialog
              isUserMaster
            />
          ) : null}
        </Grid>
        <Grid item style={{ width: '23%' }} className={baseClasses.grid}>
          <span className={`${baseClasses.title4} ${classes.label}`}>
            役割
            <span className={classes.asterisk}>＊</span>
          </span>
          <CustomSelect initial={selectData} />
        </Grid>
        <Grid item style={{ width: '22%' }} className={baseClasses.grid}>
          <span className={`${baseClasses.title4} ${classes.label}`}>
            役職
            <span className={classes.asterisk}>＊</span>
          </span>
          <CustomSelect initial={selectRole} />
        </Grid>
        <Grid item style={{ width: '15%', textAlign: 'right' }}>
          <Button className={classes.textButton} onClick={() => clickCancel()}>
            キャンセル
          </Button>
        </Grid>
        <Grid item style={{ width: '15%' }}>
          <Button
            disabled={!isCreate}
            className={`${baseClasses.buttonsPrimary} ${classes.userCustomButtonSave}`}
            onClick={() => clickSave()}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrgSettingCreate;
