import { useCallback } from 'react';
import CommonTableRow from './commonTableRow';
import CustomTable from './customTable';
import { MEMBERS_REDUCER_KEYS } from '../../../containers/browsingHistory/browsingHistoryReducer';

const headCells = [
  { id: 'searchedAt', label: '日時', sortable: true },
  { id: 'margeText', label: 'ページタイトル' },
];

export default function SearchTable(props) {
  const {
    data,
    getApiDataFromListApi,
    isSP,
    handleOpenModal = null,
  } = props;
  const searchTablelimit = localStorage.getItem('searchTablelimit') ? localStorage.getItem('searchTablelimit') : 20;
  const setSearchTablelimit = useCallback((current) => {
    localStorage.setItem('searchTablelimit', current);
  }, []);
  return (
    <CustomTable
      hideHeader={isSP}
      title="検索条件履歴"
      headCells={headCells}
      total={data.total}
      list={data.conditions}
      rowRender={(list) => list.map((row, i) => (<CommonTableRow
        key={String(i)}
        date={row.searchedAt}
        title={row.margeText}
        url={row.url}
      />))}
      onPageChange={(pageInfo) => {
        getApiDataFromListApi(MEMBERS_REDUCER_KEYS.searchConditions, pageInfo);
      }}
      divider
      contentHeight={(isSP || handleOpenModal === null) ? null : 130}
      propLimit={searchTablelimit}
      setPropLimit={setSearchTablelimit}
      isNoSlice
      handleOpenModal={handleOpenModal}
    />
  );
}
