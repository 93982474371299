import ChangeManageContainer from '../../../../containers/common/customer/changeManage/changeManageContainer';

function ChangeManage(props) {
  const {
    initialParam,
    onClickSelectButton,
    open,
    setOpen,
    updateTree,
  } = props;

  return (
    <ChangeManageContainer
      initialParam={initialParam}
      onClickSelectButton={onClickSelectButton}
      open={open}
      setOpen={setOpen}
      updateTree={updateTree}
    />
  );
}

export default ChangeManage;
