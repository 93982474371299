import { createSlice } from '@reduxjs/toolkit';

const initialState = { libraryDirectories: [] };

export const librarydirGetDirectlyTreeSlice = createSlice({
  name: 'librarydirGetDirectlyTree',
  initialState: { librarydirGetDirectlyTree: initialState },
  reducers: {
    getlibrarydirDirectlyTreeApi: (state, { payload: { libraryDirectories } }) => {
      state.librarydirGetDirectlyTree.libraryDirectories = libraryDirectories.sort((a, b) => {
        return a.mailCode < b.mailCode ? -1 : 1;
      });
    },
  },
});

export const { getlibrarydirDirectlyTreeApi } = librarydirGetDirectlyTreeSlice.actions;

export default librarydirGetDirectlyTreeSlice.reducer;
