import { createSlice } from '@reduxjs/toolkit';

/**
 * @type { import(
 *   '../../apis/act/getIncompleteListApiTypes'
 * ).IncompleteListGetResponse }
 */
const initialState = {
  message: '',
  description: '',
  data: {
    total: 0,
    directs: [],
  },
};

export const incompleteListSlice = createSlice({
  name: 'incompleteList',
  initialState,
  reducers: {
    updateIncompleteList: (state, action) => {
      state.message = action.payload.message;
      state.description = action.payload.description;
      state.data = action.payload.data;
    },
  },
});

export const { updateIncompleteList } = incompleteListSlice.actions;

export default incompleteListSlice.reducer;
