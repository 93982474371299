import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import store from '../../../store';

import { MAIL_CODE_PRIVATE, MAIL_CODE_PUBLIC } from '../../../constants';
import { MAIL_TEMPLATE_ADMIN } from '../../../constants/userRole';

const Context = createContext({});

export const useLibraryDirectoryTreeContext = () => {
  return useContext(Context);
};

const initialDir = {};

export function LibraryDirectoryTreeProvider(props) {
  const {
    selectDir = initialDir,
    setSelectDir,
    isNameChange,
    updatedirectoryName,
    prefix,
    modalOption,
    isSearch,
    isTopRootDirectory, // 初回表示でディレクトの一番上を選択状態にする
    isShowOnlyEditable = false, // 編集可能のデータのみを表示する
    type = 'search',
    targetDir = {}, // typeがcopy, moveの時に変更があるかどうか判定するためのオブジェクト
    children,
    choiceDir = [], // 検索画面でチェックを入れたものの情報を入れる配列
  } = props;

  // ツリー上で選択状態かを管理するステート
  const [activeDir, setActiveDir] = useState(selectDir);

  const directories = useSelector(
    (s) => s.librarydirGetDirectlyTree.librarydirGetDirectlyTree.libraryDirectories,
  );
  const { responseHeader } = store.getState();
  const { userId } = responseHeader;
  const isTemplateAdmin = responseHeader.roleIds.includes(MAIL_TEMPLATE_ADMIN);
  const filterDirectories = (directory) => {
    if (!isShowOnlyEditable) return true;
    if (
      directory.mailCode === MAIL_CODE_PRIVATE
      && (directory.userId === userId || isTemplateAdmin)
    ) return true;
    if (directory.mailCode === MAIL_CODE_PUBLIC) return true;
    return false;
  };
  const list = useMemo(() => directories.filter(filterDirectories), [directories]);

  useEffect(() => {
    if (modalOption) return;
    // ツリー選択状態のオブジェクトを参照元のステートに同期させる
    setSelectDir(activeDir);
  }, [activeDir]);

  useEffect(() => {
    if (!modalOption) return;
    if (isTopRootDirectory) return;
    // 参照元のステートをツリー選択状態にする
    setActiveDir(selectDir);
  }, [selectDir]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    selectDir,
    setSelectDir,
    activeDir,
    setActiveDir,
    isNameChange,
    updatedirectoryName,
    prefix,
    modalOption,
    isSearch,
    isShowOnlyEditable,
    isTopRootDirectory,
    type,
    targetDir,
    list,
    choiceDir,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
