// import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NO_CONTACT_DETAIL_REASON_CODE } from '../../../../../constants';

import commonTheme from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  listItem: {
    width: '100%',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  listItemText: {
    '& .MuiTypography-root': {
      fontWeight: '700',
    },
  },
  gutters: {
    margin: '0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0',
      '&.Mui-selected': {
        background: '#fff',
        color: theme.palette.primaryColor,
      },
    },
    '& > .MuiListItemText-root': {
      [commonTheme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '16px',
        borderBottom: '1px solid #F3F3F3',
      },
    },
  },
}));

export default function DoNotChaseReasonList(props) {
  const { setValue, label, setLabel, setOpen, setState } = props;
  const classes = useStyles();
  const initial = NO_CONTACT_DETAIL_REASON_CODE;
  const menus = Object.keys(initial);

  return (
    <List className={classes.list}>
      {menus.map((data) => (
        <ListItem
          button
          key={data}
          className={classes.listItem}
          classes={{ gutters: classes.gutters }}
          selected={label === initial[data]}
          onClick={() => {
            setLabel(initial[data]);
            setValue(data);
            setState(data);
            setOpen(false);
          }}
        >
          <ListItemText
            primary={initial[data]}
            className={classes.listItemText}
          />
        </ListItem>
      ))}
    </List>
  );
}
