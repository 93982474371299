import InfoRegisterOnlyPostContainers from '../../../containers/common/infoRegisterOnlyPost/infoRegisterOnlyPostContainers';

/**
 * GA004 案内登録画面 実装ドキュメント (https://openhouse.backlog.jp/alias/wiki/1144646)
 * ※本画面は、ActionScheduleからGA004 案内登録画面を開く際に使用
 * 経緯：https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2822#comment-161700388
 * @module infoRegister
 * @param {Boolean} open - 必須：(Boolean) infoRegisterの表示非表示
 * @param {function} onClose - 必須：(function) infoRegisterを閉じる関数
 * @param {number} guidanceId - 呼び出したい案内情報のguidanceId or null(nullの場合、新規登録になる)
 * @param {object} customer - 必須：customerのオブジェクト
 * @param {function} [onCloseTypesDrawer] - 任意：<SP>対応履歴の種別選択ドロワーのonClose関数
 * @param {function} [setClickInfoRegister] - 任意：infoRegisterモーダルの予約登録完了時に実行する関数
 * @return {jsx} - 案内登録モーダル
 */
function InfoRegister(props) {
  const {
    open, // 必須：(Boolean) infoRegisterの表示非表示
    onClose, // 必須：(function) infoRegisterを閉じる関数
    guidanceId = null, // ＜新規登録の場合：nullでOK＞＜新規登録以外：必須(integer)＞
    customer, // 必須：(object) customerIdなど、common001のレスを参照
    onCloseTypesDrawer = null, // 任意項目：<SP>対応履歴の種別選択ドロワーのonClose関数
    setClickInfoRegister = null, // 任意項目：infoRegisterモーダルの予約登録完了時に実行する関数
    actionId,
    reacquisition,
  } = props;

  return (
    <div>
      <InfoRegisterOnlyPostContainers
        open={open}
        onClose={onClose}
        guidanceId={guidanceId}
        onCloseTypesDrawer={onCloseTypesDrawer}
        setClickInfoRegister={setClickInfoRegister}
        customer={customer}
        actionId={actionId}
        reacquisition={reacquisition}
      />
    </div>
  );
}

export default InfoRegister;
