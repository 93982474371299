import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesPostSlice = createSlice({
  name: 'schedulesPostApi',
  initialState: { schedulesPostApi: initialState },
  reducers: {
    setSchedulesPostApi: (state, data) => {
      state.schedulesPostApi = data.payload;
    },
  },
});

export const { setSchedulesPostApi } = schedulesPostSlice.actions;

export default schedulesPostSlice.reducer;
