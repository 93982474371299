/* eslint-disable max-len */
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import SelectProductMulti from '../../../pages/selectProductMulti/selectProductMulti';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #C8C8C8',
      background: '#fff',
      padding: '8px 8px 7px',
    },
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  box: {
    width: 730,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 0,
    width: 'auto',
    textAlign: 'left',
    minHeight: 20,
    textTransform: 'none',
    flexGrow: 1,
    '& .MuiButton-label': {
      display: 'block',
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    whiteSpace: 'nowrap',
  },
  // SP
  list: {
    background: '#fff',
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
    textAlign: 'left',
  },
  product: {
    display: 'flex',
    '&:not(:last-child)': { marginBottom: 6 },
    '& > span': {
      display: 'block',
      '&:first-child': { flexShrink: 0, marginRight: 16 },
    },
  },
});

function InfoRegisterChoice2(props) {
  const { values } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  // const [products, setProducts] = useState([...values.state]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  if (isSP) {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              onClick={handleOpen}
              className={classes.listBtn}
            >
              <Typography className={classes.listTtl}>
                {values.label}
                {values.required && <span>＊</span>}
              </Typography>
              <Typography className={classes.listTxt}>
                {values.state.map((o) => (
                  <span className={classes.product} key={o.suggestProductId}>
                    <span>{o.productName}</span>
                    <span>{o.memo !== '' && o.memo}</span>
                  </span>
                ))}
              </Typography>
            </Button>
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <Typography className={classes.ttl}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <Grid className={classes.box}>
          <Button
            className={classes.btn}
            onClick={handleOpen}
          >
            {/* {values.state ? values.state.productName : ''} */}
            {values.state.map((o) => (
              <span className={classes.product} key={o.productId}>
                <span>{o.productName}</span>
                <span>{o.memo !== '' && o.memo}</span>
              </span>
            ))}
          </Button>
          <Typography className={classes.txt}>
            （複数可）
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      {open
        && (
        <SelectProductMulti
          open={open}
          onClose={handleClose}
          values={values.state}
          products={values.state}
          setProducts={values.setState}
        />
        )}
    </Grid>
  );
}

export default InfoRegisterChoice2;
