import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../styles';
import {
  validateFormTel,
  validateFormEmail,
  validateFormUrl,
} from '../../commonFunction/validations';

function Contact(props) {
  const { division, setDivision, getComponent } = props;
  const baseClasses = commonStyles();

  const handleChange = (e, data) => {
    const val = e.target ? e.target.value : e;
    switch (data.key) {
      case 'phoneNumber':
        setDivision({ ...division, phoneNumber: String(val) });
        break;
      case 'fax':
        setDivision({ ...division, fax: String(val) });
        break;
      case 'mailingListAddress':
        setDivision({ ...division, mailingListAddress: String(val) });
        break;
      case 'googleChatWebhookUrl':
        setDivision({ ...division, googleChatWebhookUrl: String(val) });
        break;
      default:
        break;
    }
  };

  const basicField = [
    {
      label: '電話番号',
      require: true,
      type: 'str',
      key: 'phoneNumber',
      state: division.phoneNumber,
      set: handleChange,
      validator: validateFormTel(),
    },
    {
      label: 'FAX',
      require: false,
      type: 'str',
      key: 'fax',
      state: division.fax,
      set: handleChange,
      validator: validateFormTel(),
    },
    {
      label: 'メーリングリスト',
      require: false,
      type: 'str',
      key: 'mailingListAddress',
      state: division.mailingListAddress,
      set: handleChange,
      validator: validateFormEmail(),
    },
    {
      label: 'Googleチャット（WebhookURL）',
      require: false,
      type: 'str',
      key: 'googleChatWebhookUrl',
      state: division.googleChatWebhookUrl,
      set: handleChange,
      validator: validateFormUrl(),
    },
  ];
  return (
    <>
      <Typography style={{ marginBottom: 10 }} className={baseClasses.title3}>
        組織の連絡先
      </Typography>
      <Grid style={{ marginBottom: 30 }}>
        {basicField.map((data) => getComponent(data))}
      </Grid>
    </>
  );
}

export default Contact;
