/* eslint-disable max-len */
import React, { useState } from 'react';
import Calendar from '../common/calendar';

// 下記一旦テスト用
function CalendarTest() {
  // const [date, setDate] = useState({ assignAt: '2021-01-01' });
  // const assignAtOnchange = (value) => {
  //   setDate({ ...date, assignAt: value });
  // };
  // const [date2, setDate2] = useState({ aaa: { timeAt: '' }, bbb: 'ccc' });
  // const assignAtOnchange2 = (value) => {
  //   setDate2({ ...date2, aaa: { timeAt: value } });
  // };
  const [time, setTime] = useState({ assignAt: '2021-01-01T00:00:00' });
  const timeOnchange = (value) => {
    setTime({ ...time, assignAt: value });
  };
  // const [multitime, setMultitime] = useState({ startAt: '2021-01-01T00:00:00', endAt: '2021-01-01T10:00:00' });
  // const multitimeOnchange = (value1, value2) => {
  //   setMultitime({
  //     ...multitime,
  //     startAt: value1,
  //     endAt: value2,
  //   });
  // };
  // const [multiple, setMultiple] = useState({ startAt: '2021/01/01 00:00:00', endAt: '2021/01/01 00:00:00' });
  // const multipleOnchange = (value1, value2) => {
  //   setMultiple({
  //     ...multiple,
  //     startAt: value1,
  //     endAt: value2,
  //   });
  // };
  const datalist = [
    // {
    //   id: 'id1', // String - labelとinputを紐づけます
    //   label: '日付のみ', // String - ラベル
    //   require: true, // Boolean - 必須かどうか
    //   type: 'date', // String 'date'|'time'|'multitime'|'multiple'
    //   state: date, // state
    //   setState: assignAtOnchange, // setState
    // },
    // {
    //   id: 'id2', // String - labelとinputを紐づけます
    //   label: '初期値空欄', // String - ラベル
    //   require: true, // Boolean - 必須かどうか
    //   type: 'date', // String 'date'|'time'|'multitime'|'multiple'
    //   state: date2.aaa, // state
    //   setState: assignAtOnchange2, // setState
    // },
    {
      id: 'id3', // ※IDが同じでも独立して動きますが、コンソールエラーが出るのでユニーク推奨
      label: '日時',
      require: false,
      type: 'time',
      state: time,
      setState: timeOnchange,
    },
    // {
    //   id: 'multitimetest',
    //   label: '日時',
    //   require: false,
    //   type: 'multitime',
    //   startState: multitime.startAt,
    //   endState: multitime.endAt,
    //   setState: multitimeOnchange,
    // },
    // {
    //   id: 'idtest',
    //   label: ['開始日時', '終了日時'], // typeが'multiple'のときのみ配列
    //   require: false,
    //   type: 'multiple',
    //   startState: multiple.startAt,
    //   endState: multiple.endAt,
    //   setState: multipleOnchange,
    // },
  ];

  return (
    <div>
      <span>Test</span>
      {datalist.map(array => {
        return (
          <div key={array.id}>
            <Calendar data={array} />
          </div>
        );
      })}
      <p>親コンポーネント側state</p>
      <p>time:{time.assignAt}</p>
      {/* <p>date:{date.assignAt}</p> */}
      {/* <p>date２:{date2.aaa.timeAt}</p>
      <p>multitile / start:{multitime.startAt} end:{multitime.endAt}</p>
      <p>multiple / start:{multiple.startAt} end:{multiple.endAt}</p> */}
    </div>
  );
}

export default CalendarTest;
