import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LibrarySignatureComponent from '../../components/mailLibrary/librarySignature/librarySignatureConponent';
import libraryUpdateApi from '../../apis/library/libraryUpdateApi';
import libraryGetSignitureApi from '../../apis/library/libraryGetSignitureApi';
import { libraryGetSigniture } from '../../store/library/libraryGetSignitureSlice';
import store from '../../store';
import { useLoading } from '../../hooks';

import { SIGNITURE_EDIT_WINDOW } from '../../constants/loading';

export default function LibrarySignatureContainer(props) {
  const { open, onClose } = props;
  const { addLoading, removeLoading } = useLoading();
  const dispatch = useDispatch();
  // responseHeaderを取得
  const { responseHeader } = store.getState();
  // 署名一覧を格納
  const signature = useSelector(
    (state) => state.libraryGetSigniture.libraryGetSigniture,
  );

  // PUT /libraries/management/{libraryId} 【library003】ライブラリ情報更新
  const libraryUpdateFunction = async (libraryID, modifyObj) => {
    await libraryUpdateApi(libraryID, modifyObj)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  // GET /libraries/signature/ 【library007】ライブラリ情報取得（署名）
  const libraryGetSignatureFunction = async () => {
    addLoading(SIGNITURE_EDIT_WINDOW);
    await libraryGetSignitureApi()
      .then((res) => dispatch(libraryGetSigniture(res.data)))
      .catch((err) => console.log(err))
      .finally(() => removeLoading(SIGNITURE_EDIT_WINDOW));
  };

  const onSubmit = (params) => {
    const { userId } = responseHeader;
    const { libraryId, ...restParm } = params;
    restParm.userId = userId;
    restParm.isUpdate = 1;
    libraryUpdateFunction(libraryId, restParm);
    // リセット
    dispatch(libraryGetSigniture({
      libraryDirectoryId: 0,
      libraries: [
        {
          libraryId: null,
          libraryDirectoryId: null,
          libraryName: '',
          mailSubject: '',
          mailBody: '',
          libraryMemo: '',
          attachmentUrl: [],
        },
      ],
    }));
    onClose();
  };

  // 初期読み込み
  useEffect(() => {
    libraryGetSignatureFunction();
  }, []);

  return (
    <LibrarySignatureComponent
      open={open}
      signature={signature}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
