import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    pointerEvents: 'none',
  },
  swipeContainer: {
    overflowX: 'hidden',
    position: 'relative',
    zIndex: 1,
  },
  swipeItem: {
    // touchAction: 'pan-x',
    transition: 'all 250ms',
    flexShrink: 0,
    width: '100%',
    zIndex: 1,
    background: '#fff',
    position: 'relative',
    pointerEvents: 'all',
    '&.active': {
      transform: 'translateX(-74px)',
    },
  },
  btn: {
    color: '#fff',
    flexShrink: 0,
    width: '74px',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 0,
    borderRadius: 0,
    pointerEvents: 'all',
    '& > .MuiButton-label': {
      fontSize: '13px',
      fontWeight: 'bold',
      lineHeight: 1,
    },
    '&.blue': {
      background: theme.palette.primaryColor,
    },
    '&.red': {
      background: '#D83420',
    },
    '&.active': {
      zIndex: 2,
    },
  },
}));

export default function SwipeOutButton(props) {
  const {
    swipe, setSwipe, index,
    styles, label, color, handleClick, ...children } = props;
  const classes = useStyles();

  let startX;
  let endX;

  const handleTouchStart = (event) => {
    startX = event.touches[0].pageX;
  };
  const handleTouchMove = (event) => {
    endX = event.changedTouches[0].pageX;
  };
  const handleTouchEnd = () => {
    // スワイプした向きを検知
    // right or click
    if ((startX - endX) < 100 || endX === undefined) {
      setSwipe(null);
    } else if (swipe !== index) {
      setSwipe(index);
    }
  };

  return (
    <Grid
      className={`${classes.root} ${styles}`}
    >
      <Grid className={classes.swipeContainer}>
        {/* スワイプさせるコンテンツ */}
        <Grid
          className={`${classes.swipeItem} ${swipe === index ? 'active' : ''}`}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <Grid className={classes.swipeContent} {...children} />
        </Grid>
      </Grid>
      {/* クリック要素 */}
      <Button onClick={handleClick} className={`${classes.btn} ${color} ${swipe === index ? 'active' : ''}`}>{label}</Button>
    </Grid>
  );
}
