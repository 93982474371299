import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
} from '@material-ui/core';
import commonTheme from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  btn: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    borderRadius: 0,
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
      borderBottom: '1px #8C8C8C solid',
      '&:hover': {
        backgroundColor: `${theme.palette.primaryColor}29`,
        borderColor: '#000',
      },
    },
    '& .MuiButton-label': {
      [commonTheme.breakpoints.down('sm')]: {
        color: '#8C8C8C',
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      [commonTheme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
  },
}));
function InfoChangeHistoryButton(props) {
  const { setOpenDrawer, labelValue } = props;
  const classes = useStyles();

  let resultWord = '';
  if (labelValue instanceof Object) {
    for (let i = 0; labelValue.length > i; i += 1) {
      if (i === 0) {
        resultWord = labelValue[i].userName;
      } else {
        resultWord = resultWord + ',' + labelValue[i].userName;
      }
    }
  } else if (labelValue && labelValue !== ' 0') {
    resultWord = labelValue;
  } else {
    resultWord = '選択してください';
  }

  return (
    <Button
      type="button"
      className={classes.btn}
      onClick={() => { setOpenDrawer(true); }}
    >
      {resultWord}
    </Button>
  );
}

export default InfoChangeHistoryButton;
