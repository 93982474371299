import MailAppendFileContainer from '../../../containers/mail/parts/appendFile';

function MailAppendFile(props) {
  const { uploadedList, setUploadedList, disable } = props;
  return (
    <MailAppendFileContainer
      uploadedList={uploadedList}
      setUploadedList={setUploadedList}
      disable={disable}
    />
  );
}

export default MailAppendFile;
