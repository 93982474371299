import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Add, ArrowBackIos, Folder } from '@material-ui/icons';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import commonStyles from '../../../../styles';

import LoadingEllipsis from '../../../pullRefComponent/parts/loadingEllipsis';

import { useLoading } from '../../../../../hooks';

import { classNames } from '../../../../../commonFunction';
import {
  LIBRARY_DIRECTORY_TREE_LIBRRARY,
} from '../../../../../constants/loading';

const useStyles = makeStyles((theme) => ({
  fadeContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '100%',
  },
  root: {
    width: '100%',
    maxHeight: '100%',
    padding: '0 0 16px 0',
  },
  parent: {
    position: 'relative',
    padding: 0,
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 1,
  },
  li: {
    background: '#fff',
    paddingLeft: 16,
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    fontSize: 16,
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '&.isCompact': {
      width: '80%',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  diviBtn: {
    wordBreak: 'break-all',
  },
  diviPickableButton1: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '80%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&.fullSize': {
      width: '100%',
    },
  },
  diviPickableButton2: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '20%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        marginLeft: '0',
        margin: '0',
      },
    },
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  checked: {
    color: theme.palette.primaryColor,
  },
  // 戻るボタン用
  button: {
    padding: '18px 16px',
    width: '100%',
    background: '#fff',
    justifyContent: 'flex-start',
    margin: '16px 0',
    height: 58,
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  searchItem: {
    padding: 0,
    // borderTop: '1px solid #C8C8C8',
  },
  searchWrap: {
    width: '100%',
  },
  searchTitle: {
    padding: 16,
  },
  searchList: {
    padding: 0,
  },
  result: {
    padding: '40px 16px',
    textAlign: 'center',
    color: '#8C8C8C',
    fontSize: 15,
  },
}));
export default function LibraryTreeListSp(props) {
  const {
    selectDir,
    setSelectDir,
    selectLibrary,
    setOpenPreview,
    functions,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  const { hasLoading } = useLoading();

  // ディレクトリ一覧
  const { libraryDirectories = [] } = useSelector(
    (state) => state.librarydirGetDirectlyTree.librarydirGetDirectlyTree,
  );

  // ライブラリ一覧を保持
  const libraries = useSelector(
    (state) => state.libraryGetSpecifyDirctory.libraryGetSpecifyDirctory,
  );

  // 表示用リスト(ディレクトリ, ライブラリを配列にする)
  const [displayList, setDisplayList] = useState([]);

  const directoryMap = useMemo(() => {
    const tmpMap = [];
    const setDirectories = (item) => {
      item.children.forEach((child) => {
        setDirectories(child);
      });
      tmpMap.push([item.libraryDirectoryId, item]);
    };
    libraryDirectories.forEach((item) => setDirectories(item));
    return new Map(tmpMap);
  }, [libraryDirectories]);

  const isTopDirectory = useMemo(() => {
    const topDirectoryIdArray = libraryDirectories.map(
      (i) => i.libraryDirectoryId,
    );
    return topDirectoryIdArray.some(
      (id) => selectDir.libraryDirectoryId === id,
    );
  }, [libraryDirectories, selectDir]);

  const isNoneSelectedDirectory = Object.keys(selectDir).length === 0;

  // ライブラリを選択
  const handleSelectLibrary = (item) => {
    // プレビュー用
    functions.getLibraryFunction(item.libraryId);
    // プレビュー用のモーダルを開く
    setOpenPreview(true);
  };

  // ディレクトリを選択
  const handleSelectDirectory = (item) => setSelectDir(item);

  // 一個上のディレクトリに戻る
  const handleBack = () => {
    const parentDir = isTopDirectory
      ? {}
      : directoryMap.get(selectDir.parentId) || {};
    handleSelectDirectory(parentDir);
  };

  // フォルダー選択用のボタン
  const directoryButton = (item) => {
    const hasChidren = item.libraryCount > 0 || item.children[0];
    const buttonClass = [common.title3, classes.diviPickableButton1];
    if (selectDir.libraryDirectoryId === item.libraryDirectoryId) buttonClass.push(classes.checked);
    if (!hasChidren) buttonClass.push('fullSize');
    return (
      <Grid className={classes.li} key={`key${item.libraryDirectoryId}`}>
        <Button
          className={classNames(...buttonClass)}
          onClick={() => handleSelectDirectory(item)}
        >
          <Folder className={classes.icon} />
          <Typography className={classNames(common.title3, classes.diviBtn)}>
            {item.directoryName}
            {item.libraryCount !== null ? `（${item.libraryCount}）` : ''}
          </Typography>
        </Button>
        {/* ライブラリ選択が可能なパーツの場合 */}
        {hasChidren ? (
          <Button
            className={classes.diviPickableButton2}
            onClick={() => handleSelectDirectory(item)}
          >
            <Add className={classes.addButtonIcon} />
          </Button>
        ) : null}
      </Grid>
    );
  };

  // ライブラリ選択用のボタン
  const libraryButton = (item) => {
    const buttonClass = [
      common.title3,
      classes.diviPickableButton1,
      'fullSize',
    ];
    if (selectLibrary.libraryId === item.libraryId) buttonClass.push(classes.checked);
    return (
      <Grid className={classes.li} key={`key${item.libraryId}`}>
        <Button
          className={classNames(...buttonClass)}
          onClick={() => handleSelectLibrary(item)}
        >
          <MailOutlineIcon className={classes.icon} />
          <Typography className={common.title3}>{item.libraryName}</Typography>
        </Button>
      </Grid>
    );
  };

  // 画面に各階層のリストを描画
  const getListItem = (arr) => {
    return arr.map((item) => {
      if (item === undefined) return false;
      return 'children' in item ? directoryButton(item) : libraryButton(item);
    });
  };

  useEffect(() => {
    const arr = isNoneSelectedDirectory
      ? [...libraryDirectories]
      : [...selectDir.children, ...libraries];
    setDisplayList(arr);
  }, [libraries]);

  useEffect(() => {
    if (!isNoneSelectedDirectory) {
      functions.libraryGetSpecifyDirctoryFunction(selectDir.libraryDirectoryId);
      return;
    }
    // rootディレクトリはlibraryが存在しない(api)を叩かないので、表示の配列を更新
    setDisplayList([...libraryDirectories]);
  }, [selectDir, libraryDirectories]);

  return (
    <>
      {!isNoneSelectedDirectory && (
        // 戻るボタン
        <Button className={classes.button} onClick={handleBack}>
          <ArrowBackIos className={classes.backIcon} />
          <Typography className={classNames(classes.text, common.title3)}>
            {selectDir.directoryName}
          </Typography>
        </Button>
      )}
      <Grid className={classes.root}>
        {getListItem(displayList)}
        {hasLoading(LIBRARY_DIRECTORY_TREE_LIBRRARY) ? <LoadingEllipsis /> : null}
      </Grid>
    </>
  );
}
