// 選択された組織/ユーザー配下の全ての顧客IDを取得
const getFromAllCustomer = (targetObj, targetProps) => {
  const customerList = [];
  // const assignObj = {};
  // 第2引数が指定されてなければ、customersの全データをcustomerListへpush
  if (targetProps === undefined) {
    targetProps = ['customerId', 'lastName', 'firstName',
      'customerStatusCode', 'userLevel', 'effectAt', 'responsibleUserId',
      'responsibleUserLastName', 'responsibleUserFirstName', 'lastActivityAt',
      'memberStatusCode', 'isShared', 'parentKeyList',
    ];
  }
  // customerListへPush -- 共通関数
  // TODO: 値がnullの場合、配列から除外?
  const pushCustomerId = (obj) => {
    // 重複チェック
    if (customerList.some(item => item.customerId === obj.customerId)) return;
    customerList.push({
      customerId: obj.customerId,
      // update: 2023/04/20 - nullを表示させない
      customerName: [obj.lastName, obj.firstName].filter(Boolean).join(' '),
      customerStatusCode: obj.customerStatusCode,
      userLevel: obj.userLevel,
      effectAt: obj.effectAt,
      responsibleUserId: obj.responsibleUserId,
      responsibleUserLastName: obj.responsibleUserLastName,
      responsibleUserFirstName: obj.responsibleUserFirstName,
      lastActivityAt: obj.lastActivityAt,
      memberStatusCode: obj.memberStatusCode,
      isShared: obj.isShared,
    });
  };

  const getCustomerIds = (target) => {
    // let result = null;
    if (Array.isArray(target)) {
      for (let i = 0; target.length > i; i += 1) {
        if ('customerId' in target[i]) {
          // for (let prop = 0; targetProps.length > prop; prop += 1) {
          //   assignObj[targetProps[prop]] = target[i][targetProps[prop]];
          // }
          // console.log({ assignObj });
          pushCustomerId(target[i]);
        } else {
          for (const key in target[i]) {
            if (target[i][key] instanceof Object || Array.isArray(target[i][key])) {
              getCustomerIds(target[i][key]);
            } else if (key === 'customerId') {
              pushCustomerId(target[i]);
            }
          }
        }
      }
    } else if (target instanceof Object) {
      if ('customerId' in target) {
        pushCustomerId(target);
      } else {
        for (const key in target) {
          if (target[key] instanceof Object || key === 'customers') {
            getCustomerIds(target[key]);
          } else if (key === 'customerId') {
            pushCustomerId(target);
            break;
          }
        }
      }
    }
  };
  getCustomerIds(targetObj);
  return customerList;
};

// ユーザーIDに紐づく全ての顧客情報をdivision005から取得し、customerListとして返す
export const getAllCustomerByUserId = (targetObj, userId) => {
  const customerList = [];
  // customerListへPush -- 共通関数
  const pushCustomerId = (obj) => {
    // 重複チェック
    if (customerList.some(item => item.customerId === obj.customerId)) return;
    const newObj = Object.assign({ customerName: [(obj.lastName ?? ''), (obj.firstName ?? '')].filter(Boolean).join(' ') }, obj);
    customerList.push(newObj);
  };

  const getCustomerIds = (target) => {
    // let result = null;
    if (Array.isArray(target)) {
      for (let i = 0; target.length > i; i += 1) {
        if ('customerId' in target[i]) {
          if (target[i].userId === userId) {
            pushCustomerId(target[i]);
          }
        } else {
          for (const key in target[i]) {
            if (target[i][key] instanceof Object || Array.isArray(target[i][key])) {
              getCustomerIds(target[i][key]);
            } else if (key === 'customerId') {
              if (target[i].userId === userId) {
                pushCustomerId(target[i]);
              }
            }
          }
        }
      }
    } else if (target instanceof Object) {
      if ('customerId' in target) {
        if (target.userId === userId) {
          pushCustomerId(target);
        }
      } else {
        for (const key in target) {
          if (target[key] instanceof Object || key === 'customers') {
            getCustomerIds(target[key]);
          } else if (key === 'customerId') {
            if (target.userId === userId) {
              pushCustomerId(target);
            }
            break;
          }
        }
      }
    }
  };
  getCustomerIds(targetObj);
  return customerList;
};

export default getFromAllCustomer;

// TODO: 全部のユーザーオブジェクトをAPIから取得し、userObjとして返す
/**
 * 選択された組織/ユーザー情報を取得
 * @typedef {{
 *   userId: number;
 * }} CustomerTreeItem
 * @param {CustomerTreeItem} list CustomerTreeItem[]
 * @param {number} userId number
 * @param {number} divisionId number
 * @returns {null | CustomerTreeItem}
 */
export const getUserFromCustomerTree = (list, userId, divisionId) => {
  if (!list?.length || !userId) return null;
  let item;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < list.length; i++) {
    item = list[i];
    if ((item.userId === userId && item.divisionId === divisionId)
    || (item.userId === userId && divisionId === 0)) {
      return item;
    } else {
      const res = getUserFromCustomerTree(item.users, userId, divisionId)
        || getUserFromCustomerTree(item.children, userId, divisionId);
      if (res) return res;
    }
  }
  return null;
};

// TODO: 全部の組織オブジェクト取得
/**
 * @typedef {{
*   divisionId: number;
* }} CustomerTreeItem
* @param {CustomerTreeItem} list CustomerTreeItem[]
* @param {number} divisionId number
* @returns {null | CustomerTreeItem}
*/
export const getDivisionFromCustomerTree = (list, divisionId) => {
  if (!list?.length || !divisionId) return null;
  let item;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < list.length; i++) {
    item = list[i];
    if ((item.divisionId === divisionId) || (divisionId === 0)) {
      return item;
    } else {
      const res = getDivisionFromCustomerTree(item.users, divisionId)
        || getDivisionFromCustomerTree(item.children, divisionId);
      if (res) return res;
    }
  }
  return null;
};
