import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import ContractInformationTable from './parts/contractInformationTable';
import CommonDialog from '../../common/modal';

const useStyles = makeStyles({
  content: {
    padding: 8,
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
});

export default function ContractInformation(props) {
  const {
    open,
    onClose,
    list,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={isSp ? '契約情報確認' : '契約情報確認ウィンドウ'}
      width={640}
      className={baseClasses.cancelIndex}
      bodyClassName={classes.content}
    >
      <ContractInformationTable
        list={list}
      />
    </CommonDialog>
  );
}
