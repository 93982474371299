import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import { IS_HIDE, IS_HIDE_OPPOSE, IS_TREE } from '../../constants';
import modifyObject from '../../commonFunction/modifyObject';
import { validateFormInt1 } from '../../commonFunction/validations';

const useStyles = makeStyles({
  container: {
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
});

function Display(props) {
  const { division, setDivision, getComponent, isEdit } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();

  const handleChange = (e, data) => {
    const val = e.target ? e.target.value : e;
    switch (data.key) {
      case 'isSalesDivision':
        setDivision({ ...division, isSalesDivision: Number(val) });
        break;
      case 'isHide':
        setDivision({ ...division, isHide: Number(val) });
        break;
      case 'isDisplaySalesResponseTree':
        setDivision({ ...division, isDisplaySalesResponseTree: Number(val) });
        break;
      case 'displayOrder':
        setDivision({ ...division, displayOrder: Number(val) });
        break;
      default:
        break;
    }
  };

  const basicField = [
    {
      label: '営業店舗フラグ',
      require: false,
      type: 'select',
      obj: modifyObject(IS_HIDE, '営業店舗フラグ'),
      key: 'isSalesDivision',
      width: '49.5%',
      state: division.isSalesDivision,
      set: handleChange,
    },
    {
      label: '反響ツリー表示フラグ',
      require: false,
      type: 'select',
      obj: modifyObject(IS_TREE, '反響ツリー表示フラグ'),
      key: 'isDisplaySalesResponseTree',
      width: '49.5%',
      state: division.isDisplaySalesResponseTree,
      set: handleChange,
    },
  ];
  const basicField2 = [
    {
      label: '店舗の並び順',
      require: false,
      type: 'str',
      key: 'displayOrder',
      width: '49.5%',
      state: division.displayOrder,
      set: handleChange,
      validator: validateFormInt1({
        minFloat: 0.0,
        maxFloat: 999999999.0,
      }),
    },
    {
      label: '非表示フラグ',
      require: false,
      type: 'select',
      obj: modifyObject(IS_HIDE_OPPOSE, '非表示フラグ'),
      key: 'isHide',
      width: '49.5%',
      state: division.isHide,
      set: handleChange,
      disable: !isEdit,
    },
  ];

  return (
    <>
      <Typography style={{ marginBottom: 10 }} className={baseClasses.title3}>表示</Typography>
      <Grid className={classes.container}>
        <Grid className={classes.row}>
          {basicField.map((data) => (
            getComponent(data)
          ))}
        </Grid>
        <Grid className={classes.row}>
          {basicField2.map((data) => (
            getComponent(data)
          ))}
        </Grid>
      </Grid>
    </>
  );
}
export default Display;
