import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'deep-equal';
import divisionsGetResponseTreeApi from '../../../apis/divisions/divisionsGetResponseTreeApi';
import { getDivisionsResponseTreeApi, initialResponseTreeState } from '../../../store/divisions/divisionsGetResponseTreeSlice';
import getDataFromAllCustomer, { getUserFromCustomerTreeDivision, getUserFromCustomerTree } from '../../../components/common/customerTree/commonFunc/getDataFromAllCustomer';
import { setIsDetailSearch } from '../../../store/search/searchItemsSlice';
import { getLocalStorage, debounce } from '../../../commonFunction';
import { TREE_TYPECODE, TREE_UNITCODE, TREE_DIVISION_ID } from '../../../constants/localStorage';
import store from '../../../store';
import deletePathParameter from '../../../commonFunction/deletePathParameter';
import { getDivisionFromCustomerTree } from '../../../components/common/customerTree/commonFunc/getListFromAllCustomer';
import { sortCustomers } from '../../../components/common/customerTree/commonFunc/treeHelpers';

/**
 * オブジェクトの空値(null, [], '')削除
 * @param {object} data
 * @returns object
 */
function removeEmptyItem(data) {
  const result = {};
  let value;
  Object.keys(data).forEach((key) => {
    value = data[key];
    if (Array.isArray(value)) {
      if (value.length) result[key] = value;
    } else if (value || value === 0) {
      result[key] = value;
    }
  });
  return result;
}

const CustomerTreeContext = createContext({});

export function CustomerTreeContextProvider(props) {
  const responseHeader = useSelector((state) => state.responseHeader);
  const defaultHeader = {
    typeCode: getLocalStorage(TREE_TYPECODE, 0), // constants/index.js DIVISION_CODE
    unitCode: getLocalStorage(TREE_UNITCODE, 2), // constants/index.js UNIT_CODE
    divisionId: getLocalStorage(TREE_DIVISION_ID, responseHeader?.mainDivision?.id),
  };
  const {
    children,
    setSelectUser,
    setSelectDivision,
    setSelectCustomer,
    setSelectUniqueId,
    // divisionSelectableのtrue/falseで
    // 組織ツリーの組織セクションをボタンにするか/しないかを出し分けしている
    divisionSelectable = true,
    searchForm,
    targetFlgList = {},
    initialHeader = defaultHeader,
    isHaveInitialParams,
    defaultUserId,
    defaultDivisionId,
    defaultUniqueId,
    displayCustomer,
    isAvoidSharedCustomer,
    isMulti,
    isDirect,
    isSp,
    options,
    sortType,
    treeHeight,
  } = props;
  const searchParams = useSelector((state) => state.searchItems.searchParams);
  const isDetailSearch = useSelector((state) => state.searchItems.isDetail);
  const requestParamsRef = useRef({});
  const isHave = useRef(isHaveInitialParams);
  const firstDivisionTree = useRef(true);

  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const divisionsGetResponseTree = useSelector(
    (state) => state.divisionsGetResponseTree.divisionsGetResponseTree,
  );

  const [searchHeader, setSearchHeader] = useState({
    typeCode: 'typeCode' in initialHeader ? initialHeader.typeCode : defaultHeader.typeCode,
    unitCode: 'unitCode' in initialHeader ? initialHeader.unitCode : defaultHeader.unitCode,
    divisionId: 'divisionId' in initialHeader ? initialHeader.divisionId : defaultHeader.divisionId,
  });

  // 検索結果
  const [searchObj, setSearchObj] = useState({});
  const [keyword, setKeyword] = useState('');

  const [selectedDivisionId, setSelectedDivisionId] = useState(defaultDivisionId);

  const setSelectedDivision = (divisions) => {
    setSelectDivision(divisions);
    setSelectedDivisionId(isMulti ? divisions.divIdMultipleList : divisions.id);
  };

  // POST API 反響ツリー【divisionsGetResponseTree】の更新用
  const getCustomerTreeResponseFunction = useCallback(debounce(async (parameter) => {
    const params = { ...parameter };
    if ('typeCode' in params && 'unitCode' in params && 'divisionId' in params) {
      if (params.typeCode !== '' && params.unitCode !== '') {
        // 被共有顧客除外フラグ
        if (isAvoidSharedCustomer) {
          params.isAvoidSharedCustomer = 1;
          delete params.isSendShare;
          delete params.isReceiveShare;
        }
        delete params.isReload;
        await divisionsGetResponseTreeApi(params)
          .then((res) => {
            if (!firstDivisionTree.current) deletePathParameter();
            dispatch(getDivisionsResponseTreeApi(res.data));
            dispatch(setIsDetailSearch(false));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            firstDivisionTree.current = false;
          });
      }
    }
  }), []);

  useEffect(() => {
    const copyObjHeader = Object.assign({}, searchHeader);
    const copyObjParams = Object.assign({}, searchParams);
    const params = removeEmptyItem({ ...copyObjHeader, ...copyObjParams });

    // 詳細検索の際はbudgetMin & Maxは除外
    if (isDetailSearch) {
      if ('budgetMin' in params) delete params.budgetMin;
      if ('budgetMax' in params) delete params.budgetMax;
    }

    // 【division005】反響ツリー取得チェック
    // 同じパラメーターの場合、APIの叩きをスキップする
    if (equal(params, requestParamsRef.current)) return;
    requestParamsRef.current = params;
    // グローバルステート：顧客ツリー【divisionsGetResponseTree】の更新
    if (!isHave.current) {
      // SPの場合は現担当選択が無いので、弾く
      if (isSp) {
        if ('currentUserId' in params) delete params.currentUserId;
        const parentId = getLocalStorage(TREE_DIVISION_ID, responseHeader?.mainDivision?.id);
        params.divisionId = parentId;
      }
      getCustomerTreeResponseFunction(params);
    }
    isHave.current = false;
  }, [searchHeader, searchParams]);

  useEffect(() => {
    // 簡易検索がある画面から、他の簡易検索がある画面にリンク遷移をするとstoreに残っている値を使用して
    // 各画面の後続のAPIが走ることがるので、コンポーネントの破棄のタイミングでdivision005の返り値を初期化する。
    // useSelectorから取得した場合、下のuseLayoutEffectでの初期が効いてない場合があるのでstoreから直接取得する。
    const {
      divisionsGetResponseTree: {
        divisionsGetResponseTree: tmpDivisionsGetResponseTree,
      },
    } = store.getState();
    if (list === tmpDivisionsGetResponseTree) return;
    setList(tmpDivisionsGetResponseTree);
    // 選択されたユーザー更新
    // 顧客一括管理一覧更新のため
    if (isMulti) {
      let newCustomerList = [];
      let newCustomerIdList = [];
      for (const divId of selectedDivisionId) {
        const divObj = getDivisionFromCustomerTree(
          tmpDivisionsGetResponseTree,
          divId,
        );
        const customerList = getDataFromAllCustomer(divObj);
        const targetCustomerList = customerList.filter(
          // eslint-disable-next-line no-loop-func
          (c) => !newCustomerIdList.includes(c.customerId),
        );
        newCustomerList = [...newCustomerList, ...targetCustomerList];
        newCustomerIdList = [
          ...newCustomerIdList,
          ...targetCustomerList.map((c) => c.customerId),
        ];
      }
      setSelectCustomer(sortCustomers(newCustomerList, sortType));
      return;
    }
    let userObj = null;
    if (defaultDivisionId && defaultUserId) {
      userObj = getUserFromCustomerTree(
        tmpDivisionsGetResponseTree,
        defaultUserId,
        defaultDivisionId,
      );
      const customerList = getDataFromAllCustomer(userObj);
      setSelectCustomer(sortCustomers(customerList, sortType));
    } else if (defaultDivisionId) {
      userObj = getUserFromCustomerTreeDivision(
        tmpDivisionsGetResponseTree,
        defaultDivisionId,
      );
      const customerList = getDataFromAllCustomer(userObj);
      setSelectCustomer(sortCustomers(customerList, sortType));
    }
  }, [list, divisionsGetResponseTree, defaultUserId]);

  useLayoutEffect(() => {
    return () => {
      // 簡易検索がある画面から、他の簡易検索がある画面にリンク遷移をするとstoreに残っている値を使用して
      // 各画面の後続のAPIが走ることがるので、コンポーネントの破棄のタイミングでdivision005の返り値を初期化する。
      dispatch(getDivisionsResponseTreeApi(initialResponseTreeState));
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    list,
    setSelectUser,
    setSelectDivision: setSelectedDivision,
    setSelectCustomer,
    setSelectUniqueId,
    divisionSelectable,
    searchHeader,
    setSearchHeader,
    defaultUserId,
    defaultDivisionId: selectedDivisionId,
    defaultUniqueId,
    searchForm,
    targetFlgList,
    displayCustomer,
    searchObj,
    setSearchObj,
    keyword,
    setKeyword,
    isMulti,
    isDirect,
    options,
    sortType,
    treeHeight,
  };

  return (
    <CustomerTreeContext.Provider value={value}>
      {children}
    </CustomerTreeContext.Provider>
  );
}

export function useCustomerTree() {
  return useContext(CustomerTreeContext);
}
