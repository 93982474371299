import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import { Refresh, Add, CheckBoxOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import commonTheme from '../styles/theme';
// eslint-disable-next-line import/no-cycle
import AllListTable from './parts/allListTable';
import MailSelectBox from './parts/allSelectbox';
import MailCreateWindow from '../mail/createWindow';
import CustomTablePagination from '../eleCommon/customTablePagenation';
import KanniKensaku from '../search/kanniKensaku';

import { updateObjSearchItems } from '../../store/search/searchItemsSlice';

import { getLocalStorage, setLocalStorage } from '../../commonFunction';

import { SALES_GROUPS, MARKETING_GROUPS, SYSTEM } from '../../constants/userRoleGroup';
import { operation, display } from '../../constants/allMail/allMailConstants';
import { LIMIT_INSTRUCTION_LIST } from '../../constants/localStorage';
import { initialTreeState } from '../../containers/search/detailSearch/detailSearchContainers';
import AsideCustomerTree from './asideCustomerTree';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    flexWrap: 'nowrap',
  },
  sideMenu: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 240,
      flexShrink: 0,
      maxHeight: 'calc(100vh - 52px)',
      overflowY: 'hidden',
      overflowX: 'hidden',
      borderRight: '1px #c8c8c8 solid',
    },
    '& > div': {
      [commonTheme.breakpoints.up('md')]: {
        width: 240,
      },
    },
  },
  root: {
    padding: '16px',
    width: 'calc(100% - 240px)',
  },
  appBar: {
    color: '#333',
    background: 'none',
    width: 252,
    boxShadow: 'none',
    position: 'relative',
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  tabs: {
    minHeight: 'auto',
  },
  tab: {
    width: '50%',
    minWidth: 'auto',
    minHeight: 'auto',
    padding: '8px 0',
    '&.Mui-selected': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
  },
  tabPanel: {
    padding: '0 16px',
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  header: {
    paddingBottom: 16,
    alignItems: 'center',
  },
  refresh: {
    color: theme.palette.primaryColor,
    // "\node_modules\@material-ui\core\TablePagination\TablePagination.js"59行目で定義されている定数値
    // TablePaginationを表示しない場合、高さが他と異なるため導入
    minHeight: 52,
    margin: '0 40px',
    '& .MuiSvgIcon-root': {
      width: '16px',
    },
  },
  choice: { marginRight: '16px', display: 'flex' },
  check: { fontSize: '16px', verticalAlign: 'middle' },
  label: {
    margin: '0 16px',
    '&::before': {
      content: '""',
      display: 'inline-block',
      height: '32px',
      width: '1px',
      background: '#C8C8C8',
      marginRight: '16px',
      verticalAlign: 'middle',
    },
  },
  // scrollArea: {
  //   width: '100%',
  //   maxHeight: 'calc(100vh - 206px)',
  //   overflow: 'auto',
  // },
  sticky: {
    position: 'sticky',
    top: 0,
    background: '#fafafa',
    zIndex: 1,
    marginTop: -24,
    marginBottom: 24,
  },
}));

// リセット用のパラメータ
const initialSalesSearchParam = {
  ...initialTreeState,
  isSendableMailExist: 1,
  isWebSearchOption: true,
};

const initialMarkeSearchParam = {
  ...initialTreeState,
  isSendableMailExist: 1,
  isWebSearchOption: true,
};

const fristTimeSalesSearchParam = {
  isSendableMailExist: 1,
  isWebSearchOption: true,
};

const fristTimeMarkeSearchParam = {
  isSendableMailExist: 1,
  isWebSearchOption: true,
};

export default function AllMailComponent(props) {
  const { getMailLists } = props;
  const classes = useStyles();
  const common = commonStyles();

  const dispatch = useDispatch();

  // グローバルステート
  const { totalCount, customerIds, mails } = useSelector(
    (state) => state.mailIdListGet.mailIdListGet,
  );
  const { userId: currentUserId, roleIds, roleGroupId } = useSelector(
    (state) => state.responseHeader,
  );

  const initialSearchParam = SALES_GROUPS.includes(roleGroupId)
    ? initialSalesSearchParam
    : initialMarkeSearchParam;

  const fristTimeSearchParam = SALES_GROUPS.includes(roleGroupId)
    ? fristTimeSalesSearchParam
    : fristTimeMarkeSearchParam;

  // ページネーション
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(getLocalStorage(LIMIT_INSTRUCTION_LIST, 20));
  // checkbox
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  // ヘッダー検索条件
  const [opeVal, setOpeVal] = useState(0);
  // 表示
  const [disVal, setDisVal] = useState(0);

  // サイドバー
  const [customers, setCustomers] = useState([]);

  // 一斉メール送信用のパラメータ
  // division005の検索パラメータ
  const searchParams = useSelector((state) => state.searchItems.searchParams);
  const [selectUser, setSelectUser] = useState({
    userId: SALES_GROUPS.includes(roleGroupId) ? currentUserId : undefined,
  });
  // divisionIdsの取得: {"divId":string,"divisionName":string,"divIdMultipleList":number[]}
  const [divisions, setDivisions] = useState({
    divId: '',
    divisionName: '',
    divIdMultipleList: [],
  });

  const onChangeOperation = (e) => setOpeVal(e);

  // table sort
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('response_at');

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 更新
  const refresh = () => {
    if ([...MARKETING_GROUPS, SYSTEM].includes(roleGroupId)) return;
    getMailLists(page * rowsPerPage, rowsPerPage, {
      customerIds: customers.map((customer) => customer.customerId),
      menuOptionCode: opeVal,
      displayOption: disVal,
      order,
      orderBy,
    });
  };

  const setRowPages = (pageCount) => {
    setRowsPerPage(pageCount);
    setLocalStorage(LIMIT_INSTRUCTION_LIST, pageCount);
  };

  useEffect(() => {
    // マーケ使用の場合のみ、反響ツリークリックによるAPIコールを抑制する
    if (roleIds.includes(12)) return;
    getMailLists(page * rowsPerPage, rowsPerPage, {
      customerIds: customers.map((customer) => customer.customerId),
      menuOptionCode: opeVal,
      displayOption: disVal,
      order,
      orderBy,
    });
  }, [customers, page, rowsPerPage, opeVal, order, orderBy]);

  useEffect(() => {
    // 初期表示時、追加条件を設定
    dispatch(updateObjSearchItems(fristTimeSearchParam));
    return () => {
      // 一斉メール描画時にデフォルトで入れていたパラメータを抜く
      const resettedSearchParam = {
        emailStatusCodes: initialTreeState.emailStatusCodes,
        isSendableMailExist: initialTreeState.isSendableMailExist,
        isWebSearchOption: initialTreeState.isWebSearchOption,
      };
      dispatch(updateObjSearchItems(resettedSearchParam));
    };
  }, []);

  // 反響ツリーの高さ調整
  const [treeHeight, setTreeHeight] = useState('');
  const [openAccordion, setOpenAccordion] = useState(true);
  const changeAccordion = (d) => {
    setOpenAccordion(d);
  };
  useEffect(() => {
    const accordionHeight = openAccordion ? 464 : 24;
    const height = 144 + accordionHeight;
    setTreeHeight(`calc(100vh - ${height}px)`);
  }, [openAccordion]);

  return (
    <Grid className={classes.pageWrapper} container>
      <Grid className={classes.sideMenu}>
        <KanniKensaku
          isAvoidSharedCustomer
          initialSearchParam={initialSearchParam}
          isFromAllmail
          changeAccordion={changeAccordion}
        />
        <AsideCustomerTree
          defaultUserId={selectUser.userId}
          defaultDivisionId={
            !SALES_GROUPS.includes(roleGroupId)
              ? divisions.divIdMultipleList
              : divisions.divId
          }
          setSelectUser={setSelectUser}
          setSelectDivision={setDivisions}
          setSelectCustomer={setCustomers} // 任意：選択されたユーザー情報のsetState(setState)
          isMulti={!SALES_GROUPS.includes(roleGroupId)}
          treeHeight={treeHeight}
        />
      </Grid>
      {roleIds.includes(12) ? ( // マーケ使用の場合のみ、表をレンダリングしない
        <Grid className={classes.root}>
          <div className={classes.sticky}>
            <Grid container className={classes.header}>
              <Typography className={common.title2}>メール</Typography>
              <Button className={classes.refresh} onClick={refresh}>
                <Refresh />
                <Typography className={common.smallStrong}>更新</Typography>
              </Button>
            </Grid>
            <Grid container className={classes.header}>
              <Typography className={`${common.bass} ${classes.choice}`}>
                <Typography className={common.title2}>{customers.length}件</Typography>にメールを送信しますか？
              </Typography>
              <MailCreateWindow
                user={{
                  ids: customers.map((customer) => customer.customerId),
                  mails: mails.map((i) => i.mail),
                }}
                btnlabel={
                  <>
                    <Add />
                    <Typography className={common.smallStrong}>
                      メール作成
                    </Typography>
                  </>
                }
                disabled={customers.length === 0}
                additionalMailParam={{
                  ...searchParams,
                  divisionIds: divisions.divIdMultipleList,
                  customerIds: undefined,
                  isMarketingUse: 1,
                  isAvoidSharedCustomer: undefined,
                }}
                isLocalStorage
                isActiveSaveTemplate
              />
            </Grid>
          </div>
        </Grid>
      ) : (
        <Grid className={classes.root}>
          <div className={classes.sticky}>
            <Grid container className={classes.header}>
              <Typography className={common.title2}>メール</Typography>
              <Button className={classes.refresh} onClick={refresh}>
                <Refresh />
                <Typography className={common.smallStrong}>更新</Typography>
              </Button>
              <CustomTablePagination
                rowsPerPageOptions={[20, 40, 60]}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowPages}
                totalCount={totalCount}
              />
            </Grid>
            <Grid container className={classes.header}>
              <Typography className={`${common.bass} ${classes.choice}`}>
                {selectedCustomerIds.length}件選択中
              </Typography>
              <MailCreateWindow
                user={{
                  ids: selectedCustomerIds,
                  mails: mails
                    .filter((i) => selectedCustomerIds.includes(i.customerId))
                    .map((i) => i.mail),
                }}
                btnlabel={
                  <>
                    <Add />
                    <Typography className={common.smallStrong}>
                      メール作成
                    </Typography>
                  </>
                }
                disabled={selectedCustomerIds.length === 0}
                additionalMailParam={{
                  ...searchParams,
                  customerIds: selectedCustomerIds,
                  isMarketingUse: 0,
                  isAvoidSharedCustomer: undefined,
                }}
                isLocalStorage
                isActiveSaveTemplate
              />
              <Typography className={`${common.title4} ${classes.label}`}>
                <CheckBoxOutlined className={classes.check} />
                検索
              </Typography>
              <MailSelectBox
                constants={operation}
                value={opeVal}
                set={onChangeOperation}
              />
              <Typography className={`${common.title4} ${classes.label}`}>
                表示
              </Typography>
              <MailSelectBox constants={display} value={disVal} set={setDisVal} />
            </Grid>
          </div>
          <Grid className={classes.scrollArea}>
            <AllListTable
              list={mails}
              totalCount={totalCount}
              display={disVal}
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              allCustomreIds={customerIds}
              selectedCustomerIds={selectedCustomerIds}
              setSelectedCustomerIds={setSelectedCustomerIds}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
