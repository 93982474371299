import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  tr: {
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    // '&:hover td': {
    //   background: `${theme.palette.primaryColor}29!important`,
    // },
    '&:nth-child(even) td': {
      background: '#F3F3F3',
    },
  },
  td: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
    textAlign: 'center',
    borderRight: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    color: '#333',
    width: 104,
    background: '#fff',
    '&:first-child': {
      padding: 0,
      textAlign: 'left',
      width: 120,
      position: 'sticky',
      zIndex: '2',
      left: 0,
      '& > span': {
        borderRight: '2px solid #8C8C8C',
        boxSizing: 'border-box',
        padding: '8px 16px',
      },
    },
    '&.active::before': {
      content: '""',
      position: 'absolute',
      background: `${theme.palette.primaryColor}29`,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
  },
  box: {
    display: 'flex',
    '& span': {
      display: 'block',
      '&:first-child': {
        marginRight: 8,
      },
    },
  },
}));

export default function ProcessTableRow(props) {
  const {
    row,
    rowNum,
  } = props;

  const classes = useStyles();

  const onClickCell = (i1, i2) => () => {
    // 初期化
    const target1 = document.getElementsByClassName('as-cell');
    for (let i = 0; i < target1.length; i += 1) {
      target1[i].classList.remove('active');
    }
    // 設定
    const target2 = document.getElementsByClassName('as-row-' + i1);
    for (let i = 0; i < target2.length; i += 1) {
      target2[i].classList.add('active');
    }
    const target3 = document.getElementsByClassName('as-cell-' + i2);
    for (let i = 0; i < target3.length; i += 1) {
      target3[i].classList.add('active');
    }
  };

  // 小数第二位を四捨五入
  const round = (num) => {
    return (Math.round(num * 10)) / 10;
  };

  return (
    <TableRow hover tabIndex={-1} className={classes.tr}>
      <TableCell className={[classes.td, 'as-row-' + rowNum, 'as-cell']}>
        <span className={classes.box}>
          <span>{row.jisName}</span>
        </span>
      </TableCell>
      {row.settings.map((setting, colNum) => (
        <TableCell
          key={setting.storeGroupId}
          className={`${classes.td} ${'as-row-' + rowNum} ${
            'as-cell as-cell-' + colNum
          }`}
          onClick={onClickCell(rowNum, colNum)}
        >
          {setting.score ? round(setting.score) : 0}
        </TableCell>
      ))}
    </TableRow>
  );
}
