import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import commonStyles from '../../styles/index';
import SortCheckbox from './sortCheckbox';
import SortSelect from './sortSelect';
import SortButtons from './sortButtons';
import { KanniKensakuContenxtProvider } from '../../../containers/search/kanniKensaku/KanniKensakuContext';
import { randomId } from '../../../commonFunction';

const useStyles = makeStyles(() => ({
  accordionDetails: {
    padding: '8px 8px 4px',
  },
  checkboxGrid: {
    '&.MuiGrid-item': {
      borderBottom: 'solid 1px #C8C8C8',
    },
  },
  selectGrid: {
    '&.MuiGrid-item': {
      padding: '0 0 2px',
      width: '100%',
    },
  },
  buttonsGrid: {
    '&.MuiGrid-item': {
      paddingTop: 4,
      width: '100%',
    },
  },
}));

function AccordionWrap(props) {
  const { children, title, changeAccordion } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [id] = useState(randomId());

  const [expanded, setExpanded] = React.useState(`panel${id}`);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (changeAccordion) changeAccordion(newExpanded);
  };

  return (
    <Accordion
      square
      className={common.sideAccordion}
      expanded={expanded === `panel${id}`}
      onChange={handleChange(`panel${id}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}a-content`}
        id={`panel${id}a-header`}
        className={common.sideAccordionHeader}
      >
        <Box>
          <SearchIcon style={{ color: '#8C8C8C' }} fontSize="small" />
          <Typography>{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

function KanniKensakuMain(props) {
  const {
    isAvoidSharedCustomer,
    addOnlyFilterBlock,
    initialSearchParam,
    isFromAllmail = false,
    changeAccordion = null,
    changeAccordionFilter = null,
    changeAccordionSearch = null,
  } = props;

  return (
    <KanniKensakuContenxtProvider
      isAvoidSharedCustomer={isAvoidSharedCustomer}
      initialSearchParam={initialSearchParam}
    >
      {addOnlyFilterBlock ? (
        <Grid>
          <AccordionWrap title="絞り込み" changeAccordion={changeAccordionFilter}>
            <ContentForOnlyFilter />
          </AccordionWrap>
          <AccordionWrap title="検索" changeAccordion={changeAccordionSearch}>
            <Content addOnlyFilterBlock isFromAllmail={isFromAllmail} />
          </AccordionWrap>
        </Grid>
      ) : (
        <AccordionWrap title="絞り込み/検索" changeAccordion={changeAccordion}>
          <Content isFromAllmail={isFromAllmail} />
        </AccordionWrap>
      )}
    </KanniKensakuContenxtProvider>
  );
}

function Content(props) {
  const { addOnlyFilterBlock, isFromAllmail } = props;
  const classes = useStyles();

  return (
    <Grid container>
      {!addOnlyFilterBlock && (
        <Grid item className={classes.checkboxGrid}>
          <SortCheckbox />
        </Grid>
      )}
      <Grid item className={classes.selectGrid}>
        <SortSelect isCustomSelects={addOnlyFilterBlock} />
      </Grid>
      <Grid item className={classes.buttonsGrid}>
        <SortButtons isFromAllmail={isFromAllmail} />
      </Grid>
    </Grid>
  );
}

// 見込み案件管理表独自の検索パーツ
function ContentForOnlyFilter() {
  return (
    <Grid container>
      <Grid item>
        <SortCheckbox addOnlyFilterBlock />
      </Grid>
    </Grid>
  );
}

export default KanniKensakuMain;
