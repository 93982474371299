/* eslint-disable no-self-assign */
/* eslint-disable max-len */
// GA004 案内登録画面 実装ドキュメント(https://openhouse.backlog.jp/alias/wiki/1144646)
// ※本画面は、ActionScheduleからGA004 案内登録画面を開く際に使用
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import InfoRegisterButton from '../../common/infoRegister/parts/infoRegisterButton';
import InfoRegisterGuidance from '../../common/infoRegister/parts/infoRegisterGuidance';
import InfoRegisterAchievement from '../../common/infoRegister/parts/infoRegisterAchievement';
import InfoRegisterHistory from '../../common/infoRegister/parts/infoRegisterHistory';
import InfoRegisterStatus from '../../common/infoRegister/parts/infoRegisterStatus';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
// import ActionSchedule from '../../actionSchedule';
import Modal from '../modal';
// import store from '../../../store';

const useStyles = makeStyles({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      // zIndex: '1270!important',
      '& .MuiDialog-paper': {
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'none',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    [commonTheme.breakpoints.down('sm')]: {
      paddingBottom: 58,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 1160,
      background: '#fff',
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '19px 0 8px',
      textAlign: 'center',
      background: '#fff',
      borderBottom: '1px solid #C8C8C8',
      position: 'sticky',
      top: -1,
      zIndex: 2,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
      borderBottom: '1px solid #C8C8C8',
    },
  },
  content: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      overflowY: 'auto',
      maxHeight: '100vh',
    },
  },
  info: {
    background: '#F3F3F3',
    [commonTheme.breakpoints.up('md')]: {
      width: '55%',
      height: '100%',
      paddingTop: 24,
    },
  },
  history: {
    [commonTheme.breakpoints.up('md')]: {
      width: '45%',
      borderLeft: '1px solid #C8C8C8',
      position: 'sticky',
      top: 0,
      left: 0,
    },
  },
  wrapper: {
    [commonTheme.breakpoints.up('md')]: {
      padding: 0,
      overflowY: 'hidden',
    },
  },
  box: {
    marginBottom: 24,
  },
  ttl: {
    marginBottom: 8,
  },
  childwrap: {
    width: '100%',
  },
  itemwrap: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  caption: {
    color: '#D83420',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginTop: '-8px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
      padding: '24px 0',
    },
    [commonTheme.breakpoints.up('md')]: {
      borderTop: '1px solid #C8C8C8',
      padding: '16px 0',
    },
    '&.achievement button:nth-child(1)': {
      display: 'none',
    },
    '&.achievement button:nth-child(2)': {
      display: 'block',
    },
  },
  footerBtn: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 28px',
    '&:nth-child(1)': {
      display: 'block',
    },
    '&:nth-child(2)': {
      display: 'none',
    },
  },
  // SP
  drawer1: {
    zIndex: '1352!important',
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
    },
  },
  drawer2: {
    zIndex: '1353!important',
    '& .MuiDrawer-paper': {
      width: '84%',
      right: 0,
      left: 'auto',
    },
  },
});

export default function InfoRegisterWrapperOnlyPost(props) {
  const {
    open,
    // onOpen,
    onClose,
    // user,
    guidanceId,
    registerInitial,
    setClickInfoRegister,
    functions,
    onCloseTypesDrawer,
    customer,
    // response,
    responseHeader,
  } = props;

  const {
    getGuidanceInfoByGuidanceIdFunction,
    postGuidanceFunction,
    putGuidanceByGuidanceIdFunction,
    deleteGuidanceByGuidanceIdFunction,
  } = functions;

  const [initialValue, setInitialValue] = useState(registerInitial); // 初期表示用。
  const [initialValueAfterCount, setInitialValueAfterCount] = useState(0); // 初期表示のuseEffect後に呼び出すために使用

  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  // SP・PCでの分岐用
  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);

  // 案内情報グローバルステート
  const guidanceInfo = useSelector(state => state.schedulesGetGuidanceByGuidanceId.schedulesGetGuidanceByGuidanceId);

  let contents;

  // 現在の日時を取得
  const nowDate = new Date();

  const localParams = useRef({});

  // -------------------- ステート --------------------

  // 履歴
  const [guidanceIdHistory, setGuidanceIdHistory] = useState(null);
  // 契約済み
  const [contract, setContract] = useState(false);
  // 日時
  const [date1, setDate1] = useState(
    {
      assignAt: initialValue.guidanceStartAt,
    },
  );
  const [date2, setDate2] = useState(
    {
      assignAt: initialValue.guidanceEndAt,
    },
  );
  // guidanceId
  const [isInitialRegistration, setIsInitialRegistration] = useState(true);
  // 接客者1
  const [contactAccount1, setContactAccount1] = useState({
    userName: '',
    userId: '',
    divId: '',
    divisionName: '',
  });
  const [contactMinutes1, setContactMinutes1] = useState('');
  // 接客者2
  const [contactAccount2, setContactAccount2] = useState('');
  const [contactMinutes2, setContactMinutes2] = useState('');
  // 同席者
  const [attendContactAccount, setAttendContactAccount] = useState({
    userName: '',
    userId: '',
  });
  // 案内者
  const [guideContactAccount, setGuideContactAccount] = useState({
    userName: '',
    userId: '',
    divId: '',
    divisionName: '',
  });
  const [guideContactMinutes, setGuideContactMinutes] = useState('');
  // 同行者
  const [accompanyingPerson, setAccompanyingPerson] = useState({
    userName: '',
    userId: '',
  });
  // 実績登録の物件
  const [guidedProducts, setGuidedProducts] = useState('');
  // 案内予約の物件
  const [product, setProduct] = useState('');
  // 顧客名
  const [customerName, setCustomerName] = useState('');
  // 獲得者
  const [guidanceGetUser, setGuidanceGetUser] = useState({
    userName: '',
    userId: '',
  });
  // 獲得者 組織
  const [guidanceGetUserDivision, setGuidanceGetUserDivision] = useState({
    divId: '',
    divisionName: '',
  });
  // 案内予定者
  const [guidanceUser, setGuidanceUser] = useState({
    userName: '',
    userId: '',
  });
  // 案内予定者 組織
  const [guidanceUserDivision, setGuidanceUserDivision] = useState({
    divId: '',
    divisionName: '',
  });
  // メモ
  const [memo, setMemo] = useState('');
  // 案内種別
  const [type, setType] = useState(initialValue.scheduleStatusCode);
  // 来社
  const [visit, setVisit] = useState('');
  // 案内
  const [guidance, setGuidance] = useState('');
  // 種類
  const [kinds, setKinds] = useState(Number.isInteger(initialValue.porterResultCode) ? initialValue.porterResultCode : 0);
  // 表示制御
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  // 案件情報 揃い
  const [check1, setCheck1] = useState(false);
  // 案件情報 FP
  const [check2, setCheck2] = useState(false);
  // 次の案内予約も一緒に登録する
  const [check3, setCheck3] = useState(false);
  // 実績 揃い
  const [check4, setCheck4] = useState(false);
  // 接触対象
  const [check5, setCheck5] = useState(false);
  const [check6, setCheck6] = useState(false);
  const [check7, setCheck7] = useState(false);
  const [check8, setCheck8] = useState(false);
  const [check9, setCheck9] = useState(false);
  // ボタンの必須制御（予約登録）
  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  // 予約後かどうかの判定
  const [reserveFlg, setReserveFlg] = useState(false);
  // 活性、非活性の制御
  const [inactive1, setInactive1] = useState(false);
  const [inactive2, setInactive2] = useState(false);
  const achIacInitial = {
    propertyName: false,
    customerService1: false,
    customerService2: false,
    attendees: false,
    guide: false,
    accompanyingPerson: false,
    contactTarget: false,
  };
  const [achIac, setAchIac] = useState(achIacInitial);
  // フッターナビ
  const [openSelect, setOpenSelect] = useState(false);
  const handleOpenSelect = () => {
    setOpenSelect(!openSelect);
  };
  const handleCloseSelect = () => {
    setOpenSelect(false);
  };
  // 接触履歴
  const [openHistory, setOpenHistory] = useState(false);
  // 行動予定表開閉state
  const [openActionSchedule, setOpenActionSchedule] = useState(false);

  const handleOpenHistory = () => {
    setOpenHistory(!openHistory);
  };
  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  /**
   * 予約登録/予約更新用の物件データをtargetObjに追加
   * @module setProductHandler
   * @param {object} targetObj
   * @return {object} {result, targetObj} result = 物件配列 / targetObj = request bodyオブジェクト
  */
  const setProductHandler = (targetObj) => {
    let result;
    const initProducts = initialValue.suggestProducts;
    if (initProducts.length > 0) { // 初期ロード時のinitialValueにsuggestProductsが1件でも含まれている場合
      const arr = initProducts.map((s) => {
        const newState = { ...s, isScheduleGuidance: 0 };
        return newState;
      });

      result = product[0]?.siteId
        ? [{
          productName: product[0].productName,
          siteId: product[0].siteId,
          isScheduleGuidance: 1,
        }, ...arr]
        : [...initProducts];
    } else if (product[0]?.siteId) { // 初期ロード時のinitialValueにsuggestProductsが1件も存在しない且つ、新しくproductを選択した場合
      result = [{
        productName: product[0].productName,
        siteId: product[0].siteId,
        isScheduleGuidance: 1,
      }];
    } else { // // 初期ロード時のinitialValueにsuggestProductsが1件も含まれていない且つ、新しくproductも含まれていない
      result = [];
    }

    // siteId(必須プロパティ)が空のオブジェクトをmapで消す
    const finalResult = [];
    for (const r of result) {
      if (r?.siteId || r?.siteId === 0) {
        finalResult.push(r);
      }
    }

    // targetObjに格納する
    targetObj.suggestProducts = finalResult;
    return {
      result: finalResult,
      targetObj,
    };
  };

  // 実績登録用の物件データをtargetObjに追加
  const achievementProductHandler = (targetObj) => {
    // 案内予約の物件配列を取得
    const initProducts = setProductHandler(targetObj).result;

    // 案内予約と実績登録の物件で重複しているものを取得
    const doubled = initProducts.filter((p) => {
      return (guidedProducts.some((s) => s.siteId === p?.siteId));
    });

    // 実績登録の配列を取得（重複も含む）
    const guided = guidedProducts.map((i) => {
      if (doubled.some((o) => o.siteId === i.siteId && o.isScheduleGuidance === 1)) {
        return {
          ...i,
          isGuidance: 1,
          isScheduleGuidance: 1,
        };
      }
      return {
        ...i,
        isGuidance: 1,
        isScheduleGuidance: 0,
      };
    });
    return { ...targetObj, suggestProducts: [...guided] };
  };

  // --------------API周り----------------

  // ステートを更新
  // const initial = info;
  const set = (key, value) => {
    if (key === 'customerName') setCustomerName(value);
    if (key === 'suggestProducts') setProduct(value);
    if (key === 'guidanceGetUser') { // 案内獲得社員
      setGuidanceGetUser({
        userName: value.userName,
        userId: value.userId,
      });
    }
    if (key === 'guidanceUser') {
      setGuidanceUser({
        userName: value.userName,
        userId: value.userId,
      });
    }
    if (key === 'memo') setMemo(value);
  };

  const setDivision = (key, value) => {
    if (key === 'guidanceGetUser') { // 案内獲得社員
      setGuidanceGetUserDivision({
        divId: value.divId,
        divisionName: value.divisionName,
      });
    }
    if (key === 'guidanceUser') {
      setGuidanceUserDivision({
        divId: value.divId,
        divisionName: value.divisionName,
      });
    }
  };

  const setResults = (key, value) => {
    if (key === 'suggestProducts') {
      setGuidedProducts([...value]);
    }
    if (key === 'contactAccount1') {
      setContactAccount1({
        userName: value.userName,
        userId: value.userId,
        divId: value.divId,
        divisionName: value.divisionName,
      });
    }
    if (key === 'contactMinutes') setContactMinutes1(value);
    if (key === 'contactAccount2') {
      setContactAccount2({
        userName: value.userName,
        userId: value.userId,
      });
    }
    if (key === 'contactMinutes2') setContactMinutes2(value);
    if (key === 'attendContactAccount') {
      setAttendContactAccount({
        userName: value.userName,
        userId: value.userId,
      });
    }
    if (key === 'guideContactAccount') {
      setGuideContactAccount({
        userName: value.userName,
        userId: value.userId,
        divId: value.divId,
        divisionName: value.divisionName,
      });
    }
    if (key === 'guideContactMinutes') setGuideContactMinutes(value);
    if (key === 'accompanyingPerson') {
      setAccompanyingPerson({
        userName: value.userName,
        userId: value.userId,
      });
    }
  };

  // 接触対象のペア組みから値を割り出す関数 (https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2549)
  const contactTargetPatterns = () => {
    if (check5 && !check6 && !check7 && !check8 && !check9) return 1; // 1 本人
    if (!check5 && check6 && !check7 && !check8 && !check9) return 2; // 2 配偶者
    if (check5 && check6 && !check7 && !check8 && !check9) return 3; // 3 本人_配偶者
    if (!check5 && !check6 && check7 && !check8 && !check9) return 4; // 4 親
    if (check5 && !check6 && check7 && !check8 && !check9) return 5; // 5 本人_親
    if (!check5 && check6 && check7 && !check8 && !check9) return 6; // 6 配偶者_親
    if (check5 && check6 && check7 && !check8 && !check9) return 7; // 7 本人_配偶者_親
    if (!check5 && !check6 && !check7 && check8 && !check9) return 8; // 8 子供
    if (check5 && !check6 && !check7 && check8 && !check9) return 9; // 9 本人_子供
    if (!check5 && check6 && !check7 && check8 && !check9) return 10; // 10 配偶者_子供
    if (check5 && check6 && !check7 && check8 && !check9) return 11; // 11 本人_配偶者_子供
    if (!check5 && !check6 && check7 && check8 && !check9) return 12; // 12 親_子供
    if (check5 && !check6 && check7 && check8 && !check9) return 13; // 13 本人_親_子供
    if (!check5 && check6 && check7 && check8 && !check9) return 14;// 14 配偶者_親_子供
    if (check5 && check6 && check7 && check8 && !check9) return 15;// 15 本人_配偶者_親_子供
    if (!check5 && !check6 && !check7 && !check8 && check9) return 16;// 16 その他
    if (check5 && !check6 && !check7 && !check8 && check9) return 17;// 17 本人_その他
    if (!check5 && check6 && !check7 && !check8 && check9) return 18;// 18 配偶者_その他
    if (check5 && check6 && !check7 && !check8 && check9) return 19;// 19 本人_配偶者_その他
    if (!check5 && !check6 && check7 && !check8 && check9) return 20;// 20 親_その他
    if (check5 && !check6 && check7 && !check8 && check9) return 21;// 21 本人_親_その他
    if (!check5 && check6 && check7 && !check8 && check9) return 22;// 22 配偶者_親_その他
    if (check5 && check6 && check7 && !check8 && check9) return 23;// 23 本人_配偶者_親_その他
    if (!check5 && !check6 && !check7 && check8 && check9) return 24;// 24 子供_その他
    if (check5 && !check6 && !check7 && check8 && check9) return 25;// 25 本人_子供_その他
    if (!check5 && check6 && !check7 && check8 && check9) return 26;// 26 配偶者_子供_その他
    if (check5 && check6 && !check7 && check8 && check9) return 27;// 27 本人_配偶者_子供_その他
    if (!check5 && !check6 && check7 && check8 && check9) return 28;// 28 親_子供_その他
    if (check5 && !check6 && check7 && check8 && check9) return 29;// 29 本人_親_子供_その他
    if (!check5 && check6 && check7 && check8 && check9) return 30;// 30 配偶者_親_子供_その他
    if (check5 && check6 && check7 && check8 && check9) return 31;// 31 本人_配偶者_親_子供_その他
    return 32; // 32 未指定
  };

  // customerCodeから接触対象を復元する関数 update: 2023/02/14
  const restoreContactTarget = (customerCode = 32) => {
    // console.log('restoreContactTarget:customerCode', customerCode);
    // 本人
    const isCheck5 = [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31].includes(customerCode);
    // 配偶者
    const isCheck6 = [2, 3, 6, 7, 10, 11, 14, 15, 18, 19, 22, 23, 26, 27, 30, 31].includes(customerCode);
    // 親
    const isCheck7 = [4, 5, 6, 7, 12, 13, 14, 15, 20, 21, 22, 23, 28, 29, 30, 31].includes(customerCode);
    // 子供
    const isCheck8 = [8, 9, 10, 11, 12, 13, 14, 15, 24, 25, 26, 27, 28, 29, 30, 31].includes(customerCode);
    // その他
    const isCheck9 = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].includes(customerCode);
    setCheck5(isCheck5);
    setCheck6(isCheck6);
    setCheck7(isCheck7);
    setCheck8(isCheck8);
    setCheck9(isCheck9);
  };

  // 予約登録、予約更新用のデータ成型：schedules004 / schedules005を叩く際にリクエストとして送信するデータを成形
  const setRequestForAppointment = () => {
    // schedule004 / 005でリクエストするコード値の成形
    // swagger: schedule004/05を参照にボディパラメータのテンプレート
    const obj = {
      contactId: initialValue.contactId || null,
      actionId: initialValue.actionId || null,
      guideGetActionId: initialValue?.guideGetActionId || null,
      guidanceStartAt: date1.assignAt + ':00', // required
      guidanceEndAt: date2.assignAt + ':00', // required
      customerId: initialValue.customerId, // required
      guidanceStatusCode: Number(type),
      isGuidanceRegistered: 0, // 0: 案内実績登録なし 1:案内実績登録有
      guidanceGetUserId: guidanceGetUser?.userId || null,
      guidanceGetDivisionId: guidanceGetUserDivision?.divId || null,
      guidanceUserId: guidanceUser?.userId || null,
      guidanceDivisionId: guidanceUserDivision?.divId || null,
      memo: memo || null,
      isPairCheck: check1 ? 1 : null,
      isFinancialPlanner: check2 ? 1 : null,
      contactCode: 1, // required
      customerCode: initialValue.customerCode || 32,
      isReserved: Number(![7, 8].includes(Number(type))), // 源泉接触、ポーター予約の場合は予約なし: 0, それ以外は1
      porterResultCode: kinds, // 実績登録
      // isVisitedCompany: 0, // 実績登録 - 来社 0:なし, 1: あり
      // isGuided: 0, // 実績登録 - 案内 0:なし, 1: あり
      // callContactAccountId: '', // 実績登録
      // contactAccountId1: '', // 実績登録
      // contactAccountId2: '', // 実績登録
      // attendContactAccountId: '', // 実績登録
      // guideContactAccountId: '', // 実績登録
      // accompanyContactAccountId: '', // 実績登録
      // callContactMinutes: '', // 実績登録
      // contactMinutes1: '', // 実績登録
      // contactMinutes2: '', // 実績登録
      // guideContactMinutes: '', // 実績登録
      suggestProducts: [], // setProductHandlerで処理
    };

    let copyObj = {}; // requestで送るパラメータオブジェクトの入れ物

    // nullではない場合、copyObjに含める
    for (const key in obj) {
      if (obj[key] || obj[key] === 0) copyObj[key] = obj[key];
    }

    if (type !== 7 || type !== 8) {
      copyObj = setProductHandler(copyObj).targetObj;
    }

    return copyObj;
  };

  // 実績情報を登録するためのデータ成型：schedules004を叩く際にリクエストとして送信するデータを成形
  const setRequestForAchievement = () => {
    // swagger: schedule004を参照にボディパラメータのテンプレート
    const obj = {
      contactId: initialValue.contactId || null,
      actionId: initialValue.actionId || null,
      guideGetActionId: initialValue?.guideGetActionId || null,
      guidanceStartAt: date1.assignAt + ':00', // required
      guidanceEndAt: date2.assignAt + ':00', // required
      customerId: initialValue.customerId, // required
      guidanceStatusCode: Number(type),
      isGuidanceRegistered: 1, // 0: 案内実績登録なし 1:案内実績登録有
      guidanceGetUserId: guidanceGetUser?.userId || null,
      guidanceGetDivisionId: guidanceGetUserDivision?.divId || null,
      guidanceUserId: guidanceUser?.userId || null,
      guidanceDivisionId: guidanceUserDivision?.divId || null,
      memo, // メモはそのまま反映させる
      isReserved: Number(![7, 8].includes(Number(type))), // 源泉接触、ポーター予約の場合は予約なし: 0, それ以外は1
      isPairCheck: check1 ? 1 : null,
      isFinancialPlanner: check2 ? 1 : null,
      contactCode: 1, // required // TODO: スプレッドシート「APIパラメータ定義」によると、"1 = 案内 / 2 = 架電"となっているため、この画面では基本的にContactIdは1として扱うものかと想定している。確認要。
      customerCode: contactTargetPatterns(),
      porterResultCode: kinds, // 実績登録
      isVisitedCompany: visit || (initialValue.isVisitCompanyResult || 0), // 実績登録
      isGuided: guidance || (initialValue.isGuided || 0), // 実績登録
      callContactAccountId: null, // 実績登録？
      contactAccountId1: contactAccount1.userId ? contactAccount1.userId : initialValue.contactAccountId1, // 実績登録 - 接客者①
      contactDivisionId1: contactAccount1.divId ? contactAccount1.divId : initialValue.contactDivisionId1, // 実績登録 / 接客者①のディビジョン
      contactAccountId2: contactAccount2.userId ? contactAccount2.userId : initialValue.contactAccountId2, // 実績登録
      attendContactAccountId: attendContactAccount.userId ? attendContactAccount.userId : initialValue.attendContactAccountId, // 実績登録
      guideContactAccountId: guideContactAccount.userId ? guideContactAccount.userId : initialValue.guideContactAccountId, // 実績登録 - 案内者
      guideContactDivisionId: guideContactAccount.divId ? guideContactAccount.divId : initialValue.guideContactDivisionId, // 実績登録 - 案内者のディビジョン
      accompanyContactAccountId: accompanyingPerson.userId ? accompanyingPerson.userId : initialValue.accompanyContactAccountId, // 実績登録
      callContactMinutes: null, // 実績登録?
      contactMinutes1: contactMinutes1 || initialValue.contactMinutes, // 実績登録
      contactMinutes2: contactMinutes2 || initialValue.contactMinutes2, // 実績登録
      guideContactMinutes: guideContactMinutes || initialValue.guideContactMinutes, // 実績登録
      suggestProducts: [], // achievementProductHandlerで処理
    };

    let copyObj = {};
    for (const key in obj) {
      if (obj[key] || obj[key] === 0 || key === 'memo') copyObj[key] = obj[key];
    }

    copyObj = achievementProductHandler(copyObj);
    return copyObj;
  };

  // 案内情報を削除
  const deleteGuidanceById = (deleteCode) => {
    deleteGuidanceByGuidanceIdFunction(guidanceId || guidanceIdHistory, deleteCode);
    handleCloseHistory();
    handleCloseSelect();
    onClose();
    if (onCloseTypesDrawer) onCloseTypesDrawer();
  };

  // -------------------- 関数 --------------------
  const [sendingRequest, setSendingRequest] = useState(false);
  const [sendingAchievement, setSendingAchievement] = useState(false);

  // 予約登録押下時
  const reserveRegister = async () => {
    // start a request for schedules004 / schedules005
    setSendingRequest(true);

    // カスタマイズ関数(前画面からpropsで指定)
    if (setClickInfoRegister) {
      setClickInfoRegister();
    }
  };

  // 日時変更時
  const dateCreate = (value) => {
    const year = value.getFullYear();
    const month = ('00' + (value.getMonth() + 1)).slice(-2);
    const day = ('00' + value.getDate()).slice(-2);
    const hour = ('00' + value.getHours()).slice(-2);
    const min = ('00' + value.getMinutes()).slice(-2);
    const newDate = year + '/' + month + '/' + day + ' ' + hour + ':' + min;
    return newDate;
  };
  const dateChange1 = (value) => {
    if (value === '') {
      setDate1({ assignAt: '' });
    } else {
      const startDate = new Date(value);
      // 開始日を設定
      setDate1({ assignAt: dateCreate(startDate) });
      // 終了日を設定
      startDate.setHours(startDate.getHours() + 2);// 2時間プラス
      setDate2({ ...date2, assignAt: dateCreate(startDate) });
    }
  };
  const dateChange2 = (value) => {
    if (value === '') {
      setDate2({ ...date2, assignAt: '' });
    } else {
      const startDate = new Date(date1.assignAt);
      const endtDate = new Date(value);
      // 終了日を設定
      setDate2({ ...date2, assignAt: dateCreate(endtDate) });
      // 終了日が開始日より過去の場合、2時間マイナスした開始日を設定
      if (startDate > endtDate) {
        const change = new Date(value);
        change.setHours(change.getHours() - 2);// 2時間マイナス
        setDate1({ ...date1, assignAt: dateCreate(change) });
      }
    }
  };
  // 現在の日時より過去の場合、実績を表示する
  const dateClose = () => {
    if ((nowDate > date2.assignAt) && reserveFlg) {
      setDisplay1(true);
    } else {
      setDisplay1(false);
    }
  };

  // 表示制御（接客者、同席者、物件、案内者、同行者）
  const displayControl = (v, g) => {
    if (v === '') {
      if (g === 0) { // 案内なし
        setAchIac({
          ...achIac,
          propertyName: true,
          guide: true,
          accompanyingPerson: true,
        });
      } else { // 案内あり
        setAchIac({
          ...achIac,
          propertyName: false,
          guide: false,
          accompanyingPerson: false,
        });
      }
    } else if (g === '') {
      if (v === 0) { // 来社なし
        setAchIac({
          ...achIac,
          customerService1: true,
          customerService2: true,
        });
      } else { // 来社あり
        setAchIac({
          ...achIac,
          customerService1: false,
          customerService2: false,
        });
      }
    } else if (v === 0 && g === 0) { // 来社なし 案内なし
      setAchIac({
        ...achIac,
        customerService1: true,
        customerService2: true,
        propertyName: true,
        guide: true,
        accompanyingPerson: true,
      });
    } else if (v === 0 && g === 1) { // 来社なし 案内あり
      setAchIac({ // 来社なし 案内あり
        ...achIac,
        customerService1: true,
        customerService2: true,
        propertyName: false,
        guide: false,
        accompanyingPerson: false,
      });
    } else if (v === 1 && g === 0) { // 来社あり 案内なし
      setAchIac({
        ...achIac,
        customerService1: false,
        customerService2: false,
        propertyName: true,
        guide: true,
        accompanyingPerson: true,
      });
    } else { // 来社あり 案内あり
      setAchIac({
        ...achIac,
        customerService1: false,
        customerService2: false,
        propertyName: false,
        guide: false,
        accompanyingPerson: false,
      });
    }
  };

  // 表示を変更
  const displayChange = (value) => {
    switch (Number(value)) {
      case 1: // 来案(1)
        // 来社あり
        setVisit(1);
        // 案内あり
        setGuidance(1);
        // 表示制御
        displayControl(1, 1);
        break;
      // 訪案(2)
      case 2:
        // 来社なし
        setVisit(0);
        // 案内あり
        setGuidance(1);
        // 表示制御
        displayControl(0, 1);
        break;
      // 待案(3)
      case 3:
        // 来社なし
        setVisit(0);
        // 案内あり
        setGuidance(1);
        // 表示制御
        displayControl(0, 1);
        break;
      // 来店接客のみ(5)
      case 5:
        // 来社あり
        setVisit(1);
        // 案内なし
        setGuidance(0);
        // 表示制御
        displayControl(1, 0);
        break;
      // 資料渡し(6)
      case 6:
        // 来社なし
        setVisit(0);
        // 案内なし
        setGuidance(0);
        // 表示制御
        displayControl(0, 0);
        break;
      // 源泉接触(7)
      case 7:
        // 来社なし
        setVisit(0);
        // 案内あり
        setGuidance(1);
        // 表示制御
        displayControl(0, 1);
        // 揃い、FPをクリア
        setCheck1(false);
        setCheck2(false);
        break;
      // ポーター接触(8)
      case 8:
        // 来社なし
        setVisit(0);
        // 案内なし
        setGuidance(0);
        // 表示制御
        displayControl(0, 0);
        // 「種類」を「店舗にて資料渡しのみ」に変更
        setKinds(3);
        // 揃い、FPをクリア
        setCheck1(false);
        setCheck2(false);
        break;
      default:
        setVisit('');
        setGuidance('');
        break;
    }
  };

  // 案内種別変更時
  const typeChange = (value) => {
    setType(value);

    // 初期化
    setAchIac(achIacInitial);

    // 表示変更
    displayChange(value);
  };

  // 案内情報 揃い変更時
  const checked1Change = (value) => {
    setCheck1(value);
  };
  // 案内情報 FP変更時
  const checked2Change = (value) => {
    setCheck2(value);
  };

  // 次の案内予約も一緒に登録する変更時
  const checked3Change = (value) => {
    setCheck3(value);
  };

  // 実績 揃い変更時
  const checked4Change = (value) => {
    setCheck4(value);
  };

  // 接触対象変更時 本人
  const checked5Change = (value) => {
    setCheck5(value);
  };
  // 接触対象変更時 配偶者
  const checked6Change = (value) => {
    setCheck6(value);
  };
  // 接触対象変更時 親
  const checked7Change = (value) => {
    setCheck7(value);
  };
  // 接触対象変更時 子供
  const checked8Change = (value) => {
    setCheck8(value);
  };
  // 接触対象変更時 その他
  const checked9Change = (value) => {
    setCheck9(value);
  };

  // 種類変更時
  const kindsChange = (value) => {
    setKinds(Number(value));
    // ポーター接触
    if (Number(type) === 8) {
      // 初期化
      setAchIac(achIacInitial);
      // 種類
      switch (Number(value)) {
        // 来案
        case 1:
          // 来社あり
          setVisit(1);
          // 案内あり
          setGuidance(1);
          // 表示制御
          displayControl(1, 1);
          break;
        // 来店接客のみ
        case 2:
          // 来社あり
          setVisit(1);
          // 案内なし
          setGuidance(0);
          // 表示制御
          displayControl(1, 0);
          break;
        // 店舗にて資料渡しのみ
        case 3:
          // 来社なし
          setVisit(0);
          // 案内なし
          setGuidance(0);
          // 表示制御
          displayControl(0, 0);
          break;
        default:
          break;
      }
    }
  };

  // 来社変更時
  const visitChange = (value) => {
    setVisit(Number(value));
    // 表示制御
    displayControl(Number(value), guidance);
  };

  // 案内変更時
  const guidanceChange = (value) => {
    setGuidance(Number(value));
    // 表示制御
    displayControl(visit, Number(value));
  };

  // 初期化
  const setInitial = () => {
    // 実績
    setContactAccount1({
      userName: '',
      userId: '',
      divId: '',
      divisionName: '',
    });
    setContactMinutes1('');
    setContactAccount2({
      userName: '',
      userId: '',
    });
    setContactMinutes2('');
    setAttendContactAccount({
      userName: '',
      userId: '',
    });
    setGuideContactAccount({
      userName: '',
      userId: '',
      divId: '',
      divisionName: '',
    });
    setGuideContactMinutes('');
    setAccompanyingPerson({
      userName: '',
      userId: '',
    });
    setGuidedProducts([]);

    // 予約
    setProduct('');
    setCustomerName('');
    // setGuidanceGetUser({
    //   userId: '',
    //   userName: '',
    // });
    // setGuidanceUser({
    //   userName: '',
    //   userId: '',
    // });
    setGuidanceGetUserDivision({
      divId: '',
      divisionName: '',
    });
    setGuidanceUserDivision({
      divId: '',
      divisionName: '',
    });
    setMemo('');
    setType('');
    setVisit('');
    setGuidance('');
    setKinds(0);
    setDisplay1(false);
    setDisplay2(false);
    setCheck3(false);
    setDisabled1(false);
    setDisabled2(false);
    setReserveFlg(false);
    setInactive1(false);
    setInactive2(false);
    setInitialValue(registerInitial);
    // 日時
    setDate1({ ...date1, assignAt: registerInitial.guidanceStartAt });
    setDate2({ ...date2, assignAt: registerInitial.guidanceEndAt });
  };

  // 予約登録（処理実行）
  const reserveStart = async () => {
    console.log('予約登録');
    reserveRegister();
  };
  // 実績登録（続けて登録あり）
  const achievementStart1 = () => {
    console.log('実績登録');
    setSendingAchievement(true); // useEffectでschedules004を叩くためのトリガー
    // カスタマイズ関数(前画面からpropsで指定)
    if (setClickInfoRegister) {
      setClickInfoRegister();
    }
  };
  // 実績登録（続けて登録なし）
  const achievementStart2 = async () => {
    console.log('実績登録（続けて登録なし）');
    setSendingAchievement(true);// useEffectでschedules004を叩くためのトリガー
    // カスタマイズ関数(前画面からpropsで指定)
    if (setClickInfoRegister) {
      setClickInfoRegister();
    }
  };
  // 確認画面
  const confirmOpen2 = () => {
    dispatch(changeConfirmMessage({
      title: '続けて案内予約をしますか？',
      msgList: [],
      buttons: [
        {
          label: 'CANCEL',
          set: achievementStart2,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: achievementStart1,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };
  // 実績登録（確認） 続けて登録
  const achievementConfirm2 = () => {
    if (check3) {
      // handleOpen3();
      confirmOpen2();
    } else {
      achievementStart2();
    }
  };
  // 確認画面
  const confirmOpen1 = () => {
    dispatch(changeConfirmMessage({
      title: ['ルールで決まっている7日よりも前の予約を', '登録しようとしていますが、よろしいですか？'],
      msgList: [],
      buttons: [
        {
          label: 'CANCEL',
          set: () => { console.log('CANCEL'); },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: achievementConfirm2,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };
  // 実績登録（確認） 日付
  const achievementConfirm1 = () => {
    const dateAgo = new Date();
    dateAgo.setDate(dateAgo.getDate() - 7);
    const dateStart = new Date(date1.assignAt);
    if (dateAgo > dateStart) {
      // handleOpen2();
      confirmOpen1();
    } else {
      achievementConfirm2();
    }
  };

  // 背景クリックにてウィンドウを閉じる
  const runCloseBg = () => {
    onClose();
    setInitial();
    setInitialValue(registerInitial);
  };

  // 背景クリック時
  const closeBg = () => {
    const requestParam = setRequestForAppointment();
    if (JSON.stringify(localParams.current) !== JSON.stringify(requestParam)) {
      // 変更があった場合
      dispatch(changeConfirmMessage({
        title: ['接触・案内情報が変更されています'],
        msgList: ['ウィンドウを閉じた場合、変更した内容が保存されません。', 'よろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: () => { console.log('CANCEL'); },
            classes: baseClasses.buttonsSecondary,
          },
          {
            label: 'OK',
            set: runCloseBg,
            classes: baseClasses.buttonsPrimary,
          },
        ],
      }));
    } else {
      runCloseBg();
    }
  };

  // ボタンの必須制御関数（予約登録）
  const controlReserveBtnStatus = () => {
    if (!date1.assignAt) {
      // 案内時間From
      setDisabled1(true);
    } else if (!date2.assignAt) {
      // 案内時間To
      setDisabled1(true);
    } else if (!type) {
      // 案内種別
      setDisabled1(true);
    } else if (!guidanceGetUser.userId) {
      // 獲得者
      setDisabled1(true);
    } else if (!guidanceUser.userId) {
      // 案内予定者
      setDisabled1(true);
    } else {
      setDisabled1(false);
    }
  };

  // ボタンの必須制御関数（実績登録）
  const controlAchievementBtnStatus = () => {
    if (date1.assignAt === '') {
      // 案内時間From
      setDisabled2(true);
    } else if (date2.assignAt === '') {
      // 案内時間To
      setDisabled2(true);
    } else if (!type) {
      // 案内種別
      setDisabled2(true);
    } else if (initialValue.customerName === '') {
      // 顧客名
      setDisabled2(true);
    } else if (guidanceGetUser.userName === '') {
      // 獲得者
      setDisabled2(true);
    } else if (!guidanceUser.userId && Number(type) !== 6 && Number(type) !== 7 && Number(type) !== 8) {
      // 案内予定者
      setDisabled2(true);
    } else if (visit === '') {
      // 来社
      setDisabled2(true);
    } else if (guidance === '') {
      // 案内
      setDisabled2(true);
    } else if (guidedProducts && guidedProducts.length < 1 && guidance === 1) {
      // 物件
      setDisabled2(true);
    } else if (!contactAccount1.userId && visit === 1) {
      // 接客者1
      setDisabled2(true);
    } else if (!guideContactAccount.userId && guidance === 1) {
      // 案内者
      setDisabled2(true);
    } else {
      setDisabled2(false);
    }
  };

  // 案内種別 活性、非活性の制御
  const changeStatusWithType = () => {
    // ポーター接触の場合、種類を表示
    switch (Number(type)) {
      case 8:
        setDisplay2(true);
        break;
      default:
        setDisplay2(false);
        break;
    }
    // 案内種別 活性、非活性の制御
    switch (Number(type)) {
      case 5:
        // 来店接客のみ
        setInactive1(false);
        setInactive2(true);
        break;
      case 6:
        // 資料渡し
        setInactive1(false);
        setInactive2(true);
        break;
      default:
        setInactive1(false);
        setInactive2(false);
        break;
    }
  };

  // 接触履歴リストのアイテムをクリックすると案内情報が更新される
  const clickHistoryCard = async (id) => {
    setIsInitialRegistration(false); // 新規登録ではないと明示する
    // schedules003叩く
    await getGuidanceInfoByGuidanceIdFunction(id);

    setGuidanceIdHistory(id);
    if (isSP) {
      handleCloseHistory();
      handleCloseSelect();
    }
  };

  // -------------------- input --------------------
  // 揃い、FP
  const checked1 = [
    {
      label: '揃い',
      name: 'val1',
      checked: check1,
      set: checked1Change,
    },
    {
      label: 'FP',
      name: 'val2',
      checked: check2,
      set: checked2Change,
    },
  ];
  // 揃い
  const checked2 = [
    {
      label: '揃い',
      name: 'val1',
      checked: check4,
      set: checked4Change,
    },
  ];
  // 次の案内予約も一緒に登録する
  const checked3 = [
    {
      label: '次の案内予約も一緒に登録する',
      name: 'val1',
      checked: check3,
      set: checked3Change,
    },
  ];
  // 接触対象
  const checked4 = [
    {
      label: '本人',
      name: 'val1',
      checked: check5,
      set: checked5Change,
    },
    {
      label: '配偶者',
      name: 'val2',
      checked: check6,
      set: checked6Change,
    },
    {
      label: '親',
      name: 'val3',
      checked: check7,
      set: checked7Change,
    },
    {
      label: '子ども',
      name: 'val4',
      checked: check8,
      set: checked8Change,
    },
    {
      label: 'その他',
      name: 'val5',
      checked: check9,
      set: checked9Change,
    },
  ];

  // データをセット
  const input1 = {
    initialVal: initialValue,
    customerName,
    product,
    guidanceGetUser, // 案内獲得社員
    guidanceUser, // 案内予定社員
    memo,
    setState: set,
    setStateDivision: setDivision,
    dat1: date1,
    datCha1: dateChange1,
    dat2: date2,
    datCha2: dateChange2,
    datClose: dateClose,
    typ: type,
    typCha: typeChange,
    che1: checked1,
    ina1: inactive1,
    openHistory: handleOpenHistory,
    responseHeader,
    isInitialRegistration,
    reserveFlg,
  };
  const input2 = {
    initialVal: initialValue,
    guidedProducts,
    contactAccount1,
    contactMinutes1,
    contactAccount2,
    contactMinutes2,
    attendContactAccount,
    guideContactAccount,
    guideContactMinutes,
    accompanyingPerson,
    setState: setResults,
    setStateDivision: setDivision,
    vis: visit,
    visCha: visitChange,
    gui: guidance,
    guiCha: guidanceChange,
    che2: checked2,
    che3: checked3,
    che4: checked4,
    kin: kinds,
    kinCha: kindsChange,
    dis1: display1,
    dis2: display2,
    ina2: inactive2,
    acIa: achIac,
    dat1: date1,
    dat2: date2,
    responseHeader,
    isInitialRegistration,
    reserveFlg,
  };

  // -------------------- useEffect --------------------

  useEffect(async () => {
    // 新規登録の場合
    if (!guidanceId && guidanceId !== 0) {
      setInitial();
      setInitialValue(registerInitial);
      setIsInitialRegistration(true); // 新規登録であると明示する
      setReserveFlg(false);
    } else {
      setIsInitialRegistration(false);
      setReserveFlg(true);
    }

    // 既存の案内情報を更新 / 実績登録する場合
    if (guidanceId) {
      setIsInitialRegistration(false);
      setReserveFlg(true);
      // guidanceIdがある場合、schedules003叩く
      await getGuidanceInfoByGuidanceIdFunction(guidanceId);
    }
    setCustomerName(customer.customerName ? customer.customerName : `${[customer.lastName, customer.firstName].filter(Boolean).join(' ')}`);
  }, [open]);

  // schedule004/005を叩くためのuseEffect
  useEffect(async () => {
    let requestParam;
    if (sendingRequest) requestParam = await setRequestForAppointment();

    // 新規登録フラッグがtrue
    if (sendingRequest && isInitialRegistration) {
      postGuidanceFunction(requestParam);
      setSendingRequest(false); // finish the request
      onClose();
    }
    // 新規登録フラッグがfalse
    if (sendingRequest && !isInitialRegistration) {
      putGuidanceByGuidanceIdFunction(guidanceIdHistory || guidanceId, requestParam);
      setSendingRequest(false); // finish the request
      onClose();
    }
  }, [sendingRequest]);

  // schedule004を叩くためのuseEffect
  useEffect(async () => {
    let requestParam;
    if (sendingAchievement) requestParam = await setRequestForAchievement();

    /**
     * check3 : (boolean) 続けて登録するか否か
     * isInitialRegistration: (boolean) 初期登録か否か
     * sendingAchievement: (boolean) 実績登録ボタンからrequestを送信しているか否か
     */
    if (sendingAchievement && !check3 && !isInitialRegistration) {
      console.log('requestParam:guidanceId//////', guidanceId, guidanceIdHistory, requestParam);
      // 接触履歴から更新する場合nullになるため、guidanceIdHistoryを追加 / guidanceIdHistoryを優先
      putGuidanceByGuidanceIdFunction(guidanceIdHistory || guidanceId, requestParam);
      onClose();
      setInitial();
      setSendingAchievement(false); // finish the request
    }

    if (sendingAchievement && check3 && !isInitialRegistration) {
      // 接触履歴から更新する場合nullになるため、guidanceIdHistoryを追加 / guidanceIdHistoryを優先
      putGuidanceByGuidanceIdFunction(guidanceIdHistory || guidanceId, requestParam);
      setIsInitialRegistration(true); // 続けて登録するので初期登録flgをtrueに変更
      setInitial(); // 値を初期化
      setInitialValue(registerInitial); // 値を初期化
      setSendingAchievement(false); // finish the request
    }

    if (sendingAchievement && isInitialRegistration && !check3) {
      postGuidanceFunction(requestParam);
      setInitial(); // 値を初期化
      setInitialValue(registerInitial); // 値を初期化
      setSendingAchievement(false); // finish the request
      onClose();
    }

    if (sendingAchievement && isInitialRegistration && check3) {
      postGuidanceFunction(requestParam);
      setIsInitialRegistration(true); // 続けて登録するので初期登録flgをtrueに変更
      setInitial(); // 値を初期化
      setInitialValue(registerInitial); // 値を初期化
      setSendingAchievement(false); // finish the request
    }
  }, [sendingAchievement]);

  useEffect(() => {
    // 実績登録の案内者の対応時間
    // 3600000 ms = 1hour
    // 60000 ms = 1min
    // 1000 ms = 1sec
    // console.log('change initialValue///', initialValue);
    const guideDuration = Math.floor(Math.abs(new Date(initialValue.guidanceEndAt) - new Date(initialValue.guidanceStartAt)) / 60000);

    if (Number(initialValue.isReserved) === 1) setReserveFlg(true);
    // setReserveFlg(true); // FIXME：schedule004にて意図しない422エラーが発生しており、isReservedを更新できないため検証用に設置。結合完了時に消去

    // 案内情報 //////////////////////////////
    // APIから値を取得してinitialの値を取得した際にstate更新
    setDate1({
      assignAt: dateCreate(new Date(initialValue.guidanceStartAt)),
    });
    setDate2({
      assignAt: dateCreate(new Date(initialValue.guidanceEndAt)),
    });
    // 案内情報：獲得者初期値セット
    setGuidanceGetUser({
      userId: initialValue.guidanceGetUserId || '',
      userName: initialValue.guidanceGetUserName || '',
    });
    // 案内情報：獲得者（組織）初期値セット
    setGuidanceGetUserDivision({
      divId: initialValue.guidanceGetDivisionId || '',
      divisionName: initialValue.guidanceGetDivisionName || '',
    });
    // 案内情報：案内予定者
    setGuidanceUser({
      userId: initialValue.guidanceUserId || '',
      userName: initialValue.guidanceUserName || '',
    });
    // 案内情報：案内予定者（組織）
    setGuidanceUserDivision({
      divId: initialValue.guidanceDivisionId || '',
      divisionName: initialValue.guidanceDivisionName || '',
    });
    // 案内情報：予約段階での予約予定物件
    setProduct(initialValue.suggestProducts.filter((f) => {
      return f.isScheduleGuidance === 1;
    }));
    // 案内情報：メモ
    setMemo(initialValue.memo);

    setType(initialValue.scheduleStatusCode);
    // 実績の揃い値初期セット
    // eslint-disable-next-line no-unneeded-ternary
    setCheck4(initialValue.isPairCheck === 1 ? true : false);
    // 案内情報の揃い値初期セット
    // eslint-disable-next-line no-unneeded-ternary
    setCheck1(initialValue.isPairCheck === 1 ? true : false);
    // 案内情報のFP値初期セット
    // eslint-disable-next-line no-unneeded-ternary
    setCheck2(initialValue.isFinancialPlanner === 1 ? true : false);

    // 実績情報 //////////////////////////////
    // 来社 update: 2023/02/14
    setVisit(initialValue.isVisitCompanyResult);
    // 案内 update: 2023/02/14
    setGuidance(initialValue.isGuidanceResult);
    // 来社と案内の関連する項目の活性・非活性 update: 2023/02/14
    displayControl(initialValue.isVisitCompanyResult, initialValue.isGuidanceResult);
    // 実績登録時の物件初期セット
    setGuidedProducts(initialValue.suggestProducts.filter((m) => m.isGuided === 1));
    // 実績：接客者1初期値セット
    setContactAccount1({
      userId: initialValue.contactAccountId1 || responseHeader.userId,
      userName: initialValue.contactAccountName1 || responseHeader.userName,
      divId: initialValue.contactDivisionId1 || responseHeader.mainDivision.id,
      divisionName: initialValue.contactAccountDivName1 || responseHeader.mainDivision.name,
    });
    // 実績：接客時間1初期値セット
    setContactMinutes1(initialValue.contactMinutes);
    // 実績：接客者2初期値セット
    setContactAccount2({
      userId: initialValue.contactAccountId2,
      userName: initialValue.contactAccountName2,
    });
    // 実績：接客時間2初期値セット
    setContactMinutes2(initialValue.contactMinutes2);
    // 実績：同席者初期値セット
    setAttendContactAccount({
      userName: initialValue.attendContactAccountName,
      userId: initialValue.attendContactAccountId,
    });
    // console.log('---//', responseHeader.mainDivision.id, responseHeader.mainDivision.name);
    // 実績：案内者初期値セット
    setGuideContactAccount({
      userName: initialValue.guideContactAccountName || responseHeader.userName,
      userId: initialValue.guideContactAccountId || responseHeader.userId,
      divId: initialValue.guideContactDivisionId || responseHeader.mainDivision.id,
      divisionName: initialValue.guideContactAccountDivName || responseHeader.mainDivision.name,
    });
    // 実績：案内時間初期値セット
    setGuideContactMinutes(initialValue.guideContactMinutes || guideDuration);
    // 実績：同行者ネーム初期値セット
    setAccompanyingPerson({
      userName: initialValue.accompanyContactAccountName,
      userId: initialValue.accompanyContactAccountId,
    });
    // 実績：接触対象の初期値セット update: 2023/02/14

    restoreContactTarget(initialValue.customerCode);

    console.log('initialValue', initialValue);

    setInitialValueAfterCount(initialValueAfterCount + 1);
  }, [initialValue]);

  // initialValueのuseEffect処理後
  useEffect(() => {
    // 背景クリックで閉じる時に比較するために保持
    localParams.current = setRequestForAppointment();
  }, [initialValueAfterCount]);

  // guidanceInfoに値が入ったときに初期値とロード状態を管理するstateを更新する
  useEffect(() => {
    if (!isInitialRegistration && Object.keys(guidanceInfo).length > 0) {
      console.log('guidanceInfo///', guidanceInfo);
      setInitialValue(guidanceInfo);
    }
    // guidanceIdがある状態（新規でない場合）で終了時間が現在より前だった場合に実績を表示する
    if (guidanceId && nowDate > new Date(guidanceInfo.guidanceEndAt)) {
      setDisplay1(true);
    } else {
      setDisplay1(false);
    }
  }, [guidanceInfo]);

  useEffect(() => {
    // 契約済み
    switch (initialValue.customerStatusCode) {
      case 10:
        setContract(true);
        break;
      default:
        setContract(false);
        break;
    }

    // ボタンの必須制御（予約登録）
    controlReserveBtnStatus();

    // ボタンの必須制御（実績登録）
    controlAchievementBtnStatus();

    // 案内種別 活性、非活性の制御
    // ポーター接触の場合、種類を表示
    changeStatusWithType();
  });

  // ロード時
  useEffect(() => {
    // 予約登録状態セット（reserveFlg）
    if (Number(initialValue.isReserved) === 1) setReserveFlg(true);
    // 実績の揃い値セット
    // eslint-disable-next-line no-unneeded-ternary
    setCheck4(initialValue.isPairCheck === 1 ? true : false);
    // 案内情報の揃い値セット
    // eslint-disable-next-line no-unneeded-ternary
    setCheck1(initialValue.isPairCheck === 1 ? true : false);
    // 案内情報のFP値セット
    // eslint-disable-next-line no-unneeded-ternary
    setCheck2(initialValue.isFinancialPlanner === 1 ? true : false);
  }, []);

  // 失活時に初期化
  useEffect(() => {
    if (inactive1) {
      // 案内情報：予約段階での予約予定物件
      setProduct('');
      // 案内情報：案内予定者
      setGuidanceUser({
        userId: '',
        userName: '',
      });
    }
  }, [inactive1]);
  useEffect(() => {
    if (achIac.customerService1) {
      // 接客者1
      setContactAccount1({
        userName: '',
        userId: '',
        divId: '',
        divisionName: '',
      });
      setContactMinutes1('');
    }
    if (achIac.customerService2) {
      // 接客者2
      setContactAccount2('');
      setContactMinutes2('');
      // 同席者
      setAttendContactAccount({
        userName: '',
        userId: '',
      });
    }
    if (achIac.guide) {
      // 案内者
      setGuideContactAccount({
        userName: '',
        userId: '',
        divId: '',
        divisionName: '',
      });
      setGuideContactMinutes('');
    }
    if (achIac.accompanyingPerson) {
      // 同行者
      setAccompanyingPerson({
        userName: '',
        userId: '',
      });
    }
  }, [achIac]);

  // 実績表示時
  useEffect(() => {
    if (display1 && guidanceInfo.isVisitCompanyResult === null) {
      displayChange(type);
    }
  }, [display1]);

  if (isSP) {
    contents = (
      <Grid>
        <Drawer
          anchor="bottom"
          open={openSelect}
          onClose={handleCloseSelect}
          className={classes.drawer1}
        >
          <InfoRegisterButton
            onClose={closeBg}
            reserveFlg={reserveFlg}
            handleOpenHistory={handleOpenHistory}
            deleteGuidanceById={deleteGuidanceById}
            actionScheduleFlg
          />
        </Drawer>
        <Drawer
          anchor="right"
          open={openHistory}
          onClose={handleCloseHistory}
          className={classes.drawer2}
        >
          {/* 案内履歴 */}
          <InfoRegisterHistory
            // infoList={infoList}
            clickHistoryCard={clickHistoryCard} // 接触履歴リストのアイテムをクリックすると案内情報が更新される
            openHistory={openHistory}
            initialValue={initialValue}
            customer={customer}
          />
        </Drawer>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.history}>
        <InfoRegisterHistory
          // infoList={infoList}
          clickHistoryCard={clickHistoryCard} // 接触履歴リストのアイテムをクリックすると案内情報が更新される
          customer={customer}
          display1={display1}
        />
      </Grid>
    );
  }

  const body = () => {
    return (
      <>
        <Typography
          className={`${baseClasses.title2} ${classes.header} ${baseClasses.onlySp}`}
        >
          <Button
            className={baseClasses.openButton}
            onClick={handleOpenSelect}
          />
          案内・接触登録
          <Button
            className={baseClasses.closeButton}
            onClick={onClose}
          />
        </Typography>
        <Grid className={classes.content}>
          {/* 接触・案内情報登録ウインドウの入力（PCの時は左側の表示） */}
          <Grid className={classes.info}>
            <InfoRegisterStatus
              contract={contract}
            />
            <Grid className={baseClasses.onlyPc}>
              {/* 削除ボタンなど */}
              <InfoRegisterButton
                onClose={closeBg}
                reserveFlg={reserveFlg}
                openActionSchedule={openActionSchedule}
                setOpenActionSchedule={setOpenActionSchedule}
                deleteGuidanceById={deleteGuidanceById}
                actionScheduleFlg
              />
            </Grid>
            {/* 予約 */}
            <InfoRegisterGuidance
              input={input1}
            />
            {/* 実績 */}
            <InfoRegisterAchievement
              input={input2}
            />
          </Grid>
          {/* 接触・案内情報登録ウインドウの履歴（PCの時は右側の表示） */}
          {contents}
        </Grid>
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeBg}
      title={['接触・案内情報登録ウインドウ', '']}
      headerClassName={baseClasses.onlyPc}
      bodyClassName={classes.wrapper}
      footerClassName={`${display1 ? 'achievement' : ''} ${classes.footer}`}
      buttons={[{
        label: '予約登録',
        onClick: reserveStart,
        className: `${baseClasses.buttonsPrimary} ${classes.footerBtn} ${disabled1 ? 'disabled' : ''}`,
      }, {
        label: '実績登録',
        onClick: achievementConfirm1,
        className: `${baseClasses.buttonsPrimary} ${classes.footerBtn} ${disabled2 ? 'disabled' : ''}`,
      }]}
      maxWidth={1160}
    >
      {body()}
    </Modal>
  );
}
