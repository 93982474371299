import React, { useCallback } from 'react';
import { Grid, FormControl, InputLabel } from '@material-ui/core';
import 'moment/locale/ja';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector } from 'react-redux';

import InputFormMain from './parts/userBasicMain';
import InputFormOption from './parts/userBasicMainOption';
import InputFromMemo from './parts/userBasicMemo';
import InputFormOrg from './parts/userBasicOrg';
import UserTextField from './parts/userTextField';
import CustomSelect from '../eleCommon/customSelect';
import UserMultipleDate from './parts/userMultipleDate';
import UserSingleDate from './parts/userSingleDate';
import InputFormAuthority from './parts/userBasicAuthority';

function UserBasic(props) {
  const {
    setUser,
    validate,
    userListFunction,
    userDivListFunction,
    createMode,
    updateUserFunction,
    deleteUserDivision,
    errorCallback,
    updateDivisionFunction,
  } = props;

  const userDetail = useSelector((state) => state.user.userDetail);
  const getComponent = useCallback((data) => {
    const asterisk = data.required ? '＊' : '';
    switch (data.type) {
      case 'text':
      case 'number':
        return <UserTextField initial={data} validate={validate} />;
      case 'flg':
        return (
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink>
              {data.obj.title}
              <span style={{ color: 'red', fontSize: '22px' }}>{asterisk}</span>
            </InputLabel>
            <CustomSelect initial={data} />
          </FormControl>
        );
      case 'multipleDate':
        return <UserMultipleDate dateMultiple={data} />;
      case 'date':
        return <UserSingleDate dateSingle={data} />;
      default:
        return <div>対応しない要素です</div>;
    }
  });

  const getAuthorityComponent = () => {
    return (
      <InputFormAuthority
        getComponent={getComponent}
        state={userDetail}
        setState={setUser}
      />
    );
  };

  return (
    <Grid container>
      <InputFormMain
        getComponent={getComponent}
        state={userDetail}
        setState={setUser}
        valid={validate}
        errorCallback={errorCallback}
      />
      <InputFormOption
        getComponent={getComponent}
        state={userDetail}
        setState={setUser}
        errorCallback={errorCallback}
      />
      <InputFromMemo
        getComponent={getComponent}
        state={userDetail}
        setState={setUser}
      />
      <InputFormOrg
        getComponent={getComponent}
        state={userDetail}
        setState={setUser}
        userListFunction={userListFunction}
        userDivListFunction={userDivListFunction}
        createMode={createMode}
        updateUserFunction={updateUserFunction}
        deleteUserDivision={deleteUserDivision}
        updateDivisionFunction={updateDivisionFunction}
      />
      {getAuthorityComponent(userDetail)}
    </Grid>
  );
}

export default UserBasic;
