/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import ResisterHouseWindow from './resisterHouseWindow/resisterHouseWindow';
import ResisterHouseWindowSp from './resisterHouseWindow/sp/resisterHouseWindowSp';
import ResHouseTable from './resHousetable/resHousetable';
import ResHouseList from './resHousetable/sp/resHouseList';
import PullRefComponent from '../pullRefComponent';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 102,
      paddingBottom: 160,
      minHeight: 'calc(100vh - 60px)',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      padding: '0px 9px',
      height: '50px !important',
    },
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '16px',
    },
  },
  button: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: 1,
      minWidth: 'auto',
      padding: '0',
      position: 'fixed',
      bottom: '84px',
      right: '24px',
      width: '56px',
      height: '56px',
      backgroundColor: theme.palette.primaryColor,
      borderRadius: '50%',
      boxShadow:
        '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    },
  },
  addButton: {
    fontFamily: 'Roboto',
    color: theme.palette.primaryColor,
  },
  addIcon: {
    width: '15px',
    height: '15px',
    color: theme.palette.primaryColor,
    marginRight: '3px',
    [commonTheme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      color: '#fff',
    },
  },
  txt: {
    fontSize: 15,
    lineHeight: '160px',
    letterSpacing: 0,
    textAlign: 'center',
  },
}));

function ResHouse(props) {
  const {
    refreshing,
    onRefresh,
    deleteAndUpdateHouseList,
    registerNewProdAndUpdateHouseList,
    customerId,
    houseList,
  } = props;
  const { hasLoading } = useLoading();
  const today = new Date();

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false); // 新規設定モーダル用
  const [date, setDate] = useState(`${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`);
  const [house, setHouse] = useState('');

  const classes = useStyles();
  const baseClasses = commonStyles();

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  // const data = initial.data.products; // 各物件情報(APIから取得したデータ)

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // 3桁カンマ区切り
  const comma = (num) => {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  };

  useEffect(() => {
    console.log({ date, house });
  }, [date, house]);

  const content = () => {
    return (
      isSP ? (
        <Grid className={classes.container}>
          {/* 更新箇所 */}
          <PullRefComponent
            refreshing={refreshing}
            onRefresh={onRefresh}
          >
            <Grid className={classes.root}>
              <Grid className={classes.headerContainer}>
                <Typography className={baseClasses.small}>
                  {comma(houseList.length)}件
                </Typography>
              </Grid>
              {houseList.length ? (
                <ResHouseList
                  data={houseList}
                  deleteAndUpdateHouseList={deleteAndUpdateHouseList}
                />
              ) : (
                <Typography className={classes.txt}>
                  対象が存在しません。
                </Typography>
              )}
            </Grid>
          </PullRefComponent>
          {/* 新規作成時 */}
          <ResisterHouseWindowSp
            open={open}
            setOpen={setOpen}
            onClose={onClose}
            date={date}
            house={house}
            setDate={setDate}
            setHouse={setHouse}
            registerNewProdAndUpdateHouseList={registerNewProdAndUpdateHouseList}
            customerId={customerId}
          />
          {/* 追加ボタン */}
          <Button className={classes.button} onClick={handleClick}>
            <AddIcon className={classes.addIcon} />
          </Button>
        </Grid>
      ) : (
        <>
          <Grid className={classes.headerContainer}>
            <Button onClick={handleClick}>
              <AddIcon className={classes.addIcon} />
              <Typography className={`${baseClasses.smallStrong} ${classes.addButton}`}>
                追加
              </Typography>
            </Button>
            <Typography className={baseClasses.small}>
              {houseList.length}件
            </Typography>
          </Grid>
          <ResHouseTable
            data={houseList}
            deleteAndUpdateHouseList={deleteAndUpdateHouseList}
          />
          <ResisterHouseWindow
            open={open}
            setOpen={setOpen}
            onClose={onClose}
            registerNewProdAndUpdateHouseList={registerNewProdAndUpdateHouseList}
            customerId={customerId}
          />
        </>
      )
    );
  };

  return (
    <Grid style={{ height: '100%' }} className={hasLoading('reHouse') ? baseClasses.loading : ''}>
      {content()}
    </Grid>
  );
}

export default ResHouse;
