import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const divisionsResisterSlice = createSlice({
  name: 'divisionsResister',
  initialState: { divisionsResister: initialState },
  reducers: {
    divisionsResisterApi: (state, data) => {
      state.divisionsResister = data.payload;
    },
  },
});

export const { divisionsResisterApi } = divisionsResisterSlice.actions;

export default divisionsResisterSlice.reducer;
