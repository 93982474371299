import { Button, makeStyles, Grid, Dialog } from '@material-ui/core';
import { useEffect } from 'react';
import Calendar from '../../../../common/calendar';
import commonStyles from '../../../../styles';
import toISOStringWithTimezone from '../../../../../commonFunction/toISOStringWithTimezone';

const useStyles = makeStyles({
  flex: {
    padding: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiGrid-root': {
      border: 'none',
    },
    '&.disabled': {
      // background: '#C8C8C8',
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
  item: {
    '& label': {
      width: '100%',
      '& > span': {
        fontSize: '20px',
      },
    },
    '& > .MuiGrid-container': {
      flexWrap: 'nowrap',
      padding: 0,
      '& > *:first-child': {
        flexShrink: 0,
        marginRight: 8,
      },
    },
  },
  header: {
    padding: '16px 16px 8px 16px',
    width: '100%',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    boxSizing: 'border-box',
    position: 'relative',
    textAlign: 'center',
  },
});

export default function CalendarWindow(props) {
  const { open, setOpen, values } = props;
  const common = commonStyles();
  const classes = useStyles();

  const now = new Date();
  now.setDate(now.getDate() + 7);

  const onClose = () => {
    setOpen(false);
  };

  const onclickSave = () => {
    setOpen(false);
  };

  const formatDate = (dt) => {
    const tostring = toISOStringWithTimezone(dt);
    const result = tostring.slice(0, 16);
    return result;
  };

  const getDate = (state) => {
    switch (state) {
      case '':
        return '未設定';
      default:
        break;
    }
    return state;
  };

  const dataList = [
    {
      id: 1,
      data: [
        {
          type: 'date',
          label: '開始日時',
          state: getDate(values.state),
          setState: values.setState,
          min: formatDate(new Date()),
          disabled: false,
        },
      ],
    },
    {
      id: 2,
      data: [
        {
          type: 'date',
          label: '終了日時',
          state: getDate(values.stateto),
          setState: values.setStateto,
          min: formatDate(new Date()),
          disabled: values.disabled || false,
        },
      ],
    },
  ];

  const dateCreate = (value) => {
    const year = value.getFullYear();
    const month = ('00' + (value.getMonth() + 1)).slice(-2);
    const day = ('00' + value.getDate()).slice(-2);
    const newDate = year + '/' + month + '/' + day;
    return newDate;
  };

  useEffect(() => {
    // 対応日時のみの処理
    if (values.key === 'actionCalendar') {
      if (values.state) {
        const startDate = new Date(values.state);
        startDate.setFullYear(startDate.getFullYear() + 1);
        if (!values.stateto) {
          // ToにFromの1年後の日付をセット
          values.setStateto(dateCreate(startDate));
        } else {
          const endDate = new Date(values.stateto);
          if (startDate < endDate) { // FromにToの1年以上前の日付が選択された場合
            // ToにFromの1年後の日付をセット
            values.setStateto(dateCreate(startDate));
          }
        }
      } else {
        // Toの日付をリセット
        values.setStateto('');
      }
    }
  }, [values.state]);

  useEffect(() => {
    // 対応日時のみの処理
    if (values.key === 'actionCalendar') {
      if (values.stateto) {
        const startDate = new Date(values.state);
        const endDate = new Date(values.stateto);
        endDate.setFullYear(endDate.getFullYear() - 1);
        if (startDate < endDate) { // ToにRromの1年以上前の日付が選択された場合
          // FromにToの1年後の日付をセット
          values.setState(dateCreate(endDate));
        }
      } else {
        // Fromの日付をリセット
        values.setState('');
      }
    }
  }, [values.stateto]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={`${common.dialog} ${common.maxWidthDialog} ${common.cancelIndex}`}
    >
      <Grid container>
        <Grid className={`${common.title2} ${classes.header}`}>
          {values.label}
          <Button
            className={common.closeButton}
            onClick={onClose}
          />
        </Grid>
        {dataList.map((o) => (
          <Grid key={o.id} className={`${classes.flex} ${o.data[0].disabled && 'disabled'}`}>
            <Grid className={classes.item}>
              <Calendar
                data={o.data[0]}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="center" className={common.fixedFooter}>
          <Button
            className={
              `${common.buttonsSecondary} ${classes.fixedFooterBtn}`
            }
            onClick={() => onClose()}
          >
            キャンセル
          </Button>
          <Button
            className={
              `${common.buttonsPrimary} ${classes.fixedFooterBtn}`
            }
            onClick={onclickSave}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
