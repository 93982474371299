import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerApi from '../../../../apis/customer/customerApi';
import { getCustomerApi } from '../../../../store/customer/customerSlice';
// get customer015
import customerGetBrowsingHistoryApi from '../../../../apis/customer/customerGetBrowsingHistoryApi';
import { customerGetBrowsingHistory } from '../../../../store/customer/customerGetBrowsingHistorySlice';
import actCreateCustomHistoryApi from '../../../../apis/act/actCreateCustomHistoryApi';
import actCreateHistoryApi from '../../../../apis/act/actCreateHistoryApi';
import actPutHistoryApi from '../../../../apis/act/actPutHistoryApi';
import actDeleteHistoryApi from '../../../../apis/act/actDeleteHistoryApi';
import { actCreateHistory } from '../../../../store/act/actCreateHistorySlice';
import { actPutHistory } from '../../../../store/act/actPutHistorySlice';
import { actDeleteHistory } from '../../../../store/act/actDeleteHistorySlice';
import CustomerInfoSpComponent from '../../../../components/common/customer/customerInfo/customerInfoSp/parts/customerInfoSp';
import { downloadZip, getNowDate } from '../../../../commonFunction';
import sendmailAttachmentsDownloadApi from '../../../../apis/sendmail/sendmailAttachmentsDownloadApi';

function check(customerObj, cstmrId) {
  if (typeof customerObj !== 'object' && !cstmrId) throw new RangeError('CustomerInfoSpの引数に正しい値を入れてください。');
}

function CustomerInfoContainer(props) {
  const { cstmrId, open = false, setOpen, onClose, customerObj, fullScreen } = props;

  try {
    check(customerObj, cstmrId);
  } catch (error) {
    console.error(error);
  }

  const dispatch = useDispatch();

  const customerPrf = useSelector((state) => state.customer.customer);
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // GET Customer
  const getCustomerFunction = async (customerId) => {
    if (customerId.length) {
      const id = customerId;
      await CustomerApi(id, isSp ? 1 : 0, 0, 0, 1)
        .then((res) => {
          console.log(res.data);
          // 更新用に日付をセット
          res.data.getDataAt = getNowDate();
          dispatch(getCustomerApi(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(getCustomerApi(
        {
          customers: [],
        },
      ));
    }
  };

  // GET Customer 個人単位
  const [customerDetail, setCustomerDetail] = useState(null);
  const [customerMail, setCustomerMail] = useState(null);
  const getCustomerFunctionSingle = async (customerId, detailFlg) => {
    const [customersRes] = await Promise.all([
      // 顧客取得
      CustomerApi(customerId, isSp ? 1 : 0),
    ]);
    if (detailFlg) {
      setCustomerDetail(customersRes.data.customers[0]);
    } else {
      setCustomerMail(customersRes.data.customers[0]);
    }
  };

  // 顧客のメールアドレスをstoreから取得
  // https://openhouse.backlog.jp/view/OH_KDSFA-681
  const getCustomerMailFunction = (customerId) => {
    const targetCustomer = customerPrf?.customers.find(c => {
      return c.customerId === customerId;
    });
    setCustomerMail(targetCustomer);
  };

  // 【SP】get: 顧客閲覧履歴 customer015
  // TO CONFIRM: 500エラー https://openhouse.backlog.jp/view/SFA_ASIAQUEST-263
  const getBrowsingHistoryFunction = async (params) => {
    if (params && !params.limit) console.log('customer015: 取得上限数パラメータが設定されていません');
    if (params && (!Number.isInteger(params.offset) && !(params.offset))) console.log('customer015: 取得開始位置パラメータが設定されていません');
    const path = `?limit=${params.limit}`
      + `&offset=${params && params.offset}`;
    await customerGetBrowsingHistoryApi(path || '')
      .then((res) => {
        console.log({ customer015Response: res });
        dispatch(customerGetBrowsingHistory(res.data));
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  // 対応履歴取得
  const [actionHistory, setActionHistory] = useState({
    total: 0,
    histories: [],
  });
  const [actionHistorySearch, setActionHistorySearch] = useState({
    offset: 0,
    limit: 10,
  });
  const getActionHistoryList = async (id, searchObj) => {
    await actCreateCustomHistoryApi(id, searchObj)
      .then(async (res) => {
        await setActionHistory(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 対応履歴情報登録
  const createHistory = async (cid, parmObj) => {
    return new Promise((resolve, reject) => {
      actCreateHistoryApi(parmObj)
        .then(async (res) => {
          await dispatch(actCreateHistory(res.data));
          // 【act003】対応履歴取得（顧客ID指定）
          await getActionHistoryList(cid, actionHistorySearch);
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

  // 対応履歴情報更新
  const updateHistory = async (cid, id, parmObj) => {
    await actPutHistoryApi(id, parmObj)
      .then(async (res) => {
        await dispatch(actPutHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        await getActionHistoryList(cid, actionHistorySearch);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 対応履歴情報削除
  const deleteHistory = async (cid, id) => {
    await actDeleteHistoryApi(id)
      .then(async (res) => {
        await dispatch(actDeleteHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        await getActionHistoryList(cid, actionHistorySearch);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 添付ファイルダウンロード
  const downloadAttachment = async (actionId) => {
    await sendmailAttachmentsDownloadApi(actionId)
      .then((f) => downloadZip(f))
      .catch((err) => console.error(err));
  };

  // API用の関数をまとめたオブジェクト
  const functions = {
    getCustomerFunction,
    getCustomerFunctionSingle,
    getCustomerMailFunction,
    getBrowsingHistoryFunction, // 顧客閲覧履歴 customer015 取得関数
    getActionHistoryList, // 対応履歴取得
    actionHistorySearch, // 対応履歴取得
    setActionHistorySearch, // 対応履歴取得
    actionHistory,
    createHistory, // 対応履歴登録
    updateHistory, // 対応履歴更新
    deleteHistory, // 対応履歴削除
    downloadAttachment,
  };
  return (
    <CustomerInfoSpComponent
      functions={functions}
      customerId={cstmrId || customerObj.customerId}
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      customerDetail={customerDetail}
      setCustomerDetail={setCustomerDetail}
      customerMail={customerMail}
      setCustomerMail={setCustomerMail}
      fullScreen={fullScreen}
    />
  );
}

export default CustomerInfoContainer;
