import { BUDGET_SELECT } from '../../constants';

export const rankSelect = {
  menus: {
    0: '★なし',
    1: '★1',
    2: '★2',
    3: '★3',
    4: '★4',
    5: '★5',
  },
  title: 'ランク',
};

export const phoneHistorySelect = {
  menus: {
    1: '今日',
    2: '昨日',
    3: '一昨日',
    4: '～7日',
    5: '～14日',
    6: '～30日',
    7: '～過去全て',
  },
  title: 'TEL未対応',
};

export const mailSendHistorySelect = {
  menus: {
    1: '今日',
    2: '昨日',
    3: '一昨日',
    4: '～7日',
    5: '～14日',
    6: '～30日',
    7: '～過去すべて',
  },
  title: 'Mail未送信',
};

export const guidanceCountSelect = {
  menus: {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6回以上',
  },
  title: '案内回数',
};

export const budgetSelect = {
  menus: BUDGET_SELECT,
  title: '予算（万円）',
};

export const wishAreaSelect = {
  menus: { 0: 'test', 1: 'test' },
  title: '希望地域',
};

export const stationSelect = {
  menus: { 0: 'test', 1: 'test' },
  title: '希望駅',
};

export const keyWordSelect = {
  menus: { 0: 'test', 1: 'test' },
  title: 'キーワード',
};
