import { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';

import ResponseRow, { basicFields } from './parts/responseRow';
import HeaderCell from './parts/headerCell';

import ButtonPopover from './parts/buttonPopover';
import Header from './header';
import SelectWindow from './selectWindow';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import commonStyles from '../styles';
import getEnv from '../../commonFunction/getEnv';
import { SORT_APP_URL_BASES, SORT_APP_ENV_NAME } from '../../constants/crm/index';

const useStyles = makeStyles({
  root: {
    minWidth: 1765,
    margin: '0 auto',
    background: '#fff',
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 117px)',
    margin: '0 16px 16px',
    overflow: 'hidden',
  },
  thead: {
    position: 'sticky',
    top: 46,
    zIndex: 1,
    background: '#fff',
    '& > .MuiTableRow-root': { borderBottom: '2px solid #C8C8C8' },
  },
});

// ソート
function descendingComparator(a, b, orderBy) {
  // memberStatusCodeが0だとnullと混同されてソートがおかしくなるため分離
  if (orderBy === 'memberStatusCode') {
    const aVal = a[orderBy] === null ? -1 : a[orderBy];
    const bVal = b[orderBy] === null ? -1 : b[orderBy];
    if (bVal < aVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  } else {
    // nullとstringの比較の場合、常にfalseになってしまうので変換する
    const aVal = a[orderBy] === null ? '' : a[orderBy];
    const bVal = b[orderBy] === null ? '' : b[orderBy];
    if (bVal < aVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  }
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ResponseList(props) {
  const {
    responseTypeList,
    responsesObj,
    getResponses,
    registerFunc,
    deleteFunc,
    prevRequestRef,
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();

  const [selectedResponseId, setSelectedResponseId] = useState();
  /**
   * NOTE: 各Dialogが表示されたタイミングでhandlePopoverCloseが発火して
   * selectedResponseIdがundefinedになるので、退避用の変数として用意
   */
  const responseIdRefForDialog = useRef();

  // buttonの制御
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setSelectedResponseId(
      parseInt(event.currentTarget.id.replace('idResponse-', ''), 10),
    );
  }, []);
  const handlePopoverClose = () => {
    responseIdRefForDialog.current = selectedResponseId;
    setAnchorEl(null);
  };
  const popOverOpen = Boolean(anchorEl);

  // GA015 担当者変更ウィンドウの制御
  const [isOpenSelectWindow, setIsOpenSelectWindow] = useState(false);
  const openSelectWindow = () => {
    setIsOpenSelectWindow(true);
  };
  const completeSelectWindow = async (data) => {
    try {
      await registerFunc(
        responseIdRefForDialog.current,
        data.userId,
        data.divisionId,
      );
    } finally {
      setIsOpenSelectWindow(false);
    }
  };

  const onClickRegister = () => {
    dispatch(
      changeConfirmMessage({
        title: '反響情報の登録確認',
        msgList: ['該当の反響情報を登録しますか？'],
        buttons: [
          {
            label: 'CANCEL',
            classes: common.buttonsSecondary,
            set: () => {},
          },
          {
            label: 'OK',
            classes: common.buttonsPrimary,
            set: () => openSelectWindow(),
          },
        ],
      }),
    );
  };

  const onClickDelete = () => {
    dispatch(
      changeConfirmMessage({
        title: '反響情報の削除確認',
        msgList: [
          '該当の反響情報を削除しますか？',
          '削除を行うと、反響情報のデータが全て消えます。',
        ],
        buttons: [
          {
            label: 'CANCEL',
            classes: common.buttonsSecondary,
            set: () => {},
          },
          {
            label: 'OK',
            classes: common.buttonsPrimary,
            set: () => deleteFunc(responseIdRefForDialog.current),
          },
        ],
      }),
    );
  };

  // 振り分け（反響回し）
  const handleOpen2 = () => {
    const tmpResponse = responsesObj.responses.filter((r) => r.responseId === selectedResponseId);
    // 別タブで、旧SFAで使用している反響振り分けアプリを表示する
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5614

    // 反響振り分けアプリURLのベース部分(responseIdを除く)
    let sortAppUrlBase = '';
    // 環境名を取得
    const envName = getEnv();
    if (envName === SORT_APP_ENV_NAME.staging) {
      sortAppUrlBase = SORT_APP_URL_BASES.development;
    }
    if (envName === SORT_APP_ENV_NAME.production) {
      sortAppUrlBase = SORT_APP_URL_BASES.production;
    }
    if (envName === SORT_APP_ENV_NAME.staging || envName === SORT_APP_ENV_NAME.production) {
      window.open(sortAppUrlBase + tmpResponse[0].responseId, '_blank');
    }
  };

  // ソート
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('index');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Grid className={classes.root} id="responseList">
      <Header
        getResponses={getResponses}
        count={responsesObj.count}
        prevRequestRef={prevRequestRef}
      />
      <Table onMouseLeave={handlePopoverClose}>
        <TableHead className={classes.thead} onMouseEnter={handlePopoverClose}>
          <TableRow>
            {basicFields.map((obj) => (
              <HeaderCell
                key={obj.key}
                obj={obj}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(
            responsesObj.responses,
            getComparator(order, orderBy),
          ).map((row) => {
            return (
              <ResponseRow
                key={row.responseId}
                row={row}
                responseTypeList={responseTypeList}
                handlePopoverOpen={handlePopoverOpen}
              />
            );
          })}
        </TableBody>
        <ButtonPopover
          id="mouse-over-popover"
          open={popOverOpen}
          anchorEl={anchorEl}
          onClickRegister={onClickRegister}
          onClickDelete={onClickDelete}
          onClickSorting={handleOpen2}
        />
      </Table>
      {isOpenSelectWindow && (
        <SelectWindow
          isOpen={isOpenSelectWindow}
          setIsOpen={setIsOpenSelectWindow}
          onSelect={completeSelectWindow}
        />
      )}
    </Grid>
  );
}

export default ResponseList;
