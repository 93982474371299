import { useState, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { Publish as PublishIcon } from '@material-ui/icons';
import UploadDialog from './uploadDialog';
import commonStyles from '../../styles';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';

export default function ButtonUpload() {
  const { uploadApi } = useCombinationSettingContext();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const common = commonStyles();

  const onUpdate = useCallback((file) => {
    uploadApi(file);
    setShowUploadModal(false);
  }, [uploadApi]);

  return (
    <>
      <Button
        className={common.buttonsPrimary}
        onClick={() => setShowUploadModal(true)}
      >
        <PublishIcon />アップロード
      </Button>
      <UploadDialog
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onSubmit={onUpdate}
      />
    </>
  );
}
