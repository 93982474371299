/* eslint-disable max-len */
import { Button, Grid, List, makeStyles, Typography } from '@material-ui/core';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Rating } from '@material-ui/lab';
import LoadingEllipsis from '../common/pullRefComponent/parts/loadingEllipsis';
import commonStyles from '../styles';
import commonTheme from '../styles/theme';
import PullRefComponent from '../common/pullRefComponent';
import CustomerInfoSp from '../common/customer/customerInfo/customerInfoSp';
import { debounce } from '../../commonFunction';
import MemberStatusCode from '../eleCommon/memberStatusCode';
import { useLoading } from '../../hooks';
import { GET_CHANCE_LIST } from '../../constants/loading';

const loadingElH = 64;

const useStyles = makeStyles({
  itemWrap: {
    borderBottom: '1px #F3F3F3 solid',
    alignItems: 'center',
    padding: '8px 16px',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  date: {
    background: '#F3F3F3',
    width: '100%',
    padding: 16,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  timeWrap: { padding: '16px 16px 16px 32px' },
  wrap: {
    [commonTheme.breakpoints.up('md')]: {
      height: 'calc(100vh * 1.25 - 48px)',
      overflowY: 'auto',
      position: 'relative',
    },
    [commonTheme.breakpoints.down('sm')]: {
      // update: 2023/04/26
      height: 'calc(100vh - 51px)',
      overflowY: 'auto',
      '& .ptr': {
        height: 'auto',
      },
      '& .ptr__pull-down': {
        top: 0,
      },
    },
  },
  red: { color: '#D83420' },
  grid: {
    flexWrap: 'nowrap',
  },
  dateWrp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10%',
  },
  segment: {
    width: '25%',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.2,
    margin: 0,
  },
  starLbl: {
    fontSize: '1em',
    margin: '0 8px',
  },
  customerName: {
    width: '100%',
    marginBottom: 2,
  },
  noData: {
    paddingTop: '50px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 'calc(100vh - 48px)',
  },
  txtWrap: {
    width: '60%',
  },
  extraSpace: {
    height: loadingElH,
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  more: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.3)',
    fontSize: '0.9rem',
  },
  subTxt: {
    textAlign: 'left',
  },
  premium: {
    marginRight: 4,
  },
  shrink: {
    flexShrink: 0,
  },
});
function GetTime(props) {
  const { time, receive, segment } = props;
  const common = commonStyles();
  const classes = useStyles();
  const receiveTimeBase = new Date(receive);
  const receiveTime = {
    year: receiveTimeBase.getFullYear(),
    month: receiveTimeBase.getMonth() + 1,
    day: receiveTimeBase.getDate(),
    hour: receiveTimeBase.getHours(),
    minute: receiveTimeBase.getMinutes(),
  };

  if (receiveTime.year < time.year) {
    return (
      <>
        <Grid className={classes.dateWrp}>
          <Typography className={common.title5}>{receiveTime.year}</Typography>
          <Typography className={common.title4}>{receiveTime.month}/{receiveTime.day}</Typography>
          <Typography className={common.title5}>
            {receiveTime.hour}:{receiveTime.minute < 10 ? `0${receiveTime.minute}` : receiveTime.minute}
          </Typography>
        </Grid>
        <Grid className={classes.segment}>{segment}</Grid>
      </>
    );
  }
  if (`${receiveTime.month}${receiveTime.day}` === `${time.month}${time.day}`
    && receiveTime.hour === time.hour) {
    return (
      <>
        <Grid className={classes.dateWrp}>
          <Typography className={`${common.title2} ${classes.red}`}>{`${time.minute}` - `${receiveTime.minute}`}</Typography>
          <Typography className={`${common.title5} ${classes.red}`}>
            分前
          </Typography>
        </Grid>
        <Grid className={`${classes.red} ${classes.segment}`}>{segment}</Grid>
      </>
    );
  }
  if (`${receiveTime.month}${receiveTime.day}` === `${time.month}${time.day}`
    && `${time.hour}${time.minute < 10 ? `0${time.minute}` : time.minute}` - `${receiveTime.hour}${receiveTime.minute < 10 ? `0${receiveTime.minute}` : receiveTime.minute}` < 100) {
    return (
      <>
        <Grid className={classes.dateWrp}>
          <Typography className={`${common.title2} ${classes.red}`}>{`${time.hour}${time.minute < 10 ? `0${time.minute}` : time.minute}` - 40 - `${receiveTime.hour}${receiveTime.minute < 10 ? `0${receiveTime.minute}` : receiveTime.minute}`}</Typography>
          <Typography className={`${common.title5} ${classes.red}`}>
            分前
          </Typography>
        </Grid>
        <Grid className={`${classes.red} ${classes.segment}`}>{segment}</Grid>
      </>
    );
  }
  return (
    <>
      <Grid className={classes.dateWrp}>
        <Typography className={common.title4}>{receiveTime.month}/{receiveTime.day}</Typography>
        <Typography className={common.title5}>
          {receiveTime.hour}:{receiveTime.minute < 10 ? `0${receiveTime.minute}` : receiveTime.minute}
        </Typography>
      </Grid>
      <Grid className={classes.segment}>{segment}</Grid>
    </>
  );
}

function ListItemComponent(props) {
  const { data, time, setOpen, setCutstomerId } = props;
  const common = commonStyles();
  const classes = useStyles();
  return (
    <Button
      container
      className={classes.itemWrap}
      onClick={() => {
        setOpen(true);
        setCutstomerId(data.customerId);
      }}
    >
      <GetTime time={time} receive={data.receiveAt} segment={data.segment} />
      <Grid className={classes.txtWrap}>
        <Grid container alignItems="center" className={classes.grid}>
          <Typography className={`${common.title4} ${classes.customerName}`}>
            {data.customerLastName} {data.customerFirstName}&nbsp;
          </Typography>
          <Rating
            name="size-large"
            value={data.userLevel}
            className={classes.starLbl}
            readOnly
          />
          <Typography className={`${common.title6} ${classes.shrink}`}>
            <MemberStatusCode
              hideNonMemberIcon
              code={data.memberStatus}
            />
          </Typography>
        </Grid>
        <Typography className={classes.subTxt} style={{ lineHeight: 1 }}>
          <span className={common.title5}>反響日 </span>
          <span className={common.small}>{data.effectAt}</span>
        </Typography>
        <Typography className={classes.subTxt} style={{ lineHeight: 1 }}>
          <span className={common.title5}>担当 </span>
          <span className={common.verySmall}>
            {data.divisionName}　{data.userLastName} {data.userFirstName}
          </span>
        </Typography>
      </Grid>
    </Button>
  );
}
export default function ChanceNotificationComponent(props) {
  const LIMIT = 10;
  const {
    time,
    getChanceNotificationsFunction, // listNotifications001 チャンス通知取得
  } = props;
  const classes = useStyles();
  const { hasLoading } = useLoading();

  // モーダル開閉
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(!open);
  };

  // チャンス通知一覧 取得
  const chanceNotifications = useSelector(state => state.membersGetNotifications);

  const [refreshing, setRefreshing] = useState(false);
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  const [customerId, setCutstomerId] = useState('');
  const [isFull, setIsFull] = useState(false);
  const [count, setCount] = useState(0);
  const element = useRef(null);

  // 更新時処理
  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    setIsLoadingActive(true);
    setCount(0);
    setIsFull(false);
    await getChanceNotificationsFunction({ limit: LIMIT, offset: 0 });
    setIsLoadingActive(false);
    setRefreshing(false);
  }, [refreshing]);

  const handleScroll = debounce(async () => {
    const elH = element.current.clientHeight;
    const maxScroll = element.current.scrollHeight;
    const scrollTopVal = element.current.scrollTop;
    const diff = maxScroll - elH;
    const isScrolled = diff - scrollTopVal < loadingElH;
    const isFullLoaded = (count + 1) * LIMIT >= chanceNotifications.total;
    if (isScrolled && !isFullLoaded) {
      setIsLoadingActive(true);
      setCount((v) => v + 1);
      await getChanceNotificationsFunction({
        limit: LIMIT,
        offset: (count + 1) * LIMIT,
      });
      setIsLoadingActive(false);
    }
  }, 100);

  useEffect(() => {
    getChanceNotificationsFunction({ limit: LIMIT, offset: 0 });
  }, []);

  useEffect(() => {
    const isFullLoaded = (count + 1) * LIMIT >= chanceNotifications.total;
    setIsFull(isFullLoaded);
  }, [chanceNotifications]);

  return (
    <Grid className={classes.wrap} ref={element} onScroll={handleScroll}>
      {customerId && (
        <CustomerInfoSp
          open={open}
          setOpen={setOpen}
          onClose={onClose}
          customerId={customerId}
          fullScreen
        />
      )}
      <PullRefComponent
        refreshing={refreshing}
        onRefresh={handleRefresh}
        canFetchMore
      >
        <List className={classes.list}>
          {/* FIXME: data.mapはデータ検証用。 あとでnotificationsLoacal~に戻す */}
          {/* {notificationsLocal.notifications.length > 0 ? notificationsLocal.notifications.map((d) => { */}
          {/* // FIXME: データ検証用。notificationsLocal~が本番 */}
          {chanceNotifications.notifications?.length > 0 && (
            chanceNotifications.notifications.map((d) => {
              return (
                <ListItemComponent
                  key={`key${d.memberId}`}
                  data={d}
                  time={time}
                  setOpen={setOpen}
                  setCutstomerId={setCutstomerId}
                />
              );
            })
          )}
          {!chanceNotifications.notifications?.length && !hasLoading(GET_CHANCE_LIST) && (
            <Typography className={classes.noData}>
              チャンス通知は存在しませんでした
            </Typography>
          )}
        </List>
        {!isFull && (
          <Grid className={classes.extraSpace}>
            {isLoadingActive ? (
              <LoadingEllipsis />
            ) : (
              <Typography className={classes.more}>
                下スクロールでさらに取得する
              </Typography>
            )}
          </Grid>
        )}
      </PullRefComponent>
    </Grid>
  );
}
