import { createSlice } from '@reduxjs/toolkit';

/**
 * @type { import(
 *   '../../apis/act/getResponsesRecentListApiTypes'
 * ).ResponseRecentListGetResponse }
 */
const initialState = {
  message: '',
  description: '',
  data: {
    total: 0,
    guidances: [],
  },
};

export const responsesRecentListSlice = createSlice({
  name: 'responsesRecentList',
  initialState,
  reducers: {
    updateResponsesRecentList: (state, action) => {
      state.message = action.payload.message;
      state.description = action.payload.description;
      state.data = action.payload.data;
    },
  },
});

export const { updateResponsesRecentList } = responsesRecentListSlice.actions;

export default responsesRecentListSlice.reducer;
