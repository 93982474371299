import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, FormControlLabel, Checkbox, List, ListItem } from '@material-ui/core';
import commonStyles from '../../../../styles';
import commonTheme from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal1: {
    width: 208,
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      order: 2,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 98,
    },
    '& .MuiTypography-root': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 16,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
}));

export default function RegisterWindowDate(props) {
  const {
    checked,
    checkChange,
    getParts,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const getCheckBox = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={checked} onChange={checkChange} />}
        label="一緒に登録"
      />
    );
  };

  const list = [
    {
      type1: 'checkbox',
      type2: '',
      lbl: '案内も一緒に登録',
      val: checked,
      content: getCheckBox,
    },
  ];

  if (isSP) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {getParts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.contentIpt}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          案内登録
        </Typography>
        <Grid className={classes.contentIptVal1}>
          <FormControlLabel
            className={classes.formControlLabel}
            control={<Checkbox checked={checked} onChange={checkChange} />}
            label="一緒に登録"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
