import client from '../httpClient';

/**
 * @module filestorageResisterApi - 【filestorage003】ファイルストレージ情報登録
 * @param {FormData} data - files, limitAt, isNotification
 */
const filestorageResisterApi = (data) => {
  const headers = { 'Content-Type': 'multipart/form-data;' };
  return client.post('/file-storages', data, { headers });
};

export default filestorageResisterApi;
