import client from '../httpClient';

// 【customer003】クレーム解除申請
const claimCreateCancelApi = async (customerId) => {
  /**
   * @param {string} customerId
   */
  const result = await client.post(`/claims/cancel/${customerId}`);
  return result;
};

export default claimCreateCancelApi;
