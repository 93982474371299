import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function TaiouRirekiSearchCheckBoxAll(props) {
  const { label, flg, index, row, setRow, clickCheckFunc } = props;

  const checkInitial = () => {
    switch (flg) {
      case '1':
        return true;
      default:
        return false;
    }
  };

  const [checked, setChecked] = useState(checkInitial());

  const handleChange = (e) => {
    clickCheckFunc(e);
    setChecked(e.target.checked);
    switch (e.target.checked) {
      case true:
        setRow({ ...row, no: index, check: 1 });
        break;
      case false:
        setRow({ ...row, no: index, check: 2 });
        break;
      default:
        // 処理なし
    }
  };

  const handleBlur = () => {
    setRow({ ...row, no: index, check: 0 });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            onBlur={handleBlur}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
