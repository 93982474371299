import React, { useState, useMemo } from 'react';
// import { useDispatch } from 'react-redux';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Grid,
  Typography,
  Button as MuiButton,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { randomId, classNames } from '../../../commonFunction';
import { useBrowser } from '../../../hooks';
// import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import handleKeyDown from '../../../commonFunction/preventKeyCode';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'var(--modal-max-width)',
      minHeight: 'var(--modal-min-height)',
      width: 'var(--modal-width)',
    },
    '& .MuiDialog-paper': {
      [commonTheme.breakpoints.down('sm')]: {
        margin: 0,
        borderRadius: 0,
        width: '100%',
        height: '100%',
        maxHeight: 'none',
      },
    },
  },
  visibleFooter: {
    [commonTheme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 58px)',
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '19px 0 8px',
      textAlign: 'center',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
    },
  },
  titleCenter: {
    textAlign: 'center',
  },
  content: {
    position: 'relative',
    background: '#F3F3F3',
    overflowY: 'auto',
    borderTop: '1px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 20px)',
      padding: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },
  noPadding: {
    padding: 0,
  },
  whiteBg: {
    background: '#fff',
  },
  noScroll: {
    overflow: 'hidden',
  },
  footer: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #C8C8C8',
  },
});

const useButtonStyles = makeStyles({
  root: {
    minHeight: 40,
    paddingLeft: 22,
    paddingRight: 22,
    margin: '0 8px',
  },
});

function PaperComponent(props) {
  const {
    'aria-labelledby': id,
  } = props;

  return (
    <Draggable
      handle={`[data-drag-id="${id}"]`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function formatCssValue(input) {
  if (typeof input === 'number') {
    return `${input}px`;
  }
  return input;
}

export function Button(props) {
  const {
    label,
    disabled,
    className,
    style,
    type,
    icon,
    onClick,
  } = props;

  const baseClasses = commonStyles();
  const classes = useButtonStyles();

  return (
    <MuiButton
      onClick={onClick}
      disabled={disabled}
      style={style}
      startIcon={icon}
      className={classNames(
        classes.root,
        {
          [baseClasses.buttonsPrimary]: !type || type === 'primary',
          [baseClasses.buttonsSecondary]: type === 'secondary',
        },
        className,
      )}
    >
      {label}
    </MuiButton>
  );
}

/**
 * Custom Dialog/Modal
 * @param {{
 * open: boolean;
 * onClose?: () => void;
 * title: string;
 * white?: boolean;
 * }} props
 * @returns {ReactNode}
 */
export default function Modal(props) {
  const {
    open,
    onClose,
    children, // body
    title, // タイトル
    white, // bodyは白い背景にする
    noScroll, // bodyのスクロールをなくす
    width = '80%',
    maxWidth = '100%',
    minHeight,
    className,
    style,
    spOnly = false,
    pcOnly = false,
    bodyClassName,
    bodyStyle,
    noPadding = false, // contentがpaddingなし
    header,
    headerClassName,
    footer,
    footerClassName,
    footerStyle,
    buttons,
    zIndex,
    disableEscapeKeyDown,
    titleCenter,
    topId,
    visibleFooter, // SPでメインのナビが見えるようにするとき boolean
    acceptEnter = false,
    buttonClose = false, // 背景ではなくボタンを表示してモーダルを閉じる時（PCのみ）
  } = props;

  const baseClasses = commonStyles();
  const classes = useStyles();
  const [id] = useState(randomId());
  const { isSP } = useBrowser();
  // const dispatch = useDispatch();

  const titles = useMemo(() => {
    const arr = !Array.isArray(title) ? [title] : title;
    return {
      pc: arr[0],
      sp: arr[1] ?? arr[0],
    };
  }, [title]);

  const styles = useMemo(() => {
    const data = {
      ...style,
      '--modal-width': formatCssValue(width),
      '--modal-max-width': formatCssValue(maxWidth),
      '--modal-min-height': formatCssValue(minHeight),
    };
    if (zIndex) data.zIndex = zIndex;
    return data;
  }, [
    style,
    width,
    maxWidth,
    minHeight,
    zIndex,
  ]);

  const footerButtons = useMemo(() => {
    if (!buttons) return null;
    const arr = Array.isArray(buttons) ? buttons : [buttons];
    return arr.length ? arr : null;
  }, [buttons]);

  return (
    <Dialog
      id={topId}
      open={open}
      onClose={!buttonClose ? onClose : null}
      PaperComponent={PaperComponent}
      aria-labelledby={id}
      className={`${classNames(classes.dialog, className)} ${visibleFooter && classes.visibleFooter}`}
      style={styles}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {spOnly || (!pcOnly && isSP) ? (
        <Grid
          className={classNames(
            classes.header,
            baseClasses.title2,
            headerClassName,
          )}
        >
          {titles.sp}
          <MuiButton
            className={baseClasses.closeButton}
            onClick={onClose}
          />
        </Grid>
      ) : (
        <DialogTitle
          data-drag-id={id}
          className={classNames(
            classes.header,
            headerClassName,
          )}
        >
          {header || (
            <Typography className={classNames(baseClasses.title3, titleCenter ? classes.titleCenter : '')}>
              {titles.pc}
            </Typography>
          )}
          {buttonClose && (
            <MuiButton
              className={baseClasses.closeButton}
              style={{ display: 'block' }}
              onClick={onClose}
            />
          )}
        </DialogTitle>
      )}
      <DialogContent
        className={classNames(
          classes.content,
          {
            [classes.whiteBg]: white,
            [classes.noPadding]: noPadding,
            [classes.noScroll]: noScroll,
          },
          bodyClassName,
        )}
        style={bodyStyle}
      >
        {children}
      </DialogContent>
      {(footer || footerButtons) && (
        <Grid
          className={classNames(
            classes.footer,
            footerClassName,
          )}
          style={footerStyle}
          onKeyDown={!acceptEnter ? handleKeyDown : null}
        >
          {footerButtons?.map((item, i) => (
            <Button {...item} key={String(i)} />
          ))}
          {footer}
        </Grid>
      )}
    </Dialog>
  );
}
