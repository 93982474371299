const setLocation = (setState, state) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = (position) => {
    const { longitude, latitude } = position.coords;
    if (state) {
      setState({ ...state, longitude, latitude });
    } else {
      setState({ longitude, latitude });
    }
  };

  const error = (err) => console.warn(`ERROR(${err.code}): ${err.message}`);

  navigator.geolocation.getCurrentPosition(success, error, options);
};
export default setLocation;
