import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import commonTheme from '../../../../../styles/theme';
import BrowsingHistory from '../../../../../browsingHistory';

import {
  profileHeadMenuTabsList,
} from '../../../../../../constants/customerMain';
import ContactTimeContainers from '../../../../../../containers/common/contactTime/contactTimeContainers';
import ContactTotalContainers from '../../../../../../containers/common/contactTotal/contactTotalContainers';
import ResHouseContainers from '../../../../../../containers/common/resHouse/resHouseContainers';
import PullRefComponent from '../../../../pullRefComponent';
import InfoChangeHistory from '../../../../infoChangeHistory';
import TaiouRireki from '../../../../taiouRireki';
/* eslint-disable */
import InstructionReport from '../../../../instructionReport';
import CustomerInformation from './customerInformation';
import CustomerMemos from './customerMemos';
import FixedFootNav from '../../../../../customerMain/parts/fixedFootNav';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  appBar: {
    top: '52px',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    background: 'transparent',
  },
  tabs: {
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      color: '#333',
      fontSize: 13,
      lineHeight: 1,
      letterSpacing: 0,
      opacity: '1',
      [commonTheme.breakpoints.down('sm')]: {
        padding: '24px 16px 12px 16px',
      },
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  tabPanel: {
    height: '100%',
    '& > div': {
      height: '100%',
    },
    '& > div > div': {
      height: '100%',
    },
    '& > div > div > div': {
      height: '100%',
    },
    '& .ptr__pull-down--pull-more': {
      display: 'none',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, refreshing, onRefresh, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProfileHeadMenuTabs(props) {
  const {
    input,
    updatePage,
    functions,
    fullScreen,
  } = props;
  const classes = useStyles();
  const { customer } = input;
  const { customerId } = customer;

  const [value, setValue] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  // 完了アラート制御用 対応履歴
  const [openAlert, setOpenAlert] = useState(false);

  // タブ変更時
  const handleChange = async (event, newValue) => {
    setRefreshing(true);
    setValue(newValue);
    try {
      switch (newValue) {
        case 0:
        case 1:
          // 顧客情報, メモに変更時はカスタマー情報を再取得
          await functions.getCustomerFunction([customerId]);
          break;
      }
    } catch (err) {
      console.error(err);
    }
    setRefreshing(false);
  };

  // ページプル時: update 2023/03/29
  const handleRefresh = React.useCallback(async () => {
    setRefreshing(true);
    try {
      switch (value) {
        case 0:
        case 1:
          // 顧客情報, メモをプル時はカスタマー情報を再取得（他の場合は再レンダリングで初期化）
          await functions.getCustomerFunction([customerId]);
          break;
      }
    } catch (err) {
      console.error(err);
    }
    setRefreshing(false);
  }, [customerId, value]);

  const menusKeys = Object.keys(profileHeadMenuTabsList.menus);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Tabs value={value} onChange={handleChange} className={classes.tabs} variant="scrollable" scrollButtons="auto">
          {menusKeys.map((data, index) => (
            <Tab key={data} label={profileHeadMenuTabsList.menus[data]} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={0}
      >
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={handleRefresh}
        >
          {/* 顧客情報 */}
          <CustomerInformation
            input={input}
            functions={functions}
            setValue={setValue}
            updatePage={updatePage}
          />
        </PullRefComponent>
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={1}
      >
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={handleRefresh}
        >
          {/* 顧客メモ */}
          <CustomerMemos
            input={input}
          />
        </PullRefComponent>
      </TabPanel>
      {/* 対応履歴 */}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={2}
      >
        {!(value === 2 && refreshing) && (
          <TaiouRireki
            setOpenAlert={setOpenAlert}
            customerId={customerId}
            customer={customer}
            getActionHistoryList={functions.getActionHistoryList}
            createHistory={functions.createHistory}
            updateHistory={functions.updateHistory}
            deleteHistory={functions.deleteHistory}
            downloadAttachment={functions.downloadAttachment}
            search={functions.actionHistorySearch}
            setSearch={functions.setActionHistorySearch}
            actionHistory={functions.actionHistory}
            refreshing={refreshing}
            onRefresh={handleRefresh}
          />
        )}
      </TabPanel>
      {/* 指示報告 */}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={3}
      >
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={handleRefresh}
        >
          {!(value === 3 && refreshing) && (
            <InstructionReport customer={customer} />
          )}
        </PullRefComponent>
      </TabPanel>
      {/* 情報変更履歴 */}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={4}
      >
        <InfoChangeHistory
          customer={customer}
          customerId={customerId}
          refreshing={refreshing}
          onRefresh={handleRefresh}
        />
      </TabPanel>
      {/* Web履歴確認画面 */}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={5}
      >
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={handleRefresh}
        >
          {!(value === 5 && refreshing) && (
            <BrowsingHistory customer={customer} customerId={customerId} />
          )}
        </PullRefComponent>
      </TabPanel>
      {/* 連絡がつきやすい時間帯の統計 */}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={6}
      >
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={handleRefresh}
        >
          {!(value === 6 && refreshing) && (
            <ContactTimeContainers
              customerId={customerId}
            />
          )}
        </PullRefComponent>
      </TabPanel>
      {/* 問合せ物件 */}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={7}
      >
        <ResHouseContainers
          refreshing={refreshing}
          onRefresh={handleRefresh}
          customerId={customerId}
          customer={customer}
        />
      </TabPanel>
      {/* 接触総数 */}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={8}
      >
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={handleRefresh}
        >
          {!(value === 8 && refreshing) && (
            <ContactTotalContainers
              customer={customer}
            />
          )}
        </PullRefComponent>
      </TabPanel>
      {/* フットナビ */}
      <FixedFootNav
        value={value}
        customer={customer}
        input={input}
        functions={functions}
        refreshing={refreshing}
        onSubmit={handleRefresh}
        fullScreen={fullScreen}
      />
    </div>
  );
}
