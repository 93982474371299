import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import commonStyles from '../../../../../styles';
import commonTheme from '../../../../../styles/theme';
import CustomBackSelect from '../../../../../eleCommon/customBackSelect';
import CustomMainSelect from '../../../../../eleCommon/customMainSelect';
import MemberStatusCode from '../../../../../eleCommon/memberStatusCode';

import ListBtn from '../../../../../customerMain/parts/listBtn';
import ListMultiButton from '../../../../../customerMain/parts/listMultiButton';
import ListCheckbox from '../../../../../customerMain/parts/listCheckbox';
import EditDialog1 from '../../../../../customerMain/parts/editDialog1';
import EditDialog2 from '../../../../../customerMain/parts/editDialog2';

import ProfileHeadMenuTabs from './profileHeadMenuTabs';
import ITemLayout from '../../../../../customerMain/parts/sp/itemLayout';

const useStyles = makeStyles((theme) => ({
  content: {
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  main: {
    display: 'flex',
  },
  sideMenu: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 240,
    },
  },
  contentHeader: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '16px 0',
    },
    [commonTheme.breakpoints.up('md')]: {
      height: 60,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiGrid-root': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    '& .MuiTablePagination-root': {
      '& .MuiToolbar-root': {
        [commonTheme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
      '& .MuiTablePagination-selectRoot': {
        [commonTheme.breakpoints.up('md')]: {
          marginLeft: 0,
          marginRight: 40,
        },
      },
      '& .MuiTablePagination-actions': {
        [commonTheme.breakpoints.up('md')]: {
          marginLeft: 14,
        },
        '& button': {
          [commonTheme.breakpoints.up('md')]: {
            padding: 6,
          },
        },
      },
      '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
        [commonTheme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
  },
  contentBody: {
    display: 'flex',
    padding: 16,
    [commonTheme.breakpoints.up('md')]: {
      padding: '12px 16px',
    },
  },
  contentMain: {
    [commonTheme.breakpoints.up('md')]: {
      // width: 1234,
      height: 'calc(100vh * 1.25 - 170px)',
      overflowY: 'auto',
    },
    '& .MuiTableContainer-root': {
      [commonTheme.breakpoints.up('md')]: {
        overflowX: 'unset',
      },
    },
  },
  contentSub: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 399,
      height: 865,
      border: '1px solid rgba(51, 51, 51, 0.4)',
    },
  },
  headline: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      padding: '19px 0 9px',
      margin: '0px',
      top: '0px',
      background: '#fff',
      zIndex: 10,
      '& .MuiTypography-root': {
        textAlign: 'center',
      },
    },
  },
  title: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  name: {
    display: 'flex',
    color: '#333',
  },
  name1: {
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 1,
    marginRight: 8,
  },
  name2: {
    fontSize: 13,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'left',
    marginBottom: 2,
    display: 'inline-flex',
    alignItems: 'center',
  },
  title4: {
    fontWeight: 400,
  },
  container: {
    background: '#F3F3F3',
  },
  root: {
    position: 'relative',
    background: '#F3F3F3',
    padding: '16px 16px 8px',
    border: '1px solid #C8C8C8',
    marginBottom: 40,
  },
  button: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 17px',
  },
  box: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 8,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 8,
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrow: {
    fontSize: 16,
    color: theme.palette.primaryColor,
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 'auto',
    padding: '16px',
    '& .MuiSvgIcon-root': {
      boxSizing: 'border-box',
      width: '16px',
      height: '24px',
    },
  },
  status: {
    textAlign: 'center',
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 58px)',
    },
    '& > .MuiBackdrop-root': {
      [commonTheme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 58px)',
      },
    },
  },
  fullscreenDialog: {
    [commonTheme.breakpoints.down('sm')]: {
      height: '100vh',
    },
    '& > .MuiBackdrop-root': {
      [commonTheme.breakpoints.down('sm')]: {
        height: '100vh',
      },
    },
  },
  tableCell: {
    [commonTheme.breakpoints.up('md')]: {
      padding: 0,
      border: 'none',
      display: 'flex',
    },
  },
}));

const getComponent = (data) => {
  switch (data.type) {
    case 'select':
      return (
        <CustomMainSelect initial={data} />
      );
    case 'selectBack':
      return (
        <CustomBackSelect initial={data} />
      );
    default:
      return <div>対応しない要素です</div>;
  }
};

// SP ボタン
const getParts = (data) => {
  switch (data.type1) {
    case 'button':
      return (
        <ListBtn
          click={(e) => data.click(e, data)}
          lbl={data.lbl}
          fontSize={data.fontSize}
          val={(data.val ?? '') + (data.suffix ?? '')}
        />
      );
    case 'multiButton':
      return (
        <ListMultiButton
          click1={(e) => data.click1(e, data, 0)}
          click2={(e) => data.click2(e, data, 1)}
          lbl={data.lbl}
          fontSize={data.fontSize}
          val1={data.val1}
          val2={data.val2}
        />
      );
    case 'checkbox':
      return (
        <ListCheckbox
          control={data.content()}
          lbl={data.lbl}
        />
      );
    case 'noModal':
      return (
        <ITemLayout
          content={data.content()}
          lbl={data.lbl}
        />
      );
    default:
      return <span style={{ display: 'none' }}>該当なし</span>;
  }
};

function CustomerInfoSpComponent(props) {
  const {
    functions,
    customerId,
    open = false,
    onClose,
    customerDetail,
    setCustomerDetail,
    customerMail,
    setCustomerMail,
    fullScreen,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [loading, setLoading] = useState(false);

  const customers = useSelector((state) => state.customer.customer.customers);

  // ローディングが終わるまで顧客情報を表示しない
  const current = (!loading && customers[0]) || {};

  const customerName = useMemo(() => {
    return [current.lastName, current.firstName].filter(Boolean).join('');
  }, [current]);

  const mainMember = useMemo(() => {
    const { webs = [] } = current;
    return webs.find((item) => item.isMain) || webs[0] || {};
  }, [current]);

  // 反響ツリー選択顧客 TODO:初期値はログインユーザーに紐づく顧客を設定
  const [selectCustomer, setSelectCustomer] = useState([]);

  // SP モーダル
  const getEditDialog = (data) => {
    switch (data.type2) {
      case 'text':
        return (
          <EditDialog1
            classes={classes}
            open={data.opn ?? false}
            onClose={data.onClose}
            content={data.content}
            set={data.decision}
            lbl={data.lbl}
          />
        );
      case 'select':
        return (
          <EditDialog2
            classes={classes}
            open={data.opn ?? false}
            onClose={data.onClose}
            content={data.content}
            lbl={data.lbl}
          />
        );
      case 'multiSelect':
        return (
          <Grid>
            <EditDialog2
              classes={classes}
              open={data.opn1 ?? false}
              onClose={data.onClose1}
              content={data.content1}
              lbl={data.lbl}
            />
            <EditDialog2
              classes={classes}
              open={data.opn2 ?? false}
              onClose={data.onClose2}
              content={data.content2}
              lbl={data.lbl}
            />
          </Grid>
        );
      default:
        return (
          <span style={{ display: 'none' }}>該当なし</span>
        );
    }
  };

  useEffect(() => {
    if (customerId) {
      setSelectCustomer([customerId]);
    }
  }, [customerId]);

  // 顧客情報を取得
  useEffect(async () => {
    if (selectCustomer?.length && open) {
      setLoading(true);
      await functions.getCustomerFunction(selectCustomer);
      setLoading(false);
    }
  }, [selectCustomer]);

  const input = {
    component: getComponent,
    parts: getParts,
    editDialog: getEditDialog,
    customer: current,
    customerDetail,
    setCustomerDetail,
    customerMail,
    setCustomerMail,
  };

  if (!customers[0]) return null;

  // ページ更新
  const updatePage = () => {
    functions.getCustomerFunction(selectCustomer);
  };

  return open && (
    <Dialog
      open={open}
      onClose={onClose}
      className={`${fullScreen ? classes.fullscreenDialog : classes.dialog} ${baseClasses.dialog} ${baseClasses.maxWidthDialog}`}
    >
      <Grid className={classes.container} container>
        <Grid className={`${classes.headline}`}>
          <Button className={`${classes.arrow}`} onClick={onClose}>
            <ArrowBackIosIcon />
          </Button>
          <Grid className={classes.title}>
            <Typography className={classes.name1}>
              {customerName}
            </Typography>
            <Typography className={classes.name2}>
              様
            </Typography>
          </Grid>
          <Grid className={classes.status}>
            <MemberStatusCode
              code={mainMember.memberStatusCode}
            />
          </Grid>
        </Grid>
        {/* 上部タブ・コンテンツ */}
        {loading
          ? null
          : (<ProfileHeadMenuTabs
              input={input}
              updatePage={updatePage}
              functions={functions}
              fullScreen={fullScreen}
          />)}
      </Grid>
    </Dialog>
  );
}

export default CustomerInfoSpComponent;
