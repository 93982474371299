import { useMemo, useCallback } from 'react';
import BrowsingTableRow from './browsingTableRow';
import CustomTable from './customTable';
import { MEMBERS_REDUCER_KEYS } from '../../../containers/browsingHistory/browsingHistoryReducer';

const headCells = [
  { id: 'accessedAt', label: '日時' },
  { id: 'thumbnail', label: 'サムネイル', alignCenter: true },
  { id: 'homepage', label: <span>自社<br />HP</span>, alignCenter: true },
  { id: 'registaUrl', label: <span>レジ<br />スタ</span>, alignCenter: true },
  { id: 'buildingType', label: '種別', alignCenter: true },
  { id: 'price', label: '価格(万円)', alignCenter: true },
  { id: 'address1', label: '物件情報', alignCenter: true },
  { id: 'railway', label: '交通', alignCenter: true },
  { id: 'InformationProvider', label: '情報提供', alignCenter: true },
  { id: 'content', label: '内容', alignCenter: true },
];

export default function BrowsingTable(props) {
  const {
    data,
    getApiDataFromListApi,
    isSP,
    adjustPriceText,
    handleOpenModal = null,
  } = props;
  const browsingTablelimit = localStorage.getItem('browsingTablelimit') ? localStorage.getItem('browsingTablelimit') : 20;
  const setBrowsingTablelimit = useCallback((current) => {
    localStorage.setItem('browsingTablelimit', current);
  }, []);
  const tableHeadCells = useMemo(() => {
    // 閲覧履歴にレジスタが、モバイルのみこちら不要
    return isSP ? headCells.filter((item) => item.id !== 'registaUrl') : headCells;
  }, [isSP]);

  return (
    <CustomTable
      hideHeader={isSP}
      title="閲覧履歴"
      headCells={tableHeadCells}
      total={data.total}
      list={data.histories}
      rowRender={(list) => list.map((row, i) => (<BrowsingTableRow
        key={String(i)}
        row={row}
        isSP={isSP}
        adjustPriceText={adjustPriceText}
      />))}
      onPageChange={(pageInfo) => {
        getApiDataFromListApi(MEMBERS_REDUCER_KEYS.productsHistories, pageInfo);
      }}
      divider
      sortable
      contentHeight={(isSP || handleOpenModal === null) ? null : 367}
      // contentHeight={(isSP || handleOpenModal === null) ? null : 195} // 戻すとき
      propLimit={browsingTablelimit}
      setPropLimit={setBrowsingTablelimit}
      isNoSlice
      handleOpenModal={handleOpenModal}
    />
  );
}
