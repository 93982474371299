import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import alongRailwaysApi from '../../../apis/common/alongRailways';
import stationsApi from '../../../apis/common/stations';
import { AREA_CODES } from '../../../constants/apiParameterCode';
import SelectRailwaysAndStations from '../../../components/search/railwayAndStations/index';
import { useLoading } from '../../../hooks';

// TODO: 沿線/区画ウィンドウ -> 江川 以下props
// onClickSelectButton: 選択クリック時の処理
// initialParam: 呼び出し元の初期値

// あいうえお順にソート関数
const sortRailwayList = (railwayList) => {
  railwayList.sort(
    (a, b) => a.railwayKanaName.localeCompare(b.railwayKanaName),
    'ja',
  );
  return railwayList;
};

// アカサタナでグループ化
const groupByAKaSaTaNa = (targetList) => {
  const aKaSaTaNaList = [
    // TODO: 沿をはずす
    { key: 'あ', inclusion: ['ｱ', 'ｲ', 'ｳ', 'ｴ', 'ｵ'] },
    {
      key: 'か',
      inclusion: ['ｶ', 'ｷ', 'ｸ', 'ｹ', 'ｺ', 'ｶﾞ', 'ｷﾞ', 'ｸﾞ', 'ｹﾞ', 'ｺﾞ'],
    },
    {
      key: 'さ',
      inclusion: ['ｻ', 'ｼ', 'ｽ', 'ｾ', 'ｿ', 'ｻﾞ', 'ｼﾞ', 'ｽﾞ', 'ｾﾞ', 'ｿﾞ'],
    },
    {
      key: 'た',
      inclusion: ['ﾀ', 'ﾁ', 'ﾂ', 'ﾃ', 'ﾄ', 'ﾀﾞ', 'ﾁﾞ', 'ｽﾞ', 'ﾃﾞ', 'ﾄﾞ'],
    },
    { key: 'な', inclusion: ['ﾅ', 'ﾆ', 'ﾇ', 'ﾈ', 'ﾉ'] },
    {
      key: 'は',
      inclusion: ['ﾊ', 'ﾋ', 'ﾌ', 'ﾍ', 'ﾎ', 'ﾊﾞ', 'ﾋﾞ', 'ﾌﾞ', 'ﾍﾞ', 'ﾎﾞ'],
    },
    { key: 'ま', inclusion: ['ﾏ', 'ﾐ', 'ﾑ', 'ﾒ', 'ﾓ'] },
    { key: 'や', inclusion: ['ﾔ', 'ﾕ', 'ﾖ'] },
    { key: 'ら', inclusion: ['ﾗ', 'ﾘ', 'ﾙ', 'ﾚ', 'ﾛ'] },
    { key: 'わ', inclusion: ['ﾜ', 'ｦ', 'ﾝ'] },
  ];

  const groupList = [];
  let tmpObj = { key: '', railways: [] };
  let targetIdx = 0;

  aKaSaTaNaList.forEach((aKaSaTaNa) => {
    tmpObj.key = aKaSaTaNa.key;
    for (let idx = targetIdx; idx < targetList.length; idx += 1) {
      const target = targetList[idx];
      if (aKaSaTaNa.inclusion.includes(target.railwayKanaName[0])) {
        tmpObj.railways.push(target);
      } else {
        targetIdx = idx;
        break;
      }
    }
    groupList.push(tmpObj);
    tmpObj = { key: '', railways: [] };
  });

  return groupList;
};

export default function SelectRailwaysAndStationsContainer(props) {
  const {
    initialParam = {},
    onUpdate,
    open,
    onClose,
    isNearestStation, // 最寄り駅
  } = props;
  const { addLoading, removeLoading } = useLoading();
  const [openedStations, setOpenedStations] = useState(false);
  const [openStations, setOpenStations] = useState(false);
  const [openFromStation, setOpenFromStation] = useState(false);
  const [openToStation, setOpenToStation] = useState(false);
  const [railways, setRailways] = useState();
  const [stations, setStations] = useState();
  const [selectedItems, setSelectedItems] = useState({
    areaCode: AREA_CODES.KANTO,
    railway: {},
    currentFromStation: {},
    fromStation: {},
    toStation: {},
    currentToStation: {},
  });
  const [countInitial, setCountInitial] = useState(0);
  const [countSelect, setCountSelect] = useState(0);
  const [countDelete, setCountDelete] = useState(0);

  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);

  // stationIdからインデックス（並び順）を取得
  const getStationIndex = (stationId) => {
    return stations.findIndex(station => station.stationId === stationId);
  };

  const clickStationsFunc = (station, toStationFlg = false, defaultStation = {}) => {
    const isStation = station !== null;
    const currentStation = isStation ? station : defaultStation;
    if (!toStationFlg) {
      setSelectedItems((state) => ({
        ...state,
        currentFromStation: currentStation,
      }));
    } else {
      setSelectedItems((state) => ({
        ...state,
        currentToStation: currentStation,
      }));
    }
  };

  // GET stations
  const clickRailwayFunc = async (railway) => {
    setSelectedItems((state) => ({
      ...state,
      railway,
      currentFromStation: {},
      currentToStation: {},
    }));
    setStations([]);
    addLoading('RailwayStation');
    await stationsApi(railway.railwayId)
      .then((res) => {
        removeLoading('RailwayStation');
        // orderCode順にソート
        setStations(res.data.stations.sort((a, b) => {
          return a.orderCode > b.orderCode ? 1 : -1;
        }));
        const firstStation = res.data.stations[0];
        const lastStation = res.data.stations[res.data.stations.length - 1];
        if (selectedItems.fromStation.stationId !== undefined) {
          // 始駅が既に選択されている場合
          const station = res.data.stations.find(
            e => {
              return e.stationId === selectedItems.fromStation.stationId;
            },
          );
          clickStationsFunc(station || null, false, firstStation);
        } else {
          clickStationsFunc(null, false, firstStation);
        }
        if (!initialParam.showOnlyFromStation) {
          if (selectedItems.toStation.stationId !== undefined) {
            // 終駅が既に選択されている場合
            const station = res.data.stations.find(
              e => {
                return e.stationId === selectedItems.toStation.stationId;
              },
            );
            clickStationsFunc(station || null, true, lastStation);
          } else {
            clickStationsFunc(null, true, lastStation);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // GET alongRailways
  const clickAreaTabFunc = async (areaCode) => {
    const param = { areaCode, isWishRailway: 1 };
    setSelectedItems((state) => ({
      ...state,
      areaCode,
      currentFromStation: {},
      currentToStation: {},
    }));
    setRailways([]);
    setStations([]);
    addLoading('RailwayArea');
    await alongRailwaysApi(param)
      .then((res) => {
        removeLoading('RailwayArea');
        const sortedRailwayList = sortRailwayList(res.data.railways);
        setRailways(groupByAKaSaTaNa(sortedRailwayList));
        if (selectedItems.railway.railwayId !== undefined) {
          // 沿線IDが既に選択されている場合
          const railway = res.data.railways.find(
            e => {
              return e.railwayId === selectedItems.railway.railwayId;
            },
          );
          if (railway) {
            clickRailwayFunc(railway);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 選択が押された時
  const clickSelectFunc = () => {
    if (initialParam.showOnlyFromStation) {
      const newSelectedItems = { ...selectedItems };
      newSelectedItems.fromStation = selectedItems.currentFromStation;
      delete newSelectedItems.toStation;
      delete newSelectedItems.currentToStation;
      setSelectedItems(newSelectedItems);
    } else {
      const fromStationIndex = getStationIndex(selectedItems.currentFromStation.stationId);
      const toStationIndex = getStationIndex(selectedItems.currentToStation.stationId);
      let fromStation = null;
      let toStation = null;
      if (fromStationIndex > toStationIndex) {
        // Fromの方が大きい（下に表示されている）場合、toとfromを入れ替える
        fromStation = selectedItems.currentToStation;
        toStation = selectedItems.currentFromStation;
      } else {
        fromStation = selectedItems.currentFromStation;
        toStation = selectedItems.currentToStation;
      }
      setSelectedItems((state) => ({ ...state, fromStation }));
      setSelectedItems((state) => ({ ...state, toStation }));
    }
    setCountSelect(countSelect + 1);
  };

  const clickDeselectionFunc = () => {
    // setSelectedItems((state) => ({
    //   ...state,
    //   areaCode: AREA_CODES.KANTO,
    //   railway: {},
    //   currentFromStation: {},
    //   fromStation: {},
    //   currentToStation: {},
    //   toStation: {},
    // }));
    // update 2023/02/24
    setSelectedItems((state) => ({
      ...state,
      areaCode: null,
      railway: {
        railwayId: null,
        railwayName: null,
      },
      currentFromStation: {},
      fromStation: {
        stationId: null,
        name: null,
      },
      currentToStation: {},
      toStation: {
        stationId: null,
        name: null,
      },
    }));

    //
    // 駅表示をクリア
    setStations([]);
    setCountDelete(countDelete + 1);
    // 選択した区間を解除
    // setFromStationName('');
    // setToStationName('');
  };

  // 沿線選択をコール
  useEffect(() => {
    if (open) {
      // console.log(selectedItems);
      onUpdate(selectedItems);
      onClose();
    }
  }, [countDelete]);

  // エリア一覧
  const tabLabels = [
    { id: 0, areaCode: AREA_CODES.KANTO, labelText: '関東' },
    { id: 1, areaCode: AREA_CODES.TOKAI, labelText: '名古屋' },
    { id: 2, areaCode: AREA_CODES.KYUSHU, labelText: '福岡' },
    { id: 3, areaCode: AREA_CODES.KANSAI, labelText: '関西' },
    { id: 4, areaCode: AREA_CODES.GUNMA, labelText: '北関東' },
  ];

  // タブインデックスを検索
  const findTabIndex = (areaCode) => {
    const index = tabLabels.findIndex(e => e.areaCode === areaCode);
    return index === -1 ? 0 : index;
  };

  // 沿線選択をコール
  useEffect(() => {
    if (open) {
      const index = findTabIndex(initialParam.wishRailwayAreaCodeId);
      const { areaCode } = tabLabels[index === -1 ? 0 : index];
      clickAreaTabFunc(areaCode);
    }
  }, [countInitial]);

  // 初期値の受け渡し
  useEffect(() => {
    if (open) {
      // console.log('initialParam', initialParam);
      if (isSP) {
        // 区間選択モーダルを表示・非表示
        setOpenStations(initialParam.wishRailwayId);
        setOpenedStations(initialParam.wishRailwayId);
      }
      if (initialParam.id) {
        setSelectedItems((state) => ({
          ...state,
          id: initialParam.id,
        }));
      }
      if (initialParam.wishRailwayId) {
        // 沿線が入力済の場合
        setSelectedItems((state) => ({ ...state,
          railway: {
            railwayId: initialParam.wishRailwayId,
            railwayName: initialParam.wishRailway,
          },
        }));
      }
      if (initialParam.wishStationIdFrom) {
        // 始駅が入力済の場合
        setSelectedItems((state) => ({ ...state,
          fromStation: {
            stationId: initialParam.wishStationIdFrom,
            name: initialParam.wishStationFrom,
          },
        }));
      }
      if (initialParam.wishStationIdTo) {
        // 終駅が入力済の場合
        setSelectedItems((state) => ({ ...state,
          toStation: {
            stationId: initialParam.wishStationIdTo,
            name: initialParam.wishStationTo,
          },
        }));
      }
      setCountInitial(countInitial + 1);
    }
  }, [open]);

  // セレクト時
  useEffect(() => {
    if (open) {
      onUpdate(selectedItems);
      onClose();
    }
  }, [countSelect]);

  return (
    <SelectRailwaysAndStations
      open={open}
      onClose={onClose}
      openedStations={openedStations}
      openStations={openStations}
      setOpenedStations={setOpenedStations}
      setOpenStations={setOpenStations}
      openFromStation={openFromStation}
      setOpenFromStation={setOpenFromStation}
      openToStation={openToStation}
      setOpenToStation={setOpenToStation}
      selectedItems={selectedItems}
      railways={railways}
      stations={stations}
      showOnlyFromStation={initialParam.showOnlyFromStation}
      clickAreaTabFunc={clickAreaTabFunc}
      clickRailwayFunc={clickRailwayFunc}
      clickStationsFunc={clickStationsFunc}
      clickSelectFunc={clickSelectFunc}
      clickDeselectionFunc={clickDeselectionFunc}
      isNearestStation={isNearestStation}
    />
  );
}
