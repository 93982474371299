import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    contractDate: '',
    contractId: '',
    contractStatus: '',
    productName: '',
  },
];

export const getContractInfoSlice = createSlice({
  name: 'getContractInfo',
  initialState: { getContractInfo: initialState },
  reducers: {
    getContractInfoApi: (state, data) => {
      state.getContractInfo = data.payload;
    },
  },
});

export const { getContractInfoApi } = getContractInfoSlice.actions;

export default getContractInfoSlice.reducer;
