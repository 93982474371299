import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';
import { useUpdate } from '../../../containers/customerMain';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '8px 5px',
    marginBottom: 6,
  },
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  formGroup: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 5px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  lbl: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 8px 16px 16px',
      borderBottom: '1px solid #F3F3F3',
      width: '100%',
      margin: 0,
      '& .MuiIconButton-root': {
        order: 2,
      },
      '& .MuiTypography-root': {
        order: 1,
        fontSize: 13,
      },
    },
  },
}));

const message1 = (baseClasses) => {
  return (
    <Grid>
      <Typography className={baseClasses.small}>対象：資産整理などで不動産の売却に興味がある顧客</Typography>
      <Typography className={baseClasses.small}>関連：開発事業部</Typography>
      <Typography className={baseClasses.small}>戸建用地の買取をサポート</Typography>
    </Grid>
  );
};
const message2 = (baseClasses) => {
  return (
    <Grid>
      <Typography className={baseClasses.small}>対象：新築マンションに興味がある顧客</Typography>
      <Typography className={baseClasses.small}>関連：マンション開発事業部</Typography>
      <Typography className={baseClasses.small}>都心部を中心に1LDK~3LDKの新築マンションを販売</Typography>
    </Grid>
  );
};
const message3 = (baseClasses) => {
  return (
    <Grid>
      <Typography className={baseClasses.small}>対象：高額帯の収益物件に興味がある顧客</Typography>
      <Typography className={baseClasses.small}>関連：ソリューション事業部、WM事業部</Typography>
      <Typography className={baseClasses.small}>2億円以上の1棟物の収益物件を販売</Typography>
      <Typography className={baseClasses.small}>節税を目的とした4,000万円前後の海外不動産を販売</Typography>
    </Grid>
  );
};
const message4 = (baseClasses) => {
  return (
    <Grid>
      <Typography className={baseClasses.small}>対象：投資用1Rマンションに興味がある顧客</Typography>
      <Typography className={baseClasses.small}>関連：プレサンスコーポレーション</Typography>
      <Typography className={baseClasses.small}>3,000万円前後の1R投資用マンションを販売</Typography>
    </Grid>
  );
};
const message5 = (baseClasses) => {
  return (
    <Grid>
      <Typography className={baseClasses.small}>対象：戸建の建て替えに興味がある顧客</Typography>
      <Typography className={baseClasses.small}>関連：オープンハウス・アーキテクト</Typography>
      <Typography className={baseClasses.small}>戸建の建て替えを提案</Typography>
    </Grid>
  );
};

function CustomerExamination(props) {
  const { input } = props;
  const { update, isSp, current } = useUpdate(input.customer, [
    'customerId',
    'isThinkSale',
    'isThinkApartment',
    'isThinkWholeHouseInvestment',
    'isThinkDivisionInvestment',
    'isThinkRebuilding',
  ]);

  const classes = useStyles();
  const baseClasses = commonStyles();

  let contents;

  const [checked, setChecked] = useState({
    isThinkSale: current.isThinkSale === 1,
    isThinkApartment: current.isThinkApartment === 1,
    isThinkWholeHouseInvestment: current.isThinkWholeHouseInvestment === 1,
    isThinkDivisionInvestment: current.isThinkDivisionInvestment === 1,
    isThinkRebuilding: current.isThinkRebuilding === 1,
  });
  const {
    isThinkSale,
    isThinkApartment,
    isThinkWholeHouseInvestment,
    isThinkDivisionInvestment,
    isThinkRebuilding,
  } = checked;

  // 値が変わった時にステートを更新
  const examinationChange = (e) => {
    const { name, checked: isChecked } = e.target;
    setChecked({ ...checked, [name]: isChecked });
    // 更新処理
    update({ [name]: +isChecked });
  };

  const getCheck1 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkSale} onChange={examinationChange} name="isThinkSale" />}
        label="売却"
      />
    );
  };

  const getCheck2 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkApartment} onChange={examinationChange} name="isThinkApartment" />}
        label="マンション"
      />
    );
  };

  const getCheck3 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkWholeHouseInvestment} onChange={examinationChange} name="isThinkWholeHouseInvestment" />}
        label="一棟物投資"
      />
    );
  };

  const getCheck4 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkDivisionInvestment} onChange={examinationChange} name="isThinkDivisionInvestment" />}
        label="区分投資"
      />
    );
  };

  const getCheck5 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={isThinkRebuilding} onChange={examinationChange} name="isThinkRebuilding" />}
        label="建替"
      />
    );
  };

  const list = [
    {
      lbl: '売却',
      val: isThinkSale,
      content: getCheck1,
    },
    {
      lbl: 'マンション',
      val: isThinkApartment,
      content: getCheck2,
    },
    {
      lbl: '一棟物投資',
      val: isThinkWholeHouseInvestment,
      content: getCheck3,
    },
    {
      lbl: '区分投資',
      val: isThinkDivisionInvestment,
      content: getCheck4,
    },
    {
      lbl: '建替',
      val: isThinkRebuilding,
      content: getCheck5,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          検討
        </Typography>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {item.content()}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Typography className={classes.lbl}>
            検討
          </Typography>
          <FormGroup className={classes.formGroup}>
            <NormalTooltip title={message1(baseClasses)}>
              {getCheck1()}
            </NormalTooltip>
            <NormalTooltip title={message2(baseClasses)}>
              {getCheck2()}
            </NormalTooltip>
            <NormalTooltip title={message3(baseClasses)}>
              {getCheck3()}
            </NormalTooltip>
            <NormalTooltip title={message4(baseClasses)}>
              {getCheck4()}
            </NormalTooltip>
            <NormalTooltip title={message5(baseClasses)}>
              {getCheck5()}
            </NormalTooltip>
          </FormGroup>
        </FormControl>
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerExamination);
