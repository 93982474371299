import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import commonStyles from '../../../styles';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';
import { changeAlertMessage } from '../../../../store/eleCommon/customAlertMessage';
import {
  CUSTOMER_STATUS_CLAIM,
  CUSTOMER_STATUS_CODE,
  TEL_STATUS_CODE,
  EMAIL_STATUS_CODE,
  USER_LEVEL,
} from '../../../../constants';
import ClaimRegister from '../../claimRegister';
import DoNotChaseReason from '../../doNotChaseReason';
import ResponseRegisterAlert from './responseRegisterAlert';
import { classNames, toNumber } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  inHouse: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
  },
  inHouseInfoBox: {
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  inHouseInfoBlock: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  inHouseInfoName: {
    display: 'flex',
    alignItems: 'center',
  },
  inHouseInfoName1: {
    marginRight: 4,
  },
  inHouseInfoSelect: {
    marginLeft: 8,
    '& .MuiSelect-select.MuiSelect-select': {
      fontWeight: 400,
    },
    '& .MuiSelect-select': {
      '&:focus': {
        background: 'none',
      },
    },
  },
  inHouseInfoStatus1: {
    padding: 8,
  },
  inHouseInfoStatus2: {
    pointerEvents: 'none',
    background: '#C8C8C8',
    padding: 8,
    '& button': {
      background: '#DEDEDE',
      '& > span': {
        color: '#AFAFAF',
      },
    },
  },
  inHouseInfoBtn: {
    padding: '4px 10px',
    '& > span': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 5px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& > .MuiIconButton-label': {
        background: '#fff',
        width: 18,
        height: 18,
        borderRadius: 4,
      },
    },
  },
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  inHouseRankCheck: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  inHouseRank: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    width: 270,
  },
  star: {
    position: 'relative',
    width: 118,
    height: 20,
    margin: '-4px 0 0 10px',
  },
  starLbl: {
    background: '#fff',
    position: 'absolute',
    zIndex: '2',
    top: '-2px',
    left: 0,
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    minHeight: 23,
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  starSelect: {
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    minHeight: 20,
    display: 'flex',
    alignItems: 'center',
  },
  inHouseTf: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inHouseTfLbl: {
    whiteSpace: 'nowrap',
  },
  inHouseTfInput: {
    margin: '-3px 0 0 8px',
    width: '100%',
  },
  claim: {
    pointerEvents: 'none',
    background: '#C8C8C8',
  },
  // モーダル
  paper: {
    width: 515,
    backgroundColor: '#fff',
    boxShadow:
      '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 60px',
  },
  formControl: {
    width: '100%',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
}));

export default function ResponseRegisterInHouse(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  const {
    // user,
    detail,
    setDetail,
    getComponent,
    isLeadersMemoUse,
    isClaim,
    isNew,
    customerId,
  } = props;

  const [reasonStatus, setReasonStatus] = useState({
    noContactDetailReasonCode: detail.noContactDetailReasonCode,
    searchArea: detail.searchArea,
    reasonNotOhd: detail.reasonNotOhd,
    searchStartAt: detail.searchStartAt,
    reasonNotSearch: detail.reasonNotSearch,
    reasonNotBuy: detail.reasonNotBuy,
  });

  // 星ランクによるメールステータスの選択肢制御
  const [isOpenMailStatueAlert, setIsOpenMailStatueAlert] = useState(false);
  const [forceRenderId, setForceRenderId] = useState(0);
  const openMailStatueAlert = () => {
    setIsOpenMailStatueAlert(true);
  };
  const closeMailStatueAlert = () => {
    setIsOpenMailStatueAlert(false);
  };

  // ローカルステートの更新
  const initial = detail;
  const onBlur = (e) => {
    if (e.target.value !== detail[e.target.name]) {
      initial[e.target.name] = e.target.value;
      setDetail({ ...detail });
      console.log(detail[e.target.name]);
    }
  };
  const telStatusCodeBlur = (e) => {
    if (e.target.value !== detail.telStatusCode) {
      initial.telStatusCode = e.target.value;
      setDetail({ ...detail });
      console.log(detail.telStatusCode);
    }
  };

  const prevEmailStatusRef = useRef(detail.emailStatusCode);
  const emailStatusCodeBlur = (e) => {
    if (e.target.value === detail.emailStatusCode) return;

    if (
      detail.userLevel === 2
      && (e.target.value === '1' || e.target.value === '3')
    ) {
      // SFA_ASIAQUEST-2341 【CLIENT結合】GA-066-PC 新規顧客データの作成画面 / 星ランク制御
      // 星ランクが2の顧客で、メールステータスを「ダメ全部NG」と「代行もすべてOK」以外に変更しようとした場合
      openMailStatueAlert();
      initial.emailStatusCode = prevEmailStatusRef.current;
      setDetail({ ...detail });
      setForceRenderId((state) => state + 1);
      return;
    }

    initial.emailStatusCode = e.target.value;
    prevEmailStatusRef.current = e.target.value;
    setDetail({ ...detail });
    console.log(detail.emailStatusCode);
  };

  // チェックボックス 顧客紹介対象
  const [checked, setChecked] = useState(detail.isCustomerIntroduction === 1);
  const isCustomerIntroductionChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    initial.isCustomerIntroduction = isChecked ? 1 : 0;
    setDetail({ ...detail });
  };

  // 顧客ステータス
  const tempStatusCode = { ...CUSTOMER_STATUS_CODE };
  if (isNew) delete tempStatusCode[CUSTOMER_STATUS_CLAIM];
  const menusKeys = Object.keys(tempStatusCode);
  const val = detail.customerStatusCode;

  // 追わない customerStatusCode:2
  // [GA092]画面表示用
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  // プルダウンの値を変更
  const customerStatusSet2 = (value) => {
    initial.noContactDetailReasonCode = value.noContactDetailReasonCode;
    initial.searchArea = value.searchArea;
    initial.reasonNotOhd = value.reasonNotOhd;
    initial.searchStartAt = value.searchStartAt ? `${value.searchStartAt} 00:00:00` : '';
    initial.reasonNotSearch = value.reasonNotSearch;
    initial.reasonNotBuy = value.reasonNotBuy;
    initial.customerStatusCode = 2;
    setDetail({ ...detail });
  };

  // クレーム [GA018]画面
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  // プルダウンの値を変更
  const customerStatusSet11 = () => {
    initial.customerStatusCode = 11;
    setDetail({ ...detail });
  };

  const customerStatusCodeBlur = (e) => {
    if (e.target.value !== detail.customerStatusCode) {
      initial.customerStatusCode = e.target.value;
      setDetail({ ...detail });
      console.log(detail.customerStatusCode);
    }
  };

  const customerStatusSet = () => {
    initial.customerStatusCode = 10;
    setDetail({ ...detail });
  };

  // 確認ウインドウ 追わない
  const confirmOpenClaim = () => {
    dispatch(changeConfirmMessage({
      title: 'クレームはありませんでしたか？',
      msgList: ['クレームをいただいた場合はクレーム登録を行ってください。'],
      buttons: [
        {
          label: 'クレーム登録',
          set: () => {
            handleOpen2();
          },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'はい',
          set: () => {
            // [GA092]画面を呼び出す
            handleOpen3();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const confirmOpenAuto = () => {
    dispatch(changeConfirmMessage({
      title: 'まずは自動追客対象にしませんか？',
      msgList: ['メールの許可を「代行も全てOK」に変更すると設定できます。'],
      buttons: [
        {
          label: '代行も全てOK にする',
          set: () => {
            // メールステータスを変更
            initial.emailStatusCode = 5;
            setDetail({ ...detail });
          },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'しない',
          set: () => {
            confirmOpenClaim();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // 確認ウインドウ 契約
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '',
      msgList: ['顧客ステータスを契約にして保存すると、', '契約済み顧客となりますが、本当によろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => {
            customerStatusSet();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const customerStatusCodeChange = (e) => {
    switch (Number(e.target.value)) {
      case 1:
        // 追客中
        initial.customerStatusCode = 1;
        setDetail({ ...detail });
        break;
      case 2:
        // 追わない
        confirmOpenAuto();
        break;
      case 10:
        // 契約済み
        confirmOpen();
        break;
      case 11:
        // クレーム
        // [GA018]画面を呼び出す
        handleOpen2();
        break;
      default:
        break;
    }
  };

  // ランク
  const [star, setStar] = useState(toNumber(detail.userLevel));
  const userLevelChange = (e) => {
    const userLevel = toNumber(e.target.value);

    if (star !== userLevel) {
      const array = [0, 5];
      switch (userLevel) {
        case 2:
          if (!array.includes(detail.emailStatusCode)) {
            initial.emailStatusCode = 5;
            dispatch(changeAlertMessage({
              msgList: [], // 必須
              title: 'メール許可を「代行もすべてOK」に変更しました。', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
              isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
            }));
          }
          setStar(userLevel);
          initial.userLevel = userLevel;
          prevEmailStatusRef.current = '5';
          setDetail({ ...detail });
          break;
        case 3:
          if (!array.includes(detail.emailStatusCode)) {
            dispatch(changeConfirmMessage({
              title: 'メール許可を「代行もすべてOK」に変更しますか？',
              msgList: ['「代行もすべてOK」に変更すると、自動で追客されます。'],
              buttons: [
                {
                  label: '代行も全てOK にする',
                  set: () => {
                    setStar(userLevel);
                    initial.emailStatusCode = 5;
                    initial.userLevel = userLevel;
                    setDetail({ ...detail });
                  },
                  classes: baseClasses.buttonsSecondary,
                },
                {
                  label: 'しない',
                  set: () => {
                    setStar(userLevel);
                    initial.userLevel = userLevel;
                    setDetail({ ...detail });
                  },
                  classes: baseClasses.buttonsPrimary,
                },
              ],
            }));
          } else {
            setStar(userLevel);
            initial.userLevel = userLevel;
            setDetail({ ...detail });
          }
          break;
        default:
          setStar(userLevel);
          initial.userLevel = userLevel;
          setDetail({ ...detail });
          break;
      }
    }
  };

  // プルダウン
  const inHouseSelectList = {
    telStatusCodeItem: {
      type: 'select',
      obj: {
        menus: TEL_STATUS_CODE,
      },
      state: detail.telStatusCode,
      set: telStatusCodeBlur,
    },
    emailStatusCodeItem: {
      type: 'select',
      obj: {
        menus: EMAIL_STATUS_CODE,
      },
      state: detail.emailStatusCode,
      set: emailStatusCodeBlur,
    },
    rankItem: {
      type: 'selectBack',
      obj: {
        menus: USER_LEVEL,
      },
      state: star,
      change: userLevelChange,
      // set: userLevelBlur,
    },
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>社内情報</Typography>
      <Grid className={classes.inHouse}>
        <Grid className={classes.inHouseInfo}>
          <Grid className={classes.inHouseInfoBox}>
            <Grid className={classes.inHouseInfoBlock}>
              <Typography className={baseClasses.title4}>状態</Typography>
              <Grid className={classes.inHouseInfoSelect}>
                {isClaim ? (
                  <TextField
                    className={classes.textField}
                    defaultValue={CUSTOMER_STATUS_CODE[val]}
                    name="customerStatusCode"
                    disabled
                  />
                ) : (
                  <FormControl
                    className={`${classes.formControl} ${classes.formControl2}`}
                  >
                    <Select
                      value={val}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={customerStatusCodeChange}
                      onBlur={customerStatusCodeBlur}
                    >
                      {menusKeys.map((data) => (
                        <MenuItem key={data} value={data}>
                          {CUSTOMER_STATUS_CODE[data]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <ClaimRegister
                open={open2}
                onClose={handleClose2}
                id=""
                setStatus={customerStatusSet11}
                customerId={customerId}
              />
              <DoNotChaseReason
                open={open3}
                onClose={handleClose3}
                setStatus={customerStatusSet2}
                userData={reasonStatus}
                setUserData={setReasonStatus}
              />
            </Grid>
            <Grid className={classes.inHouseInfoBlock}>
              <Typography className={baseClasses.title4}>
                電話ステータス
              </Typography>
              <Grid className={classes.inHouseInfoSelect}>
                {isClaim ? (
                  <TextField
                    className={classes.textField}
                    defaultValue={TEL_STATUS_CODE[detail.telStatusCode]}
                    name="telStatusCode"
                    disabled
                  />
                ) : (
                  getComponent(inHouseSelectList.telStatusCodeItem)
                )}
              </Grid>
            </Grid>
            <Grid className={classes.inHouseInfoBlock}>
              <Typography className={baseClasses.title4}>メール許可</Typography>
              <Grid className={classes.inHouseInfoSelect} key={forceRenderId}>
                {isClaim ? (
                  <TextField
                    className={classes.textField}
                    defaultValue={EMAIL_STATUS_CODE[detail.emailStatusCode]}
                    name="emailStatusCode"
                    disabled
                  />
                ) : (
                  getComponent(inHouseSelectList.emailStatusCodeItem)
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.inHouseRankCheck}>
        <Grid className={classes.inHouseRank}>
          <Typography
            className={classNames(baseClasses.title4, 'nowrap')}
          >
            ランク（課員）
          </Typography>
          <Grid className={classes.star}>
            <Rating
              name="size-large"
              value={star}
              size="large"
              readOnly
              className={classes.starLbl}
            />
            <Grid className={classes.starSelect}>
              {getComponent(inHouseSelectList.rankItem)}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={checked}
                onChange={isCustomerIntroductionChange}
                name="isCustomerIntroduction"
              />
            }
            label="顧客紹介対象"
          />
        </Grid>
      </Grid>
      {isLeadersMemoUse && (
        <Grid className={classes.inHouse}>
          <Grid className={classes.inHouseTf}>
            <Typography
              className={`${classes.inHouseTfLbl} ${baseClasses.title4}`}
            >
              上長メモ
            </Typography>
            <TextField
              className={`${classes.inHouseTfInput} ${classes.textField}`}
              defaultValue={detail.leaderMemo}
              name="leaderMemo"
              onBlur={onBlur}
            />
          </Grid>
        </Grid>
      )}
      <ResponseRegisterAlert
        open={isOpenMailStatueAlert}
        onClose={closeMailStatueAlert}
        data={{
          txt: 'メール許可を「個別のみOK」「個別・一斉OK」にする場合は、ランクを変更してください。',
        }}
      />
    </Grid>
  );
}
