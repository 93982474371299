import { useEffect } from 'react';

/**
 * delay(秒数)毎に実行したいcallback関数を実行
 */
export default function useInterval(callback, delay = null) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(() => callback(), delay || 0);
      return () => clearInterval(interval);
    }
  }, [callback, delay]);
}
