// マウスホイールと上下キーによる数値の増減を無効にする
const inputNumber = () => {
  const inputNumbers = document.querySelectorAll('input[type=number]');
  for (let i = 0; i < inputNumbers.length; i += 1) {
    inputNumbers[i].addEventListener('wheel', e => {
      e.preventDefault();
    }, { passive: false });
    inputNumbers[i].addEventListener('keydown', e => {
      switch (e.code) {
        case 'ArrowUp':
        case 'ArrowDown':
          e.preventDefault();
          break;
        default:
          return false;
      }
      return false;
    });
  }
};

export default inputNumber;
