import { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import InfoChangeHistoryItem from './infoChangeHistoryItemSp';
import LoadingEllipsis from '../../../pullRefComponent/parts/loadingEllipsis';
import commonStyles from '../../../../styles';

import { useLoading } from '../../../../../hooks';
import { INFO_CHANGE_HISTORY_LOADING } from '../../../../../constants/loading';

const useStyles = makeStyles(() => ({
  root: {
    padding: '17px 8px 124px 0',
    overflowY: 'auto',
    height: 'calc(100vh - 58px)',
    background: '#fff',
  },
  list: {
    paddingTop: 16,
  },
  itemWrap: {
    '&.isGroup': {
      position: 'relative',
      marginTop: '-1px',
      zIndex: 1,
    },
  },
  date: {
    position: 'relative',
    background: '#fff',
    '&::before': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: '100%',
      height: '1px',
      position: 'absolute',
      top: '50%',
    },
  },
  dateTxt: {
    position: 'relative',
    background: '#fff',
    display: 'inline-block',
    padding: '8px',
    color: '#8C8C8C',
  },
}));

function InfoChangeHistoryItemsSp(props) {
  const { histories = [], onScroll, rerenderKey } = props;

  const classes = useStyles();
  const common = commonStyles();

  const { hasLoading } = useLoading();
  const isLoading = hasLoading(INFO_CHANGE_HISTORY_LOADING);

  const element = useRef(null); // スクロール要素

  // コンテンツのスクロール量を検知し、下スクロールで更新カウントを取得
  const handleScroll = () => {
    const elH = element.current.clientHeight;
    const maxScroll = element.current.scrollHeight;
    const scrollTopVal = element.current.scrollTop;
    const loadingElH = 64; // ローディングアイコン分の高さ
    const diff = maxScroll - elH;

    if (diff - scrollTopVal < loadingElH && !isLoading) onScroll();
  };

  return (
    <Grid key={rerenderKey} className={classes.root} ref={element} onScroll={handleScroll}>
      <Grid className={classes.list}>
        {histories.map((history, index) => {
          const indextDate = histories[index > 0 ? index - 1 : 0].createdAt;
          const conditionA = index > 0 && history.createdAt.split(/\s+/)[0] !== indextDate.split(/\s+/)[0];
          const conditionB = index === 0;
          return (
            <Grid className={`${classes.itemWrap} ${conditionA ? 'isGroup' : ''}`} key={history.customerHistoryId}>
              {/* 前データの日程と差分があったら日程を表示 */}
              {(conditionA || conditionB) && (
                <Grid className={classes.date}>
                  <Typography className={`${common.title4} ${classes.dateTxt}`}>
                    {history.createdAt.substr(0, 10)}
                  </Typography>
                </Grid>
              )}
              <InfoChangeHistoryItem history={history} />
            </Grid>
          );
        })}
      </Grid>
      {/* ローディング用アイコン */}
      {isLoading && <LoadingEllipsis />}
    </Grid>
  );
}

export default InfoChangeHistoryItemsSp;
