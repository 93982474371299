import { isArray } from '../../../../commonFunction';

function formatUsers(parent, users, parentUniqueId, onlyDivision, isNoAffili) {
  const type = 'user';
  let uniqueId;
  if (onlyDivision) return [];
  return users.map((item) => {
    uniqueId = `${parentUniqueId}${parentUniqueId ? '_' : ''}${type}${item.userId}`;
    return {
      ...item,
      type,
      id: item.userId,
      label: `${item.userName}${item.isMainDivision || isNoAffili ? '' : '(サブ)'}`,
      uniqueId,
      selectable: true,
      parentId: parent.id,
      parentName: parent.name,
      parentType: parent.type,
      isMain: item.isMainDivision,
      children: [],
    };
  });
}

// eslint-disable-next-line max-len
function formatDivisionChildren(parent, children, users, parentUniqueId, options, isDuplicate, onlyDivision, isMulti, setTargetDivisionTree) {
  const arr = [];
  if (children?.length) {
    // eslint-disable-next-line no-use-before-define, max-len
    arr.push(...createTreeList(children, null, parentUniqueId, isDuplicate, options, parent, isMulti, setTargetDivisionTree));
  }
  if (users?.length && !isDuplicate) {
    arr.push(...formatUsers(parent, users, parentUniqueId, onlyDivision));
  }
  return arr;
}

// Treeを形成
export function createTreeList(list, noAffiliList, parentUniqueId = '', isDuplicate = false, options = {}, parent = {}, isMulti = false, setTargetDivisionTree = false) {
  const type = 'division';
  if (!parentUniqueId) console.time('createTreeList');
  const arr = [];
  const uniqueIdPrefix = parentUniqueId ? `${parentUniqueId}_` : '';
  list.forEach((item) => {
    const uniqueId = `${uniqueIdPrefix}${type}${item.divisionId}`;
    // 組織
    arr.push({
      label: item.subName,
      levelCode: item.levelCode,
      type,
      id: item.divisionId,
      name: item.subName,
      parentId: parent.id,
      parentName: parent.name,
      parentType: parent.type,
      selectable: isMulti && !options?.onlyUser ? true : options.divisionFlg,
      uniqueId,
      users: !setTargetDivisionTree ? [] : item.users,
      children: formatDivisionChildren(
        {
          type,
          id: item.divisionId,
          name: item.subName,
        },
        item.children,
        item.users,
        uniqueId,
        options,
        isDuplicate,
        options.onlyDivision,
        isMulti,
        setTargetDivisionTree,
      ),
    });
  });

  if (isArray(noAffiliList) && options?.isUserMaster) {
    const uniqueId1 = `${uniqueIdPrefix}division0`;
    const divInfo = {
      id: 0,
      name: null,
      type,
    };
    arr.push({
      ...divInfo,
      label: '所属無し',
      type,
      selectable: isMulti && !options?.onlyUser ? true : options.divisionFlg,
      // 新規と編集アイコンを非表示する
      hideHoverNodes: true,
      uniqueId: uniqueId1,
      // eslint-disable-next-line max-len
      children: options.divisionFlg ? [] : formatUsers(divInfo, noAffiliList, uniqueId1, false, true),
    });
  }
  if (!parentUniqueId) console.timeEnd('createTreeList');
  return arr;
}

// userIdを元にツリー内から対象を取得.
export function getTreeItemByUserId(treeList, userId, divisionId) {
  let item;
  for (let i = 0; i < treeList.length; i += 1) {
    item = treeList[i];
    if (item.type === 'division') {
      const temp = getTreeItemByUserId(item.children, userId, divisionId);
      if (temp) return temp;
    } else if (item.type === 'user') {
      if (divisionId) {
        if (item.id === userId && item.userDivisionId === divisionId) return item;
      } else if (item.id === userId) return item;
    }
  }
  return null;
}

// userNameを元にツリー内から対象を取得
export function getTreeItemByUserName(treeList, userName) {
  const result = [];
  const getUserName = (list, word) => {
    let item;
    for (let i = 0; i < list.length; i += 1) {
      item = list[i];
      if (item.type === 'division') {
        const temp = getUserName(item.children, word);
        if (temp) return temp;
      } else if (item.type === 'user') {
        if (item.userName.includes(word)) result.push(item);
      }
    }
    return null;
  };
  getUserName(treeList, userName);
  return result;
}

// divisionIdを元にツリー内から対象を取得
export function getTreeItemByDivisionId(treeList, divisionId) {
  let result;
  // divisionIDをKeyにツリーから対象を抽出
  const getDivision = (list, divId) => {
    let item;
    for (let i = 0; i < list.length; i += 1) {
      item = list[i];
      if (item.type === 'division') {
        if (item.id === divisionId) {
          result = item;
          break;
        }
        if (item.children) getDivision(item.children, divId);
      }
    }
    return null;
  };
  getDivision(treeList, divisionId);
  return result;
}

// divisionNameを元にツリー内から対象を取得
export function getTreeItemByDivisionName(treeList, divisionName) {
  const result = [];
  const getDivisionName = (list, word) => {
    let item;
    for (let i = 0; i < list.length; i += 1) {
      item = list[i];
      if (item.type === 'division') {
        if (item.name.includes(word)) result.push(item);
        if (item.children) getDivisionName(item.children, word);
      }
    }
    return null;
  };
  getDivisionName(treeList, divisionName);
  return result;
}

// division配下にdivisionがあるか判定(Openの開閉の為)
export function isDivisionAddComponent(division) {
  let item;
  const result = [];
  for (let i = 0; division.children?.length > i; i += 1) {
    item = division.children[i];
    result.push(item.type === 'division');
  }
  return result.includes(true);
}

// 配列（divisionId複数）を元に対象を配列で返す
export function getTreeMultiItemByDivisionId(treeList, divisionIdArray) {
  const result = [];
  let count = 0;
  // divisionIDをKeyにツリーから対象を抽出
  const getDivision = (list, divId) => {
    let item;
    for (let i = 0; i < list.length; i += 1) {
      item = list[i];
      if (item.type === 'division') {
        if (item.id === Number(divId)) {
          result.push(item);
          count += 1;
          if (item.children) getDivision(item.children, divisionIdArray[count]);
          break;
        }
      }
    }
    return null;
  };
  getDivision(treeList, divisionIdArray[count]);
  return result;
}

// divisionを受取り、そのdivisionまでの全ての親組織を配列で返す
export const getPastDivisionArray = (treeList, division, isUser) => {
  const result = [];
  const uniqueIds = division.uniqueId.split('_');
  if (uniqueIds.length > 1) {
    for (let i = 0; uniqueIds.length > i; i += 1) {
      result.push(uniqueIds[i].replace(/[^0-9]/g, ''));
    }
    if (!isUser) result.pop();
    return getTreeMultiItemByDivisionId(treeList, result);
  }
  return [];
};

// userのparentIdを受取り、親系全てのdivisionIdと現在のinitialSelectを併合
export const setSelectCount = (uniqueId, initialSelect, isMinus) => {
  const uniqueIds = uniqueId.split('_');
  uniqueIds.pop();
  if (uniqueIds.length > 0) {
    for (let i = 0; uniqueIds.length > i; i += 1) {
      const divisionId = String(uniqueIds[i].replace(/[^0-9]/g, ''));
      if (isMinus) {
        initialSelect[divisionId] -= 1;
      } else {
        // eslint-disable-next-line max-len
        divisionId in initialSelect ? initialSelect[divisionId] += 1 : initialSelect[divisionId] = 1;
      }
    }
    return initialSelect;
  }
  return [];
};

// getAllUsers関数のuserIdsを受取り、initialSelectから対象divisionの総数を増減
export const setSelectCountForDivision = (uniques, initialSelect, isMinus) => {
  const divisions = [];
  for (let i = 0; uniques.length > i; i += 1) {
    const uniqueIds = uniques[i].split('_');
    uniqueIds.pop();
    for (let ii = 0; uniqueIds.length > ii; ii += 1) {
      divisions.push(uniqueIds[ii].replace(/[^0-9]/g, ''));
    }
  }
  const obj = Object.assign({}, initialSelect);
  for (let i = 0; divisions.length > i; i += 1) {
    const divisionId = String(divisions[i]);
    if (isMinus) {
      obj[divisionId] -= 1;
    } else {
      divisionId in obj ? obj[divisionId] += 1 : obj[divisionId] = 1;
    }
  }
  if (!isMinus) {
    for (const p in obj) {
      if (p in initialSelect) {
        obj[p] -= initialSelect[p];
      }
    }
  }
  return obj;
};

// 配列内重複削除
export const removeDuplicateArray = (arr) => {
  if (arr.length === 0) return [];
  const uniqueArr = new Set(arr);
  const newArr = [...uniqueArr];
  return newArr;
};

/**
 * @module getAllUsers - function
 * @description - divisionIdを受取、配下の全てのuserをselectUserベースで返す
 * @example [{userId: 9, userName: '田中綾', userDivisionId: 1572, userDivisionName: '闇の組織'}]
 */
export const getAllUsers = (divisions) => {
  const result = [];
  const userIds = [];
  const divisionIds = [];
  const uniques = [];
  // divisionIDをKeyにツリーから対象を抽出
  const getUsers = (divisionArray) => {
    let item;
    for (let i = 0; i < divisionArray.length; i += 1) {
      item = divisionArray[i];
      if (item.type === 'division') divisionIds.push(item.id);
      if (item.type === 'user') {
        userIds.push(`${item.userId}_${item.parentId ?? '0'}`);
        uniques.push(item.uniqueId);
        result.push({
          userId: item.userId,
          userName: item.userName,
          userDivisionId: item.parentId,
          userDivisionName: item.parentName,
        });
      }
      if (item.children) getUsers(item.children);
    }
    return null;
  };
  getUsers(divisions);
  const newUserIds = removeDuplicateArray(userIds);
  const newDivisions = removeDuplicateArray(divisionIds);
  const newUniques = removeDuplicateArray(uniques);
  return [result, newUserIds, newDivisions, newUniques];
};
