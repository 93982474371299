import client from '../httpClient';
/**
 * @param {{
 *          userLevel:number,
 *          mailStatusCode:number,
 *          customerIds:number[]}} params,
 */
const customerPostUpdateStatusMultiCustomerApi = (params) => {
  return client.post('/customers/update-status-multi-customer', JSON.stringify(params));
};

export default customerPostUpdateStatusMultiCustomerApi;
