import client from '../httpClient';

const headers = {
  'content-type': 'multipart/form-data',
};

/**
 * 【combination-setting006】組み合わせ設定アップロード
 * http://10.22.2.48:8080/docs/#/combinationSetting/post-combination-settings-csv-upload
 * @returns Promise<*>
 */
const combinationSettingPostCsvUploadApi = async (file) => {
  const data = new FormData();
  // TODO APIのパラメーター不明
  data.append('csvFile', file);

  const res = await client.post('/combination-settings/csv-upload', data, { headers });
  return res.data;
};

export default combinationSettingPostCsvUploadApi;
