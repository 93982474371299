import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const flyerSalesTreeSlice = createSlice({
  name: 'flyerSalesGetTree',
  initialState: { flyerSalesGetTree: initialState },
  reducers: {
    getFlyerTreeApi: (state, data) => {
      state.flyerSalesGetTree = data.payload;
    },
  },
});

export const { getFlyerTreeApi } = flyerSalesTreeSlice.actions;

export default flyerSalesTreeSlice.reducer;
