import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import UserTree from '../common/userTreeNew';

export default function UserTreeSelectTest() {
  // ツリー表示/非表示
  const [isOpen, setIsOpen] = useState(false);
  // 組織ツリー選択ユーザー
  // const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  const [selectUser, setSelectUser] = useState({ userId: 5192, userName: '渡邊 界' });
  // const [selectUser, setSelectUser] = useState([
  //   { userId: 5192, userName: '渡邊界', userDivisionId: 1572, userDivisionName: '闇の組織' },
  //   { userId: 5587, userName: '叶樹欣', userDivisionId: 1698, userDivisionName: 'データ課' },
  // ]);
  // const setSelectUser1 = (a) => {
  //   setSelectUser(a);
  // };
  // 組織ツリー選択組織
  const [selectDivision, setSelectDivision] = useState([]);
  // const [selectDivision, setSelectDivision] = useState({ divId: 1572, divisionName: '' });
  // const [selectDivision, setSelectDivision] = useState({ divId: 1, divisionName: 'テスト営業部2' });
  // const [initialAcquirer, setInitialAcquirer] = useState('1');
  // const [targetDivisionTree, setTargetDivisionTree] = useState(null);

  useEffect(() => {
    console.log('------ 選択されたユーザー ------');
    console.log({ selectUser });
    console.log('------ 選択された組織 ------');
    console.log({ selectDivision });
  }, [selectUser, selectDivision]);

  const handleCheck = () => {
    // console.log(targetDivisionTree);
    console.log('checkState');
    console.log(selectUser);
    console.log(selectDivision);
  };

  return (
    <>
      <Button onClick={() => { setIsOpen(true); }}>
        担当者選択ウィンドウ（1人）
      </Button>
      <Button onClick={() => handleCheck()}>CheckState</Button>
      <UserTree
        isOpenTree={isOpen}
        setIsOpenTree={setIsOpen}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        isDefaultLoginUser
        // showOnlyDivisions
        // isSelectUserWindow
        // isAcquirer
        // initialAcquirer={initialAcquirer}
        // setInitialAcquirer={setInitialAcquirer}
        clickableUserAndDivision
        isDialog
        doubleSearch
        // isMulti
        // showOnlyDivisions
        // setTargetDivisionTree={setTargetDivisionTree}
      />
    </>
  );
}
