import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setSettingGroups, deleteSettingGroup } from '../../store/settingGroupStore/settingGroupsSlice';

import settingGroupStoreSearchApi from '../../apis/settingGroupStore/settingGroupStoreSearchApi';
import settingGroupStoreDeleteApi from '../../apis/settingGroupStore/settingGroupStoreDeleteApi';

import SetStoreGroup from '../../components/setStoreGroup/setStoreGroup';
import { useLoading } from '../../hooks';

function SetStoreGroupContainers() {
  const settingGroups = useSelector((state) => state.settingGroupsStore.settingGroups);
  const [areaCode, setAreaCode] = useState(0);
  const { addLoading, removeLoading } = useLoading();

  const dispatch = useDispatch();

  const getSettings = () => {
    addLoading('storeGroup');
    settingGroupStoreSearchApi(areaCode)
      .then((res) => {
        const { settings = [] } = res.data;
        dispatch(setSettingGroups(settings));
      })
      .catch((err) => console.log(err))
      .finally(() => removeLoading('storeGroup'));
  };

  const deleteStoreGroup = (settingGroupObj) => {
    const param = {
      areaCode: settingGroupObj.areaCode,
      storeGroupId: settingGroupObj.storeGroupId,
    };
    addLoading('storeGroup');
    settingGroupStoreDeleteApi(param)
      .then((res) => {
        dispatch(deleteSettingGroup(res.data));
        removeLoading('storeGroup');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // 未選択(areaCode == 0)の場合は除外
    if (areaCode && areaCode > 0) {
      getSettings();
    }
  }, [areaCode]);

  return (
    <SetStoreGroup
      settings={settingGroups}
      areaCode={areaCode}
      setAreaCode={setAreaCode}
      deleteStoreGroup={deleteStoreGroup}
    />
  );
}

export default SetStoreGroupContainers;
