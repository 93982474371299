import { useSelector } from 'react-redux';
import CreateResponsiblePerson from './parts/createResponsiblePerson';
import CommonDialog from '../../common/modal';

import TextInput from './parts/textInput';

import useManagmentLine from './hooks';
import ResponsiblePersonSp from './parts/sp/responsiblePersonSp';

function ManagementTalkRoom(props) {
  const { open, onClose, line, isNew, onRegist } = props;

  const { addParam, setAddParam, registLine, isInvalid } = useManagmentLine({
    line,
    onRegist,
  });

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={isNew ? 'トークルーム新規作成' : 'トークルーム'}
      width={640}
      buttons={[
        {
          label: 'キャンセル',
          onClick: onClose,
          type: 'secondary',
        },
        {
          label: '保存',
          onClick: registLine,
          disabled: isInvalid,
        },
      ]}
      white={isSp}
    >
      <TextInput
        defaultText={addParam.lineRoomName}
        onBlur={(lineRoomName) => setAddParam(s => ({ ...s, lineRoomName }))}
        label="トークルーム名"
      />
      <TextInput
        defaultText={
            Array.isArray(addParam.customerLineIds) && addParam.customerLineIds.length === 1
              ? addParam.customerLineIds[0]
              : ''
          }
        onBlur={(txt) => setAddParam(s => ({ ...s, customerLineIds: [txt] }))}
        label={addParam.isUseResourceId ? 'リソースID' : 'チャネルID'}
        isDisabled={addParam.isUseResourceId}
      />
      {isSp ? (
        <ResponsiblePersonSp addParam={addParam} setAddParam={setAddParam} />
      ) : (
        <CreateResponsiblePerson addParam={addParam} setAddParam={setAddParam} />
      )}
    </CommonDialog>
  );
}

export default ManagementTalkRoom;
