import { SendOutlined } from '@material-ui/icons';
import { Button, makeStyles } from '@material-ui/core';
import { useLoading } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginBottom: 14,
    flex: '0 0 40px',
    alignSelf: 'flex-end',
    minWidth: 'auto',
    height: 36,
    marginLeft: '8px',
    '&:disabled > span > svg': {
      color: '#AFAFAF',
    },
  },
  icon: {
    color: theme.palette.primaryColor,
  },
}));

export default function SmsSendContainer(props) {
  // eslint-disable-next-line max-len
  const { text, onSended, sendParams, setSendParams, functions, postFlg, setPostFlg, setTalk, talk } = props;
  const classes = useStyles();
  const { hasLoading } = useLoading();
  const send = async () => {
    // POST
    const telId = await functions.postSms(sendParams);
    if (!sendParams.smsCustomerTelId) {
      setSendParams({ ...sendParams, smsCustomerTelId: telId });
      setTalk({ ...talk, open: false });
    }
    if (postFlg && setPostFlg) {
      // 値が渡されていない？
      setPostFlg(postFlg + 1);
    }

    onSended();
  };

  return (
    <Button
      className={classes.btn}
      onClick={send}
      // 電話番号がない場合 または 送信するテキストが入力されてなければ、ボタンを非活性
      disabled={[null, '-'].includes(sendParams.phoneNumber) || text === '' || hasLoading('getSms')}
    >
      <SendOutlined
        className={classes.icon}
      />
    </Button>
  );
}
