import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerRegisterSlice = createSlice({
  name: 'customerRegister',
  initialState: { customerRegister: initialState },
  reducers: {
    customerRegisterApi: (state, data) => {
      state.customerRegister = data.payload;
    },
  },
});

export const { customerRegisterApi } = customerRegisterSlice.actions;

export default customerRegisterSlice.reducer;
