import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
} from '@material-ui/core';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
    '& span': {
      color: '#D83420',
    },
  },
  listVal: {
    textTransform: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

function EditDialog1(props) {
  const { click, lbl, required, fontSize, val } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Button
      className={classes.listBtn}
      onClick={click}
    >
      <span className={`${baseClasses.title6} ${classes.listLbl}`}>
        {lbl}
        <span>{required}</span>
      </span>
      <span
        className={`${baseClasses.title4} ${classes.listVal}`}
        style={{
          fontSize: fontSize || '13px',
        }}
      >
        {val}
      </span>
    </Button>
  );
}

export default EditDialog1;
