// redux管理不要そう
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const filestorageResisterSlice = createSlice({
  name: 'filestorageResister',
  initialState: { filestorageResister: initialState },
  reducers: {
    registerFilesApi: (state, data) => {
      state.filestorageResister = data.payload;
    },
  },
});

export const { registerFilesApi } = filestorageResisterSlice.actions;

export default filestorageResisterSlice.reducer;
