import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import InfoChangeHistoryItems from './parts/infoChangeHistoryItems';
import InfoChangeHistoryItemsSp from './parts/sp/infoChangeHistoryItemsSp';
import InfoChangeHistorySearch from './parts/infoChangeHistorySearch';
import InfoChangeHistorySearchWindowSp from './parts/sp/infoChangeHistorySearchWindowSp';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import SearchIcon from '../../icons/searchIcon';
import { useLoading } from '../../../hooks';
import PullRefComponent from '../pullRefComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      height: 'calc(100% - 48px)',
      padding: '12px 8px 0',
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 102,
      height: 'calc(100vh - 58px)',
      background: '#fff',
      overflow: 'hidden',
    },
  },
  button: {
    color: theme.palette.primaryColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    marginBottom: 12,
    minWidth: 'auto',
  },
  buttonTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 0 0 2px',
  },
  buttonIcon: {
    fontSize: 16,
  },
  searchButton: {
    backgroundColor: theme.palette.primaryColor,
    bottom: '82px',
    zIndex: 1105,
    right: 24,
    width: 56,
    height: 56,
    position: 'fixed',
    borderRadius: '50%',
    minWidth: 0,
  },
}));

function InfoChangeHistoryWrapper(props) {
  const {
    histories = [],
    getHistories,
    moreLoadFunc,
    search,
    setSearch,
    resetFunc,
    refreshing,
    onRefresh,
    searchFunc,
    rerenderKey,
  } = props;

  const common = commonStyles();
  const classes = useStyles();
  const { hasLoading } = useLoading();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [openSearchWindow, setOpenSearchWindow] = useState(false);
  const set = (key, value) => {
    setSearch({ ...search, [key]: value });
  };
  const set2 = (value1, value2) => {
    setSearch({
      ...search,
      infoMultiple: { startAt: value1, endAt: value2 },
    });
  };
  const reset = () => {
    resetFunc();
    setOpenSearchWindow(false);
  };

  let contents;

  if (isSp) {
    contents = (
      <>
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={onRefresh}
        >
          <Grid className={`${classes.root} ${hasLoading('infoChangeHistory') ? common.loading : ''}`}>
            <InfoChangeHistoryItemsSp
              histories={histories}
              onScroll={moreLoadFunc}
              rerenderKey={rerenderKey}
            />
          </Grid>
        </PullRefComponent>
        {/* 検索ボタン */}
        <Button
          onClick={() => {
            setOpenSearchWindow(!openSearchWindow);
          }}
          className={`${common.searchButton} ${classes.searchButton}`}
        >
          <SearchIcon />
        </Button>
        <InfoChangeHistorySearchWindowSp
          openSearchWindow={openSearchWindow}
          setOpenSearchWindow={setOpenSearchWindow}
          search={search}
          setSearch={set}
          setSearch2={set2}
          reset={reset}
          searchFunc={searchFunc}
        />
      </>
    );
  } else {
    contents = (
      <Grid className={`${classes.root} ${hasLoading('infoChangeHistory') ? common.loading : ''}`}>
        <Button className={classes.button} onClick={() => getHistories()}>
          <UpdateIcon className={classes.buttonIcon} />
          <Typography className={classes.buttonTxt}>更新</Typography>
        </Button>
        <InfoChangeHistoryItems
          histories={histories}
          onScroll={moreLoadFunc}
          rerenderKey={rerenderKey}
        />
        <InfoChangeHistorySearch
          search={search}
          setSearch={set}
          setSearch2={set2}
          reset={reset}
          searchFunc={searchFunc}
        />
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default InfoChangeHistoryWrapper;
