import calendarUploadCSVApi from '../../../apis/calendar/calendarUploadCSVApi';
import calendarDownloadCSVApi from '../../../apis/calendar/calendarDownloadCSVApi';

import CalendarMasterComponent from '../../../components/common/calendarMaster/calendarMasterComponent';

import { downloadCSV } from '../../../commonFunction/csv';

export default function CalendarMasterContainer(props) {
  const { onClose } = props;
  // TODO: API
  // /calendars/csv-upload upload
  // /calendars/csv-download download
  const uploadFunc = (e) => {
    console.log(e);
    calendarUploadCSVApi(e)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => onClose());
  };
  const downloadFunc = () => {
    calendarDownloadCSVApi()
      .then((res) => downloadCSV(res.data))
      .catch((err) => console.log(err))
      .finally(() => onClose());
  };
  return <CalendarMasterComponent upload={uploadFunc} download={downloadFunc} />;
}
