import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import TreeForMulti from '../../../components/common/commonTree/treeForMulti';
import TreeForMultiSp from '../../../components/common/commonTree/sp/treeForMultiSp';
import TreeForSingle from '../../../components/common/commonTree/treeForSingle';
import TreeForSingleSp from '../../../components/common/commonTree/sp/treeForSingleSp';
import TreeSearch from '../../../components/common/commonTree/treeSearch';
import TreeSelectDivision from '../../../components/common/commonTree/treeSelectDivision';
import TreeSelectUnitSp from '../../../components/common/commonTree/sp/treeSelectUnitSp';
import TreeSelectUnit from '../../../components/common/commonTree/treeSelectUnit';
// 担当者選択用Api
import divisionsGetTreeApi from '../../../apis/divisions/divisionsGetTreeApi';
import { getUserDivListApi } from '../../../store/user/userDivListSlice';

export default function CommonTreeContainers(props) {
  const {
    users,
    setUsers,
    selectUser,
    setSelectUser,
    divisionSelect,
    searchForm,
    customerTree,
    divisionPickable,
  } = props;

  // API連携
  const dispatch = useDispatch();
  const userDiv = useSelector((state) => state.userDivList.userDivList);

  // userDivListFunction
  const userDivListFunction = async (params) => {
    await divisionsGetTreeApi(params)
      .then((res) => {
        dispatch(getUserDivListApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [list, setList] = useState(userDiv);
  let getApi;

  useEffect(() => {
    const userDivParams = {
      isIgnoreExpire: 1,
      userStatusCode: [1, 2],
    };

    userDivListFunction(userDivParams);
  }, []);

  useEffect(() => {
    setList(userDiv);
  }, [userDiv]);

  // SP用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // const [searchObj, setSearchObj] = useState('');
  // 開閉用ローカルステート
  const [state, setState] = useState({});
  // 選択状態保持
  const [checked, setChecked] = useState(`${users.userId}_${users.userDivisionId}`);
  // 表示する組織とユーザー
  const [displayDivision, setDisplayDivision] = useState([]);
  // 選択したデータのルート情報を格納
  const [displayDivisionRoot, setDisplayDivisionRoot] = useState([]);
  // 検索状態
  const [searchedUserList, setSearchedUserList] = useState(false);

  if (divisionSelect) {
    getApi = (value) => {
      if (value.target.value % 2) {
        setList(userDiv);
        // setHeadValue(1);
      } else {
        setList(users.lists);
        // setHeadValue(2);
      }
    };
  }
  const [code, setCode] = useState({
    typeCode: 1,
    unitCode: 2,
  });
  // useEffect(() => {
  //   if (customerTree) {
  //     // TODO:[/division/customertree]APIを呼び出す
  //     if (code.typeCode % 2 && code.unitCode % 2) {
  //       setList(data01.data);
  //     } else {
  //       setList(data02.data);
  //     }
  //   }
  // }, [code]);
  // サーチ
  const [search, setSearch] = useState('');

  const contents = (
    <Grid container justifyContent="flex-end" alignItems="center">
      {isSp ? (
        <>
          {displayDivision.length === 0 && (
            <>
              {customerTree
                ? (
                  <TreeSelectUnitSp
                    setList={getApi}
                    code={code}
                    setCode={setCode}
                  />)
                : null}
              {searchForm
                ? (
                  <TreeSearch
                    list={list}
                    search={search}
                    setSearch={setSearch}
                    setDisplayDivision={setDisplayDivision}
                    setDisplayDivisionRoot={setDisplayDivisionRoot}
                    setChecked={setChecked}
                    setUsers={setUsers}
                    setSelectUser={setSelectUser}
                    setSearchedUserList={setSearchedUserList}
                  />)
                : null}
            </>
          )}
          {!selectUser
            ? (
              <TreeForSingleSp
                list={list}
                setUsers={setUsers}
                search={search}
                state={state}
                setState={setState}
                checked={checked}
                setChecked={setChecked}
                displayDivision={displayDivision}
                setDisplayDivision={setDisplayDivision}
                displayDivisionRoot={displayDivisionRoot}
                setDisplayDivisionRoot={setDisplayDivisionRoot}
                searchedUserList={searchedUserList}
                divisionPickable={divisionPickable}
              />)
            : (
              <TreeForMultiSp
                list={list}
                selectUser={selectUser}
                setSelectUser={setSelectUser}
                // setUsers={setUsers}
                search={search}
                state={state}
                setState={setState}
                checked={checked}
                setChecked={setChecked}
                displayDivision={displayDivision}
                setDisplayDivision={setDisplayDivision}
                displayDivisionRoot={displayDivisionRoot}
                setDisplayDivisionRoot={setDisplayDivisionRoot}
                searchedUserList={searchedUserList}
              />)}
        </>
      ) : (
        <>
          {divisionSelect
            ? (
              <TreeSelectDivision
                setList={getApi}
              />)
            : null}
          {searchForm
            ? (
              <TreeSearch
                list={list}
                search={search}
                setSearch={setSearch}
              />)
            : null}
          {customerTree
            ? (
              <TreeSelectUnit
                setList={getApi}
                code={code}
                setCode={setCode}
              />)
            : null}
          {!selectUser
            ? (
              <TreeForSingle
                list={list}
                users={users}
                setUsers={setUsers}
                search={search}
                divisionPickable={divisionPickable}
              />)
            : (
              <TreeForMulti
                list={list}
                selectUser={selectUser}
                setSelectUser={setSelectUser}
                search={search}
              />)}
        </>
      )}
    </Grid>
  );

  return contents;
}
