import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useStoreGroupsSettingsAreas } from '../../../components/combinationSetting/common/useStoreGroupsSettingsAreas';
import { setSettingGroups } from '../../../store/settingGroupStore/settingGroupsSlice';
import {
  setSettingGroupStoreDetail,
  setInitialSettingStoreDetail,
  setSettingGroupStoreSettings,
} from '../../../store/settingGroupStore/settingGroupStoreDetailSlice';

import settingGroupStoreSearchApi from '../../../apis/settingGroupStore/settingGroupStoreSearchApi';
import settingGroupStoreSearchDetailApi from '../../../apis/settingGroupStore/settingGroupStoreSearchDetailApi';
import settingGroupStoreUpdateApi from '../../../apis/settingGroupStore/settingGroupStoreUpdateApi';

import EditStoreGroup from '../../../components/setStoreGroup/editStoreGroup/editStoreGroup';

function EditStoreGroupContainers(props) {
  const { open, setOpen, areaCode, storeGroup = {} } = props;
  const areaInfo = useStoreGroupsSettingsAreas().areaList?.find(area => area.areaCode === areaCode);

  const dispatch = useDispatch();
  const [nextRegist, setNextRegsit] = useState({}); // 次の登録先店舗
  const [nextResponse, setNextResponse] = useState({}); // 次に反響登録したい店舗

  const settingGroupStoreDetailObj = useSelector(
    (state) => state.settingGroupStoreDetail.settingGroupStoreDetail,
  );

  const storeChange = (order, data) => {
    const list = [...settingGroupStoreDetailObj.settings];
    const listChange = settingGroupStoreDetailObj.settings.map((item, index) => {
      if (item.responseRegistrationOrder === order) {
        return {
          ...list[index],
          divisionId: data.divisionId,
          divisionSubName: data.divisionSubName2,
        };
      }
      return item;
    });

    dispatch(setSettingGroupStoreDetail({
      ...settingGroupStoreDetailObj,
      settings: listChange,
    }));
  };

  const { storeGroupId } = storeGroup;

  const getSettings = () => {
    settingGroupStoreSearchDetailApi({ areaCode, storeGroupId })
      .then((res) => {
        const settingGroupStoreObj = res.data ? res.data[0] : {};
        if (settingGroupStoreObj) {
          dispatch(setSettingGroupStoreDetail(settingGroupStoreObj));
          for (const setting of settingGroupStoreObj.settings) {
            if (setting.isNextRegistration) setNextRegsit(setting);
            if (setting.isEmergencyRegistration) setNextResponse(setting);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const updateSettings = (modifyObj) => {
    const { settings } = modifyObj;
    const modifySettings = [];
    for (const setting of settings) {
      let isEmergencyRegistration;
      if (
        nextResponse.divisionId === setting.divisionId
        && nextResponse.responseRegistrationOrder === setting.responseRegistrationOrder
      ) {
        isEmergencyRegistration = 1;
      } else {
        isEmergencyRegistration = 0;
      }
      modifySettings.push({ ...setting, isEmergencyRegistration });
    }
    // 更新と削除で使用
    settingGroupStoreUpdateApi({
      ...modifyObj,
      settings: modifySettings,
    })
      .then(() => {
        // 表示順がサーバーサイド側で他のレコードも振られ直すので、再度一覧取得のapiをたたく
        settingGroupStoreSearchApi(areaCode)
          .then((res1) => {
            dispatch(setSettingGroups(res1.data ? res1.data.settings : []));
          })
          .catch((err1) => console.log(err1));
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    setOpen(false);
  };

  const deleteStore = (storeObj) => {
    const registeredStores = [];
    let isResetNextRegist = false;

    for (const tmpStore of settingGroupStoreDetailObj.settings) {
      if (tmpStore.divisionId === storeObj.divisionId
        && tmpStore.responseRegistrationOrder === storeObj.responseRegistrationOrder) {
      // 削除される店舗が次の登録先店舗の場合
        if (nextRegist.divisionId === storeObj.divisionId) isResetNextRegist = true;
      } else {
        registeredStores.push({ ...tmpStore });
      }
    }
    if (isResetNextRegist && registeredStores.length) {
      registeredStores[0].isNextRegistration = 1;
      setNextRegsit(registeredStores[0]);
    }
    dispatch(setSettingGroupStoreSettings(registeredStores));
    if (storeGroupId) {
      // dbに保存されているときのみupdate
      updateSettings({
        ...settingGroupStoreDetailObj,
        settings: registeredStores,
      });
    }
  };

  const registStoreGroup = () => {
    updateSettings(settingGroupStoreDetailObj);
    onClose();
  };

  const handleChangeGroupName = (e) => {
    dispatch(setSettingGroupStoreDetail({
      ...settingGroupStoreDetailObj,
      storeGroupName: e.target.value,
    }));
  };
  const handleChangeDisplayOrder = (e) => {
    dispatch(
      setSettingGroupStoreDetail({
        ...settingGroupStoreDetailObj,
        displayOrder: e.target.value,
      }),
    );
  };
  const handleChangeCorrection = (e) => {
    dispatch(
      setSettingGroupStoreDetail({
        ...settingGroupStoreDetailObj,
        correction: e.target.value,
      }),
    );
  };
  const handleReset = () => setNextResponse({});

  useEffect(() => {
    dispatch(setInitialSettingStoreDetail(areaInfo));
    if (storeGroupId) {
      getSettings();
    }
  }, []);

  return (
    <EditStoreGroup
      open={open}
      onClose={onClose}
      storeGroupDetail={settingGroupStoreDetailObj}
      areaCode={areaCode}
      nextRegist={nextRegist}
      setNextRegsit={setNextRegsit}
      nextResponse={nextResponse}
      setNextResponse={setNextResponse}
      handleChangeGroupName={handleChangeGroupName}
      handleChangeDisplayOrder={handleChangeDisplayOrder}
      handleChangeCorrection={handleChangeCorrection}
      handleReset={handleReset}
      registStoreGroup={registStoreGroup}
      deleteStore={deleteStore}
      storeChange={storeChange}
    />
  );
}

export default EditStoreGroupContainers;
