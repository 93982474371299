import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  MAIL_SENDSTATUS,
} from '../../../../constants';
import CheckBoxLabel from '../../../eleCommon/checkBoxLabel';

const useStyles = makeStyles({
  box: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px 4px 8px',
    height: 'auto',
    marginBottom: 8,
    '& label': {
      fontSize: 22,
      display: 'flex',
      alignItems: 'center',
    },
  },
  lbl: {
    width: 102,
  },
  radio: {
    '& .MuiFormGroup-root': {
      flexDirection: 'unset',
    },
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  txt: {
    fontSize: 13,
    lineHeight: '1.6',
    letterSpacing: '0',
    marginLeft: 6,
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  caption: {
    position: 'relative',
    background: 'rgba(252, 224, 52, 0.24)',
    fontSize: 12,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px 4px 4px 15px',
    display: 'none',
    '&::before': {
      content: '"※"',
      position: 'absolute',
      top: 4,
      left: 3,
    },
    '&.active': {
      display: 'block',
    },
  },
});

export default function BulkChangeWindowRank(props) {
  const classes = useStyles();

  const {
    getComponent,
    checked,
    setChecked,
    radio,
    setRadio,
    isRank2,
  } = props;

  // 非活性
  const [disabled, setDisabled] = useState(true);

  // チェックボックス
  const set = (value) => {
    setChecked(value);
    if (value) {
      setDisabled(false);
      setRadio(0);
    } else {
      setDisabled(true);
      setRadio();
    }
  };

  // 表示制御
  const [active, setActive] = useState(false);

  // ラジオボタン

  const radioChange = (e) => {
    setRadio(Number(e.target.value));
  };

  useEffect(() => {
    if (radio === 1 || radio === 3) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [radio]);

  useEffect(() => {
    setDisabled(!checked);
  }, [checked]);

  const menus = isRank2
    ? {
      0: MAIL_SENDSTATUS[0],
      5: MAIL_SENDSTATUS[5],
    }
    : MAIL_SENDSTATUS;
  const radioList = {
    radioItem: {
      type: 'radioGroup',
      obj: { menus },
      state: radio,
      change: radioChange,
    },
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.box}>
        <Grid className={classes.lbl}>
          <CheckBoxLabel
            label="メール許可"
            initial={checked}
            set={set}
          />
        </Grid>
        <Grid className={classes.wrap}>
          <Grid className={`${classes.radio} ${disabled && 'disabled'}`}>
            {getComponent(radioList.radioItem)}
          </Grid>
          <Typography className={`${classes.txt} ${disabled && 'disabled'}`}>に変更する</Typography>
        </Grid>
      </Grid>
      <Typography className={`${classes.caption} ${active && 'active'}`}>
        メール許可を「個別のみOK」もしくは「一斉・個別OK」に変更すると、 ランクが「★★」のお客様に限り、メール許可は「代行もすべてOK」に変更されます。
      </Typography>
    </Grid>
  );
}
