import { Grid } from '@material-ui/core';
// リスト
import FlyerSalesTreeListResponse from './parts/treeList';
// import FlyerSalesTreeListResponse from './parts/flyerSalesTreeListResponse';

function FlyerSalesTreeResponseWrapper(props) {
  const {
    list,
    setSelectUser,
    setSelectDivision,
    setSelectFlyer,
  } = props;

  // const classes = useStyles();

  // isSpを取得したい場合は下記一行をコピペでOK
  // const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // 開閉用ローカルステート
  // const [state, setState] = useState({});
  // 選択状態保持
  // const [checked, setChecked] = useState(0);
  // // 表示する組織とユーザー
  // const [displayDivision, setDisplayDivision] = useState([]);
  // // 選択したデータのルート情報を格納
  // const [displayDivisionRoot, setDisplayDivisionRoot] = useState([]);
  // // 検索結果
  // const [searchObj, setSearchObj] = useState({});
  // // 検索状態
  // const [searchedUserList, setSearchedUserList] = useState(false);

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <FlyerSalesTreeListResponse
        list={list}
        setSelectUser={setSelectUser}
        setSelectDivision={setSelectDivision}
        setSelectFlyer={setSelectFlyer}
      />
    </Grid>
  );
}

export default FlyerSalesTreeResponseWrapper;
