// divisionWindow.js

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DivisionInfoComponent from './divisionInfoComponent';
import CommonDialog from '../common/modal';
import { useLoading } from '../../hooks';
import commonStyles from '../styles';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';

function DivisionWindow(props) {
  const {
    divisionDetail,
    setDivisionDetail,
    open,
    onClose,
    updateDivisionDetail,
    createDivisionDetail,
    getDivisionDetail,
    isEdit,
    setIsEdit,
    moldingParameter,
  } = props;
  const [validate, setValidate] = useState(false);
  const { addLoading, removeLoading, hasLoading } = useLoading();
  const common = commonStyles();

  const dispatch = useDispatch();

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const checkValidItems = (div) => {
    const requireList = [
      div.divisionSubName,
      div.isHide,
      div.levelCode,
      div.phoneNumber,
      div.isSalesDivision,
      div.isDisplaySalesResponseTree,
      div.startAt,
      div.endAt,
      div.zip,
    ];
    if (
      requireList.includes(null)
      || requireList.includes('')
      || errorSet.size !== 0
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!checkValidItems(divisionDetail)) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [divisionDetail, errorSet]);

  // 編集モード
  const setEdit = async (divisionId) => {
    await getDivisionDetail(divisionId);
    setIsEdit(true);
  };

  // divisionの作成
  const createDivision = async () => {
    addLoading('divisionEditId');
    await createDivisionDetail(divisionDetail, setEdit);
    removeLoading('divisionEditId');
    onClose();
  };

  // divisionの更新
  const updateDivision = async () => {
    addLoading('divisionEditId');
    const updateDetail = moldingParameter(divisionDetail);
    await updateDivisionDetail(divisionDetail.divisionId, updateDetail);
    removeLoading('divisionEditId');
    onClose();
  };

  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '非表示フラグ「有効（組織削除）」が選択されました',
      msgList: ['本当に組織を削除しますか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: common.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => {
            updateDivision();
          },
          classes: common.buttonsPrimary,
        },
      ],
    }));
  };

  // divisionの作成 or 更新
  const createOrUpdateDivision = async () => {
    if (isEdit && divisionDetail.divisionId) {
      if (divisionDetail.isHide === 1) {
        // 表示フラグ「有効（組織削除）」が選択され場合、確認のメッセージを表示
        confirmOpen();
      } else {
        updateDivision();
      }
    } else {
      createDivision();
    }
  };

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="組織編集ウィンドウ"
      width={740}
      topId="divisionEditId"
      buttons={[
        {
          label: 'キャンセル',
          onClick: onClose,
          type: 'secondary',
        },
        {
          label: '保存',
          onClick: createOrUpdateDivision,
          disabled: validate,
        },
      ]}
      className={hasLoading('divisionEditId') ? common.loading : ''}
    >
      <DivisionInfoComponent
        divisionDetail={divisionDetail}
        setDivisionDetail={setDivisionDetail}
        errorCallback={errorCallback}
        isEdit={isEdit}
      />
    </CommonDialog>
  );
}

export default DivisionWindow;
