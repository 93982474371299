import React, { useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import {
  PERMANENT_RESIDENCY,
} from '../../../constants';
import { capitalizeFirstLetter } from '../../../commonFunction';
// import SpEditDialog from './spEditDialog';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import inputNumber from '../../../commonFunction/inputNumber';
import { convertForm1, convertFormKana, convertFormNumber } from '../../../commonFunction/convertors';
import { validateFormString1, validateFormInt1 } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  block: {
    marginBottom: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '5px 8px',
  },
  blockBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blockLbl: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    lineHeight: '15.6px',
    width: '18%',
  },
  blockLbl01: {
    marginRight: 16,
    transform: 'translate(0, 7px)',
  },
  blockLbl02: {
    fontWeight: 700,
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& input': {
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      transform: 'translate(0, 7px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -5px) scale(0.75)',
      },
    },
    '& > div': {
      margin: 0,
    },
  },
  blockInfo: {
    width: '82%',
  },
  blockKana: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    lineHeight: '20.8px',
  },
  blockKanaTxt: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginRight: '16px',
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      marginRight: '16px',
    },
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      [commonTheme.breakpoints.up('md')]: {
        width: 84,
      },
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#C8C8C8',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      fontSize: 13,
      transform: 'translate(0, 13px) scale(1)',
      '&.Mui-focused': {
        opacity: 0,
      },
      '&.MuiInputLabel-shrink': {
        opacity: 0,
      },
    },
  },
  blockName: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0 0',
    [commonTheme.breakpoints.up('md')]: {
      width: '92%',
      lineHeight: '24px',
    },
  },
  blockNameTxt: {
    display: 'block',
    width: '50%',
    textAlign: 'left',
    [commonTheme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginRight: '16px',
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      marginRight: '16px',
    },
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 16,
        zoom: 0.81,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#C8C8C8',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      fontSize: 20,
      transform: 'translate(0, 9px) scale(1)',
      '&.Mui-focused': {
        opacity: 0,
      },
      '&.MuiInputLabel-shrink': {
        opacity: 0,
      },
    },
  },
  blockAge: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      lineHeight: '20.8px',
    },
    [commonTheme.breakpoints.up('md')]: {
      margin: 0,
      width: 54,
    },
  },
  blockAgeNum: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      margin: '2px 0 0 4px',
    },
    '& > div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& input': {
      color: '#333',
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
  },
  blockAgeTxt: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  blockList: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
  },
  blockItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 8px',
    lineHeight: '15.6px',
  },
  blockNationalityTxt: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: '20.8px',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '2em',
      margin: '-2px 8px 0 0',
    },
    '& > div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
  },
  lbl: {
    whiteSpace: 'nowrap',
    marginRight: 4,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

const menus = Object.keys(PERMANENT_RESIDENCY);

function CustomerName1(props) {
  const { input, isSub } = props;
  const {
    current,
    original,
    update,
    isSp,
  } = useUpdate(input.customer);

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  // メインとサブ区分のためのキー
  const fields = useMemo(() => {
    const keys = [
      'firstNameKana',
      'firstName',
      'lastNameKana',
      'lastName',
      'age',
      'nationality',
      'relation',
    ];

    const data = keys.reduce((prev, key) => {
      prev[key] = isSub ? capitalizeFirstLetter(key, 'sub') : key;
      return prev;
    }, {});
    data.isPermanentResidency = isSub ? 'isSubPermanentResidency' : 'isPermanentResidency';
    return data;
  }, [isSub]);

  let contents;

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    const { name, value } = e.target;
    if ((original[name] || '') === value) return;
    update({ [name]: value });
  };

  // SP 項目選択時に更新
  const onSelect = (val) => {
    const newData = { [fields.isPermanentResidency]: val !== '' ? Number(val) : null };
    if (isChanged(newData, original)) update(newData);
  };

  // PC 永住権
  const selectChange = (e) => {
    onSelect(e.target.value);
  };

  const customerSelectList = {
    isPermanentResidencyItem: {
      type: 'selectBlank',
      obj: {
        menus: PERMANENT_RESIDENCY,
      },
      state: current[fields.isPermanentResidency] ?? '',
      change: selectChange,
    },
  };

  const getKana = () => {
    return (
      <Grid className={classes.blockKana}>
        <TextBaseField
          className={isSp ? '' : classes.blockKanaTxt}
          defaultValue={current[fields.lastNameKana] ?? ''}
          label={!isSp && 'セイ'}
          name={fields.lastNameKana}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormString1({ maxLengthInt: 50 })}
          convertor={convertFormKana}
        />
        <TextBaseField
          className={isSp ? '' : classes.blockKanaTxt}
          defaultValue={current[fields.firstNameKana] ?? ''}
          label={!isSp && 'メイ'}
          name={fields.firstNameKana}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormString1({ maxLengthInt: 50 })}
          convertor={convertFormKana}
        />
      </Grid>
    );
  };

  const getName = () => {
    return (
      <Grid className={classes.blockName}>
        <TextBaseField
          className={isSp ? '' : classes.blockNameTxt}
          defaultValue={current[fields.lastName] ?? ''}
          label={!isSp && '姓'}
          name={fields.lastName}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormString1({ maxLengthInt: 50 })}
          convertor={convertForm1}
        />
        <TextBaseField
          className={isSp ? '' : classes.blockNameTxt}
          defaultValue={current[fields.firstName] ?? ''}
          label={!isSp && '名'}
          name={fields.firstName}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormString1({ maxLengthInt: 50 })}
          convertor={convertForm1}
        />
      </Grid>
    );
  };

  const getAge = () => {
    return (
      <Grid className={classes.blockAge}>
        <TextBaseField
          type="number"
          inputProps={{ min: '0' }}
          className={classes.blockAgeNum}
          defaultValue={current[fields.age] ?? ''}
          name={fields.age}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999.0 })}
          convertor={convertFormNumber}
        />
        <Typography className={`${baseClasses.title4} ${classes.blockAgeTxt}`}>
          才
        </Typography>
      </Grid>
    );
  };

  const getNationality = () => {
    return (
      <Grid className={classes.blockItem}>
        <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
          国籍
        </Typography>
        <TextBaseField
          className={classes.blockNationalityTxt}
          defaultValue={current[fields.nationality] ?? ''}
          name={fields.nationality}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormString1({ maxLengthInt: 20 })}
          convertor={convertForm1}
        />
      </Grid>
    );
  };

  const getResidency = () => {
    return (
      <Grid className={classes.blockItem}>
        <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
          永住権
        </Typography>
        {input.component(customerSelectList.isPermanentResidencyItem)}
      </Grid>
    );
  };

  const getResidencySp = () => {
    return (
      <Select
        className={classes.residency}
        defaultValue={current[fields.isPermanentResidency] ?? 99}
      >
        <MenuItem value={99} onClick={() => { onSelect(''); }}>未選択</MenuItem>
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect(data);
              }}
            >
              {PERMANENT_RESIDENCY[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  // SP表示用
  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'セイメイ',
      keys: [fields.lastNameKana, fields.firstNameKana],
      val: [current[fields.lastNameKana], current[fields.firstNameKana]],
      label: ['セイ', 'メイ'],
      content: getKana,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '姓名',
      keys: [fields.lastName, fields.firstName],
      val: [current[fields.lastName], current[fields.firstName]],
      label: ['姓', '名'],
      fontSize: '20px',
      content: getName,
    },
    {
      type1: 'noModal',
      type2: 'number',
      lbl: '年齢',
      keys: fields.age,
      val: current[fields.age],
      suffix: '才',
      content: getAge,
    },
    // TODO 関係
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '国籍',
      keys: fields.nationality,
      val: current[fields.nationality],
      content: getNationality,
    },
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '永住権',
      keys: fields.isPermanentResidency,
      val: PERMANENT_RESIDENCY[current[fields.isPermanentResidency]],
      content: getResidencySp,
    },
  ];

  const getRelation = () => {
    return (
      <TextBaseField
        className={classes.blockLbl02}
        defaultValue={current.relation}
        name="relation"
        placeholder="関係"
        onBlur={onBlur}
        validator={validateFormString1({ maxLengthInt: 50 })}
        convertor={convertForm1}
      />
    );
  };

  if (isSp) {
    contents = (
      <Grid className={classes.root}>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          顧客 {isSub ? '副' : '主'}
        </Typography>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.parts(item)}
                {input.editDialog(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.block}>
        <Grid className={classes.blockLbl}>
          <Typography className={`${baseClasses.title6} ${classes.blockLbl01}`}>
            {isSub ? '副' : '主'}
          </Typography>
          {isSub ? getRelation() : (
            <Typography className={`${baseClasses.title6} ${classes.blockLbl02}`}>
              本人
            </Typography>
          )}
        </Grid>
        <Grid className={classes.blockInfo}>
          <Grid className={classes.blockBox}>
            {getKana()}
            <Grid className={classes.blockList}>
              {getNationality()}
              {getResidency()}
            </Grid>
          </Grid>
          <Grid className={classes.blockBox}>
            {getName()}
            {getAge()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerName1);
