import ContractInformationContainers from '../../../containers/customerMain/contractInformation/contractInformationContainers';

function ContractInformation(props) {
  const { open, onClose, customerId } = props;
  return (
    <div>
      <ContractInformationContainers
        open={open}
        onClose={onClose}
        customerId={customerId}
      />
    </div>
  );
}

export default ContractInformation;
