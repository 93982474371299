import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const mokuhyoSetUpdateSlice = createSlice({
  name: 'mokuhyoSetUpdate',
  initialState: { mokuhyoSetUpdate: initialState },
  reducers: {
    mokuhyoSetUpdateApi: (state, data) => {
      state.mokuhyoSetUpdate = data.payload;
    },
  },
});

export const { mokuhyoSetUpdateApi } = mokuhyoSetUpdateSlice.actions;

export default mokuhyoSetUpdateSlice.reducer;
