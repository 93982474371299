import EditStoreGroupContainers from '../../../containers/setStoreGroup/editStoreGroup/editStoreGroupContainers';

function editStoreGroup(props) {
  const { open, setOpen, areaCode, storeGroup } = props;
  return (
    <div>
      {open ? (
        <EditStoreGroupContainers
          open={open}
          setOpen={setOpen}
          areaCode={areaCode}
          storeGroup={storeGroup}
        />
      ) : (
        false
      )}
    </div>
  );
}

export default editStoreGroup;
