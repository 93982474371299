import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles, Button } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import commonStyles from '../../styles';
import CustomInputStickyLabel from './customInputStickyLabel';

import { RESPONSE_LEVEL_MAP } from '../../../constants';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import CommonDialog from '../../common/modal';

import { toNumber } from '../../../commonFunction';

const useStyles = makeStyles({
  grid: {
    '&:not(:last-child)': {
      marginBottom: 8,
    },
    '& > *:not(:last-child)': {
      marginRight: 8,
    },
  },
  content: {
    padding: '8px',
  },
  inputWrap: {
    width: '100%',
  },
  label: {
    flexShrink: 0,
  },
  button: {
    margin: '0 8px',
  },
});

const getParentTypeName = (parentTypeId, responseTypeList) => {
  let parentTypeName = '';
  if (responseTypeList) {
    responseTypeList.some((responseType) => {
      if (responseType.responseTypeId === parentTypeId) {
        parentTypeName = responseType.responseTypeName;
        return true;
      }
      return false;
    });
  }
  return parentTypeName;
};

const filteredResponseType = (row, searchParam, responseTypeList) => {
  const checkFunc = (key) => {
    const searchValue = RegExp(searchParam[key]);
    if (key === 'responseTypeParentName') {
      return searchParam[key]
        ? searchValue.test(
          getParentTypeName(row.responseTypeParentId, responseTypeList),
        )
        : true;
    }
    return searchParam[key] ? searchValue.test(row[key]) : true;
  };
  const checkList = [
    'responseTypeName',
    'responseTypeLevelCode',
    'displayOrder',
    'responseTypeParentName',
  ];

  return checkList.every((checkKey) => checkFunc(checkKey));
};

const getParentTypeMenus = (responseTypeLevelCode, responseTypeList) => {
  const parentResponseLevel = String(parseInt(responseTypeLevelCode, 10) - 1);
  const menus = {};
  responseTypeList.map((row) => {
    if (
      filteredResponseType(
        row,
        { responseTypeLevelCode: parentResponseLevel },
        responseTypeList,
      )
    ) {
      menus[row.responseTypeId] = row.responseTypeName;
      return true;
    }
    return false;
  });
  return menus;
};

const initialObj = {
  responseTypeName: '',
  responseTypeLevelCode: '',
  responseTypeParentId: '',
  displayOrder: '999',
  isAllowSales: 0,
};

function ResponseTypeEditModal(props) {
  const {
    open,
    onClose,
    initial = initialObj,
    isNewEntry,
    resisterResponseType,
    updateResponseType,
    deleteResponseType,
  } = props;
  const commonClasses = commonStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const [isValid, setIsValid] = useState(false);
  const [responseTypeLocal, setResponseTypeLocal] = useState(
    isNewEntry ? initialObj : initial,
  );
  const responseTypeList = useSelector(
    (state) => state.responseGetType.responseGetType.responseTypes,
  );

  const deleteFunc = () => {
    deleteResponseType(responseTypeLocal);
    onClose();
  };

  const confirmOpen = () => {
    dispatch(
      changeConfirmMessage({
        title: '選択項目を削除',
        msgList: ['削除してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: () => {},
            classes: commonClasses.buttonsSecondary,
          },
          {
            label: 'OK',
            set: () => deleteFunc(),
            classes: commonClasses.buttonsPrimary,
          },
        ],
      }),
    );
  };

  const registerFunc = () => {
    if (isNewEntry) {
      resisterResponseType(responseTypeLocal);
    } else {
      updateResponseType(responseTypeLocal);
    }
    onClose();
  };

  const responseTypeInitial = {
    label: '応答タイプ',
    required: true,
    defaultValue: responseTypeLocal.responseTypeName || '',
    contentClass: `${classes.content}`,
    labelClass: `${commonClasses.title6} ${classes.label}`,
    inputWrapClass: `${classes.inputWrap}`,
    onBlur: (e) => {
      setResponseTypeLocal((state) => ({
        ...state,
        responseTypeName: e.target.value,
      }));
    },
    name: 'responsetype',
    errorCallback: errorCallback('responsetype'),
  };

  const initialObjs = [
    {
      label: 'レベル',
      type: 'select',
      menus: RESPONSE_LEVEL_MAP,
      required: true,
      defaultValue: responseTypeLocal.responseTypeLevelCode || '',
      contentClass: `${classes.content}`,
      labelClass: `${commonClasses.title6}`,
      onChange: (e) => {
        setResponseTypeLocal((state) => ({
          ...state,
          responseTypeLevelCode: e.target.value,
          responseTypeParentId:
            e.target.value === '1' ? '' : state.responseTypeParentId,
        }));
      },
    },
    {
      label: '親応答タイプ',
      type: 'select',
      required: toNumber(responseTypeLocal.responseTypeLevelCode) !== 1,
      menus: getParentTypeMenus(
        responseTypeLocal.responseTypeLevelCode || '',
        responseTypeList,
      ),
      disable: !responseTypeLocal.responseTypeLevelCode
        || toNumber(responseTypeLocal.responseTypeLevelCode) === 1,
      defaultValue: responseTypeLocal.responseTypeParentId || '',
      contentClass: `${classes.content}`,
      labelClass: `${commonClasses.title6}`,
      onChange: (e) => {
        setResponseTypeLocal((state) => ({
          ...state,
          responseTypeParentId: e.target.value,
        }));
      },
    },
  ];

  const initialObjs2 = [
    {
      label: '営業フラグ',
      type: 'checkbox',
      defaultValue: Boolean(responseTypeLocal.isAllowSales),
      contentClass: `${classes.content}`,
      labelClass: `${commonClasses.title6}`,
      onChange: (e) => {
        setResponseTypeLocal((state) => ({
          ...state,
          isAllowSales: +e.target.checked,
        }));
      },
    },
    {
      label: 'ストアオーダー',
      type: 'order',
      defaultValue: responseTypeLocal.displayOrder || '999',
      required: false,
      contentClass: `${classes.content}`,
      labelClass: `${commonClasses.title6}`,
      onChange: (e) => {
        setResponseTypeLocal((state) => ({
          ...state,
          displayOrder: e.target.value,
        }));
      },
      name: 'storeOrder',
      errorCallback: errorCallback('storeOrder'),
    },
  ];

  // 初期値の制御
  useEffect(() => {
    setResponseTypeLocal(isNewEntry ? initialObj : initial);
  }, [isNewEntry, initial]);

  // バリデーション制御
  useEffect(() => {
    const requiredList = [
      Boolean(responseTypeLocal.responseTypeName),
      Boolean(responseTypeLocal.responseTypeLevelCode),
    ];
    if (toNumber(responseTypeLocal.responseTypeLevelCode) !== 1) {
      requiredList.push(Boolean(responseTypeLocal.responseTypeParentId));
    }
    setIsValid(!requiredList.includes(false));
  }, [responseTypeLocal]);

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="応答タイプ"
      width={476}
      footerClassName={classes.footer}
      footer={
        <Grid
          container
          alignItems="center"
          justifyContent="center"
        >
          {isNewEntry === true ? (
            ''
          ) : (
            <Button className={classes.button} onClick={() => confirmOpen()}>
              <DeleteOutlineIcon style={{ color: '#D83420' }} />
              <div style={{ color: '#D83420' }}>削除</div>
            </Button>
          )}
          <Button
            className={`${commonClasses.buttonsPrimary} ${classes.button}`}
            disabled={!isValid || errorSet.size !== 0}
            onClick={() => registerFunc()}
            type="button"
            size="small"
          >
            登録
          </Button>
        </Grid>
      }
    >
      <Grid className={classes.body}>
        <Grid className={classes.grid}>
          <CustomInputStickyLabel
            initial={responseTypeInitial}
          />
        </Grid>
        <Grid container className={classes.grid}>
          {initialObjs.map((ele) => (
            <Grid className={classes.labelWrap} item key={ele.label}>
              <CustomInputStickyLabel key={ele.label} initial={ele} />
            </Grid>
          ))}
        </Grid>
        <Grid container className={classes.grid}>
          {initialObjs2.map((ele) => (
            <Grid item key={ele.label}>
              <CustomInputStickyLabel key={ele.label} initial={ele} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </CommonDialog>
  );
}

export default ResponseTypeEditModal;
