import React from 'react';
import { Dialog, Paper, Grid, Typography, Button } from '@material-ui/core';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    width: 640,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderRadius: 4,
  },
  ttl: {
    padding: '24px 24px 8px',
    borderBottom: '1px solid #C8C8C8',
    textAlign: 'center',
  },
  txt: {
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: 24,
    borderBottom: '1px solid #C8C8C8',
    textAlign: 'center',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 0',
  },
  btn1: {
    width: 89,
    padding: '8px 0',
  },
  btn2: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 89,
    padding: '8px 0',
    marginLeft: 16,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ResponseRegisterConfirm(props) {
  const { open, onClose, data } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Dialog
      open={open}
      onClose={async () => {
        await data.onCancel();
        onClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={classes.dialog}
    >
      <Grid className={classes.paper}>
        <Typography
          id="draggable-dialog-title"
          className={`${baseClasses.title3} ${classes.ttl}`}
        >
          {data.ttl}
        </Typography>
        <Typography className={classes.txt}>{data.txt}</Typography>
        <Grid className={classes.btnWrap}>
          <Button
            className={`${baseClasses.buttonsSecondary} ${classes.btn1}`}
            onClick={async () => {
              await data.onCancel();
              onClose();
            }}
          >
            CANCEL
          </Button>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.btn2}`}
            onClick={async () => {
              await data.onOk();
              onClose();
            }}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ResponseRegisterConfirm;
