import client from '../httpClient';

const calendarUploadCSVApi = (csv) => {
  const data = new FormData();
  data.append('csvFile', csv);

  const headers = { 'content-type': 'multipart/form-data' };
  return client.post('/calendars/csv-upload/', data, {
    headers,
    isShowMessage: true,
  });
};

export default calendarUploadCSVApi;
