import InstructionWindowContainers from '../../../containers/instructionList/instructionWindowContainers';

function InstructionWindow(props) {
  const {
    open,
    setOpen,
    onClose,
    customerId,
    tab,
    row,
    resetRender,
    setResetRender,
  } = props;

  return (
    <InstructionWindowContainers
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      customerId={customerId}
      tab={tab}
      row={row}
      resetRender={resetRender}
      setResetRender={setResetRender}
    />
  );
}

export default InstructionWindow;
