import { useState } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  btn: {
    textTransform: 'none',
    borderRadius: 0,
    background: '#fff',
    justifyContent: 'space-between',
    flexGrow: 1,
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '12px 8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px',
      borderBottom: '1px solid #F3F3F3',
    },
  },
  selected: {
    background: `${theme.palette.primaryColor}29`,
  },
}));
function Item(props) {
  const { data, customer, values, beforeSend, setBeforeSend } = props;
  const classes = useStyles();
  const common = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // 各電話番号選択時
  const selectPhoneRecord = (d) => {
    setBeforeSend({
      number: d.phoneNumber,
      id: d.smsCustomerTelId,
    });
  };

  return (
    <Button
      className={`${classes.btn} ${values.number === beforeSend.number ? classes.selected : ''}`}
      onClick={() => { selectPhoneRecord(data); }}
    >
      {isSp ? (
        <>
          <Grid className={classes.col2}>
            <Typography className={`${common.title2} ${classes.number}`}>{data.phoneNumber}</Typography>
            <Typography className={`${common.title6} ${classes.w28} ${classes.nameAndMemo}`}>
              {data.customerName && <span>{data.customerName} 様</span>}
              {data.memo && <span>{data.memo}</span>}
            </Typography>
          </Grid>
          <Grid className={`${classes.col2} ${classes.right}`}>
            <Typography className={`${common.title6} ${classes.w20}`}>{data.lastSendAt}</Typography>
            <Typography className={`${common.title6} ${classes.w26} ${classes.name}`}>
              {customer.userLastName}{customer.userFirstName}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Typography className={`${common.title2} ${classes.number}`}>{data.phoneNumber}</Typography>
          <Typography className={`${common.title6} ${classes.w28} ${classes.nameAndMemo}`}>
            {data.customerName && <span>{data.customerName} 様</span>}
            {data.memo && <span>{data.memo}</span>}
          </Typography>
          <Typography className={`${common.title6} ${classes.w26}`}>{data.lastSendAt}</Typography>
          <Typography className={`${common.title6} ${classes.w20} ${classes.name}`}>
            {customer.userLastName}{customer.userFirstName}
          </Typography>
        </>
      )}
    </Button>
  );
}
export default function ChangeSmsComponent(props) {
  const { values, customer, beforeSend, setBeforeSend } = props;
  const classes = useStyles();
  const [talk, setTalk] = useState({
    open: false,
    talkData: {},
    onCloseFunc: () => { return setTalk({ ...talk, open: false }); },
  });
  const list = useSelector((state) => state.smsHistory.smsHistory);

  const content = () => {
    const contentWrap = list.info.filter(l => l.isSuspension !== 1 && l.isDeletedPhoneNumber !== 1);
    return contentWrap.map((l) => (
      <Grid container className={classes.itemWrap} key={`key${l.smsCustomerTelId}`}>
        <Item
          data={l}
          customer={customer}
          values={values}
          beforeSend={beforeSend}
          setBeforeSend={setBeforeSend}
        />
      </Grid>
    ));
  };
  return (
    <Grid container>
      {list.info !== undefined && content()}
    </Grid>
  );
}
