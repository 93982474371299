import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const claimGetSlice = createSlice({
  name: 'claimGet',
  initialState: { claimGet: initialState },
  reducers: {
    getClaimApi: (state, data) => {
      state.claimGet = data.payload;
    },
  },
});

export const { getClaimApi } = claimGetSlice.actions;

export default claimGetSlice.reducer;
