// URL Const
export const URL_MAPPER = {
  developer: '/develop/menu',
  dev_search: '/develop/search',
  dev_usertree: '/develop/usertree',
  dev_customertree: '/develop/customertree',
  dev_flyertree: '/develop/flyertree',
  dev_calendar: '/develop/calendar',
  dev_acquisition_radio: '/develop/acquisition_radio',
  dev_acquisition_text: '/develop/acquisition_text',
  dev_validation: '/develop/validation',
  customer: '/sfa/customer',
  direct: '/sfa/direct',
  customer_list: '/sfa/customer_list',
  unsend_mail: '/sfa/unsend_mail',
  mail: '/sfa/mail',
  web: '/sfa/web',
  change: '/sfa/change',
  library: '/sfa/library',
  schedule: '/sfa/schedule',
  sp: '/sfa/home',
  crm: '/crm',
  user: '/mst/user',
  store_user_move: '/mst/store_user_move',
  user_move: '/mst/user_move',
  point: '/mst/point_allocations',
  simulator: '/mst/simulator',
  response_type: '/mst/response_type',
  store_group: '/mst/store_group',
  auto_regist: '/mst/auto_regist',
  customer_move: '/mst/customer_move',
  prohibited: '/mst/prohibited',
  target: '/mst/target',
  combination: '/mst/combination_setting',
  dashboard: '/sfa/dashboard',
  date_time: '/mst/date_time',
  user_move_history: '/mst/user_move_history',
};

export const URL_PATHS = [
  { title: '開発リンク', path: '/develop/menu' },
  // SFA
  { title: '顧客メイン', path: '/sfa/customer' },
  { title: '指示一覧', path: '/sfa/direct' },
  { title: '見込み案件管理表', path: '/sfa/customer_list' },
  { title: '未送信メール', path: '/sfa/unsend_mail' },
  { title: '一斉メール', path: '/sfa/mail' },
  { title: 'Web履歴', path: '/sfa/web' },
  { title: '顧客一括管理', path: '/sfa/change' },
  { title: 'ライブラリ管理', path: '/sfa/library' },
  { title: '案内予定一覧', path: '/sfa/schedule' },
  { title: 'メニュー(SP)', path: '/sfa/home' },
  { title: 'ダッシュボード', path: '/sfa/dashboard' },
  // CRM
  { title: 'CRM', path: '/crm' },
  // MST
  { title: '組織社員マスタ', path: '/mst/user' },
  { title: '営業自店舗課編成', path: '/mst/store_user_move' },
  { title: '組織情報一括更新', path: '/mst/user_move' },
  { title: '配点設定', path: '/mst/point_allocations' },
  { title: 'シミュレーション', path: '/mst/simulator' },
  { title: '反響媒体マスタ', path: '/mst/response_type' },
  { title: '店舗グループ設定', path: '/mst/store_group' },
  { title: '自動反響処理モード設定', path: '/mst/auto_regist' },
  { title: '店舗間顧客移動設定', path: '/mst/customer_move' },
  { title: '注意語句一覧', path: '/mst/prohibited' },
  { title: '目標設定', path: '/mst/target' },
  { title: '組合せ設定', path: '/mst/combination_setting' },
  { title: '課編成日付設定', path: '/mst/date_time' },
  { title: '組織情報一括更新_履歴', path: '/mst/user_move_history' },
  // DEVELOPER
  { title: '開発用 詳細簡易検索', path: '/develop/search' },
  { title: '開発用 組織ツリー', path: '/develop/usertree' },
  { title: '開発用 反響ツリー', path: '/develop/customertree' },
  { title: '開発用 源泉未処理ツリー', path: '/develop/flyertree' },
];
