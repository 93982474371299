import { createSlice } from '@reduxjs/toolkit';

// 現在時間生成
const now = new Date();
const year = now.getFullYear();
const month = ('00' + (now.getMonth() + 1)).slice(-2);
const day = ('00' + now.getDate()).slice(-2);
const hour = ('00' + now.getHours()).slice(-2);
const min = ('00' + now.getMinutes()).slice(-2);
const dateInitial = year + '/' + month + '/' + day + ' ' + hour + ':' + min;

const initialState = {
  isManager: null,
  isCenterManger: null,
  directCode: null,
  approveCode: null,
  directId: null,
  customerId: null,
  customerLastName: null,
  customerFirstName: null,
  memberStatusCode: null,
  directedUserId: null,
  directedLastName: null,
  directedFirstName: null,
  directedDivisionId: null,
  expireAt: dateInitial,
  directReportStatusCode: null,
  directUserId: null,
  directUserLastName: null,
  directUserFirstName: null,
  importantCode: null,
  userLevel: null,
  others: [],
  timelines: [],
};

export const directDetailSlice = createSlice({
  name: 'directDetailSlice',
  initialState: { directDetail: initialState },
  reducers: {
    setDirectDetail: (state, data) => {
      state.directDetail = data.payload;
    },
    setInitialState: (state) => {
      state.directDetail = initialState;
    },
  },
});

export const { setDirectDetail, setInitialState } = directDetailSlice.actions;

export default directDetailSlice.reducer;
