import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import UserTree from '../../userTreeNew';
import inputNumber from '../../../../commonFunction/inputNumber';
import infoRegisterStyles from './infoRegisterStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  block: {
    width: 280,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '8px 8px 7px',
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  box: {
    width: 288,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 0,
    textAlign: 'left',
    minHeight: 20,
    borderRadius: 0,
    '& .MuiButton-label': {
      display: 'block',
    },
  },
  btn1: {
    width: 85,
  },
  btn2: {
    width: '100%',
  },
  input: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 40,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
      minHeight: 20,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    whiteSpace: 'nowrap',
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&:nth-child(even)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
    '& span': {
      margin: '0 16px',
    },
  },
}));

function InfoRegisterChoiceText2(props) {
  const { values } = props;
  const classes = useStyles();
  const infoRegisterClasses = infoRegisterStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  const [treeWindowOpen1, setTreeWindowOpen1] = useState(false);
  const [treeWindowOpen3, setTreeWindowOpen3] = useState(false);

  const initialUser = { userId: 0, userName: '' };
  // 組織ツリーユーザー選択（接客者）
  const [selectUser1, setSelectUser1] = useState(initialUser);
  // 組織ツリーユーザー選択（同席者）
  const [selectUser3, setSelectUser3] = useState(initialUser);
  // 組織ツリー選択（接客者）
  const [selectDivision1, setSelectDivision1] = useState({ divId: 0, divisionName: '' });
  // 組織ツリー選択（同席者）
  const [selectDivision3, setSelectDivision3] = useState({ divId: 0, divisionName: '' });

  const onClickHandler1 = () => {
    setTreeWindowOpen1(!treeWindowOpen1);
  };

  const onClickHandler3 = () => {
    setTreeWindowOpen3(!treeWindowOpen3);
  };

  const [text, setText] = useState(values.state2);
  const handleChange = (e) => {
    setText(e.target.value);
    // PCで文字を入力すう度に再レンダリングされるのでコメントアウト
    // values.setState2(e.target.value);
  };

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  const handleSelectFunction1 = () => {
    if (selectUser1.userId !== 0) {
      values.setState1(selectUser1);
    } else {
      values.setState1(values.selectUser1);
    }
  };
  const handleClearFunction1 = () => {
    values.setState1(initialUser);
  };

  const handleSelectFunction2 = () => {
    if (selectUser3.userId !== 0) {
      values.setState3(selectUser3);
    } else {
      values.setState3(values.selectUser3);
    }
  };
  const handleClearFunction2 = () => {
    values.setState3(initialUser);
  };

  if (isSp) {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <List className={infoRegisterClasses.list}>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={onClickHandler1}
            >
              <Typography className={infoRegisterClasses.listTtl}>
                {values.label1}
                {values.required && <span>＊</span>}
              </Typography>
              <Typography className={infoRegisterClasses.listTxt}>
                {values.state1 || ''}
              </Typography>
            </Button>
          </ListItem>
          <ListItem className={infoRegisterClasses.listItem}>
            <Typography className={infoRegisterClasses.listTtl}>
              対応時間
            </Typography>
            <TextField
              className={infoRegisterClasses.input}
              onChange={handleChange}
              onBlur={(e) => { return values.setState2(e.target.value); }}
              value={text}
              type="number"
              placeholder="-"
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={onClickHandler3}
            >
              <Typography className={infoRegisterClasses.listTtl}>
                {values.label2}
                {values.required && <span>＊</span>}
              </Typography>
              <Typography className={infoRegisterClasses.listTxt}>
                {values.state3 || ''}
              </Typography>
            </Button>
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <Grid className={classes.block}>
          <Typography className={classes.ttl}>
            {values.label1}
            {values.required && <span>＊</span>}
          </Typography>
          <Grid className={classes.box}>
            <Button
              onClick={onClickHandler1}
              className={`${classes.btn} ${classes.btn1}`}
            >
              {values.state1 || ''}
            </Button>
            <TextField
              className={classes.input}
              onChange={handleChange}
              onBlur={(e) => { return values.setState2(e.target.value); }}
              value={text}
              defaultValue={0}
              type="number"
              inputProps={{ min: '0' }}
            />
            <Typography className={classes.txt}>
              分間対応
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.block}>
          <Typography className={classes.ttl}>
            {values.label2}
            {values.required && <span>＊</span>}
          </Typography>
          <Grid className={classes.box}>
            <Button
              className={`${classes.btn} ${classes.btn2}`}
              onClick={onClickHandler3}
            >
              {values.state3 || ''}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      {/* 接客者用の担当者選択ツリー */}
      <UserTree
        isOpenTree={treeWindowOpen1}
        setIsOpenTree={setTreeWindowOpen1}
        selectDivision={selectDivision1}
        setSelectDivision={setSelectDivision1}
        selectUser={selectUser1.userName !== '' ? selectUser1 : values.selectUser1}
        setSelectUser={setSelectUser1}
        handleSelectFunction={handleSelectFunction1}
        handleClearFunction={handleClearFunction1}
        isDialog
        isSelectUserWindow
      />
      {/* 同席者用の担当者選択ツリー */}
      <UserTree
        isOpenTree={treeWindowOpen3}
        setIsOpenTree={setTreeWindowOpen3}
        selectDivision={selectDivision3}
        setSelectDivision={setSelectDivision3}
        selectUser={selectUser3.userName !== '' ? selectUser3 : values.selectUser3}
        setSelectUser={setSelectUser3}
        handleSelectFunction={handleSelectFunction2}
        handleClearFunction={handleClearFunction2}
        isDialog
        isSelectUserWindow
      />
      {/* <Dialog
        open={treeWindowOpen1 || treeWindowOpen3}
        onClose={onClose}
      >
        <TreeWindow
          label="担当者選択"
          users={treeWindowOpen1 ? values.state1 : values.state3}
          setUsers={treeWindowOpen1 ? values.setState1 : values.setState3}
          onClose={onClose}
          searchForm
        />
      </Dialog> */}
    </Grid>
  );
}

export default InfoRegisterChoiceText2;
