import { useState } from 'react';
import { randomString } from '../commonFunction';

/**
 * 画面が更新しない時、強制的更新される
 * @returns {{
* rerender: () => void;
* rerenderKey: string;
* }}
*/
export default function useRerender() {
  const [randomKey, setRandomKey] = useState(randomString());

  const rerender = () => {
    setRandomKey(randomString());
  };

  return {
    rerender,
    rerenderKey: randomKey,
  };
}
