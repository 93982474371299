import client from '../httpClient';

const simulatorUploadApi = (csv, areaCode) => {
  const data = new FormData();
  data.append('csvFile', csv);
  data.append('areaCode', areaCode);
  const headers = { 'content-type': 'multipart/form-data' };
  return client.post('/simulator/csv-upload', data, {
    headers,
    // responseType: 'arraybuffer',
  });
};

export default simulatorUploadApi;
