import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';
import MemberStatusCode from '../../eleCommon/memberStatusCode';
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
  },
  lbl1: {
    marginRight: 8,
  },
  lbl2: {
    marginRight: 4,
  },
  button: {
    padding: '4px 10px',
  },
  link: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    whiteSpace: 'nowrap',
  },
  date: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 180,
  },
});

function CustomerHistory(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { input } = props;
  const { webs, hashCustomerId, lastWebAccessAt } = input.customer;

  // Web履歴はメインの情報を表示するため
  const mainMember = useMemo(() => {
    return webs.find((item) => item.isMain) || webs[0] || {};
  }, [webs]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.box}>
        <Typography className={`${baseClasses.title6} ${classes.lbl1}`}>
          Web
        </Typography>
        <MemberStatusCode
          hideNonMemberIcon
          code={mainMember.memberStatusCode}
        />
      </Grid>
      <Grid className={classes.box}>
        <Typography className={`${baseClasses.title6} ${classes.lbl2}`}>
          履歴
        </Typography>
        <NormalTooltip title={lastWebAccessAt || ''}>
          <Typography className={`${baseClasses.title4} ${classes.date}`}>
            {lastWebAccessAt || ''}
          </Typography>
        </NormalTooltip>
        {/* <NormalTooltip title={mainMember.lastWebAccessDate || ''}>
          <Typography className={`${baseClasses.title4} ${classes.date}`}>
            {mainMember.lastWebAccessDate || ''}
          </Typography>
        </NormalTooltip> */}
      </Grid>
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button}`}
      >
        <Link
          href={mainMember.memberId ? `${URL_MAPPER.web}?memberId=${mainMember.memberId}` : `${URL_MAPPER.web}?hashCustomerId=${hashCustomerId}`}
          target="_blank"
          className={classes.link}
        >
          Web履歴
        </Link>
      </Button>
    </Grid>
  );
}

export default React.memo(CustomerHistory);
