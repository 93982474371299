import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const directTupdateSlice = createSlice({
  name: 'directTupdate',
  initialState: { directTupdate: initialState },
  reducers: {
    directTupdateApi: (state, data) => {
      state.directTupdate = data.payload;
    },
  },
});

export const { directTupdateApi } = directTupdateSlice.actions;

export default directTupdateSlice.reducer;
