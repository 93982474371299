import { Grid } from '@material-ui/core';
import { useEffect } from 'react';
import GensenRegisterContainer from '../../containers/gensenRegister/gensenRegisterContainer';
import commonStyles from '../styles';
import { useLoading } from '../../hooks';

export default function GensenRegister(props) {
  const {
    onClose,
    // responseID = null, // こっちが本番
    responseID, // 詳細表示用
  } = props;
  const common = commonStyles();
  const { hasLoading } = useLoading();

  // 詳細表示フラグ
  const readOnly = !!responseID;

  useEffect(() => {
    if (!responseID) console.log('responseIDが渡ってきていません');
    console.log({ responseID });
  }, []);

  return (
    <Grid className={`${hasLoading('GensenRegister') ? common.loading : ''}`}>
      <GensenRegisterContainer
        responseID={responseID}
        onClose={onClose}
        readOnly={readOnly}
      />
    </Grid>
  );
}
