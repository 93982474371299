import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import inputNumber from '../../../../commonFunction/inputNumber';
import { TextBaseField } from '../../../eleCommon/validation';
import { convertFormNumber } from '../../../../commonFunction/convertors';
import { validateFormInt1 } from '../../../../commonFunction/validations';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  budget: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  budgetBox: {
    [commonTheme.breakpoints.up('md')]: {
      width: 104,
    },
  },
  textField: {
    display: 'block',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
}));

export default function CustomerDetailBudget(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // フォーカスが外れた時
  const onBlur = (e) => {
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  const getBox1 = () => {
    return (
      <Grid className={classes.budgetBox}>
        <TextBaseField
          label="本人年収（万円）"
          defaultValue={input.detail.annualIncome}
          className={classes.textField}
          type="number"
          inputProps={{ min: '0' }}
          name="annualIncome"
          onBlur={onBlur}
          placeholder="-"
          InputLabelProps={{ 'data-shrink': true }}
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
          convertor={convertFormNumber}
          errorCallback={input.errorCallback('annualIncome')}
        />
      </Grid>
    );
  };

  const getBox2 = () => {
    return (
      <Grid className={classes.budgetBox}>
        <TextBaseField
          label="合算者（万円）"
          defaultValue={input.detail.subAnnualIncome}
          className={classes.textField}
          type="number"
          inputProps={{ min: '0' }}
          name="subAnnualIncome"
          onBlur={onBlur}
          InputLabelProps={{ 'data-shrink': true }}
          placeholder="-"
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
          convertor={convertFormNumber}
          errorCallback={input.errorCallback('subAnnualIncome')}
        />
      </Grid>
    );
  };

  const getBox3 = () => {
    return (
      <Grid className={classes.budgetBox}>
        <TextBaseField
          label="頭金（万円）"
          defaultValue={input.detail.deposit}
          className={classes.textField}
          type="number"
          inputProps={{ min: '0' }}
          name="deposit"
          onBlur={onBlur}
          InputLabelProps={{ 'data-shrink': true }}
          placeholder="-"
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
          convertor={convertFormNumber}
          errorCallback={input.errorCallback('deposit')}
        />
      </Grid>
    );
  };

  const getBox4 = () => {
    return (
      <Grid className={classes.budgetBox}>
        <TextBaseField
          label="援助（万円）"
          defaultValue={input.detail.assistAmount}
          className={classes.textField}
          type="number"
          inputProps={{ min: '0' }}
          name="assistAmount"
          onBlur={onBlur}
          placeholder="-"
          InputLabelProps={{ 'data-shrink': true }}
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
          convertor={convertFormNumber}
          errorCallback={input.errorCallback('assistAmount')}
        />
      </Grid>
    );
  };

  const getBox5 = () => {
    return (
      <Grid className={classes.budgetBox}>
        <TextBaseField
          label="月々支払（万円）"
          defaultValue={input.detail.monthlyPayment}
          className={classes.textField}
          type="number"
          inputProps={{ min: '0' }}
          name="monthlyPayment"
          onBlur={onBlur}
          InputLabelProps={{ 'data-shrink': true }}
          placeholder="-"
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
          convertor={convertFormNumber}
          errorCallback={input.errorCallback('monthlyPayment')}
        />
      </Grid>
    );
  };

  const getBox6 = () => {
    return (
      <Grid className={classes.budgetBox}>
        <TextBaseField
          label="ボーナス（万円）"
          defaultValue={input.detail.bonusPayment}
          className={classes.textField}
          type="number"
          inputProps={{ min: '0' }}
          name="bonusPayment"
          onBlur={onBlur}
          InputLabelProps={{ 'data-shrink': true }}
          placeholder="-"
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
          convertor={convertFormNumber}
          errorCallback={input.errorCallback('bonusPayment')}
        />
      </Grid>
    );
  };

  // SP 表示用
  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '本人年収（万円）',
      val: input.detail.annualIncome,
      fontSize: '16px',
      content: getBox1,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '合算者（万円）',
      val: input.detail.subAnnualIncome,
      fontSize: '16px',
      content: getBox2,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '頭金（万円）',
      val: input.detail.deposit,
      fontSize: '16px',
      content: getBox3,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '援助（万円）',
      val: input.detail.assistAmount,
      fontSize: '16px',
      content: getBox4,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '月々支払（万円）',
      val: input.detail.monthlyPayment,
      fontSize: '16px',
      content: getBox5,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'ボーナス（万円）',
      val: input.detail.bonusPayment,
      fontSize: '16px',
      content: getBox6,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          予算情報
        </Typography>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          予算情報
        </Typography>
        <Grid className={classes.budget}>
          {getBox1()}
          {getBox2()}
          {getBox3()}
          {getBox4()}
          {getBox5()}
          {getBox6()}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
