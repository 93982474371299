import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mails: [],
  totalCount: 0,
  customerIds: [],
};

export const mailIdListGetSlice = createSlice({
  name: 'mailIdListGet',
  initialState: { mailIdListGet: initialState },
  reducers: {
    mailIdListGetApi: (state, data) => {
      state.mailIdListGet = data.payload;
    },
  },
});

export const { mailIdListGetApi } = mailIdListGetSlice.actions;

export default mailIdListGetSlice.reducer;
