import React from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import AutomaticProcess from './automaticProcess';

const useStyles = makeStyles({
  root: {
    flexWrap: 'nowrap',
  },
  side: {
    flexShrink: 0,
    width: '200px',
  },
  main: {
    flexGrow: 1,
  },
});

function AutomaticProcessTest() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid className={classes.main}><AutomaticProcess /></Grid>
    </Grid>
  );
}

export default AutomaticProcessTest;
