import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableCell,
  TableRow,
  Link,
  Checkbox,
  Icon,
} from '@material-ui/core';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';
import MemberStatusCode from '../../eleCommon/memberStatusCode';
import UserLevel from '../../eleCommon/userLevel';

import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '6px 4px',
    textAlign: 'left',
    borderBottom: '1px solid #8C8C8C',
    height: 52,
    width: 'calc((100vw * 1.25 - 728px - 64px - 240px) / 9)',
    maxWidth: 'calc((100vw * 1.25 - 728px - 64px - 240px) / 9)',
    minWidth: 120,
    '&:nth-child(1)': {
      width: 26,
      maxWidth: 26,
      minWidth: 26,
    },
    '&:nth-child(2)': {
      width: 40,
      maxWidth: 40,
      minWidth: 40,
      textAlign: 'center',
    },
    '&:nth-child(3)': {
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    '&:nth-child(4)': {
      width: 92,
      maxWidth: 92,
      minWidth: 92,
      '& .MuiGrid-root': {
        justifyContent: 'center',
      },
    },
    '&:nth-child(6)': {
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    '&:nth-child(7)': {
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    '&:nth-child(8)': {
      width: 180,
      maxWidth: 180,
      minWidth: 180,
      textAlign: 'center',
    },
    '&:nth-child(9)': {
      textAlign: 'center',
    },
    '&:nth-child(10)': {
      textAlign: 'center',
    },
    '&:nth-child(11)': {
      width: 290,
      maxWidth: 290,
      minWidth: 290,
    },
    '&:nth-child(12)': {
      textAlign: 'center',
    },
    '&:nth-child(13)': {
      textAlign: 'center',
    },
    '&:nth-child(14)': {
      textAlign: 'center',
    },
    '&:nth-child(16)': {
      width: 110,
      maxWidth: 110,
      minWidth: 110,
    },
  },
  tableTdLink: {
    color: '#333',
  },
  checkBox2: {
    padding: 0,
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  expiredIcon: {
    display: 'none',
    fontSize: 12,
    color: '#D83420',
    margin: '0px 0 -1px 2px',
    '&.expired': {
      display: 'inline-block',
    },
  },
  taLeft: {
    textAlign: 'left',
    '& > p': { textAlign: 'left' },
  },
}));

export default function EnhancedTable(props) {
  const {
    index,
    row,
    isItemSelected,
    labelId,
    handleClick,
    style,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const getLink = (code) => {
    switch (code) {
      case 0:
      case 1:
      case 2:
      case 3:
        return (
          <Link
            href={`${URL_MAPPER.web}?memberId=${row.webMemberId}`}
            target="_blank"
            className={classes.tableTdLink}
          >
            <MemberStatusCode code={code} />
          </Link>
        );
      default:
        return (
          <Link
            href={`${URL_MAPPER.web}?customerId=${row.customerId}`}
            target="_blank"
            className={classes.tableTdLink}
          >
            <MemberStatusCode code={code} />
          </Link>
        );
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      className={classes.tableRow}
      style={style}
    >
      <TableCell className={classes.tableTd}>
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          onClick={(event) => handleClick(event, row.customerId)}
          className={classes.checkBox2}
        />
      </TableCell>
      <TableCell className={classes.tableTd}>
        {index + 1}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.customerId}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {getLink(row.memberStatusCode)}
      </TableCell>
      <TableCell id={labelId} className={classes.tableTd}>
        {row.effectAt?.substr(0, 16)}
        <Icon className={classes.expiredIcon}>
          local_fire_department
        </Icon>
      </TableCell>
      <NormalTooltip title={row.customerLastName ?? ''}>
        <TableCell className={`${classes.tableTd} ${baseClasses.tooltipText} ${classes.taLeft}`}>
          {row.customerLastName}
        </TableCell>
      </NormalTooltip>
      <NormalTooltip title={row.customerFirstName ?? ''}>
        <TableCell className={`${classes.tableTd} ${baseClasses.tooltipText} ${classes.taLeft}`}>
          {row.customerFirstName}
        </TableCell>
      </NormalTooltip>
      <NormalTooltip title={`${row.userLastName} ${row.userFirstName}`}>
        <TableCell className={`${classes.tableTd} ${baseClasses.tooltipText}`}>
          {row.userLastName} {row.userFirstName}
        </TableCell>
      </NormalTooltip>
      <TableCell className={classes.tableTd}>
        {row.detachedHouseBudgetMax}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.landBudgetMax}
      </TableCell>
      <NormalTooltip title={row.wishArea ?? ''}>
        <TableCell className={`${classes.tableTd} ${baseClasses.tooltipText}`}>
          {row.wishArea}
        </TableCell>
      </NormalTooltip>
      <TableCell className={classes.tableTd}>
        {row.annualIncome}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.ownFunds}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.monthlyPayment}
      </TableCell>
      <TableCell className={`${classes.tableTd} ${classes.taLeft}`}>
        <UserLevel code={row.userLevel} />
      </TableCell>
    </TableRow>
  );
}
