import { useEffect, useState } from 'react';
import {
  Grid,
  Drawer,
  List,
  ListItem,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import commonStyles from '../../../styles';

import { RESPONSE_TYPE } from '../../../../constants/responseType';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '100%',
      background: '#F3F3F3',
    },
    '& .MuiDialog-paper': {
      margin: 0,
      maxWidth: 'none',
      width: '100%',
      height: '100vh',
      maxHeight: 'initial',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      borderRadius: 0,
    },
  },
  paperHeader: {
    padding: '19px 0 8px',
    background: '#fff',
  },
  paperTtl: {
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
  pagerIcon: {
    fontSize: 14,
    color: theme.palette.primaryColor,
  },
  body: {
    padding: '16px 0 74px',
    overflowY: 'auto',
  },
  list: {
    backgroundColor: '#fff',
    padding: '16px 0 16px 16px',
  },
  item: {
    borderBottom: '1px #F3F3F3 solid',
    padding: '18.5px 16px',
    '&.Mui-selected': {
      background: '#F3F3F3',
    },
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function ResponseTypeListSp(props) {
  const { data, setState, state, open, setOpen, defaultResponse,
    resetData, set, addResponseType, parentOpen } = props;
  const common = commonStyles();
  const classes = useStyles();

  // リスト選択用ローカルステート
  const [level, setLevel] = useState(0);

  const makeSelectedList = () => {
    if (state[0] === undefined) return [];
    const l = [];
    // let lv = 0;
    state.map((s) => {
      // lv += 1;
      return l.push(s.responseTypeId);
    });
    // setLevel(lv);
    return l;
  };

  const [select, setSelect] = useState(() => {
    return makeSelectedList();
  });

  const childArray = RESPONSE_TYPE;

  // モーダルの開閉をすべてリセット
  const handleClose = () => {
    setLevel(0);
    set();
    setOpen({ open1: false, open2: false, open3: false, open4: false });
  };

  // 戻るボタン押下
  const handleBack = (clickLevel) => {
    select.splice(clickLevel - 1, 4);
    setLevel(0, Math.max(level - 1));
    setSelect(select);
    const stateList = data.responseTypes.filter((item) => {
      const { responseTypeId } = item;
      return select.includes(responseTypeId);
    });

    setState(stateList.sort((a, b) => {
      return a.responseTypeLevelCode < b.responseTypeLevelCode ? -1 : 1;
    }));

    if (level === 0 || (addResponseType && level === 1)) {
      // 反響媒体（大）の時に戻った場合 or 源泉仮登録で反響媒体（中）の時に戻った場合
      resetData();
    }
  };

  // dataに格納されたデータから[responseTypeLevelCode, responseTypeParentId]でfilterをかけたリストを取得
  // 反響媒体リストを描画時に使用
  const filterData = (filterLevel) => {
    const filtered = data.responseTypes?.filter((item) => {
      const { responseTypeLevelCode, responseTypeParentId } = item;
      if (filterLevel === 1) return responseTypeLevelCode === filterLevel;
      return responseTypeLevelCode === filterLevel && select.includes(responseTypeParentId);
    });

    return filtered;
  };

  // 選択肢を選んだときの処理
  const handleListItemClick = (clickLevel, id) => {
    if (select.includes(id) || select[clickLevel - 1]) {
      select.splice(clickLevel - 1, 4);
    }
    select.push(id);
    // 次のデータを取得
    const nextList = filterData(clickLevel + 1);
    if (nextList.length) {
      setLevel(clickLevel);
    }
    setSelect(select);

    const stateList = data.responseTypes.filter((item) => {
      const { responseTypeId } = item;
      return select.includes(responseTypeId);
    });

    setState(stateList.sort((a, b) => {
      return a.responseTypeLevelCode < b.responseTypeLevelCode ? -1 : 1;
    }));
  };

  const getFilterList = (filterLevel, list) => {
    let copyList = [...list];
    if (defaultResponse) {
      if (defaultResponse?.level === filterLevel) {
        for (const key of copyList) {
          if (key.responseTypeId === defaultResponse?.responseTypeId) copyList = [key];
        }
      }
    }
    return copyList.map((item) => (
      <ListItem
        button
        selected={select.includes(item.responseTypeId)}
        onClick={() => handleListItemClick(filterLevel, item.responseTypeId)}
        key={`item${item.responseTypeId}`}
        className={`${common.title3} ${classes.item}`}
      >
        {item.responseTypeName}
      </ListItem>
    ));
  };

  // レベル変更時、関連するモーダルを開く
  useEffect(() => {
    if (level > 0) {
      setOpen((prev) => ({
        ...prev,
        [`open${level + 1}`]: true,
      }));
    }
  }, [level]);

  useEffect(() => {
    // 源泉仮登録の場合
    if (parentOpen && addResponseType) {
      // 反響媒体大に「源泉」を設定
      handleListItemClick(1, 5);
    }
  }, [parentOpen]);

  return (
    <>
      {/* 各反響媒体のリストを含んだDialogを描画(各項目を選択し、絞り込み項目が0の場合は、その階層で画面遷移を止める) */}
      {childArray.map(c => {
        const filteredList = filterData(c.responseTypeLevelCode);
        if (filteredList && filteredList.length > 0) {
          return (
            <Drawer
              anchor="right"
              key={c.responseTypeLevelCode}
              open={open[`open${c.responseTypeLevelCode}`]}
              onClose={() => { setOpen({ ...open, [`open${c.responseTypeLevelCode}`]: !open[`open${c.responseTypeLevelCode}`] }); }}
              className={classes.dialog}
            >
              <Grid className={classes.paperHeader}>
                <Typography
                  className={`${common.title2} ${classes.paperTtl}`}
                >
                  {c.label}
                </Typography>
                <Button
                  className={common.arrowButton}
                  onClick={() => {
                    handleBack(c.responseTypeLevelCode);
                    // 関連するモーダルを閉じる
                    if (addResponseType && c.responseTypeLevelCode === 2) {
                      // 源泉仮登録で反響媒体（中）の時に戻った場合
                      setOpen({ ...open, open1: false, open2: false });
                    } else {
                      setOpen({ ...open, [`open${c.responseTypeLevelCode}`]: !open[`open${c.responseTypeLevelCode}`] });
                    }
                  }}
                >
                  <ArrowBackIosIcon className={classes.pagerIcon} />
                </Button>
              </Grid>
              <Grid className={classes.body}>
                <List className={classes.list}>
                  {getFilterList(c.responseTypeLevelCode, filteredList)}
                </List>
              </Grid>
              <Grid container justifyContent="center" className={common.fixedFooter}>
                <Button
                  className={common.buttonsPrimary}
                  onClick={handleClose}
                >
                  選択
                </Button>
              </Grid>
            </Drawer>
          );
        } else {
          return false;
        }
      })}
    </>
  );
}

export default ResponseTypeListSp;
