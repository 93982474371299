import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
} from '@material-ui/core';
import CustomerDetail from '../../../../customerDetail';
import CustomerResponsible from '../../../../../customerMain/parts/customerResponsible';
import CustomerResponse from '../../../../../customerMain/parts/customerResponse';
import CustomerName from '../../../../../customerMain/parts/customerName1';
// import CustomerName2 from '../../../../../customerMain/parts/customerName2';
import CustomerAddress from '../../../../../customerMain/parts/customerAddress';
import CustomerWorkPlace from '../../../../../customerMain/parts/customerWorkPlace';
import CustomerExamination from '../../../../../customerMain/parts/customerExamination';
import CustomerStatus from '../../../../../customerMain/parts/customerStatus';
import CustomerRank from '../../../../../customerMain/parts/customerRank';
import CustomerTel from '../../../../../customerMain/parts/customerTel';
import CustomerMail from '../../../../../customerMain/parts/customerMail';
import CustomerTime from '../../../../../customerMain/parts/customerTime';
import CustomerHoliday from '../../../../../customerMain/parts/customerHoliday';
import CustomerInfo1 from '../../../../../customerMain/parts/customerInfo1';
import CustomerInfo2 from '../../../../../customerMain/parts/customerInfo2';
import CustomerArea from '../../../../../customerMain/parts/customerArea';
import { HolidayTimeProvider } from '../../../../../customerMain/parts/holidayTimeContext';
import commonStyles from '../../../../../styles';
import { useLoading, useRerender } from '../../../../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 102,
    paddingBottom: 160,
  },
  buttonWrap: {
    padding: 16,
  },
  button: {
    fontFamily: 'Roboto',
    color: theme.palette.primaryColor,
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    background: '#fff',
    width: '100%',
    padding: '9px 8px 7px',
  },
}));

function Wrap(props) {
  const {
    data,
    functions,
    children,
    onDetailChange,
  } = props;

  const { hasLoading } = useLoading();
  const classes = useStyles();
  const common = commonStyles();

  const [open, setOpen] = useState(false); // 顧客詳細モーダル開閉用のローカルステート

  // 個人の顧客情報を取得（詳細）
  const getCustomerDetail = () => {
    functions.getCustomerFunctionSingle([data.customer.customerId], true);
  };
  const handleClose = () => {
    setOpen(false);
    // 個人の顧客情報を初期化（詳細）
    data.setCustomerDetail(null);
  };
  useEffect(() => {
    if (data.customerDetail) {
      // 個人の顧客情報を取得後、モーダルを開く
      setOpen(true);
    }
  }, [data.customerDetail]);

  return (
    <Grid className={`${classes.root} ${hasLoading('getCustomer') ? common.loading : ''}`}>
      <Grid className={classes.buttonWrap}>
        <Button
          className={classes.button}
          onClick={getCustomerDetail}
        >
          全項目表示
        </Button>
      </Grid>
      {data.customerDetail && (
        <CustomerDetail
          open={open}
          onClose={handleClose}
          input={data}
          onChange={onDetailChange}
        />
      )}
      {children}
    </Grid>
  );
}

export default function CustomerInformation(props) {
  const {
    input,
    functions,
    setValue,
    updatePage,
  } = props;

  const [customerInfo, setCustomerInfo] = useState({
    userLevel: input.customer.userLevel,
    emailStatusCode: input.customer.emailStatusCode,
    isMailSend1: input.customer.isMailSend1,
    isMailSend2: input.customer.isMailSend2,
    isMailSend3: input.customer.isMailSend3,
    isMailSend4: input.customer.isMailSend4,
    telStatusCode: input.customer.telStatusCode,
  });

  const isFirstRender = useRef(true);

  const data = {
    customer: input.customer,
    component: input.component,
    parts: input.parts,
    editDialog: input.editDialog,
    setTab: setValue,
    update: updatePage,
    customerDetail: input.customerDetail,
    setCustomerDetail: input.setCustomerDetail,
    customerInfo,
    setCustomerInfo,
    isFirstRender,
  };

  const { rerender, rerenderKey } = useRerender();

  useEffect(() => {
    if (!isFirstRender.current) {
      setCustomerInfo({
        ...customerInfo,
        userLevel: input.customer.userLevel,
        emailStatusCode: input.customer.emailStatusCode,
        isMailSend1: input.customer.isMailSend1,
        isMailSend2: input.customer.isMailSend2,
        isMailSend3: input.customer.isMailSend3,
        isMailSend4: input.customer.isMailSend4,
        telStatusCode: input.customer.telStatusCode,
      });
      // 画面再描画
      rerender();
    } else {
      isFirstRender.current = false;
    }
  }, [input.customer]);

  return (
    <Wrap data={data} functions={functions} onDetailChange={updatePage} key={rerenderKey}>
      <CustomerResponsible input={data} />
      <CustomerStatus input={data} />
      <CustomerRank input={data} />
      <CustomerResponse input={data} />
      <CustomerName input={data} />
      <CustomerName input={data} isSub />
      {/* <CustomerName2 input={data} /> */}
      <CustomerTel input={data} />
      <HolidayTimeProvider customer={input.customer}>
        <CustomerTime setTab={setValue} />
        <CustomerMail input={data} />
        <CustomerHoliday />
      </HolidayTimeProvider>
      <CustomerAddress input={data} />
      <CustomerWorkPlace input={data} />
      <CustomerInfo1 input={data} />
      <CustomerInfo2 input={data} />
      <CustomerArea input={data} />
      <CustomerExamination input={data} />
    </Wrap>
  );
}
