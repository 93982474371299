import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import CustomerTree from '../common/customerTree/customerTree';

function AsideCustomerTree(props) {
  const {
    defaultUserId,
    defaultDivisionId,
    setSelectUser,
    setSelectDivision,
    setSelectCustomer,
    isMulti,
    treeHeight,
  } = props;

  const searchParams = useSelector((state) => state.searchItems.searchParams);

  return useMemo(() => {
    return (
      <CustomerTree
        defaultUserId={defaultUserId}
        defaultDivisionId={defaultDivisionId}
        setSelectUser={setSelectUser}
        setSelectDivision={setSelectDivision}
        setSelectCustomer={setSelectCustomer}
        targetFlgList={{
          userFlg: true,
          divisionFlg: true,
          customerFlg: true,
        }} // 組織、社員、顧客にて検索、選択をするか判定(object)
        isMulti={isMulti}
        isHaveInitialParams
        isAvoidSharedCustomer
        treeHeight={treeHeight}
      />
    );
  }, [searchParams, defaultUserId, treeHeight]);
}

export default memo(AsideCustomerTree);
