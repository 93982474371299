import { createSlice } from '@reduxjs/toolkit';

const initialState = '';
export const pagePathSlice = createSlice({
  name: 'pagePath',
  initialState: {
    page: initialState,
  },
  reducers: {
    setPage: (state, data) => {
      state.page = data.payload;
    },
  },
});

export const { setPage } = pagePathSlice.actions;

export default pagePathSlice.reducer;
