import { useEffect, useState } from 'react';
import './App.css';
import MainComponent from './components';
import getDeviceType from './components/getDeviceType';

function App() {
  const [deviceClass, setDeviceClass] = useState('pc');
  useEffect(() => {
    if (getDeviceType()) {
      setDeviceClass('sp');
    }
  });

  return (
    <div className={`App ${deviceClass}`}>
      <MainComponent />
    </div>
  );
}

export default App;
