import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // TextField,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import CommonDialog from '../../common/modal';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import getOwnDivisionApi from '../../../apis/divisionMaster/getOwnDivisionApi';
import { TextBaseField } from '../../eleCommon/validation';
import { validateFormFloat1 } from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { MAN_YEN } from '../../../constants';

const useStyles = makeStyles({
  wrap: {
    alignItems: 'center',
    background: '#fff',
    border: '1px #c8c8c8 solid',
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 8px',
    '&:not(:last-child)': { marginBottom: 8 },
  },
  radioWrap: {
    flexDirection: 'row',
  },
  ttl: {
    marginRight: 16,
    '& span': { color: '#D83420' },
  },
  cell: {
    padding: 4,
    '& input': { textAlign: 'right' },
  },
  dist: {
    cursor: 'pointer',
    flexGrow: 1,
  },
  selectDivisionInput: {
    cursor: 'pointer',
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
});

function DistRow(localProps) {
  const { label, code, values, setValues, errorCallback } = localProps;
  const classes = useStyles();

  const changeText = (c, txt, id) => {
    setValues({
      ...values,
      [`code${c}`]: {
        ...values[`code${c}`],
        sendObject: {
          ...values[`code${c}`].sendObject,
          [id]: txt,
        },
      },
    });
  };

  const toggleChecked = (c) => {
    setValues({
      ...values,
      [`code${c}`]: {
        ...values[`code${c}`],
        checked: !values[`code${c}`].checked,
      },
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const fieldName = name?.replace(code, '');
    changeText(code, value, fieldName);
  };

  return (
    <TableRow>
      <TableCell className={classes.cell}>{label}</TableCell>
      <TableCell className={classes.cell}>
        <TextBaseField
          size="small"
          type="number"
          defaultValue={values[`code${code}`].sendObject.buildingCount}
          onBlur={handleBlur}
          placeholder=" - "
          disabled={!values[`code${code}`].checked}
          convertor={convertFormNumber}
          validator={validateFormFloat1({
            minFloat: 0.0,
            maxFloat: 99999.0,
            precisionInt: 4,
          })}
          name={`buildingCount${code}`}
          errorCallback={errorCallback(`buildingCount${code}`)}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <TextBaseField
          size="small"
          type="number"
          defaultValue={values[`code${code}`].sendObject.sales}
          onBlur={handleBlur}
          placeholder=" - "
          disabled={!values[`code${code}`].checked}
          convertor={convertFormNumber}
          validator={validateFormFloat1({
            minFloat: 0.0,
            maxFloat: 99999.0,
            precisionInt: 4,
          })}
          name={`sales${code}`}
          errorCallback={errorCallback(`sales${code}`)}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <Checkbox
          color="primary"
          onChange={() => {
            return toggleChecked(code);
          }}
          value={values[`code${code}`].checked}
          checked={values[`code${code}`].checked}
        />
      </TableCell>
    </TableRow>
  );
}

export default function AddAimWindow(props) {
  const { registerFunction, open, onClose, getKimarisTargetList } = props;
  const dispatch = useDispatch();
  const common = commonStyles();
  const classes = useStyles();
  const [isValid, setIsValid] = useState(true);
  const [radio, setRadio] = useState(0);
  const [centers, setCenters] = useState([]);
  const [salesDivisions, setSalesDivisions] = useState([]);
  const [selectDivision, setSelectDivision] = useState({ divId: 0 });
  const defaultInitial = {
    code1: {
      checked: false,
      sendObject: {
        positionCode: 1,
        // divisionId: selectDivision.divId,
        divisionId: null,
        sales: '',
        buildingCount: '',
      },
    },
    code2: {
      checked: false,
      sendObject: {
        positionCode: 2,
        // divisionId: selectDivision.divId,
        divisionId: null,
        sales: '',
        buildingCount: '',
      },
    },
    code6: {
      checked: false,
      sendObject: {
        positionCode: 6,
        // divisionId: selectDivision.divId,
        divisionId: null,
        sales: '',
        buildingCount: '',
      },
    },
    code3: {
      checked: false,
      sendObject: {
        positionCode: 3,
        // divisionId: selectDivision.divId,
        divisionId: null,
        sales: '',
        buildingCount: '',
      },
    },
    code4: {
      checked: false,
      sendObject: {
        positionCode: 4,
        // divisionId: selectDivision.divId,
        divisionId: null,
        sales: '',
        buildingCount: '',
      },
    },
  };

  const [values, setValues] = useState(defaultInitial);

  const [errorSet, setErrorSet] = useState(new Set());
  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  useEffect(async () => {
    // 先んじて営業部と店舗を取得
    const getSalesDivisions = await getOwnDivisionApi({
      divisionSearchCode: 0,
    });
    const getCenters = await getOwnDivisionApi({ divisionSearchCode: 1 });
    setSalesDivisions(getSalesDivisions?.divisions);
    setCenters(getCenters?.divisions);
  }, []);

  // ツリー選択後にsetSelectDivision
  useEffect(() => {
    const copyObj = Object.assign({}, values);
    Object.keys(copyObj).forEach((value) => {
      copyObj[value].sendObject.divisionId = selectDivision.divId;
    });
    setValues(copyObj);
    if (radio === 0) {
      copyObj.code1.sendObject.divisionId = null;
    } else {
      copyObj.code2.sendObject.divisionId = null;
      copyObj.code3.sendObject.divisionId = null;
      copyObj.code4.sendObject.divisionId = null;
      copyObj.code6.sendObject.divisionId = null;
    }
  }, [selectDivision]);

  // ValidationCheck
  useEffect(() => {
    const validList = [];
    Object.keys(values).forEach((value) => {
      if (values[value].checked) {
        const checkValues = [
          values[value].sendObject.buildingCount > 0
            ? values[value].sendObject.buildingCount
            : null,
          values[value].sendObject.divisionId > 0
            ? values[value].sendObject.divisionId
            : null,
          values[value].sendObject.positionCode,
          values[value].sendObject.sales > 0
            ? values[value].sendObject.sales
            : null,
        ];
        if (checkValues.includes(null) || checkValues.includes('')) {
          validList.push('miss');
        } else {
          validList.push('ok');
        }
      } else {
        validList.push('null');
      }
    });
    if (validList.includes('miss')) {
      setIsValid(true);
    } else if (validList.includes('ok')) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [values]);

  // 新規目標登録
  const clickRegister = async () => {
    // 成功時のstateの初期化
    const callback = () => {
      setValues(defaultInitial);
      setSelectDivision({ divId: 0 });
    };
    // -------------------
    const requestParams = [];
    // checkが入ってるもののみrequestに追加
    const copyObj = JSON.parse(JSON.stringify(values));
    Object.keys(copyObj).forEach((value) => {
      if (copyObj[value].checked) {
        // requestに乗せる売上のみ10000倍にする
        copyObj[value].sendObject.sales *= MAN_YEN;
        requestParams.push(copyObj[value].sendObject);
      }
    });
    await registerFunction(requestParams, callback);
    await getKimarisTargetList();
    onClose();
  };

  const cancelCreate = () => {};

  // 確認画面
  const confirmOpen = () => {
    dispatch(
      changeConfirmMessage({
        title: '目標追加',
        msgList: ['新規の目標を追加します。よろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: cancelCreate,
            classes: common.buttonsSecondary,
          },
          {
            label: 'OK',
            set: clickRegister,
            classes: common.buttonsPrimary,
          },
        ],
      }),
    );
  };

  // 対象の変更 最上位ラジオボタンの切り替えによってStateを変更
  const handleChange = (e) => {
    const copyObj = Object.assign({}, defaultInitial);
    setSelectDivision({ divId: 0 });
    if (e.target.value === '1') {
      copyObj.code1.checked = true;
      copyObj.code2 = defaultInitial.code2;
      copyObj.code6 = defaultInitial.code6;
      copyObj.code3 = defaultInitial.code3;
      copyObj.code4 = defaultInitial.code4;
      setRadio(1);
    } else {
      copyObj.code1 = defaultInitial.code1;
      setRadio(0);
    }
    setValues(copyObj);

    // ラジオ切り替え時に単項目チェックエラーもリセット
    setErrorSet(new Set());
  };

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="目標追加"
      width={640}
      buttons={{
        label: '登録',
        disabled: isValid || errorSet.size !== 0,
        onClick: confirmOpen,
      }}
    >
      <Grid>
        <FormControl component={Grid} className={`${classes.wrap}`}>
          <Typography className={`${common.title6} ${classes.ttl}`}>
            対象<span>＊</span>
          </Typography>
          <RadioGroup
            name="target"
            value={radio}
            onChange={handleChange}
            className={classes.radioWrap}
          >
            <FormControlLabel
              className={common.small}
              value={0}
              control={<Radio />}
              label={
                <Typography className={common.small}>
                  センター長,サブセンター長,マネージャー,プレイヤー
                </Typography>
              }
            />
            <FormControlLabel
              className={common.small}
              value={1}
              control={<Radio />}
              label={<Typography className={common.small}>営業部長</Typography>}
            />
          </RadioGroup>
        </FormControl>
        {radio ? (
          <Grid className={classes.wrap}>
            <Typography className={`${common.title6} ${classes.ttl}`}>
              営業部<span>＊</span>
            </Typography>
            <Grid className={common.inputWrap}>
              <Select
                labelId="demo-simple-select-standard-label1"
                id="demo-simple-select-standard1"
                value={selectDivision.divId}
                disableUnderline
                onClick={(e) => setSelectDivision({ divId: e.target.value })}
                label="営業部"
              >
                <MenuItem value={0}>未選択</MenuItem>
                {salesDivisions.map((salesDivision) => (
                  <MenuItem
                    value={salesDivision.divisionId}
                    key={salesDivision.divisionName}
                  >
                    {salesDivision.divisionName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.wrap}>
            <Typography className={`${common.title6} ${classes.ttl}`}>
              店舗<span>＊</span>
            </Typography>
            <Grid className={common.inputWrap}>
              <Select
                labelId="demo-simple-select-standard-label2"
                id="demo-simple-select-standard2"
                value={selectDivision.divId}
                className={classes.selectBox}
                disableUnderline
                onClick={(e) => setSelectDivision({ divId: e.target.value })}
                label="店舗"
              >
                <MenuItem value={0}>未選択</MenuItem>
                {centers.map((center) => (
                  <MenuItem value={center.divisionId} key={center.divisionId}>
                    {center.divisionName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        <Grid className={classes.wrap}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>役割</TableCell>
                <TableCell className={classes.cell}>棟</TableCell>
                <TableCell className={classes.cell}>売上（万円）</TableCell>
                <TableCell className={classes.cell}>追加</TableCell>
              </TableRow>
            </TableHead>
            {radio ? (
              <TableBody key={radio}>
                <DistRow
                  label="営業部長"
                  code={1}
                  values={values}
                  setValues={setValues}
                  errorCallback={errorCallback}
                />
              </TableBody>
            ) : (
              <TableBody key={radio}>
                <DistRow
                  label="センター長"
                  code={2}
                  values={values}
                  setValues={setValues}
                  errorCallback={errorCallback}
                />
                <DistRow
                  label="サブセンター長"
                  code={6}
                  values={values}
                  setValues={setValues}
                  errorCallback={errorCallback}
                />
                <DistRow
                  label="マネージャー"
                  code={3}
                  values={values}
                  setValues={setValues}
                  errorCallback={errorCallback}
                />
                <DistRow
                  label="プレイヤー"
                  code={4}
                  values={values}
                  setValues={setValues}
                  errorCallback={errorCallback}
                />
              </TableBody>
            )}
          </Table>
        </Grid>
      </Grid>
    </CommonDialog>
  );
}
