import { useCallback, useEffect, useState } from 'react';
import customerPostIdListApi from '../../apis/customer/customerPostIdListApi';
import CustomerBatchWrapper from '../../components/customerBatch/customerBatch';

function CustomerBatchContainers(props) {
  const { selectCustomer } = props;
  // /customers/id-list
  // 【changeEmp001】顧客表示
  const [list, setList] = useState([]);

  const setCustomers = useCallback(async () => {
    if (selectCustomer.length === 0) {
      setList([]);
      return;
    }
    const customerIdList = selectCustomer.map((customer) => {
      return customer.customerId;
    });
    const customersList = await customerPostIdListApi({ customerIds: customerIdList });
    setList(customersList.data.customers);
  }, [selectCustomer]);

  useEffect(() => {
    setCustomers();
  }, [selectCustomer]);

  return (
    <CustomerBatchWrapper list={list} />
  );
}

export default CustomerBatchContainers;
