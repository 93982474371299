import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  contentIpt: {
    padding: '8px',
    display: 'flex',
  },
  hopeTfInput: {
    margin: '-3px 0 0 8px',
    width: '88%',
  },
  textField: {
    display: 'block',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
      textAlign: 'right',
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  main: {
    fontWeight: 700,
    flexGrow: 1,
  },
  label: {
    width: 80,
    flexShrink: 0,
  },
}));

function ResSiteIdField(props) {
  const classes = useStyles();
  const { house } = props;

  return (
    <Grid className={classes.contentIpt}>
      <Typography className={classes.label}>
        物件番号
      </Typography>
      <Typography className={classes.main}>
        {house ? house.siteNumber : ''}
      </Typography>
    </Grid>
  );
}

export default ResSiteIdField;
