import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const kimarisTargetSettingsRegistSlice = createSlice({
  name: 'kimarisTargetSettingsRegist',
  initialState: { kimarisTargetSettingsRegist: initialState },
  reducers: {
    kimarisTargetSettingsRegistApi: (state, data) => {
      state.kimarisTargetSettingsRegist = data.payload;
    },
  },
});

export const { kimarisTargetSettingsRegistApi } = kimarisTargetSettingsRegistSlice.actions;

export default kimarisTargetSettingsRegistSlice.reducer;
