import { Button, Grid, Input, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '8px',
    '& input': {
      color: '#8C8C8C',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: '7px 0',
    },
  },
  inputWrap: {
    position: 'relative',
    width: 280,
    height: 28,
    marginTop: '-10px',
    '& .MuiInput-root': {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
  },
  boxLbl: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 0 5px',
    width: 108,
    textAlign: 'center',
    pointerEvents: 'none',
  },
  upload: {
    position: 'relative',
    marginRight: '16px',
    '&::after': {
      content: '""',
      background: '#C8C8C8',
      width: '1px',
      height: '42px',
      position: 'absolute',
      top: 0,
      right: '-8px',
    },
  },
}));

export default function CalendarMasterComponent(props) {
  const { upload, download } = props;
  const common = commonStyles();
  const classes = useStyles();
  const [path, setPath] = useState('');
  const onChange = (e) => { setPath(e.target.files[0]); };
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid className={classes.inputWrap}>
        <Typography className={classes.boxLbl}> ファイルを選択</Typography>
        <Input type="file" onChange={onChange} inputProps={{ accept: '.csv' }} />
      </Grid>
      <Button
        className={`${common.buttonsPrimary} ${classes.upload}`}
        onClick={() => { return upload(path); }}
        disabled={!path}
      >
        アップロード
      </Button>
      <Button
        className={common.buttonsSecondary}
        onClick={download}
      >
        ダウンロード
      </Button>
    </Grid>
  );
}
