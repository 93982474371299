import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import TalkRoomList from './talkRoomList';
import CommonDialog from '../../common/modal';

import store from '../../../store';

import ManagementTalkRoom from './managementTalkRoom';

const useStyles = makeStyles((theme) => ({
  flex: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  addIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.primaryColor,
  },
  addNewTalkButton: {
    color: theme.palette.primaryColor,
    [commonTheme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      width: '100%',
      padding: '16px',
      borderBottom: '1px solid #F3F3F3',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.6,
      justifyContent: 'flex-start',
    },
  },
}));

function ManagementLine(props) {
  const { open, onClose, customerId, functions, rooms } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [openTalkRoom, setOpenTalkRoom] = useState(false);
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const { responseHeader } = store.getState();
  const { userId, userName, mainDivision } = responseHeader;
  const userDivisionName = mainDivision.name;
  const userDivisionId = mainDivision.division_id;
  const initialAddParam = {
    customerId,
    isUseResourceId: 0, // 0: 複数人チャットのIDフラグ, 1: 個人チャットのIDフラグ
    lineRoomName: '',
    customerLineIds: [],
    users: [{ userId, userName, userDivisionName, userDivisionId }],
  };

  const closeTalkRoom = () => setOpenTalkRoom(false);
  const onRegist = (param) => {
    setOpenTalkRoom(false);
    functions.addNewLineRooms(param);
  };

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={isSp ? 'LINE WORKS' : 'LINE WORKS管理画面'}
      width={640}
    >
      <Grid className={classes.flex}>
        {!isSp && (
          <Typography className={baseClasses.title3}>トークルーム</Typography>
        )}
        <Grid>
          <Button
            className={`${baseClasses.title4} ${classes.addNewTalkButton}`}
            onClick={() => setOpenTalkRoom(true)}
          >
            <AddIcon className={classes.addIcon} />
            新規追加
          </Button>
          <ManagementTalkRoom
            open={openTalkRoom}
            onClose={closeTalkRoom}
            customerId={customerId}
            line={initialAddParam}
            onRegist={onRegist}
            isNew
          />
        </Grid>
      </Grid>
      <Grid>
        {Object.keys(rooms).length ? (
          <TalkRoomList dataList={rooms} functions={functions} />
        ) : null}
      </Grid>
    </CommonDialog>
  );
}

export default ManagementLine;
