import { useState } from 'react';
import { Button, FormControl, Grid, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { DirectionsRun, HelpOutline, History, MailOutline, MobileScreenShare, OpenInNew, PostAdd, TrackChanges } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import EasyRegister from '../easyRegister';
import Dialog from '../common/modal';
import GensenRegister from '../gensenRegister';
import GensenRegisterHistory from '../gensenRegister/gensenRegisterHistory';
import DashBoard from '../pages/dashBoard/dashBoard';
import commonStyles from '../styles';
import UnsentMail from '../unsentMail';
import LogoutButton from '../auth/logout/logout';
import { COLOR_THEME, COLOR_CODE } from '../../constants';
import { getColorTheme } from '../../store/common/getTheme';
import ChanceNotificationContainer from '../../containers/chanceNotification/chanceNotificationContainer';

const useStyles = makeStyles((theme) => ({
  root: { background: '#F3F3F3', padding: '32px 16px' },
  week: {
    background: '#333333',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    merginLeft: 8,
  },
  btn: {
    background: '#fff',
    borderRadius: 4,
    boxShadow: '0px 0px 4px rgba(51, 51, 51, 0.12), 0px 4px 4px rgba(51, 51, 51, 0.24)',
    minWidth: 106,
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  btn2: {
    background: '#fff',
    borderRadius: 4,
    boxShadow: '0px 0px 4px rgba(51, 51, 51, 0.12), 0px 4px 4px rgba(51, 51, 51, 0.24)',
    minWidth: 106,
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  btn3: {
    height: '100%',
    minWidth: 106,
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  fullDialog: {
    zIndex: '1150!important',
    height: '100vh',
    // update: 2023/04/26
    '&.chance .MuiDialogContent-root': {
      overflow: 'hidden',
    },
  },
  number: { fontWeight: 700, fontSize: 40, lineHeight: 1 },
  parent: {
    display: 'grid',
    gridTemplateColumns: '1fr repeat(2, 0.5fr) 1fr',
    gridTemplateRows: 'repeat(3, 1fr) repeat(2, 0.62fr)',
    gridColumnGap: 12,
    gridRowGap: 12,
  },
  div1: { gridArea: '1 / 1 / 3 / 2' },
  div2: { gridArea: '1 / 2 / 2 / 4' },
  div3: { gridArea: '2 / 2 / 3 / 4' },
  div4: { gridArea: '1 / 4 / 3 / 5' },
  div5: { gridArea: '3 / 1 / 4 / 3' },
  div6: { gridArea: '3 / 3 / 4 / 5' },
  div7: { gridArea: '4 / 1 / 5 / 3' },
  div8: { gridArea: '4 / 3 / 5 / 5' },
  div9: { gridArea: '5 / 1 / 6 / 5' },
  iconBig: { fontSize: 40 },
  icon: { fontSize: 24 },
  iconSmall: { fontSize: 16, color: theme.palette.primaryColor },
  logoutbtn: {
    background: '#fff',
    border: 'none',
    borderRadius: 4,
    boxShadow: '0px 0px 4px rgba(51, 51, 51, 0.12), 0px 4px 4px rgba(51, 51, 51, 0.24)',
    minWidth: 106,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    '& span': {
      fontFamily: "'Roboto', 'Noto Sans JP', -apple-system, 'Ubuntu', 'Helvetica Neue', sans-serif",
      fontWeight: 700,
      fontSize: 14,
    },
  },
  colorWrapper: {
    borderRadius: 4,
    boxShadow: '0px 0px 4px rgba(51, 51, 51, 0.12), 0px 4px 4px rgba(51, 51, 51, 0.24)',
    background: '#fff',
    position: 'relative',
    textAlign: 'center',
  },
  selectOpen: {
    position: 'absolute',
    display: 'block',
  },
  selectClose: { display: 'none' },
  // logoutIcon: { position: 'absolute', left: '23%' },
}));
export default function MainMenuSp() {
  const common = commonStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const countState = useSelector((state) => state.commonGetHeaderNumber.commonGetHeaderNumber);

  // 本日
  const today = new Date();
  const dayOfWeek = today.getDay();
  const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeek];

  const [open, setOpen] = useState({
    register: false,
    easyregister: false,
    history: false,
    chance: false,
    dashboard: false,
    unsentmail: false,
    dashboardTab: 0,
    color: false,
  });

  const [cookies, setCookie] = useCookies();
  const [colorVal, setColorVal] = useState(cookies.color);

  const handleChange = (e) => {
    const val = e.target.value;
    setColorVal(val);
    setCookie('color', val);
    dispatch(getColorTheme(val));
    setOpen({ ...open, color: false });
  };

  const handleClose = () => {
    setOpen({ ...open, color: false });
  };

  const handleOpen = () => {
    setOpen({ ...open, color: true });
  };

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Typography className={common.title6}>本日</Typography>
        <Typography className={common.title1}>
          {today.getMonth() + 1} / {today.getDate()}
        </Typography>
        <Typography className={`${classes.week} ${common.title5}`}>{dayOfWeekStr}</Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <Button
          onClick={() => { return setOpen({ ...open, dashboard: true, dashboardTab: 0 }); }}
          className={classes.btn}
        >
          <Typography className={classes.number}>
            {countState.guidanceNumerator}
          </Typography>
          <Typography className={common.title4}>今週の案内実績</Typography>
        </Button>
        <Button
          className={classes.btn}
          onClick={() => { return setOpen({ ...open, dashboard: true, dashboardTab: 1 }); }}
        >
          <Typography className={classes.number}>
            {countState.direct}
          </Typography>
          <Typography className={common.title4}>指示(未完)</Typography>
        </Button>
        <Button
          className={classes.btn}
          onClick={() => { return setOpen({ ...open, dashboard: true, dashboardTab: 2 }); }}
        >
          <Typography className={classes.number}>
            {countState.count}
          </Typography>
          <Typography className={common.title4}>案内(実績未)</Typography>
        </Button>
      </Grid>
      <Grid container justifyContent="center" style={{ margin: '32px 0 16px' }}>
        <Typography className={common.title4}>メニュー</Typography>
      </Grid>
      <Grid className={classes.parent}>
        <Button
          className={`${classes.btn} ${classes.div1}`}
          onClick={() => { return setOpen({ ...open, easyregister: true }); }}
        >
          <MobileScreenShare className={classes.iconBig} />
          <Typography className={common.title4}>簡単登録</Typography>
        </Button>
        <Dialog
          open={open.easyregister}
          onClose={() => { return setOpen({ ...open, easyregister: false }); }}
          title="簡単登録"
        >
          <EasyRegister
            onClose={() => { return setOpen({ ...open, easyregister: false }); }}
          />
        </Dialog>
        <Button
          className={`${classes.btn} ${classes.div2}`}
          onClick={() => { return setOpen({ ...open, register: true }); }}
        >
          <PostAdd className={classes.iconBig} />
          <Typography className={common.title4}>源泉仮登録</Typography>
        </Button>
        <Dialog
          fullScreen
          open={open.register}
          onClose={() => { return setOpen({ ...open, register: false }); }}
          title="源泉仮登録"
        >
          <GensenRegister
            onClose={() => { return setOpen({ ...open, register: false }); }}
          />
        </Dialog>
        <Button
          className={`${classes.btn} ${classes.div3}`}
          onClick={() => { return setOpen({ ...open, history: true }); }}
        >
          <History className={classes.iconBig} />
          <Typography className={common.title4}>源泉登録履歴</Typography>
        </Button>
        <Dialog
          className={classes.fullDialog}
          fullScreen
          open={open.history}
          onClose={() => { return setOpen({ ...open, history: false }); }}
          title="源泉登録履歴"
        >
          <GensenRegisterHistory
            onClose={() => { return setOpen({ ...open, history: false }); }}
          />
        </Dialog>
        <Button
          className={`${classes.btn} ${classes.div4}`}
          onClick={() => { return setOpen({ ...open, chance: true }); }}
        >
          <TrackChanges className={classes.iconBig} />
          <Typography className={common.title4}>チャンス通知</Typography>
        </Button>
        <Dialog
          className={`${classes.fullDialog} chance`}
          fullScreen
          open={open.chance}
          onClose={() => { return setOpen({ ...open, chance: false }); }}
          title="チャンス通知"
          white
        >
          <ChanceNotificationContainer />
        </Dialog>
        <Button
          onClick={() => { return setOpen({ ...open, dashboard: true, dashboardTab: 3 }); }}
          className={`${classes.btn} ${classes.div5}`}
        >
          <DirectionsRun className={classes.icon} />
          <Typography className={common.title4}>直近<br />反響対応状況</Typography>
        </Button>
        <DashBoard
          open={open.dashboard}
          tab={open.dashboardTab}
          handleClose={() => { return setOpen({ ...open, dashboard: false }); }}
        />
        <Button
          onClick={() => { return setOpen({ ...open, unsentmail: true }); }}
          className={`${classes.btn} ${classes.div6}`}
        >
          <MailOutline className={classes.iconBig} />
          <Typography className={common.title4}>未送信メール</Typography>
        </Button>
        <UnsentMail
          open={open.unsentmail}
          onClose={() => { return setOpen({ ...open, unsentmail: false }); }}
        />
        <Button
          className={`${classes.btn2} ${classes.div7}`}
          target="_blank"
          href="https://sites.google.com/openhouse-group.com/new-sfa-manual/home"
        >
          <HelpOutline className={classes.icon} />
          <Typography className={common.title4}>マニュアル</Typography>
          <OpenInNew className={classes.iconSmall} />
        </Button>
        <Grid
          className={`${classes.colorWrapper} ${classes.div8}`}
        >
          <Button className={`${classes.btn3}`} onClick={handleOpen}>
            カラー変更
          </Button>
          <FormControl className={open.color ? classes.selectOpen : classes.selectClose}>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open.color}
              onClose={handleClose}
              onOpen={handleOpen}
              value={colorVal}
              onChange={handleChange}
            >
              {Object.keys(COLOR_THEME).map((key) => {
                return (
                  <MenuItem
                    key={key}
                    value={key}
                  >
                    {COLOR_THEME[key]}
                    <span style={{ color: COLOR_CODE[key] }}>■</span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <LogoutButton className={`${classes.logoutbtn} ${classes.div9}`} />
      </Grid>
    </Grid>
  );
}
