import LibraryCreateContainer from '../../../containers/mailLibrary/libraryCreateContainer';

export default function LibraryCreate(props) {
  const {
    open,
    onClose,
    selectDir,
    reloadLibraries,
    initialMailTitle,
    initialMailBody,
  } = props;

  if (!open.create) return null;
  return (
    <LibraryCreateContainer
      open={open.create}
      edit={open.edit}
      onClose={onClose}
      selectDir={selectDir}
      reloadLibraries={reloadLibraries}
      initialMailTitle={initialMailTitle}
      initialMailBody={initialMailBody}
    />
  );
}
