import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import TaiouRirekiItems from '../parts/taiouRirekiItems';
import TaiouRirekiItemsSp from '../parts/taiouRirekiItemsSp';
import Modal from '../../modal';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function TaiouRirekiList(props) {
  const {
    open,
    onClose,
    customerId,
    customer,
    getActionHistoryList,
    updateHistory,
    deleteHistory,
    downloadAttachment,
    search,
    setSearch,
    rerenderKeySearch,
    rerenderSearch,
  } = props;

  const historyList = useSelector((state) => state.actCreateCustomHistory.actCreateCustomHistory);

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const onCloseBg = () => {
    onClose();
  };

  useEffect(() => {
    open && getActionHistoryList(customerId, search);
  }, [open]);
  if (isSP) {
    contents = (
      <TaiouRirekiItemsSp
        state={historyList}
        getActionHistoryList={getActionHistoryList}
        downloadAttachment={downloadAttachment}
      />
    );
  } else {
    contents = (
      <TaiouRirekiItems
        key={rerenderKeySearch}
        state={historyList}
        getActionHistoryList={getActionHistoryList}
        updateHistory={updateHistory}
        deleteHistory={deleteHistory}
        downloadAttachment={downloadAttachment}
        search={search}
        setSearch={setSearch}
        customerId={customerId}
        customer={customer}
        rerenderSearch={rerenderSearch}
      />
    );
  }

  return (
    <Modal
      open={open}
      onClose={onCloseBg}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      title={['対応履歴一覧ウィンドウ', '対応履歴一覧']}
      maxWidth={800}
    >
      {historyList && contents}
    </Modal>
  );
}

export default TaiouRirekiList;
