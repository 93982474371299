import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import PersonalGoal from '../../../containers/user/personalGoal';
import OrgSetting from '../orgSetting';
import modifyObject from '../../../commonFunction/modifyObject';
import { USER_STATUS_DIVISION } from '../../../constants/index';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 8,
    position: 'static',
    width: '265.5px',
    left: '0px',
    top: '0px',
    background: '#FFFFFF',
    border: '1px solid #C8C8C8',
    boxSizing: 'border-box',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    margin: 4,
  },
  customHeight: {
    height: 'auto',
  },
  customTitle3: {
    position: 'static',
    width: '100%',
    height: '20px',
    left: '8px',
    top: '8px',
    display: 'flex',
    'align-items': 'center',
    /* OH-Black */
    color: '#333333',
    flex: 'none',
    order: 0,
    'flex-grow': 0,
    margin: '20px 4px 0',
  },
  customButtonSpan: {
    color: '#ffffff',
    position: 'static',
    left: '32%',
    right: '16%',
    top: '13.79%',
    bottom: '13.79%',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
}));

function InputFormOrg(props) {
  const {
    getComponent,
    state,
    setState,
    userListFunction,
    userDivListFunction,
    createMode,
    updateUserFunction,
    deleteUserDivision,
    updateDivisionFunction,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const handleChange = (e) => {
    setState({ ...state, userTypeCode: e.target.value });
  };

  const field = [
    {
      title: '社員区分',
      required: true,
      key: 'userTypeCode',
      type: 'flg',
      state: state.userTypeCode,
      set: handleChange,
      obj: modifyObject(USER_STATUS_DIVISION, '社員区分'),
    },
  ];

  const getOtherComponent = () => {
    if (!createMode) {
      return (
        <>
          <OrgSetting
            state={state}
            setState={setState}
            userListFunction={userListFunction}
            userDivListFunction={userDivListFunction}
            updateUserFunction={updateUserFunction}
            deleteUserDivision={deleteUserDivision}
            updateDivisionFunction={updateDivisionFunction}
          />
          {state.divisions.length > 0 ? (
            <PersonalGoal
              state={state}
            />
          ) : null}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Typography
        className={`${baseClasses.title3} ${classes.customTitle3}`}
      >
        所属組織情報
      </Typography>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Grid container alignItems="center">
          {field.map((data) => (
            <Grid
              item
              key={data.key}
              className={`${classes.root} ${classes.customHeight} ${baseClasses.inputWrap}`}
            >
              {getComponent(data)}
            </Grid>
          ))}
          {getOtherComponent()}
        </Grid>
      </Grid>
    </>
  );
}
export default React.memo(InputFormOrg);
