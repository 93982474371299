import { useState } from 'react';

import {
  Button,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import commonStyles from '../styles';

import {
  PRIVACY_POLICY_PAGE,
  TERMS_OF_SERVICE_PAGE,
  LASTA_STAFF_URL,
  SMS_LAST_TEMPLATE,
} from '../../constants/easyRegister';

const useStyles = makeStyles((theme) => ({
  root: { padding: 16 },
  title: { textAlign: 'center' },
  closeIcon: {
    color: theme.palette.primaryColor,
    position: 'absolute',
    right: 10,
    top: 13,
  },
  txt: {
    fontSize: 13,
    textAlign: 'center',
    marginTop: 20,
  },
  icon: { fontSize: 16, verticalAlign: 'sub' },
  btn: { margin: '20px auto', display: 'block' },
  a: {
    color: '#fff',
    textDecoration: 'none',
  },
}));
export default function EasyRegisterSend(props) {
  const { userId } = props;
  const common = commonStyles();
  const classes = useStyles();
  const [txt, setTxt] = useState('');

  const escapeNewLine = (s) => {
    return s.replace(/\r?\n/g, '%0D%0A');
  };

  const smsBody = escapeNewLine(`${SMS_LAST_TEMPLATE}${LASTA_STAFF_URL}?staff_id=${userId}&link_id=stafftopbtn`);
  const smsHref = `sms:${txt};?&body=${smsBody}`;

  return (
    <Grid className={classes.root}>
      <TextField
        inputProps={{
          type: 'number',
          maxlength: 11,
        }}
        value={txt}
        onChange={(e) => {
          return setTxt(e.target.value);
        }}
        fullWidth
      />
      <Typography className={classes.txt}>
        <Link href={TERMS_OF_SERVICE_PAGE} target="_blank">
          利用規約
          <Launch className={classes.icon} />
        </Link>
        及び
        <Link href={PRIVACY_POLICY_PAGE} target="_blank">
          個人情報保護方針
          <Launch className={classes.icon} />
        </Link>
        に
      </Typography>
      <Button className={`${common.buttonsPrimary} ${classes.btn}`}>
        <a className={classes.a} href={smsHref}>同意して送信</a>
      </Button>
    </Grid>
  );
}
