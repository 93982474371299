import ContactTimeContainers from '../../../containers/common/contactTime/contactTimeContainers';

function ContactTime(props) {
  const {
    open,
    onClose,
    customerId,
  } = props;
  return <ContactTimeContainers open={open} onClose={onClose} customerId={customerId} />;
}

export default ContactTime;
