import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from 'react-redux';
import commonStyles from '../../../styles';
// import CustomAlertMessage from '../../eleCommon/customAlertMessage';
import { changeAlertMessage } from '../../../../store/eleCommon/customAlertMessage';
import UserTree from '../../../common/userTreeNew';
import EmailSelectListDrawer from './emailSelectListDrawer';
import { dateFormatter } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '447px',
    margin: 'auto',
    justifyContent: 'center',
    background: '#F3F3F3',
  },
  title: {
    display: 'flex',
    '& span': { color: '#D83420' },
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    padding: '32px 24px 24px',
    justifyContent: 'space-between',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
  },
  middle: {
    padding: '32px',
    '& .MuiFormControl-root': { width: '100%' },
  },
  blue: {
    color: theme.palette.primaryColor,
    marginRight: '16px',
  },
  select: {
    // minWidth: '160px',
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
    },
    '& .MuiSelect-icon': {
      width: '17px',
    },
  },
  itemWrap: {
    borderBottom: '1px solid #F3F3F3',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  item: {
    padding: '16px',
    width: '100%',
    borderRadius: 0,
  },
  accordion: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiAccordion-rounded:first-child, & .MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  accordionBtn: {
    pointerEvents: 'all',
    padding: 0,
    '& > .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& > .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  accordionDetails: {
    display: 'block',
  },
  disable: {
    pointerEvents: 'none',
    '& *': { pointerEvents: 'none' },
  },
  text: {
    marginRight: 'auto',
    marginLeft: '32px',
    textAlign: 'left',
  },
  toggleButton: {
    padding: 0,
    textAlign: 'left',
    display: 'block',
    minWidth: 10,
    fill: theme.palette.primaryColor,
  },
  listitem: {
    width: '100%',
    padding: '8px 4px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginRight: 0,
  },
}));

export default function ChangeToSp(props) {
  const {
    values,
    user,
    allMailFlg = false,
    updateFunction,
    disable = false,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const common = commonStyles();
  const [state, setState] = useState(values.check);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // to
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closeandchange = () => {
    const sendData = () => {
      const list = Object.keys(state).filter(s => {
        return state[s] === true;
      });
      let result = '';
      for (let j = 0; j < list.length; j += 1) {
        if (j !== 0) { result += ', '; }
        result += user[list[j]];
      }
      return result;
    };
    if (sendData().length === 0) {
      values.setList([]);

      dispatch(changeAlertMessage({ msgList: ['・送信先のメールアドレスを１つ以上チェックしてください。'] }));
    } else {
      const param = {
        updateType: 1,
        isMailSend1: state.mail1 ? 1 : 0,
        isMailSend2: state.mail2 ? 1 : 0,
        isMailSend3: state.mail3 ? 1 : 0,
        isMailSend4: state.mail4 ? 1 : 0,
        isNotCheckUpdate: 0,
        getDataAt: dateFormatter(new Date()),
      };
      updateFunction(user.customerId, param);
      values.setCheck(state);
      values.setList(sendData());
      handleClose();
    }
  };

  // CC,BCC
  const [modal, setModal] = useState({
    open: false,
    type: '',
  });
  const [ccUsers, setCcUsers] = useState([]);
  const [bccUsers, setBccUsers] = useState([]);
  const modalOpen = (type) => {
    setModal({
      open: true,
      type,
    });
  };

  useEffect(() => {
    setState(values.check);
  }, [values]);

  return (
    <Grid container className={`${classes.itemWrap} ${disable ? classes.disable : ''}`} style={values.style}>
      <Accordion className={classes.accordion} square>
        <AccordionSummary
          className={classes.accordionBtn}
          expandIcon={<ExpandMoreIcon className={classes.toggleButton} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Button
            className={`${classes.item} ${allMailFlg ? classes.disable : null}`}
            onClick={handleOpen}
          >
            <Typography className={`${common.title4} ${classes.title}`}>{values.label}<span>＊</span></Typography>
            <Typography className={`${common.small} ${classes.text}`}>{values.list}</Typography>
          </Button>
        </AccordionSummary>
        <AccordionDetails className={`${classes.accordionDetails} ${classes.accordionBtn}`}>
          <Button
            className={`${classes.item}`}
            onClick={() => { return modalOpen('cc'); }}
          >
            <Typography className={common.title4}>CC</Typography>
            <Typography className={`${common.small} ${classes.text}`}>{ccUsers.map(c => c.userName)}</Typography>
          </Button>
          <Button
            className={`${classes.item}`}
            onClick={() => { return modalOpen('bcc'); }}
          >
            <Typography className={common.title4}>BCC</Typography>
            <Typography className={`${common.small} ${classes.text}`}>{bccUsers.map(c => c.userName)}</Typography>
          </Button>
          <UserTree
            isOpenTree={modal.open}
            setIsOpenTree={(bool) => setModal((s) => ({ ...s, open: bool }))}
            selectUser={modal.type === 'cc' ? ccUsers : bccUsers}
            setSelectUser={modal.type === 'cc' ? setCcUsers : setBccUsers}
            isMulti
            isDialog
          />
        </AccordionDetails>
      </Accordion>
      {/* メール送信先選択 モーダル */}
      {!allMailFlg ? (
        <EmailSelectListDrawer
          initial={state}
          user={user}
          open={open}
          handleClose={handleClose}
          handleChange={handleChange}
          okButton={
            <Button
              className={common.buttonsPrimary}
              onClick={closeandchange}
            >
              OK
            </Button>
          }
        />
      ) : null}
    </Grid>
  );
}
