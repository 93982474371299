import React from 'react';
import { InputLabel, FormControl, makeStyles } from '@material-ui/core';
import CustomSidebarSelect from '../../eleCommon/customSidebarSelect';
import NormalTooltip from '../../styles/tooltip';
import SortSelectMain from './parts/sortSelectMain';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  inputControl: {
    paddingTop: 16,
    height: 32,
    '&> label + .MuiInput-formControl': {
      marginTop: 0,
      height: 32,
    },
    '&.wishArea .MuiSvgIcon-root': {
      display: 'none',
    },
    '&.stationFrom .MuiSvgIcon-root': {
      display: 'none',
    },
    '&.stationTo .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      paddingBottom: 0,
      paddingTop: 0,
      height: 32,
    },
  },
  InputLabel: {
    fontSize: 13,
    top: '17%',
    color: '#333',
    fontWeight: 500,
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 7px) scale(0.85)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  width_100: {
    width: '100%',
  },
  inputWrap: {
    'margin-top': 0,
  },
  centerMark: {
    display: 'flex',
    'justify-content': 'flex-end',
    'align-items': 'center',
    '&>p': {
      display: 'inline-block',
    },
  },
}));

function SortSelect() {
  const classes = useStyles();

  const getComponent = (data) => {
    switch (data.type) {
      case 'select':
        return (
          <NormalTooltip title={data.tooltip}>
            <FormControl
              fullWidth
              className={`${classes.inputControl} ${data.key}`}
            >
              <InputLabel className={classes.InputLabel}>
                {data.title}
              </InputLabel>
              <CustomSidebarSelect initial={data} />
            </FormControl>
          </NormalTooltip>
        );
      default:
        return <div>対応しない要素です</div>;
    }
  };

  return (
    <SortSelectMain
      className={classes.root}
      getComponent={getComponent}
    />
  );
}

export default SortSelect;
