import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';

import store from '../../../../store';

import UserTree from '../../userTreeNew';

import InfoChangeHistoryCalendar from './infoChangeHistoryCalendar';
import InfoChangeHistoryButton from './infoChangeHistoryButton';
import InfoChangeHistoryText from './infoChangeHistoryText';
import ItemWindow from './itemWindow';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 8px',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '-12px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
    '&[data-width="30"]': {
      width: '30%',
    },
    '&[data-width="46"]': {
      width: '46%',
    },
    '&[data-width="100"]': {
      width: '100%',
    },
    '&:first-child': {
      marginBottom: 11,
    },
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  reset: {
    color: theme.palette.primaryColor,
    fontSize: 15,
    fontWeight: '700',
    marginRight: 20,
  },
}));

function InfoChangeHistorySearch(props) {
  const { search, setSearch, setSearch2, searchFunc, reset } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル開閉用のローカルステート
  const [isOpenItem, setIsOpenItem] = useState(false);
  const [isOpenTree, setIsOpenTree] = useState(false);

  const infoChangerRef = useRef({ ...search.infoChanger });

  const searchStart = () => {
    searchFunc();
  };

  const box = {
    items: [
      {
        type: 'calendar',
        key: 'infoMultiple',
        width: 100,
        input: {
          label: '変更期間',
          state: { endAt: search.infoMultiple.endAt, startAt: search.infoMultiple.startAt },
          setState: (start, end) => { return setSearch2(start, end); },
        },
      },
      {
        type: 'button',
        key: 'infoItem',
        width: 46,
        input: {
          label: '項目',
          state: '',
          setState: () => {},
          click: () => setIsOpenItem(true),
        },
      },
      {
        type: 'button',
        key: 'infoChanger',
        width: 46,
        input: {
          label: '変更者',
          state: search.infoChanger.userName,
          setState: () => {},
          click: () => setIsOpenTree(true),
        },
      },
      {
        type: 'text',
        key: 'noneContactKeyword',
        width: 100,
        input: {
          label: '',
          place: 'キーワード',
          state: search.infoKeyword,
          setState: (e) => { return setSearch('infoKeyword', e); },
        },
      },
    ],
  };

  const setInfoItem = (val) => {
    setSearch('infoItem', val);
  };
  const setInfoChanger = (val) => {
    setSearch('infoChanger', val);
    infoChangerRef.current = val;
  };

  const switchType = (val) => {
    const returnType = [];
    switch (val.type) {
      case 'calendar':
        return <InfoChangeHistoryCalendar values={val.input} />;
      case 'button':
        return <InfoChangeHistoryButton values={val.input} />;
      case 'text':
        return <InfoChangeHistoryText values={val.input} />;
      default:
        break;
    }
    return returnType;
  };

  const items = () => {
    const itemchild = [];
    box.items.map(i => itemchild.push(
      <Grid
        item
        key={i.key}
        className={classes.item}
        data-width={i.width}
      >
        {switchType(i)}
      </Grid>,
    ));
    return itemchild;
  };

  useEffect(() => {
    const { responseHeader } = store.getState();
    const { userId, userName } = responseHeader;
    infoChangerRef.current = { userId, userName };
  }, []);

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title3}>検索</Typography>
      <Grid item className={classes.list}>
        {items()}
        <ItemWindow
          open={isOpenItem}
          onClose={() => setIsOpenItem(false)}
          values={search.infoItem}
          setValues={setInfoItem}
        />
        <UserTree
          isOpenTree={isOpenTree}
          setIsOpenTree={setIsOpenTree}
          selectUser={infoChangerRef.current}
          setSelectUser={setInfoChanger}
          selectDivision={[]}
          setSelectDivision={() => {}}
          handleClearFunction={() => setSearch('infoChanger', { userId: null, userName: '' })}
          isDialog
          isSelectUserWindow
        />
      </Grid>
      <Grid item className={classes.btn}>
        <Button className={classes.reset} onClick={reset} type="reset">
          リセット
        </Button>
        <Button className={baseClasses.buttonsPrimary} onClick={searchStart}>
          検索
        </Button>
      </Grid>
    </Grid>
  );
}

export default InfoChangeHistorySearch;
