import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import ReshouseTableRow from './reshouseTableRow';

const useStyles = makeStyles((theme) => ({
  resHouseRow: {
    position: 'relative',
    transition: 'all 0.5s ease important',
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .deleteButton': {
        opacity: 1,
      },
    },
    '&:nth-of-type(odd)': {
      backgroundColor: '#F3F3F3',
    },
  },
  tableContainer: {
    overflowY: 'auto',
    maxHeight: 640,
  },
  tableCell: {
    padding: 0,
    textAlign: 'center',
    lineHeight: 1,
  },
  tableHeader: {
    color: '#8C8C8C',
    padding: '8px',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& ul': {
      display: 'flex',
      paddingLeft: 0,
      '& li': {
        listStyle: 'none',
        marginRight: '7px',
      },
    },
  },
  openInNewIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.primaryColor,
  },
  iconFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  adjustPadding: {
    padding: '14px 0 0 15px',
  },
  deleteButton: {
    width: '66px',
    height: '28px',
    position: 'absolute',
    right: '10px',
    top: 0,
    bottom: 0,
    margin: 'auto 0',
    backgroundColor: '#fff',
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .2)',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s ease',
    opacity: 0,
    '&:hover': {
      opacity: 0,
      background: '#fff',
    },
  },
  deleteButtonTxt: {
    color: theme.palette.primaryColor,
  },
  deleteButtonIcon: {
    color: theme.palette.primaryColor,
  },
  link: {
    color: theme.palette.primaryColor,
    marginRight: '3px',
  },
  txt: {
    fontSize: 15,
    lineHeight: '160px',
    letterSpacing: 0,
    textAlign: 'center',
  },
}));

function ResHousetable(props) {
  const { data, deleteAndUpdateHouseList } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        {!isSP && (
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.tableHeader} ${baseClasses.title4}`}>日時</TableCell>
              <TableCell className={`${classes.tableHeader} ${baseClasses.title4}`}>物件名/反響媒体</TableCell>
              <TableCell className={`${classes.tableHeader} ${baseClasses.title4}`}>区画</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody className={classes.tableBody}>
          {!data.length && (
            <TableRow>
              <TableCell colSpan={3} style={{ borderBottom: 'none' }}>
                <Typography className={classes.txt}>
                  対象が存在しません。
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {data.map((row, index) => {
            return (
              <ReshouseTableRow
                row={row}
                productName={row.productName}
                inquiredAt={row.inquiredAt}
                key={row.buildingNumber}
                index={index}
                deleteAndUpdateHouseList={deleteAndUpdateHouseList}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ResHousetable;
