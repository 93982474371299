import React from 'react';
import DivisionInformationTableContainer from '../../containers/divisionInformation/divisionInformationTable';
import DivisionInformationLayout from './parts/divisionInformationLayout';

export default function DivisionInformationComponent(props) {
  const {
    selectedAreaCode,
    onChangeAreaTab,
  } = props;

  return (
    <DivisionInformationLayout
      title="店舗間顧客移動設定"
      selectedAreaCode={selectedAreaCode}
      onChangeAreaTab={onChangeAreaTab}
    >
      <DivisionInformationTableContainer
        selectedAreaCode={selectedAreaCode}
      />
    </DivisionInformationLayout>
  );
}
