import ClaimRegisterContainers from '../../../containers/common/claimRegister/claimRegisterContainers';

/**
 * ClaimRegisterモダール
 * @param {{
 * open: boolean;
 * onClose: (isChanged: boolean) => void;
 * customerId: number;
 * isEdit?: boolean;
 * setStatus?: () => void;
 * responsibleUserId: number;
 * }} props
 * @returns {void}
 */
function ClaimRegister(props) {
  const {
    open,
    onClose,
    isEdit,
    setStatus,
    responsibleUserId = null, // 担当者ID
    customerId, // 顧客ID
  } = props;
  return (
    <div>
      <ClaimRegisterContainers
        open={open}
        onClose={onClose}
        isEdit={isEdit}
        setStatus={setStatus}
        responsibleUserId={responsibleUserId}
        customerId={customerId}
      />
    </div>
  );
}

export default ClaimRegister;
