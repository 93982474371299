import client from '../httpClient';
// import { FULLSCREEN_LOADING } from '../../constants/loading';

const libraryUpdateApi = (params, putData) => {
  return client.put(`/libraries/management/${params}`, putData, {
    isShowMessage: true,
    isShowLoading: false,
  });
};

export default libraryUpdateApi;
