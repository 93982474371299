import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import localStyles from './localStyle';
import commonStyles from '../../../styles';
import CalendarLabel from './CalendarLabel';

const useStyles = makeStyles({
  wrap: {
    alignItems: 'center',
    padding: '3px',
    width: '40%',
    borderBottom: '1px solid #cfcfcf',
    margin: '0 auto',
  },
  validWrap: {
    alignItems: 'center',
    padding: '3px',
    width: '40%',
    borderBottom: '2px solid #f44336',
  },
  wrapMultiParallel: {
    alignItems: 'center',
    padding: '0px',
  },
  border: {
    border: '1px solid #C8C8C8',
  },
  id_userCalendarLabel: {
    '& > h6': {
      width: '100%',
    },
  },
  span: {
    display: 'flex',
    alignItems: 'center',
  },
});

function CalendarInputMultiDateUser(props) {
  const { state, id, setDate, label, require, type, valid, readonly, convertDateTimeToIso } = props;
  const classes = useStyles();
  const style = localStyles();
  const common = commonStyles();

  // handleChange
  const valChange = (e) => {
    const StartInput = document.getElementById(`${id}t1`);
    const EndInput = document.getElementById(`${id}t2`);
    let startValue = StartInput.value.replace('T', ' ');
    let endValue = EndInput.value.replace('T', ' ');
    const startOrEnd = e.target === EndInput;

    if (e.target === StartInput) {
      EndInput.setAttribute('min', startValue);
      if (StartInput.validity.rangeOverflow) {
        StartInput.value = '無効な時間です';
        startValue = '';
      } else {
        startValue = startValue.replace(/-/g, '/').replace(/T/g, ' ');
      }
    }

    if (e.target === EndInput) {
      StartInput.setAttribute('max', endValue);
      if (EndInput.validity.rangeUnderflow) {
        EndInput.value = '無効な時間です';
        endValue = '';
      } else {
        endValue = endValue.replace(/-/g, '/').replace(/T/g, ' ');
      }
    }

    startOrEnd
      ? setDate.setEnd(endValue.replaceAll('-', '/'))
      : setDate.setFirst(startValue.replaceAll('-', '/'));
  };

  const onClick = (e, dateVal) => {
    const d = new Date(dateVal);
    e.target.value = convertDateTimeToIso(d);
  };

  return (
    <Grid container>
      <CalendarLabel
        id="id_userCalendarLabel"
        txt={label[0]}
        require={require}
      />
      <Grid item style={{ width: '100%' }} />
      <Grid
        item
        className={
          (valid && !document.getElementById(`${id}t1`))
          || (valid && !state.startAt)
            ? classes.validWrap
            : classes.wrap
        }
      >
        <label
          className={style.label}
          htmlFor={`${id}t1`}
          style={{ width: '100%' }}
        >
          <span className={`${common.strong} ${style.txt}`}>
            {state.startAt}
          </span>
          <input
            className={style.input}
            require={require.toString()}
            id={`${id}t1`}
            type={type}
            onChange={(e) => {
              valChange(e);
            }}
            onClick={(e) => onClick(e, state.startAt)}
            readOnly={readonly ? 'readOnly' : ''}
          />
        </label>
      </Grid>
      <span className={classes.span}>～</span>
      <Grid
        item
        className={
          (valid && !document.getElementById(`${id}t2`))
          || (valid && !state.endAt)
            ? classes.validWrap
            : classes.wrap
        }
      >
        <label
          className={style.label}
          htmlFor={`${id}t2`}
          style={{ width: '100%' }}
        >
          <span className={`${common.strong} ${style.txt}`}>{state.endAt}</span>
          <input
            className={style.input}
            require={require.toString()}
            id={`${id}t2`}
            type={type}
            onChange={valChange}
            onClick={(e) => onClick(e, state.endAt)}
            readOnly={readonly ? 'readOnly' : ''}
          />
        </label>
      </Grid>
    </Grid>
  );
}

export default CalendarInputMultiDateUser;
