import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../styles';
import SimulatorTabs from './parts/simulatorTabs';
import SimulatorCheckFile from './parts/simulatorCheckFile';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  headerWrapper: {
    alignItems: 'center',
    background: '#fff',
  },
  header: {
    maxWidth: 1200,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
  },
  headerTtl: {
    padding: '16px 16px 8px',
  },
  content: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function AllocationSetting(props) {
  const {
    areas,
    clickAreaTabFunc,
    cities,
    storesStores,
    setStoresStores,
    storeScores,
    setStoreScores,
    judgementStart,
    downloadFunc,
    uploadFunc,
    downloadReusultFunc,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.headerWrapper}>
        <Grid className={classes.header}>
          <Typography className={`${baseClasses.title2} ${classes.headerTtl}`}>
            反響自動処理シミュレーション
          </Typography>
          <SimulatorCheckFile downloadFunc={downloadReusultFunc} />
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <SimulatorTabs
          areas={areas}
          clickAreaTabFunc={clickAreaTabFunc}
          cities={cities}
          storesStores={storesStores}
          setStoresStores={setStoresStores}
          storeScores={storeScores}
          setStoreScores={setStoreScores}
          judgementStart={judgementStart}
          downloadFunc={downloadFunc}
          uploadFunc={uploadFunc}
        />
      </Grid>
    </Grid>
  );
}

export default AllocationSetting;
