import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, IconButton, InputAdornment, makeStyles, TablePagination, Typography, useTheme } from '@material-ui/core';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Refresh } from '@material-ui/icons';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import commonStyles from '../styles';
import AddFiles from './parts/addFiles';
import FileTable from './parts/fileTable';
import { useLoading } from '../../hooks';
import { FILE_STORAGE } from '../../constants/loading';
import { TextBaseField } from '../eleCommon/validation';
import { validateFormString1 } from '../../commonFunction/validations';

const useStyles = makeStyles((theme) => ({
  root: { width: '1160px' },
  header: {
    padding: '24px 24px 8px',
    borderBottom: '1px #C8C8C8 solid',
  },
  refresh: {
    color: theme.palette.primaryColor,
    margin: '0 8px',
    width: '3.5rem',
    '& .MuiSvgIcon-root': {
      width: '16px',
    },
  },
  checkedWrap: {
    borderLeft: '1px #c8c8c8 solid',
    paddingLeft: '16px',
    marginLeft: '16px',
  },
  checkedBtn: {
    margin: '0 8px',
    '&.Mui-disabled': {
      background: 'rgba(255, 255, 255, .2)',
      '&>span': { color: 'rgba(0, 0, 0, 0.26)' },
    },
  },
  pagenationBtns: {
    width: 'fit-content',
    marginLeft: '1rem',
  },
  tablePaginationHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tablePaginationHeaderContent: {
    display: 'flex',
  },
  pagenation: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiTablePagination-selectRoot': { display: 'none' },
    '& .MuiTablePagination-toolbar': {
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'flex-end',
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
    },
  },
  paginationTypogragh: {
    fontSize: '14px',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  DialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: '1.2rem',
  },
  container: {
    width: '515px',
    height: '130px',
    background: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    padding: '8px 0',
    boxSizing: 'border-box',
  },
  dialogButton: {
    color: theme.palette.primaryColor,
  },
  textInput: {
    padding: '5px',
    fontSize: '13px',
    width: '100%',
    '&.MuiSelect-select:focus': { background: 'none' },
  },
  iconAdornment: {
    cursor: 'pointer',
    padding: '0px',
    '&::before': { borderBottom: '1px solid rgba(0, 0, 0, 0)' },
  },
  searchIcon: {
    height: '1rem',
  },
  textArea: {
    margin: '0px',
    backgroundColor: '#FFFFFF',
    border: '0px solid #FFFFFF',
    width: '100%',
  },
}));

function Checked(props) {
  const { checkedList, setCheckedList, updateFiles } = props;
  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();

  const fileStorages = useSelector((state) => state.filestorageGet.filestorageGet);
  const targetFileStorages = fileStorages.fileStorages.filter((fileStorage) => {
    return checkedList.includes(fileStorage.shareFileStorageAttachmentId);
  });

  const turnOffNotification = () => {
    const updateParam = targetFileStorages.map((fileStorage) => ({
      shareFileStorageAttachmentId: fileStorage.shareFileStorageAttachmentId,
      isNotification: 0, // FIXME: magic number
    }));
    updateFiles({ fileStorages: updateParam });
    setCheckedList([]);
  };

  const onDelete = () => {
    const deleteParam = targetFileStorages.map((fileStorage) => ({
      shareFileStorageAttachmentId: fileStorage.shareFileStorageAttachmentId,
      deletedReasonCode: 2, // FIXME: magic number
    }));
    updateFiles({ fileStorages: deleteParam });
    setCheckedList([]);
  };
  const onClickDelete = () => {
    dispatch(changeConfirmMessage({
      msgList: ['削除されます'], // 必須
      title: '削除しますか？', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'CANCEL', // ボタンラベル
        set: () => { return false; }, // ボタンをクリックしたときの処理
        classes: common.buttonsSecondary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }, {
        label: 'OK', // ボタンラベル
        set: onDelete, // ボタンをクリックしたときの処理
        classes: common.buttonsPrimary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
    }));
  };

  return (
    <Grid container alignItems="center" className={classes.checkedWrap}>
      <Typography className={classes.paginationTypogragh}>チェックしたファイルの</Typography>
      <Button
        className={`${common.buttonsSecondary} ${classes.checkedBtn}`}
        onClick={onClickDelete}
        disabled={!checkedList.length}
      >
        削除
      </Button>
      <Button
        className={`${common.buttonsSecondary} ${classes.checkedBtn}`}
        onClick={turnOffNotification}
        disabled={!checkedList.length}
      >
        DL通知をOFF
      </Button>
    </Grid>
  );
}
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);

  const handleBackButtonClick = (event) => onPageChange(event, page - 1);

  const handleNextButtonClick = (event) => onPageChange(event, page + 1);

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.pagenationBtns}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function FileStrageComponent(props) {
  const {
    getFileList,
    registerFiles,
    downloadFiles,
    updateFiles,
    limitOffset,
    setLimitOffset,
  } = props;
  const classes = useStyles();
  const common = commonStyles();

  const [checkedList, setCheckedList] = useState([]);
  const data = useSelector((state) => state.filestorageGet.filestorageGet); // getした値が入る

  const { hasLoading } = useLoading();

  // local state
  const page = parseInt(limitOffset.offset / limitOffset.limit, 10);

  const handleChangePage = (_, newPage) => {
    setLimitOffset((s) => {
      const newState = {
        limit: s.limit,
        offset: newPage * s.limit,
      };
      getFileList(newState);
      return newState;
    });
  };

  const refresh = () => getFileList();

  const handleSelectAllClick = (e) => {
    const checkedListState = !e.target.checked
      ? []
      : data.fileStorages.map((n) => n.shareFileStorageAttachmentId);
    setCheckedList(checkedListState);
  };

  const handleClickIcon = () => {
    getFileList({
      offset: limitOffset.offset,
      limit: limitOffset.limit,
      keyword: limitOffset.keyword,
    });
  };
  const onBlur = (e) => {
    setLimitOffset({ ...limitOffset, keyword: e.target.value });
  };
  const onKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    getFileList({
      offset: limitOffset.offset,
      limit: limitOffset.limit,
      keyword: e.target.value,
    });
    e.target.blur(e);
  };
  const searchCell = () => {
    return (
      <TextBaseField
        placeholder="ファイル名検索(部分一致)"
        className={classes.textArea}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        margin="none"
        InputProps={{
          classes: {
            input: classes.textInput,
            adornedStart: classes.iconAdornment,
          },
          startAdornment: (
            <InputAdornment position="start" onClick={handleClickIcon}>
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
        size="small"
        validator={validateFormString1({
          maxLengthInt: 50,
        })}
      />
    );
  };

  useEffect(() => {
    getFileList();
  }, []);

  return (
    <Grid className={hasLoading(FILE_STORAGE) ? `${classes.body} ${common.loading}` : classes.body}>
      <Grid container alignItems="center">
        <AddFiles registerFiles={registerFiles} />
      </Grid>
      <Grid container className={classes.tablePaginationHeader}>
        <Grid className={classes.tablePaginationHeaderContent}>
          <Button
            className={classes.refresh}
            onClick={refresh}
          >
            <Refresh />
            <Typography className={common.smallStrong}>更新</Typography>
          </Button>
          <Checked
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            updateFiles={updateFiles}
          />
        </Grid>
        <TablePagination
          className={classes.pagenation}
          count={data.total}
          rowsPerPage="10"
          page={page}
          onPageChange={handleChangePage}
          component="div"
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          ActionsComponent={TablePaginationActions}
        />
      </Grid>
      <FileTable
        list={data.fileStorages}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        handleSelectAllClick={handleSelectAllClick}
        updateFiles={updateFiles}
        downloadFiles={downloadFiles}
        searchCell={searchCell}
      />
    </Grid>
  );
}
