/**
 * 【webRes009】顧客情報と顧客情報をマージした後の顧客情報更新
 * http://10.22.2.48:8080/docs/#/responses/post-webRes-update-custToCust
 * ※キーの順位はAPIドキュメント通り
 */
// eslint-disable-next-line import/prefer-default-export
export const ctcParams = {
  // 顧客ID（名寄せによって削除する顧客情報）
  deleteCustomerId: null, // * number
  // 顧客ID（名寄せによって更新される顧客情報）
  updateCustomerId: null, // number // *
  // 姓
  lastName: null, // string
  // 名
  firstName: null, // string
  // カタカナ姓
  lastNameKana: null, // string
  // カタカナ名
  firstNameKana: null, // string
  // 性別
  genderCode: null, // number
  // 国籍
  nationality: null, // string
  // 永住権
  isPermanentResidency: null, // number
  // 年齢
  age: null, // number
  // 誕生日
  birthday: null, // string // birthDay
  // 副・姓
  subLastName: null, // string
  // 副・名
  subFirstName: null, // string
  // カタカナ副・姓
  subLastNameKana: null, // string
  // カタカナ副・名
  subFirstNameKana: null, // string
  // 副・性別
  subGenderCode: null, // number
  // 副・国籍
  subNationality: null, // string
  // 副・永住権
  isSubPermanentResidency: null, // number
  // 副・年齢
  subAge: null, // number
  // 副・誕生日
  subBirthday: null, // string
  // 関係
  relation: null, // string
  // 休日
  holidayWeekday: null, // string
  // 休日その他
  otherHolidayWeekday: null, // string
  // 連絡がつきやすい時間帯
  convenientTime: null, // string
  // 連絡がつきやすい時間帯その他
  otherConvenientTime: null, // string
  // TEL1
  phoneNumber1: null, // string
  phoneNumber2: null, // string
  phoneNumber3: null, // string
  phoneNumber4: null, // string
  // 電話番号１補足
  phoneNumberMemo1: null, // string
  phoneNumberMemo2: null, // string
  phoneNumberMemo3: null, // string
  phoneNumberMemo4: null, // string
  // FAX
  fax: null, // string
  // メールアドレス１
  mail1: null, // string
  mail2: null, // string
  mail3: null, // string
  mail4: null, // string
  // メールアドレス１補足
  mailMemo1: null, // string
  mailMemo2: null, // string
  mailMemo3: null, // string
  mailMemo4: null, // string
  // メールアドレス１送信可否
  isMailSend1: null, // number // +
  isMailSend2: null, // number // +
  isMailSend3: null, // number // +
  isMailSend4: null, // number // +
  // 郵便番号
  zip: null, // string
  // 住所・都道府県
  prefecture: null, // string
  // 住所・市区町村
  address1: null, // string
  // 住所・それ以降の住所
  address2: null, // string
  // 住所・建物名
  building: null, // string
  // 家族人数・大人
  adultCount: null, // number
  // 家族人数・子供
  childCount: null, // number
  // 子供の年齢1
  childAge: null, // number
  // 子供の年齢2
  otherChildAge: null, // number
  // 入居予定人数
  familyTotalCount: null, // string // string?
  // 入居予定・誰
  familyWho: null, // string
  // 勤務先1
  company: null, // string
  // 勤務先路線ID1
  companyRailwayId: null, // number // +
  // 勤務先駅ID1
  companyStationId: null, // number // +
  // 勤務場所1
  companyAddress: null, // string
  // 勤務年数1
  companyWorkingYear: null, // number
  // 職業種
  jobType: null, // string
  subCompany: null, // string
  subCompanyRailwayId: null, // number // +
  subCompanyStationId: null, // number // +
  subCompanyAddress: null, // string
  subCompanyWorkingYear: null, // number
  // https://gitea.openhouse-group.com/AsiaQuest-Org/SFA-Prj/src/branch/develop/laravel/app/Usecase/Api/WebRes/PutResponsesCustomerToCustomerUsecase.php
  // subWorkingYears: null, // + PHPソースに存在しない
  subJobType: null,
  // 主顧客年収
  annualIncome: null, // string
  // 副顧客年収
  subAnnualIncome: null, // string
  // 頭金
  deposit: null, // string
  // 月々の支払
  monthlyPayment: null, // string
  // ボーナス支払
  bonusPayment: null, // string
  // 購入援助金額
  assistAmount: null, // string
  // 電話番号可否
  telStatusCode: null, // number // *
  // メール送信可否
  emailStatusCode: null, // number // *
  // メモ
  memo: null, // string
  // ランク(担当者)
  userLevel: null, // number // *
  // 希望地域コード
  wishAreaCode: null, // +
  // 希望地域id number[]
  wishAreaIds: [], // +
  // 希望路線１
  wishRailwayId1: null, // number
  // 希望駅From１
  wishStationIdFrom1: null, // number
  // 希望駅To１
  wishStationIdTo1: null, // number
  // 希望路線２
  wishRailwayId2: null, // number
  // 希望駅From２
  wishStationIdFrom2: null, // number // +
  // 希望駅To２
  wishStationIdTo2: null, // number // *?
  // 希望学区
  wishSchoolDistrict1: null, // string
  wishSchoolDistrict2: null, // string
  // マンション検討フラグ
  isThinkApartment: null, // number // *
  // 戸別希望額下限
  detachedHouseBudgetMin: null, // number
  // 戸別希望額上限
  detachedHouseBudgetMax: null, // number
  // 土地希望額下限
  landBudgetMin: null, // number
  // 土地希望額上限
  landBudgetMax: null, // number
  // 希望居住広さ
  livingSpace: null, // string
  // 間取りコード
  floorPlanCode: null, // number
  // 土地希望広さ
  wishLandArea: null, // string // +
  // 重視するポイント
  importantPoint: null, // string
  // 検討のきっかけ
  reason: null, // string
  // 入居希望時期
  wishMoveInDay: null, // string
  // 購入目的
  purchasePurpose: null, // string
  // 一棟物投資検討フラグ
  isThinkWholeHouseInvestment: null, // number
  // 買取検討フラグ
  isThinkSale: null, // number
  // 区分投資検討フラグ
  isThinkDivisionInvestment: null, // number
  // 建替検討フラグ
  isThinkRebuilding: null, // number
  // これまで見た物件の種類
  checkedProductType: null, // string
  // これまで見た物件の件数
  checkedProductCount: null, // number
  // 種類(現況)
  abodeNowBuildingType: null, // string
  // 間取り(現況)
  abodeNowFloorPlanCode: null, // number
  // 面積(現況)
  abodeNowLivingSpace: null, // string
  // 当時の価格(現況)
  abodeNowPriceAtTime: null, // number
  // 希望売却価格(現況)
  abodeNowSalePrice: null, // number
  // 居住期間(現況)
  abodeNowResidencePeriod: null, // string
  // 家賃(現況)
  abodeNowRent: null, // string
  // 買替有無(現況)
  abodeNowReplacementCode: null, // string
  // 買替種類(現況)
  abodeNowReplaceType: null, // string // +
  // 借入残年数(現況)
  abodeNowCurrentMaturity: null, // string
  // 借入銀行(現況)
  abodeNowBankName: null, // string
  // 借入額(現況)
  abodeNowBorrowingAmount: null, // string
  // 車種(現況)
  abodeNowCarType: null, // string // +
  // 台数(現況)
  abodeNowCarCount: null, // number // +
  // 駐車場希望フラグ
  isCarPark: null, // number
  // 追わない詳細理由
  noContactDetailReasonCode: null, // number // +
  // 購入できない理由
  reasonNotBuy: null, // string // +
  // お客様が探していなかった理由
  reasonNotSearch: null, // string // +
  // 要望エリア
  searchArea: null, // string // +
  // OHDにはまらない理由
  reasonNotOhd: null, // string // +
  // 検討開始時期
  searchStartAt: null, // string // +
  // 組織ID
  divisionId: null, // number // +*
  // 社員ID
  userId: null, // number // *
  // 顧客情報取得APIで取得した時間
  getDataAt: null, // string // * 2022/11/18 09:35:20
  // 非顧客フラグ
  isNoCustomer: null, // number // *
  // 物件ID
  siteId: null, // number // +
  // 物件部屋ID
  buildingId: null, // number // +
  // 上長メモ
  leaderMemo: null, // string
  // 問合せ日
  inquiredAt: null, // string // +
  // 確認不要フラグ
  isNotCheckUpdate: null, // number
  // 働き方コード
  workingStyleCode: null, // number
  // 副・働き方コード
  subWorkingStyleCode: null, // number
  // 反響媒体ID(大)
  largeResponseTypeId: null, // number // *
  mediumResponseTypeId: null, // number
  smallResponseTypeId: null, // number
  fineResponseTypeId: null, // number
  // 反響日
  effectAt: null, // string // +
  // 獲得者id
  acquisitionUserId: null, // number // +
  // 獲得者コード
  acquisitionCode: null, // number // +
  // 獲得者メモ
  acquisitionMemo: null, // string // +
  // 顧客紹介フラグ
  isCustomerIntroduction: null, // number
  // 下記はAPIの対象外のキーである
  // productName: null,
  // responseAt: '2022/10/26 00:00:00',
  abodeNowPurchaseAt: null,
  // wishArea: '/関東/千代田区/',
  summary: null,
  // acquisitionId: '1',
  reasonKnowOh: null,
  customerStatusCode: 1,
  driveUrl1: null,
  driveUrl2: null,
  driveUrl3: null,
  // responseId: 1,
};
