import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlyerSalesTreeResponseWrapper from '../../../components/common/flyerSalesTree/flyerSalesTreeResponseWrapper';
import flyerSalesGetResponseTreeApi from '../../../apis/flyerSales/flyerSalesGetApi';
import { getFlyerTreeApi } from '../../../store/flyerSalesTree/flyerSalesTreeSlice';
import { useLoading } from '../../../hooks';
import { CRM_FLYER_SALES } from '../../../constants/loading';

function FlyerSalesTreeResponseContainers(props) {
  const {
    setSelectUser,
    setSelectDivision,
    setSelectFlyer,
    levelCode,
    divisionId,
    reloadHanlder,
  } = props;

  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const flyerGetResponseTree = useSelector(
    (state) => state.flyerSalesTree.flyerSalesGetTree,
  );
  // 初期表示でコード値（initialState）を更新
  const [searchHeader, setSearchHeader] = useState({
    levelCode,
    divisionId,
  });
  const { removeLoading } = useLoading();

  // POST API 源泉未処理ツリー【flyerSalesTree】の更新用
  /**
   * @module getCustomerTreeResponseFunction
   * @param {*} params object - {levelCode: int, divisionId: int}
   * @return null - update divisionGetResponseTree
   */
  const getFlyerTreeResponseFunction = async () => {
    await flyerSalesGetResponseTreeApi(searchHeader)
      .then((res) => {
        dispatch(getFlyerTreeApi(res.data));
      })
      .catch((err) => {
        console.log(err);
        removeLoading(CRM_FLYER_SALES);
      });
  };

  useEffect(() => {
    // flyerSalesTreeのGlobalState更新
    getFlyerTreeResponseFunction();

    // 外部で更新できるため
    reloadHanlder?.(getFlyerTreeResponseFunction);
  }, [searchHeader]);

  useEffect(async () => {
    await setList(flyerGetResponseTree);
  }, [flyerGetResponseTree]);

  return (
    <FlyerSalesTreeResponseWrapper
      list={list}
      setSelectUser={setSelectUser}
      setSelectDivision={setSelectDivision}
      setSelectFlyer={setSelectFlyer}
      searchHeader={searchHeader}
      setSearchHeader={setSearchHeader}
    />
  );
}

export default FlyerSalesTreeResponseContainers;
