import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Add, Folder, Person } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import commonStyles from '../../../../styles';
import {
  getTreeItemByDivisionId,
  getTreeItemByUserId,
  isDivisionAddComponent,
  getPastDivisionArray,
} from '../../commonFunc/helpers';

const useStyles = makeStyles((theme) => ({
  fadeContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '100%',
  },
  root: {
    width: '100%',
    maxHeight: '100%',
    padding: '0 0 16px 0',
  },
  parent: {
    position: 'relative',
    padding: 0,
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 1,
  },
  li: {
    padding: '0 0 0 16px',
    display: 'flex',
    background: '#fff',
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    fontSize: 16,
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '&.isCompact': {
      width: '80%',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  diviPickableButton1: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '80%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&.fullSize': {
      width: '100%',
    },
  },
  diviPickableButton2: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '20%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        marginLeft: '0',
        margin: '0',
      },
    },
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  checkedUser: {
    color: theme.palette.primaryColor,
  },
  // 戻るボタン用
  button: {
    padding: '18px 16px',
    width: '100%',
    background: '#fff',
    justifyContent: 'flex-start',
    margin: '0 0 16px',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  searchItem: {
    padding: 0,
    // borderTop: '1px solid #C8C8C8',
  },
  searchWrap: {
    width: '100%',
  },
  searchTitle: {
    padding: 16,
  },
  searchList: {
    padding: 0,
  },
  result: {
    padding: '40px 16px',
    textAlign: 'center',
    color: '#8C8C8C',
    fontSize: 15,
  },
}));

function UserTreeListSp(props) {
  const common = commonStyles();
  const classes = useStyles();
  const {
    list,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    displayDivision,
    setDisplayDivision,
    displayDivisionRoot,
    setDisplayDivisionRoot,
    searchObj,
    // setSearchObj,
    setTargetDivisionTree,
    showOnlyDivisions,
    isSelectUserWindow,
    clickableUserAndDivision,
  } = props;

  // 選択状態保持
  const [checked, setChecked] = useState(0);
  // 組織選択状態保持
  const [divChecked, setDivChecked] = useState(0);
  // 検索後、データを保持
  const [sortItems, setSortItems] = useState({});
  const firstFlg = useRef(true);

  // 選択した組織内のリストを表示。
  const handleSetDirectory = (divisionId, backVal) => {
    // 対象の階層を参照
    const resultDivision = getTreeItemByDivisionId(list, divisionId);
    // let result = [];

    // 階層にchildrenが存在する場合
    if (resultDivision.children) {
      const { children } = resultDivision;
      // ------------------------------
      if (showOnlyDivisions) {
        const onlyDivision = [];
        for (let i = 0; children.length > i; i += 1) {
          children[i]?.type === 'division' ? onlyDivision.push(children[i]) : null;
        }
        setDisplayDivision(onlyDivision);
      } else {
        setDisplayDivision(children);
      }
      !backVal ? setDisplayDivisionRoot([...displayDivisionRoot, resultDivision]) : null;
    }

    // 戻るボタンを押した場合は、以下の処理を行う
    if (backVal) {
      const resultArray = [];
      for (let i = 0; i < displayDivisionRoot.length; i += 1) {
        if (backVal.includes(displayDivisionRoot[i].id)) {
          resultArray.push(displayDivisionRoot[i]);
        }
      }
      setDisplayDivisionRoot(resultArray);
    }
  };

  // 組織を選択した時の処理
  const handleSelectDivision = async (divObj) => {
    if (isSelectUserWindow) return;
    // 組織を選択
    setDivChecked(divObj.id);
    setSelectDivision({
      divId: divObj.id,
      divisionName: divObj.name,
    });
    // 担当者選択ウィンドウの場合
    if (isSelectUserWindow || clickableUserAndDivision) {
      // すでに選択したユーザーの初期化
      setChecked(0);
      setSelectUser({ userId: 0, userName: '' });

    // 行動予定の組織ツリーの為
    } else if (setTargetDivisionTree) {
      setTargetDivisionTree(divObj);
    }
  };

  // ユーザーを選択した時の処理
  const handleSelectUser = async (userObj) => {
    // ユーザーを選択
    setSelectUser({ userId: userObj.userId, userName: userObj.userName });
    setChecked(userObj.userId);
    // ユーザーに所属組織があれば、組織をセット
    if (userObj?.parentId) {
      setSelectDivision({ divId: userObj.parentId, divisionName: userObj.parentName });
      setDivChecked(userObj.parentId);
    }
  };

  // 戻るボタンを押した時の処理
  const getBackList = async () => {
    const divIds = [];
    for (let i = 0; displayDivisionRoot.length > i; i += 1) {
      if (divIds.includes(displayDivisionRoot[i])) {
        break;
      }
      divIds.push(displayDivisionRoot[i].id);
    }
    if (divIds.length > 1) {
      const resetDivision = divIds[divIds.length - 2];
      if (divIds.includes(resetDivision)) {
        await divIds.splice(divIds.indexOf(resetDivision) + 1);
        await setDisplayDivisionRoot(divIds);
        handleSetDirectory(divIds[divIds.length - 1], divIds);
      } else {
        handleSetDirectory(resetDivision);
      }
    } else {
      setDisplayDivision([]);
      setDisplayDivisionRoot([]);
    }
  };

  // ユーザー用のボタン
  const userButton = (item) => (
    <Grid
      key={`u${item.userId}`}
      className={`${common.small} ${classes.li} ${classes.person}`}
    >
      <Button
        className={`${common.title3} ${classes.btn} ${
          checked === item.userId ? classes.checkedUser : null
        }`}
        id={`id-user-${item.userId}`}
        style={{ cursor: 'pointer' }}
        variant="text"
        startIcon={<Person className={classes.icon} />}
        onClick={() => handleSelectUser(item)}
      >
        {item.userName}
        {item.isMainDivision === 0 && '（サブ）'}
      </Button>
    </Grid>
  );

  const addButton = (item) => {
    if (item.children?.length === 0) return null;
    return (
      showOnlyDivisions ? (
        (isDivisionAddComponent(item)) && (
          <Button
            className={classes.diviPickableButton2}
            onClick={() => {
              handleSetDirectory(item.id);
            }}
          >
            <Add className={classes.addButtonIcon} />
          </Button>
        )
      ) : (
        (item.children || item.users) && (
          <Button
            className={classes.diviPickableButton2}
            onClick={() => {
              handleSetDirectory(item.id);
            }}
          >
            <Add className={classes.addButtonIcon} />
          </Button>
        )
      )
    );
  };

  // 組織用のボタン
  const diviPickableButton = (item, isSearch) => {
    return (
      <Grid className={classes.li}>
        <Button
          className={`${common.title3} ${classes.diviPickableButton1} ${
            divChecked === item.id ? classes.checkedUser : null
          } ${((!item.children && !item.users) || (showOnlyDivisions && !item.children && item.levelCode > 1)) && 'fullSize'}`}
          onClick={() => handleSelectDivision(item)}
        >
          <Folder className={classes.icon} />
          <Typography
            className={common.title3}
          >
            {item.name}
          </Typography>
        </Button>
        {isSearch ? null : addButton(item)}
        {/* {addButton(item)} */}
      </Grid>
    );
  };

  // ユーザー要素
  const userList = (data) => {
    const userlist = [];
    if ('userId' in data) {
      userlist.push(
        userButton(data),
      );
    } else {
      data.users.map((u) => {
        return userlist.push(
          userButton(u),
        );
      });
    }
    return userlist;
  };

  // 組織要素
  const childList = (division, isSearch) => {
    const childlist = [];
    childlist.push(
      <Grid key={`divi${division.id}`} className={classes.parent}>
        {diviPickableButton(division, isSearch)}
      </Grid>,
    );
    return childlist;
  };

  // 画面に各階層のリストを描画
  const getListItem = (searchArr, initFlg = false, isSearch = false) => {
    let firstInitFlg = initFlg;
    let loopItems = null;
    if (displayDivision.length > 0) {
      loopItems = displayDivision;
    } else if (!searchArr) {
      firstInitFlg = true;
      // loopItems = getFirstList(list);
      loopItems = list;
    }

    // 初期表示
    if (firstInitFlg) {
      return loopItems.map((data) => (
        <div key={`s${data.id}${uuid()}`} className={classes.parent}>
          {diviPickableButton(data)}
        </div>
      ));
      // 検索を実行後、表示させるリスト
    } else if (searchArr) {
      return (
        searchArr.length > 0 ? (
          <Grid className={classes.searchItem} key={uuid()}>
            {searchArr.map((arr) => {
              return (
                // 組織選択ウィンドウの場合
                showOnlyDivisions ? (
                  <Grid className={classes.searchWrap} key={`s${arr.id}${uuid()}`}>
                    {/* リストの表示 */}
                    <Grid className={classes.searchList}>
                      {childList(arr, isSearch)}
                    </Grid>
                  </Grid>
                ) : (
                  // 担当者選択ウィンドウの場合
                  <Grid className={classes.searchWrap} key={`s${arr.divisionId}${uuid()}`}>
                    {/* 組織名／ユーザー名でグルーピング */}
                    {arr.groupName ? (
                      <>
                        <Typography
                          className={`${common.title3} ${classes.searchTitle}`}
                        >
                          {arr.groupName}
                        </Typography>
                        {/* リストの表示 */}
                        <Grid className={classes.searchList}>
                          {arr.users.map((u) => userList(u))}
                        </Grid>
                      </>
                    ) : (
                      <Grid className={classes.searchList}>
                        {arr.users.map((u) => userList(u))}
                      </Grid>
                    )}
                  </Grid>
                )
              );
            })}
          </Grid>
        ) : <Typography className={classes.result}>0件</Typography>
      );
    } else {
      const resultList = loopItems.map((data) => (
        !showOnlyDivisions && 'userId' in data ? userList(data) : childList(data)
      ));

      return (
        resultList.map((item) => (
          <Grid key={uuid()}>
            {item}
          </Grid>
        ))
      );
    }
  };

  // 初回ツリー描画  & 初期値指定
  useEffect(() => {
    if (list.length > 0 || firstFlg.current) {
      // eslint-disable-next-line no-use-before-define, max-len
      const division = selectDivision?.divId ? getTreeItemByDivisionId(list, selectDivision.divId) : null;
      const user = selectUser?.userId
        ? getTreeItemByUserId(list, selectUser.userId, division ? division.id : undefined)
        : null;
      if (user) {
        setChecked(user.userId);
        if (user.parentId) {
          const userDivision = getTreeItemByDivisionId(list, user.parentId);
          setDisplayDivision(userDivision.children);
          const targetRootDivisions = getPastDivisionArray(list, userDivision, true);
          setDisplayDivisionRoot(targetRootDivisions);
        } else {
          setDisplayDivision(list);
          setDisplayDivisionRoot([]);
        }
      }
      if (division) {
        setDivChecked(division.id);
        if (!user) {
          const targetRootDivisions = getPastDivisionArray(list, division);
          setDisplayDivisionRoot(targetRootDivisions);
          if (targetRootDivisions.length > 0) {
            const displayItems = targetRootDivisions.slice(-1)[0];
            if (showOnlyDivisions && displayItems?.children) {
              const resultDivision = [];
              for (const key of displayItems.children) {
                if (key?.type === 'division') resultDivision.push(key);
              }
              setDisplayDivision(resultDivision);
            } else setDisplayDivision(displayItems?.children);
          } else {
            setDisplayDivision(list);
          }
        }
        if (setTargetDivisionTree) setTargetDivisionTree(division);
      }
      firstFlg.current = false;
    }
  }, [list]);

  // 検索社員選択の場合
  const setUsers = () => {
    const allList = [];
    for (let i = 0; searchObj.length > i; i += 1) {
      const item = searchObj[i];
      let isAlready = false;
      for (let ii = 0; allList?.length > ii; ii += 1) {
        if (item.id === allList[ii].divisionId) {
          allList[ii].users.push(item);
          isAlready = true;
          break;
        }
      }
      if (!isAlready) {
        allList.push({
          groupName: item.parentName,
          divisionId: item.parentId,
          users: [item],
        });
      }
    }
    // 表示用
    setSortItems(allList);
  };

  // 検索組織選択の場合
  const setDivisions = () => {
    setSortItems(searchObj);
  };

  // 検索時のEffect
  useEffect(() => {
    if (searchObj.length > 0) {
      showOnlyDivisions ? setDivisions() : setUsers();
    }
  }, [searchObj]);

  return (
    <>
      {displayDivisionRoot.length > 0 && !searchObj && (
        // 戻るボタン
        <Button
          className={classes.button}
          onClick={getBackList}
        >
          <ArrowBackIosIcon className={classes.backIcon} />
          <Typography className={`${classes.text} ${common.title3}`}>{displayDivisionRoot.slice(-1)[0].name}</Typography>
        </Button>
      )}
      <Grid className={`${classes.root}`}>
        {Array.isArray(sortItems) && searchObj ? (
          // 検索後
          getListItem(sortItems, false, true)
          // 検索前
        ) : (
          <>
            {getListItem()}
          </>
        )}
      </Grid>
    </>
  );
}

export default UserTreeListSp;
