import { makeStyles } from '@material-ui/core';
import commonTheme from '../../../styles/theme';

const infoRegisterStyles = makeStyles((theme) => ({
  list: { background: '#fff', padding: 0 },
  listItem: {
    display: 'block',
    padding: '10px 8px 10px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    textAlign: 'left',
    '& *::before, & *::after': { borderBottom: 'none' },
    '&:not(:has(& > button))': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    // marginBottom: 16,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    flexShrink: 0,
    marginRight: 16,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
  input: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
      textAlign: 'right',
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after, & > div:hover:not(.Mui-disabled):before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: `2px solid ${theme.palette.primaryColor}`,
      },
    },
  },
  textarea: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after, & > div:hover:not(.Mui-disabled):before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: `2px solid ${theme.palette.primaryColor}`,
      },
    },
  },
}));

export default infoRegisterStyles;
