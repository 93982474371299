import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const divisionMasterUpdateOrganizationSlice = createSlice({
  name: 'divisionMasterUpdateOrganization',
  initialState: { divisionMasterUpdateOrganization: initialState },
  reducers: {
    divisionMasterUpdateOrganizationApi: (state, data) => {
      state.divisionMasterUpdateOrganization = data.payload;
    },
  },
});

export const {
  divisionMasterUpdateOrganizationApi,
} = divisionMasterUpdateOrganizationSlice.actions;

export default divisionMasterUpdateOrganizationSlice.reducer;
