import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { crmPageSelector } from '../../../store/crmPage';
import ResponseRegisterWrapper from '../../../components/common/responseRegister/responseRegister';

import { UPDATE_TYPE_CODE } from '../../../constants/apiParameterCode';
import { ALL_CUSTOMER_ADMIN, LEADERS_MEMO_USE } from '../../../constants/userRole';
import { CUSTOMER_SATISFACTION } from '../../../constants/userRoleGroup';

function ResponseRegisterContainers() {
  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleIds, roleGroupId } = responseHeader;
  const page = useSelector(crmPageSelector);

  /**
   * 支援権限 role_idsに9があるか、顧客満足室ならtrue (falseは営業権限)
   */
  const isAllCustomerAdmin = roleIds.includes(ALL_CUSTOMER_ADMIN);
  const isCustomerSatisfaction = roleGroupId === CUSTOMER_SATISFACTION;
  /**
   * 上長メモ権限 role_idsに13があればtrue
   */
  const isLeadersMemoUse = roleIds.includes(LEADERS_MEMO_USE);

  /**
   * keyを更新することでコンポーネントの破棄・作成を行う。
   * <input defaultValue> <select defaultValue>によって、
   * stateを更新してもUIの表示が変わらない現象が発生するのでforceRenderingする。
   */
  const [forceRenderId, setForceRenderId] = useState(0);

  useEffect(() => {
    setForceRenderId(forceRenderId + 1);
  }, [page.user]);

  if (!page.isReady) {
    return null;
  }

  const isEdit = page.user.updateType === UPDATE_TYPE_CODE.CUSTOMER_UPDATE
    || page.user.updateType === UPDATE_TYPE_CODE.RETURN
    || page.user.updateType === UPDATE_TYPE_CODE.REBELLION;

  return (
    <ResponseRegisterWrapper
      key={forceRenderId}
      user={page.user}
      inquiry={page.inquiry}
      response={page.response}
      id={isEdit ? page.editCustomerId : undefined}
      updateType={page.user.updateType}
      isAllCustomerAdmin={isAllCustomerAdmin || isCustomerSatisfaction}
      isLeadersMemoUse={isLeadersMemoUse}
    />
  );
}

export default ResponseRegisterContainers;
