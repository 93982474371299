import { makeStyles } from '@material-ui/core';

const dashboardStyle = makeStyles({
  premiumIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    marginLeft: 5,
  },
  stars: { fontSize: 13, margin: '0 5px' },
  red: { color: '#D83420' },
});

export default dashboardStyle;
