import { configureStore } from '@reduxjs/toolkit';

// user
import usersReducer from './user/userListSlice';
import userDetailReducer from './user/userDetailSlice';
import userCreateReducer from './user/userCreateSlice';
import userUpdateReducer from './user/userUpdateSlice';
import userHistoryReducer from './user/userHistorySlice';
import userReducer from './user/userSlice';
import userGetShareListReducer from './user/userGetShareListSlice';
import userGetSharedReducer from './user/userGetSharedSlice';
import userDivSliceReducer from './user/userDivListSlice';

// customer
import customerResponsibleReducer from './customer/customerResponsibleSlice';
import customerResponsibleUpdateReducer from './customer/customerResponsibleUpdateSlice';
import customerReducer from './customer/customerSlice';
import customerGetTelsReducer from './customer/customerGetTelsSlice';
import customerCreateReducer from './customer/customerCreateSlice';
import customerGetShareReducer from './customer/customerGetShareSlice';
import customerUpdateShareReducer from './customer/customerUpdateShareSlice';
import customerGetStatisticsReducer from './customer/customerGetStatisticsSlice';
import customerRegisterReducer from './customer/customerRegisterSlice';
import customerGetBrowsingHistoryReducer from './customer/customerGetBrowsingHistorySlice';
import customerProductsRecommendationsReducer from './customer/customerProductsRecommendationsSlice';
import getDataTimeReducer from './customer/gotDataTimeSlice';

// common
import responseHeaderReducer from './common/responseHeaderSlice';
import areaListReducer from './common/areaListSlice';
import alongRailwaysReducer from './common/alongRailwaysSlice';
import stationReducer from './common/stationsSlice';
import zipsReducer from './common/zipsSlice';
import apiMessageReducer from './common/apiMessageSlice';
import commonGetHeaderNumberRecucer from './common/commonGetHeaderNumberSlice';
import getProductsReducer from './common/getProductsSlice';
// act
import actCreateHistoryReducer from './act/actCreateHistorySlice';
import actPutHistoryReducer from './act/actPutHistorySlice';
import actCreateCustomHistoryReducer from './act/actCreateCustomHistorySlice';
import actGetHistoriesReducer from './act/actGetHistoriesSlice';
import actDeleteHistoryReducer from './act/actDeleteHistorySlice';
import actGetTodayGuidancesReducer from './act/actGetTodayGuidancesSlice';
import actGuidancesReducer from './act/actGuidances';
import incompleteListReducer from './act/incompleteList';
import responsesRecentListReducer from './act/responsesResentList';
import unregistListReducer from './act/unregistList';

// claim
import claimGetReducer from './claim/claimGetSlice';
import claimUpdateReducer from './claim/claimUpdateSlice';
import claimCreateCancelReducer from './claim/claimCreateCancelSlice';
import registerClaimApiReducer from './claim/claimRegisterSlice';

// direct
import directGetListReducer from './direct/directGetListSlice';
import directGetDetailReducer from './direct/directGetDetailSlice';
import directRegistReducer from './direct/directRegistSlice';
import directUpdateReducer from './direct/directUpdateSlice';
import directTupdateReducer from './direct/directTupdateSlice';
import directGetInforReducer from './direct/directGetInforSlice';

// hpinfo
import hpinfoGetInfoReducer from './hpinfo/hpinfoGetInfoSlice';
import hpinfoUpdateStatusReducer from './hpinfo/hpinfoUpdateStatusSlice';
import hpinfoGetSearchConditionReducer from './hpinfo/hpinfoGetSearchConditionSlice';
import hpinfoGetOneClickSearchConditionReducer from './hpinfo/hpinfoGetOneClickSearchConditionSlice';
import hpinfoGetContentsHistoriesReducer from './hpinfo/hpinfoGetContentsHistoriesSlice';
import hpinfoGetProductsHistoriesReducer from './hpinfo/hpinfoGetProductsHistoriesSlice';
import hpinfoGetFavoriteReducer from './hpinfo/hpinfoGetFavoriteSlice';
import hpinfoGetLoanReducer from './hpinfo/hpinfoGetLoanSlice';
import hpinfoGetHistoriesReducer from './hpinfo/hpinfoGetHistoriesSlice';

// response
import responseGetTypeReducer from './response/responseGetTypeSlice';
import responseGetMediumReducer from './response/responseGetMediumSlice';
import responseResisterMediumReducer from './response/responseResisterMediumSlice';
import responseUpdateMediumReducer from './response/responseUpdateMediumSlice';
import responseGetListByUserIdReducer from './response/responseGetListByUserIdSlice';
import responseGetResponseByResponseIdReducer from './response/responseGetResponseByResponseIdSlice';

// idList4Potential
import idList4PotentialReducer from './idList4Potential/idList4PotentialSlice';

// schedule
import schedulesGetActionDateReducer from './schedules/schedulesGetActionDateSlice';
import schedulesGetActionWeekReducer from './schedules/schedulesGetActionWeekSlice';
import schedulesGetContactsCountReducer from './schedules/schedulesGetContactsCountSlice';
import schedulesGetUnregistGuidancesReducer from './schedules/schedulesGetUnregistGuidancesSlice';
import schedulesGetActionDetailReducer from './schedules/schedulesGetActionDetailSlice';
import schedulesGetWeekActionPlanReducer from './schedules/schedulesGetWeekActionPlanSlice';
import schedulesRegisterReducer from './schedules/schedulesRegisterSlice';
import schedulesSubmissionReducer from './schedules/schedulesSubmissionSlice';
import schedulesGetGuidancesByCustomerIdReducer from './schedules/schedulesGetGuidancesByCustomerIdSlice';
import schedulesGetGuidanceByGuidanceIdReducer from './schedules/schedulesGetGuidanceByGuidanceIdSlice';
import schedulesUpdateReducer from './schedules/schedulesUpdateSlice';
import schedulesGetContactsTimeReducer from './schedules/schedulesGetContactsTimeSlice';
import schedulesGetGuidancesReducer from './schedules/schedulesGetGuidancesSlice';
import schedulesUpdateGuidancesReducer from './schedules/schedulesUpdateGuidancesSlice';
import schedulesRegistGuidancesReducer from './schedules/schedulesRegistGuidancesSlice';
import schedulesPutGuidanceByGuidanceIdReducer from './schedules/schedulesPutGuidanceByGuidanceIdSlice';
import schedulesDeleteGuidanceByGuidanceIdReducer from './schedules/schedulesDeleteGuidanceByGuidanceIdSlice';
import schedulesPostGuidanceReducer from './schedules/schedulesPostGuidanceSlice';

// search
import searchItemsReducer from './search/searchItemsSlice';

// listAd
import listAdGetKeywordsReducer from './listAd/listAdGetKeywordsSlice';

// listNotification
import membersGetNotificationsReducer from './members/membersGetNotificationsSlice';

// sendmail
import sendmailLastUpdateSignatureReducer from './sendmail/sendmailLastUpdateSignatureSlice';
import sendmailCustomerMailReducer from './sendmail/sendmailCustomerMailSlice';
import sendmailCancelReducer from './sendmail/sendmailCancelSlice';
import sendmailUpdateReducer from './sendmail/sendmailUpdateSlice';
import sendmailGetUnsendReducer from './sendmail/sendmailGetUnsendSlice';
import sendmailGetInfoReducer from './sendmail/sendmailGetInfoSlice';
import sendmailGetIdListReducer from './sendmail/sendmailIdListSlice';
import sendmailGetCountsReducer from './sendmail/sendmailGetCountsSlice';

// resHouse
import resHouseGetListReducer from './resHouse/resHouseGetSlice';
import resHouseRegisterReducer from './resHouse/resHouseRegisterSlice';
import resHouseDeleteReducer from './resHouse/resHouseDeleteSlice';

// mokuhyoSet
import mokuhyoSetResisterReducer from './mokuhyoSet/mokuhyoSetResisterSlice';
import mokuhyoSetDeleteReducer from './mokuhyoSet/mokuhyoSetDeleteSlice';
import mokuhyoSetGetChangeHistryReducer from './mokuhyoSet/mokuhyoSetGetChangeHistrySlice';
import mokuhyoSetUpdateReducer from './mokuhyoSet/mokuhyoSetUpdateSlice';

// library
import libraryGetReducer from './library/libraryGetSlice';
import libraryGetSpecifyDirctoryReducer from './library/libraryGetSpecifyDirctorySlice';
import libraryUpdateReducer from './library/libraryUpdateSlice';
import libraryResisterReducer from './library/libraryResisterSlice';
import libraryGetInterruptedReducer from './library/libraryGetInterruptedSlice';
import libraryCopyReducer from './library/libraryCopySlice';
import libraryGetSignitureReducer from './library/libraryGetSignitureSlice';
import librarySearchReducer from './library/librarySearchSlice';
import libraryShareReducer from './library/libraryShareSlice';

// librarydir
import librarydirCopyReducer from './librarydir/librarydirCopySlice';
import librarydirGetDirectlyTreeReducer from './librarydir/librarydirGetDirectlyTreeSlice';
import librarydirResisterReducer from './librarydir/librarydirResisterSlice';
import librarydirUpdateReducer from './librarydir/librarydirUpdateSlice';
import librarydirOwnReducer from './librarydir/librarydirOwnSlice';

// line
import lineAddNewIdReducer from './line/lineAddNewIdSlice';
import lineGetIdReducer from './line/lineGetIdSlice';
import lineUpdateIdReducer from './line/lineUpdateIdSlice';

// autoRegistResponse
import autoRegistResponseControlSearchReducer from './autoRegistResponse/autoRegistResponseControlSearchSlice';
import autoRegistResponseControlUpdateReducer from './autoRegistResponse/autoRegistResponseControlUpdateSlice';

// calendar
import calendarDownloadTSVReducer from './calendar/calendarDownloadTSVSlice';
import calendarUploadTSVReducer from './calendar/calendarUploadTSVSlice';

// divisionMaster
import divisionMasterDownloadCSVReducer from './divisionMaster/divisionMasterDownloadCSVSlice';
import settingReducer from './divisionMaster/setting';
import storeDivisionsReducer from './divisionMaster/storeDivisions';
import divisionMastersReducer from './divisionMaster/divisionMasters';
import userListReducer from './divisionMaster/userList';
import divisionMasterUpdateOrganizationReducer from './divisionMaster/divisionMasterUpdateOrganizationSlice';
import divisionMasterUpdateOrganizationStoreReducer from './divisionMaster/divisionMasterUpdateOrganizationStoreSlice';
import divisionMasterUpdateSectionReducer from './divisionMaster/divisionMasterUpdateSectionSlice';
import divisionMasterUploadCSVReducer from './divisionMaster/divisionMasterUploadCSVSlice';
import getAllSettingReducer from './divisionMaster/getAllSettingSlice';
import getUserSearchListReducer from './divisionMaster/getUserSearchListSlice';

// divisions
import divisionsGetDetailReducer from './divisions/divisionsGetDetailSlice';
import divisionsGetTreeReducer from './divisions/divisionsGetTreeSlice';
import divisionsResisterReducer from './divisions/divisionsResisterSlice';
import divisionsUpdateReducer from './divisions/divisionsUpdateSlice';
import divisionsGetResponseTreeReducer from './divisions/divisionsGetResponseTreeSlice';
// FlyerSalesTree
import flyerSalesTreeReducer from './flyerSalesTree/flyerSalesTreeSlice';

// filestorage
import filestorageDownloadReducer from './filestorage/filestorageDownloadSlice';
import filestorageGetReducer from './filestorage/filestorageGetSlice';
import filestorageResisterReducer from './filestorage/filestorageResisterSlice';
import filestorageUpdateReducer from './filestorage/filestorageUpdateSlice';

// settingGroupStore
import settingGroupsStoreReduer from './settingGroupStore/settingGroupsSlice';
import settingGroupStoreDetailReducer from './settingGroupStore/settingGroupStoreDetailSlice';
import settingGroupStoreAreasReducer from './settingGroupStore/settingGroupStoreAreaListSlice';

// simulator
import simulatorDownLoadReducer from './simulator/simulatorDownLoadSlice';
import simulatorGetJISCodeReducer from './simulator/simulatorGetJISCodeSlice';
import simulatorJudgementReducer from './simulator/simulatorJudgementSlice';
import simulatorUploadReducer from './simulator/simulatorUploadSlice';
import simulatorRunningStatusReducer from './simulator/simulatorRunningStatusSlice';

// webRes
import webResJudgeHandlingReducer from './webRes/webResJudgeHandlingSlice';
import webResUntreatAndUpdateStoreGroupReducer from './webRes/webResUntreatAndUpdateStoreGroupSlice';
import webResCreateCustomerReducer from './webRes/webResCreateCustomerSlice';
import webResGetReducer from './webRes/webResGetSlice';
import webResUpdateAfterMargedCustomerReducer from './webRes/webResUpdateAfterMargedCustomerSlice';
import webResUpdateAfterMargedReducer from './webRes/webResUpdateAfterMargedSlice';
import webResUpdateReducer from './webRes/webResUpdateSlice';

// csvRes
import csvResResisterFBReducer from './csvRes/csvResResisterFBSlice';

// eleCommon
import customAlertMessageReducer from './eleCommon/customAlertMessage';
import customConfirmMessageReducer from './eleCommon/customConfirmMessage';
import cantCloseAlertMessageReducer from './eleCommon/cantCloseAlertMessage';

// kimarisTargetSettings
import kimarisTargetSettingsGetListReducer from './kimarisTargetSettings/kimarisTargetSettingsGetListSlice';
import kimarisTargetSettingsRegistReducer from './kimarisTargetSettings/kimarisTargetSettingsRegistSlice';
import kimarisTargetSettingsUpdateReducer from './kimarisTargetSettings/kimarisTargetSettingsUpdateSlice';
import kimarisTargetSettingsAllUpdateReducer from './kimarisTargetSettings/kimarisTargetSettingsAllUpdateSlice';

// allocationSetting
import allocationSettingGetReducer from './allocationSetting/allocationSettingGetSlice';
import allocationSettingCsvUploadReducer from './allocationSetting/allocationSettingCsvUploadSlice';
import allocationSettingCsvDownloadReducer from './allocationSetting/allocationSettingCsvDownloadSlice';
import allocationSettingDeleteReducer from './allocationSetting/allocationSettingDeleteSlice';
import areaWishCityCountUpdateReducer from './allocationSetting/areaWishCityCountUpdateSlice';
import allocationSettingGetOnAreaCodeReducer from './allocationSetting/allocationSettingGetOnAreaCodeSlice';

// device type
import deviceTypeSliceReducer from './getDeviceType';
// authtoken
import accessTokenReducer from './common/accessToken';
// pagepath
import pagePathReducer from './getPagePath';

// keymalis
import getContractInfoReducer from './keymalis/getContractInfoSlice';

// page
import crmPageReducer from './crmPage';
import libraryPageReducer from './libraryPage';

// sms
import getSmsHistoryReducer from './sms/smsHistorySlice';
import getSmsTalkReducer from './sms/smsTalkSlice';

// loading
import loaddingReducer from './loading';

// colorTheme
import colorChangerReducer from './common/getTheme';

const store = configureStore({
  reducer: {
    // user
    user: userReducer,
    users: usersReducer,
    userDetail: userDetailReducer,
    userCreate: userCreateReducer,
    userUpdate: userUpdateReducer,
    userHistory: userHistoryReducer,
    userGetShareList: userGetShareListReducer,
    userGetShared: userGetSharedReducer,
    userDivList: userDivSliceReducer,

    // customer
    customer: customerReducer,
    customerResponsible: customerResponsibleReducer,
    customerResponsibleUpdate: customerResponsibleUpdateReducer,
    customerGetTels: customerGetTelsReducer,
    customerCreate: customerCreateReducer,
    customerGetShare: customerGetShareReducer,
    customerUpdateShare: customerUpdateShareReducer,
    customerGetStatistics: customerGetStatisticsReducer,
    customerRegister: customerRegisterReducer,
    customerGetBrowsingHistory: customerGetBrowsingHistoryReducer,
    customerProductsRecommendations: customerProductsRecommendationsReducer,
    gotDataTime: getDataTimeReducer,

    // common
    responseHeader: responseHeaderReducer,
    areaList: areaListReducer,
    alongRailways: alongRailwaysReducer,
    stations: stationReducer,
    zips: zipsReducer,
    apiMessage: apiMessageReducer,
    commonGetHeaderNumber: commonGetHeaderNumberRecucer,
    getProducts: getProductsReducer,
    // act
    actCreateHistory: actCreateHistoryReducer,
    actPutHistory: actPutHistoryReducer,
    actCreateCustomHistory: actCreateCustomHistoryReducer,
    actGetHistories: actGetHistoriesReducer,
    actDeleteHistory: actDeleteHistoryReducer,
    actGetTodayGuidances: actGetTodayGuidancesReducer,
    actGuidances: actGuidancesReducer,
    incompleteList: incompleteListReducer,
    responsesRecentList: responsesRecentListReducer,
    unregistList: unregistListReducer,
    // claim
    claimGet: claimGetReducer,
    claimUpdate: claimUpdateReducer,
    claimCreateCancel: claimCreateCancelReducer,
    registerClaim: registerClaimApiReducer,
    // direct
    directList: directGetListReducer,
    directGetDetail: directGetDetailReducer,
    directRegist: directRegistReducer,
    directUpdate: directUpdateReducer,
    directTupdate: directTupdateReducer,
    directGetInfor: directGetInforReducer,
    // directCustomer: directCustomerReducer,
    // hpinfo
    hpinfoGetInfo: hpinfoGetInfoReducer,
    hpinfoUpdateStatus: hpinfoUpdateStatusReducer,
    hpinfoGetSearchCondition: hpinfoGetSearchConditionReducer,
    hpinfoGetOneClickSearchCondition: hpinfoGetOneClickSearchConditionReducer,
    hpinfoGetContentsHistories: hpinfoGetContentsHistoriesReducer,
    hpinfoGetProductsHistories: hpinfoGetProductsHistoriesReducer,
    hpinfoGetFavorite: hpinfoGetFavoriteReducer,
    hpinfoGetLoan: hpinfoGetLoanReducer,
    hpinfoGetHistories: hpinfoGetHistoriesReducer,
    // response
    responseGetType: responseGetTypeReducer,
    responseGetMedium: responseGetMediumReducer,
    responseResisterMedium: responseResisterMediumReducer,
    responseUpdateMedium: responseUpdateMediumReducer,
    responseGetListByUserId: responseGetListByUserIdReducer,
    responseByResponseId: responseGetResponseByResponseIdReducer,
    // idList4Potential
    idList4Potential: idList4PotentialReducer,
    // schedule
    schedulesGetGuidancesByCustomerId: schedulesGetGuidancesByCustomerIdReducer,
    schedulesGetGuidanceByGuidanceId: schedulesGetGuidanceByGuidanceIdReducer,
    schedulesPutGuidanceByGuidanceId: schedulesPutGuidanceByGuidanceIdReducer,
    schedulesDeleteGuidanceByGuidanceId:
      schedulesDeleteGuidanceByGuidanceIdReducer,
    schedulesPostGuidance: schedulesPostGuidanceReducer,

    schedulesGetActionDate: schedulesGetActionDateReducer,
    schedulesGetActionWeek: schedulesGetActionWeekReducer,
    schedulesGetUnregistGuidances: schedulesGetUnregistGuidancesReducer,
    schedulesGetActionDetail: schedulesGetActionDetailReducer,
    schedulesGetWeekActionPlan: schedulesGetWeekActionPlanReducer,
    schedulesRegister: schedulesRegisterReducer,
    schedulesSubmission: schedulesSubmissionReducer,
    schedulesUpdate: schedulesUpdateReducer,
    schedulesGetContactsCount: schedulesGetContactsCountReducer,
    schedulesGetContactsTime: schedulesGetContactsTimeReducer,
    schedulesGetGuidances: schedulesGetGuidancesReducer,
    schedulesUpdateGuidances: schedulesUpdateGuidancesReducer,
    schedulesRegistGuidances: schedulesRegistGuidancesReducer,
    // search
    searchItems: searchItemsReducer,
    // listAd
    listAdGetKeywords: listAdGetKeywordsReducer,
    // listNotification
    membersGetNotifications: membersGetNotificationsReducer,
    // sendmail
    sendmailLastUpdateSignature: sendmailLastUpdateSignatureReducer,
    sendmailCustomerMail: sendmailCustomerMailReducer,
    sendmailCancel: sendmailCancelReducer,
    sendmailUpdate: sendmailUpdateReducer,
    sendmailGetUnsend: sendmailGetUnsendReducer,
    sendmailGetInfo: sendmailGetInfoReducer,
    mailIdListGet: sendmailGetIdListReducer,
    sendmailGetCounts: sendmailGetCountsReducer,
    // resHouse
    resHouseGetList: resHouseGetListReducer,
    resHouseRegister: resHouseRegisterReducer,
    resHouseDelete: resHouseDeleteReducer,
    // mokuhyoSet
    mokuhyoSetResister: mokuhyoSetResisterReducer,
    mokuhyouSetDelete: mokuhyoSetDeleteReducer,
    mokuhyoList: mokuhyoSetGetChangeHistryReducer,
    mokuhyoSetUpdate: mokuhyoSetUpdateReducer,
    // library
    libraryGet: libraryGetReducer,
    libraryGetSpecifyDirctory: libraryGetSpecifyDirctoryReducer,
    libraryUpdate: libraryUpdateReducer,
    libraryResister: libraryResisterReducer,
    libraryGetInterrupted: libraryGetInterruptedReducer,
    libraryCopy: libraryCopyReducer,
    libraryGetSigniture: libraryGetSignitureReducer,
    librarySearch: librarySearchReducer,
    libraryShare: libraryShareReducer,
    // librarydir
    librarydirCopy: librarydirCopyReducer,
    librarydirGetDirectlyTree: librarydirGetDirectlyTreeReducer,
    librarydirResister: librarydirResisterReducer,
    librarydirUpdate: librarydirUpdateReducer,
    librarydirOwn: librarydirOwnReducer,
    // line
    lineAddNewId: lineAddNewIdReducer,
    lineGetId: lineGetIdReducer,
    lineUpdateId: lineUpdateIdReducer,
    // autoRegistResponse
    autoRegistResponseControlSearch: autoRegistResponseControlSearchReducer,
    autoRegistResponseControlUpdate: autoRegistResponseControlUpdateReducer,
    // calendar
    calendarDownloadTSV: calendarDownloadTSVReducer,
    calendarUploadTSV: calendarUploadTSVReducer,
    // divisionMaster
    divisionMasterDownloadCSV: divisionMasterDownloadCSVReducer,
    setting: settingReducer,
    storeDivisions: storeDivisionsReducer,
    divisionMasters: divisionMastersReducer,
    userList: userListReducer,
    divisionMasterUpdateOrganization: divisionMasterUpdateOrganizationReducer,
    divisionMasterUpdateOrganizationStore:
      divisionMasterUpdateOrganizationStoreReducer,
    divisionMasterUpdateSection: divisionMasterUpdateSectionReducer,
    divisionMasterUploadCSV: divisionMasterUploadCSVReducer,
    getAllSetting: getAllSettingReducer,
    getUserSearchList: getUserSearchListReducer,
    // divisions
    divisionsDetail: divisionsGetDetailReducer,
    divisionsGetTree: divisionsGetTreeReducer,
    divisionsResister: divisionsResisterReducer,
    divisionsUpdate: divisionsUpdateReducer,
    divisionsGetResponseTree: divisionsGetResponseTreeReducer,
    // flyerSalesTree
    flyerSalesTree: flyerSalesTreeReducer,
    // filestorage
    filestorageDownload: filestorageDownloadReducer,
    filestorageGet: filestorageGetReducer,
    filestorageResister: filestorageResisterReducer,
    filestorageUpdate: filestorageUpdateReducer,
    // settingGroupStore
    settingGroupsStore: settingGroupsStoreReduer,
    settingGroupStoreDetail: settingGroupStoreDetailReducer,
    settingGroupStoreAreas: settingGroupStoreAreasReducer,
    // simulator
    simulatorDownLoad: simulatorDownLoadReducer,
    simulatorGetJISCode: simulatorGetJISCodeReducer,
    simulatorJudgement: simulatorJudgementReducer,
    simulatorUpload: simulatorUploadReducer,
    simulatorRunningStatus: simulatorRunningStatusReducer,
    // webRes
    webResJudgeHandling: webResJudgeHandlingReducer,
    webResUntreatAndUpdateStoreGroup: webResUntreatAndUpdateStoreGroupReducer,
    webResCreateCustomer: webResCreateCustomerReducer,
    webResGet: webResGetReducer,
    webResUpdateAfterMargedCustomer: webResUpdateAfterMargedCustomerReducer,
    webResUpdateAfterMarged: webResUpdateAfterMargedReducer,
    webResUpdate: webResUpdateReducer,
    // csvRes
    csvResResisterFB: csvResResisterFBReducer,
    // eleCommon
    alertMessage: customAlertMessageReducer,
    cantCloseMessage: cantCloseAlertMessageReducer,

    // kimarisTargetSettings
    kimarisTargetSettingsGetList: kimarisTargetSettingsGetListReducer,
    kimarisTargetSettingsRegist: kimarisTargetSettingsRegistReducer,
    kimarisTargetSettingsUpdate: kimarisTargetSettingsUpdateReducer,
    kimarisTargetSettingsAllUpdate: kimarisTargetSettingsAllUpdateReducer,
    confirmMessage: customConfirmMessageReducer,

    // allocationSetting
    allocationSettingGet: allocationSettingGetReducer,
    allocationSettingCsvUpload: allocationSettingCsvUploadReducer,
    allocationSettingCsvDownload: allocationSettingCsvDownloadReducer,
    allocationSettingDelete: allocationSettingDeleteReducer,
    areaWishCityCountUpdate: areaWishCityCountUpdateReducer,
    allocationSettingGetOnAreaCode: allocationSettingGetOnAreaCodeReducer,

    // deviceType
    deviceTypeSlice: deviceTypeSliceReducer,

    // accessToken
    accessToken: accessTokenReducer,

    // pagepath
    pagePathSlice: pagePathReducer,

    // keymalis
    getContractInfo: getContractInfoReducer,

    // page
    crmPage: crmPageReducer,
    libraryPage: libraryPageReducer,

    // sms
    smsHistory: getSmsHistoryReducer,
    smsTalk: getSmsTalkReducer,

    // loading
    loading: loaddingReducer,

    // colorTheme
    colorTheme: colorChangerReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['confirmMessage/changeConfirmMessage'],
    },
  }),
});

export default store;
