import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const filestorageDownloadSlice = createSlice({
  name: 'filestorageDownload',
  initialState: { filestorageDownload: initialState },
  reducers: {
    downloadFilesApi: (state, data) => {
      state.filestorageDownload = data.payload;
    },
  },
});

export const { downloadFilesApi } = filestorageDownloadSlice.actions;

export default filestorageDownloadSlice.reducer;
