import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoGetHistoriesSlice = createSlice({
  name: 'hpinfoGetHistories',
  initialState: { hpinfoGetHistories: initialState },
  reducers: {
    hpinfoGetHistoriesApi: (state, data) => {
      state.hpinfoGetHistories = data.payload;
    },
  },
});

export const { hpinfoGetHistoriesApi } = hpinfoGetHistoriesSlice.actions;

export default hpinfoGetHistoriesSlice.reducer;
