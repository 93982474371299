import { Grid, makeStyles } from '@material-ui/core';
import commonTheme from '../styles/theme';
import UnregisteredRecord from '../unregisteredRecord';
import ActionSchedule from '../actionSchedule';
import { ActionScheduleProvider } from '../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles({
  root: {
    flexWrap: 'nowrap',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  side: {
    flexShrink: 0,
    width: '200px',
  },
  main: {
    flexGrow: 1,
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  unregisteredRecord: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

export default function UnregisteredRecordTest() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid className={classes.main}>
        <Grid className={classes.unregisteredRecord}>
          <UnregisteredRecord />
        </Grid>
        <ActionScheduleProvider>
          <ActionSchedule />
        </ActionScheduleProvider>
      </Grid>
    </Grid>
  );
}
