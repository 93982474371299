import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
export const smsCustomerCreateSlice = createSlice({
  name: 'smsCustomerCreate',
  initialState: { smsCustomerCreate: initialState },
  reducers: {
    createSmsCustomerApi: (state, data) => {
      state.smsCustomerCreate = data.payload;
    },
  },
});

export const { createSmsCustomerApi } = smsCustomerCreateSlice.actions;

export default smsCustomerCreateSlice.reducer;
