import {
  createContext,
  useContext,
} from 'react';
import { useCityJisCodes } from '../common/useCityJisCodes';

const Context = createContext({});

export function useCityJisCodesContext() {
  return useContext(Context);
}

export function CityJisCodesContextProvider(props) {
  const {
    children,
    onChange,
    defaultSelected,
  } = props;

  const { areaList, cityJisCodes } = useCityJisCodes();

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    areaList,
    cityJisCodes,
    onChange,
    defaultSelected,
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}
