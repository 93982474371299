import client from '../httpClient';

const idList4PotentialApi = (ids) => {
  const params = {
    customerId: ids?.some(item => !!item.customerId)
      // idsは[{ customerId: number }, ...]の場合
      ? ids.map(item => item.customerId)
      // [ number, ..., number ]の場合
      : ids,
  };
  return client.get('/customers-for-projects', { params });
};

export default idList4PotentialApi;
