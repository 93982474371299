import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  // TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';

import CustomerSearchRow, { basicFields } from './parts/customerSearchRow';
import HeaderCell from './parts/headerCell';
import CustomerSearchList from './parts/customerSearchList';
import Header from './header';
import CustomerOperation from '../pages/customerOperation/customerOperation';
import { crmPageActions, crmPageSelector } from '../../store/crmPage';

import { JOIN_DATA_TYPES } from '../../constants/crm';

const useStyles = makeStyles({
  root: {
    // width: 2076,
    minWidth: 2540,
    margin: '0 auto',
    padding: 0,
    background: '#fff',
  },
  tableContainer: {
    padding: '0 16px',
  },
  // table: {
  //   display: 'block',
  // },
  thead: {
    display: 'block',
    position: 'sticky',
    top: 104,
    zIndex: 1,
    background: '#fff',
  },
  tbody: {
    display: 'block',
  },
  tr: {
    display: 'flex',
    borderBottom: '2px solid #C8C8C8',
    paddingBottom: 4,
    marginBottom: 4,
  },
});

// ソート
function descendingComparator(a, b, orderBy) {
  // memberStatusCodeが0だとnullと混同されてソートがおかしくなるため分離
  if (orderBy === 'memberStatusCode') {
    const aVal = a[orderBy] === null ? -1 : a[orderBy];
    const bVal = b[orderBy] === null ? -1 : b[orderBy];
    if (bVal < aVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  } else {
    // nullとstringの比較の場合、常にfalseになってしまうので変換する
    const aVal = a[orderBy] === null ? '' : a[orderBy];
    const bVal = b[orderBy] === null ? '' : b[orderBy];
    if (bVal < aVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  }
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function CustomerSearch(props) {
  const { data, searchValue, setSearchValue, searchStart } = props;

  const classes = useStyles();
  const { customerId, joinData } = useSelector(crmPageSelector);
  const { total, customers } = data;
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  // ソート
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onRowClick = (row) => {
    // 選択された行のcustomer情報でStateを更新する
    // 反響未処理（WEB）選択された場合(WEB再反、WEB戻し登録可能になる)
    if (joinData.webResponseData && joinData.type !== JOIN_DATA_TYPES.MERGE) {
      dispatch(crmPageActions.setSelectedCustomerRow(row));
      !joinData.isShow && setOpen(true);
    } else if (customerId && joinData.isShow) {
      // データ結合
      // 同じ行が選択された場合、処理しない
      if (customerId === row.customerId) return;

      dispatch(crmPageActions.setJoinData({
        childResponseId: row.customerId,
      }));
    } else {
      // その他
      dispatch(crmPageActions.setSelectedCustomerRow(row));
      setOpen(true);
    }
  };

  return (
    <Grid className={classes.root} id="customerSearch">
      <Header getResponses={searchStart} count={total} />
      <CustomerSearchList
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        searchStart={searchStart}
      />
      {/* <TableContainer className={classes.tableContainer}> */}
      <Table>
        <TableHead className={classes.thead}>
          <TableRow className={classes.tr}>
            {basicFields.map((obj) => (
              <HeaderCell
                key={obj.key}
                obj={obj}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          className={classes.tbody}
        >
          {stableSort(customers, getComparator(order, orderBy)).map(
            (row, index) => {
              return (
                <CustomerSearchRow
                  key={String(index)}
                  row={row}
                  selected={
                    row.customerId === customerId || row.customerId === joinData.childResponseId
                  }
                  index={index}
                  onClick={() => onRowClick(row)}
                />
              );
            },
          )}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
      <CustomerOperation
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        searchStart={searchStart}
      />
    </Grid>
  );
}

export default CustomerSearch;
