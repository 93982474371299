import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../common/calendar';

const useStyles = makeStyles(() => ({
  root: {
    margin: '4px 8px',
    width: 'auto',
  },
  cal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '-14px',
    '& > div': {
      position: 'relative',
      border: 'none',
      padding: '8px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > div': {
        position: 'relative',
        width: 'auto',
        padding: '8px 0',
        '&:first-child': {
          marginRight: 4,
        },
        '&::after': {
          content: '""',
          display: 'block',
          width: 0,
          height: 0,
          borderWidth: '5px 5px 0',
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.54) transparent',
          position: 'absolute',
          top: 18,
          right: 6,
          pointerEvents: 'none',
        },
      },
      '& > p': { flexShrink: 0 },
      '& label': {
        width: '100%',
        borderBottom: '1px #8c8c8c solid',
        textAlign: 'left',
        minWidth: 92,
      },
      '& label > span': {
        fontFamily: 'Roboto',
        fontSize: 13,
        lineHeight: 1.6,
        fontWeight: 400,
        padding: '8px 24px 8px 0px',
      },
      '& > h6:empty': { display: 'none' },
    },
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 11,
    lineHeight: 1.6,
    letterSpacing: 0,
    height: 0,
    color: '#333',
    marginBottom: '-1px',
  },
}));

/**
 * 年月日フォーマット
 * @param {string} input YYYY-MM-DD
 * @returns YYYY/MM/DD
 */
function formatDate(input) {
  if (!input || typeof input !== 'string') return '';
  return input.replace(/-/g, '/');
}

function SearchDate(props) {
  const { initial } = props;

  const classes = useStyles();

  const [date, setDate] = useState(
    {
      startAt: initial.state1,
      endAt: initial.state2,
    },
  );

  const set = (value1, value2) => {
    setDate({
      ...date, startAt: value1, endAt: value2,
    });
    initial.setState(
      {
        ...initial.value,
        effectStartAt: formatDate(value1),
        effectEndAt: formatDate(value2),
      },
    );
  };

  const data = {
    label: [], // typeが'multiple'のときのみ配列
    require: false,
    inputType: 'date',
    type: 'multiple',
    startState: date.startAt,
    endState: date.endAt,
    setState: (start, end) => { return set(start, end); },
  };

  const onBlur = () => {
    if (date.startAt || date.endAt) {
      initial.search();
    }
  };

  // reset
  useEffect(() => {
    const newDate = {
      ...date,
    };
    let isChanged = false;
    if (!initial.state1 && date.startAt) {
      newDate.startAt = '';
      isChanged = true;
    }
    if (!initial.state2 && date.endAt) {
      newDate.endAt = '';
      isChanged = true;
    }
    if (isChanged) setDate(newDate);
  }, [initial.state1, initial.state2, date]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.label}>
        {initial.title}
      </Grid>
      <Grid className={classes.cal} onBlur={onBlur}>
        <Calendar data={data} />
      </Grid>
    </Grid>
  );
}

export default SearchDate;
