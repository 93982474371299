import customerUpdateCallApi from '../../apis/customer/customerUpdateApi';
import postSendMails from '../../apis/sendmail/sendMailsPostApi';
import postSendMailsBulk from '../../apis/sendmail/sendmailMailsBulkPostApi';
import sendmailTestApi from '../../apis/sendmail/sendmailTestApi';
import divisionsGetMangerApi from '../../apis/divisions/divisionsGetMangerApi';

import store from '../../store';
import { isOpen } from '../../store/common/apiMessageSlice';
import { validateJsonSize } from '../../commonFunction/validations';
import sendmailGetDefaultApi from '../../apis/sendmail/sendmailGetDefaultApi';
import libraryGetSignitureApi from '../../apis/library/libraryGetSignitureApi';
import { MAIL_FROM_SELF, MAIL_FROM_TEAM_MEMBER } from '../../constants';

const tmpFunc = () => {};
// メール送信
const sendMail = (
  param,
  successCallBack = tmpFunc,
  failureCallBack = tmpFunc,
) => {
  let result = '';
  // サイズチェック
  const error = validateJsonSize(param);
  if (error) {
    store.dispatch(isOpen({
      message: error,
      status: 'error',
    }));
    return result;
  }
  postSendMails(param, false)
    .then(async (res) => {
      const { message, description } = res;
      result = message;
      const msg = { message, description, status: 'success' };
      successCallBack();
      store.dispatch(isOpen(msg));
    })
    .catch((err) => {
      console.error({ err });
      failureCallBack(err);
    });
  return result;
};

// メール一斉送信
const sendMails = async (
  param,
  successCallBack = tmpFunc,
  failureCallBack = tmpFunc,
) => {
  let result = '';
  // サイズチェック
  const error = validateJsonSize(param);
  if (error) {
    store.dispatch(isOpen({
      message: error,
      status: 'error',
    }));
    return result;
  }
  await postSendMailsBulk(param, false)
    .then(async (res) => {
      const { message, description } = res;
      result = message;
      const msg = { message, description, status: 'success' };
      if (message === '類似のメールを過去30日以内に送っているため、メールを送信できません。') {
        return; // 呼び出しもとで確認モーダルとして表示される。
      }
      successCallBack(res);
      store.dispatch(isOpen(msg));
    })
    .catch((err) => {
      console.error({ err });
      failureCallBack(err);
    });
  return result;
};

// テストメール送信
const sendTestMail = (
  param,
  successCallBack = tmpFunc,
  failureCallBack = tmpFunc,
) => {
  let result = false;
  // サイズチェック
  const error = validateJsonSize(param);
  if (error) {
    store.dispatch(isOpen({
      message: error,
      status: 'error',
    }));
    return result;
  }
  sendmailTestApi(param)
    .then(() => {
      result = true;
    })
    .catch((err) => {
      console.error(err);
      result = false;
      failureCallBack(err);
    });
  successCallBack();
  return result;
};

// 署名取得
const getSignature = async (
  signature,
  customerId,
  mailFromCode,
  setMailSetting,
  successCallBack = tmpFunc,
  failureCallBack = tmpFunc,
) => {
  const thenFunc = (data) => {
    const copyData = structuredClone(data);
    const signList = {};
    if (!copyData.libraries.length) {
      signList[''] = '署名がありません';
    } else {
      const libs = copyData.libraries.sort((a, b) => a.libraryId - b.libraryId);
      libs.map((sign) => {
        signList[sign.libraryId] = sign.libraryName;
        return signList;
      });
    }
    setMailSetting((prevState) => ({
      ...prevState,
      sign: {
        ...prevState.sign,
        constants: {
          menus: signList,
        },
      },
    }));
    successCallBack(copyData);
  };

  if (mailFromCode === MAIL_FROM_SELF) { // from自分の場合
    // signatureがあればAPIを叩かない
    if (signature) {
      thenFunc(signature);
      return;
    }
    await libraryGetSignitureApi()
      .then((res) => thenFunc(res.data))
      .catch((err) => {
        console.error(err);
        failureCallBack(err);
      });
  } else if (mailFromCode === MAIL_FROM_TEAM_MEMBER) { // from担当課員の場合
    await sendmailGetDefaultApi({ customerId, mailFromCode })
      .then((res) => {
        const signList = {};
        if (!Array.isArray(res.data) || !res.data.length) {
          signList[''] = '署名がありません';
        } else {
          res.data.map((sign) => {
            signList[sign.libraryId] = sign.libraryName;
            return signList;
          });
        }
        setMailSetting((prevState) => ({
          ...prevState,
          sign: {
            ...prevState.sign,
            constants: {
              menus: signList,
            },
          },
        }));
        successCallBack(res.data);
      })
      .catch((err) => {
        console.error(err);
        failureCallBack(err);
      });
  }
};

// メール送信先選択
const updateMailAddress = async (
  customerId,
  params,
  successCallBack = tmpFunc,
  failureCallBack = tmpFunc,
  option = {},
) => {
  await customerUpdateCallApi(customerId, params, option)
    .then((res) => successCallBack(res))
    .catch((err) => {
      console.error(err);
      failureCallBack(err);
    });
};

// テストCC取得
const getTestCC = async (
  divisionId,
  successCallBack = tmpFunc,
  failureCallBack = tmpFunc,
) => {
  await divisionsGetMangerApi(divisionId)
    .then((res) => successCallBack(res))
    .catch((err) => {
      console.error(err);
      failureCallBack(err);
    });
};

const mailFunctions = {
  sendTestMail,
  getSignature,
  sendMail,
  sendMails,
  updateMailAddress,
  getTestCC,
};

export default mailFunctions;
