import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

/**
 * 【webres015】未処理反響情報取得
 * @module responseGetResponseByResponseIdSlice
 */
export const responseGetResponseByResponseIdSlice = createSlice({
  name: 'responseByResponseId',
  initialState: { responseByResponseId: initialState },
  reducers: {
    responseByResponseIdApi: (state, data) => {
      state.responseByResponseId = data.payload;
    },
  },
});

export const { responseByResponseIdApi } = responseGetResponseByResponseIdSlice.actions;

export default responseGetResponseByResponseIdSlice.reducer;
