import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
// eslint-disable-next-line import/no-cycle
import DayTable from './dayTable';
import ActRegistWindow from '../../pages/actRegist/actRegistWindow';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      overflowX: 'inherit',
      overflowY: 'scroll',
      height: 'calc(100vh - 134px)',
      paddingTop: 12,
      background: '#F3F3F3',
      paddingBottom: 58,
    },
  },
  table: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  thead: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      background: '#F3F3F3',
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 3,
    },
  },
  theadTr: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'relative',
    },
  },
  th: {
    borderBottom: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      width: 40,
      height: 38,
      padding: '0 4px 0 0',
      textAlign: 'right',
      borderRight: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '6px 4px',
      ...theme.overrides.font.weightBold,
      ...theme.overrides.font.sizeSmall3,
      ...theme.overrides.lineHeight.small,
    },
    '&:not(:first-child)': {
      [commonTheme.breakpoints.up('md')]: {
        width: 56,
      },
    },
    '&:not(:last-child)': {
      [commonTheme.breakpoints.up('md')]: {
        borderRight: '1px solid #C8C8C8',
      },
    },
    '&:first-child': {
      [commonTheme.breakpoints.down('sm')]: {
        background: '#fff',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 1,
      },
    },
    '&:nth-child(2)': {
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
    '& span': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
        transform: 'translateY(-5px)',
      },
    },
  },
  tbody: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      width: 'calc(100% - 40px)',
      // overflow: 'hidden',
    },
  },
  label: {
    background: '#F3F3F3',
    borderTop: '2px solid #C8C8C8',
    borderBottom: '2px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  labelTd: {
    position: 'relative',
    padding: 8,
    borderBottom: 'none',
    '& > span': {
      marginLeft: 20,
    },
    '& > span > span': {
      fontSize: 13,
    },
  },
  labelBtn: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    '& .MuiIconButton-label': {
      display: 'block',
      textAlign: 'left',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: theme.palette.primaryColor,
      margin: '2px 0 0 10px',
    },
  },
  contents: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  contentsTd: {
    padding: 0,
    borderBottom: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  contentsBox: {
    margin: 0,
  },
  headerBox: {
    position: 'relative',
    borderRight: '1px solid #C8C8C8',
    borderBottom: `2px solid ${theme.palette.primaryColor}`,
    width: 40,
    height: 52,
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'flex-end',
    transform: 'translateY(-13px)!important',
    paddingBottom: 4,
    background: '#fff',
  },
  headerWeek: {
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    margin: '0 auto',
    fontSize: 10,
    fontWeight: 700,
    color: theme.palette.primaryColor,
    textAlign: 'center',
    transform: 'translateY(0)!important',
  },
  headerDay: {
    background: theme.palette.primaryColor,
    color: '#fff',
    width: 32,
    height: 32,
    borderRadius: '50%',
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(0)!important',
  },
  button: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: 1101,
      minWidth: 'auto',
      padding: '0',
      position: 'fixed',
      bottom: '84px',
      right: '24px',
      width: '56px',
      height: '56px',
      backgroundColor: '#fff',
      borderRadius: '50%',
      boxShadow:
        '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  addIcon: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      color: theme.palette.primaryColor,
    },
  },
}));

function Row(props) {
  const {
    classes,
    baseClasses,
    setOpenCustomertree,
    targetDivisionTree,
    searchInput,
    actionsDay,
  } = props;
  const [open, setOpen] = useState(true);

  // 案内件数（親組織単位）
  const [num, setNum] = useState(0);
  let numCount = 0;
  const countUpParent = (c) => {
    numCount += c;
  };
  useEffect(() => {
    setNum(numCount);
  }, [searchInput.searchCount, targetDivisionTree, actionsDay.divisions]);

  return (
    <>
      <TableRow className={`${classes.label} rndDay-label`}>
        <TableCell
          colSpan={27}
          className={`${baseClasses.title3} ${classes.labelTd}`}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={classes.labelBtn}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span>{targetDivisionTree.groupName}<span>（案内 : {num}）</span></span>
        </TableCell>
      </TableRow>
      <TableRow className={classes.contents}>
        <TableCell colSpan={27} className={classes.contentsTd}>
          <Collapse in={open} timeout={0} unmountOnExit>
            <Box margin={1} className={classes.contentsBox}>
              <DayTable
                setOpenCustomertree={setOpenCustomertree}
                countUpParent={countUpParent}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function Day(props) {
  const {
    date,
    setOpenCustomertree,
  } = props;

  const {
    // nowDate,
    targetDivisionTree,
    setReacquisitionFlg,
    functions,
    searchInput,
    actionsDay,
    actionScheduleDate,
  } = useActionSchedule();

  const classes = useStyles();
  const baseClasses = commonStyles();

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  // 開閉用
  const [openNewActRegist, setOpenNewActRegist] = useState(false);
  // 目標
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });
  const [selectDivision, setSelectDivision] = useState({
    divId: 0,
    divisionName: '-',
  });

  const [initialData, setInitialData] = useState({});

  const th = [
    { time: '~ 9:00' },
    { time: '9:00' },
    { time: '9:30' },
    { time: '10:00' },
    { time: '10:30' },
    { time: '11:00' },
    { time: '11:30' },
    { time: '12:00' },
    { time: '12:30' },
    { time: '13:00' },
    { time: '13:30' },
    { time: '14:00' },
    { time: '14:30' },
    { time: '15:00' },
    { time: '15:30' },
    { time: '16:00' },
    { time: '16:30' },
    { time: '17:00' },
    { time: '17:30' },
    { time: '18:00' },
    { time: '18:30' },
    { time: '19:00' },
    { time: '19:30' },
    { time: '20:00' },
    { time: '20:30' },
    { time: '21:00 ~' },
  ];

  const weekDay = ['日', '月', '火', '水', '木', '金', '土'];

  return (
    <TableContainer component={Paper} className={`${classes.tableContainer} rndDay-tableContainer`}>
      <Table aria-label="collapsible table" className={`${classes.table} rndDay-table`}>
        <TableHead className={`${classes.thead} rndDay-thead`}>
          <TableRow className={`${classes.theadTr} rndDay-theadTr`}>
            <TableCell className={`${classes.th} rndDay-th-first`}>
              {isSP && (
                <span className={classes.headerBox}>
                  <span className={`${baseClasses.title5} ${classes.headerWeek}`}>
                    {weekDay[new Date(date).getDay()]}
                  </span>
                  <span className={`${baseClasses.title3} ${classes.headerDay}`}>
                    {new Date(date).getDate()}
                  </span>
                </span>
              )}
            </TableCell>
            <TableCell className={`${baseClasses.onlySp} ${classes.th} rndDay-th-sp`} />
            {/* 時間 */}
            {th.map((cell) => (
              <TableCell
                key={cell.time}
                className={`${baseClasses.title5} ${classes.th} rndDay-th`}
              >
                <span>{cell.time}</span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={`${classes.tbody} rndDay-tbody`}>
          {(targetDivisionTree && Object.keys(targetDivisionTree).length !== 0) && (
            <Row
              classes={classes}
              baseClasses={baseClasses}
              setOpenCustomertree={setOpenCustomertree}
              targetDivisionTree={targetDivisionTree}
              searchInput={searchInput}
              actionsDay={actionsDay}
            />
          )}
        </TableBody>
      </Table>
      <Button className={`${classes.button} rndDay-button`} onClick={() => { setOpenNewActRegist(true); }}>
        <AddIcon className={classes.addIcon} />
      </Button>
      {/* [m06-4]画面 新規登録 */}
      {openNewActRegist && (
        <ActRegistWindow
          open={openNewActRegist}
          onClose={() => { setOpenNewActRegist(false); }}
          setReacquisitionFlg={setReacquisitionFlg}
          values={initialData}
          setUpdateData={setInitialData}
          setOpenCustomertree={setOpenCustomertree}
          actRegistFlag={Boolean(true)}
          editableFlag={Boolean(false)}
          targetObj={targetObj}
          setTargetObj={setTargetObj}
          archieveObj={archieveObj}
          setArchieveObj={setArchieveObj}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          isSource={Boolean(false)}
          createUpdateFunc={functions.schedulesPostApiFunction}
          date={actionScheduleDate}
        />
      )}
    </TableContainer>
  );
}
