import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const actCreateHistorySlice = createSlice({
  name: 'actCreateHistory',
  initialState: { actCreateHistory: initialState },
  reducers: {
    actCreateHistory: (state, data) => {
      state.actCreateHistory = data.payload;
    },
  },
});

export const { actCreateHistory } = actCreateHistorySlice.actions;

export default actCreateHistorySlice.reducer;
