import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, List, ListItem, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import commonStyles from '../../styles';
import LibraryTree from '../../common/libraryTree';
import SettingWindow from '../parts/sp/settingWindow';

import { getBase64, randomId } from '../../../commonFunction';
import { compressImage } from '../../../commonFunction/compressImage';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { MAIL_SENDING, TESTMAIL_SENDING } from '../../../constants/loading';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  navList: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  navItem: {
    padding: 0,
    flex: 0,
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      flexWrap: 'wrap',
      whiteSpace: 'pre',
    },
    '&:not(:last-child)': {
      marginRight: '8px',
    },
  },
  navIcon: {
    padding: '4px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiButton-startIcon > svg': {
      fill: theme.palette.primaryColor,
      fontSize: '24px',
    },
    '&.isBook svg': {
      fontSize: '18px',
    },
    '&.isSend svg': {
      fontSize: '18px',
    },
  },
  button: {
    minWidth: 'auto',
    '&.disabled': {
      pointerEvents: 'none',
      '& > .MuiButton-label': {
        color: '#C8C8C8',
      },
      '& svg': {
        fill: '#C8C8C8',
      },
    },
  },
  input: {
    display: 'none',
  },
  isFile: { color: theme.palette.primaryColor },
  disable: {
    pointerEvents: 'none',
    '& span': { color: '#C8C8C8 !important' },
    '& svg': { fill: '#C8C8C8 !important' },
  },
}));

function ModalComponent(props) {
  const {
    handleClose,
    type, values,
    open, setOpen,
    selectLibrary, setSelectLibrary,
    selectDir, setSelectDir,
  } = props;
  let component;

  switch (type) {
    case 'library':
      component = <LibraryTree
        isOpenTree={open}
        setIsOpenTree={setOpen}
        selectDir={selectDir}
        setSelectDir={setSelectDir}
        selectLibrary={selectLibrary}
        setSelectLibrary={setSelectLibrary}
        isDialog
        isPreview
      />;
      break;
    // TODO: m22作成後、入れ込み
    case 'sendTest':
    case 'send':
      component = '';
      break;
    case 'more':
      component = <SettingWindow
        handleClose={handleClose}
        values={values}
        open={open}
        setOpen={setOpen}
      />;
      break;
    default:
      return '';
  }
  return component;
}

function CreateWindowNav(props) {
  const {
    sendMail,
    handleClose,
    values,
    editorState,
    setEditorState,
    setMailBodyKey,
    uploads,
    setUploads,
    isDisabled,
    isTestSent,
    afterCall = false,
    testSendHandler,
    // ここからライブラリ選択ツリー用
    selectDir,
    setSelectDir,
    selectLibrary,
    setSelectLibrary,
    taioRirekiFlg,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const { hasLoading } = useLoading();

  const dispatch = useDispatch();

  const [openLibrary, setOpenLibrary] = useState(false); // ライブラリ用モーダルの制御
  const [openButtonManagerWindow, setOpenButtonManagerWindow] = useState(false); // 担当者選択モーダルの制御
  const [openSendConfirmWindow, setOpenSendConfirmWindow] = useState(false); // 送信確認用モーダルの制御
  const [openSettingMore, setOpenSettingMore] = useState(false); // メニュー用モーダルの制御

  // 担当者選択モーダルのonClose
  const handleButtonWindow = () => {
    setOpenButtonManagerWindow(!openButtonManagerWindow);
  };

  const changeUploadFile = async (event) => {
    const { files = [] } = event.target;
    if (files.length === 0) return;
    const imageExts = '.(jpeg|jpg|png|gif)$'; // 画像の拡張子・識別用

    const firstFile = files[0];

    // 画像の場合、Editorにデータを添付
    if (firstFile.name.match(imageExts)) {
      const compressFile = await compressImage(firstFile);
      const { base64 } = await getBase64(compressFile);
      const newEditorState = `${editorState}\n<img src="${base64}" alt="" />`;
      setEditorState(newEditorState);
      setMailBodyKey(randomId());
    } else {
      getBase64(firstFile).then(({ base64, fileObj }) => {
        const file = base64.replace(/^data:(.*;base64,)?/, '');
        setUploads([...uploads, { fileName: fileObj.name, mimeType: fileObj.type, file }]);
      });
    }

    event.target.value = '';
  };

  const fileButton = () => (
    <label htmlFor="upload-button">
      <AttachFileIcon className={classes.isFile} />
      <input
        className={classes.input}
        type="file"
        id="upload-button"
        name="upload-button"
        onChange={changeUploadFile}
      />
    </label>
  );

  const disabledModalButton = (data) => {
    // 元のコード
    // (isDisabled && data.type === 'sendTest') || (!isTestSent && data.type === 'send')
    switch (data.type) {
      case 'sendTest':
        return isDisabled;
      case 'send':
        // 対応履歴の不通からの登録の場合はテスト送信は存在しないためisDisabledで判断
        // そうではない場合はisTestSentで判断
        return afterCall ? isDisabled : !isTestSent;
      default:
        return false;
    }
  };
  // 確認モーダルからOKボタンを押した時の処理
  const customHandleClose = () => sendMail();
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '確認',
      msgList: ['お客様へメールを送信してもよろしいですか？', '送信内容が適切かどうかすべてチェックしましたか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: '',
          classes: common.buttonsSecondary,
        },
        {
          label: 'OK',
          set: customHandleClose,
          classes: common.buttonsPrimary,
        },
      ],
    }));
  };
  const modalButton = (data) => (
    <>
      <Button
        className={`${common.title5} ${data.class} ${disabledModalButton(data) ? 'disabled' : ''} ${classes.button} ${classes.navIcon}`}
        startIcon={<data.iconTag />}
        onClick={() => {
          if (data.type === 'file') return;
          if (data.state.open !== '') {
            data.state.setOpen(!data.state.open);
          }
          if (data.type === 'sendTest') {
            testSendHandler();
          }
          if (data.type === 'send') {
            confirmOpen();
          }
        }}
      >
        {data.title}
        {data.children ? data.children : null}
      </Button>
      <ModalComponent
        handleClose={handleClose}
        initialData={values}
        type={data.type}
        values={data.values}
        open={data.state.open}
        setOpen={data.state.setOpen}
        onClose={data.state.onClose}
        open2={data.state.open2}
        setOpen2={data.state.setOpen2}
        editorState={editorState}
        setEditorState={setEditorState}
        uploads={uploads}
        setUploads={setUploads}
        // ここからライブラリ選択ツリー用
        selectDir={selectDir}
        setSelectDir={setSelectDir}
        selectLibrary={selectLibrary}
        setSelectLibrary={setSelectLibrary}
      />
    </>
  );

  // アイコンリスト
  const navItems = [
    { id: 1, type: 'library', values: values.library, title: 'ライブラリ', class: 'isBook', state: { open: openLibrary, setOpen: setOpenLibrary }, iconTag: MenuBookIcon, invisible: afterCall },
    { id: 2, type: 'file', values: '', title: '', class: 'isFile', iconTag: AttachFileIcon, invisible: false },
    { id: 3, type: 'sendTest', values: values.tcc, title: 'テスト', class: 'isSend', state: { open: openButtonManagerWindow, setOpen: setOpenButtonManagerWindow, onClose: handleButtonWindow }, iconTag: SendIcon, invisible: false },
    { id: 4, type: 'send', values: '', title: '', class: 'isSendOutline', state: { open: openSendConfirmWindow, setOpen: setOpenSendConfirmWindow }, iconTag: SendOutlinedIcon, invisible: false },
    { id: 5, type: 'more', values: { tcc: values.tcc, sendTime: values.sendTime }, title: '', class: '', state: { open: openSettingMore, setOpen: setOpenSettingMore }, iconTag: MoreHorizOutlinedIcon, invisible: afterCall },
  ];

  const disableTerms = (type) => {
    if (
      taioRirekiFlg
      || (type === 'send' && hasLoading(MAIL_SENDING))
      || (type === 'sendTest' && hasLoading(TESTMAIL_SENDING))
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid>
      <List className={classes.navList}>
        {navItems.filter(data => !data.invisible).map((data) => (
          <ListItem
            key={data.id}
            className={`${classes.navItem} ${disableTerms(data.type) ? classes.disable : ''}`}
          >
            {data.type === 'file' ? fileButton() : modalButton(data)}
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}

export default CreateWindowNav;
