/**
 * @name useLocalStorage
 * @description - ローカルストレージに保存や削除を実行する関数。有効期限は無し。また、Keyを取得しない場合は現在のURLをKeyとする為
 * １画面で複数LocalStorageを扱う場合以外は「取得も追加もKeyの情報は必要ない」
 * @returns {fn}
 */
function useLocalStorage() {
  // 対象ストレージ値取得
  const getLocalStorage = (key = window.location.href) => {
    return localStorage.getItem(key);
  };

  // 任意のKey名（重複しないように）とValue
  const addLocalStorage = (value, key = window.location.href) => {
    if (key && value) {
      localStorage.setItem(key, value);
    }
  };

  // 上記任意のKey名に紐づいたValueを削除
  const removeLocalStorage = (key = window.location.href) => {
    if (key) localStorage.removeItem(key);
  };

  return {
    getLocalStorage,
    addLocalStorage,
    removeLocalStorage,
  };
}

export default useLocalStorage;
