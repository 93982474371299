import { Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    height: '100%',
    background: 'transparent',
    padding: 0,
    '&::before': {
      display: 'none',
    },
    '& .MuiIconButton-edgeEnd': {
      margin: 0,
      padding: 0,
    },
  },
  label: {
    flexDirection: 'row-reverse',
    padding: 0,
    minHeight: 'auto !important',
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  icon: {
    fill: theme.palette.primaryColor,
    height: '16px',
    width: '16px',
  },
  title4: {
    marginRight: '8px',
    marginLeft: '4px',
  },
  detail: {
    padding: 0,
  },
}));

function CustomAccordion(props) {
  const { label01, label02, children } = props;
  const common = commonStyles();
  const classes = useStyles();

  return (
    <Accordion
      className={classes.root}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMore className={classes.icon} />}
        className={classes.label}
      >
        <Typography className={`${common.title4} ${classes.title4}`}>{label01}</Typography>
        <Typography className={common.small}>{label02}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.detail}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordion;
