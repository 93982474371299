import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../styles';
import ProspectsListTableRow from './prospectsListTableRow';
import ProspectsCustomerContainers from '../../../../containers/prospectsList/prospectsCustomerContainers';
import { CustomerMainProvider } from '../../../../containers/customerMain/customerMainContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
  },
  control: {
    position: 'absolute',
    top: 8,
    left: 424,
    display: 'flex',
    alignItems: 'center',
  },
  controlLbl: {
    color: 'rgba(51, 51, 51, 0.6)',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginRight: 8,
  },
  tablePagination: {
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-selectRoot': {
      margin: '0 40px 0 0',
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
      color: 'rgba(51, 51, 51, 0.6)',
    },
    '& .MuiSelect-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
      color: 'rgba(51, 51, 51, 0.6)',
    },
    '& .MuiIconButton-root': {
      padding: '0 5px',
    },
  },
  tableContainer: {
    height: 'calc(100vh - 140px)',
    border: '1px solid #8C8C8C',
  },
  table: {
    display: 'block',
  },
  tableHead: {
    display: 'block',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#fff',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px solid #8C8C8C',
  },
  tableCellTh: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.2',
    letterSpacing: '0',
    padding: '2px 0',
    color: '#8C8C8C',
    textAlign: 'center',
    borderBottom: 'none',
    width: '5%',
    '&:not(:last-child)': {
      borderRight: '1px solid #8C8C8C',
    },
    '&.userLastName': {
      width: '5%',
    },
    '&.largeResponseTypeName, &.effectAt, &.lastGuideAt, &.nextGuideAt': {
      width: '5%',
    },
    '&.isShare, &.customerId': {
      width: '4.6%',
    },
    '&.lastName': {
      background: 'rgba(252, 224, 52, 0.24)',
      width: '5.2%',
    },
    '&.address': {
      width: '11.5%',
    },
    '&.company': {
      width: '7.5%',
    },
    '&.detachedHouseBudgetMin, &.annualIncome, &.deposit, &.abodeNowRent': {
      width: '4.25%',
    },
    '&.wishArea': {
      width: '10.3%',
    },
    '&.totalGuidanceCount, &.contactTime': {
      width: '3.3%',
      background: 'rgba(252, 224, 52, 0.24)',
    },
    '&.summary': {
      width: '7.5%',
    },
    '& .MuiTableSortLabel-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px 8px 2px 20px',
      '& .MuiSvgIcon-root': {
        fontSize: 12,
        marginRight: 0,
        color: `${theme.palette.primaryColor}!important`,
      },
    },
    '& > p': {
      background: '#F3F3F3',
      borderTop: '2px solid #8C8C8C',
      borderRight: '1px solid #C8C8C8',
      margin: 0,
      padding: '5px 8px',
    },
  },
  tableBody: {
    display: 'block',
  },
  button: {
    background: '#F3F3F3',
    borderTop: '2px solid #8C8C8C',
    padding: '7px 0 7px 6px',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonIcon: {
    color: '#333',
    fontSize: 14,
    margin: '0 2px 0 0',
  },
  buttonTxt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  tableSortLabel: {
    display: 'block',
  },
  checkBox1: {
    padding: '4px 8px',
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: 'userLastName', label: '担当' },
  { id: 'largeResponseTypeName', label: '媒体' },
  { id: 'effectAt', label: '反響日' },
  { id: 'isShare', label: '共有' },
  { id: 'customerId', label: '顧客ID' },
  { id: 'lastName', label: 'お名前' },
  { id: 'address', label: '住所' },
  { id: 'company', label: '勤務先' },
  { id: 'detachedHouseBudgetMin', label: <span>ご予算<br />(万円)</span> },
  { id: 'annualIncome', label: <span>年収<br />(万円)</span> },
  { id: 'deposit', label: <span>頭金<br />(万円)</span> },
  { id: 'abodeNowRent', label: <span>家賃<br />(万円)</span> },
  { id: 'wishArea', label: '希望地域' },
  { id: 'totalGuidanceCount', label: <span>案内<br />回数</span> },
  { id: 'contactTime', label: <span>接触<br />時間</span> },
  { id: 'summary', label: '概要メモ' },
  { id: 'lastGuideAt', label: '前回案内' },
  { id: 'nextGuideAt', label: '次回案内' },
];

function EnhancedTableHead(props) {
  const { classes } = props;

  const baseClasses = commonStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow className={classes.tableRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={`${baseClasses.title4} ${classes.tableCellTh} ${headCell.id}`}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props) {
  const {
    list,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    total,
  } = props;

  const classes = useStyles();

  // localData
  const [localData, setLocalData] = useState({
    direct: false,
    userId: 0,
    customer: false,
    customerId: 0,
    customerData: {},
  });
  const [lastRowFlg, setLastRowFlg] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableContainer = document.querySelector('.prospectsListTableContainer');
  const checkTableHeight = () => {
    const table = document.querySelector('.prospectsListTable');

    if (tableContainer) {
      setLastRowFlg(tableContainer.clientHeight < table.clientHeight);
    }
  };

  // ランクを押した時の開閉
  const rowClose = (code, flg) => {
    const elements = Array.from(document.getElementsByClassName('userLevel-' + code));
    elements.forEach((element) => {
      element.style.display = flg ? 'flex' : 'none';
    });
    checkTableHeight();
  };

  useEffect(() => {
    // ランク
    const elements = Array.from(document.getElementsByClassName('userLevel'));
    let rank = '';
    elements.forEach((element) => {
      if (rank === element.textContent) {
        element.style.display = 'none';
      } else {
        element.style.display = 'block';
        rank = element.textContent;
      }
    });
  });

  useEffect(() => {
    checkTableHeight();
  }, [list]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid className={classes.control}>
          <Typography className={classes.controlLbl}>表示行数</Typography>
          <TablePagination
            rowsPerPageOptions={[20, 40, 60]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tablePagination}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          />
        </Grid>
        <TableContainer
          id="print-target"
          className={`${classes.tableContainer} prospectsListTableContainer`}
        >
          <Table
            className={`${classes.table} prospectsListTable`}
            aria-labelledby="prospectsListTable"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
            />
            <TableBody
              id="instructionListTablebody"
              className={classes.tableBody}
            >
              {list.customers.map((row, index) => {
                const labelId = `rank-${index}`;
                return (
                  <ProspectsListTableRow
                    key={row.customerId + '_' + index.toString()}
                    row={row}
                    labelId={labelId}
                    rowClose={rowClose}
                    localData={localData}
                    setLocalData={setLocalData}
                    lastRowFlg={lastRowFlg && index === list.customers.length - 1}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <InstructionsWindow
        open={localData.direct}
        onClose={() => { return setLocalData({ ...localData, direct: false, userId: '' }); }}
        directId={localData.userId}
      /> */}
      <CustomerMainProvider>
        <ProspectsCustomerContainers
          localData={localData}
          setLocalData={setLocalData}
        />
      </CustomerMainProvider>
    </div>
  );
}
