/* eslint-disable */
import InstructionReportContainers from '../../../containers/common/instructionReport/instructionReportContainers';

function InstructionReport(props) {
  const { customer } = props;

  return (
    <div>
      <InstructionReportContainers customer={customer} />
    </div>
  );
}

export default InstructionReport;
