import { useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import { RESPONSE_TYPE } from '../../../constants/responseType';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  wrap: {
    width: '22.3%',
    position: 'relative',
    '&:not(:last-child)': {
      marginRight: '3.6%',
    },
    '&:not(:first-child)::after': {
      content: '""',
      backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="%238c8c8c" d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" /></svg>\')',
      position: 'absolute',
      width: '6.5%',
      height: '100%',
      top: 0,
      left: '-11.6%',
      display: 'block',
      backgroundRepeat: 'no-repeat',
    },
  },
  list: {
    height: '330px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    border: '1px #c8c8c8 solid',
    marginTop: '8px',
    padding: 0,
  },
  item: {
    borderBottom: '1px #c8c8c8 solid',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  itemNotHover: {
    borderBottom: '1px #c8c8c8 solid',
    pointerEvents: 'none',
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));

function ResponseTypeList(props) {
  const { data, setState, state, noChange } = props;
  const common = commonStyles();
  const classes = useStyles();

  // リスト選択用ローカルステート
  const [level, setLevel] = useState(0);

  // リストのスクロール位置取得
  const [scrollPosition, setScrollPosition] = useState({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });

  const makeSelectedList = () => {
    if (state[0] === undefined) return [];
    const l = [];
    let lv = 0;
    state
      .sort((a, b) => a.responseTypeLevelCode - b.responseTypeLevelCode)
      .map((s) => {
        lv += 1;
        return l.push(s.responseTypeId);
      });
    setLevel(lv);
    return l;
  };

  const [selectedResponseTypeId, setSelectedResponseTypeId] = useState(() => {
    return makeSelectedList();
  });

  useEffect(() => {
    setSelectedResponseTypeId(makeSelectedList());
  }, [state]);

  const childArray = RESPONSE_TYPE;

  const { responseTypes = [] } = data;

  const handleListItemClick = (clickLevel, id, element) => {
    setScrollPosition({
      ...scrollPosition,
      [`level${clickLevel}`]: element.target.parentElement.scrollTop,
    });
    if (selectedResponseTypeId.includes(id)) {
      selectedResponseTypeId.splice(clickLevel - 1, 4);
      setLevel(clickLevel - 1);
    } else if (selectedResponseTypeId[clickLevel - 1]) {
      selectedResponseTypeId.splice(clickLevel - 1, 4, id);
      setLevel(clickLevel);
    } else {
      selectedResponseTypeId.push(id);
      setLevel(clickLevel);
    }
    setSelectedResponseTypeId(selectedResponseTypeId);

    const stateList = responseTypes.filter((item) => {
      const { responseTypeId } = item;
      return selectedResponseTypeId.includes(responseTypeId);
    });

    setState(
      stateList.sort((a, b) => {
        return a.responseTypeLevelCode < b.responseTypeLevelCode ? -1 : 1;
      }),
    );
  };

  const filteredList = (filterLevel) => {
    const filtered = responseTypes.filter((item) => {
      const { responseTypeLevelCode, responseTypeParentId } = item;
      if (filterLevel === 1) return responseTypeLevelCode === filterLevel;
      return responseTypeLevelCode === filterLevel
        && selectedResponseTypeId.includes(responseTypeParentId);
    });

    return filtered.map((item) => (
      <ListItem
        button
        selected={selectedResponseTypeId.includes(item.responseTypeId)}
        onClick={(e) => handleListItemClick(filterLevel, item.responseTypeId, e)}
        key={`item${item.responseTypeId}`}
        className={noChange ? classes.itemNotHover : classes.item}
      >
        {item.responseTypeName}
      </ListItem>
    ));
  };

  useEffect(() => {
    setTimeout(() => {
      const scrollTargets = document.getElementsByClassName('is-scrolled');
      for (let index = 0; index < scrollTargets.length; index += 1) {
        const el = scrollTargets[index];
        if (noChange) {
          const target = el.getElementsByClassName('Mui-selected')[0];
          el.scrollTo({
            top: target ? target.offsetTop : 0,
            left: 0,
          });
        } else {
          el.scrollTo({
            top: el.dataset.scroll,
            left: 0,
          });
        }
      }
    }, 2);
  }, [scrollPosition, state]);

  function Child() {
    const li = [];
    childArray.map((c, i) => {
      if (c.responseTypeLevelCode > level + 1) return false;
      return li.push(
        <Grid
          item
          className={classes.wrap}
          key={`c${c.responseTypeLevelCode}`}
        >
          <Typography className={common.title4}>{c.label}</Typography>
          <List
            className={`${classes.list} is-scrolled`}
            data-scroll={scrollPosition[`level${i + 1}`]}
          >
            {filteredList(c.responseTypeLevelCode)}
          </List>
        </Grid>,
      );
    });
    return li;
  }

  return (
    <Grid className={classes.root}>
      <Child />
    </Grid>
  );
}

export default ResponseTypeList;
