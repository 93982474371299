import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, Grid, Button, AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import commonStyles from '../../styles';
import MailSelect from '../parts/sp/mailSelect';
import CreateWindowNav from './createWindowNav';
import MailTextfield from '../parts/textfield';
import ChangeToSp from '../parts/sp/changeTo';
import MailTitleAndBody from '../parts/sp/titleAndBody';
import MailAppendFile from '../parts/sp/mailAppendFile';
import SmsTalkBodyContainer from '../../../containers/sms/smsTalkContainers';
import SmsSendContainer from '../../../containers/sms/smsSendContainers';
import { MAIL_SENDING, TESTMAIL_SENDING } from '../../../constants/loading';
import { useLoading } from '../../../hooks';
import { MAIL_FROM_SELF } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      background: '#fff',
    },
    '& .MuiDialog-scrollPaper': {
      background: '#fff',
    },
  },
  tabPanel: {
    height: '100%',
    overflow: 'hidden',
    '& > .MuiGrid-root': {
      height: '100%',
      '& > span': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navList: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  navItem: {
    padding: 0,
    flex: 0,
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      flexWrap: 'wrap',
      whiteSpace: 'pre',
    },
    '&:not(:last-child)': {
      marginRight: '8px',
    },
  },
  navIcon: {
    padding: '4px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiButton-startIcon > svg': {
      fill: theme.palette.primaryColor,
      fontSize: '24px',
    },
    '&.isBook svg': {
      fontSize: '18px',
    },
    '&.isSend svg': {
      fontSize: '18px',
    },
  },
  button: {
    minWidth: 'auto',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    padding: '32px 24px 24px',
    justifyContent: 'space-between',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
  },
  middle: {
    background: '#fff',
    padding: 0,
    borderTop: 'none',
  },
  middleSms: {
    height: 'auto',
  },
  middleSmsContent: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100%',
    height: 'calc(100vh - 118px)',
  },
  middleSmsBody: {
    flex: '1 1 auto',
    minHeight: 100,
  },
  middleSmsKind: {
    flex: '0 0 auto',
  },
  middleSmsInputArea: {
    flex: '0 0 auto',
  },
  middleMail: {
    height: 'calc(100vh - 126.5px)',
  },
  contents: {
    padding: '12px 16px',
    flexWrap: 'initial',
    flex: 1,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  blue: {
    color: theme.palette.primaryColor,
    marginRight: '16px',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid p={3}>
          <span>{children}</span>
        </Grid>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MailCreateWindowSpAfter(props) {
  const {
    user,
    open,
    setOpen,
    afterClose,
    selectType,
    setSelectType,
    meta,
    setMeta,
    setting,
    afterCallFunctions,
    btnLabel,
    setDrawerOpen,
    type,
    editorState,
    setEditorState,
    closeAndChange,
  } = props;
  const { addLoading, removeLoading } = useLoading();
  const classes = useStyles();
  const common = commonStyles();

  // モーダル開閉用のローカルステート
  const [isDisabled, setIsDisabled] = useState(false); // 送信先が設定されていない場合、テスト送信ボタンを非活性化
  const [isSendOk, setIsSendOk] = useState(false); // テスト送信ボタンをクリックで送信ボタンを活性化
  const [uploads, setUploads] = useState([]); // アップロードされたデータの格納先
  const [value, setValue] = useState(selectType);

  // 署名一覧を格納
  const signature = useSelector(
    (state) => state.libraryGetSigniture.libraryGetSigniture,
  );

  // SMS送信完了後
  const [openAlert, setOpenAlert] = useState(false);
  const onSended = () => {
    setEditorState('');
    // 送信した後クローズする場合は以下
    // afterClose();
  };

  // モーダル
  const handleOpen = () => {
    if (closeAndChange) {
      // SMSの時のみ
      closeAndChange();
    }
    setDrawerOpen(false);
    setValue(type);
    return setOpen(true);
  };

  const handleClose = () => {
    return setOpen(false);
  };

  const handleChange = (e, newVal) => {
    setSelectType(newVal);
  };

  // テスト送信
  const [isTestSent, setIsTestSent] = useState(false);

  // テストメールの送信パラメータ
  const [testMailParam, setTestMailParam] = useState({
    mailSubject: '',
    mailBody: editorState,
    mailFromCode: 1,
    customerId: user.customerId,
    isNotSetCustomer: 0,
  });

  const testSendHandler = () => {
    addLoading(TESTMAIL_SENDING);
    // 送信が成功したら送信ボタン活性
    const result = afterCallFunctions.sendTestMail(testMailParam);
    setIsTestSent(result);
    setTimeout(() => removeLoading(TESTMAIL_SENDING), 2000);
  };

  // メールの送信パラメータ
  const [mailParam, setMailParam] = useState({
    mailSubject: '',
    mailBody: editorState,
    mailFromCode: 1,
    customerIds: user.customerId ? [user.customerId] : user.ids,
    isNotSetCustomer: user.customerId || user.ids ? 0 : 1,
    mailTypeCode: user.ids ? 3 : 1,
  });

  const sendMail = () => {
    addLoading(MAIL_SENDING);
    afterCallFunctions.sendMail(mailParam);
    afterClose();
    setTimeout(() => removeLoading(MAIL_SENDING), 2000);
  };

  // SMS送信用パラメータの設定
  const [sendParams, setSendParams] = useState({
    phoneNumber: meta.sms,
    smstext: editorState,
    customerId: user.customerId,
  });

  useEffect(() => {
    setSendParams({
      phoneNumber: meta.sms,
      smstext: editorState,
      customerId: user.customerId,
    });
    const addParam = {};
    if (meta.title !== '') {
      addParam.mailSubject = meta.title;
    }
    if (meta.sign !== 0) {
      addParam.signatureLibraryId = meta.sign;
      addParam.signature = meta.from === MAIL_FROM_SELF
        ? signature.libraries.find(s => {
          return s.libraryId === meta.sign;
        })?.mailBody
        : null;
    }
    if (meta.kind !== 0) {
      addParam.libraryId = meta.kind;
    }
    // mailFromCodeを反映
    addParam.mailFromCode = meta.from;
    // infoCodeを反映
    if (meta.from === 3 && meta.info !== 0) {
      addParam.infoCode = meta.info;
    }
    setTestMailParam({ ...testMailParam, ...addParam });
    setMailParam({ ...mailParam, ...addParam });
  }, [meta, editorState]);

  useEffect(() => {
    setTestMailParam({
      ...testMailParam,
      mailBody: editorState,
    });
    setMailParam({
      ...mailParam,
      mailBody: editorState,
    });
    setMeta({ ...meta, content: editorState });
  }, [editorState]);

  useEffect(() => {
    const files = uploads.map(d => ({ base64: d.file, fileName: d.fileName }));
    setTestMailParam({ ...testMailParam, files });
    setMailParam({ ...mailParam, files });
  }, [uploads]);

  // test送信などの活性・非活性制御 - afterCallではtest送信がないけど、送信で使用
  useEffect(() => {
    const requiredFields = [
      Boolean(setting.to.list),
      Boolean(setting.title.state),
    ];
    setIsDisabled(!requiredFields.every((i) => i));
  }, [setting.to.list, setting.title.state]);

  return (
    <>
      <Button
        className={common.buttonsPrimary}
        onClick={handleOpen}
      >
        {btnLabel}
      </Button>
      <Dialog open={open} onClose={handleClose} scroll="paper" className={`${common.maxWidthDialog} ${classes.dialog}`}>
        <AppBar position="sticky">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="SMS" {...a11yProps(0)} />
            <Tab label="メール" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        {/* SMS */}
        <TabPanel value={value} index={0} className={classes.tabPanel}>
          <Grid item className={`${common.header} ${classes.header}`}>
            <Button
              className={classes.button}
              onClick={afterClose}
            >
              <ArrowBackIosIcon className={classes.backIcon} />
            </Button>
            <Grid style={{ margin: 'auto', textAlign: 'center' }}>
              <Typography className={common.title2}>
                {user.lastName} {user.firstName} 様
              </Typography>
              {![null, '-'].includes(meta.sms) && (
                <Typography className={common.small}>
                  {meta.sms}
                </Typography>
              )}
            </Grid>
          </Grid>
          <DialogContent dividers className={`${common.dialogContent} ${classes.middle} ${classes.middleSms}`}>
            <Grid className={classes.middleSmsContent}>
              <Grid className={classes.middleSmsBody}>
                <SmsTalkBodyContainer />
              </Grid>
              <Grid className={classes.middleSmsKind}>
                <MailSelect
                  values={setting.kindSms}
                />
              </Grid>
              <Grid container className={`${classes.contents} ${classes.middleSmsInputArea}`}>
                <MailTitleAndBody
                  values={setting.content}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
                <SmsSendContainer
                  text={setting.content}
                  onSended={onSended}
                  openAlert={openAlert}
                  setOpenAlert={setOpenAlert}
                  sendParams={sendParams}
                  functions={{ postSms: afterCallFunctions.sendSms }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </TabPanel>
        {/* メール */}
        <TabPanel value={value} index={1}>
          <Grid item className={`${common.header} ${classes.header}`}>
            <Button
              className={classes.button}
              onClick={afterClose}
            >
              <ArrowBackIosIcon className={classes.backIcon} />
            </Button>
            <Grid>
              <CreateWindowNav
                handleClose={handleClose}
                values={setting}
                editorState={editorState}
                setEditorState={setEditorState}
                uploads={uploads}
                setUploads={setUploads}
                isDisabled={isDisabled}
                isSendOk={isSendOk}
                setIsSendOk={setIsSendOk}
                testSendHandler={testSendHandler}
                isTestSent={isTestSent}
                sendMail={sendMail}
                afterCall
              />
            </Grid>
          </Grid>
          <DialogContent dividers className={`${common.dialogContent} ${classes.middle} ${classes.middleMail}`}>
            <Grid className={classes.dialogContent}>
              <MailSelect
                values={setting.kind}
              />
              <ChangeToSp
                values={setting.to}
                user={user}
                updateFunction={afterCallFunctions.updateAddress}
              />
              <MailSelect values={setting.from} />
              {meta.from === 3 ? (
                <MailSelect
                  values={setting.info}
                />
              ) : ''}
              <MailTextfield values={setting.title} />
              <Grid className={classes.contents}>
                <MailTitleAndBody
                  initData={editorState}
                  setMailBody={setEditorState}
                />
              </Grid>
              {uploads.length > 0 && <MailAppendFile uploads={uploads} setUploads={setUploads} />}
            </Grid>
          </DialogContent>
        </TabPanel>
      </Dialog>
    </>
  );
}

export default MailCreateWindowSpAfter;
