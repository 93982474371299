import { memo, useState, useMemo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  // Dialog,
  Grid,
  Link,
  makeStyles,
  Typography,
  List,
  Collapse,
  ListItem,
  Drawer,
} from '@material-ui/core';
import {
  Error,
  ExpandMore, // 下向き
  ExpandLess, // 上向き
  Close,
} from '@material-ui/icons';
// import Draggable from 'react-draggable';
import logo from '../../../images/ohLogo.svg';
import commonStyles from '../../../styles';

import { classNames } from '../../../../commonFunction/format';

import { MAIN_MENU_DISPLAY } from '../../../../constants/userRole';

const useStyles = makeStyles((theme) => ({
  wrap: {
    cursor: 'pointer',
    marginRight: 'auto',
    borderRight: '1px #c8c8c8 solid',
    padding: '4px 26px 4px 20px',
    flexShrink: 0,
  },
  icon: {
    fill: theme.palette.primaryColor,
    width: '20px',
    marginRight: '4px',
  },
  menuWrap: {
    backgroundColor: '#f3f3f3',
    width: '240px',
    maxHeight: '100%',
    flexWrap: 'nowrap',
  },
  logoWrap: {
    backgroundColor: '#fff',
    padding: '8px',
  },
  logo: {
    display: 'block',
    width: '75px',
  },
  level1: {
    background: '#fff',
    color: theme.palette.primaryColor,
    borderBottom: '1px #c8c8c8 solid',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    '&.linkBtn': {
      padding: 0,
      '& > a': {
        width: '100%',
        padding: '8px 16px',
      },
    },
  },
  lists: {
    maxHeight: 'calc(100% - 10px)',
  },
  childwrap: {
    background: '#f3f3f3',
    '& >.MuiCollapse-wrapper': {
      paddingTop: 8,
    },
  },
  child: {
    color: theme.palette.primaryColor,
    marginLeft: '16px',
    marginBottom: '8px',
    width: 'auto',
    borderRadius: '20px 0 0 20px',
    padding: 0,
    '& > a': {
      width: '100%',
      padding: '8px 16px',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '&.external': {
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '& > a': {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  currentChild: { background: `${theme.palette.primaryColor}29` },
  heading: {
    display: 'flex',
    alignItems: 'center',
    color: '#33333399',
    paddingRight: 0,
    '&::after': {
      content: '""',
      background: '#c8c8c8',
      height: '1px',
      flexGrow: 1,
      marginLeft: '8px',
    },
  },
  drawer: {
    position: 'relative',
    '& > .MuiBackdrop-root': {
      background: 'transparent',
    },
  },
  close: {
    position: 'absolute',
    right: '6px',
    top: '6px',
    width: '35px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  hidden: {
    visibility: 'hidden',
  },
}));

const Child = memo(({ obj, path, closeModal }) => {
  const common = commonStyles();
  const classes = useStyles();

  switch (obj.type) {
    case 'link': {
      const linkClass = obj.href === path
        ? [classes.child, classes.currentChild]
        : [classes.child];
      return (
        <ListItem
          button
          key={`child-${obj.txt}`}
          className={classNames(linkClass)}
          disabled={obj.isDisabled}
        >
          <NavLink
            to={obj.href ? obj.href : '/'}
            className={classes.link}
            onClick={closeModal}
          >
            <Typography className={common.strong}>{obj.txt}</Typography>
          </NavLink>
        </ListItem>
      );
    }
    case 'externalLink': {
      return (
        <ListItem
          key={`child-${obj.txt}`}
          className={`${classes.child} external`}
        >
          <Link
            href={obj.href}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography className={common.strong}>{obj.txt}</Typography>
          </Link>
        </ListItem>
      );
    }
    case 'heading': {
      return (
        <ListItem
          key={`child-${obj.txt}`}
          className={`${classes.heading} ${common.title4}`}
        >
          <Typography className={common.strong}>{obj.txt}</Typography>
        </ListItem>
      );
    }
    case 'comp':
      return <Grid key={`child-${obj.txt}`}>{obj.component}</Grid>;
    default:
      return null;
  }
});

const MenuItem = memo(({ open, handleClick, menuObj, path, closeModal }) => {
  const common = commonStyles();
  const classes = useStyles();
  if (!Array.isArray(menuObj.children)) {
    return (
      <ListItem
        button
        className={`${classes.level1} linkBtn`}
        onClick={closeModal}
        disabled={menuObj.isDisabled}
      >
        <NavLink to={menuObj.href ? menuObj.href : '/'} className={classes.link}>
          <Typography className={common.strong}>{menuObj.txt}</Typography>
        </NavLink>
      </ListItem>
    );
  }
  return (
    <>
      <ListItem
        button
        className={classes.level1}
        onClick={() => handleClick(menuObj.key)}
      >
        <Typography className={common.strong}>{menuObj.txt}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} className={classes.childwrap} timeout={0}>
        {menuObj.children.map((obj) => (
          <Child obj={obj} path={path} closeModal={closeModal} />
        ))}
      </Collapse>
    </>
  );
});

function MainmenuModal(props) {
  const { menulist, path } = props;
  const common = commonStyles();
  const classes = useStyles();

  const { roleIds } = useSelector((s) => s.responseHeader);
  const isAllowedShowMenu = useMemo(() => roleIds.includes(MAIN_MENU_DISPLAY), [roleIds]);

  // モーダル用
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    if (!isAllowedShowMenu) return;
    setOpen(true);
  }, [isAllowedShowMenu]);
  const handleClose = useCallback(() => setOpen(false), []);

  // 開閉用ローカルステート
  const [state, setState] = useState({});
  const handleClick = useCallback((menu) => setState((s) => ({ ...s, [`${menu}Open`]: !s[`${menu}Open`] })), []);

  return (
    <Grid item className={classes.wrap}>
      <Grid
        container
        alignItems="center"
        onClick={handleOpen}
        className={!isAllowedShowMenu ? classes.hidden : undefined}
      >
        <Error className={classes.icon} />
        <Typography className={common.smallStrong}>メニュー</Typography>
      </Grid>
      <Drawer
        anchor="left"
        open={open}
        onClose={handleClose}
        className={classes.drawer}
      >
        <Close className={classes.close} onClick={handleClose} />
        <Grid item className={classes.logoWrap}>
          <img src={logo} alt="OPEN HOUSE" className={classes.logo} />
        </Grid>
        <List className={classes.lists}>
          {menulist.map((obj) => (
            <MenuItem
              open={state[`${obj.key}Open`]}
              handleClick={handleClick}
              menuObj={obj}
              path={path}
              closeModal={handleClose}
            />
          ))}
        </List>
      </Drawer>
    </Grid>
  );
}

export default memo(MainmenuModal);
