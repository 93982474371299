import { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import AfterCallSettingContainer from '../../containers/mail/afterCallSettingContainer';

const useStyles = makeStyles((theme) => ({
  child: {
    color: theme.palette.primaryColor,
    marginLeft: '16px',
    marginBottom: '8px',
    width: 'calc(100% - 16px)',
    borderRadius: '20px 0 0 20px',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    background: '#f3f3f3',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));
function MailAfterCallSetting() {
  const classes = useStyles();
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);

  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className={classes.child}
        onClick={handleOpen}
      >
        不通時メール
      </Button>
      {open ? (
        <AfterCallSettingContainer open={open} onClose={handleClose} />
      ) : ''}
    </>
  );
}

export default MailAfterCallSetting;
