import { useEffect, useState } from 'react';
import { Button, makeStyles, Grid, Dialog, List } from '@material-ui/core';
import UserTree from '../../../common/userTreeNew';
import SwipeOutButton from '../../../eleCommon/swipeOutButton';
// import { useState } from 'react';
import commonStyles from '../../../styles';

const useStyles = makeStyles({
  item: {
    width: '100%',
    '& label': {
      width: '100%',
      '& > span': {
        fontSize: '20px',
      },
    },
  },
  header: {
    padding: '16px 16px 8px 16px',
    width: '100%',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    boxSizing: 'border-box',
    position: 'relative',
    textAlign: 'center',
  },
  swipe: {
    '& > .MuiGrid-root > .MuiGrid-root': {
      padding: '16px',
      backgroundColor: '#fff',
    },
  },
});

export default function ChangeTestCC(props) {
  const { open, setOpen, values } = props;
  const common = commonStyles();
  const classes = useStyles();
  const [displayUser, setDisplayUser] = useState([]); // リスト表示用
  const [selectUser, setSelectUser] = useState([]); // UserTree用
  const [openModal, setOpenModal] = useState(false);
  const [swipe, setSwipe] = useState(null);

  const onClickSelect = () => {
    setOpen(false);
    values.setState(displayUser);
  };

  const handleDelete = (e) => {
    const del = displayUser.filter(s => s.userId !== e);
    setDisplayUser(del);
    displayUser(del);
  };

  const handleSelectClick = () => setDisplayUser(selectUser);
  const handleClearClick = () => setDisplayUser([]);

  useEffect(() => {
    setDisplayUser(values.state);
    setSelectUser(values.state);
  }, [values.state]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className={`${common.dialog} ${common.maxWidthDialog}`}
    >
      <Grid container>
        <Grid className={`${common.title1} ${classes.header}`}>
          テストメールCC
          <Button
            className={common.closeButton}
            onClick={() => setOpen(false)}
          />
        </Grid>
        <Grid className={classes.item}>
          <List>
            {displayUser.map((user, index) => (
              <SwipeOutButton
                key={`key${user.userId}`}
                user={user}
                index={index}
                swipe={swipe}
                setSwipe={setSwipe}
                styles={classes.swipe}
                label="削除"
                color="red"
                handleClick={() => handleDelete(user.userId)}
              >
                {user.userName}
              </SwipeOutButton>
            ))}
          </List>
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            className={common.buttonsSecondary}
          >
            ＋追加
          </Button>
          <UserTree
            changeTitle="テストCC送信先選択ウィンドウ"
            isOpenTree={openModal}
            setIsOpenTree={setOpenModal}
            selectUser={selectUser}
            setSelectUser={setSelectUser}
            handleSelectFunction={handleSelectClick}
            handleClearFunction={handleClearClick}
            isMulti
            isDialog
            isSelectUserWindow
          />
        </Grid>
        <Grid container justifyContent="center" className={common.fixedFooter}>
          <Button
            className={`${common.buttonsPrimary} ${classes.fixedFooterBtn}`}
            onClick={onClickSelect}
          >
            選択
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
