import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  total: 1,
  histories: [
    {
      actionId: 1,
      startAt: '2020/12/23 12:00:00',
      endAt: '2020/12/23 15:00:00',
      actionCode: 5,
      detailCode: 11,
      content: '来案\n対応内容詳細に関するメモ：',
      customerId: 2,
      userId: 1,
      contactMinutes: 60,
      customerFullName: 'test',
      userFullName: 'test',
      mailSubject: '件名1',
      isAttachment: 0,
      mailBody: '本文1',
      guidanceId: 2,
      guidanceStatusCode: 5,
      isGuided: 1,
      isVisitedCompany: 1,
      guideUserId: null,
      getSupportGuidanceId: 1,
      guidanceStartAt: '2022/08/31 00:00:00',
      isOpenedMail: 1,
      latestMailOpenedAt: '2022/01/10 00:00:00',
      totalMailOpenedCount: 1,
      acquisitionId: 6,
      acquisitionCode: 1,
      acquisitionFullName: '田中太郎',
    },
  ],
};

export const actCreateCustomHistorySlice = createSlice({
  name: 'actCreateCustomHistory',
  initialState: { actCreateCustomHistory: initialState },
  reducers: {
    actCreateCustomHistory: (state, data) => {
      state.actCreateCustomHistory = data.payload;
    },
  },
});

export const { actCreateCustomHistory } = actCreateCustomHistorySlice.actions;

export default actCreateCustomHistorySlice.reducer;
