import { useEffect, useState } from 'react';
import ResponseType from '../index';

function ResponseTypeModalSp(props) {
  const {
    open,
    onClose,
    state,
    setState,
    defaultResponse = false,
    resetData = () => {},
    isSearch = false, // 反響媒体の検索かどうか
    addResponseType = false,
  } = props;
  const [responseTypeList, setResponseTypeList] = useState(state);
  // モーダル開閉用
  const [childOpen, setChildOpen] = useState({
    open1: false, // 反響媒体（大）
    open2: false, // 反響媒体（中）
    open3: false, // 反響媒体（小）
    open4: false, // 反響媒体（細）
  });

  useEffect(() => {
    setResponseTypeList(state);
  }, [state]);

  useEffect(() => {
    if (open) {
      setChildOpen({ open1: true });
    }
  }, [open]);

  useEffect(() => {
    if (!childOpen.open1) {
      onClose();
      // 値を元に戻す
      resetData();
    }
  }, [childOpen]);

  const set = () => {
    console.log('responseTypeList ///', responseTypeList);
    setState(responseTypeList);
    onClose();
  };

  return (
    <ResponseType
      open={childOpen}
      setOpen={setChildOpen}
      state={responseTypeList}
      setState={setResponseTypeList}
      defaultResponse={defaultResponse}
      resetData={resetData}
      set={set}
      isSearch={isSearch}
      addResponseType={addResponseType}
      parentOpen={open}
    />
  );
}

export default ResponseTypeModalSp;
