import React, { useState, useEffect } from 'react';
import { Grid, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomOriginalSelect from '../../eleCommon/customOriginalSelect';

const useStyles = makeStyles({
  root: {
    margin: '4px 8px',
    width: 96,
    '& .MuiSelect-root': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  InputLabel: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    top: '-25%',
    color: '#333',
  },
  select: {
    display: 'flex',
    margin: '0px',
    paddingTop: '12px',
    width: '100%',
    height: 35,
    border: '0px solid #FFFFFF',
    borderBottom: '1px solid #8C8C8C',
    '& fieldset': {
      border: 'none',
    },
  },
  hasValue: {
    transform: 'translate(0, 7px) scale(0.85)',
  },
});

function SearchSelect(props) {
  const { initial } = props;

  // 次のレンダリングlivecycleで検索initial.searchを発火するため
  const [flg, setFlg] = useState(1);

  useEffect(() => {
    initial.search();
  }, [flg]);

  const classes = useStyles();

  const menusKeys = Object.keys(initial.obj.menus);

  const changeValue = (value) => {
    initial.set(value, initial);
    setFlg(flg + 1);
  };

  return (
    <Grid className={classes.root}>
      <FormControl
        fullWidth
        className={initial.key}
      >
        <InputLabel className={`${classes.InputLabel} ${initial.state ? classes.hasValue : ''}`}>
          {initial.title}
        </InputLabel>
        <Grid className={classes.select}>
          <CustomOriginalSelect
            data={{
              key: initial.key,
              val: initial.state,
              index: 0,
              type: 'select',
              select: initial.obj.menus,
              menuKeys: menusKeys,
            }}
            changeValue={changeValue}
            hasClearBotton
          />
        </Grid>
      </FormControl>
    </Grid>
  );
}

export default SearchSelect;
