/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesGetGuidanceByGuidanceIdSlice = createSlice({
  name: 'schedulesGetGuidanceByGuidanceId',
  initialState: { schedulesGetGuidanceByGuidanceId: initialState },
  reducers: {
    setGuidanceByGuidanceIdApi: (state, data) => {
      state.schedulesGetGuidanceByGuidanceId = data.payload;
    },
  },
});

export const { setGuidanceByGuidanceIdApi } = schedulesGetGuidanceByGuidanceIdSlice.actions;

export default schedulesGetGuidanceByGuidanceIdSlice.reducer;
