import {
  useCallback,
  useState,
  useEffect,
} from 'react';
import areaListApi from '../../../apis/common/areaList';
import { useLoading } from '../../../hooks';
import { setLocalStorage, getLocalStorage, joinStr } from '../../../commonFunction';
import { useStoreGroupsSettingsAreas } from '../common/useStoreGroupsSettingsAreas';

export const CACHE_KEY_CITY_JIS_CODES = 'CACHE_KEY_CITY_JIS_CODES';

let isRequesting = false;

export function useCityJisCodes() {
  const { addLoading, removeLoading } = useLoading();

  const { areaList, isSameDay } = useStoreGroupsSettingsAreas();

  // 【combination-setting002】全国地方公共団体コード検索（エリアコード指定）
  // { [areaCode]: Array<{
  //   city: string, jisCode: string, prefecture: string, laebl: string, value: number
  // }>}
  const [cityJisCodes, setCityJisCodes] = useState(getLocalStorage(CACHE_KEY_CITY_JIS_CODES, {}));

  // areaListApiを呼び出す際のパラメータ
  const params = {
    isWishArea: 1,
    sortColumn: 2,
    sortOrder: 0,
  };

  /**
   * 【settingGroupStore008】エリアリスト取得
   */
  const getTargetCityJisCodeApi = useCallback(async (areas) => {
    if (isRequesting) return;
    isRequesting = true;
    addLoading();
    try {
      const cityJisCodesRes = await Promise.allSettled(
        areas.map((item) => areaListApi(item.areaCode, params)),
      );
      // to object
      const codesData = {};
      areas.forEach((item, i) => {
        codesData[item.areaCode] = cityJisCodesRes[i].value.data.areas || [];
        // label追加
        codesData[item.areaCode].forEach((child) => {
          child.label = joinStr(child.prefecture, child.city);
          child.uniqueId = `area${item.areaCode}-jisCode${child.cityJisCode}`;
          child.selectable = true;
          child.type = 'area';
          child.id = +child.cityJisCode;
        });
      });
      setCityJisCodes(codesData);
      setLocalStorage(CACHE_KEY_CITY_JIS_CODES, codesData);
    } finally {
      removeLoading();
      isRequesting = false;
    }
  }, []);

  // 初期化
  useEffect(() => {
    const isEmpty = !Object.keys(cityJisCodes).length;
    if (areaList.length && (isEmpty || !isSameDay)) {
      getTargetCityJisCodeApi(areaList);
    }
  }, [areaList]);

  return {
    areaList,
    cityJisCodes,
    isSameDay,
  };
}
