import client from '../httpClient';
/**
 * @param {{responsibilities:{
 *          customerId: number,
 *          userId?: number,
 *          divisionId: number,
 *          responseId?: number,
 *          effectAt?: string}[]}} params,
 */
const customerPutResponsibilitiesApi = (params) => {
  return client.put('/customers/responsibilities', JSON.stringify(params), { isShowMessage: true, isShowLoading: false });
};

export default customerPutResponsibilitiesApi;
