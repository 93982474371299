import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import idList4PotentialApi from '../../apis/idList4Potential/idList4PotentialApi';
import ProspectsListWrapper from '../../components/prospectsList/prospectsList';
import { useQuery } from '../../hooks';

function ProspectsListContainers() {
  // パラメータからIDを取得
  const [
    areaId,
    tenpoId,
    kaId,
    member,
  ] = useQuery(['area', 'tenpo', 'ka', 'member']);

  // ログイン中のユーザーの情報を取得
  const responseHeader = useSelector((state) => state.responseHeader);
  // ログイン情報
  const initLoginData = useMemo(() => {
    const data = {};
    if (member) {
      data.userId = member;
    } else if (areaId) {
      data.divisionId = areaId;
    } else if (tenpoId) {
      data.divisionId = tenpoId;
    } else if (kaId) {
      data.divisionId = kaId;
    } else {
      data.userId = responseHeader.userId;
    }
    return data;
  }, [responseHeader]);

  // /customers-for-projects
  // 【idList4Potential001】顧客情報(案件管理表用)取得API
  const [list, setList] = useState({
    total: 0,
    customers: [],
  });
  // GET
  const getProspectsList = async (customerIds, page, rowsPerPage) => {
    if (customerIds.length) {
      let ids;
      if (customerIds.length < ((page + 1) * rowsPerPage)) {
        ids = customerIds.slice(page * rowsPerPage);
      } else {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        ids = customerIds.slice(start, end);
      }
      await idList4PotentialApi(ids)
        .then(async (res) => {
          res.data?.customers?.sort((c1, c2) => {
            if (!c1.userLevel) {
              return 1;
            }
            if (!c2.userLevel) {
              return -1;
            }
            if (c1.userLevel === c2.userLevel) {
              return 0;
            }
            return c1.userLevel < c2.userLevel ? 1 : -1;
          });
          await setList(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setList({
        total: 0,
        customers: [],
      });
    }
  };

  // 反響ツリー選択顧客
  const [selectCustomer, setSelectCustomer] = useState('');

  // 反響ツリー選択顧客（重複なし）
  const [selectCustomerUnique, setSelectCustomerUnique] = useState('');

  useEffect(() => {
    if (selectCustomer === '') {
      return;
    }

    // customerIdの重複を削除
    const uniqueCustomerIds = [];
    const idArray = new Set();

    selectCustomer.forEach((obj) => {
      if (!idArray.has(obj.customerId)) {
        idArray.add(obj.customerId);
        uniqueCustomerIds.push(obj);
      }
    });
    setSelectCustomerUnique(uniqueCustomerIds);
  }, [selectCustomer]);

  const value = {
    getProspectsList,
    list,
    setList,
    selectCustomer,
    setSelectCustomer,
    selectCustomerUnique,
    initLoginData,
  };

  return (
    <ProspectsListWrapper value={value} />
  );
}

export default ProspectsListContainers;
