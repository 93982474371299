import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Add, Cancel } from '@material-ui/icons';
import commonTheme from '../../../styles/theme';
import ResponseTypeModal from '../../../common/responseType/responseTypeModal';
import ResponseTypeModalSp from '../../../common/responseType/sp/responseTypeModalSp';

const useStyles = makeStyles((theme) => ({
  itemButton: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&>span': {
      fontSize: 13,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&.disabled': {
      // background: '#C8C8C8',
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  btn: {
    fontFamily: 'Roboto',
    [commonTheme.breakpoints.up('md')]: {
      fontSize: '13px',
      width: '246px',
      borderBottom: '1px #8c8c8c solid',
      borderRadius: 0,
      background: 'transparent top 50% right 5px/17px no-repeat',
      backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%238c8c8c" d="M7 10l5 5 5-5z" /></svg>\')',
      '&:hover': {
        backgroundColor: `${theme.palette.primaryColor}29`,
        borderColor: '#000',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      lineHeight: 1.6,
      fontWeight: '700',
      width: '100%',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '16px',
      flexShrink: 0,
      borderBottom: '1px solid #F3F3F3',
      textTransform: 'none',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  buttonLabel: {
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
      flexShrink: 0,
    },
  },
  buttonText: {
    marginLeft: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      // overflowX: 'hidden',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  buttonTextList: {
    display: 'block',
    '& > *': {
      display: 'block',
    },
  },
  icon: {
    color: theme.palette.primaryColor,
    width: '16px',
    flexShrink: 0,
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
}));
function SearchPartsResponse(props) {
  const { values, responseClearCount, handleClear } = props;
  const classes = useStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // モーダル開閉用のローカルステート
  const [localopen, setLocalopen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setLocalopen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setLocalopen(false);
  };

  function ItemList() {
    const list = [];
    values.input.list.map((b, i) => list.push(
      <Button
        key={b}
        map={b}
        type="button"
        onClick={handleOpen}
        className={classes.btn}
      >
        {values.input.state[i] === undefined ? b : values.input.state[i].responseTypeName}
      </Button>,
    ));
    return list;
  }

  const getItemListSp = () => {
    return (
      <Button
        onClick={handleOpen}
        className={classes.itemButton}
      >
        <span className={classes.buttonLabel}>
          {values.input.label}
        </span>
        <span className={classes.buttonText}>
          <span className={classes.buttonTextList}>
            {values.input.list.map((b, i) => {
              return (
                <span key={b}>{values.input.state[i] === undefined ? '' : `${b}：${values.input.state[i].responseTypeName}`}</span>
              );
            })}
          </span>
          {values.input.state.some((s) => s) ? (
            <Cancel
              className={`${classes.icon} ${classes.cancel}`}
              onClick={(e) => {
                e.stopPropagation();
                handleClear(values);
                values.input.func([]);
                values.input.setState([]);
              }}
            />
          ) : (
            <Add className={classes.addButtonIcon} />
          )}
        </span>
      </Button>
    );
  };

  return (
    isSp ? (
      <>
        {getItemListSp()}
        <ResponseTypeModalSp
          open={localopen}
          onClose={handleClose}
          setState={(e) => {
            values.input.func(e);
            values.input.setState(e);
          }}
          state={values.input.state}
          list={values.input.list}
          isSearch
        />
      </>
    ) : (
      <>
        <div className={classes.wrap}><ItemList /></div>
        <ResponseTypeModal
          open={localopen}
          onClose={handleClose}
          setState={(e) => {
            values.input.func(e);
            values.input.setState(e);
          }}
          state={values.input.state}
          responseClearCount={responseClearCount}
          isSearch
        />
      </>
    )
  );
}

export default SearchPartsResponse;
