import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import { TextBaseField } from '../../../eleCommon/validation';
import { validateFormUrl } from '../../../../commonFunction/validations';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  box: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    width: 333,
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontSize: '13px',
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
    },
    '& input': {
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
}));

export default function CustomerDetailDrive(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    input,
  } = props;

  const onBlur = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>
        Googleドライブ
      </Typography>
      <Grid className={classes.box}>
        <Grid className={classes.list}>
          <TextBaseField
            label="ドライブURL1"
            defaultValue={input.detail.driveUrl1}
            className={classes.item}
            name="driveUrl1"
            onBlur={onBlur}
            validator={validateFormUrl(input.detail.driveUrl1)}
            errorCallback={input.errorCallback('driveUrl1')}
          />
          <TextBaseField
            label="ドライブURL2"
            defaultValue={input.detail.driveUrl2}
            className={classes.item}
            name="driveUrl2"
            onBlur={onBlur}
            validator={validateFormUrl(input.detail.driveUrl2)}
            errorCallback={input.errorCallback('driveUrl2')}
          />
          <TextBaseField
            label="ドライブURL3"
            defaultValue={input.detail.driveUrl3}
            className={classes.item}
            name="driveUrl3"
            onBlur={onBlur}
            validator={validateFormUrl(input.detail.driveUrl3)}
            errorCallback={input.errorCallback('driveUrl3')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
