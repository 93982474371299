import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import HolidayTelCheckBox from './parts/holidayTelCheckBox';
import {
  holidaySelect,
  timeSelect,
} from '../../../constants/holidayTel';
import { isEqualObject } from '../../../commonFunction';
import CommonDialog from '../../common/modal';

import {
  validateFormString1,
} from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  block: {
    marginBottom: 24,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
      '&.active': {
        display: 'block',
      },
    },
  },
  blockTtl: {
    marginBottom: 8,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  box: {
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '4px 8px',
    },
  },
  boxList: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '0 11px',
    },
  },
  boxItem: {
    [commonTheme.breakpoints.up('md')]: {
      margin: '0 3px 4px 0',
    },
    '& .MuiFormControlLabel-root': {
      [commonTheme.breakpoints.down('sm')]: {
        margin: 0,
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        borderBottom: '1px solid #F3F3F3',
        padding: '16px 0',
      },
      '& .Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& .Mui-checked + .MuiTypography-root': {
        [commonTheme.breakpoints.down('sm')]: {
          color: theme.palette.primaryColor,
        },
      },
      '& .MuiSvgIcon-root': {
        [commonTheme.breakpoints.down('sm')]: {
          fontSize: 22,
        },
      },
    },
    '& .MuiCheckbox-root': {
      padding: '0',
      [commonTheme.breakpoints.down('sm')]: {
        order: 2,
        marginRight: 9,
      },
      [commonTheme.breakpoints.up('md')]: {
        marginRight: 5,
      },
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      marginTop: 2,
      [commonTheme.breakpoints.down('sm')]: {
        order: 1,
        fontWeight: '700',
        marginLeft: 16,
      },
    },
  },
  boxOther: {
    [commonTheme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
      margin: '0 auto',
      display: 'block',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
      marginTop: '-8px',
    },
    '& .MuiInputLabel-formControl': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      color: '#333',
    },
    '& .MuiInputBase-input': {
      width: '100%',
      fontFamily: 'Roboto',
      lineHeight: 1.6,
      letterSpacing: 0,
      color: '#333',
      [commonTheme.breakpoints.down('sm')]: {
        padding: '13px 0',
        fontSize: 16,
        zoom: 0.75,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 13,
      },
    },
    '& .MuiInputBase-root': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  contentbtn: {
    background: '#fff',
    borderRadius: 4,
    width: 148,
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 8px 7px',
    marginRight: 8,
  },
}));

const initialState = {
  holidayWeekday: '',
  otherHolidayWeekday: '',
  convenientTime: '',
  otherConvenientTime: '',
};

const getTitle = (category) => {
  switch (category) {
    case 'time':
      return (
        <span>連絡がつきやすい時間帯</span>
      );
    default:
      return (
        <span>おやすみの日</span>
      );
  }
};

export default function HolidayTel(props) {
  const {
    initialParam,
    onClickSelectButton,
    open,
    onClose,
    category,
  } = props;

  const menusKeys1 = Object.keys(holidaySelect.menus);
  const menusKeys2 = Object.keys(timeSelect.menus);

  const classes = useStyles();
  const baseClasses = commonStyles();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  // 返却データ
  const [state, setState] = useState({ ...initialState });

  // 曜日
  const checkWeekday = (name, checked) => {
    let arrayWeekday = state.holidayWeekday ? state.holidayWeekday.split('/') : [];
    if (checked) {
      arrayWeekday.push(holidaySelect.menus[name]);
    } else {
      arrayWeekday = arrayWeekday.filter(item => item !== holidaySelect.menus[name]);
    }
    const orderRule = ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'];
    arrayWeekday.sort((a, b) => orderRule.indexOf(a) - orderRule.indexOf(b));
    setState({
      ...state,
      holidayWeekday: arrayWeekday.join('/'),
    });
  };

  // 時間
  const checkTime = (name, checked) => {
    let arrayTime = state.convenientTime ? state.convenientTime.split('/') : [];
    if (checked) {
      arrayTime.push(timeSelect.menus[name]);
    } else {
      arrayTime = arrayTime.filter(item => item !== timeSelect.menus[name]);
    }
    const orderRule = ['午前中', '12時～13時', '13時～15時', '15時～18時', '18時～19時', '19時～20時', '20時～21時'];
    arrayTime.sort((a, b) => orderRule.indexOf(a) - orderRule.indexOf(b));
    setState({ ...state, convenientTime: arrayTime.join('/') });
  };

  // その他
  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  // 保存
  const click = () => {
    onClickSelectButton(state);
  };

  // 初期化 その他
  useEffect(() => {
    if (open && !isEqualObject(state, initialParam)) {
      setState({
        ...initialState,
        ...initialParam,
      });
    }
  }, [open, initialParam]);

  // SP 表示制御
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  useEffect(() => {
    switch (category) {
      case 'time':
        setActive1(false);
        setActive2(true);
        break;
      default:
        setActive1(true);
        setActive2(false);
        break;
    }
  }, []);

  // SP クリア
  const [all, setAll] = useState(0);
  const allLift = () => {
    setAll(2);
  };
  const allBlur = () => {
    setAll(0);
  };
  const clickClear = () => {
    allLift();
    setState({ ...initialState });
  };

  const body = (
    <Grid>
      <Grid className={classes.content}>
        <Grid className={`${classes.block} ${active1 && 'active'}`}>
          <Typography className={`${baseClasses.title4} ${classes.blockTtl}`}>
            お休み
          </Typography>
          <Grid className={classes.box}>
            <Grid className={classes.boxList}>
              {menusKeys1.map((data, index) => (
                <Grid key={data} className={classes.boxItem}>
                  <HolidayTelCheckBox
                    label={holidaySelect.menus[data]}
                    type="weekday"
                    all={all}
                    index={index}
                    checkWeekday={checkWeekday}
                    checkTime={checkTime}
                    initialParam={state}
                  />
                </Grid>
              ))}
            </Grid>
            <TextBaseField
              className={classes.boxOther}
              onChange={(e) => handleChange('otherHolidayWeekday', e.target.value)}
              id="standard-basic"
              label="その他"
              value={state.otherHolidayWeekday ?? ''}
              validator={validateFormString1({ maxLengthInt: 100 })}
              errorCallback={errorCallback('otherHolidayWeekday')}
            />
          </Grid>
        </Grid>
        <Grid className={`${classes.block} ${active2 && 'active'}`}>
          <Typography className={`${baseClasses.title4} ${classes.blockTtl}`}>
            TEL時間帯 / 連絡がつきやすい時間帯
          </Typography>
          <Grid className={classes.box}>
            <Grid className={classes.boxList}>
              {menusKeys2.map((data, index) => (
                <Grid key={data} className={classes.boxItem}>
                  <HolidayTelCheckBox
                    label={timeSelect.menus[data]}
                    type="time"
                    all={all}
                    index={index}
                    checkWeekday={checkWeekday}
                    checkTime={checkTime}
                    initialParam={state}
                  />
                </Grid>
              ))}
            </Grid>
            <TextBaseField
              className={classes.boxOther}
              onChange={(e) => handleChange('otherConvenientTime', e.target.value)}
              id="standard-basic"
              label="その他"
              value={state.otherConvenientTime ?? ''}
              validator={validateFormString1({ maxLengthInt: 100 })}
              errorCallback={errorCallback('otherConvenientTime')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={isSp ? getTitle(category) : 'お休み / TEL時間帯 ウィンドウ'}
      width={640}
      buttons={[
        {
          label: 'クリア',
          onClick: clickClear,
          onBlur: allBlur,
          type: 'secondary',
          className: baseClasses.onlySp,
        },
        {
          label: '保存',
          onClick: () => {
            onClose();
            click();
          },
          disabled: errorSet.size !== 0,
        },
      ]}
    >
      {body}
    </CommonDialog>
  );
}
