import React, {
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actCreateCustomHistoryApi from '../../apis/act/actCreateCustomHistoryApi';
import actCreateHistoryApi from '../../apis/act/actCreateHistoryApi';
import actPutHistoryApi from '../../apis/act/actPutHistoryApi';
import actDeleteHistoryApi from '../../apis/act/actDeleteHistoryApi';
import { actCreateHistory } from '../../store/act/actCreateHistorySlice';
import { actPutHistory } from '../../store/act/actPutHistorySlice';
import { actDeleteHistory } from '../../store/act/actDeleteHistorySlice';

const CustomerMainFunctionContext = createContext({});

const CustomerMainFunctionProvider = React.memo((props) => {
  const {
    children,
  } = props;

  const customerPrf = useSelector((state) => state.customer.customer);

  // 対応履歴
  const [actionHistory, setActionHistory] = useState({});
  const [actionHistorySearch, setActionHistorySearch] = useState({
    offset: 0,
    limit: 1000,
  });

  const dispatch = useDispatch();

  // 対応履歴取得
  const getActionHistoryList = useCallback(async (id, searchObj) => {
    const params = {
      ...searchObj,
      // idはnullの場合、一覧の顧客のIDをパラメーターにする
      customerIds: id ? [id] : customerPrf.customers.map((item) => item.customerId),
    };
    await actCreateCustomHistoryApi(params)
      .then(async (arr) => {
        const data = {};
        arr.forEach((item) => {
          data[item.id] = item;
        });
        const newState = { ...actionHistory };
        params.customerIds.forEach((cid) => {
          newState[cid] = data[cid] || { total: 0, histories: [] };
        });
        setActionHistory(newState);
      })
      .catch(console.error);
  }, [customerPrf]);

  // 対応履歴情報登録
  const createHistory = useCallback(async (cid, parmObj) => {
    await actCreateHistoryApi(parmObj)
      .then(async (res) => {
        await dispatch(actCreateHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, actionHistorySearch);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [actionHistorySearch]);

  // 対応履歴情報更新
  const updateHistory = useCallback(async (cid, id, parmObj) => {
    await actPutHistoryApi(id, parmObj)
      .then(async (res) => {
        await dispatch(actPutHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, actionHistorySearch);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [actionHistorySearch]);

  // 対応履歴情報削除
  const deleteHistory = useCallback(async (cid, id) => {
    await actDeleteHistoryApi(id)
      .then(async (res) => {
        await dispatch(actDeleteHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, actionHistorySearch);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [actionHistorySearch]);

  // API用の関数をまとめたオブジェクト
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const functions = {
    actionHistorySearch, // 対応履歴取得する条件
    setActionHistorySearch, // 対応履歴取得する条件を更新する関数
    actionHistory,
    createHistory, // 対応履歴登録
    updateHistory, // 対応履歴更新
    deleteHistory, // 対応履歴削除
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { functions };

  return (
    <CustomerMainFunctionContext.Provider value={value}>
      {children}
    </CustomerMainFunctionContext.Provider>
  );
});

const useCustomerMainFunction = () => {
  return useContext(CustomerMainFunctionContext);
};

export { CustomerMainFunctionProvider, useCustomerMainFunction };
