import { makeStyles, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { useEffect, useState } from 'react';
import AimTableRow from './parts/tableRow';
import SearchCell from './parts/searchCell';
import commonStyles from '../styles';

const useStyles = makeStyles((theme) => ({
  tableWrap: {
    paddingTop: 0,
  },
  fixedHead: {
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 1,
  },
  cell: {
    padding: '8px',
  },
  icon: {
    '& .MuiTableSortLabel-icon': { fontSize: '12px' },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.primaryColor,
    },
  },
  search: { width: '16px', verticalAlign: 'middle' },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator, filterlist) {
  const ids = filterlist === undefined ? [] : Object.keys(filterlist);
  let stabilizedThis = {};
  if (ids.length) {
    const filtered = array.flatMap(e => {
      const flg = [];
      ids.forEach(key => {
        if (e[key].indexOf(filterlist[key]) !== -1) {
          flg.push(true);
        } else {
          flg.push(false);
        }
      });
      return flg.includes(false) ? false : e;
    });
    stabilizedThis = filtered.filter(f => f).map((el, index) => [el, index]);
  } else {
    stabilizedThis = array.map((el, index) => [el, index]);
  }
  stabilizedThis
    .sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeader(props) {
  const {
    cells,
    order,
    orderBy,
    onRequestSort,
    filter,
    setFilter,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.fixedHead}>
      <TableRow>
        {cells.map(c => (
          <TableCell
            key={c.id}
            style={c.style}
            sortDirection={orderBy === c.id ? order : false}
            className={`${classes.cell} ${common.title4}`}
          >
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={createSortHandler(c.id)}
              className={classes.icon}
            >
              {c.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{ width: 80 }} />
      </TableRow>
      <TableRow>
        {cells.map(c => (
          <TableCell
            key={c.id}
            className={`${classes.cell} ${common.title4}`}
          >
            {c.search
              ? <SearchCell id={c.id} filter={filter} setFilter={setFilter} />
              : null}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

export default function AimSettingComponent(props) {
  const {
    list,
    clickedit,
    deleteFunction,
    onUpdate,
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  const tableContainerHeight = (window.innerHeight - 214) + 'px';
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [filter, setFilter] = useState({});
  const header = [
    { id: 'divisionName', label: '組織', search: true, width: 'auto' },
    { id: 'positionCode', label: '役割', search: false, width: 'auto' },
    { id: 'buildingCount', label: '目標棟数', search: false, width: 'auto' },
    { id: 'sales', label: '目標売上（万円）', search: false, width: 'auto' },
    { id: 'updatedAt', label: '更新日', search: false, width: 'auto' },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
  });
  return (
    <TableContainer
      className={`${classes.tableWrap} ${common.box}`}
      style={{ height: tableContainerHeight }}
    >
      <Table>
        <EnhancedTableHeader
          cells={header}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={list.length}
          filter={filter}
          setFilter={setFilter}
        />
        <TableBody>
          {stableSort(list, getComparator(order, orderBy), filter)
            .map((row) => {
              return (
                <AimTableRow
                  key={`${row.divisionId}_${row.positionCode}`}
                  row={row}
                  cells={header}
                  clickedit={clickedit}
                  deleteFunction={deleteFunction}
                  onUpdate={onUpdate}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
