import { useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LibraryTreeListSp from './libraryTreeListSp';
import LibraryPreview from './libraryPreview';

import store from '../../../../../store';

import { useLoading } from '../../../../../hooks';

import { loadingHeight } from '../../../../common/pullRefComponent/parts/loadingEllipsis';

import { LIBRARY_DIRECTORY_TREE_LIBRRARY } from '../../../../../constants/loading';

const useStyles = makeStyles({
  wrap: {
    height: '100%',
    opacity: 0,
    visibility: 'hidden',
    overflow: 'auto',
    flexWrap: 'nowrap !important',
    flexDirection: 'column',
  },
  show: {
    opacity: 1,
    visibility: 'visible',
  },
});

export default function LibraryTreeIndexSp(props) {
  const {
    selectDir,
    setSelectDir,
    selectLibrary,
    functions,
    isPreview,
    limitOffsetRef,
  } = props;

  const classes = useStyles();
  const { hasLoading } = useLoading();
  const [openPreview, setOpenPreview] = useState(false);

  const rootRef = useRef(null);

  const onClickOk = () => functions.onClickOk?.();

  const isAllLoaded = () => {
    const { libraryGetSpecifyDirctory: { totalCount } } = store.getState();
    const { limit, offset } = limitOffsetRef.current;
    return (limit + offset) > totalCount;
  };

  const isLoading = () => {
    const { loading: { list } } = store.getState();
    return list.includes(LIBRARY_DIRECTORY_TREE_LIBRRARY);
  };

  const handleScroll = () => {
    const {
      clientHeight,
      scrollHeight,
      scrollTop,
    } = rootRef.current;
    const diff = clientHeight - scrollHeight;

    if (diff - scrollTop > loadingHeight || isAllLoaded() || isLoading()) return;
    const { limit, offset } = limitOffsetRef.current;
    limitOffsetRef.current = { limit, offset: offset + limit };
    functions.libraryGetSpecifyDirctoryFunction(selectDir.libraryDirectoryId);
  };

  return (
    <>
      <Grid
        container
        wrap="wrap"
        ref={rootRef}
        className={`${classes.wrap} ${!hasLoading() && classes.show}`}
        onScroll={handleScroll}
      >
        <LibraryTreeListSp
          key={`selectedDirectoryId-${selectDir.libraryDirectoryId}`}
          selectDir={selectDir}
          setSelectDir={setSelectDir}
          selectLibrary={selectLibrary}
          setOpenPreview={setOpenPreview}
          functions={functions}
        />
      </Grid>
      {isPreview && (
        <LibraryPreview
          open={openPreview}
          values={selectLibrary}
          onClose={() => setOpenPreview(false)}
          onClickOk={onClickOk}
        />
      )}
    </>
  );
}
