import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const responseUpdateMediumSlice = createSlice({
  name: 'responseUpdateMedium',
  initialState: { responseUpdateMedium: initialState },
  reducers: {
    responseUpdateMediumApi: (state, data) => {
      state.responseUpdateMedium = data.payload;
    },
  },
});

export const { responseUpdateMediumApi } = responseUpdateMediumSlice.actions;

export default responseUpdateMediumSlice.reducer;
