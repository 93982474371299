import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  AppBar,
  Grid,
  Tab,
  Tabs,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import UnsentList from '../parts/sp/unsentList';

const useStyles = makeStyles((theme) => ({
  fixedTop: {
    position: 'fixed',
    width: '100%',
    top: 0,
    background: '#fff',
    zIndex: 3,
  },
  appBar: {
    color: '#333',
    background: 'none',
    boxShadow: 'none',
    borderBottom: '1px solid #C8C8C8',
    position: 'relative',
    paddingRight: '16px',
    paddingLeft: '16px',
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  tabs: {
    minHeight: 'auto',
  },
  tab: {
    width: '50%',
    minWidth: 'auto',
    minHeight: 'auto',
    '&.Mui-selected': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
    '&.Mui-selected .MuiTypography-root': {
      color: theme.palette.primaryColor,
    },
  },
  tabTtl: {
    color: '#333333',
    fontSize: '13px',
  },
  count: {
    display: 'block',
    textAlign: 'center',
  },
  header: {
    padding: '16px',
    '& > p': {
      width: '100%',
      textAlign: 'center',
    },
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid p={3}>
          <Grid>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

export default function UnsentMailComponentSp(props) {
  const { list, open, onClose, functions, mailCount, isSp, refresh } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [mailCountTab, setMailCountTab] = useState(0);
  const [limit, setLimit] = useState(20);

  const options = useRef({
    limit,
    offset: 0,
    mailCode: mailCountTab,
  });

  // スクロールが下まで行ったら再度API叩く
  useEffect(() => {
    if (isSp) {
      functions.getUnSendList({
        offset: 0,
        limit,
        mailCode: mailCountTab,
      });
      options.current = {
        offset: 0,
        limit,
        mailCode: mailCountTab,
      };
    }
  }, [limit]);

  // タブ切り替え時
  const changeTab = (event, newValue) => {
    setMailCountTab(newValue);
    setLimit(20);
    functions.getUnSendList({
      offset: 0,
      limit: 20,
      mailCode: newValue,
    });
    options.current = ({
      offset: 0,
      limit: 20,
      mailCode: newValue,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="draggable-dialog-title"
      className={`${common.dialog} ${common.maxWidthDialog}`}
    >
      <Grid className={classes.fixedTop}>
        <Grid container className={classes.header}>
          <Typography className={common.title2}>未送信メール</Typography>
          <Button className={common.closeButton} onClick={onClose} />
        </Grid>
        <AppBar className={classes.appBar}>
          <Tabs
            onChange={changeTab}
            className={classes.tabs}
            value={mailCountTab}
          >
            <Tab
              label={
                <Typography className={classes.tabTtl}>
                  <span className={`${common.title2} ${classes.count}`}>
                    {mailCount.individuallyMailCount}
                  </span>
                  個別
                </Typography>
              }
              {...a11yProps(0)}
              className={classes.tab}
            />
            <Tab
              label={
                <Typography className={classes.tabTtl}>
                  <span className={`${common.title2} ${classes.count}`}>
                    {mailCount.broadCastMailCount}
                  </span>
                  一斉
                </Typography>
              }
              {...a11yProps(1)}
              className={classes.tab}
            />
          </Tabs>
        </AppBar>
      </Grid>
      <TabPanel value={mailCountTab} index={0} className={classes.tabPanel}>
        <UnsentList
          arrIndex="0"
          list={list.individual}
          functions={functions}
          mailCount={mailCount}
          mailCountTab={mailCountTab}
          limit={limit}
          setLimit={setLimit}
          options={options}
          refresh={refresh}
        />
      </TabPanel>
      <TabPanel value={mailCountTab} index={1} className={classes.tabPanel}>
        <UnsentList
          arrIndex="1"
          list={list.all}
          functions={functions}
          mailCount={mailCount}
          mailCountTab={mailCountTab}
          limit={limit}
          setLimit={setLimit}
          options={options}
          refresh={refresh}
        />
      </TabPanel>
    </Dialog>
  );
}
