import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles({
  select: {
    '& .MuiSelect-select:focus': { background: 'transparent' },
    '&:before': { content: 'none' },
    '&:after': { content: 'none' },
  },
});
export default function MailSelectBox(props) {
  const { constants, value, set } = props;
  const menusKeys = Object.keys(constants);
  const classes = useStyles();
  const onChangeFunc = (e) => {
    set(e.target.value);
  };

  return (
    <FormControl>
      <Select
        onChange={onChangeFunc}
        value={value}
        className={classes.select}
      >
        {menusKeys.map(e => (
          <MenuItem
            key={`select${e}`}
            value={e}
          >
            {constants[e]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
