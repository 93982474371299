import { createSlice } from '@reduxjs/toolkit';

/** @type { import('../../apis/divisionMaster/getSettingApiTypes').SettingGetResponse } */
const initialState = {
  message: '',
  description: '',
  data: {
    areaCode: 0,
    moveLimitAt: '',
    applyingAt: '',
    editLimitAt: '',
    isPromotionAuthority: 0,
  },
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    updateSetting: (state, action) => {
      state.message = action.payload.message;
      state.description = action.payload.description;
      state.data = action.payload.data;
    },
  },
});

export const { updateSetting } = settingSlice.actions;

export default settingSlice.reducer;
