import React, { useState } from 'react';
import { List, ListItem, Button, Grid, makeStyles } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import SearchTextField from './searchTextField';
import SearchDate from './searchDate';
import SearchButton from './searchButton';
import SearchSelect from './searchSelect';
import SearchResponse from './searchResponse';
import commonStyles from '../../styles';

import {
  CUSTOMER_STATUS_CODE,
  ALLOCATION,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    padding: 4,
    background: '#F3F3F3',
    margin: '0 0 8px',
    borderRight: '16px #fff solid',
    borderLeft: '16px #fff solid',
    position: 'sticky',
    top: 44,
    zIndex: 1,
  },
  listItem: {
    padding: 0,
    background: '#fff',
    marginRight: 4,
    width: 'auto',
    border: '1px solid #C8C8C8',
  },
  buttonIcon: {
    color: '#333',
    fontSize: 14,
    margin: '0 2px -1px 0',
  },
  searchItem: {
    display: 'block',
  },
  clear: {
    borderRadius: 0,
    padding: 6,
    '& > span': {
      lineHeight: 1,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));

function CustomerSearchList(props) {
  const { searchValue, setSearchValue, searchStart } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [divisionDisplayName, setdivisionDisplayName] = useState('');
  const [responseTypeDisplayName, setResponseTypeDisplayName] = useState({
    state1: '',
    state2: '',
    state3: '',
    state4: '',
  });
  const handleChange = (e, data) => {
    const val = e?.target ? e.target.value : e;
    switch (data.key) {
      case 'searchWord':
        setSearchValue({ ...searchValue, searchWord: val });
        break;
      case 'userSearchCode':
        setSearchValue({ ...searchValue, userSearchCode: val });
        break;
      case 'customerStatusCode':
        setSearchValue({ ...searchValue, customerStatusCode: val });
        break;
      default:
        break;
    }
  };

  const responseAtChange = (val) => {
    setSearchValue({
      ...searchValue,
      responseTypeId1: val[0] ? val[0].responseTypeId : null,
      responseTypeId2: val[1] ? val[1].responseTypeId : null,
      responseTypeId3: val[2] ? val[2].responseTypeId : null,
      responseTypeId4: val[3] ? val[3].responseTypeId : null,
    });
  };
  const list = [
    {
      key: 'searchWord',
      title: 'キーワード',
      state: searchValue.searchWord,
      setState: setSearchValue,
      value: searchValue,
      search: searchStart,
      set: handleChange,
    },
    {
      key: 'effectStartAt',
      title: '再反基準日',
      state1: searchValue.effectStartAt,
      state2: searchValue.effectEndAt,
      value: searchValue,
      setState: setSearchValue,
      search: searchStart,
    },
    {
      key: 'divisionId',
      title: '担当・組織',
      state: searchValue,
      setState: setSearchValue,
      search: searchStart,
      displayName: divisionDisplayName,
      setDisplayName: setdivisionDisplayName,
    },
    {
      key: 'userSearchCode',
      title: '割り当て',
      obj: {
        menus: ALLOCATION,
        title: '割り当て',
      },
      state: searchValue.userSearchCode,
      setState: setSearchValue,
      search: searchStart,
      set: handleChange,
    },
    {
      key: 'customerStatusCode',
      title: '状態',
      obj: {
        menus: CUSTOMER_STATUS_CODE,
        title: '状態',
      },
      state: searchValue.customerStatusCode,
      setState: setSearchValue,
      search: searchStart,
      set: handleChange,
    },
    {
      key: 'responseType',
      title: '媒体',
      title1: '媒体(大)',
      title2: '媒体(中)',
      title3: '媒体(小)',
      title4: '媒体(細)',
      state1: searchValue.responseTypeId1,
      state2: searchValue.responseTypeId2,
      state3: searchValue.responseTypeId3,
      state4: searchValue.responseTypeId4,
      setState: setSearchValue,
      search: searchStart,
      displayName: responseTypeDisplayName,
      setDisplayName: setResponseTypeDisplayName,
      set: responseAtChange,
    },
  ];

  const getSearch = (data) => {
    switch (data.key) {
      case 'searchWord':
        return <SearchTextField initial={data} />;
      case 'effectStartAt':
        return <SearchDate initial={data} />;
      case 'divisionId':
        return <SearchButton initial={data} />;
      case 'userSearchCode':
        return <SearchSelect initial={data} />;
      case 'customerStatusCode':
        return <SearchSelect initial={data} />;
      case 'responseType':
        return <SearchResponse initial={data} />;
      default:
        return (
          <span>該当なし</span>
        );
    }
  };

  return (
    <List
      className={classes.list}
    >
      {list.map((item) => (
        <ListItem
          key={item.key}
          className={classes.listItem}
        >
          <Grid className={classes.searchItem}>
            {getSearch(item)}
          </Grid>
        </ListItem>
      ))}
      <ListItem className={classes.listItem}>
        <Button
          className={`${baseClasses.verySmallStrong} ${classes.clear}`}
          style={{ color: 'red' }}
          size="small"
          onClick={() => setSearchValue()}
        >
          <ClearIcon className={classes.buttonIcon} />
          <span>クリア</span>
        </Button>
      </ListItem>
    </List>
  );
}

export default CustomerSearchList;
