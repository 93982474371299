import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import Icon from '@material-ui/core/Icon';
import {
  NO_CONTACT_DETAIL_REASON_CODE,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
  status: {
    position: 'absolute',
    zIndex: '9',
    top: '0',
    left: '0',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: '0',
      borderWidth: '32px 32px 0 0',
      borderStyle: 'solid',
      position: 'absolute',
      top: '0',
      left: '0',
    },
  },
  status1: {
    color: theme.palette.primaryColor,
    '&::before': {
      borderColor: `${theme.palette.primaryColor} transparent`,
    },
  },
  status2: {
    color: '#D83420',
    '&::before': {
      borderColor: '#D83420 transparent',
    },
  },
  status3: {
    color: '#D83420',
    '&::before': {
      borderColor: '#D83420 transparent',
    },
  },
  status4: {
    color: '#27AE60',
    '&::before': {
      borderColor: '#27AE60 transparent',
    },
  },
  statusBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  statusIcon: {
    fontSize: 60,
  },
  statusTtl: {
    fontSize: 52,
    lineHeight: 1.2,
    fontWeight: '700',
    letterSpacing: 0,
  },
  statusTxt: {
    fontSize: 15,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    width: '100%',
    textAlign: 'center',
  },
}));

function Status(props) {
  const { customer } = props;

  const classes = useStyles();

  const getStatus = () => {
    switch (customer.customerStatusCode) {
      case 2:
        return (
          <Grid className={`${classes.status} ${classes.status2}`}>
            <Grid className={classes.statusBox}>
              <BlockIcon className={classes.statusIcon} />
              <Typography className={classes.statusTtl}>
                追わない
              </Typography>
              <Typography className={classes.statusTxt}>
                {NO_CONTACT_DETAIL_REASON_CODE[customer.noContactDetailReasonCode]}
              </Typography>
            </Grid>
          </Grid>
        );
      case 10:
        return (
          <Grid className={`${classes.status} ${classes.status4}`}>
            <Grid className={classes.statusBox}>
              <Icon className={classes.statusIcon}>verified</Icon>
              <Typography className={classes.statusTtl}>
                契約済み
              </Typography>
            </Grid>
          </Grid>
        );
      case 11:
        return (
          <Grid className={`${classes.status} ${classes.status3}`}>
            <Grid className={classes.statusBox}>
              <FlashOnIcon className={classes.statusIcon} />
              <Typography className={classes.statusTtl}>
                クレーム
              </Typography>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            {/* ラベルなし */}
          </Grid>
        );
    }
  };

  return getStatus();
}

export default React.memo(Status);
