import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Draggable from 'react-draggable';
import { Button, Dialog, DialogTitle, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../styles/theme';
import commonStyles from '../../styles';
import CustomerTreeResponse from './customerTreeResponse';
import { classNames, randomId } from '../../../commonFunction';
import store from '../../../store';
import { setInitialState } from '../../../store/search/searchItemsSlice';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '100%',
        background: '#F3F3F3',
      },
      '& .MuiDialog-paper': {
        maxWidth: 'none',
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'initial',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
        borderRadius: 0,
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      '& .MuiDialog-paperWidthSm': {
        padding: 0,
        minWidth: 400,
      },
    },
  },
  header: {
    borderBottom: 'solid 1px #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '19px 0 9px',
      textAlign: 'center',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
    },
    '& > p': {
      [commonTheme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  body: {
    overflowY: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      overflowY: 'initial',
      '& > div': {
        marginTop: 0,
        height: '100%',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
      height: '100%',
    },
  },
  isModalBody: {
    [commonTheme.breakpoints.up('md')]: {
      height: '390px',
    },
  },
  inner: {
    background: '#fff',
    borderTop: '1px #C8C8C8 solid',
    borderRadius: 4,
    padding: 8,
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
  },
  notTree: {
    textAlign: 'center',
    padding: '10% 0',
  },
});

function PaperComponent(props) {
  const {
    'aria-labelledby': id,
  } = props;

  return (
    <Draggable
      handle={`[data-drag-id="${id}"]`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function SP(props) {
  const {
    isOpen,
    onCancel,
    onClickSelect,
    children,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();
  const { hasLoading } = useLoading();

  return (
    <Dialog
      className={`${classes.dialog}`}
      open={isOpen}
      onClose={() => { onCancel(false); }}
    >
      <Grid className={`${common.header} ${classes.header}`}>
        <Typography className={common.title2}>
          担当者選択
        </Typography>
        <Button className={common.closeButton} onClick={() => { onCancel(false); }} />
      </Grid>
      <Grid className={`${common.body} ${classes.body} ${hasLoading('spCustomerTree') ? common.loading : ''}`}>
        {children}
      </Grid>
      <Grid className={`${common.footer} ${classes.footer}`}>
        <Grid item className={classes.btn}>
          <Button
            className={common.buttonsSecondary}
            onClick={() => {
              onCancel(true);
              dispatch(setInitialState());
            }}
            type="reset"
          >
            選択解除
          </Button>
          <Button
            className={common.buttonsPrimary}
            onClick={() => {
              onClickSelect();
            }}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

// 元コンポーネントからuser, division, customerのstateが渡されなかった時の為、
// 仮のFunction用意
const temporaryFunction = () => {};

/**
*@module CustomerTree
*@param {boolean}  isOpen, - ツリー描画可否(Boolean)
*@param {func}  setIsOpen, - ツリー描画可否setState(setState)
*@param {func}  setSelectUser, - 任意：選択されたユーザー情報のsetState(setState)
*@param {func}  setSelectDivision, - 任意：選択された組織情報のsetState(setState)
*@param {func}  setSelectCustomer, - 任意：選択された顧客情報のsetState(setState)
*@param {func}  - searchParams, - division005のリクエストbody内容 (object)
*@param {boolean}  isModal, - ダイアログ形式かどうか判定（Boolean）
*@param {func}  setHandleComfirm, - [保存]ボタンを押した場合に走らせる処理
*@param {func}  setHandleCancel, - [選択解除]ボタンを押した場合に走らせる処理
*@param {boolean}  searchForm, - キーワード検索の有無判定（Boolean）
*@param {obj}  targetFlgList, - 組織、社員、顧客にて検索、選択をするか判定(object)
*@param {string}  titleHeader, - ツリーのタイトルが必要なら文字列 / 不要ならnull
*@param {int}  defaultUserId, - 初期描画で必要なデフォルトのUserId / 不要ならnull
*@param {boolean}  displayCustomer, - 顧客を表示非表示のbool
 */
function CustomerTree(props) {
  const {
    isOpen, // ツリー描画可否(Boolean)
    setIsOpen, // ツリー描画可否setState(setState)
    setSelectUser = temporaryFunction, // 任意：選択されたユーザー情報のsetState(setState)
    setSelectDivision = temporaryFunction, // 任意：選択された組織情報のsetState(setState)
    setSelectCustomer = temporaryFunction, // 任意：選択された顧客情報のsetState(setState)
    setSelectUniqueId = temporaryFunction,
    isModal, // ダイアログ形式かどうか判定（Boolean）
    setHandleComfirm = () => {}, // [保存]ボタンを押した場合に走らせる処理
    setHandleCancel = () => {}, // 選択解除ボタンを押した場合に走らせる処理
    searchForm, // キーワード検索の有無判定（Boolean）
    initialHeader, // 分類/単位の初期値を設定({typeCode: number, unitCode: number}, 初期値は反響日、センター)
    isHaveInitialParams, // { key: value } 参照: src\store\search\searchItemsSlice.js/initialState
    targetFlgList, // 組織、社員、顧客にて検索、選択をするか判定(object)
    titleHeader, // ツリーのタイトルが必要なら文字列 / 不要ならnull
    defaultUserId, // 初期描画で必要なデフォルトのUserId / 不要ならnull
    defaultDivisionId, // デフォルトのDivisionId / 不要ならnull
    // defaultCustomerId, // デフォルトの顧客ID（顧客メインのURLにCustomer指定時のみ使う）
    defaultUniqueId,
    displayCustomer, // 顧客を表示するかどうか / true = 表示
    isAvoidSharedCustomer, // 被共有顧客除外フラグを1に固定するか,
    isMulti, // 複数選択
    isDirect, // 指示ツリー時使用
    options,
    sortType = 'effectAt',
    treeHeight = '302px',
  } = props;

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const classes = useStyles();
  const common = commonStyles();
  const [id] = useState(randomId());
  const { responseHeader } = store.getState();

  // 値の初期化とモーダル閉じる
  const onCancel = useCallback((isClear = false) => {
    // ✖ボタンでinitialに戻すなら下記
    // if (isInitial) {
    //   setSelectUser({ userId: defaultUserId ?? 0, userName: '' });
    //   setSelectDivision({ divId: defaultDivisionId ?? '', divisionName: '' });
    // } else {
    //   setSelectUser({ userId: 0, userName: '' });
    //   setSelectDivision({ divId: 0, divisionName: '' });
    // }
    setIsOpen(false);
    // console.log('isClear', isClear);
    if (isClear) {
      // UserTreeと同じにしたかったけど、selectUser, selectDivisionを渡していないため、
      // ✖ボタンに（PCは背景押下）による、キャンセルであるか選択解除によるキャンセルか判断し
      // 選択解除によるキャンセルの場合のみ値をクリアしsetHandleCancelをコール
      setSelectUser({ userId: 0, userName: '' });
      setSelectDivision({ divId: 0, divisionName: '' });
      setSelectCustomer([]);
      setSelectUniqueId(undefined);
      setHandleCancel();
    }
  }, []);

  const onClickSelect = useCallback(() => {
    setHandleComfirm();
    setIsOpen(false);
  }, [options]);

  const customerTreeResponse = useMemo(() => {
    return (
      <CustomerTreeResponse
        setSelectUser={setSelectUser}
        setSelectDivision={setSelectDivision}
        setSelectCustomer={setSelectCustomer}
        setSelectUniqueId={setSelectUniqueId}
        inModal={isModal} // モーダル内に配置する場合[true]に設定
        searchForm={searchForm}
        initialHeader={initialHeader}
        isHaveInitialParams={isHaveInitialParams}
        targetFlgList={targetFlgList}
        defaultUserId={defaultUserId}
        defaultDivisionId={defaultDivisionId}
        defaultUniqueId={defaultUniqueId}
        displayCustomer={displayCustomer}
        isAvoidSharedCustomer={isAvoidSharedCustomer}
        isMulti={isMulti}
        isDirect={isDirect}
        options={options}
        sortType={sortType}
        treeHeight={treeHeight}
      />
    );
  }, [
    isModal,
    isMulti,
    searchForm,
    targetFlgList,
    defaultUserId,
    defaultDivisionId,
    displayCustomer,
    isHaveInitialParams,
    isAvoidSharedCustomer,
    treeHeight,
  ]);

  const pcTree = isSp ? null : (
    <>
      {titleHeader ? (
        <DialogTitle data-drag-id={id} className={classNames(common.header, classes.header)}>
          <Typography className={common.title3}>
            {titleHeader}
          </Typography>
        </DialogTitle>
      ) : null}
      <Grid className={classNames({ [`${common.body} ${classes.isModalBody}`]: isOpen }, classes.body)}>
        <Grid className={`${classes.inner}`}>
          {customerTreeResponse}
        </Grid>
      </Grid>
    </>
  );

  // PC -- 常時表示かdialogの切り分け
  const displayDivisionTree = () => {
    if (isOpen) {
      return (
        <Dialog
          open={isOpen}
          onClose={() => { onCancel(false); }}
          PaperComponent={PaperComponent}
          aria-labelledby={id}
          maxWidth="xs"
          fullWidth
          className={`${classes.dialog}`}
        >
          { pcTree }
          <Grid className={`${common.footer} ${classes.footer}`}>
            <Button className={common.buttonsSecondary} onClick={() => { onCancel(false); }} type="reset">
              キャンセル
            </Button>
            <Button className={common.buttonsPrimary} onClick={onClickSelect}>
              保存
            </Button>
          </Grid>
        </Dialog>
      );
    }
    return pcTree;
  };

  if (isMulti && defaultUserId) {
    return <div>Error: customerTree.js:285</div>;
  } else if (responseHeader.roleIds) {
    if (!responseHeader.roleIds.includes(37)) {
      return <div className={classes.notTree}>反響ツリー参照権限がありません</div>;
    }
  }

  // SP -- dialogでの表示(m32でのみ使用)
  return isSp ? (
    <SP
      isOpen={isOpen}
      onCancel={onCancel}
      onClickSelect={onClickSelect}
    >
      {customerTreeResponse}
    </SP>
  ) : displayDivisionTree();
}

export default React.memo(CustomerTree);
