import client from '../httpClient';

/**
 * 【hpinfo002】WEB会員ステータス変更
 * http://10.22.2.48:8080/docs/#/members/get-members-search-conditions-member-id
 * memberId/hashCustomerId いずれか1つのパラメータを指定
 * @param {{
 * memberId?: number;
 * hashCustomerId?: number;
 * offset: number;
 * limit: number;
 * }} params
 * @returns {Promise<{total: number; conditions: T[]}>}
 */
const membersGetSearchConditionsApi = async (params) => {
  try {
    const res = await client.get('/members/search-conditions', { params });
    return res.data;
  } catch (e) {
    console.error(e);
    return {
      total: 0,
      conditions: [],
    };
  }
};

export default membersGetSearchConditionsApi;
