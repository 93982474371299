import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const divisionMasterUploadCSVSlice = createSlice({
  name: 'divisionMasterUploadCSV',
  initialState: { divisionMasterUploadCSV: initialState },
  reducers: {
    divisionMasterUploadCSVApi: (state, data) => {
      state.divisionMasterUploadCSV = data.payload;
    },
  },
});

export const { divisionMasterUploadCSVApi } = divisionMasterUploadCSVSlice.actions;

export default divisionMasterUploadCSVSlice.reducer;
