import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InstructionReportItem from './instructionReportItem';
import InstructionReportItemSp from './sp/instructionReportItemSp';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      marginTop: 8,
      overflowY: 'auto',
      maxHeight: 626,
    },
  },
  txt: {
    fontSize: 15,
    lineHeight: '160px',
    letterSpacing: 0,
    textAlign: 'center',
  },
});

function InstructionReportItems(props) {
  const {
    tab,
    id1,
    id2,
    informations,
    createTimeline,
    getDirects,
    headerReload,
    // customer,
  } = props;

  const classes = useStyles();
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const list = informations.directs.filter((item) => {
    return item.directReportStatusCode === id1 || item.directReportStatusCode === id2;
  });

  const len = list.length;

  const getItem = () => {
    return (
      <Grid className={classes.informationItems}>
        {list.map((information) => (
          isSP ? (
            <InstructionReportItemSp
              setOpen={() => console.log('not modal')}
              key={information.directId}
              information={information}
              tab={tab}
              getDetailDirect={() => console.log('not get')}
              getDirects={getDirects}
              // customer={customer}
            />
          ) : (
            <InstructionReportItem
              setOpen={() => console.log('not modal')}
              information={information}
              tab={tab}
              getDetailDirect={() => console.log('not get')}
              createTimeline={createTimeline}
              getDirects={getDirects}
              headerReload={headerReload}
            />
          )
        ))}
      </Grid>
    );
  };

  const getNone = () => {
    return (
      <Grid className={classes.informationItems}>
        <Typography className={classes.txt}>
          対象が存在しません。
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid className={classes.root}>
      {len === 0 ? getNone() : getItem()}
    </Grid>
  );
}

export default InstructionReportItems;
