import { useState, useEffect } from 'react';
import getDeviceType from '../components/getDeviceType';

/**
 * ブラウザやウィンドウなどの情報処理
 * @returns {{
 * isSP: boolean;
 * }}
 */
export default function useBrowser() {
  const [isSP, setIsSP] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      // TODO
      setIsSP(getDeviceType() || document.querySelector('#root')?.offsetWidth < 768);
    };

    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return {
    isSP,
  };
}
