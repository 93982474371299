import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, Select, MenuItem } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import {
  PERMANENT_RESIDENCY,
} from '../../../../constants';
import Calendar from '../../calendar';
import inputNumber from '../../../../commonFunction/inputNumber';
import { convertForm1, convertFormKana, convertFormNumber } from '../../../../commonFunction/convertors';
import { TextBaseField } from '../../../eleCommon/validation';
import { validateFormString1, validateFormInt1 } from '../../../../commonFunction/validations';
import toISOStringWithTimezone from '../../../../commonFunction/toISOStringWithTimezone';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 8,
    [commonTheme.breakpoints.up('md')]: {
      padding: 8,
    },
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
  },
  block: {
    width: 536,
    padding: 8,
    background: '#fff',
    border: '1px solid #C8C8C8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blockBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blockLbl: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    lineHeight: '15.6px',
    width: '18%',
  },
  blockLbl01: {
    marginRight: 16,
  },
  blockLbl02: {
    fontWeight: 700,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [commonTheme.breakpoints.up('md')]: {
      width: 40,
      marginRight: 16,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '2px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      [commonTheme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    '& > div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  blockKana: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    lineHeight: '20.8px',
  },
  blockKanaTxt: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    // textAlign: 'left',
    [commonTheme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginRight: '16px',
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 100,
      marginRight: '16px',
    },
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      textAlign: 'left',
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#C8C8C8',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      fontSize: 13,
      transform: 'translate(0, 9px) scale(1)',
      '&.Mui-focused': {
        opacity: 0,
      },
      '&.MuiInputLabel-shrink': {
        opacity: 0,
      },
    },
  },
  blockName: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0 0',
    [commonTheme.breakpoints.up('md')]: {
      lineHeight: '24px',
    },
  },
  blockNameTxt: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    '&:first-child': {
      [commonTheme.breakpoints.down('sm')]: {
        marginRight: '16px',
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 100,
      marginRight: '16px',
    },
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#C8C8C8',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      fontSize: 20,
      transform: 'translate(0, 7px) scale(1)',
      '&.Mui-focused': {
        opacity: 0,
      },
      '&.MuiInputLabel-shrink': {
        opacity: 0,
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  blockAge: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      lineHeight: '20.8px',
    },
    [commonTheme.breakpoints.up('md')]: {
      margin: '4px 8px 0 0',
      width: 54,
    },
  },
  blockAgeNum: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'right',
    },
    [commonTheme.breakpoints.up('md')]: {
      margin: '0 0 8px 4px',
    },
    '& > div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      color: '#333',
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
      [commonTheme.breakpoints.up('md')]: {
        marginBottom: '-5px',
      },
    },
  },
  blockBirthday: {
    '& div': {
      border: 'none',
      padding: 0,
    },
    '& span': {
      padding: 0,
    },
  },
  blockBirthdayBox: {
    display: 'flex',
    alignItems: 'center',
  },
  blockList: {
    display: 'flex',
    alignItems: 'center',
  },
  blockItem: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '15.6px',
    '& :not(:first-child)': {
      marginLeft: 8,
    },
  },
  blockItemSelect: {
    width: 52,
  },
  blockItemVal: {
    display: 'block',
    margin: '0 0 0 4px',
    width: 17,
    '& > div': {
      margin: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      color: '#333',
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  blockNationalityTxt: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: '20.8px',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 20,
      margin: '-5px 8px 0 0',
    },
    '& > div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -8px) scale(0.6)',
      },
      [commonTheme.breakpoints.down('sm')]: {
        height: 20,
      },
    },
  },
  lbl: {
    whiteSpace: 'nowrap',
    marginRight: 4,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function CustomerDetailName(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  // 永住権
  const menus = Object.keys(PERMANENT_RESIDENCY);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // 年齢計算
  const getAgeCalc = (date) => {
    const today = new Date();
    const birthdayDate = new Date(date);
    const thisYearsBirthday = new Date(
      today.getFullYear(),
      birthdayDate.getMonth(),
      birthdayDate.getDate(),
    );
    let ageCount = today.getFullYear() - birthdayDate.getFullYear();
    if (today < thisYearsBirthday) {
      ageCount -= 1;
    }
    return ageCount;
  };

  // 年齢
  const [age, setAge] = useState(
    input.detail.birthday ? getAgeCalc(input.detail.birthday) : input.detail.age,
  );
  const [subAge, setSubAge] = useState(
    input.detail.subBirthday ? getAgeCalc(input.detail.subBirthday) : input.detail.subAge,
  );
  const handleChangeAge = (e) => {
    setAge(convertFormNumber(e.target.value));
  };
  const handleChangeSubAge = (e) => {
    setSubAge(convertFormNumber(e.target.value));
  };

  // フォーカスが外れた時
  const onBlur = (e) => {
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  // 項目選択時に更新 SP
  const onSelect1 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, isPermanentResidency: val });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data,
      isPermanentResidency: val !== 99 ? Number(val) : null,
    });
  };
  const onSelect2 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, isSubPermanentResidency: val });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data,
      isSubPermanentResidency: val !== 99 ? Number(val) : null,
    });
  };

  // 永住権 PC
  const selectChange1 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, isPermanentResidency: e.target.value !== '' ? Number(e.target.value) : null });
  };
  const selectChange2 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, isSubPermanentResidency: e.target.value !== '' ? Number(e.target.value) : null });
  };
  const selectBlur1 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, isPermanentResidency: e.target.value !== '' ? Number(e.target.value) : null });
  };
  const selectBlur2 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, isSubPermanentResidency: e.target.value !== '' ? Number(e.target.value) : null });
  };
  const customerSelectList = {
    isPermanentResidencyItem: {
      type: 'selectBlank',
      obj: {
        menus: PERMANENT_RESIDENCY,
      },
      state: input.detail.isPermanentResidency,
      set: selectBlur1,
      change: selectChange1,
    },
    isSubPermanentResidencyItem: {
      type: 'selectBlank',
      obj: {
        menus: PERMANENT_RESIDENCY,
      },
      state: input.detail.isSubPermanentResidency,
      set: selectBlur2,
      change: selectChange2,
    },
  };

  // 生年月日
  const maxDate = toISOStringWithTimezone(new Date()).split('T')[0]; // 1999-01-01の形式
  const [birthday, setBirthday] = useState(input.detail.birthday ?? '');
  const birthdaySetState = (data) => {
    if (data) {
      setBirthday(data.replaceAll('-', '/'));
      // 変更した値をセット
      input.setDetail({
        ...input.detail,
        birthday: data.replaceAll('-', '/'),
        age: getAgeCalc(data.replaceAll('-', '/')),
      });
      // 更新用の値をセット
      input.dataInput.setData({
        ...input.dataInput.data,
        birthday: data.replaceAll('-', '/'),
        age: getAgeCalc(data.replaceAll('-', '/')),
      });
      // 年齢を計算して設定
      setAge(getAgeCalc(data.replaceAll('-', '/')));
    } else {
      setBirthday(null);
      // 変更した値をセット
      input.setDetail({
        ...input.detail,
        birthday: null,
      });
      // 更新用の値をセット
      input.dataInput.setData({
        ...input.dataInput.data,
        birthday: null,
      });
    }
  };
  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: birthday, // state
      setState: birthdaySetState,
      max: maxDate,
      initialDate: '1990-01-01',
    },
  ];
  const [subBirthday, setSubBirthday] = useState(input.detail.subBirthday ?? '');
  const subBirthdaySetState = (data) => {
    if (data) {
      setSubBirthday(data.replaceAll('-', '/'));
      // 変更した値をセット
      input.setDetail({
        ...input.detail,
        subBirthday: data.replaceAll('-', '/'),
        subAge: getAgeCalc(data.replaceAll('-', '/')),
      });
      // 更新用の値をセット
      input.dataInput.setData({
        ...input.dataInput.data,
        subBirthday: data.replaceAll('-', '/'),
        subAge: getAgeCalc(data.replaceAll('-', '/')),
      });
      // 年齢を計算して設定
      setSubAge(getAgeCalc(data.replaceAll('-', '/')));
    } else {
      setSubBirthday(null);
      // 変更した値をセット
      input.setDetail({
        ...input.detail,
        subBirthday: null,
      });
      // 更新用の値をセット
      input.dataInput.setData({
        ...input.dataInput.data,
        subBirthday: null,
      });
    }
  };
  const datalistSub = [
    {
      id: 'id2', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: subBirthday, // state
      setState: subBirthdaySetState,
      max: maxDate,
      initialDate: '1990-01-01',
    },
  ];

  const getKana = () => {
    return (
      <Grid className={classes.blockKana}>
        <TextBaseField
          className={classes.blockKanaTxt}
          defaultValue={input.detail.lastNameKana}
          label="セイ"
          name="lastNameKana"
          onBlur={onBlur}
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('lastNameKana')}
        />
        <TextBaseField
          className={classes.blockKanaTxt}
          defaultValue={input.detail.firstNameKana}
          label="メイ"
          name="firstNameKana"
          onBlur={onBlur}
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('firstNameKana')}
        />
      </Grid>
    );
  };
  const getKanaSp = () => {
    return (
      <Grid className={classes.blockKana}>
        <TextBaseField
          defaultValue={input.detail.lastNameKana}
          name="lastNameKana"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('lastNameKana')}
        />
        <TextBaseField
          defaultValue={input.detail.firstNameKana}
          name="firstNameKana"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('firstNameKana')}
        />
      </Grid>
    );
  };

  const getName = () => {
    return (
      <Grid className={classes.blockName}>
        <TextBaseField
          className={classes.blockNameTxt}
          defaultValue={input.detail.lastName}
          label="姓"
          name="lastName"
          onBlur={onBlur}
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('lastName')}
        />
        <TextBaseField
          className={classes.blockNameTxt}
          defaultValue={input.detail.firstName}
          label="名"
          name="firstName"
          onBlur={onBlur}
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('firstName')}
        />
      </Grid>
    );
  };
  const getNameSp = () => {
    return (
      <Grid className={classes.blockName}>
        <TextBaseField
          defaultValue={input.detail.lastName}
          name="lastName"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('lastName')}
        />
        <TextBaseField
          defaultValue={input.detail.firstName}
          name="firstName"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('firstName')}
        />
      </Grid>
    );
  };

  const getAge = () => {
    return (
      <Grid className={classes.blockAge}>
        <TextBaseField
          type="number"
          inputProps={{ min: '0' }}
          className={classes.blockAgeNum}
          value={age}
          name="age"
          onChange={handleChangeAge}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999.0 })}
          errorCallback={input.errorCallback('age')}
        />
        <Typography className={`${baseClasses.title4} ${classes.blockAgeTxt}`}>
          才
        </Typography>
      </Grid>
    );
  };

  const getBirthday = () => {
    return (
      <Calendar data={datalist[0]} />
    );
  };

  const getNationality = () => {
    return (
      <Grid className={classes.blockItem}>
        <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
          国籍
        </Typography>
        <TextBaseField
          className={classes.blockNationalityTxt}
          defaultValue={input.detail.nationality}
          name="nationality"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 20 })}
          errorCallback={input.errorCallback('nationality')}
        />
      </Grid>
    );
  };

  const getResidencyPc = () => {
    return (
      <Grid className={classes.blockItem}>
        <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
          永住権
        </Typography>
        <Grid className={classes.blockItemSelect}>
          {input.dataInput.component(customerSelectList.isPermanentResidencyItem)}
        </Grid>
      </Grid>
    );
  };

  const getResidencySp = () => {
    return (
      <Select
        className={classes.residency}
        defaultValue={input.detail.isPermanentResidency || 99}
        onChange={(e) => {
          onSelect1(e.target.value);
        }}
      >
        <MenuItem value={99}>未選択</MenuItem>
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
            >
              {PERMANENT_RESIDENCY[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getSubKana = () => {
    return (
      <Grid className={classes.blockKana}>
        <TextBaseField
          className={classes.blockKanaTxt}
          defaultValue={input.detail.subLastNameKana}
          label="セイ"
          name="subLastNameKana"
          onBlur={onBlur}
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subLastNameKana')}
        />
        <TextBaseField
          className={classes.blockKanaTxt}
          defaultValue={input.detail.subFirstNameKana}
          label="メイ"
          name="subFirstNameKana"
          onBlur={onBlur}
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subFirstNameKana')}
        />
      </Grid>
    );
  };
  const getSubKanaSp = () => {
    return (
      <Grid className={classes.blockKana}>
        <TextBaseField
          defaultValue={input.detail.subLastNameKana}
          name="subLastNameKana"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subLastNameKana')}
        />
        <TextBaseField
          defaultValue={input.detail.subFirstNameKana}
          name="subFirstNameKana"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subFirstNameKana')}
        />
      </Grid>
    );
  };

  const getSubName = () => {
    return (
      <Grid className={classes.blockName}>
        <TextBaseField
          className={classes.blockNameTxt}
          defaultValue={input.detail.subLastName}
          label="姓"
          name="subLastName"
          onBlur={onBlur}
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subLastName')}
        />
        <TextBaseField
          className={classes.blockNameTxt}
          defaultValue={input.detail.subFirstName}
          label="名"
          name="subFirstName"
          onBlur={onBlur}
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subFirstName')}
        />
      </Grid>
    );
  };
  const getSubNameSp = () => {
    return (
      <Grid className={classes.blockName}>
        <TextBaseField
          defaultValue={input.detail.subLastName}
          name="subLastName"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subLastName')}
        />
        <TextBaseField
          defaultValue={input.detail.subFirstName}
          name="subFirstName"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 50 })}
          errorCallback={input.errorCallback('subFirstName')}
        />
      </Grid>
    );
  };

  const getSubAge = () => {
    return (
      <Grid className={classes.blockAge}>
        <TextBaseField
          type="number"
          inputProps={{ min: '0' }}
          className={classes.blockAgeNum}
          value={subAge}
          name="subAge"
          onChange={handleChangeSubAge}
          onBlur={onBlur}
          placeholder="-"
          validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999.0 })}
          convertor={convertFormNumber}
          errorCallback={input.errorCallback('subAge')}
        />
        <Typography className={`${baseClasses.title4} ${classes.blockAgeTxt}`}>
          才
        </Typography>
      </Grid>
    );
  };

  const getSubBirthday = () => {
    return (
      <Calendar data={datalistSub[0]} />
    );
  };

  const getSubRelation = () => {
    return (
      <TextBaseField
        className={classes.blockLbl02}
        defaultValue={input.detail.relation}
        name="relation"
        onBlur={onBlur}
        placeholder={isSp ? '-' : '関係'}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('relation')}
      />
    );
  };

  const getSubNationality = () => {
    return (
      <Grid className={classes.blockItem}>
        <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
          国籍
        </Typography>
        <TextBaseField
          className={classes.blockNationalityTxt}
          defaultValue={input.detail.subNationality}
          name="subNationality"
          onBlur={onBlur}
          placeholder="-"
          convertor={convertForm1}
          validator={validateFormString1({ maxLengthInt: 20 })}
          errorCallback={input.errorCallback('subNationality')}
        />
      </Grid>
    );
  };

  const getSubResidencyPc = () => {
    return (
      <Grid className={classes.blockItem}>
        <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
          永住権
        </Typography>
        <Grid className={classes.blockItemSelect}>
          {input.dataInput.component(customerSelectList.isSubPermanentResidencyItem)}
        </Grid>
      </Grid>
    );
  };

  const getSubResidencySp = () => {
    return (
      <Select
        className={classes.residency}
        defaultValue={input.detail.isSubPermanentResidency || 99}
        onChange={(e) => {
          onSelect2(e.target.value);
        }}
      >
        <MenuItem value={99}>未選択</MenuItem>
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
            >
              {PERMANENT_RESIDENCY[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const list1 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'セイメイ',
      val: (input.detail.lastNameKana ? input.detail.lastNameKana : '') + '　' + (input.detail.lastNameKana ? input.detail.lastNameKana : ''),
      content: getKanaSp,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '姓名',
      val: (input.detail.lastName ? input.detail.lastName : '') + '　' + (input.detail.firstName ? input.detail.firstName : ''),
      fontSize: '20px',
      content: getNameSp,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '年齢',
      val: (age || '') + '才',
      content: getAge,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '生年月日',
      val: birthday,
      content: getBirthday,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '国籍',
      val: input.detail.nationality,
      content: getNationality,
    },
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '永住権',
      val: PERMANENT_RESIDENCY[input.detail.isPermanentResidency],
      content: getResidencySp,
    },
  ];

  const list2 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'セイメイ',
      val: (input.detail.subLastNameKana ? input.detail.subLastNameKana : '') + '　' + (input.detail.subFirstNameKana ? input.detail.subFirstNameKana : ''),
      content: getSubKanaSp,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '姓名',
      val: (input.detail.subLastName ? input.detail.subLastName : '') + '　' + (input.detail.subFirstName ? input.detail.subFirstName : ''),
      fontSize: '20px',
      content: getSubNameSp,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '年齢',
      val: (subAge || '') + '才',
      content: getSubAge,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '生年月日',
      val: subBirthday,
      content: getSubBirthday,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '関係',
      val: input.detail.relation,
      content: getSubRelation,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '国籍',
      val: input.detail.subNationality,
      content: getSubNationality,
    },
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '永住権',
      val: PERMANENT_RESIDENCY[input.detail.isSubPermanentResidency],
      content: getSubResidencySp,
    },
  ];

  if (isSp) {
    contents = (
      <Grid className={classes.root}>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          顧客 主
        </Typography>
        <List className={classes.list}>
          {list1.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          顧客 副
        </Typography>
        <List className={classes.list}>
          {list2.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          氏名
        </Typography>
        <Grid className={classes.box}>
          <Grid className={classes.block}>
            <Grid className={classes.blockLbl}>
              <Typography className={`${baseClasses.title6} ${classes.blockLbl01}`}>
                主
              </Typography>
              <Typography className={`${baseClasses.title6} ${classes.blockLbl02}`}>
                本人
              </Typography>
            </Grid>
            <Grid className={classes.blockInfo}>
              <Grid className={classes.blockBox}>
                {getKana()}
                <Grid className={classes.blockList}>
                  {getNationality()}
                  {getResidencyPc()}
                </Grid>
              </Grid>
              <Grid className={classes.blockBox}>
                {getName()}
                {getAge()}
                <Grid className={classes.blockBirthday}>
                  <Grid className={classes.blockBirthdayBox}>
                    <Typography className={baseClasses.title4}>(</Typography>
                    {getBirthday()}
                    <Typography className={baseClasses.title4}>)</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.block}>
            <Grid className={classes.blockLbl}>
              <Typography className={`${baseClasses.title6} ${classes.blockLbl01}`}>
                副
              </Typography>
              {getSubRelation()}
            </Grid>
            <Grid className={classes.blockInfo}>
              <Grid className={classes.blockBox}>
                {getSubKana()}
                <Grid className={classes.blockList}>
                  {getSubNationality()}
                  {getSubResidencyPc()}
                </Grid>
              </Grid>
              <Grid className={classes.blockBox}>
                {getSubName()}
                {getSubAge()}
                <Grid className={classes.blockBirthday}>
                  {datalistSub.map((array) => {
                    return (
                      <Grid key={array.id} className={classes.blockBirthdayBox}>
                        <Typography className={baseClasses.title4}>(</Typography>
                        <Calendar data={array} />
                        <Typography className={baseClasses.title4}>)</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
