import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const lineUpdateIdSlice = createSlice({
  name: 'lineUpdateId',
  initialState: { lineUpdateId: initialState },
  reducers: {
    lineUpdateIdApi: (state, data) => {
      state.lineUpdateId = data.payload;
    },
  },
});

export const { lineUpdateIdApi } = lineUpdateIdSlice.actions;

export default lineUpdateIdSlice.reducer;
