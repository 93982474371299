import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { closeAlertMessage } from '../../store/eleCommon/customAlertMessage';
import commonStyles from '../styles';
import commonTheme from '../styles/theme';

const useStyles = makeStyles(() => ({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '1301!important',
    },
  },
  alertTitleColor: {
    color: '#f44336',
    textAlign: 'center',
  },
  noticeTitleColor: {
    textAlign: 'center',
  },
  alertBack: {
    backgroundColor: 'rgb(253, 236, 234)',
  },
  userCustomButtonSave: {
    padding: '8px 22px',
    width: '74px',
    height: '40px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '12px 16px',
  },
}));

// TODO: Use Alert -> 以下三行が必要です
// import { useDispatch } from 'react-redux';
// import { changeAlertMessage } from 'store/eleCommon/customAlertMessage';
// エラーを出したい時に
// dispatch(changeAlertMessage({
//   msgList: ['メッセージ'], // 必須
//   title: 'タイトル', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
//   isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
// }));
function CustomAlertMessage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const baseClasses = commonStyles();
  const openAlert = useSelector((state) => state.alertMessage.alertStatus);

  const handleAlertClose = () => {
    // 以下Openしたい時
    // dispatch(changeAlertMessage({msgList: ['メッセージ', 'メッセージ2']})
    // 以下Closeしたい時
    dispatch(closeAlertMessage());
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={openAlert.open}
      onClose={handleAlertClose}
      fullWidth
      maxWidth="sm"
      className={classes.dialog}
    >
      <DialogTitle
        className={openAlert.isNotice
          ? classes.noticeTitleColor
          : classes.alertTitleColor}
      >
        {openAlert.title
          ? openAlert.title
          : '入力された内容に誤りがあります'}
      </DialogTitle>
      <DialogContent
        className={openAlert.isNotice
          ? null
          : classes.alertBack}
      >
        <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
          {openAlert.msgList.map((data) => (
            <div key={data}>
              <span style={{ width: '100%' }}>{data}</span>
              <br />
            </div>
          ))}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            onClick={handleAlertClose}
            color="primary"
            className={`${baseClasses.buttonsPrimary} ${classes.userCustomButtonSave}`}
          >
            OK
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default CustomAlertMessage;
