// components/areaSentaku/

// お休み options
export const holidaySelect = {
  menus: {
    0: '月曜日',
    1: '火曜日',
    2: '水曜日',
    3: '木曜日',
    4: '金曜日',
    5: '土曜日',
    6: '日曜日',
  },
  title: 'お休み',
};

// TEL時間帯 / 連絡がつきやすい時間帯 options
export const timeSelect = {
  menus: {
    0: '午前中',
    1: '12時～13時',
    2: '13時～15時',
    3: '15時～18時',
    4: '18時～19時',
    5: '19時～20時',
    6: '20時～21時',
  },
  title: 'TEL時間帯 / 連絡がつきやすい時間帯',
};
