import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const mokuhyoSetResisterSlice = createSlice({
  name: 'mokuhyoSetResister',
  initialState: { mokuhyoSetResister: initialState },
  reducers: {
    mokuhyoSetResisterApi: (state, data) => {
      state.mokuhyoSetResister = data.payload;
    },
  },
});

export const { mokuhyoSetResisterApi } = mokuhyoSetResisterSlice.actions;

export default mokuhyoSetResisterSlice.reducer;
