const zenkakuToHankaku = (str) => {
  if (Number.isSafeInteger(str)) {
    return str;
  } else if (str.length === 0) {
    return '';
  } else {
    const hen = str.replace(/[！-～]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    return hen;
  }
};

const hankakuToZenkaku = (str) => {
  const hankaku = [
    'ｶﾞ',
    'ｷﾞ',
    'ｸﾞ',
    'ｹﾞ',
    'ｺﾞ',
    'ｻﾞ',
    'ｼﾞ',
    'ｽﾞ',
    'ｾﾞ',
    'ｿﾞ',
    'ﾀﾞ',
    'ﾁﾞ',
    'ﾂﾞ',
    'ﾃﾞ',
    'ﾄﾞ',
    'ﾊﾞ',
    'ﾊﾟ',
    'ﾋﾞ',
    'ﾋﾟ',
    'ﾌﾞ',
    'ﾌﾟ',
    'ﾍﾞ',
    'ﾍﾟ',
    'ﾎﾞ',
    'ﾎﾟ',
    'ｳﾞ',
    'ｧ',
    'ｱ',
    'ｨ',
    'ｲ',
    'ｩ',
    'ｳ',
    'ｪ',
    'ｴ',
    'ｫ',
    'ｵ',
    'ｶ',
    'ｷ',
    'ｸ',
    'ｹ',
    'ｺ',
    'ｻ',
    'ｼ',
    'ｽ',
    'ｾ',
    'ｿ',
    'ﾀ',
    'ﾁ',
    'ｯ',
    'ﾂ',
    'ﾃ',
    'ﾄ',
    'ﾅ',
    'ﾆ',
    'ﾇ',
    'ﾈ',
    'ﾉ',
    'ﾊ',
    'ﾋ',
    'ﾌ',
    'ﾍ',
    'ﾎ',
    'ﾏ',
    'ﾐ',
    'ﾑ',
    'ﾒ',
    'ﾓ',
    'ｬ',
    'ﾔ',
    'ｭ',
    'ﾕ',
    'ｮ',
    'ﾖ',
    'ﾗ',
    'ﾘ',
    'ﾙ',
    'ﾚ',
    'ﾛ',
    'ﾜ',
    'ｦ',
    'ﾝ',
    '｡',
    '｢',
    '｣',
    '､',
    '･',
    'ｰ',
    'ﾞ',
    'ﾟ',
  ];

  const zenkaku = [
    'ガ',
    'ギ',
    'グ',
    'ゲ',
    'ゴ',
    'ザ',
    'ジ',
    'ズ',
    'ゼ',
    'ゾ',
    'ダ',
    'ヂ',
    'ヅ',
    'デ',
    'ド',
    'バ',
    'パ',
    'ビ',
    'ピ',
    'ブ',
    'プ',
    'ベ',
    'ペ',
    'ボ',
    'ポ',
    'ヴ',
    'ァ',
    'ア',
    'ィ',
    'イ',
    'ゥ',
    'ウ',
    'ェ',
    'エ',
    'ォ',
    'オ',
    'カ',
    'キ',
    'ク',
    'ケ',
    'コ',
    'サ',
    'シ',
    'ス',
    'セ',
    'ソ',
    'タ',
    'チ',
    'ッ',
    'ツ',
    'テ',
    'ト',
    'ナ',
    'ニ',
    'ヌ',
    'ネ',
    'ノ',
    'ハ',
    'ヒ',
    'フ',
    'ヘ',
    'ホ',
    'マ',
    'ミ',
    'ム',
    'メ',
    'モ',
    'ャ',
    'ヤ',
    'ュ',
    'ユ',
    'ョ',
    'ヨ',
    'ラ',
    'リ',
    'ル',
    'レ',
    'ロ',
    'ワ',
    'ヲ',
    'ン',
    '。',
    '「',
    '」',
    '、',
    '・',
    'ー',
    '゛',
    '゜',
  ];
  // 変換開始
  for (let i = 0; i <= 88; i += 1) {
    // 89文字あるのでその分だけ繰り返す
    while (str.indexOf(hankaku[i]) >= 0) {
      // 該当する半角カナがなくなるまで繰り返す
      str = str.replace(hankaku[i], zenkaku[i]); // 半角カナに対応する全角カナに置換する
    }
  }
  return str;
};

const isHiraganaCode = (c) => {
  return (c >= 12353 && c <= 12435) || c === 12445 || c === 12446;
};

const hiraganaToKatakana = (src) => {
  if (src === null) return '';

  let str = '';
  const len = src.length;
  for (let i = 0; i < len; i += 1) {
    const c = src.charCodeAt(i);
    if (isHiraganaCode(c)) {
      str += String.fromCharCode(c + 96); // ひらがなをカタカナに変換する
    } else str += src.charAt(i);
  }

  return str;
};

const numCheckToChange = (str) => {
  const pattern = /^-?[0-9]+(\.[0-9]*)?$/;
  if (!pattern.test(str)) { // 整数or小数の形で渡ってこない場合は変換しない
    return str;
  }
  return Number(str);
};

export const convertForm1 = (str) => {
  str = zenkakuToHankaku(str);
  return str;
};

export const convertFormKana = (str) => {
  str = zenkakuToHankaku(str);
  str = hankakuToZenkaku(str);
  str = hiraganaToKatakana(str);
  return str;
};

export const convertFormNumber = (str) => {
  str = zenkakuToHankaku(str);
  str = numCheckToChange(str);
  return str;
};
