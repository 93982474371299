import { createSlice } from '@reduxjs/toolkit';

const initialState = { color: 'default' };

export const colorThemeSlice = createSlice({
  name: 'colorThemeOption',
  initialState,
  reducers: {
    getColorTheme: (state, data) => {
      state.color = data.payload;
    },
  },
});

export const { getColorTheme } = colorThemeSlice.actions;
export default colorThemeSlice.reducer;
