import CustomerShareupdateAddContainer from '../../../../containers/common/customer/shareupdateAdd/customerShareupdateContainer';

function CustomerShareupdateAdd(props) {
  const { customerId, label, functions, shareAddDisabledFlg } = props;

  return (
    <CustomerShareupdateAddContainer
      customerId={customerId}
      label={label}
      functions={functions}
      shareAddDisabledFlg={shareAddDisabledFlg}
    />
  );
}

export default CustomerShareupdateAdd;
