import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const libraryGetInterruptedSlice = createSlice({
  name: 'libraryGetInterrupted',
  initialState: { libraryGetInterrupted: initialState },
  reducers: {
    libraryGetInterruptedApi: (state, data) => {
      state.libraryGetInterrupted = data.payload;
    },
  },
});

export const { libraryGetInterruptedApi } = libraryGetInterruptedSlice.actions;

export default libraryGetInterruptedSlice.reducer;
