import { useState, useEffect } from 'react';
import lineAddNewIdApi from '../../../apis/line/lineAddNewIdApi';
import lineDeleteApi from '../../../apis/line/lineDeleteApi';
import lineGetIdApi from '../../../apis/line/lineGetIdApi';
import lineUpdateIdApi from '../../../apis/line/lineUpdateIdApi';
import ManagementLine from '../../../components/common/managementLine/managementLine';

function ManagementLineContainers(props) {
  const { open, onClose, customerId } = props;

  // GET
  const [rooms, setRooms] = useState({});
  const getLineRooms = () => {
    lineGetIdApi(customerId).then(res => setRooms(res.data))
      .catch(err => console.error(err));
  };

  // add - POST
  const addNewLineRooms = (param) => {
    lineAddNewIdApi(param).then(() => getLineRooms(customerId))
      .catch((err) => console.error(err));
  };

  // edit - PUT
  const updateLineRoome = (lineRoomLinkId, param) => {
    lineUpdateIdApi(lineRoomLinkId, param)
      .then(() => getLineRooms(customerId))
      .catch((err) => console.error(err));
  };

  // delete
  const deleteLineRooms = (roomId) => {
    lineDeleteApi(roomId)
      .then(() => getLineRooms(customerId))
      .catch((err) => console.error(err));
  };

  const functions = {
    addNewLineRooms,
    deleteLineRooms,
    updateLineRoome,
  };

  useEffect(() => {
    if (open && customerId) getLineRooms();
    return () => {
      setRooms([]);
    };
  }, [open, customerId]);

  return (
    <ManagementLine
      open={open}
      onClose={onClose}
      customerId={customerId}
      rooms={rooms}
      setRooms={setRooms}
      functions={functions}
    />
  );
}

export default ManagementLineContainers;
