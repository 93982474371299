import { useState, useEffect } from 'react';
import { Grid, Button, makeStyles, IconButton } from '@material-ui/core';

import ReplayIcon from '@material-ui/icons/Replay';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '18px',
    paddingBottom: '10.5px',
    paddingLeft: '16px',
  },
  blue: {
    color: theme.palette.primaryColor,
    fontWeight: '700',
  },
  page: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    opacity: '0.6',
    // minWidth: '184px',
    textAlign: 'left',
  },
  buttons: {
    padding: '0px 8px',
    '& .MuiIconButton-root': {
      color: theme.palette.primaryColor,
      padding: 3,
      '&.Mui-disabled': {
        color: '#8C8C8C',
      },
    },
  },
  first: {
    transform: 'rotate(180deg)',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
  last: {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
}));

/**
 * @param {{
 *  getApi: () => void;
 *  count: number;
 *  limit?: number;
 * }} props
 * @returns
 */
function PageNation(props) {
  const {
    onClickReload,
    getApi,
    count,
    limit = 25,
    hideReloadButton = false,
    offset = 0,
  } = props;
  const classes = useStyles();

  const [offsetStart, setOffsetStart] = useState(0);
  const offsetEnd = Math.min(offsetStart + limit, count);
  const maxPageNum = count % limit === 0 ? count / limit : Math.floor(count / limit) + 1;
  const nowPageNum = Math.floor(offsetStart / limit) + 1;
  const label = `${count !== 0 ? offsetStart + 1 : 0}-${offsetEnd}/${count}`;

  // reset
  useEffect(() => {
    setOffsetStart(offset);
  }, [count]);

  const clickReload = () => (
    onClickReload ? onClickReload() : getApi({ offset: offsetStart, limit })
  );
  const clickMoveButton = (type) => {
    let nextOffset = 0;
    switch (type) {
      case 'first':
        nextOffset = 0;
        break;
      case 'previous':
        nextOffset = offsetStart - limit;
        break;
      case 'next':
        nextOffset = offsetStart + limit;
        break;
      case 'last':
        nextOffset = (maxPageNum - 1) * limit;
        break;
      default:
    }
    setOffsetStart(nextOffset);
    getApi({ offset: nextOffset, limit });
  };

  return (
    <>
      {hideReloadButton
        ? null
        : (<Button className={classes.blue} onClick={clickReload}><ReplayIcon fontSize="small" />更新</Button>)}
      <Grid className={classes.page}>{label}</Grid>
      <Grid className={classes.buttons}>
        <IconButton
          onClick={() => clickMoveButton('first')}
          disabled={offsetStart === 0}
          className={classes.first}
        >
          <DoubleArrowIcon />
        </IconButton>
        <IconButton
          onClick={() => clickMoveButton('previous')}
          disabled={offsetStart === 0}
          className={classes.previous}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          onClick={() => clickMoveButton('next')}
          disabled={nowPageNum === maxPageNum || count === 0}
          className={classes.next}
        >
          <NavigateNextIcon />
        </IconButton>
        <IconButton
          onClick={() => clickMoveButton('last')}
          disabled={nowPageNum === maxPageNum || count === 0}
          className={classes.last}
        >
          <DoubleArrowIcon />
        </IconButton>
      </Grid>
    </>
  );
}

export default PageNation;
