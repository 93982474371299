import { useDispatch } from 'react-redux';
import InstructionWindow from '../../components/instructionList/instructionWindow/instructionWindow';
import directGetDetailApi from '../../apis/direct/directGetDetailApi';
import directTupdateApi from '../../apis/direct/directTupdateApi';
import { setDirectDetail } from '../../store/direct/directGetDetailSlice';

function InstructionWindowContainers(props) {
  const {
    open,
    setOpen,
    onClose,
    // customerId, この値を元にデータを取得する
    tab,
    row,
    resetRender,
    setResetRender,
  } = props;

  const dispatch = useDispatch();

  // 指示情報取得 【direct002】
  /**
   * @param {integer} directId
   */
  const getDetailDirect = async (directId) => {
    await directGetDetailApi(directId)
      .then((res) => {
        dispatch(setDirectDetail(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // タイムライン登録 【direct005】
  const createTimeline = async (directId, params) => {
    await directTupdateApi(directId, params)
      .then((res) => {
        console.log(res);
        setResetRender(resetRender + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <InstructionWindow
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      tab={tab}
      row={row}
      getDetailDirect={getDetailDirect}
      createTimeline={createTimeline}
    />
  );
}

export default InstructionWindowContainers;
