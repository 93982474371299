import React from 'react';
import DivisionTextField from './parts/divisionTextField';
import CustomSelect from './parts/customSelect';
import DivisionMultipleDate from './parts/divisionMultiDate';
import Division from './division';
import Place from './place';
import Contact from './contact';
import Display from './display';
import Reverberation from './reverberation';

function DivisionInfoComponent(props) {
  const { divisionDetail, setDivisionDetail, errorCallback, isEdit } = props;

  const getComponent = (data) => {
    switch (data.type) {
      case 'str':
      case 'number':
        return (<DivisionTextField initial={data} errorCallback={errorCallback} />);
      case 'select':
        return <CustomSelect initial={data} />;
      case 'multiple':
        return <DivisionMultipleDate dateMultiple={data} />;
      default:
        return <div>対応しない要素です</div>;
    }
  };

  return (
    <>
      <Division
        division={divisionDetail}
        setDivision={setDivisionDetail}
        getComponent={getComponent}
      />
      <Place
        division={divisionDetail}
        setDivision={setDivisionDetail}
        getComponent={getComponent}
      />
      <Contact
        division={divisionDetail}
        setDivision={setDivisionDetail}
        getComponent={getComponent}
      />
      <Display
        division={divisionDetail}
        setDivision={setDivisionDetail}
        getComponent={getComponent}
        isEdit={isEdit}
      />
      <Reverberation
        division={divisionDetail}
        setDivision={setDivisionDetail}
        getComponent={getComponent}
      />
    </>
  );
}

export default DivisionInfoComponent;
