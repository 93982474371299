import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ResultTable from './parts/resultTable';

import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  root: {
    padding: '16px 0',
    width: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      padding: '0',
      '& > hr': {
        display: 'none',
      },
    },
  },
  tableContainer: {
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
    },
  },
  tableHead: {
    '& > tr': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
        background: '#F3F3F3',
      },
    },
    '& > tr > th': {
      [commonTheme.breakpoints.down('sm')]: {
        borderBottom: 'none',
        fontWeight: 'bold',
        fontSize: '13px',
        padding: '16px 0 16px 16px',
      },
      [commonTheme.breakpoints.up('md')]: {
        padding: '8px',
        borderBottom: '2px solid #C8C8C8',
        /*  OH-DarkerGray  */
        color: '#8C8C8C',
        '&:not(:last-child)': {
          width: 'calc(57% / 3)',
        },
      },
    },
  },
});

export default function SearchResult(props) {
  const { resultList, onClickTr, onClose } = props;
  const classes = useStyles();
  const common = commonStyles();
  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let table;

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  // テストとして仮データを検索結果にpush
  // const addArea = [
  //   {
  //     city: '世田谷区',
  //     cityKana: 'セタガヤク',
  //     localGovernmentCode: 103,
  //     multiZipCode1: 1,
  //     multiZipCode2: 0,
  //     multiZipCode3: 1,
  //     multiZipCode4: 2,
  //     multiZipCode5: 1,
  //     multiZipCode6: 6,
  //     oldZip: 11345,
  //     prefecture: '東京都',
  //     prefectureKana: 'トウキョウト',
  //     town: '大橋',
  //     townKana: 'オオハシ',
  //     zip: 1134567,
  //   },
  //   {
  //     localGovernmentCode: 1234,
  //     oldZip: 123456,
  //     zip: 12345670,
  //     prefectureKana: 'トウキョウト',
  //     cityKana: 'スギナミク',
  //     townKana: 'アサガヤ',
  //     prefecture: '東京都',
  //     city: '杉並区',
  //     town: '阿佐ヶ谷',
  //     multiZipCode1: 3,
  //     multiZipCode2: 3,
  //     multiZipCode3: 3,
  //     multiZipCode4: 3,
  //     multiZipCode5: 3,
  //     multiZipCode6: 3,
  //   },
  //   {
  //     localGovernmentCode: 12,
  //     oldZip: 1234,
  //     zip: 123457,
  //     prefectureKana: 'カナガワケン',
  //     cityKana: 'ヨコハマシツヅキク',
  //     townKana: 'ナカガワ',
  //     prefecture: '神奈川県',
  //     city: '横浜市都筑区',
  //     town: '中川',
  //     multiZipCode1: 1,
  //     multiZipCode2: 2,
  //     multiZipCode3: 3,
  //     multiZipCode4: 4,
  //     multiZipCode5: 5,
  //     multiZipCode6: 6,
  //   },
  // ];
  // const reArr = resultList.concat(addArea);
  const reArr = resultList;
  // 地域区分でグルーピング
  const resultListGroupByCity = groupBy(reArr, 'city');

  const ObjectsOfResultListGroupByCity = Object.keys(resultListGroupByCity)
    .map((k) => { return { key: k, value: resultListGroupByCity[k] }; });

  if (isSP) {
    table = (
      ObjectsOfResultListGroupByCity.map((data) => (
        <Table key={data.key}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>{data.value[0].prefecture}</TableCell>
              <TableCell>{data.key}</TableCell>
            </TableRow>
          </TableHead>
          <ResultTable
            resultList={data.value}
            onClickTr={onClickTr}
            onClose={onClose}
          />
        </Table>
      ))
    );
  } else {
    table = (
      <Table>
        <TableHead className={`${classes.tableHead} ${common.onlyPc}`}>
          <TableRow>
            <TableCell>郵便番号</TableCell>
            <TableCell>都道府県</TableCell>
            <TableCell>市区町村</TableCell>
            <TableCell>以下住所</TableCell>
          </TableRow>
        </TableHead>
        <ResultTable
          resultList={resultList}
          onClickTr={onClickTr}
          onClose={onClose}
        />
      </Table>
    );
  }

  return (
    <Grid className={classes.root}>
      <hr width="100%" />
      <TableContainer className={classes.tableContainer}>
        {table}
      </TableContainer>
    </Grid>
  );
}
