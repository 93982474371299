/* eslint-disable import/prefer-default-export */
import moment from 'moment';

/**
 * 現在の日時を取得
 * @param {string} format - YMDHms..
 * @returns {string}
 */
export const getNowDate = (format = 'YYYY/MM/DD HH:mm:ss') => {
  return moment().format(format);
};

export const getAge = (birthday, targetDate = new Date()) => {
  const birthdayDate = new Date(birthday);
  const thisYearsBirthday = new Date(
    targetDate.getFullYear(),
    birthdayDate.getMonth() + 1,
    birthdayDate.getDate(),
  );
  let ageCount = targetDate.getFullYear() - birthdayDate.getFullYear();
  if (targetDate < thisYearsBirthday) {
    ageCount -= 1;
  }
  return ageCount;
};
