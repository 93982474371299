import client from '../httpClient';

/**
 * @typedef {
 *   import('./divisionMasterGetTransferUserListApiTypes').UserListGetResponse
 * } UserListGetResponse
 * @typedef {{ areaCode: number; offset: number; limit: number; isCommitted: 0 | 1; }} Query
 * @type { (query: Query) => Promise<UserListGetResponse> }
 */
const getUserListApi = async (params) => {
  const result = await client.get('/division-masters/user-list', { params, isShowLoading: true });
  return result;
};

export default getUserListApi;
