import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import CustomEditDialog from '../../../eleCommon/sp/customEditDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '10px 8px 9px',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
    },
  },
  ttl: {
    width: 120,
    marginRight: 8,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  inputSp: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  inputPc: {
    width: '100%',
    marginLeft: -80,
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  inputPc2: {
    width: '100%',
    marginLeft: -60,
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
    [commonTheme.breakpoints.up('md')]: {
      minHeight: 20,
      width: 428,
      marginLeft: 16,
      padding: 0,
      '& .MuiButton-label': {
        display: 'block',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
      borderBottom: '1px solid #F3F3F3',
      borderRadius: 0,
      width: '100%',
      padding: '16px',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
}));

function ClaimRegisterText(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(values.state);
  const handleChange = (e) => {
    setText(e.target.value);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleSetValues = () => {
    switch (values.label) {
      case '原因':
        console.log('原因');
        break;
      case '対処方法':
        console.log('対処方法');
        break;
      case '対処結果':
        console.log('対処結果');
        break;
      default:
        break;
    }
    values.setState(text);
  };
  if (isSp) {
    return (
      <Grid className={classes.root}>
        <Button className={classes.btn} onClick={handleClick}>
          <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
            {values.label}
            {values.required && <span>＊</span>}
          </Typography>
          <Typography className={`${baseClasses.title6}`}>{values.state === '' ? '-' : values.state}</Typography>
        </Button>
        <CustomEditDialog
          open={open}
          setOpen={setOpen}
          content={
            <TextField
              autoFocus
              multiline
              className={classes.inputSp}
              onChange={(e) => { return setText(e.target.value); }}
              name={values.label}
              value={text}
            />
          }
          set={handleSetValues}
          lbl={values.label}
        />
      </Grid>
    );
  } else {
    return (
      <Grid className={classes.root}>
        <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <TextField
          className={values.label === '原因' ? classes.inputPc : classes.inputPc2}
          onChange={handleChange}
          onBlur={(e) => { return values.setState(e.target.value); }}
          name={values.label}
          value={text}
        />
      </Grid>
    );
  }
}

export default ClaimRegisterText;
