import { Checkbox, makeStyles, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  cell: {
    padding: '4px 8px',
    position: 'relative',
    '&:not(:last-child)::after': {
      content: '""',
      display: 'inline-block',
      width: '1px',
      height: 'calc(100% - 8px)',
      background: '#C8C8C8',
      position: 'absolute',
      right: 0,
      top: '4px',
    },
  },
  row: { padding: '4px 0' },
  checkbox: { padding: 0 },
  ttl: { color: '#8C8C8C' },
  checkcolumn: { width: '24px' },
});
export default function FileTableHeader(props) {
  const { cells, rowCount, numSelected, onSelectAllClick, searchCell } = props;
  const classes = useStyles();
  const common = commonStyles();
  return (
    <TableHead>
      <TableRow className={classes.row}>
        <TableCell className={`${classes.cell} ${classes.checkcolumn}`}>
          <Checkbox
            color="primary"
            className={classes.checkbox}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {cells.map(c => (
          <TableCell
            className={classes.cell}
            style={c.style}
          >
            {c.id === 'directoryName' ? searchCell() : (
              <Typography className={`${classes.ttl} ${common.title4}`}>
                {c.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
