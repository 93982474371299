import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { crmPageSelector } from '../../store/crmPage';
import webResListByDivisionIdPostApi from '../../apis/webRes/webResListByDivisionIdPostApi';
import FlyerSalesList from '../../components/flyerSalesList/flyerSalesList';

import { CRM_FLYER_SALES } from '../../constants/loading';
import { useLoading } from '../../hooks';

let throttleTimerId = 0;

/**
 * 組織ツリーをクリックすると、state(selectUserとselectDivision)更新が連続で発生してfetchが複数回動くため、1回だけ動くようにする。
 * 対処法
 * - react18からはAutomatic Batchingが有効になるのでアップデートする
 * - react17以前であれば、非同期関数内のsetStateをunstable_batchedupdatesで囲む
 */
function throttle(callback) {
  clearTimeout(throttleTimerId);
  throttleTimerId = window.setTimeout(callback, 100);
}

/**
 * 子供のdivisionを取得する
 * @param {Array<{id, type, name, parentId}>} children
 * @param {*} arr
 */
function getChildrenDivisions(children, arr) {
  if (children?.length && children.some((item) => item.type === 'division')) {
    children.forEach((item) => {
      // divisionではない、または
      // arrに既に存在するdivisionはarr追加しない
      if (item.type !== 'division' || arr.some((a) => a.divisionId === item.id)) return;
      arr.push({ divisionId: item.id });
      getChildrenDivisions(item.children, arr);
    });
  }
}

function FlyerSalesListContainer() {
  // /responses/division
  // 【webRes010】未処理反響一覧取得（組織ID/社員所属指定）
  const [responseData, setResponseData] = useState({
    total: 0,
    responses: [],
  });
  // 呼び出し元: src/components/pages/joinData/joinData.js
  const { isReady, forceReload } = useSelector(crmPageSelector);

  const { removeLoading } = useLoading();

  /**
   * 直前のリクエストパラメーターのoffset, limitを記録
   */
  const prevRequestCountRef = useRef({ offset: 0, limit: 25 });

  // 反響ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  // 反響ツリー選択組織
  const [selectDivision, setSelectDivision] = useState({
    divId: 0,
    divisionName: '',
  });

  /**
   * @param {{
   *   offset: number;
   *   limit: number;
   * } | undefined} params デフォルト値として直前のリクエストの値を再利用する
   */
  const getResponses = async (params = prevRequestCountRef.current) => {
    if (!selectDivision.divId) return;

    const users = (() => {
      if (!selectUser.userId) {
        const divisions = [{ divisionId: selectDivision.divId }];
        // 子供のdivisionをリクエストのパラメーターにする
        // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2818#comment-168840338
        getChildrenDivisions(selectDivision.children, divisions);
        return divisions;
      }
      return [
        {
          userId: selectUser.userId,
          divisionId: selectDivision.divId,
        },
      ];
    })();

    prevRequestCountRef.current = params;
    const data = { ...params, users };
    const response = await webResListByDivisionIdPostApi(data, CRM_FLYER_SALES)
      .catch((err) => console.log(err))
      .finally(() => removeLoading(CRM_FLYER_SALES));
    // eslint-disable-next-line no-useless-return
    if (!response) return;

    // 表示用の番号を採番する。responseIdは使わない。
    response.data.responses = response.data.responses.map((value, index) => {
      return {
        ...value,
        index: prevRequestCountRef.current.offset + index + 1,
      };
    });
    setResponseData(response.data);

    const wrapper = document.getElementById('flyerSalesList');
    wrapper.scrollTo(0, 0);
  };

  useEffect(() => {
    throttle(getResponses);
    // eslint-disable-next-line
  }, [selectUser, selectDivision]);

  // 呼び出し元: src/components/pages/joinData/joinData.js
  useEffect(() => {
    if (!isReady) {
      return;
    }
    throttle(getResponses);
  }, [forceReload.flyerSalesList]);

  return (
    <FlyerSalesList
      // responseTypeList={list}
      responsesObj={responseData}
      getResponses={getResponses}
      setSelectUser={setSelectUser}
      setSelectDivision={setSelectDivision}
    />
  );
}

export default FlyerSalesListContainer;
