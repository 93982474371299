import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const kimarisTargetSettingsUpdateSlice = createSlice({
  name: 'kimarisTargetSettingsUpdate',
  initialState: { kimarisTargetSettingsUpdate: initialState },
  reducers: {
    kimarisTargetSettingsUpdateApi: (state, data) => {
      state.kimarisTargetSettingsUpdate = data.payload;
    },
  },
});

export const { kimarisTargetSettingsUpdateApi } = kimarisTargetSettingsUpdateSlice.actions;

export default kimarisTargetSettingsUpdateSlice.reducer;
