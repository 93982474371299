import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    bukkenArea: 1,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 2,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 3,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 4,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 7,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 9,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 10,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 11,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 12,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 13,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 14,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 15,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 16,
    count: 0,
    isLimited: false,
  },
  {
    bukkenArea: 17,
    count: 0,
    isLimited: false,
  },
];

export const sendmailGetCountsSlice = createSlice({
  name: 'sendmailGetCounts',
  initialState: { sendmailGetCounts: initialState },
  reducers: {
    getSendmailGetCountsApi: (state, data) => {
      state.sendmailGetCounts = data.payload;
    },
    initSendmailCounts: (state) => {
      state.sendmailGetCounts = initialState;
    },
  },
});

export const { getSendmailGetCountsApi, initSendmailCounts } = sendmailGetCountsSlice.actions;

export default sendmailGetCountsSlice.reducer;
