import { useRef, useCallback, useMemo } from 'react';
import { StoreGroupsContextProvider } from './context';
import Dialog from '../../common/modal';
import Content from './content';

export default function StoreGroupsDialog(props) {
  const { open, onClose, onSubmit, currentArea } = props;

  const selectedRef = useRef();

  const onChange = useCallback((data) => {
    selectedRef.current = data;
  }, []);

  const onClick = useCallback(() => {
    onSubmit?.(selectedRef.current);
    onClose?.();
  }, []);

  const onCancel = () => {
    selectedRef.current = [];
    onSubmit?.([]);
  };

  const buttons = useMemo(() => [
    {
      label: '選択',
      onClick,
    },
    {
      label: 'キャンセル',
      type: 'secondary',
      onClick: onClose,
    },
  ], []);

  return (
    // eslint-disable-next-line max-len
    <StoreGroupsContextProvider {...props} onChange={onChange} onCancel={onCancel} currentArea={currentArea}>
      <Dialog
        title="店舗選択画面"
        open={open}
        onClose={onClose}
        buttons={buttons}
        minHeight="70vh"
        maxWidth="640px"
      >
        <Content />
      </Dialog>
    </StoreGroupsContextProvider>
  );
}
