import client from '../httpClient';

/**
 * @type {(csvFile: File, query: {
 * isCommitted: 0 | 1; applyingAt?: string; areaCode?: number
 * }) => Promise<void>}
 */
const postUploadCsvApi = async (csvFile, query) => {
  const formData = new FormData();
  // isCommittedが1の場合、ファイルをアップロード必要がない、
  // isCommittedが0の際既にアップロード済み、
  // かつ1の場合、バックエンド側にはファイルを処理していない
  if (csvFile) formData.append('csvFile', csvFile);
  formData.append('isCommitted', query.isCommitted);

  // エリアコード
  if (query.areaCode) formData.append('areaCode', query.areaCode);
  // 適用日
  if (query.applyingAt) formData.append('applyingAt', query.applyingAt);

  await client.post('/division-masters/user-csv-upload/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default postUploadCsvApi;
