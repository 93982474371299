import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const actPutHistorySlice = createSlice({
  name: 'actPutHistory',
  initialState: { actPutHistory: initialState },
  reducers: {
    actPutHistory: (state, data) => {
      state.actPutHistory = data.payload;
    },
  },
});

export const { actPutHistory } = actPutHistorySlice.actions;

export default actPutHistorySlice.reducer;
