import client from '../httpClient';

/**
 * 【hpinfo002】WEB会員ステータス変更
 * http://10.22.2.48:8080/docs/#/members/put-members-member-id
 * @param {number} memberId
 * @param {{
 * memberStatusCode: number;
 * isQuit: number;
 * }} data いずれか1つのパラメータを指定
 * @returns {Promise<T>}
 */
const membersPutChangeStatusCodeApi = (memberId, data) => {
  return client.put(`/members/${memberId}`, JSON.stringify(data), { isShowMessage: true });
};

export default membersPutChangeStatusCodeApi;
