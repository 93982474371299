/* eslint-disable */
import React from 'react';
import CustomerInfoContainer from '../../../../../containers/common/customer/customerInfo/customerInfoContainer';

/**
 * SP用 顧客詳細ページのモーダルコンポーネント
 * 引数として下記必須項目および、★のいずれかひとつを必ず引数にいれること
 * @module CustomerInfoSp
 * @param {int} props.customerId - ★: (int) customerId
 * @param {obj} customerObj - ★:(obj) customerの情報オブジェクト
 * @param {boolean} props.open - 必須: (Boolean) 開閉状態true/false
 * @param {function} props.setOpen - 必須：(setState)props.openのsetState関数
 * @param {function} props.onClose - 必須：(function) props.openのトグル関数(true→false / false→true)
 * @returns {jsx} - jsx
 *
 * 注意点：customerIdは親コンポーネント側で指定して渡すようにしてください
 */

function CustomerInfoSp(props) {
  const {
    customerId, // ★のどちらか必須
    open,
    setOpen,
    onClose,
    customerObj, // ★のどちらか必須
    fullScreen = false,
  } = props;
  return (
    <CustomerInfoContainer
      cstmrId={customerId}
      customerObj={customerObj}
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      fullScreen={fullScreen}
    />
  );
}

export default CustomerInfoSp;
