/* eslint-disable import/prefer-default-export */
import store from '../store';

import { MAIL_SEND_PROXY, INFO_USE } from '../constants/userRole';

import {
  MAIL_FROM_TEAM_MEMBER,
  MAIL_FROM_INFO,
  MAIL_FROM_CODE,
} from '../constants';

export const checkMailVaildation = (email) => {
  const reg = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  return Boolean(email.match(reg));
};

export const getFromChoice = (roleIds = []) => {
  if (roleIds.length === 0) {
    const { responseHeader } = store.getState;
    roleIds = responseHeader.roleIds;
  }
  const choices = MAIL_FROM_CODE;
  if (!roleIds.includes(MAIL_SEND_PROXY)) delete choices[MAIL_FROM_TEAM_MEMBER];
  if (!roleIds.includes(INFO_USE)) delete choices[MAIL_FROM_INFO];
  return choices;
};

// タグがない場合に補完（エディタに入れられないため）
export const transMailBody = (txt) => {
  let content = txt;
  const regex = /(<([^>]+)>)/gi;
  const found = content?.match(regex);
  // エディターへ選択したライブラリの情報を渡す
  if (!found) {
    content = `<p>${content}</p>`;
  }
  return content;
};
