import { TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ResultRow from './reslultRow';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles({
  resultRow: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: '16px',
    },
    '& > tr(even) > td': {
      /* OH-liteGray */
      background: '#F3F3F3',
    },
  },
});

export default function ResultTable(props) {
  const { resultList, onClickTr, onClose } = props;

  const classes = useStyles();
  return (
    <TableBody className={classes.resultRow}>
      {resultList.map((resultRow, index) => (
        <ResultRow
          key={`${resultRow.zip}${resultRow.town}_${index.toString()}`}
          row={resultRow}
          onClickTr={onClickTr}
          onClose={onClose}
        />
      ))}
    </TableBody>
  );
}
