import TaiouRirekiWrapper from '../../../components/common/taiouRireki/taiouRireki';

function TaiouRirekiContainers(props) {
  const {
    setOpenAlert,
    customerId,
    customer,
    getActionHistoryList,
    createHistory,
    updateHistory,
    deleteHistory,
    downloadAttachment,
    search,
    setSearch,
    actionHistory,
    customerMail,
    refreshing,
    onRefresh,
    rerenderKeySearch,
    rerenderSearch,
  } = props;

  return (
    <TaiouRirekiWrapper
      setOpenAlert={setOpenAlert}
      getActionHistoryList={getActionHistoryList} // 取得・更新・検索
      createHistory={createHistory}
      updateHistory={updateHistory}
      deleteHistory={deleteHistory}
      downloadAttachment={downloadAttachment}
      search={search}
      setSearch={setSearch}
      customerId={customerId}
      customer={customer}
      actionHistory={actionHistory}
      customerMail={customerMail}
      refreshing={refreshing}
      onRefresh={onRefresh}
      rerenderKeySearch={rerenderKeySearch}
      rerenderSearch={rerenderSearch}
    />
  );
}

export default TaiouRirekiContainers;
