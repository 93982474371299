/**
 * import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
 * dispatch(changeConfirmMessage({ ... });
 * エラーが発生するため、仮のモダールを作成する
 * A non-serializable value was detected in the state, in the path:
 * `confirmMessage.confirmStatus.buttons.1.set`. Value: ...`
 * (See https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state)
 */
import React, { useMemo } from 'react';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from './';

const useStyles = makeStyles(() => ({
  txt: {
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'center',
  },
}));

/**
 * ConfirmModal
 * @param {{
 * open: boolean;
 * onCancel?: (e) => void; // 存在しない場合、キャンセルボタンが表示しない
 * onSubmit: (e) => void;
 * onClose?: () => void; // 存在しない場合、モダール以外をクリックしても閉じない
 * title: string;
 * content: string | string[];
 * children?: ReactNode;
 * }} props
 * @returns {ReactNode}
 */
function ConfirmModal(props) {
  const {
    open,
    onCancel,
    onSubmit,
    onClose,
    title,
    content,
    children,
  } = props;

  const classes = useStyles();

  const buttons = useMemo(() => {
    const arr = [];
    if (onCancel) {
      arr.push({
        label: 'CANCEL',
        type: 'secondary',
        onClick: onCancel,
      });
    }
    arr.push({
      label: 'OK',
      onClick: onSubmit,
    });
    return arr;
  }, [onCancel, onSubmit]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      maxWidth="600px"
      title={title}
      buttons={buttons}
      white
      titleCenter
    >
      {!content ? null : (
        <Grid className={classes.txt}>
          {Array.isArray(content) ? content.map((message, i) => (
            <Typography key={String(i)}>{message}</Typography>
          )) : <Typography>{content}</Typography>}
        </Grid>
      )}
      {children}
    </Dialog>
  );
}

export default ConfirmModal;
