import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../styles';
import CommonDialog from '../../common/modal';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles({
  wrap: {
    alignItems: 'center',
    background: '#fff',
    border: '1px #c8c8c8 solid',
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 8px',
    '&:not(:last-child)': { marginBottom: 8 },
  },
  ttl: {
    marginRight: 16,
    '& span': { color: '#D83420' },
  },
  disable: {
    background: 'rgba(0, 0, 0, 0.16)',
    color: '#8c8c8c',
    pointerEvents: 'none',
  },
  select: {
    width: 160,
    '&::before, &::after': { display: 'none' },
  },
});
export default function ChangeAimWindow(props) {
  const { open, onClose, allChangeFunction } = props;
  const dispatch = useDispatch();
  const common = commonStyles();
  const classes = useStyles();
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const [date, setDate] = useState({
    year: Number(todayYear),
    month: Number(todayMonth),
  });

  // 一括更新実行
  const clickRegister = () => {
    allChangeFunction(date);
    setDate({});
    onClose();
  };

  // Cancel
  const cancelAllUpdate = () => {
    console.log('cancel');
  };

  // 一括更新確認画面
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '目標一括変更',
      msgList: [`目標一括変更を実行しますか？ 対象年月: ${date.year}年 ${date.month}月`],
      buttons: [
        {
          label: 'CANCEL',
          set: cancelAllUpdate,
          classes: common.buttonsSecondary,
        },
        {
          label: 'OK',
          set: clickRegister,
          classes: common.buttonsPrimary,
        },
      ],
    }));
  };

  // 年月のChangeEvent
  const onChange = (type, e) => {
    if (type === 'year' && e === todayYear && date.month < todayMonth) {
      setDate({ year: Number(e), month: Number(todayMonth) });
    } else {
      setDate({ ...date, [type]: Number(e) });
    }
  };

  // 年の選択肢
  const menuItemYear = () => {
    const result = [];
    for (let i = 0; i < 10; i += 1) {
      result.push(<MenuItem value={todayYear + i} key={`key${i}`}>{todayYear + i}</MenuItem>);
    }
    return result;
  };

  // 月の選択肢
  const menuItemMonth = () => {
    const result = [];
    for (let i = 1; i < 13; i += 1) {
      result.push(
        <MenuItem
          value={i}
          key={`keyM${i}`}
          className={`${todayYear === date.year && i < todayMonth ? classes.disable : ''}`}
        >
          {i}
        </MenuItem>,
      );
    }
    return result;
  };

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="目標一括登録"
      width={640}
      buttons={[
        {
          label: 'キャンセル',
          onClick: onClose,
          type: 'secondary',
        },
        {
          label: '実行',
          onClick: confirmOpen,
        },
      ]}
    >
      <>
        <Grid className={classes.wrap}>
          <Typography className={`${common.title6} ${classes.ttl}`}>年<span>＊</span></Typography>
          <Select
            className={classes.select}
            value={date.year}
            onChange={(e) => { onChange('year', e.target.value); }}
          >
            {menuItemYear()}
          </Select>
        </Grid>
        <Grid className={classes.wrap}>
          <Typography className={`${common.title6} ${classes.ttl}`}>月<span>＊</span></Typography>
          <Select
            className={classes.select}
            value={date.month}
            onChange={(e) => { onChange('month', e.target.value); }}
          >
            {menuItemMonth()}
          </Select>
        </Grid>
      </>
    </CommonDialog>
  );
}
