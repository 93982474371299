import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import UserTree from '../../userTreeNew';
import { useStore } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '10px 8px 9px',
    },
  },
  ttl: {
    width: 120,
    marginRight: 8,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
    [commonTheme.breakpoints.up('md')]: {
      minHeight: 20,
      width: 428,
      marginLeft: 16,
      padding: 0,
      '& .MuiButton-label': {
        display: 'block',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
      borderBottom: '1px solid #F3F3F3',
      borderRadius: 0,
      width: '100%',
      padding: '16px',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
}));

function ClaimRegisterChoice(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // モーダル用
  const [open, setOpen] = useState(false);
  const [selectUser, setSelectUser] = useState({ userId: values.userId });
  const [selectDivision, setSelectDivision] = useState([]);

  const { responseHeader } = useStore();

  const handleClick = () => {
    setOpen(!open);
  };

  // 初期化処理: デフォルトの処理では選択済みユーザーの解除ができなかった為追加
  const handleClearUser = () => {
    setSelectUser({ userId: '', users: '' });
    values.setState({ userId: '', userName: '' });
  };

  const handleSelectUser = () => {
    values.setState({ userId: selectUser.userId, userName: responseHeader.userName });
  };

  useEffect(() => {
    if (!open && selectUser.userName) {
      values.setState(selectUser);
    }
  }, [open, selectUser]);

  return (
    <Grid className={classes.root}>
      {isSp ? (
        <Button className={classes.btn} onClick={handleClick}>
          <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
            {values.label}
            {values.required && <span>＊</span>}
          </Typography>
          <Typography className={`${baseClasses.title6}`}>
            {values.users ? values.users : ''}
          </Typography>
        </Button>
      ) : (
        <>
          <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
            {values.label}
            {values.required && <span>＊</span>}
          </Typography>
          <Button
            className={classes.btn}
            onClick={handleClick}
          >
            {values.users ? values.users : ''}
          </Button>
        </>
      )}
      <UserTree
        isOpenTree={open}
        setIsOpenTree={setOpen}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        handleClearFunction={handleClearUser}
        handleSelectFunction={isSp && handleSelectUser}
        // isSelectUserWindow
        isDialog
      />
    </Grid>
  );
}

export default ClaimRegisterChoice;
