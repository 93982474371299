import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const librarydirUpdateSlice = createSlice({
  name: 'librarydirUpdate',
  initialState: { librarydirUpdate: initialState },
  reducers: {
    librarydirUpdateApi: (state, data) => {
      state.librarydirUpdate = data.payload;
    },
  },
});

export const { librarydirUpdateApi } = librarydirUpdateSlice.actions;

export default librarydirUpdateSlice.reducer;
