import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';

const localStyles = makeStyles((theme) => ({
  label: {
    position: 'relative',
    minWidth: '120px',
    width: '30%',
    minHeight: '21px',
    lineHeight: '21px',
    display: 'inline-block',
    textAlign: 'center',
    // borderBottom: '1px #8C8C8C solid',
    [commonTheme.breakpoints.down('sm')]: {
      '&:focus-within': {
        background: 'transparent',
        color: theme.palette.primaryColor,
        // borderBottom: `2px solid ${theme.palette.primaryColor}`,
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      '&:hover': {
        background: `${theme.palette.primaryColor}29`,
      },
    },
  },
  txt: {
    padding: '8px',
    width: '100%',
  },
  input: {
    height: '100%',
    opacity: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
    // update: 2023/04/24 カレンダークリック時の領域
    border: 'none',
    '&::-webkit-calendar-picker-indicator': {
      height: '100%',
      margin: 0,
      // position: 'relative',
      width: '100%',
      cursor: 'pointer',
      // update: 2023/04/24 カレンダークリック時の領域
      position: 'absolute',
    },
  },
  dateContainer: {
    width: '80%',
    margin: 'auto',
  },
  placeholderTxt: {
    color: '#c8c8c8',
  },
}));

export default localStyles;
