export const operation = {
  0: '全件検索',
  1: '本日未送信者のみ選択',
  2: '本日の送信が3通未満のみ選択',
  3: '週3通未満のみ選択',
  4: '週1通未満のみ選択',
};

export const display = {
  0: 'すべて表示',
  1: '一斉のみ表示',
  2: '個別のみ表示',
};
