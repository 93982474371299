import SimulatorContainers from '../../containers/simulator/simulatorContainers';

function Simulator() {
  return (
    <div>
      <SimulatorContainers />
    </div>
  );
}

export default Simulator;
