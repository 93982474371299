import { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import UserTree from '../../userTreeNew';
import Calendar from '../../calendar';
import CommonDialog from '../../modal';
import store from '../../../../store';

const useStyles = makeStyles((theme) => ({
  inner: {
    height: 405,
    '& > div': {
      width: '100%',
      maxWidth: 'initial',
      border: '1px solid #c8c8c8',
    },
  },
  date: {
    backgroundColor: '#fff',
    border: '1px #c8c8c8 solid',
    marginTop: '8px',
    width: '100%',
    padding: '8px',
    alignSelf: 'flex-start',
    '& > .MuiGrid-container': {
      border: 'none',
      flexWrap: 'nowrap',
      alignItems: 'center',
      '& h6:empty': {
        display: 'none',
      },
    },
    '& label': {
      width: '100%',
    },
  },
  blue: {
    ...theme.overrides.font.weightBold,
    ...theme.overrides.font.sizeSmall,
    color: theme.palette.primaryColor,
  },
}));

function CustomerShareUpdateAdd(props) {
  const {
    customerId,
    label,
    multiple,
    setMultiple,
    functions,
    shareAddDisabledFlg = false,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const { responseHeader } = store.getState();
  // 組織ツリーユーザー選択
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  // 組織ツリー選択
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });
  // 「保存」ボタンをクリック時に送るパラメーター用ローカルステート
  const [params, setParams] = useState({
    userId: 0,
    divisionId: 0,
    startAt: '',
    endAt: '',
  });

  // モーダル用
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickSave = () => {
    // 更新
    const copyOjb = Object.assign({}, params);
    functions.updateCustomerShare(customerId, copyOjb);
    setOpen(false);
  };
  // バリデート
  const checkNullValid = () => {
    const requireList = [
      multiple.startAt,
      multiple.endAt,
    ];

    return requireList.includes(null || '');
  };

  useEffect(() => {
    setParams({
      userId: selectUser.userId,
      divisionId: selectDivision.divId,
      // 期間は、パラメーターに渡す前に整形
      startAt: multiple.startAt.replace(/-/g, '/').replace(/T/g, ' ') + ':00',
      endAt: multiple.endAt.replace(/-/g, '/').replace(/T/g, ' ') + ':00',
    });
  }, [selectUser, selectDivision, multiple]);

  useEffect(() => {
    if (checkNullValid() || selectDivision.divId === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [multiple, selectDivision.divId, disabled]);

  const data = {
    id: 'add',
    label: ['', ''],
    // require: true,
    type: 'multiple',
    startState: multiple.startAt,
    endState: multiple.endAt,
    setState: setMultiple,
  };
  return (
    <>
      <Button
        type="button"
        onClick={handleOpen}
        className={classes.blue}
        disabled={shareAddDisabledFlg}
      >
        {label}
      </Button>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title="共有者管理ウィンドウ"
        width={445}
        buttons={[
          {
            label: 'キャンセル',
            onClick: handleClose,
            type: 'secondary',
          },
          {
            label: '保存',
            onClick: handleClickSave,
            disabled,
          },
        ]}
      >
        <Grid className={classes.inner}>
          <UserTree
            selectDivision={selectDivision}
            setSelectDivision={setSelectDivision}
            selectUser={selectUser.userName !== '' ? selectUser : responseHeader}
            setSelectUser={setSelectUser}
            isSelectUserWindow
            showOnlyDivisions={false}
            treeHeaderSelect={null}
            doubleSearch
          />
          <div className={classes.date}>
            <Typography className={common.title4}>
              共有期間<span style={{ color: '#D83420' }}>＊</span>
            </Typography>
            <Calendar data={data} />
          </div>
        </Grid>
      </CommonDialog>
    </>
  );
}

export default CustomerShareUpdateAdd;
