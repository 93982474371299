import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const mokuhyoSetGetChangeHistrySlice = createSlice({
  name: 'mokuhyoSetGetChangeHistry',
  initialState: { mokuhyoList: initialState },
  reducers: {
    setMokuhyoList: (state, data) => {
      state.mokuhyoList = data.payload;
    },
    initialMokuhyoList: (state) => {
      state.mokuhyoList.targets = initialState;
    },
  },
});

export const { setMokuhyoList, initialMokuhyoList } = mokuhyoSetGetChangeHistrySlice.actions;

export default mokuhyoSetGetChangeHistrySlice.reducer;
