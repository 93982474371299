// redux管理不要そう
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const filestorageUpdateSlice = createSlice({
  name: 'filestorageUpdate',
  initialState: { filestorageUpdate: initialState },
  reducers: {
    updateFilesApi: (state, data) => {
      state.filestorageUpdate = data.payload;
    },
  },
});

export const { updateFilesApi } = filestorageUpdateSlice.actions;

export default filestorageUpdateSlice.reducer;
