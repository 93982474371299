import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { v4 as uuid } from 'uuid';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: '12px 16px 40px',
    borderTop: '1px solid #F3F3F3',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '8px 20px 8px 12px',
    background: '#FBFAF8',
    borderRadius: '4px',
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: '8px',
    },
  },
  filePath: {
    marginRight: '8px',
    marginLeft: '4px',
  },
  icon: {
    color: theme.palette.primaryColor,
    width: '16px',
    flexShrink: 0,
  },
  cancel: {
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

function MailAppendFile(props) {
  const { uploads, setUploads } = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    <Grid item className={classes.wrap}>
      <div className={classes.list}>
        {uploads.map(file => (
          <span key={uuid()} className={classes.li}>
            <AttachFileIcon className={classes.icon} />
            <Typography className={`${classes.filePath} ${common.small}`}>{file.fileName}</Typography>
            <Cancel
              className={`${classes.icon} ${classes.cancel}`}
              onClick={() => {
                let deletelist = uploads;
                deletelist = deletelist.filter((f) => {
                  return f.fileName !== file.fileName;
                });
                setUploads(deletelist);
              }}
            />
          </span>
        ))}
      </div>
    </Grid>
  );
}

export default MailAppendFile;
