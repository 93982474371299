import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesRegistGuidancesSlice = createSlice({
  name: 'schedulesRegistGuidances',
  initialState: { schedulesRegistGuidances: initialState },
  reducers: {
    schedulesRegistGuidancesApi: (state, data) => {
      state.schedulesRegistGuidances = data.payload;
    },
  },
});

export const { schedulesRegistGuidancesApi } = schedulesRegistGuidancesSlice.actions;

export default schedulesRegistGuidancesSlice.reducer;
