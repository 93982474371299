import React from 'react';
import Calendar from '../../common/calendar';

function UserMultipleDate(props) {
  const { dateMultiple } = props;
  const datalist = [
    {
      id: 'idtest',
      label: ['アカウント有効期間', ''], // typeが'multiple'のときのみ配列
      require: true,
      type: 'multipleParallel',
      state: dateMultiple.state,
      setState: {
        setFirst: dateMultiple.setState.setFirst,
        setEnd: dateMultiple.setState.setEnd,
      },
      valid: dateMultiple.valid,
    },
  ];

  return (
    <>
      {datalist.map((array) => {
        return <Calendar key="UserSoshikiCalendar" data={array} />;
      })}
    </>
  );
}

// prevStateとnextStateを比較し、Stateに変更が無ければメモの値を返す。
// const areEqual = (prevProps, nextProps) => {
//   return Object.is(prevProps.dateMultiple.state, nextProps.dateMultiple.state);
// };

export default React.memo(UserMultipleDate);
