import React from 'react';
import {
  Grid,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  textFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    backgroundColor: '#fff',
    padding: 8,
    marginBottom: 8,
  },
  label: {
    marginRight: 20,
  },
  // input：最小幅／paddingの指定なし
  compactInput: {
    padding: 0,
    '& > .MuiInput-root': {
      minWidth: 'initial',
    },
  },
});

function CustomSelect(props) {
  const { initial } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();
  const menusKeys = Object.keys(initial.obj.menus);
  const initialStyle = {
    width: initial.width ? initial.width : '100%',
  };

  return (
    <Grid style={initialStyle} className={classes.textFieldContainer}>
      <Typography className={`${baseClasses.title6} ${classes.label}`}>
        {initial.label}{initial.require ? <span style={{ color: '#D83420' }}>＊</span> : ''}
      </Typography>
      <FormControl className={`${baseClasses.inputWrap} ${classes.compactInput}`} disabled={initial?.disable}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e) => initial.set(e, initial)}
          className={baseClasses.smallStrong}
          defaultValue={initial.state ? initial.state : 0}
        >
          {initial?.require ? (
            <MenuItem
              value={0}
            >
              未選択
            </MenuItem>
          ) : null}
          {menusKeys.map((data) => (
            <MenuItem value={data} key={data}>{initial.obj.menus[data]}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default CustomSelect;
