import { useState, useEffect, useReducer, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import BrowsingHistoryWrapper from '../../components/browsingHistory/browsingHistory';
import { useQuery } from '../../hooks';
import hpinfoGetInfoApi from '../../apis/hpinfo/hpinfoGetInfoApi';
import {
  membersPutChangeStatusCodeApi,
  membersGetSearchConditionsApi,
  membersGetContentsHistoriesApi,
  membersGetOneClickSearchConditionsApi,
  membersGetFavoritesApi,
  membersGetProductsHistoriesApi,
  membersGetLoanApi,
  membersGetHistoriesApi,
  membersGetNotificationsApi,
  // membersGetKeywordsApi,
} from '../../apis/members';
import customerCallApi from '../../apis/customer/customerApi';
import { setMembersGetNotifications } from '../../store/members/membersGetNotificationsSlice';
import {
  MEMBERS_REDUCER_KEYS,
  reducer,
  initialResponseDataState,
} from './browsingHistoryReducer';

const apis = {
  [MEMBERS_REDUCER_KEYS.contentsHistories]: membersGetContentsHistoriesApi,
  [MEMBERS_REDUCER_KEYS.favorites]: membersGetFavoritesApi,
  [MEMBERS_REDUCER_KEYS.histories]: membersGetHistoriesApi,
  // [MEMBERS_REDUCER_KEYS.keywords]: membersGetKeywordsApi,
  [MEMBERS_REDUCER_KEYS.loan]: membersGetLoanApi,
  [MEMBERS_REDUCER_KEYS.notifications]: membersGetNotificationsApi,
  [MEMBERS_REDUCER_KEYS.oneclickSearchConditions]: membersGetOneClickSearchConditionsApi,
  [MEMBERS_REDUCER_KEYS.productsHistories]: membersGetProductsHistoriesApi,
  [MEMBERS_REDUCER_KEYS.searchConditions]: membersGetSearchConditionsApi,
};

function BrowsingHistoryContainers(props) {
  const {
    // Mobileの場合、顧客メインから渡すcustomerId/hashCustomerId
    customerId: propsCustomerId,
    hashCustomerId: propsHashCustomerId,
    customer = null,
  } = props;
  const dispatch = useDispatch();
  const [
    queryCustomerId,
    memberId,
    queryHashCustomerId,
  ] = useQuery(['customerId', 'memberId', 'hashCustomerId']);
  const [state, setState] = useReducer(reducer, initialResponseDataState, () => {
    return memberId ? {
      ...initialResponseDataState,
      currentMemberId: memberId,
    } : initialResponseDataState;
  });

  const customerId = propsCustomerId || queryCustomerId;
  const hashCustomerId = propsHashCustomerId || queryHashCustomerId;

  const [hpinfoCustomerId, setHpinfoCustomerId] = useState(null);

  // 退会済か否か(https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4393)
  const [isQuit, setIsQuit] = useState(0);

  // members系APIのparams
  const apiParams = useMemo(() => {
    if (state.currentMemberId) {
      return {
        memberId: state.currentMemberId,
      };
    } else if (hashCustomerId) {
      return { hashCustomerId };
    } else if (customerId) {
      return { customerId };
    }
    return {};
  }, [hashCustomerId, customerId, state.currentMemberId]);

  /**
   * 一覧系API情報を取得する
   * @param {string} type
   * @param {{offset: number; limit: number; memberId?: number}} params
   * @returns `Promise<{total: number; [listKey]: T[]} | null>`
   */
  const getApiDataFromListApi = useCallback(async (type, params) => {
    if (!apiParams.memberId && !apiParams.hashCustomerId) return null;
    let tablelimit;
    switch (type) {
      case MEMBERS_REDUCER_KEYS.searchConditions:
        tablelimit = localStorage.getItem('searchTablelimit') ? localStorage.getItem('searchTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.contentsHistories:
        tablelimit = localStorage.getItem('contentsTablelimit') ? localStorage.getItem('contentsTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.favorites:
        tablelimit = localStorage.getItem('favoritesTablelimit') ? localStorage.getItem('favoritesTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.histories:
        tablelimit = localStorage.getItem('prosessTablelimit') ? localStorage.getItem('prosessTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.keywords:
        tablelimit = localStorage.getItem('keywordTablelimit') ? localStorage.getItem('keywordTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.loan:
        tablelimit = localStorage.getItem('loanTablelimit') ? localStorage.getItem('loanTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.oneclickSearchConditions:
        tablelimit = localStorage.getItem('oneclickTablelimit') ? localStorage.getItem('oneclickTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.productsHistories:
        tablelimit = localStorage.getItem('browsingTablelimit') ? localStorage.getItem('browsingTablelimit') : 20;
        break;
      case MEMBERS_REDUCER_KEYS.notifications:
        tablelimit = localStorage.getItem('chanceTablelimit') ? localStorage.getItem('chanceTablelimit') : 20;
        break;
      default:
        tablelimit = 50;
        break;
    }
    params = params || { offset: 0, limit: tablelimit };
    // 一覧系のAPIを叩く
    const payload = await apis[type]({
      ...apiParams,
      ...params,
    });

    setState({ type, payload });
    return payload;
  }, [apiParams]);

  /**
   * 会員情報と関連WEB会員情報を取得する
   * @params {isForce: boolean} 強制APIを叩くか
   */
  const getMemberAndRelatedMembersData = useCallback(async (isForce = false) => {
    // 同じ会員の情報が既に存在した場合、API叩く中止にする
    if (!isForce && apiParams.memberId && state.member.memberId === apiParams.memberId) return;
    // 【hpInfo001】WEB会員情報取得
    const res = await hpinfoGetInfoApi(apiParams);
    setHpinfoCustomerId(res.customerId);
    let currentMember = res.members[0] || {};
    // 関連Web会員ID情報を取得する
    if (currentMember.customerId) {
      const customerResponse = await customerCallApi([currentMember.customerId]);
      const webs = customerResponse.data.customers[0].webs || [];
      setState({
        type: MEMBERS_REDUCER_KEYS.webs,
        payload: webs,
      });
      // 顧客メインからWeb会員情報画面を開いた際には
      // isMain=1、いなければweb会員IDが最大のものを開く
      if (apiParams.customerId && res.members.length > 1) {
        const isMainMember = webs.find(item => item.isMain === 1);
        const findMaxMemberId = () => {
          let maxMemberId = 0;
          for (const key in webs) {
            if (webs[key].memberId > maxMemberId) {
              maxMemberId = webs[key].memberId;
            }
          }
          return maxMemberId;
        };
        const mainMemberId = isMainMember
          ? isMainMember.memberId
          : findMaxMemberId();
        currentMember = res.members.find((item) => item.memberId === mainMemberId);
      }
    }
    setState({
      type: MEMBERS_REDUCER_KEYS.member,
      payload: currentMember,
    });
    setState({
      type: MEMBERS_REDUCER_KEYS.currentMemberId,
      payload: currentMember.memberId,
    });
    // 退会済か否かをセットする(https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4393)
    setIsQuit(currentMember.isQuit);
  }, [apiParams, state.member.memberId]);

  /**
   * APIから関する情報を取得する
   * @params {{isForce: boolean}} params 強制更新するか
   */
  const getApiData = useCallback(async (params) => {
    if (!Object.keys(apiParams).length) return;
    // 会員と関連WEB会員情報を取得する
    await getMemberAndRelatedMembersData(params?.isForce);
    // 各一覧取得
    getApiDataFromListApi(MEMBERS_REDUCER_KEYS.searchConditions);
    getApiDataFromListApi(MEMBERS_REDUCER_KEYS.contentsHistories);
    getApiDataFromListApi(MEMBERS_REDUCER_KEYS.favorites);
    if (apiParams.memberId) {
      getApiDataFromListApi(MEMBERS_REDUCER_KEYS.histories);
    }
    // getApiDataFromListApi(MEMBERS_REDUCER_KEYS.keywords);
    getApiDataFromListApi(MEMBERS_REDUCER_KEYS.loan);
    getApiDataFromListApi(MEMBERS_REDUCER_KEYS.oneclickSearchConditions);
    getApiDataFromListApi(MEMBERS_REDUCER_KEYS.productsHistories);
    const notifications = await getApiDataFromListApi(MEMBERS_REDUCER_KEYS.notifications);
    // 既存のロジック、一旦保留
    if (notifications) dispatch(setMembersGetNotifications(notifications));
  }, [getMemberAndRelatedMembersData, getApiDataFromListApi]);

  // 会員のステータス変更
  const updateStatusChange = async (data) => {
    if (!state.member.memberId) return;
    await membersPutChangeStatusCodeApi(state.member.memberId, data);
    // 会員情報を取得する
    await getMemberAndRelatedMembersData(true);
    // 処理履歴一覧を更新する
    await getApiDataFromListApi(MEMBERS_REDUCER_KEYS.histories);
  };

  useEffect(() => {
    getApiData();
  }, [apiParams, customer]);

  return (
    <BrowsingHistoryWrapper
      customerId={hpinfoCustomerId}
      state={state}
      setState={setState}
      onUpdate={() => getApiData({ isForce: true })}
      updateStatusChange={updateStatusChange}
      getApiDataFromListApi={getApiDataFromListApi}
      isQuit={isQuit}
    />
  );
}

export default BrowsingHistoryContainers;
