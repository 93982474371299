import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem } from '@material-ui/core';
import commonStyles from '../../../../styles';
import Calendar from '../../../calendar';

const useStyles = makeStyles({
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal1: {
    width: 208,
  },
  contentIptDate: {
    marginTop: '-2px',
    '& div': {
      border: 'none',
      padding: 0,
    },
    '& span': {
      padding: 0,
    },
    '& label': {
      minWidth: 125,
      width: '100%',
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 16,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
});

export default function RegisterWindowDate(props) {
  const {
    date,
    setDate,
    setDisabled,
    getParts,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // 対応日時
  const initDateStr = date.replaceAll('/', '-').substr(0, 16);
  const [dateObj, setDateObj] = useState({ assignAt: initDateStr });
  // const [dateSp, setDateSp] = useState(initDateStr);
  const dateSetState = (val) => {
    const dateStr = `${val.replaceAll('-', '/').substr(0, 10)} ${val.substr(11, 16)}`;
    setDateObj({
      ...dateObj,
      assignAt: dateStr,
    });
    setDate(dateStr);
    setDisabled(val === '');
  };

  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'time', // String 'date'|'time'|'multitime'|'multiple'
      state: dateObj, // state
      setState: dateSetState,
    },
  ];

  const getDate = () => {
    return (
      <Grid className={classes.contentIptDate}>
        <Calendar data={datalist[0]} />
      </Grid>
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '対応日時',
      required: '＊',
      val: dateObj.assignAt.replace(/-/g, '/').replace(/T/g, ' '),
      content: getDate,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {getParts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.contentIpt}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          対応日時<span>＊</span>
        </Typography>
        <Grid className={classes.contentIptVal1}>
          {getDate()}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
