import { TextField } from '@material-ui/core';
import { useState, useEffect } from 'react';
import gensenStyle from './gensenRegisterStyles';
import ListItem from './listItem';

export default function AddMemo(props) {
  const { data, setData, readOnly } = props;
  const gCommon = gensenStyle();
  const [txt, setTxt] = useState('');

  const onChange = (e) => {
    return setTxt(e.target.value);
  };
  const onBlur = () => {
    setData({ ...data, memo: txt });
  };

  useEffect(() => {
    setTxt(data.memo || '');
  }, [data]);

  return (
    <ListItem
      label="メモ"
      readOnly={readOnly}
      noBtn
    >
      <TextField
        multiline
        value={txt}
        onChange={(e) => { return onChange(e); }}
        onBlur={onBlur}
        placeholder="-"
        className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
      />
    </ListItem>
  );
}
