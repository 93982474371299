import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../../store';
/* eslint-disable import/no-cycle */
import UserTreeIndex from '../../../components/common/userTreeNew/parts/userTreeIndex';
import UserTreeIndexSp from '../../../components/common/userTreeNew/parts/sp/userTreeIndexSp';
import userListApi from '../../../apis/user/userListApi';
import divisionsGetTreeApi from '../../../apis/divisions/divisionsGetTreeApi';
import { getUserListApi } from '../../../store/user/userListSlice';
import { getUserDivListApi } from '../../../store/user/userDivListSlice';

import {
  useUserTreeContext,
  UserTreeContextProvider,
} from '../../../components/common/userTreeNew/context';
import { useStore } from '../../../hooks';

function UserTreeContainers(props) {
  const {
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    treeHeaderSelect,
    showOnlyDivisions,
    clickableUserAndDivision,
    setIsOpenTree,
    isDialog,
    isMulti,
    isDuplicate,
    isSelectUserWindow,
    setTargetDivisionTree,
    isNotSearch,
    isUserMaster,
    isDefaultLoginUser,
    doubleSearch,
  } = props;

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.userList);
  const {
    userDivList: userDiv,
    userDivNoAffiliationList: noAffiliationUsers,
    isReload: reload,
    isUserMaster: prevUserMaster,
  } = useSelector((state) => state.userDivList);

  const { setIsLoading } = useUserTreeContext();
  const isReloadUserTree = useRef(false);

  const { responseHeader } = useStore();

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // TODO: [/division/customertree]APIを呼び出す globalstate?
  // 現在はセレクトボックスのvalue偶数/奇数で交互に返すだけ
  const [list, setList] = useState(userDiv);
  const [noAffiliationList, setNoAffiliationList] = useState(noAffiliationUsers);
  // 1(基本社員情報ツリー)がデフォルト
  const [headValue, setHeadValue] = useState(1);

  // GET ユーザーのツリーリスト取得
  const userListFunction = async (status) => {
    setIsLoading(true);
    await userListApi(status)
      .then((res) => dispatch(getUserListApi(res.data)))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  // 組織のツリーリスト取得
  const userDivListFunction = async () => {
    setIsLoading(true);
    await divisionsGetTreeApi(isUserMaster)
      .then((res) => {
        res.data.isUserMaster = isUserMaster;
        dispatch(getUserDivListApi(res.data));
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(async () => {
    if (userDiv.length === 0 || isUserMaster !== prevUserMaster) {
      await userDivListFunction();
      if (isUserMaster) userListFunction(1);
      // eslint-disable-next-line max-len
      isDefaultLoginUser ? setSelectUser({ userId: responseHeader.userId, userName: responseHeader.userName }) : null;
    }
  }, []);

  useEffect(async () => {
    if (isReloadUserTree.current !== reload) {
      await userDivListFunction();
      isReloadUserTree.current = reload;
      const { userDivList } = store.getState();
      setList(userDivList.userDivList);
    }
  }, [reload]);

  useEffect(() => {
    setList(userDiv);
    setNoAffiliationList(noAffiliationUsers);
  }, [userDiv, noAffiliationUsers]);

  const getApi = (value) => {
    let selectValue = null;
    if (isSp) {
      selectValue = value;
    } else {
      selectValue = value.target.value;
    }
    if (selectValue % 2) {
      setHeadValue(1);
      setList(userDiv);
      setNoAffiliationList(noAffiliationUsers);
    } else {
      setHeadValue(2);
      setList(users.lists);
    }
  };

  // ツリーアイウエオ順
  const getObjectSortFunction = (userLists, word) => {
    const userIdList = [];
    let count = 0;
    for (const head of userLists) {
      const obj = { headChar: head.headChar, userIds: [] };
      for (const userObj of head.userList) {
        if (userObj.fullName.includes(word)) {
          obj.userIds.push(userObj.userId);
          count += 1;
        }
      }
      obj.userIds.length > 0 ? userIdList.push(obj) : null;
    }
    userIdList.push(count);
    userIdList.push(word);
    return userIdList;
  };

  return isSp ? (
    <UserTreeIndexSp
      list={list}
      setList={getApi}
      noAffiliList={noAffiliationList}
      setNoAffiliList={setNoAffiliationList}
      selectUser={selectUser}
      setSelectUser={setSelectUser}
      selectDivision={selectDivision}
      setSelectDivision={setSelectDivision}
      treeHeaderSelect={treeHeaderSelect}
      setTargetDivisionTree={setTargetDivisionTree}
      getObjectSortFunction={getObjectSortFunction}
      headValue={headValue}
      setIsOpenTree={setIsOpenTree}
      isMulti={isMulti}
      isSelectUserWindow={isSelectUserWindow}
      showOnlyDivisions={showOnlyDivisions}
      clickableUserAndDivision={clickableUserAndDivision}
    />
  ) : (
    <UserTreeIndex
      list={list}
      setList={getApi}
      noAffiliList={noAffiliationList}
      setNoAffiliList={setNoAffiliationList}
      selectUser={selectUser}
      setSelectUser={setSelectUser}
      selectDivision={selectDivision}
      setSelectDivision={setSelectDivision}
      treeHeaderSelect={treeHeaderSelect}
      getObjectSortFunction={getObjectSortFunction}
      headValue={headValue}
      setIsOpenTree={setIsOpenTree}
      isDialog={isDialog}
      isMulti={isMulti}
      isDuplicate={isDuplicate}
      isSelectUserWindow={isSelectUserWindow}
      showOnlyDivisions={showOnlyDivisions}
      clickableUserAndDivision={clickableUserAndDivision}
      userDivListFunction={userDivListFunction}
      setTargetDivisionTree={setTargetDivisionTree}
      isNotSearch={isNotSearch}
      isUserMaster={isUserMaster}
      doubleSearch={doubleSearch}
    />
  );
}

export default function UserTreeContainersWithProvider(props) {
  return (
    <UserTreeContextProvider>
      <UserTreeContainers {...props} />
    </UserTreeContextProvider>
  );
}
