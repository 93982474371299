import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  id: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 130,
    background: 'rgba(51, 51, 51, 0.04)',
    padding: '0 8px',
  },
});

function CustomerResponse(props) {
  const { customerId } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.id}>
      <Typography className={baseClasses.title6}>
        顧客ID
      </Typography>
      <Typography className={baseClasses.title4}>
        {customerId}
      </Typography>
    </Grid>
  );
}

export default React.memo(CustomerResponse);
