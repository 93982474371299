import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';
import MailCreateWindowSpAfter from '../../sp/afterCallWindowMail';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    '& .MuiDialog-paper': {
      width: '100%',
      margin: '0 8px',
    },
  },
  zIndex: { zIndex: '1301 !important' },
  list: {
    paddingLeft: '16px',
  },
  btn: {
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    fontFamily: 'Roboto',
    padding: '16px',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& > p': {
        fontSize: '13px',
      },
    },
  },
  label: {
    marginRight: '8px',
  },
  icon: {
    fill: theme.palette.primaryColor,
    marginLeft: '10px',
  },
  disable: {
    pointerEvents: 'none',
    background: '#f8f8f8',
  },
}));

export default function PhoneNumberSelectDrawerInAfterCall(props) {
  const {
    user,
    open,
    setOpen,
    afterClose,
    selectType,
    setSelectType,
    meta,
    setMeta,
    setting,
    afterCallFunctions,
    responseHeader,
    libraries,
    mailParam,
    setMailParam,
    editorState,
    setEditorState,
    defaultSmsUserId,
    setDefaultSmsUserId,
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  const list = [
    user.phoneNumber1 ? user.phoneNumber1 : '-',
    user.phoneNumber2 ? user.phoneNumber2 : '-',
    user.phoneNumber3 ? user.phoneNumber3 : '-',
    user.phoneNumber4 ? user.phoneNumber4 : '-',
  ];

  const [defaultUser, setDefaultUser] = useState(list[defaultSmsUserId]);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const values = setting.tosms;
  const [state, setState] = useState(values.check);

  // dataは電話番号: 00000000000など, keyはphoneNumber1などが入る
  const handleChange = (data, index) => {
    const result = {
      phoneNumber1: false,
      phoneNumber2: false,
      phoneNumber3: false,
      phoneNumber4: false,
    };
    const key = `phoneNumber${index + 1}`;
    result[key] = true;
    setDefaultSmsUserId(index);
    setState(result);
    setDefaultUser(data);
  };

  // MailCreateWindowSpAfterのOKボタン押下時
  const closeAndChange = () => {
    values.setCheck(state);
    values.setList(defaultUser);
  };

  return (
    <CustomSimpleDrawer
      className={`${classes.dialog} ${drawerOpen ? classes.zIndex : null}`}
      open={open}
      onClose={() => { setOpen(false); }}
      list={
        <>
          <List className={classes.list}>
            {list.map((data, i) => (
              <ListItem
                button
                key={data}
                classes={{ gutters: common.gutters }}
                selected={defaultUser === data}
                onClick={() => { handleChange(data, i); }}
                className={`${data === '-' ? classes.disable : null}`}
              >
                <ListItemText
                  primary={data}
                />
              </ListItem>
            ))}
          </List>
          <Grid container justifyContent="center">
            <Button
              className={common.buttonsSecondary}
              onClick={() => { setOpen(false); }}
            >
              CANCEL
            </Button>
            <MailCreateWindowSpAfter
              open={open}
              setOpen={setOpen}
              afterClose={afterClose}
              user={user}
              btnLabel={<span>OK</span>}
              type={0}
              selectType={selectType}
              setSelectType={setSelectType}
              setting={setting}
              meta={meta}
              setMeta={setMeta}
              afterCallFunctions={afterCallFunctions}
              responseHeader={responseHeader}
              libraries={libraries}
              mailParam={mailParam}
              setMailParam={setMailParam}
              setDrawerOpen={setDrawerOpen}
              editorState={editorState}
              setEditorState={setEditorState}
              closeAndChange={closeAndChange}
            />
          </Grid>
        </>
      }
    />
  );
}
