import AfterCallWindowContainer from '../../../containers/mail/sp/afterCallWindowContainer';

function AfterCallWindowSp(props) {
  const {
    user,
    open,
    setOpen,
    onClose,
  } = props;
  return (
    <AfterCallWindowContainer
      user={user}
      open={open}
      setOpen={setOpen}
      onClose={onClose}
    />
  );
}

export default AfterCallWindowSp;
