import PullToRefresh from 'react-simple-pull-to-refresh';
import { makeStyles } from '@material-ui/core';
// import LoadingSpinner from './parts/_loadingSpinner';
import { classNames } from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  pull: {
    '& > .ptr__pull-down': {
      top: '120px',
      '& .lds-ellipsis': {
        zIndex: 100,
        '& > div': {
          background: theme.palette.primaryColor,
        },
      },
    },
  },
}));

function PullRefComponent(props) {
  const {
    refreshing,
    onRefresh,
    canFetchMore,
    onFetchMore,
    fetchMoreThreshold = undefined,
    className,
    children,
  } = props;

  const classes = useStyles();

  return (
    <PullToRefresh
      refreshing={refreshing}
      onRefresh={onRefresh}
      canFetchMore={canFetchMore}
      onFetchMore={onFetchMore}
      className={classNames(classes.pull, className)}
      fetchMoreThreshold={fetchMoreThreshold}
      // pullingContent={onRefresh ? <LoadingSpinner /> : null}
    >
      { children }
    </PullToRefresh>
  );
}

export default PullRefComponent;
