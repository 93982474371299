import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import OrgSettingTargetUser from './orgSettingTargetUser';
import CustomSelect from '../../../eleCommon/customSelect';
import {
  USER_CLASS_SELECT,
  USER_GRADE_SELECT_LIST,
} from '../../../../constants/users';
import CustomCheckModal from '../../../eleCommon/customCheckModal';
import UserTree from '../../../common/userTreeNew';

import { dateFormatter } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  isMainClass: {
    backgroundColor: '#F3F3F3',
    width: '100%',
    padding: '10px 5px',
    border: '1px solid #C8C8C8',
  },
  optionGrid: {
    marginTop: '1%',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  gridMarginR: {
    marginRight: '1%',
  },
  gridPaper: {
    width: '50%',
    backgroundColor: 'white',
    border: '1px solid #C8C8C8',
    padding: '5px 10px',
  },
  gridPaperTitle: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  formRadioClass: {
    '& > span': {
      fontSize: '13px',
      padding: '0px',
    },
  },
  textButton: {
    textAlign: 'right',
    color: theme.palette.primaryColor,
    fontSize: '13px',
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
  roleClass: {
    backgroundColor: 'white',
    padding: '5px',
    margin: '5px',
    border: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  formControlClass: {
    margin: '5px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  isMainFormGroup: {
    justifyContent: 'flex-start',
  },
  selectDivisionInput: {
    width: '100%',
    cursor: 'pointer',
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
  radioTitle: {
    marginRight: '1rem',
  },
  disabledColor: {
    '& > span': {
      color: '#8E8E8E!important',
    },
  },
  asterisk: { color: '#db3131', marginLeft: 3 },
}));

const defaultAddList = [
  { label: '追客中', checked: true, id: 1 },
  { label: '追わない', checked: true, id: 2 },
  { label: '契約', checked: false, id: 10 },
  { label: 'クレーム', checked: true, id: 11 },
];

function OrgSettingTransfer(props) {
  const {
    setLocalUserState,
    division,
    userState,
    // NOTE: 以下propsがバリデーション制御に使用されている
    selectDivision,
    setSelectDivision,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [positionId, setPositionId] = useState('');
  const [gradeId, setGradeId] = useState('');
  const [openStatus, setOpenStatus] = useState(false);
  const [stayName, setStayName] = useState('置いていく（変更前の組織へ）');
  const [addlists, setAddLists] = useState(defaultAddList);

  const [isOpenTree, setIsOpenTree] = useState(false);

  const { divisionId } = division;

  // {1: 共有の解除, 2: 共有の継続, 3: 持っていく, 4: 置いていく}
  const radioHeader = [
    { title: '共有された顧客', type: '1' },
    { title: 'あなたから共有した顧客', type: '2' },
  ];
  const radioKokyaku = [
    { title: '共有の解除', state: '1', ml: '13px' },
    { title: '共有の継続', state: '2', ml: '0px' },
  ];
  const radioTitle = ['戸建'];
  const radioChangeKokyaku = [
    {
      title: '持っていく（あなたが担当のまま変更後の組織へ）',
      state: '3',
      ml: '13px',
    },
    { title: stayName, state: '4', ml: '0px', id: 'id_transfer_stay' },
  ];

  // 異動先
  const handleOpenTree = () => setIsOpenTree((s) => !s);

  const setLocalUserVal = (key, val) => {
    setLocalUserState((s) => {
      const { divisions, ...newUserDetail } = structuredClone(s);
      newUserDetail.divisions = [];
      divisions.forEach((d) => {
        if (d.userDivisionId === division.userDivisionId) d[key] = val;
        newUserDetail.divisions.push(d);
      });
      return newUserDetail;
    });
  };

  // 異動先組織の変更
  const handleTreeOk = () => setLocalUserVal('divisionId', selectDivision.divId);
  const handleTreeCancel = () => setSelectDivision({ divId: 0, divisionName: '-' });

  // 役割
  const changePosition = (e) => {
    setPositionId(e.target.value);
    const positionValue = Number(e.target.value);
    setLocalUserVal('positionCode', positionValue);
  };

  // 役職
  const changeGrade = (e) => {
    setGradeId(e.target.value);
    const intRoleValue = Number(e.target.value);
    setLocalUserVal('gradeCode', intRoleValue);
  };

  const setLocalUserCustomerStatusCode = (statusArray) => {
    setLocalUserState((s) => {
      const { divisions, ...newUserDetail } = structuredClone(s);
      newUserDetail.divisions = [];
      divisions.forEach((d) => {
        if (d.userDivisionId === division.userDivisionId) {
          const tmp = d.divisionsFrom[0];
          d.divisionsFrom = [
            {
              ...tmp,
              customerStatusCodes: statusArray,
            },
          ];
        }
        newUserDetail.divisions.push(d);
      });
      return newUserDetail;
    });
  };
  // 変更後の担当顧客
  const handleEvent = (e) => {
    let tmpCustomerStatusCodes = [];
    if (e.target.value === '4') {
      setAddLists(defaultAddList);
      tmpCustomerStatusCodes = defaultAddList
        .map((i) => i.checked)
        .map((i) => i.id);
      setStayName(
        `置いていく（${defaultAddList
          .map((i) => i.checked)
          .map((i) => i.label)
          .join()}）`,
      );
      setOpenStatus(true);
    } else {
      setAddLists(addlists.map((list) => ({ ...list, checked: false })));
      setStayName('置いていく（変更前の組織へ）');
    }
    setLocalUserCustomerStatusCode(tmpCustomerStatusCodes);
  };
  // 変更後の担当顧客 - 置いてく
  const handleStayOk = (statusCodes) => setLocalUserCustomerStatusCode(statusCodes);

  // 共有被共有顧客
  const changeKokyaku = (e, w) => {
    const selectedValue = e.target.value === '2' ? 0 : Number(e.target.value); // '2'
    const selectType = w.type;
    setLocalUserState((s) => {
      const newUserDetail = structuredClone(s);
      if (selectType === '1') {
        newUserDetail.isReceiveShare = selectedValue;
      } else if (selectType === '2') {
        newUserDetail.isSendShare = selectedValue;
      }
      return newUserDetail;
    });
  };

  // 対象顧客
  const openCustomerGraph = (w) => {
    const typeFlg = w.type === '1';
    setType(typeFlg);
    setOpen(true);
  };

  const selectData = {
    title: '',
    required: true,
    key: 'orgRole',
    type: 'flg',
    obj: USER_CLASS_SELECT,
    state: positionId,
    set: changePosition,
    width: '90%',
  };

  const selectRole = {
    title: '',
    required: true,
    key: 'orgRole',
    type: 'flg',
    obj: USER_GRADE_SELECT_LIST,
    state: gradeId,
    set: changeGrade,
    width: '70%',
  };

  // ラジオボタン
  const radioBoxComponent = (w) => {
    return (
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => changeKokyaku(e, w)}
          defaultValue="1"
        >
          {radioKokyaku.map((data) => (
            <FormControlLabel
              key={data.state}
              value={data.state}
              control={<Radio size="small" />}
              label={data.title}
              className={classes.formRadioClass}
              style={{ marginLeft: data.ml }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  // 共有された顧客とあなたから共有した顧客
  const radioRowComponent = (radioValue) => {
    return (
      <Grid container className={classes.optionGrid} key={radioValue.title}>
        <Grid item style={{ width: '18%' }}>
          <Typography className={baseClasses.title4}>
            {radioValue.title}
          </Typography>
        </Grid>
        {radioTitle.map((data) => (
          <Grid
            item
            className={`${classes.gridPaper} ${classes.gridMarginR}`}
            key={data}
          >
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item className={classes.radioTitle}>
                <Typography className={baseClasses.title6}>{data}</Typography>
              </Grid>
              <Grid item>{radioBoxComponent(radioValue)}</Grid>
              <Grid item>
                <Button
                  className={classes.textButton}
                  onClick={() => openCustomerGraph(radioValue)}
                >
                  対象顧客
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  // 異動元組織初期設定
  useEffect(() => {
    setLocalUserState((s) => {
      const nowDate = dateFormatter(new Date());
      const { divisions, ...newUserDetail } = structuredClone(s);
      newUserDetail.divisions = [];
      divisions.forEach((d) => {
        if (d.userDivisionId !== division.userDivisionId) return;
        newUserDetail.divisions.push({
          ...d,
          startAt: nowDate,
          endAt: '9999/12/31 23:59:59',
        });
      });
      return newUserDetail;
    });
    setPositionId(division.positionCode);
    setGradeId(division.gradeCode);
  }, []);

  return (
    <Grid className={classes.isMainClass}>
      <Grid container>
        <Grid item className={classes.roleClass}>
          <span className={`${baseClasses.title4} ${baseClasses.labelShort}`}>
            異動先
            <span className={classes.asterisk}>＊</span>
          </span>
          <TextField
            className={`${classes.selectDivisionInput} ${baseClasses.modalBtn}`}
            id="orgSetting-division-input"
            value={selectDivision.divisionName}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            onClick={() => {
              handleOpenTree(selectDivision);
            }}
          />
          {isOpenTree ? (
            <UserTree
              selectDivision={selectDivision}
              isOpenTree={isOpenTree}
              setIsOpenTree={setIsOpenTree}
              setSelectDivision={setSelectDivision}
              handleSelectFunction={handleTreeOk}
              handleClearFunction={handleTreeCancel}
              showOnlyDivisions
              isDialog
              isDuplicate
              isUserMaster
            />
          ) : null}
        </Grid>
        <Grid container className={classes.roleClass}>
          <Grid item style={{ width: '50%' }} className={baseClasses.grid}>
            <span className={baseClasses.title4}>
              役割
              <span className={classes.asterisk}>＊</span>
            </span>
            <CustomSelect initial={selectData} />
          </Grid>
          <Grid item style={{ width: '50%' }} className={baseClasses.grid}>
            <span className={baseClasses.title4}>
              役職
              <span className={classes.asterisk}>＊</span>
            </span>
            <CustomSelect initial={selectRole} />
          </Grid>
        </Grid>
        <Grid container className={classes.roleClass}>
          <span className={baseClasses.title4} style={{ marginRight: '4%' }}>
            変更後の担当顧客
          </span>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleEvent}
            defaultValue="3"
          >
            {radioChangeKokyaku.map((data) => (
              <FormControlLabel
                key={data.state}
                value={data.state}
                control={<Radio size="small" color="primary" />}
                label={data.title}
                className={classes.formRadioClass}
                style={{ marginLeft: data.ml }}
              />
            ))}
          </RadioGroup>
          {openStatus ? (
            <CustomCheckModal
              lists={addlists}
              setLists={setAddLists}
              setName={setStayName}
              open={openStatus}
              setOpen={setOpenStatus}
              setValue={handleStayOk}
            />
          ) : null}
        </Grid>
      </Grid>
      {radioHeader.map((data) => radioRowComponent(data))}
      {open ? (
        <OrgSettingTargetUser
          open={open}
          setOpen={setOpen}
          type={type}
          divisionId={divisionId}
          user={userState}
        />
      ) : null}
    </Grid>
  );
}

export default OrgSettingTransfer;
