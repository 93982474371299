import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AutoSizer, List } from 'react-virtualized';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  // Grid,
} from '@material-ui/core';
import commonStyles from '../../styles';
import CustomerBatchTableRow from './customerBatchTableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 32px',
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
  },
  control: {
    position: 'absolute',
    top: '-16px',
    right: 0,
    display: 'flex',
    alignItems: 'center',
  },
  controlLbl: {
    color: 'rgba(51, 51, 51, 0.6)',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginRight: 8,
  },
  tablePagination: {
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-selectRoot': {
      margin: '0 40px 0 0',
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
      color: 'rgba(51, 51, 51, 0.6)',
    },
    '& .MuiSelect-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
      color: 'rgba(51, 51, 51, 0.6)',
    },
    '& .MuiIconButton-root': {
      padding: '0 5px',
    },
  },
  tableHead: {
    '&::after': {
      content: '""',
      height: 32,
      width: '100%',
      display: 'block',
    },
  },
  tableCellTh: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: 0,
    textAlign: 'center',
    borderBottom: '2px solid #C8C8C8',
    color: '#8C8C8C',
    width: 'calc((100vw * 1.25 - 728px - 64px - 240px) / 9)',
    maxWidth: 'calc((100vw * 1.25 - 728px - 64px - 240px) / 9)',
    minWidth: 120,
    '& .MuiTextField-root': {
      borderTop: '2px solid #8C8C8C',
      borderRight: '1px solid #C8C8C8',
      boxSizing: 'borderBox',
    },
    '&:nth-child(1)': {
      width: 26,
      maxWidth: 26,
      minWidth: 26,
      '& .MuiButtonBase-root': {
        padding: '6px 4px',
      },
      '& .MuiTableSortLabel-root': {
        marginLeft: 0,
      },
    },
    '&:nth-child(2)': {
      width: 40,
      maxWidth: 40,
      minWidth: 40,
    },
    '&:nth-child(3)': {
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    '&:nth-child(4)': {
      width: 92,
      maxWidth: 92,
      minWidth: 92,
    },
    '&:nth-child(6)': {
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    '&:nth-child(7)': {
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    '&:nth-child(8)': {
      width: 180,
      maxWidth: 180,
      minWidth: 180,
    },
    '&:nth-child(11)': {
      width: 290,
      maxWidth: 290,
      minWidth: 290,
    },
    '&:nth-child(12)': {
      width: 130,
      maxWidth: 130,
      minWidth: 130,
    },
    '&:nth-child(16)': {
      width: 110,
      maxWidth: 110,
      minWidth: 110,
    },
    '& .MuiTableSortLabel-root': {
      padding: '6px 8px',
      whiteSpace: 'nowrap',
      // marginLeft: 6,
      '& span': {
        display: 'inline-block',
      },
      '& .MuiSvgIcon-root': {
        fontSize: 12,
        color: `${theme.palette.primaryColor}!important`,
      },
    },
    '& > p': {
      background: '#F3F3F3',
      borderTop: '2px solid #8C8C8C',
      borderRight: '1px solid #C8C8C8',
      margin: 0,
      padding: '5px 8px',
    },
  },
  button: {
    background: '#F3F3F3',
    borderTop: '2px solid #8C8C8C',
    padding: '8px 0 8px 6px',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonIcon: {
    color: '#333',
    fontSize: 14,
    margin: '0 2px 0 0',
  },
  buttonTxt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  tableSortLabel: {
    display: 'block',
  },
  checkBox1: {
    padding: '4px 8px',
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableContainer: { height: 'calc(100vh - 240px)', width: '100%' },
  thead: {
    background: '#fafafa',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  let a2 = a[orderBy];
  let b2 = b[orderBy];
  if (orderBy === 'memberStatusCode') {
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4577
    // Web会員の並び替えでnull値の場合の場合の並び替えに対応
    // 4という数値は以下 - 昇順の場合、プレミアム否認の次に表示されている必要がある
    // https://docs.google.com/spreadsheets/d/1UMUmqCQ51Wg-zp1wWnohRWSnENvAR1oOhfGkL1hpizQ/edit#gid=859084260&range=E863
    a2 = a2 ?? 4;
    b2 = b2 ?? 4;
  }
  if (b2 < a2) {
    return -1;
  }
  if (b2 > a2) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'no', label: 'No' },
  { id: 'customerId', label: '顧客ID' },
  { id: 'memberStatusCode', label: 'Web会員' },
  { id: 'effectAt', label: '反響日' },
  { id: 'customerLastName', label: '姓' },
  { id: 'customerFirstName', label: '名' },
  { id: 'userLastName', label: '担当者' },
  { id: 'detachedHouseBudgetMax', label: '戸建上限' },
  { id: 'landBudgetMax', label: '土地上限' },
  { id: 'wishArea', label: '希望地域' },
  { id: 'annualIncome', label: <span>年収<br />（万円）</span> },
  { id: 'ownFunds', label: <span>自己資金<br />（万円）</span> },
  { id: 'monthlyPayment', label: '月々の返済' },
  { id: 'userLevel', label: 'ランク' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const baseClasses = commonStyles();

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell className={classes.tableCellTh}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            className={classes.checkBox1}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={`${baseClasses.title4} ${classes.tableCellTh}`}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={classes.tableSortLabel}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function CustomerBatchTable(props) {
  const {
    rows,
    setSelectedCustomerId,
    selectedCustomerId,
  } = props;

  const classes = useStyles();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('expireAt');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.customerId);
      setSelectedCustomerId(newSelecteds);
      return;
    }
    setSelectedCustomerId([]);
  };

  const isSelected = (name) => selectedCustomerId.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selectedCustomerId.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCustomerId, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCustomerId.slice(1));
    } else if (selectedIndex === selectedCustomerId.length - 1) {
      newSelected = newSelected.concat(selectedCustomerId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCustomerId.slice(0, selectedIndex),
        selectedCustomerId.slice(selectedIndex + 1),
      );
    }

    setSelectedCustomerId(newSelected);
  };

  const customerList = stableSort(rows, getComparator(order, orderBy));
  if (customerList.length === 0) return <div />;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedCustomerId.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody id="instructionListTablebody" className={classes.tableContainer}>
              <AutoSizer>
                {({
                  width,
                  height,
                }) => {
                  return (
                    <List
                      width={width}
                      height={height}
                      rowCount={customerList?.length ?? 0}
                      rowHeight={52}
                      overscanRowCount={1}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      rowRenderer={({ index, style }) => {
                        const isItemSelected = isSelected(customerList[index]?.customerId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const items = [];
                        items.push(
                          <CustomerBatchTableRow
                            key={customerList[index].customerId}
                            index={index}
                            row={customerList[index]}
                            isItemSelected={isItemSelected}
                            labelId={labelId}
                            handleClick={handleClick}
                            style={style}
                          />,
                        );
                        return items;
                      }}
                    />
                  );
                }}
              </AutoSizer>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

// 仮想スクロールが完成するまでは残しておく
// return (
//   <div className={classes.root}>
//     <Paper className={classes.paper}>
//       <TableContainer className={classes.tableContainer}>
//         <Table
//           className={classes.table}
//           aria-labelledby="tableTitle"
//           aria-label="enhanced table"
//           stickyHeader
//         >
//           <EnhancedTableHead
//             classes={classes}
//             numSelected={selectedCustomerId.length}
//             order={order}
//             orderBy={orderBy}
//             onSelectAllClick={handleSelectAllClick}
//             onRequestSort={handleRequestSort}
//             rowCount={rows.length}
//           />
//           <TableBody id="instructionListTablebody">
//             {stableSort(rows, getComparator(order, orderBy))
//               .map((row, index) => {
//                 const isItemSelected = isSelected(row.customerId);
//                 const labelId = `enhanced-table-checkbox-${index}`;
//                 return (
//                   <CustomerBatchTableRow
//                     key={row.customerId}
//                     index={index}
//                     row={row}
//                     isItemSelected={isItemSelected}
//                     labelId={labelId}
//                     handleClick={handleClick}
//                   />
//                 );
//               })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Paper>
//   </div>
// );
