import { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import customerCallApi from '../../apis/customer/customerApi';
import CustomerUpdateApi from '../../apis/customer/customerUpdateApi';
import { updateGotDateTime } from '../../store/customer/gotDataTimeSlice';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import CustomerMainItem from '../../components/customerMain/parts/customerMainItem';
import ListBtn from '../../components/customerMain/parts/listBtn';
import ListMultiButton from '../../components/customerMain/parts/listMultiButton';
import ListCheckbox from '../../components/customerMain/parts/listCheckbox';
import CustomBackSelect from '../../components/eleCommon/customBackSelect';
import CustomMainSelect from '../../components/eleCommon/customMainSelect';
import CustomBlankSelect from '../../components/eleCommon/customBlankSelect';
import CustomMainSelectMulti from '../../components/eleCommon/customMainSelectMulti';
import commonTheme from '../../components/styles/theme';
import EditDialog1 from '../../components/customerMain/parts/editDialog1';
import EditDialog2 from '../../components/customerMain/parts/editDialog2';
import DraggableDialog from '../../components/eleCommon/draggableDialog';
import LoadingEllipsis from '../../components/common/pullRefComponent/parts/loadingEllipsis';
import commonStyles from '../../components/styles';
import CustomMainSelectPlaceholder from '../../components/eleCommon/customPlaceholderSelect';

const useStyles = makeStyles((theme) => ({
  content: {
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  main: {
    display: 'flex',
  },
  sideMenu: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 240,
    },
  },
  contentHeader: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '16px 0',
    },
    [commonTheme.breakpoints.up('md')]: {
      height: 60,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiGrid-root': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    '& .MuiTablePagination-root': {
      '& .MuiToolbar-root': {
        [commonTheme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
      '& .MuiTablePagination-selectRoot': {
        [commonTheme.breakpoints.up('md')]: {
          marginLeft: 0,
          marginRight: 40,
        },
      },
      '& .MuiTablePagination-actions': {
        [commonTheme.breakpoints.up('md')]: {
          marginLeft: 14,
        },
        '& button': {
          [commonTheme.breakpoints.up('md')]: {
            padding: 6,
          },
        },
      },
      '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
        [commonTheme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
  },
  contentBody: {
    display: 'flex',
    padding: 16,
  },
  contentMain: {
    [commonTheme.breakpoints.up('md')]: {
      // width: 1234,
      height: 'calc(100vh - 170px)',
      overflowY: 'auto',
    },
    '& .MuiTableContainer-root': {
      [commonTheme.breakpoints.up('md')]: {
        overflowX: 'unset',
      },
    },
  },
  contentSub: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 399,
      height: 865,
      border: '1px solid rgba(51, 51, 51, 0.4)',
    },
  },
  headline: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      padding: '19px 0 8px',
      margin: '0px',
      top: '0px',
      background: '#fff',
      zIndex: 10,
      '& .MuiTypography-root': {
        textAlign: 'center',
      },
    },
  },
  title: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  name: {
    display: 'flex',
    color: '#333',
  },
  name1: {
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 1,
    marginRight: 8,
  },
  name2: {
    fontSize: 13,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'left',
    marginBottom: 2,
    display: 'inline-flex',
    alignItems: 'center',
  },
  title4: {
    fontWeight: 400,
  },
  container: {
    background: '#F3F3F3',
  },
  root: {
    position: 'relative',
    background: '#F3F3F3',
    padding: '16px 16px 8px',
    border: '1px solid #C8C8C8',
    marginBottom: 40,
  },
  button: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 17px',
  },
  box: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 8,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 8,
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrow: {
    fontSize: 16,
    color: theme.palette.primaryColor,
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 'auto',
    padding: '16px',
    '& .MuiSvgIcon-root': {
      boxSizing: 'border-box',
      width: '16px',
      height: '24px',
    },
  },
  status: {
    textAlign: 'center',
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '1200!important',
    },
  },
  tableCell: {
    [commonTheme.breakpoints.up('md')]: {
      padding: 0,
      border: 'none',
      display: 'flex',
    },
  },
}));

function ProspectsCustomerContainers(props) {
  const { localData, setLocalData } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  const [message, setMessage] = useState('');

  const getNowDate = () => {
    const date = new Date();
    const Y = date.getFullYear();
    const M = ('00' + (date.getMonth() + 1)).slice(-2);
    const D = ('00' + date.getDate()).slice(-2);
    const h = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);
    const s = ('00' + date.getSeconds()).slice(-2);
    const nowDate = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s;
    return nowDate;
  };
  const [dataAt, setDataAt] = useState(getNowDate());
  // GET
  const getCustomerFunction = async (id) => {
    await customerCallApi([id])
      .then((res) => {
        setLocalData({ ...localData, customerData: res.data.customers[0] });
        setDataAt(getNowDate());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // PUT Update Customer
  const customerUpdateFunction = async (customerId, modifyObj) => {
    await CustomerUpdateApi(customerId, modifyObj)
      .then(async (res) => {
        dispatch(updateGotDateTime({ customerId, gotDataTime: res.data.updatedAt }));
        setMessage(res.message);
        if (res.data.updatedAt) {
          setDataAt(res.data.updatedAt);
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  // 更新処理
  const [count, setCount] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const updateInitial = {
    updateType: 0,
    getDataAt: dataAt,
    isNotCheckUpdate: 0,
  };
  const [updateData, setUpdateData] = useState(updateInitial);

  const updateCustomer = (id) => {
    // 顧客IDを設定
    setCustomerId(id);
    setCount(count + 1);
  };

  // 項目保存時の別ユーザーによる項目更新と差分比較
  const handleClick1 = () => {
    console.log('編集画面に戻る');
  };
  const handleClick2 = () => {
    console.log('上書き保存する');
    setUpdateData({ ...updateData, isNotCheckUpdate: 1 });
    setCount(count + 1);
  };
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '別のユーザーによって更新されています。',
      msgList: ['上書き保存しますか？'],
      buttons: [
        {
          label: '編集画面に戻る',
          set: handleClick1,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: '上書き保存する',
          set: handleClick2,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  useEffect(() => {
    if (count > 0) {
      // 更新
      customerUpdateFunction(customerId, updateData);
    }
  }, [count]);

  useEffect(() => {
    console.log(message);
    if (message === 'すでに顧客情報が変更されています。') {
      // 確認ウインドウ
      confirmOpen();
    } else {
      // 初期化
      setUpdateData(updateInitial);
    }
    setMessage('');
  }, [message]);

  useEffect(() => {
    // 初期化
    setUpdateData(updateInitial);
  }, [dataAt]);

  const getComponent = (data) => {
    switch (data.type) {
      case 'select':
        return (
          <CustomMainSelect initial={data} />
        );
      case 'selectBlank':
        return (
          <CustomBlankSelect initial={data} />
        );
      case 'selectBack':
        return (
          <CustomBackSelect initial={data} />
        );
      case 'selectMulti':
        return (
          <CustomMainSelectMulti initial={data} />
        );
      case 'selectPlaceholder':
        return (
          <CustomMainSelectPlaceholder initial={data} />
        );
      default:
        return <div>対応しない要素です</div>;
    }
  };
  // SP ボタン
  const getParts = (data) => {
    switch (data.type1) {
      case 'button':
        return (
          <ListBtn
            click={data.click}
            lbl={data.lbl}
            fontSize={data.fontSize}
            val={data.val}
          />
        );
      case 'multiButton':
        return (
          <ListMultiButton
            click1={data.click1}
            click2={data.click2}
            lbl={data.lbl}
            fontSize={data.fontSize}
            val1={data.val1}
            val2={data.val2}
          />
        );
      case 'checkbox':
        return (
          <ListCheckbox
            control={data.content()}
            lbl={data.lbl}
          />
        );
      default:
        return <span style={{ display: 'none' }}>該当なし</span>;
    }
  };
  const getEditDialog = (data) => {
    switch (data.type2) {
      case 'text':
        return (
          <EditDialog1
            classes={classes}
            open={data.opn}
            onClose={data.onClose}
            content={data.content}
            set={data.decision}
            lbl={data.lbl}
          />
        );
      case 'select':
        return (
          <EditDialog2
            classes={classes}
            open={data.opn}
            onClose={data.onClose}
            content={data.content}
            lbl={data.lbl}
          />
        );
      case 'multiSelect':
        return (
          <Grid>
            <EditDialog2
              classes={classes}
              open={data.opn1}
              onClose={data.onClose1}
              content={data.content1}
              lbl={data.lbl}
            />
            <EditDialog2
              classes={classes}
              open={data.opn2}
              onClose={data.onClose2}
              content={data.content2}
              lbl={data.lbl}
            />
          </Grid>
        );
      default:
        return (
          <span style={{ display: 'none' }}>該当なし</span>
        );
    }
  };
  useEffect(() => {
    if (localData.customerId) {
      getCustomerFunction(localData.customerId);
    }
  }, [localData.customerId]);

  // ページ更新
  const updatePage = () => {
    getCustomerFunction(localData.customerId);
  };

  return (
    <DraggableDialog
      open={localData.customer}
      onClose={() => {
        return setLocalData({
          ...localData,
          customer: false,
          customerId: 0,
          customerData: {},
        });
      }}
    >
      {Object.keys(localData.customerData).length ? (
        <CustomerMainItem
          customer={localData.customerData}
          updateData={updateData}
          setUpdateData={setUpdateData}
          updateInitial={updateInitial}
          updateCustomer={updateCustomer}
          getComponent={getComponent}
          getParts={getParts}
          getEditDialog={getEditDialog}
          updatePage={updatePage}
        />
      ) : <LoadingEllipsis /> }
    </DraggableDialog>
  );
}

export default ProspectsCustomerContainers;
