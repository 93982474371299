import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';
import { URL_MAPPER } from '../../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 16,
      padding: '0 24px',
    },
  },
  button1: {
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 0,
    boxShadow: 'none',
    minWidth: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      color: '#333',
      display: 'block',
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
      textAlign: 'left',
      fontSize: 16,
      padding: 16,
      lineHeight: 1.2,
    },
    [commonTheme.breakpoints.up('md')]: {
      color: theme.palette.primaryColor,
      padding: '5px 4px',
      margin: '0 8px',
      lineHeight: 1.6,
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  button2: {
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    boxShadow: 'none',
    minWidth: 'auto',
    display: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      color: '#333',
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
      textAlign: 'left',
      fontSize: 16,
      padding: 16,
      lineHeight: 1.2,
    },
    [commonTheme.breakpoints.up('md')]: {
      color: theme.palette.primaryColor,
      padding: '5px 4px',
      margin: '0 8px',
      lineHeight: 1.6,
    },
    '&.active': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
      },
      [commonTheme.breakpoints.up('md')]: {
        display: 'inline-flex',
      },
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  button3: {
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    boxShadow: 'none',
    minWidth: 'auto',
    display: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      color: '#333',
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
      textAlign: 'left',
      fontSize: 16,
      padding: 16,
      lineHeight: 1.2,
    },
    [commonTheme.breakpoints.up('md')]: {
      color: '#D83420',
      padding: '5px 4px',
      margin: '0 8px',
      lineHeight: 1.6,
    },
    '&.active': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
      },
      [commonTheme.breakpoints.up('md')]: {
        display: 'inline-flex',
      },
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  button4: {
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      fontFamily: 'Roboto',
      fontWeight: 700,
      letterSpacing: 0,
      boxShadow: 'none',
      minWidth: 'auto',
      color: '#333',
      display: 'block',
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
      textAlign: 'left',
      fontSize: 16,
      padding: 16,
      lineHeight: 1.2,
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function InfoRegisterButton(props) {
  const {
    onClose,
    reserveFlg,
    handleOpenHistory,
    deleteGuidanceById,
    actionScheduleFlg,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const actionScheduleHandler = () => {
    if (actionScheduleFlg) {
      onClose();
    } else {
      navigate(URL_MAPPER.schedule);
    }
  };

  const confirmChancel = () => {
    deleteGuidanceById(1);
  };

  const confirmDelete = () => {
    deleteGuidanceById(2);
  };

  const confirmOpen1 = () => {
    dispatch(changeConfirmMessage({
      title: 'キャンセルしますか？',
      msgList: [],
      buttons: [
        {
          label: 'CANCEL',
          set: () => { console.log('CANCEL'); },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: confirmChancel,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const confirmOpen2 = () => {
    dispatch(changeConfirmMessage({
      title: '削除しますか？',
      msgList: [],
      buttons: [
        {
          label: 'CANCEL',
          set: () => { console.log('CANCEL'); },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: confirmDelete,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const actionScheduleBtn = () => {
    if (isSp) {
      return (
        <Button
          variant="contained"
          className={classes.button1}
          onClick={() => actionScheduleHandler()}
          startIcon={<DateRangeOutlinedIcon />}
        >
          <span className={classes.buttonTxt}>行動予定表を表示</span>
        </Button>
      );
    } else if (actionScheduleFlg) {
      return (
        <Button
          variant="contained"
          className={classes.button1}
          onClick={() => onClose()}
          startIcon={<DateRangeOutlinedIcon />}
        >
          <span className={classes.buttonTxt}>行動予定表を表示</span>
        </Button>
      );
    } else {
      return (
        <Link
          href={URL_MAPPER.schedule}
          target="_blank"
          className={classes.button1}
        >
          <span className={classes.buttonTxt}>行動予定表を表示</span>
        </Link>
      );
    }
  };

  return (
    <Grid className={classes.root}>
      {actionScheduleBtn()}
      <Button
        variant="contained"
        className={classes.button4}
        onClick={handleOpenHistory}
      >
        <span className={classes.buttonTxt}>接触履歴</span>
      </Button>
      <Button
        variant="contained"
        className={`${classes.button2} ${reserveFlg && 'active'}`}
        startIcon={<CloseIcon />}
        onClick={confirmOpen1}
      >
        <span className={classes.buttonTxt}>予約キャンセル</span>
      </Button>
      <Button
        variant="contained"
        className={`${classes.button3} ${reserveFlg && 'active'}`}
        startIcon={<DeleteOutlineOutlinedIcon />}
        onClick={confirmOpen2}
      >
        <span className={classes.buttonTxt}>削除（間違え登録など）</span>
      </Button>
    </Grid>
  );
}

export default InfoRegisterButton;
