import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoGetInfoSlice = createSlice({
  name: 'hpinfoGetInfo',
  initialState: { hpinfoGetInfo: initialState },
  reducers: {
    hpinfoGetInfoApi: (state, data) => {
      state.hpinfoGetInfo = data.payload;
    },
  },
});

export const { hpinfoGetInfoApi } = hpinfoGetInfoSlice.actions;

export default hpinfoGetInfoSlice.reducer;
