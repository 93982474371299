import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import SelectProduct from '../../../pages/selectProduct/selectProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #C8C8C8',
      background: '#fff',
      padding: '8px 8px 7px',
    },
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 0,
    width: '100%',
    textAlign: 'left',
    minHeight: 20,
    '& .MuiButton-label': {
      display: 'block',
    },
    '&:hover': { backgroundColor: `${theme.palette.primaryColor}29` },
  },
  txt: {
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
  },
  // SP
  list: {
    background: '#fff',
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
}));

function InfoRegisterChoice3(props) {
  const { values } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [product, setProduct] = useState('');

  // SP・PCでの分岐用
  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);

  let contents;

  useEffect(() => {
    if (product.siteId) {
      values.setState([{
        productName: product.productName,
        siteId: product.siteId,
        isScheduleGuidance: 1,
      }]);
    }
    if (product.productType === '' && product.productName === '') {
      values.setState([{
        productName: '',
        siteId: '',
        isScheduleGuidance: 0,
      }]);
    }
  }, [product, setProduct]);

  if (isSP) {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={handleOpen}
            >
              <Typography className={classes.listTtl}>
                {values.label}
                {values.required && <span>＊</span>}
              </Typography>
              <Typography className={classes.listTxt}>
                {values.state.length > 0 && values.state[0].siteId ? (
                  <span key={values.state[0].suggestProductId}>
                    {values.state[0].productName}
                  </span>
                ) : ''}
              </Typography>
            </Button>
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <Typography className={classes.ttl}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <Button
          className={classes.btn}
          onClick={handleOpen}
        >
          {values.state.length > 0 && (
            <Typography key={values.state[0].suggestProductId} className={classes.txt}>
              {values.state[0].productName}
            </Typography>
          )}
        </Button>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      {open
        && (
        <SelectProduct
          open={open}
          setOpen={setOpen}
          onClose={handleClose}
          product={product}
          setProduct={setProduct}
        />
        )}
    </Grid>
  );
}

export default InfoRegisterChoice3;
