import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoGetContentsHistoriesSlice = createSlice({
  name: 'hpinfoGetContentsHistories',
  initialState: { hpinfoGetContentsHistories: initialState },
  reducers: {
    hpinfoGetContentsHistoriesApi: (state, data) => {
      state.hpinfoGetContentsHistories = data.payload;
    },
  },
});

export const { hpinfoGetContentsHistoriesApi } = hpinfoGetContentsHistoriesSlice.actions;

export default hpinfoGetContentsHistoriesSlice.reducer;
