import client from '../httpClient';

/**
 * @typedef { import(
 *   './getActGuidancesApiTypes'
 * ).ActGuidancesGetResponse } GuidanceGetResponse
 * @type { (query: { userId?: number; divisionId: number; }) => Promise<GuidanceGetResponse> }
 */

const getGuidanceApi = async (params) => {
  const res = await client.get('/actions/guidances/today', { params });
  return res;
};

export default getGuidanceApi;
