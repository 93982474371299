import React, {
  useMemo,
  // useEffect
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  //   ListItemIcon,
} from '@material-ui/core';
// import { Remove, Add, Folder, Person } from '@material-ui/icons';
// import commonStyles from '../../../styles';
// import UserSortNameTreeItem from './userSortNameTreeItem';
import { Tree, TREE_TYPES } from '../../tree';
import { createTreeList } from '../commonFunc/sortNameHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    minHeight: '30%',
    overflow: 'auto',
  },
  parent: {
    position: 'relative',
    padding: 0,
    maxHeight: 'unset',
    '&::after': {
      content: '""',
      height: 'calc(100% - 25px)',
      position: 'absolute',
      backgroundColor: '#c8c8c8',
      width: '1px',
      left: '7.5px',
      top: '25px',
    },
  },
  child: {
    padding: '0 0 0 20px',
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
  },
  li: {
    padding: 0,
    zIndex: 1,
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  treeUser: {
    padding: '0px',
    margin: '0px',
    fontSize: '12px',
  },
  checkedUser: {
    background: 'linear-gradient(90deg, #A7F1FF 0%, #C2EEFF 100%)',
  },
}));
function UserSortNameTree(props) {
  // const common = commonStyles();
  const classes = useStyles();
  const {
    list,
    setSelectUser,
    // searchObj,
    searchKeyword,
  } = props;

  // TreeのValueのみ複製
  const treeList = useMemo(() => {
    return createTreeList(list, searchKeyword);
  }, [list, searchKeyword]);

  // 開閉用ローカルステート
  // const [state, setState] = useState({});
  // 選択状態保持
  // const [checked, setChecked] = useState(0);
  // 検索Enter時のfocus
  // const [searchIndex, setSearchIndex] = useState(0);
  // 検索結果の元を保持
  // const [initialSearch, setInitialSearch] = useState('');

  // const handleClick = (menu) => {
  //   setState({ ...state, [`${menu}Open`]: !state[`${menu}Open`] });
  // };

  const handleSelectUser = (item) => {
    setSelectUser({
      userId: item.userId,
      userName: item.fullName,
    });
    // setChecked(item.userId);
  };

  const onItemClick = (item) => {
    handleSelectUser(item);
  };

  // ツリーの検索
  // useEffect(() => {
  //   if (searchObj) {
  //     if (searchObj instanceof Array) {
  //       const searchWord = searchObj.pop();
  //       const count = searchObj.pop();
  //       let targetIndex = searchIndex;
  //       setInitialSearch(searchWord);
  //       // 二回目以降のEnter
  //       if (initialSearch === searchWord || !targetIndex) {
  //         targetIndex += 1;
  //         setSearchIndex(targetIndex);
  //       } else {
  //         targetIndex = 0;
  //         setSearchIndex(0);
  //       }
  //       for (const head of searchObj) {
  //         if (head instanceof Object) {
  //           if (head.userIds.length >= targetIndex && count >= targetIndex) {
  //             setState((prev) => ({
  //               ...prev,
  //               [`root${head.headChar}Open`]: true,
  //             }));
  //             setChecked(head.userIds[targetIndex - 1]);
  //             setSelectUser({ userId: head.userIds[targetIndex - 1], userName: '' });
  //             break;
  //           } else if (targetIndex > count) {
  //             targetIndex = count > 1 ? 1 : 0;
  //             setSearchIndex(targetIndex);
  //             setState((prev) => ({
  //               ...prev,
  //               [`root${searchObj[0].headChar}Open`]: true,
  //             }));
  //             setChecked(searchObj[0].userIds[0]);
  //             setSelectUser({ userId: searchObj[0].userIds[0], userName: '' });
  //             break;
  //           } else {
  //             targetIndex -= head.userIds.length;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, [searchObj]);

  return (
    <Grid id="user-tree-list-parent" className={`${classes.root}`}>
      <Tree
        list={treeList}
        onChange={onItemClick}
        defaultOpenTypes={searchKeyword ? [TREE_TYPES.division] : []}
      />
    </Grid>
  );
}

export default UserSortNameTree;
