import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ContactTotalHeader from './parts/contactTotalHeader';
// eslint-disable-next-line import/no-cycle
import ContactTotalGuidance from './parts/contactTotalGuidance';
import commonTheme from '../../styles/theme';
import commonStyles from '../../styles';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      height: 'calc(100% - 48px)',
      padding: '0 8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      paddingTop: 102,
      paddingBottom: 160,
      minHeight: 'calc(100vh - 60px)',
    },
  },
});

function ContactTotal(props) {
  const { guidancesObj, contactObjs, totalTime, customer } = props;

  const classes = useStyles();
  const common = commonStyles();
  const { hasLoading } = useLoading();

  return (
    <Grid className={`${classes.root} ${hasLoading('contactTotal') ? common.loading : ''}`}>
      <ContactTotalHeader
        contactObjs={contactObjs}
        totalTime={totalTime}
      />
      <ContactTotalGuidance guidances={guidancesObj.guidances} customer={customer} />
    </Grid>
  );
}

export default ContactTotal;
