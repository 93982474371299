import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Dialog, TextField, makeStyles } from '@material-ui/core';
import SmsSendContainer from '../../../containers/sms/smsSendContainers';
import CommonDialog from '../../common/modal';
import SmsHeader from './smsTalkWindowHeader';
import SmsHeaderSp from './sp/smsTalkWindowHeader';
import commonTheme from '../../styles/theme';
import commonStyles from '../../styles';
import SmsTalkBody from './smsTalkBody';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles({
  wrap: {
    [commonTheme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      flexWrap: 'nowrap',
    },
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      padding: 0,
      maxWidth: 800,
    },
  },
  content: {
    padding: 0,
    [commonTheme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 150px)',
    },
  },
  header: {
    padding: '32px 32px 16px',
  },
  body: {
    [commonTheme.breakpoints.up('md')]: {
      borderTop: 'none',
      marginTop: '-20px',
      overflow: 'hidden',
    },
  },
  nofooter: {
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px',
    padding: '18px',
  },
  input: {
    flexGrow: 1,
    [commonTheme.breakpoints.up('md')]: {
      marginRight: '8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      '& input': {
        fontSize: '13px',
      },
      '& > .MuiInput-root::before': { border: 'none' },
    },
  },
});

function SmsFooter(props) {
  const {
    // eslint-disable-next-line max-len
    customer, sendParams, setSendParams, functions, postFlg, setPostFlg, setTalk, talk,
  } = props;

  const classes = useStyles();
  const [text, setText] = useState('');
  const responseHeader = useSelector((state) => state.responseHeader);
  const onChangeFunc = (e) => {
    setText(e.target.value);
  };
  const onBlur = () => {
    const parameter = {
      userId: responseHeader.userId, // 送信者（ログインユーザー）のuserId
      divisionId: responseHeader.mainDivision.id, // 送信者（ユーザー）のdivisionId
      lastName: customer.lastName, // 送信者（ログインユーザー）のlastName
      firstName: customer.firstName, // 送信者（ログインユーザー）のfirstName
      smstext: text, // SMS本文
      waitreturnsms: 9999,
      type: 'sms',
      userName: responseHeader.userName,
      status: 1,
      returnsms: 1,
    };
    setSendParams({ ...sendParams, ...parameter });
  };
  const onSended = () => {
    setText('');
  };

  return (
    <Grid className={classes.footer} container>
      <TextField
        value={text}
        onChange={onChangeFunc}
        onBlur={() => { onBlur(); }}
        className={classes.input}
        placeholder="メッセージ"
        multiline
        maxRows={8}
      />
      <SmsSendContainer
        text={text}
        onSended={onSended}
        functions={functions}
        sendParams={sendParams}
        setSendParams={setSendParams}
        setPostFlg={setPostFlg}
        postFlg={postFlg}
        setTalk={setTalk}
        talk={talk}
      />
    </Grid>
  );
}

const windowWidth = window.innerWidth;
const breakPoint = 768;
const isSP = (windowWidth < breakPoint);

export default function SmsTalkWindow(props) {
  // eslint-disable-next-line max-len
  const { customer, talk, setTalk, sendParams, setSendParams, functions, postFlg, setPostFlg } = props;
  const { hasLoading } = useLoading();
  const classes = useStyles();
  const common = commonStyles();
  // 名前state(globalStateからAPIを更新するときにもこれ使う予定)
  const [nameState, setNameState] = useState('');
  // メモstate(globalStateからAPIを更新するときにもこれ使う予定)
  const [memoState, setMemoState] = useState('');
  const updateFunction = async (name) => {
    // sms006で【smsCustomerTelId】がnullの場合、新規追加。
    // すでにid値が設定されている場合は、更新。

    // 引数(name)が渡ってきている場合はそちらを優先、渡ってきていなければnameStateをsmsCustomerNameに設定する
    let smsCustomerName = nameState;
    if (name) {
      smsCustomerName = name;
    }
    const updateParams = {
      smsCustomerName,
      memo: memoState,
    };
    if (talk.talkData.smsCustomerTelId !== null) {
      // PUT
      functions.updateSmsCustomer(
        talk.talkData.smsCustomerTelId,
        talk.talkData.customerId,
        { ...updateParams, phoneNumber: talk.talkData.phoneNumber },
      );
    } else {
      const postParams = {
        customerId: talk.talkData.customerId,
        phoneNumber: talk.talkData.phoneNumber,
        smsCustomerName: nameState,
        memo: memoState,
      };
      const assignParams = { ...updateParams, ...postParams };
      // POST
      await functions.createSmsCustomer(assignParams);
    }
  };

  useEffect(() => {
    setNameState(talk.talkData.customerName);
    setMemoState(talk.talkData.memo);
  }, [talk]);

  if (isSP) {
    return (
      <Dialog
        open={talk.open}
        onClose={talk.onCloseFunc}
        scroll="paper"
        className={`${common.maxWidthDialog} ${classes.dialog}`}
      >
        <Grid container direction="column" className={`${classes.wrap} ${!hasLoading() ? 'isShow' : ''}`}>
          <SmsHeaderSp
            data={talk}
            customer={customer}
            nameState={nameState}
            setNameState={setNameState}
            memoState={memoState}
            setMemoState={setMemoState}
            updateFunction={updateFunction}
          />
          <SmsTalkBody />
          {Number(talk.talkData.isDeletedPhoneNumber) === 0
            ? (
              <SmsFooter
                customer={customer}
                functions={functions}
                sendParams={sendParams}
                setSendParams={setSendParams}
                setPostFlg={setPostFlg}
                postFlg={postFlg}
                setTalk={setTalk}
                talk={talk}
              />
            )
            : <div className={classes.nofooter}>登録抹消された電話番号です</div>}
        </Grid>
      </Dialog>
    );
  } else {
    return (
      <CommonDialog
        open={talk.open}
        onClose={talk.onCloseFunc}
        width={640}
        noPadding
        headerClassName={classes.header}
        bodyClassName={classes.body}
        // header={}
        footer={Number(talk.talkData.isDeletedPhoneNumber) === 0
          ? (
            <SmsFooter
              customer={customer}
              functions={functions}
              sendParams={sendParams}
              setSendParams={setSendParams}
              setPostFlg={setPostFlg}
              postFlg={postFlg}
              setTalk={setTalk}
              talk={talk}
            />
          )
          : <div className={classes.nofooter}>登録抹消された電話番号です</div>}
        acceptEnter
      >
        <SmsHeader
          data={talk.talkData}
          customer={customer}
          nameState={nameState}
          setNameState={setNameState}
          memoState={memoState}
          setMemoState={setMemoState}
          updateFunction={updateFunction}
        />
        <SmsTalkBody />
      </CommonDialog>
    );
  }
}
