import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  subGrid: {
    marginTop: '32px',
    textAlign: 'center',
    '& > .MuiButton-root': {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
}));

export default function InstructionsWindowTextField(props) {
  const {
    values,
    setDisabled,
    notFocus,
  } = props;

  const classes = useStyles();

  const handleChange = (e) => {
    switch (e.target.value) {
      case '':
        setDisabled(true);
        break;
      default:
        setDisabled(false);
        break;
    }
  };

  return (
    <TextField
      defaultValue={values.state}
      onChange={handleChange}
      onBlur={(e) => { return values.setState(e.target.value); }}
      name={values.label}
      className={classes.textField}
      autoFocus={!notFocus}
    />
  );
}
