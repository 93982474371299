const convertCustomerData = (webResGetResponses) => {
  // コメントがついてるものがフォームで使われているもの
  const customerData = {
    customerId: webResGetResponses.customerId, //
    lastName: webResGetResponses.customerLastName, //
    firstName: webResGetResponses.customerFirstName, //
    lastNameKana: webResGetResponses.customerLastNameKana, //
    firstNameKana: webResGetResponses.customerFirstNameKana, //
    genderCode: webResGetResponses.genderCode, //
    birthday: webResGetResponses.birthday, //
    age: webResGetResponses.age, //
    nationality: null, //
    isPermanentResidency: null, //
    subLastName: null,
    subFirstName: null, //
    subLastNameKana: null, //
    subFirstNameKana: null, //
    subGenderCode: null, //
    subBirthday: null, //
    subAge: null, //
    subNationality: null, //
    isSubPermanentResidency: null, //
    relation: null, //
    holidayWeekday: webResGetResponses.holidayWeekday, //
    otherHolidayWeekday: webResGetResponses.holidayWeekdayMemo, //
    convenientTime: webResGetResponses.telTime, //
    otherConvenientTime: webResGetResponses.otherTelTime, //
    phoneNumber1: webResGetResponses.phoneNumber1, //
    phoneNumber2: webResGetResponses.phoneNumber2, //
    phoneNumber3: null, //
    phoneNumber4: null, //
    phoneNumberMemo1: null, //
    phoneNumberMemo2: null, //
    phoneNumberMemo3: null, //
    phoneNumberMemo4: null, //
    fax: webResGetResponses.fax, //
    mail1: webResGetResponses.mail1, //
    mail2: webResGetResponses.mail2, //
    mail3: null, //
    mail4: null, //
    mailMemo1: null, //
    mailMemo2: null, //
    mailMemo3: null, //
    mailMemo4: null, //
    isMailSend1: null,
    isMailSend2: null,
    isMailSend3: null,
    isMailSend4: null,
    zip: webResGetResponses.zip, //
    prefecture: webResGetResponses.prefecture, //
    address1: webResGetResponses.address1, //
    address2: webResGetResponses.address2, //
    building: webResGetResponses.building, //
    adultCount: webResGetResponses.adultCount, //
    childCount: webResGetResponses.childCount, //
    familyTotalCount: webResGetResponses.familyCount, //
    familyWho: null, //
    childAge: null, //
    otherChildAge: null, //
    company: webResGetResponses.company, //
    companyRailway: null,
    companyStation: null,
    companyWorkingYear: null, //
    companyAddress: null, //
    jobType: webResGetResponses.jobType, //
    subCompany: webResGetResponses.subCompany, //
    subCompanyRailway: null,
    subCompanyStation: null,
    subCompanyWorkingYear: null, //
    subCompanyAddress: null, //
    subJobType: null, //
    annualIncome: webResGetResponses.annualIncome, //
    subAnnualIncome: webResGetResponses.subAnnualIncome, //
    deposit: webResGetResponses.deposit, //
    monthlyPayment: webResGetResponses.monthlyPayment, //
    bonusPayment: webResGetResponses.bonusPayment, //
    assistAmount: null, //
    telStatusCode: null, //
    emailStatusCode: null, //
    summary: null, //
    memo: webResGetResponses.memo, //
    leaderMemo: null, //
    userLevel: null, //
    customerIntroductionStatus: null,
    responseId: webResGetResponses.responseId,
    wishArea: webResGetResponses.wishArea, //
    wishRailwayId1: webResGetResponses.railwayId, //
    wishRailway1: webResGetResponses.railwayName, //
    wishStationIdFrom1: webResGetResponses.stationId, //
    wishStationFrom1: webResGetResponses.stationName, //
    wishStationIdTo1: null, //
    wishStationTo1: null, //
    wishRailwayId2: null, //
    wishRailway2: null, //
    wishRailwayAreaCodeId2: null,
    wishStationIdFrom2: null, //
    wishStationFrom2: null, //
    wishStationIdTo2: null, //
    wishStationTo2: null, //
    wishSchoolDistrict1: webResGetResponses.wishSchoolDistrict1, //
    wishSchoolDistrict2: webResGetResponses.wishSchoolDistrict2, //
    isThinkApartment: webResGetResponses.isThinkApartment, //
    detachedHouseBudgetMin: webResGetResponses.houseBudgetMin, //
    detachedHouseBudgetMax: webResGetResponses.houseBudgetMax, //
    landBudgetMin: webResGetResponses.landBudgetMin, //
    landBudgetMax: webResGetResponses.landBudgetMax, //
    livingSpace: webResGetResponses.wishLivingSpace, //
    floorPlanCode: webResGetResponses.floorPlanCode, //
    wishLandArea: null,
    importantPoint: null, //
    reason: webResGetResponses.reason, //
    wishMoveInDay: webResGetResponses.wishMoveInDay, //
    purchasePurpose: null, //
    isThinkWholeHouseInvestment: null, //
    isThinkSale: null, //
    isThinkDivisionInvestment: null, //
    isThinkRebuilding: null, //
    checkedProductType: null, //
    checkedProductCount: null, //
    abodeNowBuildingType: null, //
    abodeNowFloorPlanCode: null, //
    abodeNowLivingSpace: null, //
    abodeNowPriceAtTime: null, //
    abodeNowSalePrice: null, //
    abodeNowResidencePeriod: null, //
    abodeNowRent: webResGetResponses.nowRent, //
    abodeNowReplacementCode: null, //
    abodeNowReplaceType: null,
    abodeNowCurrentMaturity: null, //
    abodeNowBankName: null, //
    abodeNowBorrowingAmount: null, //
    abodeNowCarType: null,
    abodeNowCarCount: null,
    isCarPark: null, //
    responseAt: null, //
    largeResponseTypeId: webResGetResponses.largeResponseTypeId,
    mediumResponseTypeId: webResGetResponses.mediumResponseTypeId,
    smallResponseTypeId: webResGetResponses.smallResponseTypeId,
    fineResponseTypeId: webResGetResponses.fineResponseTypeId, //
    acquisitionCode: webResGetResponses.acquisitionCode,
    isNoCustomer: null, //
    reasonKnowOh: null, //
    noContactDetailReasonCode: null,
    reasonNotBuy: null,
    searchStartAt: null,
    reasonNotSearch: null,
    searchArea: null,
    reasonNotOhd: null,
    driveUrl1: null, //
    driveUrl2: null, //
    driveUrl3: null, //
    userId: null, //
    divisionId: null,
    effectAt: null,
    userLastName: webResGetResponses.acquisitionUserFirstName, //
    userFirstName: webResGetResponses.acquisitionUserLastName, //
    divisionSubName: null,
    acquisitionId: webResGetResponses.acquisitionUserId, //
    acquisitionName: webResGetResponses.acquisitionUserFirstName //
    + webResGetResponses.acquisitionUserLastName,
    guidanceStartAt: null,
    guidanceScheduleId: null,
    guidanceStatusCode: null,
    directId: null,
    directStatusCode: null,
    expireAt: null,
    webs: [
      {
        memberId: webResGetResponses.isWebMemberId, //
        memberStatusCode: null,
        lastAccessAt: null,
        isMain: null,
      },
    ],
    largeResponseTypeName: webResGetResponses.largeResponseTypeName, //
    mediumResponseTypeName: webResGetResponses.mediumResponseTypeName, //
    smallResponseTypeName: webResGetResponses.smallResponseTypeName, //
    fineResponseTypeName: webResGetResponses.fineResponseTypeName, //
    staticsTels: [],
    isShared: null,
    isContract: null,
    customerStatusCode: null, //
    workingStyleCode: null, //
    subWorkingStyleCode: null, //
    abodeNowPurchaseAt: null, //
  };
  return customerData;
};

export default convertCustomerData;
