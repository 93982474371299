import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  findItemsByDefaultSelected,
  splitUniqueId,
  findItemsBySearch,
  isSameTreeItem,
  isDefaultSelectedChanged,
  checkedAllChildren,
  uncheckedAllChildren,
} from './helpers';
import { isArray } from '../../../commonFunction';
import { getTreeItemByDivisionId } from '../userTreeNew/commonFunc/helpers';

const Context = createContext({});

export function TreeContextProvider(props) {
  const {
    options,
    list, // [{id: number, parentId: number, name: string, parentName: string}]
    defaultSelected, // [{id: 11, type: ''}]
    defaultOpenTypes,
    search, // string | {keyword: string, type: string}
    onChange,
    checkbox,
    accordion,
    children,
    multiple,
    rootRef,
    treeListRef,
    multipleInitialUser,
    isSearch,
    searchHeader,
    displayCustomer,
    rerenderDivisionIds,
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [defaultOpenUniqueIds, setDefaultOpenUniqueIds] = useState([]);

  const isMultipleSearch = useRef([]);

  const collapsedHandlerRef = useRef([]);
  const isCollapse = useRef(false);

  const defaultSelectedRef = useRef(null);
  const isClickedRef = useRef(false); // ツリー操作時に自動スクロールを実行させないためのフラグ
  const selectedDivision = useRef(0);
  // Clickされた時は [ 検索時 & ツリー作成時 ] のuseEffectにてsetSelectedItemsされないようにする
  const isNotSearch = useRef(false);

  // 検索時に何番目のuniqueIdを取得したのかを保持するオブジェクト
  const selectedUniqueIdsIndexGroupRef = useRef({});

  // 一番上の階層の組織のIDを返す division5 and division12 -> [division5, division12]
  const level1UniqueIds = useMemo(() => {
    return list.map((item) => item.uniqueId);
  }, [list]);

  // ツリーを開く
  const addCollapseLisenter = useCallback((item, fn) => {
    if (level1UniqueIds.includes(item.uniqueId)) {
      collapsedHandlerRef.current.push(fn);
    }
  }, [level1UniqueIds]);

  // ツリーを閉じる
  const removeCollapseLisenter = useCallback((fn) => {
    const index = collapsedHandlerRef.current.indexOf(fn);
    if (index !== -1) collapsedHandlerRef.current.splice(index, 1);
  }, []);

  // 検索時の対象UniqueID取得
  const selectedUniqueIds = useMemo(() => {
    return selectedItems.map((item) => item.uniqueId);
  }, [selectedItems]);

  // 複数選択の検索時対象UniqueId取得
  const selectedIsMultipleSearch = useMemo(() => {
    // eslint-disable-next-line max-len
    if (isArray(isMultipleSearch.current)) {
      return isMultipleSearch.current.map((item) => item.uniqueId);
    } else {
      return [];
    }
  }, [isMultipleSearch.current]);

  // 複数選択の検索時のCheckは付けずにフォーカス判定リストをリセット
  const resetIsMultipleSearch = () => {
    isMultipleSearch.current = [];
  };

  // 検索時 & ツリー作成時、以下走る
  useEffect(() => {
    if (!defaultSelected?.length) return;
    // default選択肢のIDとTreeListの中から同IDを持つ要素をarrに格納
    const defaultChoiceArr = findItemsByDefaultSelected(defaultSelected, list);
    if (defaultChoiceArr.length) {
      // ユーザー所属営業センターのツリーを開く
      const resultChoiceArr = [];
      // デフォルトの値を加工 from: [division1, division3] -> to: [division1, division1-division3]
      defaultChoiceArr.forEach((item) => {
        resultChoiceArr.push(...splitUniqueId(item.uniqueId, false, true));
      });
      setDefaultOpenUniqueIds(resultChoiceArr);
    }
    // defaultSelectedにあるけど、listに存在しないデータは、そのままでarrに入れる
    defaultSelected.forEach((item) => {
      if (!defaultChoiceArr.some((v) => isSameTreeItem(item, v))) {
        defaultChoiceArr.push(item);
      }
    });
    // defaultSelectedが変更された場合のみ
    if (!isDefaultSelectedChanged(defaultSelectedRef.current, defaultSelected)) {
      // Tabが変更される時listが変わるため、selectedItemsチェックを追加する
      selectedItems.forEach((item) => {
        if (!defaultChoiceArr.some((v) => isSameTreeItem(item, v))) {
          defaultChoiceArr.push(item);
        }
      });
    }
    // defaultSelectedを保存して、次のレンダリングでチェック用
    defaultSelectedRef.current = defaultSelected;
    if (!multiple && defaultChoiceArr.length > 0) {
      if (!isNotSearch.current) {
        // eslint-disable-next-line max-len
        if (Object.keys(defaultChoiceArr[0]).length > 0) {
          setSelectedItems([defaultChoiceArr[defaultChoiceArr.length - 1]]);
        }
      }
    }
    isNotSearch.current = false;
    if (multiple) isMultipleSearch.current = defaultChoiceArr;
  }, [defaultSelected, list]);

  useEffect(() => {
    const arr = findItemsBySearch(search, list);
    const ids = arr.map((item) => splitUniqueId(item.uniqueId));
    setDefaultOpenUniqueIds(Array.from(new Set(ids.flat())));
  }, [search]);

  useEffect(() => {
    if (multipleInitialUser?.length > 0) setSelectedItems(multipleInitialUser);
  }, [multipleInitialUser]);

  const onItemClick = useCallback((target) => {
    // Checkboxの場合、onCheckboxChangeで処理する
    if (checkbox) return;

    // 複数選択パータン
    if (multiple) {
      const arr = [...selectedItems];
      const index = arr.findIndex((item) => isSameTreeItem(target, item));
      if (index === -1) {
        arr.push(target);
      } else {
        arr.splice(index, 1);
      }
      setSelectedItems(arr);
      onChange(arr);
    } else if (target.uniqueId !== selectedItems[0]?.uniqueId) {
      setSelectedItems([target]);
      onChange(target);
    }
    isNotSearch.current = true;
    isClickedRef.current = true;
  }, [multiple, checkbox, selectedItems]);

  /**
   * @param { Boolean } isOpen - 開閉State
   * @param { Object } item - ツリーひとつひとつの要素
   * @param { Function } fn - 開閉Function
   */
  const onCollapsedIconClick = (isOpen, item) => {
    isClickedRef.current = true;
    if (
      // accordionがtrueではない
      !accordion
      // 該当TreeがOpenではない
      || !isOpen
      // レベル1のTreeではない
      || !level1UniqueIds.includes(item.uniqueId)) return;
    collapsedHandlerRef.current.forEach((fn) => fn(item));
  };

  // checkboxパータンの場合
  const onCheckboxChange = useCallback((e, target) => {
    const { checked } = e.target;
    let arr;
    if (checked) {
      // 各要素にparentの全要素をValueとして持たせる？重すぎるか・・・
      // setSelectedItemsで親のuniqueId入れ込む？
      arr = checkedAllChildren(target, selectedItems);
    } else {
      const uncheckedArr = uncheckedAllChildren(target, selectedItems, list);
      // 親が存在する場合は、親のチェックも外す
      arr = target.parentId ? uncheckedArr.filter(i => target.parentId !== i.id) : uncheckedArr;
    }
    setSelectedItems(arr);
    onChange(arr, checked);
    isClickedRef.current = true;
  }, [selectedItems, list]);
  // デフォルト値にカーソルを合わせる処理
  useEffect(() => {
    const uniqueIds = multiple ? selectedIsMultipleSearch : selectedUniqueIds;

    if (uniqueIds.length === 0
      || !rootRef
      || !treeListRef
      || !uniqueIds[0]
      || isClickedRef.current
    ) return;
    let targetId;
    let targetIdx;
    const tmp = uniqueIds[0].match(/[a-zA-Z]+\d+$/);
    if (!tmp) return;
    const selectedId = tmp[0];
    if (uniqueIds.length === 1
      || !(selectedId in selectedUniqueIdsIndexGroupRef.current)
      || selectedUniqueIdsIndexGroupRef.current[selectedId] === uniqueIds.length - 1
    ) {
      [targetId] = uniqueIds;
      targetIdx = 0;
    } else {
      targetIdx = selectedUniqueIdsIndexGroupRef.current[selectedId] + 1;
      targetId = uniqueIds[targetIdx];
    }
    const rootElement = rootRef.current;
    const observeElement = treeListRef.current;
    const targetElement = observeElement.querySelector(`#${targetId}`);
    if (!targetElement || !rootElement) return;

    const scrollToTarget = () => {
      const targetRect = targetElement.getBoundingClientRect();
      const rootRect = rootElement.getBoundingClientRect();
      rootElement.scrollBy(0, targetRect.y - rootRect.y - 100); // 検索boxなどと被らないようにぴったりにしない
      const scrolledTargetRect = targetElement.getBoundingClientRect();
      return rootRect.top > scrolledTargetRect.top;
    };

    // クラスが変更しない場合があるので、その対策として一度実行。
    if (scrollToTarget()) {
      selectedUniqueIdsIndexGroupRef.current[selectedId] = targetIdx;
      return;
    }

    // 画面外であれば監視する。
    const observerHandler = (mutations, observer) => {
      if (isClickedRef.current) { return; }
      mutations.forEach(() => {
        if (scrollToTarget()) {
          selectedUniqueIdsIndexGroupRef.current[selectedId] = targetIdx;
          observer.disconnect();
        }
      });
    };
    const observer = new MutationObserver(observerHandler);
    const observeOption = { attributeFilter: ['class'], subtree: true };
    let parentId;
    targetId.split('_').forEach((str, idx) => {
      parentId = idx === 0 ? str : `${parentId}-${str}`;
      if (parentId === targetId) return;
      const parentElement = observeElement.querySelector(`#${parentId}_collapse`);
      if (parentElement) observer.observe(parentElement, observeOption);
    });
    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [selectedUniqueIds, selectedIsMultipleSearch, multiple]);

  // 検索実行時に再度自動スクロールが走るように初期化
  useEffect(() => {
    isClickedRef.current = false;
    isCollapse.current = false;
    return () => {
      isClickedRef.current = false;
    };
  }, [defaultSelected]);

  useEffect(() => {
    if (searchHeader?.divisionId !== selectedDivision.current) {
      setSelectedItems([]);
      selectedDivision.current = searchHeader?.divisionId;
    }
  }, [searchHeader]);

  useEffect(() => {
    if (!rerenderDivisionIds?.length) return;
    let items = [];
    for (const divId of rerenderDivisionIds) {
      const divisionsInfo = getTreeItemByDivisionId(list, divId);
      if (divisionsInfo) items = [...items, ...checkedAllChildren(divisionsInfo, null)];
    }
    setSelectedItems(items);
  }, [list]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    options,
    selectedUniqueIds,
    defaultOpenUniqueIds,
    defaultOpenTypes,
    onItemClick,
    onCollapsedIconClick,
    addCollapseLisenter,
    removeCollapseLisenter,
    checkbox,
    onCheckboxChange,
    selectedIsMultipleSearch,
    resetIsMultipleSearch,
    isCollapse,
    multiple,
    isSearch,
    searchHeader,
    displayCustomer,
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}

export function useTreeContext() {
  return useContext(Context);
}
