import { createContext, useContext, useMemo } from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import { classNames } from '../../../commonFunction';

const context = createContext({});

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 16,
    marginBottom: 5,
  },
  inline: {
    display: 'inline-flex',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontSize: '0.8125rem',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    fontSize: '0.875rem',
  },
  column: {
    flexDirection: 'column',
    '& > .content': {
      flex: 'inherit',
      alignItems: 'flex-start',
    },
  },
  blod: {
    fontWeight: 700,
  },
  required: {
    color: '#D83420',
  },
  box: {
    border: '1px solid #C8C8C8',
    padding: 8,
    background: '#fff',
  },
});

export function LabelWrap(props) {
  const {
    label,
    labelWidth,
    children,
    className,
    style,
    labelStyle,
    labelClassName,
    contentStyle,
    contentClassName,
    column,
    thin,
    required,
    gap,
    // label text position
    right,
    center,
    middle,
    bottom,
    top,
    left,
    offsetTop,
    // box
    box,
    padding,
    inline,
  } = props;

  const parent = useContext(context);

  const classes = useStyles();

  const styles = useMemo(() => {
    const data = {
      ...style,
    };
    if (typeof padding !== 'undefined') {
      style.padding = padding;
    }
    if (parent.spacing) {
      style.marginBottom = parent.spacing;
    }
    return data;
  }, [
    style,
    padding,
    parent.spacing,
  ]);

  const labelStyles = useMemo(() => {
    const data = {
      ...labelStyle,
      width: labelWidth || parent.labelWidth,
    };
    if (data.width && !column) data.flex = `0 0 ${data.width}`;

    if (right || parent.right || center || parent.center) {
      const val = center || parent.center ? 'center' : 'flex-end';
      if (column) {
        data.alignItems = val;
      } else {
        data.justifyContent = val;
      }
    }
    if (middle || parent.middle || bottom || parent.bottom) {
      const val = middle || parent.middle ? 'center' : 'flex-end';
      if (column) {
        data.justifyContent = val;
      } else {
        data.alignItems = val;
      }
    }
    if (top || parent.top || left || parent.left) {
      const val = 'flex-start';
      if (column) {
        data.justifyContent = val;
      } else {
        data.alignItems = val;
      }
    }
    if (offsetTop) {
      data.paddingTop = offsetTop;
    }
    return data;
  }, [
    labelStyle,
    labelWidth,
    parent,
    column,
    right,
    center,
    middle,
    bottom,
    top,
    left,
    offsetTop,
  ]);

  const contentStyles = useMemo(() => {
    const data = {
      ...contentStyle,
    };
    const space = gap || parent.gap || 0;
    if (column) {
      data.marginTop = space;
    } else {
      data.marginLeft = space;
    }
    return data;
  }, [contentStyle, gap, parent.gap, column]);

  return (
    <Grid
      className={classNames(
        classes.root,
        {
          [classes.column]: column,
          [classes.box]: box,
          [classes.inline]: inline,
        },
        className,
      )}
      style={styles}
    >
      {label && (
        <Grid
          className={classNames(
            classes.label,
            thin ? null : classes.blod,
            labelClassName,
          )}
          style={labelStyles}
        >
          {label} {required && <span className={classes.required}>＊</span>}
        </Grid>
      )}
      <Grid
        className={classNames(
          classes.content,
          'content',
          contentClassName,
        )}
        style={contentStyles}
      >
        { children }
      </Grid>
    </Grid>
  );
}

export function LabelWrapGroup(props) {
  const { children } = props;

  return (
    <context.Provider value={props}>
      {children}
    </context.Provider>
  );
}
