import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
  sideMenuList,
} from '../../../constants/customerMain';
// eslint-disable-next-line import/no-cycle
import TaiouRireki from '../../common/taiouRireki';
import InstructionReport from '../../common/instructionReport';
// eslint-disable-next-line import/no-cycle
import ContactTotal from '../../common/contactTotal';
import InfoChangeHistory from '../../common/infoChangeHistory';
import ResHouseContainers from '../../../containers/common/resHouse/resHouseContainers';
import { useCustomerMain } from '../../../containers/customerMain';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 399,
    border: '1px solid rgba(51, 51, 51, 0.4)',
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  tabs: {
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      opacity: '1',
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  tabPanel: {
    height: '100%',
    '& > div': {
      height: '100%',
    },
    '& > div > div': {
      height: '100%',
    },
    '& > div > div > div': {
      height: '100%',
    },
  },
}));

function SideMenuTabs(props) {
  const {
    customer,
  } = props;

  const { functions, rerenderKeySearch, rerenderSearch } = useCustomerMain();

  const { customerId } = customer;

  const classes = useStyles();

  const [value, setValue] = useState(0);
  // 完了アラート制御用 対応履歴
  const [openAlert, setOpenAlert] = useState(false);

  const handleChange = (event, newValue) => setValue(newValue);

  const menusKeys = Object.keys(sideMenuList.menus);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} className={classes.tabs}>
          {menusKeys.map((data, index) => (
            <Tab
              key={data}
              label={sideMenuList.menus[data]}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <TaiouRireki
          customerId={customerId}
          customer={customer}
          open={openAlert}
          setOpenAlert={setOpenAlert}
          getActionHistoryList={functions.getActionHistoryList}
          createHistory={functions.createHistory}
          updateHistory={functions.updateHistory}
          deleteHistory={functions.deleteHistory}
          actionHistory={functions.actionHistory[customerId]}
          downloadAttachment={functions.downloadAttachment}
          search={functions.actionHistorySearch}
          setSearch={functions.setActionHistorySearch}
          rerenderKeySearch={rerenderKeySearch}
          rerenderSearch={rerenderSearch}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <InstructionReport
          customer={customer}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={2}>
        <ResHouseContainers
          customerId={customerId}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={3}>
        <ContactTotal customer={customer} />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={4}>
        <InfoChangeHistory customerId={customerId} />
      </TabPanel>
    </div>
  );
}

export default React.memo(SideMenuTabs);
