import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomTable from '../../../browsingHistory/parts/customTable';

const useStyles = makeStyles({
  tr: {
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  th: {
    position: 'relative',
    color: '#8C8C8C',
    borderBottom: '2px solid #C8C8C8',
    padding: '4px 6px',
    '&:not(:last-child)::after': {
      content: '""',
      width: 1,
      height: '80%',
      background: '#C8C8C8',
      position: 'absolute',
      top: '10%',
      right: 0,
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#8C8C8C',
    },
  },
  td: {
    position: 'relative',
    padding: '4px 6px',
    border: 'none',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    textAlign: 'left',
    letterSpacing: 0,
    '&:not(:last-child)::after': {
      content: '""',
      width: 1,
      height: '80%',
      background: '#C8C8C8',
      position: 'absolute',
      top: '10%',
      right: 0,
    },
  },
});

const headCells = [
  { id: 'responseAt', label: '反響日' },
  { id: 'effectAt', label: '再反基準日' },
  { id: 'customerTypeCode', label: '区分' },
  { id: 'largeResponseTypeName', label: '反響媒体(大)' },
  { id: 'mediumResponseTypeName', label: '反響媒体(中)' },
  { id: 'smallResponseTypeName', label: '反響媒体(小)' },
  { id: 'fineResponseTypeName', label: '反響媒体(細)' },
  { id: 'divisionName', label: '組織' },
  { id: 'sample', label: '担当者名' },
];

export default function ResponseRegisterTable2(props) {
  const {
    rows,
  } = props;

  const classes = useStyles();

  return (
    <CustomTable
      list={rows}
      headCells={headCells}
      divider
      gray
      hidePaging
      sortable
      empty="該当情報は存在しません"
      rowRender={(list) => list.map((row, i) => (
        <TableRow
          hover
          tabIndex={-1}
          key={String(i)}
          className={classes.tr}
        >
          <TableCell className={classes.td}>{row.responseAt}</TableCell>
          <TableCell className={classes.td}>{row.effectAt}</TableCell>
          <TableCell className={classes.td}>{row.responseImportResultCodeName}</TableCell>
          <TableCell className={classes.td}>{row.responseTypeName1}</TableCell>
          <TableCell className={classes.td}>{row.responseTypeName2}</TableCell>
          <TableCell className={classes.td}>{row.responseTypeName3}</TableCell>
          <TableCell className={classes.td}>{row.responseTypeName4}</TableCell>
          <TableCell className={classes.td}>{row.divisionName}</TableCell>
          <TableCell className={classes.td}>
            {row.responsibleUserLastName + row.responsibleUserFirstName}
          </TableCell>
        </TableRow>
      ))}
    />
  );
}
