import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Drawer,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    // zIndex: '1300!important',
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
      maxHeight: 'calc(100vh - 52px)',
      // bottom: 58,
    },
  },
  header: {
    padding: '16px',
    borderBottom: '1px solid #C8C8C8',
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
    '& > p': {
      fontSize: '20px',
    },
  },
  listWrap: {
    padding: '16px 0 40px 16px',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  footer: {
    borderTop: '1px solid #C8C8C8',
    padding: '24px',
  },
  list: {
    padding: 0,
  },
  item: {
    padding: 0,
    '&.Mui-selected': {
      background: 'transparent',
      '& .MuiButton-label': {
        color: theme.palette.primaryColor,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
}));

function CustomSimpleDrawer(props) {
  const { className, open, setOpen, onClose, title, list, foot } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => {
        if (onClose !== undefined) {
          onClose();
        } else {
          setOpen(!open);
        }
      }}
      className={`${classes.drawer} ${className && className}`}
    >
      {/* タイトルが設定されている場合、ヘッダーを表示 */}
      {title !== undefined && (
        <Grid className={classes.header}>
          <Typography className={baseClasses.title3}>{title}</Typography>
        </Grid>
      )}
      {/* 呼び出し元で指定したリスト */}
      <Grid className={classes.listWrap}>{list}</Grid>
      {/* フッター要素があればfootで指定 */}
      {foot !== undefined && (
        <Grid container justifyContent="center" className={classes.footer}>
          {foot.buttons.map((button, index) => (
            <Button
              key={button.label}
              className={`${button.classes} ${classes.footerBtn}`}
              disabled={button.disabled}
              onClick={() => {
                if (button.handleEvent !== undefined) {
                  button.handleEvent();
                }
                if (index === 1) {
                  // 決定
                  if (onClose !== undefined) {
                    onClose();
                  } else {
                    setOpen(!open);
                  }
                } else {
                  // キャンセル
                  setOpen(false);
                }
              }}
            >
              {button.label}
            </Button>
          ))}
        </Grid>
      )}
    </Drawer>
  );
}

export default CustomSimpleDrawer;

export function CustomDrawerList(props) {
  const {
    menus = {},
    strValue = false,
    state,
    setState,
    onClose = () => {},
    single = false,
    isNumber,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const isArray = Array.isArray(state);

  const menuKeys = Object.keys(menus);

  const clickButton = (menuKey) => {
    const tmpMenuKey = strValue || !isNumber ? menuKey : Number(menuKey);
    const setObj = isArray && !single ? [...state, tmpMenuKey] : tmpMenuKey;
    setState(setObj);
    onClose();
  };

  const isJudegeSelected = (menuKey) => {
    return isArray ? state.includes(menuKey) : String(state) === String(menuKey);
  };

  return (
    <List className={classes.list}>
      {menuKeys.map((menuKey) => (
        <ListItem
          key={menuKey}
          className={classes.item}
          selected={isJudegeSelected(menuKey)}
        >
          <Button
            className={`${common.title3} ${classes.btn}`}
            onClick={() => clickButton(menuKey)}
          >
            {menus[menuKey]}
          </Button>
        </ListItem>
      ))}
    </List>
  );
}
