import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const resHouseDeleteSlice = createSlice({
  name: 'resHouseDelete',
  initialState: { resHouseDelete: initialState },
  reducers: {
    resHouseDeleteApi: (state, data) => {
      state.resHouseDelete = data.payload;
    },
  },
});

export const { resHouseDeleteApi } = resHouseDeleteSlice.actions;

export default resHouseDeleteSlice.reducer;
