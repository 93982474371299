import React, { useState, useRef, useCallback } from 'react';
import CustomTable from '../../browsingHistory/parts/customTable';
import Row from './row';
import ConfirmModal from '../../common/modal/confirmModal';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';

const headCells = [
  { id: 'no', label: 'No', alignCenter: true },
  { id: 'targetCityName', label: '選択地域', alignCenter: true, sortable: true },
  { id: 'patternName', label: '組合せ名称', alignCenter: true, sortable: true },
  { id: 'storeGroupNamesPatternTypeCode1', label: '履歴あり登録先店舗G', alignCenter: true, sortable: true },
  { id: 'pricePatternTypeCode2', label: '以上', alignCenter: true, sortable: true },
  { id: 'storeGroupNamesPatternTypeCode2', label: '履歴なし 指定価格以下 登録先店舗G', alignCenter: true, sortable: true },
  { id: 'pricePatternTypeCode3', label: '以下', alignCenter: true, sortable: true },
  { id: 'storeGroupNamesPatternTypeCode3', label: '履歴なし 指定価格以下 登録先店舗G', alignCenter: true, sortable: true },
  { id: 'storeGroupNamesPatternTypeCode4', label: '履歴なし 価格指定なし 登録先店舗G', alignCenter: true, sortable: true },
  { id: 'displayOrder', label: '表示', alignCenter: true, sortable: true },
];

export default function ListTable(props) {
  const { className } = props;
  const {
    list,
    showEditDialog,
    deleteApi,
  } = useCombinationSettingContext();

  // 削除確認モダール表示用のステート
  const [delConfirmVisible, setDelConfirmVisible] = useState(false);
  const currentRowRef = useRef();

  // 削除実行
  const onDelete = useCallback(() => {
    deleteApi({
      patternId: currentRowRef.current.patternId,
      areaCode: currentRowRef.current.areaCode,
    });
    setDelConfirmVisible(false);
  }, [deleteApi]);

  return (
    <>
      <CustomTable
        headCells={headCells}
        list={list}
        empty="該当するデータは存在しません。"
        className={className}
        divider
        hidePaging
      >
        <Row
          onDelete={(e, row) => {
            currentRowRef.current = row;
            setDelConfirmVisible(true);
          }}
          onEdit={(e, row) => showEditDialog(true, row)}
        />
      </CustomTable>
      <ConfirmModal
        title="削除確認"
        open={delConfirmVisible}
        onCancel={() => setDelConfirmVisible(false)}
        content="該当の情報を削除しますか？"
        onSubmit={onDelete}
      />
    </>
  );
}
