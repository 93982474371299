import React, { useState, cloneElement, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import commonStyles from '../../styles';
import { classNames } from '../../../commonFunction';

const dividerStyles = {
  content: '""',
  display: 'block',
  background: '#C8C8C8',
  width: 1,
  height: '88%',
  position: 'absolute',
  top: '6%',
  right: 0,
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    marginBottom: 8,
    overflow: 'hidden',
  },
  box: {
    border: '1px solid #C8C8C8',
    padding: '8px 0',
    '& .MuiTableContainer-root': {
      padding: '0 8px',
    },
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-16px',
    padding: '0 8px',
  },
  control: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 40,
  },
  controlLbl: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginRight: 8,
  },
  tableContainer: {
    overflowY: 'auto',
    // height: 250,
    // padding: '0 8px',
    width: 'calc(100% - 8px)',
    maxHeight: 'calc(100vh - 224px)',
  },
  hidePageCaption: {
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
  },
  tablePagination: {
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-selectRoot': {
      margin: '0 40px 0 0',
      '& .MuiSelect-icon': {
        color: theme.palette.primaryColor,
      },
      '& .MuiSelect-select': {
        '&:focus': {
          background: 'none',
        },
      },
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
    '& .MuiSelect-root': {
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
    '& .MuiIconButton-root': {
      padding: 5,
      color: theme.palette.primaryColor,
      '&.Mui-disabled': {
        color: '#C8C8C8',
      },
      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
  },
  tableHead: {
    position: 'sticky',
    zIndex: '1',
    top: 0,
    background: '#fff',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      borderBottom: '2px solid #8C8C8C',
    },
  },
  tableCellTh: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.2',
    letterSpacing: '0',
    padding: '4px 16px',
    '& .MuiTableSortLabel-root': {
      padding: 0,
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        fontSize: 12,
        marginRight: 0,
        color: `${theme.palette.primaryColor} !important`,
      },
      '& .MuiTableSortLabel-iconDirectionAsc': {
        transform: 'translateY(-50%) rotate(180deg)',
      },
    },
  },
  tableCellThCenter: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 12px !important',
  },
  divider: {
    '& th:not(:last-child)::after': dividerStyles,
    '& td': {
      position: 'relative',
      '&:not(:last-child)::after': dividerStyles,
    },
  },
  tableSortLabel: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  // themeはgray系で表示する
  gray: {
    '& .MuiTableCell-head': {
      color: '#8C8C8C',
    },
    '& thead::after': {
      borderColor: '#C8C8C8',
    },
  },
  // 該当情報が存在しない場合、表示内容のスタイル
  empty: {
    padding: '1em',
    fontSize: '13px',
    color: '#8C8C8C',
  },
  button: {
    fontSize: 12,
    padding: 4,
    minWidth: 48,
    margin: '6px 0 0 auto',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    headCells,
    orderBy,
    onRequestSort,
    sortable,
  } = props;

  const baseClasses = commonStyles();

  const createSortHandler = useCallback((property) => (event) => {
    onRequestSort(event, property);
  }, [onRequestSort]);

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classNames(
              baseClasses.title4,
              classes.tableCellTh,
              headCell.className,
            )}
          >
            {headCell.sortable || sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classNames(
                  classes.tableSortLabel,
                  {
                    [classes.tableCellThCenter]: headCell.alignCenter || headCell.style?.textAlign === 'center',
                  },
                )}
                style={headCell.style}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <Grid
                className={classNames(
                  {
                    [classes.tableCellThCenter]: headCell.alignCenter || headCell.style?.textAlign === 'center',
                  },
                )}
              >
                {headCell.label}
              </Grid>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

/**
 * CustomTable
 * @param {{
 * title?: string;
 * headCells: {
 *   id: string;
 *   label: string;
 *   sortable?: boolean;
 *   alignCenter?: boolean;
 *   style?: CSSProperties;
 * }[];
 * list: *[];
 * total?: number;
 * onPageChange: (params: {offset: number; limit: number}, newPage: number) => void;
 * rowRender: (list: *[]) => ReactNode[];
 * divider?: boolean;
 * gray?: boolean;
 * contentHeight?: number;
 * sortable?: boolean;
 * hideHeader?: boolean;
 * hidePaging?: boolean;
 * noBorder?: boolean;
 * className?: string;
 * style?: CSSProperties;
 * empty?: string | ReactNode;
 * }} props
 * @returns `ReactNode`
 */
export default function CustomTable(props) {
  const {
    title, // テーブルのタイトル
    headCells = [], // テーブルのヘッダー情報配列
    list = [], // テーブルのデータ配列
    total = 0, // データ件数
    onPageChange, // ページャー、または表示行数が変更した際発火関数
    rowRender, // テーブルの行レンダリング関数
    divider = false, // テーブルの行内の要素間のディバイダ
    gray = false, // gray color
    contentHeight, // テーブルのコンテンツの高さ
    // テーブルの全てキーがソートできるか
    // 一部のキーがソートされたい場合には、headCellsに指定してください
    sortable = false,
    // コンポーネントのヘッダーとborderボックスを非表示するか
    hideHeader = false,
    hidePaging = false, // 表示行数とページャー情報のみを非表示する
    noBorder = false, // borderボックスのみを非表示する
    className,
    style,
    empty = null, // 情報は存在しない時に表示する文言
    propLimit = '',
    setPropLimit = '',
    rowsPerPageOptions = [20, 40, 60],
    labelDisplayedRows, // https://mui.com/material-ui/api/table-pagination/
    showPageCaption = false,
    children, // childrenのpropsに{row: list[index], index: number}が追加される
    isNoSlice = false, // slice制御をしない
    handleOpenModal = null,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('expireAt');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(propLimit);

  const handleRequestSort = useCallback((event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [order, orderBy]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
    onPageChange?.({
      limit,
      offset: limit * newPage,
    }, newPage);
  }, [limit]);

  const handleChangeRowsPerPage = useCallback((event) => {
    const value = parseInt(event.target.value, 10);
    setLimit(value);
    if (setPropLimit) { setPropLimit(value); }
    setPage(0);
    onPageChange({
      limit: value,
      offset: 0,
    });
  }, []);

  // 行レンダリング処理
  const rows = useMemo(() => {
    let renderList = stableSort(list, getComparator(order, orderBy));
    if (!hidePaging && !isNoSlice) {
      renderList = renderList.slice(page * limit, (page + 1) * limit);
    }
    if (children) {
      return renderList.map((item, index) => {
        return cloneElement(children, {
          ...children.props,
          key: index,
          row: item,
          index,
        });
      });
    } else if (rowRender) {
      return rowRender(renderList);
    }
    return null;
  }, [list, order, orderBy, page, limit, children]);

  return (
    <div
      className={classNames(
        classes.root,
        hideHeader || noBorder ? null : classes.box,
        divider ? classes.divider : null,
        gray ? classes.gray : null,
        className,
        showPageCaption || typeof labelDisplayedRows === 'function' ? null : classes.hidePageCaption,
      )}
      style={style}
    >
      <Paper className={classes.paper}>
        {hideHeader ? null : (
          <Grid className={!hidePaging ? classes.header : ''}>
            {title && <Typography className={baseClasses.title3}>{title}</Typography>}
            {hidePaging ? null : (
              <Grid className={classes.control}>
                {rowsPerPageOptions.length ? (
                  <Typography className={classes.controlLbl}>表示行数</Typography>
                ) : null}
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={total}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className={classes.tablePagination}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
                />
              </Grid>
            )}
            {handleOpenModal && (
              <Button
                onClick={handleOpenModal}
                className={`${classes.button} ${baseClasses.buttonsPrimary}`}
              >
                拡大
              </Button>
            )}
          </Grid>
        )}
        <TableContainer
          className={classes.tableContainer}
          style={{ height: contentHeight }}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              sortable={sortable}
            />
            <TableBody>
              {rows}
            </TableBody>
          </Table>
          { !list.length && empty ? <Grid className={classes.empty}>{empty}</Grid> : null }
        </TableContainer>
      </Paper>
    </div>
  );
}
