import { makeStyles } from '@material-ui/core';

const layoutStyle = makeStyles({
  /*
  * コンテンツの幅指定
  */
  inner: {
    maxWidth: 1200,
    margin: '0 16',
  },
  /*
  * パーツ同士の間隔調整などで使用
  */
  // タイトル下のセレクトボックスなどを囲ったブロック
  gridS: {
    '& > *:not(:last-child)': {
      marginRight: 8,
    },
  },
  /*
  * コンテンツのスクロール用クラス(PC)
  */
  // 3行ブロック（タイトル・コンテンツ）
  scrollToRow2: {
    overflowY: 'auto',
    height: 'calc(100vh - 109px)',
  },
  // 3行ブロック（タイトル・コンテンツ・フッター）
  scrollToRow3: {
    overflowY: 'auto',
    height: 'calc(100vh - 179px)',
  },
});

export default layoutStyle;
