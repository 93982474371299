import { Button, Grid, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useState } from 'react';
import UserTree from '../../common/userTreeNew';
import commonStyles from '../../styles';
import gensenStyle from './gensenRegisterStyles';

export default function AddUser(props) {
  const { onClose, local, setLocal } = props;
  const gCommon = gensenStyle();
  const common = commonStyles();
  const [selectUser, setSelectUser] = useState({
    userId: local.responsibleUserId,
    userName: local.responsibleUserName,
  });

  const onClick = () => {
    setLocal({
      ...local,
      responsibleUserId: selectUser.userId,
      responsibleUserName: selectUser.userName,
    });
    onClose();
  };

  return (
    <Grid className={gCommon.root}>
      <Grid className={common.header}>
        <Typography className={`${common.title2} ${gCommon.title}`}>担当者選択</Typography>
        <Close className={gCommon.closeIcon} onClick={onClose} />
      </Grid>
      <UserTree
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={[]}
        setSelectDivision={() => {}}
        isSelectUserWindow
      />
      <Grid container justifyContent="center" style={{ marginTop: 32 }}>
        <Button
          className={common.buttonsPrimary}
          onClick={onClick}
        >
          選択
        </Button>
      </Grid>
    </Grid>
  );
}
