import { useState, useEffect } from 'react';
import ResponseType from '.';
import CommonDialog from '../../common/modal';

function ResponseTypeModal(props) {
  const {
    open,
    onClose,
    state,
    setState,
    onSubmit,
    responseClearCount,
    isSearch = false, // 反響媒体の検索かどうか
  } = props;
  const [responseList, setResponseList] = useState(state);

  useEffect(() => {
    setResponseList(state);
  }, []);

  // 反響媒体のクリア処理 本コンポーネントより上位のstate(res)、下位のstate(responseList)ともクリアする
  useEffect(() => {
    if (responseClearCount > 0) {
      setResponseList([]);
      setState([]);
    }
  }, [responseClearCount]);

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="反響媒体選択ウィンドウ"
      width="90%"
      buttons={{
        label: '選択',
        onClick: (e) => {
          onClose(e);
          onSubmit?.(e);
          setState(responseList);
        },
      }}
    >
      <ResponseType
        state={responseList}
        setState={setResponseList}
        isSearch={isSearch}
      />
    </CommonDialog>
  );
}

export default ResponseTypeModal;
