import { createSlice } from '@reduxjs/toolkit';

const initialLibrary = {
  libraryGetSpecifyDirctory: [],
  totalCount: 0,
};

export const libraryGetSpecifyDirctorySlice = createSlice({
  name: 'libraryGetSpecifyDirctory',
  initialState: initialLibrary,
  reducers: {
    getLibraryGetSpecifyDirctory: (state, data) => {
      if (Array.isArray(data.payload) && data.payload.length === 1) {
        // PCのlibrary008のresponseを格納
        state.totalCount = data.payload[0].total;
        state.libraryGetSpecifyDirctory = data.payload[0].libraries;
      } else {
        // update: 2023/03/22
        // SPのlibrary002のresponseを格納
        state.totalCount = data.payload.total;
        state.libraryGetSpecifyDirctory = data.payload.libraries;
      }
    },
    insertLibraries: (state, { payload }) => {
      const { libraries: insertLibraries = [], total } = payload;
      state.totalCount = total;
      state.libraryGetSpecifyDirctory = [
        ...state.libraryGetSpecifyDirctory,
        ...insertLibraries,
      ];
    },
    initializeLibraries: (state) => {
      state.libraryGetSpecifyDirctory = initialLibrary.libraryGetSpecifyDirctory;
      state.totalCount = initialLibrary.totalCount;
    },
  },
});

export const {
  getLibraryGetSpecifyDirctory,
  insertLibraries,
  initializeLibraries,
} = libraryGetSpecifyDirctorySlice.actions;

export default libraryGetSpecifyDirctorySlice.reducer;
