import { isObject, isArray } from '../../../../commonFunction';

function handleUsers(parent, users, parentUniqueId, options) {
  const type = 'user';
  const arr = [];
  let uniqueId;
  let name;
  users.forEach((item) => {
    uniqueId = `${parentUniqueId}_${type}${item.userId}`;
    name = item.userLastName + item.userFirstName;
    arr.push({
      ...item,
      id: item.userId,
      name,
      type,
      parentId: parent.id,
      parentName: parent.name,
      parentType: parent.type,
      label: `${name}(${item.responseCount})`,
      selectable: options.userFlg,
      uniqueId,
      children: [],
    });
  });
  return arr;
}

function formatDivisionChildren(parent, { children, users }, parentUniqueId, options) {
  const arr = [];
  if (isArray(children) && children.length) {
    // eslint-disable-next-line no-use-before-define
    arr.push(...createTreeList(children, parentUniqueId, options, parent));
  }
  if (isArray(users) && users.length) {
    arr.push(...handleUsers(parent, users, parentUniqueId, options));
  }
  return arr;
}

const DEF_OPTIONS = {
  divisionFlg: true,
  userFlg: true,
};

export function createTreeList(list, parentUniqueId = '', options = DEF_OPTIONS, parent = {}) {
  const type = 'division';
  // パラメーターチェック
  if (isObject(parentUniqueId)) {
    options = parentUniqueId;
    parentUniqueId = '';
  }
  const arr = [];

  let uniqueId;

  list.forEach((item) => {
    uniqueId = `${parentUniqueId}${parentUniqueId ? '_' : ''}${type}${item.divisionId}`;
    // 組織
    arr.push({
      label: `${item.subName}(${item.responseCount})`,
      type,
      id: item.divisionId,
      name: item.subName,
      parentId: parent.id,
      parentName: parent.name,
      parentType: parent.type,
      selectable: options.divisionFlg,
      uniqueId,
      children: formatDivisionChildren({
        type,
        id: item.divisionId,
        name: item.subName,
      }, item, uniqueId, options),
    });
  });
  return arr;
}

// 選択された組織/ユーザー配下の全ての顧客IDを取得
export function getAllFlyer(targetObj) {
  const arr = [];
  const getResponseIds = (target) => {
    if (target.responseId) {
      arr.push({
        ...target,
        customerName: target.lastName + ' ' + target.firstName,
      });
      return;
    }
    if (target.responses?.length) {
      target.responses.forEach(getResponseIds);
      return;
    }
    if (target.children?.length) {
      target.children.forEach(getResponseIds);
    }
  };
  getResponseIds(targetObj);
  return arr;
}

/**
 * findItemsByUserId
 * @param {*} userId
 * @param {*} list
 * @param {*} arr
 * @returns {Array<*>}
 */
export function findItemsByUserId(userId, list, arr) {
  arr = arr || [];
  if (!userId || !isArray(list)) return arr;
  let item;
  for (let i = 0; i < list.length; i += 1) {
    item = list[i];
    if (item.type === 'user' && userId === item.id) {
      arr.push(item);
    }
    findItemsByUserId(userId, item.children, arr);
  }
  return arr;
}
