import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const allocationSettingDeleteSlice = createSlice({
  name: 'allocationSettingDelete',
  initialState: { allocationSettingDelete: initialState },
  reducers: {
    allocationSettingDeleteApi: (state, data) => {
      console.log({ data });
      state.allocationSettingDelete = data.payload;
    },
  },
});

export const { allocationSettingDeleteApi } = allocationSettingDeleteSlice.actions;

export default allocationSettingDeleteSlice.reducer;
