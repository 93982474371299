import { useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import layoutStyle from '../../styles/layout';
import DivisionHistoryRow from './parts/divisionHistoryRow';
import DivisionHistorySearch from './parts/divisionHistorySearch';
import CustomTablePagination from '../../eleCommon/customTablePagenation';
import CustomSelect from '../../eleCommon/customSelect';
import { AREA_CODE_HISTORY } from '../../../constants/index';

const useStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginLeft: 8,
    },
  },
  content: {
    display: 'block',
    marginTop: 16,
  },
  box: {
    padding: 8,
  },
  th: {
    color: '#8C8C8C',
    background: '#fff',
    padding: '6px 4px',
    borderBottom: '1px solid #C8C8C8',
  },
  control: {
    marginBottom: 10,
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
    '& .MuiTablePagination-selectRoot': {
      display: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
      minHeight: 'auto',
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  selectContent: {
    background: '#fff',
    margin: '0 0 16px',
    padding: 16,
  },
  selectBox: {
    display: 'flex',
    flexGrow: 1,
    '& .MuiSelect-selectMenu': {
      padding: 0,
      width: '100%',
    },
    '& > .MuiFormControl-root': {
      width: '90%',
    },
    '& .MuiInput-formControl': {
      minWidth: 'auto',
    },
  },
  buttons: {
    display: 'flex',
    margin: '0 0 10px 24px',
  },
  button: {
    width: 263,
    marginRight: 8,
    minHeight: 'auto',
    padding: 4,
    '& .MuiButton-label': {
      lineHeight: 1.6,
      fontSize: 13,
    },
  },
  txt: {
    fontSize: 15,
    lineHeight: '160px',
    letterSpacing: 0,
    textAlign: 'center',
  },
});

function EnhancedTableHead(props) {
  const {
    classes,
  } = props;

  const baseClasses = commonStyles();
  const [headCells] = useState({
    cells: [
      { id: 'no', label: 'NO.' },
      { id: 'applyingAt', label: '取り込み日' },
      { id: 'userId', label: '社員ID' },
      { id: 'userFullName', label: '社員名' },
      { id: 'oldPositionName', label: '旧役割' },
      { id: 'newPositionName', label: '新役割' },
      { id: 'oldGradeName', label: '旧役職' },
      { id: 'newGradeName', label: '新役職' },
      { id: 'oldUserRoleGroupName', label: '旧権限' },
      { id: 'newUserRoleGroupName', label: '新権限' },
      { id: 'mainDivisionName', label: '組織区分' },
      { id: 'oldDivisionName', label: '旧組織' },
      { id: 'newDivisionName', label: '新組織' },
      { id: 'isNewManager', label: '新人MGR' },
      { id: 'isCustomerMove', label: '顧客移動' },
    ],
  });

  const getHeader = () => {
    return (
      headCells.cells.map((headCell) => (
        <TableCell
          key={headCell.id}
          className={`${baseClasses.title4} ${classes.th}`}
        >
          {headCell.label}
        </TableCell>
      ))
    );
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {getHeader()}
      </TableRow>
    </TableHead>
  );
}

export default function DivisionHistory(props) {
  const { functions, data } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const lCommon = layoutStyle();

  const [open, setOpen] = useState(false);

  const areaData = {
    type: 'select',
    obj: {
      menus: AREA_CODE_HISTORY,
    },
    state: data.searchDisplay.areaCode,
    set: functions.selectChangeArea,
    name: 'areaCode',
  };

  const getTable = () => {
    return (
      <Box className={baseClasses.box}>
        <Grid className={classes.control}>
          <CustomTablePagination
            rowsPerPageOptions={[20, 40, 60]}
            rowsPerPage={data.rowsPerPage}
            page={data.page}
            setPage={data.setPage}
            setRowsPerPage={data.setRowsPerPage}
            totalCount={data.userSearchList.total}
          />
        </Grid>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
            />
            <TableBody id="instructionListTablebody">
              {data.userSearchList.users
                .map((row, index) => {
                  return (
                    <DivisionHistoryRow
                      key={row.userId}
                      row={row}
                      index={index}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const getNone = () => {
    return (
      <Box className={baseClasses.box}>
        <Typography className={classes.txt}>
          対象が存在しません。
        </Typography>
      </Box>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid className={baseClasses.header}>
        <Grid className={lCommon.inner}>
          <Typography className={baseClasses.title2}>
            組織情報一括更新_履歴
          </Typography>
        </Grid>
      </Grid>
      <Grid className={`${baseClasses.body} ${lCommon.scrollToRow2}`}>
        <Grid container className={lCommon.inner}>
          <Grid container className={classes.content}>
            <Grid className={classes.selectContent} container>
              <Grid className={`${classes.selectBox} ${baseClasses.selectBox}`}>
                <Typography className={`${baseClasses.title4} ${classes.areaLbl}`}>
                  エリア
                </Typography>
                <CustomSelect initial={areaData} />
              </Grid>
              <Grid className={classes.buttons}>
                <Button
                  className={`${baseClasses.buttonsSecondary} ${classes.button}`}
                  onClick={() => { setOpen(true); }}
                  disabled={!data.hasPerMission}
                >
                  詳細検索
                </Button>
                <Button
                  className={`${baseClasses.buttonsPrimary} ${classes.button}`}
                  onClick={functions.searchFunction}
                  disabled={data.disabled || !data.hasPerMission}
                >
                  検索
                </Button>
              </Grid>
            </Grid>
            {data.userSearchList.total === 0 ? getNone() : getTable()}
          </Grid>
          {open && (
            <DivisionHistorySearch
              open={open}
              onClose={() => { return setOpen(false); }}
              data={data}
              functions={functions}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
