import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  List,
  ListItem,
} from '@material-ui/core';
import { useUpdate } from '../../../containers/customerMain';
import SpEditDialog from './spEditDialog';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    // marginBottom: '8px',
    padding: '4px 8px',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
    '&:not(:last-child)': {
      marginBottom: 6,
    },
  },
  textField: {
    width: '100%',
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
    },
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 4,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'block',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    display: 'block',
    textTransform: 'none',
    marginBottom: 6,
  },
  listVal: {
    display: 'block',
    textTransform: 'none',
    width: '100%',
    pointerEvents: 'none',
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.2,
      fontWeight: '700',
      letterSpacing: 0,
    },
    '& div': {
      width: '100%',
      padding: 0,
    },
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
});

function CustomerLeaderMemo(props) {
  const { input } = props;
  const { current, update, original, isSp, editTargetRef } = useUpdate(input.customer, [
    'customerId',
    'leaderMemo',
  ]);

  const classes = useStyles();

  let contents;

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    if (isSp) return;
    const { name, value } = e.target;
    // 更新処理
    if (original[name] !== value) update({ [name]: value });
  };

  // SP 決定押下時に更新
  const onDecision = (value) => {
    if (original.leaderMemo !== value) {
      update({ leaderMemo: value });
    }
  };

  // モーダル用
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = (e, target) => {
    editTargetRef.current = target;
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  // SP 表示用
  const list = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '上長メモ',
      val: current.leaderMemo,
      keys: 'leaderMemo',
      click: handleOpen1,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <SpEditDialog
          title={editTargetRef.current.lbl}
          open={open1}
          onClose={handleClose1}
          input={editTargetRef.current.val}
          onSubmit={onDecision}
          multiline
          keys="leaderMemo"
          originalValue1={original.leaderMemo}
        />
      </Grid>
    );
  } else {
    contents = (
      <form className={classes.root} noValidate autoComplete="off">
        <TextBaseField
          className={classes.textField}
          defaultValue={current.leaderMemo}
          multiline
          rows="2"
          name="leaderMemo"
          onBlur={onBlur}
          placeholder="上長メモ"
        />
      </form>
    );
  }

  return contents;
}

export default React.memo(CustomerLeaderMemo);
