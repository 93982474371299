import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../../../calendar';
import CustomEditDialog from '../../../../../eleCommon/sp/customEditDialog';
import commonStyles from '../../../../../styles';

const useStyles = makeStyles({
  root: {
    background: '#fff',
  },
  ttl: {
    flexShrink: 0,
    whiteSpace: 'initial',
    '& span': {
      color: '#D83420',
    },
  },
  value: {
    marginTop: 8,
    fontWeight: 'bold',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
    textTransform: 'none',
    borderBottom: '1px solid #F3F3F3',
    borderRadius: 0,
    width: '100%',
    padding: '16px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  calendar: {
    '& div': {
      border: 'none',
      padding: 0,
    },
    '& span': {
      padding: 0,
      fontSize: 13,
      lineHeight: '1.6',
    },
  },
});

function ResisterHouseWindowCalendar(props) {
  const { type, values } = props;
  const [date, setDate] = useState('');
  const classes = useStyles();
  const baseClasses = commonStyles();
  let result;

  useEffect(() => {
    const dt = new Date();
    const y = dt.getFullYear();
    const m = ('00' + (dt.getMonth() + 1)).slice(-2);
    const d = ('00' + dt.getDate()).slice(-2);
    result = y + '/' + m + '/' + d;

    setDate(result);
  }, []);

  // モーダル用
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    console.log('its clidked');
    setOpen(true);
  };
  const setHandleClose = () => {
    // 値のリセット
    values.setState('');
  };

  const getDate = () => {
    switch (values.state) {
      case '':
        if (type === 'new') {
          return date;
        } else {
          return '未設定';
        }
      default:
        break;
    }
    return values.state;
  };

  const data = {
    id: 'id1', // String - labelとinputを紐づけます
    label: '', // String - ラベル
    require: false, // Boolean - 必須かどうか
    type: 'date', // String 'date'|'time'|'multitime'|'multiple'
    state: getDate(), // state
    setState: values.setState,
  };

  const content = (
    <Grid className={classes.calendar}>
      {/* {datalist.map((array) => { */}
      {/* return ( */}
      <Calendar key={data.id} data={data} />
      {/* ); */}
      {/* })} */}
    </Grid>
  );

  return (
    <Grid className={classes.root}>
      <Button className={classes.btn} onClick={handleClick}>
        <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
          {values.label}<span>＊</span>
        </Typography>
        <Typography className={`${baseClasses.title6} ${classes.value}`}>{getDate()}</Typography>
      </Button>

      <CustomEditDialog
        type="button2"
        size="small"
        open={open}
        setOpen={setOpen}
        setHandleClose={setHandleClose}
        content={content}
        lbl={values.label}
      />
    </Grid>
  );
}

export default ResisterHouseWindowCalendar;
