import {
  makeStyles,
  Grid,
} from '@material-ui/core';
import Modal from '../../common/modal';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root': {
      padding: '24px',
      width: '100%',
      marginRight: '16px',
      marginLeft: '16px',
    },
  },
  content: {
    padding: 0,
  },
  ttl: {
    textAlign: 'center',
    padding: 0,
    marginTop: '8px',
    '& .MuiTypography-root': {
      fontWeight: '700',
    },
  },
  button: {
    '&::before, &::after': {
      background: '#fff',
    },
  },
  footer: {
    /* Auto layout */
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px',
  },
});

export default function UnsentDialogConfirmForPC(props) {
  const {
    openDialog,
    setOpenDialog,
    text,
    functions, // update, delete, getのAPIが入ったObject
    targetRow, // 対象mailの全情報
    deleteFlg, // 削除の場合はtrue
    setExclusionMailId, // 今すぐ送信したMailをリストから除外する為必要
    options,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  /**
   * @module postSendMail - 【sendmail006】未送信メール更新
   * @param {Object} targetRow - 選択された対象メールの全内容
   * @param {date} deliveryAt - 今すぐ送信の為、現在時刻
   */
  const postSendMail = async () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = ('00' + (now.getMonth() + 1)).slice(-2);
    const day = ('00' + now.getDate()).slice(-2);
    const hour = ('00' + now.getHours()).slice(-2);
    const min = String(Number(('00' + now.getMinutes()).slice(-2)));
    const sec = String(Number(('00' + now.getSeconds()).slice(-2)));
    const dateInitial = year + '/' + month + '/' + day + ' ' + hour + ':' + min + ':' + sec;

    setOpenDialog(false);
    await functions
      .updateUnSendMail({
        mailQueueId: targetRow.mailQueueId,
        deliveryAt: dateInitial,
      }, options?.current)
      .then((res) => {
        console.log(res);
        setExclusionMailId(targetRow.mailQueueId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * @module deleteSendMail - 【sendmail005】メール送信停止
   * @param {Object} targetRow - 選択された対象メールの全内容
   */
  const deleteSendMail = async () => {
    setOpenDialog(false);
    // tabValue = {'個別': 0, '一斉': 1}
    // mailCancelCodeは個別も一斉もMailQueueIdで削除するので2で決め打ち
    await functions.cancelSendMail({
      mailQueueId: targetRow.mailQueueId,
      mailCancelCode: 2,
    }, options?.current)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={openDialog}
      width={360}
      onClose={() => {
        setOpenDialog(false);
      }}
      title="確認"
      buttons={[{
        onClick: () => { setOpenDialog(false); },
        label: 'CANCEL',
        className: `${baseClasses.buttonsSecondary} ${classes.button}`,
      }, {
        className: `${baseClasses.buttonsPrimary} ${classes.button}`,
        onClick: () => { deleteFlg ? deleteSendMail() : postSendMail(); },
        label: 'OK',
      }]}
    >
      <Grid className={classes.content}>{text}</Grid>
    </Modal>
  );
}
