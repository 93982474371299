import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  DialogContent,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  dialog: {
    zIndex: '1301!important',
    '& .MuiDialog-paper': {
      borderRadius: 12,
      margin: 8,
      maxWidth: 'none',
      width: '100%',
      height: '100vh',
      maxHeight: 'calc(100% - 32px)',
    },
  },
  header: {
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    padding: '19px 0 8px',
  },
  headerTtl: {
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
  body: {
    padding: '16px 0 0 16px',
    overflowX: 'auto',
    '&.gray': {
      background: '#F3F3F3',
      padding: '16px 0',
    },
  },
});

function CustomScrollDialog(props) {
  const { type, color, open, setOpen, lbl, content } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // 文字列の改行
  const brLbl = lbl.split(/(\n)/).map((item) => {
    return (
      <React.Fragment key={item}>
        { item.match(/\n/) ? <br /> : item }
      </React.Fragment>
    );
  });

  return (
    <Dialog
      open={open}
      onClose={() => { setOpen(false); }}
      scroll="paper"
      className={classes.dialog}
    >
      <Grid className={classes.header}>
        <Typography
          className={`${baseClasses.title2} ${classes.headerTtl}`}
        >
          {brLbl}
        </Typography>
        <Button
          className={baseClasses.closeButton}
          onClick={() => { setOpen(false); }}
        />
      </Grid>
      <DialogContent className={`${classes.body} ${type !== undefined && type} ${color !== undefined && color}`} dividers>
        {content}
      </DialogContent>
    </Dialog>
  );
}

export default CustomScrollDialog;
