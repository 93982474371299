import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';

const useStyles = makeStyles({
  button: {
    '&>span': {
      fontSize: 13,
    },
  },
});

function ResetButton(props) {
  const { txt, resetStart } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Button
      className={`${baseClasses.buttonsSecondary} ${classes.button}`}
      type="button"
      onClick={resetStart}
    >
      {txt}
    </Button>
  );
}
export default ResetButton;
