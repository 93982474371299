import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const calendarUploadTSVSlice = createSlice({
  name: 'calendarUploadTSV',
  initialState: { calendarUploadTSV: initialState },
  reducers: {
    calendarUploadTSVApi: (state, data) => {
      state.calendarUploadTSV = data.payload;
    },
  },
});

export const { calendarUploadTSVApi } = calendarUploadTSVSlice.actions;

export default calendarUploadTSVSlice.reducer;
