const positionChange = () => {
  const checkElement = () => {
    const targetElement = document.querySelector('.MuiPopover-root');
    if (targetElement) {
      // 要素が見つかった場合の処理
      const listElement = targetElement.querySelector('.MuiPaper-root');
      if (parseInt(listElement.style.top, 10) < 0) {
        listElement.style.top = '40px';
      }
    } else {
      // 要素が見つからなかった場合は再度setTimeoutを呼び出す
      setTimeout(checkElement, 100);
    }
  };
  checkElement(); // 初回実行
};

export default positionChange;
