// Tab：指示 & 指示者 === CurrentUser
export const REGISTER_TYPE_MENU1_1 = new Map([
  [7, '催促'],
  [5, '取り消し'],
  [6, 'コメント'],
]);

// Tab：指示 & 指示受け者 === CurrentUser
export const REGISTER_TYPE_MENU1_2 = new Map([
  [2, '対応予定'],
  [6, 'コメント'],
  [4, '対応なし'],
  [3, '報告'],
]);

// Tab：報告 & 指示者 === CurrentUser
export const REGISTER_TYPE_MENU2_1 = new Map([
  [8, '承認'],
  [9, 'やり直し'],
  [5, '取り消し'],
]);

// Tab：報告 & 指示受け者 === CurrentUser
export const REGISTER_TYPE_MENU2_2 = new Map([
  [10, '引き戻し'],
]);
