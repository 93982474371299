import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  TextField,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { classNames, isNotPhoneNumber } from '../../../commonFunction';
import { useRerender } from '../../../hooks';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      margin: 0,
      maxWidth: 'none',
      width: '100%',
      height: '100vh',
      maxHeight: 'none',
      borderRadius: 0,
    },
  },
  paper: {
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderRadius: 0,
    padding: 0,
  },
  paperHeader: {
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    padding: '19px 0 8px',
    boxShadow: '0 0 6px rgba(51, 51, 51, 0.1)',
  },
  paperTtl: {
    textAlign: 'center',
  },
  paperBody: {
    padding: '15px 6px 0',
  },
  paperBtn: {
    display: 'block',
    margin: '32px auto 0',
  },
  inputWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0 0',
    [commonTheme.breakpoints.up('md')]: {
      width: '92%',
      lineHeight: '24px',
    },
    '& .MuiTextField-root': {
      margin: '0 10px',
      flex: 1,
    },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: 0,
      transform: 'translate(0, 2px) scale(1)',
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 'var(--font-size)',
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      fontWeight: '500',
      lineHeight: 1.2,
      letterSpacing: 0,
      fontSize: 13,
      '&.Mui-focused': {
        color: theme.palette.primaryColor,
      },
      '&.MuiInputLabel-shrink': {
        // opacity: 0,
      },
    },
  },
  isSingle: {
    '& .MuiTextField-root:last-child': {
      display: 'none',
    },
  },
}));

/**
 * SpEditDialog
 * @param {{
 * open: boolean;
 * title: string;
 * placeholder?: string;
 * onClose?: () => void;
 * input: string | string[];
 * onSubmit: (string | string[]) => void;
 * }} props - props
 * @returns {ReactNode}
 */
function SpEditDialog(props) {
  const {
    open = false,
    onClose,
    children,
    title,
    input,
    type, // input type
    onSubmit,
    placeholder,
    label,
    fontSize = 13,
    style,
    hideButton = false, // 決定ボタンを非表示する
    multiline,
    keys,
    originalValue1,
    originalValue2,
  } = props;
  const { rerender } = useRerender();

  const inputRef = useRef(null);

  const values = useRef({
    count: 0,
    isMultiple: false,
    field1: '',
    field2: '',
  });

  const classes = useStyles();
  const baseClasses = commonStyles();
  const dispatch = useDispatch();

  const placeholders = useMemo(() => {
    return Array.isArray(placeholder) ? placeholder : [placeholder];
  }, [placeholder]);

  const labels = useMemo(() => {
    return Array.isArray(label) ? label : [label];
  }, [label]);

  const styles = useMemo(() => {
    return {
      ...style,
      '--font-size': fontSize + (typeof fontSize === 'number' ? 'px' : ''),
    };
  }, [style, fontSize]);

  useEffect(() => {
    if (!open) return;
    values.current.isMultiple = Array.isArray(input);
    if (values.current.isMultiple) {
      const [a, b] = input;
      values.current.field1 = a;
      values.current.field2 = b;
    } else {
      values.current.field1 = input;
    }
    // 画面再描画
    rerender();
    // focus
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [input, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    values.current[name] = value;
  };

  const [validationError, setValidationError] = useState(false);

  const onBlur = (e) => {
    if (keys === 'phoneNumber') {
      const { value } = e.target;
      const result = isNotPhoneNumber(value);
      setValidationError(result);
    }
  };

  const onClick = () => {
    const { isMultiple, field1, field2 } = values.current;
    if (keys === 'phoneNumber') {
      const result = isNotPhoneNumber(field1);
      setValidationError(result);
      if (!result) {
        onSubmit?.(isMultiple ? [field1, field2] : field1);
        onClose?.();
      }
    } else {
      setValidationError(false);
      onSubmit?.(isMultiple ? [field1, field2] : field1);
      onClose?.();
    }
  };

  const onClickClose = () => {
    const { isMultiple, field1, field2 } = values.current;
    const keysList = ['summary', 'memo', 'leaderMemo'];
    if (originalValue1 !== field1 || (isMultiple && originalValue2 !== field2)) {
      if (keysList.includes(keys)) {
        dispatch(
          changeConfirmMessage({
            title: '保存しますか？',
            msgList: [],
            buttons: [
              {
                label: 'Cancel',
                classes: baseClasses.buttonsSecondary,
                set: () => {
                  setValidationError(false);
                  onClose?.();
                },
              },
              {
                label: 'OK',
                classes: baseClasses.buttonsPrimary,
                set: () => {
                  setValidationError(false);
                  onSubmit?.(isMultiple ? [field1, field2] : field1);
                  onClose?.();
                },
              },
            ],
          }),
        );
      } else {
        setValidationError(false);
        onClose?.();
      }
    } else {
      setValidationError(false);
      onClose?.();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} style={styles}>
      <Grid className={classes.paper}>
        <Grid className={classes.paperHeader}>
          <Typography className={`${baseClasses.title2} ${classes.paperTtl}`}>{title}</Typography>
          {onClose ? <Button className={baseClasses.closeButton} onClick={onClickClose} /> : null}
        </Grid>
        <Grid className={classes.paperBody}>
          {!children ? (
            <Grid
              className={classNames(
                classes.inputWrap,
                {
                  [classes.isSingle]: !values.current.isMultiple,
                },
              )}
            >
              <TextField
                label={labels[0]}
                defaultValue={values.current.field1 ?? ''}
                name="field1"
                onChange={handleChange}
                onBlur={onBlur}
                type={type}
                placeholder={placeholders[0]}
                inputRef={inputRef}
                multiline={multiline}
                inputProps={{ pattern: '^[0-9-]+$' }}
                error={validationError}
                helperText={validationError ? '数字とハイフンのみを入力してください' : ''}
              />
              <TextField
                label={labels[1]}
                defaultValue={values.current.field2 ?? ''}
                name="field2"
                onChange={handleChange}
                type={type}
                placeholder={placeholders[1]}
                multiline={multiline}
              />
            </Grid>
          ) : children}
        </Grid>
        {hideButton ? null : (
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.paperBtn}`}
            onClick={onClick}
          >
            決定
          </Button>
        )}
      </Grid>
    </Dialog>
  );
}

export default SpEditDialog;
