import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../../styles';
import MemberInfoDetail from './memberInfoDetail';
import MemberStatusCode from '../../eleCommon/memberStatusCode';
import MemberChange from './memberChange';
import RelatedMemberWindow from '../../pages/RelatedMemberWindow';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  id: {
    width: 127,
    background: 'rgba(51, 51, 51, 0.04)',
    display: 'flex',
    alignItems: 'center',
    padding: 8,
  },
  memberId: {
    fontSize: 13,
    lineHeight: 1.6,
    textAlign: 'left',
    marginLeft: 4,
  },
  box: {
    width: 873,
    border: '1px solid #C8C8C8',
    padding: '5px 8px',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    borderRadius: 0,
    padding: 0,
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 14,
    },
  },
  button1: {
    margin: '0 18px 0 6px',
  },
  button2: {
    marginLeft: 20,
  },
}));

export default function MemberInfo(props) {
  const {
    customerId,
    data,
    updateStatusChange,
    webs,
    setWebs,
    isQuit,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [state, setState] = useState({
    // 会員情報詳細
    isShowDetailDialog: false,
    // 変更
    isShowChangeStatusDialog: false,
    // 関連WEB会員ID
    isShowRelatedMemberWindow: false,
  });

  const fullName = `${data.lastName ?? ''} ${data.firstName ?? ''}`;

  return (
    <Grid className={classes.root}>
      <Grid className={classes.id}>
        <Typography className={baseClasses.title4}>
          Web会員ID
        </Typography>
        <Typography className={classes.memberId}>
          {data.memberId}
        </Typography>
      </Grid>
      <Grid className={classes.box}>
        <MemberStatusCode
          code={data.memberStatusCode}
        />
        <Button
          className={`${classes.button} ${classes.button1}`}
          onClick={() => {
            setState({
              ...state,
              isShowChangeStatusDialog: true,
            });
          }}
          disabled={isQuit === 1}
        >
          変更
        </Button>
        <MemberChange
          open={state.isShowChangeStatusDialog}
          statusCode={data.memberStatusCode}
          onChange={updateStatusChange}
          onClose={() => {
            setState({
              ...state,
              isShowChangeStatusDialog: false,
            });
          }}
        />
        <Typography className={baseClasses.title2}>
          {fullName}
        </Typography>
        <Button
          className={`${classes.button} ${classes.button2}`}
          startIcon={<Icon className="material-icons-outlined">summarize</Icon>}
          onClick={() => {
            setState({
              ...state,
              isShowDetailDialog: true,
            });
          }}
        >
          会員情報詳細
        </Button>
        <MemberInfoDetail
          open={state.isShowDetailDialog}
          onClose={() => {
            setState({
              ...state,
              isShowDetailDialog: false,
            });
          }}
          detail={data}
        />
        <Button
          href={`${URL_MAPPER.customer}?customerId=${customerId}`}
          target="_blank"
          className={`${classes.button} ${classes.button2}`}
          startIcon={<Icon className="material-icons-outlined">summarize</Icon>}
        >
          顧客メイン
        </Button>
        <Button
          className={`${classes.button} ${classes.button2}`}
          startIcon={<LanguageIcon />}
          onClick={() => {
            setState({
              ...state,
              isShowRelatedMemberWindow: true,
            });
          }}
        >
          関連Web会員ID({webs.length})
        </Button>
      </Grid>
      <RelatedMemberWindow
        open={state.isShowRelatedMemberWindow}
        onClose={() => {
          setState({
            ...state,
            isShowRelatedMemberWindow: false,
          });
        }}
        customerId={customerId}
        list={webs}
        onChange={setWebs}
      />
    </Grid>
  );
}
