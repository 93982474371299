import { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { DeleteOutline } from '@material-ui/icons';
import commonStyles from '../../../styles';
import CustomerShareupdateAdd from '../shareupdateAdd';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';
import NormalTooltip from '../../../styles/tooltip';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    border: '1px #c8c8c8 solid',
    width: '100%',
    padding: '8px',
    overflow: 'hidden',
  },
  delete: {
    color: '#D83420',
  },
  icon: {
    width: '16px',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'scroll',
    maxHeight: '240px',
  },
  checkbox: {
    width: '100%',
    margin: 0,
    '&:nth-child(even)': {
      background: '#f3f3f3',
    },
    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: '8px',
    },
    '& .MuiFormControlLabel-label': {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
  },
});

function CustomerShareUpdateComponent(props) {
  const { customerId, customerList, functions, shareAddDisabledFlg } = props;
  const common = commonStyles();
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [params, setParams] = useState([]);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const userId = e.target.value.split('_')[0];
    const divisionId = e.target.value.split('_')[1];

    if (values.includes(e.target.value)) {
      setValues([...values.filter((item) => item !== e.target.value)]);
      const newListObj = params.filter(s => s.userId !== userId || s.divisionId !== divisionId);
      setParams(newListObj);
    } else {
      setValues([...values.concat(e.target.value)]);
      setParams([...params.concat({
        userId: Number(userId),
        divisionId: Number(divisionId),
      })]);
    }
  };

  const callDelete = () => {
    const deleteParams = {
      customerId,
      deleteTargetIds: params,
    };
    // 削除処理
    functions.deleteCustomerShare(deleteParams);
    // setValues([]);
  };

  const handleDeleteClick = () => {
    dispatch(changeConfirmMessage({
      msgList: ['削除されます'], // 必須
      title: '削除しますか？', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'CANCEL', // ボタンラベル
        set: () => { return false; }, // ボタンをクリックしたときの処理
        classes: common.buttonsSecondary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }, {
        label: 'OK', // ボタンラベル
        set: callDelete, // ボタンをクリックしたときの処理
        classes: common.buttonsPrimary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
    }));
  };

  useEffect(() => {
    setValues([]);
  }, [customerList]);

  const normalTooltipTitle = shareAddDisabledFlg ? '担当無し顧客は共有できません' : '';
  return (
    <NormalTooltip title={normalTooltipTitle}>
      <div className={classes.root}>
        <CustomerShareupdateAdd customerId={customerId} label="＋共有者追加" functions={functions} shareAddDisabledFlg={shareAddDisabledFlg} />
        <Button
          type="button"
          className={classes.delete}
          onClick={handleDeleteClick}
          disabled={values.length === 0}
        >
          <DeleteOutline className={classes.icon} />
          <Typography className={common.title4}>
            選択者を削除
          </Typography>
        </Button>
        <FormGroup className={classes.formGroup}>
          {customerList && customerList.map(c => (
            <FormControlLabel
              key={`checkbox${c.userId}_${c.divisionId}`}
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={values.includes(`${c.userId}_${c.divisionId}`)}
                  onChange={handleChange}
                  name="shareupdtae"
                  color="primary"
                  value={`${c.userId}_${c.divisionId}`}
                />
              }
              label={
                <>
                  <span className={common.small}>{c.userName}</span>
                  <span className={common.small}>{c.endAt.substr(0, 16)} まで</span>
                </>
              }
            />
          ))}
        </FormGroup>
      </div>
    </NormalTooltip>
  );
}

export default CustomerShareUpdateComponent;
