import {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  updateSearchItems,
  createInitialState,
} from '../../../store/search/searchItemsSlice';
import { initialTreeState } from '../detailSearch/detailSearchContainers';
import { removeSessionStorage } from '../../../commonFunction';

const KanniKensakuContenxt = createContext({});

export function useKanniKensaku() {
  return useContext(KanniKensakuContenxt);
}

export function KanniKensakuContenxtProvider(props) {
  const { children, isAvoidSharedCustomer, initialSearchParam = initialTreeState } = props;
  const searchParams = useSelector((state) => state.searchItems.searchParams);

  const dispatch = useDispatch();
  // ローカルステート：検索条件を格納
  const initialState = createInitialState();
  const [localSearchConditions, setLocalSearchConditions] = useState({ ...initialState });

  // 詳細検索にてGStateが更新されたら簡易検索も同期
  useEffect(() => {
    setLocalSearchConditions(searchParams);
  }, [searchParams]);

  // リセット
  const resetStart = useCallback(() => {
    for (const key in initialState) {
      removeSessionStorage('daily_' + key);
    }
    setLocalSearchConditions({ ...initialSearchParam });
    // グローバルステート(簡易検索条件)の初期化
    dispatch(updateSearchItems(initialSearchParam));
  }, [initialSearchParam]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isAvoidSharedCustomer,
    searchConditions: localSearchConditions,
    setSearchConditions: setLocalSearchConditions,
    resetStart,
  };

  return (
    <KanniKensakuContenxt.Provider value={value}>
      {children}
    </KanniKensakuContenxt.Provider>
  );
}
