import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AllocationSetting from '../allocationSetting';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: 1920,
    minWidth: 1920,
    margin: '0 auto',
    '& > .MuiGrid-container': {
      position: 'fixed',
      zIndex: 9,
      top: 0,
      left: 0,
    },
  },
});

function AllocationSettingPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.main}>
        <AllocationSetting />
      </Grid>
    </div>
  );
}

export default AllocationSettingPage;
