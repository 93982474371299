import { useSelector } from 'react-redux';
import { FormControl, Grid, InputLabel, makeStyles } from '@material-ui/core';
import CustomSelect from '../../../eleCommon/customSelect';
import { COLOR_CODE } from '../../../../constants';

const useStyles = makeStyles({
  selectBoxGrid: {
    height: '100%',
  },
  selectBoxGridContainer: {
    height: '35px',
    maxHeight: '35px',
  },
});

function UserTreeSelectBox(props) {
  const { setList, treeHeaderSelect } = props;
  const themeColor = useSelector((state) => state.colorTheme.color);

  const classes = useStyles();

  const getTreeSelectHeader = () => {
    const data = [];
    treeHeaderSelect.forEach((selectObj) => {
      data.push({
        key: 'selectHeader',
        obj: selectObj,
        set: setList,
        defaultValue: Object.keys(selectObj.menus)[0],
        margin: '0px',
        fontColor: COLOR_CODE[themeColor],
        fontBold: 'bold',
      });
    });
    const widthStyle = data.length === 1 ? '100%' : '50%';
    return data.map((d) => (
      <Grid
        item
        style={{ width: widthStyle, border: '1px solid #C8C8C8' }}
        className={classes.selectBoxGrid}
        key={d.key}
      >
        <FormControl style={{ width: '100%' }}>
          <InputLabel>{d.obj.title}</InputLabel>
          <CustomSelect initial={d} />
        </FormControl>
      </Grid>
    ));
  };

  return (
    <Grid container justifyContent="space-between" className={classes.selectBoxGridContainer}>
      {getTreeSelectHeader(treeHeaderSelect)}
    </Grid>
  );
}

export default UserTreeSelectBox;
