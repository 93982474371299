import {
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import UserTree from '../../userTreeNew';
import store from '../../../../store';

const useStyles = makeStyles((theme) => ({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '100%',
        background: '#F3F3F3',
      },
      '& .MuiDialog-paper': {
        maxWidth: 'none',
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'initial',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
        borderRadius: 0,
      },
    },
  },
  root: {
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    background: '#F3F3F3',
    [commonTheme.breakpoints.up('md')]: {
      maxWidth: '447px',
    },
  },
  title: {
    backgroundColor: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px 24px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 8px',
      '& > p': {
        textAlign: 'center',
      },
    },
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  modalBtn: {
    backgroundColor: '#fff',
    border: '1px #c8c8c8 solid',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: '40px',
    '& p': {
      padding: '4px',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  wrap: {
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
    overflowY: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px',
      height: '577px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
      height: '100%',
    },
  },
  childwrap: {
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px #c8c8c8 solid',
    marginTop: '8px',
    width: '100%',
    padding: '8px',
  },
  date: {
    backgroundColor: '#fff',
    border: '1px #c8c8c8 solid',
    marginTop: '8px',
    width: '100%',
    padding: '8px',
    alignSelf: 'flex-start',
    '& > .MuiGrid-container': {
      border: 'none',
      flexWrap: 'nowrap',
      '& *:empty': {
        display: 'none',
      },
    },
  },
  tree: {
    width: '100%',
    [commonTheme.breakpoints.up('md')]: {
      backgroundColor: '#fff',
      padding: '8px',
      border: '1px #c8c8c8 solid',
    },
    '& > .MuiAccordion-root.Mui-expanded': {
      margin: 0,
      maxWidth: 'none',
      width: '100%',
      '& > .MuiAccordionDetails-root': {
        padding: 0,
      },
    },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
  foot: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#fff',
      borderTop: '1px solid rgba(51, 51, 51, 0.04)',
    },
  },
}));

function ChangeManageWindowComponents(props) {
  const {
    componentLabel,
    useCustomBtn,
    open,
    setOpen,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    handleSelectFunction,
    clickableUserAndDivision,
  } = props;
  const classes = useStyles();
  const common = commonStyles();

  const { responseHeader } = store.getState();

  return (
    <>
      {useCustomBtn ? ''
        : (
          <Button
            className={classes.modalBtn}
            type="button"
            onClick={() => { setOpen(true); }}
          >
            {(componentLabel) || (<Typography className={common.title4}>担当</Typography>)}
            <Typography className={common.bass}>{selectUser ? selectUser.userName : '' }</Typography>
          </Button>
        )}
      <UserTree
        isOpenTree={open}
        setIsOpenTree={setOpen}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        selectUser={selectUser.userName !== '' ? selectUser : responseHeader}
        setSelectUser={setSelectUser}
        isDialog
        // isSelectUserWindow
        clickableUserAndDivision={clickableUserAndDivision}
        doubleSearch
        changeTitle="担当変更画面"
        handleSelectFunction={handleSelectFunction}
      />
    </>
  );
}

export default ChangeManageWindowComponents;
