import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import responseGetMediumApi from '../../apis/response/responseGetMediumApi';
import responseResisterMediumApi from '../../apis/response/responseResisterMediumApi';
import responseUpdateMediumApi from '../../apis/response/responseUpdateMediumApi';
import responseDeleteMediumApi from '../../apis/response/responseDeleteMediumApi';

import { responseGetTypeApi } from '../../store/response/responseGetTypeSlice';

import ResponseTypeMaster from '../../components/responseTypeMaster/responseTypeMaster';

function ResponseTypeMasterContainer() {
  const [selectedResponseType, setSelectedResponseType] = useState({});

  const dispatch = useDispatch();
  const responseTypeObj = useSelector(
    (state) => state.responseGetType.responseGetType,
  );
  const getResponseTypes = (params) => {
    responseGetMediumApi(params)
      .then((res) => {
        dispatch(responseGetTypeApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resisterResponseType = (responseTypeObject) => {
    const {
      responseTypeName,
      responseTypeParentId,
      responseTypeLevelCode,
      displayOrder,
      isAllowSales,
    } = responseTypeObject;

    const params = {
      responseTypeName,
      isAllowSales,
      levelCode: responseTypeLevelCode,
      displayOrder,
    };
    if (responseTypeParentId) params.parentId = responseTypeParentId;

    responseResisterMediumApi(params)
      .then((res) => {
        console.log(res.data);
        getResponseTypes({ limit: -1, offset: 0 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateResponseType = (responseTypeObject) => {
    const params = {
      responseTypeId: responseTypeObject.responseTypeId,
      responseTypeName: responseTypeObject.responseTypeName,
      levelCode: responseTypeObject.responseTypeLevelCode,
      parentId: responseTypeObject.responseTypeParentId,
      displayOrder: responseTypeObject.displayOrder,
      isAllowSales: responseTypeObject.isAllowSales,
    };
    responseUpdateMediumApi(responseTypeObject.responseTypeId, params)
      .then((res) => {
        console.log(res.data);
        getResponseTypes({ limit: -1, offset: 0 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteResponseType = (responseTypeObject) => {
    responseDeleteMediumApi(responseTypeObject.responseTypeId)
      .then((res) => {
        console.log(res);
        getResponseTypes({ limit: -1, offset: 0 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    getResponseTypes({ limit: -1, offset: 0 });
  }, []);

  return (
    <ResponseTypeMaster
      responseTypeObj={responseTypeObj}
      selectedResponseType={selectedResponseType}
      setSelectedResponseType={setSelectedResponseType}
      resisterResponseType={resisterResponseType}
      updateResponseType={updateResponseType}
      deleteResponseType={deleteResponseType}
    />
  );
}

export default ResponseTypeMasterContainer;
