import CustomerDetailContainers from './customerDetail';

// 下記で最低限動きそう
// const input = {
//   component: () => {},
//   editDialog: () => {},
//   parts: () => {},
//   customerDetail: customerObj,
// };

function CustomerDetail(props) {
  const {
    open,
    onClose,
    input,
    onChange,
  } = props;
  return (
    <CustomerDetailContainers
      open={open}
      onClose={onClose}
      input={input}
      onChange={onChange}
    />
  );
}

export default CustomerDetail;
