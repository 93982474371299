import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: '',
  divisions: [],
};

// const initialState = {
//   date: '20221025',
//   divisions: [
//     {
//       divisionId: 1,
//       divisionName: '営業部',
//       users: [
//         {
//           userId: 15,
//           actions: [
//             {
//               scheduleId: 7,
//               guidanceId: 1,
//               startAt: '2022/10/25 10:00:00',
//               endAt: '2022/10/25 11:00:00',
//               actionScheduleCode: 1,
//               guidanceStatusCode: 1,
//               isResultRegister: 1,
//               guidanceCount: 2,
//               isPairCheck: 1,
//               customerId: 7,
//               customerName: '山田太郎',
//               siteId: 7,
//               memo: 'メモテスト1',
//               customerStatusCode: 1,
//               userLevel: 1,
//             },
//             {
//               scheduleId: 8,
//               guidanceId: 1,
//               startAt: '2022/10/25 12:00:00',
//               endAt: '2022/10/25 13:00:00',
//               actionScheduleCode: 3,
//               guidanceStatusCode: 1,
//               isResultRegister: 1,
//               guidanceCount: 10,
//               isPairCheck: 1,
//               customerId: 7,
//               customerName: '山田太郎',
//               siteId: 7,
//               memo: 'メモテスト2',
//               customerStatusCode: 1,
//               userLevel: 1,
//             },
//             {
//               scheduleId: 9,
//               guidanceId: 1,
//               startAt: '2022/10/25 14:00:00',
//               endAt: '2022/10/25 15:00:00',
//               actionScheduleCode: 3,
//               guidanceStatusCode: 2,
//               isResultRegister: 1,
//               guidanceCount: 2,
//               isPairCheck: 1,
//               customerId: 8,
//               customerName: '山田太郎',
//               siteId: 8,
//               memo: 'メモテスト3',
//               customerStatusCode: 1,
//               userLevel: 1,
//             },
//             {
//               scheduleId: 9,
//               guidanceId: 1,
//               startAt: '2022/10/25 15:30:00',
//               endAt: '2022/10/25 16:00:00',
//               actionScheduleCode: 999,
//               guidanceStatusCode: 2,
//               isResultRegister: 0,
//               guidanceCount: 2,
//               isPairCheck: 1,
//               customerId: 8,
//               customerName: '山田太郎',
//               siteId: 8,
//               memo: 'メモテスト3',
//               customerStatusCode: 1,
//               userLevel: 1,
//             },
//             {
//               scheduleId: 10,
//               guidanceId: 1,
//               startAt: '2022/10/25 16:30:00',
//               endAt: '2022/10/25 17:00:00',
//               actionScheduleCode: 999,
//               guidanceStatusCode: 2,
//               isResultRegister: 0,
//               guidanceCount: 2,
//               isPairCheck: 1,
//               customerId: 8,
//               customerName: '山田太郎',
//               siteId: 8,
//               memo: 'メモテスト3',
//               customerStatusCode: 10,
//               userLevel: 1,
//             },
//             {
//               scheduleId: 11,
//               guidanceId: 1,
//               startAt: '2022/10/25 17:30:00',
//               endAt: '2022/10/25 18:00:00',
//               actionScheduleCode: 999,
//               guidanceStatusCode: 2,
//               isResultRegister: 1,
//               guidanceCount: 2,
//               isPairCheck: 1,
//               customerId: 8,
//               customerName: '山田太郎',
//               siteId: 8,
//               memo: 'メモテスト3',
//               customerStatusCode: 1,
//               userLevel: 1,
//             },
//           ],
//         },
//       ],
//       actions: [
//         {
//           scheduleId: 7,
//           guidanceId: 1,
//           startAt: '2022/10/25 15:00:00',
//           endAt: '2022/10/25 16:00:00',
//           actionScheduleCode: 3,
//           guidanceStatusCode: 2,
//           isResultRegister: 1,
//           guidanceCount: 2,
//           isPairCheck: 1,
//           customerId: 7,
//           customerName: '山田太郎',
//           siteId: 7,
//           memo: 'メモテスト1',
//           customerStatusCode: 1,
//           userLevel: 1,
//         },
//         {
//           scheduleId: 8,
//           guidanceId: 1,
//           startAt: '2022/10/25 12:00:00',
//           endAt: '2022/10/25 13:00:00',
//           actionScheduleCode: 3,
//           guidanceStatusCode: 2,
//           isResultRegister: 1,
//           guidanceCount: 2,
//           isPairCheck: 1,
//           customerId: 8,
//           customerName: '山田太郎',
//           siteId: 8,
//           memo: 'メモテスト1',
//           customerStatusCode: 1,
//           userLevel: 1,
//         },
//       ],
//     },
//     {
//       divisionId: 2,
//       divisionName: 'グループA_1_1',
//       users: [
//         {
//           userId: 15,
//           actions: [
//             {
//               scheduleId: 7,
//               guidanceId: 1,
//               startAt: '2022/10/25 12:00:00',
//               endAt: '2022/10/25 13:00:00',
//               actionScheduleCode: 3,
//               guidanceStatusCode: 2,
//               isResultRegister: 1,
//               guidanceCount: 2,
//               isPairCheck: 1,
//               customerId: 7,
//               customerName: '山田太郎',
//               siteId: 7,
//               memo: 'メモテスト1',
//               customerStatusCode: 1,
//               userLevel: 1,
//             },
//             {
//               scheduleId: 8,
//               guidanceId: 1,
//               startAt: '2022/10/25 14:00:00',
//               endAt: '2022/10/25 15:00:00',
//               actionScheduleCode: 3,
//               guidanceStatusCode: 2,
//               isResultRegister: 1,
//               guidanceCount: 2,
//               isPairCheck: 1,
//               customerId: 8,
//               customerName: '山田太郎',
//               siteId: 8,
//               memo: 'メモテスト1',
//               customerStatusCode: 1,
//               userLevel: 1,
//             },
//           ],
//         },
//       ],
//       actions: [
//         {
//           scheduleId: 7,
//           guidanceId: 1,
//           startAt: '2022/10/25 15:00:00',
//           endAt: '2022/10/25 16:00:00',
//           actionScheduleCode: 3,
//           guidanceStatusCode: 2,
//           isResultRegister: 1,
//           guidanceCount: 2,
//           isPairCheck: 1,
//           customerId: 7,
//           customerName: '山田太郎',
//           siteId: 7,
//           memo: 'メモテスト1',
//           customerStatusCode: 1,
//           userLevel: 1,
//         },
//         {
//           scheduleId: 8,
//           guidanceId: 1,
//           startAt: '2022/10/25 12:00:00',
//           endAt: '2022/10/25 13:00:00',
//           actionScheduleCode: 3,
//           guidanceStatusCode: 2,
//           isResultRegister: 1,
//           guidanceCount: 2,
//           isPairCheck: 1,
//           customerId: 8,
//           customerName: '山田太郎',
//           siteId: 8,
//           memo: 'メモテスト1',
//           customerStatusCode: 1,
//           userLevel: 1,
//         },
//       ],
//     },
//   ],
// };

export const schedulesGetActionDateSlice = createSlice({
  name: 'schedulesGetActionDate',
  initialState: { schedulesGetActionDate: initialState },
  reducers: {
    getSchedulesActionDateApi: (state, data) => {
      state.schedulesGetActionDate = data.payload;
    },
  },
});

export const { getSchedulesActionDateApi } = schedulesGetActionDateSlice.actions;

export default schedulesGetActionDateSlice.reducer;
