import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  total: 0,
  fileStorages: [],
};

export const filestorageGetSlice = createSlice({
  name: 'filestorageGet',
  initialState: { filestorageGet: initialState },
  reducers: {
    getFilestrageItems: (state, data) => {
      state.filestorageGet = data.payload;
    },
  },
});

export const { getFilestrageItems } = filestorageGetSlice.actions;

export default filestorageGetSlice.reducer;
