import { Button, Collapse, List, ListItem, Typography } from '@material-ui/core';
import { Add, ExpandLess, ExpandMore, Folder, Person, Remove } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import commonStyles from '../../styles';
import commonTreeStyles from './parts/commonTreeStyle';

export default function TreeForSingle(props) {
  const {
    list,
    users,
    setUsers,
    search,
    divisionPickable,
  } = props;
  const common = commonStyles();
  const classes = commonTreeStyles();

  // 開閉用ローカルステート
  const [state, setState] = useState({});
  // 組織選択状態保持
  const [divChecked, setDivChecked] = useState(0);
  // 検索Enter時のfocus
  const [searchIndex, setSearchIndex] = useState(0);
  // 検索結果の元を保持
  const [initialSearch, setInitialSearch] = useState('');
  const handleClick = (menu) => {
    setState({ ...state, [`${menu}Open`]: !state[`${menu}Open`] });
  };
  const [checked, setChecked] = useState({
    userId: users.userId,
    divisionId: users.divisionId,
  });
  const handleSelectUser = (item) => {
    if (checked.userId === item.userId) {
      setChecked('');
      setUsers('');
    } else {
      setChecked({ userId: item.userId });
      setUsers(item);
    }
  };

  const handleSelectDivision = (item) => {
    if (checked.divisionId === item.divisionId) {
      setChecked('');
      setUsers('');
    } else {
      setChecked({ divisionId: item.divisionId });
      setUsers(item);
    }
  };

  // ツリーの検索
  useEffect(() => {
    if (search instanceof Object) {
      let targetIndex = searchIndex;
      if (initialSearch && search) {
        if (String(initialSearch) !== String(Object.keys(search))) {
          setSearchIndex(0);
          targetIndex = 0;
        }
      }
      setInitialSearch(Object.keys(search));
      const multipleUser = [];
      let targetUserId = '';
      if (Object.keys(search).length) {
        for (const property in search) {
          for (const div in search[property]) {
            multipleUser.push({
              targetId: search[property][div].targetId,
              divIdList: search[property][div].divisionList,
              type: search[property][div].type,
            });
          }
        }
        for (const property in search) {
          if (
            search[property].length > targetIndex && property !== 'count'
          ) {
            for (const division in search[property]) {
              for (const divId in search[property][division].divisionList) {
                if (
                  String(initialSearch) === String(Object.keys(search)) || !searchIndex
                ) {
                  setSearchIndex(searchIndex + 1);
                } else {
                  setSearchIndex(0);
                }
                if (search[property][division].type === 'user') {
                  setChecked({ ...checked, userId: search[property][division].targetId });
                  setUsers(search[property][division].targetId);
                  setDivChecked(0);
                } else if (search[property][division].type === 'division') {
                  setDivChecked(search[property][division].targetId);
                  setChecked(0);
                }
                targetUserId = search[property][division].targetId;
                setState((prev) => ({
                  ...prev,
                  [`root${search[property][division].divisionList[divId]}Open`]: true,
                }));
                setState((prev) => ({
                  ...prev,
                  [`divi${search[property][division].divisionList[divId]}Open`]: true,
                }));
              }
            }
            break;
            // 対象の総数（ユーザー）
          } else if (property === 'count') {
            setSearchIndex(1);
            targetIndex = 1;
            if (search[Object.keys(search)[0]][0].type === 'user') {
              setChecked({ ...checked, userId: search[Object.keys(search)[0]][0].targetId });
              setDivChecked(0);
              setUsers(search[Object.keys(search)[0]][0].targetId);
            } else if (
              search[Object.keys(search)[0]][0].type === 'division'
            ) {
              setDivChecked({ ...checked, divisionId: search[Object.keys(search)[0]][0].targetId });
              setChecked(0);
            }
          } else {
            targetIndex -= search[property].length;
          }
        }
      }
      // 重複Check
      if (multipleUser.length) {
        const multi = multipleUser.filter((e) => e.targetId === targetUserId);
        for (const obj of multi) {
          for (const d of obj.divIdList) {
            setState((prev) => ({ ...prev, [`root${d}Open`]: true }));
            setState((prev) => ({ ...prev, [`divi${d}Open`]: true }));
          }
        }
      }
    }
  }, [search]);

  const userList = (arr) => {
    const userlist = [];
    arr.users.map((u, i) => {
      return userlist.push(
        <ListItem
          key={`u${u.userId}-${String(i)}`}
          className={`${common.small} ${classes.li} ${classes.person}`}
        >
          <Person className={classes.icon} />
          <Button
            className={`${classes.treeUser} ${
              checked.userId === u.userId ? classes.checkedUser : null
            }`}
            id={`id-user-${u.userId}`}
            style={{ cursor: 'pointer' }}
            variant="text"
            onClick={() => {
              handleSelectUser({
                ...arr,
                ...u,
              });
            }}
          >
            {u.userName}
          </Button>
        </ListItem>,
      );
    });
    return userlist;
  };
  const childList = (arr) => {
    const childlist = [];
    if ('children' in arr) {
      arr.children.map((c) => {
        return childlist.push(
          <List key={`divi${c.divisionId}`} className={classes.parent}>
            <ListItem
              className={classes.li}
              onClick={() => {
                if (!divisionPickable) handleClick(`divi${c.divisionId}`);
              }}
            >
              {state[`divi${c.divisionId}Open`] ? (
                <>
                  <Remove
                    className={classes.iconsPc}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                  <ExpandLess
                    className={classes.iconsSp}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                </>
              ) : (
                <>
                  <Add
                    className={classes.iconsPc}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                  <ExpandMore
                    className={classes.iconsSp}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                </>
              )}
              <Folder
                className={classes.icon}
                onClick={() => {
                  handleClick(`divi${c.divisionId}`);
                }}
              />
              {divisionPickable
                ? (
                  <Button
                    className={`${classes.treeUser} ${
                      checked.divisionId === c.divisionId ? classes.checkedUser : null
                    }`}
                    id={`id-division-${c.divisionId}`}
                    style={{ cursor: 'pointer' }}
                    variant="text"
                    onClick={() => {
                      handleSelectDivision(c);
                    }}
                  >
                    {c.subName}
                  </Button>
                ) : (
                  <Typography
                    className={`${classes.treeUser} ${common.small} ${
                      divChecked === c.divisionId ? classes.checkedUser : null
                    }`}
                  >
                    {c.subName}
                  </Typography>
                )}
            </ListItem>
            <Collapse
              in={state[`divi${c.divisionId}Open`]}
              className={classes.child}
              timeout={0}
            >
              {childList(c)}
              {c.users.length
                ? <List>{userList(c)}</List>
                : null}
            </Collapse>
          </List>,
        );
      });
    } else {
      return false;
    }
    return childlist;
  };
  const getListItem = () => {
    return list.map((data) => (
      <div key={data.subName} className={classes.parent}>
        <ListItem
          className={classes.li}
          onClick={() => {
            if (!divisionPickable) handleClick(`divi${data.divisionId}`);
          }}
        >
          {state[`divi${data.divisionId}Open`] ? (
            <>
              <Remove
                className={classes.iconsPc}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
              <ExpandLess
                className={classes.iconsSp}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
            </>
          ) : (
            <>
              <Add
                className={classes.iconsPc}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
              <ExpandMore
                className={classes.iconsSp}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
            </>
          )}
          <Folder
            className={classes.icon}
            onClick={() => {
              handleClick(`divi${data.divisionId}`);
            }}
          />
          {divisionPickable
            ? (
              <Button
                className={`${classes.treeUser} ${
                  checked.divisionId === data.divisionId ? classes.checkedUser : null
                }`}
                id={`id-division-${data.divisionId}`}
                style={{ cursor: 'pointer' }}
                variant="text"
                onClick={() => {
                  handleSelectDivision(data);
                }}
              >
                {data.subName}
              </Button>
            ) : (
              <Typography
                className={`${classes.treeUser} ${common.small} ${
                  divChecked === data.divisionId ? classes.checkedUser : null
                }`}
              >
                {data.subName}
              </Typography>
            )}
        </ListItem>
        <Collapse
          in={state[`divi${data.divisionId}Open`]}
          className={classes.child}
          timeout={0}
        >
          {childList(data)}
          {userList(data)}
        </Collapse>
      </div>
    ));
  };
  return (
    <List className={`${classes.root}`}>{getListItem()}</List>
  );
}
