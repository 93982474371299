import { useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  makeStyles,
  Button,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import commonStyles from '../../../styles';
import { useCustomerTree } from '../../../../containers/common/customerTree/context';
import { getLocalStorage, setLocalStorage } from '../../../../commonFunction';
import {
  TREE_DIVISION_ID,
  ALL_DIVISION,
} from '../../../../constants/localStorage';
import getOwnDivisionApi from '../../../../apis/divisionMaster/getOwnDivisionApi';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';
// import { useStore } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    marginBottom: '8px',
    '& .MuiSelect-selectMenu': {
      fontSize: '13px',
      textAlign: 'left',
    },
  },
  selectBtn: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 4px 4px 12px',
    fontSize: 13,
    borderRadius: 60,
  },
  arrowIcon: {
    color: theme.palette.primaryColor,
    fontSize: 18,
  },
  item: {
    padding: 0,
    '&.Mui-selected': {
      background: 'transparent',
      '& .MuiButton-label': {
        color: theme.palette.primaryColor,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
}));

function DrawerList(props) {
  const { initial, onClose } = props;
  const classes = useStyles();
  const common = commonStyles();
  const list = initial.menu;

  return (
    <List className={classes.list}>
      {Object.keys(list).map((o) => (
        <ListItem
          key={o}
          className={classes.item}
          selected={initial.state !== undefined && initial.state === Number(o)}
        >
          <Button
            className={`${common.title3} ${classes.btn}`}
            onClick={() => {
              initial.setState(Number(o));
              onClose();
            }}
          >
            {initial.menu[o]}
          </Button>
        </ListItem>
      ))}
    </List>
  );
}

function CustomerTreeSelectDivisionSp(props) {
  const {
    setDisplayDivision,
    setDisplayDivisionRoot,
  } = props;

  const {
    searchHeader,
    setSearchHeader,
    setSelectUser,
    setSelectDivision,
    setSelectCustomer,
  } = useCustomerTree();
  const classes = useStyles();

  const [selectList, setSelectList] = useState({
    menus: {},
    title: '部選択',
  });

  const setValues = (value) => {
    setLocalStorage(TREE_DIVISION_ID, Number(value));
    setSearchHeader({
      ...searchHeader,
      divisionId: Number(value),
    });
    setSelectUser({ userId: 0, userName: '' });
    setSelectDivision({ divId: '', divisionName: '' });
    setSelectCustomer([]);
    setDisplayDivision([]);
    setDisplayDivisionRoot([]);
  };

  const divisionData = {
    key: 'divisionId',
    obj: selectList,
    state: searchHeader.divisionId,
    setState: (e) => setValues(e),
    menu: selectList.menus,
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const getOwnDivision = async (params) => {
    let result;
    await getOwnDivisionApi(params)
      .then((res) => {
        console.log('test');
        result = res?.divisions;
      })
      .catch(() => {
        result = [];
      });
    return result;
  };

  const allDivision = async () => {
    let resultObj = {
      menus: {},
      title: '部選択',
    };
    const divisionStorage = getLocalStorage(ALL_DIVISION, resultObj);
    if (Object.keys(divisionStorage?.menus).length > 0) {
      resultObj = divisionStorage;
    } else {
      const ownDivisions = await getOwnDivision({ divisionSearchCode: 0 });
      if (ownDivisions?.length > 0) {
        for (let i = 0; ownDivisions?.length > i; i += 1) {
          resultObj.menus[ownDivisions[i].divisionId] = ownDivisions[i].divisionName;
        }
      }
      if (Object.keys(resultObj.menus).length > 0) {
        setLocalStorage(ALL_DIVISION, resultObj);
      }
    }
    return resultObj;
  };

  useEffect(async () => {
    const result = await allDivision();
    setSelectList(result);
  }, []);

  return (
    <Grid
      item
    >
      <Button onClick={() => { setIsDrawerOpen(true); }} className={classes.selectBtn}>
        {searchHeader?.divisionId ? selectList?.menus[searchHeader?.divisionId] : '営業部'}
        <ArrowDropDownIcon className={classes.arrowIcon} />
      </Button>
      <CustomSimpleDrawer
        open={isDrawerOpen}
        onClose={() => { setIsDrawerOpen(false); }}
        title={divisionData.obj.title}
        list={<DrawerList initial={divisionData} onClose={() => { setIsDrawerOpen(false); }} />}
      />
    </Grid>
  );
}

export default CustomerTreeSelectDivisionSp;
