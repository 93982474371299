import { makeStyles } from '@material-ui/core/styles';
import commonTheme from './theme';
import loadingImg from '../images/loading.svg';

const commonStyles = makeStyles((theme) => ({
  title1: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    textAlign: 'left',
  },
  title2: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    textAlign: 'left',
  },
  title3: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    textAlign: 'left',
  },
  title4: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    textAlign: 'left',
  },
  title5: {
    fontSize: '10px !important',
    fontWeight: 700,
    lineHeight: '1.2 !important',
    letterSpacing: 0,
    textAlign: 'left',
  },
  title6: {
    fontSize: '13px',
    lineHeight: 1.2,
    letterSpacing: 0,
    textAlign: 'left',
  },
  labelShort: {
    width: '4rem',
    marginRight: '1rem',
  },
  large: {
    fontSize: '20px',
    lineHeight: '1.6',
  },
  bass: {
    fontSize: '15px',
    lineHeight: 1.6,
  },
  small: {
    fontSize: '12px',
    lineHeight: 1.6,
  },
  verySmall: {
    fontSize: '10px',
    lineHeight: 1.6,
  },
  strong: {
    fontSize: '15px',
    fontWeight: 700,
  },
  smallStrong: {
    fontSize: '12px',
    fontWeight: 700,
  },
  verySmallStrong: {
    fontSize: '10px',
    fontWeight: 700,
  },
  veryStrong: {
    fontSize: '10px',
    fontWeight: 700,
  },
  tags: {
    background: '#888',
    color: '#fff',
    padding: '4px',
    fontSize: '10px',
    marginRight: '8px',
    lineHeight: 1,
    verticalAlign: 'middle',
  },
  buttonsPrimary: {
    background: `linear-gradient(90deg, ${theme.palette.primaryColor} 0%, ${theme.palette.gradLightColor} 100%)`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '8px 22px',
      margin: '0px 4px',
      minWidth: '86px',
    },
    '&>span': {
      color: '#FFFFFF',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    '&.disabled': {
      pointerEvents: 'none',
      background: '#DEDEDE',
      '&>span': {
        color: '#AFAFAF',
      },
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      background: '#DEDEDE',
      '&>span': {
        color: '#AFAFAF',
      },
    },
    '& .MuiSvgIcon-root': {
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 15,
      },
    },
  },
  buttonsSecondary: {
    background: '#FFFFFF !important',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #FFFFFF !important',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '8px 22px',
      margin: '0px 4px',
      minWidth: '86px',
    },
    '&:hover': {
      background: '#FFFFFF',
      border: `1px solid ${theme.palette.primaryColor}`,
      boxShadow: '0px 0px 0px 4px rgba(6, 121, 191, 0.16)',
      transition: '0.3s',
    },
    '&>span': {
      color: theme.palette.primaryColor,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    '&.Mui-disabled > span': {
      color: 'inherit',
    },
    '& .MuiSvgIcon-root': {
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 15,
      },
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  grid3: {
    '& .MuiGrid-container': {
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      '& .MuiButton-label': {
        fontSize: 13,
      },
    },
  },
  sideAccordion: {
    width: 240,
    margin: 0,
    '&.MuiAccordion-root': {
      margin: 0,
    },
    '&:not(:first-child)': {
      borderTop: '1px solid #C8C8C8',
    },
  },
  drawer: {
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiDrawer-paper': {
        borderRadius: '12px 12px 0 0',
      },
    },
  },
  drawerHeader: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px',
      borderBottom: '1px solid #C8C8C8',
      '& .MuiTypography-root': {
        textAlign: 'center',
      },
      '& .MuiDrawer-paper': {
        maxHeight: '80vh',
        borderRadius: '12px 12px 0 0',
      },
      '& ul': {
        padding: '16px 0 40px 16px',
        '& > *': {
          padding: '18.5px 16px',
          borderBottom: '1px solid #F3F3F3',
        },
      },
    },
  },
  maxWidthDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
    [commonTheme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      '& .MuiDialog-paperWidthSm': {
        margin: '0',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        borderRadius: '0',
        boxShadow: 'none',
      },
      '& .MuiTypography-h3': {
        margin: '0 auto',
        fontSize: '20px',
      },
    },
  },
  sideAccordionHeader: {
    'background-color': '#F3F3F3!important',
    height: '24px!important',
    'min-height': '24px!important',
    '&.Mui-expanded': {
      height: 24,
      'min-height': 24,
    },
    '&:first-child': {
      paddingLeft: 4,
      paddingRight: 4,
      padding: 0,
      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& p': {
      fontSize: 13,
      lineHeight: 2,
    },
    '&>*, &>.MuiAccordionSummary-content.Mui-expanded': {
      height: 24,
      'min-height': 24,
      margin: '0!important',
    },
    '&>:last-child': {
      padding: 0,
      color: theme.palette.primaryColor,
      '& svg': {
        width: 16,
      },
    },
  },
  cssRequired: {
    color: 'red',
  },
  modalBtn: {
    borderRadius: 0,
    minHeight: '1.6em',
    transition: 'all 0.3s ease',
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
    },
  },
  inputWrap: {
    background: '#FFFFFF',
    // padding: '0px 8px 2px',
    '& > .MuiInput-root': {
      minWidth: 80,
    },
    '& .MuiSelect-select': {
      paddingLeft: 8,
      fontWeight: 400,
    },
    '& .MuiInput-underline:not(.Mui-disabled):before, .MuiInput-underline:before, div[class*="makeStyles-wrap"]':
      {
        borderBottom: 0,
      },
    '& label, h6': {
      color: '#333333',
    },
    '& label, label>span, h6': {
      fontWeight: 700,
      lineHeight: '120%',
    },
    '& .MuiInput-input': {
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: `${theme.palette.primaryColor}29`,
      },
    },
  },
  tooltipText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  onlyPc: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none!important',
    },
  },
  onlySp: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none!important',
    },
  },
  cancelButton: {
    width: '6rem',
    color: theme.palette.primaryColor,
    fontSize: '13px',
    '&:hover': {
      boxShadow: `1px 1px 3px ${theme.palette.primaryColor}`,
      border: `1px solid ${theme.palette.primaryColor}`,
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
  arrowButton: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
    position: 'absolute !important',
    top: 0,
    left: 0,
    minWidth: 'auto',
    padding: '0 !important',
    width: 51,
    height: 51,
  },
  openButton: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
    position: 'absolute !important',
    top: 0,
    left: 0,
    minWidth: 'auto !important',
    padding: '0 !important',
    width: 51,
    height: 51,
    '& .MuiButton-label': {
      display: 'block',
      width: 4,
      height: 4,
      background: theme.palette.primaryColor,
      borderRadius: '50%',
      position: 'absolute',
      top: 27,
      right: 0,
      left: 0,
      margin: '0 auto',
    },
    '&::before': {
      content: '""',
      display: 'block',
      width: 4,
      height: 4,
      background: theme.palette.primaryColor,
      borderRadius: '50%',
      position: 'absolute',
      top: 27,
      left: 15,
    },
    '&::after': {
      content: '""',
      display: 'block',
      width: 4,
      height: 4,
      background: theme.palette.primaryColor,
      borderRadius: '50%',
      position: 'absolute',
      top: 27,
      right: 15,
    },
  },
  closeButton: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
      '&::before': {
        width: '24px!important',
        right: '14px!important',
      },
      '&::after': {
        width: '24px!important',
        right: '14px!important',
      },
    },
    position: 'absolute !important',
    top: 0,
    right: 0,
    minWidth: 'auto',
    padding: 0,
    width: 51,
    height: 51,
    '&::before': {
      content: '""',
      display: 'block',
      width: 16,
      height: 2,
      background: theme.palette.primaryColor,
      position: 'absolute',
      top: 27,
      right: 16,
      transform: 'rotate(45deg)',
    },
    '&::after': {
      content: '""',
      display: 'block',
      width: 16,
      height: 2,
      background: theme.palette.primaryColor,
      position: 'absolute',
      top: 27,
      right: 16,
      transform: 'rotate(-45deg)',
    },
  },
  icon: {
    fill: theme.palette.primaryColor,
  },
  alert: {
    position: 'fixed',
    top: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(100% - 16px)',
    zIndex: '10000',
    '& .MuiAlert-filledInfo': {
      background: '#27AE60',
    },
  },
  searchButton: {
    [commonTheme.breakpoints.down('sm')]: {
      minWidth: 'auto !important',
      padding: '0 !important',
      position: 'fixed !important',
      bottom: '24px',
      right: '24px',
      width: '56px',
      height: '56px',
      backgroundColor: `${theme.palette.primaryColor} !important`,
      borderRadius: '100% !important',
      boxShadow:
        '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    },
  },
  // タイトルなどを囲むヘッダー
  header: {
    background: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px #C8C8C8 solid',
      padding: 16,
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 16px 8px 16px',
      borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
      position: 'relative',
      '& > h2': {
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'center',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
    borderTop: 'solid 1px #C8C8C8',
    gap: 16,
    padding: 16,
  },
  fixedHeader: {
    position: 'fixed',
    width: '100%',
    top: 0,
    background: '#fff',
    zIndex: 3,
  },
  dialogContent: {
    height: 'calc(100vh - 66px)',
    overflowY: 'auto',
    background: '#F3F3F3',
  },
  drawerHeaderSp: {
    padding: '16px',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
    '& > p': {
      fontSize: '20px',
    },
  },
  fixedFooter: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#fff',
      borderTop: '1px solid rgba(51, 51, 51, 0.04)',
    },
  },
  fixedFooterBtn: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 28px',
  },
  body: {
    background: '#F3F3F3',
    padding: '24px',
    width: '100%',
  },
  bodyType2: {
    background: '#F3F3F3',
    padding: '16px',
    width: '100%',
  },
  gutters: {
    margin: '0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0',
      '&.Mui-selected': {
        background: '#fff',
        color: theme.palette.primaryColor,
      },
    },
    '& > .MuiListItemText-root': {
      [commonTheme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '16px',
        borderBottom: '1px solid #F3F3F3',
      },
      '& > span': {
        fontWeight: 'bold',
      },
    },
  },
  stateBox: {
    border: '1px solid #C8C8C8',
  },
  cancelIndex: {
    zIndex: '10000!important',
  },
  colorOhBlue: { color: theme.palette.primaryColor },
  box: {
    width: '100%',
    background: '#fff',
    padding: 8,
    border: '1px solid #C8C8C8',
  },
  // paddingなし
  boxType2: {
    width: '100%',
    background: '#fff',
    border: '1px solid #C8C8C8',
  },
  /*
  * デザイン共通
  * ルール（※https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3454）
  */
  textField: {
    // width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      // fontSize: 13,
      lineHeight: 1.6,
      // fontWeight: '700',
      letterSpacing: 0,
      // padding: 0,
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 16,
        zoom: 0.88,
      },
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    // 数字の増減UIを非表示
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
  },
  /*
  * コンポーネントパーツをいくつか組み合わせてできたもの
  */
  selectBox: {
    border: '1px solid #C8C8C8',
    display: 'inline-flex',
    alignItems: 'center',
    padding: 8,
    marginRight: 8,
    // height: '2.5rem',
    backgroundColor: '#fff',
    '& .MuiSelect-select': {
      width: 145,
      paddingTop: 6,
      paddingBottom: 5,
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: '1.6',
      letterSpacing: '0',
      padding: 0,
      textAlign: 'left',
      '&:focus': {
        background: 'none',
      },
    },
    '& .MuiInput-underline:not(.Mui-disabled):before, .MuiInput-underline:before, div[class*="makeStyles-wrap"]':
      {
        borderBottom: 0,
      },
    '& > p': {
      marginRight: 16,
    },
    '& label, h6': {
      color: '#333333',
    },
    '& label, label>span, h6': {
      fontWeight: 700,
      lineHeight: '120%',
    },
    '& .MuiInput-input:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
    },
    '&.disabled': {
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },
  '@keyframes loadingSpinner': {
    '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
    '100%': { transform: 'translate(-50%, -50%) rotate(360deg)' },
  },
  loading: {
    position: 'relative',
    zIndex: 0,
    '&::before': {
      background: 'rgba(200, 200, 200, .8)',
      content: '""',
      display: 'block',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      transitionDuration: '.3s',
      width: '100%',
      zIndex: 100000,
    },
    '&::after': {
      animation: '$loadingSpinner 2s infinite',
      backgroundImage: `url("${loadingImg}")`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      content: '""',
      display: 'block',
      height: 50,
      left: '50%',
      position: 'absolute',
      top: '50%',
      width: 50,
      zIndex: 100001,
    },
    '&.allWrapper': {
      '&::before': {
        position: 'fixed',
      },
      '&::after': {
        position: 'fixed',
      },
    },
  },
}));

export default commonStyles;
