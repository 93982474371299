const judgeMemberStatus = (memberStatus) => {
  let result = '';
  switch (memberStatus) {
    // case 0:
    //   result = '非会員';
    //   break;
    case 0:
      result = '会員'; // 通常会員
      break;
    case 1:
      result = '申請中';
      break;
    case 2:
      result = '会員'; // プレミアム会員
      break;
    case 3:
      result = '否認';
      break;
    default:
      result = '非会員';
  }
  return result;
};

export default judgeMemberStatus;
