import client from '../httpClient';

/**
 * 【combination-setting005】組み合わせ設定ダウンロード
 * http://10.22.2.48:8080/docs/#/combinationSetting/get-combination-settings-csv-download
 * @returns Promise<*>
 */
const combinationSettingGetCsvDownloadApi = async () => {
  const res = await client.get('/combination-settings/csv-download', {
    responseType: 'arraybuffer',
  });
  return res.data;
};

export default combinationSettingGetCsvDownloadApi;
