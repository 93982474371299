import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import NormalTooltip from '../../styles/tooltip';
import ChangeManage from '../../common/customer/changeManage';
import { useUpdate } from '../../../containers/customerMain';
import { useCustomerMain } from '../../../containers/customerMain/customerMainContext';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles({
  rp: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '4px 8px',
      width: 384,
    },
  },
  rpTxt: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 16px',
      borderBottom: '1px solid #F3F3F3',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  rpTxtLbl: {
    marginRight: 8,
    whiteSpace: 'nowrap',
  },
  rpTxtName: {
    width: 246,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

function CustomerResponsible(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { input } = props;

  const searchParams = useSelector((state) => state.searchItems.searchParams);

  const {
    functions,
    selectCustomer,
    defaultHeader,
    selectUser,
    selectDivision,
    selectUniqueId,
    queryCustomerId,
  } = useCustomerMain();

  const dispatch = useDispatch();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const { current, updateCurrent, updateApi } = useUpdate(input.customer, [
    'customerId',
    'userId',
    'userName',
    'divisionId',
    'divisionSubName',
    'userLastName',
    'userFirstName',
    'isShared',
  ]);

  const updateTree = () => {
    functions.getCustomerTreeResponseFunction({ ...searchParams, ...defaultHeader });
  };

  const currentUserName = useMemo(() => {
    const { userLastName, userFirstName } = current;
    return current.userName || [userLastName, userFirstName].filter(Boolean).join('');
  }, [current]);

  const [open, setOpen] = useState(false);

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (input.customer?.isResponsibleSharingActive) {
      if (input.customer?.isResponsibleSharingActive === 1) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      let list = [];
      if (selectCustomer) {
        list = selectCustomer.filter((item) => {
          return (
            item.customerId === current.customerId
            && (item.isShared === 0)
          );
        });
        if (list.length > 0) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    }
  }, []);

  const [divisionClickFlg, setDivisionClickFlg] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(selectUser?.userId);
  const [deleteDivisionId, setDeleteDivisionId] = useState(selectDivision?.divId);

  useEffect(() => {
    if (selectUniqueId) {
      if (!selectUniqueId.includes('user')) { // 組織選択時
        setDivisionClickFlg(true);
      } else {
        setDivisionClickFlg(false);
        const match = selectUniqueId.match(/division(\d+)_user(\d+)_?/);
        if (match) {
          setDeleteUserId(match[2]);
          setDeleteDivisionId(match[1]);
        }
      }
    }
  }, [selectUniqueId]);

  // changeManageで使用するユーザー情報
  const initialParam = {
    customerId: current.customerId, // 顧客ID
    userId: current.userId, // ユーザーID
    userName: currentUserName, // ユーザー名
    divisionSubName: current.divisionSubName, // 組織名
  };

  // changeManageで【選択ボタン】を押した時の顧客情報更新処理
  const clickSelectUserFunc = (data) => {
    console.log('gotten data:', data);
    // 画面に表示
    const { userId, userName, divisionId, divisionSubName } = data;
    updateCurrent({
      userId,
      userName,
      divisionId,
      divisionSubName,
    });
    // 更新処理
    updateApi(current.customerId, {
      userId,
      divisionId,
    });
  };

  const callCancel = () => {
    // 共有解除
    const deleteParams = {
      customerId: current.customerId,
      deleteTargetIds: [{
        userId: deleteUserId,
        divisionId: deleteDivisionId,
      }],
    };
    functions.deleteCustomerShare(deleteParams);
  };

  const handleCancelClick = () => {
    dispatch(changeConfirmMessage({
      msgList: ['解除してもよろしいですか？'],
      title: '共有解除',
      buttons: [{
        label: 'CANCEL',
        set: () => { return false; },
        classes: baseClasses.buttonsSecondary,
      }, {
        label: 'OK',
        set: callCancel,
        classes: baseClasses.buttonsPrimary,
      }],
    }));
  };

  return (
    <Grid className={classes.rp}>
      {isSp ? (
        <Button className={classes.rpTxt} onClick={() => { setOpen(!open); }} disabled={disabled}>
          <Typography className={`${baseClasses.title6} ${classes.rpTxtLbl}`}>
            担当
          </Typography>
          <Typography className={`${baseClasses.strong} ${classes.rpTxtName}`}>
            {currentUserName || '担当無し'}
            （店舗名/{current.divisionSubName}）
          </Typography>
        </Button>
      ) : (
        <NormalTooltip title={`${currentUserName}（店舗名/ ${current.divisionSubName}`}>
          <Grid className={classes.rpTxt}>
            <Typography className={`${baseClasses.title6} ${classes.rpTxtLbl}`}>
              担当
            </Typography>
            <Typography className={`${baseClasses.strong} ${classes.rpTxtName}`}>
              {currentUserName || '担当無し'}
              （店舗名/{current.divisionSubName}）
            </Typography>
          </Grid>
        </NormalTooltip>
      )}
      {disabled ? (
        <NormalTooltip title={divisionClickFlg || queryCustomerId ? '共有解除時は左下のツリーから営業マンを選択し解除してください、組織選択状態から顧客共有を解除することは出来ません。' : ''}>
          <Grid>
            <Button
              variant="contained"
              className={`${baseClasses.buttonsPrimary} ${classes.button}`}
              onClick={handleCancelClick}
              disabled={divisionClickFlg || queryCustomerId}
            >
              共有解除
            </Button>
          </Grid>
        </NormalTooltip>
      ) : (
        <Button
          variant="contained"
          className={`${baseClasses.buttonsPrimary} ${classes.button}`}
          onClick={() => { setOpen(!open); }}
        >
          担当/共有
        </Button>
      )}
      {open && (
        <ChangeManage
          initialParam={initialParam}
          onClickSelectButton={clickSelectUserFunc}
          open={open}
          setOpen={setOpen}
          updateTree={updateTree}
        />
      )}
    </Grid>
  );
}

export default React.memo(CustomerResponsible);
