import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  subGrid: {
    marginTop: '32px',
    textAlign: 'center',
    '& > .MuiButton-root': {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
}));

export default function SmsTalkEditDialogTextField(props) {
  const {
    values,
    onClose,
    updateFunction,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const handleUpdateValue = () => {
    updateFunction();
    onClose();
  };

  return (
    <>
      <TextField
        onBlur={(e) => { return values.setState(e.target.value); }}
        name={values.label}
        defaultValue={values.key === 'memo' ? values.state : ''}
        className={classes.textField}
        autoFocus
      />
      {isSP ? (
        <Grid className={classes.subGrid}>
          <Button
            className={baseClasses.buttonsPrimary}
            onClick={handleUpdateValue}
          >
            決定
          </Button>
        </Grid>
      ) : null}
    </>
  );
}
