import { isNumberLike, isObject } from './check';
/**
 * Promise.allSettledのvalueを取得する
 * rejectedの場合、initialを返す
 * @param {*} param0 PromiseSettledResult
 * @param {*} initial any、デフォルトはnullである
 * @returns any
 */
export const getPromiseSettledResult = ({ status, value }, initial = null) => {
  if (status === 'fulfilled') {
    return value;
  }
  return initial;
};

/**
 * 数字のstrを数字にする
 * inputは数字の場合、そのまま返却する
 * そのほかは0を返却する
 * @param {*} input `any` 必須
 * @param {*} initial `number`
 * @returns `number`
 */
export const toNumber = (input, initial = 0) => {
  if (typeof input === 'number') return input;
  if (typeof input === 'string') {
    const n = Number(input);
    return Number.isNaN(n) ? initial : n;
  }
  return initial;
};

/**
 * utf-8をデコードする。
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/escape#browser_compatibility
 * でescapeが非推奨となっているが、現状使えるので採用
 * @param {*} s  `string`
 * @returns `string`
 */
export const decodeUtf8 = (s) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    console.error(e);
  }
  return s;
};

/**
 * フォーマット電話番号
 * 00000000000 => 000-0000-0000
 * @param {*} input 電話番号
 * @returns {string}
 */
export const toSnakeCasePhoneNumber = (input) => {
  if (typeof input !== 'string') return '';
  return input.replace(/(.{3})(.{4})(.{1,4})/, '$1-$2-$3');
};

/**
 * 値は数字に見える場合、数字に変更する処理
 * @param {*} input
 * @returns {*}
 */
export const formatValue = (input) => {
  if (isNumberLike(input)) return +input;
  return input;
};

/**
 * classNameを作成する
 * @param  {...any} args
 * @returns {string}
 */
export const classNames = (...args) => {
  const arr = [];
  args.forEach(item => {
    // 注意：classNameは0の場合も無視される
    if (!item) return;
    // ['classA', {classB: true}, 1000]
    if (Array.isArray(item)) {
      arr.push(classNames(...item));
    } else if (isObject(item)) {
      // {isTrue: true, isAny: 2, isFalse: false} => 'isTrue isAny'
      Object.keys(item).forEach((key) => {
        if (item[key]) arr.push(key);
      });
    } else {
      // string or *
      arr.push(item);
    }
  });
  return arr.join(' ');
};

export const randomString = () => {
  return Math.random().toString(16).substring(2, 8);
};

/**
 * ランダムIDを作成する
 * @returns string
 */
export const randomId = () => {
  const str = new Date().getTime().toString(16);
  return str.concat('-', randomString(), '-', randomString());
};

/**
 * 日付データをformat形式の文字列に変換する
 * Y: year, M: month, D: day, h: hour, m: min, s: sec, W: week
 * @param {Date} value
 * @param {string} format
 * @returns {string}
 */
export const dateFormatter = (value, format = 'YYYY/MM/DD hh:mm:ss') => {
  const paddingZero = (v, num = 2, slice = 2) => {
    return (Array(num + 1).join('0') + v).slice(-1 * slice);
  };
  let tmp = format;
  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  const day = value.getDate();
  const hour = value.getHours();
  const min = value.getMinutes();
  const sec = value.getSeconds();
  const week = value.getDay();
  const weekday = ['日', '月', '火', '水', '木', '金', '土'];

  const replaceMap = {
    YYYY: paddingZero(year, 4, 4),
    MM: paddingZero(month),
    DD: paddingZero(day),
    hh: paddingZero(hour),
    mm: paddingZero(min),
    ss: paddingZero(sec),
    W: weekday[week],
  };

  Object.entries(replaceMap).forEach(([key, val]) => {
    const reg = new RegExp(key, 'g');
    tmp = tmp.replace(reg, val);
  });
  return tmp;
};

/**
 * 日付ソート
 * @param {Array<*>} list
 * @param {string} sortKey
 * @param {boolean} isDesc 降順か
 * @returns Array
 */
export const sortByDate = (list, sortKey, isDesc = false) => {
  if (Array.isArray(list) && list.length > 1 && sortKey) {
    const sort = isDesc
      ? (a, b) => new Date(b[sortKey]) - new Date(a[sortKey])
      : (a, b) => new Date(a[sortKey]) - new Date(b[sortKey]);
    return [...list].sort(sort);
  }
  return list;
};

/**
 * `lastName` => `LastName`
 * @param {string} input
 * @returns {string}
 */
export const capitalizeFirstLetter = (input, prefix = '') => {
  return prefix + input.substring(0, 1).toUpperCase() + input.substring(1);
};

// 結合符号
const JOIN_OPERATORS = [' ', '-', '_', '/'];

/**
 * ストリングの結合
 * 注意：0が無視される
 * @param  {Array<string>} args
 * @returns {string}
 */
export function joinStr(...args) {
  let operator = '';
  // 最後のパラメターは結合符号にするか
  if (JOIN_OPERATORS.includes(args[args.length - 1])) {
    operator = args.pop();
  }
  return args.flat().filter(Boolean).join(operator);
}

/**
 * joinBySlash
 * [1, '2', 0, '', null] => `/1/2/`
 * @param {Array<{}>} arr
 * @returns {string}
 */
export function joinBySlash(arr) {
  const newArr = arr?.filter(Boolean);
  return newArr?.length ? `/${newArr.join('/')}/` : '';
}
