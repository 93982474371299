import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import {
  combinationSettingPostListApi,
  combinationSettingDeleteApi,
  combinationSettingPutApi,
  combinationSettingGetCsvDownloadApi,
  combinationSettingPostCsvUploadApi,
} from '../../apis/combinationSetting';
import { useLoading } from '../../hooks';
import { downloadCSV } from '../../commonFunction/csv';
import { useCityJisCodes } from '../../components/combinationSetting/common/useCityJisCodes';

// CombinationSetting Context
const CombinationSettingContext = createContext({});

/**
 * CombinationSettingProvider
 * @param {*} props
 * @returns {Provider}
 */
export function CombinationSettingProvider(props) {
  const { children } = props;

  // 【settingGroupStore008】エリアリスト取得
  // { areaCode: number, areaName: string, wishCityCount: number }
  const { areaList: areaOptions, cityJisCodes } = useCityJisCodes();

  // ローディング
  const { addLoading, removeLoading } = useLoading();

  // 検索条件: エリア
  const [currentArea, setCurrentArea] = useState({});
  // 検索条件: 地域
  const [currentPlace, setCurrentPlace] = useState({});
  // 検索条件: 1単一のみ、2複数込
  const [areaSearchType, setAreaSearchType] = useState(1);

  const searchConditionsRef = useRef({});
  searchConditionsRef.current = {
    areaCode: currentArea.areaCode,
    cityJisCodes: currentPlace.jisCode ? [currentPlace.jisCode] : [],
    areaSearchType,
  };

  // 組合せ設定新規・編集画面の表示ステート
  const editTargetRef = useRef();
  const [isShowEditDialog, setIsShowEditDialog] = useState(false);

  // 【combination-setting001】組合せ設定一覧取得（エリアコード指定）
  const [list, setList] = useState([]);

  /**
   * 【combination-setting001】組合せ設定一覧取得（エリアコード指定）
   */
  const postListApi = useCallback(async (areaCode, data) => {
    addLoading();
    try {
      const res = await combinationSettingPostListApi(areaCode, data);
      setList(res || []);
    } finally {
      removeLoading();
    }
  }, []);

  /**
   * 検索ボタンが押す際発火
   */
  const onSearch = useCallback(async () => {
    const data = {
      cityJisCodes: searchConditionsRef.current.cityJisCodes,
      areaSearchType: searchConditionsRef.current.areaSearchType,
    };
    await postListApi(searchConditionsRef.current.areaCode, data);
  }, []);

  /**
   * 【combination-setting003】組み合わせ設定削除
   */
  const deleteApi = useCallback(async (params) => {
    addLoading();
    try {
      await combinationSettingDeleteApi(params);
      onSearch();
    } finally {
      removeLoading();
    }
  }, []);

  /**
   * 【combination-setting004】組み合わせ設定更新
   */
  const editApi = useCallback(async (params) => {
    addLoading();
    try {
      await combinationSettingPutApi(params);
      // eslint-disable-next-line no-use-before-define
      showEditDialog(false);
      // eslint-disable-next-line no-use-before-define
      await onSearch();
    } finally {
      removeLoading();
    }
  }, []);

  /**
   * 【combination-setting005】組み合わせ設定ダウンロード
   */
  const downloadApi = useCallback(async () => {
    addLoading();
    try {
      const buf = await combinationSettingGetCsvDownloadApi();
      downloadCSV(buf, `組合せ設定-${moment().format('YYYYMMDDHHmmss')}.csv`);
    } finally {
      removeLoading();
    }
  }, []);

  /**
   * 【combination-setting006】組み合わせ設定アップロード
   */
  const uploadApi = useCallback(async (file) => {
    addLoading();
    try {
      await combinationSettingPostCsvUploadApi(file);
    } finally {
      removeLoading();
    }
  }, []);

  /**
   * 組合せ設定新規・編集画面を表示するか
   * @param {boolean} flg
   * @param {object} row - 一覧該当行の情報
   */
  const showEditDialog = useCallback((flg, row) => {
    editTargetRef.current = row;
    setIsShowEditDialog(flg);
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    // edit dialog
    isShowEditDialog,
    showEditDialog,
    // search select options
    areaOptions,
    cityJisCodes,
    // search condition state
    currentArea,
    setCurrentArea,
    currentPlace,
    setCurrentPlace,
    areaSearchType,
    setAreaSearchType,
    // search function
    onSearch,
    // 一覧情報
    list,
    // 編集ターゲット情報
    editTargetRef,
    // 削除
    deleteApi,
    // 新規・編集
    editApi,
    downloadApi,
    uploadApi,
  };

  return (
    <CombinationSettingContext.Provider value={value}>
      {children}
    </CombinationSettingContext.Provider>
  );
}

export function useCombinationSettingContext() {
  return useContext(CombinationSettingContext);
}
