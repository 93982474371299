import { TablePagination } from '@material-ui/core';

export default function CustomTablePagination(props) {
  const {
    rowsPerPageOptions,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    totalCount,
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TablePagination
      count={totalCount}
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="表示行数"
      component="div"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
    />
  );
}
