import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const webResCreateCustomerSlice = createSlice({
  name: 'webResCreateCustomer',
  initialState: { webResCreateCustomer: initialState },
  reducers: {
    webResCreateCustomerApi: (state, data) => {
      state.webResCreateCustomer = data.payload;
    },
  },
});

export const { webResCreateCustomerApi } = webResCreateCustomerSlice.actions;

export default webResCreateCustomerSlice.reducer;
