import { useCallback } from 'react';
import ProcessTableRow from './processTableRow';
import { MEMBERS_REDUCER_KEYS } from '../../../containers/browsingHistory/browsingHistoryReducer';
import CustomTable from './customTable';

const headCells = [
  { id: 'createdAt', label: '日時', sortable: true },
  { id: 'memberStatusCode', label: 'ステータス / メモ' },
];

export default function ProcessTable(props) {
  const {
    data,
    getApiDataFromListApi,
    handleOpenModal = null,
  } = props;

  const prosessTablelimit = localStorage.getItem('prosessTablelimit') ? localStorage.getItem('prosessTablelimit') : 20;
  const setProsessTablelimit = useCallback((current) => {
    localStorage.setItem('prosessTablelimit', current);
  }, []);

  return (
    <CustomTable
      title="処理履歴"
      headCells={headCells}
      total={data.total}
      list={data.histories}
      rowRender={(list) => list.map((row, i) => (<ProcessTableRow
        key={String(i)}
        row={row}
      />))}
      onPageChange={(pageInfo) => {
        getApiDataFromListApi(MEMBERS_REDUCER_KEYS.histories, pageInfo);
      }}
      contentHeight={handleOpenModal ? 130 : null}
      propLimit={prosessTablelimit}
      setPropLimit={setProsessTablelimit}
      isNoSlice
      handleOpenModal={handleOpenModal}
    />
  );
}
