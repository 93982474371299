import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import LibraryShareComponent from '../../components/mailLibrary/libraryShare/libraryShareComponent';
import { randomId } from '../../commonFunction';
import commonStyles from '../../components/styles';
import commonTheme from '../../components/styles/theme';

const useStyles = makeStyles(() => ({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '10000!important',
      '& .MuiPaper-root': {
        width: '100%',
        background: '#F3F3F3',
      },
      '& .MuiDialog-paper': {
        maxWidth: 'none',
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'initial',
        borderRadius: 0,
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
        borderRadius: 0,
      },
    },
  },
  header: {
    borderBottom: 'solid 1px #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '19px 0 8px',
      textAlign: 'center',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
    },
  },
  contentElement: {
    width: '100%',
    position: 'static',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    [commonTheme.breakpoints.up('md')]: {
      height: '500px',
      overflowY: 'scroll',
      left: '0px',
      top: '53px',
      padding: 24,
      background: '#F3F3F3',
      borderBottom: '#c8c8c8 1px solid',
      '& > div': {
        padding: 0,
        marginTop: 0,
        height: '100%',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0',
    },
    '&>*': {
      boxSizing: 'border-box',
      width: '100%',
    },
  },
  customTitle3: {
    boxSizing: 'border-box',
    width: '100%',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      [commonTheme.breakpoints.up('md')]: {
        marginLeft: 16,
      },
    },
  },
  footer: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 16,
    },
  },
}));

function PaperComponent(props) {
  const { 'aria-labelledby': id } = props;

  return (
    <Draggable
      handle={`[data-drag-id="${id}"]`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function LibraryShareContainer(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [id] = useState(randomId());

  const { open, onClose, onShare } = props;
  // 組織ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState([]);
  // 組織ツリー選択ユーザー
  const [selectDivision, setSelectDivision] = useState([]);

  // 選択押下時
  const onClick = () => {
    onShare(selectUser.map((u) => u.userId));
    onClose();
    setSelectUser([]);
  };
  const userDivList = useSelector((state) => state.userDivList.userDivList);
  const responseHeader = useSelector((state) => state.responseHeader);

  // 自分が所属する組織を再帰的に検索するヘルパー関数
  function findById(data, idToFind) {
    let result = null;

    function search(array) {
      for (const item of array) {
        if (item.divisionId === idToFind) {
          result = item;
          return;
        }
        if (item.children && item.children.length) {
          search(item.children);
          if (result) return;
        }
      }
    }
    search(data);
    return result;
  }

  // 自分が所属する組織の自分以外のユーザーを選択する
  useEffect(() => {
    if (!userDivList.length) return false;

    const userDivision = findById(userDivList, responseHeader.mainDivision.id);

    const filteredUsers = userDivision.users
      .filter(val => val.userId !== responseHeader.userId)
      .map((val) => ({
        userId: val.userId,
        userName: val.userName,
      }));
    return setSelectUser(filteredUsers);
  }, [userDivList, open]);
  return (
    <Dialog
      aria-labelledby={id}
      className={`${classes.dialog}`}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown
      fullWidth
    >
      <DialogTitle className={`${classes.header}`} data-drag-id={id}>
        <Typography className={`${baseClasses.title3} ${classes.customTitle3}`}>
          <span>ライブラリ共有ウィンドウ</span>
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentElement}>
        <LibraryShareComponent
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
        />
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Grid container justifyContent="center" className={classes.btn}>
          <Button
            className={baseClasses.buttonsPrimary}
            onClick={onClick}
            disabled={!selectUser.length}
          >
            選択
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
