import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';
// import MailCreateWindowSp from '../../sp/createWindow';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    '& .MuiDialog-paper': {
      width: '100%',
      margin: '0 8px',
    },
  },
  list: {
    paddingLeft: '16px',
  },
  btn: {
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    fontFamily: 'Roboto',
    padding: '16px',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& > p': {
        fontSize: '13px',
      },
    },
  },
  label: {
    marginRight: '8px',
  },
  icon: {
    fill: theme.palette.primaryColor,
    marginLeft: '10px',
  },
  disable: {
    pointerEvents: 'none',
    background: '#f8f8f8',
  },
  listitem: {
    width: '100%',
    padding: '8px 4px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
}));

export default function EmailSelectListDrawer(props) {
  const {
    user,
    open,
    okButton,
    handleClose = () => {},
    handleChange = () => {},
    initial = {},
  } = props;

  const [checkObj, setCheckObj] = useState(initial);

  const classes = useStyles();
  const common = commonStyles();

  const isValid = (email) => {
    if (!email) return false;
    if (email === '-') return false;
    return true;
  };

  const handleChangeFunc = (event) => {
    handleChange(event);
    setCheckObj({ ...checkObj, [event.target.name]: event.target.checked });
  };

  const checkboxList = () => {
    const list = [];
    for (let i = 1; i < 5; i += 1) {
      const mail = user['mail' + i];
      list.push(
        <FormControlLabel
          key={`mail${i}`}
          className={classes.listitem}
          control={
            <Checkbox
              color="primary"
              onChange={handleChangeFunc}
              checked={checkObj['mail' + i]}
              disabled={!isValid(mail)}
              name={`mail${i}`}
            />
          }
          label={
            <>
              <Typography className={common.title4}>Mail{i}</Typography>
              <Typography className={common.small}>{mail}</Typography>
            </>
          }
        />,
      );
    }
    return list;
  };

  useEffect(() => {
    setCheckObj(initial);
  }, [open]);

  return (
    <CustomSimpleDrawer
      className={classes.dialog}
      open={open}
      onClose={() => handleClose()}
      list={
        <>
          <List className={classes.list}>{checkboxList()}</List>
          <Grid container justifyContent="center">
            <Button
              className={common.buttonsSecondary}
              onClick={() => handleClose()}
            >
              CANCEL
            </Button>
            {okButton}
          </Grid>
        </>
      }
    />
  );
}
