import React, { useState } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import Calendar from '../../common/calendar';
import SelectProduct, { SELECT_PRODUCT_TYPES } from '../selectProduct/selectProduct';

const useStyles = makeStyles((theme) => ({
  resHouseContainer: {
    // width: 476,
    backgroundColor: '#F3F3F3',
    border: 'solid 1px #C8C8C8',
    padding: '17px 8px',
    boxSizing: 'border-box',
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },
  elementLeft: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    padding: 8,
    boxSizing: 'border-box',
    marginBottom: 16,
    '& input': {
      padding: 0,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '160%',
    },
  },
  label: {
    width: 130,
    marginRight: 30,
  },
  contentIpt: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '6px 8px',
    marginBottom: 8,
    boxSizing: 'border-box',
  },
  contentIptDate: {
    marginTop: '-2px',
    '& div': {
      border: 'none',
      padding: 0,
      display: 'flex',
      '& .MuiTypography-root': {
        width: 130,
        marginRight: '30px',
        fontWeight: 700,
      },
      '& label': {
        width: 280,
        cursor: 'pointer',
      },
    },
    '& span': {
      padding: 0,
    },
  },
  input: {
    height: '100%',
    opacity: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
    cursor: 'pointer',
  },
  modalBtn: {
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));

function AddResHouse(props) {
  const { register, setFormValue } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState('');
  const [product, setProduct] = useState({ productType: '', productName: '', siteId: 0 });

  const assignAtOnchange = (value) => {
    setDate(value);
    // 問合せ日
    setFormValue('inquiredAt', value);
  };

  const dataList = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '問合せ日', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: date, // state
      setState: assignAtOnchange, // setState
    },
  ];

  return (
    <>
      <Grid className={classes.resHouseContainer}>
        <Typography style={{ marginBottom: 16 }} className={baseClasses.title4}>
          問合せ物件を追加登録
        </Typography>
        <Grid className={classes.contentIpt}>
          {dataList.map((array) => {
            return (
              <Grid key={array.id} className={classes.contentIptDate}>
                <Calendar data={array} />
              </Grid>
            );
          })}
        </Grid>
        <Grid className={classes.inner}>
          <Typography className={`${baseClasses.title6} ${classes.elementLeft} ${classes.modalBtn}`}>
            <span className={`${baseClasses.title4} ${classes.label}`}>物件名</span>
            <span className={baseClasses.small}>{product.productName}</span>
            <input
              {...register('productName')}
              type="text"
              onClick={() => setOpen(true)}
              className={classes.input}
            />
          </Typography>
        </Grid>
      </Grid>
      <SelectProduct
        open={open}
        setOpen={setOpen}
        product={product}
        setProduct={(data) => {
          setProduct(data);
          setFormValue('siteId', data.siteId);
        }}
        showTypes={[
          SELECT_PRODUCT_TYPES.OHD,
          SELECT_PRODUCT_TYPES.HAWK_ONE,
          SELECT_PRODUCT_TYPES.MAISOKU,
        ]}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default AddResHouse;
