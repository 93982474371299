import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const allocationSettingCsvDownloadSlice = createSlice({
  name: 'allocationSettingCsvDownload',
  initialState: { allocationSettingCsvDownload: initialState },
  reducers: {
    allocationSettingCsvDownloadApi: (state, data) => {
      state.allocationSettingCsvDownload = data.payload;
    },
  },
});

export const { allocationSettingCsvDownloadApi } = allocationSettingCsvDownloadSlice.actions;

export default allocationSettingCsvDownloadSlice.reducer;
