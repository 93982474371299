import { createSlice } from '@reduxjs/toolkit';

// TODO: 以下Component開発者テスト用にinitial作成
const initialState = [];

export const userDivSlice = createSlice({
  name: 'userDiv',
  initialState: {
    userDivList: initialState,
    userDivNoAffiliationList: initialState,
    isUserMaster: false,
    isReload: false,
  },
  reducers: {
    getUserDivListApi: (state, data) => {
      state.userDivList = data.payload.divisions;
      state.userDivNoAffiliationList = data.payload.noAffiliationUsers;
      state.isUserMaster = data.payload.isUserMaster;
    },
    reloadTree: (state) => {
      state.isReload = !state.isReload;
    },
  },
});

export const { getUserDivListApi, reloadTree } = userDivSlice.actions;

export default userDivSlice.reducer;
