import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
} from '@material-ui/core';
import commonStyles from '../../../../styles';
import commonTheme from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIpt3: {
    display: 'block',
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal2: {
    width: '100%',
    marginTop: 8,
  },
  contentIptTxt: {
    width: '100%',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '2px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      [commonTheme.breakpoints.up('md')]: {
        textAlign: 'right',
      },
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
    },
    '& > div': {
      margin: 0,
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 16,
  },
  listItem: {
    padding: 0,
    minHeight: 16,
    '& .MuiButton-label': {
      display: 'block',
      '& > span': {
        display: 'block',
        '&:last-child': {
          marginTop: 8,
        },
      },
    },
  },
}));

export default function RegisterWindowDate(props) {
  const {
    content,
    setContent,
    getParts,
    // getEditDialog,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル用
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  const onBlur = (e) => {
    setContent(e.target.value);
  };

  const getContentSp = () => {
    return (
      <TextField
        className={classes.contentIptTxt}
        defaultValue={content}
        name="content"
        onBlur={onBlur}
        placeholder="-"
      />
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '対応内容',
      click: handleOpen,
      val: content,
      opn: open,
      onClose: handleClose,
      content: getContentSp,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {getParts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          対応内容
        </Typography>
        <Grid className={classes.contentIptVal2}>
          <TextField
            className={classes.contentIptTxt}
            defaultValue={content}
            name="content"
            multiline
            rows="8"
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
