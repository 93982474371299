import { createSlice } from '@reduxjs/toolkit';

// TODO: 正直CreateにSliceは不要そうだが念のため作成。
const initialState = {};

export const userUpdateSlice = createSlice({
  name: 'userUpdate',
  initialState: { userUpdate: initialState },
  reducers: {
    updateUserApi: (state, data) => {
      state.userUpdate = data.payload;
    },
  },
});

export const { updateUserApi } = userUpdateSlice.actions;

export default userUpdateSlice.reducer;
