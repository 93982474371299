import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stations: [
    {
      stationId: '1',
      stationCode: 5,
      alongRailwayId: 7,
      orderCode: 12,
      alongRailwayName: '総武本線',
      shortAlongRailwayName: 'JO',
      name: '成田空港(成田第1ターミナル)',
      shortName: '成田空港',
      kana: 'ナリタクウコウ',
      storeId: '10',
      divisionId: 10,
      isWishStation: 1,
      wishDisplayOrder: 1,
    },
    {
      stationId: '2',
      stationCode: 4,
      alongRailwayId: 8,
      orderCode: 13,
      alongRailwayName: '総武本線',
      shortAlongRailwayName: 'JO',
      name: '成田空港(成田第2ターミナル)',
      shortName: '成田空港',
      kana: 'ナリタクウコウ',
      storeId: '10',
      divisionId: 10,
      isWishStation: 1,
      wishDisplayOrder: 2,
    },
    {
      stationId: '3',
      stationCode: 6,
      alongRailwayId: 9,
      orderCode: 16,
      alongRailwayName: '総武本線',
      shortAlongRailwayName: 'JO',
      name: '三鷹駅',
      shortName: '三鷹',
      kana: '、オタカ',
      storeId: '10',
      divisionId: 10,
      isWishStation: 1,
      wishDisplayOrder: 3,
    },
  ],
};

export const apiStationsSlice = createSlice({
  name: 'stations',
  initialState: { stations: initialState },
  reducers: {
    getStationsApi: (state, res) => {
      state.stations = res.payload;
    },
  },
});

export const { getStationsApi } = apiStationsSlice.actions;

export default apiStationsSlice.reducer;
