import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

/**
 * 【webRes014】未処理反響一覧取得（担当社員ID指定）
 * @module responseGetListByUserIdSlice
 */
export const responseGetListByUserIdSlice = createSlice({
  name: 'responseListbyUserId',
  initialState: { responseListbyUserId: initialState },
  reducers: {
    responseListbyUserIdApi: (state, data) => {
      state.responseListbyUserId = data.payload;
    },
  },
});

export const { responseListbyUserIdApi } = responseGetListByUserIdSlice.actions;

export default responseGetListByUserIdSlice.reducer;
