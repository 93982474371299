import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, InputBase, TextField, Typography, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import commonTheme from '../../styles/theme';
import getTargetFunction from './sp/commonTreeFunction';
import CustomEditDialog from '../../eleCommon/sp/customEditDialog';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #C8C8C8',
    [commonTheme.breakpoints.up('md')]: {
      margin: '4px 0',
      width: '100%',
    },
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '5px',
      borderRadius: '50px',
      width: 'auto',
      margin: '16px 16px 16px 8px',
    },
  },
  searchIcon: {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
    },
  },
  searchText: {
    fontSize: 13,
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
}));
export default function TreeSearch(props) {
  const { list, setSearch,
    setDisplayDivisionRoot, setDisplayDivision,
    setSearchedUserList, setSelectUser, setUsers, setChecked, checked } = props;
  const classes = useStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [open, setOpen] = useState(false); // 検索用のモーダル制御(SP)
  const [searchValue, setSearchValue] = useState(''); // テキストフィールドのバリュー値制御

  function formatSearchValues(values) {
    const word = values.replaceAll('　', ' ').split(' ').filter(Boolean);
    const re = word.join(',');
    return re;
  }

  // グルーピング用
  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const getObjectFunction = (obj, word) => {
    const targetObj = {};
    const divisionIdArray = [];
    let countUser = 0;
    // ユーザーツリー
    const getObject = (theObject, searchWord) => {
      let result = null;
      let appendFlg = false;
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i += 1) {
          result = getObject(theObject[i], word);
          if (result) {
            break;
          }
        }
      } else {
        // 重複時のためDivisionId onlyでまわす
        for (const prop in theObject) {
          if (prop === 'divisionId') {
            const divId = String(theObject.divisionId);
            appendFlg = true;
            divisionIdArray.push(divId);
          }
        }
        for (const prop in theObject) {
          if (prop === 'subName') {
            if (theObject[prop].includes(searchWord)) {
              const divisionKey = 'd' + String(theObject.divisionId);
              if (!targetObj[divisionKey]) {
                targetObj[divisionKey] = [];
              }
              const divList = divisionIdArray.slice(0, divisionIdArray.length);
              targetObj[divisionKey].push({
                targetName: theObject[prop],
                divisionList: divList,
                targetId: theObject.divisionId,
                type: 'division',
              });
              countUser += 1;
            }
          }
          if (prop === 'users' && theObject[prop].length > 0) {
            for (const i of theObject[prop]) {
              if (i.userName.includes(searchWord)) {
                const userKey = 'u' + String(theObject.divisionId);
                if (!targetObj[userKey]) {
                  targetObj[userKey] = [];
                }
                const targetDivisionList = divisionIdArray.slice(0, divisionIdArray.length);
                countUser += 1;
                targetObj[userKey].push({
                  targetName: i.userName,
                  divisionList: targetDivisionList,
                  targetId: i.userId,
                  type: 'user',
                });
              }
            }
          }
          if (
            theObject[prop] instanceof Object || theObject[prop] instanceof Array
          ) {
            result = getObject(theObject[prop], word);
            if (result) {
              break;
            }
          }
        }
      }
      if (appendFlg) {
        divisionIdArray.pop();
      }
      return result;
    };
    getObject(obj, word);
    if (Object.keys(targetObj).length > 0) {
      targetObj.count = countUser;
    }
    return targetObj;
  };

  const handleEnter = (e) => {
    // ここでSearchのHeaderがどちらなのか分かる必要がある
    // 以下で検索ワードに合ったツリーの中身を部分一致
    let searchObj = '';
    searchObj = getObjectFunction(list, e.target.value);
    if ('count' in searchObj || searchObj instanceof Array) {
      setSearch(searchObj);
    }
  };

  // SP:決定ボタンを押した場合
  const handleSetSearchName = (keywords) => {
    setSearchedUserList(true);
    // キーワードからusersのuserDivisionIdを取得
    const resultDivision = getTargetFunction(list, null, keywords);

    if (Object.keys(resultDivision).length !== 0) {
      const resultArray = [];
      resultDivision.users.map((i) => {
        const division = getTargetFunction(list, i.userDivisionId);
        resultArray.push({ data: division, userId: i.userId, divisionId: i.userDivisionId });

        return resultArray;
      });

      // divisionIdでグルーピング
      const groupByDivisionId = groupBy(resultArray, 'divisionId');
      const resultListGroupByDivisionId = Object.keys(groupByDivisionId)
        .map((k) => { return { key: Number(k), value: groupByDivisionId[k] }; });

      setDisplayDivision(resultListGroupByDivisionId); // 表示する要素
      if (setSelectUser) {
        setSelectUser([resultListGroupByDivisionId[0].value[0].data]); // 検索結果の1つ目
      } else {
        setUsers(resultListGroupByDivisionId[0].value[0].data.divisions[0]);
      }
      setChecked(`${resultListGroupByDivisionId[0].value[0].userId}_${resultListGroupByDivisionId[0].value[0].userDivisionId}`);
      console.log(checked);
    } else {
      setDisplayDivision([]);
    }
    setDisplayDivisionRoot([]);
  };

  const content = (
    <TextField
      autoFocus
      className={classes.input}
      onChange={(e) => { return setSearchValue(e.target.value); }}
      name="担当者検索"
      value={searchValue}
    />
  );

  return (
    <Grid container className={classes.wrapper}>
      <Grid className={classes.searchIcon}>
        <SearchIcon onClick={() => { isSp && setOpen(!open); }} />
      </Grid>
      {!isSp ? (
        <InputBase
          placeholder="ユーザー名"
          onKeyPress={(e) => {
            if (e.key === 'Enter' && e.target.value) {
              handleEnter(e);
            }
          }}
        />
      ) : (
        <>
          <CustomEditDialog
            open={open}
            setOpen={setOpen}
            content={content}
            set={() => { handleSetSearchName(formatSearchValues(searchValue)); }}
            lbl="担当者検索"
          />
          <Typography className={classes.searchText}>{formatSearchValues(searchValue)}</Typography>
        </>
      )}
    </Grid>
  );
}
