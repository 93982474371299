// import axios from 'axios';
import client from '../httpClient';

const defaultOption = {
  isShowLoading: false,
  isShowMessage: true,
};

// 【customer005】顧客情報更新
/**
 * @param {string} customerId
 * @param {Record<string, any>} params
 */
const customerUpdateCallApi = async (customerId, params, option) => {
  const result = await client.put(
    `/customers/${customerId}`,
    params,
    {
      ...defaultOption,
      ...option,
    },
  );
  return result;
};

export default customerUpdateCallApi;
