import { useRef, useState } from 'react';

/**
 * validation付き入力フィールドの内部ロジックを提供するCustom Hooks
 * @param {function(value): any} [convertor=(value)=>value]
 *   - 入力値から余計な値を除く関数
 * @param {function(event): void} [onBlur=null]
 *   - 単項目チェック後に実行される関数
 * @param {function(event): void} [onFocus=null]
 *   - フォーカスイン時(入力前)に発火する関数
 * @param {function(value): Array<string>} [validator=null]
 *   - 現在の値が正しいかを評価する関数
 * @param {function(boolean): void} [errorCallback=null]
 *   - エラーメッセージの有無を引数に取るコールバック関数
 * @returns {Object} - Custom Hooksとして外部に公開する値・処理
 */
const useBaseValidation = (
  convertor = (value) => value,
  onBlur = null,
  onFocus = null,
  validator = null,
  errorCallback = null,
  inputRef = null,
) => {
  // `const ref = inputRef ?? useRef(null);`は下記エラーになる
  // React Hook "useRef" is called conditionally. React Hooks must be called in
  // the exact same order in every component render  react-hooks/rules-of-hooks
  const tempRef = useRef(null);
  const ref = inputRef ?? tempRef;
  const [errorMessages, setErrorMessages] = useState([]);
  const [prevValue, setPrevValue] = useState(null);

  /**
   * フォーカス時(onFocus)に、現在の値を保持する関数
   * 単項目チェックを行う条件である値の変更確認に使用
   */
  const handleFocus = (e) => {
    setPrevValue(ref?.current?.value);
    onFocus?.(e);
  };

  /**
   * フォーカス移動時(onBlur)に、入力された値が不正であればフォーカスを戻す関数
   */
  const handleBlur = (e) => {
    e.preventDefault();

    // 値の変更チェック: 変更されていなければ、単項目チェックは行わない
    if (prevValue === e.target?.value) return;

    // 入力値の前後から空白を除く
    let trimmedValue = e.target?.value;
    if (typeof e.target?.value !== 'number') {
      trimmedValue = e.target?.value?.trim();
    }

    // 入力値の変換処理
    const convertedValue = convertor?.(trimmedValue) || trimmedValue;
    if (ref?.current?.value) ref.current.value = convertedValue;

    // 単項目チェックの実行
    const messages = validator?.(convertedValue) || [];

    if (Array.isArray(messages) && !messages.length) onBlur?.(e);

    setErrorMessages(messages);
    errorCallback?.(messages.length !== 0);
  };

  return { ref, handleBlur, handleFocus, errorMessages };
};

export default useBaseValidation;
