import { useState } from 'react';
import {
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Cancel } from '@material-ui/icons';
import commonTheme from '../../../styles/theme';
import { ACTION_CODE_CUSTOM, ACTION_DETAIL_CODE_CUSTOM } from '../../../../constants/customIndex';
import TaiouRirekiSearch from '../../../common/taiouRireki/parts/taiouRirekiSearch';

const useStyles = makeStyles((theme) => ({
  popupbtn: {
    width: '100%',
    position: 'relative',
    [commonTheme.breakpoints.up('md')]: {
      fontFamily: 'Roboto',
      fontSize: '13px',
      borderBottom: '1px #8c8c8c solid',
      borderRadius: 0,
      background: 'transparent top 50% right 5px/17px no-repeat',
      backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%238c8c8c" d="M7 10l5 5 5-5z" /></svg>\')',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#000',
      },
      '&::after': {
        content: '""',
        display: 'block',
        background: '#8c8c8c',
        width: '100%',
        height: 1,
        position: 'absolute',
        left: 0,
        bottom: 8,
        pointerEvents: 'none',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      lineHeight: 1.6,
      fontWeight: '700',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '16px',
      flexShrink: 0,
      borderBottom: '1px solid #F3F3F3',
      textTransform: 'none',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&.disabled': {
      // background: '#C8C8C8',
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
  wrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // '& span > span': {
    //   color: '#D83420',
    // },
  },
  required: { color: '#D83420' },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  btnLbl: { flexShrink: 0 },
  icon: {
    color: theme.palette.primaryColor,
    width: '16px',
    flexShrink: 0,
  },
  buttonText: {
    display: 'flex',
    alignItems: 'center',
    '& span': { fontSize: 13, fontWeight: 400 },
  },
}));

function SearchCheckboxWindowComponent(props) {
  const { values } = props;
  const classes = useStyles();
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    // setLocalActions(values.state);
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
  //   setLocalActions([]);
    setOpen(false);
  };

  const handleClear = () => {
    // setSelectAll([]);
    values.setState({
      actions: [],
    });
  };
  const getKeyByValueActionCode = (object, value) => {
    return Object.keys(object).reduce((r, k) => {
      return object[k] === value ? k : r;
    }, null);
  };
  const getKeyByValueDetailCode = (object, value) => {
    // Object.keys()を使用してオブジェクトのキーを取得し、それをイテレートします。
    for (const key of Object.keys(object)) {
      // 現在のキーの値（配列）に対して、指定された値が含まれているかをチェックします。
      if (object[key].includes(value)) {
        // 指定された値を含むキーを見つけた場合、そのキーを返します。
        return key;
      }
    }
    // 指定された値を含むキーが見つからなかった場合、nullを返します。
    return null;
  };
  const selectedList = () => {
    const result = values.state?.map((data) => {
      const actionCode = getKeyByValueActionCode(ACTION_CODE_CUSTOM, data.actionCode);
      return actionCode + '（' + data.detailCodes.map(detail => getKeyByValueDetailCode(ACTION_DETAIL_CODE_CUSTOM, detail)) + '）';
    });
    return result;
  };
  return (
    <>
      <Button className={`${classes.popupbtn} ${values.disabled && 'disabled'}`} onClick={handleOpen}>
        {!isSP ? (
          <span className={classes.wrap}>
            {values.state?.length === 0 ? (
              <span>
                {values.label}
                {values.required ? <span>＊</span> : ''}
              </span>
            ) : selectedList()}
          </span>
        ) : (
          <span className={classes.wrap}>
            <span className={classes.btnLbl}>
              {values.label}
              {values.required ? <span className={classes.required}>＊</span> : ''}
            </span>

            {values.state?.length === 0 ? (
              <span>
                <Add className={classes.addButtonIcon} />
              </span>
            ) : (
              <span className={classes.buttonText}>
                <span>{selectedList()}</span>
                <Cancel
                  className={`${classes.icon} ${classes.cancel}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClear();
                  }}
                />
              </span>
            )}
          </span>
        )}
      </Button>
      <TaiouRirekiSearch
        open={open}
        onClose={handleClose}
        search={{ actions: values.state }}
        setSearch={(newState) => {
          values.setState({
            actions: newState.actions,
          });
        }}
      />
    </>
  );
}

export default SearchCheckboxWindowComponent;
