import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccountTreeOutlined } from '@material-ui/icons';
import commonTheme from '../styles/theme';
import UserTree from '../common/userTreeNew';
import Search from './search';
import ActionScheduleTabs from './parts/actionScheduleTabs';
import { useActionSchedule } from '../../containers/actionSchedule/actionScheduleContext';
import commonStyles from '../styles';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      borderTop: '2px solid #8C8C8C',
    },
  },
  side: {
    [commonTheme.breakpoints.up('md')]: {
      width: 242,
      flexShrink: 0,
      height: 'calc(100vh - 112px)',
      overflowY: 'auto',
      overflowX: 'hidden',
      '& .MuiAccordion-root.Mui-expanded:last-child': {
        marginTop: 0,
      },
      '& > .MuiAccordion-root': {
        maxHeight: '40%',
      },
    },
  },
  main: {
    [commonTheme.breakpoints.up('md')]: {
      width: 'calc(100vw - 240px)',
      borderLeft: '2px solid #8C8C8C',
      boxSizing: 'border-box',
      height: 'calc(100vh - 112px)',
      overflow: 'auto',
    },
  },
  ad: { padding: 0 },
});

function Main() {
  const {
    actionsWeeks,
    isOpenTree,
    setIsOpenTree,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    setTargetDivisionTree,
  } = useActionSchedule();

  const classes = useStyles();
  const baseClasses = commonStyles();
  const [expanded, setExpanded] = useState('panel2');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  // 検索と選択対象
  const [targetFlgList] = useState({
    userFlg: false,
    divisionFlg: false,
    customerFlg: true,
  });

  let mainWidth;
  if (isSP) {
    mainWidth = '100%';
  } else {
    mainWidth = 1680;
    if (actionsWeeks.date.length > 0) {
      mainWidth = 210 + (210 * actionsWeeks.date.length);
    }
  }

  if (isSP) {
    contents = (
      <Grid className={classes.root}>
        {isOpenTree && (
          <UserTree
            isOpenTree={isOpenTree}
            setIsOpenTree={setIsOpenTree}
            selectUser={selectUser}
            setSelectUser={setSelectUser}
            selectDivision={selectDivision}
            setSelectDivision={setSelectDivision}
            showOnlyDivisions
            setTargetDivisionTree={setTargetDivisionTree}
            isNotSearch
            isDialog
          />
        )}
        <Grid className={classes.main} Tabsstyle={{ width: mainWidth }}>
          <ActionScheduleTabs
            targetFlgList={targetFlgList}
          />
        </Grid>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Grid className={classes.side}>
          <Search />
          <Accordion
            square
            className={baseClasses.sideAccordion}
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={baseClasses.sideAccordionHeader}
            >
              <AccountTreeOutlined style={{ color: '#8C8C8C' }} fontSize="small" />
              反響ツリー
            </AccordionSummary>
            <AccordionDetails
              className={classes.ad}
            >
              {(selectDivision.divId !== 0) && (
                <UserTree
                  isOpenTree={isOpenTree}
                  setIsOpenTree={setIsOpenTree}
                  selectUser={selectUser}
                  setSelectUser={setSelectUser}
                  selectDivision={selectDivision}
                  setSelectDivision={setSelectDivision}
                  showOnlyDivisions
                  setTargetDivisionTree={setTargetDivisionTree}
                  isNotSearch
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className={classes.main}>
          <ActionScheduleTabs
            targetFlgList={targetFlgList}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid style={{ position: 'relative', zIndex: 0 }}>
      {contents}
    </Grid>
  );
}

export default Main;
