import { Resizable } from 're-resizable';
import { Grid, makeStyles } from '@material-ui/core';

// 使い方 mailLibrary/index.jsでも使っています
// import ResizableWrapper from '../eleCommon/resizableWrapper';
// <ResizableWrapper
//   resize={<左側または上に入れるコンポーネント />} // 必須
//   column // 上下の場合のみ入れる
// >
//   <右側または下側に入れるコンポーネント />
// </ResizableWrapper>

const useStyles = makeStyles({
  column: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    '& *::webkit-scrollbar': { width: 10, height: 10 },
  },
  row: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    '& *::webkit-scrollbar': { width: 10, height: 10 },
  },
  borderBottom: { borderBottom: '1px rgba(51, 51, 51, 0.4) solid' },
  borderRight: {
    borderRight: '1px rgba(51, 51, 51, 0.4) solid',
    minHeight: 'calc(100vh - 57px)',
    paddingBottom: '52px',
    overflow: 'hidden',
    background: '#F3F3F3',
    '& > .MuiGrid-root': {
      background: 'none',
    },
  },
  child: { flexGrow: 1 },
});

export default function ResizableWrapper(props) {
  const { resize, column, children, defaultSize } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      className={column
        ? classes.column
        : classes.row}
    >
      <Resizable
        defaultSize={defaultSize}
        className={column
          ? classes.borderBottom
          : classes.borderRight}
      >
        {resize}
      </Resizable>
      <Grid item className={classes.child}>{children}</Grid>
    </Grid>
  );
}
