import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
} from '@material-ui/core';
import commonTheme from '../../styles/theme';
// eslint-disable-next-line import/no-cycle
import DayRnd from './dayRnd';
// eslint-disable-next-line import/no-cycle
import DayRndSp from './dayRndSp';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles({
  sub: {
    padding: 0,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      minWidth: 60,
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '&:first-child': {
      '& > span > span': {
        [commonTheme.breakpoints.up('md')]: {
          borderTop: '1px solid #C8C8C8',
        },
      },
    },
  },
  subLbl: {
    display: 'block',
    borderBottom: '1px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      height: '38px!important',
      borderRight: '1px solid #C8C8C8',
      background: '#fff',
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 2,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 176,
      height: 42,
      marginLeft: 16,
      borderRight: '1px solid #C8C8C8',
      borderLeft: '1px solid #C8C8C8',
    },
    '&::after': {
      [commonTheme.breakpoints.down('sm')]: {
        content: '""',
        display: 'block',
        width: '102%',
        height: 15,
        background: '#fff',
        position: 'absolute',
        zIndex: 3,
        top: '-15px',
        left: 0,
      },
    },
    '& > span': {
      height: '100%',
      [commonTheme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
      },
      '& > span': {
        [commonTheme.breakpoints.down('sm')]: {
          display: 'block',
          textAlign: 'center',
        },
        '&:first-child': {
          [commonTheme.breakpoints.down('sm')]: {
            fontWeight: 700,
            fontSize: 13,
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 2,
          },
        },
        '&:last-child': {
          [commonTheme.breakpoints.down('sm')]: {
            fontSize: 10,
          },
        },
      },
    },
  },
  subLbl2: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
      height: 20,
      background: '#F3F3F3',
      borderTop: '1px solid #C8C8C8',
      borderRight: '1px solid #C8C8C8',
      borderBottom: '1px solid #C8C8C8',
    },
  },
  subTime: {
    [commonTheme.breakpoints.down('sm')]: {
      height: '38px!important',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      height: 42,
    },
  },
  subItem: {
    display: 'block',
    background: '#F3F3F3',
    borderBottom: '1px solid #C8C8C8',
    borderRight: '1px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      height: 38,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 56,
      height: '100%',
      borderRight: '1px solid #C8C8C8',
    },
  },
});

export default function DayRow(props) {
  const {
    action,
    divisions,
    setOpenCustomertree,
    countUpChild,
    noChargeFlg,
  } = props;

  const {
    targetDivisionTree,
    searchInput,
    actionScheduleDate,
    dateConversion2,
  } = useActionSchedule();

  const classes = useStyles();
  // 【schedule010】行動予定詳細のグローバルステート
  const schedulesGetActionDetail = useSelector(
    (state) => state.schedulesGetActionDetail.schedulesGetActionDetail,
  );
  // ローカルで更新データを保持
  const [localUpdateData, setLocalUpdateData] = useState({});

  useEffect(() => {
    setLocalUpdateData(schedulesGetActionDetail);
  }, [schedulesGetActionDetail]);

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;
  let list = [];
  let guidanceList = [];
  // ラベルの高さを設定 PC
  const rndHeightList = [];
  let multiBk = -1;
  let positionBk = 0;
  let order = 0;
  let lblHeight = 0;
  let count = 0;
  // 行の高さを設定用 PC
  let rowHeight = 42;
  let rndWidthList;
  // 列の横幅を設定
  let columnWidth;
  // ユーザーの件数
  let userCount;

  if (targetDivisionTree) {
    userCount = targetDivisionTree.users ? targetDivisionTree.users.length + 1 : 1;
  }

  if (divisions.length > 0) {
    if (noChargeFlg) {
      // 担当なし
      list = [{ actions: divisions[0].actions }];
    } else {
      // ユーザーIDで絞り込み
      list = divisions[0].users.filter((item) => {
        return action.userId === item.userId;
      });
    }

    if (list.length > 0) {
      // サイドメニューの検索
      list = [{ actions: searchInput.listSearchFunction(list) }];

      // 案内件数を加算
      guidanceList = list[0].actions.filter((item) => {
        return item.actionScheduleCode === null;
      });
      if (guidanceList.length > 0) {
        countUpChild(guidanceList.length);
      }

      // 開始日と基準日が異なる場合、除外する
      let listDate = [];
      listDate = list[0].actions.filter((item) => {
        return item.startAt.substr(0, 10) === dateConversion2(actionScheduleDate);
      });
      list = [{ actions: listDate }];

      // ラベルの件数分ループ
      for (let i = 0; i < list[0].actions.length; i += 1) {
        // 比較する基準の日時を設定
        const startBase = new Date(list[0].actions[i].startAt);
        const endBase = new Date(list[0].actions[i].endAt);
        // 複数行の判定
        let multi = 0;
        let position = 0;

        // ラベルの件数分ループ
        for (let j = 0; j < list[0].actions.length; j += 1) {
          if (i !== j) { // 自データと異なる場合
            const startAt = new Date(list[0].actions[j].startAt);
            const endAt = new Date(list[0].actions[j].endAt);

            if (startAt >= startBase) { // 開始日時 >= 基準の開始日時
              if (startAt < endBase) { // 開始日時 < 基準の終了日時
                // 複数行
                multi = 1;
                if (j === i + 1) {
                  position = 1;
                }
              }
            }
            if (startAt < endBase) { // 開始日時 < 基準の終了日時
              if (endAt > startBase) { // 終了日時 > 基準の開始日時
                // 複数行
                multi = 1;
                if (j === i + 1) {
                  position = 1;
                }
              }
            }
          }
        }

        if (multiBk === -1 && multi === 1) { // 初回の場合
          // if (multi === 1) {
          // }
          lblHeight = 1; // ラベルの高さ判定用
        } else if (multiBk === 1 && positionBk === 1) { // 前回が複数行判定の場合
          // if (positionBk === 1) {
          // }
          order += 1; // 順番
          lblHeight = 1; // ラベルの高さ判定用
        } else {
          order = 0; // 順番
          if (multi === 1) {
            lblHeight = 1; // ラベルの高さ判定用
          } else {
            lblHeight = 0; // ラベルの高さ判定用
          }
        }

        if (count < order) {
          count = order; // 行の高さ判定用
        }

        // 配列に格納
        rndHeightList.push({ cnt: lblHeight, odr: order });
        // 前回の複数行判定を退避
        multiBk = multi;
        // 前回の位置判定を退避
        positionBk = position;
      }
    }

    if (count > 1) {
      rowHeight += (count - 1) * 22;
    }

    // ラベルの横幅を設定用 SP
    rndWidthList = rndHeightList;

    if (isSP) {
      columnWidth = (windowWidth - 40) / userCount;
      if (columnWidth < 60) {
        columnWidth = 60;
      }
    } else {
      columnWidth = 'auto';
    }
  }

  const getUserName = (text) => {
    const names = text.split(' ');
    return (
      <>
        <span>{names[0]}</span>
        <span>{names[1]}</span>
      </>
    );
  };

  if (isSP) {
    contents = (
      <>
        <span className={classes.subLbl}>
          <span>
            {getUserName(action.userName)}
          </span>
        </span>
        <span className={classes.subLbl2} />
        <span className={classes.subTime}>
          {Array.from(Array(26).keys()).map((i) => (
            <span className={classes.subItem} key={i} />
          ))}
          {list.length > 0 && list[0].actions.map((item, index) => (
            <DayRndSp
              key={item.scheduleId}
              item={item}
              index={index}
              rndWidthList={rndWidthList}
              columnWidth={columnWidth}
              userCount={userCount}
              setOpenCustomertree={setOpenCustomertree}
              localUpdateData={localUpdateData}
              setLocalUpdateData={setLocalUpdateData}
            />
          ))}
        </span>
      </>
    );
  } else {
    contents = (
      <>
        <span className={`${classes.subLbl} rndDay-row-subLbl`} style={{ height: rowHeight }}>
          <span>
            {action.userName}
          </span>
        </span>
        <span className={`${classes.subTime} rndDay-row-subTime`} style={{ height: rowHeight }}>
          {Array.from(Array(26).keys()).map((i) => (
            <span className={`${classes.subItem} rndDay-row-subItem`} key={i} />
          ))}
          {list.length > 0 && list[0].actions.map((item, index) => (
            <DayRnd
              key={item.scheduleId}
              item={item}
              index={index}
              rndHeightList={rndHeightList}
              localUpdateData={localUpdateData}
              setLocalUpdateData={setLocalUpdateData}
              setOpenCustomertree={setOpenCustomertree}
            />
          ))}
        </span>
      </>
    );
  }

  return (
    <ListItem className={`${classes.sub} rndDay-row-sub`} style={{ width: columnWidth }}>
      {contents}
    </ListItem>
  );
}
