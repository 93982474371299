import { Button, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import { useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import FileTableHeader from './fileTableHeader';
import FileTableRow from './fileTableRow';

const useStyles = makeStyles({
  root: { background: '#fff' },
  alertSuccess: {
    position: 'absolute',
    height: '80px',
    right: '0',
    top: '0',
    bottom: '0',
    left: '0',
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
  },
  alertTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0',
  },
});

export default function FileTable(props) {
  const {
    list,
    checkedList,
    setCheckedList,
    handleSelectAllClick,
    updateFiles,
    downloadFiles,
    searchCell,
  } = props;
  const classes = useStyles();
  const [showAlert, setshowAlert] = useState(false);

  const closeAlert = () => {
    setshowAlert(false);
  };

  const cells = [
    { label: 'ファイル名', id: 'directoryName', style: { width: '600px' } },
    { label: 'DL回数', id: 'downloadCount', style: { width: '40px' } },
    { label: 'DL通知', id: 'isNotification', style: { width: '40px' } },
    { label: 'サイズ', id: 'fileSize', style: { width: '40px' } },
    { label: '保存期間', id: 'ratention', style: { width: '40px' } },
    { label: '削除予定日時', id: 'limitAt', style: { width: '110px' } },
    { label: '追加日時', id: 'createdAt', style: { width: '110px' } },
  ];

  return (
    <TableContainer className={classes.root}>
      <Table>
        <FileTableHeader
          cells={cells}
          rowCount={list.length}
          numSelected={checkedList.length}
          onSelectAllClick={handleSelectAllClick}
          searchCell={searchCell}
        />
        <TableBody>
          {list.map((l) => {
            return (
              <FileTableRow
                key={l.shareFileStorageAttachmentId}
                row={l}
                cells={cells}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
                updateFiles={updateFiles}
                downloadFiles={downloadFiles}
                setshowAlert={setshowAlert}
              />
            );
          })}
        </TableBody>
      </Table>
      {showAlert && (
        <Alert
          severity="success"
          className={classes.alertSuccess}
          action={
            <Button color="inherit" size="small" onClick={closeAlert}>
              <Close />
            </Button>
          }
        >
          <AlertTitle className={classes.alertTitle}>
            コピーしました！
          </AlertTitle>
        </Alert>
      )}
    </TableContainer>
  );
}
