import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  alertTitleColor: {
    color: '#f44336',
    textAlign: 'center',
  },
  noticeTitleColor: {
    textAlign: 'center',
  },
  alertBack: {
    backgroundColor: 'rgb(253, 236, 234)',
  },
  userCustomButtonSave: {
    padding: '8px 10px',
    width: '95px',
    height: '40px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '12px 16px',
  },
  textButton: {
    color: theme.palette.primaryColor,
    fontSize: '13px',
    '&:hover': {
      boxShadow: `1px 1px 3px ${theme.palette.primaryColor}`,
      border: `1px solid ${theme.palette.primaryColor}`,
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
}));

function DeleteCheckDivision(props) {
  const {
    isDelete,
    setIsDelete,
    selectDivision,
    moldingParameter,
    updateDivisionDetail,
    localDivision,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const closeDeleteModal = () => {
    setIsDelete(false);
  };

  // 組織削除
  const executionDelete = async () => {
    const updateDetail = moldingParameter(localDivision, true);
    await updateDivisionDetail(localDivision.divisionId, updateDetail);
    setIsDelete(false);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={isDelete}
      onClose={() => closeDeleteModal()}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.noticeTitleColor}>
        組織の削除
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
          本当に {selectDivision.subName} を組織から削除しますか？<br />
          ※この操作は戻せません。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            onClick={() => executionDelete()}
            color="primary"
            className={`${baseClasses.buttonsPrimary} ${classes.userCustomButtonSave}`}
          >
            OK
          </Button>
          <Button
            onClick={() => closeDeleteModal()}
            color="primary"
            className={`${classes.textButton} ${classes.userCustomButtonSave}`}
            style={{ backgroundColor: 'white' }}
          >
            CANCEL
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCheckDivision;
