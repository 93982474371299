import { Checkbox, Collapse, FormControlLabel, Grid, List, ListItem, Typography } from '@material-ui/core';
import { Add, ExpandLess, ExpandMore, Folder, Person, Remove } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import commonStyles from '../../styles';
import commonTreeStyles from './parts/commonTreeStyle';

export default function TreeForMulti(props) {
  const {
    list,
    selectUser,
    setSelectUser,
    search,
  } = props;
  const common = commonStyles();
  const classes = commonTreeStyles();

  // 開閉用ローカルステート
  const [state, setState] = useState({});
  const handleClick = (menu) => {
    setState({ ...state, [`${menu}Open`]: !state[`${menu}Open`] });
  };
  // 検索Enter時のfocus
  const [searchIndex, setSearchIndex] = useState(0);
  // 検索結果の元を保持
  const [initialSearch, setInitialSearch] = useState('');
  const [checked, setChecked] = useState([]);
  useEffect(() => {
    const setList = selectUser.map(s => s.userId);
    setChecked(setList);
  }, []);

  const handleSelectUser = (item) => {
    if (checked.includes(item.userId)) {
      const newList = checked.filter(c => c !== item.userId);
      setChecked(newList);
      const newListObject = selectUser.filter(s => s.userId !== item.userId);
      setSelectUser(newListObject);
    } else {
      setChecked([...checked, item.userId]);
      setSelectUser([...selectUser, item]);
    }
  };

  // ツリーの検索
  useEffect(() => {
    if (search instanceof Object) {
      let targetIndex = searchIndex;
      if (initialSearch && search) {
        if (String(initialSearch) !== String(Object.keys(search))) {
          setSearchIndex(0);
          targetIndex = 0;
        }
      }
      setInitialSearch(Object.keys(search));
      const multipleUser = [];
      let targetUserId = '';
      if (Object.keys(search).length) {
        for (const property in search) {
          for (const div in search[property]) {
            multipleUser.push({
              targetId: search[property][div].targetId,
              divIdList: search[property][div].divisionList,
              type: search[property][div].type,
            });
          }
        }
        for (const property in search) {
          if (
            search[property].length > targetIndex && property !== 'count'
          ) {
            for (const division in search[property]) {
              for (const divId in search[property][division].divisionList) {
                if (
                  String(initialSearch) === String(Object.keys(search)) || !searchIndex
                ) {
                  setSearchIndex(searchIndex + 1);
                } else {
                  setSearchIndex(0);
                }
                if (search[property][division].type === 'user') {
                  setChecked([...checked, search[property][division].targetId]);
                  setSelectUser([...selectUser, search[property][division].targetId]);
                } else if (search[property][division].type === 'division') {
                  setChecked(0);
                }
                targetUserId = search[property][division].targetId;
                setState((prev) => ({
                  ...prev,
                  [`root${search[property][division].divisionList[divId]}Open`]: true,
                }));
                setState((prev) => ({
                  ...prev,
                  [`divi${search[property][division].divisionList[divId]}Open`]: true,
                }));
              }
            }
            break;
            // 対象の総数（ユーザー）
          } else if (property === 'count') {
            setSearchIndex(1);
            targetIndex = 1;
            if (search[Object.keys(search)[0]][0].type === 'user') {
              setChecked([...checked, search[Object.keys(search)[0]][0].targetId]);
              setSelectUser([...selectUser, search[Object.keys(search)[0]][0].targetId]);
            } else if (
              search[Object.keys(search)[0]][0].type === 'division'
            ) {
              setChecked(0);
            }
          } else {
            targetIndex -= search[property].length;
          }
        }
      }
      // 重複Check
      if (multipleUser.length) {
        const multi = multipleUser.filter((e) => e.targetId === targetUserId);
        for (const obj of multi) {
          for (const d of obj.divIdList) {
            setState((prev) => ({ ...prev, [`root${d}Open`]: true }));
            setState((prev) => ({ ...prev, [`divi${d}Open`]: true }));
          }
        }
      }
    }
  }, [search]);

  const userList = (arr) => {
    const userlist = [];
    arr.users.map((u) => {
      return userlist.push(
        <ListItem
          key={`u${u.userId}`}
          className={`${common.small} ${classes.li} ${classes.person}`}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={u.userId}
                checked={checked.includes(u.userId)}
                onChange={() => { handleSelectUser(u); }}
                className={classes.checkbox}
              />
            }
            label={
              <Grid container alignItems="center">
                <Person className={classes.icon} />
                <Typography
                  className={`${classes.treeUser} ${
                    checked.includes(u.userId) ? classes.checkedUser : null
                  }`}
                >
                  {u.userName}
                </Typography>
              </Grid>
            }
          />
        </ListItem>,
      );
    });
    return userlist;
  };
  const childList = (arr) => {
    const childlist = [];
    if ('children' in arr) {
      arr.children.map((c) => {
        return childlist.push(
          <List key={`divi${c.divisionId}`} className={classes.parent}>
            <ListItem
              className={classes.li}
              onClick={() => {
                handleClick(`divi${c.divisionId}`);
              }}
            >
              {state[`divi${c.divisionId}Open`] ? (
                <>
                  <Remove
                    className={classes.iconsPc}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                  <ExpandLess
                    className={classes.iconsSp}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                </>
              ) : (
                <>
                  <Add
                    className={classes.iconsPc}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                  <ExpandMore
                    className={classes.iconsSp}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(`divi${c.divisionId}`);
                    }}
                  />
                </>
              )}
              <Folder className={classes.icon} />
              <Typography
                className={`${classes.treeUser} ${common.small}`}
              >
                {c.subName}
              </Typography>
            </ListItem>
            <Collapse
              in={state[`divi${c.divisionId}Open`]}
              className={classes.child}
              timeout={0}
            >
              {childList(c)}
              {c.users.length
                ? <List>{userList(c)}</List>
                : null}
            </Collapse>
          </List>,
        );
      });
    } else {
      return false;
    }
    return childlist;
  };
  const getListItem = () => {
    return list.map((data) => (
      <div key={data.subName} className={classes.parent}>
        <ListItem
          className={classes.li}
          onClick={() => {
            handleClick(`divi${data.divisionId}`);
          }}
        >
          {state[`divi${data.divisionId}Open`] ? (
            <>
              <Remove
                className={classes.iconsPc}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
              <ExpandLess
                className={classes.iconsSp}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
            </>
          ) : (
            <>
              <Add
                className={classes.iconsPc}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
              <ExpandMore
                className={classes.iconsSp}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(`divi${data.divisionId}`);
                }}
              />
            </>
          )}
          <Folder className={classes.icon} />
          <Typography className={common.small}>{data.subName}</Typography>
        </ListItem>
        <Collapse
          in={state[`divi${data.divisionId}Open`]}
          className={classes.child}
          timeout={0}
        >
          {childList(data)}
          {userList(data)}
        </Collapse>
      </div>
    ));
  };
  return (
    <List className={`${classes.root}`}>{getListItem()}</List>
  );
}
