import React, { useState } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
    background: '#fff',
    padding: '0 0 0 16px',
    '&.active': {
      '& .MuiButtonBase-root': {
        padding: '18px 22px 18px 24px',
      },
      '& .MuiButton-label span:nth-child(2)': {
        display: 'none',
      },
      '& .MuiButton-label span:nth-child(3)': {
        display: 'block',
      },
    },
  },
  label: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 1.2,
    letterSpacing: 0,
    padding: '18px 22px 18px 16px',
    borderBottom: '1px solid #F3F3F3',
    display: 'block',
    width: '100%',
    textAlign: 'left',
  },
  chose: {
    fontSize: 13,
    fontWeight: 400,
    position: 'absolute',
    marginTop: 3,
    top: '50%',
    transform: 'translateY(-50%)',
    right: 34,
    maxWidth: '55%',
    maxHeight: 30,
    // display: 'flex',
    // justifyContent: 'flex-end',
    // alignItems: 'center',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
  open: {
    width: 14,
    height: 14,
    position: 'absolute',
    top: 21,
    right: 14,
    pointerEvents: 'none',
    '&::before': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: '100%',
      height: 2,
      position: 'absolute',
      top: 6,
      left: 0,
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: 2,
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 6,
    },
  },
  close: {
    width: 7,
    height: 7,
    position: 'absolute',
    top: 22,
    left: 8,
    borderTop: `2px solid ${theme.palette.primaryColor}`,
    borderLeft: `2px solid ${theme.palette.primaryColor}`,
    transform: 'rotate(-45deg)',
    pointerEvents: 'none',
    display: 'none',
  },
}));

export default function AreaSentakuLabel(props) {
  const {
    label,
    lower,
    setLower,
    chose,
  } = props;

  const classes = useStyles();

  const [active, setActive] = useState(false);
  const click = () => {
    setActive(!active);
    setLower(!lower);
  };

  return (
    <Grid className={`${classes.root} ${active && 'active'}`}>
      <Button
        className={classes.label}
        onClick={click}
      >
        {label}
        <span className={classes.chose}>{chose}</span>
        <span className={classes.open} />
        <span className={classes.close} />
      </Button>
    </Grid>
  );
}
