import { useSelector } from 'react-redux';
import {
  MAIL_SEND_ALL_AT_ONCE,
  CRM_USE,
  ALL_MASTA_ADMIN,
  RESPONSE_MASTA_ADMIN,
  STORE_MOVE_MASTA_ADMIN,
  STORE_MOVE_MASTA_ADMIN_RESPONSIBLE_AREA_ONLY,
  SALES_OWN_STORE_SECTION_ORGANIZATION_MASTA_ADMIN,
  FILE_STORAGE_USE,
  SIMULATOR_MASTA_ADMIN,
} from '../constants/userRole';
import { MARKETING_GROUPS, SYSTEM } from '../constants/userRoleGroup';
import { URL_MAPPER } from '../constants/urls';
import COMPONENT_NAME_MAPPER from '../constants/componentName';
import getEnv from '../commonFunction/getEnv';

const usePermissions = () => {
  // レスポンスヘッダから、ログイン中ユーザに紐づくロールIDを取得
  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleIds, roleGroupId } = responseHeader;

  // デフォルト権限(link)
  const URL_PERMISSION_LIST = {
    '/develop/menu': false,
    // SFA
    '/sfa/customer': true, // 顧客メイン
    '/sfa/direct': true, // 指示一覧
    '/sfa/customer_list': true, // 見込み案件管理表
    '/sfa/unsend_mail': true, // 未送信メール
    '/sfa/mail': false, // 一斉メール
    '/sfa/web': true, // WEB履歴
    '/sfa/change': true, // 顧客一括管理
    '/sfa/library': true, // ライブラリ管理
    '/sfa/schedule': true, // 案内予定一覧
    '/sfa/home': true, // メニュー(SP)
    '/sfa/dashboard': true, // ダッシュボード
    // CRM
    '/crm': false, // CRM
    // MST
    '/mst/user': false, // 組織社員マスタ
    '/mst/store_user_move': false, // 営業自店舗課編成
    '/mst/user_move': false, // 組織情報一括更新
    '/mst/point_allocations': false, // 配点設定
    '/mst/simulator': false, // シミュレーション
    '/mst/response_type': false, // 反響媒体マスタ
    '/mst/store_group': false, // 店舗グループ設定
    '/mst/auto_regist': false, // 自動反響処理モード設定
    '/mst/customer_move': false, // 店舗間顧客移動設定
    '/mst/prohibited': false, // 注意語句一覧
    '/mst/target': false, // 目標設定
    '/mst/combination_setting': false, // 組合せ設定
    '/mst/date_time': false, // 課編成日付設定
    // DEVELOPER
    '/develop/search': false,
    '/develop/usertree': false,
    '/develop/customertree': false,
    '/develop/flyertree': false,
    '/develop/calendar': false,
    '/develop/acquisition_radio': false,
    '/develop/acquisition_text': false,
    '/develop/validation': true,
    '/develop/editor': true,
  };

  // roleId: 10が含まれていれば使用可能なpathを格納する配列
  const roleIdTenPaths = [
    URL_MAPPER.user, // 組織社員マスタ
    URL_MAPPER.target, // 目標設定
    URL_MAPPER.prohibited, // 注意語句一覧マスタ
    URL_MAPPER.auto_regist, // 自動反響処理モード設定
    URL_MAPPER.store_group, // 店舗グループ設定
    URL_MAPPER.combination, // 組合せ設定
    URL_MAPPER.point, // 配点設定
    URL_MAPPER.simulator, // シミュレータ
    URL_MAPPER.user_move, // 組織情報一括更新
  ];

  // roleGroupId: 55が含まれていれば使用可能なpathを格納する配列
  const roleGroupIdFiftyfivePaths = [
    URL_MAPPER.developer, // 開発リンク
    URL_MAPPER.dev_search, // 開発用 詳細簡易検索
    URL_MAPPER.dev_usertree, // 開発用 組織ツリー
    URL_MAPPER.dev_customertree, // 開発用 反響ツリー
    URL_MAPPER.dev_flyertree, // 開発用 源泉未処理ツリー
    URL_MAPPER.dev_calendar, // 開発用 カレンダー
    URL_MAPPER.dev_acquisition_radio, // 開発用 獲得者区分RadioButton
    URL_MAPPER.dev_acquisition_text, // 開発用 獲得者区分Text
    // URL_MAPPER.dev_validation, // 開発用 入力項目チェック
  ];

  // roleId: 38が含まれていれば使用可能なpathを格納する配列
  const roleIdThirtyeightPaths = [
    URL_MAPPER.simulator, // シミュレータ
  ];

  /**
   * 対象画面のURL・ログイン中ユーザに紐づくロールIDから、権限の有無を判定する
   * @param {*} urlPath 対象画面のpath
   * @returns true or false(権限有無)
   */
  const checkUrlPermission = (urlPath) => {
    // URL_PERMISSION_LISTにてデフォルトでfalseとなっている画面については、
    // roleIds(ログイン中ユーザに紐づくroleIdの配列)に含まれるロールIDにより権限可否を判定する

    // デフォルトの権限可否
    const result = URL_PERMISSION_LIST?.[urlPath];

    // 一斉メール画面 -> roleId: 2(一斉メール送信)の有無
    if (urlPath === URL_MAPPER.mail && roleIds.includes(MAIL_SEND_ALL_AT_ONCE)) {
      return !result;
    }

    // CRM画面 -> roleId: 8(CRM利用)の有無
    if (urlPath === URL_MAPPER.crm && roleIds.includes(CRM_USE)) {
      return !result;
    }

    // 組織社員マスタ・目標設定・注意語句一覧マスタ・自動反響処理モード設定・店舗グループ設定・組合せ設定・配点設定・シミュレータ・組織情報一括更新
    // roleId: 10(全マスタ管理)の有無
    if ((roleIdTenPaths.includes(urlPath)) && roleIds.includes(ALL_MASTA_ADMIN)) {
      return !result;
    }

    // シミュレータ
    // roleId: 38(シミュレーター利用)の有無
    if ((roleIdThirtyeightPaths.includes(urlPath)) && roleIds.includes(SIMULATOR_MASTA_ADMIN)) {
      return !result;
    }

    // 反響媒体マスタ -> roleId: 10(全マスタ管理) or 11(反響マスタ管理)の有無
    if (urlPath === URL_MAPPER.response_type
      && (roleIds.includes(ALL_MASTA_ADMIN) || roleIds.includes(RESPONSE_MASTA_ADMIN))) {
      return !result;
    }

    // 店舗間顧客移動設定 -> roleId: 10(全マスタ管理) or 30(店舗間移動マスタ管理) or 31(店舗間移動マスタ管理(担当エリア限定))の有無
    // FIXME: roleGroupIdではなくroleIdで判定してほしい
    if (urlPath === URL_MAPPER.customer_move
      && !MARKETING_GROUPS.includes(Number(roleGroupId))
      && (roleIds.includes(ALL_MASTA_ADMIN)
      || roleIds.includes(STORE_MOVE_MASTA_ADMIN)
      || roleIds.includes(STORE_MOVE_MASTA_ADMIN_RESPONSIBLE_AREA_ONLY))) {
      return !result;
    }

    // 営業自店舗課編成 -> roleId: 10(全マスタ管理) or 32(営業自店舗課編成マスタ管理)の有無
    // FIXME: roleGroupIdではなくroleIdで判定してほしい
    if (urlPath === URL_MAPPER.store_user_move
      && !MARKETING_GROUPS.includes(Number(roleGroupId))
      && (roleIds.includes(ALL_MASTA_ADMIN)
      || roleIds.includes(SALES_OWN_STORE_SECTION_ORGANIZATION_MASTA_ADMIN))) {
      return !result;
    }

    // 課編成日付設定 -> 30(店舗間移動マスタ管理) の有無
    if (urlPath === URL_MAPPER.date_time
      && roleIds.includes(STORE_MOVE_MASTA_ADMIN)) {
      return !result;
    }

    // 組織情報一括更新_履歴 -> roleId: 10(全マスタ管理)の有無
    if (urlPath === URL_MAPPER.user_move_history
      && roleIds.includes(ALL_MASTA_ADMIN)) {
      return !result;
    }

    // roleGroupId: 55(情シス権限)の有無※本番環境以外
    if (getEnv() !== 'PRODUCTION'
    && (roleGroupIdFiftyfivePaths.includes(urlPath))
    && Number(roleGroupId) === SYSTEM) {
      return !result;
    }

    // デフォルトのまま返却する
    return result;
  };

  /**
   * 対象コンポーネント・ログイン中ユーザに紐づくロールIDから、権限の有無を判定する
   * @param {*} componentKey 対象コンポーネントのkey
   * @returns true or false(権限有無)
   */
  const checkComponentPermission = (componentKey) => {
    // デフォルトの権限可否
    const result = false;

    // 営業カレンダーマスタ roleId: 10(全マスタ管理)の有無
    if (componentKey === COMPONENT_NAME_MAPPER.calendarMaster
      && roleIds.includes(ALL_MASTA_ADMIN)) {
      return !result;
    }

    // ファイルストレージ roleId: 35(ファイルストレージ利用)の有無
    if (componentKey === COMPONENT_NAME_MAPPER.menuFileStorage
      && roleIds.includes(FILE_STORAGE_USE)) {
      return !result;
    }

    // デフォルトのまま返却する
    return result;
  };

  return { checkUrlPermission, checkComponentPermission };
};

export default usePermissions;
