// import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ProcessTableRow from '../processTableRow';

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
    paddingBottom: 16,
  },
  TableBody: {
    borderTop: 'none',
  },
  th: {
    padding: '5px 0 10px 5px',
    fontWeight: '700',
    borderBottom: 'none',
    '&:first-child': {
      marginRight: 16,
    },
  },
});

export default function ProcessTableSp(props) {
  const {
    list,
  } = props;

  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>日時</TableCell>
            <TableCell className={classes.th}>ステータス / メモ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.TableBody}>
          {list.map((row, i) => {
            return (
              <ProcessTableRow
                key={String(i)}
                row={row}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
