import { createSlice } from '@reduxjs/toolkit';

// TODO: 正直CreateにSliceは不要そうだが念のため作成。
const initialState = {};

export const userHistorySlice = createSlice({
  name: 'userHistoryUpdate',
  initialState: { userHistory: initialState },
  reducers: {
    updateUserHistoryApi: (state, data) => {
      state.userHistory = data.payload;
    },
  },
});

export const { updateUserHistoryApi } = userHistorySlice.actions;

export default userHistorySlice.reducer;
