import { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import ContactTotalWrapper from '../../../components/common/contactTotal/contactTotal';

import schedulesGetGuidancesByCustomerIdApi from '../../../apis/schedules/schedulesGetGuidancesByCustomerIdApi';
import schedulesGetContactsCountApi from '../../../apis/schedules/schedulesGetContactsCountApi';
import schedulesGetContactsTimeApi from '../../../apis/schedules/schedulesGetContactsTimeApi';

import { getPromiseSettledResult } from '../../../commonFunction';

function ContactTotalContainers(props) {
  const { customer } = props;
  const { customerId } = customer;

  const [guidancesObj, setGuidancesObj] = useState({});
  const [contactObjs, setContactObjs] = useState([]);
  const [totalTime, setTotalTime] = useState();

  useEffect(async () => {
    const promises = [
      schedulesGetGuidancesByCustomerIdApi(customerId),
      schedulesGetContactsCountApi(customerId),
      schedulesGetContactsTimeApi(customerId),
    ];
    const [
      guidancesApi,
      contactsCountApi,
      contactsTimeApi,
    ] = await Promise.allSettled(promises);
    setGuidancesObj(getPromiseSettledResult(guidancesApi, {})?.data);
    setContactObjs(getPromiseSettledResult(contactsCountApi, [])?.data.types);
    setTotalTime(getPromiseSettledResult(contactsTimeApi)?.data.totalContactMinutes);
  }, [customer]);

  return (
    <ContactTotalWrapper
      totalTime={totalTime}
      guidancesObj={guidancesObj}
      contactObjs={contactObjs}
      customer={customer}
    />
  );
}

export default ContactTotalContainers;
