// import React, { useEffect, useState } from 'react';
import
{ Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../styles';
import ResisterHouseWindowButton from '../parts/sp/resisterHouseWindowButton';
import ResisterHouseWindowCalendar from '../parts/sp/resisterHouseWindowCalendar';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  container: {
    height: '100vh',
    overflowY: 'hidden',
  },
  header: {
    padding: '24px 24px 8px',
  },
  paperTtl: {
    textAlign: 'center',
  },
  content: {
    backgroundColor: '#F3F3F3',
    padding: '16px 0',
    height: '100%',
  },
});

// 日時をresHouse002のバリデーションの形式に合わせる（Y/m/d H:i:s）
const dateCreate = (value) => {
  const v = new Date(value);
  const year = v.getFullYear();
  const month = ('00' + (v.getMonth() + 1)).slice(-2);
  const day = ('00' + v.getDate()).slice(-2);
  const newDate = year + '/' + month + '/' + day + ' 00:00:00';
  return newDate;
};

function ResisterHouseWindowSp(props) {
  const {
    open,
    setOpen,
    onClose,
    date,
    setDate,
    house,
    setHouse,
    registerNewProdAndUpdateHouseList,
    customerId,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const dataList = [
    {
      type: 'calendar',
      key: 'date',
      label: '問合せ日',
      state: date,
      setState: (e) => { return setDate(e); },
    },
    {
      type: 'text',
      key: 'house',
      label: '物件名',
      state: house,
      setState: (e) => { return setHouse(e); },
    },
  ];

  const switchType = (val) => {
    const returnType = [];

    switch (val.type) {
      case 'calendar':
        return <ResisterHouseWindowCalendar type="new" values={val} />;
      case 'text':
        return <ResisterHouseWindowButton values={val} />;
      default:
        break;
    }
    return returnType;
  };

  const handleRegist = () => {
    const newProductParam = {
      customerId,
      siteId: house.siteId,
      inquiredAt: dateCreate(date),
    };
    setOpen(false);
    // setDate('');
    // [/resHouse/regist]APIを呼び出す処理
    registerNewProdAndUpdateHouseList(newProductParam);
    setHouse('');
  };

  const body = (
    <Grid className={classes.container}>
      <DialogTitle
        id="draggable-dialog-title"
        className={`${baseClasses.header}`}
      >
        <Typography className={`${baseClasses.title2} ${classes.paperTtl}`}>
          問合せ物件登録
        </Typography>
        <Button
          className={baseClasses.closeButton}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        {dataList.map((item) => (
          <Grid key={item.key} className={classes.item}>
            {switchType(item)}
          </Grid>
        ))}
      </DialogContent>
      <Grid container justifyContent="center" className={`${baseClasses.fixedFooter} ${classes.buttons}`}>
        <Button
          className={`${baseClasses.buttonsPrimary} ${classes.footerBtn2}`}
          onClick={handleRegist}
          disabled={!date || !house}
        >
          登録
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={`${baseClasses.maxWidthDialog} ${baseClasses.cancelIndex} ${classes.dialog}`}
    >
      {body}
    </Dialog>
  );
}

export default ResisterHouseWindowSp;
