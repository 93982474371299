import React from 'react';
import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  TableBody,
  Link,
  Tooltip,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import commonStyles from '../../styles';
import omit from '../../../commonFunction/omit';
import EmptyAlert from './emptyAlert';
import Customer from './customer';
import { classNames } from '../../../commonFunction';
import EllipsisWrap from './ellipsisWrap';
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: '#F3F3F3',
    '& .MuiAccordionDetails-root': {
      display: 'block',
      padding: 0,
    },
  },
  bodyContent: {
    '& .MuiTable-root': {
      background: '#fff',
      border: 'solid 1px C8C8C8',
    },
  },
  tableContainer: {
    maxHeight: 147,
    border: 'solid 1px #C8C8C8',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .material-icons': {
      color: '#D83420',
      fontSize: 18,
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  headerLinkTxt: {
    color: theme.palette.primaryColor,
  },
  buttonFlex: {
    display: 'flex',
    flexDirection: 'flex-start',
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
      '& .MuiIconButton-label': {
        color: theme.palette.primaryColor,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  toggleButton: {
    padding: 0,
    textAlign: 'left',
    display: 'block',
    minWidth: 10,
  },
  tableHead: {
    '& th': {
      backgroundColor: '#fff',
    },
  },
  headerCell: {
    color: '#8C8C8C',
    textAlign: 'center',
    borderBottom: 'solid 2px #C8C8C8',
    padding: '12px 0 6px 0',
  },
  expireAt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingRight: 18,
    '& .material-icons': {
      opacity: 0,
      color: '#D83420',
      fontSize: 18,
    },
    '& .show': {
      opacity: 1,
    },
  },
  bodyTableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '& td': {
      fontSize: 13,
      padding: '5px 0',
      position: 'relative',
    },
    '& td:not(.hook-left)': {
      textAlign: 'center',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
  },
}));

const headerCells = [
  { id: 'number', label: 'No' },
  { id: 'deadLine', label: '期限' },
  { id: 'status', label: '状態', width: '80px' },
  { id: 'customer', label: '顧客' },
  { id: 'importance', label: '重要度' },
  { id: 'memo', label: 'メモ' },
  { id: 'resPerson', label: '担当' },
];

const judgeDirectStatus = (directStatus) => {
  let result = '';
  switch (directStatus) {
    case 1:
      result = '未確認';
      break;
    case 2:
      result = '対応予定';
      break;
    case 3:
      result = '承認待ち';
      break;
    default:
      result = '';
  }
  return result;
};

const judgeImportance = (importantCode) => {
  let result = '';
  switch (importantCode) {
    case 1:
      result = '低';
      break;
    case 2:
      result = '中';
      break;
    case 3:
      result = '高';
      break;
    default:
      result = '';
  }
  return result;
};

// dateは現在より前か
export const checkExpiredTime = (date) => {
  if (!date) return false;
  // replaceはRFC2822/ISO化のため
  return moment().isAfter(date.replace(/\//g, '-'));
};

/**
 * expireAtの形をMM/DD-HH:mmに変更
 * @param {string} expireAt
 * @return {string | null}
 */
const formatDate = (input) => {
  if (!input) return null;
  return moment(input, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD-HH:mm');
};

function IncompleteList(props) {
  const { dataList } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const { directs = [], total = 0 } = dataList.data || {};

  const tableBody = (
    <TableBody>
      {directs.map((data, index) => {
        return (
          <TableRow
            className={classes.bodyTableRow}
            key={String(index)}
            hover
            style={checkExpiredTime(data.expireAt) ? { backgroundColor: 'rgba(216, 52, 32, 0.08)' } : {}}
          >
            <TableCell
              style={{ width: '3%' }}
            >
              {index + 1}
            </TableCell>
            <TableCell style={{ width: '11%' }}>
              <Grid className={classes.expireAt}>
                <Icon className={classNames({ show: checkExpiredTime(data.expireAt) })}>
                  local_fire_department
                </Icon>
                {formatDate(data.expireAt)}
              </Grid>
            </TableCell>
            <TableCell style={{ width: '7%' }} className="hook-left">
              {judgeDirectStatus(data.directStatusCode)}
            </TableCell>
            <Customer
              id={data.customerId}
              fullName={`${[data.customerLastName, data.customerFirstName].filter(Boolean).join(' ')}`}
              rating={data.userLevel}
              status={data.memberStatusCode}
            />
            <TableCell style={{ width: '5%' }}>{judgeImportance(data.importantCode)}</TableCell>
            <Tooltip title={data.content}>
              <TableCell style={{ width: '25%' }} className="hook-left">
                <EllipsisWrap>{data.content}</EllipsisWrap>
              </TableCell>
            </Tooltip>
            <Tooltip title={`${data.divisionSubName} ${data.userLastName}${data.userFirstName}`}>
              <TableCell style={{ width: '15%' }}>
                {omit(data.divisionSubName, 9)} {data.userLastName}{data.userFirstName}
              </TableCell>
            </Tooltip>
          </TableRow>
        );
      })}
    </TableBody>
  );

  const body = (
    <TableContainer className={`${classes.tableContainer} dashbordScrollArea`}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headerCells.map((headerCell) => {
              return (
                <TableCell
                  key={headerCell.id}
                  className={`${baseClasses.title4} ${classes.headerCell}`}
                  colSpan={headerCell.id === 'customer' ? 3 : 1}
                >
                  <Grid style={{ width: headerCell.width }}>{headerCell.label}</Grid>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
      {!total && <EmptyAlert />}
    </TableContainer>
  );

  return (
    <Accordion
      className={classes.accordion}
      defaultExpanded
    >
      <Grid className={classes.headerContainer}>
        <Grid className={classes.buttonFlex}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.toggleButton} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          />
          <Grid className={classes.buttonContainer}>
            <Icon>local_fire_department</Icon>
            <Typography className={baseClasses.title3}>
              未完了指示 ({total})
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Link href={URL_MAPPER.direct}>
            <Typography className={`${baseClasses.smallStrong} ${classes.headerLinkTxt}`}>
              指示一覧で確認
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <AccordionDetails>
        <Grid className={classes.bodyContent}>
          {body}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default IncompleteList;
