import { MenuItem, Select } from '@material-ui/core';
import { FAMILY_STRUCTURE } from '../../../constants';
import ListItem from './listItem';

export default function AddFamily(props) {
  const { data, setData, readOnly } = props;
  const items = Object.keys(FAMILY_STRUCTURE);

  console.log('data.familyStructureCode');
  console.log(data.familyStructureCode);

  return (
    <ListItem
      label="家族構成"
      readOnly={readOnly}
      noBtn
    >
      <Select
        onChange={(e) => { setData({ ...data, familyStructureCode: e.target.value }); }}
        value={data.familyStructureCode !== null ? data.familyStructureCode : 0}
      >
        <MenuItem
          value={0}
          disabled
        >
          未選択
        </MenuItem>
        {items.map(i => {
          return (
            <MenuItem
              key={i}
              value={Number(i)}
            >
              {FAMILY_STRUCTURE[i]}
            </MenuItem>
          );
        })}
      </Select>
    </ListItem>
  );
}
