import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

// ライブラリツリーから取得したログインユーザーのルートディレクトリ情報を格納する
export const librarydirOwnSlice = createSlice({
  name: 'librarydirOwn',
  initialState: { librarydirOwn: initialState },
  reducers: {
    librarydirOwnApi: (state, data) => {
      state.librarydirOwn = data.payload;
    },
  },
});

export const { librarydirOwnApi } = librarydirOwnSlice.actions;

export default librarydirOwnSlice.reducer;
