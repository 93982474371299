import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  multi: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 8px 0 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  multiBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  multiBtn: {
    padding: '16px 0',
    minWidth: 32,
  },
  multiLbl: {
    textTransform: 'none',
  },
  multiVal: {
    textTransform: 'none',
  },
  multiTxt: {
    textTransform: 'none',
    margin: '0 4px',
  },
});

function EditDialog1(props) {
  const { click1, click2, lbl, fontSize, val1, val2 } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.multi}>
      <Typography className={`${baseClasses.title6} ${classes.multiLbl}`}>
        {lbl}
      </Typography>
      <Grid className={classes.multiBox}>
        <Button
          className={classes.multiBtn}
          onClick={click1}
        >
          <span
            className={`${baseClasses.title4} ${classes.multiVal}`}
            style={{
              fontSize: fontSize || '13px',
            }}
          >
            {val1}
          </span>
        </Button>
        <Typography className={`${baseClasses.title6} ${classes.multiTxt}`}>
          〜
        </Typography>
        <Button
          className={classes.multiBtn}
          onClick={click2}
        >
          <span
            className={`${baseClasses.title4} ${classes.multiVal}`}
            style={{
              fontSize: fontSize || '13px',
            }}
          >
            {val2}
          </span>
        </Button>
      </Grid>
    </Grid>
  );
}

export default EditDialog1;
