import React, { useMemo } from 'react';
import {
  Grid,
  Typography,
  Link,
  ListItem,
  makeStyles,
  List,
} from '@material-ui/core';
import { MailOutline, PhoneForwarded, Warning } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import { useNavigate } from 'react-router-dom';
import commonStyles from '../../../styles';
import dashboardStyle from './dashBoardStyles';
import MemberStatusCode from '../../../eleCommon/memberStatusCode';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../../constants/urls';

const useStyles = makeStyles({
  wrap: {
    textAlign: 'center',
    padding: '40px 0',
  },
  reddate: { color: '#D83420', borderLeft: '#D83420 8px solid' },
  red: { color: '#D83420' },
  icons: { fontSize: 16 },
  count: { display: 'block', lineHeight: 1, textAlign: 'center' },
  itemWrap: { borderBottom: '1px #c8c8c8 solid', padding: '0 16px', background: '#fff' },
  txtWrap: { flexGrow: 1, padding: '16px 0', marginLeft: 16 },
});

function ListItemComponent(props) {
  const { data } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const dCommon = dashboardStyle();

  const navigate = useNavigate();

  const isTelCount = useMemo(() => {
    return Boolean(data?.telCount);
  }, [data?.telCount]);

  const isMailCount = useMemo(() => {
    return Boolean(data?.mailCount);
  }, [data?.mailCount]);

  return (
    <ListItem
      onClick={() => navigate(`${URL_MAPPER.customer}?customerId=${data.customerId}`)}
      className={classes.itemWrap}
    >
      <Grid style={{ marginRight: 8 }}>
        <PhoneForwarded className={classes.icons} />
        <span className={`${classes.count} ${baseClasses.title3} ${!isTelCount && classes.red}`}>{data.telCount}</span>
      </Grid>
      <Grid>
        <MailOutline className={classes.icons} />
        <span className={`${classes.count} ${baseClasses.title3} ${!isMailCount && classes.red}`}>{data.mailCount}</span>
      </Grid>
      <Grid className={classes.txtWrap}>
        <Grid container alignItems="center">
          <Typography className={baseClasses.strong}>
            {data.customerLastName} {data.customerFirstName}
          </Typography>
          <Rating
            value={data.userLevel}
            size="small"
            className={dCommon.stars}
            readOnly
          />
          <MemberStatusCode
            code={data.memberStatusCode}
            hideNonMemberIcon
            thin
          />
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography>
            <span className={baseClasses.verySmall}>反響日 </span>
            <span className={baseClasses.small}>{data.effectAt}</span>
          </Typography>
          <Typography>
            <span className={baseClasses.verySmall}>担当 </span>
            <span className={baseClasses.small}>
              {data.divisionSubName}/{data.userLastName} {data.userFirstName}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}

function CorrespondenceListSp(props) {
  const { dataList } = props;
  const { total = 0, customers = [] } = dataList.data || {};
  const classes = useStyles();
  const baseClasses = commonStyles();
  const dCommon = dashboardStyle();

  return (
    <Grid>
      {!total ? (
        <Grid className={classes.wrap}>
          <Warning className={dCommon.red} />
          <Typography className={`${baseClasses.strong}`}>0 件</Typography>
        </Grid>
      ) : (
        <List>
          {customers.map(l => (
            <ListItemComponent
              key={`key${l.customerId}`}
              data={l}
            />
          ))}
        </List>
      )}
      <Grid container justifyContent="center">
        <Link
          className={baseClasses.buttonsSecondary}
          href={URL_MAPPER.customer}
        >
          <Typography className={`${baseClasses.smallStrong} ${classes.headerLinkTxt}`}>
            顧客メインで表示
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default CorrespondenceListSp;
