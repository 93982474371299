import { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';

import GensenRegisterComponent from '../../components/gensenRegister/gensenRegisterComponent';

// APIリソース群
import responseGetResponseByResponseIdApi from '../../apis/response/responseGetResponseByResponseIdApi';
// import {
//   responseByResponseIdApi,
// } from '../../store/response/responseGetResponseByResponseIdSlice';
import responsePostNewResponseApi from '../../apis/response/responsePostNewResponseApi';

import { useLoading, useStore } from '../../hooks';

// https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4347
// import { RESPONSE_TYPE_GENSEN } from '../../constants/responseType';

// 【webres015】未処理反響情報取得情報
// http://10.22.2.48:8080/docs/#/responses/get-responses-responseId
// a~zの順位
const initialState = {
  acquisitionCode: 0,
  acquisitionMemo: '',
  acquisitionUserFirstName: '',
  acquisitionUserId: 0,
  acquisitionUserLastName: '',
  address1: null,
  address2: null,
  adultCount: null,
  age: null,
  annualIncome: null,
  /**
   * {
   *   id: number,
   *   name: string, // 表示名
   *   base64: string,
   *   mimeType: string,
   *   isRemoved: number, // 1 | 0 フロント側用
   * }
   */
  attachmentFiles: [],
  autoRegistModeCode: null,
  birthday: null,
  bonusPayment: null,
  building: null,
  childCount: null,
  company: null,
  customerFirstName: '',
  customerFirstNameKana: '',
  customerId: '',
  customerLastName: '',
  customerLastNameKana: '',
  deposit: null,
  familyCount: null,
  familyStructureCode: null,
  fax: null,
  fineResponseTypeId: null,
  fineResponseTypeName: null,
  floorPlanCode: null,
  genderCode: null,
  holidayWeekday: '',
  holidayWeekdayMemo: '',
  houseBudgetMax: '',
  houseBudgetMin: '',
  inquiryAt: '',
  isCustomerName: null,
  isFlyerSales: 0,
  isMail1: null,
  isMail2: null,
  isPhoneNumber1: null,
  isPhoneNumber2: null,
  isSendMailLater: 0,
  isThinkApartment: 0,
  isWebMemberId: null,
  jobType: null,
  landBudgetMax: null,
  landBudgetMin: null,
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4347
  // largeResponseTypeId: RESPONSE_TYPE_GENSEN.id,
  // largeResponseTypeName: RESPONSE_TYPE_GENSEN.name,
  largeResponseTypeId: '',
  largeResponseTypeName: '',
  latitude: '',
  livingBuildingType: '',
  longitude: null,
  mail1: '',
  mail2: '',
  mailBody: '',
  mediumResponseTypeId: '',
  mediumResponseTypeName: '',
  memo: '',
  monthlyPayment: '',
  nowRent: '',
  otherTelTime: '',
  phoneNumber1: '',
  phoneNumber2: '',
  prefecture: '',
  railwayId: '',
  railwayName: '',
  reason: '',
  receptAt: '',
  registaProductNumber: '',
  replacementCode: '',
  responseCode: '',
  responseId: null,
  responseImportResultCode: '',
  responseName: '',
  responsibleDivisionId: 0,
  responsibleUserId: '',
  responsibleUserName: '',
  smallResponseTypeId: '',
  smallResponseTypeName: '',
  stationId: '',
  stationName: '',
  subAnnualIncome: '',
  subCompany: '',
  subFirstName: null,
  subFirstNameKana: null,
  subLastName: null,
  subLastNameKana: null,
  telTime: '',
  wishArea: '',
  wishAreaCode: null,
  wishAreaIds: [],
  wishJisAndAreaCode: '',
  wishLandSpace: '',
  wishLivingSpace: '',
  wishMoveInDay: '',
  wishRailway: '',
  wishSchoolDistrict1: '',
  wishSchoolDistrict2: '',
  wishStoreName: '',
  zip: '',
};

export default function GensenRegisterContainer(props) {
  const { responseID, onClose, readOnly } = props;
  const { responseHeader } = useStore();
  const [data, setData] = useState({ ...initialState });
  const [signUpFlg, setSignUpFlg] = useState(false);
  const { addLoading, removeLoading } = useLoading();

  /**
   * 【webres015】未処理反響情報取得
   * @module getResponseByresponseIdFunction
   * @param {} reId 必須
   */
  const getResponseByresponseIdFunction = async (resId) => {
    addLoading('GensenRegister');
    await responseGetResponseByResponseIdApi(resId)
      .then(async (res) => {
        setData(res.responses);
        // await dispatch(responseByResponseIdApi(res));
      })
      .catch(console.error)
      .finally(() => removeLoading('GensenRegister'));
  };

  /**
   * 【webRes004】未処理反響登録
   * @param {object} obj - webres004で登録するデータオブジェクト
   */
  const registerNewResponseFunction = async (obj) => {
    // loadingの表示は/src/components/gensenRegister/index.js
    // hasLoading('GensenRegister')で検索
    addLoading('GensenRegister');
    await responsePostNewResponseApi(obj)
      .then((res) => {
        onClose(res.data);
        setData({ ...initialState });
        // console.log('onClose', onClose);
      })
      .catch(console.error)
      .finally(() => removeLoading('GensenRegister'));
  };

  useEffect(() => {
    if (responseID) {
      getResponseByresponseIdFunction(responseID);
      setSignUpFlg(false);
    } else {
      setData({
        ...initialState,
        // 担当者はデフォルトでログインユーザーを選択する SFA_ASIAQUEST-2980
        responsibleUserId: responseHeader.userId,
        responsibleUserName: responseHeader.userName,
        responsibleUserDivisionId: responseHeader.mainDivision.id,
      });
      setSignUpFlg(true);
    }
  }, [responseID, responseHeader]);

  return (
    <GensenRegisterComponent
      data={data}
      setData={setData}
      registerFunc={registerNewResponseFunction}
      readOnly={readOnly}
      signUpFlg={signUpFlg}
    />
  );
}
