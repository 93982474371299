import { FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import MailTextfield from '../../mail/parts/textfield';
import commonStyles from '../../styles';
import MailTitleAndBody from '../../mail/parts/titleAndBody';

import { useLoading } from '../../../hooks';
import Modal from '../../common/modal';

import { randomId } from '../../../commonFunction';

import { SIGNITURE_EDIT_WINDOW } from '../../../constants/loading';

const useStyles = makeStyles({
  radioWrapper: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '12px 8px',
    alignItems: 'center',
    marginBottom: '24px',
  },
  radioGroup: {
    flexDirection: 'row',
    marginLeft: '24px',
  },
  label: {
    marginRight: '24px',
    '& > span': { fontSize: '13px' },
  },
  radio: {
    padding: 0,
    marginRight: '4px',
    '& svg': { width: '20px' },
  },
  dialogActions: {
    justifyContent: 'center',
  },
  gridDialog: {
    padding: '1rem 1rem',
  },
  footer: {
    borderTop: '1px solid #C8C8C8',
    padding: 16,
    margin: '24px -24px -24px',
    background: '#fff',
    width: 'calc(100% + 48px)',
  },
});

export default function LibrarySignatureComponent(props) {
  const {
    open,
    signature,
    onSubmit,
    onClose,
  } = props;

  const common = commonStyles();
  const classes = useStyles();

  const { hasLoading } = useLoading();

  const [editorState, setEditorState] = useState('');
  const [initialData, setInitialData] = useState({
    libraryName: signature.libraries[0].libraryName,
    mailBody: signature.libraries[0].mailBody,
    count: 0,
  });

  const [localVal, setLocalVal] = useState({
    libraryDirectoryId: null,
    radiovalOld: 0,
    radioval: 0,
    clickNum: 0,
    displayData: signature.libraries[0],
  });

  const [mailBodyKey, setMailBodyKey] = useState('');

  // 設定
  const setting = {
    title: {
      label: '名前',
      state: localVal.displayData?.libraryName || '',
      setState: (e) => {
        return setLocalVal({
          ...localVal,
          displayData: {
            ...localVal.displayData,
            libraryName: e,
          },
        });
      },
      style: { width: '100%' },
      require: true,
      maxLengthInt: 250,
    },
    mailBody: {
      state: localVal.displayData?.mailBody || '',
      setState: (e) => {
        return setLocalVal({
          ...localVal,
          displayData: {
            ...localVal.displayData,
            mailBody: e,
          },
        });
      },
      style: { width: '100%' },
      wrapper: 'full',
      toolbar: true,
    },
  };

  // ラジオボタン生成
  // 署名は先頭1つのみ使用
  const forRadio = (arr) => {
    const items = [];
    const item = arr[0];
    const label = item && item.libraryName ? item.libraryName : '';
    const suffix = '(代行メール使用)';
    items.push(
      <FormControlLabel
        key={`radio${0}`}
        label={`${label}${suffix}`}
        value={0}
        control={<Radio className={classes.radio} />}
        className={classes.label}
      />,
    );
    return items;
  };

  // 登録押下時
  const onRegisterFunc = () => {
    const mailBody = editorState;
    const params = {
      libraryDirectoryId: signature.libraryDirectoryId,
      mailBody,
      libraryName: localVal.displayData.libraryName,
      libraryId: localVal.displayData.libraryId,
      mailSubject: '_signature',
    };
    onSubmit(params);
  };

  // signature変更時(初期データ投入時)
  useEffect(() => {
    setLocalVal({
      ...localVal,
      libraryDirectoryId: signature.libraryDirectoryId,
      displayData: signature.libraries[0],
    });
  }, [signature]);

  // ディスプレイデータ変更時
  useEffect(() => {
    if (!localVal.displayData?.libraryId) {
      return;
    }
    // wysiwygへ情報を反映
    // https://github.com/jpuri/html-to-draftjs#readme
    setEditorState(localVal.displayData?.mailBody || '');
    // EditorStateの更新が即時でないため遅延させる
    setInitialData(state => ({
      ...state,
      count: initialData.count + 1,
    }));
    setMailBodyKey(randomId());
  }, [localVal.displayData?.libraryId]);

  // initialData.count変更時
  useEffect(() => {
    if (!initialData.count) {
      return;
    }
    setInitialData(state => ({
      ...state,
      libraryName: localVal.displayData.libraryName,
      mailBody: editorState.trim(),
    }));
  }, [initialData.count]);

  const [errorSet, setErrorSet] = useState(new Set());
  const [validate, setValidate] = useState(false);

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  useEffect(() => {
    if (!setting.title.state || errorSet.size !== 0) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }); [errorSet];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="署名の編集ウィンドウ"
      maxWidth={1024}
      bodyClassName={hasLoading(SIGNITURE_EDIT_WINDOW) ? common.loading : undefined}
      buttons={[{
        label: 'キャンセル',
        className: common.buttonsSecondary,
        onClick: onClose,
      }, {
        label: '登録',
        className: common.buttonsPrimary,
        onClick: onRegisterFunc,
        disabled: validate,
      }]}
      buttonClose
    >
      <Grid container className={classes.radioWrapper}>
        <Typography className={common.title4}>編集枠</Typography>
        <FormControl>
          <RadioGroup
            value={Number(localVal.radioval)}
            className={classes.radioGroup}
          >
            {forRadio(signature.libraries)}
          </RadioGroup>
        </FormControl>
      </Grid>
      <MailTextfield values={setting.title} errorCallback={errorCallback} />
      <MailTitleAndBody
        key={mailBodyKey}
        initData={setting.mailBody.state}
        setMailBody={setEditorState}
        full
      />
    </Modal>
  );
}
