import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  open: false,
  title: '',
  msgList: [],
};

export const cantCloseAlertMessageSlice = createSlice({
  name: 'cantCloseMessage',
  initialState: { cantCloseState: initialState },
  reducers: {
    cantCloseAlertMessage: (state, data) => {
      const { msgList, title } = data.payload;
      state.cantCloseState.open = true;
      state.cantCloseState.title = title === undefined ? '' : title;
      state.cantCloseState.msgList = msgList;
    },
    initCantCloseAlertMessage: (state) => {
      state.cantCloseState = initialState;
    },
  },
});

// eslint-disable-next-line
export const { cantCloseAlertMessage, initCantCloseAlertMessage } = cantCloseAlertMessageSlice.actions;

export default cantCloseAlertMessageSlice.reducer;
