import client from '../httpClient';

/**
 * 【combination-setting004】組み合わせ設定更新
 * http://10.22.2.48:8080/docs/#/combinationSetting/put-combination-settings
 * @param {{
 * infomations: {
 * patternId: number;
 * areaCode: number;
 * patternName: string;
 * targetCityJisCode: string;
 * specialRule: number;
 * displayOrder: number;
 * deletedAt: string;
 * datails: {
 *   patternId: number;
 *   patternTypeId: number;
 *   areaCode: number;
 *   price: number;
 *   deletedAt: string;
 * }[];
 * }[];
 * }} data
 * @returns Promise<*>
 */
const combinationSettingPutApi = async (data) => {
  const res = await client.put('/combination-settings', JSON.stringify(data));
  return res.data;
};

export default combinationSettingPutApi;
