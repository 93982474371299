import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Icon from '@material-ui/core/Icon';
import EditWindow from '../editWindow';
import InfoRegister from '../../../common/infoRegister';
import CreateWindowContainer from '../../../../containers/mail/createWindowContainer';

import { dateFormatter } from '../../../../commonFunction';

import {
  ACTION_CODE,
  ACTION_DETAIL_CODE,
  ACTION_DETAILE_MAIL_GROUP,
  ACTION_DETAIL_TAIO_RIREKI_GROUP,
} from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  historyRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '5px 0',
    transition: 'all 0.5s ease',
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .historyContentBtn': {
        opacity: 1,
      },
    },
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
    '& th': {
      padding: 0,
    },
    '& td': {
      display: 'block',
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      border: 'none',
      padding: 0,
      position: 'relative',
    },
    '& .MuiTableRow-root.Mui-selected': {
      background: 'none',
    },
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: '9',
  },
  iconButton1: {
    color: theme.palette.primaryColor,
    padding: 2,
    minWidth: 'auto',
    marginRight: 8,
    '& .material-icons': {
      fontSize: 16,
    },
  },
  iconButton2: {
    color: theme.palette.primaryColor,
    padding: 2,
    minWidth: 'auto',
    '& .material-icons': {
      fontSize: 16,
    },
  },
  headTtl: {
    color: '#8C8C8C!important',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  historyDate: {
    display: 'block',
    position: 'relative',
    width: '100%',
    color: '#8C8C8C!important',
    fontWeight: 700,
    border: 'none',
    background: '#fff',
    padding: '4px 8px!important',
    margin: '-5px 0 5px',
    '& span': {
      display: 'inline-block',
      background: '#fff',
      position: 'relative',
      zIndex: '2',
      padding: '0 8px',
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: '100%',
      height: 1,
      position: 'absolute',
      zIndex: '1',
      top: 13,
      left: 0,
    },
  },
  historyTime: {
    position: 'relative',
    width: 60,
    paddingLeft: '16px!important',
  },
  historyContent: {
    width: 180,
    display: 'flex!important',
  },
  historyContentBtn: {
    color: theme.palette.primaryColor,
    background: '#fff',
    minWidth: 'auto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 56,
    maxHeight: 21,
    borderRadius: '4px',
    padding: '0 4px',
    marginLeft: 4,
    opacity: 0,
    transition: 'all 0.5s ease',
    flexShrink: 0,
    position: 'absolute',
    right: 0,
    '& .material-icons': {
      fontSize: 16,
      marginRight: 3,
    },
  },
  historyName: {
    width: 80,
  },
  historyeBtn: {
    width: 20,
    paddingRight: '16px!important',
  },
  historyMailSubject: {
    width: '83%',
    marginLeft: 'auto',
    fontSize: '10px!important',
    paddingBottom: '4px!important',
    display: 'flex!important',
    alignItems: 'center',
  },
  openedMailIcon: {
    background: '#27AE60',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center;',
    alignItems: 'center',
    marginRight: 4,
    '& .MuiSvgIcon-root': {
      fontSize: '10px!important',
      color: '#fff!important',
      margin: '0!important',
    },
  },
  clipIcon: {
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center;',
    alignItems: 'center',
    marginRight: 4,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: '20px!important',
      color: '#696969!important',
      margin: '0!important',
    },
  },
  historyMailSubjectTxt: {
    width: 160,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  historyMailSubjectCount: {
    width: 150,
    display: 'block',
  },
  historyMailBodyBtn: {
    position: 'absolute',
    top: 4,
    left: 4,
    width: 10,
    height: 10,
    minWidth: 'auto',
    padding: 0,
    borderRadius: 0,
    '&::before': {
      content: '""',
      display: 'block',
      background: theme.palette.primaryColor,
      width: 1,
      height: 7,
      position: 'absolute',
      top: 2,
      left: 4,
      transition: 'all 0.5s ease',
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: theme.palette.primaryColor,
      width: 7,
      height: 1,
      position: 'absolute',
      top: 5,
      left: 1,
    },
    '&.active::before': {
      opacity: 0,
    },
  },
  historyBtn: {
    width: 28,
  },
}));

export default function TaiouRirekiItem(props) {
  const {
    state,
    setState,
    row,
    index,
    labelId,
    refleshHistory,
    updateHistory,
    deleteHistory,
    customerId,
    customer,
    rerenderSearch,
    getActionHistoryList,
    downloadAttachment,
  } = props;

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  // 案内・接触登録
  const [infoRegisterOpen, setInfoRegisterOpen] = useState(false);

  // メール作成ウィンドウ
  const [createWindowOpen, setCreateWindowOpen] = useState(false);
  const createWindowClose = () => {
    setCreateWindowOpen(false);
  };

  const classes = useStyles();

  // 連打防止
  const isProcessing = useRef(false);

  const handleDownload = async () => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    await downloadAttachment(row.actionId);
    isProcessing.current = false;
  };

  const mailSubject = () => {
    return (
      <TableCell className={classes.historyMailSubject}>
        {row.isOpenedMail === 1 && (
          <span className={classes.openedMailIcon}>
            <DraftsOutlinedIcon />
          </span>
        )}
        {row.isAttachment === 1 && (
          <Grid className={classes.clipIcon} onClick={handleDownload}>
            <AttachFileIcon />
          </Grid>
        )}
        <span className={classes.historyMailSubjectTxt}>
          {row.mailSubject}
        </span>
        {row.detailCode === 115 ? '閲覧確認不可' : (
          <span className={classes.historyMailSubjectCount}>
            (閲覧{row.totalMailOpenedCount ? row.totalMailOpenedCount : 0}回：
            {row.latestMailOpenedAt && (row.latestMailOpenedAt).substr(0, 16).replaceAll('-', '/')})
          </span>
        )}
      </TableCell>
    );
  };
  const otherContent = () => {
    return (
      <TableCell className={classes.historyMailSubject}>
        <span className={classes.historyMailSubjectTxt}>
          {row.content}
        </span>
      </TableCell>
    );
  };

  const getDetail = () => {
    if (row.guidanceId) {
      return (
        <Button
          className={`${classes.historyContentBtn} historyContentBtn`}
          onClick={() => { setInfoRegisterOpen(true); }}
        >
          <Icon>drive_file_rename_outline</Icon>詳細
        </Button>
      );
    } else if (row.actionCode === 7 && ACTION_DETAILE_MAIL_GROUP.includes(row.detailCode)) {
      return (
        <Button
          className={`${classes.historyContentBtn} historyContentBtn`}
          onClick={() => { setCreateWindowOpen(true); }}
        >
          <Icon>drive_file_rename_outline</Icon>詳細
        </Button>
      );
    } else {
      return (
        <Button
          className={`${classes.historyContentBtn} historyContentBtn`}
          onClick={handleOpen}
        >
          <Icon>drive_file_rename_outline</Icon>詳細
        </Button>
      );
    }
  };

  const getGuidance = () => {
    if (row.getSupportGuidanceId) {
      return (
        <Grid className={classes.iconButton}>
          <Button
            className={classes.iconButton1}
            onClick={() => { setInfoRegisterOpen(true); }}
          >
            <Icon>date_range</Icon>
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid />
      );
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={classes.historyRow}
    >
      <TableCell
        id={labelId}
        className={`${classes.historyDate} historyDate-${customerId}`}
      >
        <span>{dateFormatter(new Date(row.startAt), 'YYYY/MM/DD')}</span>
      </TableCell>
      <TableCell className={classes.historyTime}>
        {dateFormatter(new Date(row.startAt), 'hh:mm')}
      </TableCell>
      <TableCell className={classes.historyContent}>
        {ACTION_CODE[row.actionCode]}・{ACTION_DETAIL_CODE[row.detailCode]}
        {getDetail()}
        <EditWindow
          open={open}
          onClose={handleClose}
          index={index}
          row={row}
          state={state}
          setState={setState}
          actionCode={row.actionCode}
          detailCode={row.detailCode}
          updateHistory={updateHistory}
          deleteHistory={deleteHistory}
          customerId={customerId}
        />
        <InfoRegister
          open={infoRegisterOpen}
          onOpen={() => {
            setInfoRegisterOpen(true);
          }}
          onClose={() => {
            setInfoRegisterOpen(false);
          }}
          guidanceId={row.guidanceId || row.getSupportGuidanceId}
          customer={customer}
          rerenderSearch={rerenderSearch}
          actionHistorySearch={{
            offset: 0,
            limit: 20,
          }}
          getActionHistoryList={getActionHistoryList}
        />
        {createWindowOpen && (
          <CreateWindowContainer
            user={customer}
            open={createWindowOpen}
            handleClose={createWindowClose}
            actionId={row.actionId}
            mailQueueId={row.mailQueueId}
            mailAddress={row.mailAddress}
            refleshCallBack={refleshHistory}
            isLocalStorage={false}
            taioRirekiFlg
            isActiveSaveTemplate
          />
        )}
      </TableCell>
      <TableCell className={classes.historyName}>{row.userFullName}</TableCell>
      <TableCell className={classes.historyBtn}>{getGuidance()}</TableCell>
      {
        row.actionCode === 7
          && !ACTION_DETAIL_TAIO_RIREKI_GROUP.includes(row.detailCode)
          ? mailSubject()
          : otherContent()
      }
    </TableRow>
  );
}
