import { useSelector } from 'react-redux';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  AccountTreeOutlined,
  ExpandMore,
} from '@material-ui/icons';
import FlyerSalesTreeResponseContainers from '../../../containers/common/flyerSalesTree/flyerSalesTreeResponseContainers';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  title: {
    background: '#f3f3f3',
    minHeight: 'auto !important',
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      margin: '4px 0',
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0,
    },
  },
  icon: {
    width: '16px',
    marginRight: '4px',
  },
  arrow: {
    color: theme.palette.primaryColor,
    width: '16px',
  },
  details: {
    display: 'block',
  },
}));

function FlyerSalesTreeResponse(props) {
  const {
    setSelectUser,
    setSelectDivision,
    setSelectFlyer,
    levelCode,
    divisionId,
    reloadHanlder,
  } = props;
  const classes = useStyles();
  const common = commonStyles();

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    isSp ? (
      <FlyerSalesTreeResponseContainers
        setSelectFlyer={setSelectFlyer}
      />
    ) : (
      <Grid>
        <Accordion
          defaultExpanded
          className={classes.root}
        >
          <AccordionSummary
            className={classes.title}
            expandIcon={<ExpandMore className={classes.arrow} />}
          >
            <AccountTreeOutlined className={classes.icon} />
            <Typography className={common.title6}>源泉未処理ツリー</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <FlyerSalesTreeResponseContainers
              levelCode={levelCode}
              divisionId={divisionId}
              setSelectUser={setSelectUser}
              setSelectDivision={setSelectDivision}
              setSelectFlyer={setSelectFlyer}
              reloadHanlder={reloadHanlder}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  );
}

export default FlyerSalesTreeResponse;
