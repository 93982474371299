import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customers: [
    {
      customerId: null,
    },
  ],
  getDataAt: null,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState: { customer: initialState },
  reducers: {
    getCustomerApi: (state, data) => {
      state.customer = data.payload;
    },
    resetCustomerApi: (state) => {
      state.customer = initialState;
    },
  },
});

export const { getCustomerApi, resetCustomerApi } = customerSlice.actions;

export default customerSlice.reducer;
