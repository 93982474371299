import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const mokuhyoSetDeleteSlice = createSlice({
  name: 'mokuhyoSetDelete',
  initialState: { mokuhyoSetDelete: initialState },
  reducers: {
    mokuhyoSetDeleteApi: (state, data) => {
      state.mokuhyoSetDelete = data.payload;
    },
  },
});

export const { mokuhyoSetDeleteApi } = mokuhyoSetDeleteSlice.actions;

export default mokuhyoSetDeleteSlice.reducer;
