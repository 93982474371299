import { makeStyles, Grid } from '@material-ui/core';
import { useState } from 'react';
import Calendar from '../../common/calendar';
import DraggableDialog from '../../common/modal';
import commonStyles from '../../styles';
import toISOStringWithTimezone from '../../../commonFunction/toISOStringWithTimezone';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& label': { width: '100%' },
    '& > .MuiGrid-root': { background: '#fff' },
  },
  onFocus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    transition: 'transition: all 0.5s ease-out',
  },
  btn: { marginRight: 16 },
});

export default function SendTimeChangeWindow(props) {
  const { open, setOpen, values, minDate = null, maxDate = null } = props;
  const common = commonStyles();
  const classes = useStyles();

  const [time, setTime] = useState({ assignAt: '' });
  const timeOnchange = (value) => {
    setTime({ ...time, assignAt: value });
  };

  // カレンダーinputにフォーカスが当たった時、ボタンの位置を変更するためのstate管理
  // const [isFocused, setIsFocused] = useState(false);

  const onClose = () => {
    setOpen(0);
  };

  const onclickSave = () => {
    const dist = time.assignAt.replace(/-/g, '/').replace(/T/g, ' ');
    values.setState(dist);
    setOpen(0);
  };

  const formatDate = (dt) => {
    const tostring = toISOStringWithTimezone(dt);
    const result = tostring.slice(0, 16);
    return result;
  };

  return (
    <DraggableDialog
      open={open}
      onClose={onClose}
      title="送信開始時間"
      width={360}
      buttons={[{
        onClick: onClose,
        label: 'キャンセル',
        className: `${common.buttonsSecondary} ${classes.btn}`,
      }, {
        className: common.buttonsPrimary,
        onClick: onclickSave,
        label: '保存',
      }]}
    >
      <Grid className={classes.root}>
        <p className={common.bass}>送信開始日時を設定してください</p>
        <Calendar
          data={{
            id: 'sendtime',
            type: 'time',
            state: time,
            setState: timeOnchange,
            min: minDate ? formatDate(minDate) : formatDate(new Date()),
            max: maxDate ? formatDate(maxDate) : null,
            fromMail: true,
          }}
        />
      </Grid>
    </DraggableDialog>
  );
}
