import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const listAdGetKeywordsSlice = createSlice({
  name: 'listAdGetKeywords',
  initialState: { listAdGetKeywords: initialState },
  reducers: {
    listAdGetKeywordsApi: (state, data) => {
      state.listAdGetKeywords = data.payload;
    },
  },
});

export const { listAdGetKeywordsApi } = listAdGetKeywordsSlice.actions;

export default listAdGetKeywordsSlice.reducer;
