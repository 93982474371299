import { List, makeStyles } from '@material-ui/core';

import RailwayListFiltered from './railwayListFiltered';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles({
  list: {
    overflow: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      overflowY: 'auto',
      height: 'calc(100% - 50px)',
    },
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
});

export default function RailwayList(props) {
  const {
    selectedItems,
    railways = [],
    clickRailwayFunc,
    setOpenedStations,
    setOpenStations,
  } = props;
  const classes = useStyles();

  const isOpened = (groupedRailways) => {
    if (selectedItems.railway.railwayId === undefined || !groupedRailways.railways.length) {
      return false;
    } else {
      return groupedRailways.railways.findIndex(
        e => e.railwayId === selectedItems.railway.railwayId,
      ) !== -1;
    }
  };

  return (
    <List className={classes.list}>
      {railways.map((groupedRailways) => (
        <RailwayListFiltered
          key={groupedRailways.key}
          railways={groupedRailways}
          clickRailwayFunc={clickRailwayFunc}
          setOpenedStations={setOpenedStations}
          setOpenStations={setOpenStations}
          selectedItems={selectedItems}
          opened={isOpened(groupedRailways)}
        />
      ))}
    </List>
  );
}
