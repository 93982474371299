import { Grid, Tabs, Tab, makeStyles } from '@material-ui/core';
// import { TabPanel } from '@material-ui/lab';
import commonTheme from '../../../styles/theme';
import { useStoreGroupsSettingsAreas } from '../../../combinationSetting/common/useStoreGroupsSettingsAreas';

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '100%',
    // padding: '8px',
    [commonTheme.breakpoints.down('sm')]: {
      // padding: '0 16px',
      boxSizing: 'border-box',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: '#fff',
    },
    '& .MuiTabs-root': {
      [commonTheme.breakpoints.down('sm')]: {
        padding: '0 32px',
      },
    },
  },
  tabs2: {
    // justifyContent: 'center',
  },
  tab: {
    flexDirection: 'row',
    minWidth: '25px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '160%',
    [commonTheme.breakpoints.down('sm')]: {
      // width: '20%',
      // padding: '6px 16px',
    },
  },
  scrollButton: {
    width: '30px',
  },
  tabIndicator: {
    background: theme.palette.primaryColor,
  },
  selectedTab: {
    color: theme.palette.primaryColor,
    fontWeight: '700',
    [commonTheme.breakpoints.down('sm')]: {
      // padding: '6px 16px',
    },
    '& .MuiTabs-flexContainer': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
  },
}));

export default function RegionTabs(props) {
  const { selectedArea, clickAreaTabFunc } = props;
  const classes = useStyles();
  const { areaList } = useStoreGroupsSettingsAreas();
  const tabLabels = areaList.map((area, index) => ({
    id: index,
    areaCode: area.areaCode,
    labelText: area.areaName,
  }));
  const findTabIndex = (areaCode) => {
    const index = tabLabels.findIndex(e => e.areaCode === areaCode);
    return index === -1 ? 0 : index;
  };
  return (
    <Grid className={classes.tabs}>
      <Tabs
        value={findTabIndex(selectedArea)}
        variant="scrollable"
        TabIndicatorProps={{ className: classes.tabIndicator }}
        classes={{ scrollButtons: classes.scrollButton }}
        className={classes.tabs2}
      >
        {tabLabels.map((obj) => (
          <Tab
            onClick={() => {
              clickAreaTabFunc(obj.areaCode);
            }}
            className={classes.tab}
            key={obj.id}
            label={obj.labelText}
            id={obj.id}
            classes={{ selected: classes.selectedTab }}
          />
        ))}
      </Tabs>
    </Grid>
  );
}
