import client from '../httpClient';

/**
 * @typedef { import(
 *   './getDivisionMastersApiTypes'
 * ).DivisionMastersGetResponse } DivisionMastersGetResponse
 * @type { (divisionId: number) => Promise<DivisionMastersGetResponse> }
 */
const divisionMasterGetSectionApi = async (divisionId) => {
  const result = await client.get(`/division-masters?divisionId=${divisionId}`);
  return result;
};

export default divisionMasterGetSectionApi;
