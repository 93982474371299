import { createSlice } from '@reduxjs/toolkit';
// import { LIMIT_INSTRUCTION_LIST } from '../../constants/localStorage';
// import { getLocalStorage } from '../../commonFunction';

const initialRequestParam = {
  customerIds: [],
  limit: -1,
  offset: 0,
  directTabCode: 1,
  userId: null,
  customerName: '',
  directUserName: '',
  directedUserName: '',
  content: '',
  orderBy: 'expired_at',
  order: 'asc',
};

const initialDirects = [];
const initialCounts = {
  totalDirects: 0,
  totalReports: 0,
  totalCompleate: 0,
};

export const directGetListSlice = createSlice({
  name: 'directGetList',
  initialState: {
    initialRequestParams: initialRequestParam,
    responseParams: initialDirects,
    counts: initialCounts,
  },
  reducers: {
    getDirectList: (state, data) => {
      state.responseParams = data.payload.data.directs;
      state.counts = {
        totalDirects: data.payload.data.totalDirects,
        totalReports: data.payload.data.totalReports,
        totalCompleate: data.payload.data.totalCompleate,
      };
    },
    setRequestParams: (state, data) => {
      state.initialRequestParams = data.payload;
    },
    setNotDirects: (state) => {
      state.responseParams = initialDirects;
      state.counts = initialCounts;
    },
  },
});

export const { getDirectList, setRequestParams, setNotDirects } = directGetListSlice.actions;

export default directGetListSlice.reducer;
