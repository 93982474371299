import { TextField as MuiTextField } from '@material-ui/core';
import BaseValidationField from './core/BaseValidationField';

const fieldType = {
  email: { type: 'email', inputMode: 'email' },
  number: { type: 'text', inputMode: 'numeric' },
  tel: { type: 'tel', inputMode: 'tel' },
  text: { type: 'text', inputMode: 'text' },
};

/**
 *
 * @param { "email" | "number" | "tel" | "text" } [type='text']
 * @param {any} inputProps -
 */
function TextBaseField(props) {
  // register, variableNameはsrc\components\pages\joinData\dataElement.jsで使用
  // src\components\pages\joinData\joinData.jsでuseFormにてregisterを参照
  // TextFieldに...register(variableName)でないとformのデータ変更が取得できないため
  // 変更する場合、joinData.jsのformatSetDataにてデータが変更されているか確認が必要
  const { type = 'text', register = () => {}, variableName, inputProps, ...others } = props;

  const convertedInputProps = (() => {
    const inputPropsKey = Object.keys(fieldType).includes(type) ? type : 'text';
    const additionalInputProps = fieldType[inputPropsKey];
    return { ...inputProps, ...additionalInputProps };
  })();

  return (
    <BaseValidationField {...others}>
      <MuiTextField {...register(variableName)} inputProps={convertedInputProps} />
    </BaseValidationField>
  );
}

export default TextBaseField;
