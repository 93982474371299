import { useRef } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { classNames } from '../../../commonFunction';

import { TreeContextProvider } from './context';
import TreeItem from './treeItem';

export * from './const';

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
  },
});

/**
 * Tree
 * @param {{
 * list: Array<{
 * id?: number;
 * label: string;
 * name?: string;
 * parentId?: number;
 * parentName?: string;
 * uniqueId: string;
 * type: string;
 * selectable?: boolean;
 * children?: Array<ListItem>;
 * }>;
 * defaultSelected?: Array<{
 * id: number;
 * type: string;
 * }>;
 * defaultOpenTypes?: Array<string>;
 * onChange?: (item | Array<item>) => void;
 * multiple?: boolean;
 * accordion?: boolean;
 * checkbox?: boolean;
 * rootRef: useRef;
 * }} props
 * @returns {ReactNode}
 */
export function Tree(props) {
  const {
    // initial
    // { icons: { type: <Icon />, $lastNodeIcon: null | ReactNode, $plusIcon.. }, ...}
    // $lastNodeIcon: 内臓アイコンの場合、$を付け、それ以外はlistのアイテムの[type]にする
    options = {}, // {icons: {}, styles: {}}
    list = [], // [{id: number, name: string, selectable: boolean, parentId: number, ...}]
    defaultSelected = [], // [{id: number, type?: string, parentId?: number}]
    defaultOpenTypes = [], // [type1, type2]
    search, // string | {keyword: string, type: string}
    // change
    onChange, // (item | Array<item>) => void; multipleとcheckboxの場合には配列を返す
    // boolean
    multiple, // 複数選択か
    checkbox, // Checkboxを使用するか
    accordion,
    // original properties
    className,
    style,
    // children
    children,
    footer, // ReactNode
    rootRef,
    multipleInitialUser,
    isUserTree,
    isSearch,
    searchHeader,
    displayCustomer,
    rerenderDivisionIds,
  } = props;

  const classes = useStyles();

  const treeListRef = useRef(null);
  const eventDivisionId = useRef(0);

  return (
    <TreeContextProvider
      options={options}
      list={list}
      defaultSelected={defaultSelected}
      onChange={onChange}
      multiple={multiple}
      checkbox={checkbox}
      accordion={accordion}
      defaultOpenTypes={defaultOpenTypes}
      search={search}
      rootRef={rootRef}
      treeListRef={treeListRef}
      multipleInitialUser={multipleInitialUser}
      isSearch={isSearch}
      searchHeader={searchHeader}
      displayCustomer={displayCustomer}
      rerenderDivisionIds={rerenderDivisionIds}
    >
      {children}
      <Grid ref={treeListRef} className={classNames(classes.root, className)} style={style}>
        {list.map((item, i) => (
          // eslint-disable-next-line max-len
          <TreeItem data={item} key={String(i)} eventDivisionId={eventDivisionId} isUserTree={isUserTree} isParent />
        ))}
      </Grid>
      {footer}
    </TreeContextProvider>
  );
}

export default Tree;
