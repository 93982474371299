import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const librarydirCopySlice = createSlice({
  name: 'librarydirCopy',
  initialState: { librarydirCopy: initialState },
  reducers: {
    librarydirCopyApi: (state, data) => {
      state.librarydirCopy = data.payload;
    },
  },
});

export const { librarydirCopyApi } = librarydirCopySlice.actions;

export default librarydirCopySlice.reducer;
