import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  // Drawer,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import { InstructionsSelectList } from '../../../../constants/customerMain';
import InstructionsWindowButton from './parts/instructionsWindowButton';
import InstructionsWindowRadio from './parts/instructionsWindowRadio';
// import InstructionsWindowSelectList from './parts/InstructionsWindowSelectList';
import InstructionsWindowCheckBox from './parts/instructionsWindowCheckBox';
import InstructionsWindowCheckBoxButton from './parts/instructionsWindowCheckBoxButton';
import InstructionsWindowCalendar from './parts/instructionsWindowCalendar';
// import InstructionsWindowCalendarSp from './parts/sp/instructionsWindowCalendar';
import InstructionsWindowTextField from './parts/instructionsWindowTextField';
import UserTree from '../../userTreeNew';
import getInstructionsTime from '../../../../commonFunction/getInstructionsTime';
import Modal from '../../modal';
import InstructionsWindowSelect from './parts/sp/instructionsWindowSelect';
import { DIRECT_REGISTER } from '../../../../constants/loading';
import { useLoading } from '../../../../hooks';

const useStyles = makeStyles({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '1400!important',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    background: '#FFF',
    [commonTheme.breakpoints.down('sm')]: {
      overflowY: 'auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 696,
      minWidth: 696,
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 16px 8px 16px',
      width: '100%',
      boxSizing: 'border-box',
      position: 'relative',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
    },
  },
  headerTtl: {
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '24px',
    },
  },
  body: { padding: 16 },
  content: {
    background: '#F3F3F3',
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '32px 16px 72px',
      maxHeight: 'calc(100vh - 72px)',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '32px 32px 24px',
    },
  },
  contentCheckbox: {
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: '8px',
    },
  },
  contentIpt: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    '&.edit': {
      pointerEvents: 'none',
      background: '#C8C8C8',
    },
    '& .MuiListItem-gutters': {
      [commonTheme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
        paddingRight: 0,
        display: 'inline-block',
        textAlign: 'right',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiFormLabel-root': {
        position: 'relative',
        transform: 'none',
      },
    },
  },
  contentIptBlock: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      background: 'transparent',
      border: 'none',
      marginBottom: 0,
      padding: 0,
    },
  },
  contentIpt2: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    '&.edit': {
      pointerEvents: 'none',
      background: '#C8C8C8',
    },
  },
  contentIpt3: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    alignItems: 'center',
    display: 'flex',
  },
  contentIptLbl: {
    [commonTheme.breakpoints.up('md')]: {
      width: 80,
    },
    [commonTheme.breakpoints.down('sm')]: {
      wordBreak: 'keep-all',
      display: 'inline-flex',
    },
    '& span': {
      color: '#D83420',
    },
  },
  contentIptBox: {
    [commonTheme.breakpoints.up('md')]: {
      width: 528,
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: 1,
      textAlign: 'right',
    },
  },
  contentIptBox2: {
    marginTop: 8,
    width: '100%',
    '& .MuiButtonBase-root': {
      width: '100%',
      '& .MuiButton-label': {
        justifyContent: 'flex-start',
      },
    },
  },
  contentIptBox3: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [commonTheme.breakpoints.down('sm')]: {
      '& > :not(.MuiFormGroup-row)': {
        width: '100%',
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 528,
    },
    // '& .MuiFormGroup-row': {
    //   [commonTheme.breakpoints.down('sm')]: {
    //     marginBottom: '8px',
    //   },
    // },
  },
  contentIptBoxTop: {
    width: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: 10,
      background: '#fff',
      padding: '9.5px 12px 9.5px 16px',
      border: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 20,
    },
  },
  selectButton: {
    color: '#8C8C8C',
    '& .MuiButton-label': {
      lineHeight: 1,
    },
  },
  label: {
    fontSize: '13px',
    color: '#8C8C8C',
    '&.MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        lineHeight: '20.8px',
      },
    },
  },
  drawer: {
    zIndex: '1405!important',
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
      minHeight: '50%',
    },
  },
  textFieldGrid: {
    padding: '32px 16px 16px 16px',
    '& > div': {
      width: '100%',
    },
  },
});

export default function InstructionsWindow(props) {
  const {
    detail,
    open,
    setOpen,
    onClose,
    directId,
    newInstructionFlg,
    editState,
    setEditState,
    restoreEditState,
    updateDirect,
    updatePage,
    customerId,
    onSubmit,
  } = props;

  const { hasLoading } = useLoading();

  const classes = useStyles();
  const baseClasses = commonStyles();
  const [disabled, setDisabled] = useState(true); // ボタンの必須制御
  const [inactive, setInactive] = useState(true); // カレンダーの表示制御
  const [openTree, setOpenTree] = useState(false);
  const [openMultiTree, setOpenMultiTree] = useState(false);
  const [selectUser, setSelectUser] = useState({
    userId: editState.directedUserId,
    userName: detail.directUserLastName + ' ' + detail.directUserFirstName,
  });
  const [selectMultiUser, setSelectMultiUser] = useState([]);
  const [selectDivision, setSelectDivision] = useState({
    divId: editState.directedDivisionId,
    divisionName: '',
  });
  const [selectMultiDivision, setSelectMultiDivision] = useState([]);
  const [register, setRegister] = useState({
    date: { assignAt: editState.expireAt },
  });
  const [expireRadio, setExpireRadio] = useState(newInstructionFlg ? '0' : '3');
  const responseHeader = useSelector((state) => state.responseHeader);

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = windowWidth < breakPoint;

  // 初回 - othersの中身をTreeで使うselectMultiUser用にset
  useEffect(() => {
    if (detail.others.length > 0) {
      if (detail.others[0].userId) {
        const initialUsers = [];
        for (let i = 0; detail.others.length > i; i += 1) {
          const other = detail.others[i];
          const obj = {
            userId: other.userId,
            userName: other.userLastName + other.userFirstName,
            userDivisionId: other.divisionId,
          };
          initialUsers.push(obj);
        }
        setSelectMultiUser(initialUsers);
      }
    }
  }, []);

  // 指示登録ウィンドウにおけるデフォルト担当ユーザを指定する
  useEffect(() => {
    setSelectUser(!detail.directedUserId ? {
      userId: responseHeader.userId,
      userName: responseHeader.userName,
    } : {
      userId: detail.directedUserId,
      userName: detail.directUserLastName + ' ' + detail.directUserFirstName,
    });
    setSelectDivision({
      divId: detail.directedDivisionId,
      divisionName: '',
    });
    const copyObj = Object.assign({}, editState);
    copyObj.directedUserId = detail.directedUserId;
    copyObj.directedDivisionId = detail.directedDivisionId;
    setEditState(copyObj);
  }, [detail]);

  useEffect(() => {
    if (editState.content !== '') {
      setDisabled(false);
    }
  }, [editState]);

  // 担当者選択後のsetEditState
  useEffect(() => {
    const copyObj = Object.assign({}, editState);
    copyObj.directedUserId = selectUser.userId;
    copyObj.directedDivisionId = selectDivision.divId;
    setEditState(copyObj);
  }, [selectUser, selectDivision]);

  // useEffect(() => {
  //   setEditState({ ...editState, directedDivisionId: selectDivision.divId });
  // }, [selectDivision]);

  // 担当者複数選択のeditStateとTree用State(selectMultiUser)の型合わせ
  const setMultiUserState = () => {
    const moldingOthersArr = [];
    for (let i = 0; selectMultiUser.length > i; i += 1) {
      moldingOthersArr.push({
        userId: selectMultiUser[i].userId,
        divisionId: selectMultiUser[i].userDivisionId,
      });
    }
    setEditState({ ...editState, others: moldingOthersArr });
  };

  // 担当者複数選択のsetEditState
  useEffect(() => {
    setMultiUserState();
  }, [selectMultiUser]);

  const set = (key, value) => {
    switch (value) {
      case true:
        setEditState({ ...editState, [key]: 1 });
        break;
      case false:
        setEditState({ ...editState, [key]: 0 });
        break;
      default:
        setEditState({ ...editState, [key]: value });
        break;
    }
    // setDetail({ ...detail, data: initial });
    if (key === 'deadLineCode') {
      if (value === '3') {
        setInactive(false);
      } else {
        setInactive(true);
      }
    }
  };

  // deadLineCodeがAPI項目にない
  const deadLineItem = {
    state: expireRadio,
    setState: (e) => {
      // return set('deadLineCode', e);
      setExpireRadio(e);
      set('expireAt', getInstructionsTime(Number(e), 1, editState.expireAt));
      setRegister({ date: { assignAt: getInstructionsTime(Number(e), 1, editState.expireAt) } });
    },
    list: InstructionsSelectList.deadLine,
  };

  const registerStart = async () => {
    // SPではcustomerIdが上位の処理から渡されて来ていないため、追加する
    if (!editState.customerId) {
      editState.customerId = Number(customerId);
    }
    // 登録時、期日に「:00」を追加する
    // YYYY/MM/DD hh:mm:ss（19文字）で送られてきた場合は足さない
    if (editState.expireAt.length !== 19) {
      editState.expireAt += ':00';
    }
    onClose();
    if (newInstructionFlg) {
      // 指示更新
      await updateDirect(null, editState);
      setOpen(false);
      // 表示を更新
      await updatePage(editState);
    } else if (!newInstructionFlg) {
      // editStateでUpdate
      setOpen(false);
      await updateDirect(detail.directId, editState);
    } else {
      // editStateでCreate
      onClose();
    }
    onSubmit?.();
    // レンダリングされずに再度指示登録が開かれた場合も、デフォルトとして顧客担当者が選択されるようにする
    setSelectUser({
      userId: detail.directedUserId,
      userName: detail.directUserLastName + ' ' + detail.directUserFirstName,
    });
    setSelectDivision({
      divId: detail.directedDivisionId,
      divisionName: '',
    });
    if (newInstructionFlg) {
      deadLineItem.setState('0');
      setSelectMultiUser([]);
    }
    restoreEditState();
  };

  const isdisabledCreate = () => {
    const judgeArray = [
      editState.directedDivisionId === 0 ? null : editState.directedDivisionId,
      editState.directedUserId === 0 ? null : editState.directedDivisionId,
      editState.reportCode,
      editState.directCode,
      editState.approve,
      editState.customerId,
      editState.directUserId,
      editState.expireAt,
      editState.content,
    ];

    const judgeNull = judgeArray.includes(null);
    const judgeUndefined = judgeArray.includes(undefined);

    if (detail.directReportStatusCode === 5 || detail.directReportStatusCode === 6) {
      return true;
    } else if (judgeNull && judgeUndefined) {
      return true;
    } else {
      return false;
    }
  };

  const button = {
    state: selectUser.userName,
    setState: (e) => {
      return set('directUserId', e);
    },
  };

  const checkedBox1 = {
    state: editState.isManager,
    setState: (e) => {
      return set('isManager', e);
    },
    label: 'MGR',
  };

  const checkedBox2 = {
    state: editState.isCenterManager,
    setState: (e) => {
      return set('isCenterManager', e);
    },
    label: 'センター長',
  };

  const importantItem = {
    state: String(editState.importantCode),
    setState: (e) => {
      return set('importantCode', Number(e));
    },
    list: InstructionsSelectList.important,
  };

  const directKindItem = {
    state: String(editState.directCode),
    setState: (e) => {
      return set('directCode', Number(e));
    },
    list: InstructionsSelectList.directKind,
  };

  const directTypeItem = {
    state: String(editState.approveCode),
    setState: (e) => {
      return set('approveCode', Number(e));
    },
    list: InstructionsSelectList.directType,
  };

  const calendar = {
    state: register.date,
    initial: editState.expireAt,
    setState: (e) => {
      const dateTime = e.replace(/-/g, '/').replace('T', ' ');
      return setRegister({ date: { assignAt: dateTime } });
    },
    disabled: newInstructionFlg ? inactive : false,
  };
  useEffect(() => {
    set('expireAt', register.date.assignAt);
  }, [register]);

  // 背景をクリックした時にモーダルを閉じる
  const onCloseBg = () => {
    restoreEditState();
    deadLineItem.setState('0');
    if (newInstructionFlg) setSelectMultiUser([]);
    onClose();
  };

  const textField1 = {
    title: '指示内容',
    state: editState.content,
    setState: (e) => {
      return set('content', e);
    },
    label: 'content',
  };

  const body = (
    <>
      <Grid className={`${classes.contentIpt} ${classes.contentIptBlock}`}>
        {!isSP ? (
          <Typography
            className={`${baseClasses.title4} ${classes.contentIptLbl} ${baseClasses.onlyPc}`}
          >
            担当<span>＊</span>
          </Typography>
        ) : null}
        <Grid className={classes.contentIptBox3}>
          <Grid className={classes.contentIptBoxTop}>
            <Typography
              className={`${baseClasses.title4} ${classes.contentIptLbl} ${baseClasses.onlySp}`}
            >
              担当<span>＊</span>
            </Typography>
            {isSP ? (
              <InstructionsWindowButton
                setOpenDrawer={setOpenTree}
                labelValue={button.state}
              />
            ) : (
              <InstructionsWindowButton setOpenDrawer={setOpenTree} labelValue={button.state} />
            )}
          </Grid>
          {isSP ? (
            <Grid className={classes.contentIpt3}>
              <InstructionsWindowCheckBox values={checkedBox1} />
              <InstructionsWindowCheckBox values={checkedBox2} />
            </Grid>
          ) : (
            <>
              <InstructionsWindowCheckBox values={checkedBox1} />
              <InstructionsWindowCheckBox values={checkedBox2} />
            </>
          )}
          {isSP ? (
            <Grid className={classes.contentIpt}>
              <Typography
                className={`${baseClasses.title4} ${classes.contentIptLbl}`}
              >
                その他担当者（複数可）
              </Typography>
              <InstructionsWindowButton
                setOpenDrawer={setOpenMultiTree}
                labelValue={selectMultiUser}
              />
            </Grid>
          ) : (
            <InstructionsWindowCheckBoxButton
              values={selectMultiUser}
              setOpen={setOpenMultiTree}
              setSelectMultiUser={setSelectMultiUser}
              editState={editState}
              setEditState={setEditState}
            />
          )}
        </Grid>
      </Grid>
      <Grid className={classes.contentIpt}>
        <Typography
          className={`${baseClasses.title4} ${classes.contentIptLbl}`}
        >
          重要度
        </Typography>
        <Grid className={classes.contentIptBox}>
          {isSP ? (
            <InstructionsWindowSelect
              title="重要度"
              values={importantItem}
            />
          ) : (
            <InstructionsWindowRadio values={importantItem} />
          )}
        </Grid>
      </Grid>
      <Grid className={classes.contentIpt}>
        <Typography
          className={`${baseClasses.title4} ${classes.contentIptLbl}`}
        >
          期日<span>＊</span>
        </Typography>
        <Grid className={classes.contentIptBox3}>
          {isSP ? (
            <>
              <InstructionsWindowSelect
                title="期日"
                values={deadLineItem}
              />
              {deadLineItem.state === 3 && (
                <InstructionsWindowCalendar
                  values={calendar}
                  expireRadio={deadLineItem.state}
                />
              )}
            </>
          ) : (
            <>
              <InstructionsWindowRadio values={deadLineItem} />
              <InstructionsWindowCalendar values={calendar} expireRadio={expireRadio} />
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        className={`${classes.contentIpt} ${
          directId !== '' && !newInstructionFlg ? 'edit' : ''
        }`}
      >
        <Typography
          className={`${baseClasses.title4} ${classes.contentIptLbl}`}
        >
          種類<span>＊</span>
        </Typography>
        <Grid className={classes.contentIptBox}>
          {isSP ? (
            <InstructionsWindowSelect
              title="種類"
              values={directKindItem}
              unselect="選択してください"
            />
          ) : (
            <InstructionsWindowRadio values={directKindItem} />
          )}
        </Grid>
      </Grid>
      <Grid
        className={`${classes.contentIpt2} ${
          directId !== '' && !newInstructionFlg ? 'edit' : ''
        }`}
      >
        <Typography
          className={`${baseClasses.title4} ${classes.contentIptLbl}`}
        >
          指示内容<span>＊</span>
        </Typography>
        <Grid className={classes.contentIptBox2}>
          <InstructionsWindowTextField
            values={textField1}
            setDisabled={setDisabled}
            notFocus
          />
        </Grid>
      </Grid>
      <Grid className={classes.contentIpt}>
        <Typography
          className={`${baseClasses.title4} ${classes.contentIptLbl}`}
        >
          報告後
        </Typography>
        <Grid className={classes.contentIptBox}>
          <InstructionsWindowRadio values={directTypeItem} />
        </Grid>
      </Grid>
    </>
  );

  return (
    <div>
      <Modal
        title={['指示登録ウィンドウ', '指示登録']}
        buttons={{
          label: '登録',
          onClick: registerStart,
          disabled: isdisabledCreate(detail.directReportStatusCode) || hasLoading(DIRECT_REGISTER),
          classes: `${baseClasses.buttonsPrimary} ${classes.fixedFooterBtn} ${disabled ? 'disabled' : ''}`,
        }}
        bodyClassName={classes.body}
        open={open}
        onClose={onCloseBg}
        width={680}
      >

        {body}
      </Modal>
      {openTree ? (
        <UserTree
          isOpenTree={openTree}
          setIsOpenTree={setOpenTree}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          isDialog
          isSelectUserWindow
        />
      ) : null}
      {openMultiTree ? (
        <UserTree
          isOpenTree={openMultiTree}
          setIsOpenTree={setOpenMultiTree}
          selectDivision={selectMultiDivision}
          setSelectDivision={setSelectMultiDivision}
          selectUser={selectMultiUser}
          setSelectUser={setSelectMultiUser}
          isMulti
          isDialog
          isSelectUserWindow
        />
      ) : null}
    </div>
  );
}
