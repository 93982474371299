import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  InputBase,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import commonTheme from '../../../styles/theme';
import getSearchTreeObject from '../commonFunc/getSearchTreeObject';
import CustomEditDialog from '../../../eleCommon/sp/customEditDialog';
import { getTreeItemByUserName, getTreeItemByDivisionName } from '../commonFunc/helpers';

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    [commonTheme.breakpoints.up('md')]: {
      height: '35px',
      margin: '8px 0',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      width: '100%',
      position: 'sticky',
      top: -16,
      zIndex: 2,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: '1px solid #C8C8C8',
        borderRadius: theme.shape.borderRadius,
        pointerEvents: 'none',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: -8,
        left: 0,
        width: '100%',
        height: 'calc(100% + 16px)',
        backgroundColor: theme.palette.common.white,
        zIndex: -1,
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '8px',
      borderRadius: '50px',
    },
    '&.isTypeDialog': {
      [commonTheme.breakpoints.up('md')]: {
        margin: '0 0 16px',
        '& input': {
          width: '100%',
        },
      },
    },
  },
  searchIcon: {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    [commonTheme.breakpoints.up('md')]: {
      cursor: 'pointer',
      padding: theme.spacing(0, 2),
    },
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
    },
  },
  searchText: {
    fontSize: 13,
  },
  inputRoot: {
    color: 'inherit',
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1, 1, 0),
      width: '20ch',
    },
    inputRoot: {
      color: 'inherit',
    },
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
}));

function UserTreeSearch(props) {
  const {
    list,
    setSearchObj,
    setSearchKeyword,
    headValue,
    getObjectSortFunction,
    noAffiliList,
    isSelectUserWindow,
    showOnlyDivisions,
    isDialog,
    doubleSearch,
    isUserMaster,
  } = props;
  const classes = useStyles();

  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [open, setOpen] = useState(false); // 検索用のモーダル制御(SP)
  const [searchValue, setSearchValue] = useState(''); // テキストフィールドのバリュー値制御
  const initialSearchWord = useRef('');
  const initialSearchIndex = useRef(0);
  const firstGetData = useRef();
  const [inputValue, setInputValue] = useState({ target: { value: '' } });

  useEffect(() => {
    setSearchValue('');
    setSearchObj('');
  }, [headValue]);

  useEffect(() => {
    if (isUserMaster) {
      setSearchValue('');
      setSearchObj('');
      initialSearchWord.current = '';
      initialSearchIndex.current = 0;
      firstGetData.current = null;
    }
  }, [list]);

  // Enter押したときの挙動
  const handleEnter = (e) => {
    setSearchKeyword(e.target.value);
    // ここでSearchのHeaderがどちらなのか分かる必要がある
    // 以下で検索ワードに合ったツリーの中身を部分一致
    let search = '';
    if (initialSearchWord.current === e.target.value) {
      initialSearchIndex.current = firstGetData.current[initialSearchIndex.current + 1]
        ? (initialSearchIndex.current += 1)
        : 0;
      // eslint-disable-next-line max-len
      setSearchObj(firstGetData.current[initialSearchIndex.current]);
    } else {
      initialSearchWord.current = e.target.value;
      initialSearchIndex.current = 0;
      if (headValue === 1) {
        // eslint-disable-next-line max-len
        search = getSearchTreeObject(list, e.target.value, showOnlyDivisions, noAffiliList, doubleSearch);
      } else if (headValue === 2) {
        search = getObjectSortFunction(list, e.target.value);
      }
      firstGetData.current = search;
      setSearchObj(search[initialSearchIndex.current]);
    }
  };

  // SP:決定ボタンを押した場合
  const handleSetSearchName = (keywords) => {
    let search = '';
    if (!keywords) {
      setSearchObj('');
    } else {
      // eslint-disable-next-line max-len
      search = showOnlyDivisions ? getTreeItemByDivisionName(list, keywords) : getTreeItemByUserName(list, keywords);
      setSearchObj(search);
    }
  };

  const content = (
    <TextField
      autoFocus
      className={classes.input}
      onChange={(e) => {
        return setSearchValue(e.target.value);
      }}
      name={isSelectUserWindow ? '担当者検索' : '組織検索'}
      value={searchValue}
    />
  );

  let inputPlaceHolder;
  if (doubleSearch) {
    inputPlaceHolder = '組織・ユーザー名';
  } else {
    inputPlaceHolder = showOnlyDivisions ? '組織名' : 'ユーザー名';
  }

  return (
    <div className={`${classes.search} ${isDialog && 'isTypeDialog'}`}>
      {!isSp ? (
        <>
          <div className={classes.searchIcon}>
            <SearchIcon
              onClick={() => {
                inputValue.target.value && handleEnter(inputValue);
              }}
            />
          </div>
          <InputBase
            placeholder={inputPlaceHolder}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && e.target.value) {
                handleEnter(e);
              }
            }}
            onChange={(e) => {
              setInputValue(e);
            }}
          />
        </>
      ) : (
        <>
          <div className={classes.searchIcon}>
            <SearchIcon
              onClick={() => {
                setOpen(!open);
              }}
            />
          </div>
          <CustomEditDialog
            open={open}
            setOpen={setOpen}
            content={content}
            set={() => {
              handleSetSearchName(searchValue, showOnlyDivisions);
            }}
            lbl={isSelectUserWindow ? '担当者検索' : '組織検索'}
          />
          <Typography className={classes.searchText}>{searchValue}</Typography>
        </>
      )}
    </div>
  );
}

export default UserTreeSearch;
