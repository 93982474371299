import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, Checkbox, makeStyles, TableSortLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    padding: 4,
    color: '#8C8C8C',
    borderBottom: 'none',
    display: 'flex',
    alignItems: 'center',
    '& .MuiCheckbox-root': {
      padding: 0,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #C8C8C8',
    },
    '& .MuiTableSortLabel-icon': {
      fontSize: 16,
      margin: 0,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function HeaderCell(props) {
  const { obj, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const classes = useStyles();

  // チェックボックス
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const getCheckbox = () => {
    return (
      <Checkbox
        checked={checked}
        onChange={handleChange}
      />
    );
  };

  // ソート
  const getSort = () => {
    return (
      <TableSortLabel
        active={orderBy === obj.key}
        direction={orderBy === obj.key ? order : 'asc'}
        onClick={createSortHandler(obj.key)}
      >
        {obj.title}
        {orderBy === obj.key ? (
          <span className={classes.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    );
  };

  let text = '';
  switch (obj.type) {
    case 'checkbox':
      text = getCheckbox();
      break;
    default:
      text = getSort();
  }

  return (
    <TableCell className={classes.tableHead}>
      <Grid
        style={{
          width: obj.width || 'auto',
          textAlign: obj.textAlign || 'left',
        }}
      >
        {text}
      </Grid>
    </TableCell>
  );
}

HeaderCell.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default HeaderCell;
