/* eslint import/prefer-default-export: 0 */
export const MAIL_SEND_PROXY = 1; // メールの代理送信
export const MAIL_SEND_ALL_AT_ONCE = 2; // 一斉メール送信
export const SYSTEM_ADMIN = 3; // 管理権限→システム管理
export const MAIL_TEMPLATE_ADMIN = 4; // メールテンプレート管理
export const CLAIM_CANCELLATION = 5; // クレーム解除
export const WEB_RESPONSE_IMPORT_USE = 6; // WEB未処理反響一覧→WEB未処理反響利用
export const FLYER_RESPONSE_IMPORT_USE = 7; // 源泉未処理反響一覧→源泉未処理反響利用
export const CRM_USE = 8; // CRM使用権限→CRM利用
export const ALL_CUSTOMER_ADMIN = 9; // 支援権限→全顧客管理
export const ALL_MASTA_ADMIN = 10; // マスタ管理者(個別のマスタ処理とその中に含まれないマスタ全般)→全マスタ管理
export const RESPONSE_MASTA_ADMIN = 11; // 反響タイプ(マスタのうち反響タイプ部分)→反響マスタ管理
export const INFO_USE = 12; // メール送信者で@INFOが選択可能(戸建)→@INFO利用
export const LEADERS_MEMO_USE = 13; // 上長メモ表示権限→上長メモ利用
export const DAIZIN_ADMIN = 14; // 「ダイジン」Admin
export const WEB_MEMBER_FORCED_QUIT_USE = 15; // WEB会員強制退会利用
export const RESPONSIBLE_CUSTOMER_REFERENCE = 16; // 担当顧客・参照
export const SHARE_ROLE_GRANT_CUSTOMER_REFERENCE = 17; // 共有権限付与顧客・参照
export const FOLLOWER_SALES_RESPONSIBLE_CUSTOMER_REFERENCE = 18; // 配下営業担当顧客・参照
export const FOLLOWER_SHARE_ROLE_GRANT_CUSTOMER_REFERENCE = 19; // 配下共有権限付与顧客・参照
export const DIVISION_STORE_RESPONSIBLE_NO_CUSTOMER_REFERENCE = 20; // 所属店舗内担当無顧客・参照
export const DIVISION_SECTION_RESPONSIBLE_NO_CUSTOMER_REFERENCE = 21; // 所属課内担当無顧客・参照
export const ALL_CUSTOMER_REFERENCE = 22; // 全顧客・参照
export const RESPONSIBLE_CUSTOMER_EDIT = 23; // 担当顧客・編集
export const SHARE_ROLE_GRANT_CUSTOMER_EDIT = 24; // 共有権限付与顧客・編集
export const FOLLOWER_SALES_RESPONSIBLE_CUSTOMER_EDIT = 25; // 配下営業担当顧客・編集
export const FOLLOWER_SHARE_ROLE_GRANT_CUSTOMER_EDIT = 26; // 配下共有権限付与顧客・編集
export const DIVISION_STORE_RESPONSIBLE_NO_CUSTOMER_EDIT = 27; // 所属店舗内担当無顧客・編集
export const DIVISION_SECTION_RESPONSIBLE_NO_CUSTOMER_EDIT = 28; // 所属課内担当無顧客・編集
export const ALL_CUSTOMER_EDIT = 29; // 全顧客・編集
export const STORE_MOVE_MASTA_ADMIN = 30; // 店舗間移動マスタ使用権限→店舗間移動マスタ管理
export const STORE_MOVE_MASTA_ADMIN_RESPONSIBLE_AREA_ONLY = 31; // 店舗間移動マスタ管理(担当エリア限定)
export const SALES_OWN_STORE_SECTION_ORGANIZATION_MASTA_ADMIN = 32; // 営業自店舗課編成マスタ使用権限→営業自店舗課編成マスタ管理
export const MAIN_MENU_DISPLAY = 33; // メインメニュー・表示
export const SCHEDULE_ACTION_SUBMISSION = 34; // 行動予定提出権限→行動予定提出
export const FILE_STORAGE_USE = 35; // ファイルストレージ使用権限→ファイルストレージ利用
export const MOBILE_SFA_USE = 36; // モバイルSFA利用
export const SIMULATOR_MASTA_ADMIN = 38; // シミュレーター利用

export const ROLE_MAPPER = {
  1: 'メールの代理送信',
  2: '一斉メール送信',
  3: 'システム管理',
  4: 'メールテンプレート管理',
  5: 'クレーム解除',
  6: 'WEB未処理反響利用',
  7: '源泉未処理反響利用',
  8: 'CRM利用',
  9: '全顧客管理',
  10: '全マスタ管理',
  11: '反響マスタ管理',
  12: '@INFO利用',
  13: '上長メモ利用',
  14: '「ダイジン」Admin',
  15: 'WEB会員強制退会利用',
  16: '担当顧客・参照',
  17: '共有権限付与顧客・参照',
  18: '配下営業担当顧客・参照',
  19: '配下共有権限付与顧客・参照',
  20: '所属店舗内担当無顧客・参照',
  21: '所属課内担当無顧客・参照',
  22: '全顧客・参照',
  23: '担当顧客・編集',
  24: '共有権限付与顧客・編集',
  25: '配下営業担当顧客・編集',
  26: '配下共有権限付与顧客・編集',
  27: '所属店舗内担当無顧客・編集',
  28: '所属課内担当無顧客・編集',
  29: '全顧客・編集',
  30: '店舗間移動マスタ管理',
  31: '店舗間移動マスタ管理(担当エリア限定)',
  32: '営業自店舗課編成マスタ管理',
  33: 'メインメニュー・表示',
  34: '行動予定提出',
  35: 'ファイルストレージ利用',
  36: 'モバイルSFA利用',
};
