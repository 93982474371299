import Draggable from 'react-draggable';
import { Dialog, Paper, makeStyles } from '@material-ui/core';
import commonTheme from '../styles/theme';
import { classNames } from '../../commonFunction';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      padding: '25px',
    },
    '& .MuiDialog-paper': {
      [commonTheme.breakpoints.down('sm')]: {
        margin: 0,
        borderRadius: 0,
        width: '100%',
        height: '100%',
        maxHeight: 'none',
      },
      [commonTheme.breakpoints.up('md')]: {
        margin: 0,
        borderRadius: 0,
        minWidth: '800px',
        maxHeight: '112vh',
      },
    },
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

// 呼び出し元のDialogTitleに id="draggable-dialog-title" をset
function DraggableDialog(props) {
  const { open, onClose, children, addStyle, maxWidth, className, style } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      maxWidth={maxWidth}
      aria-labelledby="draggable-dialog-title"
      className={classNames(
        classes.dialog,
        addStyle !== undefined && addStyle,
        className,
      )}
      style={style}
    >
      {children}
    </Dialog>
  );
}

export default DraggableDialog;
