import { useState, useEffect } from 'react';
import {
  Grid,
  // DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import userGetShareListApi from '../../../../apis/user/userGetShareListApi';
import userGetShareApi from '../../../../apis/user/userGetSharedApi';
import { useLoading } from '../../../../hooks';
import Dialog from '../../../common/modal';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '0',
    },
  },
  contentElement: {
    width: '100%',
    position: 'static',
    height: '322px',
    left: '0px',
    top: '53px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: '0.6rem',
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      boxSizing: 'border-box',
      width: '100%',
    },
  },
  table: {
    height: '100%',
  },
  commonBorder: {
    // borderRight: '1px solid #C8C8C8',
    padding: '3px 10px',
    borderTop: 'none',
  },
  typographMedium: {
    height: '1.8rem',
    borderBottom: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
  },
  thCommonBorder: {
    borderRight: '1px solid #C8C8C8',
    padding: '3px 10px',
  },
  customGrey: {
    color: 'white',
    backgroundColor: '#383b3c',
  },
  customRow: {
    // borderBottom: '2px solid #C8C8C8',
  },
  tableTitleStyle: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '2.3rem',
    height: '15%',
    margin: '12px 0 8px 5px',
  },
  tableGridContainer: {
    boxShadow: '0px 0px 18px rgb(0 0 0 / 20%)',
    height: '95%',
  },
  tableContainer: {
    height: '100%',
    overflowY: 'scroll',
  },
  contentsContainer: {
    // height: '85%',
    display: 'flex',
    alignItems: 'center',
  },
}));

// 対象顧客
function OrgSettingTargetUser(props) {
  const { open, setOpen, type, divisionId, user } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [userList, setUserList] = useState([]);
  const { addLoading, removeLoading, hasLoading } = useLoading();

  const getHeaderList = type
    ? ['共有元', '共有日', '顧客名']
    : ['共有先', '共有日', '顧客名'];

  useEffect(() => {
    addLoading('shareCustomer');
    if (type) {
      userGetShareApi(user.userId, divisionId)
        .then((res) => {
          'shares' in res.data > 0 ? setUserList(res.data.shares) : null;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      userGetShareListApi(user.userId, divisionId)
        .then((res) => {
          'users' in res.data > 0 ? setUserList(res.data.users) : null;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    removeLoading('shareCustomer');
  }, []);

  // Table
  const setTable = () => {
    return (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.customRow}>
              {getHeaderList.map((th) => (
                <TableCell
                  key={th}
                  align="left"
                  className={`${classes.thCommonBorder} ${classes.customGrey}`}
                >
                  {th}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.length > 0 ? userList.map((data) => (
              <TableRow>
                <TableCell className={classes.commonBorder}>
                  <span style={{ width: '15%' }}>戸建</span>
                  <span style={{ width: '50%' }}>{data.divisionName}</span>
                  <span style={{ width: '35%' }}>{data.userLastName} {data.userFirstName}</span>
                </TableCell>
                <TableCell className={classes.commonBorder}>
                  {String(data.startAt).split(' ')[0]}
                </TableCell>
                <TableCell className={classes.commonBorder}>
                  {data.customerLastName} {data.customerFirstName}
                </TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell className={classes.commonBorder}>
                  表示されるデータはありません
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getTargetList = () => {
    if (type) {
      return (
        <>
          <Grid item className={classes.tableGridContainer} style={{ width: '80%' }}>
            {setTable()}
          </Grid>
          <Grid item style={{ width: '5%', textAlign: 'center' }}>
            ➔
          </Grid>
          <Grid item style={{ width: '15%' }}>
            <Typography className={`${classes.typographMedium} ${baseClasses.title3}`}>共有先</Typography>
            <span>{user.lastName} {user.firstName}</span>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item style={{ width: '15%' }}>
          <Typography className={`${classes.typographMedium} ${baseClasses.title3}`}>共有元</Typography>
          <span>{user.lastName} {user.firstName}</span>
        </Grid>
        <Grid item style={{ width: '5%', textAlign: 'center' }}>
          ➔
        </Grid>
        <Grid className={classes.tableGridContainer} item style={{ width: '80%' }}>
          {setTable()}
        </Grid>
      </>
    );
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      fullWidth
      className={classes.dialog}
      id="shareCustomer"
      maxWidth={960}
      title={type ? '共有された顧客' : 'あなたから共有した顧客'}
      bodyClassName={`${classes.contentsContainer} ${hasLoading('shareCustomer') ? baseClasses.loading : ''}`}
    >
      {getTargetList()}
    </Dialog>
  );
}

export default OrgSettingTargetUser;
