// APIパラメータ定義
// https://docs.google.com/spreadsheets/d/1UMUmqCQ51Wg-zp1wWnohRWSnENvAR1oOhfGkL1hpizQ/edit#gid=859084260

// メール送信元コード
export const MAIL_FROM_CODE = {
  /**
   * 自分
   */
  MYSELF: 1,
  /**
   * 担当課員
   */
  SECTION_STAFF: 2,
  /**
   * @INFO
   */
  INFO: 3,
};

// 変更種別コード
export const UPDATE_TYPE_CODE = {
  /**
   * 新規、初期化など
   */
  INITIAL: 0,
  /**
   * 顧客情報更新（CRM）
   */
  CUSTOMER_UPDATE: 1,
  /**
   * 戻し
   */
  RETURN: 2,
  /**
   * 再反
   */
  REBELLION: 3,
};

// 顧客状態コード
export const CUSTOMER_STATUS_CODE = {
  /**
   * 追客中 1
   */
  CHASING: 1,
  /**
   * 追わない 2
   */
  NOT_CHASE: 2,
  /**
   * 契約 3
   */
  CONTRACT: 3,
  /**
   * クレーム 11
   */
  CLAIM: 11,
};

/**
 * クレーム更新/解除 & 解除
 */
export const CLAIM_OPERATION_TYPES = {
  /**
   * クレーム登録/更新
   */
  REGISTER_OR_UPDATE: 1,
  /**
   * クレーム解除
   */
  CANCEL: 2,
};

/**
 * エリアコード
 */
export const AREA_CODES = {
  HOKAIDO: 10, // 北海道 10
  TOHOKU: 20, // 東北 20
  KANTO: 30, // 関東 30
  GUNMA: 35, // 北関東(=群馬) 35
  KOSHINETSU: 40, // 甲信越 40
  HOKURIKU: 50, // 北陸 50
  TOKAI: 60, // 東海 60
  KANSAI: 70, // 関西 70
  CHUKOKU: 80, // 中国 80
  SHIKOKU: 90, // 四国 90
  KYUSHU: 100, // 九州 100
  OKINAWA: 110, // 沖縄 110
};
