import { Grid, makeStyles } from '@material-ui/core';
import GensenRegisterHistoryContainer from '../../containers/gensenRegister/gensenRegisterHistoryContainer';

const useStyles = makeStyles((theme) => ({
  root: { height: '100%', overflow: 'hidden' },
  title: { textAlign: 'center' },
  closeIcon: {
    color: theme.palette.primaryColor,
    position: 'absolute',
    right: 10,
    top: 13,
  },
  // gensenHistoryContainer: {
  //   height: '100vh',
  // },
}));

export default function GensenRegisterHistory() {
  const classes = useStyles();
  return (
    <Grid className={classes.gensenHistoryContainer}>
      <GensenRegisterHistoryContainer />
    </Grid>
  );
}
