import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const libraryResisterSlice = createSlice({
  name: 'libraryResister',
  initialState: { libraryResister: initialState },
  reducers: {
    libraryResisterApi: (state, data) => {
      state.libraryResister = data.payload;
    },
  },
});

export const { libraryResisterApi } = libraryResisterSlice.actions;

export default libraryResisterSlice.reducer;
