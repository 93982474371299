import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import RegisterWindowTel from './parts/registerWindowTel';
import RegisterWindowMail from './parts/registerWindowMail';
import RegisterWindowLine from './parts/registerWindowLine';
import InstructionsWindow from '../instructionsWindow';
import ListBtn from '../parts/listBtn';
import ListCheckbox from '../../../customerMain/parts/listCheckbox';
import EditDialog1 from '../../../customerMain/parts/editDialog1';
import EditDialog2 from '../../../customerMain/parts/editDialog2';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';
import store from '../../../../store/index';
import CommonDialog from '../../../common/modal';
import { useLoading } from '../../../../hooks';
import InfoRegister from '../../infoRegister';
import ITemLayout from '../../../customerMain/parts/sp/itemLayout';

const useStyles = makeStyles((theme) => ({
  paper: {
    [commonTheme.breakpoints.up('md')]: {
      padding: 8,
    },
    [commonTheme.breakpoints.down('sm')]: { borderTop: 'none' },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '19px 0 8px',
      textAlign: 'center',
      background: '#fff',
    },
  },
  box: {
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 8,
      marginBottom: 8,
      border: '1px solid #C8C8C8',
      background: '#fff',
    },
  },
  appBar: {
    width: 310,
    boxShadow: 'none',
  },
  tabs: {
    background: '#fff',
    minHeight: 'auto',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 0,
      opacity: '1',
      minHeight: 'auto',
    },
    '& .MuiTab-root.Mui-selected .MuiTypography-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-root.Mui-selected .MuiSvgIcon-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-root.Mui-selected .MuiIcon-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  buttonClose: {
    background: '#fff',
    borderRadius: 4,
    display: 'flex',
    margin: '0 auto 8px',
    padding: '4px 4px 3px 8px',
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiButton-startIcon': {
      marginBottom: 1,
    },
  },
  button: {
    color: '#8C8C8C',
    background: 'none',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    padding: '0',
    margin: '0',
    boxShadow: 'none',
    minWidth: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      width: 54,
    },
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
    },
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [commonTheme.breakpoints.up('md')]: {
        flexWrap: 'wrap',
      },
    },
    '& .MuiButton-startIcon': {
      display: 'block',
      margin: 0,
      order: 1,
    },
    '& .MuiButton-endIcon': {
      display: 'block',
      margin: 0,
      order: 2,
      textAlign: 'left',
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 14,
      },
    },
  },
  buttonIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 14,
    },
    '& .MuiIcon-root': {
      fontSize: 14,
    },
  },
  buttonTxt: {
    textAlign: 'center',
  },
  contentResponseLbl: {
    marginTop: 8,
  },
  contentResponseBox1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  contentResponseBox2: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  btn1: {
    width: 85,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 13,
    },
    '& .MuiIcon-root': {
      fontSize: 13,
    },
    '& .MuiButton-startIcon': {
      margin: '0 4px 0 0',
    },
  },
  btn2: {
    width: 117,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 13,
    },
    '& .MuiIcon-root': {
      fontSize: 13,
    },
    '& .MuiButton-startIcon': {
      margin: '0 4px 0 0',
    },
  },
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIpt3: {
    display: 'block',
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal2: {
    width: '100%',
    marginTop: 8,
  },
  hideModal: {
    display: 'none',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RegisterWindow(props) {
  const {
    open,
    onClose,
    type,
    setType,
    infoRegisterOpen,
    typeClose,
    setOpenAlert,
    createHistory,
    customerId,
    customer,
    setActionId,
    getActionHistoryList,
  } = props;
  const { responseHeader } = store.getState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const baseClasses = commonStyles();
  const { hasLoading } = useLoading();

  // 対応履歴登録 → 案内登録
  const actCreateHistoryRes = useSelector((state) => state.actCreateHistory.actCreateHistory);
  const [infoOpen, setInfoOpen] = useState(false);
  useEffect(() => {
    if (infoOpen && actCreateHistoryRes.actionId) {
      setActionId(actCreateHistoryRes.actionId);
      infoRegisterOpen();
      setInfoOpen(false);
    }
  }, [actCreateHistoryRes.actionId]);

  // SP ボタン
  const getParts = (data) => {
    switch (data.type1) {
      case 'button':
        return (
          <ListBtn
            click={data.click}
            lbl={data.lbl}
            required={data.required}
            fontSize={data.fontSize}
            val={data.val}
          />
        );
      case 'checkbox':
        return (
          <ListCheckbox
            control={data.content()}
            lbl={data.lbl}
          />
        );
      case 'noModal':
        return (
          <ITemLayout
            lbl={data.lbl}
            required={data.required}
            content={data.content()}
          />
        );
      default:
        return <span style={{ display: 'none' }}>該当なし</span>;
    }
  };

  // SP モーダル
  const getEditDialog = (data) => {
    switch (data.type2) {
      case 'text':
        return (
          <EditDialog1
            classes={classes}
            open={data.opn}
            onClose={data.onClose}
            content={data.content}
            set={data.decision}
            lbl={data.lbl}
          />
        );
      case 'select':
        return (
          <EditDialog2
            classes={classes}
            open={data.opn}
            onClose={data.onClose}
            content={data.content}
            lbl={data.lbl}
          />
        );
      case 'multiSelect':
        return (
          <Grid>
            <EditDialog2
              classes={classes}
              open={data.opn1}
              onClose={data.onClose1}
              content={data.content1}
              lbl={data.lbl}
            />
            <EditDialog2
              classes={classes}
              open={data.opn2}
              onClose={data.onClose2}
              content={data.content2}
              lbl={data.lbl}
            />
          </Grid>
        );
      default:
        return (
          <span style={{ display: 'none' }}>該当なし</span>
        );
    }
  };

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // 指示 モーダル用
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
    onClose();
  };

  const [open5, setOpen5] = useState(false);
  const handleOpen5 = () => {
    setOpen5(true);
  };
  const handleClose5 = () => {
    onClose();
    setOpen5(false);
  };

  // タブの表示管理用
  // const [value, setValue] = useState(type);
  const handleChange = (event, newValue) => {
    setType(newValue);
  };

  const btn1 = (
    <Grid className={classes.button}>
      <Grid className={classes.buttonIcon}>
        <PhoneIcon />
        <PhoneIphoneIcon />
      </Grid>
      <Typography className={`${baseClasses.title5} ${classes.buttonTxt}`}>電話</Typography>
    </Grid>
  );
  const btn2 = (
    <Grid className={classes.button}>
      <Grid className={classes.buttonIcon}>
        <MailOutlineIcon />
      </Grid>
      <Typography className={`${baseClasses.title5} ${classes.buttonTxt}`}>メール/郵送</Typography>
    </Grid>
  );
  const btn3 = (
    <Grid className={classes.button}>
      <Grid className={classes.buttonIcon}>
        <Icon>aod</Icon>
        <ChatBubbleOutlineIcon />
      </Grid>
      <Typography className={`${baseClasses.title5} ${classes.buttonTxt}`}>SMS/LINE</Typography>
    </Grid>
  );
  const btn4 = (
    <Grid className={classes.button}>
      <Grid className={classes.buttonIcon}>
        <DateRangeOutlinedIcon />
      </Grid>
      <Typography className={`${baseClasses.title5} ${classes.buttonTxt}`}>案内/来社</Typography>
    </Grid>
  );
  const btn5 = (
    <Grid className={classes.button}>
      <Grid className={classes.buttonIcon}>
        <TouchAppOutlinedIcon />
      </Grid>
      <Typography className={`${baseClasses.title5} ${classes.buttonTxt}`}>指示</Typography>
    </Grid>
  );

  // 背景をクリックした時にモーダルを閉じる
  const onCloseBg = () => {
    onClose();
  };

  // API用パラメータ
  const [countHistory, setCountHistory] = useState(0);
  const [historyParams, setHistoryParams] = useState({});
  const updateHistoryParams = (params) => {
    const { userId } = responseHeader;
    setHistoryParams((state) => ({
      ...state,
      ...params,
      customerId,
      userId,
    }));
  };
  const callCreateHistory = () => {
    setCountHistory(countHistory + 1);
  };
  useEffect(async () => {
    // console.log('historyParams', historyParams);
    if (Object.keys(historyParams).length) {
      await createHistory(customerId, historyParams)
        .then(() => {
          setOpenAlert(true);
        })
        .catch(() => {
          setOpenAlert(false);
        });
      setHistoryParams({});
    }
  }, [countHistory]);

  const handleYes = () => {
    callCreateHistory();
    // confirmClose();
    onClose();
    console.log('登録処理');
    console.log('GA004を呼び出す');
    setInfoOpen(true);
    // infoRegisterOpen();
  };
  const handleNo = () => {
    callCreateHistory();
    // confirmClose();
    onClose();
    typeClose();
    console.log('登録処理');
  };

  // 続けて案内予約の確認画面
  const confirmOpen = () => {
    dispatch(
      changeConfirmMessage({
        title: '案内予約',
        msgList: ['続けて案内予約をしますか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: handleNo,
            classes: baseClasses.buttonsSecondary,
          },
          {
            label: 'OK',
            set: handleYes,
            classes: baseClasses.buttonsPrimary,
          },
        ],
      }),
    );
  };

  // 不通時メール送信時に対応履歴を更新
  const successSendCallBack = () => getActionHistoryList(customerId, { offset: 0, limit: 20 });

  const [modalHidden, setModalHidden] = useState(false);

  const getList = () => {
    return (
      <Grid>
        <TabPanel value={type} index={0}>
          <RegisterWindowTel
            onClose={onClose}
            confirmOpen={confirmOpen}
            getParts={getParts}
            getEditDialog={getEditDialog}
            typeClose={typeClose}
            updateHistoryParams={updateHistoryParams}
            callCreateHistory={callCreateHistory}
            customer={customer}
            successSendCallBack={successSendCallBack}
            setModalHidden={setModalHidden}
          />
        </TabPanel>
        <TabPanel value={type} index={1}>
          <RegisterWindowMail
            onClose={onClose}
            confirmOpen={confirmOpen}
            getParts={getParts}
            getEditDialog={getEditDialog}
            typeClose={typeClose}
            updateHistoryParams={updateHistoryParams}
            callCreateHistory={callCreateHistory}
          />
        </TabPanel>
        <TabPanel value={type} index={2}>
          <RegisterWindowLine
            onClose={onClose}
            confirmOpen={confirmOpen}
            getParts={getParts}
            getEditDialog={getEditDialog}
            typeClose={typeClose}
            updateHistoryParams={updateHistoryParams}
            callCreateHistory={callCreateHistory}
          />
        </TabPanel>
      </Grid>
    );
  };

  if (isSp) {
    contents = (
      <>
        <Typography
          className={`${baseClasses.title2} ${classes.header}`}
        >
          対応登録
          <Button
            className={baseClasses.closeButton}
            onClick={onCloseBg}
          />
        </Typography>
        {getList()}
      </>
    );
  } else {
    contents = (
      <>
        <Grid
          className={classes.box}
        >
          <Typography className={`${baseClasses.title6} ${classes.ttl}`}>登録種類</Typography>
          <AppBar position="static" className={classes.appBar}>
            <Tabs value={type} onChange={handleChange} className={classes.tabs}>
              <Tab label={btn1} {...a11yProps(0)} />
              <Tab label={btn2} {...a11yProps(1)} />
              <Tab label={btn3} {...a11yProps(2)} />
              <Tab label={btn4} onClick={handleOpen5} />
              <Tab label={btn5} onClick={handleOpen3} />
            </Tabs>
          </AppBar>
        </Grid>
        <Button
          className={classes.buttonClose}
          startIcon={<CloseIcon />}
          onClick={onClose}
        >
          登録キャンセル
        </Button>
        {getList()}
      </>
    );
  }

  return (
    <CommonDialog
      open={open}
      onClose={onCloseBg}
      width={400}
      headerClassName={baseClasses.onlyPc}
      bodyClassName={classes.paper}
      className={
        // src/components/mail/sp/afterCallWindowComponent.jsにてライブラリを読み込み待ちさせる
        // getLibraryFromAfterCallで検索
        `${hasLoading('getLibraryFromAfterCall') ? baseClasses.loading : ''}
        ${modalHidden ? classes.hideModal : ''}`
      }
    >
      {contents}
      <InfoRegister
        open={open5}
        onOpen={handleOpen5}
        onClose={handleClose5}
        // newRegistrationFlgがtrueの場合、infoRegisterにguidanceIdを渡さない===新規登録になる
        guidanceId={null}
        onCloseTypesDrawer={onClose}
        customer={customer}
      />
      {/* <InstructionsWindow
        open={open3}
        onClose={handleClose3}
        directId=""
      /> */}
      <InstructionsWindow
        open={open3}
        setOpen={setOpen3}
        onClose={handleClose3}
        directId=""
        // update: 2023/03/22 - https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3818 以下追加
        newInstructionFlg
        customerId={customer.customerId}
        divisionId={customer.divisionId}
        userId={customer.userId}
        userFirstName={customer.userFirstName}
        userLastName={customer.userLastName}
      />

    </CommonDialog>
  );
}
