import { AppBar, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import commonStyles from '../../../styles';
import CorrespondenceListSp from './correspondenceListSp';
import IncompleteListSp from './incompleteListSp';
import SearchBoxSp from './searchBoxSp';
import TodaysGuidanceListSp from './todaysGuidanceListSp';
import UnregistListSp from './unregistListSp';

const useStyles = makeStyles((theme) => ({
  appBar: {
    paddingTop: 25,
    marginTop: -25,
    top: -25,
    '&.MuiPaper-elevation4': {
      boxShadow: 'none',
    },
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  dialogHeader: {
    width: 1160,
    background: '#fff',
    padding: '24px 24px 9px 24px',
  },
  dialogContent: {
    width: 1160,
    backgroundColor: '#F3F3F3',
    borderTop: '1px solid #C8C8C8',
    padding: '24px',
  },
  closeIcon: {
    color: theme.palette.primaryColor,
    position: 'absolute',
    right: 10,
    top: 13,
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid p={3}>{children}</Grid>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DashBoardSpComponent(props) {
  const {
    todaysGuidanceList,
    incompleteList,
    unregistList,
    correspondenceList,
    count,
    tab,
    setQuery,
    requestQuery,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [tabValue, setTabValue] = useState(tab);
  const changeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Grid>
          <SearchBoxSp key={`userId${requestQuery.userId}_divisionId${requestQuery.divisionId}`} requestQuery={requestQuery} setQuery={setQuery} />
          <Tabs value={tabValue} onChange={changeTab} variant="scrollable" className="MuiPaper-elevation4">
            <Tab
              label={
                <Grid className={classes.tab}>
                  <span className={baseClasses.title2}>{count.today}</span>
                  <span className={baseClasses.title6}>本日の案内</span>
                </Grid>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid className={classes.tab}>
                  <span className={baseClasses.title2}>{count.incomp}</span>
                  <span className={baseClasses.title6}>指示(未完)</span>
                </Grid>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Grid className={classes.tab}>
                  <span className={baseClasses.title2}>{count.unregister}</span>
                  <span className={baseClasses.title6}>案内(実績未)</span>
                </Grid>
              }
              {...a11yProps(2)}
            />
            <Tab
              label={
                <Grid className={classes.tab}>
                  <span className={baseClasses.title6}>直近</span>
                  <span className={baseClasses.title6}>反響対応状況</span>
                </Grid>
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>
      </AppBar>
      <Grid>
        <TabPanel value={tabValue} index={0}>
          <TodaysGuidanceListSp dataList={todaysGuidanceList} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <IncompleteListSp dataList={incompleteList} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <UnregistListSp dataList={unregistList} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <CorrespondenceListSp dataList={correspondenceList} />
        </TabPanel>
      </Grid>
    </>
  );
}
