import { Button, Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import commonStyles from '../../styles';
import gensenStyle from './gensenRegisterStyles';
import ListItem from './listItem';
import { convertForm1, convertFormKana } from '../../../commonFunction/convertors';
import { validateFormString1 } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

export default function AddCustomer(props) {
  const { data, setData, readOnly, errorCallback } = props;
  const common = commonStyles();
  const gCommon = gensenStyle();

  // console.log({ data });

  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState({});

  useEffect(() => {
    setDisplay({
      ...display,
      customerLastName: data.customerLastName || '',
      customerFirstName: data.customerFirstName || '',
      customerLastNameKana: data.customerLastNameKana || '',
      customerFirstNameKana: data.customerFirstNameKana || '',
      subLastName: data.subLastName || '',
      subFirstName: data.subFirstName || '',
      subLastNameKana: data.subLastNameKana || '',
      subFirstNameKana: data.subFirstNameKana || '',
    });
  }, [data]);

  return (
    <>
      <Grid container className={gCommon.blockWrap}>
        <ListItem
          label="主：姓"
          required
          readOnly={readOnly}
          noBtn
        >
          <TextBaseField
            placeholder="-"
            className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
            value={display.customerLastName}
            onChange={(e) => {
              setDisplay({
                ...display,
                customerLastName: e.target.value,
              });
            }}
            onBlur={(e) => {
              setData({
                ...data,
                customerLastName: e.target.value,
              });
            }}
            validator={validateFormString1({ maxLengthInt: 50 })}
            convertor={convertForm1}
            errorCallback={errorCallback('customerLastName')}
          />
        </ListItem>
        <ListItem
          label="主：名"
          required
          readOnly={readOnly}
          noBtn
        >
          <TextBaseField
            placeholder="-"
            className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
            value={display.customerFirstName}
            onChange={(e) => {
              setDisplay({
                ...display,
                customerFirstName: e.target.value,
              });
            }}
            onBlur={(e) => {
              setData({
                ...data,
                customerFirstName: e.target.value,
              });
            }}
            validator={validateFormString1({ maxLengthInt: 50 })}
            convertor={convertForm1}
            errorCallback={errorCallback('customerFirstName')}
          />
        </ListItem>
        <ListItem
          label="主：セイ"
          readOnly={readOnly}
          noBtn
        >
          <TextBaseField
            className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
            placeholder="-"
            value={display.customerLastNameKana}
            onChange={(e) => {
              setDisplay({
                ...display,
                customerLastNameKana: e.target.value,
              });
            }}
            onBlur={(e) => {
              setData({
                ...data,
                customerLastNameKana: e.target.value,
              });
            }}
            validator={validateFormString1({ maxLengthInt: 50 })}
            convertor={convertFormKana}
            errorCallback={errorCallback('customerLastNameKana')}
          />
        </ListItem>
        <ListItem
          label="主：メイ"
          readOnly={readOnly}
          noBtn
        >
          <TextBaseField
            className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
            placeholder="-"
            value={display.customerFirstNameKana}
            onChange={(e) => {
              setDisplay({
                ...display,
                customerFirstNameKana: e.target.value,
              });
            }}
            onBlur={(e) => {
              setData({
                ...data,
                customerFirstNameKana: e.target.value,
              });
            }}
            validator={validateFormString1({ maxLengthInt: 50 })}
            convertor={convertFormKana}
            errorCallback={errorCallback('customerFirstNameKana')}
          />
        </ListItem>
      </Grid>
      {open || data.subLastName || readOnly ? (
        <>
          <ListItem
            label="副：姓"
            readOnly={readOnly}
            noBtn
          >
            <TextBaseField
              className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
              placeholder="-"
              value={display.subLastName}
              onChange={(e) => {
                setDisplay({
                  ...display,
                  subLastName: e.target.value,
                });
              }}
              onBlur={(e) => {
                setData({
                  ...data,
                  subLastName: e.target.value,
                });
              }}
              validator={validateFormString1({ maxLengthInt: 50 })}
              convertor={convertForm1}
              errorCallback={errorCallback('subLastName')}
            />
          </ListItem>
          <ListItem
            label="副：名"
            readOnly={readOnly}
            noBtn
          >
            <TextBaseField
              className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
              placeholder="-"
              value={display.subFirstName}
              onChange={(e) => {
                setDisplay({
                  ...display,
                  subFirstName: e.target.value,
                });
              }}
              onBlur={(e) => {
                setData({
                  ...data,
                  subFirstName: e.target.value,
                });
              }}
              validator={validateFormString1({ maxLengthInt: 50 })}
              convertor={convertForm1}
              errorCallback={errorCallback('subFirstName')}
            />
          </ListItem>
          <ListItem
            label="副：セイ"
            readOnly={readOnly}
            noBtn
          >
            <TextBaseField
              className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
              placeholder="-"
              value={display.subLastNameKana}
              onChange={(e) => {
                setDisplay({
                  ...display,
                  subLastNameKana: e.target.value,
                });
              }}
              onBlur={(e) => {
                setData({
                  ...data,
                  subLastNameKana: e.target.value,
                });
              }}
              validator={validateFormString1({ maxLengthInt: 50 })}
              convertor={convertFormKana}
              errorCallback={errorCallback('subLastNameKana')}
            />
          </ListItem>
          <ListItem
            label="副：メイ"
            readOnly={readOnly}
            noBtn
          >
            <TextBaseField
              className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
              placeholder="-"
              value={display.subFirstNameKana}
              onChange={(e) => {
                setDisplay({
                  ...display,
                  subFirstNameKana: e.target.value,
                });
              }}
              onBlur={(e) => {
                setData({
                  ...data,
                  subFirstNameKana: e.target.value,
                });
              }}
              validator={validateFormString1({ maxLengthInt: 50 })}
              convertor={convertFormKana}
              errorCallback={errorCallback('subFirstNameKana')}
            />
          </ListItem>
        </>
      ) : (
        <Button
          onClick={() => { return setOpen(true); }}
          className={`${common.buttonsSecondary} ${gCommon.addBtn}`}
        >
          <AddCircle className={gCommon.icon} />
          副顧客名を追加
        </Button>
      )}
    </>
  );
}
