import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import commonTheme from '../../styles/theme';
import {
  LOAN_CODE,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
    textAlign: 'center',
    borderBottom: '1px solid #8C8C8C',
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #C8C8C8',
    },
    '&:not(:last-child)::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: 1,
      height: '88%',
      position: 'absolute',
      top: '6%',
      right: 0,
      [commonTheme.breakpoints.down('sm')]: {
        height: 'calc(100% - 8px)',
        top: '4px',
      },
    },
    '&:nth-child(1)': {
      width: 80,
      textAlign: 'left',
    },
    '&:nth-child(2)': {
      width: 80,
      textAlign: 'left',
    },
  },
}));

export default function LoanTableRow(props) {
  const {
    row,
  } = props;

  // 3桁カンマ区切り
  const comma = (num) => {
    if (num != null) {
      return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    return num;
  };
  const classes = useStyles();

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.tableRow}
    >
      <TableCell className={classes.tableTd}>
        {row.createdAt}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {LOAN_CODE[row.loanCode]}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {comma(row.productPrice)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {comma(row.totalIncome)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {comma(row.totalDeposit)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {comma(row.bonusPayment)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {comma(row.totalOtherLoan)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.interestRate}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.paymentTerm}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {comma(row.monthlyPayment)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {comma(row.possibleAmount)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.paymentRate}
      </TableCell>
    </TableRow>
  );
}
