import React, { useEffect, useRef } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { crmPageSelector } from '../../../store/crmPage';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '4px 8px',
    width: 249,
  },
  textField: {
    '&>.MuiInputLabel-root': {
      fontSize: 13,
      top: '-12%',
      color: '#333',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 7px) scale(0.85)',
      },
    },
    '& .MuiInput-input': {
      height: 20,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: 13,
    },
  },
  button1: {
    display: 'block',
    position: 'absolute',
    zIndex: 9,
    top: 20,
    right: 25,
    padding: 0,
    width: 22,
    height: 22,
    minWidth: 'auto',
    '&::before': {
      content: '""',
      display: 'block',
      background: '#8C8C8C',
      transform: 'rotate(45deg)',
      width: 12,
      height: 2,
      position: 'absolute',
      top: 10,
      left: 5,
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: '#8C8C8C',
      transform: 'rotate(-45deg)',
      width: 12,
      height: 2,
      position: 'absolute',
      top: 10,
      left: 5,
    },
  },
  button2: {
    display: 'block',
    position: 'absolute',
    zIndex: 9,
    top: 20,
    right: 3,
    padding: 2,
    minWidth: 'auto',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiButton-label': {
      height: 18,
    },
  },
  button2Icon: {
    color: theme.palette.primaryColor,
    fontSize: 18,
  },
}));

function SearchTextField(props) {
  const { initial } = props;

  const classes = useStyles();
  const inputRef = useRef(null);
  const researchRef = useRef(0);
  // 検索ワードを一時保存する（連打防止）
  const searchWordRef = useRef('');

  const { searchKeyword, forceSearch } = useSelector(crmPageSelector);

  const isFirstRender = useRef(true);

  // crm画面からの検索の際に実行される
  useEffect(() => {
    if (!searchKeyword && researchRef.current === forceSearch) return;
    if (searchKeyword) {
      if (!isFirstRender.current) {
        initial.setState({ ...initial.value, searchWord: searchKeyword });
      }
      if (initial.state === searchKeyword) initial.search();
    } else {
      initial.search();
    }
    researchRef.current = forceSearch;
  }, [searchKeyword, forceSearch]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (initial.state && initial.state === searchKeyword) {
      // crm画面からの検索の時だけ実行させる
      console.log(initial.value);
      initial.search();
    }
  }, [initial.state]);

  const onClear = () => {
    initial.setState({ ...initial.value, searchWord: '' });
  };

  const onKeyPress = (e) => {
    // Enterキーを押し、かつキーワードが存在する場合検索を行う
    if (e.key === 'Enter' && !!initial.state && initial.state !== searchWordRef.current) {
      initial.search();
      inputRef.current.blur();
      searchWordRef.current = initial.state;
    }
  };

  return (
    <Grid className={classes.root}>
      <TextField
        className={classes.textField}
        fullWidth
        label={initial.title}
        value={initial.state}
        onKeyPress={onKeyPress}
        onChange={(e) => {
          initial.set(e, initial);
        }}
        inputRef={inputRef}
      />
      <Button
        className={classes.button1}
        onMouseDown={onClear}
      />
      <Button
        className={classes.button2}
        onMouseDown={() => initial.search()}
      >
        <SearchIcon className={classes.button2Icon} />
      </Button>
    </Grid>
  );
}

export default SearchTextField;
