import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import commonStyles from '../styles';
import AllocationSettingTable from './parts/allocationSettingTable';
import CsvUpload from './parts/csvUpload';

import CustomSelect from '../eleCommon/customSelect';
import { useStoreGroupsSettingsAreas } from '../combinationSetting/common/useStoreGroupsSettingsAreas';

import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  headerTtl: {
    marginRight: 206,
  },
  content: {
    padding: 16,
  },
  contentMenu: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  main: {
    width: 1040,
  },
  side: {
    width: 832,
  },
  area: {
    border: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    marginRight: 8,
    height: '2.5rem',
    backgroundColor: '#fff',
    '& .MuiSelect-select': {
      width: 145,
      paddingTop: 6,
      paddingBottom: 5,
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: '1.6',
      letterSpacing: '0',
      padding: 0,
      textAlign: 'left',
      '&:focus': {
        background: 'none',
      },
    },
  },
  areaLbl: {
    marginRight: 16,
  },
  hope: {
    border: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 8px',
    width: 280,
    marginRight: 26,
    height: '3.1rem',
    backgroundColor: '#fff',
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  hopeLbl: {
    marginRight: 16,
    pointerEvents: 'none',
  },
  hopeInput: {
    width: 60,
    marginRight: 16,
    '& > div::before': {
      content: 'none',
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: '1.6',
      letterSpacing: '0',
      padding: 0,
      textAlign: 'left',
    },
  },
  hopeBtn: {
    padding: '0',
    display: 'none',
    height: '1.5rem',
    alignItems: 'center',
    marginLeft: 'auto',
    '&.active': {
      display: 'block',
    },
  },
  csv: {
    display: 'flex',
    alignItems: 'center',
  },
  csvBtn: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: 7,
    textAlign: 'left',
    marginRight: 20,
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      margin: '-2px 2px 0 0',
    },
    '&.disabled': {
      color: '#DEDEDE',
      pointerEvents: 'none',
    },
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // モーダル
  paper: {
    width: 515,
    backgroundColor: '#fff',
    boxShadow:
      '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 60px',
  },
}));

function AllocationSetting(props) {
  const {
    selectedAreaCode,
    setSelectedAreaCode,
    tableData,
    uploadCsv,
    file,
    setFile,
    getAllocationSetting,
    downloadCsv,
    deleteAllocationSetting,
    updateWishCityCount,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // ステート
  const [areaNum, setAreaNum] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [activeUpdate, setActiveUpdate] = useState(false);

  const { areaList } = useStoreGroupsSettingsAreas();

  const dispatch = useDispatch();

  const areaChange = (e) => {
    const areaCode = Number(e.target.value);
    setDisabled(areaCode === 0);
    setSelectedAreaCode(areaCode);
  };

  const areaMap = {};
  areaList.forEach((a) => {
    areaMap[a.areaCode] = a.areaName;
  });

  const data = {
    type: 'select',
    obj: {
      menus: areaMap,
    },
    state: selectedAreaCode,
    set: areaChange,
  };

  // 希望地域数
  const onChangeInput = (e) => {
    if (e.target.value !== areaNum) {
      setAreaNum(e.target.value);
      setActiveUpdate(true);
    }
  };

  // 保存確認モーダルボタン
  const clickYesToUpdate = () => {
    updateWishCityCount({ areaCode: selectedAreaCode, wishCityCount: areaNum });
    setActiveUpdate(false);
  };

  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '顧希望地域数が変更されています。',
      msgList: ['希望地域数を更新します。よろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: clickYesToUpdate,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // CsvUploadの制御
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const showCsvUpload = () => {
    return (
      <CsvUpload
        open={open}
        onClose={handleClose}
        file={file}
        setFile={setFile}
        uploadCsv={uploadCsv}
      />
    );
  };

  useEffect(() => {
    const tmpArea = areaList.filter((area) => area.areaCode === selectedAreaCode);
    if (tmpArea.length !== 0) {
      setAreaNum(String(tmpArea[0].wishCityCount));
    }
  }, [selectedAreaCode]);

  return (
    <Grid className={classes.root}>
      <Grid className={baseClasses.header}>
        <Typography className={`${baseClasses.title2} ${classes.headerTtl}`}>
          配点設定
        </Typography>
      </Grid>
      <Grid className={classes.content}>
        <Grid className={classes.contentMenu}>
          <Grid className={baseClasses.selectBox}>
            <Typography className={`${baseClasses.title4} ${classes.areaLbl}`}>
              エリア
            </Typography>
            <CustomSelect initial={data} />
          </Grid>
          <Grid className={`${classes.hope} ${disabled && 'disabled'}`}>
            <Typography className={`${baseClasses.title4} ${classes.hopeLbl}`}>
              希望地域数
            </Typography>
            <TextField
              type="number"
              value={areaNum}
              className={classes.hopeInput}
              onChange={(ev) => onChangeInput(ev)}
              // onFocus={() => onFocusInput()}
              // onBlur={() => onBlurInput()}
            />
            <Button
              className={`${baseClasses.buttonsPrimary} ${classes.hopeBtn} ${
                activeUpdate && 'active'
              }`}
              onClick={() => confirmOpen()}
            >
              保存
            </Button>
          </Grid>
          <Grid className={classes.csv}>
            <Button
              className={`${classes.csvBtn} ${disabled && 'disabled'}`}
              onClick={downloadCsv}
            >
              <InfoIcon />
              ダウンロード（CSV）
            </Button>
            <Button
              className={`${classes.csvBtn} ${disabled && 'disabled'}`}
              onClick={handleOpen}
            >
              <InfoIcon />
              アップロード（CSV）
            </Button>
          </Grid>
        </Grid>
        {selectedAreaCode ? (
          <AllocationSettingTable
            tableData={tableData}
            activeBtn={Boolean(file)}
            setFile={setFile}
            getAllocationSetting={getAllocationSetting}
            uploadCsv={uploadCsv}
            deleteAllocationSetting={deleteAllocationSetting}
          />
        ) : null}
      </Grid>
      {showCsvUpload()}
    </Grid>
  );
}

export default AllocationSetting;
