import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const claimUpdateSlice = createSlice({
  name: 'claimUpdate',
  initialState: { claimUpdate: initialState },
  reducers: {
    updateClaim: (state, data) => {
      state.claimUpdate = data.payload;
    },
  },
});

export const { updateClaim } = claimUpdateSlice.actions;

export default claimUpdateSlice.reducer;
