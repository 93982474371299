import React from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles({
  contentIpt: {
    width: '100%',
    background: '#fff',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'space-between',
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 4,
      paddingLeft: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: 10,
      marginBottom: 8,
    },
  },
  contentBtn: {
    // pointerEvents: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 8px 16px 16px',
      borderBottom: '1px solid #F3F3F3',
    },
  },
  divSubTxt: {
    fontSize: '12px',
    fontWeight: '700',
  },
});

function Store(props) {
  const { setIsOpenOrgTree, isOpenOrgTree, selectDivision } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const onFocusHandler = () => {
    setIsOpenOrgTree(true);
    console.log(isOpenOrgTree);
  };

  if (isSP) {
    contents = (
      <Grid container className={classes.contentIpt}>
        <Button
          className={classes.contentBtn}
          onClick={() => {
            onFocusHandler();
          }}
        >
          <Typography className={baseClasses.title6}>
            店舗<span style={{ color: '#D83420' }}>＊</span>
          </Typography>
          <Typography className={classes.divSubTxt}>
            {selectDivision.divisionName === '-' || selectDivision.divisionName === ''
              ? '選択してください'
              : selectDivision.divisionName}
          </Typography>
        </Button>
      </Grid>
    );
  } else {
    contents = (
      <Grid container className={classes.contentIpt}>
        <Typography style={{ width: '15%' }} className={baseClasses.title6}>
          店舗<span style={{ color: '#D83420' }}>＊</span>
        </Typography>
        <Button
          className={classes.contentBtn}
          onClick={() => { onFocusHandler(); }}
        >
          <Typography className={classes.divSubTxt}>
            {selectDivision.divisionName === '-' || selectDivision.divisionName === '' ? '選択してください' : selectDivision.divisionName}
          </Typography>
        </Button>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default Store;
