import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResponseTypeModalSp from '../../common/responseType/sp/responseTypeModalSp';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  btn: {
    // fontFamily: 'Roboto',
    [commonTheme.breakpoints.up('md')]: {
      fontSize: '13px',
      width: '246px',
      borderBottom: '1px #8c8c8c solid',
      borderRadius: 0,
      background: 'transparent top 50% right 5px/17px no-repeat',
      backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%238c8c8c" d="M7 10l5 5 5-5z" /></svg>\')',
      '&:hover': {
        backgroundColor: `${theme.palette.primaryColor}29`,
        borderColor: '#000',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      lineHeight: 1.6,
      fontWeight: 'normal',
      width: '100%',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '16px',
      flexShrink: 0,
      borderBottom: '1px solid #F3F3F3',
      textTransform: 'none',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&.readonly': {
      pointerEvents: 'none',
    },
  },
  buttonLabel: {
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
      flexShrink: 0,
    },
  },
  buttonText: {
    marginLeft: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      // overflowX: 'hidden',
      fontSize: 13,
      fontWeight: 'normal',
      lineHeight: 1,
    },
  },
  buttonTextList: {
    display: 'block',
    '& > *': {
      display: 'block',
    },
  },
  readOnlyButtonTextList: {
    fontWeight: 600,
    textAlign: 'right',
  },
  required: {
    color: 'red',
  },
}));

export default function AddResponseType(props) {
  const { data, setData, readOnly } = props;
  const classes = useStyles();
  // const [levelCode, setLevelCode] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [res, setRes] = useState([]);

  const head = [
    { id: 'largeResponseTypeId', name: 'largeResponseTypeName' },
    { id: 'mediumResponseTypeId', name: 'mediumResponseTypeName' },
    { id: 'smallResponseTypeId', name: 'smallResponseTypeName' },
    { id: 'fineResponseTypeId', name: 'fineResponseTypeName' },
  ];

  const responseObj = {
    type: 'response',
    key: 'responseCode1Sp',
    responsive: 'onlySp',
    open: isOpen,
    setOpen: setIsOpen,
    className: 'response__responseCode1Container',
    input: {
      label: '反響媒体',
      required: true,
      list: ['媒体（大）', '媒体（中）', '媒体（小）', '媒体（細）'],
      state: res,
      setState: setRes,
      func: (e) => {
        const copyObj = { ...data };
        head.forEach((value, index) => {
          copyObj[value.id] = e[index] ? e[index].responseTypeId : null;
          copyObj[value.name] = e[index] ? e[index].responseTypeName : null;
        });
        setData(copyObj);
      },
      resetData: () => {
        // 最初のデータに戻す
        const map = head.map((item) => {
          return {
            responseTypeId: data[item.id],
            responseTypeName: data[item.name],
          };
        }).filter((item) => {
          return item.responseTypeId;
        });
        responseObj.input.setState(map);
      },
    },
  };

  useEffect(() => {
    responseObj.input.resetData();
  }, [data]);

  const getItemListSp = () => {
    return (
      <Button
        onClick={() => setIsOpen(true)}
        className={`${classes.btn} ${readOnly ? 'readonly' : ''}`}
      >
        <span>
          {responseObj.input.label}
          {responseObj.input.required ? (<span className={classes.required}>＊</span>) : ''}
        </span>
        <span className={classes.buttonText}>
          <span className={`${classes.buttonTextList} ${readOnly && classes.readOnlyButtonTextList}`}>
            {responseObj.input.list.map((b, i) => {
              return (
                <span key={b}>{responseObj.input.state[i] === undefined ? '' : `${b}：${responseObj.input.state[i].responseTypeName}`}</span>
              );
            })}
          </span>
        </span>
      </Button>
    );
  };

  return (
    <>
      {getItemListSp()}
      <ResponseTypeModalSp
        open={isOpen}
        onClose={() => setIsOpen(false)}
        setState={(e) => {
          responseObj.input.func(e);
          responseObj.input.setState(e);
        }}
        state={responseObj.input.state}
        list={responseObj.input.list}
        defaultResponse={{ responseTypeId: 5, level: 1 }}
        resetData={responseObj.input.resetData}
        addResponseType
      />
    </>
  );
}
