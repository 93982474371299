import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const kimarisTargetSettingsAllUpdateSlice = createSlice({
  name: 'kimarisTargetSettingsAllUpdate',
  initialState: { kimarisTargetSettingsAllUpdate: initialState },
  reducers: {
    kimarisTargetSettingsAllUpdateApi: (state, data) => {
      state.kimarisTargetSettingsAllUpdate = data.payload;
    },
  },
});

export const { kimarisTargetSettingsAllUpdateApi } = kimarisTargetSettingsAllUpdateSlice.actions;

export default kimarisTargetSettingsAllUpdateSlice.reducer;
