import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import MailTitleAndBody from './parts/titleAndBody';
import MailSelect from './parts/mailSelect';
import MailTextfield from './parts/textfield';
import MailAppendFile from './parts/appendFile';
import MailRadio from './parts/mailRadio';
import SmsBody from './parts/smsBody';
import Modal from '../common/modal';
import { useLoading } from '../../hooks';

import { randomString } from '../../commonFunction';
import { TESTMAIL_SENDING } from '../../constants/loading';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1160px',
    margin: 'auto',
    justifyContent: 'center',
    background: '#F3F3F3',
  },
  title: {
    padding: '24px 24px 8px 24px',
    backgroundColor: '#fff',
  },
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    padding: '32px 24px 24px',
    justifyContent: 'space-between',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
  },
  middle: {
    padding: '32px',
  },
  blue: {
    color: theme.palette.primaryColor,
    marginRight: '16px',
  },
  none: { display: 'none' },
}));

export default function AfterCallSettingComponent(props) {
  const {
    open, onClose,
    meta, setMeta,
    setting,
    settingFunctions,
    updateParam, setUpdateParam,
    testMailParam, setTestMailParam,
    editorState, setEditorState,
    responseHeader,
    libraries,
    watchEdit, setWatchEdit,
    uploadedList, setUploadedList,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const { addLoading, removeLoading, hasLoading } = useLoading();

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const [mailBodyKey, setMailBodyKey] = useState('');
  const setEditorStateWrap = (v) => {
    setEditorState(v);
    setMailBodyKey(randomString());
  };

  // テスト送信
  const testSendHandler = async () => {
    // settingFunctions.sendTestMail(testMailParam);
    addLoading(TESTMAIL_SENDING);
    const sendParam = testMailParam;
    sendParam.mailBody = meta.content;
    sendParam.mailSubject = meta.title;
    sendParam.mailFromCode = meta.from;
    sendParam.mailTypeCode = 1;
    sendParam.mailFromCode = 1;
    sendParam.isNotSetCustomer = 1;
    // sendParam.customerId = user.customerId;
    delete sendParam.customerIds;
    await settingFunctions.sendTestMail(sendParam);
    setTimeout(() => removeLoading(TESTMAIL_SENDING), 2000);
  };

  // ライブラリの保存
  const updateLibrary = () => {
    let saveParam = {};
    if (meta.type === 0) {
      // SMS
      saveParam.libraryId = meta.kindSms;
      saveParam.libraryName = updateParam.libraryName;
      saveParam.mailBody = meta.content;
      saveParam.mailSubject = '-';
      saveParam.isUpdate = 1;
    } else {
      // メール
      saveParam = Object.assign({}, updateParam, {
        libraryId: meta.kind,
        libraryName: updateParam.libraryName,
        mailSubject: meta.title,
        // 変更がない場合取得できないのでこちらで強制取得
        mailBody: meta.content,
        attachments: uploadedList,
        isUpdate: 1,
      });
    }
    settingFunctions.updateSetting(Object.assign({}, updateParam, saveParam));
    setUpdateParam(Object.assign({}, updateParam, saveParam));
    setWatchEdit(false);
  };

  // 初回表示
  useEffect(async () => {
    // ライブラリ取得
    addLoading('getAfterCallLibrary');
    const mailLib = await settingFunctions.getLibraryMail(
      responseHeader.userId,
    );
    const smsLib = await settingFunctions.getLibrarySms(responseHeader.userId);
    if (smsLib && mailLib) {
      setMeta({
        ...meta,
        kind: mailLib.libraryId,
        kindSms: smsLib.libraryId,
        content: smsLib.mailBody !== undefined ? smsLib.mailBody : '',
      });
      setUpdateParam({
        userId: responseHeader.userId,
        libraryDirectoryId: smsLib?.libraryDirectoryId,
        mailSubject: smsLib?.mailSubject,
        libraryName: smsLib?.libraryName,
        libraryId: smsLib?.libraryId,
      });
      removeLoading('getAfterCallLibrary');
    }
  }, []);

  // 本文編集時
  useEffect(() => {
    setTestMailParam({
      ...testMailParam,
      mailBody: editorState,
    });
    setMeta({ ...meta, content: editorState });
  }, [editorState]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="不通時設定"
      buttons={[
        {
          label: 'テスト送信',
          className: `${classes.blue} ${meta.type === 1 ? '' : classes.none}`,
          onClick: testSendHandler,
          disabled: errorSet.size !== 0 || hasLoading(TESTMAIL_SENDING),
        },
        {
          label: '保存',
          className: common.buttonsPrimary,
          onClick: updateLibrary,
          disabled: errorSet.size !== 0,
        },
      ]}
      maxWidth={1160}
      buttonClose
    >
      <Grid container justifyContent="space-between">
        <MailSelect values={setting.type} />
        {/* kindは【library003】のlibraryNameと対応 */}
        <MailRadio
          values={meta.type === 1 ? setting.kind : setting.kindSms}
          libNameEditable={Boolean(true)}
          updateParam={updateParam}
          setUpdateParam={setUpdateParam}
          libraries={libraries}
          meta={meta}
          setMeta={setMeta}
          setEditorState={setEditorStateWrap}
          updateLibrary={updateLibrary}
          watchEdit={watchEdit}
          setWatchEdit={setWatchEdit}
          setUploadedList={setUploadedList}
        />
      </Grid>
      {meta.type === 1 ? (
        <MailTextfield values={setting.title} errorCallback={errorCallback} />
      ) : null}
      <Grid
        container
        justifyContent="space-between"
        alignItems="stretch"
      >
        {meta.type === 1 ? (
          <MailTitleAndBody
            key={mailBodyKey}
            initData={setting.content.state}
            setMailBody={setEditorState}
          />
        ) : (
          <SmsBody values={setting.contentSms} />
        )}
        {meta.type === 1 ? (
          <MailAppendFile
            uploadedList={uploadedList}
            setUploadedList={setUploadedList}
          />
        ) : null}
      </Grid>
    </Modal>
  );
}
