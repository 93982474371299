import client from '../httpClient';

const customerHistoriesApi = (customerId, searchParam) => {
  const GET_CUSTOMERS_HISTORIES = `/customers/histories/${customerId}`;

  const { columnNames = [], ...rest } = searchParam;
  const params = new URLSearchParams({ ...rest });
  columnNames.forEach((columnName) => params.append('columnName[]', columnName));

  return client.get(GET_CUSTOMERS_HISTORIES, { params });
};

export default customerHistoriesApi;
