import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import commonStyles from '../styles';
import CustomerBatchTable from './parts/customerBatchTable';
import UpdateButton from '../eleCommon/updateButton';
import CustomBackSelect from '../eleCommon/customBackSelect';
import CustomRadioGroup from '../eleCommon/customRadioGroup';
import BulkChargeChangeWindow from './bulkChargeChangeWindow';
import BulkChangeWindow from './bulkChangeWindow';
import { reload } from '../../store/search/searchItemsSlice';

const useStyles = makeStyles((theme) => ({
  headrWrap: {
    position: 'sticky',
    top: 0,
    left: 240,
    zIndex: 1,
    background: 'rgb(250 250 250)',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '14px 16px 6px',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1,
    width: 'fit-content',
  },
  ttl: {
    marginRight: 46,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 32px',
    position: 'sticky',
    top: 53,
    left: 0,
    zIndex: 1,
    width: 'fit-content',
  },
  num: {
    fontSize: '15px',
    lineHeight: '1.6',
    letterSpacing: '0',
    marginRight: 6,
  },
  num1: {
    fontWeight: '700',
    marginRight: 4,
  },
  num2: {
    marginRight: 4,
  },
  operation: {
    position: 'relative',
    width: 108,
    height: 40,
    '& .MuiFormControl-root': {
      position: 'relative',
      zIndex: 1,
      borderBottom: 'none!important',
    },
    '& .MuiInputBase-root:before': {
      content: 'none',
    },
    '& .MuiInputBase-root:after': {
      content: 'none',
    },
    '& .MuiSelect-root': {
      height: 40,
    },
  },
  operationTxt: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    pointerEvents: 'none',
    textAlign: 'left',
    padding: '0 28px 0 20px',
    lineHeight: '40px',
    color: '#fff',
    fontSize: '15px',
    fontWeight: '700',
    letterSpacing: '0',
    borderRadius: '4px',
    background: `linear-gradient(90deg, ${theme.palette.primaryColor} 0%, ${theme.palette.gradLightColor} 100%)`,
    '&.disabled': {
      pointerEvents: 'visible',
      background: '#DEDEDE',
      color: '#AFAFAF',
      '& span': {
        borderColor: '#AFAFAF transparent transparent',
      },
    },
  },
  operationIcon: {
    width: 0,
    height: 0,
    borderWidth: '4px 4px 0',
    borderStyle: 'solid',
    borderColor: '#fff transparent transparent',
    position: 'absolute',
    top: 17,
    right: 10,
  },
}));

function CustomerBatch(props) {
  const { list } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const baseClasses = commonStyles();

  // チェックボックス（一括変更ウィンドウ）
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const check = {
    c1: checked1,
    sc1: setChecked1,
    c2: checked2,
    sc2: setChecked2,
  };

  // モーダル用
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
    // 一括変更ウィンドウのチェックボックスを初期化
    setChecked1(false);
    setChecked2(false);
  };

  const [selectedCustomerId, setSelectedCustomerId] = useState([]);
  // listが更新された際にselectedCustomerIdを空にする
  useEffect(() => {
    setSelectedCustomerId([]);
  }, [list]);

  // 更新処理
  const updateStart = () => {
    setSelectedCustomerId([]);
    dispatch(reload());
    // listの更新は反響ツリーの更新でキックされる
  };

  // 一括操作
  const [operation, setOperation] = useState(0);
  const operationChange = (e) => {
    setOperation(Number(e.target.value));
    if (Number(e.target.value) === 0) {
      handleOpen1();
    } else {
      handleOpen2();
    }
  };

  const operationList = {
    operationItem: {
      type: 'selectBack',
      obj: {
        menus: {
          0: '担当変更',
          1: '情報変更',
        },
      },
      state: operation,
      change: operationChange,
      set: () => {},
    },
  };

  const getComponent = (data) => {
    switch (data.type) {
      case 'selectBack':
        return (
          <CustomBackSelect initial={data} />
        );
      case 'radioGroup':
        return (
          <CustomRadioGroup initial={data} />
        );
      default:
        return <div>対応しない要素です</div>;
    }
  };

  // 選択件数
  const choice = selectedCustomerId.length;

  return (
    <Grid className={classes.root}>
      <div className={classes.headrWrap}>
        <Grid className={classes.header}>
          <Typography
            className={`${baseClasses.title2} ${classes.ttl}`}
          >
            顧客一括管理
          </Typography>
          <UpdateButton
            onClick={updateStart}
          />
        </Grid>
        <Grid className={classes.box}>
          <Typography className={classes.num}>
            <span className={classes.num1}>{choice}</span>
            <span className={classes.num2}>件を</span>
          </Typography>
          <Grid className={classes.operation}>
            <Typography className={`${classes.operationTxt} ${!choice ? 'disabled' : ''}`}>
              一括操作
              <span className={classes.operationIcon} />
            </Typography>
            {getComponent(operationList.operationItem)}
          </Grid>
        </Grid>
      </div>
      <CustomerBatchTable
        rows={list}
        setSelectedCustomerId={setSelectedCustomerId}
        selectedCustomerId={selectedCustomerId}
      />
      {open1 && (
        <BulkChargeChangeWindow
          open={open1}
          onClose={handleClose1}
          choice={choice}
          selectedCustomerId={selectedCustomerId}
          updateStart={() => updateStart('BulkChargeChangeWindow')}
        />
      )}
      {open2 && (
        <BulkChangeWindow
          open={open2}
          onClose={handleClose2}
          choice={choice}
          check={check}
          getComponent={getComponent}
          selectedCustomerId={selectedCustomerId}
          updateStart={updateStart}
        />
      )}
    </Grid>
  );
}

export default CustomerBatch;
