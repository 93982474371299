import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import CustomSelect from '../../../eleCommon/customSelect';
import UserTextField from '../../parts/userTextField';
import { USER_CLASS_SELECT, MONTH_SELECT } from '../../../../constants/users';
import mokuhyoSetResisterApi from '../../../../apis/mokuhyoSet/mokuhyoSetResisterApi';

import { MAN_YEN } from '../../../../constants';
import { validateFormFloat1 } from '../../../../commonFunction/validations';
import { convertFormNumber } from '../../../../commonFunction/convertors';

const useStyles = makeStyles(() => ({
  rootGrid: {
    width: '100%',
    marginTop: '1%',
    padding: '0px',
    marginBottom: '2%',
  },
  childGrid: {
    width: 250,
    background: '#FFFFFF',
    border: '1px solid #C8C8C8',
    boxSizing: 'border-box',
    height: '37px',
    // marginRight: '1%',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      padding: '4px',
      margin: 0,
    },
    '& input': {
      textAlign: 'center',
    },
  },
  buttonChild: {
    width: '5%',
  },
  title4: {
    fontSize: '11px',
    fontWeight: 'bold',
  },
  userCustomButtonSave: {
    padding: '2px 6px',
    flex: 'none',
    order: 2,
    flexGrow: 0,
  },
  asterisk: { color: '#db3131', marginLeft: 3 },
  itemsContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectEle: {
    fontSize: '13px',
    textAlign: 'center',
    width: '80%',
    '&::before': { transform: 'scaleX(0)' },
  },
  mediumLengthContainer: {
    width: '77%',
    display: 'inline-block',
    textAlign: 'center',
    '& .MuiInputBase-root.MuiInput-underline': {
      width: '100%',
    },
  },
  shortLengthContainer: {
    width: '40%',
    display: 'inline-block',
    textAlign: 'center',
    '& .MuiInputBase-root.MuiInput-underline': {
      width: '100%',
    },
  },
  monthSelect: {
    '& .MuiInput-root': {
      minWidth: 'auto',
      width: 50,
    },
  },
}));

function PersonalCreate(props) {
  const { setIsOpen, reload, setReload, divisionId } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const mokuhyoList = useSelector((state) => state.mokuhyoList.mokuhyoList);
  const [validate, setValidate] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [localParam, setLocalParam] = useState({});
  const d = new Date();

  // Postデフォルト値
  const setInitialValue = () => {
    setLocalParam({
      userId: mokuhyoList.userId,
      divisionId: divisionId ?? mokuhyoList.userDivisions[0].divisionId,
      buildingCount: null,
      sales: null,
      positionCode: '',
      year: d.getFullYear(),
      month: d.getMonth() + 1,
    });
  };

  // デフォルト値の挿入
  useEffect(() => {
    setInitialValue();
  }, []);

  // 保存ボタンvalidation制御
  useEffect(() => {
    const paramList = [
      localParam.year,
      localParam.month,
      localParam.buildingCount,
      localParam.sales,
      localParam.positionCode,
    ];

    if (paramList.includes(null) || paramList.includes(0) || paramList.includes('')) {
      setIsCreate(false);
    } else {
      setIsCreate(true);
    }
  }, [localParam]);

  // 各項目変更に伴うStateの変更
  const changeState = (e, initial) => {
    let val = e.target ? e.target.value : e;
    if (initial.key === 'sales' || initial.key === 'buildingCount') {
      val = Number(val);
    }
    setLocalParam({
      ...localParam,
      [initial.key]: val,
    });
  };

  const firstField = [
    {
      title: '',
      required: true,
      key: 'year',
      type: 'number',
      state: localParam.year,
      set: changeState,
      notfullwidth: true,
      fontSize: '13px',
    },
    {
      title: '',
      required: false,
      key: 'month',
      type: 'flg',
      obj: MONTH_SELECT,
      state: localParam.month,
      set: changeState,
      notfullwidth: true,
      underline: true,
      defaultValue: d.getMonth() + 1,
      fontSize: '13px',
    },
  ];

  const baseField = [
    {
      title: '',
      required: true,
      key: 'buildingCount',
      type: 'number',
      state: localParam.buildingCount,
      set: changeState,
      notfullwidth: true,
      firstStr: '棟',
      fontSize: '13px',
      convertor: convertFormNumber,
      validator: validateFormFloat1({
        minFloat: 0.0,
        maxFloat: 99999.0,
        precisionInt: 1,
      }),
    },
    {
      title: '',
      required: true,
      key: 'sales',
      type: 'number',
      state: localParam.sales,
      set: changeState,
      notfullwidth: true,
      firstStr: '売上（万円）',
      fontSize: '13px',
      convertor: convertFormNumber,
      validator: validateFormFloat1({
        minFloat: 0.0,
        maxFloat: 99999.0,
        precisionInt: 4,
      }),
    },
    {
      title: '',
      required: true,
      key: 'positionCode',
      type: 'flg',
      obj: USER_CLASS_SELECT,
      state: localParam.positionCode,
      set: changeState,
      firstStr: '役割',
      notfullwidth: true,
      underline: true,
      fontSize: '11px',
    },
  ];

  // 組織の選択
  const selectDivision = (e) => {
    const val = e.target ? e.target.value : e;
    setLocalParam({
      ...localParam,
      divisionId: val,
    });
  };

  // Post mokuhyoSet002
  const createMokuhyo = (params) => {
    const tmpParams = { ...params };
    tmpParams.sales *= MAN_YEN;
    mokuhyoSetResisterApi(tmpParams)
      .then(() => {
        setInitialValue();
        setIsOpen(false);
      })
      .catch((err) => console.log(err));
  };

  const checkRequire = () => {
    const setValueNumberType = Object.assign({}, localParam);
    const requireList = [
      setValueNumberType.year,
      setValueNumberType.month,
      setValueNumberType.buildingCount,
      setValueNumberType.sales,
    ];
    return requireList.includes(null || '' || 0);
  };

  // 保存時処理
  const saveMokuhyo = async () => {
    setValidate(false);
    if (!checkRequire()) {
      const setValueNumberType = Object.assign({}, localParam);
      setValueNumberType.buildingCount = Number(setValueNumberType.buildingCount);
      setValueNumberType.month = Number(setValueNumberType.month);
      setValueNumberType.sales = Number(setValueNumberType.sales);
      setValueNumberType.year = Number(setValueNumberType.year);
      await createMokuhyo(setValueNumberType);
      await setReload(reload + 1);
    } else {
      setValidate(true);
    }
  };

  // 各選択肢生成
  const getComponent = (data) => {
    switch (data.type) {
      case 'number':
        return (
          <div style={{ display: 'inline-block' }}>
            <UserTextField initial={data} validate={validate} />
          </div>
        );
      case 'flg':
        return <CustomSelect initial={data} />;
      default:
        return <div>対応しない要素です</div>;
    }
  };

  return (
    <Grid className={classes.rootGrid}>
      <p className={baseClasses.title3}>新規月間目標</p>
      <Grid container className={classes.itemsContainer}>
        <Grid
          item
          className={classes.childGrid}
          style={{
            flexGrow: 0,
            flexBasis: '18%',
          }}
        >
          <div className={classes.title4}>
            年月<span className={classes.asterisk}>＊</span>
          </div>
          <div style={{ width: '22%' }}>
            <span style={{ lineHeight: '190%' }} className={classes.selectTxt}>
              {getComponent(firstField[0])}
            </span>
          </div>
          <span className={classes.title4}>年</span>
          <div
            style={{
              display: 'inline-block',
              textAlign: 'center',
            }}
            className={classes.monthSelect}
          >
            {getComponent(firstField[1])}
          </div>
          <span className={classes.title4}>月</span>
        </Grid>
        {baseField.map((data) => (
          <Grid
            item
            className={classes.childGrid}
            key={data.firstStr}
            style={data.key === 'buildingCount'
              ? {
                flexGrow: 0,
                flexBasis: '14%',
              } : {
                flexGrow: 0,
                flexBasis: '17%',
              }}
          >
            <div className={classes.title4}>
              {data.firstStr}
              <span className={classes.asterisk}>
                {data.required ? '＊' : ''}
              </span>
            </div>
            <div
              className={`${(data.key === 'buildingCount') ? classes.mediumLengthContainer : classes.shortLengthContainer}`}
            >
              {getComponent(data)}
            </div>
          </Grid>
        ))}
        <Grid
          item
          className={classes.childGrid}
          style={{
            flexGrow: 0,
            flexBasis: '26%',
            // marginRight: '1%',
          }}
        >
          <div className={classes.title4}>
            所属組織
          </div>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) => {
              selectDivision(e);
            }}
            label="label"
            defaultValue={divisionId ?? mokuhyoList.userDivisions[0].divisionId}
            className={classes.selectEle}
          >
            {mokuhyoList.userDivisions.map((data) => (
              <MenuItem value={data.divisionId} key={data.divisionId}>
                {data.divisionName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          item
          className={classes.buttonChild}
          style={{
            flexGrow: 0,
            maxWidth: '6%',
            flexBasis: '6%',
          }}
        >
          <Button
            onClick={() => {
              saveMokuhyo();
            }}
            disabled={!isCreate}
            className={`${classes.userCustomButtonSave} ${baseClasses.buttonsPrimary}`}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PersonalCreate;
