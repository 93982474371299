// import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Typography, Dialog, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RailwaySentakuLabel from './parts/railwaySentakuLabel';

import StationsList from './parts/stationsList';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '1303!important',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      margin: '0',
      width: '100%',
      height: '100%',
      bottom: '-34px',
    },
    '&[aria-hidden] .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '& .MuiGrid-item': {
      width: '100%',
    },
  },
  title: {
    /* Head */
    position: 'static',
    width: '52px',
    height: '16px',
    left: '0px',
    top: '4px',

    /* identical to box height, or 16px */
    display: 'flex',
    alignItems: 'center',

    /* OH-Black */
    color: '#333333',

    /* Inside auto layout */
    flex: 'none',
    order: '0',
    flexGrow: '0',
    margin: '0px 10px',
  },
  title4: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    textAlign: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      fontWeight: 400,
    },
  },
  title4Bold: {
    fontWeight: 'bold',
  },
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    /* OH-White */
    background: '#FFFFFF',
    boxSizing: 'border-box',
    /* Inside auto layout */
    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: '0px 0px',
    [commonTheme.breakpoints.down('sm')]: {
      height: '100%',
      background: '#f3f3f3',
    },
    [commonTheme.breakpoints.up('md')]: {
      /* OH-Gray */
      border: '1px solid #C8C8C8',
      // width: '664px',
      height: '684px',
      left: '0px',
      top: '708px',
    },
  },
  mainGridInner: {
    height: '100%',
  },
  subGrid: {
    position: 'relative',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      background: '#fff',
      marginTop: '16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      height: '40px',
    },
  },
  subGridPc: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none!important',
    },
  },
  buttonWrap: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
    },
  },
  button: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '18px 22px 18px 16px',
      borderBottom: '1px solid #F3F3F3',
    },
    '& .MuiButton-label': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  hr: {
    size: '1px',
    width: '95%',
    color: '#C8C8C8',
    marginTop: '0',
    marginBottom: '0',
  },
  headline: {
    [commonTheme.breakpoints.down('sm')]: {
      height: 'auto',
      position: 'relative',
      padding: '19px 0 16px',
      margin: '0px',
      top: '0px',
      background: '#fff',
      width: '100%',
      textAlign: 'center',
      borderBottom: '1px solid #F3F3F3',
      filter: 'drop-shadow(0px 0px 8px rgba(51, 51, 51, 0.08))',
    },
  },
  minHeadline: {
    [commonTheme.breakpoints.down('sm')]: {
      filter: 'none',
    },
  },
  headlineTtl: {
    [commonTheme.breakpoints.down('sm')]: {
      margin: 'auto',
      fontSize: '20px',
      lineHeight: '1',
      textAlign: 'center',
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 16px 16px 32px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '50%',
      padding: '12px 0px',
    },
  },
  headerShowOnlyFromStation: {
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: 'auto',
    width: '20px',
    height: '20px',
    transform: 'translate(-50%, -50%)',
  },
  stationGrid: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '0px',
      height: '100vh',
      backgroundColor: '#fff',
      borderRadius: '4px 4px 0 0',
      zIndex: '5',
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      height: '625px',
    },
    '& .MuiGrid-item': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
});

export default function SelectStations(props) {
  const {
    selectedItems,
    stations,
    showOnlyFromStation,
    clickStationsFunc,
    onClose,
    setOpenStations,
    openFromStation,
    setOpenFromStation,
    openToStation,
    setOpenToStation,
    isNearestStation,
  } = props;
  const commonClasses = commonStyles();
  const classes = useStyles();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <>
      {!isSp && (
        <Grid className={`${classes.title} ${classes.headline}`}>
          <Typography className={`${commonClasses.title4} ${classes.headlineTtl} nowrap`}>
            {isNearestStation ? '最寄り駅選択' : '区間選択'}
            <Button className={commonClasses.closeButton} onClick={onClose} />
          </Typography>
        </Grid>
      )}
      <Grid className={`${classes.mainGrid}`}>
        <Grid className={classes.mainGridInner}>
          <RailwaySentakuLabel
            setOpenStations={setOpenStations}
            selectedItems={selectedItems}
          />
          {/* PCで表示 */}
          { isNearestStation ? null : (
            <Grid container className={`${classes.subGrid} ${classes.subGridPc}`}>
              <Grid item className={`${classes.header} ${showOnlyFromStation && classes.headerShowOnlyFromStation}`}>
                <Typography className={classes.title4}>始駅</Typography>
              </Grid>
              {!showOnlyFromStation && (
                <>
                  <Grid item className={classes.header}>
                    <Typography className={classes.title4}>終駅</Typography>
                  </Grid>
                  <Grid className={`${classes.arrow}`}>
                    <ArrowForwardIcon fontSize="small" />
                  </Grid>
                </>
              )}
            </Grid>
          )}

          {/* SPで表示 */}
          <Grid container className={`${classes.subGrid} ${commonClasses.onlySp}`}>
            <Grid item className={classes.buttonWrap}>
              <Button onClick={(() => { setOpenFromStation(true); })} className={classes.button}>
                <Typography className={classes.title4}>始駅</Typography>
                <Typography className={`${classes.title4} ${classes.title4Bold}`}>{selectedItems.currentFromStation.name}</Typography>
              </Button>
            </Grid>
            {!showOnlyFromStation && (
              <Grid item className={classes.buttonWrap}>
                <Button onClick={(() => { setOpenToStation(true); })} className={classes.button}>
                  <Typography className={classes.title4}>終駅</Typography>
                  <Typography className={`${classes.title4} ${classes.title4Bold}`}>{selectedItems.currentToStation.name}</Typography>
                </Button>
              </Grid>
            )}
          </Grid>
          {isNearestStation ? null : <hr className={`${classes.hr} ${commonClasses.onlyPc}`} />}
          <Grid container className={`${classes.stationGrid} ${commonClasses.onlyPc}`}>
            <StationsList
              selectedStation={selectedItems.currentFromStation}
              stations={stations}
              showOnlyFromStation={showOnlyFromStation || isNearestStation}
              clickStationsFunc={clickStationsFunc}
              setOpenFromStation={setOpenFromStation}
              setOpenToStation={setOpenToStation}
            />
            {(!showOnlyFromStation && !isNearestStation) && (
              <StationsList
                selectedStation={selectedItems.currentToStation}
                stations={stations}
                showOnlyFromStation={showOnlyFromStation}
                clickStationsFunc={clickStationsFunc}
                setOpenFromStation={setOpenFromStation}
                setOpenToStation={setOpenToStation}
                toStationFlg
              />
            )}
          </Grid>
          {/* SPのみで表示させる始駅・終駅のモーダル */}
          <Grid container className={`${classes.stationGrid} ${commonClasses.onlySp}`}>
            {/* 始駅用モーダル */}
            <Dialog
              className={classes.dialog}
              open={openFromStation}
              onClose={(() => { setOpenFromStation(false); })}
            >
              <Grid className={`${classes.headline}`}>
                <Typography className={`${commonClasses.title4} ${classes.headlineTtl}`}>駅</Typography>
              </Grid>
              <StationsList
                selectedStation={selectedItems.currentFromStation}
                stations={stations}
                showOnlyFromStation={showOnlyFromStation}
                clickStationsFunc={clickStationsFunc}
                setOpenFromStation={setOpenFromStation}
                setOpenToStation={setOpenToStation}
              />
            </Dialog>
            {/* 終駅用モーダル */}
            <Dialog
              className={classes.dialog}
              open={openToStation}
              onClose={(() => { setOpenToStation(false); })}
            >
              <Grid className={`${classes.headline} ${classes.minHeadline}`}>
                <Typography className={`${commonClasses.title4} ${classes.headlineTtl}`}>駅</Typography>
              </Grid>
              <StationsList
                selectedStation={selectedItems.currentToStation}
                stations={stations}
                showOnlyFromStation={showOnlyFromStation}
                clickStationsFunc={clickStationsFunc}
                setOpenFromStation={setOpenFromStation}
                setOpenToStation={setOpenToStation}
                toStationFlg
              />
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
