import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';
import { toNumber, joinBySlash, setLocalStorage } from '../../../commonFunction';
import { useRerender } from '../../../hooks';
import {
  formatFormData,
  formatInitialParamStore,
} from './helpers';

const numberKeys = ['displayOrder', 'pricePatternTypeCode2', 'pricePatternTypeCode3'];

const EditDialogContext = createContext({});

export function useEditDialogContext() {
  return useContext(EditDialogContext);
}

export function EditDialogContextProvider(props) {
  const { children } = props;

  const {
    isShowEditDialog,
    showEditDialog,
    currentArea,
    editTargetRef,
    editApi,
  } = useCombinationSettingContext();

  const { rerender } = useRerender();
  const dispatch = useDispatch();

  // フォームステート
  const formRef = useRef({});
  // 選択された店舗情報
  const selectedStoresRef = useRef({});

  // 希望地域開閉用のローカルステート
  const [openArea, setOpenArea] = useState(false);
  // 店舗開閉用のローカルステート
  const [openStore, setOpenStore] = useState(false);

  // 地域初期値
  const [initialParamArea, setInitialParamArea] = useState([]);
  // 店舗選択初期値
  const [initialParamStore, setInitialParamStore] = useState([]);

  // 操作している店舗情報1~4
  const storeGroupIdsPatternTypeCodeRef = useRef(0);

  // ボタン制御
  const disabled = useMemo(() => {
    const {
      patternName, displayOrder,
      targetCityName, storeGroupNamesPatternTypeCode1 } = formRef.current;
    return ![
      patternName, displayOrder, targetCityName,
      storeGroupNamesPatternTypeCode1?.length].every(Boolean);
  }, [
    formRef.current.patternName, formRef.current.displayOrder,
    formRef.current.targetCityName, formRef.current.storeGroupNamesPatternTypeCode1]);

  // テキスト系InputがonBlur
  const onBlur = useCallback((e) => {
    const { name, value } = e.target;

    // 価格チェック
    const { pricePatternTypeCode2, pricePatternTypeCode3 } = formRef.current;
    const numberValue = toNumber(value);
    const upperLimitPrice = toNumber(pricePatternTypeCode3);
    const lowerLimitPrice = toNumber(pricePatternTypeCode2);

    let message = null;
    if (numberKeys.includes(name) && numberValue < 0) {
      message = '自然数をいれてください';
    } else if (name === 'pricePatternTypeCode3' && numberValue && lowerLimitPrice && numberValue >= lowerLimitPrice) {
      message = '下限価格は上限以上にできません';
    } else if (name === 'pricePatternTypeCode2' && numberValue && upperLimitPrice && numberValue <= upperLimitPrice) {
      message = '上限価格は下限以下にできません';
    }
    if (message) {
      formRef.current[name] = '';
      // エラーメッセージが表示される
      dispatch(changeAlertMessage({ msgList: [message] }));
      // 入力内容を削除
      e.target.value = '';
    }
    // 画面更新
    rerender();
  }, []);

  const onInputChange = useCallback((e) => {
    const { name, value } = e.target;
    formRef.current[name] = value;
  }, []);

  // エリア選択決定
  const onAreaSubmit = useCallback((res) => {
    // setInitialParamArea(res);
    formRef.current.targetCityCode = joinBySlash(res.map(item => item.cityJisCode));
    formRef.current.targetCityName = joinBySlash(res.map(item => item.label));
    rerender();
  }, []);

  // 店舗選択決定
  const onStoreSubmit = useCallback((res) => {
    const num = storeGroupIdsPatternTypeCodeRef.current;
    formRef.current['storeGroupIdsPatternTypeCode' + num] = res.map(item => item.storeGroupId);
    formRef.current['storeGroupNamesPatternTypeCode' + num] = res.map(item => item.storeGroupName);
    selectedStoresRef.current[num] = res;
  }, []);

  const onSubmit = useCallback(async () => {
    await editApi(formatFormData(formRef.current, selectedStoresRef.current));
  }, []);

  // 地域選択決定
  const openAreaDialog = useCallback(() => {
    if (
      formRef.current.targetCityCode
      && formRef.current.targetCityCode !== joinBySlash(initialParamArea.map(item => item.jisCode))
    ) {
      // 選択地域初期化
      const targetCityNames = formRef.current.targetCityName?.split('/').filter(Boolean);
      const targetCityCodes = formRef.current.targetCityCode.split('/').filter(Boolean);
      setInitialParamArea(targetCityCodes.map((code, i) => {
        return {
          type: 'area',
          // Treeでデフォルトの際選択状態にするため
          uniqueId: code,
          // onAreaSubmitの際、取得できるため
          jisCode: code,
          label: targetCityNames[i],
        };
      }));
    }
    setOpenArea(true);
  }, [initialParamArea]);

  const patternTypeIdStorageKey = 'PATTERN_TYPE_ID_';
  // 店舗選択決定
  const openShopDialog = useCallback((num) => {
    storeGroupIdsPatternTypeCodeRef.current = num;
    const initialParamStoreContent = formatInitialParamStore(num, formRef.current);
    setInitialParamStore(initialParamStoreContent);
    setLocalStorage(patternTypeIdStorageKey + num, initialParamStoreContent);
    setOpenStore(true);
  }, []);

  // 選択済店舗初期設定
  const initializeSelectedStores = (num) => {
    const initialParamStoreContent = formatInitialParamStore(num, formRef.current);
    selectedStoresRef.current[num] = initialParamStoreContent;
  };

  useEffect(() => {
    // モダール表示の際、ステートをリセットする
    if (isShowEditDialog) {
      formRef.current = editTargetRef.current
        ? JSON.parse(JSON.stringify(editTargetRef.current))
        : {
          areaCode: currentArea.areaCode,
          // 表示順序にはデフォルトで999
          displayOrder: 999,
        };
      // 選択済店舗を反映させる（1:履歴あり～4:履歴、価格なし）
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5591
      for (let i = 1; i <= 4; i += 1) {
        initializeSelectedStores?.(i);
      }
      rerender();
    }
  }, [isShowEditDialog]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isShowEditDialog,
    currentArea,
    showEditDialog,
    editTargetRef,
    formRef,
    onBlur,
    onInputChange,
    openAreaDialog,
    openShopDialog,
    onSubmit,
    disabled,
    openArea,
    initialParamArea,
    setOpenArea,
    onAreaSubmit,
    openStore,
    initialParamStore,
    setOpenStore,
    onStoreSubmit,
  };

  return (
    <EditDialogContext.Provider value={value}>
      {children}
    </EditDialogContext.Provider>
  );
}
