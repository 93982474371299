import ListWindowContainers from '../../../../containers/common/taiouRireki/listWindow/listWindowContainer';

function ListWindow(props) {
  const { open, onClose, customerId, customer } = props;
  return (
    <ListWindowContainers
      open={open}
      onClose={onClose}
      customerId={customerId}
      customer={customer}
    />
  );
}

export default ListWindow;
