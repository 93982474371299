import React, { useState } from 'react';
import {
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommonDialog from '../common/modal';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&:not(:first-child)': {
      marginTop: 8,
    },
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginRight: 4,
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '&:not($checked) .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: -1,
      },
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
}));
function CustomCheckModal(props) {
  const { lists, setLists, setName, open, setOpen, setValue } = props;
  const [state, setState] = useState(lists.map((list) => ({ ...list })));
  const classes = useStyles();

  const handleChange = (value, item) => {
    for (let i = 0; state.length > i; i += 1) {
      if (state[i].id === item.id) {
        const hoge = state.map((list) => ({ ...list }));
        hoge[i].checked = value;
        setState(hoge);
      }
    }
  };

  const handleSave = () => {
    setOpen(false);
    setLists(state);
    const wordArray = [];
    const valueArray = [];
    for (let i = 0; state.length > i; i += 1) {
      if (state[i].checked) {
        wordArray.push(state[i].label);
        valueArray.push(state[i].id);
      }
    }
    if (wordArray.length > 0) {
      setName(`置いていく（${wordArray.join()}）`);
    }
    setValue(valueArray);
  };

  return (
    <Grid>
      {open ? (
        <CommonDialog
          open={open}
          onClose={() => { setOpen(false); }}
          title="追客ステータス"
          width={350}
          buttons={[
            {
              label: 'OK',
              onClick: handleSave,
            },
          ]}
        >
          {state.map((item) => (
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  checked={item.checked}
                  onChange={(e) => {
                    handleChange(e.target.checked, item);
                  }}
                />
            }
              label={item.label}
            />
          ))}
        </CommonDialog>
      ) : (
        <Button onClick={() => setOpen(true)}>追客ステータス</Button>
      )}
    </Grid>
  );
}

export default CustomCheckModal;
