import React, { useState, useRef, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 10,
    height: '56px',
  },
  form: {
    display: 'flex',
    flexDirction: 'colum',
  },
  formHeader: {
    color: theme.palette.primaryColor,
    fontSize: 12,
    fontWeight: 400,
  },
  textField: {
    fontSize: 13,
    color: '333333',
    width: '80%',
  },
  cancelButtonTxt: {
    fontSize: 12,
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontStyle: 'normai',
    fontWeight: 700,
    lineHeight: 1.6,
  },
  resistButton: {
    fontSize: 15,
    color: '#ffffff',
    fontFamily: 'Roboto',
    fontStyle: 'normai',
    fontWeight: 700,
    lineHeight: 1.6,
    width: '74px',
    height: '40px',
  },
}));

function InputIdForm(props) {
  const { show, setShow, onSubmit } = props;
  const [memberId, setMemberId] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  const inputRef = useRef();

  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    setMemberId(e.target.value);
  };

  const classes = useStyles();
  const baseClasses = commonStyles();

  const onSave = async () => {
    setIsDisable(true);
    await onSubmit(memberId, () => {
      handleClose();
      setMemberId('');
    });
    setIsDisable(false);
  };

  const onKeyPress = (e) => {
    // Enterキーを押した場合
    if (e.key === 'Enter' && memberId) {
      onSave();
    }
  };

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.querySelector('input')?.focus();
    }
  }, [show, inputRef]);

  if (show) {
    return (
      <Grid className={classes.container}>
        <Grid style={{ width: '70%' }}>
          <Typography className={classes.formHeader}>
            Web会員ID
          </Typography>
          <TextField
            className={classes.textField}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            ref={inputRef}
          />
        </Grid>
        <Grid style={{ width: '15%' }}>
          <Button
            onClick={handleClose}
          >
            <Typography className={classes.cancelButtonTxt}>
              キャンセル
            </Typography>
          </Button>
        </Grid>
        <Grid style={{ width: '15%' }}>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.resistButton}`}
            disabled={!memberId || isDisable}
            onClick={onSave}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    );
  }
  return null;
}

export default InputIdForm;
