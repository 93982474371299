import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'loading',
  initialState: {
    list: [],
  },
  reducers: {
    setList: (state, { payload }) => {
      state.list = Array.isArray(payload) ? payload : [];
    },
    addLoadingId: (state, { payload }) => {
      state.list = [...state.list, payload];
    },
    removeLoadingId: (state, { payload }) => {
      const idx = state.list.indexOf(payload);
      const tmp = [...state.list];
      if (idx !== -1) {
        tmp.splice(idx, 1);
        state.list = tmp;
      }
    },
    clearLoadingIds: (state) => {
      state.list = [];
    },
  },
});

/**
 * @param {import("../type").Store} state
 */
export const loadingSelector = (state) => state.loading;
export const { setList, addLoadingId, removeLoadingId, clearLoadingIds } = slice.actions;
export default slice.reducer;
