import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const idList4PotentialSlice = createSlice({
  name: 'idList4Potential',
  initialState: { idList4Potential: initialState },
  reducers: {
    idList4PotentialApi: (state, data) => {
      state.idList4Potential = data.payload;
    },
  },
});

export const { idList4PotentialApi } = idList4PotentialSlice.actions;

export default idList4PotentialSlice.reducer;
