import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoGetSearchConditionSlice = createSlice({
  name: 'hpinfoGetSearchCondition',
  initialState: { hpinfoGetSearchCondition: initialState },
  reducers: {
    hpinfoGetSearchConditionApi: (state, data) => {
      state.hpinfoGetSearchCondition = data.payload;
    },
  },
});

export const { hpinfoGetSearchConditionApi } = hpinfoGetSearchConditionSlice.actions;

export default hpinfoGetSearchConditionSlice.reducer;
