import LibrarySendContainer from '../../../containers/mailLibrary/librarySendContainer';

export default function LibrarySend(props) {
  const { open, onClose, selectDir, rows, reloadLibraries } = props;

  if (open) {
    return (
      <LibrarySendContainer
        onClose={onClose}
        open={open}
        selectDir={selectDir}
        rows={rows}
        reloadLibraries={reloadLibraries}
      />
    );
  } else {
    return false;
  }
}
