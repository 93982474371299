import { createSlice } from '@reduxjs/toolkit';

const initialState = { targets: [] };

export const kimarisTargetSettingsGetListSlice = createSlice({
  name: 'kimarisTargetSettingsGetList',
  initialState: { kimarisTargetList: initialState },
  reducers: {
    setKimarisTarget: (state, data) => {
      state.kimarisTargetList = data.payload;
    },
    updateKimarisTarget: (state, { payload: { targetId, params } }) => {
      state.kimarisTargetList.targets = state.kimarisTargetList.targets.map(
        (target) => (
          target.keymalisTargetSettingId === targetId
            ? { ...target, ...params }
            : target
        ),
      );
    },
  },
});

export const { setKimarisTarget, updateKimarisTarget } = kimarisTargetSettingsGetListSlice.actions;

export default kimarisTargetSettingsGetListSlice.reducer;
