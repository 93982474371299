import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
    textAlign: 'center',
    borderBottom: '1px solid #8C8C8C',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '13px 4px',
      borderBottom: '1px solid #C8C8C8',
    },
    '&:not(:last-child)::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: 1,
      height: '88%',
      position: 'absolute',
      top: '6%',
      right: 0,
      [commonTheme.breakpoints.down('sm')]: {
        height: 'calc(100% - 8px)',
        top: '4px',
      },
    },
    '&:nth-child(1)': {
      width: 64,
      textAlign: 'left',
    },
    '&:nth-child(3)': {
      width: 40,
    },
    '&:nth-child(4)': {
      width: 40,
    },
  },
  link: {
    color: theme.palette.primaryColor,
  },
  icon: {
    display: 'block',
    fontSize: 16,
    color: theme.palette.primaryColor,
    margin: '0 auto',
  },
  icon2: {
    fontSize: 16,
    color: theme.palette.primaryColor,
    display: 'inline-block',
    margin: '0px 3px -3px 0',
  },
}));

export default function BrowsingTableRow(props) {
  const {
    row,
    isSP,
    adjustPriceText,
  } = props;

  const classes = useStyles();

  const getIcon = (val) => {
    switch (val) {
      case '閲覧':
        return (
          <VisibilityIcon className={classes.icon2} />
        );
      case '資料請求':
        return (
          <LocalShippingIcon className={classes.icon2} />
        );
      case 'お気に入り':
        return (
          <FavoriteIcon className={classes.icon2} />
        );
      default:
        return (
          <CheckBoxIcon className={classes.icon2} />
        );
    }
  };

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.tableRow}
    >
      <TableCell className={classes.tableTd}>
        {row.accessedAt}
      </TableCell>
      <TableCell className={classes.tableTd}>
        <img src={row.thumbnail} alt="" />
      </TableCell>
      <TableCell className={classes.tableTd}>
        <Link
          href={row.homepage}
          target="_blank"
          className={classes.link}
        >
          <LaunchIcon className={classes.icon} />
        </Link>
      </TableCell>
      {isSP ? null : (
        <TableCell className={classes.tableTd}>
          <Link
            href={row.registaUrl}
            target="_blank"
            className={classes.link}
          >
            <LaunchIcon className={classes.icon} />
          </Link>
        </TableCell>
      )}
      <TableCell className={classes.tableTd}>
        {row.buildingType}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {adjustPriceText(row.price)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {/* 仮 APIに項目がない */}
        <Link
          href={row.registaUrl}
          target="_blank"
          className={classes.link}
        >
          {row.address1}
          {row.address2}
          {row.address3}
          {row.address4}
        </Link>
      </TableCell>
      <TableCell className={classes.tableTd}>
        {(row.railway && row.station) && (
          `${row.railway}「${row.station}」駅${row.walk ? ` 徒歩${row.walk}分` : ''}`
        )}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {row.InformationProvider}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {getIcon(row.content)}
        {row.content}
      </TableCell>
    </TableRow>
  );
}
