import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function TaiouRirekiSearchCheckBoxAll(props) {
  const {
    item,
    onChange,
  } = props;
  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const newItem = {
      ...item,
      checked: isChecked,
      detailCodes: item.detailCodes.map((v) => {
        return {
          ...v,
          checked: isChecked,
        };
      }),
    };
    onChange(newItem);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={item.checked ?? false}
            onChange={handleChange}
            color="primary"
            value={item.actionCode}
          />
        }
        label={item.actionCodeLabel}
      />
    </FormGroup>
  );
}
