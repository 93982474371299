import { createSlice } from '@reduxjs/toolkit';

import { getCookie, setCookie, deleteCookie } from '../../commonFunction/cookie';

const initialState = {
  token: getCookie('ACCESS_TOKEN'),
};

export const accessTokenSlice = createSlice({
  name: 'accessToken',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }) => {
      setCookie('ACCESS_TOKEN', payload);
      state.token = payload;
    },
    clearAccessToken: (state) => {
      deleteCookie('ACCESS_TOKEN');
      state.token = '';
    },
  },
});

export const { setAccessToken, clearAccessToken } = accessTokenSlice.actions;

export default accessTokenSlice.reducer;
