import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  open: false,
  title: '',
  msgList: [],
  buttons: [],
  onBackdropClick: null,
};

export const customConfirmMessageSlice = createSlice({
  name: 'confirmMessage',
  initialState: { confirmStatus: initialState },
  reducers: {
    changeConfirmMessage: (state, data) => {
      const { msgList, title, buttons, onBackdropClick = () => {} } = data.payload;
      state.confirmStatus.open = true;
      state.confirmStatus.title = title === undefined ? '' : title;
      state.confirmStatus.msgList = msgList;
      state.confirmStatus.buttons = buttons;
      state.confirmStatus.onBackdropClick = onBackdropClick;
    },
    closeConfirmMessage: (state) => {
      state.confirmStatus.open = false;
      state.confirmStatus.title = '';
      state.confirmStatus.msgList = [];
      state.confirmStatus.buttons = [];
      state.confirmStatus.onBackdropClick = () => {};
    },
    backdropClickConfirmMessage: (state) => {
      state.confirmStatus.onBackdropClick();
    },
  },
});
// export const initialState = {
//   open: false,
//   title: '',
//   msgList: [],
//   button1: '',
//   button2: '',
//   set1: '',
//   set2: '',
// };

// export const customConfirmMessageSlice = createSlice({
//   name: 'confirmMessage',
//   initialState: { confirmStatus: initialState },
//   reducers: {
//     changeConfirmMessage: (state, data) => {
//       const { msgList, title, button1, button2, set1, set2 } = data.payload;
//       state.confirmStatus.open = true;
//       state.confirmStatus.title = title === undefined ? '' : title;
//       state.confirmStatus.msgList = msgList;
//       state.confirmStatus.button1 = button1 === undefined ? 'CANCEL' : button1;
//       state.confirmStatus.button2 = button2 === undefined ? 'OK' : button2;
//       state.confirmStatus.set1 = set1 === undefined ? '' : set1;
//       state.confirmStatus.set2 = set2 === undefined ? '' : set2;
//     },
//     closeConfirmMessage: (state) => {
//       state.confirmStatus.open = false;
//       state.confirmStatus.title = '';
//       state.confirmStatus.msgList = [];
//       state.confirmStatus.button1 = '';
//       state.confirmStatus.button2 = '';
//       state.confirmStatus.set1 = '';
//       state.confirmStatus.set2 = '';
//     },
//   },
// });

export const {
  changeConfirmMessage,
  closeConfirmMessage,
  backdropClickConfirmMessage,
} = customConfirmMessageSlice.actions;

export default customConfirmMessageSlice.reducer;
