import { Button, Grid } from '@material-ui/core';
import { useEffect, useState, useMemo } from 'react';
// import { useSelector } from 'react-redux';
import moment from 'moment';

import commonStyles from '../styles';

import AddAttachment from './parts/addAttachment';
import AddContact from './parts/addContact';
import AddCustomer from './parts/addCustomer';
import AddFamily from './parts/addFamily';
import AddGetUser from './parts/addGetUser';
import AddMemo from './parts/addMemo';
import gensenStyle from './parts/gensenRegisterStyles';
// import { toNumber } from '../../commonFunction';
import ListItem from './parts/listItem';

export default function GensenRegisterComponent(props) {
  const {
    registerFunc,
    data,
    setData,
    readOnly,
    signUpFlg,
  } = props;
  const gCommon = gensenStyle();
  const common = commonStyles();
  const [position, setPosition] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  // // 【webres015】未処理反響情報globalState
  // const responseData = useSelector(
  //   (state) => state.responseByResponseId.responseByResponseId,
  // );

  // customerのデータ形式から未処理反響登録のデータ形式に変換
  const shapeObj = (dataObj) => {
    const requestParams = {
      receptAt: dataObj.receptAt || moment().format('YYYY/MM/DD HH:mm:ss'),
      responsibleUserId: dataObj.responsibleUserId || null,
      responsibleUserName: dataObj.responsibleUserName || null,
      responsibleDivisionId: dataObj.responsibleUserDivisionId || null,
      lastName: dataObj.customerLastName || null,
      firstName: dataObj.customerFirstName || null,
      lastNameKana: dataObj.customerLastNameKana || null,
      firstNameKana: dataObj.customerFirstNameKana || null,
      subLastName: dataObj.subLastName || null,
      subFirstName: dataObj.subFirstName || null,
      subLastNameKana: dataObj.subLastNameKana || null,
      subFirstNameKana: dataObj.subFirstNameKana || null,
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4838 ②
      phoneNumber: dataObj.phoneNumber1.replace(/-/g, '') || null,
      // phoneNumber: dataObj.phoneNumber1 || null,
      phoneNumberMemo: dataObj.phoneNumberMemo || null,
      mail: dataObj.mail1 || null,
      mailMemo: dataObj.mailMemo || null,
      largeResponseTypeId: dataObj.largeResponseTypeId || null,
      mediumResponseTypeId: dataObj.mediumResponseTypeId || null,
      smallResponseTypeId: dataObj.smallResponseTypeId || null,
      fineResponseTypeId: dataObj.fineResponseTypeId || null,
      memo: dataObj.memo || null,
      familyStructureCode: dataObj.familyStructureCode || null,
      acquisitionUserId: dataObj.acquisitionUserId || null,
      acquisitionCode: dataObj.acquisitionCode || null,
      acquisitionMemo: dataObj.acquisitionMemo || null,
      attachmentFiles: dataObj.attachmentFiles || [],
      // latitude: dataObj.latitude || null,
      // longitude: dataObj.longitude || null,
    };

    // update: 2023/04/03 0を送るとバリデートエラー発生するため変更
    // null許容されていないデータ
    // const notNullableList = [
    //   'mediumResponseTypeId',
    //   'smallResponseTypeId',
    //   'fineResponseTypeId',
    //   'acquisitionUserId',
    //   'familyStructureCode',
    //   'acquisitionCode',
    // ];
    // notNullableList.forEach((key) => {
    //   if (typeof requestParams[key] !== 'number') {
    //     requestParams[key] = toNumber(requestParams[key]);
    //   }
    // });

    if (position.lat) requestParams.latitude = position.lat;
    if (position.lon) requestParams.longitude = position.lon;

    return requestParams;
  };

  const registerBtnHandler = (obj) => {
    registerFunc(shapeObj(obj));
  };

  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      const crd = pos.coords;

      setPosition({
        lat: crd.latitude,
        lon: crd.longitude,
      });
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
    // getResponseFunc();
  }, []);

  // useEffect(() => {
  //   if (responseData?.responses && Object.keys(responseData?.responses).length > 0) {
  //     const targetObj = Object.assign({}, responseData.responses);
  //     targetObj.latitude = position.lat; // データセットの中に緯度をセット
  //     targetObj.longitude = position.lon; // データセットの中に経度をセット
  //     setData(targetObj);
  //   }
  // }, [responseData]);

  const checkValid = (data1) => {
    // customersのデータ形式でバリデーションチェック
    const requiredField = [
      // ファイル添付
      Boolean(data1.attachmentFiles.length),
      // 主 顧客名
      data1.customerLastName,
      // data1.customerFirstName,
      // 主 電話番号
      data1.phoneNumber1,
      // 獲得者
      data1.acquisitionUserId || data1.acquisitionMemo || null,
      // 担当者
      data1.responsibleUserId,
      // 反響媒体 大
      data1.largeResponseTypeId,
      // 反響媒体 中
      // data1.mediumResponseTypeId,
    ];
    return ![false, null, '', 0].some((invalid) => requiredField.includes(invalid));
  };

  const positionText = useMemo(() => {
    if (position.lat && position.lon) return [position.lat, position.lon].join(' ,');
    return (
      <Grid className={common.small}>
        座標を取得できませんでした。<br />
        位置情報利用許可を確認し、または電波が届ける場所でもう一度試してください。
      </Grid>
    );
  }, [position]);

  useEffect(() => {
    console.log(data);
    setIsValid(checkValid(data));
  }, [data]);

  return (
    <Grid>
      <AddAttachment
        list={[...data.attachmentFiles]}
        onChange={(files) => {
          setData({
            ...data,
            attachmentFiles: files,
          });
        }}
        readOnly={readOnly}
      />
      <Grid>
        <AddCustomer
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorCallback={errorCallback}
        />
      </Grid>
      <Grid style={{ marginBottom: 42 }}>
        <AddContact
          data={data}
          setData={setData}
          readOnly={readOnly}
          errorCallback={errorCallback}
        />
      </Grid>
      <Grid>
        <Grid className={gCommon.blockWrap}>
          <AddFamily data={data} setData={setData} readOnly={readOnly} />
          <AddMemo data={data} setData={setData} readOnly={readOnly} />
        </Grid>
      </Grid>
      { !signUpFlg && (
        <ListItem
          transparent
          label="緯度・経度"
          readOnly
        >
          { readOnly ? [data.latitude, data.longitude].join(', ') : positionText }
        </ListItem>
      )}
      <Grid style={{ marginTop: 42 }}>
        <AddGetUser data={data} setData={setData} readOnly={readOnly} />
      </Grid>
      <Grid container justifyContent="center" style={{ padding: 24 }}>
        {
          readOnly ? null : (
            <Button
              className={common.buttonsPrimary}
              onClick={() => registerBtnHandler(data)}
              disabled={!isValid || errorSet.size !== 0}
            >
              保存
            </Button>
          )
        }
      </Grid>
    </Grid>
  );
}
