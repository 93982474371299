import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import RegionTabs from '../../search/railwayAndStations/parts/regionTabs';
import { dateFormatter } from '../../../commonFunction';

const useStyles = makeStyles({
  dateWrap: {
    display: 'inline-flex',
    background: '#fff',
    border: '1px solid #C8C8C8',
    alignItems: 'center',
    marginRight: 16,
    padding: 8,
  },
  headerWrap: {
    alignItems: 'center',
    padding: '16px 24px',
    '& > div': { width: 'auto' },
  },
  allWrap: { height: '100%', flexWrap: 'nowrap' },
  body: {
    padding: '0 24px',
  },
  date: {
    fontWeight: 700,
    marginLeft: 16,
  },
});

export default function DivisionInformationLayout(props) {
  const {
    title,
    children,
    selectedAreaCode,
    onChangeAreaTab,
    setting,
    headerExtend,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.allWrap}>
      <Grid className={common.header}>
        <Typography className={common.title2}>{title}</Typography>
      </Grid>
      <Grid container className={classes.headerWrap}>
        <RegionTabs
          selectedArea={selectedAreaCode}
          clickAreaTabFunc={onChangeAreaTab}
          closeCollapse={() => { return false; }}
        />
        {setting ? (
          <Grid className={classes.dateWrap}>
            <span>一括取り込み適用日</span>
            <span className={classes.date}>{dateFormatter(new Date(setting.applyingAt), 'YYYY/MM/DD hh:mm')}</span>
          </Grid>
        ) : null}
        {headerExtend}
      </Grid>
      <Grid className={classes.body}>
        {children}
      </Grid>
    </Grid>
  );
}
