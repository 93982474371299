import ManagementLineContainers from '../../../containers/common/managementLine/managementLineContainer';

function ManagementLine(props) {
  const {
    open,
    onClose,
    customerId,
  } = props;
  return (
    <ManagementLineContainers
      open={open}
      onClose={onClose}
      customerId={customerId}
    />
  );
}

export default ManagementLine;
