import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const calendarDownloadTSVSlice = createSlice({
  name: 'calendarDownloadTSV',
  initialState: { calendarDownloadTSV: initialState },
  reducers: {
    calendarDownloadTSVApi: (state, data) => {
      state.calendarDownloadTSV = data.payload;
    },
  },
});

export const { calendarDownloadTSVApi } = calendarDownloadTSVSlice.actions;

export default calendarDownloadTSVSlice.reducer;
