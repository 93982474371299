import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../styles';
import ConditionSearch from './parts/conditionSearch';
import ButtonUpload from './parts/buttonUpload';
import ButtonDownload from './parts/buttonDownload';
import ListTable from './parts/listTable';
import EditDialog from './editDialog/index';

const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
  },
  stickey: {
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 1,
  },
  root: {
    maxWidth: '96%',
    margin: '0 auto',
    paddingBottom: 20,
  },
  conditionWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    flexWrap: 'wrap',
    padding: '20px',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    '& button:last-child': {
      marginLeft: 20,
    },
  },
  table: {
    margin: '16px 20px',
  },
});

export default function CombinationSetting() {
  const common = commonStyles();
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={`${common.header} ${classes.stickey}`}>
        <Grid className={classes.headerInnter}>
          <Typography className={common.title2}>
            組合せ設定一覧画面
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.conditionWrap}>
        <ConditionSearch />

        <Grid className={classes.buttonGroup}>
          <ButtonUpload />
          <ButtonDownload />
        </Grid>
      </Grid>

      <ListTable className={classes.table} />

      <EditDialog />
    </Grid>
  );
}
