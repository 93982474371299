import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import {
  howToWorkSelect,
} from '../../../constants/customerMain';
import NormalTooltip from '../../styles/tooltip';
import SelectRailwaysAndStationsContainer from '../../../containers/search/railwayAndStations/selectRailwayAndStations';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import inputNumber from '../../../commonFunction/inputNumber';
import { convertForm1, convertFormNumber } from '../../../commonFunction/convertors';
import { validateFormString1, validateFormInt1 } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  workPlace: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  workPlace1: {
    width: 140,
  },
  workPlace2: {
    width: 50,
  },
  workPlace3: {
    width: 85,
  },
  workPlace4: {
    width: 140,
  },
  workPlace4Button: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 139,
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
  lbl: {
    whiteSpace: 'nowrap',
    marginRight: 4,
  },
  textField: {
    display: 'block',
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
    },
    '& div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  button: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    minWidth: 'auto',
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minWidth: 42,
      minHeight: 21,
    },
    '&:hover': {
      background: '#fff',
    },
  },
  buttonTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 4px',
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&:nth-child(4n)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

const menus = Object.keys(howToWorkSelect.menus);

function CustomerWorkPlace(props) {
  const { input } = props;
  const {
    current,
    original,
    update,
    isSp,
  } = useUpdate(input.customer);

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  let contents;

  // 沿線開閉用のローカルステート
  const [openRailway, setOpenRailway] = useState(false);

  // 沿線パラメーターを設定
  const [initialParamRailway, setInitialParamRailway] = useState({});

  // 沿線を開く
  const handleOpenRailway = (wishID) => {
    // 沿線の第1希望, 第2希望のパラメータをセット
    setInitialParamRailway({
      id: wishID,
      showOnlyFromStation: true,
      wishRailwayAreaCodeId: current[wishID === 1 ? 'companyRailwayAreaCodeId' : 'subCompanyRailwayAreaCodeId'],
      wishRailwayId: current[wishID === 1 ? 'companyRailwayId' : 'subCompanyRailwayId'],
      wishRailway: current[wishID === 1 ? 'companyRailway' : 'subCompanyRailway'],
      wishStationIdFrom: current[wishID === 1 ? 'companyStationId' : 'subCompanyStationId'],
      wishStationFrom: current[wishID === 1 ? 'companyStation' : 'subCompanyStation'],
    });
    setOpenRailway(true);
  };

  // 沿線を閉じる
  const handleCloseRailway = () => {
    setOpenRailway(false);
  };

  // 沿線を更新
  const handleUpdateRailway = (data) => {
    console.log('handleUpdateRailway', data);
    const newData = {
      [data.id === 1 ? 'companyRailwayAreaCodeId' : 'subCompanyRailwayAreaCodeId']: data.areaCode,
      [data.id === 1 ? 'companyRailwayId' : 'subCompanyRailwayId']: data.railway.railwayId,
      [data.id === 1 ? 'companyRailway' : 'subCompanyRailway']: data.railway.railwayName,
      [data.id === 1 ? 'companyStationId' : 'subCompanyStationId']: data.fromStation.stationId,
      [data.id === 1 ? 'companyStation' : 'subCompanyStation']: data.fromStation.name,
    };
    // 更新処理
    isChanged(newData, current) && update(newData);
  };

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    const { name, value } = e.target;
    if ((original[name] || '') === value) return;
    // 更新処理
    update({ [name]: value });
  };

  // SP 決定押下時に更新
  const onDecision = (values, keys) => {
    if (current[keys] !== values) {
      update({ [keys]: values });
    }
  };

  // PC 働き方
  const selectChange = (e) => {
    const { name, value } = e.target;
    update({ [name]: value });
  };

  const customerSelectList = {
    howToWorkItem1: {
      type: 'select',
      obj: howToWorkSelect,
      state: current.workingStyleCode,
      name: 'workingStyleCode',
      // set: selectBlur1,
      change: selectChange,
    },
    howToWorkItem2: {
      type: 'select',
      obj: howToWorkSelect,
      state: current.subWorkingStyleCode,
      name: 'subWorkingStyleCode',
      // set: selectBlur2,
      change: selectChange,
    },
  };

  const getBox1 = () => {
    return (
      <TextBaseField
        label="勤務先1"
        defaultValue={current.company}
        className={classes.textField}
        name="company"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormString1({ maxLengthInt: 80 })}
        convertor={convertForm1}
      />
    );
  };

  const getBox2 = () => {
    return (
      <TextBaseField
        label="年数"
        defaultValue={current.companyWorkingYear}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="companyWorkingYear"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
      />
    );
  };

  const getBox3 = () => {
    return (
      <Select
        value={current.workingStyleCode}
        defaultValue={current.workingStyleCode !== undefined
          ? current.workingStyleCode : 99}
      >
        {/* <MenuItem disabled value={99}>未選択</MenuItem> */}
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onDecision(data, 'workingStyleCode');
              }}
            >
              {howToWorkSelect.menus[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getBox5 = () => {
    return (
      <TextBaseField
        label="勤務先2"
        defaultValue={current.subCompany}
        className={classes.textField}
        name="subCompany"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormString1({ maxLengthInt: 50 })}
        convertor={convertForm1}
      />
    );
  };

  const getBox6 = () => {
    return (
      <TextBaseField
        label="年数"
        defaultValue={current.subCompanyWorkingYear}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="subCompanyWorkingYear"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
      />
    );
  };

  const getBox7 = () => {
    return (
      <Select
        value={current.subWorkingStyleCode}
        defaultValue={current.subWorkingStyleCode !== undefined
          ? current.subWorkingStyleCode : 99}
      >
        {/* <MenuItem disabled value={99}>未選択</MenuItem> */}
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onDecision(data, 'subWorkingStyleCode');
              }}
            >
              {howToWorkSelect.menus[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const list1 = [
    {
      type1: 'noModal',
      id: 1,
      lbl: '勤務先 1',
      val: current.company,
      keys: 'company',
      content: getBox1,
    },
    {
      type1: 'noModal',
      type2: 'number',
      id: 2,
      lbl: '年数',
      val: current.companyWorkingYear,
      keys: 'companyWorkingYear',
      content: getBox2,
    },
    {
      type1: 'noModal',
      type2: 'select',
      id: 3,
      lbl: '働き方',
      val: howToWorkSelect.menus[current.workingStyleCode],
      keys: 'workingStyleCode',
      content: getBox3,
    },
  ];
  const list2 = [
    {
      type1: 'button',
      id: 4,
      lbl: '沿線／最寄り駅',
      val: (current.companyRailway ? current.companyRailway : '') + ' / ' + (current.companyStation ? current.companyStation : ''),
      click: () => {
        handleOpenRailway(1);
      },
    },
  ];
  const list3 = [
    {
      type1: 'noModal',
      id: 5,
      lbl: '勤務先 2',
      label: '勤務先2',
      val: current.subCompany,
      keys: 'subCompany',
      content: getBox5,
    },
    {
      type1: 'noModal',
      type2: 'number',
      id: 6,
      lbl: '年数',
      val: current.subCompanyWorkingYear,
      keys: 'subCompanyWorkingYear',
      content: getBox6,
    },
    {
      type1: 'noModal',
      type2: 'select',
      id: 7,
      lbl: '働き方',
      val: howToWorkSelect.menus[current.subWorkingStyleCode],
      keys: 'subWorkingStyleCode',
      content: getBox7,
    },
  ];
  const list4 = [
    {
      type1: 'button',
      id: 8,
      lbl: '沿線／最寄り駅',
      val: (current.subCompanyRailway ? current.subCompanyRailway : '') + ' / ' + (current.subCompanyStation ? current.subCompanyStation : ''),
      click: () => {
        handleOpenRailway(2);
      },
    },
  ];

  const getStatusPc = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Grid className={classes.workPlace}>
              <Typography className={baseClasses.title6}>
                勤務先
              </Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid>
            <Grid className={classes.workPlace}>
              <Grid className={classes.workPlace1}>
                {getBox1()}
              </Grid>
              <Grid className={classes.workPlace2}>
                {getBox2()}
              </Grid>
              <Grid className={classes.workPlace3}>
                <Typography className={baseClasses.title6}>
                  働き方
                </Typography>
                {input.component(customerSelectList.howToWorkItem1)}
              </Grid>
              <Grid className={classes.workPlace4}>
                <Typography className={baseClasses.title6}>
                  沿線／最寄り駅
                </Typography>
                <NormalTooltip title={`${current.companyRailway ? current.companyRailway : ''} / ${current.companyStation ? current.companyStation : ''}`}>
                  <Grid className={classes.workPlace4Button}>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(1);
                      }}
                    >
                      {current.companyRailway}
                    </Button>
                    <Typography className={classes.buttonTxt}>
                      /
                    </Typography>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(1);
                      }}
                    >
                      {current.companyStation}
                    </Button>
                  </Grid>
                </NormalTooltip>
              </Grid>
            </Grid>
            <Grid className={classes.workPlace}>
              <Grid className={classes.workPlace1}>
                {getBox5()}
              </Grid>
              <Grid className={classes.workPlace2}>
                {getBox6()}
              </Grid>
              <Grid className={classes.workPlace3}>
                <Typography className={baseClasses.title6}>
                  働き方
                </Typography>
                {input.component(customerSelectList.howToWorkItem2)}
              </Grid>
              <Grid className={classes.workPlace4}>
                <Typography className={baseClasses.title6}>
                  沿線／最寄り駅
                </Typography>
                <NormalTooltip title={`${current.subCompanyRailway ? current.subCompanyRailway : ''} / ${current.subCompanyStation ? current.subCompanyStation : ''}`}>
                  <Grid className={classes.workPlace4Button}>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(2);
                      }}
                    >
                      {current.subCompanyRailway}
                    </Button>
                    <Typography className={classes.buttonTxt}>
                      /
                    </Typography>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(2);
                      }}
                    >
                      {current.subCompanyStation}
                    </Button>
                  </Grid>
                </NormalTooltip>
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  const getStatusSp = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Grid className={classes.workPlace}>
              <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                勤務先
              </Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              勤務先
            </Typography>
            <List className={classes.list}>
              {list1.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    className={classes.listItem}
                  >
                    {input.parts(item)}
                    {input.editDialog(item)}
                  </ListItem>
                );
              })}
              {list2.map((item) => {
                return (
                  <ListItem
                    key={item.lbl}
                    className={classes.listItem}
                  >
                    <Button
                      className={classes.listBtn}
                      onClick={item.click}
                    >
                      <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                        {item.lbl}
                      </span>
                      <span
                        className={`${baseClasses.title4} ${classes.listVal}`}
                      >
                        {item.val}
                      </span>
                    </Button>
                  </ListItem>
                );
              })}
              {list3.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    className={classes.listItem}
                  >
                    {input.parts(item)}
                    {input.editDialog(item)}
                  </ListItem>
                );
              })}
              {list4.map((item) => {
                return (
                  <ListItem
                    key={item.lbl}
                    className={classes.listItem}
                  >
                    <Button
                      className={classes.listBtn}
                      onClick={item.click}
                    >
                      <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                        {item.lbl}
                      </span>
                      <span
                        className={`${baseClasses.title4} ${classes.listVal}`}
                      >
                        {item.val}
                      </span>
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        );
    }
  };

  if (isSp) {
    contents = (
      <Grid>
        {getStatusSp()}
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        {getStatusPc()}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      {/* <SelectRailwaysAndStationsContainer
        initialParam={testSelectRailwayAndStations}
        onClickSelectButton={testClickSelectBoxFunc}
        open={openWish}
        onClose={onCloseWish}
      /> */}

      <SelectRailwaysAndStationsContainer
        initialParam={initialParamRailway}
        open={openRailway}
        onUpdate={handleUpdateRailway}
        onClose={handleCloseRailway}
        isNearestStation
      />
    </Grid>
  );
}

export default React.memo(CustomerWorkPlace);
