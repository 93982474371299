import React, { useState } from 'react';
import {
  Grid,
} from '@material-ui/core';
import MainHeadMenuTabs from './parts/mainHeadMenuTabs';
import CustomerInfoSp from '../common/customer/customerInfo/customerInfoSp';
import { useCustomerMain } from '../../containers/customerMain/customerMainContext';

function MainSp() {
  const {
    functions,
    selectCustomer,
  } = useCustomerMain();

  // SP
  const [open2, setOpen2] = useState(false);
  const onClose2 = () => {
    setOpen2(!open2);
  };

  return (
    <Grid>
      {/* メインタブ・コンテンツ */}
      <Grid>
        <MainHeadMenuTabs
          onClose2={onClose2}
          getCustomerTreeResponseFunction={functions.getCustomerTreeResponseFunction}
          getBrowsingHistoryFunction={functions.getBrowsingHistoryFunction}
        />
      </Grid>
      {/* 各顧客情報用モーダル */}
      {Number.isInteger(selectCustomer[0]?.customerId) && (
        <CustomerInfoSp
          customerId={selectCustomer[0].customerId}
          open={open2}
          setOpen={setOpen2}
          onClose={onClose2}
        />
      )}
    </Grid>
  );
}

export default React.memo(MainSp);
