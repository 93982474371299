import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  total: 0,
  users: [],
};

export const getUserSearchListSlice = createSlice({
  name: 'getUserSearchList',
  initialState: { getUserSearchList: initialState },
  reducers: {
    getUserSearchListApi: (state, data) => {
      state.getUserSearchList = data.payload;
    },
    initUserSearchListApi: (state) => {
      state.getUserSearchList = initialState;
    },
  },
});

export const { getUserSearchListApi, initUserSearchListApi } = getUserSearchListSlice.actions;

export default getUserSearchListSlice.reducer;
