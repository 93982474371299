import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import {
  abodeNowBuildingTypeSelect,
  abodeNowFloorPlanSelect,
  isAbodeNowReplacementSelect,
} from '../../../../constants/customerMain';
import inputNumber from '../../../../commonFunction/inputNumber';
import { TextBaseField } from '../../../eleCommon/validation';
import { validateFormInt1, validateFormFloat1, validateFormString1 } from '../../../../commonFunction/validations';
import { convertForm1, convertFormNumber } from '../../../../commonFunction/convertors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  presentCondition: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    width: '100%',
  },
  presentConditionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  presentConditionBlock1: {
    width: 138,
  },
  presentConditionBlock2: {
    width: 200,
  },
  presentConditionBlock3: {
    width: 400,
  },
  presentConditionBlock4: {
    width: 83,
  },
  presentConditionBlock5: {
    width: 570,
  },
  presentConditionSelect: {
    width: '100%',
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 5px',
    [commonTheme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%',
      justifyContent: 'space-between',
    },
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      [commonTheme.breakpoints.up('md')]: {
        marginLeft: 4,
      },
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
    '& + span': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  textField: {
    display: 'block',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    '&:not(:last-child)': {
      marginBottom: 4,
    },
  },
  listItem: {
    position: 'relative',
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function CustomerDetailPresentCondition(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  const menus1 = Object.keys(abodeNowBuildingTypeSelect.menus);
  const menus2 = Object.keys(abodeNowFloorPlanSelect.menus);
  const menus3 = Object.keys(isAbodeNowReplacementSelect.menus);

  // 現況:種類の初期値を取得
  // abodeNowBuildingTypeはstringのためstringで比較
  const initial1 = Object.keys(abodeNowBuildingTypeSelect.menus).find((key) => {
    return abodeNowBuildingTypeSelect.menus[key] === input.detail.abodeNowBuildingType;
  }) || 0;

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // フォーカスが外れた時
  const onBlur = (e) => {
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  // SP 項目選択時に更新
  const onSelect1 = (val) => {
    // コードを文字列に変換
    const stringVal = (Number(val) !== 0)
      ? abodeNowBuildingTypeSelect.menus[val] : null;
    // 変更した値をセット
    input.setDetail({
      ...input.detail,
      abodeNowBuildingType: stringVal,
    });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data,
      abodeNowBuildingType: stringVal,
    });
  };
  const onSelect2 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, abodeNowFloorPlanCode: val });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, abodeNowFloorPlanCode: val });
  };
  const onSelect3 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, abodeNowReplacementCode: val });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, abodeNowReplacementCode: val });
  };

  // 種類 間取り 買い替え有無 PC
  const selectChange1 = (e) => {
    const stringVal = (Number(e.target.value) !== 0)
      ? abodeNowBuildingTypeSelect.menus[e.target.value] : null;
    // 変更した値をセット
    input.setDetail({
      ...input.detail, abodeNowBuildingType: stringVal,
    });
  };
  const selectChange2 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, abodeNowFloorPlanCode: e.target.value });
  };
  const selectChange3 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, abodeNowReplacementCode: e.target.value });
  };
  const selectBlur1 = (e) => {
    // コードを文字列に変換
    const stringVal = (Number(e.target.value) !== 0)
      ? abodeNowBuildingTypeSelect.menus[e.target.value] : null;
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data,
      abodeNowBuildingType: stringVal,
    });
  };
  const selectBlur2 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, abodeNowFloorPlanCode: e.target.value });
  };
  const selectBlur3 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, abodeNowReplacementCode: e.target.value });
  };

  const customerSelectList = {
    abodeNowBuildingTypeItem: {
      type: 'select',
      obj: abodeNowBuildingTypeSelect,
      state: initial1,
      set: selectBlur1,
      change: selectChange1,
    },
    abodeNowFloorPlanItem: {
      type: 'select',
      obj: abodeNowFloorPlanSelect,
      state: input.detail.abodeNowFloorPlanCode,
      set: selectBlur2,
      change: selectChange2,
    },
    isAbodeNowReplacementItem: {
      type: 'select',
      obj: isAbodeNowReplacementSelect,
      state: input.detail.abodeNowReplacementCode,
      set: selectBlur3,
      change: selectChange3,
    },
  };

  // チェックボックス
  const isCarParkInitial = () => {
    switch (input.detail.isCarPark) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const [checked, setChecked] = useState(isCarParkInitial());
  const isCarParkChange = (e) => {
    setChecked(e.target.checked);
    switch (e.target.checked) {
      case true:
        // 変更した値をセット
        input.setDetail({ ...input.detail, [e.target.name]: 1 });
        // 更新用の値をセット
        input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: 1 });
        break;
      default:
        // 変更した値をセット
        input.setDetail({ ...input.detail, [e.target.name]: 0 });
        // 更新用の値をセット
        input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: 0 });
    }
  };

  const getBox1 = () => {
    return (
      <Select defaultValue={initial1}>
        {menus1.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect1(data);
              }}
            >
              {abodeNowBuildingTypeSelect.menus[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox2 = () => {
    return (
      <Select defaultValue={99}>
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus2.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect2(data);
              }}
            >
              {abodeNowFloorPlanSelect.menus[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox3 = () => {
    return (
      <TextBaseField
        label="面積（㎡）"
        defaultValue={input.detail.abodeNowLivingSpace}
        className={classes.textField}
        name="abodeNowLivingSpace"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormFloat1({ minFloat: 0.0, maxFloat: 9999.0, precisionInt: 3 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowLivingSpace')}
      />
    );
  };
  const getBox4 = () => {
    return (
      <TextBaseField
        label="当時の価格（万円）"
        defaultValue={input.detail.abodeNowPriceAtTime}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="abodeNowPriceAtTime"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99999.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowPriceAtTime')}
      />
    );
  };
  const getBox5 = () => {
    return (
      <TextBaseField
        label="居住期間（年）"
        defaultValue={input.detail.abodeNowResidencePeriod}
        className={classes.textField}
        name="abodeNowResidencePeriod"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormFloat1({ minFloat: 0.0, maxFloat: 99.0, precisionInt: 4 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowResidencePeriod')}
      />
    );
  };
  const getBox6 = () => {
    return (
      <TextBaseField
        label="家賃（万円）"
        defaultValue={input.detail.abodeNowRent}
        className={classes.textField}
        name="abodeNowRent"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormFloat1({ minFloat: 0.0, maxFloat: 99999.0, precisionInt: 4 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowRent')}
      />
    );
  };
  const getBox7 = () => {
    return (
      <TextBaseField
        label="希望売却価格（万円）"
        defaultValue={input.detail.abodeNowSalePrice}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="abodeNowSalePrice"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99999.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowSalePrice')}
      />
    );
  };
  const getBox8 = () => {
    return (
      <Select defaultValue={99}>
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus3.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect3(data);
              }}
            >
              {isAbodeNowReplacementSelect.menus[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox9 = () => {
    return (
      <TextBaseField
        label="買い替え種類"
        defaultValue={input.detail.abodeNowReplaceType}
        className={classes.textField}
        name="abodeNowReplaceType"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 30 })}
        errorCallback={input.errorCallback('abodeNowReplaceType')}
      />
    );
  };
  const getBox10 = () => {
    return (
      <TextBaseField
        label="借り入れ残年数（年）"
        defaultValue={input.detail.abodeNowCurrentMaturity}
        className={classes.textField}
        name="abodeNowCurrentMaturity"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormFloat1({ minFloat: 0.0, maxFloat: 99.0, precisionInt: 4 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowCurrentMaturity')}
      />
    );
  };
  const getBox11 = () => {
    return (
      <TextBaseField
        label="借り入れ額（万円）"
        defaultValue={input.detail.abodeNowBorrowingAmount}
        className={classes.textField}
        name="abodeNowBorrowingAmount"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999999.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowBorrowingAmount')}
      />
    );
  };
  const getBox12 = () => {
    return (
      <TextBaseField
        label="借り入れ銀行"
        defaultValue={input.detail.abodeNowBankName}
        className={classes.textField}
        name="abodeNowBankName"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('abodeNowBankName')}
      />
    );
  };
  const getBox13 = () => {
    return (
      <TextBaseField
        label="車種"
        defaultValue={input.detail.abodeNowCarType}
        className={classes.textField}
        name="abodeNowCarType"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 30 })}
        errorCallback={input.errorCallback('abodeNowCarType')}
      />
    );
  };
  const getBox14 = () => {
    return (
      <TextBaseField
        label="台数（台）"
        defaultValue={input.detail.abodeNowCarCount}
        className={classes.textField}
        name="abodeNowCarCount"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('abodeNowCarCount')}
      />
    );
  };
  const getBox15 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox checked={checked} onChange={isCarParkChange} name="isCarPark" />}
        label="駐車場希望"
      />
    );
  };

  const list1 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '種類',
      val: input.detail.abodeNowBuildingType,
      content: getBox1,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '間取り',
      val: abodeNowFloorPlanSelect.menus[input.detail.abodeNowFloorPlanCode],
      content: getBox2,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '面積（㎡）',
      val: input.detail.abodeNowLivingSpace,
      fontSize: '16px',
      content: getBox3,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '当時の価格（万円）',
      val: input.detail.abodeNowPriceAtTime,
      fontSize: '16px',
      content: getBox4,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '居住期間（年）',
      val: input.detail.abodeNowResidencePeriod,
      fontSize: '16px',
      content: getBox5,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '家賃（万円）',
      val: input.detail.abodeNowRent,
      fontSize: '16px',
      content: getBox6,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '希望売却価格（万円）',
      val: input.detail.abodeNowSalePrice,
      fontSize: '16px',
      content: getBox7,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '買い替え有無',
      val: isAbodeNowReplacementSelect.menus[input.detail.abodeNowReplacementCode],
      content: getBox8,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '買い替え種類',
      val: input.detail.abodeNowReplaceType,
      content: getBox9,
    },
  ];

  const list2 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '借り入れ残年数（年）',
      val: input.detail.abodeNowCurrentMaturity,
      fontSize: '16px',
      content: getBox10,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '借り入れ額（万円）',
      val: input.detail.abodeNowBorrowingAmount,
      fontSize: '16px',
      content: getBox11,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '借り入れ銀行',
      val: input.detail.abodeNowBankName,
      content: getBox12,
    },
  ];

  const list3 = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '車種',
      val: input.detail.abodeNowCarType,
      content: getBox13,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '台数（台）',
      val: input.detail.abodeNowCarCount,
      fontSize: '16px',
      content: getBox14,
    },
    {
      type1: 'checkbox',
      type2: '',
      lbl: '駐車場希望',
      val: checked,
      content: getBox15,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          現況
        </Typography>
        <List className={classes.list}>
          {list1.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <List className={classes.list}>
          {list2.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <List className={classes.list}>
          {list3.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          現況
        </Typography>
        <Grid className={classes.presentCondition}>
          <Grid className={classes.presentConditionBox}>
            <Grid className={classes.presentConditionBlock1}>
              <Typography className={baseClasses.title6}>
                種類
              </Typography>
              <Grid className={classes.presentConditionSelect}>
                {input.dataInput.component(customerSelectList.abodeNowBuildingTypeItem)}
              </Grid>
            </Grid>
            <Grid className={classes.presentConditionBlock1}>
              <Typography className={baseClasses.title6}>
                間取り
              </Typography>
              <Grid className={classes.presentConditionSelect}>
                {input.dataInput.component(customerSelectList.abodeNowFloorPlanItem)}
              </Grid>
            </Grid>
            <Grid className={classes.presentConditionBlock1}>
              {getBox3()}
            </Grid>
            <Grid className={classes.presentConditionBlock1}>
              {getBox4()}
            </Grid>
            <Grid className={classes.presentConditionBlock1}>
              {getBox5()}
            </Grid>
            <Grid className={classes.presentConditionBlock1}>
              {getBox6()}
            </Grid>
            <Grid className={classes.presentConditionBlock1}>
              {getBox7()}
            </Grid>
          </Grid>
          <Grid className={classes.presentConditionBox}>
            <Grid className={classes.presentConditionBlock1}>
              <Typography className={baseClasses.title6}>
                買い替え有無
              </Typography>
              <Grid className={classes.presentConditionSelect}>
                {input.dataInput.component(customerSelectList.isAbodeNowReplacementItem)}
              </Grid>
            </Grid>
            <Grid className={classes.presentConditionBlock2}>
              {getBox9()}
            </Grid>
            <Grid className={classes.presentConditionBlock2}>
              {getBox10()}
            </Grid>
            <Grid className={classes.presentConditionBlock2}>
              {getBox12()}
            </Grid>
            <Grid className={classes.presentConditionBlock2}>
              {getBox11()}
            </Grid>
          </Grid>
          <Grid className={classes.presentConditionBox}>
            <Grid className={classes.presentConditionBlock3}>
              {getBox13()}
            </Grid>
            <Grid className={classes.presentConditionBlock4}>
              {getBox14()}
            </Grid>
            <Grid className={classes.presentConditionBlock5}>
              {getBox15()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
