import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { SEARCHABLE_PRODUCT_TYPES, SELECT_PRODUCT_TYPES, SITE_ID_MAP } from './constants';
import { PROPERTY, PROPERTY_PART } from '../../../constants/userRoleGroup';

const useStyles = makeStyles((theme) => ({
  container: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      border: '1px solid #C8C8C8',
      padding: '10px',
      marginBottom: 8,
    },
    '& .makeStyles-checkBoxContainer-32': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiFormControl-root': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: 16,
      },
    },
  },
  ttl: {
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 700,
      width: '100%!important',
      padding: '16px 0',
      borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    },
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& label': {
      '& .PrivateSwitchBase-root-33': {
        padding: 5,
      },
      '& .MuiTypography-body1': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '160%',
      },
    },
  },
  btnWwrap: {
    padding: '0 0 40px 16px',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

function SelectProductType(props) {
  const {
    setOpenProductSearch,
    productType,
    setProductType,
    changeKinds,
    onClose,
    selectType,
    multi,
    showTypes, // 物件種別の配列、OHDのみの場合['OHD']にすればいい、空の場合全ての種別が表示される
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  // レスポンスヘッダからroleGroupIdを取得
  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleGroupId } = responseHeader;

  // クリア処理
  const onSelectClear = () => {
    selectType('', null);
    onClose();
  };

  // 項目を選択した時の処理（SPで使用）
  const onSelect = (val) => {
    setProductType(val);

    if (val in SEARCHABLE_PRODUCT_TYPES) {
      setOpenProductSearch(true);
      changeKinds();
    } else {
      // FIXME: SITE_IDは仮でいれてます。このSITEIDはSFA_ASIAQUEST-2593対応時に削除
      selectType(val, SITE_ID_MAP);
      onClose();
    }
  };

  const typeList = useMemo(() => {
    // roleGroupIdが51または52ならば、「全物件」のみでearlyReturnする
    if (roleGroupId === PROPERTY || roleGroupId === PROPERTY_PART) {
      setOpenProductSearch(false);
      return [SELECT_PRODUCT_TYPES.ALL_PRODUCT];
    }
    if (Array.isArray(showTypes) && showTypes.length) return showTypes;
    const arr = [
      SELECT_PRODUCT_TYPES.OHD,
      SELECT_PRODUCT_TYPES.HAWK_ONE,
      SELECT_PRODUCT_TYPES.MAISOKU,
    ];
    if (!multi) arr.push(SELECT_PRODUCT_TYPES.NO_FAVORITE);
    arr.push(SELECT_PRODUCT_TYPES.NO_HOUSE);
    return arr;
  }, [showTypes, multi]);

  const onRadioGroupChange = (e) => {
    const val = e.target.value;
    setOpenProductSearch(val in SEARCHABLE_PRODUCT_TYPES);
    setProductType(val);
  };

  if (isSP) {
    contents = (
      <Grid className={classes.container}>
        <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
          物件種別
        </Typography>
        <Grid className={classes.btnWwrap}>
          {typeList.map((type) => (
            <Button
              key={type}
              className={`${baseClasses.title3} ${classes.selectBtn}`}
              onClick={() => {
                onSelect(type);
              }}
            >
              {type}
            </Button>
          ))}
          <Button
            className={`${baseClasses.title3} ${classes.selectBtn}`}
            onClick={() => {
              onSelectClear();
            }}
          >
            クリア
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.container}>
        <Typography style={{ width: '15%' }} className={`${baseClasses.title6} ${classes.ttl}`}>
          物件種別<span style={{ color: '#D83420' }}>＊</span>
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={productType || SELECT_PRODUCT_TYPES.OHD || SELECT_PRODUCT_TYPES.ALL_PRODUCT}
            className={classes.formGroupContainer}
            onChange={onRadioGroupChange}
          >
            {typeList.map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio color="primary" />}
                label={type}
                labelPlacement="end"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default SelectProductType;
