import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const claimCreateCancelSlice = createSlice({
  name: 'claimCreateCancel',
  initialState: { claimCreateCancel: initialState },
  reducers: {
    claimCreateCancelApi: (state, data) => {
      state.claimCreateCancel = data.payload;
    },
  },
});

export const { claimCreateCancelApi } = claimCreateCancelSlice.actions;

export default claimCreateCancelSlice.reducer;
