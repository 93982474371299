import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoGetOneClickSearchConditionSlice = createSlice({
  name: 'hpinfoGetOneClickSearchCondition',
  initialState: { hpinfoGetOneClickSearchCondition: initialState },
  reducers: {
    hpinfoGetOneClickSearchConditionApi: (state, data) => {
      state.hpinfoGetOneClickSearchCondition = data.payload;
    },
  },
});

export const {
  hpinfoGetOneClickSearchConditionApi,
} = hpinfoGetOneClickSearchConditionSlice.actions;

export default hpinfoGetOneClickSearchConditionSlice.reducer;
