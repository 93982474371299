/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesPutGuidanceByGuidanceIdSlice = createSlice({
  name: 'schedulesPutGuidanceByGuidanceId',
  initialState: { schedulesPutGuidanceByGuidanceId: initialState },
  reducers: {
    updateGuidanceByGuidanceIdApi: (state, data) => {
      state.schedulesPutGuidanceByGuidanceId = data.payload;
    },
  },
});

export const { updateGuidanceByGuidanceIdApi } = schedulesPutGuidanceByGuidanceIdSlice.actions;

export default schedulesPutGuidanceByGuidanceIdSlice.reducer;
