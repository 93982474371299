import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  dialog: {
    zIndex: '1352!important',
    '& .MuiDialog-paper': {
      margin: 0,
      maxWidth: 'none',
      width: '100%',
      height: '100vh',
      maxHeight: 'none',
      borderRadius: 0,
    },
  },
  paper: {
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderRadius: 0,
    padding: 0,
  },
  paperHeader: {
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    padding: '19px 0 8px',
  },
  paperTtl: {
    textAlign: 'center',
  },
  paperBody1: {
    padding: '40px 16px 0',
  },
  paperBody2: {
    padding: '16px 0 0 16px',
  },
  paperBtn: {
    display: 'block',
    margin: '32px auto 0',
  },
});

function EditDialog2(props) {
  const { open, onClose, content, lbl } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
    >
      <Grid className={classes.paper}>
        <Grid className={classes.paperHeader}>
          <Typography
            className={`${baseClasses.title2} ${classes.paperTtl}`}
          >
            {lbl}
          </Typography>
          <Button
            className={baseClasses.closeButton}
            onClick={onClose}
          />
        </Grid>
        <Grid className={classes.paperBody2}>
          {content()}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default EditDialog2;
