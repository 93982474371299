import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
export const smsHistorySlice = createSlice({
  name: 'smsHistory',
  initialState: { smsHistory: initialState },
  reducers: {
    getSmsHistoryApi: (state, data) => {
      state.smsHistory = data.payload;
    },
  },
});

export const { getSmsHistoryApi } = smsHistorySlice.actions;

export default smsHistorySlice.reducer;
