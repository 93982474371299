import { useMemo } from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'table',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    tableLayout: 'fixed',
    '& > div': {
      display: 'table-cell',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      padding: '0 var(--offset-horizontal)',
    },
  },
});

export default function EllipsisWrap(props) {
  const { children, style, offset = 10 } = props;

  const classes = useStyles();

  const styles = useMemo(() => {
    const res = {
      ...style,
    };
    if (offset) {
      res['--offset-horizontal'] = offset + (typeof offset === 'number' ? 'px' : '');
    }
    return res;
  }, [offset]);

  return (
    <Grid className={classes.root} style={styles}>
      <Grid>{ children }</Grid>
    </Grid>
  );
}
