import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gotDataTime: {},
};

// customerIdごとにデータの取得時間、DBの最終更新時間を保存する
export const gotDataTimeSlice = createSlice({
  name: 'gotDataTime',
  initialState,
  reducers: {
    updateGotDateTime: (state, { payload: { customerId, gotDataTime } }) => {
      state.gotDataTime[customerId] = gotDataTime;
    },
    bulkUpdateGotDateTime: (state, { payload: { customerIds = [], gotDataTime } }) => {
      state.gotDataTime = customerIds.reduce((p, c) => Object.assign(p, { [c]: gotDataTime }), {});
    },
  },
});

export const {
  updateGotDateTime,
  bulkUpdateGotDateTime,
} = gotDataTimeSlice.actions;

export default gotDataTimeSlice.reducer;
