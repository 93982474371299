import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../styles';
import UserTree from '../../common/userTreeNew';
import customerPutResponsibilitiesApi from '../../../apis/customer/customerPutResponsibilitiesApi';
import Modal from '../../common/modal';
import store from '../../../store';
import { useLoading } from '../../../hooks';
import { BULK_MANAGE } from '../../../constants/loading';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    width: 640,
    background: '#fff',
  },
  header: {
    padding: '24px 24px 8px',
    borderBottom: '1px solid #C8C8C8',
  },
  content: {
    background: '#F3F3F3',
    '& .MuiPaper-root': {
      margin: 0,
      maxWidth: 'none',
    },
  },
  inner: {
    height: 447,
  },
  footer: {
    padding: '16px 0',
  },
  footerBtn: {
    margin: '0 auto',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 19px',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn1: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 89,
    padding: '8px 0',
    marginRight: 16,
  },
  btn2: {
    width: 89,
    padding: '8px 0',
  },
  txt1: {
    marginBottom: 8,
  },
  txt2: {
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  txt3: {
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginBottom: 16,
  },
  lbl: {
    margin: '0 0 8px 8px',
  },
}));

export default function BulkChargeChangeWindow(props) {
  const { open, onClose, choice, selectedCustomerId, updateStart } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const { responseHeader } = store.getState();
  const { addLoading, removeLoading, hasLoading } = useLoading();

  // 組織ツリーユーザー選択
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  // 組織ツリー選択
  const [selectDivision, setSelectDivision] = useState({
    divId: 0,
    divisionName: '',
  });

  const onClickChange = async () => {
    addLoading(BULK_MANAGE);
    const changeDatas = selectedCustomerId.map((customerId) => {
      const item = {
        customerId,
      };
      return item;
    });
    await customerPutResponsibilitiesApi({
      userId: selectUser.userId || null,
      divisionId: selectDivision.divId,
      responsibilities: changeDatas,
    })
      .finally(async () => {
        await updateStart();
        removeLoading(BULK_MANAGE);
        onClose();
      });
  };

  const isDisabledSaveButton = selectDivision.divisionName === '';

  useEffect(() => {
    setSelectUser({
      userId: responseHeader?.userId,
      userName: '',
    });
    setSelectDivision({
      divId: 0,
      divisionName: '',
    });
  }, [open]);

  return (
    <Modal
      title="一括担当変更ウィンドウ"
      open={open}
      onClose={onClose}
      maxWidth={640}
      className={hasLoading(BULK_MANAGE) && baseClasses.loading}
      buttons={[
        {
          label: 'キャンセル',
          onClick: onClose,
          type: 'secondary',
        },
        {
          label: '保存',
          disabled: isDisabledSaveButton,
          onClick: onClickChange,
        },
      ]}
    >
      <Grid className={classes.content}>
        <Typography className={`${baseClasses.title2} ${classes.txt1}`}>
          {choice}件選択中
        </Typography>
        <Typography className={classes.txt2}>
          変更後、あなたは上記件数の顧客情報の変更権限を失います。
        </Typography>
        <Typography className={classes.txt3}>
          よく確認してから変更を行ってください。
          <br />※
          変更後に変更権限を持つのは、新しい担当者と新しい担当者の上長のみです。
        </Typography>
        <Typography className={`${baseClasses.title4} ${classes.lbl}`}>
          新しい担当
        </Typography>
        <Grid container className={classes.inner}>
          <UserTree
            selectDivision={selectDivision}
            setSelectDivision={setSelectDivision}
            selectUser={selectUser}
            setSelectUser={setSelectUser}
            clickableUserAndDivision
            doubleSearch
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
