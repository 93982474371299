import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Checkbox,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import HolidayTel from '../../holidayTel';
import { LabelWrap } from '../../labelWrap';

import { CUSTOMER_MAIL_KEYS } from '../../../crmMain/useMailVerification';

import { checkMailVaildation } from '../../../../commonFunction/mail';

import { CUSTOMER_STATUS_CLAIM } from '../../../../constants';

import {
  convertForm1,
} from '../../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormEmail,
  validateFormTelNum,
} from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },
  // お休み
  holiday: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '6px 8px',
    margin: '0 0 8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  holidayButton: {
    width: 444,
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 0,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  holidayTxt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 396,
  },
  // 連絡がつきやすい時間帯
  time: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
  },
  timeButton1: {
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    width: 495,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  timeButton2: {
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    width: 393,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  timeTxt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  // TEL
  tel: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    width: 380,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
  },
  telItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '20%',
  },
  telLbl: {
    width: 174,
    marginBottom: 4,
  },
  telTextField: {
    display: 'block',
    width: 198,
    '& input': {
      fontFamily: 'Roboto',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  telTextField1: {
    marginRight: 8,
    '& input': {
      fontSize: 13,
      lineHeight: 1.6,
    },
  },
  telTextField2: {
    '& input': {
      fontSize: 13,
      lineHeight: 1.6,
    },
  },
  telClaim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
  },
  // Mail
  mail: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    width: 380,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
    margin: '0 8px',
  },
  mailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '20%',
  },
  mailItem2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  mailLbl1: {
    width: 162,
    marginBottom: 4,
  },
  mailLbl2: {
    width: 162,
    marginBottom: 4,
  },
  mailLbl3: {
    width: 26,
    marginBottom: 4,
  },
  mailTextField: {
    display: 'block',
    '& input': {
      fontFamily: 'Roboto',
      lineHeight: 1.6,
      letterSpacing: 0,
      fontSize: 13,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  mailTextField1: {
    width: 198,
  },
  mailTextField2: {
    width: 198,
  },
  mailChecked: {
    width: 26,
    padding: 0,
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  mailClaim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
  },
  // FAX
  fax: {
    padding: '4px 8px',
    // width: 142,
    flex: 1,
  },
  faxWrap: {
    marginTop: 8,
  },
  faxTextField: {
    display: 'block',
    marginTop: '-3px',
    '& input': {
      fontFamily: 'Roboto',
      letterSpacing: 0,
      padding: 0,
      fontSize: 13,
      lineHeight: 1.6,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  faxClaim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
  },
}));

export default function ResponseRegisterContact(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    user,
    detail,
    setDetail,
    searchCustomerByKeyword,
    // isCreate,
    errorCallback,
  } = props;

  // モーダル 連絡がつきやすい時間帯
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const initial = detail;

  // チェックボックス
  const [isMailSendState, setIsMailSendState] = useState({
    isMailSend1: user.isMailSend1 === 1,
    isMailSend2: user.isMailSend2 === 1,
    isMailSend3: user.isMailSend3 === 1,
    isMailSend4: user.isMailSend4 === 1,
  });

  const onBlur = (e) => {
    const { name: label, value } = e.target;
    if (value === detail[label]) return;
    initial[label] = value;
    detail[label] = value;
    if ([
      'phoneNumber1',
      'phoneNumber2',
      'phoneNumber3',
      'phoneNumber4',
      'fax',
      ...CUSTOMER_MAIL_KEYS,
    ].includes(label)) searchCustomerByKeyword(detail, [label]);
    if (CUSTOMER_MAIL_KEYS.includes(label)) {
      const mailIndex = label.slice(-1);
      const isMailSendLabel = `isMailSend${mailIndex}`;
      let isMailSendFlg = false;
      if (checkMailVaildation(value)) {
        detail[isMailSendLabel] = 1;
        isMailSendFlg = true;
      }
      setIsMailSendState((s) => ({ ...s, [isMailSendLabel]: isMailSendFlg }));
    }
    setDetail({ ...detail });
  };

  const onMailSendChange = (e, key) => {
    const isChecked = e.target.checked;
    setIsMailSendState({
      ...isMailSendState,
      [key]: isChecked,
    });
    initial[key] = isChecked ? 1 : 0;
    setDetail({ ...detail });
  };

  const getTextFieldPhoneNumber = (name) => {
    return (
      <TextBaseField
        placeholder="-"
        type="text"
        defaultValue={user[name]}
        className={`${classes.telTextField} ${classes.telTextField1}`}
        name={name}
        onBlur={onBlur}
        validator={validateFormTelNum()}
        errorCallback={errorCallback(name)}
      />
    );
  };

  const getTextFieldPhoneNumberMemo = (name) => {
    return (
      <TextBaseField
        placeholder="-"
        defaultValue={user[name]}
        className={`${classes.telTextField} ${classes.telTextField2}`}
        name={name}
        onBlur={onBlur}
        validator={validateFormString1({ maxLengthInt: 10 })}
        convertor={convertForm1}
        errorCallback={errorCallback(name)}
      />
    );
  };

  const getTextFieldMail = (name) => {
    return (
      <TextBaseField
        placeholder="-"
        defaultValue={user[name]}
        className={`${classes.mailTextField} ${classes.mailTextField1}`}
        name={name}
        onBlur={onBlur}
        validator={validateFormEmail()}
        errorCallback={errorCallback(name)}
      />
    );
  };

  const getTextFieldMailMemo = (name) => {
    return (
      <TextBaseField
        placeholder="-"
        defaultValue={user[name]}
        className={`${classes.mailTextField} ${classes.mailTextField2}`}
        name={name}
        onBlur={onBlur}
        validator={validateFormString1({ maxLengthInt: 10 })}
        convertor={convertForm1}
        errorCallback={errorCallback(name)}
      />
    );
  };

  const getIsSendMailCheckbox = (name, textField) => {
    return (
      <Checkbox
        checked={isMailSendState[name]}
        onChange={(e) => onMailSendChange(e, name)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        className={classes.mailChecked}
        disabled={!detail[textField]}
      />
    );
  };

  // TEL
  const getTel = () => {
    if (Number(detail.customerStatusCode) === 11) {
      return (
        <Grid className={classes.tel}>
          <Typography className={baseClasses.title4}>TEL</Typography>
          <Typography className={classes.telClaim}>
            クレーム中は閲覧不可
          </Typography>
        </Grid>
      );
    }

    if (Number(detail.telStatusCode) === 0) {
      return (
        <Grid className={classes.tel}>
          <Typography className={baseClasses.title4}>TEL</Typography>
          <Typography className={classes.telClaim}>
            電話NGの場合は閲覧不可
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid className={classes.tel}>
        <Grid className={classes.telItem}>
          <Typography className={`${baseClasses.title4} ${classes.telLbl}`}>
            TEL
          </Typography>
          <Typography className={`${baseClasses.title4} ${classes.telLbl}`}>
            補足
          </Typography>
        </Grid>
        {[1, 2, 3, 4].map(index => (
          <Grid className={classes.telItem}>
            {getTextFieldPhoneNumber('phoneNumber' + index)}
            {getTextFieldPhoneNumberMemo('phoneNumberMemo' + index)}
          </Grid>
        ))}
      </Grid>
    );
  };

  // Mail
  const getMail = () => {
    const code = Number(detail.customerStatusCode);
    if ([0, CUSTOMER_STATUS_CLAIM].includes(code)) { // 0はおそらく必要ない気がする、、、
      return (
        <Grid className={classes.mail}>
          <Typography className={baseClasses.title4}>Mail</Typography>
          <Typography className={classes.mailClaim}>
            {code === CUSTOMER_STATUS_CLAIM
              ? 'クレーム中は閲覧不可'
              : '全部NGの場合は閲覧不可'}
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid className={classes.mail}>
        <Grid className={classes.mailItem}>
          <Typography className={`${baseClasses.title4} ${classes.mailLbl1}`}>
            Mail
          </Typography>
          <Typography className={`${baseClasses.title4} ${classes.mailLbl2}`}>
            補足
          </Typography>
          <Typography className={`${baseClasses.title4} ${classes.mailLbl3}`}>
            送信
          </Typography>
        </Grid>
        {[1, 2, 3, 4].map(index => (
          <Grid className={classes.mailItem}>
            {getTextFieldMail('mail' + index)}
            {getTextFieldMailMemo('mailMemo' + index)}
            {getIsSendMailCheckbox('isMailSend' + index, 'mail' + index)}
          </Grid>
        ))}
      </Grid>
    );
  };

  // FAX
  const getFax = () => {
    return (
      <LabelWrap label="他の連絡手段" box column className={classes.fax}>
        { Number(detail.customerStatusCode) === CUSTOMER_STATUS_CLAIM ? (
          <Typography className={classes.faxClaim}>
            クレーム中は閲覧不可
          </Typography>
        ) : (
          <LabelWrap label="FAX" gap={10} className={classes.faxWrap}>
            <TextBaseField
              placeholder="-"
              defaultValue={user.fax}
              className={classes.faxTextField}
              name="fax"
              onBlur={onBlur}
              validator={validateFormString1({ maxLengthInt: 20 })}
              convertor={convertForm1}
              errorCallback={errorCallback('fax')}
            />
          </LabelWrap>
        )}
      </LabelWrap>
    );
  };

  const holidayTelParameter = {
    holidayWeekday: detail.holidayWeekday,
    otherHolidayWeekday: detail.otherHolidayWeekday,
    convenientTime: detail.convenientTime,
    otherConvenientTime: detail.otherConvenientTime,
  };

  /**
   * @param {typeof holidayTelParameter} data
   */
  const onClickSelectButtonHolidayTelWindow = (data) => {
    setDetail({ ...detail, ...data });
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>連絡先</Typography>
      <Grid className={classes.box}>
        {getTel()}
        {getMail()}
        {getFax()}
      </Grid>
      <Grid className={classes.holiday}>
        <Button className={classes.holidayButton} onClick={handleOpen1}>
          <Typography className={baseClasses.title4}>お休み</Typography>
          <Typography className={classes.holidayTxt}>
            {detail.holidayWeekday}
          </Typography>
        </Button>
        <Button className={classes.holidayButton} onClick={handleOpen1}>
          <Typography className={baseClasses.title4}>その他</Typography>
          <Typography className={classes.holidayTxt}>
            {detail.otherHolidayWeekday}
          </Typography>
        </Button>
      </Grid>
      <Grid className={classes.time}>
        <Button className={classes.timeButton1} onClick={handleOpen1}>
          <Typography className={baseClasses.title4}>
            連絡がつきやすい時間帯
          </Typography>
          <Typography className={classes.timeTxt}>
            {detail.convenientTime}
          </Typography>
        </Button>
        <Button className={classes.timeButton2} onClick={handleOpen1}>
          <Typography className={baseClasses.title4}>その他</Typography>
          <Typography className={classes.timeTxt}>
            {detail.otherConvenientTime}
          </Typography>
        </Button>
      </Grid>
      <HolidayTel
        open={open1}
        onClose={handleClose1}
        category="time"
        onClickSelectButton={onClickSelectButtonHolidayTelWindow}
        initialParam={holidayTelParameter}
      />
    </Grid>
  );
}
