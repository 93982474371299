import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoGetProductsHistoriesSlice = createSlice({
  name: 'hpinfoGetProductsHistories',
  initialState: { hpinfoGetProductsHistories: initialState },
  reducers: {
    hpinfoGetProductsHistoriesApi: (state, data) => {
      state.hpinfoGetProductsHistories = data.payload;
    },
  },
});

export const { hpinfoGetProductsHistoriesApi } = hpinfoGetProductsHistoriesSlice.actions;

export default hpinfoGetProductsHistoriesSlice.reducer;
