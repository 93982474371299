import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import WeekRow from './weekRow';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: 'none',
  },
  label: {
    [commonTheme.breakpoints.down('sm')]: {
      borderTop: '1px solid #C8C8C8',
    },
    '&:first-child': {
      '& td': {
        paddingTop: 8,
      },
      '& .MuiIconButton-label': {
        marginTop: 2,
      },
    },
  },
  labelTd: {
    position: 'relative',
    borderBottom: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
      padding: 8,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '22px 8px 8px 18px',
    },
    '& > span': {
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
      [commonTheme.breakpoints.up('md')]: {
        marginLeft: 20,
      },
    },
    '& > span > span': {
      fontSize: 13,
    },
  },
  labelBtn: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    '& .MuiIconButton-label': {
      display: 'block',
      textAlign: 'left',
      marginTop: 15,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: theme.palette.primaryColor,
      margin: '0 0 0 22px',
    },
  },
  contentsTd: {
    padding: 0,
    borderBottom: 'none',
  },
  contentsBox: {
    margin: 0,
  },
  contentsList: {
    padding: 0,
  },
}));

function Row(props) {
  const {
    row,
    classes,
    baseClasses,
    setOpenCustomertree,
    countUpParent,
    countUpChildWeekDay,
    dates,
    actionsWeeks,
    targetDivisionTree,
  } = props;

  const [open, setOpen] = useState(true);

  let divisions = [];

  if (actionsWeeks) {
    // 組織IDで絞り込み
    divisions = actionsWeeks.divisions.filter((division) => {
      return row.id === division.divisionId;
    });
  }

  // 案内件数（子組織単位）
  const [num, setNum] = useState(0);
  let numCount = 0;
  const countUpChild = (c) => {
    numCount += c;
  };
  useEffect(() => {
    setNum(numCount);
    countUpParent(numCount);
  }, [targetDivisionTree, divisions]);

  return (
    <>
      <TableRow className={`${classes.label} rndWeek-table-label`}>
        <TableCell
          colSpan={27}
          className={`${baseClasses.title3} ${classes.labelTd} rndWeek-table-labelTd`}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={`${baseClasses.onlyPc} ${classes.labelBtn}`}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span>{row.name}<span>（案内 : {num}）</span></span>
        </TableCell>
      </TableRow>
      <TableRow className={classes.contents}>
        <TableCell colSpan={27} className={classes.contentsTd}>
          <Collapse in={open} timeout={0} unmountOnExit>
            <Box margin={1} className={classes.contentsBox}>
              <List className={classes.contentsList}>
                {row.users.map((action) => (
                  <WeekRow
                    key={action.userId}
                    action={action}
                    divisions={divisions}
                    setOpenCustomertree={setOpenCustomertree}
                    countUpChild={countUpChild}
                    countUpChildWeekDay={countUpChildWeekDay}
                    dates={dates}
                  />
                ))}
                <WeekRow
                  action={{ userName: '担当なし' }}
                  divisions={divisions}
                  setOpenCustomertree={setOpenCustomertree}
                  countUpChild={countUpChild}
                  countUpChildWeekDay={countUpChildWeekDay}
                  dates={dates}
                  noChargeFlg
                />
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function WeekTable(props) {
  const {
    setOpenCustomertree,
    countUpParent,
    countUpChildWeekDay,
    dates,
  } = props;

  const {
    actionsWeeks,
    searchInput,
    targetDivisionTree,
    rerender,
    rerenderKey,
  } = useActionSchedule();

  const classes = useStyles();
  const baseClasses = commonStyles();

  const getChildren = (rows) => {
    const list = rows.filter((item) => {
      return item.users;
    });
    return list.map((row) => (
      <Row
        key={`actionScheduleWeekRecord-${row.id}`}
        row={row}
        classes={classes}
        baseClasses={baseClasses}
        setOpenCustomertree={setOpenCustomertree}
        countUpParent={countUpParent}
        countUpChildWeekDay={countUpChildWeekDay}
        dates={dates}
        actionsWeeks={actionsWeeks}
        searchInput={searchInput}
        targetDivisionTree={targetDivisionTree}
      />
    ));
  };

  // 組織が変更されたら表示をリセットする
  useEffect(() => {
    rerender();
  }, [targetDivisionTree]);

  return (
    <TableContainer component={Paper} className={classes.tableContainer} key={rerenderKey}>
      <Table aria-label="collapsible table">
        <TableBody>
          {/* 組織に紐づくユーザーを表示 */}
          <Row
            row={targetDivisionTree}
            classes={classes}
            baseClasses={baseClasses}
            setOpenCustomertree={setOpenCustomertree}
            countUpParent={countUpParent}
            countUpChildWeekDay={countUpChildWeekDay}
            dates={dates}
            actionsWeeks={actionsWeeks}
            searchInput={searchInput}
            targetDivisionTree={targetDivisionTree}
          />
          {/* 子組織に紐づくユーザーを表示 */}
          {targetDivisionTree.children.length > 0 && (
            getChildren(targetDivisionTree.children)
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
