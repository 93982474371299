import React, { useCallback } from 'react';
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormLabel,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatValue, classNames } from '../../../commonFunction';
import positionChange from '../../../commonFunction/positionChange';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& > label': {
      fontSize: '0.875rem',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiSelect-root': {
      minWidth: 120,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiInput-input': {
      fontSize: 13,
    },
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    '& > label + .MuiInput-formControl': {
      margin: '0 0 0 10px',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  noUnderline: {
    '& .MuiInput-underline::before': {
      borderColor: 'transparent',
    },
  },
  left: {
    '& .MuiInputBase-root': {
      textAlign: 'left',
    },
  },
  select: {
    height: '100%',
    '& .MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 20,
    },
  },
}));

/**
 * Select(division)
 * @param {{
 * label?: string;
 * value: *;
 * options: Array<*>;
 * onChange: (value, currentItem, e) => void;
 * labelKey?: string;
 * valueKey?: string;
 * readOnly?: boolean;
 * disabled?: boolean;
 * row?: boolean;
 * noUnderline?: boolean;
 * }} props
 * @returns {ReactNode}
 */
const Select = React.forwardRef((props, ref) => {
  const {
    label,
    value,
    defaultValue,
    name,
    onBlur,
    onChange,
    options = [],
    labelKey = 'label',
    valueKey = 'value',
    readOnly,
    disabled,
    row = false,
    noUnderline,
    left, // textAlign=left
    className,
    style,
    placeholder = false,
    activeLabel = false, // ぴょこんと上に動くラベルの場合true
    hasZero = false, // 0のバリューを持ち、別途空valueを持つ場合true
  } = props;

  const onSelectChange = useCallback((e) => {
    const val = formatValue(e.target.value);
    const current = options.find(item => item[valueKey] === val);
    onChange?.(val, current, e);
  }, [options, valueKey, labelKey]);

  const classes = useStyles();

  return (
    <FormControl
      className={classNames(
        classes.root,
        row ? classes.row : null,
        noUnderline ? classes.noUnderline : null,
        left ? classes.left : null,
        className,
      )}
      style={style}
    >
      {label && !activeLabel && (
        <FormLabel>{label}</FormLabel>
      )}
      {label && activeLabel && (
        <InputLabel>{label}</InputLabel>
      )}
      <MuiSelect
        name={name}
        value={value || (hasZero && value === 0) ? value : ''}
        defaultValue={defaultValue}
        onChange={onSelectChange}
        onBlur={onBlur}
        ref={ref}
        readOnly={readOnly}
        disabled={disabled}
        onOpen={positionChange}
        className={classes.select}
      >
        {placeholder ? (
          <MenuItem
            value="undefined"
            disabled
          >
            {placeholder}
          </MenuItem>
        ) : null}
        {hasZero ? (
          <MenuItem
            value=""
          >
            &nbsp;
          </MenuItem>
        ) : null}
        {options.map((item, i) => (
          <MenuItem
            value={item[valueKey]}
            key={String(i)}
          >
            {item[labelKey]}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
});

export default Select;
