import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import commonStyles from '../styles';
import { validateFormInt1 } from '../../commonFunction/validations';

function Reverberation(props) {
  const { division, setDivision, getComponent } = props;
  const baseClasses = commonStyles();

  const handleChange = (e, data) => {
    const val = e.target ? e.target.value : e;
    switch (data.key) {
      case 'phoneNumberAreaCode':
        setDivision({ ...division, phoneNumberAreaCode: val });
        break;
      default:
        break;
    }
  };

  const setDate = (firstDate, endDate) => {
    if (firstDate.indexOf('-') || endDate.indexOf('-')) {
      firstDate = firstDate.replaceAll('-', '/');
      endDate = endDate.replaceAll('-', '/');
    }
    setDivision({
      ...division,
      responseAutomationStartAt: firstDate,
      responseAutomationEndAt: endDate,
    });
  };

  const basicField = [
    {
      label: '市外局番',
      require: false,
      type: 'number',
      key: 'phoneNumberAreaCode',
      state: division.phoneNumberAreaCode,
      set: handleChange,
      validator: validateFormInt1({
        minFloat: 0.0,
        maxFloat: 99999.0,
      }),
    },
    {
      id: 'divisionAutoDate',
      label: '対象期間',
      require: false,
      type: 'multiple',
      key: 'period',
      state: {
        startAt: division.responseAutomationStartAt ? division.responseAutomationStartAt : '',
        endAt: division.responseAutomationEndAt ? division.responseAutomationEndAt : '',
      },
      setState: setDate,
    },
  ];

  return (
    <>
      <Typography style={{ marginBottom: 10 }} className={baseClasses.title3}>反響自動処理</Typography>
      {basicField.map((data) => (
        <Grid key={data.key}>
          {getComponent(data)}
        </Grid>
      ))}
    </>
  );
}
export default Reverberation;
