import client from '../httpClient';

/**
 * @typedef { import(
 *   './getUnregistListApiTypes'
 * ).UnregistListGetResponse } UnregistListGetResponse
 * @type { (query: {
 *   userId?: number;
 *   divisionId: number;
 * }) => Promise<IncompleteListGetResponse> }
 */
const getUnregistListApi = async (params) => {
  const res = await client.get('/guidances/unregist-list/', { params });
  return res;
};

export default getUnregistListApi;
