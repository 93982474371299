import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import commonTheme from './styles/theme';

export default function CustomTheme(props) {
  const { children } = props;
  const themeColor = useSelector((state) => state.colorTheme.color);
  const [colorTheme, setColorTheme] = useState('default');

  const theme = createTheme({
    palette: colorTheme,
    overrides: {
      MuiSelect: {
        root: {
          '&.MuiSelect-select:focus': { backgroundColor: 'transparent' },
        },
        select: {
          [commonTheme.breakpoints.down('sm')]: { fontSize: 14, fontWeight: 600 },
          '&:hover': { backgroundColor: `${colorTheme.primaryColor}29` },
        },
      },
      MuiAlert: {
        action: {
          '& .MuiIconButton-colorInherit': { color: '#fff' },
        },
      },
      MuiIconButton: {
        colorInherit: {
          color: colorTheme.primaryColor,
        },
      },
      MuiTablePagination: {
        colorInherit: {
          color: colorTheme.primaryColor,
        },
        selectIcon: {
          color: colorTheme.primaryColor,
          fill: colorTheme.primaryColor,
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
          '&.Mui-checked': {
            color: `${colorTheme.primaryColor} !important`,
          },
        },
      },
      MuiRadio: {
        colorPrimary: {
          '&.Mui-checked': { color: colorTheme.primaryColor },
        },
        colorSecondary: {
          '&.Mui-checked': { color: colorTheme.primaryColor },
        },
      },
      MuiInput: {
        underline: {
          '&:hover:not(.Mui-disabled)::before': { borderBottom: `2px solid ${colorTheme.primaryColor}` },
          '&:has(> input[type="text"]):hover::after, &:has(> input[type="number"]):hover::after, &: has(> textarea): hover:: after': { transform: 'scaleX(1)' },
          '&::after': { borderBottom: `2px solid ${colorTheme.primaryColor}` },
          '&:has(> .MuiSelect-select)::before, &:has(> .MuiSelect-select)::after': {
            [commonTheme.breakpoints.down('sm')]: { display: 'none' },
          },
        },
      },
      MuiInputLabel: {
        root: {
          fontFamily: "'Roboto', 'Noto Sans JP', -apple-system, 'Ubuntu', 'Helvetica Neue', sans-serif",
        },
      },
      MuiAppBar: {
        root: {
          '& .MuiTabs-indicator': { backgroundColor: colorTheme.primaryColor },
        },
        colorPrimary: {
          backgroundColor: '#fff',
          color: '#333',
        },
      },
      MuiTab: {
        textColorInherit: {
          opacity: 1,
          '&.Mui-selected': { color: colorTheme.primaryColor },
        },
      },
      MuiTypography: {
        body1: {
          fontFamily: "'Roboto', 'Noto Sans JP', -apple-system, 'Ubuntu', 'Helvetica Neue', sans-serif",
          fontSize: 'none',
          fontWeight: 'none',
          letterSpacing: 'none',
          lineHeight: 'none',
        },
        colorPrimary: { color: colorTheme.primaryColor },
      },
      MuiButton: {
        root: {
          fontFamily: "'Roboto', 'Noto Sans JP', -apple-system, 'Ubuntu', 'Helvetica Neue', sans-serif",
          fontWeight: 700,
          backgroundColor: 'none',
        },
      },
      MuiTableHead: {
        root: { whiteSpace: 'pre' },
      },
      MuiTableCell: {
        stickyHeader: { whiteSpace: 'pre' },
      },
      MuiTableSortLabel: {
        root: { flexDirection: 'row' },
      },
      MuiAccordion: {
        root: {
          '&.Mui-expanded': { margin: 0 },
        },
      },
      // ---- 上書き用スタイル
      font: {
        weightBold: {
          fontWeight: 700,
        },
        sizeSmall: {
          fontSize: '12px',
        },
        sizeSmall2: {
          fontSize: '13px',
        },
        sizeSmall3: {
          fontSize: '10px',
        },
      },
      btn: {
        typeSecondary: {
          background: '#FFFFFF',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #FFFFFF',
          [commonTheme.breakpoints.down('sm')]: {
            padding: '8px 22px',
            margin: '0px 4px',
            minWidth: '86px',
          },
          '&:hover': {
            background: '#FFFFFF',
            border: `1px solid ${colorTheme.primaryColor}`,
            boxShadow: '0px 0px 0px 4px rgba(6, 121, 191, 0.16)',
            transition: '0.3s',
          },
          '&>span': {
            color: `${colorTheme.primaryColor}`,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          },
          '&.Mui-disabled > span': {
            color: 'inherit',
          },
          '& .MuiSvgIcon-root': {
            [commonTheme.breakpoints.up('md')]: {
              fontSize: 15,
            },
          },
        },
        // モーダルを呼び出すボタン
        typeModal: {
          borderRadius: 0,
          minHeight: '1.6em',
          transition: 'all 0.3s ease',
          '& .MuiInputBase-input': {
            cursor: 'pointer',
          },
          '&:hover': {
            backgroundColor: `${colorTheme.primaryColor}29`,
          },
        },
      },
      lineHeight: {
        small: {
          lineHeight: 1.2,
        },
      },
      searchButton: {
        [commonTheme.breakpoints.down('sm')]: {
          minWidth: 'auto',
          padding: '0',
          position: 'fixed',
          bottom: '24px',
          right: '24px',
          width: '56px',
          height: '56px',
          backgroundColor: colorTheme.primaryColor,
          borderRadius: '100%',
          boxShadow:
            '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
        },
      },
    },
  });

  useEffect(() => {
    if (themeColor === 'default') {
      setColorTheme({
        primaryColor: '#0679BF',
        gradLightColor: '#56B4E9',
        // #0679BF
      });
    }
    if (themeColor === 'pink') {
      setColorTheme({
        primaryColor: '#bf0653',
        gradLightColor: '#f14e92',
      });
    }
    if (themeColor === 'green') {
      setColorTheme({
        primaryColor: '#048308',
        gradLightColor: '#89d733',
        // #06bf83
      });
    }
    if (themeColor === 'lightgreen') {
      setColorTheme({
        primaryColor: '#67bf06',
        gradLightColor: '#9ff53f',
        // #06bf83
      });
    }
    if (themeColor === 'orange') {
      setColorTheme({
        primaryColor: '#d56719',
        gradLightColor: '#ff9f5a',
        // #06bf83
      });
    }
    if (themeColor === 'purple') {
      setColorTheme({
        primaryColor: '#5d13d9',
        gradLightColor: '#9575fd',
        // #06bf83
      });
    }
  }, [themeColor]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
