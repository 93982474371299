import { useState } from 'react';
import {
  MenuItem,
  TextField,
  TableCell,
  makeStyles,
  InputAdornment,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextBaseField from './../../eleCommon/validation/TextBaseField';
import { validateFormInt1, validateFormString1 } from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';

const useStyles = makeStyles({
  tableSearchRowCell: {
    margin: '0px',
    padding: '4px',
  },
  iconAdornment: {
    padding: '0px',
  },
  searchIcon: {
    height: '1rem',
  },
  textArea: {
    margin: '0px',
    backgroundColor: '#FFFFFF',
    border: '0px solid #FFFFFF',
  },
  textInput: {
    padding: '5px',
    fontSize: '13px',
    '&.MuiSelect-select:focus': { background: 'none' },
  },
  searchButton: {
    padding: '6px 0',
    minWidth: 'auto',
  },
});

function SearchCell(props) {
  const { initial, style, setSearchParm } = props;
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');

  const onChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filterFunc = (val) => {
    setSearchParm((state) => ({ ...state, [initial.key]: val }));
  };

  const onClickSearch = () => {
    filterFunc(searchValue);
  };

  const onKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    filterFunc(e.target.value);
  };

  switch (initial.type) {
    case 'select': {
      const { menus } = initial;
      return (
        <TableCell style={style} className={classes.tableSearchRowCell}>
          <TextField
            select
            defaultValue={initial.state}
            onChange={(e) => {
              if (e.target.value !== 0) {
                filterFunc(e.target.value);
                setSearchValue(e.target.value);
              } else {
                filterFunc('');
                setSearchValue('');
              }
            }}
            className={classes.textArea}
            margin="none"
            InputProps={{
              classes: {
                input: classes.textInput,
                adornedStart: classes.iconAdornment,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Button
                    onClick={onClickSearch}
                    className={classes.searchButton}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          >
            {menus.map((data) => (
              <MenuItem value={data.value} key={data.value} size="small">
                {data.text}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
      );
    }
    case 'order':
      return (
        <TableCell style={style} className={classes.tableSearchRowCell}>
          <TextBaseField
            className={classes.textArea}
            onChange={onChange}
            onKeyDown={onKeyDown}
            margin="none"
            InputProps={{
              classes: {
                input: classes.textInput,
                adornedStart: classes.iconAdornment,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Button
                    onClick={onClickSearch}
                    className={classes.searchButton}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            validator={validateFormInt1({
              minFloat: -999,
              maxFloat: 999,
            })}
            convertor={convertFormNumber}
          />
        </TableCell>
      );
    case 'sales':
      return (
        <TableCell style={style} className={classes.tableSearchRowCell}>
          <TextBaseField
            className={classes.textArea}
            onChange={onChange}
            onKeyDown={onKeyDown}
            margin="none"
            InputProps={{
              classes: {
                input: classes.textInput,
                adornedStart: classes.iconAdornment,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Button
                    onClick={onClickSearch}
                    className={classes.searchButton}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            validator={validateFormInt1({
              minFloat: 0,
              maxFloat: 1,
            })}
            convertor={convertFormNumber}
          />
        </TableCell>
      );
    default:
      return (
        <TableCell style={style} className={classes.tableSearchRowCell}>
          <TextBaseField
            className={classes.textArea}
            onChange={onChange}
            onKeyDown={onKeyDown}
            margin="none"
            InputProps={{
              classes: {
                input: classes.textInput,
                adornedStart: classes.iconAdornment,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Button
                    onClick={onClickSearch}
                    className={classes.searchButton}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            validator={validateFormString1({
              maxLengthInt: 50,
            })}
          />
        </TableCell>
      );
  }
}

export default SearchCell;
