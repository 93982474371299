import React, { useEffect, useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import commonStyles from '../../styles';
import { afterCallSelect } from '../../../constants/customerMain';
import { getLocalStorage, setLocalStorage } from '../../../commonFunction';
import { AFTER_CALL_CODE } from '../../../constants/localStorage';

const useStyles = makeStyles(() => ({
  accordionDetails: {
    padding: '8px 8px 4px',
  },
  select: {
    '& .MuiFormLabel-root': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      top: '-10px',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 9px) scale(0.7)',
      },
    },
    '& .MuiSelect-root': {
      width: 200,
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      textAlign: 'left',
    },
    '& .MuiSelect-select': {
      padding: '0 24px 0 0',
    },
  },
}));

function ChaseOption() {
  const classes = useStyles();
  const common = commonStyles();
  const [afterCallCode, setAfterCallCode] = useState(getLocalStorage(AFTER_CALL_CODE, 1));

  useEffect(() => {
    setLocalStorage(AFTER_CALL_CODE, afterCallCode);
  }, [afterCallCode]);

  return (
    <Grid>
      <Accordion
        defaultExpanded
        className={common.sideAccordion}
      >
        <AccordionSummary
          className={common.sideAccordionHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <AddIcon style={{ color: '#8C8C8C' }} fontSize="small" />
          <Typography>追客オプション</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid className={classes.select}>
            <Select
              id="after-call-select"
              value={afterCallCode}
              onChange={(e) => setAfterCallCode(e.target.value)}
            >
              {Object.keys(afterCallSelect).map((key) => (
                <MenuItem value={key}>{afterCallSelect[key]}</MenuItem>
              ))}
            </Select>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default ChaseOption;
