import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { type, unit } from '../../../constants/customerTree';

const useStyles = makeStyles({
  select: {
    width: '49%',
    marginBottom: '8px',
    '& .MuiSelect-selectMenu': {
      fontSize: '13px',
      textAlign: 'left',
    },
  },
});

function SelectBox(data) {
  const { list, value, setValue } = data;
  const classes = useStyles();
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <FormControl className={classes.select}>
      <InputLabel>{list.title}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
      >
        {Object.keys(list.menus).map(key => (
          <MenuItem key={list.menus[key]} value={key}>
            {list.menus[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function TreeSelectUnit(props) {
  const { code, setCode } = props;

  return (
    <Grid container justifyContent="space-between">
      <SelectBox
        list={type}
        value={code.typeCode}
        setValue={(e) => { return setCode({ ...code, typeCode: e }); }}
      />
      <SelectBox
        list={unit}
        value={code.unitCode}
        setValue={(e) => { return setCode({ ...code, unitCode: e }); }}
      />
    </Grid>
  );
}
