import axios from 'axios';

import store from '../store/index';
import { setResponseHeader } from '../store/common/responseHeaderSlice';
import { isOpen } from '../store/common/apiMessageSlice';
import {
  addLoadingId,
  removeLoadingId,
  clearLoadingIds,
} from '../store/loading';

import { FULLSCREEN_LOADING } from '../constants/loading';
import { CHANGE_USER } from '../constants/localStorage';

import { isArray, toNumber } from '../commonFunction';
import { getCookie } from '../commonFunction/cookie';
import { signOutProcess } from '../commonFunction/authorize';
import getEnv, { getEnvVariable } from '../commonFunction/getEnv';
import getDeviceType from '../components/getDeviceType';

const env = getEnv();
const isSP = getDeviceType();

class client {
  static createInstance(param = {}) {
    const { isShowLoading = false, isShowMessage = false } = param;
    const { responseHeader } = store.getState();
    let baseURL;
    if (process.env.REACT_APP_BASE_API_URL === 'GITHUB') {
      // Github Actions CI用
      baseURL = 'http://localhost:8000/';
    } else if (process.env.REACT_APP_BASE_API_URL === 'LOCAL') {
      // スタブ ローカル用
      baseURL = 'http://10.22.2.48:8000/';
    } else {
      // 通常の環境用
      baseURL = `https://api.${getEnvVariable('DOMAIN')}.openhouse-group.com/api/v1/`;
    }

    const apiInformation = {
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
      // loading用のカスタム変数
      isShowLoading,
      loadingId: FULLSCREEN_LOADING,
      isShowMessage,
      params: {
        opeUserId: responseHeader.userId,
        cVer: getEnvVariable('CLIENT_VERSION'),
      },
    };

    if (process.env.REACT_APP_TEST_USER) {
      apiInformation.headers.Authorization = 'Bearer ' + process.env.REACT_APP_TEST_USER;
    }

    const instance = axios.create(apiInformation);
    instance.interceptors.request.use((request) => this.preRequestProcess(request));
    instance.interceptors.response.use(
      async (response) => this.requestSuccess(response),
      async (error) => this.requestFailure(error),
    );

    return instance;
  }

  static setAuth(request) {
    const accessToken = getCookie('ACCESS_TOKEN');
    let token = decodeURIComponent(accessToken);
    if (env !== 'PRODUCTION') {
      const changeUserEmail = localStorage.getItem(CHANGE_USER);
      token = changeUserEmail || token;
    }
    request.headers.Authorization = `Bearer ${token}`;
  }

  static setLoading(request) {
    if (!request.isShowLoading && request.loadingId === FULLSCREEN_LOADING) return;
    store.dispatch(addLoadingId(request.loadingId));
  }

  static preRequestProcess(request) {
    this.setAuth(request);

    const timeInLS = JSON.parse(localStorage.getItem('timeSpentOnHoliday'));
    if (!isSP || request.url === '/common/users/count') {
      // PCの場合 または act006の場合は必ず is-skip-check-holiday=1にする
      request.headers['is-skip-check-holiday'] = '1';
    } else {
      request.headers['is-skip-check-holiday'] = timeInLS?.availableTime <= 0 ? '0' : '1';
    }

    this.setLoading(request);
    return request;
  }

  static async requestSuccess(response) {
    const at = response.data.data ? response.data.data.access_token : null;
    if (at) {
      response.headers.Authorization = response.data.data.access_token;
    }
    // response header process
    const { responseHeader } = store.getState();
    if (
      toNumber(response.headers.userid) !== responseHeader.userId
      || toNumber(response.headers['role-group-id']) !== responseHeader.roleGroupId
      || !!toNumber(response.headers['is-holiday']) !== responseHeader.isHoliday
      || !!toNumber(response.headers['is-holiday-alert']) !== responseHeader.isHolidayAlert
    ) {
      store.dispatch(setResponseHeader(response.headers));
    }
    if (response.status !== 200 || response.config.isShowMessage) {
      // api msg process
      const data = { ...response.data, status: 'success' };
      store.dispatch(isOpen(data));
    }
    const res = typeof response.data === 'object' && !(response.data instanceof ArrayBuffer)
      ? { ...response.data, status: response.status }
      : { data: response.data, status: response.status };
    res.headers = response.headers;

    // api loading process
    if (response.config.isShowLoading) {
      store.dispatch(removeLoadingId(response.config.loadingId));
    }
    return res;
  }

  static response422(error) {
    const message = `${error.response.status} ${error.response.data.description}`;
    const errors = error.response.data.errors || {};
    const messageList = [];
    const spreadArrayAndObject = (obj, parentKey = null) => {
      if (typeof obj === 'string') {
        messageList.push(parentKey ? `${parentKey}: ${obj}` : obj);
        return;
      }
      if (isArray(obj)) {
        obj.forEach((e) => spreadArrayAndObject(e, parentKey));
        return;
      }
      Object.entries(obj).forEach(([key, val]) => {
        if (isArray(val)) {
          spreadArrayAndObject(val, key);
          return;
        }
        messageList.push(`${key}: ${val}`);
      });
    };
    spreadArrayAndObject(errors);
    return { message, messageList };
  }

  static requestFailure(error) {
    if (!axios.isAxiosError(error)) {
      // axios以外のエラー処理
      console.error(error);
      store.dispatch(clearLoadingIds());
      return error;
    }

    let message = '';
    let description = '';
    let messageList = [];

    if (!error.response) {
      console.error(error);
      message = error.code === 'ECONNABORTED'
        ? 'タイムアウトエラー'
        : 'ネットワークエラー';
    } else {
      // response error
      console.error(error.response);
      switch (error.response.status) {
        case 422: {
          const tmp422 = this.response422(error);
          message = tmp422.message;
          messageList = tmp422.messageList;
          break;
        }
        default:
          if (error.response.status === 401) signOutProcess();
          message = `${error.response.status} ${error.response.data.message}`;
          description = error.response.data.description;
          break;
      }
    }
    const errorMsg = {
      status: 'error',
      message,
      description,
      messageList,
    };
    store.dispatch(isOpen(errorMsg));

    // api loading process
    if (!error.response) {
      store.dispatch(removeLoadingId(FULLSCREEN_LOADING));
    } else if (error.response.config.isShowLoading) {
      store.dispatch(removeLoadingId(error.response.config.loadingId));
    }

    throw error;
  }

  static get(url, config) {
    const instance = this.createInstance();
    return instance.get(url, config);
  }

  static delete(url, config) {
    const instance = this.createInstance({ isShowLoading: true });
    return instance.delete(url, config);
  }

  static head(url, config) {
    const instance = this.createInstance();
    return instance.head(url, config);
  }

  static options(url, config) {
    const instance = this.createInstance();
    return instance.options(url, config);
  }

  static post(url, data, config) {
    const instance = this.createInstance({ isShowLoading: true });
    return instance.post(url, data, config);
  }

  static put(url, data, config) {
    const instance = this.createInstance({ isShowLoading: true });
    return instance.put(url, data, config);
  }

  static patch(url, data, config) {
    const instance = this.createInstance();
    return instance.patch(url, data, config);
  }
}

export default client;
