import React from 'react';
import {
  Dialog,
  Paper,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '15000!important',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        margin: 16,
        width: 'calc(100% - 32px)',
        maxHeight: 'calc(100% - 32px)',
      },
    },
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderRadius: 4,
    [commonTheme.breakpoints.up('md')]: {
      width: 640,
    },
  },
  content: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 24,
      borderTop: '1px solid #C8C8C8',
      borderBottom: '1px solid #C8C8C8',
    },
  },
  ttl: {
    padding: '24px 24px 8px',
    [commonTheme.breakpoints.up('md')]: {
      cursor: 'move',
      borderBottom: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 20,
      textAlign: 'center',
    },
  },
  txt: {
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px solid #C8C8C8',
      padding: 24,
    },
    '& span': {
      display: 'block',
    },
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 0',
    },
  },
  btn1: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 22px 7px',
    marginRight: 16,
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  btn2: {
    fontSize: 15,
    padding: '8px 22px 6px',
    minWidth: 89,
  },
}));

const windowWidth = window.innerWidth;
const breakPoint = 768;
const isSP = (windowWidth < breakPoint);

function PaperComponent(props) {
  return (
    isSP ? (
      <Paper {...props} />
    ) : (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    )
  );
}

function SelectProductConfirm(props) {
  const {
    open,
    onClose,
    data,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={`${baseClasses.cancelIndex} ${classes.dialog}`}
    >
      <Grid className={classes.paper}>
        <Typography
          id="draggable-dialog-title"
          className={`${baseClasses.title3} ${classes.ttl}`}
        >
          {data.ttl}
        </Typography>
        <Grid className={classes.content}>
          <Typography className={classes.txt}>
            {data.txt.map((txt) => (
              <span key={txt}>
                {txt}
              </span>
            ))}
          </Typography>
        </Grid>
        <Grid className={classes.btnWrap}>
          <Button
            className={classes.btn1}
            onClick={() => {
              data.set1();
              onClose();
            }}
          >
            CANCEL
          </Button>
          <Button
            className={`${baseClasses.buttonsPrimary} ${classes.btn2}`}
            onClick={() => {
              data.set2();
              onClose();
            }}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default SelectProductConfirm;
