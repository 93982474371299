import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 16px 20px 24px',
      borderBottom: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: 8,
      borderBottom: '1px solid #F3F3F3',
    },
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalTime: {
    flexShrink: 0,
  },
  totalTimeLbl: {
    marginBottom: 4,
    textAlign: 'center',
  },
  totalTimeNum: {
    textAlign: 'center',
  },
  totalNumber: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '24px',
    },
  },
  totalNumberItem: {
    display: 'flex',
    alignItems: 'center',
    height: 27,
    background: '#F3F3F3',
    borderRadius: '100px',
    marginBottom: 4,
    [commonTheme.breakpoints.up('md')]: {
      width: 223,
      justifyContent: 'space-between',
      padding: '0 13px 0 18px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 8px 0 18px',
    },
  },
  totalNumberIcon: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
    '& .MuiSvgIcon-root': {
      color: '#333',
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 14,
      },
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 18,
        marginRight: 18,
      },
    },
  },
  totalNumberTxt: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 90,
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '50%',
      '& + &': {
        marginLeft: 9,
      },
    },
  },
  totalNumberLbl: {
    marginRight: 4,
    flexShrink: 0,
  },
});

function ContactTotalHeader(props) {
  const {
    contactObjs = [],
    totalTime,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const countMap = {
    案内: 0,
    来社: 0,
    通電: 0,
    不通: 0,
    送信: 0,
    受信: 0,
  };
  contactObjs.forEach((obj) => {
    obj.counts.forEach((count) => {
      const num = count.totalContactCount ? count.totalContactCount : 0;
      switch (count.typeName) {
        case '送付':
          countMap['送信'] += num;
          break;
        case '受理':
          countMap['受信'] += num;
          break;
        default:
          countMap[count.typeName] += num;
          break;
      }
    });
  });

  const numbers = [
    {
      icon: <DateRangeOutlinedIcon />,
      lbl1: '案内',
      num1: countMap['案内'],
      lbl2: '来社',
      num2: countMap['来社'],
    },
    {
      icon: <PhoneIcon />,
      lbl1: '通電',
      num1: countMap['通電'],
      lbl2: '不通',
      num2: countMap['不通'],
    },
    {
      icon: <MailOutlineIcon />,
      lbl1: '送信',
      num1: countMap['送信'],
      lbl2: '受信',
      num2: countMap['受信'],
    },
  ];

  const getHour = () => {
    const hour = totalTime ? '00' + Math.floor(totalTime / 60) : '00';
    const min = totalTime ? '00' + (totalTime % 60) : '00';
    const hourMin = `${hour.slice(-2)}:${min.slice(-2)}`;
    return (hourMin);
  };

  // 3桁カンマ区切り
  const comma = (num) => {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  };

  return (
    <Grid className={classes.root}>
      <Grid>
        <Typography className={baseClasses.title3}>実績</Typography>
      </Grid>
      <Grid className={classes.item}>
        <Grid className={classes.totalTime}>
          <Typography
            className={`${baseClasses.title6} ${classes.totalTimeLbl}`}
          >
            総接触時間
          </Typography>
          <Typography
            className={`${baseClasses.title3} ${classes.totalTimeNum}`}
          >
            {getHour()}
          </Typography>
        </Grid>
        <Grid className={classes.totalNumber}>
          <Grid className={classes.totalNumberList}>
            {numbers.map((number) => (
              <Grid key={number.lbl1} className={classes.totalNumberItem}>
                <Grid className={classes.totalNumberIcon}>{number.icon}</Grid>
                <Grid className={classes.totalNumberTxt}>
                  <Typography
                    className={`${baseClasses.title6} ${classes.totalNumberLbl}`}
                  >
                    {number.lbl1}
                  </Typography>
                  <Typography className={baseClasses.title3}>
                    {comma(number.num1)}
                  </Typography>
                </Grid>
                <Grid className={classes.totalNumberTxt}>
                  <Typography
                    className={`${baseClasses.title6} ${classes.totalNumberLbl}`}
                  >
                    {number.lbl2}
                  </Typography>
                  <Typography className={baseClasses.title3}>
                    {number.num2}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactTotalHeader;
