import { memo, useRef } from 'react';
import { CKEditor } from 'ckeditor4-react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  body: {
    width: 'auto',
    height: '100%',
    flex: '1 1 auto',
    '& figure': {
      margin: 0,
      '& > img': {
        width: '100%',
      },
    },
    '& .public-DraftStyleDefault-block': {
      wordBreak: 'break-all',
    },
    '& .toolbar': {
      display: 'none',
    },
  },
});

function MailTitleAndBody(props) {
  const { initData = '', setMailBody = () => {}, disable = false } = props;
  const classes = useStyles();

  const ref = useRef(null);

  // memo: onBlurの場合、以下の理由で期待される動作にならなかった
  // onBlur->onClick と走るものの、useStateの更新が反映される前の為に登録内容と表示に差異が出る
  const handleChange = (event) => {
    const data = event?.editor?.getData?.() ?? '';
    setMailBody(data);
  };

  const handleLoaded = ({ editor }) => {
    // 下記Issueにて、CKEditor4にはmodal等で"tabIndex='-1'"が設定されている場合、input要素が入力できない問題が挙げられている
    // Material UI側ではPropsによる設定ができなかった為、querySelectorで暫定対応
    // ref: Can't type in fields · Issue #3555 · ckeditor/ckeditor4 · GitHub
    // link: https://github.com/ckeditor/ckeditor4/issues/3555
    const muiModal = document.getElementsByClassName(
      'MuiDialog-container MuiDialog-scrollPaper',
    )?.[0];
    muiModal?.removeAttribute('tabindex');

    // CKEditorの高さ自動調整プラグイン`autogrow`と`config.fullPage`を併用した際に発生する既知の問題。
    // 解決策が見つからなかったため、JS内で動的に挿入で対処。
    const maxHeight = ref?.current?.offsetHeight ?? 0;
    editor?.resize(null, maxHeight, true);
  };

  return (
    <Grid className={classes.body} ref={ref}>
      <CKEditor
        initData={initData}
        onChange={handleChange}
        onLoaded={handleLoaded}
        readOnly={disable}
        editorUrl="/CKEditor/sp/ckeditor.js"
      />
    </Grid>
  );
}

export default memo(MailTitleAndBody, () => true);
