import React, { useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  List,
  ListItem,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import ConfirmModal from '../common/modal/confirmModal';
import { URL_MAPPER } from '../../constants/urls';

const useStyles = makeStyles((theme) => ({
  memberListGridRow: {
    border: '1px solid #C8C8C8',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    marginTop: '10px',
  },
  memberListLittleContent: {
    width: '62%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  memberId: {
    marginRight: '20px',
  },
  buttonColor: {
    color: theme.palette.primaryColor,
  },
  errorButton: {
    width: '16.67px',
    height: '16.67px',
    color: theme.palette.primaryColor,
    marginRight: '4px',
  },
  ArrowForwardButton: {
    width: '10px',
    height: '15px',
    color: theme.palette.primaryColor,
    marginLeft: '4px',
  },
  buttonDisableColor: {
    color: '#AFAFAF',
  },
}));

/**
 * RelatedMember
 * @param {{
 * list: {memberId: number; isMain: number; memberStatusCode: number; lastWebAccessDate: string}[];
 * fullName: string;
 * onDelete: (memberId: number, callback: () => void) => void;
 * onMainChange: (memberId: number, callback: () => void) => void;
 * }} props
 * @returns
 */
export default function RelatedMember(props) {
  const { list, onDelete, onMainChange } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  // キャンセルボタンモーダル用ステート
  const [open, setOpen] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState(null);

  const handleOpen = (memberId) => {
    setOpen(true);
    setCurrentMemberId(memberId);
  };

  const onClose = () => {
    setOpen(false);
  };

  const memberList = (
    isSP ? (
      <List>
        {list.map((member) => (
          <ListItem>
            {member.memberId}
          </ListItem>
        ))}
      </List>
    ) : (
      <>
        {list.map((item) => (
          <Grid key={item.memberId} className={classes.memberListGridRow}>
            <Grid style={{ width: '10%' }}>
              <Typography className={`${baseClasses.title4} ${classes.memberId}`}>
                {item.memberId}
              </Typography>
            </Grid>
            <Grid style={{ width: '28%' }}>
              <Typography className={baseClasses.title4}>
                {`${item.lastName ?? ''} ${item.firstName ?? ''}`} {item.isMain === 1 ? '(メイン)' : ''}
              </Typography>
              <Typography className={baseClasses.small}>
                {item.lastWebAccessDate} 閲覧
              </Typography>
            </Grid>
            <Grid className={classes.memberListLittleContent}>
              <Button
                disabled={item.isMain === 1}
                onClick={() => handleOpen(item.memberId)}
              >
                <Typography className={`${baseClasses.smallStrong} ${classes.buttonColor} ${item.isMain === 1 ? classes.buttonDisableColor : ''}`}>
                  削除{item.isMain === 1 ? '(メインは不可)' : ''}
                </Typography>
              </Button>
              <Button
                disabled={item.isMain === 1}
                onClick={() => onMainChange(item.memberId, onClose)}
              >
                <Typography className={`${baseClasses.smallStrong} ${classes.buttonColor} ${item.isMain === 1 ? classes.buttonDisableColor : ''}`}>
                  メインに変更
                </Typography>
              </Button>
              <Button
                href={`${URL_MAPPER.web}?memberId=${item.memberId}`}
                target="_blank"
              >
                <ErrorIcon className={classes.errorButton} />
                <Typography className={`${baseClasses.smallStrong} ${classes.buttonColor}`}>
                  Web閲覧履歴
                </Typography>
                <ArrowForwardIosIcon className={classes.ArrowForwardButton} />
              </Button>
            </Grid>
          </Grid>
        ))}
      </>
    )
  );

  return (
    <>
      {memberList}
      {!isSP && (
        <ConfirmModal
          open={open}
          title="削除確認"
          content="本当に削除してよろしいでしょうか？"
          onCancel={onClose}
          onSubmit={() => onDelete(currentMemberId, onClose)}
        />
      )}
    </>
  );
}
