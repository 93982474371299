import { Button } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import commonStyles from '../../styles';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';

export default function ButtonDownload() {
  const { downloadApi } = useCombinationSettingContext();

  const common = commonStyles();

  return (
    <Button
      className={common.buttonsSecondary}
      onClick={downloadApi}
    >
      <GetAppIcon />ダウンロード
    </Button>
  );
}
