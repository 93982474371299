import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: [],
  divisions: [],
};

// const initialState = {
//   date: [
//     '2022-10-23',
//     '2022-10-24',
//     '2022-10-25',
//     '2022-10-26',
//     '2022-10-27',
//     '2022-10-28',
//     '2022-10-29',
//     '2022-10-30',
//   ],
//   divisions: [
//     {
//       divisionId: 1,
//       divisionName: 'テスト営業部',
//       users: [
//         {
//           userId: 1,
//           actions: [
//             {
//               scheduleId: 272,
//               guidanceId: null,
//               startAt: '2022/10/25 10:30:00',
//               endAt: '2022/10/25 12:00:00',
//               actionScheduleCode: 3,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//           ],
//         },
//         {
//           userId: 15,
//           actions: [
//             {
//               scheduleId: 266,
//               guidanceId: null,
//               startAt: '2022/10/24 12:45:00',
//               endAt: '2022/10/24 14:15:00',
//               actionScheduleCode: 1,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 274,
//               guidanceId: null,
//               startAt: '2022/10/25 10:30:00',
//               endAt: '2022/10/25 12:30:00',
//               actionScheduleCode: 2,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 277,
//               guidanceId: null,
//               startAt: '2022/10/25 18:30:00',
//               endAt: '2022/10/25 19:30:00',
//               actionScheduleCode: 999,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 278,
//               guidanceId: null,
//               startAt: '2022/10/25 19:30:00',
//               endAt: '2022/10/25 20:30:00',
//               actionScheduleCode: 999,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 278,
//               guidanceId: null,
//               startAt: '2022/10/25 20:30:00',
//               endAt: '2022/10/25 21:00:00',
//               actionScheduleCode: 999,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 298,
//               guidanceId: null,
//               startAt: '2022/10/26 13:00:00',
//               endAt: '2022/10/26 14:00:00',
//               actionScheduleCode: 999,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//           ],
//         },
//       ],
//       actions: [
//         {
//           scheduleId: 266,
//           guidanceId: null,
//           startAt: '2022/10/24 10:30:00',
//           endAt: '2022/10/24 11:30:00',
//           actionScheduleCode: 1,
//           guidanceStatusCode: null,
//           isResultRegister: null,
//           guidanceCount: null,
//           isPairCheck: null,
//           customerId: null,
//           customerName: null,
//           siteId: null,
//           memo: 'テストメモ',
//           customerStatusCode: null,
//           userLevel: null,
//         },
//       ],
//     },
//     {
//       divisionId: 2,
//       divisionName: 'グループA_1_1',
//       users: [
//         {
//           userId: 16,
//           actions: [
//             {
//               scheduleId: 267,
//               guidanceId: null,
//               startAt: '2022/10/24 12:00:00',
//               endAt: '2022/10/24 15:00:00',
//               actionScheduleCode: 1,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 268,
//               guidanceId: null,
//               startAt: '2022/10/25 09:15:00',
//               endAt: '2022/10/25 11:45:00',
//               actionScheduleCode: 1,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 269,
//               guidanceId: null,
//               startAt: '2022/10/25 13:00:00',
//               endAt: '2022/10/25 14:00:00',
//               actionScheduleCode: 1,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 270,
//               guidanceId: null,
//               startAt: '2022/10/25 15:45:00',
//               endAt: '2022/10/25 16:45:00',
//               actionScheduleCode: 1,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//             {
//               scheduleId: 271,
//               guidanceId: null,
//               startAt: '2022/10/25 17:00:00',
//               endAt: '2022/10/25 18:00:00',
//               actionScheduleCode: 1,
//               guidanceStatusCode: null,
//               isResultRegister: null,
//               guidanceCount: null,
//               isPairCheck: null,
//               customerId: null,
//               customerName: null,
//               siteId: null,
//               memo: 'テストメモ',
//               customerStatusCode: null,
//               userLevel: null,
//             },
//           ],
//         },
//       ],
//       actions: [],
//     },
//     {
//       divisionId: 3,
//       divisionName: 'グループA_1_1_1',
//       users: [],
//       actions: [],
//     },
//   ],
// };

export const schedulesGetActionWeekSlice = createSlice({
  name: 'schedulesGetActionWeek',
  initialState: { schedulesGetActionWeek: initialState },
  reducers: {
    getSchedulesActionWeekApi: (state, data) => {
      state.schedulesGetActionWeek = data.payload;
    },
  },
});

export const { getSchedulesActionWeekApi } = schedulesGetActionWeekSlice.actions;

export default schedulesGetActionWeekSlice.reducer;
