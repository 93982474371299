import { useDispatch } from 'react-redux';
import sendmailIdListApi from '../../apis/sendmail/sendmailIdListApi';
import AllMailComponent from '../../components/allMail/allMailComponent';
import { mailIdListGetApi } from '../../store/sendmail/sendmailIdListSlice';

export default function AllMailContainer() {
  const dispatch = useDispatch();
  // GET
  const getMailLists = async (offset, limit, obj) => {
    if (obj.customerIds.length !== 0) {
      await sendmailIdListApi(offset, limit, obj)
        .then(async (res) => {
          dispatch(mailIdListGetApi(res.data));
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      dispatch(
        mailIdListGetApi({
          totalCount: 0,
          customerIds: [],
          mails: [],
        }),
      );
    }
  };
  return <AllMailComponent getMailLists={getMailLists} />;
}
