import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import Modal from '../../common/modal';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: '6px 18px 12px 4px',
    background: '#fff',
    border: '1px solid #C8C8C8',
    '& input': {
      color: '#8C8C8C',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: '7px 0',
    },
  },
  inputWrap: {
    position: 'relative',
    width: 232,
    height: 28,
    '& .MuiInput-root': {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
  },
  boxLbl: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 0 5px',
    width: 108,
    textAlign: 'center',
    pointerEvents: 'none',
  },
  btn: {
    margin: '0 auto',
    padding: '8px 22px',
  },
}));

function CsvUpload(props) {
  const { open, onClose, file, setFile, uploadCsv } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [disabled, setDisabled] = useState(true);

  const pathChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    await uploadCsv(true);
    onClose();
  };

  useEffect(() => {
    if (file === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="CSVアップロードウィンドウ"
      buttons={{
        label: 'アップロード',
        className: `${baseClasses.buttonsPrimary} ${classes.btn}`,
        onClick: handleUpload,
        disabled,
      }}
      maxWidth={640}
    >
      <Grid className={classes.box}>
        <Grid className={classes.inputWrap}>
          <Input type="file" onChange={pathChange} />
          <Typography className={classes.boxLbl}> ファイル選択<span style={{ color: '#D83420' }}>＊</span></Typography>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default CsvUpload;
