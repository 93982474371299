import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';

const useStyles = makeStyles({
  titleClass: {
    color: 'black',
  },
});

function OrgSettingTitle(props) {
  const { createMode, setCreateMode } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();

  const handleOpen = () => setCreateMode(!createMode);

  return (
    <>
      <Grid item>
        <Typography
          align="center"
          className={`${baseClasses.title3} ${classes.titleClass}`}
        >
          所属組織・役割
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            handleOpen();
          }}
          className={baseClasses.buttonsPrimary}
          style={{ marginLeft: '22%' }}
        >
          組織・役割追加
        </Button>
      </Grid>
    </>
  );
}

export default OrgSettingTitle;
