import client from '../httpClient';

// user: users/list ユーザー一覧取得
const userListApi = (status) => {
  // const GET_USER_LIST_URL = '/api/v1/users/list';
  const GET_USER_LIST_URL = '/users';
  return client.get(GET_USER_LIST_URL, status);
};

export default userListApi;
