import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesPostGuidanceSlice = createSlice({
  name: 'schedulesPostGuidance',
  initialState: { schedulesPostGuidance: initialState },
  reducers: {
    postGuidanceApi: (state, data) => {
      state.schedulesPostGuidance = data.payload;
    },
  },
});

export const { postGuidanceApi } = schedulesPostGuidanceSlice.actions;

export default schedulesPostGuidanceSlice.reducer;
