/**
 * 新規反響登録の初期化情報
 */
export default {
  // ResponseRegisterName
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  nationality: '',
  isPermanentResidency: null,
  genderCode: null,
  age: '',
  birthday: '',
  subLastName: '',
  subFirstName: '',
  subLastNameKana: '',
  subFirstNameKana: '',
  subNationality: '',
  isSubPermanentResidency: null,
  subGenderCode: null,
  subAge: '',
  subBirthday: '',
  relation: '',
  // ResponseRegisterContact
  phoneNumber1: '',
  phoneNumber2: '',
  phoneNumber3: '',
  phoneNumber4: '',
  phoneNumberMemo1: '',
  phoneNumberMemo2: '',
  phoneNumberMemo3: '',
  phoneNumberMemo4: '',
  mail1: '',
  mail2: '',
  mail3: '',
  mail4: '',
  mailMemo1: '',
  mailMemo2: '',
  mailMemo3: '',
  mailMemo4: '',
  isMailSend1: 0,
  isMailSend2: 0,
  isMailSend3: 0,
  isMailSend4: 0,
  fax: '',
  holidayWeekday: '',
  otherHolidayWeekday: '',
  convenientTime: '',
  otherConvenientTime: '',
  // ResponseRegisterAddress
  zip: '',
  prefecture: '',
  address1: '',
  address2: '',
  building: '',
  // ResponseRegisterFamily
  adultCount: '',
  childCount: '',
  childAge: undefined,
  otherChildAge: undefined,
  familyTotalCount: '',
  familyWho: '',
  // ResponseRegisterWorkPlace
  company: '',
  companyRailwayId: undefined,
  companyStationId: undefined,
  companyWorkingYear: undefined,
  companyAddress: '',
  workingStyleCode: 0,
  jobType: '',
  subCompany: '',
  subCompanyRailwayId: undefined,
  subCompanyStationId: undefined,
  subCompanyWorkingYear: undefined,
  subCompanyAddress: '',
  subWorkingStyleCode: undefined,
  subJobType: '',
  // ResponseRegisterBudget
  annualIncome: '0',
  subAnnualIncome: '0',
  deposit: '0',
  assistAmount: '0',
  monthlyPayment: '0',
  bonusPayment: '0',
  abodeNowRent: '0',
  // ResponseRegisterHope
  isThinkSale: 0,
  isThinkApartment: 0,
  isThinkWholeHouseInvestment: 0,
  isThinkDivisionInvestment: 0,
  isThinkRebuilding: 0,
  wishArea: '',
  wishRailwayId1: undefined,
  wishStationIdFrom1: undefined,
  wishStationIdTo1: undefined,
  wishRailwayId2: undefined,
  wishStationIdFrom2: undefined,
  wishStationIdTo2: undefined,
  wishSchoolDistrict1: '',
  wishSchoolDistrict2: '',
  detachedHouseBudgetMin: 0,
  detachedHouseBudgetMax: 0,
  landBudgetMin: 0,
  landBudgetMax: 0,
  livingSpace: '0',
  floorPlanCode: undefined,
  wishLandArea: '0',
  importantPoint: '',
  reason: '',
  wishMoveInDay: '',
  purchasePurpose: '',
  checkedProductType: '',
  checkedProductCount: '',
  summary: '',
  memo: '',
  // ResponseRegisterPresentCondition
  abodeNowBuildingType: '',
  abodeNowFloorPlanCode: undefined,
  abodeNowLivingSpace: '0',
  abodeNowPurchaseAt: '',
  abodeNowPriceAtTime: '0',
  abodeNowResidencePeriod: '0',
  abodeNowSalePrice: '0',
  abodeNowReplacementCode: undefined,
  abodeNowReplaceType: '',
  abodeNowCurrentMaturity: '0',
  abodeNowBankName: '',
  abodeNowBorrowingAmount: '0',
  abodeNowCarType: '',
  abodeNowCarCount: '',
  isCarPark: 0,
  // ResponseRegisterResponse 反響周りのstate管理できてないかもかも?
  effectAt: '',
  largeResponseTypeId: '',
  mediumResponseTypeId: undefined,
  smallResponseTypeId: undefined,
  fineResponseTypeId: undefined,
  userId: null,
  divisionId: null,
  webs: [],
  inquiryProducts: [],
  acquisitionUserId: undefined,
  acquisitionCode: undefined,
  acquisitionMemo: null,
  // ResponseRegisterInHouse
  customerStatusCode: 1,
  telStatusCode: 1,
  emailStatusCode: 1,
  leaderMemo: '',
  userLevel: 0,
  noContactDetailReasonCode: undefined,
  reasonNotBuy: '',
  reasonNotSearch: '',
  searchArea: '',
  reasonNotOhd: '',
  searchStartAt: '',
  isCustomerIntroduction: 0, // 顧客紹介対象
  // 以下、UIが存在しない項目
  isNoCustomer: 0,
  isAllowDm: 0,
  isPrivacyAgree: 0,
  responseClass: 0,
};
