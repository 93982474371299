import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesGetGuidancesSlice = createSlice({
  name: 'schedulesGetGuidances',
  initialState: { schedulesGetGuidances: initialState },
  reducers: {
    schedulesGetGuidancesApi: (state, data) => {
      state.schedulesGetGuidances = data.payload;
    },
  },
});

export const { schedulesGetGuidancesApi } = schedulesGetGuidancesSlice.actions;

export default schedulesGetGuidancesSlice.reducer;
