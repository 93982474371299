import { useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import TreeWrapper from './treeWrapper';

const useStyles = makeStyles({
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '24px 24px 8px 24px',
      backgroundColor: '#fff',
      '& > p': {
        textAlign: 'center',
      },
    },
  },
  body: {
    [commonTheme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      padding: 0,
      height: '100%',
    },
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
  },
});

/**
 * divisionId対する組織名を取得する
 * @param {Array} userDiv
 * @param {number} divisionId
 * @returns string | null
 */
function findDivisionName(userDiv, divisionId) {
  if (Array.isArray(userDiv) && userDiv.length && divisionId) {
    const len = userDiv.length;
    let item;
    let tempName;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      item = userDiv[i];
      if (item.divisionId === divisionId) return item.subName;
      tempName = findDivisionName(item.children, divisionId);
      if (tempName) return tempName;
    }
  }
  return null;
}

// 選択ボタンで確定するコンポーネント
export default function TreeWindow(props) {
  const {
    label, // タイトルラベル/必須
    onClose, // 選択ボタンでモーダルを閉じるため/必須
    users = false, // 一人分のオブジェクトを返す場合
    setUsers = false, // 一人分のオブジェクトを返す場合
    selectUser = false, // 複数のオブジェクトを返す場合
    setSelectUser = false, // 複数のオブジェクトを返す場合
    divisionSelect = false, // 組織のセレクトボックスあり→APIが[/division/usertree]になる
    searchForm = false, // 検索ボックスあり
    customerTree = false, // センターやランクのセレクトボックスあり→APIが[/division/customertree]になる
    divisionPickable = false, // 組織名をクリックして値として受け取りたい場合は親要素でtrueにする
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const userDiv = useSelector((state) => state.userDivList.userDivList);

  const [result, setResult] = useState({
    single: users,
    multi: selectUser,
  });
  // TODO: リセット処理
  const onClickReset = () => {
    if (setUsers) setResult({ ...result, single: '' });
    if (setSelectUser) setResult({ ...result, multi: [] });
  };
  const onClickSelect = () => {
    if (setUsers) {
      const userDivisionName = findDivisionName(userDiv, result.single.userDivisionId);
      setUsers({ ...result.single, userDivisionName });
    }
    if (setSelectUser) setSelectUser(result.multi);
    onClose();
  };

  const setSingle = (e) => {
    setResult({ ...result, single: e });
  };
  const setMulti = (e) => {
    setResult({ ...result, multi: e });
  };

  const clickClose = () => {
    onClickSelect();
    onClose();
  };

  return (
    <>
      <Grid
        className={`${common.header} ${classes.header}`}
        id={!isSp && 'draggable-dialog-title'}
      >
        <Typography className={`${isSp ? common.title2 : common.title3}`}>{label}</Typography>
        {isSp && (
          <Button
            className={common.closeButton}
            onClick={clickClose}
          />
        )}
      </Grid>
      <Grid className={`${common.body} ${classes.body}`}>
        <TreeWrapper
          users={result.single}
          setUsers={!setUsers ? false : setSingle}
          selectUser={result.multi}
          setSelectUser={!setSelectUser ? false : setMulti}
          divisionSelect={divisionSelect}
          searchForm={searchForm}
          customerTree={customerTree}
          divisionPickable={divisionPickable}
        />
      </Grid>
      <Grid className={`${common.footer} ${classes.footer}`}>
        {isSp ? (
          <Grid item className={classes.btn}>
            {setUsers ? (
              <>
                <Button
                  className={common.buttonsSecondary}
                  onClick={onClickReset}
                  type="reset"
                >
                  選択解除
                </Button>
                <Button
                  className={common.buttonsPrimary}
                  onClick={onClickSelect}
                >
                  OK
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={common.buttonsSecondary}
                  onClick={onClickReset}
                  type="reset"
                >
                  全クリア
                </Button>
                <Button
                  className={common.buttonsPrimary}
                  onClick={onClickSelect}
                >
                  OK
                </Button>
              </>
            )}
          </Grid>
        ) : (
          <Button
            className={common.buttonsPrimary}
            onClick={onClickSelect}
          >
            選択
          </Button>
        )}
      </Grid>
    </>
  );
}
