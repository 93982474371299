import { useState } from 'react';
import MailTitleAndBody from './parts/titleAndBody';
import Modal from '../common/modal';

function EditorTest() {
  const [open, setOpen] = useState(false);
  const handleClickBtn = () => {
    setOpen(true);
  };
  return (
    <div>
      <MailTitleAndBody />
      <button type="button" onClick={handleClickBtn}>modal</button>
      <Modal
        open={open}
        onClose={() => { setOpen(false); }}
      >
        <MailTitleAndBody />
      </Modal>
    </div>
  );
}

export default EditorTest;
