// TODO: getListFromAllCustomer.jsと統合

/**
 * 選択された組織/ユーザー配下の全ての顧客IDを取得
 * @param {*} targetObj 反響ツリーで選択された情報
 * @returns {{customerId: number; customerName: string;}[]}
 */
const getDataFromAllCustomer = (targetObj) => {
  const customerList = [];
  // customerListへPush -- 共通関数
  const pushCustomerId = (target, lastName, firstName) => {
    const customer = { ...target };
    // 重複チェック
    if (customerList.some(item => item.customerId === customer?.customerId)) return;
    customer.customerName = lastName + ' ' + firstName;
    customerList.push(customer);
  };
  const getCustomerIds = (target) => {
    // // TODO: 以下、エラーが出たのでコメントアウト(不要の認識)
    // // if ('customerId' in target) {
    // //   pushCustomerId(target.customerId, target.lastName, target.firstName);
    // // } else
    // if (Array.isArray(target)) {
    //   for (let i = 0; target.length > i; i += 1) {
    //     if ('customerId' in target[i]) {
    //       pushCustomerId(
    //         target[i].customerId,
    //         target[i].lastName,
    //         target[i].firstName,
    //       );
    //     } else if ('customers' in target[i]) {
    //       getCustomerIds(target[i].customers);
    //     }
    //   }
    // } else if (target instanceof Object) {
    //   if ('customerId' in target) {
    //     pushCustomerId(target.customerId, target.lastName, target.firstName);
    //   } else {
    //     for (const key in target) {
    //       if (target[key] instanceof Object) {
    //         getCustomerIds(target[key]);
    //       } else if (key === 'customerId') {
    //         pushCustomerId(target[key], target.lastName, target.firstName);
    //         break;
    //       }
    //     }
    //   }
    // }
    // 下記チケット対応でこの関数をオーバーライドした
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2583#comment-164583766
    if (Array.isArray(target)) {
      target.forEach(item => getCustomerIds(item));
    } else if (typeof target === 'object' && target !== null) {
      if (target.customerId) {
        pushCustomerId(target, target.lastName, target.firstName);
      } else {
        Object.keys(target).forEach(key => getCustomerIds(target[key]));
      }
    }
  };
  getCustomerIds(targetObj);
  return customerList;
};

export default getDataFromAllCustomer;

/**
 * 選択された組織/ユーザー情報を取得
 * @typedef {{
 *   userId: number;
 * }} CustomerTreeItem
 * @param {CustomerTreeItem} list CustomerTreeItem[]
 * @param {number} userId number
 * @param {number} divisionId number
 * @returns {null | CustomerTreeItem}
 */
export const getUserFromCustomerTree = (list, userId, divisionId) => {
  if (!list?.length || !userId) return null;
  let item;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < list.length; i++) {
    item = list[i];
    if (item.userId === userId && item.divisionId === divisionId) {
      return item;
    } else {
      const res = getUserFromCustomerTree(item.users, userId, divisionId)
        || getUserFromCustomerTree(item.children, userId, divisionId);
      if (res) return res;
    }
  }
  return null;
};

/**
 * 選択された組織の下のユーザー情報を取得(Divisionのみ版)
 * @typedef {{
*   userId: number;
* }} CustomerTreeItem
* @param {CustomerTreeItem} list CustomerTreeItem[]
* @param {number} divisionId number
* @returns {null | CustomerTreeItem}
*/
export const getUserFromCustomerTreeDivision = (list, divisionId) => {
  if (!list?.length) return null;
  let item;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < list.length; i++) {
    item = list[i];
    if (item.divisionId === divisionId) {
      return item;
    } else {
      const res = getUserFromCustomerTreeDivision(item.users, divisionId)
        || getUserFromCustomerTreeDivision(item.children, divisionId);
      if (res) return res;
    }
  }
  return null;
};
