import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import { useBrowser } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '10px 8px 9px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '6px 12.5px 6px 22px',
      background: '#fff',
      width: '100%',
      marginTop: 8,
    },
  },
  ttl: {
    [commonTheme.breakpoints.up('md')]: {
      marginRight: 8,
      width: 120,
      '& span': {
        color: '#D83420',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 2,
      color: theme.palette.primaryColor,
    },
  },
  list: {
    padding: 0,
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  item: {
    padding: 0,
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    [commonTheme.breakpoints.up('md')]: {
      fontFamily: 'Roboto',
      fontSize: 10,
      color: theme.palette.primaryColor,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 16,
      flexShrink: 0,
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '16px',
      borderBottom: '1px solid #F3F3F3',
      '& .MuiButton-label': {
        justifyContent: 'flex-start',
      },
    },
  },
  icon: {
    fill: theme.palette.primaryColor,
  },
}));

function ClaimRegisterText(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  let parts;

  // モーダル用
  const [open, setOpen] = useState(false);
  const { isSP } = useBrowser();

  const menu = [
    {
      id: 0,
      label: '電話のかけ過ぎ',
      setStates: [
        '電話の掛け過ぎ',
        '電話禁止として処理する。',
        '二度とこちらから電話しないことでご納得いただいた。',
      ],
    },
    {
      id: 1,
      label: '夜間の電話',
      setStates: [
        '夜間の電話の掛け過ぎ',
        '電話禁止として処理する。',
        '二度とこちらから電話しないことでご納得いただいた。',
      ],
    },
  ];

  const list = (
    <List className={classes.list}>
      {menu.map((data) => (
        <ListItem
          key={data.id}
          className={`${baseClasses.title4} ${classes.item}`}
        >
          <Button
            className={classes.btn}
            onClick={() => {
              values.setState(data.setStates);

              if (isSP) {
                setOpen(!open);
              }
            }}
          >
            {data.label}
          </Button>
        </ListItem>
      ))}
    </List>
  );

  if (isSP) {
    parts = (
      <>
        <Button onClick={() => { setOpen(!open); }} className={classes.root}>
          <Typography className={`${baseClasses.strong} ${classes.ttl}`}>
            {values.label}
          </Typography>
          <MoreHorizOutlinedIcon className={classes.icon} />
        </Button>
        {/* 共通パーツ：カスタムDrawer */}
        <CustomSimpleDrawer
          open={open}
          setOpen={setOpen}
          title="短縮入力"
          list={list}
        />
      </>
    );
  } else {
    parts = (
      <Grid className={classes.root}>
        <Typography className={`${baseClasses.title5} ${classes.ttl}`}>
          {values.label}
        </Typography>
        {list}
      </Grid>
    );
  }

  return (
    <Grid>
      {parts}
    </Grid>
  );
}

export default ClaimRegisterText;
