import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import OrgSettingTitle from './parts/orgSettingTitle';
import OrgSettingRow from './parts/orgSettingRow';
import OrgSettingCreate from './parts/orgSettingCreate';
import CommonDialog from '../../common/modal';
import { reloadTree } from '../../../store/user/userDivListSlice';
import { useLoading } from '../../../hooks';
import { USER_ORG_SETTING } from '../../../constants/loading';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px 24px 8px',
    position: 'static',
    width: '1024px',
    height: '52px',
    left: '0px',
    top: '0px',
    borderBottom: '1px solid #C8C8C8',
    '& h2': {
      fontWeight: 'bold',
    },
  },
  contentElement: {
    width: '100%',
    position: 'static',
    height: '100%',
    left: '0px',
    top: '53px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    '&>*': {
      boxSizing: 'border-box',
      width: '100%',
    },
  },
  customTitle3: {
    boxSizing: 'border-box',
    width: '100%',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
  orgTitleClass: {
    padding: '10px 5px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1%',
  },
  dialogMainPaper: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        height: '80%',
      },
    },
  },
}));

function OrgSetting(props) {
  const {
    state,
    updateUserFunction,
    deleteUserDivision,
    updateDivisionFunction,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = React.useState(false);
  const [createMode, setCreateMode] = React.useState(false);
  const dispatch = useDispatch();
  const { hasLoading } = useLoading();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCreateMode(false);
  };

  const reloadUserTree = () => {
    dispatch(reloadTree());
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={`${baseClasses.buttonsPrimary} ${classes.userCustomButton} ${baseClasses.title4}`}
        disableElevation
      >
        組織設定
      </Button>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title="所属組織・役割の変更ウィンドウ"
        width={960}
        bodyClassName={hasLoading(USER_ORG_SETTING) && baseClasses.loading}
      >
        <Grid container className={classes.orgTitleClass}>
          <OrgSettingTitle setCreateMode={setCreateMode} />
        </Grid>
        <Grid container>
          <OrgSettingRow
            userState={state}
            createMode={createMode}
            setCreateMode={setCreateMode}
            updateUserFunction={updateUserFunction}
            deleteUserDivision={deleteUserDivision}
            reloadUserTree={reloadUserTree}
            updateDivisionFunction={updateDivisionFunction}
          />
          {createMode ? (
            <OrgSettingCreate
              userState={state}
              setCreateMode={setCreateMode}
              setOpen={setOpen}
              updateUserFunction={updateUserFunction}
              reloadUserTree={reloadUserTree}
            />
          ) : null}
        </Grid>
      </CommonDialog>
    </>
  );
}

export default OrgSetting;
