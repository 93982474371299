import { ListItem, MenuItem, Select } from '@material-ui/core';

export default function InstructionsWindowSelect(props) {
  const {
    title,
    values,
    unselect = null,
  } = props;
  return (
    <ListItem
      label={title}
    >
      <Select
        onChange={(e) => { return values.setState(e.target.value); }}
        value={values.state}
      >
        {unselect !== null && (
          <MenuItem
            value={0}
            disabled
          >
            {unselect}
          </MenuItem>
        )}
        {values.list.map(i => {
          return (
            <MenuItem key={`${title}${i}`} value={Number(i.val)}>
              {i.label}
            </MenuItem>
          );
        })}
      </Select>
    </ListItem>
  );
}
