import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../styles';
import UserTree from '../../common/userTreeNew';
import { useStore } from '../../../hooks';
import NormalTooltip from '../../styles/tooltip';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
    },
    '& .MuiSelect-icon': {
      width: '17px',
    },
  },
  wrap: {
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
  },
  itemWrap: {
    border: '1px #c8c8c8 solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 8px',
    marginBottom: '8px',
    flexWrap: 'nowrap',
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
  disabled: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  labelWrap: {
    flexShrink: 0,
    marginRight: 8,
  },
  valueWrap: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
  },
}));

function AddressChangeBtn(props) {
  const { values } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [open, setOpen] = useState(false);
  const [selectUsers, setSelectUsers] = useState(values?.state);

  const { responseHeader } = useStore();

  // 開閉時にselectedUserを追加
  const setSelectUser = () => {
    if (values?.state.length) return;
    setSelectUsers([
      {
        userId: responseHeader.userId,
        userName: responseHeader.userName,
      },
    ]);
  };

  const handleClick = () => {
    setSelectUser();
    setOpen(!open);
  };

  const handleSelectFunction = () => values.setState(selectUsers);
  const handleClearFunction = () => values.setState([]);

  const formatValue = () => {
    return values.state.map((e, i) => {
      let result = e.userName;
      if (values.state.length - 1 > i) { result += ', '; }
      return result;
    });
  };

  return (
    <Grid container className={`${classes.itemWrap} ${values.disabled ? classes.disabled : ''}`} style={values.style}>
      <Typography className={`${common.title4} ${classes.labelWrap}`}>
        {values.label}
      </Typography>
      <NormalTooltip title={formatValue()}>
        <Typography className={`${common.title4} ${classes.valueWrap}`}>{formatValue()}</Typography>
      </NormalTooltip>
      <Button
        onClick={handleClick}
        type="button"
        disabled={values.disabled}
      >
        <Typography className={`${common.strong} ${classes.blue}`}>変更</Typography>
      </Button>
      <UserTree
        isOpenTree={open}
        setIsOpenTree={setOpen}
        selectUser={selectUsers}
        setSelectUser={setSelectUsers}
        changeTitle={values.label}
        handleSelectFunction={handleSelectFunction}
        handleClearFunction={handleClearFunction}
        isDialog
        isMulti
        isSelectUserWindow
      />
    </Grid>
  );
}

export default AddressChangeBtn;
