import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import OrgSettingTargetUser from './orgSettingTargetUser';

const useStyles = makeStyles((theme) => ({
  orgGridRow: {
    border: '1px solid #C8C8C8',
    marginTop: '1%',
    padding: '0px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  isMainClass: {
    backgroundColor: '#F3F3F3',
    width: '100%',
    padding: '0px 5px',
    border: '1px solid #C8C8C8',
  },
  optionGrid: {
    marginTop: '1%',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  gridMarginR: {
    marginRight: '1%',
  },
  gridPaper: {
    width: '50%',
    backgroundColor: 'white',
    border: '1px solid #C8C8C8',
    padding: '5px',
  },
  gridPaperTitle: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  formControlClass: {
    '& > span': {
      fontSize: '13px',
      padding: '0px',
    },
  },
  textButton: {
    textAlign: 'right',
    color: theme.palette.primaryColor,
    fontSize: '13px',
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
}));

function OrgSettingDelete(props) {
  const { localUserState, setLocalUserState, division } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  // {1: 共有の解除, 2: 共有の継続, 3: 持っていく, 4: 置いていく}
  const radioHeader = [
    { title: '共有された顧客', type: '1' },
    { title: 'あなたから共有した顧客', type: '2' },
  ];
  const radioKokyaku = [
    { title: '共有の解除', state: '1', ml: '13px' },
    { title: '共有の継続', state: '2', ml: '0px' },
  ];
  const radioTitle = ['戸建'];

  const handleOpen = (w) => {
    const typeFlg = w?.type === '1';
    setType(typeFlg);
    setOpen(true);
    console.log(division);
  };

  useEffect(() => {
    const copyUserPrf = Object.assign({}, localUserState);
    copyUserPrf.isSendShare = 1;
    copyUserPrf.isReceiveShare = 1;
    setLocalUserState(copyUserPrf);
  }, []);

  // 共有被共有顧客
  const changeKokyaku = (e, w) => {
    const selectedValue = e.target.value === '2' ? 0 : Number(e.target.value); // '2'
    const selectType = w.type;
    const copyUserPrf = Object.assign({}, localUserState);
    if (selectType === '1') {
      copyUserPrf.isReceiveShare = selectedValue;
      setLocalUserState(copyUserPrf);
    } else if (selectType === '2') {
      copyUserPrf.isSendShare = selectedValue;
      setLocalUserState(copyUserPrf);
    }
  };

  // ラジオボタン
  const radioBoxComponent = (w) => {
    return (
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => changeKokyaku(e, w)}
          defaultValue="1"
        >
          {radioKokyaku.map((data) => (
            <FormControlLabel
              key={data.state}
              value={data.state}
              control={<Radio size="small" color="primary" />}
              label={data.title}
              className={classes.formControlClass}
              style={{ marginLeft: data.ml }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  // 共有された顧客とあなたから共有した顧客
  const radioRowComponent = (radioValue) => {
    console.log('test');
    return (
      <Grid container className={classes.optionGrid} key={radioValue.title}>
        <Grid item style={{ width: '18%' }}>
          <Typography className={baseClasses.title4}>{radioValue.title}</Typography>
        </Grid>
        {radioTitle.map((data) => (
          <Grid
            item
            className={`${classes.gridPaper} ${classes.gridMarginR}`}
            key={data}
          >
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item>
                <Typography className={baseClasses.title6}>{data}</Typography>
              </Grid>
              <Grid item>{radioBoxComponent(radioValue)}</Grid>
              <Grid item>
                <Button
                  className={classes.textButton}
                  onClick={() => handleOpen(radioValue)}
                >
                  対象顧客
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Grid className={classes.isMainClass}>
      {radioHeader.map((data) => radioRowComponent(data))}
      {open ? (
        <OrgSettingTargetUser
          open={open}
          setOpen={setOpen}
          type={type}
          divisionId={division.divisionId}
          user={localUserState}
        />
      ) : null}
    </Grid>
  );
}

export default OrgSettingDelete;
