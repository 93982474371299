import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import commonStyles from '../../../styles';
import { NO_CONTACT_DETAIL_REASON_CODE } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  ttl: {
    '& span': {
      color: '#D83420',
    },
  },
  box: {
    '& label': {
      width: 469,
      margin: '0 0 8px',
      '& .MuiTypography-root': {
        fontFamily: 'Roboto',
        fontSize: 13,
        fontWeight: '700',
        lineHeight: 1.6,
        letterSpacing: 0,
      },
      '& .MuiButtonBase-root': {
        padding: 4,
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
}));

export default function DoNotChaseReasonRadio(props) {
  const {
    value,
    setValue,
    setState,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const menus = Object.keys(NO_CONTACT_DETAIL_REASON_CODE);

  const handleChange = (e) => {
    setValue(e.target.value);
    console.log(e.target.value);
  };

  return (
    <Grid className={classes.root}>
      <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
        追わない理由<span>＊</span>
      </Typography>
      <Grid className={classes.box}>
        <RadioGroup
          name="vals"
          value={value}
          onChange={handleChange}
          onBlur={(e) => { return setState(e.target.value); }}
        >
          {menus.map((data) => (
            <FormControlLabel
              key={data}
              value={data}
              control={<Radio />}
              label={NO_CONTACT_DETAIL_REASON_CODE[data]}
            />
          ))}
        </RadioGroup>
        <Typography className={classes.txt}>
          ※ 連絡先（電話・メール）が異なる場合は「連絡不可・カマシ」を選択してください。
        </Typography>
      </Grid>
    </Grid>
  );
}
