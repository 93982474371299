import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerCreateSlice = createSlice({
  name: 'customerCreate',
  initialState: { customerCreate: initialState },
  reducers: {
    customerCreateApi: (state, data) => {
      state.customerCreate = data.payload;
    },
  },
});

export const { customerCreateApi } = customerCreateSlice.actions;

export default customerCreateSlice.reducer;
