import client from '../httpClient';
/**
 * @param {{customerIds:number[]}} params,
 * @return {Promise<{ data:{
 * customers:{
 * annualIncome:string,
 * customerFirstName:string,
 * customerId:number,
 * customerLastName:string,
 * detachedHouseBudgetMax:number,
 * divisionId:number,
 * landBudgetMax:number,
 * memberStatusCode:number,
 * monthlyPayment:string,
 * ownFunds:number,
 * responseAt:string,
 * userFirstName:string,
 * userId:number,
 * userLastName:string,
 * userLevel:number,
 * webMemberId:number,
 * wishArea:string ,
 * }[]
 * description:string,
 * message:string,
 * } }>}
 */
const customerPostIdListApi = async (params) => {
  return client.post('/customers/id-list', JSON.stringify(params), { isShowLoading: false });
};

export default customerPostIdListApi;
