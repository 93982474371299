import axios from 'axios';
import { dateFormatter } from './format';

/**
 * Base64をBlobにする
 * @param {string} base64
 * @param {string} mimeType
 * @returns Blob | null
 */
export function base64ToBlob(base64, mimeType) {
  if (!mimeType || !base64) return null;
  const data = window.atob(base64);
  const ia = new Uint8Array(data.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    ia[i] = data.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeType });
}
/**
 * Blobをbase64に変更する
 * @param {blob} Blob
 * @returns Promise
 */
export function getBase64(fileObj) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileObj);
    reader.onload = () => resolve({ base64: reader.result, fileObj });
    reader.onerror = (error) => reject(error);
  });
}

/**
 * BlobをFileに変換
 * @param {blob} Blob
 * @param {string} fileName
 * @returns File
 */
export function blobToFile(blob, fileName) {
  return new File([blob], fileName, { type: 'application/octet-stream' });
}

/**
 * BlobのURLを作成
 * @param {Blob} blob
 * @returns string
 */
export function createBlobURL(blob) {
  if (!blob) return '';
  const windowURL = window.URL || window.webkitURL;
  return windowURL.createObjectURL(blob);
}
/**
 * BlobのURLからダウンロードする処理
 * @param {string} BlobのURL
 * @param {string} fileName
 */
export function downloadFile(url, fileName) {
  const downloadEle = document.createElement('a');
  downloadEle.href = url;
  downloadEle.download = fileName;
  downloadEle.click();
  // createObjectURLで作成したオブジェクトURLを開放する
  (window.URL || window.webkitURL).revokeObjectURL(url);
}

/**
 * 署名付きURLからローカルにダウンロードする処理
 * @param {string} signedUrl 署名つきURL
 * @param {string} fileName ファイル名
 */
export const downloadFileFromSignedUrl = async (signedUrl, fileName) => {
  const res = await axios({ url: signedUrl, method: 'GET', responseType: 'blob' });
  if (res.status !== 200) return;
  const url = createBlobURL(new Blob([res.data]));
  downloadFile(url, fileName);
};

/**
 * ファイルの名と拡張子を取得する
 * @param {File | string} file
 * @returns string[] | null[]
 */
export function formatFileName(file) {
  if (file instanceof File) {
    file = file.name;
  }
  if (typeof file === 'string') {
    const arr = file.split('.');
    const ext = arr.length > 1 ? arr.pop() : '';
    return [arr.join('.'), ext];
  }
  return [null, null];
}

/**
 * zipファイルをダウンロードする
 * @param {import('axios').AxiosResponse} res APIレスポンス
 * @param {string} name ファイル名
 */
export const downloadZip = (res, name) => {
  const blob = new Blob([res.data], { type: 'application/zip' });
  const fileName = name ?? `${dateFormatter(
    new Date(),
    'YYYYMMDD',
  )}_mail_attachment_download.zip`;
  const url = createBlobURL(blob);
  downloadFile(url, fileName);
};
