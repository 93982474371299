import { useRef } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px',
    marginBottom: '8px',
    [commonTheme.breakpoints.up('md')]: {
      background: '#fff',
      border: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
    },
  },
  textField: {
    flexGrow: 1,
    '& > div::before': {
      borderBottom: '2px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      [commonTheme.breakpoints.down('sm')]: { textAlign: 'right' },
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      padding: '0px 8px',
      [commonTheme.breakpoints.down('sm')]: { textAlign: 'right' },
    },
    '& > div': {
      margin: 0,
    },
  },
}));

function TextInput(props) {
  const { defaultText, onBlur, label, isDisabled } = props;

  const inputRef = useRef(null);
  const onBlurFunc = () => onBlur(inputRef.current?.value);

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.container}>
      <Grid style={{ width: '30%' }}>
        <Typography className={baseClasses.title4}>
          {label}<span style={{ color: '#D83420' }}>＊</span>
        </Typography>
      </Grid>
      <Grid style={{ width: '70%' }}>
        <TextField
          className={classes.textField}
          defaultValue={defaultText}
          inputRef={inputRef}
          onBlur={onBlurFunc}
          disabled={isDisabled}
          multiline
          fullWidth
          placeholder="-"
        />
      </Grid>
    </Grid>
  );
}

export default TextInput;
