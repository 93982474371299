import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import commonStyles from '../../../styles';
import ResDateField from './parts/resDateField';
import ResHouseField from './parts/resHouseField';
import ResSiteIdField from './parts/resSiteIdField';
import ResSiteNumberProductNameSearch from './parts/resSiteNumberProductNameSearch';
import Modal from '../../modal';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  header: {
    padding: '24px 24px 8px',
  },
  container: {
    width: '640px',
  },
  content: {
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    backgroundColor: '#F3F3F3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px',
  },
  content2: {
    padding: '16px',
  },
  resisterButton: {
    width: '74px',
    height: '40px',
    margin: '0 auto',
  },
  wrap: {
    background: '#fff',
    border: '1px #c8c8c8 solid',
    paddingRight: 8,
  },
});

// 日時をresHouse002のバリデーションの形式に合わせる（Y/m/d H:i:s）
const dateCreate = (value) => {
  const v = new Date(value);
  const year = v.getFullYear();
  const month = ('00' + (v.getMonth() + 1)).slice(-2);
  const day = ('00' + v.getDate()).slice(-2);
  const newDate = year + '/' + month + '/' + day + ' 00:00:00';
  return newDate;
};

function ResisterHouseWindow(props) {
  const {
    open,
    setOpen,
    onClose,
    registerNewProdAndUpdateHouseList,
    customerId,
    fromCrm, // CRM画面経由であるかどうか
    completeSelectProductWindow,
  } = props;
  // 問い合わせ日
  const [date, setDate] = useState(null);
  // 物件情報
  const [house, setHouse] = useState(null);

  const addFlag = 1;

  const classes = useStyles();
  const baseClasses = commonStyles();

  /**
   * 登録する物件の情報をオブジェクトの形式で返却する
   * @returns 登録する物件情報のオブジェクト
   */
  const settingNewProductParam = () => {
    // customerId(顧客ID), inquiredAt(問い合わせ日)はデフォルト
    const newProductParam = {
      customerId,
      inquiredAt: dateCreate(date),
    };
    // 物件選択ウィンドウから物件を選択した場合
    if (house && house.siteId) {
      newProductParam.siteId = house.siteId;
    }
    if (house && house.productName) {
      newProductParam.productName = house.productName;
    }
    // CRM経由の場合は表に現場番号を出す必要あり
    if (house && house.siteNumber) {
      newProductParam.siteNumber = house.siteNumber;
    }
    return newProductParam;
  };

  /**
   * 顧客メイン 問い合わせ物件ペインから物件を登録する際の処理
   */
  const handleRegist = () => {
    // パラメータをセット
    const newProductParam = settingNewProductParam();
    // APIを実行
    registerNewProdAndUpdateHouseList(newProductParam);
    // ウィンドウを閉じる処理
    setDate(null);
    setHouse(null);
    setOpen(false);
  };

  /**
   * 枠外押下によりウィンドウを閉じる際のstate初期化処理
   */
  const runCloseBg = () => {
    setDate(null);
    setHouse(null);
  };

  /**
   * 登録・選択ボタンの活性・非活性を判定
   * @returns true(非活性) or false(活性)
   */
  const isButtonDisabled = () => {
    if (!date) {
      return true;
    }
    if (!house) {
      return true;
    }
    if (house && !house.productName) {
      return true;
    }
    if (house && !house.siteId) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        runCloseBg();
      }}
      className={classes.dialog}
      title="問合せ物件登録ウィンドウ"
      width={640}
      buttons={{
        label: fromCrm ? '選択' : '登録', // CRM経由ならば「選択」、顧客メイン経由ならば「登録」
        onClick: fromCrm
          ? () => { // CRM経由ならば、表に物件情報を表示する処理(保存は他項目と一緒に実行される)
            completeSelectProductWindow(settingNewProductParam());
            setDate(null);
            setHouse(null);
            setOpen(false);
          }
          : () => handleRegist(), // 顧客メイン経由ならば、保存処理
        disabled: isButtonDisabled(),
        className: `${baseClasses.buttonsPrimary} ${classes.resisterButton}`,
      }}
    >
      <ResDateField
        date={date}
        setDate={setDate}
        addFlag={addFlag}
      />
      <Grid
        className={classes.wrap}
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <ResSiteIdField
            house={house}
          />
          <ResHouseField
            house={house}
          />
        </Grid>
        <ResSiteNumberProductNameSearch
          house={house}
          setHouse={setHouse}
        />
      </Grid>
    </Modal>
  );
}

export default ResisterHouseWindow;
