import { useSelector } from 'react-redux';
import { FormControl, Grid, InputLabel } from '@material-ui/core';
import CustomSelect from '../../eleCommon/customSelect';
import { USERS_TREE } from '../../../constants/customerTree';
import { COLOR_CODE } from '../../../constants';

export default function TreeSelectDivision(props) {
  const { setList } = props;
  const themeColor = useSelector((state) => state.colorTheme.color);

  const getTreeSelectHeader = () => {
    const data = [];
    USERS_TREE.forEach((selectObj) => {
      data.push({
        key: 'selectHeader',
        obj: selectObj,
        set: setList,
        defaultValue: Object.keys(selectObj.menus)[0],
        margin: '0px',
        fontColor: COLOR_CODE[themeColor],
        fontBold: 'bold',
      });
    });
    const widthStyle = data.length === 1 ? '100%' : '50%';
    return data.map((d) => (
      <Grid
        item
        style={{ width: widthStyle, border: '1px solid #C8C8C8' }}
        key={d.key}
      >
        <FormControl style={{ width: '100%' }}>
          <InputLabel>{d.obj.title}</InputLabel>
          <CustomSelect initial={d} />
        </FormControl>
      </Grid>
    ));
  };

  return (
    <Grid container justifyContent="space-between">
      {getTreeSelectHeader(USERS_TREE)}
    </Grid>
  );
}
