import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../calendar';

const useStyles = makeStyles({
  cal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '-14px',
    '& > div': {
      position: 'relative',
      border: 'none',
      padding: '8px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > div': {
        position: 'relative',
        width: '46%',
        padding: '8px 0',
        '&::after': {
          content: '""',
          display: 'block',
          background: 'transparent center/cover no-repeat',
          backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23333333" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"/></svg>\')',
          width: '16px',
          height: '16px',
          position: 'absolute',
          top: '11px',
          right: '14px',
          pointerEvents: 'none',
        },
      },
      '& > p': { flexShrink: 0 },
      '& label': {
        width: '100%',
        borderBottom: '1px #8c8c8c solid',
      },
      '& label > span': {
        fontFamily: 'Roboto',
        fontSize: 13,
        lineHeight: 1.6,
        fontWeight: 400,
        padding: '8px 49px 8px 0px',
      },
      '& > h6:empty': { display: 'none' },
    },
  },
});

function InfoChangeHistoryCalendar(props) {
  const { values } = props;
  const classes = useStyles();
  const data = {
    label: [], // typeが'multiple'のときのみ配列
    require: false,
    type: 'multiple',
    inputType: 'date',
    startState: values.state.startAt,
    endState: values.state.endAt,
    setState: values.setState,
  };
  return (
    <div className={classes.cal}>
      <Calendar data={data} />
    </div>
  );
}

export default InfoChangeHistoryCalendar;
