import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Drawer, Typography, List, ListItem } from '@material-ui/core';
import { SendOutlined, DeleteOutline } from '@material-ui/icons';
import SendTimeUpdate from '../sendTimeUpdate';
import UnsentDialogChangeSendTime from './unsentDialogChangeSendTime';
import commonStyles from '../../../styles';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles({
  list: { padding: '16px 0 40px 16px' },
  item: {
    borderBottom: '1px solid #F3F3F3',
    padding: '16px',
    '& .MuiButton-text': {
      padding: 0,
    },
  },
  contentText: {
    textAlign: 'center',
  },
  icon: { width: '16px', marginRight: '4px' },
});

// 共通パーツ
function ListItemComponent(props) {
  const {
    onClick,
    text,
    icon,
  } = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    <ListItem
      button
      className={classes.item}
      onClick={onClick}
    >
      {icon === 'sendTime' ? (
        <SendOutlined className={classes.icon} />
      ) : (
        <DeleteOutline className={classes.icon} />
      )}
      <Typography className={common.title3}>{text}</Typography>
    </ListItem>
  );
}

export default function UnsentMenuDrawer(props) {
  const {
    arrIndex,
    openDrawer,
    setOpenDrawer,
    clickItem,
    functions,
    options,
    refresh,
  } = props;

  const common = commonStyles();
  const classes = useStyles();

  const dispatch = useDispatch();
  // 送信時間の変更用Dialogの開閉制御
  const [openDialogChangeSendTIme, setOpenDialogChangeSendTIme] = useState(false);
  const [deliveryAt, setdeliveryAt] = useState({ deliveryAt: '' });

  /**
 * @module postSendMail - 【sendmail006】未送信メール更新
 * @param {Object} targetRow - 選択された対象メールの全内容
 * @param {date} deliveryAt - 今すぐ送信の為、現在時刻
 */
  const postSendMail = async () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = ('00' + (now.getMonth() + 1)).slice(-2);
    const day = ('00' + now.getDate()).slice(-2);
    const hour = ('00' + now.getHours()).slice(-2);
    const min = String(Number(('00' + now.getMinutes()).slice(-2)));
    const sec = String(Number(('00' + now.getSeconds()).slice(-2)));
    const dateInitial = year + '/' + month + '/' + day + ' ' + hour + ':' + min + ':' + sec;
    await functions
      .updateUnSendMail({
        mailQueueId: clickItem.mailQueueId,
        deliveryAt: dateInitial,
      }, options?.current)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenDrawer(false);
    setTimeout(() => {
      refresh();
    }, 100);
  };
  const onSendHandleClick = () => {
    dispatch(changeConfirmMessage({
      msgList: ['今すぐ送信開始します。', '送信が多い時間帯は、送信開始まで時間がかかる場合があります。'], // 必須
      title: '確認', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'CANCEL', // ボタンラベル
        set: '', // ボタンをクリックしたときの処理
        classes: common.buttonsSecondary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }, {
        label: 'OK', // ボタンラベル
        set: postSendMail, // ボタンをクリックしたときの処理
        classes: common.buttonsPrimary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
    }));
  };

  /**
   * @module deleteSendMail - 【sendmail005】メール送信停止
   * @param {Object} targetRow - 選択された対象メールの全内容
   */
  const deleteSendMail = async () => {
    await functions
      .cancelSendMail({
        mailQueueId: clickItem.mailQueueId,
        mailCancelCode: 2,
      }, options?.current)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    refresh();
    setOpenDrawer(false);
  };
  const deleteHandleClick = () => {
    dispatch(changeConfirmMessage({
      msgList: ['送信予定のメールを削除します。', 'よろしいですか？'], // 必須
      title: '確認', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'CANCEL', // ボタンラベル
        set: '', // ボタンをクリックしたときの処理
        classes: common.buttonsSecondary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }, {
        label: 'OK', // ボタンラベル
        set: deleteSendMail, // ボタンをクリックしたときの処理
        classes: common.buttonsPrimary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
    }));
  };
  useEffect(() => {
    if (clickItem) {
      clickItem.deliveryAt ? setdeliveryAt({ deliveryAt: clickItem.deliveryAt }) : null;
    }
  }, [clickItem]);

  return (
    <Drawer
      anchor="bottom"
      open={openDrawer}
      onClose={() => {
        setOpenDrawer(!openDrawer);
      }}
      className={common.drawer}
    >
      <List className={classes.list}>
        <ListItemComponent
          onClick={onSendHandleClick}
          text="今すぐ送信"
          icon="sendTime"
        />
        <ListItem
          button
          // onClick={() => {
          //   setOpenDialogChangeSendTIme(!openDialogChangeSendTIme);
          // }}
          className={classes.item}
        >
          <SendTimeUpdate
            setOpenSendTimeDialog={setOpenDialogChangeSendTIme}
            row={deliveryAt}
            setSendTime={setdeliveryAt}
          />
        </ListItem>
        <ListItemComponent
          onClick={deleteHandleClick}
          text="送信の取り消し"
          icon="delete"
        />
      </List>
      {/* 送信時間の変更用Dialog */}
      <UnsentDialogChangeSendTime
        arrIndex={arrIndex}
        open={openDialogChangeSendTIme}
        setOpen={setOpenDialogChangeSendTIme}
        values={clickItem}
        functions={functions}
        clickItem={clickItem}
        options={options}
      />
      {/* 送信確認用パーツ */}
      {/* <UnsentDialogConfirm
        openDialog={openDialog1}
        setOpenDialog={setOpenDialog1}
        openAlert={openAlert1}
        setOpenAlert={setOpenAlert1}
        functions={functions}
        clickItem={clickItem}
        tabValue={arrIndex}
        options={options}
        text={
          <Typography
            className={`${common.bass} ${classes.contentText}`}
          >今すぐ送信開始します。<br />送信が多い時間帯は、送信開始まで時間がかかる場合があります。
          </Typography>
        }
      /> */}
      {/* 削除確認用パーツ */}
      {/* <UnsentDialogConfirm
        openDialog={openDialog2}
        setOpenDialog={setOpenDialog2}
        openAlert={openAlert2}
        setOpenAlert={setOpenAlert2}
        functions={functions}
        clickItem={clickItem}
        tabValue={arrIndex}
        mailCountTab={mailCountTab}
        options={options}
        deleteFlg
        text={
          <Typography
            className={`${common.bass} ${classes.contentText}`}
          >
            送信予定のメールを削除します。<br />よろしいですか？
          </Typography>
          }
      /> */}
    </Drawer>
  );
}
