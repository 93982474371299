// eslint-disable-next-line import/prefer-default-export
export function createTreeList(list, keyword) {
  const arr = list.map((item) => {
    return {
      label: item.headChar,
      type: 'division',
      uniqueId: `division${item.headChar}`,
      children: (keyword
        ? item.userList.filter(child => child.fullName.includes(keyword))
        : item.userList).map((child) => {
        return {
          ...child,
          id: child.userId,
          type: 'user',
          label: child.fullName,
          selectable: true,
          uniqueId: `division${item.headChar}_user${child.userId}`,
        };
      }),
    };
  });

  return keyword ? arr.filter(item => item.children.length) : arr;
}
