import { makeStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';

import PremiumIcon from '../icons/premiumIcon';
import { MEMBER_STATUS_NORMAL_MEMBER } from '../../constants';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primaryColor,
  },
}));

export default function MemberStatusIcon({ code }) {
  const classes = useStyles();
  if (code === MEMBER_STATUS_NORMAL_MEMBER) {
    return <LanguageIcon className={classes.icon} />;
  }
  if (code === null) return null;
  return <PremiumIcon />;
}
