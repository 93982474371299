// eslint-disable-next-line import/prefer-default-export
export const FAMILY_STRUCTURE_CODE = {
  1: '主人',
  2: '奥様',
  3: '夫婦',
  4: '主人+子',
  5: '奥様+子',
  6: '夫婦+子',
  7: '親',
  8: '単身男性',
  9: '単身女性',
  10: 'その他',
};
