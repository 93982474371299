import React, { useState, useMemo } from 'react';
import {
  Grid,
  Typography,
  Link,
  ListItem,
  makeStyles,
  List,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import commonStyles from '../../../styles';
import dashboardStyle from './dashBoardStyles';
import InfoRegister from '../../../common/infoRegister';
import MemberStatusCode from '../../../eleCommon/memberStatusCode';
import { sortByDate } from '../../../../commonFunction';
import { DistDate } from './todaysGuidanceListSp';

import { GUIDANCE_STATUS_CODE } from '../../../../constants';
import { URL_MAPPER } from '../../../../constants/urls';

const useStyles = makeStyles({
  wrap: {
    textAlign: 'center',
    padding: '40px 0',
  },
  itemWrap: { borderBottom: '1px #c8c8c8 solid', padding: 0, background: '#fff' },
  txtWrap: { flexGrow: 1, padding: 16 },
});

function ListItemComponent(props) {
  const { data, today } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const dCommon = dashboardStyle();

  const [infoRegisterVisible, setInfoRegisterVisible] = useState(false);

  const onClose = () => {
    setInfoRegisterVisible(false);
  };

  return (
    <>
      <ListItem
        onClick={() => setInfoRegisterVisible(true)}
        className={classes.itemWrap}
      >
        <DistDate today={today} data={data} />
        <Grid className={classes.txtWrap}>
          <Grid container alignItems="center">
            <Typography className={baseClasses.strong}>
              {data.customerLastName} {data.customerFirstName}
            </Typography>
            <Rating
              value={data.userLevel}
              size="small"
              className={dCommon.stars}
              readOnly
            />
            <MemberStatusCode
              code={data.memberStatusCode}
              hideNonMemberIcon
              thin
            />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            {/* <Typography>
              <span className={baseClasses.verySmall}>物件名 </span>
              <span className={baseClasses.small}>{data.siteName}</span>
            </Typography> */}
            <Grid className={baseClasses.small} style={{ textAlign: 'left' }}>
              {data.guidanceStatusCode in GUIDANCE_STATUS_CODE
                ? GUIDANCE_STATUS_CODE[data.guidanceStatusCode]
                : ''}
              {data.guidanceStatusCode in GUIDANCE_STATUS_CODE ? '/' : ''}
              {data.isPairCheck ? '揃い' : ''}
              {data.isPairCheck && data.isFp ? '/' : ''}
              {data.isFp ? 'FP' : ''}
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>
              <span className={baseClasses.verySmall}>担当 </span>
              <span className={baseClasses.small}>
                {data.guidanceDivisionSubName}/{data.userLastName}{' '}
                {data.userFirstName}
              </span>
            </Typography>
            <Typography>
              <span className={baseClasses.verySmallStrong}>案内回数 </span>
              <span className={baseClasses.title4}>
                {data.totalGuidanceCount}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      <InfoRegister
        open={infoRegisterVisible}
        customer={data}
        guidanceId={data.guidanceId}
        onClose={onClose}
      />
    </>
  );
}

function UnregistListSp(props) {
  const { dataList } = props;
  const { total = 0, guidances = [] } = dataList.data;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const today = new Date();
  const objToday = {
    year: today.getFullYear(),
    month: today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1,
    date: today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
    hour: today.getHours() < 10 ? `0${today.getHours()}` : today.getHours(),
    minute: today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes(),
  };

  // 順番がバラバラので、昇順でソートする
  const list = useMemo(() => {
    return sortByDate(guidances, 'startAt');
  }, [guidances]);

  return (
    <Grid>
      {!total ? (
        <Grid className={classes.wrap}>
          <Typography className={`${baseClasses.strong}`}>0 件</Typography>
        </Grid>
      ) : (
        <List>
          {list.map(l => (
            <ListItemComponent
              key={`key${l.guidanceId}`}
              data={l}
              today={objToday}
            />
          ))}
        </List>
      )}
      <Grid container justifyContent="center">
        <Link
          className={baseClasses.buttonsSecondary}
          href={URL_MAPPER.schedule}
        >
          <Typography className={`${baseClasses.smallStrong} ${classes.headerLinkTxt}`}>
            行動予定表で確認
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default UnregistListSp;
