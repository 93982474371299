import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  txt: {
    fontFamily: 'Roboto',
    color: '#333',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
});

export default function DirectReportStatusCode(props) {
  const {
    code,
  } = props;

  const classes = useStyles();

  // directReportStatusCodeを更新
  // https://docs.google.com/spreadsheets/d/1UMUmqCQ51Wg-zp1wWnohRWSnENvAR1oOhfGkL1hpizQ/edit#gid=859084260&range=B75

  const getAction = () => {
    switch (code) {
      case 1:
        return (
          <span>未確認</span>
        );
      case 2:
        return (
          <span>対応予定</span>
        );
      case 3:
        return (
          <span>承認待ち</span>
        );
      case 4:
        return (
          <span>完了</span>
        );
      case 6:
        return (
          <span>取り消し</span>
        );
      case 7:
        return (
          <span>対応なし</span>
        );
      default:
        return (
          <span>該当なし</span>
        );
    }
  };

  return (
    <span className={classes.txt}>
      {getAction()}
    </span>
  );
}
