import { useEffect } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';

import {
  validateFormString1,
} from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles({
  contentIpt: {
    width: '391px',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '3px 10px',
    marginBottom: 20,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  memoTxtField: {
    width: '100%',
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    // '& .MuiInput-underline:hover:before, &:hover .MuiInput-underline:hover:after': {
    //   borderBottom: 'none',
    // },
  },
});

function AcquisitionName(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();
  const { selectUser,
    setSelectUser,
    setSelectDivision,
    setDisplayName,
    setFormValue,
    defaultDisplayName,
  } = props;

  const setUserName = (v) => {
    if (!v) return;
    setSelectUser({ userId: 0, userName: v });
    setSelectDivision({ divId: '', divisionName: '' });
  };

  const handleAcquisitionMemoChange = (newValue) => {
    setDisplayName(newValue);
    setFormValue('acquisitionMemo', newValue);
    // 獲得者をテキストで入力する際は、acquisitionIdの方は0(最終的にnullに変換される)とする
    setFormValue('acquisitionId', '0');
  };

  const returnDefaultDisplayName = () => {
    if (defaultDisplayName) {
      return defaultDisplayName;
    }
    return selectUser?.userName;
  };

  useEffect(() => {
    // 初期値を設定
    const value = returnDefaultDisplayName();
    setUserName(value);
    handleAcquisitionMemoChange(value);
  }, []);

  const getBox = (
    <TextBaseField
      defaultValue={returnDefaultDisplayName()}
      className={classes.memoTxtField}
      onBlur={(e) => setUserName(e.target.value)}
      onChange={(e) => handleAcquisitionMemoChange(e.target.value)}
      validator={validateFormString1({ maxLengthInt: 50 })}
    />
  );

  const contents = (
    <Grid className={classes.contentIpt}>
      <Typography style={{ width: '15%' }} className={baseClasses.title6}>
        <span style={{ fontSize: 8 }}>獲得者名</span><span style={{ color: '#D83420' }}>＊</span>
      </Typography>
      {getBox}
    </Grid>
  );

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default AcquisitionName;
