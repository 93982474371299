import { useState, useMemo, useEffect } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../styles';
import Select from '../division/select';
import { classNames, isNumberLike } from '../../commonFunction';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 'var(--root-height)',
    width: '100%',
  },
  item: {
    // width: 74,
    height: '100%',
    flexGrow: 1,
    '& .MuiSelect-select': {
      minWidth: '40px !important',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontWeight: 400,
      textAlign: 'left',
      fontFamily: 'Roboto',
    },
  },
  space: {
    margin: '0 4px',
    flexShrink: 0,
    alignSelf: 'flex-end',
  },
  center: {
    '& .MuiSelect-select.MuiSelect-select': {
      textAlign: 'center',
    },
  },
  blod: {
    '& .MuiSelect-select.MuiSelect-select': {
      fontWeight: 700,
    },
  },
});

export default function RangeSelect(props) {
  const {
    defaultValue = [],
    options = [],
    onChange,
    equalable, // イコール可能か
    center,
    blod,
    height = 'auto',
    className,
    style,
    label = [],
    noUnderline = true,
    selectClassName,
    hasZero,
  } = props;

  const [state, setState] = useState(defaultValue);

  const setOption = (obj) => {
    return Object.keys(obj).reduce((prev, key) => {
      prev.push({
        label: obj[key],
        value: key,
      });
      return prev;
    }, []);
  };

  const baseClasses = commonStyles();
  const classes = useStyles();

  const styles = useMemo(() => {
    return {
      ...style,
      '--root-height': height + (isNumberLike(height) ? 'px' : ''),
    };
  }, [style, height]);

  const secondList = useMemo(() => {
    const [min] = state;
    const optionsList = setOption(options);
    return optionsList.filter((item) => {
      return equalable ? item.value >= min : item.value > min;
    });
  }, [state[0]]);

  useEffect(() => {
    const [oldMin, oldMax] = defaultValue;
    const [min, max] = state;
    if (oldMin !== min || oldMax !== max) {
      setState(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (JSON.stringify(state) !== JSON.stringify(defaultValue)) {
      // console.log('chanegState', { state, defaultValue });
      onChange(state);
    }
  }, [state]);

  return (
    <Grid
      className={classNames(
        classes.root,
        {
          [classes.center]: center,
          [classes.blod]: blod,
        },
        className,
      )}
      style={styles}
      container
      alignItems="center"
    >
      <Grid fullWidth className={`${classes.item}`}>
        <Select
          value={state[0] ?? 0}
          options={setOption(options)}
          onChange={(val) => {
            const max = state[1];
            // 下限が上限以上または超過の場合、上限をクリア
            setState([
              val,
              (equalable && val > max) || (!equalable && val >= max) ? null : max,
            ]);
          }}
          key={state}
          noUnderline={noUnderline}
          className={selectClassName}
          label={label[0]}
          activeLabel
          hasZero={hasZero}
        />
      </Grid>
      <Typography
        className={`${baseClasses.title4} ${classes.space}`}
      >
        ～
      </Typography>
      <Grid fullWidth className={`${classes.item}`}>
        <Select
          value={state[1] ?? 0}
          options={secondList}
          onChange={(val) => {
            setState([state[0], val]);
          }}
          key={state}
          noUnderline={noUnderline}
          className={selectClassName}
          label={label[1]}
          activeLabel
          hasZero={hasZero}
        />
      </Grid>
    </Grid>
  );
}
