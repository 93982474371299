import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { Add, Folder, Person } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import commonStyles from '../../../../styles';
import getTreeObject from '../../commonFunc/getTreeObject';
import getDataFromAllCustomer from '../../commonFunc/getDataFromAllCustomer';
import { useCustomerTree } from '../../../../../containers/common/customerTree/context';
import { updateObjSearchItems } from '../../../../../store/search/searchItemsSlice';

const useStyles = makeStyles((theme) => ({
  fadeContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '100%',
  },
  root: {
    width: '100%',
    maxHeight: '100%',
    // overflow: 'auto',
    padding: '0 0 16px 0',
  },
  parent: {
    position: 'relative',
    padding: 0,
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 1,
  },
  li: {
    padding: '0 0 0 16px',
    display: 'flex',
    background: '#fff',
    // '&.selectedChild': {
    //   display: 'none',
    // },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    fontSize: 16,
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '&.isCompact': {
      width: '80%',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  diviPickableButton1: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '80%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  diviPickableButton2: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '20%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        marginLeft: '0',
        margin: '0',
      },
    },
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  checkedUser: {
    color: theme.palette.primaryColor,
  },
  // 戻るボタン用
  button: {
    padding: '18px 16px',
    width: '100%',
    background: '#fff',
    justifyContent: 'flex-start',
    margin: '16px 0',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  searchItem: {
    padding: 0,
  },
  searchWrap: {
    width: '100%',
  },
  searchTitle: {
    padding: 16,
  },
  searchList: {
    padding: 0,
  },
  result: {
    padding: '40px 16px',
    textAlign: 'center',
    color: '#8C8C8C',
    fontSize: 15,
  },
  closeIcon: {
    position: 'absolute',
    right: '14px',
    bottom: '-3px',
    '& .MuiButton-label': {
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem',
      },
    },
  },
}));

function CustomerTreeListResponseSp(props) {
  const common = commonStyles();
  const classes = useStyles();
  const {
    displayDivision, // 表示される組織や顧客
    setDisplayDivision,
    displayDivisionRoot, // 戻るボタンのある組織
    setDisplayDivisionRoot,
  } = props;

  const {
    list,
    setSelectUser,
    setSelectDivision,
    setSelectCustomer,
    searchObj,
    setSearchObj,
    targetFlgList,
    defaultUserId,
    defaultDivisionId,
    searchHeader,
  } = useCustomerTree();

  // 選択状態保持
  const [checked, setChecked] = useState(0);
  // 組織選択状態保持
  const [divChecked, setDivChecked] = useState(0);
  // SPではCustomer配下を表示する要件は無い為コメントアウト
  // 顧客選択状態保持
  // const [customerChecked, setCustomerChecked] = useState(0);

  // 検索後、データを保持
  // const [sortItems, setSortItems] = useState({});
  const firstFlg = useRef(true);
  const initialFlg = useRef(!!defaultUserId || !!defaultDivisionId);
  const isChangeHeader = useRef(false);
  const dispatch = useDispatch();

  // 初回ツリー描画  & 初期値指定
  useEffect(async () => {
    if (list.length > 0 || firstFlg.current) {
      const defaultSearchObj = await getTreeObject(
        list,
        null,
        targetFlgList,
        defaultUserId,
        defaultDivisionId,
      );
      if ('count' in defaultSearchObj || defaultSearchObj instanceof Array) {
        setSearchObj(defaultSearchObj);
        firstFlg.current = false;
      }
    }
  }, [list]);

  // typeCode・divisionの変更があった際は明示的にinitialFlgをfalseに変更 -> initialFlgは本FCで定義されてる為
  useEffect(() => {
    if (isChangeHeader.current) {
      initialFlg.current = false;
    } else {
      isChangeHeader.current = true;
    }
  }, [searchHeader]);

  // デフォルト選択の場合、Backは先頭まで戻る。
  const backToTop = () => {
    initialFlg.current = false;
    setDisplayDivision([]);
    setDisplayDivisionRoot([]);
  };

  // ユーザーClick時 -- 選択されたユーザーのID/Name and 対象ユーザー以降全ての顧客をset
  const handleClickUser = async (userObj, id) => {
    const customerList = await getDataFromAllCustomer(userObj);
    const fullName = userObj.userName ? userObj.userName : userObj.lastName + ' ' + userObj.firstName;
    const divisionName = userObj.parentDivision?.divisionName ? userObj.parentDivision?.divisionName : '';
    setSelectUser({ userId: id, userName: fullName });
    setSelectDivision({ divId: userObj.divisionId, divisionName });
    setSelectCustomer(customerList);
    setChecked({ checkedUser: `${userObj.divisionId}-${id}` });
    setDivChecked(0);
    setSearchObj({});
  };

  // 組織Click時 -- 選択された組織のID/Name and 対象組織以降全ての顧客をset
  const handleSelectDivision = async (divObj) => {
    const customerList = await getDataFromAllCustomer(divObj);
    setSelectDivision({
      divId: divObj.divisionId,
      divisionName: divObj.subName,
    });
    setSelectCustomer(customerList);
    setSelectUser({ userId: 0, userName: '' });
    setDivChecked(divObj.divisionId);
    setChecked(0);
    setSearchObj({});
  };

  const getDisplayDivision = (divisionId) => {
    const resultDivision = getTreeObject(
      list,
      null,
      targetFlgList,
      null,
      divisionId,
    );
    let result = [];
    if (resultDivision.divisions[0].children) {
      result = resultDivision.divisions[0].children.concat(
        resultDivision.divisions[0].users,
      );
    } else if (resultDivision.divisions[0].users) {
      result = resultDivision.divisions[0].users;
    }
    return [result, resultDivision];
  };

  // ツリーの検索後ロジック
  useEffect(() => {
    // ルート情報を初期化
    if ('count' in searchObj) setDisplayDivisionRoot([]);
    // keyword検索パターン
    if (searchObj && searchObj.keyword) {
      dispatch(updateObjSearchItems({
        keyword: searchObj.keyword,
        keywordSearch: [1],
      }));
    }
    // Default処理
    if (defaultUserId && !searchObj.keyword) {
      let isEnd;
      for (const key of Object.keys(searchObj)) {
        if (key === 'count') break;
        for (const user of searchObj[key]) {
          if (user?.isMainDivision) {
            handleClickUser(user, user.userId);
            const result = getDisplayDivision(user.divisionId);
            setDisplayDivision(result[0]);
            isEnd = true;
            break;
          }
        }
        if (isEnd) break;
      }
    } else if (defaultDivisionId && !searchObj.keyword) {
      let isEnd;
      for (const key of Object.keys(searchObj)) {
        if (key === 'count') break;
        for (const division of searchObj[key]) {
          handleSelectDivision(division?.treeObj);
          const result = getDisplayDivision(defaultDivisionId);
          console.log(result);
          setDisplayDivision([division?.treeObj]);
          isEnd = true;
        }
        if (isEnd) break;
      }
    }
  }, [searchObj, defaultUserId, defaultDivisionId]);

  // 初期表示のリストをセット
  const getFirstList = (allList) => {
    const firstList = [];
    for (let i = 0; i < allList.length; i += 1) {
      firstList.push({
        levelCode: allList[i].levelCode,
        divisionId: allList[i].divisionId,
        groupName: allList[i].groupName,
        subName: allList[i].subName,
        users: allList[i].users,
        count: allList[i].count,
        customers: allList[i].customers,
      });
    }
    return firstList;
  };

  // 選択した組織内のリストを表示。
  const handleSetDirectory = (divisionId, backVal) => {
    const result = getDisplayDivision(divisionId);
    setDisplayDivision(result[0]);

    if (backVal) {
      const resultArray = [];
      for (let i = 0; i < displayDivisionRoot.length; i += 1) {
        if (backVal.includes(displayDivisionRoot[i].divisionId)) {
          resultArray.push(displayDivisionRoot[i]);
        }
      }
      setDisplayDivisionRoot(resultArray);
    } else {
      setDisplayDivisionRoot([
        ...displayDivisionRoot,
        result[1].divisions[0],
      ]);
    }
  };

  // ユーザーボタンの+ボタンをクリック時 -- 選択されたユーザーのIDから表示するリストとルートをセット
  const handleSetUserDirectory = async (
    userObj,
    userId,
    divisionId,
    backVal,
  ) => {
    const resultDivision = getTreeObject(
      list,
      null,
      targetFlgList,
      userId,
      divisionId,
    );
    console.log({ resultDivision });

    if (backVal) {
      setDisplayDivision(resultDivision.divisions[0].users);
      const resultArray = [];
      for (let i = 0; i < displayDivisionRoot.length; i += 1) {
        if (backVal.includes(displayDivisionRoot[i].divisionId) && displayDivisionRoot[i].type === 'division') {
          resultArray.push(displayDivisionRoot[i]);
        }
      }
      setDisplayDivisionRoot(resultArray);
    } else {
      // ユーザー配下の顧客リストを画面に一覧表示
      const customerList = await getDataFromAllCustomer(userObj);
      setDisplayDivision(customerList);
      // 表示させる顧客データ(型が配列であれば、そのまま。オブジェクトの場合は配列化)
      setDisplayDivisionRoot([...displayDivisionRoot, resultDivision.users[0]]);
    }
  };

  // SPではCustomer配下を表示する要件は無い為コメントアウト
  // 顧客Click時 -- 選択された顧客のID/Nameをset
  // const handleClickCustomer = async (customer, id) => {
  //   const customerList = [];
  //   // 顧客一人をClick
  //   customerList.push({
  //     customerId: customer.customerId,
  //     customerName: customer.customerName,
  //   });
  //   setSelectCustomer(customerList);
  //   setSelectUser({ userId: 0, userName: '' });
  //   setCustomerChecked(id);
  // };

  // 戻るボタンを押した時の処理
  const getBackList = async () => {
    const divIds = [];
    const userIds = [];
    for (let i = 0; displayDivisionRoot.length > i; i += 1) {
      if (divIds.includes(displayDivisionRoot[i])) {
        break;
      }
      divIds.push(displayDivisionRoot[i].divisionId);
      if ('userId' in displayDivisionRoot[i]) {
        if (userIds.includes(displayDivisionRoot[i])) {
          break;
        }
        userIds.push(displayDivisionRoot[i].userId);
      }
    }
    // 1階層以降
    // ユーザーで戻るボタンがクリックされた場合、handleSetUserDirectoryを実行
    if (divIds.length > 1) {
      const resetDivision = divIds[divIds.length - 2];
      if (divIds.includes(resetDivision)) {
        await divIds.splice(divIds.indexOf(resetDivision) + 1);
        if (userIds.length) {
          handleSetUserDirectory(
            null,
            userIds[0],
            divIds[divIds.length - 1],
            divIds,
          );
        } else {
          await setDisplayDivisionRoot(divIds);
          handleSetDirectory(divIds[divIds.length - 1], divIds);
        }
      } else {
        handleSetDirectory(resetDivision);
      }
      // 初期画面まで戻ったら初期表示にリセット
    } else {
      setDisplayDivision([]);
      setDisplayDivisionRoot([]);
    }
  };

  // ユーザー用のボタン
  const userButton = (item) => (
    <Grid
      key={`u${item.userId}`}
      className={`${common.small} ${classes.li} ${classes.person}`}
    >
      <Button
        className={`${common.title3} ${classes.btn} ${
          checked.checkedUser === `${item.divisionId}-${item.userId}`
            ? classes.checkedUser
            : null
        } ${item.customerCount > 0 && 'isCompact'}`}
        id={`id-user-${item.userId}`}
        style={{ cursor: 'pointer' }}
        variant="text"
        startIcon={<Person className={classes.icon} />}
        onClick={() => {
          handleClickUser(item, item.userId);
        }}
      >
        {item.lastName}
        {item.firstName}
        {`(${item.count})`}
      </Button>

      {/* ユーザー配下に顧客が0人以上存在する場合、+ボタンを表示 */}
      {item.customerCount > 0 && (
        <Button
          className={classes.diviPickableButton2}
          onClick={() => {
            handleSetUserDirectory(item, item.userId, item.divisionId, null);
          }}
        >
          <Add className={classes.addButtonIcon} />
        </Button>
      )}
    </Grid>
  );

  // 組織用のボタン
  const diviPickableButton = (item) => {
    let isDefaultDivision = false;
    if (defaultDivisionId && initialFlg.current) {
      isDefaultDivision = true;
    }
    return (
      <Grid className={classes.li}>
        <Button
          className={`${common.title3} ${classes.diviPickableButton1} ${
            divChecked === item.divisionId ? classes.checkedUser : null
          }`}
          onClick={() => handleSelectDivision(item)}
        >
          <Folder className={classes.icon} />
          <Typography className={common.title3}>
            {item.subName}（{item?.count}）
          </Typography>
        </Button>
        {!isDefaultDivision ? (
          <Button
            className={classes.diviPickableButton2}
            onClick={() => {
              handleSetDirectory(item.divisionId);
            }}
          >
            <Add className={classes.addButtonIcon} />
          </Button>
        ) : '' }
      </Grid>
    );
  };

  // SPではCustomer配下を表示する要件は無い為コメントアウト
  // 顧客要素
  // const customerList = (c, index) => {
  //   const customerlist = [];
  //   customerlist.push(
  //     <Grid
  //       key={`c${c.customerId}${index}`}
  //       className={`${common.small} ${classes.li} ${classes.person}`}
  //     >
  //       <Button
  //         className={`${classes.treeUser} ${classes.btn} ${
  //           customerChecked === `c${c.customerId}`
  //             ? classes.checkedUser
  //             : null
  //         }`}
  //         id={`id-customer-${c.customerId}`}
  //         variant="text"
  //         onClick={() => {
  //           handleClickCustomer(c, `c${c.customerId}`);
  //         }}
  //       >
  //         {c.customerName}
  //       </Button>
  //     </Grid>,
  //   );

  //   return customerlist;
  // };

  // ユーザー要素
  const userList = (data) => {
    const userlist = [];
    // 階層内にユーザーが存在する場合
    if (data.users) {
      data.users.map((u) => {
        return userlist.push(userButton(u));
      });
    } else {
      userlist.push(userButton(data));
    }
    return userlist;
  };

  // 組織要素
  const childList = (c) => {
    const childlist = [];
    childlist.push(
      <Grid key={`divi${c.divisionId}`} className={classes.parent}>
        {diviPickableButton(c)}
        {/* 組織配下にcustomersが存在する場合 -- SPではこの要件が無い為コメントアウト */}
        {/* {c?.customers && c?.customers.map((u, index) => {
          return customerList(u, index);
        })} */}
      </Grid>,
    );
    return childlist;
  };

  // 組織 or 顧客のリストを返す
  const switchList = (data) => {
    if ('divisionId' in data) {
      return childList(data);
    } else return [];
    // SPではCustomer配下を表示する要件は無い為コメントアウト
    //  else {
    //   return customerList(data);
    // }
  };

  // 画面に各階層のリストを描画
  const getListItem = (searchArr, initFlg = false) => {
    let firstInitFlg = initFlg;
    let loopItems = null;
    if (displayDivision.length > 0) {
      loopItems = displayDivision;
    } else if (!searchArr) {
      firstInitFlg = true;
      loopItems = getFirstList(list);
    }

    // 初期表示
    if (firstInitFlg) {
      return loopItems.map((data) => (
        <div key={data.subName} className={classes.parent}>
          {diviPickableButton(data)}
        </div>
      ));
      // 検索を実行後、表示させるリスト
    } else if (searchArr) {
      return searchArr.customers.length > 0 ? (
        <Grid className={classes.searchItem} key={uuid()}>
          {searchArr.customers.map((arr) => {
            return (
              <Grid
                className={classes.searchWrap}
                key={`s${arr.customerId}${uuid()}`}
              >
                {/* 組織名／ユーザー名でグルーピング */}
                <Typography className={`${common.title3} ${classes.searchTitle}`}>
                  {arr.groupName}
                </Typography>
                {/* リストの表示 SPではCustomer配下を表示する要件は無い為コメントアウト */}
                {/* <Grid className={classes.searchList}>
                    {arr.customers.map((c) => customerList(c))}
                  </Grid> */}
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography className={classes.result}>0件</Typography>
      );
    } else {
      const resultList = loopItems.map((data) => (
        'userId' in data ? userList(data) : switchList(data)
      ));
      return (
        <>
          {resultList.map((item) => (
            <Grid key={uuid()}>{item}</Grid>
          ))}
        </>
      );
    }
  };

  return (
    <>
      {displayDivisionRoot.length > 0 && (
        // 戻るボタン
        <Button className={classes.button} onClick={getBackList}>
          <ArrowBackIosIcon className={classes.backIcon} />
          <Typography className={`${classes.text} ${common.title3}`}>
            {displayDivisionRoot.slice(-1)[0].rootName}
          </Typography>
        </Button>
      )}
      {(defaultUserId && initialFlg.current) || (defaultDivisionId && initialFlg.current) ? (
        <Button className={classes.button} onClick={() => backToTop()}>
          <CloseIcon />
          <Typography className={`${classes.text} ${common.title3}`}>
            組織の先頭に戻る
          </Typography>
        </Button>
      ) : ''}
      <Grid className={`${classes.root}`}>
        {getListItem(false, false)}
      </Grid>
    </>
  );
}

export default CustomerTreeListResponseSp;
