import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';

import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import { isOpen } from '../../store/common/apiMessageSlice';

import CustomTablePagination from '../eleCommon/customTablePagenation';
import commonStyles from '../styles';
import LibraryTable from './parts/libraryTable';
import LibraryShare from './libraryShare';
import LibraryCreate from './libraryCreate';
import LibrarySignature from './librarySignature';
import LibrarySend from './librarySend';

import LibraryDirectoryTree from '../common/libraryDirectoryTree';

import {
  libraryPageSelector,
  changeLibrarySearchLimit,
  moveLibrarySearchPagenation,
  changeOrderAndOrderBy,
} from '../../store/libraryPage';

import { useLoading } from '../../hooks';

import { getEnvVariable } from '../../commonFunction/getEnv';

import { MAIL_CODE_PRIVATE, MAIL_CODE_PUBLIC, MAIL_CODE_TRASH } from '../../constants';
import { MAIL_TEMPLATE_ADMIN } from '../../constants/userRole';
import { LIBRARY_TABLE } from '../../constants/loading';
import { MARKETING_GROUPS } from '../../constants/userRoleGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
  header: {
    marginBottom: '32px',
    alignItems: 'center',
  },
  btn: { color: theme.palette.primaryColor },
  borderRight: {
    marginRight: '48px',
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      height: '32px',
      width: '1px',
      background: '#c8c8c8',
      position: 'absolute',
      top: 0,
      right: '-24px',
    },
  },
  borderLeft: {
    marginLeft: '48px',
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      height: '32px',
      width: '1px',
      background: '#c8c8c8',
      position: 'absolute',
      top: 0,
      left: '-24px',
    },
  },
  marginRight: { marginRight: '24px' },
  disable: {
    pointerEvents: 'none',
    '& > span': { color: '#c8c8c8' },
  },
}));

export default function MailLibraryComponent(props) {
  const {
    getTreeList,
    dblclick,
    viewMode,
    selectDir,
    specialDir,
    librarySearchFunction,
    libraryUpdateFunction,
    libraryDeleteFunction,
    libraryCopyFunction,
    libraryShareFunction,
  } = props;
  const dispatch = useDispatch();
  const { hasLoading } = useLoading();
  const classes = useStyles();
  const common = commonStyles();
  const [choice, setChoice] = useState([]); // checkbox
  const [choiceDir, setChoiceDir] = useState([]); // 上のチェックボックスに対応するライブラリIDとディレクトリIDの配列
  const [open, setOpen] = useState({
    create: false,
    share: false,
    edit: null,
    signature: false,
    folder: false,
    send: false,
  });

  const libraryInfo = useSelector((state) => state.libraryGetSpecifyDirctory);
  const rows = libraryInfo.libraryGetSpecifyDirctory;
  const { totalCount } = libraryInfo;

  const [sendFlg, setSendFlg] = useState(true);

  // ページネーション
  const { librarySearchParam } = useSelector(libraryPageSelector);
  const { limit, offset, orderBy, order } = librarySearchParam;
  const page = Math.floor(offset / limit);
  const setPage = (n) => {
    dispatch(moveLibrarySearchPagenation(n));
    librarySearchFunction();
  };
  const rowsPerPage = limit;
  const setRowsPerPage = (n) => {
    dispatch(changeLibrarySearchLimit(n));
    librarySearchFunction();
  };
  const setOrderAndOrderBy = async (tpmOrderBy) => {
    dispatch(changeOrderAndOrderBy(tpmOrderBy));
    librarySearchFunction();
  };

  const responseHeader = useSelector((s) => s.responseHeader);

  const isTemplateAdmin = responseHeader.roleIds.includes(MAIL_TEMPLATE_ADMIN);

  const isSearch = () => viewMode === 'search';
  const isTrashDir = selectDir.libraryDirectoryId === specialDir[MAIL_CODE_TRASH];
  const isPrivateDir = selectDir.mailCode === MAIL_CODE_PRIVATE;
  const isPublicDir = selectDir.mailCode === MAIL_CODE_PUBLIC;
  const isPrivateOwnDir = isPrivateDir && selectDir.userId === responseHeader.userId;

  const checkedSet = new Set(choice);
  const selectedLibraries = rows.filter(({ libraryId }) => checkedSet.has(libraryId));

  // ボタン制御の権限制御について
  // https://docs.google.com/spreadsheets/d/1IAQ2Lf6_L6v1j1h0O_4Yg1sTpRBADmzyOqvJoVTuq3M/edit#gid=157801650
  const isInsertEnable = isTemplateAdmin || isPrivateOwnDir || isPublicDir;
  const isUpdateEnable = isTemplateAdmin
    || selectedLibraries.some((l) => l.userId === responseHeader.userId);

  // 補助関数: オブジェクト内のnullをdelete
  const deleteNullObjectProperty = (params) => {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      }
    });
  };

  // 補助関数: ライブラリを移動またはゴミ箱へ移動の処理
  const runMoveLibraries = (libraryDirectoryId, isTrash = false) => {
    selectedLibraries.forEach(async (row, index) => {
      const params = Object.assign({}, row);
      params.libraryDirectoryId = libraryDirectoryId;
      params.isUpdate = 0;
      // null値除去
      deleteNullObjectProperty(params);
      await libraryUpdateFunction(params.libraryId, params);
      if (index === selectedLibraries.length - 1) {
        if (isTrash) {
          dispatch(isOpen({
            status: 'success',
            message: '成功',
            description: 'ライブラリをゴミ箱へ移動しました',
          }));
        }
        // 最後の時のみライブラリ一覧を再読み込み
        librarySearchFunction();
      }
    });
  };

  // 補助関数: ゴミ箱から削除の処理
  const runDeleteLibraries = () => {
    selectedLibraries.forEach(async (row, index) => {
      const params = Object.assign({}, row);
      await libraryDeleteFunction(params.libraryId);
      if (index === selectedLibraries.length - 1) {
        dispatch(
          isOpen({
            status: 'success',
            message: '成功',
            description: 'ライブラリをゴミ箱から削除しました。',
          }),
        );
        // 最後の時のみライブラリ一覧を再読み込み
        librarySearchFunction();
      }
    });
  };

  // 補助関数: ライブラリをコピー
  const runCopyLibraries = (libraryDirectoryId) => {
    selectedLibraries.forEach(async (row) => {
      const params = Object.assign({}, row);
      params.libraryDirectoryId = libraryDirectoryId;
      // null値除去
      deleteNullObjectProperty(params);
      await libraryCopyFunction(params);
    });
  };

  // 確認画面
  const confirmOpen = (title, msgList) => {
    return new Promise((resolve, reject) => {
      dispatch(
        changeConfirmMessage({
          title,
          msgList,
          buttons: [
            {
              label: 'CANCEL',
              set: () => reject(),
              classes: common.buttonsSecondary,
            },
            {
              label: 'OK',
              set: () => resolve(),
              classes: common.buttonsPrimary,
            },
          ],
        }),
      );
    });
  };

  // 編集ボタンが押されたとき
  const clickedit = (row) => {
    return setOpen({
      ...open,
      create: true,
      edit: row,
    });
  };

  // ライブラリの共有
  const onShare = async (userIds) => {
    // userIdの重複を削除
    const newUserIds = [...new Set(userIds)];
    const params = {
      libraryIds: choice,
      userIds: newUserIds,
    };
    await libraryShareFunction(params);
    // チェックを外す
    setChoice([]);
    setChoiceDir([]);
  };

  // ライブラリの移動またはコピー
  const onSelectFolder = (dir) => {
    if (!dir.libraryDirectoryId) return;
    // フォルダ選択ウィンドウで選択されたディレクトリと同じ場合選択できないのでConfirmは不要
    if (open.type === 'move') {
      runMoveLibraries(dir.libraryDirectoryId);
      // チェックを外す
      setChoice([]);
      setChoiceDir([]);
    } else if (open.type === 'copy') {
      runCopyLibraries(dir.libraryDirectoryId);
    }
  };

  // ゴミ箱へ移動
  const onMoveTrash = () => {
    if (isTrashDir) {
      confirmOpen(null, ['選択したライブラリを削除してもよろしいですか？', '削除すると元に戻すことはできません。']).then(() => {
        runDeleteLibraries();
      });
    } else {
      confirmOpen(null, ['選択したライブラリをゴミ箱に移動してもよろしいですか？']).then(() => {
        // ゴミ箱のlibraryDirectoryIdを使用
        runMoveLibraries(specialDir[MAIL_CODE_TRASH], true);
      });
    }
  };

  const reloadLibraries = () => {
    librarySearchFunction();
    getTreeList();
  };

  const modalMessageMap = {
    copy: 'コピー',
    move: '移動',
  };

  useEffect(() => {
    setChoice([]);
    setChoiceDir([]);
    if (
      MARKETING_GROUPS.includes(responseHeader.roleGroupId) // マーケ権限 51,52,54
      && selectDir.libraryDirectoryId === 21518 // 関東代行メール施策 21518
    ) {
      setSendFlg(true);
    } else {
      setSendFlg(false);
    }
  }, [selectDir]);

  return (
    <Grid className={`${classes.root} ${hasLoading(LIBRARY_TABLE) ? common.loading : ''}`}>
      <Grid container className={classes.header}>
        <Typography className={common.title2}>ライブラリ管理</Typography>
        <CustomTablePagination
          rowsPerPageOptions={[20, 40, 60]}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
      </Grid>
      <Grid container className={classes.header}>
        <Typography className={`${common.bass} ${classes.choice} ${classes.marginRight}`}>{choice.length}件選択中</Typography>
        <Button
          className={`${common.buttonsPrimary} ${classes.marginRight}`}
          onClick={() => { return setOpen({ ...open, create: true, edit: null }); }}
          disabled={isSearch() || !isInsertEnable}
        >
          新規ライブラリを作成
        </Button>
        <Button
          href={getEnvVariable('AUTO_TEMPLATE_APP')}
          target="_blank"
          className={`${common.buttonsSecondary} ${classes.borderRight}`}
        >
          ライブラリを自動作成
        </Button>
        <Button
          onClick={() => { return setOpen({ ...open, share: true }); }}
          className={`${common.buttonsSecondary} ${classes.borderRight} ${(!choice.length || isSearch() || !isInsertEnable) && classes.disable}`}
        >
          ライブラリの共有
        </Button>
        <Button
          onClick={() => { return setOpen({ ...open, folder: true, type: 'move' }); }}
          className={`${common.buttonsSecondary} ${classes.marginRight} ${(!choice.length || isSearch() || !isUpdateEnable) && classes.disable}`}
        >
          ライブラリの移動
        </Button>
        <Button
          onClick={() => { return setOpen({ ...open, folder: true, type: 'copy' }); }}
          className={`${common.buttonsSecondary} ${classes.marginRight} ${(!choice.length || isTrashDir) && classes.disable}`}
        >
          コピー
        </Button>
        <Button
          onClick={onMoveTrash}
          className={`${common.buttonsSecondary} ${classes.borderRight} ${(!choice.length || isSearch() || !isInsertEnable) && classes.disable}`}
        >
          {isTrashDir ? 'ゴミ箱から削除' : 'ゴミ箱へ移動'}
        </Button>
        <Button
          onClick={() => { return setOpen({ ...open, signature: true }); }}
          className={common.buttonsSecondary}
        >
          署名の編集
        </Button>
        {sendFlg && (
          <Button
            onClick={() => { return setOpen({ ...open, send: true }); }}
            className={`${common.buttonsSecondary} ${classes.borderLeft} ${!(rows.length > 0) && classes.disable}`}
          >
            送信
          </Button>
        )}
      </Grid>
      <LibraryTable
        dblclick={dblclick}
        list={rows}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        choice={choice}
        setChoice={setChoice}
        choiceDir={choiceDir}
        setChoiceDir={setChoiceDir}
        order={order}
        orderBy={orderBy}
        setOrderAndOrderBy={setOrderAndOrderBy}
        clickedit={clickedit}
        librarySearchFunction={librarySearchFunction}
      />
      {/*  ライブラリの共有 */}
      {open.share && (
        <LibraryShare
          open={open.share}
          onClose={() => { return setOpen({ ...open, share: false }); }}
          onShare={onShare}
        />
      )}
      {/*  新規ライブラリを作成＆編集 */}
      <LibraryCreate
        open={open}
        onClose={() => { return setOpen({ ...open, create: false }); }}
        selectDir={selectDir}
        reloadLibraries={reloadLibraries}
      />
      {/*  署名の編集 */}
      <LibrarySignature
        open={open.signature}
        onClose={() => { return setOpen({ ...open, signature: false }); }}
      />
      {/*  送信 */}
      {open.send && (
        <LibrarySend
          open={open.send}
          onClose={() => { return setOpen({ ...open, send: false }); }}
          selectDir={selectDir}
          rows={rows}
          reloadLibraries={reloadLibraries}
        />
      )}
      {/*  ライブラリの移動またはコピー */}
      <LibraryDirectoryTree
        setSelectDir={onSelectFolder}
        prefix="toLibraryDirectoryId"
        isGetLatestData={false}
        isSearch
        type={open.type}
        targetDir={selectDir}
        choiceDir={choiceDir}
        modalOption={{
          open: open.folder,
          onClose: () => setOpen((s) => ({ ...s, folder: false })),
          title: 'ライブラリのフォルダ選択ウィンドウ',
          message: `${modalMessageMap[open.type]}先のフォルダを選択してください。`,
        }}
      />
    </Grid>
  );
}
