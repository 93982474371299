import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { ArrowUpward, Cancel } from '@material-ui/icons';
import commonStyles from '../../styles';
import FileStrage from '../../fileStrage';

import {
  getBase64,
  base64ToBlob,
  createBlobURL,
  downloadFile,
} from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '25%',
    background: '#fff',
    border: '1px #c8c8c8 solid',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    background: '#F3F3F3',
    border: '1px dashed #8C8C8C',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    flexGrow: 1,
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.4,
    textAlign: 'center',
    '& span': {
      color: theme.palette.primaryColor,
      cursor: 'pointer',
    },
  },
  arrow: {
    color: '#F3F3F3',
    background: '#8C8C8C',
    borderRadius: '50%',
    border: '8px #8C8C8C solid',
    width: 40,
    height: 40,
  },
  list: {
    padding: '8px 0',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  cancel: {
    color: theme.palette.primaryColor,
    width: '16px',
    marginLeft: '8px',
    flexShrink: 0,
  },
  strage: {
    color: theme.palette.primaryColor,
    margin: '8px auto 0',
    display: 'block',
    '&:hover': {
      background: `${theme.palette.primaryColor}29` },
  },
  inputArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    '&::-webkit-file-upload-button': {
      width: '100%',
      height: '100%',
    },
  },
}));

function MailAppendFileComponent(props) {
  const { uploadedList, setUploadedList, disable } = props;
  const classes = useStyles();
  const common = commonStyles();

  const onView = async (file) => {
    const { file: base64, mimeType: type, fileName } = file;
    const blob = base64ToBlob(base64, type);
    const url = createBlobURL(blob);
    downloadFile(url, fileName);
  };
  const onUpload = async (e) => {
    const { files } = e.target;
    if (files && files.length < 1) return;
    const readerGroup = Object.keys(files).filter(k => k !== 'length').map(k => getBase64(files[k]));
    const tmpArr = uploadedList.filter((file) => file.sendgridAttachimentFileId !== undefined);
    // メール基盤側で保存されてないデータを毎回格納する。
    await Promise.all(readerGroup).then(res => {
      res.forEach(({ base64, fileObj }) => {
        const tmp = base64.split(',');
        if (tmp.length < 1) return;
        const file = tmp[1];
        const mimeType = tmp[0].replace(/data:|;base64/g, '');
        tmpArr.push({ fileName: fileObj.name, file, mimeType });
      });
    });
    await setUploadedList(uploadedList.concat(tmpArr));
    e.target.value = '';
  };

  const onCancel = (file) => {
    const tmpList = uploadedList.filter((f) => f.fileName !== file.fileName);
    setUploadedList(tmpList);
  };

  return (
    <Grid item className={classes.wrap}>
      <Typography className={common.title4}>添付</Typography>
      <div className={classes.list}>
        {uploadedList.map((file) => (
          <span className={classes.li} key={file.fileName}>
            <Typography
              className={common.small}
              onClick={() => onView(file)}
            >
              {file.fileName}
            </Typography>
            {disable ? null : <Cancel className={classes.cancel} onClick={() => onCancel(file)} />}
          </span>
        ))}
      </div>
      <Grid container className={classes.dropzone}>
        <input type="file" multiple onChange={onUpload} className={classes.inputArea} disabled={disable} />
        <ArrowUpward className={classes.arrow} />
        <p className={classes.txt}>添付ファイルを<br />ここにドラック&ドロップ<br />または<span>ファイル選択</span></p>
      </Grid>
      <FileStrage styles={`${classes.strage} ${common.strong}`} isDisabled={disable} />
    </Grid>
  );
}

export default MailAppendFileComponent;
