import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  // Tooltip,
} from '@material-ui/core';
import { canCallTimeNow } from '../../../../constants/search/detailSearch';
import Label from './label';
import { updateSearchItems } from '../../../../store/search/searchItemsSlice';
import { useKanniKensaku } from '../../../../containers/search/kanniKensaku/KanniKensakuContext';
import { dateFormatter } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginRight: 1,
    marginLeft: 0,
    '& span': {
      fontSize: 13,
    },
  },
  checkBoxMain: {
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& .MuiSvgIcon-root': { fontSize: 18 },
  },
  checkBoxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 4,
    marginBottom: 4,
  },
  infoIcon: {
    color: '#D83420',
    fontSize: 20,
  },
}));

function SortCheckBox() {
  const {
    searchConditions,
    setSearchConditions,
    isAvoidSharedCustomer,
  } = useKanniKensaku();

  const classes = useStyles();
  const dispatch = useDispatch();

  // 更新用
  const [count, setCount] = useState(0);

  useEffect(() => {
    // グローバルステート(簡易検索条件)の更新
    dispatch(updateSearchItems(searchConditions));
  }, [count]);

  const CheckChange = (name, checkStatus) => {
    if (checkStatus) {
      checkStatus = Number(checkStatus);
    } else {
      checkStatus = null;
    }

    switch (name) {
      case 'isCanCallNow':
        // 現在時刻（hours）
        // eslint-disable-next-line no-case-declarations
        const now = new Date().getHours();
        // canCallTimeNowの選択肢にある開始時間と現在時刻（now）が一致しているか
        // 一致していたらconvenientTimesに格納
        // eslint-disable-next-line no-case-declarations
        const filterKey = Object.keys(canCallTimeNow.menus).filter(
          (item) => item.includes(now),
        );
        // eslint-disable-next-line no-case-declarations
        const filterValue = filterKey.length ? [canCallTimeNow.menus[filterKey[0]]] : [];
        if (checkStatus) {
          setSearchConditions({
            ...searchConditions,
            convenientTimes: filterValue,
            isCanCallNow: checkStatus,
          });
        } else {
          setSearchConditions({
            ...searchConditions,
            convenientTimes: [],
            isCanCallNow: checkStatus,
          });
        }
        break;
      case 'isEffectWeek':
        // 直近1週間の反響
        if (checkStatus) {
          const defaultTo = new Date();
          const defaultFrom = new Date();
          defaultFrom.setDate(defaultTo.getDate() - 6);
          console.log(checkStatus);
          setSearchConditions({
            ...searchConditions,
            effectFrom: dateFormatter(defaultFrom, 'YYYY/MM/DD') + ' 00:00:00',
            effectTo: dateFormatter(defaultTo, 'YYYY/MM/DD') + ' 23:59:59',
            isEffectWeek: checkStatus,
          });
        } else {
          setSearchConditions({
            ...searchConditions,
            effectFrom: '',
            effectTo: '',
            isEffectWeek: checkStatus,
          });
        }
        break;
      case 'isCalledAndNotMailed':
        setSearchConditions({ ...searchConditions, isCalledAndNotMailed: checkStatus });
        break;
      case 'searchType4':
        setSearchConditions({ ...searchConditions, searchType4: checkStatus });
        break;
      case 'isSendShare':
        setSearchConditions({ ...searchConditions, isSendShare: checkStatus });
        break;
      case 'isWebHistoryExist':
        setSearchConditions({ ...searchConditions, isWebHistoryExist: checkStatus });
        break;
      case 'isDirectExist':
        setSearchConditions({ ...searchConditions, isDirectExist: checkStatus });
        break;
      case 'isCallablePhoneNumberExist':
        setSearchConditions({ ...searchConditions, isCallablePhoneNumberExist: checkStatus });
        break;
      case 'isSendableMailExist':
        setSearchConditions({ ...searchConditions, isSendableMailExist: checkStatus });
        break;
      default:
        break;
    }
    setCount(count + 1);
  };

  const handleChange = (event) => {
    CheckChange(event.target.name, event.target.checked);
  };

  const sortCheckList = [
    {
      key: 'isCanCallNow',
      title: '今TELが繋がりやすい顧客',
      state: searchConditions.isCanCallNow,
    },
    {
      key: 'isEffectWeek',
      title: '直近1週間反響',
      state: searchConditions.isEffectWeek,
    },
    {
      key: 'isCalledAndNotMailed',
      title: 'TEL済/未Mail',
      state: searchConditions.isCalledAndNotMailed,
    },
    {
      key: 'isSendShare',
      title: '共有顧客',
      state: !isAvoidSharedCustomer && searchConditions.isSendShare,
      disabled: isAvoidSharedCustomer,
    },
    {
      key: 'isWebHistoryExist',
      title: 'Web履歴有',
      state: searchConditions.isWebHistoryExist,
    },
    {
      key: 'isDirectExist',
      title: '指示有',
      state: searchConditions.isDirectExist,
    },
    {
      key: 'isCallablePhoneNumberExist',
      title: 'TEL有',
      state: searchConditions.isCallablePhoneNumberExist,
    },
    {
      key: 'isSendableMailExist',
      title: 'Mail有',
      state: searchConditions.isSendableMailExist,
    },
  ];

  const createCheckBox = sortCheckList.map((data) => (
    <div key={data.key} className={classes.checkBoxWrap}>
      <FormControlLabel
        className={classes.checkBox}
        size="small"
        control={
          <Checkbox
            checked={Boolean(data.state)}
            onChange={handleChange}
            name={data.key}
            color="primary"
            className={classes.checkBoxMain}
            disabled={data.disabled}
          />
        }
        label={<Label data={data} />}
      />
    </div>
  ));

  return createCheckBox;
}
export default SortCheckBox;
