import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import MemberStatusCode from '../../eleCommon/memberStatusCode';
import {
  IS_REPLACEMENT,
  IS_THINK_APARTMENT,
  OWN_FUND_CODE,
} from '../../../constants';
import Modal from '../../common/modal';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    [commonTheme.breakpoints.up('md')]: {
      background: '#fff',
      width: 640,
    },
  },
  header: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 24px',
    },
  },
  headerBlock: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px solid #C8C8C8',
      padding: '24px 0 8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: 16,
    },
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
  },
  content: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: '12px',
    },
  },
  body: {
    [commonTheme.breakpoints.up('md')]: {
      background: '#fff',
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
    },
  },
  txt1: {
    [commonTheme.breakpoints.up('md')]: {
      fontSize: 15,
    },
  },
  itemSp: {
    [commonTheme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: '#fff',
      padding: '16px 8px 12px 32px',
      marginTop: 4,
    },
  },
  block1: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #C8C8C8',
      width: '100%',
    },
  },
  block2: {
    [commonTheme.breakpoints.up('md')]: {
      width: '48.5%',
    },
  },
  block3: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      '&:not(:last-child)': {
        borderBottom: '1px solid #C8C8C8',
      },
    },
  },
  block4: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 200,
      padding: '8px 0',
    },
  },
  block5: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      width: 290,
      padding: '8px 0',
    },
  },
  block6: {
    [commonTheme.breakpoints.up('md')]: {
      width: 532,
    },
  },
  block7: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  block8: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid #C8C8C8',
    },
  },
  block9: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  },
  block10: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      width: 100,
      padding: '8px 0',
    },
  },
  block11: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      width: 160,
      padding: '8px 0',
    },
  },
  item1: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 441,
      padding: '8px 0',
    },
  },
  item2: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 143,
    },
  },
  item3: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      padding: '6px 0',
      width: '100%',
      '&:not(:last-child)': {
        borderBottom: '1px solid #C8C8C8',
      },
    },
  },
  item4: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
      padding: '6px 0',
      '&:not(:last-child)': {
        borderBottom: '1px solid #C8C8C8',
      },
    },
  },
  item5: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 254,
      padding: '6px 0',
      '&:not(:first-child)': {
        borderTop: '1px solid #C8C8C8',
      },
    },
  },
  item6: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 254,
      padding: '6px 0',
    },
  },
  item7: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 532,
      padding: '6px 0',
    },
  },
  item8: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 254,
      padding: '6px 0',
      borderBottom: '1px solid #C8C8C8',
    },
  },
  label1: {
    [commonTheme.breakpoints.up('md')]: {
      width: 50,
      paddingTop: 8,
    },
  },
  label2: {
    [commonTheme.breakpoints.up('md')]: {
      width: 89,
      paddingTop: 8,
    },
  },
  label3: {
    [commonTheme.breakpoints.up('md')]: {
      marginRight: 8,
      whiteSpace: 'nowrap',
    },
    [commonTheme.breakpoints.down('sm')]: {
      fontWeight: '400',
    },
  },
  memberId: {
    [commonTheme.breakpoints.down('sm')]: {
      marginLeft: 8,
    },
  },
  titleSp: {
    [commonTheme.breakpoints.up('md')]: {
      flexShrink: 0,
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  onlyValue: {
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  partBlock: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 16,
    },
  },
  box1: {
    [commonTheme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  box4: {
    [commonTheme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
});

export default function MemberInfoDetail(props) {
  const {
    open,
    onClose,
    detail,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const formatFamilyIncome = [];
  if (detail.familyIncome) {
    const split = detail.familyIncome.split(':');
    split.forEach(item => {
      formatFamilyIncome.push(item);
    });
  }

  const body = (
    <Grid className={classes.content}>
      <Grid className={classes.headerBlock}>
        <MemberStatusCode
          code={detail.memberStatusCode}
        />
        <Grid className={classes.headerItem}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            Web会員ID
          </Typography>
          <Typography className={`${classes.txt} ${classes.memberId}`}>
            {detail.memberId}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.block1}>
        <Grid className={`${classes.item1} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            姓名
          </Typography>
          <Typography className={`${classes.txt} ${classes.txt1}`}>
            {detail.lastName} {detail.firstName}
            （{detail.lastNameKana} {detail.firstNameKana}）
          </Typography>
        </Grid>
        <Grid className={`${classes.item2} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            生年月日
          </Typography>
          <Typography className={classes.txt}>
            {detail.birthYear}/{detail.birthMonth}/{detail.birthDay}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.block1}>
        <Grid className={classes.block2}>
          <Grid className={classes.block3}>
            <Typography className={`${baseClasses.title4} ${classes.label1} ${classes.titleSp}`}>
              住所
            </Typography>
            <Grid className={classes.box1}>
              <Grid className={`${classes.item3} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  〒
                </Typography>
                <Typography className={classes.txt}>
                  {detail.zip}
                </Typography>
              </Grid>
              <Grid className={`${classes.item3} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  都道府県
                </Typography>
                <Typography className={classes.txt}>
                  {detail.prefecture}
                </Typography>
              </Grid>
              <Grid className={`${classes.item3} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  住所
                </Typography>
                <Typography className={classes.txt}>
                  {detail.address}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.block3}>
            <Typography className={`${baseClasses.title4} ${classes.label1} ${classes.titleSp}`}>
              仕事
            </Typography>
            <Grid className={classes.box4}>
              <Grid className={`${classes.item3} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  職業
                </Typography>
                <Typography className={classes.txt}>
                  {detail.job}
                </Typography>
              </Grid>
              <Grid className={`${classes.item3} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  職種
                </Typography>
                <Typography className={classes.txt}>
                  {detail.jobType}
                </Typography>
              </Grid>
              <Grid className={`${classes.item3} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  勤務先
                </Typography>
                <Typography className={classes.txt}>
                  {detail.company}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.block2}>
          <Grid className={classes.block3}>
            <Typography className={`${baseClasses.title4} ${classes.label2} ${classes.titleSp}`}>
              連絡先
            </Typography>
            <Grid className={classes.box4}>
              <Grid className={`${classes.item4} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  TEL
                </Typography>
                <Typography className={classes.txt}>
                  {detail.phoneNumber}
                </Typography>
              </Grid>
              <Grid className={`${classes.item4} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.blockSp}`}>
                  mail
                </Typography>
                <Typography className={classes.txt}>
                  {detail.mail}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.block3}>
            <Typography className={`${baseClasses.title4} ${classes.label2} ${classes.titleSp}`}>
              連絡が<br className={baseClasses.onlyPc} />つきやすい
              <br className={baseClasses.onlyPc} />時間帯
            </Typography>
            <Grid className={classes.box4}>
              <Grid className={`${classes.item4} ${classes.onlyValue} ${classes.itemSp}`}>
                <Typography className={classes.txt}>
                  {detail.convinientTime}
                </Typography>
              </Grid>
              <Grid className={`${classes.item4} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  その他
                </Typography>
                <Typography className={classes.txt}>
                  {detail.convinientTimeMemo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.block3}>
            <Typography className={`${baseClasses.title4} ${classes.label2} ${classes.titleSp}`}>
              お休みの日
            </Typography>
            <Grid className={classes.box4}>
              <Grid className={`${classes.item4} ${classes.onlyValue} ${classes.itemSp}`}>
                <Typography className={classes.txt}>
                  {detail.holidayWeekDay}
                </Typography>
              </Grid>
              <Grid className={`${classes.item4} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  その他
                </Typography>
                <Typography className={classes.txt}>
                  {detail.holidayWeekdayMemo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={`${classes.block1} ${classes.partBlock}`}>
        <Grid className={`${classes.block4} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            現在のお住まい
          </Typography>
          <Typography className={classes.txt}>
            {detail.livingHouse}
          </Typography>
        </Grid>
        <Grid className={`${classes.block10} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            現在の家賃
          </Typography>
          <Typography className={classes.txt}>
            {detail.nowRent}
            {detail.nowRent ? '万円' : ''}
          </Typography>
        </Grid>
        <Grid className={`${classes.block10} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            世帯収入
          </Typography>
          <Typography className={classes.txt}>
            {formatFamilyIncome[0]}
            {formatFamilyIncome[0] ? '万円 ~ ' : ''}
            {formatFamilyIncome[1]}
            {formatFamilyIncome[1] ? '万円' : ''}
          </Typography>
        </Grid>
        <Grid className={`${classes.block11} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            自己資金
          </Typography>
          <Typography className={classes.txt}>
            {OWN_FUND_CODE[detail.ownFundCode]}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.block1}>
        <Grid className={`${classes.block5} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            戸建予算
          </Typography>
          <Typography className={classes.txt}>
            {detail.detachedHouseBudgetMin && Number(
              detail.detachedHouseBudgetMin,
            ).toLocaleString()}
            {detail.detachedHouseBudgetMin && '円'}
            <span> ~ </span>
            {detail.detachedHouseBudgetMax && Number(
              detail.detachedHouseBudgetMax,
            ).toLocaleString()}
            {detail.detachedHouseBudgetMax && '円'}
          </Typography>
        </Grid>
        <Grid className={`${classes.block5} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            土地予算
          </Typography>
          <Typography className={classes.txt}>
            {detail.landBudgetMin && Number(detail.landBudgetMin).toLocaleString()}
            {detail.landBudgetMin && '円'}
            <span> ~ </span>
            {detail.landBudgetMax && Number(detail.landBudgetMax).toLocaleString()}
            {detail.landBudgetMax && '円'}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.block1}>
        <Typography className={`${baseClasses.title4} ${classes.label3} ${classes.titleSp}`}>
          ご希望
        </Typography>
        <Grid className={classes.block6}>
          <Grid className={classes.block7}>
            <Grid className={classes.item}>
              <Grid className={`${classes.item5} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  エリア
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishArea}
                </Typography>
              </Grid>
              <Grid className={`${classes.item5} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  路線
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishRailway}
                </Typography>
              </Grid>
              <Grid className={`${classes.item5} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  その他エリア
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishAreaOther}
                </Typography>
              </Grid>
              <Grid className={`${classes.item5} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  部屋数
                </Typography>
                <Typography className={classes.txt}>
                  {detail.roomCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.item}>
              <Grid className={`${classes.item8} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  ご希望の担当者
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishUserName}
                </Typography>
              </Grid>
              <Grid className={`${classes.item8} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  ご希望の店舗
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishStore}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.box3}>
            <Grid className={classes.block8}>
              <Grid className={`${classes.item6} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  小学区
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishPrimarySchool}
                </Typography>
              </Grid>
              <Grid className={`${classes.item6} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  中学区
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishJuniorHighSchool}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.block8}>
              <Grid className={`${classes.item6} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  大人
                </Typography>
                <Typography className={classes.txt}>
                  {detail.adultCount}
                </Typography>
              </Grid>
              <Grid className={`${classes.item6} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  子供
                </Typography>
                <Typography className={classes.txt}>
                  {detail.childCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.block8}>
              <Grid className={`${classes.item6} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  お探しのきっかけ
                </Typography>
                <Typography className={classes.txt}>
                  {detail.reason}
                </Typography>
              </Grid>
              <Grid className={`${classes.item6} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  その他
                </Typography>
                <Typography className={classes.txt}>
                  {detail.otherReason}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.box4}>
              <Grid className={`${classes.item7} ${classes.itemSp}`}>
                <Typography className={`${baseClasses.title4} ${classes.label3}`}>
                  その他希望条件
                </Typography>
                <Typography className={classes.txt}>
                  {detail.wishCondition}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.block1}>
        <Grid className={`${classes.block5} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            買い替えの有無
          </Typography>
          <Typography className={classes.txt}>
            {IS_REPLACEMENT[detail.replacementCode]}
          </Typography>
        </Grid>
        <Grid className={`${classes.block5} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            マンション検討
          </Typography>
          <Typography className={classes.txt}>
            {IS_THINK_APARTMENT[detail.isThinkApartment]}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={`${classes.block9} ${classes.partBlock}`}>
        <Grid className={`${classes.block5} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            修正日時
          </Typography>
          <Typography className={classes.txt}>
            {detail.updatedAt}
          </Typography>
        </Grid>
        <Grid className={`${classes.block5} ${classes.itemSp}`}>
          <Typography className={`${baseClasses.title4} ${classes.label3}`}>
            登録日時
          </Typography>
          <Typography className={classes.txt}>
            {detail.createdAt}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    isSP ? (
      <Grid>
        {body}
      </Grid>
    ) : (
      <Modal
        open={open}
        onClose={onClose}
        className={classes.dialog}
        width={800}
        bodyClassName={classes.body}
      >
        {body}
      </Modal>
    )
  );
}
