import client from '../httpClient';

// 【hpInfo009】処理履歴
// http://10.22.2.48:8080/docs/#/members/get-members-histories
const hpinfoGetHistoriesApi = async (params) => {
  try {
    const res = await client.get('/members/histories', { params });
    return res.data;
  } catch (e) {
    console.log(e);
    return {
      total: 0,
      histories: [],
    };
  }
};

export default hpinfoGetHistoriesApi;
