import { useDispatch } from 'react-redux';
import PersonalGoal from '../../components/user/personalGoal';
import mokuhyoSetGetChangeHistryApi from '../../apis/mokuhyoSet/mokuhyoSetGetChangeHistryApi';
import { setMokuhyoList, initialMokuhyoList } from '../../store/mokuhyoSet/mokuhyoSetGetChangeHistrySlice';
import { useLoading } from '../../hooks';

function PersonalGoalContainer(props) {
  const { state } = props;
  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  // 目標のデータ取得
  const getMokuhyoList = async (userId) => {
    addLoading('personalGoal');
    await mokuhyoSetGetChangeHistryApi(userId)
      .then((res) => {
        dispatch(setMokuhyoList(res.data));
        removeLoading('personalGoal');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 目標データ初期化 目標リスト(targets)のみをリセットする
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5602
  const resetMokuhyoList = () => {
    dispatch(initialMokuhyoList());
  };

  return (
    <PersonalGoal
      userDetail={state}
      getMokuhyoList={getMokuhyoList}
      resetMokuhyoList={resetMokuhyoList}
    />
  );
}

export default PersonalGoalContainer;
