import React from 'react';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  inner: {
    width: '100%',
    padding: '24px, 16px',
    margin: '0 auto',
    marginBottom: 25,

  },
  content: {
    width: '100%',
    background: '#ffffff',
    marginTop: 5,
    padding: 8,
    color: '#333333',
    boxSizing: 'border-box',
    border: 'solid 1px #C8C8C8',
  },
  listContainer: {
    display: 'flex',
    borderBottom: 'solid 2px #C8C8C8',
  },
  listHeader: {
    color: '#8C8C8C',
    padding: '0 8px',
    width: 273,
  },
  list: {
    padding: 0,
  },
  listItem: {
    borderBottom: 'solid 1px #C8C8C8',
    padding: '0 8px',
    minWidth: 1000,
    transition: 'all .3s ease',
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      cursor: 'pointer',
    },
    '& label': {
      width: 260,
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& .MuiIconButton-label': {
        width: 16,
        height: 16,
      },
      '& .MuiTypography-body1': {
        fontSize: 12,
      },
    },
  },
  listItemTxt: {
    borderLeft: 'solid 1px #C8C8C8 !important',
    paddingLeft: 5,
  },
  formControl: {
    width: '100%',
  },
  updateInformation: {
    display: 'flex',
  },
}));

function AutomaticProcessInner(props) {
  const { values, setValues } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <Grid className={classes.container}>
      <Grid className={classes.inner}>
        <Typography className={baseClasses.title3}>完全停止</Typography>
        <Grid className={classes.content}>
          <List className={classes.listContainer}>
            <ListItem className={`${baseClasses.title4} ${classes.listHeader}`}>設定</ListItem>
            <ListItem style={{ borderLeft: 'solid 1px #C8C8C8' }} className={`${baseClasses.title4} ${classes.listHeader}`}>説明</ListItem>
          </List>
          <FormControl className={classes.formControl} component="fieldset">
            <RadioGroup aria-label="gender" name="stopExecuteCode" onChange={handleChange}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="ON"
                    checked={values.stopExecuteCode === '0'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    「会員登録」、「物件戻し」、「物件権利（新規/再販）」を完全に停止します　※未処理一覧にも格納されません。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="OFF"
                    checked={values.stopExecuteCode === '1'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    「会員登録」、「WEB戻し」、「権利（新規/再販）」を稼働させます。完全停止中に受信した反響を一括処理します。
                  </Typography>
                </ListItem>
              </List>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={classes.inner}>
        <Typography className={baseClasses.title3}>会員登録</Typography>
        <Grid className={classes.content}>
          <List className={classes.listContainer}>
            <ListItem className={`${baseClasses.title4} ${classes.listHeader}`}>設定</ListItem>
            <ListItem style={{ borderLeft: 'solid 1px #C8C8C8' }} className={`${baseClasses.title4} ${classes.listHeader}`}>説明</ListItem>
          </List>
          <FormControl className={classes.formControl} component="fieldset">
            <RadioGroup aria-label="gender" name="memberShipCode" onChange={handleChange}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="ON"
                    checked={values.memberShipCode === '1'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    店舗選定ロジックで選定した店舗に反響登録します。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="OFF"
                    checked={values.memberShipCode === '0'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    Web反響未処理に反響を追加します。
                  </Typography>
                </ListItem>
              </List>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={classes.inner}>
        <Typography className={baseClasses.title3}>物件戻し</Typography>
        <Grid className={classes.content}>
          <List className={classes.listContainer}>
            <ListItem className={`${baseClasses.title4} ${classes.listHeader}`}>設定</ListItem>
            <ListItem style={{ borderLeft: 'solid 1px #C8C8C8' }} className={`${baseClasses.title4} ${classes.listHeader}`}>説明</ListItem>
          </List>
          <FormControl className={classes.formControl} component="fieldset">
            <RadioGroup aria-label="gender" name="webReturnCode" onChange={handleChange}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="通常戻し"
                    checked={values.webReturnCode === '1'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    顧客の担当・課・店舗に反響登録します。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="全戻し（長期休暇など）"
                    checked={values.webReturnCode === '2'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    既存顧客に名寄せできた場合、再版基準日の判断をせず、顧客の担当者・課・店舗に反響登録します。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="OFF"
                    checked={values.webReturnCode === '0'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    Web反響未処理に反響を追加します。
                  </Typography>
                </ListItem>
              </List>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={classes.inner}>
        <Typography className={baseClasses.title3}>物件権利（新規/再反）</Typography>
        <Grid className={classes.content}>
          <List className={classes.listContainer}>
            <ListItem className={`${baseClasses.title4} ${classes.listHeader}`}>設定</ListItem>
            <ListItem style={{ borderLeft: 'solid 1px #C8C8C8' }} className={`${baseClasses.title4} ${classes.listHeader}`}>説明</ListItem>
          </List>
          <FormControl className={classes.formControl} component="fieldset">
            <RadioGroup aria-label="gender" name="productRightCode" onChange={handleChange}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="権利"
                    checked={values.productRightCode === '1'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    問合せ物件の権利課＞権利店舗＞エリア店舗の優先順位で反響登録します。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="凍結期間"
                    checked={values.productRightCode === '2'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    問合せ物件の権利店舗＞エリア店舗の優先順位で反響登録します。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="OFF"
                    checked={values.productRightCode === '0'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    Web反響未処理に反響を追加します。
                  </Typography>
                </ListItem>
              </List>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={classes.inner}>
        <Typography className={baseClasses.title3}>自動送信メール</Typography>
        <Grid className={classes.content}>
          <List className={classes.listContainer}>
            <ListItem className={`${baseClasses.title4} ${classes.listHeader}`}>設定</ListItem>
            <ListItem style={{ borderLeft: 'solid 1px #C8C8C8' }} className={`${baseClasses.title4} ${classes.listHeader}`}>説明</ListItem>
          </List>
          <FormControl className={classes.formControl} component="fieldset">
            <RadioGroup aria-label="gender" name="sendNoticeMailCode" onChange={handleChange}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="ON"
                    checked={values.sendNoticeMailCode === '1'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    「反響登録通知」「情報変更通知」「退会通知」「WEB閲覧通知」を即時送信・定期送信します。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="反響登録通知を一時OFF（表彰式など）"
                    checked={values.sendNoticeMailCode === '2'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    「反響登録通知」を即時送信せず、一時保管します。ONに変更時、一時保管している通知を順次送信します。
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="完全OFF（長期休暇など）"
                    checked={values.sendNoticeMailCode === '0'}
                  />
                  <Typography className={`${baseClasses.small} ${classes.listItemTxt}`}>
                    「反響登録通知」「情報変更通知」「退会通知」「WEB閲覧通知」を即時送信・一時保管しません。
                  </Typography>
                </ListItem>
              </List>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={classes.updateInformation}>
        <Typography className={baseClasses.small}>最終更新：</Typography>
        <span className={baseClasses.small}>
          {values.updateAt} - {values.updateByDivisionName} - {values.updateByUserName}
        </span>
      </Grid>
    </Grid>
  );
}

export default AutomaticProcessInner;
