// components/user/
// userBasicAuthority.js
// System Constants for API
export const SFA_SYSTEM_ID = 1;
export const KYMALIS_SYSTEM_ID = 2;
export const DAIJIN_SYSTEM_ID = 3;

// 戸建 / MS / WM
export const BUSINESS_KODATE = 1;
export const BUSINESS_MS = 2;
export const BUSINESS_WM = 3;

// userChangeHistory.js
// 組織・権限の変更履歴
export const GROUP_HISTORY_TH = [
  '所属(開始)',
  '所属(終了)',
  '権限',
  '所属組織',
  '役職',
];

export const USER_CLASS_SELECT = {
  menus: {
    1: '営業部長',
    2: 'センター長',
    6: 'サブセンター長',
    3: 'マネージャー',
    4: 'プレイヤー',
    5: 'その他',
  },
  sort: ['1', '2', '6', '3', '4', '5'],
};

export const USER_GRADE_SELECT_LIST = {
  menus: {
    0: 'なし',
    1: '副主任',
    2: '主任',
    3: '係長',
    4: '専任課長',
    5: '課長',
    6: '専任次長',
    7: '次長',
    8: '部長代理',
    9: '部長',
    10: '副本部長',
    11: '本部長',
    12: '専務',
    13: '監査役',
    14: '調査役',
    15: '室長',
    16: '参与',
    17: '顧問',
    19: '常務',
  },
};

// personalTable.js
// 個人目標のテーブル
export const GROUP_PERSONAL_TH = [
  '年',
  '月',
  '棟',
  '売上（万円）',
  '所属組織',
  '役割',
  '更新日',
];

// MonthSelect
export const MONTH_SELECT = {
  menus: {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
  },
  title: '',
};

export const ALL_CUSTOMER_STATUS_CODE = [
  1, // '追客中',
  2, // '追わない',
  10, // '契約',
  11, // 'クレーム',
];
