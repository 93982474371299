import React from 'react';
import { Grid } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import modifyObject from '../../../commonFunction/modifyObject';
import { IS_HIDE } from '../../../constants/index';
import commonStyles from '../../styles';
import { convertForm1, convertFormKana } from '../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormEmail,
  validateFormTel,
} from '../../../commonFunction/validations';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 8,
    position: 'static',
    width: '49%',
    left: '0px',
    top: '0px',
    border: '1px solid #C8C8C8',
    boxSizing: 'border-box',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    margin: '0.5%',
  },
  col2: {},
  half: { width: '24%' },
}));

function InputFormMain(props) {
  const { getComponent, state, setState, valid, errorCallback } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();

  const handleChange = (e, data) => {
    const val = e.target ? e.target.value : e;
    switch (data.key) {
      case 'activeDirectoryAuthenticationId':
        setState({ ...state, activeDirectoryAuthenticationId: val });
        break;
      case 'userCode':
        setState({ ...state, userCode: val });
        break;
      case 'isHide':
        setState({ ...state, isHide: val });
        break;
      case 'lastName':
        setState({ ...state, lastName: val });
        break;
      case 'firstName':
        setState({ ...state, firstName: val });
        break;
      case 'lastNameKana':
        setState({ ...state, lastNameKana: val });
        break;
      case 'firstNameKana':
        setState({ ...state, firstNameKana: val });
        break;
      case 'mail':
        setState({ ...state, mail: val });
        break;
      case 'mobilePhoneNumber':
        setState({ ...state, mobilePhoneNumber: val });
        break;
      default:
        break;
    }
  };

  const setFirstDate = (date) => {
    setState({ ...state, startAt: date });
  };

  const setEndDate = (date) => {
    setState({ ...state, endAt: date });
  };

  const basicField = [
    {
      title: 'ADアカウント',
      required: true,
      key: 'activeDirectoryAuthenticationId',
      type: 'text',
      state: state.activeDirectoryAuthenticationId,
      set: handleChange,
      validator: validateFormString1({ maxLengthInt: 30 }),
      convertor: convertForm1,
      errorCallback: errorCallback('activeDirectoryAuthenticationId'),
    },
    {
      title: 'OH社員ID',
      required: true,
      key: 'userCode',
      type: 'text',
      state: state.userCode,
      set: handleChange,
      validator: validateFormString1({ maxLengthInt: 255 }),
      convertor: convertForm1,
      errorCallback: errorCallback('userCode'),
    },
    {
      title: 'アカウント有効期間',
      required: true,
      key: 'accountDate',
      type: 'multipleDate',
      state: {
        startAt: state.startAt,
        endAt: state.endAt,
      },
      setState: { setFirst: setFirstDate, setEnd: setEndDate },
      valid,
    },
    {
      title: '非表示フラグ',
      required: true,
      key: 'isHide',
      type: 'flg',
      obj: modifyObject(IS_HIDE, '非表示フラグ'),
      state: state.isHide,
      set: handleChange,
    },
    {
      title: '姓',
      required: true,
      key: 'lastName',
      type: 'text',
      state: state.lastName,
      set: handleChange,
      half: true,
      validator: validateFormString1({ maxLengthInt: 50 }),
      convertor: convertForm1,
      errorCallback: errorCallback('lastName'),
    },
    {
      title: '名',
      required: true,
      key: 'firstName',
      type: 'text',
      state: state.firstName,
      set: handleChange,
      half: true,
      validator: validateFormString1({ maxLengthInt: 50 }),
      convertor: convertForm1,
      errorCallback: errorCallback('firstName'),
    },
    {
      title: 'セイ',
      required: true,
      key: 'lastNameKana',
      type: 'text',
      state: state.lastNameKana,
      set: handleChange,
      half: true,
      validator: validateFormString1({ maxLengthInt: 50 }),
      convertor: convertFormKana,
      errorCallback: errorCallback('lastNameKana'),
    },
    {
      title: 'メイ',
      required: true,
      key: 'firstNameKana',
      type: 'text',
      state: state.firstNameKana,
      set: handleChange,
      half: true,
      validator: validateFormString1({ maxLengthInt: 50 }),
      convertor: convertFormKana,
      errorCallback: errorCallback('firstNameKana'),
    },
    {
      title: 'PCメールアドレス',
      required: true,
      key: 'mail',
      type: 'text',
      state: state.mail,
      set: handleChange,
      validator: validateFormEmail(),
      errorCallback: errorCallback('mail'),
    },
    {
      title: '携帯電話番号(ハイフンあり)',
      required: false,
      key: 'mobilePhoneNumber',
      type: 'text',
      state: state.mobilePhoneNumber,
      set: handleChange,
      validator: validateFormTel(),
      errorCallback: errorCallback('mobilePhoneNumber'),
    },
  ];

  return basicField.map((data) => (
    <Grid
      item
      xs={6}
      md={6}
      lg={6}
      xl={6}
      key={data.key}
      className={`${classes.root} ${classes.customHeight} ${baseClasses.inputWrap} ${data.half ? classes.half : ''}`}
    >
      {getComponent(data)}
    </Grid>
  ));
}

export default React.memo(InputFormMain);
