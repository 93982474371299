import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LibrarySendComponent from '../../components/mailLibrary/librarySend/librarySendConponent';
import sendmailGetCountsApi from '../../apis/sendmail/sendmailGetCountsApi';
import sendmailBukkenTestApi from '../../apis/sendmail/sendmailBukkenTestApi';
import sendmailBukkenApi from '../../apis/sendmail/sendmailBukkenApi';
import { getSendmailGetCountsApi } from '../../store/sendmail/sendmailGetCountsSlice';
import { getSessionStorage } from '../../commonFunction';
import {
  MAIL_FROM_SELF,
  SEND_MAIL_AREA,
  INFO_OH_OFFICE,
} from '../../constants';
import {
  MAIL_FROM_CODE,
} from '../../constants/sessionStorage';
import { useLoading } from '../../hooks';
import { LIBRARY_SEND_WINDOW, MAIL_SENDING, TESTMAIL_SENDING } from '../../constants/loading';
import { isOpen } from '../../store/common/apiMessageSlice';
import { validateJsonSize } from '../../commonFunction/validations';

export default function LibrarySendContainer(props) {
  const {
    open,
    onClose,
    selectDir,
    rows,
    isLocalStorage = true,
    reloadLibraries,
  } = props;

  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  const sendmailCounts = useSelector((state) => state.sendmailGetCounts.sendmailGetCounts);

  const mailFromCode = isLocalStorage
    ? getSessionStorage(MAIL_FROM_CODE, MAIL_FROM_SELF)
    : MAIL_FROM_SELF;

  // 【sendmail012】物件テストメール送信
  // /send-mails/bukken/test
  const sendTestMailFunction = useCallback(async () => {
    // paramが少ないためサイズチェックなし
    addLoading(TESTMAIL_SENDING);
    const testMailParam = {
      libraryDirectoryId: selectDir.libraryDirectoryId,
      mailFromCode,
    };
    await sendmailBukkenTestApi(testMailParam);
    removeLoading(TESTMAIL_SENDING);
  }, []);

  // 【sendmail013】物件メール送信件取得
  // /send-mails/bukken/counts
  const [countsParam, setCountsParam] = useState({
    areaPriority: Object.keys(SEND_MAIL_AREA).map((key) => (Number(key))),
    limit: null,
    sendStartDate: null,
    libraryIds: rows.map(item => item.libraryId),
  });
  const getCountsFunction = useCallback(async (params) => {
    addLoading(LIBRARY_SEND_WINDOW);
    await sendmailGetCountsApi(params)
      .then((res) => {
        dispatch(getSendmailGetCountsApi(res.data[0]));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        removeLoading(LIBRARY_SEND_WINDOW);
      });
  }, []);

  // /send-mails/bukken
  // 【sendmail014】物件メール送信
  const [mailParam, setMailParam] = useState({
    libraryDirectoryId: selectDir.libraryDirectoryId,
    areaPriority: Object.keys(SEND_MAIL_AREA).map((key) => (Number(key))),
    infoCode: INFO_OH_OFFICE,
    limit: null,
    timerStartAt: null,
  });
  const sendMailFunction = useCallback(async (params) => {
    // サイズチェック
    const error = validateJsonSize(params);
    if (error) {
      dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      return;
    }
    addLoading(MAIL_SENDING);
    await sendmailBukkenApi(params)
      .then((res) => {
        const { message, description } = res;
        const msg = { message, description, status: 'success' };
        dispatch(isOpen(msg));
      })
      .catch(err => {
        console.error({ err });
      })
      .finally(() => removeLoading(MAIL_SENDING));
  }, []);

  const functions = {
    sendTestMailFunction,
    getCountsFunction,
    sendMailFunction,
    reloadLibraries,
  };

  const data = {
    sendmailCounts,
    countsParam,
    setCountsParam,
    mailParam,
    setMailParam,
  };

  return (
    <LibrarySendComponent
      open={open}
      onClose={onClose}
      data={data}
      functions={functions}
    />
  );
}
