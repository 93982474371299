import { EditDialogContextProvider } from './editDialogContext';
import DialogBody from './dialogBody';

export default function EditDialog() {
  return (
    <EditDialogContextProvider>
      <DialogBody />
    </EditDialogContextProvider>
  );
}
