import client from '../httpClient';
// import Customer from '../../components/customer/customer';

/**
 * 【customer001】顧客情報取得
 * @param {number[]} ids number[] customerIdの配列
 * @param {boolean} isMobile boolean
 * @returns Promise<T>
 */
const customerCallApi = (
  ids,
  isMobile = 0,
  sortType = 0,
  skipAuthorityCheck = 0,
  isUrlParameter = 0,
) => {
  const params = {
    isMobile,
    sortType,
    skipAuthorityCheck,
    isUrlParameter,
    customerId: ids?.some((item) => !!item.customerId)
      // idsは[{ customerId: number }, ...]の場合
      ? ids.map((item) => item.customerId)
      // [ number, ..., number ]の場合
      : ids,
  };
  return client.get('/customers', { params });
};

export default customerCallApi;
