import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerGetStatisticsSlice = createSlice({
  name: 'customerGetStatistics',
  initialState: { customerGetStatistics: initialState },
  reducers: {
    customerGetStatisticsApi: (state, data) => {
      state.customerGetStatistics = data.payload;
    },
  },
});

export const { customerGetStatisticsApi } = customerGetStatisticsSlice.actions;

export default customerGetStatisticsSlice.reducer;
