/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import InfoRegisterChoice1 from './infoRegisterChoice1';
import InfoRegisterChoice2 from './infoRegisterChoice2';
import InfoRegisterRadio2 from './infoRegisterRadio2';
import InfoRegisterRadio3 from './infoRegisterRadio3';
import InfoRegisterChoiceText1 from './infoRegisterChoiceText1';
import InfoRegisterChoiceText2 from './infoRegisterChoiceText2';
import InfoRegisterCheck1 from './infoRegisterCheck1';
import InfoRegisterCheck2 from './infoRegisterCheck2';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '8px',
      margin: '-16px 24px 24px',
      background: 'rgba(51, 51, 51, 0.04)',
      border: '1px solid #C8C8C8',
    },
    display: 'none',
    '&.open': {
      display: 'block',
    },
  },
  ttl: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 8,
    },
    '&::after': {
      [commonTheme.breakpoints.down('sm')]: {
        content: '""',
        display: 'block',
        width: 7,
        height: 7,
        borderTop: `2px solid ${theme.palette.primaryColor}`,
        borderRight: `2px solid ${theme.palette.primaryColor}`,
        position: 'absolute',
        top: 20,
        right: 20,
        transform: 'rotate(-45deg)',
      },
    },
    '&.close': {
      '&::after': {
        [commonTheme.breakpoints.down('sm')]: {
          transform: 'rotate(135deg)',
        },
      },
    },
  },
  childwrap: {
    width: '100%',
    '&.close': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  itemwrap: {
    width: '100%',
    '&:not(:last-child)': {
      [commonTheme.breakpoints.down('sm')]: {
        marginBottom: 4,
      },
      [commonTheme.breakpoints.up('md')]: {
        marginBottom: 8,
      },
    },
  },
}));

function InfoRegisterAchievement(props) {
  const { input } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [displayControl, setDisplayControl] = useState(false);

  const box = [
    {
      label: '実績',
      key: 'key1',
      items: [
        {
          type: 'radio3',
          key: 'guidanceType',
          input: {
            dis: input.dis2,
            label: '種類',
            required: true,
            state: input.kin,
            setState: (e) => { return input.kinCha(e); },
          },
        },
        {
          type: 'radio2',
          key: 'visit',
          input: {
            label1: '来社',
            label2: '案内',
            required: true,
            state1: input.vis,
            setState1: (e) => { return input.visCha(e); },
            state2: input.gui,
            setState2: (e) => { return input.guiCha(e); },
          },
        },
        {
          type: 'check1',
          key: 'checked2',
          input: {
            state: input.che2,
            setState: (e) => { return input.che2Cha(e); },
          },
        },
        {
          type: 'choice2',
          key: 'suggestProducts',
          input: {
            label: '物件',
            required: true,
            disabled: input.acIa.propertyName,
            state: input.guidedProducts,
            setState: (e) => { return input.setState('suggestProducts', e); },
          },
        },
        {
          type: 'choiceText1',
          key: 'customerService1',
          input: {
            label: '接客者①',
            required: true,
            disabled: input.acIa.customerService1,
            state1: input.contactAccount1.userName,
            setState1: (e) => { return input.setState('contactAccount1', e); },
            state2: input.contactMinutes1,
            setState2: (e) => { return input.setState('contactMinutes', e); },
            selectUser: input.contactAccount1.userName ? input.contactAccount1 : input.responseHeader,
          },
        },
        {
          type: 'choiceText2',
          key: 'customerService2',
          input: {
            label1: '接客者②',
            label2: '同席者',
            required: false,
            disabled: input.acIa.customerService2,
            state1: input.contactAccount2.userName,
            setState1: (e) => { return input.setState('contactAccount2', e); },
            state2: input.contactMinutes2,
            setState2: (e) => { return input.setState('contactMinutes2', e); },
            state3: input.attendContactAccount.userName,
            setState3: (e) => { return input.setState('attendContactAccount', e); },
            selectUser1: input.contactAccount2.userName ? input.contactAccount2 : input.responseHeader,
            selectUser3: input.attendContactAccount.userName ? input.attendContactAccount : input.responseHeader,
          },
        },
        {
          type: 'choiceText1',
          key: 'guide',
          input: {
            label: '案内者',
            required: true,
            disabled: input.acIa.guide,
            state1: input.guideContactAccount.userName,
            setState1: (e) => { return input.setState('guideContactAccount', e); },
            state2: input.guideContactMinutes,
            setState2: (e) => { return input.setState('guideContactMinutes', e); },
            selectUser: input.guideContactAccount.userName ? input.guideContactAccount : input.responseHeader,
          },
        },
        {
          type: 'choice1',
          key: 'accompanyingPerson',
          input: {
            label: '同行者',
            required: false,
            disabled: input.acIa.accompanyingPerson,
            state: input.accompanyingPerson.userName,
            setState: (e) => { return input.setState('accompanyingPerson', e); },
            setStateDivision: (e) => { return input.setStateDivision('accompanyingPerson', e); },
            selectUser: input.accompanyingPerson.userName ? input.accompanyingPerson : input.responseHeader,
          },
        },
        {
          type: 'check2',
          key: 'contactTarget',
          input: {
            label: '接触対象',
            state: input.che4,
          },
        },
        {
          type: 'check1',
          key: 'checked3',
          input: {
            state: input.che3,
          },
        },
      ],
    },
  ];

  const switchType = (val) => {
    const returnType = [];
    switch (val.type) {
      case 'radio2':
        return <InfoRegisterRadio2 values={val.input} />;
      case 'radio3':
        return <InfoRegisterRadio3 values={val.input} />;
      case 'check1':
        return <InfoRegisterCheck1 values={val.input} />;
      case 'check2':
        return <InfoRegisterCheck2 values={val.input} />;
      case 'choice1':
        return <InfoRegisterChoice1 values={val.input} />;
      case 'choice2':
        return <InfoRegisterChoice2 values={val.input} />;
      case 'choiceText1':
        return <InfoRegisterChoiceText1 values={val.input} />;
      case 'choiceText2':
        return <InfoRegisterChoiceText2 values={val.input} />;
      default:
        break;
    }
    return returnType;
  };

  const items = (val) => {
    const itemchild = [];
    val.map(i => itemchild.push(
      <Grid
        key={i.key}
        className={classes.itemwrap}
      >
        {switchType(i)}
      </Grid>,
    ));
    return itemchild;
  };

  function Boxlist() {
    const list = [];
    box.map(b => list.push(
      <Grid key={b.key} className={classes.box}>
        <Typography
          className={`${baseClasses.title4} ${classes.ttl} ${displayControl && 'close'}`}
          onClick={() => { setDisplayControl(!displayControl); }}
        >
          {b.label}
        </Typography>
        <Grid
          container
          className={`${classes.childwrap} ${displayControl && 'close'}`}
        >
          {items(b.items)}
        </Grid>
      </Grid>,
    ));
    return list;
  }

  return (
    <Grid
      className={`${classes.root} ${input.dis1 ? 'open' : ''}`}
    >
      {isSp ? box.map(b => (
        <Grid key={b.key} className={classes.box}>
          <Typography
            className={`${baseClasses.title4} ${classes.ttl} ${displayControl && 'close'}`}
            onClick={() => { setDisplayControl(!displayControl); }}
          >
            {b.label}
          </Typography>
          <Grid
            container
            className={`${classes.childwrap} ${displayControl && 'close'}`}
          >
            {items(b.items)}
          </Grid>
        </Grid>
      )) : (
        <Boxlist />
      )}
    </Grid>
  );
}

export default InfoRegisterAchievement;
