import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    borderRadius: 0,
    position: 'relative',
    display: 'block',
    lineHeight: 1,
    width: '100%',
    textAlign: 'left',
    padding: '6px 0',
    '&::before, &::after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      boxSizing: 'inherit',
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: 'none',
    },
    '&::before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&::after': {
      transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      transform: 'scaleY(0)',
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '&::before': {
        borderColor: 'transparent',
      },
      '&::after': {
        transform: 'scaleY(1)',
      },
    },
    '&>span': {
      fontSize: 13,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontWeight: 'normal',
    },
  },
}));

function SortInputButton(props) {
  const { initial } = props;

  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      type="button"
      onClick={initial.set}
    >
      {initial.state ? initial.state : initial.title}
    </Button>
  );
}
export default SortInputButton;
