import React, { useState } from 'react';
import { Grid, TableCell, Checkbox, makeStyles } from '@material-ui/core';
import ButtonList from './buttonList';

const useStyles = makeStyles((theme) => ({
  cell: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '6px 4px',
    '& .MuiCheckbox-root': {
      padding: 0,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
    '& > .MuiGrid-root': {
      minHeight: 20,
    },
    '&:not(:last-child) > .MuiGrid-root': {
      borderRight: '1px solid #C8C8C8',
    },
  },
  center: {
    textAlign: 'center',
  },
  no: {
    minWidth: 24,
    textAlign: 'center',
    marginLeft: 5,
    display: 'inline-block',
  },
  existing: { color: '#D83420' },
}));

function CustomCell(props) {
  const {
    obj,
    row,
    responseTypeList,
    index,
    tdIndex,
    onClickRegister,
    onClickDelete,
    onClick,
  } = props;
  const classes = useStyles();
  let className = classes.cell;
  if (obj.checkKey && row[obj.checkKey]) {
    className += ` ${classes.existing}`;
  }
  switch (obj.type) {
    case 'center':
      className += ` ${classes.center}`;
      break;
    default:
  }

  // TODO: 共通化したい(responseTypeId -> responseTypeName)
  const getResponseTypeName = (responseTypeId) => {
    let parentTypeName = '';
    if (responseTypeList) {
      responseTypeList.some((responseType) => {
        if (responseType.responseTypeId === responseTypeId) {
          parentTypeName = responseType.responseTypeName;
          return true;
        }
        return false;
      });
    }
    return parentTypeName;
  };

  // チェックボックス
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const getCheckbox = () => {
    return (
      <>
        <Checkbox checked={checked} onChange={handleChange} />
        <span className={classes.no}>{index + 1}</span>
      </>
    );
  };

  let text = '';
  switch (obj.type) {
    case 'responseType':
      text = getResponseTypeName(row[obj.key]) || 'test';
      break;
    case 'checkbox':
      text = getCheckbox();
      break;
    default:
      text = row[obj.key];
  }
  return (
    <TableCell className={className} size="small" onClick={onClick}>
      <Grid
        style={{
          fontWeight: obj.fontWeight || '400',
        }}
      >
        {text}
      </Grid>
      {tdIndex === 0 && (
        <ButtonList
          row={row}
          onClickRegister={onClickRegister}
          onClickDelete={onClickDelete}
        />
      )}
    </TableCell>
  );
}

export default CustomCell;
