import LoginCognito from '../../components/auth/login/loginCognito';

function LoginContainer(props) {
  // TODO: 事業部名/URLを一覧で取得
  const division = {
    data: [
      {
        divisionId: 1,
        groupName: '戸建て',
      },
    ],
  };
  return <LoginCognito getLoginUrl={division} sessionProps={props} />;
}

export default LoginContainer;
