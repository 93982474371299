import { useState, useRef } from 'react';
import { makeStyles, InputAdornment, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TextBaseField } from './../../eleCommon/validation';
import { validateFormString1 } from './../../../commonFunction/validations';

const useStyles = makeStyles(() => ({
  txtfield: {
    width: '100%',
    '& .MuiInput-underline::before': { display: 'none' },
    '& .MuiInput-underline::after': { display: 'none' },
  },
  iconAdornment: {
    padding: '0px',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  searchIcon: {
    height: '1rem',
  },
  textInput: {
    padding: '5px',
    fontSize: '13px',
    '&.MuiSelect-select:focus': { background: 'none' },
  },
  searchButton: {
    padding: '6px 0',
    minWidth: 'auto',
  },
}));

export default function SearchCell(props) {
  const { defaultValue = '', filterFunc = () => { }, maxLengthInt } = props;
  const classes = useStyles();
  const txtEl = useRef(null);

  const [searchValue, setSearchValue] = useState('');

  return (
    <TextBaseField
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') filterFunc(searchValue);
      }}
      inputRef={txtEl}
      defaultValue={defaultValue}
      placeholder="-"
      className={classes.txtfield}
      validator={maxLengthInt !== null ? validateFormString1({ maxLengthInt }) : null}
      InputProps={{
        classes: {
          input: classes.textInput,
          adornedStart: classes.iconAdornment,
        },
        startAdornment: (
          <InputAdornment position="start" className={classes.searchIconWrap}>
            <Button
              onClick={() => {
                filterFunc(searchValue);
              }}
              className={classes.searchButton}
            >
              <SearchIcon className={classes.searchIcon} />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}
