import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import SearchZipsContainer from '../../../../containers/search/zips/searchZips';

import CustomBlankSelect from '../../../eleCommon/customBlankSelect';

import { useRerender } from '../../../../hooks';

import { PREFECTURES_CODE } from '../../../../constants';
import { TextBaseField } from '../../../eleCommon/validation';
import { validateFormPostalCode, validateFormString1 } from '../../../../commonFunction/validations';
import { convertForm1 } from '../../../../commonFunction/convertors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  address: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
  },
  addressBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  address1: {
    width: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  address2: {
    width: 78,
  },
  address3: {
    width: 120,
  },
  address4: {
    width: 255,
  },
  address5: {
    width: 467,
  },
  addressLbl: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  formControl: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      paddingBottom: 0,
    },
    '& > div': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  addressButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 300,
  },
  button: {
    color: theme.palette.primaryColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    marginTop: 8,
  },
  buttonTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 2px',
    textTransform: 'none',
  },
  buttonIcon: {
    fontSize: 16,
    marginBottom: 2,
  },
  textField: {
    display: 'block',
    height: 21,
    marginTop: '-6px',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
    },
    '& div': {
      margin: 0,
      width: '100%',
      textAlign: 'left',
    },
    '& div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  textFieldMulti: {
    '& div': {
      width: '100%',
      padding: 0,
    },
    '& label': {
      color: '#111',
    },
    '& div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  textFieldMultiTxt: {
    '& textarea': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    marginRight: 16,
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function CustomerDetailAddress(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const { rerender, rerenderKey } = useRerender();

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // 郵便番号検索ウィンドウ（表示用）
  const [addressOpen, setAddressOpen] = useState(false);
  const onAddressOpen = () => setAddressOpen(true);
  const onAddressClose = () => setAddressOpen(false);
  // 郵便番号検索ウィンドウ（入力値）
  const [searchZipsInitialParam, setSearchZipsInitialParam] = useState({
    code: input.detail.zip,
    address: `${input.detail.prefecture || ''}${input.detail.address1 || ''}${input.detail.address2 || ''}`,
  });
  // 郵便番号検索ウィンドウ（出力値を設定）
  const searchZipsOnChangeFunc = (row) => {
    const address = {
      zip: row.zip,
      prefecture: row.prefecture,
      address1: row.city,
      address2: row.town,
    };
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data,
      ...address,
    });
    // 変更した値をセット
    input.setDetail((s) => ({
      ...s,
      ...address,
    }));
    rerender();
  };

  // フォーカスが外れた時
  const onBlur = (e) => {
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  // 項目選択時に更新 SP
  const onSelect = (e) => {
    const { name, value } = e.target;
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, [name]: value });
    // 変更した値をセット
    input.setDetail({ ...input.detail, [name]: value });
  };

  // 都道府県 PC
  const selectChange = (e) => {
    const { name, value } = e.target;
    // 変更した値をセット
    input.setDetail({ ...input.detail, [name]: value });
  };
  const selectBlur = (e) => {
    const { name, value } = e.target;
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data,
      [name]: value,
    });
  };
  const customerSelectList = {
    prefectureItem: {
      type: 'selectBlank',
      obj: {
        menus: PREFECTURES_CODE,
        title: '都道府県',
      },
      state: input.detail.prefecture,
      set: selectBlur,
      change: selectChange,
    },
  };

  const getBox1 = () => {
    return (
      <TextBaseField
        value={input.detail.zip}
        className={classes.textField}
        name="zip"
        onBlur={onBlur}
        validator={validateFormPostalCode()}
        errorCallback={input.errorCallback('zip')}
      />
    );
  };

  const getBox2 = () => {
    return (
      <Select
        key={rerenderKey}
        name="prefecture"
        onChange={onSelect}
        defaultValue={input.detail.prefecture}
      >
        <MenuItem disabled value={undefined}>未選択</MenuItem>
        {Object.entries(PREFECTURES_CODE).map(([key, val]) => {
          return (
            <MenuItem key={key} value={val}>
              {val}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getBox3 = () => {
    return (
      <TextBaseField
        key={rerenderKey}
        defaultValue={input.detail.address1}
        className={classes.textField}
        name="address1"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('address1')}
      />
    );
  };

  const getBox4 = () => {
    return (
      <TextBaseField
        key={rerenderKey}
        defaultValue={input.detail.address2}
        className={classes.textField}
        name="address2"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 250 })}
        errorCallback={input.errorCallback('address2')}
      />
    );
  };

  const getBox5 = () => {
    return (
      <TextBaseField
        key={rerenderKey}
        defaultValue={input.detail.building}
        className={classes.textField}
        name="building"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('building')}
      />
    );
  };

  const getAddress = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid className={classes.address}>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid className={classes.address}>
            <Grid className={classes.addressBox}>
              <Grid className={classes.address1}>
                <Typography className={baseClasses.title6}>
                  郵便番号
                </Typography>
              </Grid>
              <Grid className={classes.address2}>
                <Typography className={baseClasses.title6}>
                  都道府県
                </Typography>
              </Grid>
              <Grid className={classes.address3}>
                <Typography className={baseClasses.title6}>
                  市区町村
                </Typography>
              </Grid>
              <Grid className={classes.address4}>
                <Typography className={baseClasses.title6}>
                  以下住所
                </Typography>
              </Grid>
              <Grid className={classes.address5}>
                <Typography className={baseClasses.title6}>
                  マンション、部屋番号
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.addressBox}>
              <Grid className={classes.address1}>
                <Typography className={classes.addressLbl}>
                  〒
                </Typography>
                {getBox1()}
              </Grid>
              <Grid className={classes.address2}>
                <CustomBlankSelect initial={customerSelectList.prefectureItem} />
              </Grid>
              <Grid className={classes.address3}>
                {getBox3()}
              </Grid>
              <Grid className={classes.address4}>
                {getBox4()}
              </Grid>
              <Grid className={classes.address5}>
                {getBox5()}
              </Grid>
            </Grid>
            <Grid className={classes.addressBox}>
              <Grid className={classes.addressButton}>
                <Button className={classes.button} onClick={onAddressOpen}>
                  <Typography className={baseClasses.title4}>
                    〒
                  </Typography>
                  <Typography className={classes.buttonTxt}>
                    郵便番号検索
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  // SP 表示用
  const list = [
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '都道府県',
      val: input.detail.prefecture,
      content: getBox2,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '市区町村',
      val: input.detail.address1,
      content: getBox3,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '以下住所',
      val: input.detail.address2,
      content: getBox4,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'マンション、部屋番号',
      val: input.detail.building,
      content: getBox5,
    },
  ];

  const getStatusPc = () => {
    return (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          現住所
        </Typography>
        {getAddress()}
      </Grid>
    );
  };

  const getStatusSp = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Grid className={classes.addressBox}>
              <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                住所
              </Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              住所
            </Typography>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
              >
                <Button
                  className={classes.listBtn}
                  onClick={onAddressOpen}
                >
                  <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                    郵便番号
                  </span>
                  <span
                    className={`${baseClasses.title4} ${classes.listVal}`}
                  >
                    {input.detail.zip}
                  </span>
                </Button>
              </ListItem>
              {list.map((item) => {
                return (
                  <ListItem
                    key={item.lbl}
                    className={classes.listItem}
                  >
                    {input.dataInput.parts(item)}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        );
    }
  };

  if (isSp) {
    contents = (
      <Grid>
        {getStatusSp()}
      </Grid>
    );
  } else {
    contents = (
      <Grid>
        {getStatusPc()}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <SearchZipsContainer
        onClickTr={searchZipsOnChangeFunc}
        initialParam={searchZipsInitialParam}
        setInitialParam={setSearchZipsInitialParam}
        addressOpen={addressOpen}
        onAddressClose={onAddressClose}
      />
    </Grid>
  );
}
