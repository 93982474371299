import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '../../common/modal';
import ConfirmDialog from '../../common/modal/confirmModal';
import commonStyles from '../../styles';
import BulkChangeWindowRank from './parts/bulkChangeWindowRank';
import BulkChangeWindowMail from './parts/bulkChangeWindowMail';
import customerPostUpdateStatusMultiCustomerApi from '../../../apis/customer/customerPostUpdateStatusMultiCustomerApi';
import { useLoading } from '../../../hooks';
import { BULK_MANAGE } from '../../../constants/loading';

const useStyles = makeStyles({
  txt1: {
    marginBottom: 8,
  },
  txt2: {
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginBottom: 8,
  },
});

export default function BulkChangeWindow(props) {
  const {
    open,
    onClose,
    choice,
    check,
    getComponent,
    selectedCustomerId,
    updateStart,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const [star, setStar] = useState(5);
  const [radio, setRadio] = useState();
  const [open2, setOpen] = useState(false);

  const { addLoading, removeLoading, hasLoading } = useLoading();

  // 確認モーダル
  const changeStart = async () => {
    addLoading(BULK_MANAGE);
    setOpen(false);
    const postData = {
      customerIds: selectedCustomerId,
    };
    if (check.c1) postData.userLevel = star;
    if (check.c2) postData.mailStatusCode = radio;
    await customerPostUpdateStatusMultiCustomerApi(postData);
    await updateStart();
    onClose();
    removeLoading(BULK_MANAGE);
  };

  const handleClose2 = () => {
    setOpen(false);
  };

  // ボタンの必須制御
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // ボタンの必須制御
    if (check.c1) {
      setDisabled(false);
    } else if (check.c2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  const footerButtons = useMemo(() => {
    return [
      {
        label: 'キャンセル',
        type: 'secondary',
        onClick: onClose,
      },
      {
        label: '変更',
        onClick: () => setOpen(true),
        disabled,
      },
    ];
  }, [disabled]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        width={640}
        title="一括変更ウィンドウ"
        buttons={footerButtons}
        className={hasLoading(BULK_MANAGE) && baseClasses.loading}
      >
        <Typography className={`${baseClasses.title2} ${classes.txt1}`}>
          {choice}件選択中
        </Typography>
        <Typography className={classes.txt2}>
          変更する項目、変更後の値を選択してください。
        </Typography>
        <BulkChangeWindowRank
          getComponent={getComponent}
          checked={check.c1}
          setChecked={check.sc1}
          star={star}
          setStar={setStar}
        />
        <BulkChangeWindowMail
          getComponent={getComponent}
          checked={check.c2}
          setChecked={check.sc2}
          radio={radio}
          setRadio={setRadio}
          isRank2={star === 2}
        />
      </Dialog>
      <ConfirmDialog
        open={open2}
        onCancel={handleClose2}
        title="OKを押すと処理が開始します。"
        content="本当に変更してもよろしいでしょうか？"
        onSubmit={changeStart}
      />
    </>
  );
}
