import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableSortLabel,
  RadioGroup,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import ResTableRow from './resTableRow';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: 8,
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    border: 'solid 1px #C8C8C8',
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  cell: {
    padding: '8px',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    display: 'inline-block',
    borderBottom: 'none',
  },
  header: {
    borderBottom: 'solid 2px #C8C8C8',
  },
  headerTxt: {
    color: '#8C8C8C',
  },
  icon: {
    '& .MuiTableSortLabel-icon': {
      fontSize: '12px',
      marginRight: '-3px',
    },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.primaryColor,
    },
  },
  tableBody: {
    width: 0,
  },
  radioGroup: {
    width: '100%',
  },
  tableRow: {
    display: 'block',
  },
  headerCell: { whiteSpace: 'pre' },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { cells, order, orderBy, onRequestSort } = props;
  const common = commonStyles();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {cells.map(c => (
          <TableCell
            key={c.id}
            style={c.style}
            className={`${classes.cell} ${classes.headerCell}`}
            sortDirection={orderBy === c.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={createSortHandler(c.id)}
              className={classes.icon}
            >
              <Typography
                className={`${common.title4} ${classes.headerTxt}`}
              >
                {c.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function ResTable(props) {
  const {
    responseHistories,
    selectedValue,
    onChangeSelectedValue,
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('responsibleUserHistoriesId');
  const classes = useStyles();

  const allHeader = [
    { id: 'radio', label: '', style: { width: '4%' } },
    { id: 'effectAt', label: '反響日', style: { width: '10%' } },
    { id: 'guidanceStatusCode', label: '区分', style: { width: '7%' } },
    { id: 'responseTypeId1', label: '媒体（大）', style: { width: '12%' } },
    { id: 'responseTypeId2', label: '媒体（中）', style: { width: '12%' } },
    { id: 'responseTypeId3', label: '媒体（小）', style: { width: '12%' } },
    { id: 'responseTypeId4', label: '媒体（細）', style: { width: '12%' } },
    { id: 'divisionName', label: '組織', style: { width: '12%' } },
    { id: 'responsibleUserName', label: '担当', style: { width: '12%' } },
    { id: 'responseId', label: '反響ID\n(非表示)', style: { width: '7%' } },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <EnhancedTableHead
          cells={allHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody className={classes.tableBody}>
          <FormControl className={classes.formControl}>
            <RadioGroup onChange={onChangeSelectedValue} className={classes.radioGroup}>
              {stableSort(responseHistories, getComparator(order, orderBy))
                .map((row) => {
                  return (
                    <ResTableRow
                      row={row}
                      key={row.responseId}
                      selectedValue={selectedValue}
                      className={classes.resTableRow}
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ResTable;
