import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import NormalTooltip from '../../../styles/tooltip';

const useStyles = makeStyles({
  checkbox: {
    marginRight: '8px',
    '&[data-before]::before': {
      content: 'attr(data-before)',
      display: 'inline-block',
      marginRight: '16px',
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '700',
    },
    [commonTheme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: 16,
      borderBottom: '1px solid #F3F3F3',
      width: '100%',
    },
    '& span': {
      fontSize: '13px',
    },
  },
  tooltip: {
    whiteSpace: 'pre-wrap',
    maxWidth: 800,
  },
});

function SearchPartsBoolean(props) {
  const { values } = props;
  const classes = useStyles();
  return (
    <NormalTooltip title={values.tooltip ? values.tooltip : ''} classes={{ tooltip: classes.tooltip }}>
      <FormControlLabel
        className={`${classes.checkbox} ${values.className ? values.className : ''}`}
        data-before={values.before}
        control={
          <Checkbox
            checked={!!values.state}
            onChange={(e) => { return values.setState(e.target.checked ? 1 : 0); }}
            name={values.label}
            color="primary"
            disabled={values.disabled}
          />
        }
        label={values.label}
      />
    </NormalTooltip>
  );
}

export default SearchPartsBoolean;
