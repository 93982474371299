import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerProductsRecommendationsSlice = createSlice({
  name: 'customerProductsRecommendations',
  initialState: { customerProductsRecommendations: initialState },
  reducers: {
    customerProductsRecommendationsApi: (state, data) => {
      state.customerProductsRecommendations = data.payload;
    },
  },
});

export const { customerProductsRecommendationsApi } = customerProductsRecommendationsSlice.actions;

export default customerProductsRecommendationsSlice.reducer;
