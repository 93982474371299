import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../styles';
import commonTheme from '../styles/theme';
import InstructionListTabs from './parts/instructionListTabs';
import InstructionListTabsSp from './parts/sp/instructionListTabsSp';
import { setRequestParams, setNotDirects } from '../../store/direct/directGetListSlice';
// import { useDirectContext } from '../../containers/instructionList/context';
import { getLocalStorage, setLocalStorage } from '../../commonFunction';
import { LIMIT_INSTRUCTION_LIST } from '../../constants/localStorage';

const useStyles = makeStyles({
  root: {
    width: 'calc(100vw - 240px)',
    [commonTheme.breakpoints.up('md')]: {
      padding: 16,
    },
  },
  rootSp: {
    [commonTheme.breakpoints.up('md')]: {
      padding: 16,
    },
  },
});

function InstructionList(props) {
  const {
    selectCustomer,
    deleteDirects,
  } = props;

  // const {
  //   directParams,
  // } = useDirectContext();

  const classes = useStyles();
  const baseClasses = commonStyles();
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  const [selectTab, setSelectTab] = useState(1);
  const [resetRender, setResetRender] = useState(1);
  const requestParams = useSelector((state) => state.directList.initialRequestParams);
  const responseHeader = useSelector((state) => state.responseHeader);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(getLocalStorage(LIMIT_INSTRUCTION_LIST, 20));

  const getNewRequest = (newParams) => {
    // getDirectList(newParams);
    dispatch(setRequestParams(newParams));
  };

  const setRowPages = (pageCount) => {
    setRowsPerPage(pageCount);
    setLocalStorage(LIMIT_INSTRUCTION_LIST, pageCount);
  };

  useEffect(() => {
    setPage(0);
  }, [selectCustomer]);

  useEffect(() => {
    const customerIds = [];
    if (selectCustomer.length > 0) {
      for (let i = 0; selectCustomer.length > i; i += 1) {
        customerIds.push(selectCustomer[i].customerId);
      }
    }
    // PC版 - 反響ツリーに紐づく顧客のID(customerId)で 【direct001】指示一覧取得 （営業員単位）を叩く
    if (customerIds.length > 0 && customerIds[0] !== 0) {
      const copyObj = Object.assign({}, requestParams);
      copyObj.customerIds = customerIds;
      copyObj.directTabCode = selectTab;
      copyObj.offset = page * rowsPerPage;
      copyObj.limit = rowsPerPage;
      // getDirectList(copyObj);
      dispatch(setRequestParams(copyObj));

    // SP版 - ログインしている社員ID(userId)で 【direct001】指示一覧取得 （営業員単位）を叩く
    } else if (isSP && responseHeader.userId) {
      const copyObj = Object.assign({}, requestParams);
      copyObj.userId = responseHeader.userId;
      copyObj.directTabCode = selectTab;
      copyObj.limit = 9999;
      // copyObj.offset = 0;
      // getDirectList(copyObj);
      dispatch(setRequestParams(copyObj));
    } else if (customerIds.length === 0) {
      dispatch(setNotDirects());
    }
  }, [selectCustomer, selectTab, resetRender, responseHeader, page, rowsPerPage]);

  return (
    <Grid className={isSP ? classes.rootSp : classes.root}>
      {isSP ? (
        // <InstructionListTabsSp list={list} />
        <InstructionListTabsSp
          setSelectTab={setSelectTab}
          setResetRender={setResetRender}
          resetRender={resetRender}
        /> // component内でstate.directList.responseParamsをimportして下さい。
      ) : (
        <>
          <Typography className={`${baseClasses.title2} ${classes.title}`}>指示一覧</Typography>
          <InstructionListTabs
            setSelectTab={setSelectTab}
            setResetRender={setResetRender}
            resetRender={resetRender}
            deleteDirects={deleteDirects}
            getNewRequest={getNewRequest}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowPages={setRowPages}
          />
        </>
      )}
    </Grid>
  );
}

export default InstructionList;
