import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const hpinfoUpdateStatusSlice = createSlice({
  name: 'hpinfoUpdateStatus',
  initialState: { hpinfoUpdateStatus: initialState },
  reducers: {
    hpinfoUpdateStatusApi: (state, data) => {
      state.hpinfoUpdateStatus = data.payload;
    },
  },
});

export const { hpinfoUpdateStatusApi } = hpinfoUpdateStatusSlice.actions;

export default hpinfoUpdateStatusSlice.reducer;
