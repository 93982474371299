import React from 'react';
import { FormControl, FormLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { classNames } from '../../commonFunction';
import positionChange from '../../commonFunction/positionChange';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    // padding: '0px 8px 2px',
    '& > .MuiInput-root': {
      minWidth: 120,
    },
    '& .MuiSelect-select': {
      paddingLeft: 8,
    },
    '& .MuiInput-underline:not(.Mui-disabled):before, .MuiInput-underline:before, div[class*="makeStyles-wrap"]':
      {
        borderBottom: 0,
      },
    '& label': {
      fontWeight: 700,
      lineHeight: '120%',
      fontSize: '0.775rem',
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'initial',
      '& + .MuiInput-formControl': {
        marginTop: 4,
      },
    },
    '& .MuiInput-input': {
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: `${theme.palette.primaryColor}29`,
      },
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  required: {
    color: '#db3131',
  },
  sfa: {
    backgroundColor: '#e3f2fd',
  },
  keymalis: {
    backgroundColor: '#e8f5e9',
  },
  daijin: {
    backgroundColor: '#fff3e0',
  },
}));

// Example: props{onChangeFunction, state, { menus: { 0: '無効', 1: '有効' }, title: '非表示フラグ',}}
function CustomSelect(props) {
  const { initial } = props;
  const classes = useStyles();
  const initialStyle = {
    margin: initial.margin ? initial.margin : null,
    color: initial.fontColor ? initial.fontColor : null,
    fontWeight: initial.fontBold ? initial.fontBold : null,
    width: initial.width ? initial.width : null,
    borderBottom: initial.valid ? '1px solid red' : null,
    fontSize: initial.fontSize ? initial.fontSize : null,
  };

  const menusKeys = 'sort' in initial.obj ? initial.obj.sort : Object.keys(initial.obj.menus);
  switch (`${initial.set.name}`) {
    case 'handleClick':
      return (
        <FormControl className={`${classes.root}`}>
          {initial.obj.title && (
            <FormLabel>
              {initial.obj.title}
              {initial.required && (
                <span className={classes.required}>＊</span>
              )}
            </FormLabel>
          )}
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={initial.state}
            disableUnderline
            disabled
            onClick={() => {
              initial.set(initial);
            }}
            onOpen={positionChange}
          />
        </FormControl>
      );
    default:
      return (
        <FormControl className={classes.root}>
          {initial.obj.title && (
            <FormLabel
              className={classNames(
                initial?.key === 'sfa' ? classes.sfa : '',
                initial?.key === 'kymalis' ? classes.keymalis : '',
                initial?.key === 'daijin' ? classes.daijin : '',
              )}
            >
              {initial.obj.title}
              {initial.required && (
                <span className={classes.required}>＊</span>
              )}
            </FormLabel>
          )}
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={!initial.state && !initial.defaultValue ? 0 : initial.state}
            defaultValue={
              initial.defaultValue ? initial.defaultValue : initial.state
            }
            disableUnderline={!initial.underline}
            onChange={(e) => {
              initial.set(e, initial);
            }}
            style={initialStyle}
            onOpen={positionChange}
          >
            {!initial.defaultValue && (
              <MenuItem
                disabled
                value={0}
              >
                未選択
              </MenuItem>
            )}
            {menusKeys.map((data) => (
              <MenuItem value={data} key={data}>
                {initial.obj.menus[data]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
  }
}

// prevStateとnextStateを比較し、Stateに変更が無ければメモの値を返す。
// const areEqual = (prevProps, nextProps) => {
//   return Object.is(prevProps.initial.state, nextProps.initial.state);
// };

export default React.memo(CustomSelect);
