import client from '../httpClient';

/**
 * @typedef { import("./getSettingApiTypes").SettingGetResponse } SettingGetResponse
 * @type { ( areaCode: number ) => Promise<SettingGetResponse> }
 */
const getSettingApi = async (areaCode) => {
  const result = await client.get(`/division-masters/setting?areaCode=${areaCode}`);
  return result;
};

export default getSettingApi;
