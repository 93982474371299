import React, { useState } from 'react';
import {
  List,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import commonStyles from '../../../../styles';
import ResHouseListItem from './resHouseListItem';

const useStyles = makeStyles({
  list: {
    padding: '0 0 0 16px',
    background: '#fff',
  },
});

function ResHouseList(props) {
  const { data, deleteAndUpdateHouseList } = props;
  const classes = useStyles();
  // const baseClasses = commonStyles();

  const [swipe, setSwipe] = useState(null); // スワイプ用

  return (
    <List className={classes.list}>
      {data.map((row, index) => {
        return (
          <ResHouseListItem
            row={row}
            index={index}
            key={row.buildingNumber}
            swipe={swipe}
            setSwipe={setSwipe}
            deleteAndUpdateHouseList={deleteAndUpdateHouseList}
          />
        );
      })}
    </List>
  );
}

export default ResHouseList;
