import React from 'react';
import {
  Checkbox,
  makeStyles,
  TableBody,
  TableCell as MuiTableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  self: { background: '#C8C8C8', padding: 2 },
  tableContainer: {
    overflow: 'auto',
    height: 'calc(100vh - 290px)',
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    background: '#fafafa',
    zIndex: 2,
  },
  cellLeftTop: {
    position: 'sticky',
    top: 0,
    left: 0,
    background: '#fafafa',
    zIndex: 2,
  },
  headCell: {
    writingMode: 'vertical-lr',
    padding: '2px 2px 6px',
    borderRight: '1px #c8c8c8 solid',
    '& p': { display: 'inline', verticalAlign: 'inherit' },
  },
  bodyHeadCell: {
    padding: '2px 6px 2px 2px',
    borderRight: '1px #c8c8c8 solid',
    whiteSpace: 'nowrap',
    position: 'sticky',
    background: '#fafafa',
    zIndex: 1,
    left: 0,
    '& p': { display: 'inline', verticalAlign: 'inherit' },
  },
  cell: { padding: 2, borderRight: '1px #c8c8c8 solid' },
  title4: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  table: { width: 'fit-content', zIndex: 0 },
});

const TableCell = React.memo((props) => {
  return <MuiTableCell {...props} />;
});

const TableHeadCell = React.memo(({
  columnNewStoreDivision,
  isCheckedColumnAndRow,
  isDisabledColumnAndRow,
  onChangeColumnAndRow,
  classes,
  common }) => {
  const { divisionId, divisionSubName } = columnNewStoreDivision;
  const isDisabled = isDisabledColumnAndRow(divisionId);
  const isChecked = isCheckedColumnAndRow(divisionId);

  return (
    <TableCell key={`head${divisionId}`} className={classes.headCell}>
      <Checkbox
        disabled={isDisabled}
        value={divisionId}
        checked={isChecked}
        onChange={onChangeColumnAndRow}
      />
      <Typography className={`${common.title4} ${classes.nowrap}`}>
        {divisionSubName}
      </Typography>
    </TableCell>
  );
});

const TableRowItem = React.memo(({ rowOldStoreDivision, ...rest }) => {
  const {
    divisionId: rowOldStoreDivisionId,
    divisionSubName,
  } = rowOldStoreDivision;
  const isCheckedRow = rest.isCheckedColumnAndRow(rowOldStoreDivisionId);
  const isDisabledRow = rest.isDisabledColumnAndRow(rowOldStoreDivisionId);

  return (
    <TableRow key={`row${rowOldStoreDivisionId}`} {...rest}>
      <TableCell className={rest.classes.bodyHeadCell}>
        <Checkbox
          disabled={isDisabledRow}
          value={rowOldStoreDivisionId}
          checked={isCheckedRow}
          onChange={rest.onChangeColumnAndRow}
        />
        <Typography className={rest.common.title4}>
          {divisionSubName}
        </Typography>
      </TableCell>
      {rest.areaListDivisions.map((columnNewStoreDivision) => {
        const { divisionId: columnNewStoreDivisionId } = columnNewStoreDivision;
        const cellId = {
          rowOldStoreDivisionId,
          columnNewStoreDivisionId,
        };

        // 旧店舗名(行)と新店舗名(列)が同じ場合はチェックボックスを表示しない
        if (rowOldStoreDivisionId === columnNewStoreDivisionId) {
          return (
            <TableCell
              key={`body${columnNewStoreDivisionId}_${rowOldStoreDivisionId}`}
              className={rest.classes.self}
            />
          );
        }

        return (
          <TableCell
            key={`body${columnNewStoreDivisionId}_${rowOldStoreDivisionId}`}
            className={rest.classes.cell}
          >
            <Checkbox
              disabled={rest.isDisabledCell(cellId)}
              value={`${rowOldStoreDivisionId}_${columnNewStoreDivisionId}`}
              checked={rest.isCheckedCell(cellId)}
              onChange={(e) => rest.onChangeCell(cellId, e)}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
});

function DivisionInformationTable(props) {
  /**
    * @typedef {{
    *   areaCode: number;
    *   divisionId: number;
    *   divisionSubName: string;
    *   divisionSubName2: string;
    * }} AreaListDivision
    * @typedef {{
    *   rowOldStoreDivisionId: number;
    *   columnNewStoreDivisionId: number;
    * }} CellId
    * @typedef { import('react').ChangeEvent<HTMLInputElement> } E
    * @type {{
    *   areaListDivisions: AreaListDivision[];
    *   isCheckedCell: (cellId: CellId ) => boolean;
    *   onChangeCell: (cellId: CellId, e: E ) => void;
    *   isCheckedColumnAndRow: (divisionId: number) => boolean;
    *   onChangeColumnAndRow: (e: E) => void;
    *   isDisabledCell: (cellId: CellId ) => boolean;
    * }}
    */
  const {
    areaListDivisions,
    isCheckedCell,
    onChangeCell,
    isCheckedColumnAndRow,
    onChangeColumnAndRow,
    isDisabledCell,
    isDisabledColumnAndRow,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={`${classes.cell} ${classes.cellLeftTop}`}>
              <Typography align="right" className={classes.title4}>
                新店舗
              </Typography>
              <br />
              <Typography className={classes.title4}>旧店舗</Typography>
            </TableCell>
            {areaListDivisions.map((columnNewStoreDivision) => (
              <TableHeadCell
                key={`head${columnNewStoreDivision.divisionId}`}
                columnNewStoreDivision={columnNewStoreDivision}
                isCheckedColumnAndRow={isCheckedColumnAndRow}
                isDisabledColumnAndRow={isDisabledColumnAndRow}
                onChangeColumnAndRow={onChangeColumnAndRow}
                classes={classes}
                common={common}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {areaListDivisions.map((rowOldStoreDivision) => (
            <TableRowItem
              key={rowOldStoreDivision.divisionId}
              rowOldStoreDivision={rowOldStoreDivision}
              areaListDivisions={areaListDivisions}
              isCheckedCell={isCheckedCell}
              onChangeCell={onChangeCell}
              isDisabledCell={isDisabledCell}
              isCheckedColumnAndRow={isCheckedColumnAndRow}
              onChangeColumnAndRow={onChangeColumnAndRow}
              isDisabledColumnAndRow={isDisabledColumnAndRow}
              classes={classes}
              common={common}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default React.memo(DivisionInformationTable);
