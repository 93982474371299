import React from 'react';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import commonStyles from '../../../../styles';
import Calendar from '../../../calendar';
// import CustomEditDialog from '../../../../eleCommon/sp/customEditDialog';

const useStyles = makeStyles({
  root: {
    display: 'none',
    background: '#fff',
    '&.active': {
      display: 'block',
    },
    '& .MuiDialog-root': {
      zIndex: '12000!important',
    },
  },
  ttl: {
    flexShrink: 0,
    whiteSpace: 'initial',
    '& span': {
      color: '#D83420',
    },
  },
  value: {
    marginTop: 8,
    fontWeight: 'bold',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
    textTransform: 'none',
    borderBottom: '1px solid #F3F3F3',
    borderRadius: 0,
    width: '100%',
    padding: '16px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    '& .MuiButton-label': {
      display: 'block',
    },
  },
  calendar: {
    '& div': {
      border: 'none',
      padding: '2px 0 0',
      justifyContent: 'flex-start',
    },
    '& span': {
      padding: 0,
      fontSize: 13,
      lineHeight: '1.6',
    },
    '& .MuiGrid-container': {
      '& label': {
        // borderBottom: '1px solid #C8C8C8',
        width: '80%',
        textAlign: 'left',
      },
    },
  },
  calendarItem: {
    display: 'flex',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    right: '14px',
    bottom: '-3px',
    '& .MuiButton-label': {
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem',
      },
    },
  },
  dialog: {
    zIndex: '1352!important',
  },
});

function DoNotChaseReasonCalendarSp(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル用
  // const [open, setOpen] = useState(false);

  const getDate = () => {
    switch (values.state) {
      case '':
        return '未設定';
      default:
        break;
    }
    return values.state;
  };

  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: getDate(), // state
      setState: values.setState,
    },
  ];

  const deleteHandler = (id, arr) => {
    arr.setState('');
  };

  // const content = (
  //   <Grid className={classes.calendar}>
  //     {datalist.map((array) => {
  //       return (
  //         <Grid key={array.id} className={classes.calendarItem}>
  //           <Calendar key={array.id} data={array} />
  //           {values.state ? (
  //             <Button
  //               onClick={() => deleteHandler(array.id, array)}
  //               className={classes.closeIcon}
  //             >
  //               <CloseIcon />
  //             </Button>
  //           ) : ''}
  //         </Grid>
  //       );
  //     })}
  //   </Grid>
  // );

  return (
    <Grid className={`${classes.root} ${classes.btn} ${values.active && 'active'}`}>
      <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
        {values.label}
      </Typography>
      <Grid className={classes.calendar}>
        {datalist.map((array) => {
          return (
            <Grid key={array.id} className={classes.calendarItem}>
              <Calendar key={array.id} data={array} />
              {values.state ? (
                <Button
                  onClick={() => deleteHandler(array.id, array)}
                  className={classes.closeIcon}
                >
                  <CloseIcon />
                </Button>
              ) : ''}
            </Grid>
          );
        })}
      </Grid>
      {/* <Button className={classes.btn} onClick={() => { setOpen(!open); }}>
        <Typography className={`${baseClasses.title6} ${classes.value}`}>{getDate()}</Typography>
      </Button>
      <CustomEditDialog
        type="button2"
        size="small"
        open={open}
        setOpen={setOpen}
        content={content}
        lbl={values.label}
        style={classes.dialog}
      /> */}
    </Grid>
  );
}

export default DoNotChaseReasonCalendarSp;
