import { useSelector } from 'react-redux';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import RegionTabs from './parts/regionTabs';
import RailwayList from './parts/railwayList';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  onlyPc: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none!important',
    },
  },
  title: {
    /* Head */
    position: 'static',
    width: '52px',
    height: '16px',
    left: '0px',
    top: '4px',

    /* identical to box height, or 16px */
    display: 'flex',
    alignItems: 'center',

    /* OH-Black */
    color: '#333333',

    /* Inside auto layout */
    flex: 'none',
    order: '0',
    flexGrow: '0',
    margin: '0px 10px',
  },
  headline: {
    [commonTheme.breakpoints.down('sm')]: {
      height: 'auto',
      position: 'relative',
      padding: '19px 0 8px',
      margin: '0px',
      top: '0px',
      background: '#fff',
      width: '100%',
      textAlign: 'center',
    },
  },
  headlineTtl: {
    [commonTheme.breakpoints.down('sm')]: {
      margin: '0 auto',
      fontSize: '20px',
      lineHeight: '1',
      textAlign: 'center',
    },
  },
  mainGrid: {
    /* OH-White */
    background: '#FFFFFF',
    boxSizing: 'border-box',
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      left: '0px',
      width: '240px',
      height: '684px',
      top: '708px',
      /* OH-Gray */
      border: '1px solid #C8C8C8',
      /* Inside auto layout */
      flex: 'none',
      order: '1',
      flexGrow: '0',
      margin: '0px 0px',
    },
  },
  mainGridInner: {
    height: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh * 1.25 - 127px)',
    },
  },
});

export default function SelectRailway(props) {
  const {
    selectedItems,
    railways,
    clickAreaTabFunc,
    clickRailwayFunc,
    setOpenedStations,
    setOpenStations,
  } = props;
  const commonClasses = commonStyles();
  const classes = useStyles();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <>
      {!isSp && (
        <Grid className={`${classes.title} ${classes.headline}`}>
          <Typography className={`${commonClasses.title4} ${classes.headlineTtl}`}>
            沿線選択
          </Typography>
        </Grid>
      )}
      <Grid className={classes.mainGrid}>
        <Grid className={classes.mainGridInner}>
          <RegionTabs
            selectedArea={selectedItems.areaCode}
            clickAreaTabFunc={clickAreaTabFunc}
          />
          <RailwayList
            selectedItems={selectedItems}
            railways={railways}
            clickRailwayFunc={clickRailwayFunc}
            setOpenedStations={setOpenedStations}
            setOpenStations={setOpenStations}
          />
        </Grid>
      </Grid>
    </>
  );
}
