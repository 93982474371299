import React from 'react';
import { Grid, ListItem, ListItemText, Link } from '@material-ui/core';
import { URL_PATHS } from '../../constants/urls';

function DeveloperLink() {
  return (
    <Grid>
      <p>メニュー</p>
      {URL_PATHS.map((data) => (
        <Link
          key={data.title}
          href={data.path}
          style={{ display: 'block', textDecoration: 'none', color: '#616161' }}
          target="_blank"
        >
          <ListItem button>
            <ListItemText
              color="inherit"
              primary={data.title}
              className="textGray"
            />
          </ListItem>
        </Link>
      ))}
      <div>version 1.3.8 / timestamp</div>
    </Grid>
  );
}

export default DeveloperLink;
