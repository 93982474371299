import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const webResJudgeHandlingSlice = createSlice({
  name: 'webResJudgeHandling',
  initialState: { webResJudgeHandling: initialState },
  reducers: {
    webResJudgeHandlingApi: (state, data) => {
      state.webResJudgeHandling = data.payload;
    },
  },
});

export const { webResJudgeHandlingApi } = webResJudgeHandlingSlice.actions;

export default webResJudgeHandlingSlice.reducer;
