import React, { useState } from 'react';
import {
  Grid,
  Typography,
  // TextField,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomSimpleDrawer from '../../../../eleCommon/sp/customSimpleDrawer';
import DoNotChaseReasonList from './doNotChaseReasonList';
import commonStyles from '../../../../styles';
import commonTheme from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '10px 8px 9px',
    },
  },
  ttl: {
    width: 120,
    marginRight: 8,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
    [commonTheme.breakpoints.up('md')]: {
      minHeight: 20,
      width: 428,
      marginLeft: 16,
      padding: 0,
      '& .MuiButton-label': {
        display: 'block',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
      borderBottom: '1px solid #F3F3F3',
      borderRadius: 0,
      width: '100%',
      padding: '16px',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  input: {
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  drawer: {
    zIndex: '1352!important',
  },
}));

function DoNotChaseReasonButton(props) {
  const { setValue, setState } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル用
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('-');

  return (
    <Grid className={classes.root}>
      <Button className={classes.btn} onClick={() => { setOpen(!open); }}>
        <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
          追わない理由
          <span>＊</span>
        </Typography>
        <Typography className={`${baseClasses.title6}`}>{label}</Typography>
      </Button>
      <CustomSimpleDrawer
        open={open}
        setOpen={setOpen}
        title="追わない理由"
        className={classes.drawer}
        list={<DoNotChaseReasonList
          setValue={setValue}
          label={label}
          setLabel={setLabel}
          setState={setState}
          setOpen={setOpen}
        />}
      />
    </Grid>
  );
}

export default DoNotChaseReasonButton;
