import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import getTreeObject from '../commonFunc/getTreeObject';
import getDataFromAllCustomer from '../commonFunc/getDataFromAllCustomer';
import { useCustomerTree } from '../../../../containers/common/customerTree/context';
import {
  createTreeList,
  getCustomers,
  getCustomersIsMulti,
  removeDuplicateCustomerArray,
  sortCustomers,
  getDivisionIdFlat,
} from '../commonFunc/treeHelpers';
import { Tree, TREE_TYPES } from '../../tree';
import deletePathParameter from '../../../../commonFunction/deletePathParameter';
import getTreeObjectByUniqueId from '../commonFunc/getTreeObjectByUniqueId';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxHeight: '100%',
    minHeight: '30%',
    height: 302,
    // height: 244,
    overflow: 'scroll',
  },
});

// Fix: Uncaught (in promise) TypeError:
// setSelectUser/setSelectCustomer is not a function.
const defaultSetter = () => {};

function TreeList() {
  const {
    list,
    setSelectUser = defaultSetter,
    setSelectDivision = defaultSetter,
    setSelectCustomer = defaultSetter,
    setSelectUniqueId = defaultSetter,
    searchObj,
    defaultUserId,
    defaultDivisionId,
    defaultUniqueId,
    setSearchObj,
    targetFlgList,
    displayCustomer,
    searchHeader,
    keyword,
    isMulti,
    isDirect,
    sortType,
    treeHeight,
  } = useCustomerTree();
  const classes = useStyles();

  const rootRef = useRef(null);
  const isSearch = useRef(false);
  // 始めのTree描画時
  // 参考のため残すが、不要なら削除したい
  // const isFirst = useRef(true);
  const customersRef = useRef([]);

  // APIから取得データを加工する
  const treeList = useMemo(() => {
    // isFirst.current = true;
    return createTreeList(list, null, {
      ...targetFlgList,
      displayCustomer,
      ...searchHeader,
      isDirect,
    });
  }, [list]);

  const treeSearch = useMemo(() => {
    // TODO 顧客検索のみしか対応してない
    // 他のlabelを検索したい場合typeを変更すればよい
    // TODO キーワードがCSS追加？特別表示
    if (!displayCustomer) return null;
    return {
      keyword,
      type: displayCustomer ? 'customer' : null,
    };
  }, [keyword]);

  const rerenderDivisionIds = useMemo(() => {
    return isMulti ? defaultDivisionId : [];
  }, [list, isMulti, defaultDivisionId]);

  // 検索結果の元を保持
  const firstFlg = useRef({
    tree: true,
    user: true,
  });

  const notReactSearchObj = useRef(false);

  const defaultSelected = useMemo(() => {
    if (Array.isArray(searchObj)) {
      // eslint-disable-next-line max-len
      const result = defaultUniqueId
        ? searchObj.map((v) => ({
          uniqueId: v.uniqueId,
          type: v.type,
          isFirst: true, // FIXME: 仮でtrueで送る（cf. helpers.js）
        }))
        : searchObj.map((v) => ({
          id: v.targetId,
          type: v.type,
          parentId: v?.divisionId && v.targetId === -1 ? v.divisionId : null,
          isFirst: true, // FIXME: 仮でtrueで送る（cf. helpers.js）
        }));
      // isFirst.current = false;
      return result;
    }

    if (isMulti) {
      const defaultSelectedList = [];
      for (const divId of defaultDivisionId) {
        defaultSelectedList.push({ id: divId, type: TREE_TYPES.division });
      }
      // isFirst.current = false;
      return defaultSelectedList;
    }

    const data = {};
    if (defaultUserId === -1 && defaultDivisionId) {
      data.id = defaultUserId;
      data.parentId = defaultDivisionId;
      data.type = TREE_TYPES.user;
    } else if (defaultUserId) {
      data.id = defaultUserId;
      data.type = TREE_TYPES.user;
    } else if (defaultDivisionId) {
      data.id = defaultDivisionId;
      data.type = TREE_TYPES.division;
    } else if (defaultUniqueId) {
      data.id = defaultUniqueId;
      // data.type = TREE_TYPES.customer;
    }
    // isFirst.current = false;

    return [data];
  }, [defaultUserId, defaultDivisionId, searchObj]);

  // 初回ツリー描画  & 初期値指定
  useEffect(() => {
    if (!isMulti && !notReactSearchObj.current) {
      if (list.length > 0 || firstFlg.current.tree) {
        const defaultSearchObj = defaultUniqueId
          ? getTreeObjectByUniqueId(
            treeList,
            null,
            targetFlgList,
            defaultUniqueId,
          )
          : getTreeObject(
            list,
            null,
            targetFlgList,
            defaultUserId,
            defaultDivisionId,
            isDirect,
          );
        if ('count' in defaultSearchObj || defaultSearchObj instanceof Array) {
          let tmp = [];
          Object.values(defaultSearchObj).filter((v) => Array.isArray(v)).forEach((v) => {
            if (Array.isArray(v)) {
              tmp = tmp.concat(v);
            } else {
              tmp.push(v);
            }
          });
          setSearchObj(tmp);
          firstFlg.current.tree = false;
        }
      }
    } else {
      notReactSearchObj.current = false;
    }
  }, [list, isMulti, defaultUserId, defaultDivisionId, defaultUniqueId]);

  // 組織Click時 -- 選択された組織のID/Name and 対象組織以降全ての顧客をset
  const handleSelectDivision = useCallback((data) => {
    let customerList;
    if (isMulti) {
      customerList = getCustomersIsMulti(data);
    } else {
      customerList = getCustomers(data);
      setSelectDivision({
        divId: data.id,
        divisionName: data.name,
      });
      setSelectUser({ userId: 0, userName: '' });
      setSelectUniqueId(data.uniqueId);
    }
    setSelectCustomer(sortCustomers(customerList, sortType));
    deletePathParameter();
    notReactSearchObj.current = true;
    setSearchObj([]);
  }, [isMulti]);

  // ユーザーClick時 -- 選択されたユーザーのID/Name and 対象ユーザー以降全ての顧客をset
  const handleClickUser = useCallback((data) => {
    let customerList;
    if (isMulti) {
      customerList = getCustomersIsMulti(data);
    } else {
      customerList = getCustomers(data);
      setSelectUser({ userId: data.id, userName: data.name });
      setSelectDivision({ divId: data.parentId, divisionName: data.parentName });
      setSelectUniqueId(data.uniqueId);
    }
    setSelectCustomer(sortCustomers(customerList, sortType));
    notReactSearchObj.current = true;
    deletePathParameter();
    setSearchObj([]);
  }, [isMulti]);

  // 顧客Click時 -- 選択された顧客のID/Nameをset
  const handleClickCustomer = useCallback((data, checked) => {
    let customerList;
    if (isMulti) {
      customerList = getCustomersIsMulti(data); // [Array(172)]
      customerList = removeDuplicateCustomerArray(customerList, customersRef.current);
      let selectDivisionIdList = getDivisionIdFlat(customerList);
      // クリックされた場合、件数が0件の場合でもdivIdを追加する (クリックされた状態の登録)
      // 詳細検索時に0件で選択⇒3件のようになっても選択が解除されるため、対応
      // TODO: イケていないので今後改修が必要
      if (checked) {
        const targetDivisionIdList = data
          .filter((d) => d.type === 'division')
          .filter((d) => !selectDivisionIdList.includes(d.id))
          .map((d) => d.id);
        selectDivisionIdList = [...selectDivisionIdList, ...targetDivisionIdList];
      }
      setSelectDivision({ divId: '', divisionName: '', divIdMultipleList: selectDivisionIdList });
      customersRef.current = customerList.map((c) => c.customerId);
    } else {
      customerList = getCustomers(data);
      setSelectUser({ userId: 0, userName: '' });
      setSelectDivision({ divId: '', divisionName: '' });
      setSelectUniqueId(data.uniqueId);
      setSearchObj([]);
    }
    setSelectCustomer(sortCustomers(customerList, sortType));
    if (data?.parentId && data?.id === '担当無し') setSelectDivision({ divId: data?.parentId, divisionName: data?.parentName });
    deletePathParameter();
    notReactSearchObj.current = true;
  }, [isMulti]);

  const onItemClick = (item, checked = false) => {
    switch (item.type) {
      case TREE_TYPES.division:
        handleSelectDivision(item);
        break;
      case TREE_TYPES.user:
        handleClickUser(item);
        break;
      default:
        handleClickCustomer(item, checked);
    }
  };

  // ツリーの検索後ロジック
  useEffect(() => {
    if (searchObj && Array.isArray(searchObj) && searchObj.length && !isMulti) {
      // メイン組織（isMainDivision === 1）を指定する
      let mainDivsionIndex = 0;
      searchObj.forEach((obj, index) => {
        if (obj.isMainDivision === 1) mainDivsionIndex = index;
      });
      const firstSearchObj = searchObj[mainDivsionIndex];
      if (firstSearchObj.type === TREE_TYPES.customer) {
        setSelectCustomer([
          {
            customerId: firstSearchObj.targetId,
            customerName: firstSearchObj.targetName,
          },
        ]);
        setSelectUser({ userId: 0, userName: '' });
        setSelectDivision({ divId: 0, divisionName: '' });
      } else if (firstSearchObj.type === TREE_TYPES.user) {
        setSelectCustomer(sortCustomers(getDataFromAllCustomer(firstSearchObj.treeObj), sortType));
        setSelectUser({
          userId: firstSearchObj.targetId,
          userName: firstSearchObj.targetName,
        });
        setSelectDivision({
          divId: firstSearchObj.treeObj.divisionId,
          divisionName: '',
        });
      } else if (firstSearchObj.type === TREE_TYPES.division) {
        setSelectCustomer(sortCustomers(getDataFromAllCustomer(firstSearchObj.treeObj), sortType));
        setSelectUser({ userId: 0, userName: '' });
        setSelectDivision({
          divId: firstSearchObj.targetId,
          divisionName: firstSearchObj.targetName,
        });
      } else {
        setSelectCustomer(sortCustomers(getDataFromAllCustomer(firstSearchObj.treeObj), sortType));
        setSelectUser({ userId: 0, userName: '' });
        setSelectDivision({
          divId: 0,
          divisionName: '',
        });
      }
    }
  }, [searchObj]);

  const tree = useMemo(() => {
    return (
      <Tree
        list={treeList}
        onChange={onItemClick}
        defaultSelected={defaultSelected}
        accordion
        search={treeSearch}
        rootRef={rootRef}
        checkbox={isMulti}
        multiple={isMulti}
        isSearch={isSearch}
        searchHeader={searchHeader}
        displayCustomer={displayCustomer}
        rerenderDivisionIds={rerenderDivisionIds}
      />
    );
  }, [
    treeList,
    defaultSelected,
    treeSearch,
    rootRef,
    isMulti,
    isSearch,
    searchHeader,
    displayCustomer,
    rerenderDivisionIds,
  ]);

  return (
    <List className={`${classes.root}`} ref={rootRef} style={{ height: treeHeight }}>
      { tree }
    </List>
  );
}

export default TreeList;
