import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerGetTelsSlice = createSlice({
  name: 'customerGetTels',
  initialState: { customerGetTels: initialState },
  reducers: {
    customerGetTelsApi: (state, data) => {
      state.customerGetTels = data.payload;
    },
  },
});

export const { customerGetTelsApi } = customerGetTelsSlice.actions;

export default customerGetTelsSlice.reducer;
