import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  open: false,
  title: '',
  msgList: [],
  isNotice: false,
};

export const customAlertMessageSlice = createSlice({
  name: 'alertMessage',
  initialState: { alertStatus: initialState },
  reducers: {
    changeAlertMessage: (state, data) => {
      const { msgList, title, isNotice } = data.payload;
      state.alertStatus.open = true;
      state.alertStatus.title = title === undefined ? '' : title;
      state.alertStatus.msgList = msgList;
      state.alertStatus.isNotice = isNotice !== undefined;
    },
    closeAlertMessage: (state) => {
      state.alertStatus.open = false;
      state.alertStatus.title = '';
      state.alertStatus.msgList = [];
      state.alertStatus.isNotice = false;
    },
  },
});

export const { changeAlertMessage, closeAlertMessage } = customAlertMessageSlice.actions;

export default customAlertMessageSlice.reducer;
