import React, { useEffect, useRef, useState } from 'react';
import localStyles from './localStyle';
import commonStyles from '../../../styles';
import getDeviceType from '../../../getDeviceType';

function CalendarInputDateTime(props) {
  const style = localStyles();
  const common = commonStyles();
  const val = props;
  const inputRef = useRef();
  const isSP = getDeviceType();

  // ローカルステート
  const key = Object.keys(val.date)[0];
  let defaultDate = val.date[key] ? val.date[key] : '';
  let defaultValue = val.date[key] ? val.date[key] : '';
  if (defaultDate) {
    if (defaultDate.indexOf('/') !== -1 && defaultDate.indexOf(':') !== -1) {
      defaultDate = defaultDate.substr(0, 16);
      defaultValue = defaultValue.replace(/\//g, '-').replace(/ /g, 'T');
    }
    if (defaultDate.indexOf('-') !== -1 && defaultDate.indexOf('T') !== -1) {
      defaultDate = defaultDate
        .substr(0, 16)
        .replace(/-/g, '/')
        .replace(/T/g, ' ');
    }
  }
  const [local, setLocal] = useState({
    dist: defaultDate,
    value: defaultValue,
  });

  const valChange = (e) => {
    // update 2023/02/20
    // useEffectで変換
    const { value } = e.target;
    if (val.fixedMinute && value.substr(14, 2) !== '00') {
      val.alert();
    } else if (val.fixedQuarterMinute
      && (value.substr(14, 2) !== '00'
        && value.substr(14, 2) !== '15'
        && value.substr(14, 2) !== '30'
        && value.substr(14, 2) !== '45')) {
      val.alert();
    } else if (val.min && new Date(val.min) > new Date(value)) {
      setLocal({
        dist: val.min.replace(/-/g, '/').replace(/T/g, ' '),
        value: val.min,
      });
      val.setDate(val.min);
    } else if (val.max && new Date(val.max) < new Date(value)) {
      setLocal({
        dist: val.max.replace(/-/g, '/').replace(/T/g, ' '),
        value: val.max,
      });
      val.setDate(val.max);
    } else {
      setLocal({
        dist: value.replace(/-/g, '/').replace(/T/g, ' '),
        value,
      });
      val.setDate(value);
    }
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3906#comment-174724590
    // 値がセットされたらカレンダー自体を閉じる
    // inputRef.current.blur();
  };

  const onClick = (e, dateVal) => {
    const d = new Date(dateVal);
    const cDate = val.convertDateTimeToIso(d);
    e.target.value = cDate;
  };

  // 行動登録画面や接触・案内情報登録画面などのように2つdatetime-localをおいている場合に、
  // val.dateステートが更新される毎にlocalStateであるlocalを更新するためのuseEffect
  useEffect(() => {
    // if (val.date.assignAt) {
    //   setLocal({
    //     dist: val.date.assignAt.replace(/-/g, '/').replace(/T/g, ' '),
    //     value: val.date.assignAt,
    //   });
    // }
    // 外部からクリアされるので空文字も許可
    if (typeof val.date.assignAt !== 'undefined') {
      setLocal({
        dist: val.date.assignAt.replace(/-/g, '/').replace(/T/g, ' '),
        value: val.date.assignAt,
      });
    }
  }, [val.date]);

  useEffect(() => {
    if (!isSP && val.isShowPicker) inputRef?.current?.showPicker();
  }, []);

  return (
    <label className={style.label} htmlFor={val.id}>
      <span className={`${common.strong} ${style.txt}`}>{local.dist}</span>
      <input
        className={style.input}
        require={val.require.toString()}
        id={val.id}
        type={val.type}
        // エラーが発生した場合は以下を確認
        value={local.value ? val.convertDateTimeToIso(new Date(local.value)) : ''}
        onChange={valChange}
        readOnly={val.readonly ? 'readOnly' : ''}
        min={val.min}
        max={val.max}
        onClick={(e) => onClick(e, local.value)}
        onBlur={val.onBlur}
        ref={inputRef}
      />
    </label>
  );
}

export default CalendarInputDateTime;
