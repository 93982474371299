import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const sendmailUpdateSlice = createSlice({
  name: 'sendmailUpdate',
  initialState: { sendmailUpdate: initialState },
  reducers: {
    sendmailUpdateApi: (state, data) => {
      state.sendmailUpdate = data.payload;
    },
  },
});

export const { sendmailUpdateApi } = sendmailUpdateSlice.actions;

export default sendmailUpdateSlice.reducer;
