import React from 'react';
import { FormGroup } from '@material-ui/core';
import CheckBoxParts from './parts/checkbox';
import CheckBoxPartsType2 from './parts/checkboxType2';

function SortCheckBox(props) {
  const { addOnlyFilterBlock } = props;
  return (
    <FormGroup row>
      {addOnlyFilterBlock ? <CheckBoxPartsType2 /> : <CheckBoxParts />}
    </FormGroup>
  );
}
export default SortCheckBox;
