import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import commonStyles from '../../styles';
import SendTimeChangeWindow from './changeTime';

const useStyles = makeStyles((theme) => ({
  select: {
    // minWidth: '160px',
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
    },
    '& .MuiSelect-icon': {
      width: '17px',
    },
  },
  wrap: {
    // marginRight: '24px',
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
  },
  itemWrap: {
    border: '1px #c8c8c8 solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '2px 8px',
    marginBottom: '8px',
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
  bodyClass: { overflow: 'hidden' },
}));

function MailChangeTimer(props) {
  const { values, minDate = null, maxDate = null } = props;
  const classes = useStyles();
  const common = commonStyles();
  // Fix: Failed prop type:
  // Invalid prop `in` of type `number` supplied to `ForwardRef(Fade)`, expected `boolean`.
  const [open, setOpen] = useState(false);

  return (
    <Grid container className={`${values.disabled ? classes.disable : ''} ${classes.itemWrap}`} style={values.style}>
      <Typography className={common.title4}>{values.label} {values.state}</Typography>
      <Button
        onClick={() => setOpen(!open)}
        type="button"
        disabled={values.disabled}
      >
        <Typography className={`${common.strong} ${classes.blue}`}>変更</Typography>
      </Button>
      <SendTimeChangeWindow
        open={open}
        setOpen={setOpen}
        values={values}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Grid>
  );
}

export default MailChangeTimer;
