import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
} from '@material-ui/core';
import commonTheme from '../../styles/theme';
import WeekRnd from './weekRnd';
import WeekRndSp from './weekRndSp';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles({
  sub: {
    display: 'flex',
    padding: 0,
    '&:first-child': {
      '& > span > span': {
        borderTop: '1px solid #C8C8C8',
      },
    },
  },
  subLbl: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      width: 67,
      minHeight: 40,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 178,
      minHeight: 42,
      paddingLeft: 16,
    },
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      borderLeft: '1px solid #C8C8C8',
      borderRight: '1px solid #C8C8C8',
      borderBottom: '1px solid #C8C8C8',
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 10,
        fontWeight: 700,
        padding: '0 8px',
        minHeight: 40,
      },
      [commonTheme.breakpoints.up('md')]: {
        paddingLeft: 10,
        minHeight: 42,
      },
    },
  },
  subTime: {
    display: 'flex',
    [commonTheme.breakpoints.down('sm')]: {
      width: 'calc(100% - 67px)',
      minHeight: 40,
    },
    [commonTheme.breakpoints.up('md')]: {
      minHeight: 42,
    },
  },
  subItem: {
    display: 'block',
    borderRight: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      minHeight: 40,
      background: '#F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 210,
      minHeight: 42,
    },
    '&:nth-child(odd)': {
      [commonTheme.breakpoints.up('md')]: {
        background: '#F3F3F3',
      },
    },
    '&:last-child': {
      [commonTheme.breakpoints.down('sm')]: {
        borderRight: 'none',
      },
    },
  },
});

export default function WeekRow(props) {
  const {
    action,
    divisions,
    setOpenCustomertree,
    countUpChild,
    countUpChildWeekDay,
    dates,
    noChargeFlg,
  } = props;

  const {
    searchInput,
    dateConversion2,
  } = useActionSchedule();

  const classes = useStyles();
  // 【schedule010】行動予定詳細のグローバルステート
  const schedulesGetActionDetail = useSelector(
    (state) => state.schedulesGetActionDetail.schedulesGetActionDetail,
  );
  // ローカルで更新データを保持
  const [localUpdateData, setLocalUpdateData] = useState({});

  useEffect(() => {
    setLocalUpdateData(schedulesGetActionDetail);
  }, [schedulesGetActionDetail]);

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  let contents;

  // 行の高さ
  const rowHeightArray = [];
  let rowHeight;

  const listAmPc = [];
  const listPmPc = [];
  const listSp = [];
  let listUserId = [];
  let guidanceList = [];
  let guidanceListWeekDay = [];

  // ラベルのサイズ調整用 SP
  const getRndList = (list) => {
    const rndList = [];
    let multiBk = -1;
    let row = 0;
    let order = 1;
    for (let i = 0; i < list.length; i += 1) {
      let multi = 0;

      for (let j = 0; j < list.length; j += 1) {
        if (i !== j) {
          multi = 1;
        }
      }

      if (multi !== 0) {
        if (multiBk === multi) {
          order += 1;
          if (order % 2 !== 0) {
            row += 1;
          }
        } else {
          order = 1;
        }
      } else {
        order = 1;
      }
      rndList.push({ ro: row, odr: order });
      multiBk = multi;
    }
    return rndList;
  };

  if (isSp) {
    // 曜日ごとにデータを分割
    if (divisions.length > 0) {
      if (noChargeFlg) {
        // 担当なし
        listUserId = [{ actions: divisions[0].actions }];
      } else {
        // ユーザーIDで絞り込み
        listUserId = divisions[0].users.filter((item) => {
          return action.userId === item.userId;
        });
      }

      if (listUserId.length > 0) {
        // サイドメニューの検索
        listUserId = [{ actions: searchInput.listSearchFunction(listUserId) }];

        // 組織ごとの案内件数を加算
        guidanceList = listUserId[0].actions.filter((item) => {
          return item.actionScheduleCode === null;
        });
        if (guidanceList.length > 0) {
          countUpChild(guidanceList.length);
        }

        for (let i = 0; i < dates.length; i += 1) {
          // 曜日ごとの案内件数を加算
          if (listUserId[0].actions.length > 0) {
            guidanceListWeekDay = listUserId[0].actions.filter((item) => {
              return (
                item.startAt.substr(0, 10) === dateConversion2(dates[i])
                && item.actionScheduleCode === null
              );
            });
            if (guidanceListWeekDay.length > 0) {
              countUpChildWeekDay(guidanceListWeekDay.length, i);
            }
          }

          // 日付ごとにデータを分割
          const listDay = listUserId[0].actions.filter((item) => {
            return (
              item.startAt.substr(0, 10) === dateConversion2(dates[i])
            );
          });
          listSp.push(listDay);

          // 行の高さ用
          rowHeightArray.push(listDay.length);
        }
      }
    }

    // 行の高さを取得
    if (Math.max(...rowHeightArray) > 6) {
      rowHeight = 40;
      const no = Math.max(...rowHeightArray) - 6;
      if (no % 2 === 0) {
        rowHeight = 40 + (12.5 * (no / 2));
      } else {
        rowHeight = 40 + (12.5 * Math.round(no / 2));
      }
    } else {
      rowHeight = 40;
    }

    // 列の幅を設定
    const subItemWidth = (100 / dates.length) + '%';

    const data = {
      setOpenCustomertree,
      localUpdateData,
      setLocalUpdateData,
    };

    contents = (
      <>
        <span className={classes.subLbl}>
          <span style={{ height: rowHeight }}>
            {action.userName}
          </span>
        </span>
        <span className={`rndWeek-row-subTime ${classes.subTime}`} style={{ height: rowHeight }}>
          {/* 日付の件数分の列を表示 */}
          {Array.from(Array(dates.length).keys()).map((i) => (
            <span className={`rndWeek-row-subItem ${classes.subItem}`} key={i} style={{ width: subItemWidth }} />
          ))}
          {/* ラベル */}
          {listSp.map((item, index) => (
            <>
              {item.map((item2, index2) => (
                <WeekRndSp
                  key={item2.actionId}
                  no={index}
                  index={index2}
                  item={item2}
                  count={item.length}
                  rndList={getRndList(item)}
                  data={data}
                  dates={dates}
                />
              ))}
            </>
          ))}
        </span>
      </>
    );
  } else {
    if (divisions.length > 0) {
      if (noChargeFlg) {
        // 担当なし
        listUserId = [{ actions: divisions[0].actions }];
      } else {
        // ユーザーIDで絞り込み
        listUserId = divisions[0].users.filter((item) => {
          return action.userId === item.userId;
        });
      }

      if (listUserId.length > 0) {
        // サイドメニューの検索
        listUserId = [{ actions: searchInput.listSearchFunction(listUserId) }];

        // 組織ごとの案内件数を加算
        guidanceList = listUserId[0].actions.filter((item) => {
          return item.actionScheduleCode === null;
        });
        if (guidanceList.length > 0) {
          countUpChild(guidanceList.length);
        }

        for (let i = 0; i < dates.length; i += 1) {
          // 曜日ごとの案内件数を加算
          if (listUserId[0].actions.length > 0) {
            guidanceListWeekDay = listUserId[0].actions.filter((item) => {
              return (
                item.startAt.substr(0, 10) === dateConversion2(dates[i])
                && item.actionScheduleCode === null
              );
            });
            if (guidanceListWeekDay.length > 0) {
              countUpChildWeekDay(guidanceListWeekDay.length, i);
            }
          }
          // 午前
          const listDayAm = listUserId[0].actions.filter((item) => {
            return (
              (new Date(item.startAt)).getHours() < 12 // AM
              && item.startAt.substr(0, 10) === dateConversion2(dates[i])
            );
          });
          listAmPc.push(listDayAm);
          // 午後
          const listDayPm = listUserId[0].actions.filter((item) => {
            return (
              (new Date(item.startAt)).getHours() >= 12 // PM
              && item.startAt.substr(0, 10) === dateConversion2(dates[i])
            );
          });
          listPmPc.push(listDayPm);

          // 行の高さ用（午前と午後の最大値）
          rowHeightArray.push(Math.max(listDayAm.length, listDayPm.length) * 25);
        }
      }
    }

    // 行の高さを取得（全日付の最大値）
    if (rowHeightArray.length > 0) {
      rowHeight = Math.max(...rowHeightArray);
    } else {
      rowHeight = 42;
    }

    const data = {
      setOpenCustomertree,
      localUpdateData,
      setLocalUpdateData,
    };

    contents = (
      <>
        <span className={`rndWeek-row-subLbl ${classes.subLbl}`}>
          <span style={{ height: rowHeight }}>
            {action.userName}
          </span>
        </span>
        <span className={`rndWeek-row-subTime ${classes.subTime}`} style={{ height: rowHeight }}>
          {/* 日付の件数分の列を表示 */}
          {Array.from(Array(dates.length).keys()).map((i) => (
            <span className={`rndWeek-row-subItem ${classes.subItem}`} key={i} />
          ))}
          {/* 午前のラベル */}
          {listAmPc.map((item, index) => (
            <>
              {item.map((item2, index2) => (
                <WeekRnd
                  key={item2.actionId}
                  no={index}
                  index={index2}
                  item={item2}
                  count={item.length}
                  data={data}
                />
              ))}
            </>
          ))}
          {/* 午後のラベル */}
          {listPmPc.map((item, index) => (
            <>
              {item.map((item2, index2) => (
                <WeekRnd
                  key={item2.actionId}
                  no={index}
                  index={index2}
                  item={item2}
                  count={item.length}
                  data={data}
                />
              ))}
            </>
          ))}
        </span>
      </>
    );
  }

  return (
    <ListItem className={`rndWeek-row-sub ${classes.sub}`}>
      {contents}
    </ListItem>
  );
}
