import { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import { setPage } from '../../../../store/getPagePath';
import MainmenuModal from './mainmenuModal';
import MainmenuUser from './mainmenuUser';
import MainmenuStatus from './mainmenuStatus';
import getEnv from '../../../../commonFunction/getEnv';

const useStyles = makeStyles({
  wrap: {
    background: '#fff',
    borderBottom: '1px #33333366 solid',
    height: '52px',
    flexWrap: 'nowrap',
    position: 'sticky',
    // top: 0,
    zIndex: 10,
    flexShrink: 1,
    '&.STAGING': { background: '#fce76b' },
    '&.DEVELOP': { background: '#94d5f7' },
    '&.LOCAL': { background: '#d4fecd' },
  },
});

function MainmenuHeader(props) {
  const { menulist, user } = props;
  const classes = useStyles();
  const [path, setPath] = useState('');
  // 現在のLocation取得
  const location = useLocation();
  const regexCrm = /\/crm/;
  const regexMst = /\/mst/;
  useEffect(() => {
    setPage(location?.pathname);
    setPath(location?.pathname);

    const str = location?.pathname;
    if (regexCrm.test(str)) {
      document.getElementById('favicon').href = '/crmfavicon.svg';
    } else if (regexMst.test(str)) {
      document.getElementById('favicon').href = '/mstfavicon.svg';
    } else {
      document.getElementById('favicon').href = '/sfafavicon.svg';
    }
  }, [location]);

  const envClass = getEnv();

  return (
    <Grid
      className={`${classes.wrap} ${envClass}`}
      container
      alignItems="center"
    >
      <MainmenuModal menulist={menulist} path={path} />
      <MainmenuStatus env={envClass} />
      <MainmenuUser user={user} />
    </Grid>
  );
}

export default memo(MainmenuHeader);
