import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const userGetSharedSlice = createSlice({
  name: 'userGetShared',
  initialState: { userGetShared: initialState },
  reducers: {
    userGetSharedApi: (state, data) => {
      state.userGetShared = data.payload;
    },
  },
});

export const { userGetSharedApi } = userGetSharedSlice.actions;

export default userGetSharedSlice.reducer;
