import React, { useState, useEffect, useRef } from 'react';
import Rnd from 'react-rnd';
import { makeStyles } from '@material-ui/core/styles';
import NormalTooltip from '../../styles/tooltip';
import {
  ACTION_TYPE,
  ACTION_TYPE_FLYER_SALES,
  USER_LEVEL_NO,
  GUIDANCE_STATUS_CODE,
} from '../../../constants';
// eslint-disable-next-line import/no-cycle
import InfoRegisterOnlyPost from '../../common/infoRegisterOnlyPost';
import EditableWindow from '../../pages/actRegist/editableWindow';
import ActRegistWindow from '../../pages/actRegist/actRegistWindow';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  rnd: {
    position: 'relative',
    border: '1px solid #C8C8C8',
    background: '#fff',
    borderRadius: '0 4px 4px 4px',
    overflow: 'hidden',
    display: 'flex!important',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: '5px 0',
    '&.single': {
      flexWrap: 'wrap',
      '& > span:first-child': {
        width: '100%',
      },
    },
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: '14px 14px 0 0',
      borderStyle: 'solid',
      position: 'absolute',
      top: 0,
      left: 0,
      borderColor: 'transparent',
    },
    '&.actionScheduleCode-1::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-2::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-3::before': {
      borderColor: `${theme.palette.primaryColor} transparent transparent`,
    },
    '&.actionScheduleCode-4::before': {
      borderColor: '#F2994A transparent transparent',
    },
    '&.actionScheduleCode-5::before': {
      borderColor: '#DEDEDE transparent transparent',
    },
    '&.actionScheduleCode-6::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-7::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-999': {
      background: '#FCE034',
      '&::before': {
        borderColor: 'transparent',
      },
      '&.isResultRegister-1::before': {
        borderColor: '#D83420 transparent transparent',
      },
      '&.customerStatusCode-10::before': {
        borderColor: '#27AE60 transparent transparent',
      },
    },
  },
  txt1: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '1.2',
    letterSpacing: '0',
    display: 'block',
    padding: '0 0 0 8px',
    whiteSpace: 'nowrap',
    width: 'auto',
  },
  txt2: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '1.2',
    letterSpacing: '0',
    display: 'block',
    padding: '0 0 0 8px',
    whiteSpace: 'nowrap',
  },
}));

export default function DayRnd(props) {
  const {
    item,
    index,
    rndHeightList,
    localUpdateData,
    setLocalUpdateData,
    setOpenCustomertree,
  } = props;

  const {
    getSchedulesDate,
    setReacquisitionFlg,
    functions,
    selectDivision,
  } = useActionSchedule();

  const classes = useStyles();

  // クリックとドラッグの制御
  const [dragging, setDragging] = useState(false);

  // GA004モーダルの開閉
  const [open2, setOpen2] = useState(false);
  const onClose2 = () => {
    setOpen2(false);
    // 再取得
    getSchedulesDate();
  };

  // GA-108 行動登録画面
  const [editableOpen, setEditableOpen] = useState(false);
  const onCloseEditable = () => {
    setEditableOpen(false);
    // 再取得
    getSchedulesDate();
  };
  const [openActRegist, setOpenActRegist] = useState(false);
  const onCloseActRegist = () => {
    setOpenActRegist(false);
    // 再取得
    getSchedulesDate();
  };
  // 目標
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });
  const [actRegistSelectDivision, setActRegistSelectDivision] = useState(selectDivision);

  // 時と分に分割
  let startHour = item.startAt;
  let endHour = item.endAt;
  let startMin = item.startAt;
  let endMin = item.endAt;
  if (startHour) {
    startHour = startHour.substr(11, 2);
  }
  if (endHour) {
    endHour = endHour.substr(11, 2);
  }
  if (startMin) {
    startMin = startMin.substr(14, 2);
  }
  if (endMin) {
    endMin = endMin.substr(14, 2);
  }

  // 日付に変換
  const startDate = new Date(item.startAt);
  const endDate = new Date(item.endAt);

  // 開始時刻
  const [hourS, setHourS] = useState(startHour);
  const [minutesS, setMinutesS] = useState(startMin);
  // 終了時刻
  const [hourE, setHourE] = useState(endHour);
  const [minutesE, setMinutesE] = useState(endMin);
  // 合計時間
  const [sum, setSum] = useState((endDate.getTime() - startDate.getTime()) / (60 * 1000));
  // 1セルの幅（30分）
  const cellSize = 56;
  // 全セルの幅
  const cellSizeAll = 1456;
  // ラベルの幅 開始位置 X
  let rndWidthInitial = (sum / 30) * cellSize;
  let startXInitial = ((((startHour - 9) + (startMin / 60)) * 2) + 1) * cellSize;
  const endX = startXInitial + rndWidthInitial;

  // 開始時間が～9:00 or 終了時間が21:00～ 範囲外かどうかの判定
  const outsideFlg = useRef(false);

  if (item.startAt.substr(0, 10) !== item.endAt.substr(0, 10)) { // 日付を跨ぐ場合
    if (startHour < 9) { // 開始時間が～9:00
      startXInitial = 0;
      rndWidthInitial = cellSizeAll;
    } else if (startHour > 20) { // 開始時間が21:00～
      startXInitial = 1400;
      rndWidthInitial = cellSize;
    } else {
      rndWidthInitial = cellSizeAll - startXInitial;
    }
    outsideFlg.current = true;
  } else if (startHour < 9) { // 開始時間が～9:00
    if (endHour > 20) { // 終了時間が21:00～
      rndWidthInitial = cellSizeAll;
    } else if (endHour < 9) { // 終了時間が～9:00
      rndWidthInitial = cellSize;
    } else {
      rndWidthInitial += startXInitial;
    }
    startXInitial = 0;
    outsideFlg.current = true;
  } else if (startHour > 20) { // 開始時間が21:00～
    startXInitial = 1400;
    rndWidthInitial = cellSize;
    outsideFlg.current = true;
  } else if (endHour > 20) { // 終了時間が21:00～
    rndWidthInitial = cellSizeAll - startXInitial;
    if (endMin === '00') {
      outsideFlg.current = false;
    } else {
      outsideFlg.current = true;
    }
  }

  const [rndWidth, setRndWidth] = useState(rndWidthInitial);
  const [startX, setStartX] = useState(startXInitial);
  // 開始位置 Y
  const [startY, setStartY] = useState(rndHeightList[index].odr * 21);

  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (item.startAt.substr(0, 10) !== item.endAt.substr(0, 10)) { // 日付を跨ぐ場合
      outsideFlg.current = true;
    } else if (startHour < 9) { // 開始時間が～9:00
      outsideFlg.current = true;
    } else if (startHour > 20) { // 開始時間が21:00～
      outsideFlg.current = true;
    } else if (endHour > 20) { // 終了時間が21:00～
      if (endMin === '00') {
        outsideFlg.current = false;
      } else {
        outsideFlg.current = true;
      }
    } else {
      outsideFlg.current = false;
    }
    setHourS(startHour);
    setMinutesS(startMin);
    setHourE(endHour);
    setMinutesE(endMin);
    setSum((endDate.getTime() - startDate.getTime()) / (60 * 1000));
    if (startHour > 9 && startHour < 21) {
      setStartX(((((startHour - 9) + (startMin / 60)) * 2) + 1) * cellSize);
    } else {
      setStartX(startXInitial);
    }
    setStartY(rndHeightList[index].odr * 21);
    setReset(!reset);
  }, [item]);

  useEffect(() => {
    if (item.startAt.substr(0, 10) !== item.endAt.substr(0, 10)
      || (startHour >= 9 && endHour > 20)
    ) {
      setRndWidth(cellSizeAll - startX);
    } else if ((startHour < 9 && endHour < 9) || startHour > 20) {
      setRndWidth(cellSize);
    } else if (startHour < 9 && endHour > 20) {
      setRndWidth(cellSizeAll);
    } else if (startHour < 9 && endHour <= 20) {
      setRndWidth(endX);
    } else if (startHour >= 9 && endHour <= 20) {
      setRndWidth(rndWidthInitial);
    }
  }, [sum, reset]);

  const decimalPart = (num, decDigits) => {
    const decPart = num - ((num >= 0) ? Math.floor(num) : Math.ceil(num));
    return decPart.toFixed(decDigits);
  };

  // ラベルの更新制御
  const updateRndFlg = useRef(false);

  // onResizeを実行しているのかどうかのFlg
  const [isDoingOnResize, setIsDoingOnResize] = useState(false);
  const onResize = async (e, direction, ref, delta, position) => {
    if (!outsideFlg.current) {
      // 合計時間を取得
      const minutes = Math.floor(parseInt(ref.style.width, 10) / 28) * 15;
      setSum(Number(minutes));
      // 開始位置を取得
      const positionX = Math.round(position.x) - cellSize;
      // 終了位置を取得
      const positionXend = positionX + ((sum / 30) * cellSize);

      if (positionX < 0 || positionX >= 1344 || positionXend > 1344) {
        // 更新不可
        updateRndFlg.current = false;
        // 範囲外
        outsideFlg.current = true;
      } else {
        // 更新可
        updateRndFlg.current = true;
        // onResize経由の操作である旨更新
        setIsDoingOnResize(true);
        // 再取得可
        setReacquisitionFlg(true);
        // 開始時刻
        const valSart = (positionX / 112);
        const hSart = Math.floor(valSart);
        const mSart = String(Math.floor(decimalPart(valSart, 2) * 60)).padStart(2, '0');
        setHourS(9 + hSart);
        setMinutesS(mSart);
        // 終了時刻
        const valEnd = (positionX / 112) + (minutes / 60);
        const hEnd = Math.floor(valEnd);
        const mEnd = String(Math.floor(decimalPart(valEnd, 2) * 60)).padStart(2, '0');
        setHourE(9 + hEnd);
        setMinutesE(mEnd);
        // 開始位置
        setStartX(((((hSart) + (mSart / 60)) * 2) + 1) * cellSize);
      }
    }
  };

  // onDragを実行しているのかどうかのFlg
  const [isDoingOnDrag, setIsDoingOnDrag] = useState(false);
  const onDrag = async (e, d) => {
    if (!outsideFlg.current) {
      // 開始位置を取得
      const positionX = Math.round(d.x) - 440;
      // 終了位置を取得
      const positionXend = positionX + ((sum / 30) * cellSize);

      if (positionX < 0 || positionX >= 1344 || positionXend > 1344) {
        // 更新不可
        updateRndFlg.current = false;
        // 範囲外
        outsideFlg.current = true;
      } else {
        // 更新可
        updateRndFlg.current = true;
        // onDrag経由の操作である旨更新
        setIsDoingOnDrag(true);
        // 再取得可
        setReacquisitionFlg(true);
        // 開始時刻
        const valSart = (positionX / 112);
        const hSart = Math.floor(valSart);
        const mSart = String(Math.floor(decimalPart(valSart, 2) * 60)).padStart(2, '0');
        setHourS(9 + hSart);
        setMinutesS(mSart);
        // 終了時刻
        const valEnd = (positionX / 112) + (sum / 60);
        const hEnd = Math.floor(valEnd);
        const mEnd = String(Math.floor(decimalPart(valEnd, 2) * 60)).padStart(2, '0');
        setHourE(9 + hEnd);
        setMinutesE(mEnd);
        // 開始位置
        setStartX(((((hSart) + (mSart / 60)) * 2) + 1) * cellSize);
      }
    }

    // クリックとドラッグの制御
    setDragging(true);
  };

  const [count, setCount] = useState(0);

  const onResizeStop = async () => {
    if (outsideFlg.current) {
      // 更新不可
      updateRndFlg.current = false;

      // actionScheduleCodeがnullの場合、GA-004画面を開く
      if (item.actionScheduleCode === null) {
        setOpen2(true);
      } else {
        // グローバルステート(schedulesGetActionDetail)の更新
        await functions.getSchedulesDetailFunction(item.scheduleId);
        // 「~ 9:00」と「21:00 ~」は編集画面（GA-108画面）を開く
        if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
          setEditableOpen(true);
        } else {
          setOpenActRegist(true);
        }
      }
    } else {
      // 更新処理を呼び出す
      setCount(count + 1);
    }
  };

  const onDragStop = async () => {
    // クリックとドラッグの制御
    setTimeout(() => { setDragging(false); }, 500);
    if (outsideFlg.current) {
      // 更新不可
      updateRndFlg.current = false;

      // actionScheduleCodeがnullの場合、GA-004画面を開く
      if (item.actionScheduleCode === null) {
        setOpen2(true);
      } else {
        // グローバルステート(schedulesGetActionDetail)の更新
        await functions.getSchedulesDetailFunction(item.scheduleId);
        // 「~ 9:00」と「21:00 ~」は編集画面（GA-108画面）を開く
        if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
          setEditableOpen(true);
        } else {
          setOpenActRegist(true);
        }
      }
    } else {
      // 更新処理を呼び出す
      setCount(count + 1);
    }
  };

  useEffect(() => {
    // 更新可 かつ onDragまたはonResizeの実行時のみ更新処理を実行する
    if (updateRndFlg.current && (isDoingOnDrag || isDoingOnResize)) {
      // 更新
      const updateStartAt = item.startAt.substr(0, 11) + ('0' + String(hourS)).slice(-2) + ':' + minutesS + ':00';
      const updateEndAt = item.endAt.substr(0, 11) + ('0' + String(hourE)).slice(-2) + ':' + minutesE + ':00';
      if (item.actionScheduleCode) {
        functions.schedulesUpdateApiFunction(
          item.scheduleId,
          {
            startAt: updateStartAt,
            endAt: updateEndAt,
          },
        );
      } else {
        functions.putGuidanceByGuidanceIdFunction(
          item.guidanceId,
          {
            guidanceStartAt: updateStartAt,
            guidanceEndAt: updateEndAt,
            customerId: item.customerId,
            guidanceStatusCode: item.guidanceStatusCode,
            contactId: item.contactId,
            actionId: item.actionId,
            contactCode: item.contactCode,
          },
        );
      }
    }
    // onDragの実行中Flgがtrueの場合は、falseとする
    if (isDoingOnDrag) {
      setIsDoingOnDrag(false);
    }
    // onResizeの実行中Flgがtrueの場合は、falseとする
    if (isDoingOnResize) {
      setIsDoingOnResize(false);
    }
  }, [count]);

  const onClick = async () => {
    if (dragging) return;

    // actionScheduleCodeがnullの場合、GA-004画面を開く
    if (item.actionScheduleCode === null) {
      setOpen2(true);
    } else {
      // グローバルステート(schedulesGetActionDetail)の更新
      await functions.getSchedulesDetailFunction(item.scheduleId);
      if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
        setEditableOpen(true);
      } else {
        setOpenActRegist(true);
      }
    }
  };

  const getTooltipTxt = () => {
    let txt;
    if (item.actionScheduleCode) {
      txt = `${hourS}:${minutesS}-${hourE}:${minutesE} ${item.customerName ? ` ${item.customerName} 様` : ''} ${ACTION_TYPE[item.actionScheduleCode]}
      ${item.memo ? `、${item.memo}` : ''}`;
    } else {
      txt = `${hourS}:${minutesS}-${hourE}:${minutesE} ${item.customerName ? ` ${item.customerName} 様` : ''} ${item.customerStatusCode === 10 ? `(契)${GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}` : GUIDANCE_STATUS_CODE[item.guidanceStatusCode]} ${item.userLevel ? `、${USER_LEVEL_NO[item.userLevel]}` : '、★なし'} ${item.isPairCheck ? '、揃い' : ''} ${item.siteName ? `、${item.siteName}` : ''}`;
    }
    return txt;
  };

  return (
    <>
      <NormalTooltip title={getTooltipTxt()}>
        <Rnd
          key={reset}
          className={`${classes.rnd} actionScheduleCode-${item.actionScheduleCode || '999'} customerStatusCode-${item.customerStatusCode} isResultRegister-${item.isResultRegister} ${rndHeightList[index].cnt === 0 && 'single'}`}
          default={{
            x: startX,
            y: startY,
            // width: rndWidth,
            // height: rndHeightList[index].cnt === 0 ? '39px' : '20px',
          }}
          size={{
            width: rndWidth,
            height: rndHeightList[index].cnt === 0 ? '39px' : '20px',
          }}
          // position={{
          //   x: startX,
          //   y: startY,
          // }}
          dragAxis="x"
          enableResizing={{
            top: false,
            right: true,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          bounds="parent"
          resizeGrid={[28, 0]}
          dragGrid={[28, 0]}
          minWidth="28"
          onResize={onResize}
          onResizeStop={onResizeStop}
          onDrag={onDrag}
          onDragStop={onDragStop}
          onClick={onClick}
        >
          {item.actionScheduleCode ? (
            <>
              <span className={classes.txt1}>
                {hourS}:{minutesS}-{hourE}:{minutesE}
                {item.customerName ? ` ${item.customerName} 様` : ''}
              </span>
              <span className={classes.txt2}>
                {ACTION_TYPE[item.actionScheduleCode]}
                {item.memo ? `、${item.memo}` : ''}
              </span>
            </>
          ) : (
            <>
              <span className={classes.txt1}>
                {hourS}:{minutesS}-{hourE}:{minutesE}
                {item.customerName ? ` ${item.customerName} 様` : ''}
              </span>
              <span className={classes.txt2}>
                {item.customerStatusCode === 10 ? `(契)${GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}` : GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}
                {item.userLevel ? `、${USER_LEVEL_NO[item.userLevel]}` : '、★なし'}
                {item.isPairCheck ? '、揃い' : ''}
                {item.siteName ? `、${item.siteName}` : ''}
              </span>
            </>
          )}
        </Rnd>
      </NormalTooltip>
      {/* GA-108 行動登録画面（源泉） */}
      <EditableWindow
        open={editableOpen}
        onClose={onCloseEditable}
        setReacquisitionFlg={setReacquisitionFlg}
        localUpdateData={localUpdateData}
        setLocalUpdateData={setLocalUpdateData}
        setOpenCustomertree={setOpenCustomertree}
        actRegistFlag={Boolean(false)}
        editableFlag={Boolean(true)}
        schedulesUpdateApiFunction={functions.schedulesUpdateApiFunction}
        schedulesPostApiFunction={functions.schedulesPostApiFunction}
        schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
      />
      {/* GA-108 行動登録画面（源泉以外） */}
      {openActRegist && (
        <ActRegistWindow
          open={openActRegist}
          onClose={onCloseActRegist}
          setReacquisitionFlg={setReacquisitionFlg}
          values={localUpdateData}
          setUpdateData={setLocalUpdateData}
          setOpenCustomertree={setOpenCustomertree}
          actRegistFlag={Boolean(false)}
          editableFlag={Boolean(true)}
          targetObj={targetObj}
          setTargetObj={setTargetObj}
          archieveObj={archieveObj}
          setArchieveObj={setArchieveObj}
          selectDivision={actRegistSelectDivision}
          setSelectDivision={setActRegistSelectDivision}
          isSource={Boolean(false)}
          isEdit={Boolean(true)}
          createUpdateFunc={(obj) => {
            functions.schedulesUpdateApiFunction(localUpdateData.scheduleId, obj);
          }}
          schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
        />
      )}
      {/* GA-004 */}
      <InfoRegisterOnlyPost
        open={open2}
        onOpen={() => { setOpen2(true); }}
        onClose={onClose2}
        // newRegistrationFlgがtrueの場合、infoRegisterにguidanceIdを渡さない===新規登録になる
        guidanceId={item.guidanceId}
        // setClickInfoRegister={setClickInfoRegister}
        customer={item}
        // user={user}
        reacquisition={getSchedulesDate}
      />
    </>
  );
}
