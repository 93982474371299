import { Resizable } from 're-resizable';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crmPageActions, crmPageSelector } from '../../store/crmPage';
import commonStyles from '../styles';
import ResponseRegister from '../common/responseRegister';
import JoinData from './joinData';
import CustomerSearch from '../customerSearch';
import ResponseList from '../responseList';
import FlyerSalesList from '../flyerSalesList';
import { changeAlertMessage } from '../../store/eleCommon/customAlertMessage';
import { useLoading, useStore } from '../../hooks';
import { CRM_CUSTOMER_SEARCH, CRM_RESPONSE_LIST } from '../../constants/loading';
import { getLocalStorage, setLocalStorage } from '../../commonFunction';
import { DISPLAY_PANEL_LIST } from '../../constants/crm';
import { CRM_DISPLAY } from '../../constants/localStorage';

const useStyles = makeStyles({
  visibleTtl: {
    margin: '0 16px 0 16px',
    '&::before': {
      content: '""',
      background: '#c8c8c8',
      display: 'inline-block',
      width: '1px',
      height: '24px',
      marginRight: '16px',
      verticalAlign: 'middle',
    },
  },
  label: {
    marginRight: 24,
    // CheckboxのColorを元に戻す
    '& .Mui-checked.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
  upperRow: {
    borderBottom: '1px #c8c8c8 solid',
    display: 'flex',
  },
  upperRowRight: {
    borderLeft: '1px #c8c8c8 solid',
    flex: 1,
    background: '#fff',
    overflow: 'scroll',
  },
  bottom: {
    borderBottom: '1px #c8c8c8 solid',
    height: '100%',
    width: '100%',
  },
  resizableInnerWrapper: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    width: '100%',
    height: '300px',
    overflow: 'auto',
  },
  body: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 52px - 58px)',
  },
});

export default function CrmPage() {
  const common = commonStyles();
  const classes = useStyles();
  const { joinData, customerId } = useSelector(crmPageSelector);
  const [visible, setVisible] = useState(getLocalStorage(CRM_DISPLAY, DISPLAY_PANEL_LIST));

  const { responseHeader } = useStore();
  const { hasLoading } = useLoading();
  const { roleGroupId, roleIds } = responseHeader;

  const list = useMemo(() => {
    return [
      // joinData.isShow
      //   // データ結合の場合、チェックボックスを変更不能にする
      //   ? { id: 'merge', label: 'データ結合', disabled: true }
      //   : { id: 'register', label: '新規反響登録' },
      // { id: 'search', label: '検索' },
      { id: 'response', label: '反響未処理(WEB)' },
      { id: 'source', label: '源泉未処理' },
    ];
  }, [joinData.isShow]);

  const items = [];
  const addCheckboxes = (checkboxListToShow, checkboxListTotal, id) => {
    checkboxListTotal.forEach(checkbox => {
      if (checkbox.id === id) {
        checkboxListToShow.push(checkbox);
      }
    });
  };
  if (roleIds && roleIds.length > 0 && roleIds.includes(8)) {
    if (roleIds.includes(6)) {
      // roles.id = 8に加えて6も含む場合、「反響未処理」のチェックボックスのみ表示する
      addCheckboxes(items, list, 'response');
    }
    if (roleIds.includes(7)) {
      // roles.id = 8に加えて7も含む場合、「源泉未処理」のチェックボックスのみ表示する
      addCheckboxes(items, list, 'source');
    }
  }

  const dispatch = useDispatch();

  const changeDisplayPanel = (data) => {
    setVisible(data);
    setLocalStorage(CRM_DISPLAY, data);
  };

  useEffect(async () => {
    dispatch(crmPageActions.enterPage());
    dispatch(crmPageActions.resetCustomerData());

    return () => {
      dispatch(crmPageActions.exitPage());
      dispatch(crmPageActions.resetCustomerData());
    };
  }, []);

  const alertNotPermission = () => {
    dispatch(changeAlertMessage({
      msgList: ['CRM参照権限がない為画面を表示することができません。'],
      title: '認証エラー',
    }));
  };

  if (!roleIds) {
    alertNotPermission();
    return '';
  }

  if (!roleIds?.includes(8) && !roleIds?.includes(7) && !roleIds?.includes(6)) {
    alertNotPermission();
    return '';
  }

  const onChangeHeight = (e, direction, ref, d) => {
    const data = { ...visible, height: visible.height + d.height };
    changeDisplayPanel(data);
  };

  return (
    <>
      <Grid container alignItems="center" className={common.header}>
        <Typography className={common.title3}>反響処理 (CRM)</Typography>
        <Typography className={`${common.title4} ${classes.visibleTtl}`}>
          表示
        </Typography>
        {items.map((l) => (
          <FormControlLabel
            key={l.id}
            control={
              <Checkbox
                className={classes.check}
                checked={visible[l.id]}
                disabled={l.disabled}
                onChange={() => {
                  const data = { ...visible, [l.id]: !visible[l.id] };
                  changeDisplayPanel(data);
                }}
              />
            }
            className={classes.label}
            label={l.label}
          />
        ))}
      </Grid>
      <Grid className={classes.body}>
        <Grid style={{ width: '100%', overflow: 'hidden' }}>
          <Resizable
            enable={{ bottom: true }}
            size={{ height: visible.height ? visible.height : DISPLAY_PANEL_LIST.height }}
            // defaultSize={{ height: 300 }}
            className={classes.upperRow}
            onResizeStop={(e, direction, ref, d) => { onChangeHeight(e, direction, ref, d); }}
          >
            <div
              className={classes.resizableInnerWrapper}
              style={{ display: 'flex' }}
            >
              {(visible.register || joinData.isShow) && (
                <Resizable
                  enable={{ right: true }}
                  // fullHDで画面を開いた時、新規反響登録が良い感じに表示できる大きさ
                  defaultSize={{ width: '52%' }}
                  size={visible.search ? undefined : { width: '100%' }}
                >
                  <div className={classes.resizableInnerWrapper}>
                    { joinData.isShow
                      ? <JoinData
                          parentResponseDataId={customerId}
                          childResponseDataId={joinData.childResponseId}
                          operation={joinData.type}
                      />
                      : <ResponseRegister /> }
                  </div>
                </Resizable>
              )}
              {visible.search && (
                <div className={hasLoading(CRM_CUSTOMER_SEARCH)
                  ? `${classes.upperRowRight} ${common.loading}`
                  : classes.upperRowRight}
                >
                  <CustomerSearch />
                </div>
              )}
            </div>
          </Resizable>
        </Grid>
        {/* roleId = 6, 8を持つ場合、 反響未処理を表示可能とする */}
        {roleGroupId && roleIds.includes(8) && roleIds.includes(6) && visible.response ? (
          <Resizable
            className={hasLoading(CRM_RESPONSE_LIST)
              ? `${classes.bottom} ${common.loading}`
              : classes.bottom}
            defaultSize={{
              height: 300,
            }}
            enable={{
              top: false,
              right: false,
              bottom: true,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
          >
            <Grid className={classes.resizableInnerWrapper}>
              <ResponseList />
            </Grid>
          </Resizable>
        ) : null}
        {/* roleId = 7, 8を持つ場合、 源泉未処理を表示可能とする */}
        {roleGroupId && roleIds.includes(8) && roleIds.includes(7) && visible.source
          ? <FlyerSalesList /> : null}
      </Grid>
    </>
  );
}
