import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  Paper,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import { closeConfirmMessage, backdropClickConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import commonTheme from '../styles/theme';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '1500!important',
    '& .MuiDialog-paper': {
      [commonTheme.breakpoints.down('sm')]: {
        margin: 16,
        width: 'calc(100% - 32px)',
        maxHeight: 'calc(100% - 32px)',
      },
    },
  },
  dialogTitle: {
    padding: '24px 24px 8px',
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      textAlign: 'center',
    },
  },
  dialogContent: {
    padding: 24,
    borderTop: '1px solid #C8C8C8',
  },
  txt: {
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'center',
  },
  dialogActions: {
    borderTop: '1px solid #C8C8C8',
    padding: '16px 0',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    flexWrap: 'wrap',
    '& button': {
      margin: '0 8px',
    },
  },
  btn1: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 22px 7px',
    marginRight: 16,
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  btn2: {
    fontSize: 15,
    padding: '8px 22px 6px',
    minWidth: 89,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-confirm-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

// TODO: Use Alert -> 以下が必要です
// import { useDispatch } from 'react-redux';
// import { changeConfirmMessage } from 'store/eleCommon/customConfirmMessage';
// エラーを出したい時に
// dispatch(changeConfirmMessage({
//   msgList: ['メッセージ'], // 必須
//   title: 'タイトル', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
//   buttons: [{
//     label: 'CANCEL', // ボタンラベル
//     set: handleClick1, // ボタンをクリックしたときの処理
//     classes: common.buttonsSecondary, // ボタンのクラス
//     // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
//   }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
// }));
function CustomConfirmMessage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const openConfirm = useSelector((state) => state.confirmMessage.confirmStatus);

  const handleConfirmClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      // 背景押下時
      dispatch(backdropClickConfirmMessage());
    }
    // 以下Closeしたい時
    dispatch(closeConfirmMessage());
  };

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <Dialog
      disableEscapeKeyDown
      open={openConfirm.open}
      onClose={handleConfirmClose}
      PaperComponent={PaperComponent}
      fullWidth
      maxWidth="sm"
      className={classes.dialog}
      // onBackdropClick={handleBackdropClick}
    >
      {Array.isArray(openConfirm.title) ? (
        <DialogTitle
          className={classes.dialogTitle}
          id="draggable-confirm-title"
        >
          {isSp ? (
            <span>
              {openConfirm.title.map((data) => (
                <span key={data}>
                  <span>{data}</span>
                </span>
              ))}
            </span>
          ) : (
            <span>
              {openConfirm.title.map((data) => (
                <span key={data}>
                  <span style={{ width: '100%' }}>{data}</span>
                  <br />
                </span>
              ))}
            </span>
          )}
        </DialogTitle>
      ) : (
        <DialogTitle
          className={classes.dialogTitle}
          id="draggable-confirm-title"
        >
          {openConfirm.title}
        </DialogTitle>
      )}
      {openConfirm.msgList.length > 0 && (
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.txt}>
            {openConfirm.msgList.map((data) => (
              <span key={data}>
                <span style={{ width: '100%' }}>{data}</span>
                <br />
              </span>
            ))}
          </Typography>
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActions}>
        <Grid className={classes.btnWrap}>
          {openConfirm.buttons.map(b => (
            <Button
              key={b.label}
              onClick={() => {
                handleConfirmClose();
                b.set();
              }}
              className={b.classes}
            >
              {b.label}
            </Button>
          ))}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default CustomConfirmMessage;
