import CustomerBatchContainers from '../../containers/customerBatch/customerBatchContainers';

function CustomerBatch(props) {
  const { selectCustomer } = props;
  return (
    <div>
      <CustomerBatchContainers selectCustomer={selectCustomer} />
    </div>
  );
}

export default CustomerBatch;
