import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../../styles';
import CustomerDetail from '../../common/customerDetail';
import InstructionsWindow from '../../common/taiouRireki/instructionsWindow';
import MailCreateWindow from '../../mail/createWindow';
import SmsWindow from '../../sms';
import ManagementLine from '../../common/managementLine';
import { useStore } from '../../../hooks';
import { useCustomerMain } from '../../../containers/customerMain/customerMainContext';
import CustomerObi from './customerObi';
import { useUpdate } from '../../../containers/customerMain';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      color: '#fff',
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: '4px 16px 4px 18px',
      margin: '0 8px',
      '& .MuiSvgIcon-root': {
        fontSize: 14,
        marginRight: 6,
        marginBottom: '-2',
      },
    },
  },
  button: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 16px 4px 18px',
    margin: '0 8px',
    '& .MuiButton-startIcon': {
      marginRight: 6,
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 14,
    },
  },
});

function CustomerButtonIcon(props) {
  const { input } = props;

  const {
    functions,
  } = useCustomerMain();
  const {
    update,
  } = useUpdate(input.customer);

  const { responseHeader } = useStore();
  const { roleIds } = responseHeader;

  const [openFlg, setOpenFlg] = useState(false);

  // 個人の顧客情報を取得（詳細）
  const getCustomerDetail = () => {
    setOpenFlg(true);
    functions.getCustomerFunctionSingle([input.customer.customerId], true);
  };
  // 個人の顧客情報を初期化（詳細）
  const initializeCustomerDetail = () => {
    setOpenFlg(false);
    input.setCustomerDetail(null);
  };
  // 個人の顧客情報を取得（メール）
  const getCustomerMail = () => {
    functions.getCustomerMailFunction(input.customer.customerId);
  };
  // 個人の顧客情報を初期化（メール）
  const initializeCustomerMail = () => {
    input.setCustomerMail(null);
  };
  const refleshActionHistory = () => {
    functions.getActionHistoryList(input.customer.customerId, { limit: 20, offset: 0 });
  };

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを閉じる
  const handleClose = () => {
    setOpen(false);
    initializeCustomerDetail();
    input.isCloesdCustomerDetail.current = true;
  };
  useEffect(() => {
    if (openFlg && input.customerDetail) {
      // 個人の顧客情報を取得後、モーダルを開く
      setOpen(true);
    }
  }, [input.customerDetail]);

  // モーダル開閉用のローカルステート(LINE)
  const [lineOpen, setLineOpen] = useState(false);
  // モーダルを開くsetState
  const handleLineOpen = () => {
    setLineOpen(true);
  };
  // モーダルを閉じるsetState
  const closeLine = () => {
    setLineOpen(false);
  };

  // モーダル開閉用のローカルステート(指示登録)
  const [instOpen, setInstOpen] = useState(false);
  // モーダルを開くsetState
  const handleInstOpen = () => {
    setInstOpen(true);
  };
  // モーダルを閉じるsetState
  const closeInst = () => {
    setInstOpen(false);
  };

  // モーダル開閉用のローカルステート（SMS送信）
  const [smsOpen, setSmsOpen] = useState(false);
  const handleSmsOpen = () => {
    setSmsOpen(true);
  };
  const closeSms = () => {
    setSmsOpen(false);
  };

  // 指示登録時にパネル内の指示も更新
  const updateDirect = (val) => {
    update({
      directTimeLineContent: val.content,
      directStatusCode: val.directReportStatusCode,
      expireAt: val.expireAt,
    });
  };

  const classes = useStyles();
  const baseClasses = commonStyles();

  // ManagementLineのAPI初期化の際叩かないため
  const customerIdRef = useRef('');
  // LINE WORKS管理画面を一度も表示してない場合は、customerIdを設定しない
  customerIdRef.current = lineOpen || customerIdRef.current ? input.customer.customerId : '';

  return (
    <Grid className={classes.root}>
      {/* 支援権限のユーザーが、メールステータス個別のみの顧客を閲覧する場合 */}
      {/* 顧客メインのメール作成ボタンが失活 */}
      <MailCreateWindow
        user={input.customerMail}
        btnlabel={<span><MailOutlineIcon />メール作成</span>}
        onOpen={getCustomerMail}
        onClose={initializeCustomerMail}
        // 「role=1(代理送信権限) と、29(全顧客編集権限) か9(全顧客管理)を持つなら、メールステータスが代行OKでない限り失活」
        // 「isMailSend1、isMailSend2、isMailSend3、isMailSend4が全て0」
        disabled={
          (roleIds.includes(1)
            && (roleIds.includes(29) || roleIds.includes(9))
            && input.customerInfo.emailStatusCode !== 5)
          || (!input.customerInfo.isMailSend1
            && !input.customerInfo.isMailSend2
            && !input.customerInfo.isMailSend3
            && !input.customerInfo.isMailSend4)
        }
        isLocalStorage
        customerMainFlg
        refleshCallBack={refleshActionHistory}
        isActiveSaveTemplate
      />
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button}`}
        startIcon={<Icon>aod</Icon>}
        onClick={handleSmsOpen}
        disabled={input.customerInfo.telStatusCode === 0}
      >
        SMS送信
      </Button>
      <Button
        onClick={handleLineOpen}
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button}`}
        startIcon={<ChatBubbleOutlineIcon />}
      >
        LINE送信
      </Button>
      <ManagementLine
        open={lineOpen}
        onClose={closeLine}
        customerId={customerIdRef.current}
      />
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button}`}
        startIcon={<Icon className="material-icons-outlined">summarize</Icon>}
        onClick={getCustomerDetail}
      >
        詳細情報
      </Button>
      {input.customerDetail && (
        <CustomerDetail
          open={open}
          onClose={handleClose}
          input={input}
          onChange={functions ? functions.updatePage : input.update}
        />
      )}
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button}`}
        startIcon={<TouchAppOutlinedIcon />}
        onClick={handleInstOpen}
      >
        指示登録
      </Button>
      <InstructionsWindow
        open={instOpen}
        setOpen={setInstOpen}
        onClose={closeInst}
        directId=""
        newInstructionFlg
        customerId={input.customer.customerId}
        divisionId={input.customer.divisionId}
        userId={input.customer.userId}
        userFirstName={input.customer.userFirstName}
        userLastName={input.customer.userLastName}
        updatePage={updateDirect}
      />
      <SmsWindow
        open={smsOpen}
        onClose={closeSms}
        customer={input.customer}
      />
      <CustomerObi input={input} />
    </Grid>
  );
}

export default React.memo(CustomerButtonIcon);
