import React, { useState, useEffect } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import { USER_CLASS_SELECT, USER_GRADE_SELECT_LIST } from '../../../../constants/users';
import CustomSelect from '../../../eleCommon/customSelect';

const useStyles = makeStyles((theme) => ({
  orgGridRow: {
    border: '1px solid #C8C8C8',
    marginTop: '1%',
    padding: '0px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  textButton: {
    color: theme.palette.primaryColor,
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primaryColor}`,
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
  userCustomButtonSave: {
    padding: '8px 22px',
    width: '74px',
    height: '40px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '12px 16px',
  },
  isMainClass: {
    backgroundColor: '#F3F3F3',
    width: '100%',
    padding: '10px 5px',
    border: '1px solid #C8C8C8',
  },
  formControlClass: {
    margin: '5px 5px 5px 2px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  roleClass: {
    backgroundColor: 'white',
    padding: '5px',
    margin: '5px',
    border: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  asterisk: { color: '#db3131', marginLeft: 3 },
}));

function OrgSettingMain(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();
  const { setLocalUserState, division } = props;

  const [positionId, setPositionId] = useState('');
  const [gradeId, setGradeId] = useState('');

  const setLocalUserVal = (key, val) => {
    setLocalUserState((s) => {
      const { divisions, ...newUserDetail } = structuredClone(s);
      newUserDetail.divisions = [];
      divisions.forEach((d) => {
        if (d.userDivisionId === division.userDivisionId) d[key] = val;
        newUserDetail.divisions.push(d);
      });
      return newUserDetail;
    });
  };

  // 役割
  // 変更するdivisionのみレスポンスで送る
  const changePosition = (e) => {
    setPositionId(e.target.value);
    const intPositionValue = Number(e.target.value);
    setLocalUserVal('positionCode', intPositionValue);
  };

  // 役職
  // 変更するdivisionのみレスポンスで送る
  const changeGrade = (e) => {
    setGradeId(e.target.value);
    const intRoleValue = Number(e.target.value);
    setLocalUserVal('gradeCode', intRoleValue);
  };

  // 共通Selectの定義
  const selectPosition = {
    title: '',
    required: true,
    key: 'orgPosition',
    type: 'flg',
    obj: USER_CLASS_SELECT,
    state: positionId,
    set: changePosition,
    width: '70%',
  };

  const selectRole = {
    title: '',
    required: true,
    key: 'orgRole',
    type: 'flg',
    obj: USER_GRADE_SELECT_LIST,
    state: gradeId,
    set: changeGrade,
    width: '70%',
  };

  useEffect(() => {
    division.positionCode ? setPositionId(division.positionCode) : '';
    division.gradeCode ? setGradeId(division.gradeCode) : '';
  }, [division]);

  return (
    <Grid style={{ width: '100%' }}>
      <Grid container className={classes.isMainClass}>
        <Grid container className={classes.roleClass}>
          <Grid item style={{ width: '50%' }} className={baseClasses.grid}>
            <span className={baseClasses.title4} style={{ marginRight: '4%' }}>
              役割
              <span className={classes.asterisk}>＊</span>
            </span>
            <CustomSelect initial={selectPosition} />
          </Grid>
          <Grid item style={{ width: '50%' }} className={baseClasses.grid}>
            <span className={baseClasses.title4}>役職</span>
            <span className={classes.asterisk}>＊</span>
            <CustomSelect initial={selectRole} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrgSettingMain;
