import { memo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SubmitButton from './parts/submitButton';
import ResetButton from './parts/resetButton';
import DetailSearch from '../detailSearch';
import { useKanniKensaku } from '../../../containers/search/kanniKensaku/KanniKensakuContext';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightBox: {
    display: 'flex',
    '&>*': {
      marginLeft: 8,
    },
  },
});

/**
 * 詳細検索画面はDOMノードが沢山すぎるため
 * memoを使用し余計なレンダリングを制御する
 */
const DetailSearchModal = memo((props) => {
  const { isAvoidSharedCustomer, isFromAllmail } = props;

  return (
    <Box>
      {/* 詳細検索画面 */}
      <DetailSearch
        isAvoidSharedCustomer={isAvoidSharedCustomer}
        isFromAllmail={isFromAllmail}
      />
    </Box>
  );
});

function SortButtons(props) {
  const { isFromAllmail } = props;
  const {
    resetStart,
    searchConditions,
    isAvoidSharedCustomer,
  } = useKanniKensaku();

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <DetailSearchModal
        isAvoidSharedCustomer={isAvoidSharedCustomer}
        isFromAllmail={isFromAllmail}
      />
      <Box className={classes.rightBox}>
        <ResetButton
          txt="リセット"
          resetStart={resetStart}
        />
        <SubmitButton txt="検索" searchConditions={searchConditions} />
      </Box>
    </Box>
  );
}

export default SortButtons;
