import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const libraryShareSlice = createSlice({
  name: 'libraryShare',
  initialState: { libraryShare: initialState },
  reducers: {
    libraryShareApi: (state, data) => {
      state.libraryShare = data.payload;
    },
  },
});

export const { libraryShareApi } = libraryShareSlice.actions;

export default libraryShareSlice.reducer;
