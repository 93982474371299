import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../common/calendar';
// import commonStyles from '../../../../styles';

const useStyles = makeStyles({
  contentIpt: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    boxSizing: 'border-box',
  },
  contentIptDate: {
    marginTop: '-2px',
    width: '100%',
    '& div': {
      border: 'none',
      padding: 0,
      '& .MuiTypography-root': {
        marginRight: '45px',
      },
      '& label': {
        width: '89%',
      },
    },
    '& span': {
      padding: 0,
    },
  },
});

function DateField(props) {
  const { date, setDate } = props;
  console.log(date);
  const classes = useStyles();
  // const baseClasses = commonStyles();

  const assignAtOnchange = (value) => {
    setDate(value);
  };

  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '反響日', // String - ラベル
      require: true, // Boolean - 必須かどうか
      type: 'date', // String 'date'|'time'|'multitime'|'multiple'
      state: date, // state
      setState: assignAtOnchange, // setState
    },
  ];

  return (
    <Grid className={classes.contentIpt}>
      {datalist.map((array) => {
        return (
          <Grid className={classes.contentIptDate} key={array.id}>
            <Calendar data={array} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default DateField;
