import { useState, useEffect, useMemo, useCallback } from 'react';

const useManagmentLine = ({ line, onRegist }) => {
  const [addParam, setAddParam] = useState(line);

  const registLine = useCallback(() => onRegist(addParam), [addParam]);

  const isInvalid = useMemo(() => {
    return addParam.lineRoomName === ''
    || (Array.isArray(addParam.customerLineIds) && addParam.customerLineIds.length === 0)
    || (Array.isArray(addParam.users) && addParam.users.length === 0);
  }, [addParam]);

  useEffect(() => {
    setAddParam(line);
    return () => setAddParam({});
  }, [line]);

  return {
    addParam,
    setAddParam,
    registLine,
    isInvalid,
  };
};

export default useManagmentLine;
