import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef { import(
 *   '../../apis/divisionMaster/getDivisionMastersApiTypes'
 * ).DivisionMastersGetResponse } DivisionMastersGetResponse
 * @typedef { import(
 *   '../../apis/divisionMaster/getDivisionMastersApiTypes'
 * ).Division } Division
 * @typedef {{
 *   message: string;
 *   description: string;
 *   data: {
 *     divisions: Array<Division & { toDivisionId: number }>;
 *     toDivisions: DivisionMastersGetResponse['data']['toDivisions'];
 *   }
 * }} DivisionMasters
 * @type { DivisionMasters }
 */
const initialState = {
  message: '',
  description: '',
  data: {
    divisions: [
      {
        toDivisionId: 0, // GET時のレスポンスには含まれないがPUTする時に必要なのでStateに追加して管理する
        userId: 0,
        userFullName: '',
        gradeCode: 0,
        gradeName: '',
        userRoleGroupId: 0,
        userRoleGroupName: '',
        fromDivisionId: 0,
        fromDivisionName: '',
        subDivisions: [
          {
            divisionId: 0,
            divisionName: '',
          },
        ],
      },
    ],
    toDivisions: [
      {
        divisionId: 0,
        divisionName: '',
      },
    ],
  },
};

export const divisionMastersSlice = createSlice({
  name: 'divisionMasters',
  initialState,
  reducers: {
    updateDivisionMasters: (state, data) => {
      state.message = data.payload.message;
      state.description = data.payload.description;
      state.data = data.payload.data;
    },
  },
});

export const { updateDivisionMasters } = divisionMastersSlice.actions;

export default divisionMastersSlice.reducer;
