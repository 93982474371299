import { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import commonStyles from '../styles';
import { useRerender, useObjectUpdate } from '../../hooks';

export const MAIL_CONFIRM_MESSAGE = '変更前のメールアドレス宛に送信予約されているメールは全てキャンセルされます';

export const CUSTOMER_MAIL_KEYS = ['mail1', 'mail2', 'mail3', 'mail4'];
export const CUSTOMER_ABLE_MAIL_KEYS = [
  'isMailSend1',
  'isMailSend2',
  'isMailSend3',
  'isMailSend4',
];

/**
 * API返すデータはnullの場合、
 * Formから取得値は「''」になる恐れがあるため
 * nullと「''」が同じにする
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
function isEqualsFormValue(a, b) {
  return (!a && !b) || a === b;
}

export function useMailVerification(
  initialValue,
  keys = [...CUSTOMER_MAIL_KEYS, ...CUSTOMER_ABLE_MAIL_KEYS],
) {
  const {
    originalRef,
    currentRef,
    updateAll,
    updateCurrent,
    updateOriginal,
  } = useObjectUpdate(initialValue, keys);

  const baseClasses = commonStyles();
  const dispatch = useDispatch();

  const { rerender, rerenderKey } = useRerender();

  // onBlur/onChangeなどのevent情報保存
  const eventRef = useRef(null);

  const verify = useCallback((newData, callback, cancel) => {
    dispatch(changeConfirmMessage({
      title: '変更確認ウィンドウ',
      msgList: [MAIL_CONFIRM_MESSAGE],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {
            cancel?.(originalRef.current);
            const resetData = keys.reduce((prev, key) => {
              // 値をリセットする
              prev[key] = originalRef.current[key];
              return prev;
            }, {});
            updateCurrent(resetData);
          },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => {
            updateAll(newData);
            callback?.();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  }, []);

  const onHookBlur = useCallback((e, callback, cancel) => {
    // ターゲット情報保存
    eventRef.current = e;
    const { name, value } = e.target;
    // console.log(name, value);
    // 変更なしの場合処理しない
    if (originalRef.current[name] === value) return;
    verify({ [name]: value }, callback, cancel);
  }, []);

  const verifyMail = useCallback((newData, callback, cancel) => {
    if (keys.some(key => !isEqualsFormValue(newData[key], originalRef.current[key]))) {
      verify(newData, callback, cancel);
    } else {
      callback?.();
    }
  }, []);

  return {
    verifyMail,
    onHookBlur,
    originalRef,
    currentRef,
    updateOriginal,
    updateCurrent,
    updateAll,
    rerender,
    rerenderKey,
  };
}
