import EditWindowContainers from './editWindow';

function EditWindow(props) {
  const {
    open,
    onClose,
    index,
    row,
    state,
    setState,
    updateHistory,
    deleteHistory,
    customerId,
  } = props;
  return (
    <EditWindowContainers
      open={open}
      onClose={onClose}
      index={index}
      row={row}
      state={state}
      setState={setState}
      updateHistory={updateHistory}
      deleteHistory={deleteHistory}
      customerId={customerId}
    />
  );
}

export default EditWindow;
