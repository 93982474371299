import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const divisionsGetTreeSlice = createSlice({
  name: 'divisionsGetTree',
  initialState: { divisionsGetTree: initialState },
  reducers: {
    getDivisionsTreeApi: (state, data) => {
      state.divisionsGetTree = data.payload;
    },
  },
});

export const { getDivisionsTreeApi } = divisionsGetTreeSlice.actions;

export default divisionsGetTreeSlice.reducer;
