import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: -12,
    '&>.MuiInputLabel-root': {
      fontSize: 13,
      top: '-8%',
      color: '#333',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 7px) scale(0.85)',
      },
    },
    '& .MuiInput-input': {
      height: 20,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: 13,
    },
  },
}));

function SortTextField(props) {
  const classes = useStyles();
  const { initial } = props;
  return (
    <TextField
      className={classes.root}
      fullWidth
      label={initial.title}
      value={initial.state}
      type={initial.type}
      onChange={(e) => {
        initial.set(e, initial);
      }}
    />
  );
}

export default SortTextField;
