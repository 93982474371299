import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import CustomEditDialog from '../../../eleCommon/sp/customEditDialog';
import { PORTER_RESULT_CODE } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #C8C8C8',
      background: '#fff',
      padding: '3px 8px 2px',
    },
    display: 'none',
    '&.open': {
      display: 'flex',
    },
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  radioGroup: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 730,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'unset',
    },
  },
  label: {
    display: 'block',
    margin: 0,
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
      padding: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      paddingRight: 4,
    },
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiButtonBase-root': {
      padding: 4,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '&:nth-child(1)': {
      display: 'none',
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: 0,
    width: '100%',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 8px 12px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
}));

function InfoRegisterRadio3(props) {
  const { values } = props;
  const classes = useStyles();

  const menus = Object.keys(PORTER_RESULT_CODE);

  const [value, setValue] = useState(String(values.state));

  const [text, setText] = useState(PORTER_RESULT_CODE[values.state]);

  const handleChange = (val) => {
    setValue(val);
    setText(PORTER_RESULT_CODE[val]);
  };

  // モーダル用
  const [open, setOpen] = useState(false);

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const getBox = (
    <RadioGroup
      name="vals"
      value={value}
      onChange={(e) => {
        handleChange(e.target.value);
        values.setState(e.target.value);
      }}
      className={classes.radioGroup}
    >
      {menus.map((data) => (
        <FormControlLabel
          key={data}
          value={data}
          control={<Radio />}
          label={PORTER_RESULT_CODE[data]}
          className={classes.label}
        />
      ))}
    </RadioGroup>
  );

  if (isSP) {
    contents = (
      <Grid className={`${classes.root} ${values.dis ? 'open' : ''}`}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={() => { setOpen(!open); }}
            >
              <Typography className={classes.listTtl}>
                {values.label}
                {values.required && <span>＊</span>}
              </Typography>
              <Typography className={classes.listTxt}>
                {text}
              </Typography>
            </Button>
            <CustomEditDialog
              type="buttonNone"
              open={open}
              setOpen={setOpen}
              content={getBox}
              lbl={values.label}
            />
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid
        className={`${classes.root} ${values.dis ? 'open' : ''}`}
      >
        <Typography className={classes.ttl}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        {getBox}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default InfoRegisterRadio3;
