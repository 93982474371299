import { useMemo } from 'react';
import { TableRow, TableCell, Grid, makeStyles } from '@material-ui/core';
import CustomCell from './customCell';
import CustomerStatusCode from '../../eleCommon/customerStatusCode';

const useStyles = makeStyles((theme) => ({
  row: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 4,
    cursor: 'pointer',
    '&:nth-of-type(even)': {
      background: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .flyerSalesBtn': {
        opacity: 1,
      },
    },
  },
  selected: {
    background: `${theme.palette.primaryColor}29 !important`,
  },
  popover: {
    pointerEvents: 'none',
  },
  cell1: {
    display: 'block',
    width: 29,
  },
  cell2: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: 3,
    display: 'block',
    width: 'calc(100% - 32px)',
  },
  box: {
    display: 'flex',
    marginTop: 5,
  },
  block1: {
    marginLeft: 16,
    fontWeight: 700,
  },
  block2: {
    marginLeft: 8,
    fontWeight: 700,
    '& > span': {
      fontWeight: 400,
    },
  },
  status: {
    width: 80,
  },
}));

const widthName = '80px';
const widthPhoneNumber = '110px';
const widthMail = '200px';

export const basicFields = [
  { title: '会員情報', key: 'memberStatusCode', width: '80px', type: 'status' },
  { title: '反響日', key: 'responseAt', width: '78px', type: 'date' },
  { title: '姓', key: 'lastName', width: widthName },
  { title: '名', key: 'firstName', width: widthName },
  { title: 'カナ(姓)', key: 'lastNameKana', width: widthName },
  { title: 'カナ(名)', key: 'firstNameKana', width: widthName },
  { title: '住所', key: 'address1', width: '60px', textAlign: 'center' },
  { title: 'それ以降の住所', key: 'address2', width: '108px' },
  { title: '電話番号 1', key: 'phoneNumber1', width: widthPhoneNumber },
  { title: '電話番号 2', key: 'phoneNumber2', width: widthPhoneNumber },
  { title: 'Mail 1', key: 'mail1', width: widthMail },
  { title: 'Mail 2', key: 'mail2', width: widthMail },
  { title: 'Web会員ID', key: 'memberId', width: '96px' },
  { title: 'FAX', key: 'fax', width: '46px' },
  { title: '副：姓', key: 'subLastName', width: '56px' },
  { title: '副：名', key: 'subFirstName', width: '56px' },
  { title: '電話番号 3', key: 'phoneNumber3', width: '82px' },
  { title: '電話番号 4', key: 'phoneNumber4', width: '82px' },
  { title: 'Mail 3', key: 'mail3', width: '82px' },
  { title: 'Mail 4', key: 'mail4', width: '82px' },
  { title: '媒体(大)', key: 'responseTypeName1', width: '68px' },
  { title: '媒体(中)', key: 'responseTypeName2', width: '68px' },
  { title: '媒体(小)', key: 'responseTypeName3', width: '68px' },
  { title: '媒体(細)', key: 'responseTypeName4', width: '68px' },
  { title: '顧客ID', key: 'customerId', width: '62px' },
  { title: '担当組織', key: 'divisionSubName', width: '68px' },
];

function ResponseRow(props) {
  const { row, selected, index, onClick } = props;

  const classes = useStyles();

  // 担当情報
  const userInfo = useMemo(() => {
    const arr = [];
    if (row.divisionSubName) arr.push(row.divisionSubName);
    if (row.userId) arr.push(`${row.userLastName || ''}${row.userFirstName || ''}`);
    return arr.length ? arr.join('：') : null;
  }, [row.divisionSubName, row.userId]);

  return (
    <TableRow
      id={`idResponse-${row.responseId}`}
      aria-haspopup="true"
      selected={selected}
      className={classes.row}
      classes={{ selected: classes.selected }}
      onClick={onClick}
    >
      {basicFields.map((obj) => (
        <CustomCell
          key={`${row.responseId}-${obj.key}`}
          obj={obj}
          row={row}
          index={index}
        />
      ))}
      <TableCell className={classes.cell2}>
        <Grid className={classes.box}>
          <Grid className={classes.status}>
            <CustomerStatusCode code={row.customerStatusCode} />
          </Grid>
          <Grid className={classes.block1}>
            顧客ID:{row.customerId}
          </Grid>
          <Grid className={classes.block2}>
            {userInfo}
          </Grid>
          <Grid className={classes.block2}>
            <span>反響基準日：</span>{row.effectAt.substr(0, 10)}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default ResponseRow;
