/* eslint-disable import/no-cycle */
import DeveloperLink from '../components/pages/developerLink';
import Users from '../containers/user/usersIndex';
import CustomerMainPage from '../components/pages/customerMainPage';
import ResponseTypeMasterPage from '../components/pages/responseTypeMasterPage';
import DivisionMaster from '../containers/division/divisionMaster/divisionMaster';
import DivisionUpdate from '../containers/division/division/divisionUpdate';
import InstructionListPage from '../components/pages/instructionListPage';
import UnsentMailPage from '../components/pages/unsentMail';
import AllMailPage from '../components/pages/allMail';
import ProspectsListPage from '../components/pages/prospectsListPage';
import BrowsingHistoryPage from '../components/pages/browsingHistoryPage';
import CustomerBatchPage from '../components/pages/customerBatchPage';
import MailLibraryPage from '../components/pages/mailLibrary';
import UnregisteredRecord from '../components/pages/unregisteredRecord';
import SetStoreGroupTest from '../components/pages/setStoreGroupTest';
import AutomaticProcessTest from '../components/pages/automaticProcess/automaticProcessTest';
import AllocationSettingPage from '../components/pages/allocationSettingPage';
import SimulatorPage from '../components/pages/simulatorPage';
import DivisionInformationPage from '../components/pages/divisionInformation';
import CautionPhraseListPage from '../components/pages/cautionPhraseListPage';
import CrmPage from '../components/pages/crm';
import AimSettingPage from '../components/pages/aimSettingPage';
import MainMenuSp from '../components/mainMenuSp';
import CombinationSettingPage from '../components/pages/combinationSettingPage';
import SearchTest from '../components/search/detailSearch/searchTest';
import UserTreeSelect from '../components/pages/userTreeSelect';
import CustomerTreeSelectTest from '../components/pages/customerTreeSelectTest';
import FlyerSalesTreeTest from '../components/pages/flyerSalesTreeTest';
import DashBoardPage from './pages/dashBoardPage';
import CalendarTest from './pages/CalendarTest';
import AcquisitionType from './pages/joinData/acquisitionType';
import AcquisitionName from './pages/joinData/acquisitionName';
import ValidationTest from './pages/ValidationTest';
import EditorTest from '../components/mail/hoge';
import DivisionDatePage from './pages/divisionDatePage';
import DivisionHistoryPage from './pages/divisionHistoryPage';

// URL一覧 https://docs.google.com/spreadsheets/d/1YHMkttC7N1WwpTLwWLrmIgEB-dqE5kQRLlm2EYMIl8k/

export const URL_SETTINGS_LIST = [
  { title: '開発リンク', path: '/develop/menu', element: <DeveloperLink /> },
  // SFA
  { title: '顧客メイン', path: '/sfa/customer', element: <CustomerMainPage /> },
  { title: '指示一覧', path: '/sfa/direct', element: <InstructionListPage /> },
  {
    title: '見込み案件管理表',
    path: '/sfa/customer_list',
    element: <ProspectsListPage />,
  },
  {
    title: '未送信メール',
    path: '/sfa/unsend_mail',
    element: <UnsentMailPage />,
  },
  { title: '一斉メール', path: '/sfa/mail', element: <AllMailPage /> },
  { title: 'Web履歴', path: '/sfa/web', element: <BrowsingHistoryPage /> },
  {
    title: '顧客一括管理',
    path: '/sfa/change',
    element: <CustomerBatchPage />,
  },
  {
    title: 'ライブラリ管理',
    path: '/sfa/library',
    element: <MailLibraryPage />,
  },
  {
    title: '案内予定一覧',
    path: '/sfa/schedule',
    element: <UnregisteredRecord />,
  },
  { title: 'メニュー(SP)', path: '/sfa/home', element: <MainMenuSp /> },
  {
    title: 'ダッシュボード',
    path: '/sfa/dashboard',
    element: <DashBoardPage />,
  },
  // CRM
  { title: 'CRM', path: '/crm', element: <CrmPage /> },
  // MST
  { title: '組織社員マスタ', path: '/mst/user', element: <Users /> },
  {
    title: '営業自店舗課編成',
    path: '/mst/store_user_move',
    element: <DivisionMaster />,
  },
  {
    title: '組織情報一括更新',
    path: '/mst/user_move',
    element: <DivisionUpdate />,
  },
  {
    title: '配点設定',
    path: '/mst/point_allocations',
    element: <AllocationSettingPage />,
  },
  {
    title: 'シミュレーション',
    path: '/mst/simulator',
    element: <SimulatorPage />,
  },
  {
    title: '反響媒体マスタ',
    path: '/mst/response_type',
    element: <ResponseTypeMasterPage />,
  },
  {
    title: '店舗グループ設定',
    path: '/mst/store_group',
    element: <SetStoreGroupTest />,
  },
  {
    title: '自動反響処理モード設定',
    path: '/mst/auto_regist',
    element: <AutomaticProcessTest />,
  },
  {
    title: '店舗間顧客移動設定',
    path: '/mst/customer_move',
    element: <DivisionInformationPage />,
  },
  {
    title: '注意語句一覧',
    path: '/mst/prohibited',
    element: <CautionPhraseListPage />,
  },
  { title: '目標設定', path: '/mst/target', element: <AimSettingPage /> },
  {
    title: '組合せ設定',
    path: '/mst/combination_setting',
    element: <CombinationSettingPage />,
  },
  {
    title: '課編成日付設定',
    path: '/mst/date_time',
    element: <DivisionDatePage />,
  },
  {
    title: '組織情報一括更新_履歴',
    path: '/mst/user_move_history',
    element: <DivisionHistoryPage />,
  },
  // DEVELOPER
  {
    title: '開発用 詳細簡易検索',
    path: '/develop/search',
    element: <SearchTest />,
  },
  {
    title: '開発用 組織ツリー',
    path: '/develop/usertree',
    element: <UserTreeSelect />,
  },
  {
    title: '開発用 反響ツリー',
    path: '/develop/customertree',
    element: <CustomerTreeSelectTest />,
  },
  {
    title: '開発用 源泉未処理ツリー',
    path: '/develop/flyertree',
    element: <FlyerSalesTreeTest />,
  },
  {
    title: '開発用 カレンダー',
    path: '/develop/calendar',
    element: <CalendarTest />,
  },
  {
    title: '開発用 獲得者区分RadioButton',
    path: '/develop/acquisition_radio',
    element: <AcquisitionType />,
  },
  {
    title: '開発用 獲得者区分Text',
    path: '/develop/acquisition_text',
    element: <AcquisitionName />,
  },
  {
    title: '開発用 入力項目チェック',
    path: '/develop/validation',
    element: <ValidationTest />,
  },
  {
    title: 'hoge',
    path: '/develop/editor',
    element: <EditorTest />,
  },
];

export default URL_SETTINGS_LIST;
