// see: https://codepen.io/DariaIvK/pen/EpjPRM

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

export const loadingHeight = 64;

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    display: 'inline-block',
    width: `${loadingHeight}px`,
    height: `${loadingHeight}px`,
    position: 'absolute',
    zIndex: 999999,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    '& span': {
      position: 'absolute',
      top: '27px',
      width: '11px',
      height: '11px',
      borderRadius: '50%',
      background: theme.palette.primaryColor,
      animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
      '&:nth-child(1)': {
        left: '6px',
        animation: 'lds-ellipsis1 0.6s infinite',
      },
      '&:nth-child(2)': {
        left: '6px',
        animation: 'lds-ellipsis2 0.6s infinite',
      },
      '&:nth-child(3)': {
        left: '26px',
        animation: 'lds-ellipsis2 0.6s infinite',
      },
      '&:nth-child(4)': {
        left: '45px',
        animation: 'lds-ellipsis3 0.6s infinite',
      },
    },
  },
  '@keyframes lds-ellipsis1': {
    '0%': { transform: 'scale(0)' },
    '100%': { transform: 'scale(1)' },
  },
  '@keyframes lds-ellipsis2': {
    '0%': { transform: 'translate(0, 0)' },
    '100%': { transform: 'translate(19px, 0)' },
  },
  '@keyframes lds-ellipsis3': {
    '0%': { transform: 'scale(1)' },
    '100%': { transform: 'scale(0)' },
  },
}));

function LoadingEllipsis() {
  const classes = useStyles();

  return (
    <Grid className={classes.ellipsis}>
      <span />
      <span />
      <span />
      <span />
    </Grid>
  );
}

export default LoadingEllipsis;
