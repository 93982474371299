import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import layoutStyle from '../../styles/layout';
import AutomaticProcessInner from './automaticProcessInner';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import autoRegistResponseControlSearchApi from '../../../apis/autoRegistResponse/autoRegistResponseControlSearchApi';
import autoRegistResponseControlUpdateApi from '../../../apis/autoRegistResponse/autoRegistResponseControlUpdateApi';

const useStyles = makeStyles({
  headerInner: {
    width: '100%',
    margin: '0 auto',
  },
  content: {
    backgroundColor: '#F3F3F3',
    padding: '20px',
  },
  contentInner: {
    width: '100%',
    margin: '0 auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    padding: '16px 0',
  },
});

function AutomaticProcess() {
  const classes = useStyles();
  const common = commonStyles();
  const lCommon = layoutStyle();
  const dispatch = useDispatch();
  const [values, setValues] = useState();
  const [responseAutomationControlId, setResponseAutomationControlId] = useState('');
  const getAutoRegistResponse = async () => {
    const response = await autoRegistResponseControlSearchApi();
    setResponseAutomationControlId(response.data.responseAutomationControlId);
    setValues({
      stopExecuteCode: response.data.stopExecuteCode.toString(),
      memberShipCode: response.data.memberShipCode.toString(),
      webReturnCode: response.data.webReturnCode.toString(),
      productRightCode: response.data.productRightCode.toString(),
      sendNoticeMailCode: response.data.sendNoticeMailCode.toString(),
      updateAt: response.data.updateAt,
      updateByUserName: response.data.updateByUserName,
      updateByDivisionName: response.data.updateByDivisionName,
    });
  };
  useEffect(() => {
    getAutoRegistResponse();
  }, []);
  const updateAutomaticMode = async () => {
    const setParams = {
      stopExecuteCode: values.stopExecuteCode,
      memberShipCode: values.memberShipCode,
      webReturnCode: values.webReturnCode,
      productRightCode: values.productRightCode,
      sendNoticeMailCode: values.sendNoticeMailCode,
    };
    await autoRegistResponseControlUpdateApi(responseAutomationControlId, setParams);
  };
  const handleClick1 = () => {
    console.log('CANCEL');
  };

  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '確認',
      msgList: ['設定を保存してよろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: handleClick1,
          classes: common.buttonsSecondary,
        },
        {
          label: 'OK',
          set: updateAutomaticMode,
          classes: common.buttonsPrimary,
        },
      ],
    }));
  };

  return (
    <Grid>
      <Grid className={common.header}>
        <Grid className={classes.headerInner}>
          <Typography className={common.title2}>
            自動処理モード設定
          </Typography>
        </Grid>
      </Grid>
      <Grid className={`${classes.content} ${lCommon.scrollToRow3}`}>
        <Grid className={classes.contentInner}>
          {
            values
            && <AutomaticProcessInner
              values={values}
              setValues={setValues}
            />
          }
        </Grid>
      </Grid>
      <Grid className={common.footer}>
        <Button
          className={common.buttonsPrimary}
          onClick={confirmOpen}
        >
          更新
        </Button>
      </Grid>
    </Grid>
  );
}

export default AutomaticProcess;
