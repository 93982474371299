import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import commonStyles from '../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    height: '100%',
  },
  button: {
    color: theme.palette.primaryColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    margin: '0 44px 0 46px',
    minWidth: 'auto',
  },
  buttonTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 0 0 2px',
  },
  buttonIcon: {
    fontSize: 16,
  },
  line: {
    display: 'flex',
    alignItems: 'center',
  },
  lineTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
}));

function CustomerMainHeader(props) {
  const {
    updatePage,
    isSp,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  let body;

  // SPの場合、タブバーありのモーダルを初期表示
  if (isSp) {
    body = (
      <Typography className={baseClasses.title2}>
        顧客メイン
      </Typography>
    );
  } else {
    body = (
      <>
        <Typography className={baseClasses.title2}>
          顧客メイン
        </Typography>
        <Button
          className={classes.button}
          onClick={updatePage}
        >
          <UpdateIcon className={classes.buttonIcon} />
          <Typography className={classes.buttonTxt}>
            更新
          </Typography>
        </Button>
        <Grid className={classes.line}>
          <Typography className={classes.lineTxt}>
            表示行数
          </Typography>
        </Grid>
      </>
    );
  }

  return (
    <Grid className={classes.root}>
      {body}
    </Grid>
  );
}

export default CustomerMainHeader;
