import { makeStyles } from '@material-ui/core';
import { Remove, Add } from '@material-ui/icons';

const useStyles = makeStyles({
  lastNodeIcon: {
    display: 'inline-flex',
    position: 'relative',
    width: 16,
    height: 16,
    '&::before': {
      position: 'absolute',
      width: 1,
      height: 9,
      background: '#8C8C8C',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      content: '""',
    },
    '&::after': {
      position: 'absolute',
      height: 1,
      width: 8,
      background: '#8C8C8C',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      content: '""',
    },
  },
});

export function LastTreeNodeIcon(props) {
  const { onClick } = props;
  const classes = useStyles();

  return (
    // eslint-disable-next-line
    <i className={classes.lastNodeIcon} onClick={onClick} />
  );
}

export default function CollapsedIcon(props) {
  const {
    isPlus,
    className,
    onClick,
    isLastNode,
    // TODO: { $lastNodeIcon: null | ReactNode, $plusIcon: ReactNode, $minusIcon: ReactNode }
    options = {},
  } = props;

  if (isLastNode) return typeof options.$lastNodeIcon !== 'undefined' ? options.$lastNodeIcon : <LastTreeNodeIcon />;

  return isPlus
    ? <Add className={className} onClick={onClick} />
    : <Remove className={className} onClick={onClick} />;
}
