/**
 * 【webRes008】未処理反響と顧客情報をマージした後の顧客情報更新
 * http://10.22.2.48:8080/docs/#/responses/post-webRes-update-resimpToCust
 * ※キーの順位はAPIドキュメント通り
 */
// eslint-disable-next-line import/prefer-default-export
export const rtcParams = {
  // 顧客ID
  customerId: null, // *
  // 未処理反響ID
  responseId: null, // *
  // 姓
  lastName: null,
  firstName: null,
  lastNameKana: null,
  firstNameKana: null,
  // 性別
  genderCode: null,
  // 国籍
  nationality: null,
  // 永住権
  isPermanentResidency: null,
  age: null,
  birthday: null,
  // 副・姓
  subLastName: null,
  subFirstName: null,
  subLastNameKana: null,
  subFirstNameKana: null,
  // 副・性別
  subGenderCode: null,
  // 副・国籍
  subNationality: null,
  // 副・永住権
  isSubPermanentResidency: null,
  subAge: null,
  // 副・誕生日
  subBirthday: null,
  // 関係
  relation: null,
  // お休みの曜日
  holidayWeekday: null,
  // お休みの曜日その他
  otherHolidayWeekday: null,
  // 連絡がつきやすい時間帯
  convenientTime: null,
  // 連絡がつきやすい時間帯その他
  otherConvenientTime: null,
  // TEL1
  phoneNumber1: null,
  // 電話番号１補足
  phoneNumberMemo1: null,
  phoneNumber2: null,
  phoneNumberMemo2: null,
  phoneNumber3: null,
  phoneNumberMemo3: null,
  phoneNumber4: null,
  phoneNumberMemo4: null,
  // FAX
  fax: null,
  // メールアドレス１
  mail1: null,
  // メールアドレス１補足
  mailMemo1: null,
  mail2: null,
  mailMemo2: null,
  mail3: null,
  mailMemo3: null,
  mail4: null,
  mailMemo4: null,
  // メールアドレス１送信可否
  isMailSend1: null, // +
  isMailSend2: null, // +
  isMailSend3: null, // +
  isMailSend4: null, // +
  // 郵便番号
  zip: null,
  // 住所・都道府県
  prefecture: null,
  // 住所・市区町村
  address1: null,
  // 住所・それ以降の住所
  address2: null,
  // 住所・建物名
  building: null,
  // 家族人数・大人
  adultCount: null,
  // 家族人数・子供
  childCount: null,
  // 子供の年齢1
  childAge: null,
  otherChildAge: null,
  // 入居予定人数
  familyTotalCount: null,
  // 入居予定・誰
  familyWho: null,
  // 勤務先1
  company: null,
  // 勤務先路線ID1
  companyRailwayId: null,
  // 勤務先駅ID1
  companyStationId: null,
  // 勤務場所1
  companyAddress: null,
  // 勤務年数1
  companyWorkingYear: null,
  // 職業種
  jobType: null,
  // 勤務先2
  subCompany: null,
  // 勤務先路線ID2
  subCompanyRailwayId: null,
  // 勤務先駅ID2
  subCompanyStationId: null,
  subCompanyAddress: null,
  subCompanyWorkingYear: null,
  // 副・職業種
  subJobType: null,
  // 主顧客年収
  annualIncome: null,
  // 副顧客年収
  subAnnualIncome: null,
  // 頭金
  deposit: null,
  // 月々の支払
  monthlyPayment: null,
  // ボーナス支払
  bonusPayment: null,
  // 購入援助金額
  assistAmount: null,
  // 電話番号可否
  telStatusCode: null, // *
  // メール送信可否
  emailStatusCode: null, // *
  // メモ
  memo: null,
  // ランク(担当者)
  userLevel: 3,
  // 希望地域コード
  wishAreaCode: null,
  wishAreaIds: null,
  // 希望路線１
  wishRailwayId1: null,
  // 希望駅From１
  wishStationIdFrom1: null,
  // 希望駅To１
  wishStationIdTo1: null,
  wishRailwayId2: null,
  wishStationIdFrom2: null,
  wishStationIdTo2: null,
  // 希望学区
  wishSchoolDistrict1: null,
  wishSchoolDistrict2: null,
  // マンション検討フラグ
  isThinkApartment: null, // *
  // 戸別希望額下限
  detachedHouseBudgetMin: null,
  detachedHouseBudgetMax: null,
  // 土地希望額下限
  landBudgetMin: null,
  landBudgetMax: null,
  // 希望居住広さ
  livingSpace: null,
  // 間取りコード
  floorPlanCode: null,
  // 土地希望広さ
  wishLandArea: null,
  // 重視するポイント
  importantPoint: null,
  // 検討のきっかけ
  reason: null,
  // 入居希望時期
  wishMoveInDay: null,
  // 購入目的
  purchasePurpose: null,
  // 一棟物投資検討フラグ
  isThinkWholeHouseInvestment: null,
  // 買取検討フラグ
  isThinkSale: null,
  // 区分投資検討フラグ
  isThinkDivisionInvestment: null,
  // 建替検討フラグ
  isThinkRebuilding: null,
  // これまで見た物件の種類
  checkedProductType: null,
  // これまで見た物件の件数
  checkedProductCount: null,
  // 種類(現況)
  abodeNowBuildingType: null,
  // 間取りコード(現況)
  abodeNowFloorPlanCode: null,
  // 面積(現況)
  abodeNowLivingSpace: null,
  // 希望売却価格(現況)
  abodeNowSalePrice: null,
  // 当時の価格(現況)
  abodeNowPriceAtTime: null,
  // 居住期間(現況)
  abodeNowResidencePeriod: null,
  // 家賃(現況)
  abodeNowRent: null,
  // 買替有無(現況)
  abodeNowReplacementCode: null,
  // 買替種類(現況)
  abodeNowReplaceType: null, // +
  // 借入残年数(現況)
  abodeNowCurrentMaturity: null,
  // 借入銀行(現況)
  abodeNowBankName: null,
  // 借入額(現況)
  abodeNowBorrowingAmount: null,
  // 車種(現況)
  abodeNowCarType: null, // +
  abodeNowCarCount: null, // +
  // 駐車場希望フラグ
  isCarPark: null,
  // 追わない詳細理由
  noContactDetailReasonCode: null, // +
  // 購入できない理由
  reasonNotBuy: null, // +
  // お客様が探していなかった理由
  reasonNotSearch: null, // +
  // 要望エリア
  searchArea: null, // +
  // OHDにはまらない理由
  reasonNotOhd: null, // +
  // 検討開始時期
  searchStartAt: null, // +
  // 組織ID
  divisionId: null, // +*
  // 社員ID
  userId: null, // *
  // 更新者ID
  updatedBy: null, // *
  // 顧客情報取得APIで取得した時間
  getDataAt: null,
  // 獲得者ID
  acquisitionUserId: null, // +
  // 概要
  summary: null,
  // 物件ID
  siteId: null,
  // 物件部屋ID
  buildingId: null, // +
  // 上長メモ
  leaderMemo: null,
  // 獲得者種類コード
  acquisitionCode: null, // +
  // 問合せ日
  inquiredAt: null, // +
  // 物件名
  productName: null,
  // 棟・部屋名
  buildingName: null, // +
  // 確認不要フラグ
  isNotCheckUpdate: null,
  // WEB再反/WEB戻し
  isReResponse: null, // +
  // 働き方コード
  workingStyleCode: null,
  subWorkingStyleCode: null,
  // 反響媒体ID(大)
  largeResponseTypeId: null, // *
  mediumResponseTypeId: null,
  smallResponseTypeId: null,
  fineResponseTypeId: null,
  // 反響日
  effectAt: null,
  // 獲得者メモ
  acquisitionMemo: null, // +
  // 顧客紹介フラグ
  isCustomerIntroduction: null, // +
  // responseAt: null,
  abodeNowPurchaseAt: null,
  // wishArea: null,
  // acquisitionId: null,
  reasonKnowOh: null,
  customerStatusCode: 2,
  driveUrl1: null,
  driveUrl2: null,
  driveUrl3: null,
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5013
  // joinDataのformatSetDataで使用している
  isNoCustomer: null,
};
