import { isArray } from '../../../../commonFunction';
// // 社員マスタ・基本社員情報ツリー
const getTreeObjectFunction = (
  list,
  word,
  isShowOnlyDivision = false,
  noAffiliList = null,
  doubleSearch = false,
  // userId = null,
  // divisionId = null,
) => {
  const searchResultArr = [];
  const saveUserIds = [];
  const saveDivisionIds = [];
  const saveNoAffliUserIds = [];

  // ユーザーの検索
  const getUserItem = (parent, parentId) => {
    if (parent.users?.length) {
      for (let i = 0; parent.users.length > i; i += 1) {
        if (parent.users[i].userName.includes(word)) {
          if (!(saveUserIds.includes(`${parentId}${parentId ? '_' : ''}user${parent.users[i].userId}`))) {
            searchResultArr.push({
              userId: parent.users[i].userId,
              userIds: `${parentId}${parentId ? '_' : ''}user${parent.users[i].userId}`,
              userName: parent.users[i].userName,
              divisionId: parent.divisionId,
              divisionName: parent.subName,
            });
            saveUserIds.push(`${parentId}${parentId ? '_' : ''}user${parent.users[i].userId}`);
          }
        }
      }
    }
    if (parent.children?.length) {
      // eslint-disable-next-line no-use-before-define
      getChildItem(parent.children, parentId);
    }
  };

  // 組織の検索
  const getDivisionItem = (parent, parentId) => {
    if ('levelCode' in parent) {
      if (parent.subName.includes(word)) {
        if (!(saveDivisionIds.includes(`${parentId}${parentId ? '_' : ''}division${parent.divisionId}`))) {
          searchResultArr.push({
            divisionId: parent.divisionId,
            divisionIds: `${parentId}${parentId ? '_' : ''}division${parent.divisionId}`,
            divisionName: parent.subName,
          });
          saveDivisionIds.push(`${parentId}${parentId ? '_' : ''}division${parent.divisionId}`);
        }
      }
    }
    if (parent.children?.length) {
      // eslint-disable-next-line no-use-before-define
      getChildItem(parent.children, parentId);
    }
  };

  const getChildItem = (treeList, parentId = '') => {
    for (let i = 0; treeList.length > i; i += 1) {
      const item = treeList[i];
      const thisParentId = `${parentId}${parentId ? '_' : ''}division${item.divisionId}`;
      if (doubleSearch) {
        getDivisionItem(item, thisParentId);
        getUserItem(item, thisParentId);
      } else {
        isShowOnlyDivision ? getDivisionItem(item, thisParentId) : getUserItem(item, thisParentId);
      }
    }
  };

  // 所属無し用
  const getNoAffiliUsers = (noList) => {
    for (let i = 0; noList.length > i; i += 1) {
      const item = noList[i];
      if (item.userName.includes(word)) {
        if (!saveNoAffliUserIds.includes(`division0_user${item.userId}`)) {
          searchResultArr.push({
            userId: item.userId,
            userIds: `division0_user${item.userId}`,
            userName: item.userName,
            divisionId: 0,
            divisionName: '',
          });
          saveNoAffliUserIds.push(`division0_user${item.userId}`);
        }
      }
    }
  };

  // 検索条件の全ての要素をUserId毎に切り分ける -> 同一userIdの場合重複選択する必要があるため。
  // const setUserIdObj = () => {
  //   for (let i = 0; searchResultArr.length > i; i += 1) {
  //     const elementChain = searchResultArr[i].userIds;
  //     const singleWord = elementChain.split('-');
  //     const userIdStr = singleWord[singleWord.length - 1];
  //     if (userIdStr in searchResultObj) {
  //       // eslint-disable-next-line max-len
  //       if (!(searchResultObj[userIdStr].userIds.includes(elementChain)))
  // searchResultObj[userIdStr].userIds.push(elementChain);
  //     } else {
  //       searchResultObj[userIdStr] = {};
  //       searchResultObj[userIdStr].userId = searchResultArr[i].userId;
  //       searchResultObj[userIdStr].userIds = [elementChain];
  //       searchResultObj[userIdStr].userName = searchResultArr[i].userName;
  //     }
  //   }
  // };

  getChildItem(list);
  if (isArray(noAffiliList)) getNoAffiliUsers(noAffiliList);
  return searchResultArr;
};

export default getTreeObjectFunction;
