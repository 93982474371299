import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  // Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../../../styles';

const useStyles = makeStyles((theme) => ({
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptTime: {
    '& .MuiFormGroup-root': {
      justifyContent: 'flex-end',
      flexWrap: 'nowrap',
      flexDirection: 'initial',
      width: '100%',
    },
    '& .MuiButtonBase-root': {
      display: 'none',
    },
  },
  contentIptTimeLbl: {
    margin: '0 0 0 16px',
    '& .MuiTypography-root > span': {
      fontSize: 10,
      textAlign: 'left',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: '0',
      color: '#8C8C8C',
    },
    '& .MuiTypography-root > span span:first-child': {
      fontSize: 18,
    },
    '& .MuiRadio-colorSecondary.Mui-checked + span span': {
      color: theme.palette.primaryColor,
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 4,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function RegisterWindowDate(props) {
  const {
    time,
    setTime,
    timeChange,
    timeChangeSP,
    getParts,
    // getEditDialog,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const CONTACT_MINUTES = {
    0: '5分程度',
    1: '15分程度',
    2: '30分以上',
  };

  const menus = Object.keys(CONTACT_MINUTES);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // SP 項目選択時に更新
  const onSelect = (val) => {
    setTime(val);
    timeChangeSP(val);
  };

  const getTime = () => {
    return (
      <Select defaultValue={99}>
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus.map((data) => {
          return (
            <MenuItem
              value={data}
              key={data}
              onClick={() => {
                onSelect(data);
              }}
            >
              {CONTACT_MINUTES[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '通話時間',
      val: CONTACT_MINUTES[time],
      content: getTime,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
                onClick={() => { console.log(item); }}
              >
                {getParts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.contentIpt}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          通話時間
        </Typography>
        <Grid className={classes.contentIptVal3}>
          <FormControl className={classes.contentIptTime} component="fieldset">
            <RadioGroup name="times" value={time} onChange={timeChange}>
              <FormControlLabel
                value="time1"
                control={<Radio />}
                label={<span><span>5</span><span>分程度</span></span>}
                className={classes.contentIptTimeLbl}
              />
              <FormControlLabel
                value="time2"
                control={<Radio />}
                label={<span><span>15</span><span>分程度</span></span>}
                className={classes.contentIptTimeLbl}
              />
              <FormControlLabel
                value="time3"
                control={<Radio />}
                label={<span><span>30</span><span>分以上</span></span>}
                className={classes.contentIptTimeLbl}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
