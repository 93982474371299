import React from 'react';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import DriveFileRenameIcon from '../../icons/fileRename';
import { GUIDANCE_STATUS_CODE } from '../../../constants';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    position: 'relative',
    transition: 'all 0.5s ease important',
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      cursor: 'pointer',
      '& .button': {
        opacity: 1,
      },
    },
  },
  customerName: {
    '& a': {
      color: theme.palette.primaryColor,
    },
  },
  guidanceStatus: {
    color: '#333333',
  },
  button: {
    width: '58px',
    height: '24px',
    padding: '2px 3px',
    opacity: 0,
    margin: 'auto 0',
  },
  buttonTxt: {
    color: theme.palette.primaryColor,
  },
  icon: { fill: theme.palette.primaryColor },
}));

function UnregisteredRecordTablesRow(props) {
  const {
    row,
    getCustomerFunction,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  const judgeGuidanceStatus = (guidanceStatusCode) => {
    return GUIDANCE_STATUS_CODE[guidanceStatusCode];
  };

  const sliceGuidanceStartAt = (guidanceStartAt) => {
    const result = guidanceStartAt.slice(0, -3);
    return result;
  };

  const editUnregistRecord = () => {
    getCustomerFunction(row);
  };

  return (
    <TableRow className={classes.tableRow} hover>
      <TableCell className={`${common.small}`}>{sliceGuidanceStartAt(row.guidanceStartAt)}</TableCell>
      <TableCell className={`${common.smallStrong} ${classes.customerName}`}>
        <Link href={`${URL_MAPPER.customer}?customerId=${row.customerId}`} target="_blank">{row.customerName}</Link>
      </TableCell>
      <TableCell className={`${common.small} ${classes.guidanceStatus}`}>{judgeGuidanceStatus(row.guidanceStatusCode)}</TableCell>
      <TableCell className={`${common.small}`}>{row.guidanceGetUserName}</TableCell>
      <TableCell>
        <Button
          className={`${common.buttonsSecondary} ${classes.button} button`}
          onClick={editUnregistRecord}
        >
          <DriveFileRenameIcon className={classes.icon} />
          <Typography className={`${common.title4} ${classes.buttonTxt}`}>
            編集
          </Typography>
        </Button>
      </TableCell>
      <TableCell className={`${common.small}`}>{row.memo}</TableCell>
    </TableRow>
  );
}

export default UnregisteredRecordTablesRow;
