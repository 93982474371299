import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const directGetInforSlice = createSlice({
  name: 'directGetInfor',
  initialState: { directGetInfor: initialState },
  reducers: {
    directGetInforApi: (state, data) => {
      state.directGetInfor = data.payload;
    },
  },
});

export const { directGetInforApi } = directGetInforSlice.actions;

export default directGetInforSlice.reducer;
