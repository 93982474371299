import {
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  List,
  ListItem,
  Collapse,
  Grid,
  Typography,
  makeStyles,
  Checkbox,
  FormLabel,
} from '@material-ui/core';
import { Folder, Person } from '@material-ui/icons';
import commonStyles from '../../styles';
import { classNames } from '../../../commonFunction';
import CollapsedIcon from './collapsedIcon';
import { useTreeContext } from './context';
import { TREE_TYPES } from './const';
import { cloneReactElement, splitUniqueId, isHaveDivision } from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 0,
  },
  minus: {
    '&:not(:last-child)::after': {
      content: '""',
      height: 'calc(100% - 25px)',
      position: 'absolute',
      backgroundColor: '#c8c8c8',
      width: '1px',
      left: '7.5px',
      top: '25px',
    },
  },
  listItem: {
    padding: 0,
    '& button > span': {
      justifyContent: 'flex-start',
    },
    '& .tree-item-extands-hook': {
      display: 'none',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      whiteSpace: 'nowrap',
    },
    '&:hover .tree-item-extands-hook': {
      display: 'flex',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  child: {
    padding: '0 0 0 20px',
  },
  collapsedIcon: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 1,
    cursor: 'pointer',
  },
  icon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
    flexShrink: 0,
    '&.tree-item__selectable-icon': {
      cursor: 'pointer',
    },
    '& svg': {
      maxWidth: '100%',
    },
  },
  treeItem: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px 2px',
    margin: '0',
    fontSize: '12px',
    borderRadius: 4,
    fontWeight: 500,
    letterSpacing: '0.02857em',
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': { background: `${theme.palette.primaryColor}29` },
  },
  isChecked: {
    background: 'linear-gradient(90deg, #A7F1FF 0%, #C2EEFF 100%)',
  },
  isFocusDivision: {
    // background: 'linear-gradient(90deg, #A7F1FF 0%, #C2EEFF 100%)',
    border: '0.5px solid #A7F1FF',
  },
  last: {
    marginLeft: '8px',
    '&::before': {
      content: '""',
      borderLeft: '1px #c8c8c8 solid',
      borderBottom: '1px #c8c8c8 solid',
      display: 'block',
      width: '15px',
      height: '23px',
      transform: 'translateY(-8px)',
    },
  },
}));

const DEF_ICONS = {
  [TREE_TYPES.division]: <Folder />,
  [TREE_TYPES.user]: <Person />,
};

/**
 * TreeItem
 * @param {{
 * data: {
 * label: string;
 * selectable: boolean;
 * children: [],
 * },
 * }} props
 * @returns
 */
export default function TreeItem(props) {
  const { data, eventDivisionId, isUserTree, isParent = false } = props;

  const {
    options,
    selectedUniqueIds, // 選択されたアイテムのKey
    defaultOpenUniqueIds,
    defaultOpenTypes,
    onItemClick, // 選択されたときの処理関数
    onCollapsedIconClick,
    addCollapseLisenter,
    removeCollapseLisenter,
    checkbox,
    onCheckboxChange,
    selectedIsMultipleSearch = [],
    resetIsMultipleSearch,
    isCollapse,
    multiple,
    isSearch = { current: false },
    searchHeader,
    displayCustomer,
  } = useTreeContext();

  const [isOpen, setIsOpen] = useState(false);

  const common = commonStyles();
  const classes = useStyles();

  useEffect(() => {
    isOpen ? setIsOpen(false) : '';
  }, [searchHeader]);

  // 別組織を選択した時に選択前の組織のisOpenがtrueのままな為
  useEffect(() => {
    if (!displayCustomer) {
      if (selectedUniqueIds.length === 0) isOpen ? setIsOpen(false) : '';
      if (!multiple && selectedUniqueIds.length === 1 && selectedUniqueIds[0]) {
        const selectedIds = selectedUniqueIds[0]?.split('_');
        const judgeOpen = selectedIds.includes(`${data.type ? data.type : ''}${data.id ? data.id : ''}`);
        setIsOpen(judgeOpen);
      }
    }

    if (selectedUniqueIds.length === 0) isOpen ? setIsOpen(false) : '';
  }, [selectedUniqueIds]);

  // リストアイテムのスタイル
  const styles = useMemo(() => {
    return options.styles?.[data.type];
  }, [options.styles, data.type]);

  // アイコン
  const icon = useMemo(() => {
    const customIcon = options.icons?.[data.type];
    // optionsに指定されたアイコンを使用, なければデフォルトアイコンを使用する, 非表示= {icons: { user: null }}
    return typeof customIcon === 'undefined' ? DEF_ICONS[data.type] : customIcon;
  }, [data.type, options.icons]);

  // optionsのelement(<Grid />, <CreateNewFolder />, <DriveFileRenameIcon />)を使い
  // ・組織の時のみhoverNodeにElementの値が入る, 無所属 = hideHoverNodes === true, 各々のdivisionに合ったelementを複製する
  const extandComp = useMemo(() => {
    const hoverNode = options.hoverNodes?.[data.type];
    return hoverNode && !data.hideHoverNodes ? cloneReactElement(hoverNode, data) : null;
  }, [data.type, options.hoverNodes]);

  // Tree最後の要素かどうか
  const isLastNode = useMemo(() => {
    if (checkbox && data.type === 'division' && !isUserTree) return !isHaveDivision(data);
    if (isParent) {
      // 組織追加後のツリー取得しなし時にtトップのディレクトリのアイコンがマイナスのままになるため
      setIsOpen(false);
    }
    return !data.children?.length;
  }, [data.children]);

  // defaultでOpenするCollapseをset
  /**
   * @param {arr} defaultOpenUniqueIds - ex: ['division1', 'deivision1-division4']
   * @param {str} data.uniqueId - ex: 'division1'
   */
  useEffect(() => {
    if (defaultOpenUniqueIds.length) {
      if (selectedUniqueIds?.length && selectedUniqueIds[0] !== undefined && !multiple) {
        const selectArr = splitUniqueId(selectedUniqueIds[0], false, true);
        // eslint-disable-next-line max-len
        if (defaultOpenUniqueIds.includes(data.uniqueId) && selectArr?.includes(data.uniqueId)) setIsOpen(true);
      } else {
        setIsOpen(defaultOpenUniqueIds.includes(data.uniqueId));
      }
    } else if (defaultOpenTypes.length) {
      setIsOpen(defaultOpenTypes.includes(data.type));
    }
  }, [defaultOpenUniqueIds]);

  useEffect(() => {
    const collapsedHandler = (item) => {
      if (isOpen && item.uniqueId !== data.uniqueId) {
        setIsOpen(false);
      }
    };
    addCollapseLisenter(data, collapsedHandler);
    return () => {
      removeCollapseLisenter(collapsedHandler);
    };
  }, [isOpen]);

  // 開閉対象のidをセット
  const setEventDivId = (id) => {
    eventDivisionId.current = id;
    isCollapse.current = true;
  };

  // 開閉時、フロントに表示させる部分のみレンダリング
  /**
   * @param {array} - selectedUniqueIds 検索や選択された対象
   * @param {array} - selectedIsMultipleSearch 複数パターン + 検索時
   * @returns TreeItem
   */
  const getChildrens = () => {
    const key = data.type + String(data.id || data.id === 0 ? data.id : '');
    let judge = false;
    // 開閉のパターン
    if (isCollapse.current) {
      judge = [data?.id].includes(eventDivisionId.current);
      // FIXME: 2023/03/22 ツリーの開閉。一旦これで様子見る。SFA_ASIAQUEST-3992
      if (eventDivisionId.current && isOpen && data.id !== eventDivisionId.current) {
        if (data.uniqueId.split('_').includes(`division${eventDivisionId.current}`)) setIsOpen(false);
      }
    // eslint-disable-next-line max-len
    } else if (selectedUniqueIds[0] || selectedIsMultipleSearch[0] || selectedUniqueIds.length > 1) {
      if (selectedIsMultipleSearch[0] && multiple && isSearch?.current && isUserTree) {
        // FIXME: 要件次第で下記修正
        for (let i = 0; selectedIsMultipleSearch.length > i; i += 1) {
          if (selectedIsMultipleSearch[i].split('_').includes(key)) {
            judge = true;
            break;
          }
        }
      } else {
        for (let i = 0; selectedUniqueIds.length > i; i += 1) {
          if (selectedUniqueIds[i]?.split('_').includes(key)) {
            judge = true;
            break;
          }
        }
      }
    }
    if (judge) {
      return (
        data.children?.map((item, i) => (
          // eslint-disable-next-line max-len
          <TreeItem key={String(i)} data={item} eventDivisionId={eventDivisionId} isUserTree={isUserTree} />
        ))
      );
    }
    return null;
  };

  if (checkbox && data.type !== 'division' && !isUserTree) return <div />;
  return (
    <List className={`${classes.root} ${isOpen && classes.minus}`}>
      <ListItem
        id={data?.uniqueId}
        className={classNames(
          common.small,
          classes.listItem,
        )}
      >
        <FormLabel
          className={classes.label}
          style={styles}
        >
          <CollapsedIcon
            options={options.icons}
            className={classes.collapsedIcon}
            isPlus={!isOpen}
            isLastNode={isLastNode}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsOpen(!isOpen);
              onCollapsedIconClick(!isOpen, data);
              setEventDivId(data.id);
              isCollapse.current = true;
              isSearch.current = false;
            }}
          />
          {/* Checkbox */}
          { checkbox && data.selectable ? (
            <Checkbox
              // eslint-disable-next-line max-len
              checked={selectedUniqueIds.includes(data.uniqueId)}
              onChange={(e) => {
                onCheckboxChange(e, data);
                isCollapse.current = false;
                isSearch.current = false;
              }}
            />
          ) : null}
          {/* Icon */}
          {!icon ? null : (
            <Grid
              className={classNames(
                classes.icon,
                { 'tree-item__selectable-icon': data.selectable },
              )}
              onClick={() => {
                if (data.selectable) {
                  onItemClick?.(data);
                  isCollapse.current = false;
                  isSearch.current = false;
                }
              }}
            >
              {icon}
            </Grid>
          )}
          {/* Text */}
          {data.selectable ? (
            <Grid
              className={classNames(
                classes.treeItem,
                // eslint-disable-next-line max-len
                selectedUniqueIds.includes(data.uniqueId) || selectedIsMultipleSearch.includes(data.uniqueId) ? classes.isChecked : null,
              )}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onItemClick?.(data);
                resetIsMultipleSearch();
                isCollapse.current = false;
                isSearch.current = false;
              }}
            >
              {data.label}
            </Grid>
          ) : (
            <Typography
              className={classNames(
                classes.treeItem,
                common.small,
                // eslint-disable-next-line max-len
                selectedUniqueIds.includes(data.uniqueId) || selectedIsMultipleSearch.includes(data.uniqueId) ? classes.isFocusDivision : null,
              )}
            >
              {data.label}
            </Typography>
          )}
        </FormLabel>
        {/* 拡張内容 組織追加・編集 */}
        {!extandComp ? null : <Grid className="tree-item-extands-hook">{extandComp}</Grid>}
      </ListItem>
      <Collapse
        id={`${data?.uniqueId}_collapse`}
        in={isOpen}
        className={classes.child}
        timeout={0}
      >
        {getChildrens()}
      </Collapse>
    </List>
  );
}
