import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import commonStyles from '../../styles';
import PersonalIndex from './parts/personalIndex';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px 24px 8px',
    position: 'static',
    width: '1024px',
    height: '52px',
    left: '0px',
    top: '0px',
    borderBottom: '1px solid #C8C8C8',
    '& h2': {
      fontWeight: 'bold',
    },
  },
  contentElement: {
    width: '100%',
    position: 'static',
    height: '322px',
    left: '0px',
    top: '53px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    '&>*': {
      boxSizing: 'border-box',
      width: '100%',
    },
  },
  customTitle3: {
    boxSizing: 'border-box',
    width: '100%',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
  dialogMainPaper: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        height: '70%',
      },
    },
  },
}));

function PersonalGoal(props) {
  const { userDetail, getMokuhyoList, resetMokuhyoList } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        className={`${baseClasses.buttonsPrimary} ${classes.userCustomButton} ${baseClasses.title4}`}
        disableElevation
      >
        目標設定
      </Button>
      <PersonalIndex
        userDetail={userDetail}
        getMokuhyoList={getMokuhyoList}
        resetMokuhyoList={resetMokuhyoList}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default PersonalGoal;
