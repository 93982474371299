import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import EditCautionPhrase from './editCautionPhrase';
import DriveFileRenameIcon from '../icons/fileRename';
import commonStyles from '../styles';

const useStyles = makeStyles((theme) => ({
  tableWrap: {
    marginTop: 16,
    paddingTop: 0,
  },
  thCell: {
    color: '#8C8C8C',
    background: '#fff',
  },
  cell: {
    padding: 2,
  },
  wrap: {
    padding: '4px 8px',
    marginBottom: 8,
    background: '#fff',
    alignItems: 'center',
    border: '1px #c8c8c8 solid',
  },
  radioWrap: {
    flexDirection: 'row',
  },
  require: {
    marginRight: 16,
    '& span': { color: '#D83420' },
  },
  hover: { background: `${theme.palette.primaryColor}29` },
  opacity: { opacity: 0 },
  icon: { fill: theme.palette.primaryColor },
}));

const CAUTION_PHRASE_HEADER = [
  { id: 'word', label: '語句', width: 'auto' },
  { id: 'wordEdit', label: '', width: 80 },
  { id: 'controlRangeCode', label: '制御範囲', width: 'auto' },
  { id: 'createdAt', label: '登録日時', width: 'auto' },
  { id: 'updatedAt', label: '更新日時', width: 'auto' },
];
const WHITE_PHRASE_HEADER = [
  { id: 'word', label: '語句', width: 'auto' },
  { id: 'wordEdit', label: '', width: 80 },
  { id: 'createdAt', label: '登録日時', width: 'auto' },
  { id: 'updatedAt', label: '更新日時', width: 'auto' },
];

export default function PhraseListComponent(props) {
  const { words, onRegister, onUpdate, onDelete, isWhiteWord = 0 } = props;
  const common = commonStyles();
  const classes = useStyles();
  const tableContainerHeight = (window.innerHeight - 270) + 'px';
  const headers = isWhiteWord ? WHITE_PHRASE_HEADER : CAUTION_PHRASE_HEADER;
  const initialWordObj = {
    wordId: '',
    word: '',
    controlRangeCode: 0,
    isWhiteWord,
  };
  const [open, setOpen] = useState(false);
  const [isRegist, setIsRegist] = useState(false);
  const [wordObj, setWordObj] = useState(initialWordObj);
  const [hover, setHover] = useState({});

  const [sortKey, setSortKey] = useState({});

  const handleClick = (value) => {
    setHover({ ...hover, [`${value}On`]: !hover[`${value}On`] });
  };

  const dispatch = useDispatch();
  const handleClick1 = () => {
    console.log('CANCEL');
  };

  const confirmOpen = (wordObj1) => {
    const handleClick2 = () => {
      onDelete({
        wordId: wordObj1.wordId,
        word: wordObj1.word,
        controlRangeCode: wordObj1.controlRangeCode,
        isWhiteWord,
      });
    };
    dispatch(
      changeConfirmMessage({
        title: '選択項目を削除',
        msgList: ['削除してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: handleClick1,
            classes: common.buttonsSecondary,
          },
          {
            label: 'OK',
            set: handleClick2,
            classes: common.buttonsPrimary,
          },
        ],
      }),
    );
  };

  const entryFunc = () => {
    if (isRegist) {
      onRegister(wordObj);
    } else {
      onUpdate(wordObj);
    }
    setOpen(false);
  };

  const setSort = (keyId) => {
    setSortKey((s) => {
      const { key, order } = s;
      if (keyId === key) return { key, order: order === 'asc' ? 'desc' : 'asc' };
      return { key: keyId, order: 'desc' };
    });
  };

  const sortFunc = (a, b) => {
    const { key, order } = sortKey;
    if (!key) return 0;
    const aVal = a[key];
    const bVal = b[key];

    const isBool = order === 'asc' ? aVal > bVal : bVal > aVal;
    return isBool ? -1 : 1;
  };

  return (
    <>
      <Button
        className={common.buttonsPrimary}
        onClick={() => {
          setWordObj(initialWordObj);
          setIsRegist(true);
          setOpen(true);
        }}
      >
        <Add />
        新規追加
      </Button>
      <EditCautionPhrase
        wordObj={wordObj}
        setWordObj={setWordObj}
        open={open}
        setOpen={setOpen}
        onRegister={entryFunc}
        isRegist={isRegist}
        isWhiteWord={isWhiteWord}
      />
      <TableContainer
        className={`${classes.tableWrap} ${common.box}`}
        style={{ height: tableContainerHeight }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell
                  className={`${common.title4} ${classes.cell} ${classes.thCell}`}
                  key={h.id}
                  style={{ width: h.width }}
                >
                  <TableSortLabel
                    active={sortKey.key === h.id}
                    direction={sortKey.key === h.id ? sortKey.order : 'asc'}
                    onClick={() => setSort(h.id)}
                  >
                    {h.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell style={{ width: 80 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {words.sort(sortFunc).map((w) => (
              <TableRow
                key={`key${w.wordId}`}
                className={hover[`${w.wordId}On`] ? classes.hover : null}
                onMouseOver={() => {
                  handleClick(w.wordId);
                }}
                onMouseOut={() => {
                  handleClick(w.wordId);
                }}
              >
                <TableCell className={`${classes.cell} ${common.title6}`}>
                  {w.word}
                </TableCell>
                <TableCell className={`${classes.cell} ${common.title6}`}>
                  <Button
                    className={`${common.buttonsSecondary} ${
                      !hover[`${w.wordId}On`] ? classes.opacity : null
                    }`}
                    onClick={() => {
                      setIsRegist(false);
                      setOpen(true);
                      return setWordObj({
                        wordId: w.wordId,
                        word: w.word,
                        controlRangeCode: w.controlRangeCode,
                        isWhiteWord,
                      });
                    }}
                  >
                    <DriveFileRenameIcon className={classes.icon} />
                    編集
                  </Button>
                </TableCell>
                {!isWhiteWord && (
                  <TableCell className={`${classes.cell} ${common.title6}`}>
                    {w.controlRangeCode ? 'メール機能のみ' : 'SFA全体'}
                  </TableCell>
                )}
                <TableCell className={`${classes.cell} ${common.title6}`}>
                  {w.createdAt}
                </TableCell>
                <TableCell className={`${classes.cell} ${common.title6}`}>
                  {w.updatedAt}
                </TableCell>
                <TableCell className={`${classes.cell} ${common.title6}`}>
                  <Button
                    onClick={() => {
                      confirmOpen(w);
                    }}
                    className={`${common.buttonsSecondary} ${
                      !hover[`${w.wordId}On`] ? classes.opacity : null
                    }`}
                  >
                    <Delete />
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
