import client from '../httpClient';

/**
 * @module userDeleteApi - 【user008】ユーザ削除
 * @param {object} params - 以下内容
 * @param {integer} divisionId
 * @param {integer} isReceiveShare
 * @param {integer} isSendShare
 * @param {integer in array} customerStatusCode
 * @param {integer} userDivisionId
 */
const userDeleteApi = (userDivisionId, params) => {
  return client.delete(`/users/${String(userDivisionId)}`, { params, isShowLoading: true });
};

export default userDeleteApi;
