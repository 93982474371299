import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';

import gensenStyle from './gensenRegisterStyles';
import ListItem from './listItem';
import { convertForm1 } from '../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormTelNum,
  validateFormEmail,
} from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

function ChangeField(props) {
  const { data, setData, id, validator, convertor, errorCallback, readOnly } = props;
  const gCommon = gensenStyle();
  const [txt, setTxt] = useState('');

  const onChange = (e) => {
    if (convertor) {
      setTxt(convertor(e.target.value));
    } else {
      setTxt(e.target.value);
    }
  };
  const onClick = () => {
    setData({ ...data, [id]: txt });
  };

  useEffect(() => {
    setTxt(data[id] || '');
  }, [data]);

  return (
    <TextBaseField
      value={txt ?? ''}
      onChange={(e) => {
        return onChange(e);
      }}
      placeholder="-"
      className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
      onBlur={onClick}
      validator={validator}
      errorCallback={errorCallback}
    />
  );
}

const list = [
  {
    id: 'phoneNumber1',
    label: '電話番号',
    require: true,
    validator: validateFormTelNum(),
  },
  {
    id: 'phoneNumberMemo',
    label: '電話番号 補足',
    validator: validateFormString1({ maxLengthInt: 10 }),
    convertor: convertForm1,
  },
  {
    id: 'mail1',
    label: 'Mail',
    validator: validateFormEmail(),
  },
  {
    id: 'mailMemo',
    label: 'Mail 補足',
    validator: validateFormString1({ maxLengthInt: 10 }),
    convertor: convertForm1,
  },
];

export default function AddContact(props) {
  const { data, setData, readOnly, errorCallback } = props;
  const gCommon = gensenStyle();

  return (
    <Grid className={gCommon.blockWrap}>
      {list.map((item, i) => (
        <ListItem
          key={String(i)}
          readOnly={readOnly}
          label={item.label}
          required={item.require}
          noBtn
        >
          <ChangeField
            data={data}
            setData={setData}
            id={item.id}
            label={item.label}
            validator={item.validator}
            convertor={item.convertor}
            errorCallback={errorCallback(item.id)}
            readOnly={readOnly}
          />
        </ListItem>
      ))}
    </Grid>
  );
}
