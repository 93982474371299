import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles({
  textField: {
    border: 'solid 1px #C8C8C8',
    backgroundColor: '#ffffff',
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
  },
  contentIpt: {
    padding: '8px',
    display: 'flex',
  },
  buttonTypography: {
    border: 'none',
    fontSize: 13,
    '&:focus': {
      outline: 'none',
    },
  },
  productButton: {
    maxWidth: 440,
    width: '100%',
  },
  main: {
    fontWeight: 700,
    flexGrow: 1,
  },
  label: {
    width: 80,
    flexShrink: 0,
  },
});

function ResHouseField(props) {
  const { house } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.contentIpt}>
      <Typography className={classes.label}>
        物件名
      </Typography>
      <Typography className={classes.main}>
        {house ? house.productName : ''}
      </Typography>
    </Grid>
  );
}

export default ResHouseField;
