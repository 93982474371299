import { forwardRef } from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import { LabelWrap } from '../../common/labelWrap';
import { classNames } from '../../../commonFunction';
import { validateFormInt1 } from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItem: 'center',
    fontSize: '0.875rem',
    '& .MuiInputBase-root': {
      fontSize: '0.875rem',
    },
    '& .MuiTextField-root.disabled .MuiInput-underline::before': {
      borderColor: 'transparent',
    },
  },
  suffix: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  input: {
    width: '8em',
  },
});

const CustomInput = forwardRef((props, ref) => {
  const {
    label,
    labelWidth,
    value,
    defaultValue,
    required,
    name,
    id,
    onChange,
    onBlur,
    className,
    style,
    inline,
    readOnly,
    disabled,
    suffix,
    type,
    gap,
    errorCallback,
  } = props;

  const classes = useStyles();

  return (
    <LabelWrap
      label={label}
      labelWidth={labelWidth}
      className={classNames(
        classes.root,
        className,
      )}
      inline={inline}
      gap={gap}
      style={style}
      required={required}
    >
      <TextBaseField
        className={classNames(classes.input, { disabled })}
        name={name}
        id={id}
        required={required}
        value={value}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        inputProps={{
          readOnly,
          type,
          disabled,
        }}
        ref={ref}
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999999999.0 })}
        convertor={convertFormNumber}
        errorCallback={errorCallback}
      />
      {suffix && (
        <Grid className={classes.suffix}>{ suffix }</Grid>
      )}
    </LabelWrap>
  );
});

export default CustomInput;
