import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
// import CustomEditDialog from '../../../eleCommon/sp/customEditDialog';
import { validateFormString1 } from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles({
  contentIpt: {
    width: '100%',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '3px 10px',
    marginBottom: 20,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  memoTxtField: {
    width: '100%',
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    // '& .MuiInput-underline:hover:before, &:hover .MuiInput-underline:hover:after': {
    //   borderBottom: 'none',
    // },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'block',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    textAlign: 'left',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    width: '15%',
    marginRight: '0',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: '#D83420',
      padding: '0',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
    '& span': {
      margin: '0 8px',
    },
  },
});

function Memo(props) {
  const {
    editableFlag,
    values,
    setUpdateData,
    errorCallback,
    isGensen = false,
    setIsMemo,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // モーダル用
  const [open, setOpen] = useState(false);

  let contents;

  const [text, setText] = useState(editableFlag ? values.memo : '');
  const handleChange = (e) => {
    setText(e.target.value);
  };
  const handleSet = () => {
    setUpdateData({ ...values, memo: text });
    setIsMemo?.(!!text);
  };

  const getBox = (
    <TextBaseField
      value={text}
      onChange={handleChange}
      onBlur={handleSet}
      className={classes.memoTxtField}
      placeholder={isGensen ? '源泉場所を記入してください' : '-'}
      validator={validateFormString1({ maxLengthInt: 255 })}
      errorCallback={errorCallback('memo')}
    />
  );

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={() => { setOpen(!open); }}
            >
              <Typography className={classes.listTtl}>
                メモ
                {isGensen ? <span>＊</span> : ''}
              </Typography>
              {getBox}
            </Button>
            {/* <CustomEditDialog
              open={open}
              setOpen={setOpen}
              content={getBox}
              set={handleSet}
              lbl="メモ"
            /> */}
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.contentIpt}>
        <Typography style={{ width: '15%' }} className={`${baseClasses.title6} ${classes.listTtl}`}>
          メモ
          {isGensen ? <span>＊</span> : ''}
        </Typography>
        {getBox}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default Memo;
