import { useState, useRef, useEffect, useMemo } from 'react';
import {
  Button,
  Grid,
  TextField,
  Input,
  makeStyles,
  Typography,
  Dialog,
  Box,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { DeleteOutline, AddCircle } from '@material-ui/icons';
import commonStyles from '../../styles';
import gensenStyle from './gensenRegisterStyles';
import ListItem from './listItem';

import {
  base64ToBlob,
  createBlobURL,
  formatFileName,
} from '../../../commonFunction';
import { compressImage } from '../../../commonFunction/compressImage';

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    color: '#D83420',
    width: '15.4%',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  inputWrap: {
    position: 'relative',
    width: '70%',
    '& .MuiInput-root': {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
  },
  boxLbl: {
    // position: 'absolute',
    zIndex: 2,
    // top: 0,
    // left: 0,
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 0 5px',
    textAlign: 'right',
    pointerEvents: 'none',
  },
  boxLbl2: {
    // position: 'absolute',
    zIndex: 2,
    // top: 0,
    // left: 0,
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.3,
    letterSpacing: 0,
    padding: '9px 0 5px',
    width: '100%',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  lblWrap: {
    width: '70%',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'right',
    '& span': {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
  },
  imgWrap: {
    maxWidth: '80%',
    position: 'relative',
    flexGrow: 1,
    padding: '8px',
    borderBottom: '1px #f3f3f3 solid',
  },
  input: { opacity: 0 },
  hiddenInput: { opacity: 0, pointerEvents: 'none' },
  dialogTitle: {
    padding: '16px',
  },
  titleTxt: {
    fontSize: 13,
    width: '100%',
    textAlign: 'center',
    fontWeight: '700',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeBtn: {
    padding: '6px 20px',
    margin: '0 8px',
    fontSize: 13,
  },
}));
function AddAttachmentBlock(props) {
  const { list = [], onChange, index, readOnly } = props;
  const common = commonStyles();
  const classes = useStyles();
  const gCommon = gensenStyle();

  const [isShown, setIsShown] = useState(false);

  const item = useMemo(() => {
    if (!list[index]) list[index] = {};
    return list[index];
  }, [list, index]);

  const deleteFunc = () => {
    if (!list.length) return;
    list.splice(index, 1);
    onChange([...list]);
  };

  const onFileInputChange = async (e) => {
    const inputFile = e.target.files[0]; // fileList obj
    const compressedImage = await compressImage(inputFile);
    // 画像ファイルのみが対象にする
    if (/^image/.test(inputFile.type)) {
      list[index] = {
        ...list[index],
        file: compressedImage,
        fileName: formatFileName(inputFile).join('.'),
      };
      onChange([...list]);
    }
    // 表示名変更後に再度同じファイルをアップできるようにするため
    e.target.value = '';
  };
  const [open, setOpen] = useState(false);

  return (
    <Grid container className={gCommon.blockWrap}>
      <Button
        className={classes.deleteBtn}
        onClick={deleteFunc}
      >
        <DeleteOutline className={gCommon.icon} />
        <Typography>削除</Typography>
      </Button>
      <Grid className={classes.imgWrap}>
        <Grid className={gCommon.display}>
          <Typography className={`${common.title6} ${gCommon.ttl3}`}>源泉写真<span>＊</span></Typography>
          <Grid className={classes.inputWrap}>
            {item.fileName ? (
              <Button className={classes.boxLbl2} onClick={() => setIsShown(true)}>
                {item.file?.name || item.fileName}
              </Button>
            ) : (
              <Typography className={classes.boxLbl}>アップロード</Typography>
            )}
            <Input
              type="file"
              onChange={(ev) => onFileInputChange(ev)}
              inputProps={{
                accept: 'image/*',
              }}
              className={item.name ? classes.hiddenInput : classes.input}
            />
          </Grid>
        </Grid>
        <Grid className={gCommon.display}>
          <Typography className={`${common.title6} ${gCommon.ttl}`}>表示名<span>＊</span></Typography>
          <ImageNameField
            isShow={open}
            onClose={() => setOpen(false)}
            value={item.fileName}
            onChange={(newName) => {
              list[index].fileName = newName;
              onChange([...list]);
            }}
            label="表示名"
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      {isShown && (
        <DisplayImageModal
          open={isShown}
          setOpen={setIsShown}
          item={item}
        />
      )}
    </Grid>
  );
}

function ImageNameField(props) {
  const { isShow, value, onChange, readOnly } = props;
  const gCommon = gensenStyle();
  const [fileName, setFileName] = useState('');
  const [extension, setExtension] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (isShow && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isShow, inputRef]);

  useEffect(() => {
    if (fileName !== value) {
      const [_extension, ..._fileName] = value?.split('.').reverse() || ['', value];
      setFileName(_fileName);
      setExtension(_extension);
    }
  }, [value]);

  return (
    <TextField
      value={fileName}
      className={`${gCommon.txtfield} ${readOnly && gCommon.readOnlyTxtField}`}
      placeholder="-"
      onChange={(e) => {
        setFileName(e.target.value);
      }}
      onBlur={() => {
        onChange(`${fileName}.${extension}`);
      }}
      inputRef={inputRef}
      disabled={!value}
    />
  );
}

function DisplayImageModal(props) {
  const {
    item,
    open,
    setOpen,
  } = props;
  const classes = useStyles();
  const common = commonStyles();

  const blob = item.file || base64ToBlob(item.base64, item.mimeType);
  const url = createBlobURL(blob);

  return (
    <Dialog
      open={open}
      onClose={() => { return setOpen(false); }}
    >
      <DialogTitle
        className={classes.dialogTitle}
      >
        <Typography className={classes.titleTxt}>
          {item.fileName}
        </Typography>
      </DialogTitle>
      <Box
        component="img"
        alt={item.fileName}
        src={url}
      />
      <DialogActions
        className={classes.dialogActions}
      >
        <Button
          className={`${common.buttonsSecondary} ${classes.closeBtn}`}
          onClick={() => { return setOpen(false); }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AddAttachment(props) {
  const { list, onChange, readOnly } = props;
  const common = commonStyles();
  const gCommon = gensenStyle();

  const [isShown, setIsShown] = useState(false);
  const currentItem = useRef({});

  const onClick = (item) => {
    currentItem.current = item;
    setIsShown(true);
  };

  // 入力がない段階で＋追加は押せないようにする
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2980#comment-165033451
  const disabled = useMemo(() => {
    return !list.length || list.some((item) => !item?.fileName);
  }, [list]);

  // 詳細表示の際
  if (readOnly) {
    return (
      <>
        {list.map((item, i) => (
          <ListItem key={String(i)} label="源泉写真" onClick={() => onClick(item)}>
            {item.fileName}
          </ListItem>
        ))}
        <DisplayImageModal
          open={isShown}
          setOpen={setIsShown}
          item={currentItem.current}
        />
        {list.length ? <Box m={5} /> : null}
      </>
    );
  }

  return (
    <>
      {list.length ? list.map((_, i) => (
        <AddAttachmentBlock
          key={i.toString()}
          list={list}
          onChange={onChange}
          index={i}
          readOnly={readOnly}
        />
      )) : (
        <AddAttachmentBlock
          key={0}
          list={list}
          onChange={onChange}
          index={0}
          readOnly={readOnly}
        />
      )}
      <Button
        onClick={() => onChange([...list, null])}
        className={`${common.buttonsSecondary} ${gCommon.addBtn}`}
        disabled={disabled}
      >
        <AddCircle className={gCommon.icon} />
        追加
      </Button>
    </>
  );
}
