import client from '../httpClient';

/**
 * 【claim003】クレーム更新
 * http://10.22.2.48:8080/docs/#/claim/put-claims
 * @param {{
 * claimId: number;
 * customerId: number;
 * ...
 * }} params
 * @returns {Promise<{}>}
 */
const claimUpdateApi = (params) => {
  return client.put('/claims', params, { isShowLoading: true });
};

export default claimUpdateApi;
