import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Icon,
} from '@material-ui/core';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    background: 'rgba(39, 174, 96, 0.16)',
    display: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '17px 0',
      margin: '0 auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 591,
      height: 24,
      margin: '0 auto 8px',
    },
    '&.on': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  icon: {
    color: '#27AE60',
    fontSize: 24,
  },
  txt: {
    color: '#27AE60',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    margin: '0 0 -2px 2px',
  },
}));

function InfoRegisterStatus(props) {
  const { contract } = props;

  const classes = useStyles();

  return (
    <Grid
      className={`${classes.root} ${contract ? 'on' : ''}`}
    >
      <Icon className={classes.icon}>verified</Icon>
      <Typography className={classes.txt}>
        契約済み
      </Typography>
    </Grid>
  );
}

export default InfoRegisterStatus;
