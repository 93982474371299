import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const sendmailGetInfoSlice = createSlice({
  name: 'sendmailGetInfo',
  initialState: { sendmailGetInfo: initialState },
  reducers: {
    sendmailGetInfoApi: (state, data) => {
      state.sendmailGetInfo = data.payload;
    },
  },
});

export const { sendmailGetInfoApi } = sendmailGetInfoSlice.actions;

export default sendmailGetInfoSlice.reducer;
