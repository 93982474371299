/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false, // eslint-disable-line
  msg: '',
  title: '',
  msgList: [],
};

export const apiMessageSlice = createSlice({
  name: 'messageIsOpen',
  initialState: initialState, // eslint-disable-line
  reducers: {
    isOpen: (state, res) => {
      state.isOpen = true;
      state.status = res.payload.status;
      state.msg = res.payload.description;
      state.msgList = res.payload.messageList;
      state.title = res.payload.message;
    },
    isClose: (state) => {
      state.isOpen = false;
    },
  },
});

export const { isOpen, isClose } = apiMessageSlice.actions;

export default apiMessageSlice.reducer;
