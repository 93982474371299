import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';

import FlyerSalesRow, { basicFields } from './parts/flyerSalesRow';
import HeaderCell from './parts/headerCell';
import commonStyles from '../styles';
import { useLoading } from '../../hooks';

import Header from './header';
import FlyerSalesTree from '../common/flyerSalesTree/flyerSalesTree';
import SelectWindow from '../responseList/selectWindow';
import webResCreateCustomerApi from '../../apis/webRes/webResCreateCustomerApi';
import webResDeleteApi from '../../apis/webRes/webResDeleteApi';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import { FAMILY_STRUCTURE_CODE } from '../../constants/flyerSalesList';

import { CRM_FLYER_SALES } from '../../constants/loading';
import { crmPageActions } from '../../store/crmPage';

const useStyles = makeStyles({
  tree: {
    width: '300px',
    // height: '300px',
    '& > div': {
      height: '100%',
      '& > div': {
        height: '100%',
        '& > div': {
          height: '100%',
          // '& > div > div > .MuiCollapse-root': {
          //   maxHeight: 241,
          //   overflow: 'scroll',
          // },
        },
      },
    },
  },
  list: {
    width: 'calc(100% - 300px)',
    height: '310px',
    overflow: 'scroll',
    background: '#fff',
  },
  tableContainer: {
    height: 'calc(300px - 63px)',
    overflow: 'auto',
  },
});

// ソート
function descendingComparator(a, b, orderBy) {
  // if (b[orderBy] < a[orderBy]) {
  //   return -1;
  // }
  // if (b[orderBy] > a[orderBy]) {
  //   return 1;
  // }
  // return 0;
  // nullとstringの比較の場合、常にfalseになってしまうので変換する
  const aVal = a[orderBy] === null ? '' : a[orderBy];
  const bVal = b[orderBy] === null ? '' : b[orderBy];
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function toRowStateFromApiResponse(response) {
  const responsibleUser = `${response.responsibleDivisionName || ''} ${
    response.responsibleUserName || ''
  }`.trim();
  const acquisitionUser = `${response.acquisitionDivisionName || ''} ${
    response.acquisitionUserLastName || ''
  } ${response.acquisitionUserFirstName || ''}`.trim()
  // 獲得者のユーザーIDがない場合はテキストを表示
  || response.acquisitionMemo;
  const familyStructure = response.familyStructureCode
    ? FAMILY_STRUCTURE_CODE[response.familyStructureCode]
    : '';

  return {
    ...response,
    responsibleUser,
    acquisitionUser,
    familyStructure,
  };
}

function ResponseList(props) {
  const {
    // responseTypeList,
    responsesObj,
    getResponses,
    setSelectUser,
    setSelectDivision,
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();

  const [selectedResponseId] = useState();

  const { addLoading, removeLoading, hasLoading } = useLoading();

  // responsesObj.responses変換用
  const [responses, setResponses] = useState([]);

  // ソート
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('index');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const reloadHanlderRef = useRef(null);

  /**
   * 登録ウィンドウ、削除ウィンドウで操作中の反響情報を保存
   */
  const responseRowDataRef = useRef({});

  // GA015 担当者変更ウィンドウの制御
  const [isOpenSelectWindow, setIsOpenSelectWindow] = useState(false);
  // 登録ボタンをクリック
  const onClickRegister = (rowData) => {
    responseRowDataRef.current = rowData;
    setIsOpenSelectWindow(true);
  };

  const completeSelectWindow = async (data) => {
    addLoading();
    try {
      await webResCreateCustomerApi({
        responseId: responseRowDataRef.current.responseId,
        userId: data.userId,
        divisionId: data.divisionId,
      });
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4838 ③
      // キーワードで検索
      const searchKeyword = ['customerLastName', 'customerFirstName'].map((key) => responseRowDataRef.current[key] ?? '').join('');
      dispatch(crmPageActions.setSearchKeyword({ searchWord: searchKeyword, isForceSearch: true }));
      // 源泉未処理ツリー集計更新
      await reloadHanlderRef.current?.();
      await getResponses();
    } finally {
      setIsOpenSelectWindow(false);
      removeLoading();
    }
  };

  // 削除ボタンをクリック
  const onClickDelete = (rowData) => {
    responseRowDataRef.current.responseId = rowData.responseId;
    dispatch(
      changeConfirmMessage({
        title: '反響情報の削除確認',
        msgList: [
          '該当の反響情報を削除しますか？',
          '削除を行うと、反響情報のデータが全て消えます。',
        ],
        buttons: [
          {
            label: 'Cancel',
            classes: common.buttonsPrimary,
            set: () => {},
          },
          {
            label: 'OK',
            classes: common.buttonsSecondary,
            set: async () => {
              addLoading();
              await webResDeleteApi(responseRowDataRef.current.responseId);
              // 源泉未処理ツリー集計更新
              await reloadHanlderRef.current?.();
              await getResponses();
              removeLoading();
            },
          },
        ],
      }),
    );
  };

  const onClickReload = async () => {
    addLoading(CRM_FLYER_SALES);
    await reloadHanlderRef.current?.();
  };

  useEffect(() => {
    const result = responsesObj.responses.map((row) => {
      return toRowStateFromApiResponse(row);
    });
    setResponses(result);
  }, [responsesObj]);

  return (
    <Grid container className={hasLoading(CRM_FLYER_SALES) ? common.loading : ''}>
      <Grid className={classes.tree}>
        <FlyerSalesTree
          setSelectUser={setSelectUser}
          setSelectDivision={setSelectDivision}
          reloadHanlder={(callback) => {
            reloadHanlderRef.current = callback;
          }}
          // targetFlgList={{
          //   userFlg: true,
          //   divisionFlg: true,
          //   customerFlg: false,
          // }}
        />
      </Grid>
      <Grid className={classes.list}>
        <Header
          getResponses={getResponses}
          count={responsesObj.total}
          onClickReload={onClickReload}
        />
        <TableContainer id="flyerSalesList" className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {basicFields.map((obj) => (
                  <HeaderCell
                    key={obj.key}
                    obj={obj}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(responses, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <FlyerSalesRow
                      key={row.responseId}
                      row={row}
                      // responseTypeList={responseTypeList}
                      selected={row.responseId === selectedResponseId}
                      index={index}
                      onClickRegister={onClickRegister}
                      onClickDelete={onClickDelete}
                    />
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <SelectWindow
          isOpen={isOpenSelectWindow}
          setIsOpen={setIsOpenSelectWindow}
          onSelect={completeSelectWindow}
        />
      </Grid>
    </Grid>
  );
}

export default ResponseList;
