import {
  FormControl,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from '@material-ui/core';
import { formatValue } from '../../../commonFunction';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
    },
  },
}));

/**
 * RadioGroup
 * @param {{
 * value: *;
 * onChange?: (value: *, event: Event) => void;
 * items: *[];
 * labelKey?: string;
 * valueKey?: string;
 * }} props
 * @returns
 */
export default function RadioGroup(props) {
  const {
    value,
    onChange,
    items = [],
    labelKey = 'label',
    valueKey = 'value',
  } = props;

  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <MuiRadioGroup
        row
        name="target"
        value={value}
        onChange={(e, val) => {
          onChange?.(formatValue(val), e);
        }}
      >
        {items.map((item, i) => (
          <FormControlLabel
            key={String(i)}
            value={item[valueKey]}
            control={<Radio />}
            label={item[labelKey]}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
