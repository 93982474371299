import { Grid, Drawer, Typography, List, ListItem, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';

const useStyles = makeStyles({
  list: {
    padding: 16,
  },
  item: {
    padding: 0,
  },
  link: {
    fontSize: 14,
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    color: '#333',
    display: 'flex',
  },
  linkTtl: {
    width: '30%',
  },
});

export default function SmsWindow(props) {
  const { open, onClose, customer } = props;
  const classes = useStyles();
  const common = commonStyles();

  console.log(customer);

  const menu = [
    {
      ttl: 'TEL1',
      content: customer.phoneNumber1,
    },
    {
      ttl: 'TEL2',
      content: customer.phoneNumber2,
    },
    {
      ttl: 'TEL3',
      content: customer.phoneNumber3,
    },
    {
      ttl: 'TEL4',
      content: customer.phoneNumber4,
    },
  ];

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      className={common.drawer}
    >
      <Grid
        className={`${common.header} ${common.drawerHeaderSp} ${common.onlySp}`}
      >
        <Typography className={common.title3}>電話</Typography>
      </Grid>
      <List className={classes.list}>
        {menu.map((data) => (
          <ListItem
            key={data.ttl}
            className={`${common.title4} ${classes.item}`}
          >
            {data.content ? (
              <Link
                className={classes.link}
                href={`tel:${data.content}`}
              >
                <Typography className={classes.linkTtl}>{data.ttl}</Typography>
                <Typography className={classes.linkNo}>{data.content}</Typography>
              </Link>
            ) : (
              <Typography
                className={classes.link}
              >
                <Typography className={classes.linkTtl}>{data.ttl}</Typography>
                <Typography className={classes.linkNo}>-</Typography>
              </Typography>
            )}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
