import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:nth-child(even)': {
      [commonTheme.breakpoints.up('md')]: {
        background: '#F3F3F3',
      },
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '10.5px 4px',
    textAlign: 'left',
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px solid #8C8C8C',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
    },
    '&:nth-child(1)': {
      [commonTheme.breakpoints.up('md')]: {
        width: 108,
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: 34,
      },
    },
    '&:nth-child(2)': {
      [commonTheme.breakpoints.down('sm')]: {
        width: 80,
        paddingRight: 12,
      },
    },
    '& > span, & > div > span': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  },
  formatDate: {
    '& > span': {
      textAlign: 'center',
    },
  },
  minutes: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#D83420',
    '& > span': {
      display: 'block',
      textAlign: 'center',
      lineHeight: 1,
      '&:last-child': {
        marginTop: 4,
      },
    },
  },
  date: {
    textAlign: 'center',
  },
  name: {
    marginTop: 4,
  },
  dl: {
    display: 'flex',
    alignItems: 'baseline',
    margin: 0,
  },
  dt: {
    flexShrink: 0,
  },
  dd: {
    marginLeft: 8,
  },
  link: {
    color: theme.palette.primaryColor,
  },
  icon: {
    display: 'block',
    fontSize: 16,
    color: theme.palette.primaryColor,
    margin: '0 auto',
  },
  red: {
    color: '#D83420',
  },
}));

export default function ChanceTableRow(props) {
  const {
    row,
  } = props;

  const classes = useStyles();
  const common = commonStyles();

  // const [related, setRelated] = useState(false);

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  // 時間表記の分岐
  const dateSwitch = (date) => {
    const now = new Date();
    const reDate = new Date(date);
    let formatDate;
    const diffMinutes = ((now - reDate) / 1000 / 60); // 現在→取得した日時の差分(分)
    const diffYear = ((now - reDate) / 1000 / 60 / 60 / 24); // 現在→取得した日時の差分(年)

    if (diffMinutes < 60) {
      // // 赤色にマーキング
      // setRelated(true);
      formatDate = (
        <Grid className={classes.minutes}>
          <span>{Math.floor(diffMinutes)}</span>
          <span className={common.title5}>分前</span>
        </Grid>
      );
    } else if (diffYear < 365) {
      formatDate = (
        <Grid className={classes.formatDate}>
          <span className={common.title4}>{date.slice(5, 10)}</span>
          <span className={common.title5}>{date.slice(10, 16)}</span>
        </Grid>
      );
    } else {
      formatDate = (
        <Grid className={classes.formatDate}>
          <span className={common.title5}>{date.slice(0, 4)}</span>
          <span className={common.title4}>{date.slice(5, 10)}</span>
          <span className={common.title5}>{date.slice(10, 16)}</span>
        </Grid>
      );
    }

    return formatDate;
  };

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.tableRow}
    >
      <TableCell className={`${classes.tableTd} ${classes.date}`}>
        {isSP ? (
          <>{dateSwitch(row.receiveAt)}</>
        ) : (
          <span>{row.receiveAt}</span>
        )}
      </TableCell>
      <TableCell className={classes.tableTd}>
        {isSP ? (
          // TODO:一時間以内の場合、文字色を赤色に変更
          <Grid>
            <span className={`${common.title4}`}>{row.segment.split(' ')[0]}</span>
            <span className={`${common.title5} ${classes.name}`}>{row.segment.split(' ')[1]}</span>
          </Grid>
        ) : (
          <span>{row.segment}</span>
        )}
      </TableCell>
      {isSP && (
        <TableCell className={classes.tableTd}>
          <dl className={classes.dl}>
            <dt className={`${common.title5} ${classes.dt}`}>反響日</dt>
            <dd className={classes.dd}>{row.responseAt}</dd>
          </dl>
          <dl className={classes.dl}>
            {/* TODO:担当者の所属情報がチャンス通知履歴取得APIにない？（要確認） */}
            <dt className={`${common.title5} ${classes.dt}`}>担当者</dt>
            <dd className={classes.dd}>{row.customerLastName} {row.customerFirstName}</dd>
          </dl>
        </TableCell>
      )}
    </TableRow>
  );
}
