import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  zips: [
    {
      localGovementCode: 1234,
      oldZip: 123456,
      zip: 12345678,
      prefectureKana: 'トウキョウト',
      cityKana: 'スギナミク',
      townKana: 'アサガヤ',
      prefecture: '東京都',
      city: '杉並区',
      town: '阿佐ヶ谷',
      multiZipCode1: 3,
      multiZipCode2: 3,
      multiZipCode3: 3,
      multiZipCode4: 3,
      multiZipCode5: 3,
      multiZipCode6: 3,
    },
    {
      localGovementCode: 12,
      oldZip: 1234,
      zip: 123456,
      prefectureKana: 'カナガワケン',
      cityKana: 'ヨコハマシツヅキク',
      townKana: 'ナカガワ',
      prefecture: '神奈川県',
      city: '横浜市都筑区',
      town: '中川',
      multiZipCode1: 1,
      multiZipCode2: 2,
      multiZipCode3: 3,
      multiZipCode4: 4,
      multiZipCode5: 5,
      multiZipCode6: 6,
    },
  ],
};

const zipsSlice = createSlice({
  name: 'zips',
  initialState: { zips: initialState },
  reducers: {
    getZipsApi: (state, res) => {
      state.zips = res.payload;
    },
  },
});

export const { getZipsApi } = zipsSlice.actions;

export default zipsSlice.reducer;
