import { useState } from 'react';
import customerSearchApi from '../../apis/customer/customerSearch';
import CustomerSearch from '../../components/customerSearch/customerSearch';
import { useLoading } from '../../hooks';

import { CRM_CUSTOMER_SEARCH } from '../../constants/loading';

const initialData = () => {
  return {
    total: 0,
    customers: [],
  };
};

const initial = {
  searchWord: '',
  effectStartAt: '',
  effectEndAt: '',
  divisionId: '',
  userId: '',
  // userStatusCode: '',
  userSearchCode: '',
  customerStatusCode: '',
  responseTypeId1: '',
  responseTypeId2: '',
  responseTypeId3: '',
  responseTypeId4: '',
};

function CustomerSearchContainers() {
  // /customers/search
  // 【customer011】顧客情報曖昧検索
  const [data, setData] = useState(initialData());
  const [searchValue, setSearchValue] = useState({ ...initial });
  const { addLoading, removeLoading } = useLoading();

  const searchStart = async (offsetAndLimit) => {
    // searchValueチェック
    const params = {};
    // searchWordが入力されているとき全角英数字を半角に
    if (searchValue.searchWord) {
      searchValue.searchWord = searchValue.searchWord.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 65248));
    }
    // 入力していないものを省く
    Object.keys(searchValue).forEach((key) => {
      if (searchValue[key]) params[key] = searchValue[key];
    });
    // searchValueが全て省かれたときに検索をしない
    if (!Object.keys(params).length) {
      setData(initialData());
      return;
    }
    addLoading(CRM_CUSTOMER_SEARCH);
    // 有効なパラメータチェック
    // ※Eventの可能性がある
    if (!offsetAndLimit?.limit) {
      offsetAndLimit = { offset: 0, limit: 25 };
    }
    customerSearchApi({ ...params, ...offsetAndLimit })
      .then((res) => {
        const wrapper = document.getElementById('customerSearch').parentNode;
        wrapper.scrollTo(0, 0);
        if (!res.data.total) {
          setData(initialData);
          return;
        }
        const { customers, total } = res.data;
        setData({
          total,
          customers,
        });
      })
      .finally(() => removeLoading(CRM_CUSTOMER_SEARCH));
  };

  const setBeforeSearchValue = (value) => {
    if (value) {
      setSearchValue(value);
    } else {
      // 検索条件をクリアの場合
      setSearchValue({ ...initial });
      // 一覧クリアしないと、ページングがおかしい
      setData(initialData());
    }
  };

  return (
    <CustomerSearch
      data={data}
      searchValue={searchValue}
      setSearchValue={setBeforeSearchValue}
      searchStart={searchStart}
    />
  );
}

export default CustomerSearchContainers;
