import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Checkbox,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 350,
  },
  checkbox: {
    margin: '0 -2px',
    padding: 0,
    '& > span': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginRight: 5,
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-checked svg': {
      color: theme.palette.primaryColor,
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    width: '100%',
    borderBottom: '1px #8C8C8C solid',
    borderRadius: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: `${theme.palette.primaryColor}29`,
      borderColor: '#000',
    },
    '& .MuiButton-label': {
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
}));

export default function InstructionsWindowCheckBoxButton(props) {
  const { values, setOpen, setSelectMultiUser, editState, setEditState } = props;

  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (values[0]) {
      values[0].userId ? setChecked(true) : setChecked(false);
    }
  }, [values]);

  // チェック外したらその他担当者も初期化
  const handleChange = (event) => {
    if (!event.target.checked) {
      setSelectMultiUser([]);
      setEditState({ ...editState, others: [] });
    }
    setChecked(event.target.checked);
  };

  /**
   * @module returnUsers - 担当者複数選択
   * @returns {jsx} - editState/others === true、ユーザー名を繋ぎで返す
   * @returns {jsx} - editState/others === false、固定文句を返す
   */
  const returnUsers = () => {
    if (values[0]) {
      if (values[0].userId) {
        return (
          values.map((data) => (
            <span>{data.userName}, </span>
          ))
        );
      }
    }
    return <span>その他担当者（複数可）</span>;
  };

  return (
    <Grid className={classes.root}>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        name="checkedA"
        className={classes.checkbox}
      />
      <Button
        type="button"
        disabled={!checked}
        className={classes.btn}
        onClick={() => setOpen(true)}
      >
        {returnUsers()}
      </Button>
    </Grid>
  );
}
