import { createSlice } from '@reduxjs/toolkit';

/** @type { import('../../apis/act/getActGuidancesApiTypes').ActGuidancesGetResponse } */
const initialState = {
  message: '',
  description: '',
  data: {
    total: 0,
    guidances: [],
  },
};

export const actGuidancesSlice = createSlice({
  name: 'actGuidances',
  initialState,
  reducers: {
    updateActGuidances: (state, action) => {
      state.message = action.payload.message;
      state.description = action.payload.description;
      state.data = action.payload.data;
    },
  },
});

export const { updateActGuidances } = actGuidancesSlice.actions;

export default actGuidancesSlice.reducer;
