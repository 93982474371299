import Main from './main';

function ActionSchedule() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default ActionSchedule;
