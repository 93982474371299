import React from 'react';
import {
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    width: '246px',
    padding: '6px 0',
    borderBottom: '1px #8c8c8c solid',
    borderRadius: 0,
    background: 'transparent top 50% right 5px/17px no-repeat',
    backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%238c8c8c" d="M7 10l5 5 5-5z" /></svg>\')',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
});
function InfoChangeHistoryButton(props) {
  const { values } = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    <div className={classes.wrap}>
      <Button
        type="button"
        className={`${classes.btn} ${common.modalBtn}`}
        onClick={values.click}
      >
        {values.state ? values.state : values.label}
      </Button>
    </div>
  );
}

export default InfoChangeHistoryButton;
