const modifyObject = (key, title, isEmpty = false) => {
  if (!isEmpty) {
    const newObj = { menus: key, title };
    return newObj;
  }
  key[''] = '　';
  const changedArray = Object.entries(key);
  const element = changedArray.splice(changedArray.length - 1, 1);
  const arrays = [...element, ...changedArray];
  const newArray = arrays.map((array) => {
    return array[1];
  });
  const newObj = { menus: newArray, title };
  return newObj;
};

export default modifyObject;
