// 反響ツリー

// 分類
export const type = {
  menus: {
    0: '反響日',
    1: 'ランク',
    2: '電話の最新会話日',
    3: 'メール最終送信日',
  },
  title: '分類',
};

// 指示分類
export const TYPE_OF_DIRECT = {
  menus: {
    4: '指示ツリー',
  },
  title: '分類',
};

// 単位
export const unit = {
  menus: {
    // 0: '未選択',
    // 1: '地域',
    2: '部',
    3: 'センター',
    4: '課',
    // 5: '課員', // 20230602 次郎君と会話した上で不要と決定
  },
  title: '単位',
};

// user/index.js 組織・社員マスタツリー
export const USERS_TREE = {
  menus: {
    1: '基本社員情報ツリー',
    2: '組織ツリー(戸建)',
  },
  title: '',
};
export const division = {
  menus: {
    0: '戸建事業部',
    1: '開発事業部',
    2: 'マンション事業',
    3: 'ソリューション事業部',
    4: 'ウェルス・マネジメント事業部',
  },
  title: '事業部',
};
