import { Grid, makeStyles } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    flexWrap: 'nowrap',
  },
  listLbl: {
    fontWeight: 700,
    textTransform: 'none',
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  noBorder: {
    flexGrow: 1,
    textAlign: 'right',
    '& > div': {
      width: '100%',
    },
    '& > *': { border: 'none !important' },
    '& *::before, & *::after': {
      [commonTheme.breakpoints.down('sm')]: {
        borderBottom: 'none',
      },
    },
    '& input': {
      fontSize: 'inherit',
      fontWeight: '700',
      height: 'auto',
      letterSpacing: 0,
      lineHeight: 1.6,
      padding: 0,
      textAlign: 'right',
    },
  },
}));

export default function ITemLayout(props) {
  const { lbl, required, content, fontSize } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid container className={classes.list}>
      <span className={`${baseClasses.title6} ${classes.listLbl}`}>
        {lbl}
        <span>{required}</span>
      </span>
      <span
        className={classes.noBorder}
        style={{
          fontSize: fontSize || '13px',
        }}
      >
        {content}
      </span>
    </Grid>
  );
}
