import { PREVENT_TARGET_KEY_CODE } from '../constants';

/**
 * キーボードイベント抑制
 * @param {*} event
 * @return void
 */
const handleKeyDown = (event) => {
  if (PREVENT_TARGET_KEY_CODE.indexOf(event.keyCode) !== -1) {
    event.preventDefault();
  }
};
export default handleKeyDown;
