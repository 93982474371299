import client from '../httpClient';

const simulatorDownLoadApi = (receptionId) => {
  const responseType = 'arraybuffer';
  return client.get(`/simulator/csv-upload-path/${receptionId}`, {
    responseType,
  });
};

export default simulatorDownLoadApi;
