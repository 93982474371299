import { useState } from 'react';

import ChangeSms from '../../../components/mail/parts/changeSms';

export default function ChangeSmsContainer(props) {
  const { values, user } = props;
  // 最終的にPOSTするパラメーター用
  const [sendParams, setSendParams] = useState({});

  return (
    <ChangeSms
      values={values}
      user={user}
      sendParams={sendParams}
      setSendParams={setSendParams}
    />
  );
}
