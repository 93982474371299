/* eslint-disable max-len */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, makeStyles } from '@material-ui/core';
import DivisionInformationTable from '../../components/divisionInformation/parts/divisionInformationTable';
import getStoreDivisionsApi from '../../apis/divisionMaster/getStoreDivisionsApi';
import putStoreDivisionsApi from '../../apis/divisionMaster/putStoreDivisionsApi';
import { updateStoreDivisions } from '../../store/divisionMaster/storeDivisions';
import commonStyles from '../../components/styles';
import { useLoading } from '../../hooks';
import { PROMOTION, PROMOTION_NOT_MOBILE, SALES_MANAGER, SALES_MANAGER_NOT_MOBILE, SYSTEM } from '../../constants/userRoleGroup';
import { STORE_MOVE_MASTA_ADMIN_RESPONSIBLE_AREA_ONLY } from '../../constants/userRole';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  body: {
    borderTop: '1px #ccc solid',
    borderBottom: '1px #ccc solid',
    padding: 16,
  },
  fixedFooter: {
    justifyContent: 'center',
    paddingTop: 16,
  },
});

const DivisionInformationTableContainer = React.memo((props) => {
  const { selectedAreaCode } = props;
  const common = commonStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleGroupId, roleIds } = responseHeader;

  /**
   * 課編成店舗情報取得 GET `/division-masters/store-division-list?areaCode={areaCode}` のレスポンス
   *
   * @typedef { import(
   *   '../../apis/divisionMaster/getStoreDivisionsApiTypes'
   * ).StoreDivisionsGetResponse } StoreDivisionsGetResponse
   * @type { StoreDivisionsGetResponse }
   */
  const storeDivisions = useSelector((state) => state.storeDivisions);

  /**
   * セルのチェック状態
   *
   * @type { [string[], (value: string[]) => void] }
   */
  const [isCheckedCellState, setIsCheckedCellState] = useState(['']);

  /**
   * 編集権限なしでチェックありのリスト
   * - 編集権限なし isEdit: 0
   * - チェックあり isMove: 1
   *
   * @type { [string[], (value: string[]) => void] }
   */
  const [isCheckedCellAndCanNotEditCellList, setIsCheckedCellAndCanNotEditCellList] = useState(['']);

  /**
   * 「編集権限あり」なセルを判定するためのリスト
   * - リストにデータあり -> 編集権限あり isEdit: 1
   * - リストにデータなし -> 編集権限なし isEdit: 0
   *
   * @type { string[] }
   */
  const canEditCellList = useMemo(() => storeDivisions.data.divisions
    .filter((division) => division.isEdit)
    .map((division) => `${division.fromDivisionId}_${division.toDivisionId}`), [storeDivisions]);

  /**
   * 新店舗(列)と旧店舗(行)のヘッダーを生成
   * 生成されたデータをもとにセルのヘッダー及びテーブルを作成している
   *
   * @type {{ areaCode: number; divisionId: number; divisionSubName: string; }[]}
   */
  const areaListDivisions = useMemo(
    () => storeDivisions.data.divisions
      .filter((division, index) => {
        const duplicatedDivisionIds = storeDivisions.data.divisions.map((tempDivision) => {
          return tempDivision.fromDivisionId;
        });
        return duplicatedDivisionIds.indexOf(division.fromDivisionId) === index;
      }).map((division) => {
        return {
          areaCode: storeDivisions.data.areaCode,
          divisionId: division.fromDivisionId,
          divisionSubName: division.fromDivisionName,
        };
      }),
    [storeDivisions],
  );

  /**
   * 編集権限があるセルかを判定
   * - canEditCellListにデータがある -> 編集権限あり isEdit: 1
   * - canEditCellListにデータがない -> 編集権限あり isEdit: 0
   *
   * @type { (cellId: CellId) => boolean }
   */
  const isDisabledCell = useCallback((cellId) => {
    const cell = `${cellId.rowOldStoreDivisionId}_${cellId.columnNewStoreDivisionId}`;
    return !canEditCellList.includes(cell);
  }, [canEditCellList]);

  /**
   * セルのチェック状態 -> isMove: 0 | 1
   *
   * @typedef {{ rowOldStoreDivisionId: number; columnNewStoreDivisionId: number; }} CellId
   * @type { (cellId: CellId) => boolean }
   */
  const isCheckedCell = useCallback((cellId) => {
    const cell = `${cellId.rowOldStoreDivisionId}_${cellId.columnNewStoreDivisionId}`;
    if (isCheckedCellAndCanNotEditCellList.includes(cell)) {
      return true;
    }
    return isCheckedCellState.includes(cell);
  }, [isCheckedCellState, isCheckedCellAndCanNotEditCellList]);

  /**
   * 単体セルのチェック状態を変更したとき対になるセルのチェック状態も変更する
   * e.g.
   * 新店舗.渋谷:旧店舗.大井町の「チェックあり」に変更 → 新店舗.大井町:旧店舗.渋谷も「チェックあり」に変更される
   *
   * @type { (cellId: CellId, e: E) => void }
   */
  const onChangeCell = useCallback((cellId) => {
    const cell = `${cellId.rowOldStoreDivisionId}_${cellId.columnNewStoreDivisionId}`;
    const pairCell = `${cellId.columnNewStoreDivisionId}_${cellId.rowOldStoreDivisionId}`;

    // 新しい状態を生成してセットする
    setIsCheckedCellState((prevState) => {
      const newCheckedCells = new Set(prevState);

      if (newCheckedCells.has(cell)) {
        newCheckedCells.delete(cell);
        newCheckedCells.delete(pairCell);
      } else {
        newCheckedCells.add(cell);
        newCheckedCells.add(pairCell);
      }

      return Array.from(newCheckedCells);
    });

    // stateの更新
    const updatedDivisions = storeDivisions.data.divisions.map((division) => {
      if (
        (division.fromDivisionId === cellId.rowOldStoreDivisionId
          && division.toDivisionId === cellId.columnNewStoreDivisionId)
        || (division.fromDivisionId === cellId.columnNewStoreDivisionId
          && division.toDivisionId === cellId.rowOldStoreDivisionId)
      ) {
        return {
          ...division,
          isMove: division.isMove ? 0 : 1,
        };
      }
      return division;
    });

    // stateの更新
    const updatedStoreDivisions = {
      ...storeDivisions,
      data: {
        ...storeDivisions.data,
        divisions: updatedDivisions,
      },
    };
    console.log('updatedStoreDivisions', updatedStoreDivisions);
    dispatch(updateStoreDivisions(updatedStoreDivisions));
  }, [isCheckedCellState, storeDivisions]);

  /**
   * チェックした新店舗(列)と旧店舗(行)で編集権限のあるセルのみを抽出
   *
   * @type { (divisionId: number | string) => {
   *   canEditColumnAndRowList: string[];
   *   columnAndRowList: string[];
   *   isCheckedColumnAndRowList: string[];
   * }
   */
  const createCanEditColumnAndRowList = useCallback((divisionId) => {
    const getDivisionCellPair = (division) => {
      const cell = `${division.toDivisionId}_${divisionId}`;
      const pairCell = `${divisionId}_${division.toDivisionId}`;
      return [cell, pairCell];
    };

    const filterDivisionsByFromDivisionId = (divisions, fromDivisionId) => {
      return divisions.reduce((filtered, division) => {
        if (division.fromDivisionId === Number(fromDivisionId)) {
          filtered.push(division);
        }
        return filtered;
      }, []);
    };

    const mapDivisionsToCellPairs = (divisions) => {
      const pairs = [];
      for (const division of divisions) {
        pairs.push(...getDivisionCellPair(division));
      }
      return pairs;
    };

    const storeDivisionsData = storeDivisions.data.divisions;

    /**
     * 対になる列と行の全チェックボックスのリスト
     *
     *  @type { string[] }
     */
    const columnAndRowList = areaListDivisions
      .filter((division) => division.divisionId !== Number(divisionId))
      .flatMap((division) => getDivisionCellPair(division));

    /**
     * 対になる列と行の「編集権限あり」のチェックボックスのリスト
     * - isEdit: 1
     *
     *  @type { string[] }
     */
    const canEditColumnAndRowList = mapDivisionsToCellPairs(
      filterDivisionsByFromDivisionId(storeDivisionsData, divisionId)
        .filter((division) => division.isEdit),
    );

    /**
     * 対になる列と行の「編集権限なし」のチェックボックスのリスト
     * - isEdit: 0
     *
     *  @type { string[] }
     */
    const canNotEditColumnAndRowList = mapDivisionsToCellPairs(
      filterDivisionsByFromDivisionId(storeDivisionsData, divisionId)
        .filter((division) => division.isEdit === 0),
    );

    /**
     * 対になる列と行の「チェックあり」のチェックボックスのリスト
     * - isMove: 1
     *
     *  @type { string[] }
     */
    const isCheckedColumnAndRowList = mapDivisionsToCellPairs(
      filterDivisionsByFromDivisionId(storeDivisionsData, divisionId)
        .filter((division) => division.isMove),
    );

    /**
     * 対になる列と行の「チェックあり」かつ「編集権限あり」のチェックボックスのリスト
     * - isMove: 1
     * - isEdit: 1
     *
     *  @type { string[] }
     */
    const isCheckedAndCanEditColumnAndRowList = mapDivisionsToCellPairs(
      filterDivisionsByFromDivisionId(storeDivisionsData, divisionId)
        .filter((division) => division.isMove && division.isEdit),
    );

    /**
     * 対になる列と行の「チェックあり」かつ「編集権限なし」のチェックボックスのリスト
     * - isMove: 1
     * - isEdit: 0
     *
     *  @type { string[] }
     */
    const isCheckedAndCanNotEditColumnAndRowList = mapDivisionsToCellPairs(
      filterDivisionsByFromDivisionId(storeDivisionsData, divisionId)
        .filter((division) => division.isMove && !division.isEdit),
    );

    /**
     * 対になる列と行の「チェックなし」かつ「編集権限なし」のチェックボックスのリスト
     * - isMove: 0
     * - isEdit: 0
     *
     *  @type { string[] }
     */
    const isNotCheckedAndCanNotEditColumnAndRowList = mapDivisionsToCellPairs(
      filterDivisionsByFromDivisionId(storeDivisionsData, divisionId)
        .filter((division) => !division.isMove && !division.isEdit),
    );

    const isCheckedAllEditableCells = columnAndRowList.length
      === isCheckedAndCanEditColumnAndRowList.length
      + isNotCheckedAndCanNotEditColumnAndRowList.length
      + isCheckedAndCanNotEditColumnAndRowList.length;

    return {
      canEditColumnAndRowList,
      columnAndRowList,
      isCheckedColumnAndRowList,
      isCheckedAndCanNotEditColumnAndRowList,
      canNotEditColumnAndRowList,
      isCheckedAndCanEditColumnAndRowList,
      isNotCheckedAndCanNotEditColumnAndRowList,
      isCheckedAllEditableCells,
    };
  }, [storeDivisions, areaListDivisions]);

  /**
   * 新店舗(列)ヘッダーと旧店舗(行)ヘッダーの編集可能状態
   *
   * @type { (divisionId: number) => boolean }
   */
  const isDisabledColumnAndRow = useCallback((divisionId) => {
    const {
      canNotEditColumnAndRowList,
      canEditColumnAndRowList,
    } = createCanEditColumnAndRowList(divisionId);

    return canEditColumnAndRowList.length === 0 && canNotEditColumnAndRowList.length !== 0
      ? canNotEditColumnAndRowList.every((tmp) => {
        const reg = new RegExp(`_${divisionId}|${divisionId}_`);
        return reg.test(tmp);
      })
      : false;
  }, [createCanEditColumnAndRowList]);
  /**
   * 新店舗(列)ヘッダーと旧店舗(行)ヘッダーのチェック状態
   *
   * @type { (divisionId: number) => boolean }
   */
  const isCheckedColumnAndRow = useCallback((divisionId) => {
    const {
      columnAndRowList,
      canEditColumnAndRowList,
      isCheckedAllEditableCells,
      isCheckedAndCanNotEditColumnAndRowList,
    } = createCanEditColumnAndRowList(divisionId);

    if (canEditColumnAndRowList.length === 0) {
      // 列と行に「編集権限あり」のセルが一つもない場合は常にチェックなし
      return false;
    }
    if (columnAndRowList.length !== canEditColumnAndRowList.length + isCheckedAndCanNotEditColumnAndRowList.length) {
      // 列と行に一つでも「編集権限なし」のセルがある場合常にチェックなし
      return false;
    }
    /**
     * 列と行のセル全てが「編集権限あり」の場合はセルのチェック状態に応じてチェックのありなしを切り替える
     * チェックあり -> 列と行のセル全てチェックありの場合
     * チェックなし -> 列と行のセルに一つでもチェックなしが含まれる場合
     */
    return isCheckedAllEditableCells;
  }, [createCanEditColumnAndRowList]);

  /**
   * ヘッダーセルのチェック状態を変更したときチェックした列もしくは行のセルの状態を一括で変更し
   * 対になる列もしくは行のセルの状態も変更する
   *
   * e.g.
   * 新店舗(列)ヘッダー「渋谷」のチェックボックスを「チェックあり」に変更した時に
   * その列の全てのチェックボックスを「チェックあり」に変更する
   * さらに旧店舗(行)ヘッダー「渋谷」の全てェックボックスも「チェックあり」に変更する
   *
   * ※ 旧店舗(行)ヘッダーのチェック状態を変更した場合も同様の挙動になる
   * ※ 「チェックなし」に変更した場合も同様の挙動をする
   *
   * @typedef { import('react').ChangeEvent<HTMLInputElement> } E
   * @type { (e: E) => void }
   */
  const onChangeColumnAndRow = useCallback((e) => {
    const {
      canEditColumnAndRowList,
      isCheckedAllEditableCells,
    } = createCanEditColumnAndRowList(Number(e.target.value));

    // UIの更新
    if (isCheckedAllEditableCells) { // チェックを外す
      const newIsCheckedCellState = isCheckedCellState.filter((cell) => {
        return !canEditColumnAndRowList.includes(cell);
      });
      setIsCheckedCellState(newIsCheckedCellState);
    } else { // チェックをする
      const newIsCheckedCellState = [
        ...new Set(isCheckedCellState.concat(canEditColumnAndRowList)),
      ];
      setIsCheckedCellState(newIsCheckedCellState);
    }

    // stateの更新
    const newDivisions = storeDivisions.data.divisions.map((division) => {
      const isSameColumn = division.fromDivisionId === Number(e.target.value);
      const isSameRow = division.toDivisionId === Number(e.target.value);
      if (division.isEdit && (isSameColumn || isSameRow)) {
        return isCheckedAllEditableCells ? { ...division, isMove: 0 } : { ...division, isMove: 1 };
      } else {
        return division;
      }
    });
    const newMoveData = {
      ...storeDivisions,
      data: {
        areaCode: storeDivisions.data.areaCode,
        divisions: newDivisions,
      },
    };
    console.log('newMOveData', newMoveData);
    dispatch(updateStoreDivisions(newMoveData));
  }, [isCheckedCellState, createCanEditColumnAndRowList, storeDivisions]);

  /**
   * 「保存」ボタンをクリックした時に以下の更新を行う
   * - 課編成店舗情報更新 PUT /division-masters/store-division-list
   *
   * @type { () => Promise<void> }
   */
  const onClickSave = useCallback(async () => {
    const requestNewDivision = storeDivisions.data.divisions
      .filter((division) => division.isEdit)
      .map((division) => {
        return {
          fromDivisionId: division.fromDivisionId,
          toDivisionId: division.toDivisionId,
          isMove: division.isMove,
        };
      });
    const requestData = {
      areaCode: storeDivisions.data.areaCode,
      divisions: requestNewDivision,
    };
    console.log('requestData', requestData);
    /**
     * 店舗感顧客移動設定の権限がない場合はデータを更新しない
     * divisionsが空の状態でPUTするとバリデーションエラーが発生してしまうため
     */
    if (requestData.divisions.length !== 0) {
      await putStoreDivisionsApi(requestData);
    }
  }, [storeDivisions.data]);

  /**
    * 初期読み込み
    */
  useEffect(async () => {
    addLoading();

    const storeDivisionsGetResponse = await getStoreDivisionsApi(Number(selectedAreaCode));
    dispatch(updateStoreDivisions(storeDivisionsGetResponse));

    const isCheckedCellInitialState = storeDivisionsGetResponse.data.divisions
      .filter((division) => division.isMove)
      .map((division) => `${division.fromDivisionId}_${division.toDivisionId}`);
    setIsCheckedCellState(isCheckedCellInitialState);

    const isCheckedCellAndCanNotEditCellInitialState = storeDivisionsGetResponse.data.divisions
      .filter((division) => division.isEdit === 0 && division.isMove === 1)
      .map((division) => `${division.fromDivisionId}_${division.toDivisionId}`);
    setIsCheckedCellAndCanNotEditCellList(isCheckedCellAndCanNotEditCellInitialState);

    removeLoading();
  }, [selectedAreaCode]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.body}>
        <DivisionInformationTable
          areaListDivisions={areaListDivisions}
          isCheckedCell={isCheckedCell}
          onChangeCell={onChangeCell}
          isCheckedColumnAndRow={isCheckedColumnAndRow}
          onChangeColumnAndRow={onChangeColumnAndRow}
          isDisabledCell={isDisabledCell}
          isDisabledColumnAndRow={isDisabledColumnAndRow}
        />
      </Grid>
      <Grid container className={classes.fixedFooter}>
        <Button
          className={common.buttonsPrimary}
          onClick={onClickSave}
          disabled={
            roleGroupId !== PROMOTION
            && roleGroupId !== SALES_MANAGER_NOT_MOBILE
            && roleGroupId !== PROMOTION_NOT_MOBILE
            && roleGroupId !== SYSTEM
            && !(roleGroupId === SALES_MANAGER && roleIds.includes(STORE_MOVE_MASTA_ADMIN_RESPONSIBLE_AREA_ONLY))
          }
        >
          保存
        </Button>
      </Grid>
    </Grid>
  );
});

export default DivisionInformationTableContainer;
