import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { GUIDANCE_STATUS_CODE } from '../../../../constants';

const useStyles = makeStyles({
  txt: {
    fontFamily: 'Roboto',
    color: '#333',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
});

export default function StatusCode(props) {
  const {
    code,
  } = props;

  const classes = useStyles();

  return (
    <span className={classes.txt}>
      <span>
        {code in GUIDANCE_STATUS_CODE
          ? (GUIDANCE_STATUS_CODE[code])
          : '該当なし'}
      </span>
    </span>
  );
}
