import React from 'react';
import {
  Grid, Button,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import { GetApp } from '@material-ui/icons';
import commonStyles from '../../styles';
import DivisionUpdateRow from './divisionUpdateRow';
import Calendar from '../../common/calendar';
import DivisionUpdateConfirmContainer from '../../../containers/division/division/divisionUpdateCinfirmContainer';
// import DIVISION_UPDATE_CONFIRM from '../../../constants/divisionMaster/divisionUpdateConfilm';
import UploadDialog from '../../combinationSetting/parts/uploadDialog';
import DivisionInformationLayout from '../../divisionInformation/parts/divisionInformationLayout';
import CustomTable from '../../browsingHistory/parts/customTable';
import CommonDialog from '../../common/modal';
import { headCells, defaultLabelDisplayedRows } from './divisionUpdateCommon';
import { DIVISION_MASTER_GROUPS } from '../../../constants/userRoleGroup';
import store from '../../../store';

const useStyles = makeStyles((theme) => ({
  applayAt: {
    backgroundColor: '#FFF',
    border: '1px solid #C8C8C8',
    padding: '13.5px 8px 13.5px 24px',
    '& > div': {
      border: 'none',
      padding: 0,
    },
  },
  csvBtn: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: 7,
    textAlign: 'left',
    marginRight: 8,
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      margin: '-2px 2px 0 0',
    },
    '&.disabled': {
      color: '#DEDEDE',
      pointerEvents: 'none',
    },
  },
  btnSecondary: {
    ...theme.overrides.btn.typeSecondary,
  },
  table: {
    '& .MuiTableContainer-root': {
      height: 'calc(100vh - 260px)',
    },
  },
}));

function DivisionUpdate(props) {
  const classes = useStyles();

  const {
    rerenderKey,
    areaCode,
    onChangeTab,
    userList,
    setting,
    currentPageNumber,
    onPageChange,
    isOpenUploadCsvModal,
    onClickOpenUploadCsvModal,
    onClickCloseUploadCsvModal,
    calendarData,
    isOpenCsvConfirmModal,
    onClickTempUploadCsv,
    onClickLiveUploadedCsv,
    onClickCancelLiveUploadCsv,
    handleClickDownload,
  } = props;

  const baseClasses = commonStyles();

  const { responseHeader } = store.getState();
  const { roleGroupId } = responseHeader;

  const isDisableRole = !DIVISION_MASTER_GROUPS.includes(roleGroupId);

  const handleClick = async (e) => {
    const dataDownloadType = e.currentTarget?.getAttribute?.('data-download-type') ?? 0;
    await handleClickDownload(dataDownloadType);
  };

  const headerButtons = (
    <Grid container spacing={1} justifyContent="space-evenly">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          className={baseClasses.buttonsPrimary}
          onClick={onClickOpenUploadCsvModal}
          disabled={isDisableRole}
        >
          <PublishIcon />アップロード
        </Button>
        <UploadDialog
          open={isOpenUploadCsvModal}
          onClose={onClickCloseUploadCsvModal}
          onSubmit={(file) => {
            onClickTempUploadCsv(file);
            onClickCloseUploadCsvModal();
          }}
        >
          <Box className={classes.applayAt}>
            <Calendar data={calendarData} wideInput />
          </Box>
        </UploadDialog>
        <CommonDialog
          open={isOpenCsvConfirmModal}
          onClose={onClickCancelLiveUploadCsv}
          title="アップロード確認画面"
          maxWidth={1176}
          buttons={[
            {
              label: 'キャンセル',
              onClick: onClickCancelLiveUploadCsv,
              type: 'secondary',
            },
            {
              label: '確定',
              onClick: onClickLiveUploadedCsv,
            },
          ]}
        >
          <DivisionUpdateConfirmContainer
            areaCode={areaCode}
            setting={setting}
          />
        </CommonDialog>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          className={baseClasses.buttonsSecondary}
          onClick={handleClick}
          disabled={isDisableRole}
          data-download-type={0}
        >
          <GetApp />ダウンロード:全社員
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          className={baseClasses.buttonsSecondary}
          onClick={handleClick}
          disabled={isDisableRole}
          data-download-type={1}
        >
          <GetApp />ダウンロード:一括更新社員
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <DivisionInformationLayout
      title="組織情報一括更新"
      setting={setting.data}
      headerExtend={headerButtons}
      selectedAreaCode={areaCode}
      onChangeAreaTab={onChangeTab}
    >
      <CustomTable
        key={rerenderKey}
        headCells={headCells}
        list={userList.users}
        total={userList.total}
        propLimit={20}
        rowsPerPageOptions={[]}
        style={{ maxWidth: 1375 }}
        onPageChange={onPageChange}
        labelDisplayedRows={defaultLabelDisplayedRows}
        isNoSlice
        className={classes.table}
      >
        <DivisionUpdateRow currentPageNumber={currentPageNumber} />
      </CustomTable>
    </DivisionInformationLayout>
  );
}

export default DivisionUpdate;
