import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const userGetShareListSlice = createSlice({
  name: 'userGetShareList',
  initialState: { userGetShareList: initialState },
  reducers: {
    userGetShareListApi: (state, data) => {
      state.userGetShareList = data.payload;
    },
  },
});

export const { userGetShareListApi } = userGetShareListSlice.actions;

export default userGetShareListSlice.reducer;
