import { useDispatch } from 'react-redux';
import ChanceNotificationComponent from '../../components/chanceNotification/chanceNotificationComponent';

// チャンス通知履歴 listNotification001
import membersGetNotificationsApi from '../../apis/members/membersGetNotificationsApi';
import { setMembersGetNotifications, resetMembersGetNotifications } from '../../store/members/membersGetNotificationsSlice';
import { useLoading } from '../../hooks';
import { GET_CHANCE_LIST } from '../../constants/loading';

export default function ChanceNotificationContainer() {
  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  // get チャンス通知履歴 listNotification001
  const getChanceNotificationsFunction = async (params) => {
    addLoading(GET_CHANCE_LIST);
    const path = () => {
      let memberId = false;
      const offset = `offset=${params.offset}`;
      const limit = `limit=${params.limit}`;
      if (params.memberId) {
        memberId = `memberId=${params.memberId}`;
        return `?${memberId}&${offset}&${limit}`;
      }
      return `?${offset}&${limit}`;
    };
    await membersGetNotificationsApi(path())
      .then(async (res) => {
        await dispatch(
          params.offset === 0 ? resetMembersGetNotifications(res) : setMembersGetNotifications(res),
        );
        removeLoading(GET_CHANCE_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const now = new Date();
  const time = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
    hour: now.getHours(),
    minute: now.getMinutes(),
  };
  return (
    <ChanceNotificationComponent
      time={time}
      getChanceNotificationsFunction={getChanceNotificationsFunction}
    />
  );
}
