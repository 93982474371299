import React, { useCallback } from 'react';
import LoanTableRow from './loanTableRow';
import CustomTable from './customTable';
import { MEMBERS_REDUCER_KEYS } from '../../../containers/browsingHistory/browsingHistoryReducer';

const headCells = [
  { id: 'createdAt', label: '日時' },
  { id: 'loanCode', label: '種類', alignCenter: true },
  { id: 'productPrice', label: <span>物件価格<br />（万円）</span>, alignCenter: true },
  { id: 'totalIncome', label: <span>ご主人+奥様<br />（万円）</span>, alignCenter: true },
  { id: 'totalDeposit', label: <span>自己資金+<br />援助（万円）</span>, alignCenter: true },
  { id: 'bonusPayment', label: <span>ボーナス<br />(年２回/万円)</span>, alignCenter: true },
  { id: 'totalOtherLoan', label: <span>他の借入<br />(万円/毎月)</span>, alignCenter: true },
  { id: 'interestRate', label: <span>金利<br />(%)</span>, alignCenter: true },
  { id: 'paymentTerm', label: <span>返済期間<br />(年)</span>, alignCenter: true },
  { id: 'monthlyPayment', label: <span>月々の<br />返済額(円)</span>, alignCenter: true },
  { id: 'possibleAmount', label: <span>購入可能額<br />の目安(万円)</span>, alignCenter: true },
  { id: 'paymentRate', label: <span>返済比率<br />(%)</span>, alignCenter: true },
];

export default function LoanTable(props) {
  const {
    data,
    getApiDataFromListApi,
    isSP,
    handleOpenModal = null,
  } = props;
  const loanTablelimit = localStorage.getItem('loanTablelimit') ? localStorage.getItem('loanTablelimit') : 20;
  const setLoanTablelimit = useCallback((current) => {
    localStorage.setItem('loanTablelimit', current);
  }, []);
  return (
    <CustomTable
      hideHeader={isSP}
      title="ローンシミュレーション"
      headCells={headCells}
      total={data.total}
      list={data.loans}
      rowRender={(list) => list.map((row, i) => (<LoanTableRow
        key={String(i)}
        row={row}
      />))}
      onPageChange={(pageInfo) => {
        getApiDataFromListApi(MEMBERS_REDUCER_KEYS.loan, pageInfo);
      }}
      divider
      sortable
      contentHeight={(isSP || handleOpenModal === null) ? null : 150}
      propLimit={loanTablelimit}
      setPropLimit={setLoanTablelimit}
      isNoSlice
      handleOpenModal={handleOpenModal}
    />
  );
}
