export const RESPONSE_TYPE_TH = ['ID', '応答タイプ', 'レベル', '営業フラグ', 'ストアオーダー', '親'];

export const RESPONSE_TYPE_ID = [
  'responseTypeId',
  'responseTypeName',
  'responseTypeLevelCode',
  'isAllowSales',
  'displayOrder',
  'responseTypeParentId',
];
