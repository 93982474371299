// カンマ区切りの文字列をリスト型に変換
const becomeList = (before, splitKey = ',', excludes = ['[', ']']) => {
  let removedStr = before;
  excludes.forEach((exclude) => {
    removedStr = removedStr?.replace(exclude, '');
  });
  const res = removedStr?.split(splitKey);
  return res?.filter((i) => {
    return i !== '';
  }).map(Number);
};

export default becomeList;
