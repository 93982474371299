import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import commonStyles from '../../styles';

import { classNames } from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: 16,
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },
  elementLeft: {
    width: 450,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    padding: 8,
    boxSizing: 'border-box',
    position: 'relative',
    '& input': {
      padding: '6px 0',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '160%',
    },
  },
  elementRight: {
    width: 450,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    padding: 8,
    '& input': {
      padding: 0,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '160%',
    },
  },
  label: {
    width: 130,
    marginRight: 30,
    flex: '0 0 130px',
    '& + *': {
      flex: 1,
    },
  },
  errorLabel: {
    width: 130,
    marginRight: 30,
    color: '#D83420',
  },
  reqired: {
    color: '#D83420',
    marginRight: 'auto',
  },
  arrow: { color: theme.palette.primaryColor },
  input: {
    opacity: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
    cursor: 'pointer',
  },
  modalBtn: {
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  selectBox: { padding: 0 },
  disabled: {
    backgroundColor: '#F3F3F3',
    pointerEvents: 'none',
  },
  blankOption: {
    color: 'transparent',
    height: '2em',
  },
}));

export default function DataElementLayout(props) {
  const {
    label,
    required,
    parentInput,
    childInput,
    isArrow,
    handleArrowClick,
    isModal,
    isDisabled,
    isRequiredError,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.inner}>
      <Typography
        className={classNames(
          baseClasses.title6,
          classes.elementLeft,
          isModal ? classes.modalBtn : null,
          isDisabled ? classes.disabled : null,
        )}
      >
        <span
          className={`${baseClasses.title4} ${
            isRequiredError ? classes.errorLabel : classes.label
          }`}
        >
          {label}
          {required ? <span style={{ color: '#D83420' }}>＊</span> : ''}
        </span>
        {parentInput}
      </Typography>
      {isArrow ? (
        <Button onClick={handleArrowClick}>
          <ArrowBackIcon className={classes.arrow} />
        </Button>
      ) : null}
      <Typography
        className={classNames(
          baseClasses.title6,
          classes.elementRight,
          isArrow ? null : classes.disabled,
        )}
      >
        {childInput}
      </Typography>
    </Grid>
  );
}
