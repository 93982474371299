import React from 'react';
import { CombinationSettingProvider } from '../../containers/combinationSetting';
import CombinationSetting from '../combinationSetting';

export default function CombinationSettingPage() {
  return (
    <CombinationSettingProvider>
      <CombinationSetting />
    </CombinationSettingProvider>
  );
}
