import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const divisionsUpdateSlice = createSlice({
  name: 'divisionsUpdate',
  initialState: { divisionsUpdate: initialState },
  reducers: {
    divisionsUpdateApi: (state, data) => {
      state.divisionsUpdate = data.payload;
    },
  },
});

export const { divisionsUpdateApi } = divisionsUpdateSlice.actions;

export default divisionsUpdateSlice.reducer;
