import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableCell,
  TableRow,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import Icon from '@material-ui/core/Icon';
import NormalTooltip from '../../../styles/tooltip';
import InstructionsWindow from '../../../common/taiouRireki/instructionsWindow';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    // '&:not(:last-child)': { borderBottom: '1px solid #8C8C8C' },
    // '&.lastRow': { borderBottom: '1px solid #8C8C8C' },
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .prospectsListBtn': {
        opacity: 1,
      },
    },
  },
  tableTd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
    textAlign: 'center',
    boxSizing: 'border-box',
    // borderBottom: 'none',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #8C8C8C',
    width: '5%',
    '.lastRow > &:not(:first-child)': {
      borderBottom: 'none',
    },
    '& span': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&:not(:last-child):not(:first-child)': {
      borderRight: '1px solid #8C8C8C',
    },
    '&:nth-child(1)': {
      width: '100%',
    },
    '&.userLastName': {
      width: '5%',
    },
    '&.largeResponseTypeName, &.effectAt, &.lastGuideAt, &.nextGuideAt': {
      width: '5%',
    },
    '&.isShare, &.customerId': {
      width: '4.6%',
    },
    '&.lastName': {
      background: 'rgba(252, 224, 52, 0.24)',
      width: '5.2%',
    },
    '&.address': {
      width: '11.5%',
    },
    '&.company': {
      width: '7.5%',
    },
    '&.detachedHouseBudgetMin, &.annualIncome, &.deposit, &.abodeNowRent': {
      width: '4.25%',
    },
    '&.wishArea': {
      width: '10.3%',
    },
    '&.totalGuidanceCount, &.contactTime': {
      width: '3.3%',
      background: 'rgba(252, 224, 52, 0.24)',
    },
    '&.summary': {
      width: '7.5%',
    },
  },
  btnWrap: {
    opacity: 0,
    transition: 'all 0.5s ease',
    position: 'absolute',
    zIndex: 1,
    bottom: 12,
    right: 8,
    display: 'inherit!important',
    '& span': {
      display: 'inherit!important',
    },
  },
  btn1: {
    color: theme.palette.primaryColor,
    background: '#fff',
    minWidth: 'auto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    borderRadius: '4px',
    padding: '4px 4px 4px 7px',
    marginRight: 4,
    '& .material-icons': {
      fontSize: 20,
      marginRight: 3,
    },
    '&:hover': {
      background: '#fff',
    },
  },
  btn2: {
    color: theme.palette.primaryColor,
    background: '#fff',
    minWidth: 'auto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    borderRadius: '4px',
    padding: '2px 4px 2px 7px',
    '& .material-icons': {
      fontSize: 14,
      marginRight: 3,
    },
    '&:hover': {
      background: '#fff',
    },
  },
  userLevel: {
    display: 'block',
    position: 'relative',
    width: '100%',
    color: '#8C8C8C!important',
    fontWeight: 700,
    border: 'none',
    background: '#fff',
    padding: '0!important',
    borderBottom: '2px solid #8C8C8C',
    textAlign: 'left',
    '&::before': {
      content: '""',
      display: 'block',
      width: 5,
      height: 5,
      borderTop: `2px solid ${theme.palette.primaryColor}`,
      borderRight: `2px solid ${theme.palette.primaryColor}`,
      transform: 'rotate(-45deg)',
      position: 'absolute',
      top: 20,
      left: 14,
    },
    '&.close::before': {
      transform: 'rotate(135deg)',
      top: 17,
    },
  },
  userLevelBox: {
    padding: '3px 8px 3px 26px',
  },
  userLevelBox1: {
    background: 'rgba(216, 52, 32, 0.08)',
  },
  userLevelBox2: {
    background: 'rgba(39, 174, 96, 0.08)',
  },
  userLevelBox3: {
    background: '#F3F3F3',
  },
  userLevelTxt: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  userLevelTxt1: {
    color: '#D83420',
  },
  userLevelTxt2: {
    color: '#27AE60',
  },
  userLevelTxt3: {
    color: '#333',
  },
}));

export default function EnhancedTable(props) {
  const {
    row,
    labelId,
    rowClose,
    localData,
    setLocalData,
    lastRowFlg,
  } = props;

  const classes = useStyles();

  const getUserLevel = (code) => {
    switch (code) {
      case 5:
        return (
          <Grid className={`${classes.userLevelBox} ${classes.userLevelBox1}`}>
            <Typography className={`${classes.userLevelTxt} ${classes.userLevelTxt1}`}>
              ★5
            </Typography>
          </Grid>
        );
      case 4:
        return (
          <Grid className={`${classes.userLevelBox} ${classes.userLevelBox2}`}>
            <Typography className={`${classes.userLevelTxt} ${classes.userLevelTxt2}`}>
              ★4
            </Typography>
          </Grid>
        );
      case 3:
        return (
          <Grid className={`${classes.userLevelBox} ${classes.userLevelBox3}`}>
            <Typography className={`${classes.userLevelTxt} ${classes.userLevelTxt3}`}>
              ★3
            </Typography>
          </Grid>
        );
      case 2:
        return (
          <Grid className={`${classes.userLevelBox} ${classes.userLevelBox3}`}>
            <Typography className={`${classes.userLevelTxt} ${classes.userLevelTxt3}`}>
              ★2
            </Typography>
          </Grid>
        );
      case 1:
        return (
          <Grid className={`${classes.userLevelBox} ${classes.userLevelBox3}`}>
            <Typography className={`${classes.userLevelTxt} ${classes.userLevelTxt3}`}>
              ★1
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid className={`${classes.userLevelBox} ${classes.userLevelBox3}`}>
            <Typography className={`${classes.userLevelTxt} ${classes.userLevelTxt3}`}>
              ★なし
            </Typography>
          </Grid>
        );
    }
  };

  const [closeFlg, setCloseFlg] = useState(false);
  const clickClose = () => {
    setCloseFlg(!closeFlg);
  };

  // 指示登録
  const [instOpen, setInstOpen] = useState(false);
  const handleInstOpen = () => {
    setInstOpen(true);
  };
  const closeInst = () => {
    setInstOpen(false);
  };

  // 分をhh:mmに変換
  const formatContactTime = (m) => {
    if (m === null) {
      return m;
    }
    const hh = Math.floor(m / 60).toString().padStart(2, '0');
    const mm = (m % 60).toString().padStart(2, '0');
    return `${hh}:${mm}`;
  };

  // 3桁カンマ区切り
  const comma = (num) => {
    return num ? String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : num;
  };

  const getUserName = (userLastName, userFirstName) => {
    const result = [userLastName, userFirstName].filter(Boolean).join(' ');
    return result || '担当なし';
  };

  const getUserLevelValue = (level) => {
    return level ?? 0;
  };

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={`${classes.tableRow} ${lastRowFlg ? 'lastRow' : ''}`}
    >
      <TableCell
        id={labelId}
        className={`${classes.tableTd} ${classes.userLevel} userLevel ${closeFlg ? 'close' : ''}`}
        onClick={() => {
          clickClose();
          rowClose(getUserLevelValue(row.userLevel), closeFlg);
        }}
      >
        {getUserLevel(getUserLevelValue(row.userLevel))}
      </TableCell>
      <TableCell className={`${classes.tableTd} userLastName userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{ getUserName(row.userLastName, row.userFirstName) }</span>
        <span className={`${classes.btnWrap} prospectsListBtn`}>
          <Button
            className={classes.btn1}
            onClick={() => {
              return setLocalData({
                ...localData,
                customerId: row.customerId,
                customer: true,
              });
            }}
          >
            <Icon className="material-icons-outlined">summarize</Icon>顧客情報
          </Button>
          <Button
            className={classes.btn2}
            onClick={handleInstOpen}
          >
            <TouchAppOutlinedIcon />指示
          </Button>
          <InstructionsWindow
            open={instOpen}
            setOpen={setInstOpen}
            onClose={closeInst}
            directId={row.userId}
            newInstructionFlg
            customerId={row.customerId}
            divisionId={row.divisionId}
            userId={row.userId}
            userFirstName={row.userFirstName}
            userLastName={row.userLastName}
          />
        </span>
      </TableCell>
      <NormalTooltip title={row.largeResponseTypeName || ''}>
        <TableCell className={`${classes.tableTd} largeResponseTypeName userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>{row.largeResponseTypeName}</span>
        </TableCell>
      </NormalTooltip>
      <TableCell className={`${classes.tableTd} effectAt userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{row.effectAt}</span>
      </TableCell>
      <TableCell className={`${classes.tableTd} isShare userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{row.isShare === 1 ? '共有中' : '-'}</span>
      </TableCell>
      <NormalTooltip title={row.customerId}>
        <TableCell className={`${classes.tableTd} customerId userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>{row.customerId}</span>
        </TableCell>
      </NormalTooltip>
      <NormalTooltip title={`${row.lastName || ''} ${row.firstName || ''}`}>
        <TableCell className={`${classes.tableTd} lastName userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>{row.lastName} {row.firstName}</span>
        </TableCell>
      </NormalTooltip>
      <NormalTooltip title={row.address || ''}>
        <TableCell className={`${classes.tableTd} address userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>{row.address}</span>
        </TableCell>
      </NormalTooltip>
      <NormalTooltip title={row.company || ''}>
        <TableCell className={`${classes.tableTd} company userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>{row.company}</span>
        </TableCell>
      </NormalTooltip>
      <TableCell className={`${classes.tableTd} detachedHouseBudgetMin userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>
          {comma(row.detachedHouseBudgetMin)}〜<br />
          {comma(row.detachedHouseBudgetMax)}
        </span>
      </TableCell>
      <TableCell className={`${classes.tableTd} annualIncome userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{comma(row.annualIncome)}</span>
      </TableCell>
      <TableCell className={`${classes.tableTd} deposit userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{comma(row.deposit)}</span>
      </TableCell>
      <TableCell className={`${classes.tableTd} abodeNowRent userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{comma(row.abodeNowRent)}</span>
      </TableCell>
      <NormalTooltip title={row.wishArea || ''}>
        <TableCell className={`${classes.tableTd} wishArea userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>{row.wishArea}</span>
        </TableCell>
      </NormalTooltip>
      <TableCell className={`${classes.tableTd} totalGuidanceCount userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{row.totalGuidanceCount ?? 0}</span>
      </TableCell>
      <TableCell className={`${classes.tableTd} contactTime userLevel-${getUserLevelValue(row.userLevel)}`}>
        <span>{formatContactTime(row.contactTime)}</span>
      </TableCell>
      <NormalTooltip title={row.summary || ''}>
        <TableCell className={`${classes.tableTd} summary userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>{row.summary}</span>
        </TableCell>
      </NormalTooltip>
      <NormalTooltip title={row.lastGuideMemo || ''}>
        <TableCell className={`${classes.tableTd} lastGuideAt userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>
            {row.lastGuideAt}<br />
            {row.lastGuideMemo}
          </span>
        </TableCell>
      </NormalTooltip>
      <NormalTooltip title={row.nextGuideMemo || ''}>
        <TableCell className={`${classes.tableTd} nextGuideAt userLevel-${getUserLevelValue(row.userLevel)}`}>
          <span>
            {row.nextGuideAt}<br />
            {row.nextGuideMemo}
          </span>
        </TableCell>
      </NormalTooltip>
    </TableRow>
  );
}
