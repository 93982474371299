import { useState } from 'react';
import { useDispatch } from 'react-redux';
import filestorageGetApi from '../../apis/filestorage/filestorageGetApi';
import filestorageResisterApi from '../../apis/filestorage/filestorageResisterApi';
import filestorageUpdateApi from '../../apis/filestorage/filestorageUpdateApi';
import filestorageDownloadApi from '../../apis/filestorage/filestorageDownloadApi';
import { getFilestrageItems } from '../../store/filestorage/filestorageGetSlice';

import FileStrageComponent from '../../components/fileStrage/fileStrageComponent';

import { downloadFileFromSignedUrl } from '../../commonFunction';

import { validateFormDataSize } from '../../commonFunction/validations';
import { isOpen } from '../../store/common/apiMessageSlice';

const initialLimitOffset = { limit: 10, offset: 0, keyword: '' };
export default function FileStrageContainer() {
  const dispatch = useDispatch();

  const [limitOffset, setLimitOffset] = useState(initialLimitOffset);

  // GET
  const getFileList = ({ offset, limit, keyword } = limitOffset) => {
    filestorageGetApi(offset, limit, keyword)
      .then((res) => {
        const { total, fileStorages } = res.data;
        const sortedData = fileStorages.sort((a, b) => {
          const isGt = new Date(a.createdAt) > new Date(b.createdAt);
          if (isGt) return -1;
          return 1;
        });
        dispatch(getFilestrageItems({ total, fileStorages: sortedData }));
      })
      .catch((err) => console.error(err));
  };

  // POST
  /**
   * @module registerFiles - ファイルアップロード
   * @param {array} files - 必須 - [FileData, FileData...etc]
   * @param {date} limitAt - YY/mm/dd 空の場合api送信時に自動で7日後に設定
   * @param {boolean} isNotification - true or false 空の場合false
   */
  const registerFiles = (files, limitAt, isNotification) => {
    // Content-Type: multipart/form-dataの為、全てのパラメータをFormData型に変更
    const data = new FormData();
    for (let i = 0; files.length > i; i += 1) {
      data.append('files[]', files[i]);
    }
    if (limitAt === undefined) {
      limitAt = '1970/01/01';
    }
    data.append('limitAt', limitAt);
    data.append('isNotification', isNotification);

    // サイズチェック
    const error = validateFormDataSize(data);
    if (error) {
      dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      return;
    }

    filestorageResisterApi(data)
      .then(() => getFileList(limitOffset))
      .catch((err) => console.error(err));
  };

  // PUT
  const updateFiles = (paramObj) => {
    filestorageUpdateApi(paramObj)
      .then(() => getFileList(limitOffset))
      .catch((err) => console.error(err));
  };

  // POST - download
  const downloadFiles = (param, viewFlg = false) => {
    const { uuid, directoryName } = param;
    const tmp = directoryName.match(/([^/]*)[^/]+$/);
    filestorageDownloadApi({ uuid })
      .then((res) => {
        const url = res.data;
        if (viewFlg) {
          window.open(url);
        } else {
          // NOTE: ローカルだとCORSエラーが出る
          downloadFileFromSignedUrl(url, tmp ? tmp[0] : directoryName);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <FileStrageComponent
      getFileList={getFileList}
      registerFiles={registerFiles}
      updateFiles={updateFiles}
      downloadFiles={downloadFiles}
      limitOffset={limitOffset}
      setLimitOffset={setLimitOffset}
    />
  );
}
