import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, List, ListItem, Select, MenuItem } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import {
  howToWorkSelect,
} from '../../../../constants/customerMain';
import SelectRailwaysAndStationsContainer from '../../../../containers/search/railwayAndStations/selectRailwayAndStations';
import NormalTooltip from '../../../styles/tooltip';
import inputNumber from '../../../../commonFunction/inputNumber';
import { TextBaseField } from '../../../eleCommon/validation';
import { convertForm1, convertFormNumber } from '../../../../commonFunction/convertors';
import { validateFormString1, validateFormInt1 } from '../../../../commonFunction/validations';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  workPlace: {
    [commonTheme.breakpoints.up('md')]: {
      background: '#fff',
      border: '1px solid #C8C8C8',
      padding: 8,
      marginTop: 8,
    },
  },
  workPlaceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  workPlace1: {
    width: 280,
  },
  workPlace2: {
    width: 60,
  },
  workPlace3: {
    width: 280,
  },
  workPlace4: {
    width: 120,
  },
  workPlace5: {
    width: 279,
  },
  workPlace4Button: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 250,
  },
  textField: {
    display: 'block',
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  button: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    minWidth: 'auto',
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minWidth: 42,
      minHeight: 21,
    },
    '&:hover': {
      background: '#fff',
    },
  },
  buttonTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 4px',
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&:nth-child(5n)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function CustomerDetailWorkPlace(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  const menus = Object.keys(howToWorkSelect.menus);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // フォーカスが外れた時
  const onBlur = (e) => {
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };
  // SP 項目選択時に更新
  // 項目選択時に更新 SP
  const onSelect1 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, workingStyleCode: val });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, workingStyleCode: val });
  };
  const onSelect2 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, subWorkingStyleCode: val });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, subWorkingStyleCode: val });
  };

  // 働き方 PC
  const selectChange1 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, workingStyleCode: e.target.value });
  };
  const selectChange2 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, subWorkingStyleCode: e.target.value });
  };
  const selectBlur1 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, workingStyleCode: e.target.value });
  };
  const selectBlur2 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, subWorkingStyleCode: e.target.value });
  };
  const customerSelectList = {
    workingStyleCodeItem1: {
      type: 'select',
      obj: howToWorkSelect,
      state: input.detail.workingStyleCode,
      set: selectBlur1,
      change: selectChange1,
    },
    workingStyleCodeItem2: {
      type: 'select',
      obj: howToWorkSelect,
      state: input.detail.subWorkingStyleCode,
      set: selectBlur2,
      change: selectChange2,
    },
  };

  // 沿線開閉用のローカルステート
  const [openRailway, setOpenRailway] = useState(false);

  // 沿線パラメーターを設定
  const [initialParamRailway, setInitialParamRailway] = useState({});

  // 沿線を開く
  const handleOpenRailway = (wishID) => {
    // 沿線の第1希望, 第2希望のパラメータをセット
    setInitialParamRailway({
      id: wishID,
      showOnlyFromStation: true,
      wishRailwayAreaCodeId: input.detail[wishID === 1 ? 'companyRailwayAreaCodeId' : 'subCompanyRailwayAreaCodeId'],
      wishRailwayId: input.detail[wishID === 1 ? 'companyRailwayId' : 'subCompanyRailwayId'],
      wishRailway: input.detail[wishID === 1 ? 'companyRailway' : 'subCompanyRailway'],
      wishStationIdFrom: input.detail[wishID === 1 ? 'companyStationId' : 'subCompanyStationId'],
      wishStationFrom: input.detail[wishID === 1 ? 'companyStation' : 'subCompanyStation'],
    });
    setOpenRailway(true);
  };

  // 沿線を閉じる
  const handleCloseRailway = () => {
    setOpenRailway(false);
  };

  // 沿線を更新
  const handleUpdateRailway = (data) => {
    console.log('handleUpdateRailway', data);
    // 変更した値をセット
    input.setDetail(
      {
        ...input.detail,
        [data.id === 1 ? 'companyRailwayAreaCodeId' : 'subCompanyRailwayAreaCodeId']: data.areaCode,
        [data.id === 1 ? 'companyRailwayId' : 'subCompanyRailwayId']: data.railway.railwayId,
        [data.id === 1 ? 'companyRailway' : 'subCompanyRailway']: data.railway.railwayName,
        [data.id === 1 ? 'companyStationId' : 'subCompanyStationId']: data.fromStation.stationId,
        [data.id === 1 ? 'companyStation' : 'subCompanyStation']: data.fromStation.name,
      },
    );
    // 更新用の値をセット
    input.dataInput.setData(
      {
        ...input.dataInput.data,
        [data.id === 1 ? 'companyRailwayAreaCodeId' : 'subCompanyRailwayAreaCodeId']: data.areaCode,
        [data.id === 1 ? 'companyRailwayId' : 'subCompanyRailwayId']: data.railway.railwayId,
        [data.id === 1 ? 'companyRailway' : 'subCompanyRailway']: data.railway.railwayName,
        [data.id === 1 ? 'companyStationId' : 'subCompanyStationId']: data.fromStation.stationId,
        [data.id === 1 ? 'companyStation' : 'subCompanyStation']: data.fromStation.name,
      },
    );
  };

  const getBox1 = () => {
    return (
      <TextBaseField
        label="勤務先1"
        defaultValue={input.detail.company}
        className={classes.textField}
        name="company"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 80 })}
        errorCallback={input.errorCallback('company')}
      />
    );
  };

  const getBox2 = () => {
    return (
      <TextBaseField
        label="年数"
        defaultValue={input.detail.companyWorkingYear}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="companyWorkingYear"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('companyWorkingYear')}
      />
    );
  };

  const getBox3 = () => {
    return (
      <Select defaultValue={99}>
        {/* <MenuItem disabled value={99}>未選択</MenuItem> */}
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect1(data);
              }}
            >
              {howToWorkSelect.menus[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getBox5 = () => {
    return (
      <TextBaseField
        label="勤務先住所1"
        defaultValue={input.detail.companyAddress}
        className={classes.textField}
        name="companyAddress"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('companyAddress')}
      />
    );
  };

  const getBox6 = () => {
    return (
      <TextBaseField
        label="勤務先2"
        defaultValue={input.detail.subCompany}
        className={classes.textField}
        name="subCompany"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('subCompany')}
      />
    );
  };

  const getBox7 = () => {
    return (
      <TextBaseField
        label="年数"
        defaultValue={input.detail.subCompanyWorkingYear}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="subCompanyWorkingYear"
        onBlur={onBlur}
        InputLabelProps={{ 'data-shrink': true }}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('subCompanyWorkingYear')}
      />
    );
  };

  const getBox8 = () => {
    return (
      <Select defaultValue={99}>
        {/* <MenuItem disabled value={99}>未選択</MenuItem> */}
        {menus.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect2(data);
              }}
            >
              {howToWorkSelect.menus[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getBox10 = () => {
    return (
      <TextBaseField
        label="勤務先住所2"
        defaultValue={input.detail.subCompanyAddress}
        className={classes.textField}
        name="subCompanyAddress"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('subCompanyAddress')}
      />
    );
  };

  const list1 = [
    {
      type1: 'noModal',
      type2: 'text',
      id: 1,
      lbl: '勤務先 1',
      val: input.detail.company,
      content: getBox1,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 2,
      lbl: '年数',
      val: input.detail.companyWorkingYear,
      content: getBox2,
    },
    {
      type1: 'noModal',
      type2: 'select',
      id: 3,
      lbl: '働き方',
      val: howToWorkSelect.menus[input.detail.workingStyleCode],
      content: getBox3,
    },
  ];
  const list2 = [
    {
      type1: 'button',
      type2: 'text',
      id: 4,
      lbl: '沿線／最寄り駅',
      val: (input.detail.companyRailway ? input.detail.companyRailway : '') + ' / ' + (input.detail.companyStation ? input.detail.companyStation : ''),
      click: () => {
        handleOpenRailway(1);
      },
    },
  ];
  const list3 = [
    {
      type1: 'noModal',
      type2: 'text',
      id: 5,
      lbl: '勤務先住所1',
      val: input.detail.companyAddress,
      content: getBox5,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 6,
      lbl: '勤務先 2',
      val: input.detail.subCompany,
      content: getBox6,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 7,
      lbl: '年数',
      val: input.detail.subCompanyWorkingYear,
      content: getBox7,
    },
    {
      type1: 'noModal',
      type2: 'select',
      id: 8,
      lbl: '働き方',
      val: howToWorkSelect.menus[input.detail.subWorkingStyleCode],
      content: getBox8,
    },
  ];
  const list4 = [
    {
      type1: 'button',
      type2: 'text',
      id: 9,
      lbl: '沿線／最寄り駅',
      val: (input.detail.subCompanyRailway ? input.detail.subCompanyRailway : '') + ' / ' + (input.detail.subCompanyStation ? input.detail.subCompanyStation : ''),
      click: () => {
        handleOpenRailway(2);
      },
    },
  ];
  const list5 = [
    {
      type1: 'noModal',
      type2: 'text',
      id: 10,
      lbl: '勤務先住所2',
      val: input.detail.subCompanyAddress,
      content: getBox10,
    },
  ];

  const getWorkPlace = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid className={classes.workPlace}>
            <Grid className={classes.workPlaceBox}>
              <Typography className={baseClasses.title6}>
                勤務先
              </Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid className={classes.workPlace}>
            <Grid className={classes.workPlaceBox}>
              <Grid className={classes.workPlace1}>
                {getBox1()}
              </Grid>
              <Grid className={classes.workPlace2}>
                {getBox2()}
              </Grid>
              <Grid className={classes.workPlace3}>
                <Typography className={baseClasses.title6}>
                  沿線／最寄り駅
                </Typography>
                <NormalTooltip title={`${input.detail.companyRailway ? input.detail.companyRailway : ''} / ${input.detail.companyStation ? input.detail.companyStation : ''}`}>
                  <Grid className={classes.workPlace4Button}>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(1);
                      }}
                    >
                      {input.detail.companyRailway}
                    </Button>
                    <Typography className={classes.buttonTxt}>
                      /
                    </Typography>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(1);
                      }}
                    >
                      {input.detail.companyStation}
                    </Button>
                  </Grid>
                </NormalTooltip>
              </Grid>
              <Grid className={classes.workPlace4}>
                <Typography className={baseClasses.title6}>
                  働き方
                </Typography>
                <Grid>
                  {input.dataInput.component(customerSelectList.workingStyleCodeItem1)}
                </Grid>
              </Grid>
              <Grid className={classes.workPlace5}>
                {getBox5()}
              </Grid>
            </Grid>
            <Grid className={classes.workPlaceBox}>
              <Grid className={classes.workPlace1}>
                {getBox6()}
              </Grid>
              <Grid className={classes.workPlace2}>
                {getBox7()}
              </Grid>
              <Grid className={classes.workPlace3}>
                <Typography className={baseClasses.title6}>
                  沿線／最寄り駅
                </Typography>
                <NormalTooltip title={`${input.detail.subCompanyRailway ? input.detail.subCompanyRailway : ''} / ${input.detail.subCompanyStation ? input.detail.subCompanyStation : ''}`}>
                  <Grid className={classes.workPlace4Button}>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(2);
                      }}
                    >
                      {input.detail.subCompanyRailway}
                    </Button>
                    <Typography className={classes.buttonTxt}>
                      /
                    </Typography>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleOpenRailway(2);
                      }}
                    >
                      {input.detail.subCompanyStation}
                    </Button>
                  </Grid>
                </NormalTooltip>
              </Grid>
              <Grid className={classes.workPlace4}>
                <Typography className={baseClasses.title6}>
                  働き方
                </Typography>
                <Grid>
                  {input.dataInput.component(customerSelectList.workingStyleCodeItem2)}
                </Grid>
              </Grid>
              <Grid className={classes.workPlace5}>
                {getBox10()}
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  const getStatusPc = () => {
    return (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          勤務先情報
        </Typography>
        {getWorkPlace()}
      </Grid>
    );
  };

  const getStatusSp = () => {
    switch (input.detail.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Grid className={classes.workPlace}>
              <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                勤務先
              </Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              勤務先
            </Typography>
            <List className={classes.list}>
              {list1.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    className={classes.listItem}
                  >
                    {input.dataInput.parts(item)}
                  </ListItem>
                );
              })}
              {list2.map((item) => {
                return (
                  <ListItem
                    key={item.lbl}
                    className={classes.listItem}
                  >
                    <Button
                      className={classes.listBtn}
                      onClick={item.click}
                    >
                      <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                        {item.lbl}
                      </span>
                      <span
                        className={`${baseClasses.title4} ${classes.listVal}`}
                      >
                        {item.val}
                      </span>
                    </Button>
                  </ListItem>
                );
              })}
              {list3.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    className={classes.listItem}
                  >
                    {input.dataInput.parts(item)}
                  </ListItem>
                );
              })}
              {list4.map((item) => {
                return (
                  <ListItem
                    key={item.lbl}
                    className={classes.listItem}
                  >
                    <Button
                      className={classes.listBtn}
                      onClick={item.click}
                    >
                      <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                        {item.lbl}
                      </span>
                      <span
                        className={`${baseClasses.title4} ${classes.listVal}`}
                      >
                        {item.val}
                      </span>
                    </Button>
                  </ListItem>
                );
              })}
              {list5.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    className={classes.listItem}
                  >
                    {input.dataInput.parts(item)}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        );
    }
  };

  if (isSp) {
    contents = (
      <Grid>
        {getStatusSp()}
      </Grid>
    );
  } else {
    contents = (
      <Grid>
        {getStatusPc()}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <SelectRailwaysAndStationsContainer
        initialParam={initialParamRailway}
        open={openRailway}
        onUpdate={handleUpdateRailway}
        onClose={handleCloseRailway}
      />
    </Grid>
  );
}
