/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';
import InfoRegister from '../../common/infoRegister';
import schedulesGetGuidanceByGuidanceIdApi from '../../../apis/schedules/schedulesGetGuidanceByGuidanceIdApi';
import { dateFormatter } from '../../../commonFunction';
import { useCustomerMain } from '../../../containers/customerMain/customerMainContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover .MuiButton-label': { backgroundColor: `${theme.palette.primaryColor}29` },
    width: 209,
  },
  title: {
    whiteSpace: 'nowrap',
  },
  button: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    width: '100%',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    marginBottom: 6,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '6px 8px',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '900',
    color: '#D83420',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 346,
  },
}));

function CustomerGuidance(props) {
  const { input } = props;

  const {
    rerenderSearch,
  } = useCustomerMain();

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  // const dispatch = useDispatch();
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [guidanceLocal, setGuidanceLocal] = useState({});

  // get: 個別案内情報
  const getGuidanceInfoByGuidanceIdFunction = async (guidanceId) => {
    await schedulesGetGuidanceByGuidanceIdApi(guidanceId)
      .then(async (res) => {
        // 案内情報取得後、ローカルstateに直接入れる（グローバルだと、全体が変更されてしまうため）
        setGuidanceLocal({ ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const guidanceLabel = (data) => {
    const scheduledProduct = data.suggestProducts.filter(item => item.isScheduleGuidance === 1);
    const formattedDate = dateFormatter(new Date(data.guidanceStartAt), 'YYYY/MM/DD(W) hh:mm');
    return `${formattedDate}~ ${scheduledProduct.length > 0 ? scheduledProduct[0].productName : ''}`;
  };

  useEffect(() => {
    // infoRegister(案内情報登録ウィンドウ)が開閉するたびにgetGuidance~叩く（更新した時など最新の情報を取得するため）
    if (!open && input.customer.guidanceScheduleId) getGuidanceInfoByGuidanceIdFunction(input.customer.guidanceScheduleId);
  }, [open]);

  return (
    <Grid className={classes.root}>
      <NormalTooltip title={Object.keys(guidanceLocal).length > 0 ? guidanceLabel(guidanceLocal) : ''}>
        <Button className={classes.button} onClick={handleOpen}>
          <Typography className={`${baseClasses.title6} ${classes.title}`}>
            案内
          </Typography>
          <Typography
            className={classes.txt}
          >
            {Object.keys(guidanceLocal).length > 0 ? guidanceLabel(guidanceLocal) : ''}
          </Typography>
        </Button>
      </NormalTooltip>
      <InfoRegister
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        guidanceId={input.customer.guidanceScheduleId}
        // guidanceId="40" // 検証のためにisReserved=1のguidanceを取得
        // guidanceId="86"
        customer={input.customer}
        rerenderSearch={rerenderSearch}
      />
    </Grid>
  );
}

export default React.memo(CustomerGuidance);
