import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerGetShareSlice = createSlice({
  name: 'customerGetShare',
  initialState: { customerGetShare: initialState },
  reducers: {
    getCustomerShare: (state, data) => {
      state.customerGetShare = data.payload;
    },
  },
});

export const { getCustomerShare } = customerGetShareSlice.actions;

export default customerGetShareSlice.reducer;
