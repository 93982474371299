import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Rnd from 'react-rnd';
import { makeStyles } from '@material-ui/core/styles';
import {
  ACTION_TYPE,
  ACTION_TYPE_FLYER_SALES,
  USER_LEVEL_NO,
  GUIDANCE_STATUS_CODE,
} from '../../../constants';
import EditableWindow from '../../pages/actRegist/editableWindow';
import ActRegistWindow from '../../pages/actRegist/actRegistWindow';
// eslint-disable-next-line import/no-cycle
import InfoRegisterOnlyPost from '../../common/infoRegisterOnlyPost';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  rnd: {
    position: 'relative',
    border: '1px solid #C8C8C8',
    background: '#fff',
    borderRadius: '0 4px 4px 4px',
    overflow: 'hidden',
    display: 'flex!important',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: '5px 0',
    maxWidth: 'none!important',
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: '14px 14px 0 0',
      borderStyle: 'solid',
      position: 'absolute',
      top: 0,
      left: 0,
      borderColor: 'transparent',
    },
    '&.actionScheduleCode-1::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-2::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-3::before': {
      borderColor: `${theme.palette.primaryColor} transparent transparent`,
    },
    '&.actionScheduleCode-4::before': {
      borderColor: '#F2994A transparent transparent',
    },
    '&.actionScheduleCode-5::before': {
      borderColor: '#DEDEDE transparent transparent',
    },
    '&.actionScheduleCode-6::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-7::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-999': {
      background: '#FCE034',
      '&::before': {
        borderColor: 'transparent',
      },
      '&.isResultRegister-1::before': {
        borderColor: '#D83420 transparent transparent',
      },
      '&.customerStatusCode-10::before': {
        borderColor: '#27AE60 transparent transparent',
      },
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '1.2',
    letterSpacing: '0',
    display: 'block',
    padding: '0 0 0 8px',
    whiteSpace: 'nowrap',
    '& span': {
      display: 'block',
      whiteSpace: 'nowrap',
    },
  },
}));

export default function DayRndSp(props) {
  const {
    item,
    index,
    rndWidthList,
    columnWidth,
    userCount,
    localUpdateData,
    setLocalUpdateData,
    setOpenCustomertree,
  } = props;

  const {
    getSchedulesDate,
    setReacquisitionFlg,
    functions,
    selectDivision,
  } = useActionSchedule();

  // 【schedule010】行動予定詳細のグローバルステート
  const schedulesGetActionDetail = useSelector(
    (state) => state.schedulesGetActionDetail.schedulesGetActionDetail,
  );

  const classes = useStyles();

  // クリックとドラッグの制御
  const [dragging, setDragging] = useState(false);

  // m19モーダルの開閉
  const [open2, setOpen2] = useState(false);
  const onClose2 = () => {
    setOpen2(false);
    // 再取得
    getSchedulesDate();
  };

  // m06-4 行動登録画面
  const [editableOpen, setEditableOpen] = useState(false);
  const onCloseEditable = () => {
    setEditableOpen(false);
    // 再取得
    getSchedulesDate();
  };
  const [openActRegist, setOpenActRegist] = useState(false);
  const onCloseActRegist = () => {
    setOpenActRegist(false);
    // 再取得
    getSchedulesDate();
  };
  // 目標
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });
  const [actRegistSelectDivision, setActRegistSelectDivision] = useState(selectDivision);

  // 時と分に分割
  const startHour = item.startAt ? item.startAt.substr(11, 2) : '';
  const endHour = item.endAt ? item.endAt.substr(11, 2) : '';
  const startMin = item.startAt ? item.startAt.substr(14, 2) : '';
  const endMin = item.endAt ? item.endAt.substr(14, 2) : '';

  // 日付に変換
  const startDate = new Date(item.startAt);
  const endDate = new Date(item.endAt);

  // 開始時刻
  const [hourS, setHourS] = useState(startHour);
  const [minutesS, setMinutesS] = useState(startMin);
  // 終了時刻
  const [hourE, setHourE] = useState(endHour);
  const [minutesE, setMinutesE] = useState(endMin);
  // 合計時間
  const [sum, setSum] = useState((endDate.getTime() - startDate.getTime()) / (60 * 1000));
  // 1セルの高さ（30分）
  const cellSize = 38;
  // 全セルの高さ
  const cellSizeAll = 988;
  // ラベルの横幅
  let rndWidth = columnWidth - (rndWidthList[index].odr * 4);
  if (userCount === 1 && rndWidthList[index].cnt !== 0) {
    rndWidth /= 2;
  }
  // 開始位置 X
  let startXInitial = rndWidthList[index].odr * 4;
  if (userCount === 1 && rndWidthList[index].odr % 2 !== 0) {
    startXInitial = columnWidth / 2;
  }
  const [startX, setStartX] = useState(startXInitial);
  // ラベルの高さ 開始位置 Y
  let rndHeightInitial = (sum / 30) * cellSize;
  let startYInitial = ((((startHour - 9) + (startMin / 60)) * 2) + 1) * cellSize;
  const endY = startYInitial + rndHeightInitial;

  // 開始時間が～9:00 or 終了時間が21:00～
  const outsideFlg = useRef(false);

  if (item.startAt.substr(0, 10) !== item.endAt.substr(0, 10)) { // 日付を跨ぐ場合
    if (startHour < 9) { // 開始時間が～9:00
      startYInitial = 0;
      rndHeightInitial = cellSizeAll;
    } else if (startHour > 20) { // 開始時間が21:00～
      startYInitial = 950;
      rndHeightInitial = cellSize;
    } else {
      rndHeightInitial = cellSizeAll - startYInitial;
    }
    outsideFlg.current = true;
  } else if (startHour < 9) { // 開始時間が～9:00
    if (endHour > 20) { // 終了時間が21:00～
      rndHeightInitial = cellSizeAll;
    } else if (endHour < 9) { // 終了時間が～9:00
      rndHeightInitial = cellSize;
    } else {
      rndHeightInitial += startYInitial;
    }
    startYInitial = 0;
    outsideFlg.current = true;
  } else if (startHour > 20) { // 開始時間が21:00～
    startYInitial = 950;
    rndHeightInitial = cellSize;
    outsideFlg.current = true;
  } else if (endHour > 20) { // 終了時間が21:00～
    rndHeightInitial = cellSizeAll - startYInitial;
    if (endMin === '00') {
      outsideFlg.current = false;
    } else {
      outsideFlg.current = true;
    }
  }

  const [rndHeight, setRndHeight] = useState(rndHeightInitial);
  const [startY, setStartY] = useState(startYInitial);

  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (item.startAt.substr(0, 10) !== item.endAt.substr(0, 10)) { // 日付を跨ぐ場合
      outsideFlg.current = true;
    } else if (startHour < 9) { // 開始時間が～9:00
      outsideFlg.current = true;
    } else if (startHour > 20) { // 開始時間が21:00～
      outsideFlg.current = true;
    } else if (endHour > 20) { // 終了時間が21:00～
      if (endMin === '00') {
        outsideFlg.current = false;
      } else {
        outsideFlg.current = true;
      }
    } else {
      outsideFlg.current = false;
    }
    setHourS(startHour);
    setMinutesS(startMin);
    setHourE(endHour);
    setMinutesE(endMin);
    setSum((endDate.getTime() - startDate.getTime()) / (60 * 1000));
    if (startHour > 9 && startHour < 21) {
      setStartY(((((startHour - 9) + (startMin / 60)) * 2) + 1) * cellSize);
    } else {
      setStartY(startYInitial);
    }
    setStartX(rndWidthList[index].odr * 4);
    setReset(!reset);
  }, [item]);

  useEffect(() => {
    if (startY >= cellSize && endY <= 950) {
      setRndHeight((sum / 30) * cellSize);
    } else if (item.startAt.substr(0, 10) !== item.endAt.substr(0, 10)) {
      setRndHeight(cellSizeAll - startY);
    }
  }, [sum]);

  const decimalPart = (num, decDigits) => {
    const decPart = num - ((num >= 0) ? Math.floor(num) : Math.ceil(num));
    return decPart.toFixed(decDigits);
  };

  // ラベルの更新制御
  const updateRndFlg = useRef(false);

  // 動作がonResize経由であるかどうかのFlg
  // (onResizeStopはSPの通常のクリック時にも呼ばれてしまうようなので、Flgで判定する)
  const [fromOnResizeFlg, setFromOnResizeFlg] = useState(false);
  // まさにonResizeを実行中であるかのFlg(countの更新で呼び出す更新処理を走らせるかどうかの判定に用いる)
  const [isDoingOnResize, setIsDoingOnResize] = useState(false);
  const onResize = async (e, direction, ref, delta, position) => {
    if (!outsideFlg.current) {
      // 合計時間を取得
      const minutes = Math.floor(parseInt(ref.style.height, 10) / 19) * 15;
      setSum(Number(minutes));
      // 開始位置を取得
      const positionY = position.y - cellSize;
      // 終了位置を取得
      const positionYend = positionY + ((sum / 30) * cellSize);

      if (positionY < 0 || positionY >= 912 || positionYend > 912) {
        // 更新不可
        updateRndFlg.current = false;
        // 範囲外
        outsideFlg.current = true;
      } else {
        // 更新可
        updateRndFlg.current = true;
        // onResizeの実行中である旨更新
        setIsDoingOnResize(true);
        // 再取得可
        setReacquisitionFlg(true);
        // 開始時刻
        const valSart = (positionY / 76);
        const hSart = Math.floor(valSart);
        const mSart = String(Math.floor(decimalPart(valSart, 2) * 60)).padStart(2, '0');
        setHourS(9 + hSart);
        setMinutesS(mSart);
        // 終了時刻
        const valEnd = (positionY / 76) + (minutes / 60);
        const hEnd = Math.floor(valEnd);
        const mEnd = String(Math.floor(decimalPart(valEnd, 2) * 60)).padStart(2, '0');
        setHourE(9 + hEnd);
        setMinutesE(mEnd);
        // 開始位置 Y
        setStartY(((((hSart) + (mSart / 60)) * 2) + 1) * cellSize);
      }
    }

    // onResize経由の操作となったことをセット
    setFromOnResizeFlg(true);
  };

  // 動作がonDrag経由であるかどうかのFlg
  // (onDragStopはSPの通常のクリック時にも呼ばれてしまうようなので、Flgで判定する)
  const [fromOnDragFlg, setFromOnDragFlg] = useState(false);
  // まさにonDragを実行中であるかのFlg(countの更新で呼び出す更新処理を走らせるかどうかの判定に用いる)
  const [isDoingOnDrag, setIsDoingOnDrag] = useState(false);
  const onDrag = async (e, d) => {
    if (!outsideFlg.current) {
      // 開始位置を取得
      const positionY = Math.round(d.y) - 154;
      // 終了位置を取得
      const positionYend = positionY + ((sum / 30) * cellSize);

      if (positionY < 0 || positionY >= 912 || positionYend > 912) {
        // 更新不可
        updateRndFlg.current = false;
        // 範囲外
        outsideFlg.current = true;
      } else {
        // 更新可
        updateRndFlg.current = true;
        // onDragの実行中である旨更新
        setIsDoingOnDrag(true);
        // 再取得可
        setReacquisitionFlg(true);
        // 開始時刻
        const valSart = (positionY / 76);
        const hSart = Math.floor(valSart);
        const mSart = String(Math.floor(decimalPart(valSart, 2) * 60)).padStart(2, '0');
        setHourS(9 + hSart);
        setMinutesS(mSart);
        // 終了時刻
        const valEnd = (positionY / 76) + (sum / 60);
        const hEnd = Math.floor(valEnd);
        const mEnd = String(Math.floor(decimalPart(valSart, 2) * 60)).padStart(2, '0');
        setHourE(9 + hEnd);
        setMinutesE(mEnd);
        // 開始位置 Y
        setStartY(((((hSart) + (mSart / 60)) * 2) + 1) * cellSize);
      }
    }

    // クリックとドラッグの制御
    setDragging(true);
    // onDrag経由の操作となったことをセット
    setFromOnDragFlg(true);
  };

  const [count, setCount] = useState(0);

  const onResizeStop = async () => {
    // onResize経由でなければ以降の処理はそもそも実行せず
    if (!fromOnResizeFlg) {
      return;
    }
    if (outsideFlg.current) {
      // 更新不可
      updateRndFlg.current = false;
      // 「~ 9:00」と「21:00 ~」は編集画面を開く
      // actionScheduleCodeがnullの場合、m19画面を開く
      if (item.actionScheduleCode === null) {
        setOpen2(true);
      } else {
        // グローバルステート(schedulesGetActionDetail)の更新
        await functions.getSchedulesDetailFunction(item.scheduleId);
        if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
          setEditableOpen(true);
        } else {
          setOpenActRegist(true);
        }
      }
    } else {
      // 更新処理を呼び出す
      setCount(count + 1);
    }
  };

  const onDragStop = async () => {
    // onDrag経由でなければ以降の処理はそもそも実行せず
    if (!fromOnDragFlg) {
      return;
    }
    // クリックとドラッグの制御
    setTimeout(() => { setDragging(false); }, 500);
    if (outsideFlg.current) {
      // 更新不可
      updateRndFlg.current = false;
      // 「~ 9:00」と「21:00 ~」は編集画面を開く
      // actionScheduleCodeがnullの場合、m19画面を開く
      if (item.actionScheduleCode === null) {
        setOpen2(true);
      } else {
        // グローバルステート(schedulesGetActionDetail)の更新
        await functions.getSchedulesDetailFunction(item.scheduleId);
        if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
          setEditableOpen(true);
        } else {
          setOpenActRegist(true);
        }
      }
    } else {
      if (!fromOnDragFlg) {
        // onClickの内容を実行する
        if (dragging) return;
        // actionScheduleCodeがnullの場合、m19画面を開く
        if (item.actionScheduleCode === null) {
          setOpen2(true);
        } else {
          // グローバルステート(schedulesGetActionDetail)の更新
          await functions.getSchedulesDetailFunction(item.scheduleId);
          if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
            setEditableOpen(true);
          } else {
            setOpenActRegist(true);
          }
        }
        return;
      }
      // fromOnDragFlgをfalseに戻す
      setFromOnDragFlg(false);
      // fromOnResizeFlgをfalseに戻す
      setFromOnResizeFlg(false);
      // 更新処理を呼び出す
      setCount(count + 1);
    }
  };

  useEffect(() => {
    if (updateRndFlg.current && (isDoingOnDrag || isDoingOnResize)) {
      // 更新
      const updateStartAt = item.startAt.substr(0, 11) + ('0' + String(hourS)).slice(-2) + ':' + minutesS + ':00';
      const updateEndAt = item.endAt.substr(0, 11) + ('0' + String(hourE)).slice(-2) + ':' + minutesE + ':00';
      if (item.actionScheduleCode) {
        functions.schedulesUpdateApiFunction(
          item.scheduleId,
          {
            startAt: updateStartAt,
            endAt: updateEndAt,
          },
        );
      } else {
        functions.putGuidanceByGuidanceIdFunction(
          item.guidanceId,
          {
            guidanceStartAt: updateStartAt,
            guidanceEndAt: updateEndAt,
            customerId: item.customerId,
            guidanceStatusCode: item.guidanceStatusCode,
            contactId: item.contactId,
            actionId: item.actionId,
            contactCode: item.contactCode,
          },
        );
      }
    }
    // onDragの実行中Flgがtrueの場合は、falseとする
    if (isDoingOnDrag) {
      setIsDoingOnDrag(false);
    }
    // onResizeの実行中Flgがtrueの場合は、falseとする
    if (isDoingOnResize) {
      setIsDoingOnResize(false);
    }
  }, [count]);

  const onClick = async () => {
    if (dragging) return;
    // actionScheduleCodeがnullの場合、m19画面を開く
    if (item.actionScheduleCode === null) {
      setOpen2(true);
    } else {
      // グローバルステート(schedulesGetActionDetail)の更新
      await functions.getSchedulesDetailFunction(item.scheduleId);
      if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
        setEditableOpen(true);
      } else {
        setOpenActRegist(true);
      }
    }
  };

  useEffect(() => {
    setLocalUpdateData(schedulesGetActionDetail);
  }, [schedulesGetActionDetail]);

  return (
    <>
      <Rnd
        key={reset}
        className={`${classes.rnd} actionScheduleCode-${item.actionScheduleCode || '999'} isResultRegister-${item.isResultRegister} customerStatusCode-${item.customerStatusCode}`}
        default={{
          x: startX,
          y: startY,
          // width: rndWidth,
          // height: rndHeight,
        }}
        size={{
          width: rndWidth,
          height: rndHeight,
        }}
        // position={{
        //   x: startX,
        //   y: startY,
        // }}
        dragAxis="y"
        enableResizing={{
          top: true,
          right: false,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        bounds="parent"
        resizeGrid={[0, 19]}
        dragGrid={[19, 19]}
        minHeight="19"
        onResize={onResize}
        onResizeStop={onResizeStop}
        onDrag={onDrag}
        onDragStop={onDragStop}
        onClick={onClick}
      >
        {item.actionScheduleCode ? (
          <span className={classes.txt}>
            <span>
              {hourS}:{minutesS}-{hourE}:{minutesE}
            </span>
            <span>
              {item.customerName ? ` ${item.customerName} 様` : '' }
            </span>
            <span>
              {ACTION_TYPE[item.actionScheduleCode]}
            </span>
            <span>
              {item.memo ? `${item.memo}` : ''}
            </span>
          </span>
        ) : (
          <span className={classes.txt}>
            <span>
              {hourS}:{minutesS}-{hourE}:{minutesE}
            </span>
            <span>
              {item.customerName ? ` ${item.customerName} 様、` : ''}
            </span>
            <span>
              {item.customerStatusCode === 10 ? `(契)${GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}` : GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}
            </span>
            <span>
              {item.userLevel ? `${USER_LEVEL_NO[item.userLevel]}` : '★なし'}
            </span>
            <span>
              {item.isPairCheck ? '揃い' : ''}
            </span>
            <span>
              {item.siteName ? `${item.siteName}` : ''}
            </span>
          </span>
        )}
      </Rnd>
      {/* m06-4（源泉） */}
      <EditableWindow
        open={editableOpen}
        onClose={onCloseEditable}
        setReacquisitionFlg={setReacquisitionFlg}
        localUpdateData={localUpdateData}
        setLocalUpdateData={setLocalUpdateData}
        setOpenCustomertree={setOpenCustomertree}
        actRegistFlag={Boolean(false)}
        editableFlag={Boolean(true)}
        schedulesUpdateApiFunction={functions.schedulesUpdateApiFunction}
        schedulesPostApiFunction={functions.schedulesPostApiFunction}
        schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
        actionScheduleCode={item.actionScheduleCode}
      />
      {/* m06-4（源泉以外） */}
      {openActRegist && (
        <ActRegistWindow
          open={openActRegist}
          onClose={onCloseActRegist}
          setReacquisitionFlg={setReacquisitionFlg}
          values={localUpdateData}
          setUpdateData={setLocalUpdateData}
          setOpenCustomertree={setOpenCustomertree}
          actRegistFlag={Boolean(false)}
          editableFlag={Boolean(true)}
          targetObj={targetObj}
          setTargetObj={setTargetObj}
          archieveObj={archieveObj}
          setArchieveObj={setArchieveObj}
          selectDivision={actRegistSelectDivision}
          setSelectDivision={setActRegistSelectDivision}
          isSource={Boolean(false)}
          isEdit={Boolean(true)}
          createUpdateFunc={(obj) => {
            functions.schedulesUpdateApiFunction(localUpdateData.scheduleId, obj);
          }}
          schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
        />
      )}
      {/* m19 */}
      <InfoRegisterOnlyPost
        open={open2}
        onOpen={() => { setOpen2(true); }}
        onClose={onClose2}
        // newRegistrationFlgがtrueの場合、infoRegisterにguidanceIdを渡さない===新規登録になる
        guidanceId={item.guidanceId}
        // setClickInfoRegister={setClickInfoRegister}
        customer={item}
        // user={user}
        reacquisition={getSchedulesDate}
      />
    </>
  );
}
