import DashBoardContainer from '../../../containers/dashBoard/dashBoardContainer';
import CommonDialog from '../../common/modal';
import { useBrowser } from '../../../hooks';
import commonStyles from '../../styles';

const time = new Date();

function DashBoard(props) {
  const { open, handleClose, tab } = props;
  const { isSP } = useBrowser();
  const baseClasses = commonStyles();
  const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
  if (isSP) {
    return (
      <CommonDialog
        title={(
          <>
            <span className={baseClasses.title6}>本日</span>
            <span className={baseClasses.title1}>
              {time.getMonth() + 1}/{time.getDate()}
            </span>
            <span className={baseClasses.title6}>{weekDay[time.getDay()]}</span>
          </>
        )}
        open={open}
        onClose={handleClose}
        // white
      >
        <DashBoardContainer tab={tab} onClose={handleClose} isSP />
      </CommonDialog>
    );
  } else {
    return (
      <CommonDialog
        title="ダッシュボード"
        open={open}
        onClose={handleClose}
        // white
      >
        <DashBoardContainer onClose={handleClose} />
      </CommonDialog>
    );
  }
}

export default DashBoard;
