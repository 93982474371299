import { useDispatch } from 'react-redux';
import librarydirCopyApi from '../../apis/librarydir/librarydirCopyApi';
import librarydirResisterApi from '../../apis/librarydir/librarydirResisterApi';
import librarydirUpdateApi from '../../apis/librarydir/librarydirUpdateApi';
import librarySearchApi from '../../apis/library/librarySearchApi';
import { getLibraryGetSpecifyDirctory } from '../../store/library/libraryGetSpecifyDirctorySlice';
import MailLibrarySidebar from '../../components/mailLibrary/mailLibrarySidebar';

import { dateFormatter } from '../../commonFunction';
import { getLibrarySearchParam } from '../../commonFunction/library';
import { useLoading } from '../../hooks';
import { useLibraryContext } from '../../components/mailLibrary/mailLibraryContext';
import { LIBRARY_SIDEBAR } from '../../constants/loading';

export default function MailLibrarySidebarContainer(props) {
  const {
    setViewMode,
    selectDir,
    setSelectDir,
  } = props;

  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();
  const { setIsDirAdd, setIsParentUpdate } = useLibraryContext();

  // ディレクトリ新規追加---サイドバーのみローディング
  const registerNewDir = async () => {
    const param = {
      parentId: selectDir.libraryDirectoryId,
      directoryName: `新規 ${dateFormatter(new Date(), 'YYYYMMDDhhmmss')}`,
      directoryCode: 3,
      mailCode: selectDir.mailCode,
    };
    addLoading(LIBRARY_SIDEBAR);
    await librarydirResisterApi(param)
      .then((res) => {
        setIsDirAdd(true);
        setSelectDir(res.data.libraryDirectoryId);
      })
      .catch((err) => console.error(err))
      .finally(() => removeLoading(LIBRARY_SIDEBAR));
  };

  // PUTディレクトリ情報更新---サイドバーのみローディング
  const updateDir = async (param, targetDir = null) => {
    addLoading(LIBRARY_SIDEBAR);
    await librarydirUpdateApi(param)
      .then(() => {
        setIsParentUpdate(true);
        targetDir && setTimeout(() => setSelectDir(targetDir), 1000);
      })
      .catch((err) => console.error(err))
      .finally(() => removeLoading(LIBRARY_SIDEBAR));
  };

  // コピーPOST---サイドバーのみローディング
  const copyDir = async (param, targetDir = null) => {
    addLoading(LIBRARY_SIDEBAR);
    await librarydirCopyApi(param)
      .then(() => setSelectDir(targetDir))
      .catch((err) => console.error(err))
      .finally(() => removeLoading(LIBRARY_SIDEBAR));
  };

  // 検索ボタンを実行---ディレクトリ移動はテーブルのみ、初回画面全体
  const getLibraries = async () => {
    await librarySearchApi(getLibrarySearchParam())
      .then((res) => dispatch(getLibraryGetSpecifyDirctory(res.data)))
      .catch((err) => console.log(err));
  };

  // ファンクション
  const functions = {
    registerNewDir,
    updateDir,
    copyDir,
    getLibraries,
  };

  return (
    <MailLibrarySidebar
      setViewMode={setViewMode}
      functions={functions}
      selectDir={selectDir}
      setSelectDir={setSelectDir}
    />
  );
}
