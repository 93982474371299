import { Typography, Grid, Button, makeStyles } from '@material-ui/core';
import { classNames } from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    right: 4,
    transform: 'translateY(-50%)',
    opacity: 0,
    transition: 'opacity 0.5s ease',
    // background: '#fff',
    borderRadius: 4,
    marginLeft: 4,
    overflow: 'hidden',
    '& svg ~ p': {
      marginLeft: 3,
    },
  },
  button: {
    background: '#fff',
    whiteSpace: 'nowrap',
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '&:not(:last-child)': { marginRight: 4 },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
  txt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
}));

function FloatButtonGroup(props) {
  const { items = [], className } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classNames(classes.root, 'floatButtonGroupHook', className)}
    >
      {items.map((item, i) => (
        <Button
          key={String(i)}
          className={classNames(classes.button, classes.blue, item.className)}
          onClick={(e) => {
            e.stopPropagation();
            item.onClick?.(e, item);
          }}
        >
          {item.icon}
          <Typography className={classes.txt}>{item.label}</Typography>
        </Button>
      ))}
    </Grid>
  );
}

export default FloatButtonGroup;
