import RegisterWindowContainers from './registerWindow';

function RegisterWindow(props) {
  const {
    open,
    onClose,
    type,
    setType,
    infoRegisterOpen,
    typeClose,
    setOpenAlert,
    createHistory,
    customerId,
    customer,
    setActionId,
    getActionHistoryList,
  } = props;
  return (
    <RegisterWindowContainers
      open={open}
      onClose={onClose}
      type={type}
      setType={setType}
      infoRegisterOpen={infoRegisterOpen}
      typeClose={typeClose}
      setOpenAlert={setOpenAlert}
      createHistory={createHistory}
      customerId={customerId}
      customer={customer}
      setActionId={setActionId}
      getActionHistoryList={getActionHistoryList}
    />
  );
}

export default RegisterWindow;
