import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Grid, makeStyles } from '@material-ui/core';

import commonStyles from './styles';
import CustomTheme from './customTheme';

import { URL_SETTINGS_LIST } from './urls';
import CommonHeader from './common/commonHeader';
import Login from './auth/login';
import CustomAlertMessage from './eleCommon/customAlertMessage';
import CustomConfirmMessage from './eleCommon/customConfirmMessage';
import CantCloseMessage from './eleCommon/cantCloseAlertMessage';
import ApiAlertDialog from './apiMessage';
import DashBoard from './pages/dashBoard/dashBoard';

import MainFootMenuTabs from './mainMenuSp/mainFootMenuTabs';

import { useLoading } from '../hooks';
import { setDeviceType } from '../store/getDeviceType';
import { getColorTheme } from '../store/common/getTheme';

import getDeviceType from './getDeviceType';
import { classNames, setLocalStorage, getLocalStorage } from '../commonFunction';
import { signInProcess } from '../commonFunction/authorize';

import getHeaderNumberApi from '../apis/common/commonGetHeaderNumberApi';
import { commonGetHeaderNumberApi } from '../store/common/commonGetHeaderNumberSlice';
import { cantCloseAlertMessage } from '../store/eleCommon/cantCloseAlertMessage';

import { URL_MAPPER } from '../constants/urls';
import { BANNED } from '../constants/userRoleGroup';
import { MOBILE_SFA_USE } from '../constants/userRole';
import { DAILY_SHOW_DASHBOARD } from '../constants/localStorage';

import usePermissions from '../hooks/usePermissions';

const useStyles = makeStyles({
  // root: { paddingBottom: 58 },
  rootPC: { height: 'calc(100vh - 52px)', overflowY: 'auto' },
});

/**
 * パフォーマンス対応
 * ローディングの際、子供のコンポーネント関数を発火せないため
 * @param {*} props
 * @returns {ReactNode}
 */
function LoadingWrap(props) {
  const { children } = props;
  const { hasLoading } = useLoading();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const classes = useStyles();
  const common = commonStyles();

  return (
    <Grid
      className={classNames(
        isSp ? classes.root : classes.rootPC,
        hasLoading() ? common.loading : '',
        'allWrapper',
      )}
    >
      {children}
    </Grid>
  );
}

function SignedInComponent() {
  const dispatch = useDispatch();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const responseHeader = useSelector((state) => state.responseHeader);

  const [isOpenDashBoard, setIsOpenDashBoard] = useState(getLocalStorage(DAILY_SHOW_DASHBOARD, 'open') === 'open');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // 各ページの権限
  const { checkUrlPermission } = usePermissions();

  const closeDailyDashBoard = () => {
    setIsOpenDashBoard(false);
    setLocalStorage(DAILY_SHOW_DASHBOARD, 'close');
  };

  // ヘッダーカウント
  const getHeaderCountFunction = () => {
    getHeaderNumberApi()
      .then((res) => dispatch(commonGetHeaderNumberApi(res.data)))
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
        if (window.location.pathname === URL_MAPPER.customer && !window.location.search) {
          navigate(URL_MAPPER.customer);
        }
      });
  };

  useEffect(() => {
    if (!responseHeader.userId) return;
    const { roleGroupId, roleIds } = responseHeader;
    if (roleGroupId === BANNED || (isSp && !roleIds.includes(MOBILE_SFA_USE))) {
      // ログアウト処理を挟みたかったが、ログアウトをするとログアウトURLにリダイレクトするので
      // メッセージが表示されないので、ログアウト処理はしない
      dispatch(
        cantCloseAlertMessage({
          msgList: ['ユーザーはSFA/CRMの利用が許可されていません。'],
          title: '閲覧不可',
        }),
      );
    }
  }, [responseHeader]);

  useEffect(() => {
    getHeaderCountFunction();
  }, []);

  return (
    <>
      <CommonHeader />
      {isLoading || (
        <LoadingWrap>
          <Routes>
            {URL_SETTINGS_LIST.filter((url) => checkUrlPermission(url.path)).map((data) => (
              <Route path={data.path} element={data.element} />
            ))}
            <Route path="/" />
            {/* 権限がない場合は下記にリダイレクト */}
            <Route path="*" element={<>存在しないページです</>} />
          </Routes>
        </LoadingWrap>
      )}
      {isSp ? <MainFootMenuTabs /> : null}
      {isSp ? null : (
        <DashBoard open={isOpenDashBoard} handleClose={closeDailyDashBoard} />
      )}
      {/* zIndexが同じ場合にアラートが上のレイヤーになるように位置を下に変更 */}
      <CustomAlertMessage />
      <CustomConfirmMessage />
      <CantCloseMessage />
      <ApiAlertDialog />
      {/* アラートここまで */}
    </>
  );
}

function NotSignedInComponent({ openDailyDashBoard }) {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <>
            <Login
              signInProcess={openDailyDashBoard}
            />
            <CantCloseMessage />
            <ApiAlertDialog />
          </>
        }
      />
    </Routes>
  );
}

function MainComponent() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies();

  const { token: accessToken } = useSelector((state) => state.accessToken);

  const openDailyDashBoard = () => setLocalStorage(DAILY_SHOW_DASHBOARD, 'open');
  useEffect(() => {
    // 最初のロードでSPか判定
    const judgeSpFlg = getDeviceType();
    dispatch(setDeviceType(judgeSpFlg));

    // カラー設定を判定、なければデフォルトを設定
    if (cookies.color === undefined) {
      setCookie('color', 'default');
      dispatch(getColorTheme('default'));
    } else {
      dispatch(getColorTheme(cookies.color));
    }

    // accessTokenの有効性を確認. 切れている場合は更新される
    signInProcess();
  }, []);

  return (
    <CustomTheme>
      <CookiesProvider>
        <Router>
          {accessToken ? (
            <SignedInComponent />
          ) : (
            <NotSignedInComponent openDailyDashBoard={openDailyDashBoard} />
          )}
        </Router>
      </CookiesProvider>
    </CustomTheme>
  );
}

export default MainComponent;
