import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LibraryTreeContainer from '../../../containers/common/libraryTree/libraryTreeContainer';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles(() => ({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '100%',
        background: '#F3F3F3',
      },
      '& .MuiDialog-paper': {
        maxWidth: 'none',
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'initial',
        borderRadius: 0,
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
        borderRadius: 0,
      },
    },
  },
  wrapper: {
    maxWidth: 300,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    left: '0px',
    top: '0px',
    borderBottom: '1px solid #C8C8C8',
    [commonTheme.breakpoints.up('md')]: {
      position: 'static',
      width: '1024px',
      height: '52px',
      padding: '24px 24px 8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      padding: '16px 8px 8px',
      justifyContent: 'center',
    },
    '& h2': {
      fontWeight: 'bold',
    },
  },
  content: {
    padding: 0,
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      [commonTheme.breakpoints.up('md')]: {
        marginLeft: 16,
      },
    },
  },
  disabled: {
    pointerEvents: 'none',
    background: '#DEDEDE',
    '&>span': {
      color: '#AFAFAF',
    },
  },
  btnWrap: { paddingTop: 16 },
  footer: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 16,
    },
  },
}));

function LibraryTree(props) {
  const {
    isOpenTree,
    setIsOpenTree,
    selectDir: directory,
    setSelectDir: setDirectory,
    selectLibrary: library,
    setSelectLibrary: setLibrary,
    isDialog,
    isPreview,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const [selectDir, setSelectDir] = useState(directory);
  const [selectLibrary, setSelectLibrary] = useState(library);

  // 選択解除
  const handleClose = () => {
    setSelectDir({});
    setSelectLibrary({});
    setIsOpenTree(false);
  };

  const onClickOk = () => {
    setDirectory(selectDir);
    setLibrary(selectLibrary);
    setIsOpenTree(false);
  };

  const Wrapper = useMemo(() => {
    return isDialog
      ? ({ children }) => (
        <Dialog
          open={isOpenTree}
          onClose={() => setIsOpenTree(false)}
          maxWidth="xs"
          fullWidth
          className={`${classes.dialog}`}
        >
          <DialogTitle
            className={`${classes.header} ${common.title3} ${classes.customTitle3}`}
          >
            ライブラリ選択
            <Button className={common.closeButton} onClick={handleClose} />
          </DialogTitle>
          <DialogContent className={`${classes.content}`}>
            {children}
          </DialogContent>
        </Dialog>
      ) : ({ children }) => children;
  }, [isDialog, isOpenTree]);

  useEffect(() => {
    if (isPreview) return; // previewがあるときは同期させない
    setDirectory(selectDir);
    setLibrary(selectLibrary);
  }, [isPreview]);

  useEffect(() => {
    setSelectDir(directory);
    setSelectLibrary(selectLibrary);
  }, [directory, library]);

  if (!isSp) return <>PC未対応</>;
  return (
    <Wrapper>
      <LibraryTreeContainer
        selectDir={selectDir}
        setSelectDir={setSelectDir}
        selectLibrary={selectLibrary}
        setSelectLibrary={setSelectLibrary}
        onClickOk={onClickOk}
        isPreview={isPreview}
      />
    </Wrapper>
  );
}

export default LibraryTree;
