import React, { useState } from 'react';
import {
  Grid,
  // Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../../styles';
// import CustomEditDialog from '../../../../eleCommon/sp/customEditDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    background: '#fff',
    '&.active': {
      display: 'block',
    },
  },
  ttl: {
    flexShrink: 0,
    whiteSpace: 'initial',
    '& span': {
      color: '#D83420',
    },
  },
  value: {
    marginTop: 8,
    fontWeight: 'bold',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    textAlign: 'left',
    textTransform: 'none',
    borderBottom: '1px solid #F3F3F3',
    borderRadius: 0,
    width: '100%',
    padding: '16px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    '& .MuiButton-label': {
      display: 'block',
    },
  },
  input: {
    width: '100%',
    '& input, & textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  dialog: {
    zIndex: '1352!important',
  },
}));

function DoNotChaseReasonTextSp(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル用
  // const [open, setOpen] = useState(false);
  const [text, setText] = useState(values.state); // テキストフィールドのバリュー値制御

  // const content = (
  //   <TextField
  //     autoFocus
  //     multiline
  //     className={classes.input}
  //     onChange={(e) => { return setText(e.target.value); }}
  //     onBlur={(e) => { return values.setState(e.target.value); }}
  //     name={values.label}
  //     value={text}
  //   />
  // );

  return (
    <Grid className={`${classes.root} ${classes.btn} ${values.active && 'active'}`}>
      {/* <Button className={classes.btn} onClick={() => { setOpen(!open); }}> */}
      <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
        {values.label}
      </Typography>
      {/* </Button> */}
      {/* <CustomEditDialog
        open={open}
        setOpen={setOpen}
        content={content}
        lbl={values.label}
        style={classes.dialog}
      /> */}
      <TextField
        autoFocus
        multiline
        className={classes.input}
        onChange={(e) => { return setText(e.target.value); }}
        onBlur={(e) => { return values.setState(e.target.value); }}
        name={values.label}
        value={text}
        placeholder="-"
      />
    </Grid>
  );
}

export default DoNotChaseReasonTextSp;
