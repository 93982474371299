// eslint-disable-next-line import/no-cycle
import ContactTotalContainers from '../../../containers/common/contactTotal/contactTotalContainers';

function ContactTotal(props) {
  const { customer } = props;
  return (
    <div>
      <ContactTotalContainers customer={customer} />
    </div>
  );
}

export default ContactTotal;
