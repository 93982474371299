import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

import commonStyles from '../../styles';
import StoreGroupTable from './storeGroupTable';
import EditStoreGroup from '../editStoreGroup';
import CustomSelect from '../../eleCommon/customSelect';

import { useStoreGroupsSettingsAreas } from '../../combinationSetting/common/useStoreGroupsSettingsAreas';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  startIcon: {
    color: theme.palette.primaryColor,
    width: 14,
    height: 14,
    marginRight: 0,
  },
  addButton: {
    '& span': {
      marginRight: 0,
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      '&>span': {
        color: '#DEDEDE',
      },
    },
  },
  addButtonTxt: {
    color: theme.palette.primaryColor,
  },
  disabled: {
    color: '#DEDEDE',
  },
  selectContainer: {
    width: '100%',
    backgroundColor: '#F3F3F3',
    padding: 5,
    marginBottom: 10,
    textAlign: 'left',
  },
  select: {
    width: '120px',
    textAlign: 'left',
    margin: '0px',
    backgroundColor: '#ffffff',
    border: '0px solid #ffffff',
    '& .MuiSelect-select': {
      backgroundColor: '#ffffff',
    },
  },
  inputAdornment: {
    marginRight: 0,
  },
  searchIcon: {
    height: '1rem',
  },
  iconAdornment: {
    padding: '0px',
    marginRight: 0,
  },
  textInput: {
    padding: '5px',
    fontSize: '13px',
  },
}));

function SetStoreGroupInner(props) {
  const { settings, areaCode, setAreaCode, deleteStoreGroup } = props;

  const { areaList } = useStoreGroupsSettingsAreas();

  const [addButtonDsiable, setAddButtonDsiable] = useState(true);
  const [openCreate, setOpenCreate] = useState(false);
  const classes = useStyles();
  const baseClasses = commonStyles();
  const handleCreateGroup = () => {
    setOpenCreate(true);
  };
  const areaChange = (e) => {
    setAreaCode(Number(e.target.value));
  };

  const menus = {};
  areaList.forEach((area) => {
    menus[area.areaCode] = area.areaName;
  });
  const data = {
    type: 'select',
    obj: {
      menus,
    },
    state: areaCode,
    set: areaChange,
  };

  useEffect(() => {
    setAddButtonDsiable(areaCode === 0);
  }, [areaCode]);
  return (
    <>
      <Grid className={classes.container}>
        <Grid container>
          <Grid className={baseClasses.selectBox}>
            <Typography className={baseClasses.title4}>
              エリア
            </Typography>
            <CustomSelect initial={data} />
          </Grid>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <Typography className={baseClasses.title3}>グループ一覧</Typography>
          <Button
            className={classes.addButton}
            startIcon={<AddIcon className={`${classes.startIcon} ${addButtonDsiable ? `${classes.disabled}` : ''}`} />}
            disabled={addButtonDsiable}
            onClick={handleCreateGroup}
          >
            <Typography
              className={`${baseClasses.smallStrong} ${classes.addButtonTxt} ${addButtonDsiable ? `${classes.disabled}` : ''}`}
            >
              追加
            </Typography>
          </Button>
        </Grid>
        <Grid>
          <StoreGroupTable
            settings={settings}
            deleteStoreGroup={deleteStoreGroup}
          />
        </Grid>
      </Grid>
      <Grid>
        <EditStoreGroup
          open={openCreate}
          setOpen={setOpenCreate}
          areaCode={areaCode}
        />
      </Grid>
    </>
  );
}

export default SetStoreGroupInner;
