import HolidayTelContainers from './holidayTel';

function HolidayTel(props) {
  const {
    initialParam,
    onClickSelectButton,
    open,
    onClose,
    category,
  } = props;
  return (
    <HolidayTelContainers
      initialParam={initialParam}
      onClickSelectButton={onClickSelectButton}
      open={open}
      onClose={onClose}
      category={category}
    />
  );
}

export default HolidayTel;
