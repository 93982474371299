import React, { useRef, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import commonStyles from '../../../../styles';

import { useLoading } from '../../../../../hooks';

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-rounded': {
      borderRadius: '12px',
      margin: '8px',
      maxHeight: 'calc(100% - 32px)',
      width: '100%',
      height: '100%',
    },
  },
  main: {
    padding: 0,
    borderTop: '1px solid rgba(51, 51, 51, 0.04)',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
  },
  content: {
    padding: '16px',
    '& > *:not(:first-child)': {
      marginTop: '16px',
    },
    '& > p': {
      fontSize: '13px',
      lineHeight: '1.6',
    },
  },
  subject: {
    background: '#F3F3F3',
    padding: '16px',
    fontSize: '15px',
    lineHeight: '1.6',
  },
  mailBody: {
    marginTop: '1em',
    whiteSpace: 'break-spaces',
  },
  footer: {
    justifyContent: 'center',
    padding: '24px',
    gap: '24px',
  },
});

export default function LibraryPreview(props) {
  const { values, open, onClose, onClickOk, onClickCancel } = props;
  const common = commonStyles();
  const classes = useStyles();
  const ref = useRef({}); // HTML出力要素

  const { hasLoading } = useLoading();

  const handleClose = () => {
    onClose();
    onClickCancel?.();
  };

  const handleSet = () => {
    onClose();
    onClickOk?.();
  };

  // ライブラリの本文をプレビューへ反映
  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = values.mailBody;
    }
  }, [values]);

  return (
    <Dialog className={classes.dialog} open={open} onClose={handleClose}>
      {!hasLoading() && (
        <>
          <DialogTitle className={`${common.header}`}>
            {values.libraryName}
          </DialogTitle>
          <DialogContent className={classes.main} dividers>
            <Grid className={classes.subject}>{values.mailSubject}</Grid>
            {/* プレビューエリア */}
            <Grid ref={ref} className={classes.content} />
          </DialogContent>
          <DialogActions className={classes.footer}>
            <Button className={common.buttonsSecondary} onClick={handleClose}>
              選び直す
            </Button>
            <Button className={common.buttonsPrimary} onClick={handleSet}>
              決定
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
