import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function GuidanceStatusCodeCheckBox(props) {
  const {
    label,
    flg,
    code,
    all,
    set,
  } = props;

  const checkInitial = () => {
    switch (flg) {
      case 1:
        return true;
      default:
        return false;
    }
  };

  const [checked, setChecked] = useState(checkInitial());

  const handleChange = (e) => {
    setChecked(e.target.checked);
    set(e.target.checked, e.target.name);
  };

  const allCheckLift = () => {
    switch (all) {
      case 1:
        setChecked(false);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    allCheckLift();
  });

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            name={String(code)}
            onChange={handleChange}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
