import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import EditStoreTable from './parts/editStoreTable';
import SelectStoreWindow from '../selectStoreWindow';
import CommonDialog from '../../common/modal';
import inputNumber from '../../../commonFunction/inputNumber';

import {
  validateFormInt1,
  validateFormFloat1,
} from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  areaContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginBottom: 12,
  },
  groupNameContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '10px 8px',
    boxSizing: 'border-box',
    border: 'solid 1px #C8C8C8',
  },
  adjustMargin: {
    marginRight: 10,
  },
  secondLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  startIcon: {
    width: 14,
    height: 14,
  },
  addButton: {
    color: theme.palette.primaryColor,
    '& span': {
      marginRight: 0,
    },
  },
  resetButton: {
    color: theme.palette.primaryColor,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },
  registButton: {
    width: 70,
    height: 40,
    margin: '0 auto',
  },
  textField: {
    '& input': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '160%',
    },
  },
  shortTextField: {
    '& input': {
      width: 25,
      textAlign: 'center',
    },
  },
}));

function EditStoreGroup(props) {
  const {
    open,
    onClose,
    storeGroupDetail = {},
    areaCode,
    nextRegist,
    setNextRegsit,
    nextResponse,
    setNextResponse,
    // form func
    handleChangeGroupName,
    handleChangeDisplayOrder,
    handleChangeCorrection,
    handleReset,
    registStoreGroup,
    deleteStore,
    storeChange,
  } = props;

  const [isValid, setIsValid] = useState(false);
  const [errorSet, setErrorSet] = useState(new Set());
  const [openSelectStoreWindow, setOpenSelectStoreWindow] = useState(false);
  const classes = useStyles();
  const common = commonStyles();

  const { settings, storeGroupName, displayOrder, correction } = storeGroupDetail;
  const handleOpenSelectStoreWindow = () => {
    setOpenSelectStoreWindow(true);
  };

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  useEffect(() => {
    inputNumber();
  }, []);
  // validation制御
  useEffect(() => {
    if (storeGroupName === '' || displayOrder === '' || correction === '' || settings.length === 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [storeGroupName, displayOrder, correction, settings]);

  return (
    <>
      <CommonDialog
        open={open}
        onClose={onClose}
        title="店舗グループ_反響登録・編集ウィンドウ"
        width={640}
        buttons={{
          label: '登録',
          onClick: registStoreGroup,
          disabled: !isValid || errorSet.size > 0,
        }}
      >
        <>
          <Grid className={classes.areaContainer}>
            <Typography className={common.title6}>エリア</Typography>
            <Typography className={common.strong}>
              {storeGroupDetail.areaName}
            </Typography>
          </Grid>
          <Grid className={classes.secondLine}>
            <Grid
              style={{ width: 336 }}
              className={classes.groupNameContainer}
            >
              <Typography
                className={`${common.title6} ${classes.adjustMargin}`}
              >
                グループ名<span style={{ color: '#D83420' }}>＊</span>
              </Typography>
              <TextField
                value={storeGroupDetail.storeGroupName}
                className={classes.textField}
                onChange={handleChangeGroupName}
              />
            </Grid>
            <Grid
              style={{ width: 120 }}
              className={classes.groupNameContainer}
            >
              <Typography
                className={`${common.title6} ${classes.adjustMargin}`}
              >
                表示順序<span style={{ color: '#D83420' }}>＊</span>
              </Typography>
              <TextBaseField
                value={storeGroupDetail.displayOrder}
                className={classes.shortTextField}
                onChange={handleChangeDisplayOrder}
                onBlur={handleChangeDisplayOrder}
                type="number"
                inputProps={{ min: '0' }}
                validator={validateFormInt1({ minFloat: 0.0, maxFloat: 999999999.0 })}
                convertor={convertFormNumber}
                errorCallback={errorCallback('displayOrder')}
              />
            </Grid>
            <Grid
              style={{ width: 120 }}
              className={classes.groupNameContainer}
            >
              <Typography
                className={`${common.title6} ${classes.adjustMargin}`}
              >
                基本補正<span style={{ color: '#D83420' }}>＊</span>
              </Typography>
              <TextBaseField
                value={storeGroupDetail.correction}
                className={classes.shortTextField}
                onChange={handleChangeCorrection}
                onBlur={handleChangeCorrection}
                type="number"
                inputProps={{ min: '0' }}
                validator={validateFormFloat1({
                  minFloat: -999.999,
                  maxFloat: 999.999,
                  precisionInt: 3,
                })}
                convertor={convertFormNumber}
                errorCallback={errorCallback('correction')}
              />
            </Grid>
          </Grid>
          <Grid className={classes.buttonContainer}>
            <Button
              startIcon={<AddIcon className={classes.startIcon} />}
              className={`${common.smallStrong} ${classes.addButton}`}
              onClick={handleOpenSelectStoreWindow}
            >
              追加
            </Button>
            <Button
              className={`${common.smallStrong} ${classes.resetButton}`}
              onClick={handleReset}
            >
              次に反響登録したい店舗をリセット
            </Button>
          </Grid>
          <EditStoreTable
            registeredStores={settings}
            nextResponse={nextResponse}
            nextRegist={nextRegist}
            setNextResponse={setNextResponse}
            deleteStore={deleteStore}
            areaCode={areaCode}
            storeChange={storeChange}
          />
        </>
      </CommonDialog>
      <SelectStoreWindow
        open={openSelectStoreWindow}
        setOpen={setOpenSelectStoreWindow}
        areaCode={areaCode}
        setNextRegsit={setNextRegsit}
      />
    </>
  );
}

export default EditStoreGroup;
