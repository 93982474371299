import { makeStyles } from '@material-ui/core';
import commonTheme from '../../../styles/theme';

const commonTreeStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    minHeight: '30%',
    overflow: 'auto',
  },
  parent: {
    position: 'relative',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
      padding: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 0,
      '&:not(:last-child)::after': {
        content: '""',
        height: 'calc(100% - 25px)',
        position: 'absolute',
        backgroundColor: '#c8c8c8',
        width: '1px',
        left: '7.5px',
        top: '25px',
      },
    },
  },
  child: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 0 0 20px',
    },
  },
  iconsPc: {
    fill: theme.palette.primaryColor,
    width: '16px',
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#ffffff',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  iconsSp: {
    fill: theme.palette.primaryColor,
    width: '24px',
    position: 'relative',
    order: 2,
    zIndex: 1,
    boxSizing: 'content-box',
    padding: '8px',
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  li: {
    padding: 0,
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
    // [commonTheme.breakpoints.up('md')]: {},
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  treeUser: {
    margin: 0,
    fontSize: '12px',
    borderRadius: 4,
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 4px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '8px 16px',
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  checkedUser: {
    background: 'linear-gradient(90deg, #A7F1FF 0%, #C2EEFF 100%)',
  },
  person: {
    [commonTheme.breakpoints.up('md')]: {
      marginLeft: '8px',
      '&::before': {
        content: '""',
        borderLeft: '1px #c8c8c8 solid',
        borderBottom: '1px #c8c8c8 solid',
        display: 'block',
        width: '15px',
        height: '23px',
        transform: 'translateY(-8px)',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
    },
  },
  checkbox: {
    marginLeft: 12,
  },
}));

export default commonTreeStyles;
