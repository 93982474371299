import { useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Calendar from '../../../calendar';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    marginLeft: 'auto',
    '& .MuiGrid-root': {
      border: 'none',
    },
  },
  flex: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    '& .MuiGrid-root': {
      border: 'none',
    },
  },
  item: {
    '& label': {
      width: '80%',
      '& > span': {
        fontSize: '14px',
      },
    },
    '& > .MuiGrid-container': {
      flexWrap: 'nowrap',
      padding: 0,
      '& > *:first-child': {
        flexShrink: 0,
        marginRight: 8,
      },
    },
  },
  header: {
    padding: '16px 16px 8px 16px',
    width: '100%',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    boxSizing: 'border-box',
    position: 'relative',
    textAlign: 'center',
  },
});

export default function CalendarWindow(props) {
  const { values } = props;
  const classes = useStyles();

  const setFromDate = (state, value) => {
    const { to } = state;
    const numberFromDate = Number(value.replace(/\//g, ''));
    const numberToDate = Number(to.replace(/\//g, ''));

    return numberFromDate < numberToDate
      ? { ...state, from: value }
      : { from: value, to: value };
  };

  const dataList = [
    {
      id: 1,
      data: {
        type: 'date',
        label: '',
        state: values.fromToDate.from,
        setState: (e) => { return values.setFromToDate((state) => setFromDate(state, e)); },
      },
    },
    {
      id: 2,
      data: {
        type: 'date',
        label: '',
        state: values.fromToDate.to,
        setState: (e) => { return values.setFromToDate((state) => ({ ...state, to: e })); },
        min: values.fromToDate.from.replace(/\//g, '-'),
      },
    },
  ];

  useEffect(() => {
    const { from, to } = values.fromToDate;
    values.setState(from, to);
    // setOpen(false);
  }, [values.fromToDate]);

  return (
    <Grid container className={classes.wrap}>
      {dataList.map((o) => (
        <Grid key={o.id} className={classes.flex}>
          <Grid className={classes.item}>
            <Calendar
              data={o.data}
            />
          </Grid>
          {o.id === 1 && (<span>～</span>)}
        </Grid>
      ))}
    </Grid>
  );
}
