import client from '../httpClient';

/**
 * 【webRes014】未処理反響一覧取得（担当社員ID指定）
 * http://10.22.2.48:8080/docs/#/responses/get-responses-list-userId
 * @param {{
 * limit: number;
 * offset: number;
 * }} params
 * @returns Promise<{responses: []}>
 */
const responseGetListByUserIdApi = async (params) => {
  const res = await client.get('/responses/list-by-user-id', { params });
  return res.data;
};

export default responseGetListByUserIdApi;
