import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import ClaimRegister from '../../claimRegister';
import InfoRegister from '../../infoRegister';
import InstructionsWindow from '../../taiouRireki/instructionsWindow';
import ChangeManage from '../../customer/changeManage';
import ContactToResponsiblePerson from '../../../pages/contactToResponsiblePerson/contactToResponsiblePerson';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 968,
    },
  },
  button: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      width: 118,
      color: theme.palette.primaryColor,
      background: '#fff',
      padding: '4px 0 6px',
      boxShadow: 'none',
      margin: '0 0 0 8px',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 187,
      color: '#fff',
      padding: '4px 0',
    },
    '&:not(:last-child)': {
      [commonTheme.breakpoints.up('md')]: { marginRight: 16 },
    },
    '&:hover': {
      [commonTheme.breakpoints.down('sm')]: {
        background: '#fff',
      },
    },
    '& span': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'block',
        color: theme.palette.primaryColor,
        margin: '0 0 -6px!important',
      },
    },
    '& .MuiButton-startIcon': {
      marginRight: 6,
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 14,
      },
    },
  },
}));

export default function CustomerDetailButtonIcon(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // 担当者へ連絡
  const [openContactToResponsiblePerson, setOpenContactToResponsiblePerson] = useState(false);

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // 担当変更・共有モーダル開閉用のローカルステート
  const [open2, setOpen2] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  const customerStatusSet11 = () => {
    // 処理なし
  };

  // 案内予約
  const [infoOpen, setInfoOpen] = useState(false);
  const handleInfoOpen = () => {
    setInfoOpen(true);
  };
  const closeInfo = () => {
    setInfoOpen(false);
  };

  // 指示登録
  const [instOpen, setInstOpen] = useState(false);
  const handleInstOpen = () => {
    setInstOpen(true);
  };
  const closeInst = () => {
    setInstOpen(false);
  };

  // 姓と名を連結
  const userName = useMemo(() => {
    return [input.detail.userLastName, input.detail.userFirstName].filter(Boolean).join('');
  }, [input.detail]);

  // changeManageで使用するユーザー情報
  const initialParam = {
    customerId: input.detail.customerId, // 顧客ID
    userId: input.detail.userId, // ユーザーID
    userName, // ユーザー名
    divisionSubName: input.detail.divisionSubName, // 組織名
  };

  // changeManageで【選択ボタン】を押した時の顧客情報更新処理
  const clickSelectUserFunc = (data) => {
    console.log('gotten data:', data);
    // 画面に表示
    input.dataInput.setCustomer(
      {
        ...input.detail,
        userId: data.userId,
        userName: data.userName,
        divisionSubName: data.divisionSubName,
      },
    );
    // 更新処理
    input.dataInput.setData(
      {
        ...input.data,
        userId: data.userId,
        divisionId: data.divisionId,
      },
    );
    input.dataInput.update(input.detail.customerId);
  };

  return (
    <Grid className={classes.root}>
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button} ${baseClasses.onlyPc}`}
        startIcon={<MailOutlineIcon />}
        onClick={handleInfoOpen}
      >
        案内予約
      </Button>
      <InfoRegister
        open={infoOpen}
        onOpen={handleInfoOpen}
        onClose={closeInfo}
        guidanceId={input.detail.guidanceScheduleId}
        customer={input.detail}
      />
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button} ${baseClasses.onlyPc}`}
        startIcon={<TouchAppOutlinedIcon />}
        onClick={handleInstOpen}
      >
        指示登録
      </Button>
      <InstructionsWindow
        open={instOpen}
        setOpen={setInstOpen}
        onClose={closeInst}
        directId=""
        newInstructionFlg
        customerId={input.detail.customerId}
        divisionId={input.detail.divisionId}
        userId={input.detail.userId}
        userFirstName={input.detail.userFirstName}
        userLastName={input.detail.userLastName}
      />
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button} ${baseClasses.onlyPc}`}
        startIcon={<SendOutlinedIcon />}
        onClick={() => { setOpenContactToResponsiblePerson(true); }}
      >
        担当者へ連絡
      </Button>
      <ContactToResponsiblePerson
        customerId={input.detail.customerId}
        open={openContactToResponsiblePerson}
        setOpen={setOpenContactToResponsiblePerson}
      />
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button}`}
        startIcon={<PeopleAltOutlinedIcon />}
        disabled={input.detail.isShared === 1} // 被共有顧客の場合、非活性
        onClick={() => { setOpen2(!open2); }}
      >
        担当者変更
      </Button>
      {open2 && (
        <ChangeManage
          initialParam={initialParam}
          onClickSelectButton={clickSelectUserFunc}
          open={open2}
          setOpen={setOpen2}
        />
      )}
      <Button
        variant="contained"
        className={`${baseClasses.buttonsPrimary} ${classes.button}`}
        startIcon={<FlashOnIcon />}
        onClick={handleOpen}
      >
        クレーム
      </Button>
      {open && (
        <ClaimRegister
          open={open}
          onClose={handleClose}
          id=""
          customerId={input.detail.customerId}
          setStatus={customerStatusSet11}
        />
      )}
    </Grid>
  );
}
