import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const simulatorGetJISCodeSlice = createSlice({
  name: 'simulatorGetJISCode',
  initialState: { simulatorGetJISCode: initialState },
  reducers: {
    simulatorGetJISCodeApi: (state, data) => {
      state.simulatorGetJISCode = data.payload;
    },
  },
});

export const { simulatorGetJISCodeApi } = simulatorGetJISCodeSlice.actions;

export default simulatorGetJISCodeSlice.reducer;
