import { useDispatch } from 'react-redux';
import { useStore } from '../../hooks';

import DivisionInformation from '../../containers/divisionInformation/divisionInformationContainer';
import { isOpen } from '../../store/common/apiMessageSlice';

import {
  ALL_MASTA_ADMIN,
  STORE_MOVE_MASTA_ADMIN,
  STORE_MOVE_MASTA_ADMIN_RESPONSIBLE_AREA_ONLY,
} from '../../constants/userRole';
import ApiAlertDialog from '../apiMessage';

export default function DivisionInformationPage() {
  const { responseHeader } = useStore();
  const dispatch = useDispatch();
  const { roleIds } = responseHeader;

  const isAccessible = roleIds.includes(ALL_MASTA_ADMIN)
    || roleIds.includes(STORE_MOVE_MASTA_ADMIN)
    || roleIds.includes(STORE_MOVE_MASTA_ADMIN_RESPONSIBLE_AREA_ONLY);

  if (isAccessible) {
    return (<DivisionInformation />);
  } else {
    const errorMsg = {
      status: 'error',
      message: '403 Forbidden',
      description: '',
      messageList: ['権限がありません。'],
    };
    dispatch(isOpen(errorMsg));
    return (<ApiAlertDialog />);
  }
}
