import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const webResUntreatAndUpdateStoreGroupSlice = createSlice({
  name: 'webResUntreatAndUpdateStoreGroup',
  initialState: { webResUntreatAndUpdateStoreGroup: initialState },
  reducers: {
    webResUntreatAndUpdateStoreGroupApi: (state, data) => {
      state.webResUntreatAndUpdateStoreGroup = data.payload;
    },
  },
});

export const {
  webResUntreatAndUpdateStoreGroupApi,
} = webResUntreatAndUpdateStoreGroupSlice.actions;

export default webResUntreatAndUpdateStoreGroupSlice.reducer;
