/* eslint-disable import/prefer-default-export */
import { getEnvVariable } from './getEnv';

export const getCookie = (key) => {
  const { cookie = '' } = document;
  const tmp = cookie.split('; ').find((row) => row.startsWith(key));
  if (!tmp) return '';
  return tmp.split('=')[1];
};

const getDomain = () => {
  const env = getEnvVariable('OMIT_ENV_NAME', 'local');
  return env === 'local'
    ? 'localhost'
    : `${getEnvVariable('DOMAIN')}.openhouse-group.com`;
};
const getDefaultLimit = () => {
  const tommorow = new Date();
  tommorow.setDate(tommorow.getDate() + 1);

  const limitTime = tommorow.getTime() - 1000 * 60 * 60 * 1;
  return new Date(limitTime);
};

export const setCookie = (
  key,
  value,
  expires = getDefaultLimit(),
  path = '/',
) => {
  const domain = getDomain();
  document.cookie = `${key}=${value}; domain=${domain}; path=${path}; expires=${expires};`;
};

export const deleteCookie = (key, path = '/') => {
  const { cookie = '' } = document;
  const cookieArray = cookie.split('; ');

  const domain = getDomain();
  cookieArray.some((c) => {
    if (!c.startsWith(key)) return false;
    document.cookie = `${key}=; domain=${domain}; path=${path}; max-age=0;`;
    return true;
  });
};
