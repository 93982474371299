import client from '../httpClient';

const schedulesPutGuidanceByGuidanceIdApi = (pathParameter, params) => {
  return client.put(`/guidances/${pathParameter}`, JSON.stringify(params), {
    isShowMessage: true,
    isShowLoading: false,
  });
};

export default schedulesPutGuidanceByGuidanceIdApi;
