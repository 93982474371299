import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import InstructionListTable from './instructionListTable';
import commonStyles from '../../styles';
import { useLoading } from '../../../hooks';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    padding: '24px 16px 0',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #8C8C8C',
    paddingRight: 30,
    marginRight: 24,
  },
  headerNum: {
    fontSize: '15px',
    lineHeight: '1.6',
    letterSpacing: '0',
    marginRight: 6,
  },
  headerNum1: {
    fontWeight: '700',
    marginRight: 4,
  },
  headerBtn: {
    padding: '8px 16px',
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      fontSize: '15px',
      fontWeight: '700',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
    '&.disabled': {
      pointerEvents: 'none',
      '&>span': {
        color: '#AFAFAF',
      },
    },
  },
  appBar: {
    color: '#333',
    background: 'none',
    width: 252,
    boxShadow: 'none',
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  tabs: {
    minHeight: 'auto',
  },
  tab: {
    width: '50%',
    minWidth: 'auto',
    minHeight: 'auto',
    padding: '8px 0',
    '&.Mui-selected': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
  },
  tabPanel: {
    padding: '0 16px',
    '& .MuiBox-root': {
      padding: 0,
    },
  },
}));

export default function InstructionListTabs(props) {
  const {
    setSelectTab,
    resetRender,
    setResetRender,
    deleteDirects,
    getNewRequest,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setRowPages,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const { addLoading, removeLoading, hasLoading } = useLoading();

  const [value, setValue] = useState(0);
  // 選択件数
  const [choice, setChoice] = useState(0);

  // 各行選択（削除）
  const [selected, setSelected] = useState([]);
  const list = useSelector((state) => state.directList.responseParams);
  const counts = useSelector((state) => state.directList.counts);

  const handleChange = (event, newValue) => {
    setPage(0);
    setValue(newValue);
    // 選択件数初期化
    setChoice(0);
    setSelected([]);
  };

  // 件数
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const label1 = `指示 (${count1})`;
  const label2 = `報告 (${count2})`;

  // ボタンの必須制御
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setCount1(counts.totalDirects);
    setCount2(counts.totalReports);

    // ボタンの必須制御
    if (choice === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  // 確認ウインドウ
  const dispatch = useDispatch();
  const handleClick1 = () => {
    console.log('CANCEL');
  };

  // 削除更新API
  // FIX_EGAWA: Loadingここで挟みたい await前後
  const handleClick2 = async () => {
    addLoading('instructionList');
    await deleteDirects(selected, setSelected);
    await setResetRender(resetRender + 1);
    setChoice(0);
    removeLoading('instructionList');
  };

  // 削除確認モーダル
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '選択項目を削除',
      msgList: ['選択中の指示を削除してもよろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: handleClick1,
          classes: common.buttonsSecondary,
        },
        {
          label: 'OK',
          set: handleClick2,
          classes: common.buttonsPrimary,
        },
      ],
    }));
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <Grid className={classes.headerBox}>
          <Typography className={classes.headerNum}>
            <span className={classes.headerNum1}>{choice}</span>
            <span className={classes.headerNum2}>件選択中</span>
            {/* <span>{String(selected.length)}</span> */}
          </Typography>
          <Button
            className={`${classes.headerBtn} ${disabled ? 'disabled' : ''}`}
            onClick={confirmOpen}
          >
            選択項目を削除
          </Button>
        </Grid>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            className={classes.tabs}
          >
            <Tab
              label={label1}
              {...a11yProps(0)}
              className={classes.tab}
              onClick={() => setSelectTab(1)}
            />
            <Tab
              label={label2}
              {...a11yProps(1)}
              className={classes.tab}
              onClick={() => setSelectTab(2)}
            />
          </Tabs>
        </AppBar>
      </Grid>
      <TabPanel value={value} index={0} className={`${classes.tabPanel} ${hasLoading('instructionList') ? common.loading : ''}`}>
        <InstructionListTable
          tab={0}
          id1={1}
          id2={2}
          list={list}
          count={count1}
          setChoice={setChoice}
          resetRender={resetRender}
          setResetRender={setResetRender}
          selected={selected}
          setSelected={setSelected}
          getNewRequest={getNewRequest}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setRowPages={setRowPages}
        />
      </TabPanel>
      <TabPanel value={value} index={1} className={`${classes.tabPanel} ${hasLoading('instructionList') ? common.loading : ''}`}>
        <InstructionListTable
          tab={1}
          id1={3}
          id2=""
          list={list}
          count={count2}
          setChoice={setChoice}
          resetRender={resetRender}
          setResetRender={setResetRender}
          selected={selected}
          setSelected={setSelected}
          getNewRequest={getNewRequest}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowPages={setRowPages}
        />
      </TabPanel>
    </Grid>
  );
}
