/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesDeleteGuidanceByGuidanceIdSlice = createSlice({
  name: 'schedulesDeleteGuidanceByGuidanceId',
  initialState: { schedulesDeleteGuidanceByGuidanceId: initialState },
  reducers: {
    deleteGuidanceByGuidanceIdApi: (state, data) => {
      state.schedulesDeleteGuidanceByGuidanceId = data.payload;
    },
  },
});

export const { deleteGuidanceByGuidanceIdApi } = schedulesDeleteGuidanceByGuidanceIdSlice.actions;

export default schedulesDeleteGuidanceByGuidanceIdSlice.reducer;
