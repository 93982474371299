import React from 'react';
import { FormGroup, makeStyles } from '@material-ui/core';
import CheckBoxParts from './parts/checkbox';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: 16,
    },
  },
});

function SortCheckBox() {
  const classes = useStyles();

  return (
    <FormGroup row className={classes.root}>
      <CheckBoxParts />
    </FormGroup>
  );
}
export default SortCheckBox;
