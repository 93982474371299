import { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  AccountTreeOutlined,
  ExpandMore,
} from '@material-ui/icons';
import { CustomerTreeContextProvider } from '../../../containers/common/customerTree/context';
import commonStyles from '../../styles';
import Wrapper from './customerTreeResponseWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  title: {
    background: '#f3f3f3',
    minHeight: 'auto !important',
    margin: '-8px -8px 0',
    padding: '0 16px 0 8px',
    height: 24,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      margin: '4px 0',
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0,
    },
  },
  icon: {
    width: '16px',
    marginRight: '4px',
  },
  arrow: {
    color: theme.palette.primaryColor,
    width: '16px',
  },
  details: {
    display: 'block',
    padding: 0,
    // paddingRight: 0,
    // paddingLeft: 0,
    // paddingBottom: 0,
  },
}));

const Content = memo((props) => {
  const { inModal } = props;
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const classes = useStyles();
  const common = commonStyles();

  return isSp || inModal ? <Wrapper /> : (
    <Accordion
      defaultExpanded
      className={classes.root}
    >
      <AccordionSummary
        className={classes.title}
        expandIcon={<ExpandMore className={classes.arrow} />}
      >
        <AccountTreeOutlined className={classes.icon} />
        <Typography className={common.title6}>反響ツリー</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Wrapper />
      </AccordionDetails>
    </Accordion>
  );
});

function CustomerTreeResponse(props) {
  const { inModal } = props;
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <CustomerTreeContextProvider {...props} isSp={isSp}>
      <Content inModal={inModal} />
    </CustomerTreeContextProvider>
  );
}

export default CustomerTreeResponse;
