import { useState, useEffect } from 'react';
import InstructionsWindowWrapper from '../../../../components/common/taiouRireki/instructionsWindow/instructionsWindow';
import directUpdateApi from '../../../../apis/direct/directUpdateApi';
import directRegistApi from '../../../../apis/direct/directRegistApi';
import { useLoading } from '../../../../hooks';
import { DIRECT_REGISTER } from '../../../../constants/loading';

function InstructionsWindowContainers(props) {
  const {
    open,
    setOpen,
    onClose,
    direct,
    newInstructionFlg,
    getDetailDirect,
    getDirects,
    customer,
    isSp,
    updatePage,
    customerId,
    onSubmit,
  } = props;

  const { addLoading, removeLoading } = useLoading();

  /**
   * @module updateDirect  - 【direct003】指示登録 or 【direct004】指示更新
   * @param {integer} directId - 更新対象の指示ID（directId）
   * @param {params} params - 登録・更新対象の値（編集後の下記editStateの値）
   */
  const updateDirect = async (directId, params) => {
    // 登録
    addLoading(DIRECT_REGISTER);
    if (newInstructionFlg) {
      await directRegistApi(params)
        .then(async (res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    // 更新
      await directUpdateApi(directId, params)
        .then(async (res) => {
          console.log(res);
          getDirects ? await getDirects() : await getDetailDirect(directId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    removeLoading(DIRECT_REGISTER);
  };

  /**
   * @module getArrOthers - 【direct002】のres.othersから、【direct004】のothersの形を成形
   * @returns {arr} - [{userId: int, divisionId: int}]
   */
  const getArrOthers = () => {
    const resultOthers = [];
    if (direct.others?.length > 0) {
      if (direct.others[0].userId) {
        for (let i = 0; direct.others.length > i; i += 1) {
          resultOthers.push({
            userId: direct.others[i].userId,
            divisionId: direct.others[i].divisionId,
          });
        }
      }
    }
    return resultOthers;
  };

  const initailEditState = {
    isManager: direct.isManager,
    isCenterManager: direct.isCenterManager,
    reportCode:
      direct.timelines.length > 0 ? direct.timelines[0].reportCode : 0,
    directCode: direct.directCode,
    isDirectMail: 1, // 「する（1）」で決め打ち
    isReportMail: 1, // 「する（1）」で決め打ち
    approveCode: direct.approveCode,
    isRemind: 1, // 「する（1）」で決め打ち
    customerId: direct.customerId,
    directedUserId: direct.directedUserId,
    directedDivisionId: direct.directedDivisionId,
    expireAt: direct.expireAt,
    directReportStatusCode: direct.directReportStatusCode,
    importantCode: direct.importantCode,
    content: direct.timelines.length > 0
      ? direct.timelines[0].content : null,
    others: getArrOthers(),
  };

  // 【direct004】指示更新のパラメーター
  const [editState, setEditState] = useState(initailEditState);

  // 【direct004】指示更新のパラメーター初期化
  const restoreEditState = () => setEditState(initailEditState);

  useEffect(() => {
    if (open && newInstructionFlg) {
      restoreEditState();
    }
  }, [open, newInstructionFlg]);

  return (
    <InstructionsWindowWrapper
      detail={direct}
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      directId={direct.directId}
      editState={editState}
      setEditState={setEditState}
      restoreEditState={restoreEditState}
      newInstructionFlg={newInstructionFlg}
      updateDirect={updateDirect}
      customer={customer}
      isSp={isSp}
      updatePage={updatePage}
      customerId={customerId}
      onSubmit={onSubmit}
    />
  );
}

export default InstructionsWindowContainers;
