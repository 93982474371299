import React from 'react';
import {
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import commonStyles from '../../styles';
import useStyles from './buttonGroupStyles';

/**
 * @typedef {{
 *   label: string;
 *   disabled?: boolean;
 *   hidden?: boolean;
 *   onClick?: (e: Event);
 * }} ButtonGroupItemType
 * @param {{
 *   data: {label: string; items: ButtonGroupItemType[]};
 *   onItemClick?: (item: ButtonGroupItemType, e: Event) => void;
 * }} props
 * @returns
 */
function ButtonGroup(props) {
  const {
    data = {},
    onItemClick,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const items = data.items?.map((item) => {
    return item.hidden
      ? null
      : (
        <Button
          className={classes.button}
          key={item.label}
          disabled={item.disabled}
          onClick={(e) => {
            item.onClick?.(e);
            onItemClick?.(item, e);
          }}
        >
          <Typography className={classes.text}>{ item.label }</Typography>
        </Button>
      );
  });

  return (
    <Grid className={classes.section}>
      <Typography className={`${baseClasses.title6} ${classes.label}`}>
        { Array.isArray(data.label)
          ? data.label.map((t, i) => <span key={String(i)} className={classes.block}>{ t }</span>)
          : data.label }
      </Typography>
      { items }
    </Grid>
  );
}

export default ButtonGroup;
