import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import actCreateCustomHistoryApi from '../../../../apis/act/actCreateCustomHistoryApi';
import actPutHistoryApi from '../../../../apis/act/actPutHistoryApi';
import actDeleteHistoryApi from '../../../../apis/act/actDeleteHistoryApi';
import customerCallApi from '../../../../apis/customer/customerApi';
import TaiouRirekiList from '../../../../components/common/taiouRireki/listWindow/listWindow';
import { actCreateCustomHistory } from '../../../../store/act/actCreateCustomHistorySlice';
import { actPutHistory } from '../../../../store/act/actPutHistorySlice';
import { actDeleteHistory } from '../../../../store/act/actDeleteHistorySlice';
import { useRerender } from '../../../../hooks';
import sendmailAttachmentsDownloadApi from '../../../../apis/sendmail/sendmailAttachmentsDownloadApi';
import { downloadZip } from '../../../../commonFunction';

function ListWindowContainers(props) {
  const {
    open,
    onClose,
    customerId,
    customer,
  } = props;

  // 対応履歴の検索条件を初期化
  const { rerender: rerenderSearch, rerenderKey: rerenderKeySearch } = useRerender();

  const dispatch = useDispatch();

  // 対応履歴取得
  const getActionHistoryList = async (id, parmObj) => {
    await actCreateCustomHistoryApi(id, parmObj)
      .then(async (res) => {
        await dispatch(actCreateCustomHistory(res.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [search, setSearch] = useState({
    offset: 0,
    limit: 20,
  });

  // 対応履歴情報更新
  const updateHistory = async (cid, id, parmObj) => {
    await actPutHistoryApi(id, parmObj)
      .then(async (res) => {
        await dispatch(actPutHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, search);
        // 対応履歴の検索条件を初期化
        rerenderSearch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 対応履歴情報削除
  const deleteHistory = async (cid, id) => {
    await actDeleteHistoryApi(id)
      .then(async (res) => {
        await dispatch(actDeleteHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, search);
        // 対応履歴の検索条件を初期化
        rerenderSearch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 添付ファイルダウンロード
  const downloadAttachment = async (actionId) => {
    await sendmailAttachmentsDownloadApi(actionId)
      .then((f) => downloadZip(f))
      .catch((err) => console.error(err));
  };

  const [customerObj, setCustomerObj] = useState(customer || {});
  const getCustomer = () => {
    customerCallApi([customerId])
      .then((res) => setCustomerObj(res.data.customers[0]))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!open) setCustomerObj({});
    if (open && (!customer || customerObj === {})) getCustomer();
  }, [open, customerId]);

  return (
    <TaiouRirekiList
      getActionHistoryList={getActionHistoryList}
      updateHistory={updateHistory}
      deleteHistory={deleteHistory}
      downloadAttachment={downloadAttachment}
      search={search}
      setSearch={setSearch}
      customerId={customerId}
      customer={customerObj}
      open={open}
      onClose={onClose}
      rerenderKeySearch={rerenderKeySearch}
      rerenderSearch={rerenderSearch}
    />
  );
}

export default ListWindowContainers;
