import { Grid, Typography, makeStyles } from '@material-ui/core';
import commonStyles from '../../styles';
import UserTree from '../../common/userTreeNew';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  orgTitleClass: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [commonTheme.breakpoints.up('md')]: {
      padding: '10px 5px',
      marginTop: '1%',
    },
    '&.isType1': {
      [commonTheme.breakpoints.up('md')]: {
        height: '100%',
      },
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tree: {
    width: '100%',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
  },
});

export default function LibraryShareComponent(props) {
  const { selectUser, setSelectUser, selectDivision, setSelectDivision } = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    <Grid container className={classes.orgTitleClass}>
      <Grid className={classes.content}>
        <Typography className={common.title2}>
          {selectUser.length}件選択中
        </Typography>
        <Typography className={common.bass}>
          選択したテンプレを共有する相手を選択してください。
        </Typography>
      </Grid>
      <Grid className={classes.tree}>
        <UserTree
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          isMulti
        />
      </Grid>
    </Grid>
  );
}
