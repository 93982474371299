import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  List,
  ListItem,
} from '@material-ui/core';
import commonTheme from '../../styles/theme';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import SpEditDialog from './spEditDialog';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    marginBottom: 6,
    padding: '6px 8px',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      display: 'block',
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& label': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      transform: 'translate(0, 4px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -5px) scale(0.75)',
      },
    },
    '& div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  txt: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
    '& input': {
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      padding: 0,
      [commonTheme.breakpoints.down('sm')]: {
        lineHeight: 1.6,
        height: 20,
      },
    },
    '& label': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
    },
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    margin: '14px 0 4px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'block',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    display: 'block',
    textTransform: 'none',
    marginBottom: 6,
  },
  listVal: {
    display: 'block',
    textTransform: 'none',
    width: '100%',
    pointerEvents: 'none',
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.2,
      fontWeight: '700',
      letterSpacing: 0,
    },
    '& div': {
      width: '100%',
      padding: 0,
    },
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
});

function BasicTextFields(props) {
  const { input } = props;
  const { current, update, original, isSp, editTargetRef } = useUpdate(input.customer, [
    'customerId',
    'summary',
  ]);

  const classes = useStyles();

  let contents;

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    if (isSp) return;
    const { name, value } = e.target;
    const newData = { [name]: value };
    // 更新処理
    isChanged(newData, original) && update(newData);
  };

  // SP 決定押下時に更新
  const onDecision = (value) => {
    if (original.summary !== value) {
      update({ summary: value });
    }
  };

  // モーダル用
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = (e, target) => {
    editTargetRef.current = target;
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  // SP 表示用
  const list = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '概要メモ',
      val: current.summary,
      keys: 'summary',
      click: handleOpen1,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <SpEditDialog
          title={editTargetRef.current.lbl}
          open={open1}
          onClose={handleClose1}
          input={editTargetRef.current.val}
          onSubmit={onDecision}
          multiline
          keys="summary"
          originalValue1={original.summary}
        />
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root} noValidate autoComplete="off">
        <TextBaseField
          className={classes.txt}
          defaultValue={current.summary}
          name="summary"
          onBlur={onBlur}
          placeholder="概要メモ"
        />
      </Grid>
    );
  }

  return contents;
}

export default React.memo(BasicTextFields);
