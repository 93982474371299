import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SubmitButton from './parts/submitButton';
import ResetButton from './parts/resetButton';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    [commonTheme.breakpoints.down('sm')]: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      padding: '24px 0',
      borderTop: '1px solid #C8C8C8',
      width: '84%',
      justifyContent: 'center',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
    '& button': {
      [commonTheme.breakpoints.down('sm')]: {
        margin: '0 12px',
      },
    },
  },
});

function SortButtons(props) {
  const {
    handleCloseSearch,
  } = props;

  const classes = useStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  if (isSP) {
    contents = (
      <Box className={classes.root}>
        <ResetButton txt="クリア" />
        <SubmitButton txt="絞り込み" handleCloseSearch={handleCloseSearch} />
      </Box>
    );
  } else {
    contents = (
      <Box className={classes.root}>
        <ResetButton txt="リセット" />
        <SubmitButton txt="検索" handleCloseSearch={handleCloseSearch} />
      </Box>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default SortButtons;
