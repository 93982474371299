import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import commonStyles from '../../styles';
import Calendar from '../../common/calendar';
import Modal from '../../common/modal';

const useStyles = makeStyles({
  button: {
    '&::before, &::after': {
      background: '#fff',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& label': { width: '100%' },
    '& > .MuiGrid-root': { background: '#fff' },
  },
});

function ChangeScheduledTimeToSend(props) {
  const {
    openDialog,
    setOpenDialog,
    sendTime,
    setSendTime,
    targetRow,
    functions,
    options,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  /**
   * @module postSendMail - 【sendmail006】未送信メール更新
   * @param {Object} targetRow - 選択された対象メールの全内容
   * @param {date} deliveryAt - 今すぐ送信の為、現在時刻
   */
  const onClickHandler = async () => {
    setOpenDialog(false);
    const time = sendTime.deliveryAt.replaceAll('-', '/').replaceAll('T', ' ');
    await functions
      .updateUnSendMail({
        mailQueueId: targetRow.mailQueueId,
        deliveryAt: time,
      }, options?.current)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calendarOptions = {
    require: false,
    type: 'time',
    state: [sendTime.deliveryAt],
    setState: (val) => {
      return setSendTime({ ...sendTime, deliveryAt: val });
    },
    fromMail: true,
  };

  return (
    <Modal
      open={openDialog}
      onClose={() => { setOpenDialog(false); }}
      title="送信時間の変更"
      width={360}
      buttons={[{
        onClick: () => { setOpenDialog(false); },
        label: 'CANCEL',
        className: `${baseClasses.buttonsSecondary} ${classes.button}`,
      }, {
        className: `${baseClasses.buttonsPrimary} ${classes.button}`,
        onClick: onClickHandler,
        label: 'OK',
      }]}
    >
      <Grid className={classes.root}>
        <Calendar
          data={calendarOptions}
        />
      </Grid>
    </Modal>
  );
}

export default ChangeScheduledTimeToSend;
