import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, Grid, makeStyles, Typography } from '@material-ui/core';
import { ArrowUpward, Cancel } from '@material-ui/icons';
import commonStyles from '../../styles';
import Calendar from '../../common/calendar';

import { isOpen } from '../../../store/common/apiMessageSlice';

import { validateFormFile } from '../../../commonFunction/validations';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '24px',
    marginBottom: '8px',
  },
  leftBox: { width: '190px' },
  dropzone: {
    background: '#F3F3F3',
    border: '1px dashed #8C8C8C',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '143px',
    width: 'calc(100% - 287px)',
    position: 'relative',
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1.4,
    textAlign: 'center',
    '& span': {
      color: theme.palette.primaryColor,
      cursor: 'pointer',
    },
  },
  arrow: {
    color: '#F3F3F3',
    background: '#8C8C8C',
    borderRadius: '50%',
    border: '8px #8C8C8C solid',
    width: 40,
    height: 40,
  },
  list: {
    padding: '8px 0',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  cancel: {
    color: theme.palette.primaryColor,
    width: '16px',
    marginLeft: '8px',
    flexShrink: 0,
  },
  strage: {
    color: theme.palette.primaryColor,
    margin: '8px auto 0',
    display: 'block',
  },
  checkbox: { padding: 0 },
  download: {
    border: '1px #c8c8c8 solid',
    padding: '8px',
    alignItems: 'center',
  },
  mt: { marginTop: '16px' },
  inputArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    '&::-webkit-file-upload-button': {
      width: '100%',
      height: '100%',
    },
  },
}));
function AddFilesMain(props) {
  const dispatch = useDispatch();

  const { registerFiles } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [uploads, setUploads] = useState([]);

  const now = new Date();
  now.setDate(now.getDate() + 30);
  const defaultDate = now.getFullYear() + '/' + (now.getMonth() + 1) + '/' + now.getDate();
  const [date, setDate] = useState({ limitAt: defaultDate });

  const [isNotification, setIsNotification] = useState(1);

  const onChangeHandler = (ev) => {
    if (!isNotification) {
      setIsNotification(ev.target.value);
      return '';
    }
    setIsNotification(false);
    return '';
  };

  /**
   * @module uploadFile - ファイルアップロード（追加ボタン押下時event）
   * @param {array} uploads - [FileData, FileData...etc]
   * @param {da5te} limitAt - YY/mm/dd
   * @param {boolean} isNotification - true or false
   */
  const uploadFile = async () => {
    const parseLimitAt = () => {
      const before = new Date(date.limitAt);
      const Y = before.getFullYear();
      const M = before.getMonth() + 1 < 10 ? `0${before.getMonth() + 1}` : before.getMonth() + 1;
      const D = before.getDate() < 10 ? `0${before.getDate()}` : before.getDate();
      return `${Y}/${M}/${D}`;
    };

    // Request API - src\apis\filestorage\filestorageResisterApi.js
    await registerFiles(uploads, parseLimitAt(), isNotification ? 1 : 0);
    setUploads([]);
  };

  const onUpload = (e) => {
    let errors = [];
    const addedFileArray = [];
    const validate = validateFormFile({ maxLengthFilaName: 112 });
    for (let i = 0; i < e.target.files.length; i += 1) {
      const f = e.target.files[i];
      const tmpErrors = validate(f);
      if (!tmpErrors.length) addedFileArray.push(f);
      errors = [...errors, ...tmpErrors];
    }
    if (addedFileArray.length !== 0) setUploads((prev) => ([...prev, ...addedFileArray]));

    const errorsSet = new Set(errors);
    if (errors.length) {
      dispatch(isOpen({
        message: '入力エラー',
        messageList: [...errorsSet],
        status: 'warning',
      }));
    }
  };

  return (
    <Grid container className={classes.box}>
      <Typography className={common.small}>
        「 DL通知」は変更が即時反映され、ファイルがダウンロードされるたびに「追加者」へメールで通知いたします。<br />
        一斉メールへの添付の際は通知数が多いため、OFFをおすすめします。
      </Typography>
      <Grid container justifyContent="space-between" className={classes.mt}>
        <Grid container direction="column" className={classes.leftBox} justifyContent="space-between">
          <Calendar
            data={{
              type: 'date',
              label: '削除日',
              state: date.limitAt,
              setState: (e) => { return setDate({ limitAt: e }); },
            }}
          />
          <Grid className={classes.download}>
            <Typography className={common.title6}>
              ダウンロード（DL）通知
              <Checkbox
                className={classes.checkbox}
                color="primary"
                checked={isNotification}
                value="test"
                onChange={(ev) => { onChangeHandler(ev); }}
              />
            </Typography>
          </Grid>
          <Button
            className={common.buttonsPrimary}
            disabled={uploads.length < 1}
            onClick={uploadFile}
          >
            追加
          </Button>
        </Grid>
        <Grid container className={classes.dropzone}>
          <input type="file" multiple onChange={onUpload} className={classes.inputArea} />
          <ArrowUpward className={classes.arrow} />
          <p className={classes.txt}>添付ファイルを<br />ここにドラック&ドロップ<br />または<span>ファイル選択</span></p>
        </Grid>
      </Grid>
      <div className={classes.list}>
        {uploads.map(file => (
          <span className={classes.li}>
            <Typography className={common.small}>{file.name}</Typography>
            <Cancel
              className={classes.cancel}
              onClick={() => {
                let deletelist = uploads;
                deletelist = deletelist.filter((f) => {
                  return f.name !== file.name;
                });
                setUploads(deletelist);
              }}
            />
          </span>
        ))}
      </div>
    </Grid>
  );
}
export default function AddFiles(props) {
  const { registerFiles } = props;
  return <AddFilesMain registerFiles={registerFiles} />;
}
