import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  thead: {
    position: 'sticky',
    zIndex: '3',
    top: 0,
    background: '#F3F3F3',
  },
  th: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: 0,
    textAlign: 'left',
    borderRight: '1px solid #C8C8C8',
    color: '#333',
    width: 120,
    verticalAlign: 'top',
    '&:first-child': {
      padding: 0,
      width: 130,
      position: 'sticky',
      zIndex: '1',
      left: 0,
      background: '#F3F3F3',
      '& > span': {
        display: 'block',
        borderRight: '2px solid #8C8C8C',
        borderBottom: '2px solid #8C8C8C',
        boxSizing: 'border-box',
        padding: '8px 16px',
        height: '100%',
      },
    },
    '&:not(:first-child) > span': {
      display: 'block',
      height: '100%',
      borderBottom: '2px solid #8C8C8C',
      boxSizing: 'border-box',
      '& > span': {
        display: 'block',
        textAlign: 'center',
        padding: 8,
        whiteSpace: 'break-spaces',
        '&:first-child': {
          borderBottom: '1px solid #C8C8C8',
        },
      },
    },
  },
});

export default function AllocationSettingHead(props) {
  const { headerRow } = props;

  const classes = useStyles();

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell className={classes.th}>
          <span>基本補正</span>
        </TableCell>
        {headerRow.map((headerCell) => {
          const correction = Number(headerCell.correction);
          return (
            <TableCell key={headerCell.storeGroupId} className={classes.th}>
              <span>
                <span>{(Math.round(correction * 10)) / 10}</span>
                <span>{headerCell.storeGroupName}</span>
              </span>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
