import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const sendmailCancelSlice = createSlice({
  name: 'sendmailCancel',
  initialState: { sendmailCancel: initialState },
  reducers: {
    sendmailCancelApi: (state, data) => {
      state.sendmailCancel = data.payload;
    },
  },
});

export const { sendmailCancelApi } = sendmailCancelSlice.actions;

export default sendmailCancelSlice.reducer;
