import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesGetWeekActionPlanSlice = createSlice({
  name: 'schedulesGetWeekActionPlan',
  initialState: { schedulesGetWeekActionPlan: initialState },
  reducers: {
    schedulesGetWeekActionPlanApi: (state, data) => {
      state.schedulesGetWeekActionPlan = data.payload;
    },
  },
});

export const { schedulesGetWeekActionPlanApi } = schedulesGetWeekActionPlanSlice.actions;

export default schedulesGetWeekActionPlanSlice.reducer;
