import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import commonTheme from '../styles/theme';
import Aside from './aside';
import Header from './header';
import Main from './main';
import MainSP from './mainSp';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
    [commonTheme.breakpoints.up('md')]: {
      // width: 1920,
      minWidth: 1920,
      margin: '0 auto',
      background: '#fff',
      overflow: 'hidden',
      height: 'calc(100vh - 57px)',
      borderBottom: '1px #c8c8c8 solid',
    },
  },
  spRoot: {
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  main: {
    display: 'flex',
  },
  contents: {
    flex: 1,
  },
  contentBody: {
    flex: '1 1 auto',
    paddingLeft: '16px',
    [commonTheme.breakpoints.up('md')]: {
      // width: 1234,
      height: 'calc(100vh - 117px)',
      overflow: 'hidden',
    },
    [commonTheme.breakpoints.down('sm')]: { padding: 16 },
    '& .MuiTableContainer-root': {
      [commonTheme.breakpoints.up('md')]: {
        overflowX: 'unset',
      },
    },
  },
});

function CustomerMain() {
  const classes = useStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <Grid className={classes.root}>
      {isSp ? (
        <Grid container className={classes.spRoot}>
          <Header isSp />
          <MainSP isSp />
        </Grid>
      ) : (
        <Grid className={classes.main}>
          <Aside />
          <Grid className={classes.contents}>
            <Header />
            <Grid className={classes.contentBody}>
              <Main />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default CustomerMain;
