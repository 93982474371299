import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormGroup,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import SimulatorCheckBox from './simulatorCheckBox';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: 8,
    marginBottom: 24,
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  btn1: {
    display: 'block',
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '10px 22px 6px',
    margin: '0 4px',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  btn2: {
    display: 'block',
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '6px 10px 4px',
    margin: '8px auto 0',
    minWidth: 'auto',
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'unset',
    '& .MuiFormControlLabel-root': {
      width: 'calc(100% / 7)',
      margin: '0 0 8px 0',
    },
  },
}));

function SimulatorContent(props) {
  const { val, judgementStart, areaCode } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // ステート
  const [checkedCities, setCheckedCities] = useState([]);
  const [all, setAll] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [count, setCount] = useState(0);

  // 全件数を取得
  const total = val.length;

  // すべて選択 すべて選択解除
  const allCheck = () => {
    setAll(1);
    setCount(total);
    setCheckedCities(val.map(data => { return data.areaId; }));
  };
  const allLift = () => {
    setAll(2);
    setCount(0);
    setCheckedCities([]);
  };
  const allBlur = () => {
    setAll(0);
  };

  const clickCheck = (checkedJisCode) => {
    if (!checkedCities.includes(checkedJisCode)) {
      setCheckedCities([...checkedCities, checkedJisCode]);
    } else {
      setCheckedCities(
        checkedCities.filter((checkedCity) => checkedCity !== checkedJisCode),
      );
    }
  };

  const clickJudgement = () => {
    const infos = checkedCities.map((checkedJisCode) => { return { areaCode, checkedJisCode }; });
    const param = {
      info: infos,
    };
    judgementStart(param);
  };

  useEffect(() => {
    if (count > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  return (
    <Grid className={classes.root}>
      <Grid className={classes.btnWrap}>
        <Button className={classes.btn1} onClick={allCheck} onBlur={allBlur}>
          すべて選択
        </Button>
        <Button className={classes.btn1} onClick={allLift} onBlur={allBlur}>
          すべて選択解除
        </Button>
      </Grid>
      <FormGroup className={classes.formGroup}>
        {val.map((data) => (
          <SimulatorCheckBox
            key={data.areaId}
            areaId={data.areaId}
            label={data.cityName}
            initial={false}
            all={all}
            count={count}
            setCount={setCount}
            clickCheck={clickCheck}
          />
        ))}
      </FormGroup>
      <Button
        className={`${baseClasses.buttonsPrimary} ${classes.btn2} ${
          disabled && 'disabled'
        }`}
        onClick={clickJudgement}
      >
        判定
      </Button>
    </Grid>
  );
}

export default SimulatorContent;
