/* eslint-disable import/prefer-default-export */
import Amplify, { Auth } from 'aws-amplify';

import store from '../store';
import { setAccessToken, clearAccessToken } from '../store/common/accessToken';

import { getEnvVariable } from './getEnv';
import { removeDailyStorage } from './storage';

const getCongnitoConfig = () => {
  const domain = getEnvVariable('DOMAIN') === 'ohg-sfa-stg2'
    ? 'ohg-sfa-stg'
    : getEnvVariable('DOMAIN');
  const userPoolWebClientId = getEnvVariable('USER_POOL_CLIENT_ID');
  const userPoolId = getEnvVariable('USER_POOL_ID');
  const redirectURL = getEnvVariable('REDIRECT_URL');

  return {
    Auth: {
      region: 'ap-northeast-1',
      userPoolId,
      userPoolWebClientId,
      oauth: {
        domain: `auth.${domain}.openhouse-group.com`,
        scope: ['openid'],
        redirectSignIn: redirectURL,
        redirectSignOut: `https://${getEnvVariable('DOMAIN')}.openhouse-group.com/`,
        responseType: 'code',
      },
    },
  };
};

Amplify.configure(getCongnitoConfig());

const clearSetting = () => {
  store.dispatch(clearAccessToken());
  removeDailyStorage();
};

export const signOutProcess = async (callBack) => {
  // NOTE: ログイン状態でない場合にAuth.signOut()がエラーを吐くので、エラーハンドリングする
  try {
    // NOTE: おそらくローカルストレージからログイン状態なのかを判断
    await Auth.currentAuthenticatedUser();
    await Auth.signOut();
  } catch (e) {
    console.error(e);
  } finally {
    clearSetting();
    if (callBack) callBack();
  }
};

export const signInProcess = async (successCallBack, failureCallback) => {
  try {
    const res = await Auth.currentSession();
    const accessToken = res.getIdToken().getJwtToken();
    store.dispatch(setAccessToken(accessToken));
    if (successCallBack) successCallBack(res);
  } catch (e) {
    console.error(e);
    await signOutProcess();
    if (failureCallback) failureCallback(e);
  }
};
