import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {
  Drive,
} from '@trejgun/material-ui-icons-google';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  button: {
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    cursor: 'pointer',
    '&.disabled': {
      background: '#DEDEDE',
      pointerEvents: 'none',
      color: '#AFAFAF',
      '& polygon': {
        fill: '#AFAFAF',
      },
    },
  },
  icon: {
    fontSize: 18,
    marginRight: 4,
  },
}));

function CustomerDrive(props) {
  const classes = useStyles();

  const { input } = props;

  return (
    <Grid className={classes.root}>
      <Link
        className={`${classes.button} ${!input.customer.driveUrl1 && 'disabled'}`}
        href={input.customer.driveUrl1}
        target="_blank"
      >
        <Drive className={classes.icon} />
        ドライブ①
      </Link>
      <Link
        className={`${classes.button} ${!input.customer.driveUrl2 && 'disabled'}`}
        href={input.customer.driveUrl2}
        target="_blank"
      >
        <Drive className={classes.icon} />
        ドライブ②
      </Link>
      <Link
        className={`${classes.button} ${!input.customer.driveUrl3 && 'disabled'}`}
        href={input.customer.driveUrl3}
        target="_blank"
      >
        <Drive className={classes.icon} />
        ドライブ③
      </Link>
    </Grid>
  );
}

export default React.memo(CustomerDrive);
