import {
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  tr: {
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  td: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '160%',
    padding: '6px 4px',
    borderBottom: '1px solid #C8C8C8',
    '&.disabled label': {
      background: '#DEDEDE',
      pointerEvents: 'none',
    },
    '&:nth-last-child(-n+2)': {
      textAlign: 'center',
    },
  },
});

export default function DivisionHistoryRow(props) {
  const { row, index } = props;
  const classes = useStyles();

  return (
    <TableRow
      tabIndex={-1}
      key={row.userId}
      className={classes.tr}
    >
      <TableCell className={classes.td}>
        {index + 1}
      </TableCell>
      <TableCell className={classes.td}>
        {row.applyingAt.replace(/-/g, '/').substr(0, 11) || ''}
      </TableCell>
      <TableCell className={classes.td}>
        {row.userId}
      </TableCell>
      <TableCell className={classes.td}>
        {row.userFullName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.oldPositionName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.newPositionName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.oldGradeName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.newGradeName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.oldUserRoleGroupName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.newUserRoleGroupName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.mainDivisionName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.oldDivisionName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.newDivisionName}
      </TableCell>
      <TableCell className={classes.td}>
        {row.isNewManager === 1 ? '◯' : '-'}
      </TableCell>
      <TableCell className={classes.td}>
        {row.isCustomerMove === 1 ? '◯' : '-'}
      </TableCell>
    </TableRow>
  );
}
