import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../styles';
import { DIVISION_TYPE } from '../../constants';
import modifyObject from '../../commonFunction/modifyObject';
import { validateFormString1 } from '../../commonFunction/validations';

const useStyles = makeStyles({
  idWrapper: {
    marginBottom: 8,
    padding: '8px 9px',
    alignItems: 'center',
    background: 'rgba(51, 51, 51, 0.04)',
  },
  label: {
    marginRight: 16,
  },
  id: {
    fontSize: 13,
    fontWeight: 700,
  },
});

function Division(props) {
  const { division, setDivision, getComponent } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();

  // 初期値が設定されている場合、YYYY/MM/dd形式に変換 (ex: 2022/11/23)
  const dateFormatter = (value) => {
    let newDate = value;
    if (value) {
      value = new Date(value);
      const year = value.getFullYear();
      const month = ('00' + (value.getMonth() + 1)).slice(-2);
      const day = ('00' + value.getDate()).slice(-2);
      newDate = `${year}/${month}/${day}`;
    }
    return newDate;
  };

  const handleChange = (e, data) => {
    const val = e.target ? e.target.value : e;
    switch (data.key) {
      case 'divisionTypeCode':
        setDivision({ ...division, divisionTypeCode: Number(val) });
        break;
      case 'divisionGroupName':
        setDivision({ ...division, divisionGroupName: String(val) });
        break;
      case 'divisionSubName':
        setDivision({ ...division, divisionSubName: String(val) });
        break;
      default:
        break;
    }
  };

  const setDate = (firstDate, endDate) => {
    if (firstDate.indexOf('-') || endDate.indexOf('-')) {
      firstDate = firstDate.replaceAll('-', '/');
      endDate = endDate.replaceAll('-', '/');
    }
    setDivision({ ...division, startAt: firstDate, endAt: endDate });
  };

  const getItems = () => {
    const itemsArray = [
      {
        label: '組織分類',
        require: true,
        type: 'select',
        obj: modifyObject(DIVISION_TYPE, '組織分類'),
        key: 'divisionTypeCode',
        state: 'divisionTypeCode' in division ? division.divisionTypeCode : '',
        set: handleChange,
      },
      {
        label: '組織グループ名',
        require: false,
        type: 'str',
        key: 'divisionGroupName',
        state: 'divisionGroupName' in division ? division.divisionGroupName : '',
        set: handleChange,
        validator: validateFormString1({ maxLengthInt: 20 }),
      },
      {
        label: '組織名',
        require: true,
        type: 'str',
        key: 'divisionSubName',
        state: 'divisionSubName' in division ? division.divisionSubName : '',
        set: handleChange,
        validator: validateFormString1({ maxLengthInt: 20 }),
      },
      {
        id: 'divisionEditDate',
        label: '組織の有効期間',
        require: true,
        type: 'multiple',
        key: 'divisionPeriod',
        state: {
          startAt: dateFormatter(division.startAt),
          endAt: dateFormatter(division.endAt),
        },
        setState: setDate,
      },
    ];
    return itemsArray;
  };

  return (
    <>
      <Typography style={{ marginBottom: 10 }} className={baseClasses.title3}>組織</Typography>
      <Grid style={{ marginBottom: 30 }}>
        <Grid container className={classes.idWrapper}>
          <span className={`${baseClasses.title6} ${classes.label}`}>組織ID</span>
          <Typography className={classes.id}>{division.divisionId ? division.divisionId : '-'}</Typography>
        </Grid>
        {getItems().map((data) => (
          getComponent(data)
        ))}
      </Grid>
    </>
  );
}
export default Division;
