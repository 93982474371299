import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InstructionListWrapper from '../../components/instructionList/instructionList';
import directGetListApi from '../../apis/direct/directGetListApi';
import directDeleteApi from '../../apis/direct/directDeleteApi';
import store from '../../store';
import { getDirectList, setNotDirects } from '../../store/direct/directGetListSlice';

import { debounce } from '../../commonFunction';

// Note: コンポーネント内で定義したかったが、何ならかの理由で関数が再定義されてしまったので、コンポーネント外で定義
const debounceGetDirectLists = debounce((params) => {
  const copyObj = { ...params };
  if (copyObj.userId && copyObj.customerIds.length === 0) {
    delete copyObj.customerIds;
  }
  directGetListApi(copyObj)
    .then((res) => store.dispatch(getDirectList(res)))
    .catch((err) => console.log(err));
});

function InstructionListContainers(props) {
  const { selectCustomer } = props;

  const requestParams = useSelector((state) => state.directList.initialRequestParams);

  /**
   * @module getDirectLists - 【direct001】指示一覧取得 （営業員単位）
   * @param {integer} params.customerIds
   * @param {integer} params.limit
   * @param {integer} params.offset
   * @param {integer} params.directTabCode
   */
  const getDirectLists = debounceGetDirectLists;

  /**
   * @module deleteDirects - 【direct007】指示削除 (複数選択可)
   * @param {array} directIdsArray - 削除対象のdirectIds
   */
  const deleteDirects = async (directIdsArray, setSelected) => {
    const params = {
      directIds: directIdsArray,
    };
    directIdsArray.length > 0 ? (
      await directDeleteApi(params)
        .then(() => setSelected([]))
        .catch((err) => console.log(err))
    ) : null;
  };

  useEffect(() => {
    if (requestParams?.userId || requestParams?.customerIds.length > 0) {
      getDirectLists(requestParams);
    } else {
      store.dispatch(setNotDirects());
    }
  }, [requestParams]);

  return (
    <InstructionListWrapper
      selectCustomer={selectCustomer}
      deleteDirects={deleteDirects}
    />
  );
}

export default InstructionListContainers;
