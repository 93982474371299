import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { formatValue } from '../commonFunction';

/**
 * URLのSearchParamsを取得する
 * ```js
 * # https://a.com?id=100
 * const query = useQuery();
 * const id = query.get('id'); // 100
 * ```
 * @param {string | string[]} keys
 * @returns {URLSearchParams | string | string[] | number | null}
 */
function useQuery(keys) {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  if (typeof keys === 'string') {
    return formatValue(query.get(keys));
  }
  if (Array.isArray(keys)) {
    return keys.map((key) => formatValue(query.get(key)));
  }

  return query;
}

export default useQuery;
