// import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ACQUISITION_CODE, ACQUISITION_CODE_SORT_IDS } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  listItem: {
    width: '100%',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  listItemText: {
    '& .MuiTypography-root': {
      fontWeight: '700',
    },
  },
  gutters: {
    margin: '0 auto',
    padding: '0',
    '&.Mui-selected': {
      background: '#fff',
      color: theme.palette.primaryColor,
    },
    '& > .MuiListItemText-root': {
      marginTop: '0px',
      marginBottom: '0px',
      padding: '16px',
      borderBottom: '1px solid #F3F3F3',
    },
  },
}));

export default function UserTypeList(props) {
  const { setValue, label, setLabel, setOpen } = props;
  const classes = useStyles();
  const initial = ACQUISITION_CODE;
  const ids = ACQUISITION_CODE_SORT_IDS;
  return (
    <List className={classes.list}>
      {ids.map((data) => (
        <ListItem
          button
          key={data}
          className={classes.listItem}
          classes={{ gutters: classes.gutters }}
          selected={label === initial[data]}
          onClick={() => {
            setLabel(initial[data]);
            setValue(data);
            setOpen(false);
          }}
        >
          <ListItemText
            primary={initial[data]}
            className={classes.listItemText}
          />
        </ListItem>
      ))}
    </List>
  );
}
