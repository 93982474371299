import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const directUpdateSlice = createSlice({
  name: 'directUpdate',
  initialState: { directUpdate: initialState },
  reducers: {
    directUpdateApi: (state, data) => {
      state.directUpdate = data.payload;
    },
  },
});

export const { directUpdateApi } = directUpdateSlice.actions;

export default directUpdateSlice.reducer;
