import initialUpdateState from './initialUpdateState';

/**
 * @param {import("./type").RegisterState} user
 * @returns {import("./type").UpdateState}
 */
export default (user) => {
  const draft = { ...initialUpdateState, ...user };
  delete draft.isAllowDm;
  delete draft.isPrivacyAgree;
  return draft;
};
