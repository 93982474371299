import React, { useState } from 'react';
import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  Button,
  // Dialog,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmModal from '../../../common/modal/confirmModal';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  resHouseRow: {
    position: 'relative',
    transition: 'all 0.5s ease important',
    [commonTheme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
    },
    '&:hover': {
      [commonTheme.breakpoints.up('md')]: {
        background: `${theme.palette.primaryColor}29!important`,
        '& .deleteButton': {
          opacity: 1,
        },
        '& .detailButton': {
          opacity: 1,
        },
      },
    },
    '&:nth-of-type(odd)': {
      [commonTheme.breakpoints.up('md')]: {
        backgroundColor: '#F3F3F3',
      },
    },
  },
  tableCell: {
    padding: 0,
    textAlign: 'center',
    lineHeight: 1,
  },
  tableHeader: {
    color: '#8C8C8C',
    padding: '8px',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& ul': {
      display: 'flex',
      paddingLeft: 0,
      '& li': {
        listStyle: 'none',
        marginRight: '7px',
      },
    },
  },
  openInNewIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.primaryColor,
  },
  iconFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  adjustPadding: {
    padding: '14px 0 0 15px',
  },
  detailButton: {
    width: '66px',
    height: '28px',
    position: 'absolute',
    right: '80px',
    top: 0,
    bottom: 0,
    margin: 'auto 0',
    backgroundColor: '#fff',
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .2)',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s ease',
    opacity: 0,
    '&:hover': {
      opacity: 0,
      background: '#fff',
    },
  },
  detailButtonTxt: {
    color: theme.palette.primaryColor,
  },
  detailButtonIcon: {
    color: theme.palette.primaryColor,
  },
  deleteButton: {
    width: '66px',
    height: '28px',
    position: 'absolute',
    right: '10px',
    top: 0,
    bottom: 0,
    margin: 'auto 0',
    backgroundColor: '#fff',
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .2)',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s ease',
    opacity: 0,
    '&:hover': {
      opacity: 0,
      background: '#fff',
    },
  },
  deleteButtonTxt: {
    color: theme.palette.primaryColor,
  },
  deleteButtonIcon: {
    color: theme.palette.primaryColor,
  },
  link: {
    color: theme.palette.primaryColor,
    marginRight: '3px',
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  header: {
    padding: '24px 24px 8px',
  },
  container: {
    width: '640px',
  },
  content: {
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    backgroundColor: '#F3F3F3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px',
  },
  content2: {
    padding: '16px',
  },
  resisterButton: {
    width: '74px',
    height: '40px',
    margin: '0 auto',
  },
  cancelDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  cancelContent: {
    width: 515,
    padding: 24,
  },
  cancelTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  cancelParagraph: {
    textAlign: 'center',
    marginBottom: 50,
  },
  buttons: {
    display: 'flex',
    padding: '0 120px',
    justifyContent: 'space-between',
    '& .MuiButton-text': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '160%',
      color: theme.palette.primaryColor,
    },
  },
}));

function ReshouseTableRow(props) {
  const { row, deleteAndUpdateHouseList } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // 削除用
  const [open2, setOpen2] = useState(false);

  const deleteAlert = () => {
    setOpen2(true);
  };

  const closeCancelModal = () => {
    setOpen2(false);
  };

  const handleClickOk = () => {
    setOpen2(false);
    console.log({ row });
    deleteAndUpdateHouseList(row.inquiryProductId);
  };

  return (
    <>
      <TableRow className={classes.resHouseRow} key={row.buildingNumber}>
        <TableCell className={classes.tableCell}>{row.inquiredAt}</TableCell>
        <TableCell
          className={`${baseClasses.small} ${classes.tableCell} ${classes.adjustPadding}`}
        >
          <Grid className={classes.flex}>
            {row.productName}
            <Grid>
              <ul>
                <li>{row.largeResponseTypeName}</li>
                <li>{row.mediumResponseTypeName}</li>
                <li>{row.smallResponseTypeName}</li>
                <li>{row.fineResponseTypeName}</li>
              </ul>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row.buildingNumber}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Button
            className={`${classes.deleteButton} deleteButton`}
            onClick={deleteAlert}
          >
            <DeleteOutlineIcon className={classes.deleteButtonIcon} />
            <Typography
              className={`${classes.deleteButtonTxt} ${baseClasses.smallStrong}`}
            >
              削除
            </Typography>
          </Button>
        </TableCell>
      </TableRow>
      <ConfirmModal
        open={open2}
        onClose={closeCancelModal}
        title="問合せ物件の削除確認"
        content="選択した問合せ物件を削除してもよろしいですか？"
        onCancel={closeCancelModal}
        onSubmit={handleClickOk}
      />
    </>
  );
}

export default ReshouseTableRow;
