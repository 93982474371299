import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InstructionReportItems from './parts/instructionReportItems';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { useLoading } from '../../../hooks';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      height: 'calc(100% - 48px)',
      padding: '8px 8px 0',
    },
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
      paddingTop: 102,
      paddingBottom: 160,
      minHeight: 'calc(100vh - 60px)',
    },
  },
  tabs: {
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px #c8c8c8 solid',
    },
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 18,
      background: '#fff',
      borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      opacity: '1',
      width: '33.33%',
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  count: {
    display: 'block',
    textAlign: 'center',
  },
}));

function InstructionReport(props) {
  const {
    informations,
    createTimeline,
    setTabCode,
    // setDirectId,
    getDirects,
    headerReload,
    // customer,
  } = props;

  const { hasLoading } = useLoading();

  const classes = useStyles();
  const common = commonStyles();
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const [value, setValue] = useState(1);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  const label1 = (<span><span className={`${common.title2} ${classes.count}`}>{count1}</span>指示</span>);
  const label2 = (<span><span className={`${common.title2} ${classes.count}`}>{count2}</span>報告</span>);
  const label3 = (<span><span className={`${common.title2} ${classes.count}`}>{count3}</span>完了</span>);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTabCode(newValue);
    getDirects(newValue);
  };

  // タブ横の総数計算
  useEffect(() => {
    setCount1(informations.totalDirects);
    setCount2(informations.totalReports);
    setCount3(informations.totalCompleate);
  }, [informations]);

  return (
    <div className={`${classes.root} ${hasLoading('instructionReport') ? common.loading : ''}`}>
      <AppBar position="static">
        {isSP ? (
          <Tabs value={value} onChange={handleChange} className={classes.tabs}>
            <Tab style={{ display: 'none' }} />
            <Tab label={label1} {...a11yProps(1)} className={`instructionTabCount${count1}`} />
            <Tab label={label2} {...a11yProps(2)} className={`instructionTabCount${count2}`} />
            <Tab label={label3} {...a11yProps(3)} className={`instructionTabCount${count3}`} />
          </Tabs>
        ) : (
          <Tabs value={value} onChange={handleChange} className={classes.tabs}>
            <Tab style={{ display: 'none' }} />
            <Tab label={`指示 ${count1}`} {...a11yProps(1)} />
            <Tab label={`報告 ${count2}`} {...a11yProps(2)} />
            <Tab label={`完了 ${count3}`} {...a11yProps(3)} />
          </Tabs>
        )}
      </AppBar>
      {/*
        tab: 指示(1) / 報告(2) / 完了(3)
        id1 & id2 = 含めるステータス：例）1(未確認)と2(対応予定)はタブ0(指示)
      */}
      <TabPanel value={value} index={1}>
        <InstructionReportItems
          tab={0}
          id1={1}
          id2={2}
          informations={informations}
          createTimeline={createTimeline}
          getDirects={getDirects}
          headerReload={headerReload}
          // customer={customer}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <InstructionReportItems
          tab={1}
          id1={3}
          id2=""
          informations={informations}
          createTimeline={createTimeline}
          getDirects={getDirects}
          headerReload={headerReload}
          // customer={customer}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InstructionReportItems
          tab={2}
          id1={4}
          id2={5}
          informations={informations}
          createTimeline={createTimeline}
          getDirects={getDirects}
          headerReload={headerReload}
          // customer={customer}
        />
      </TabPanel>
    </div>
  );
}

export default InstructionReport;
