import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Grid, List, ListItem, Button, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import commonStyles from '../../../../styles';

import UserTree from '../../../userTreeNew';

import store from '../../../../../store';

import InfoChangeHistoryCalendarWindow from './infoChangeHistoryCalendarWindow';
// import InfoChangeHistoryTextWindow from './infoChangeHistoryTextWindow';
import InfoChangeHistoryText from '../infoChangeHistoryText';

import { dateFormatter } from '../../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 8px',
  },
  list: {
    padding: '16px 0 16px 16px',
  },
  buttonWrap: {
    padding: 0,
  },
  button: {
    alignItems: 'center',
    width: '100%',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    flexWrap: 'nowrap',
    '& > .MuiButton-label': {
      justifyContent: 'space-between',
    },
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
  },
  drawer: {
    position: 'relative',
    // zIndex: '1500!important',
    '& .MuiPaper-root': {
      width: 'calc(100% - 50px)',
      overflow: 'visible',
      boxShadow: 'none',
    },
    '& > .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  },
  title: {
    flexShrink: 0,
    '& .MuiTypography-root': {
      textAlign: 'left',
      fontSize: '20px',
    },
  },
  values: {
    fontWeight: 'bold',
    maxWidth: '50%',
  },
  date: {
    minWidth: '2em',
    display: 'inline-block',
    textAlign: 'center',
  },
  left: {
    textAlign: 'left',
  },
  close: {
    position: 'absolute',
    left: '-41px',
    top: '6px',
    width: '35px',
    height: '35px',
    fill: '#fff',
  },
  btn: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    width: '100%',
    padding: '24px 0',
    borderTop: '1px solid rgba(51, 51, 51, 0.04);',
  },
  reset: {
    color: theme.palette.primaryColor,
    fontSize: 15,
    fontWeight: '700',
    marginRight: 20,
  },
}));

function InfoChangeHistorySearchWindowSp(props) {
  const {
    openSearchWindow,
    setOpenSearchWindow,
    search,
    setSearch,
    setSearch2,
    reset,
    searchFunc,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const { responseHeader } = store.getState();
  const { userId, userName } = responseHeader;

  // モーダル開閉用のローカルステート
  const [openModal1, setOpenModal1] = useState(false); // 変更期間用モーダルの開閉用
  const [openModal2, setOpenModal2] = useState(false); // 担当者変更用モーダルの開閉用
  // const [openModal3, setOpenModal3] = useState(false); // キーワード用モーダルの開閉用

  const defaultTo = new Date();
  const defaultFrom = new Date();
  defaultFrom.setDate(defaultTo.getDate() - 7);
  const [fromToDate, setFromToDate] = useState({
    from: '',
    to: '',
  });

  const [localUser, setLocalUser] = useState({ userId: 0, userName: '' });

  const isFirst = useRef(true);

  useEffect(() => {
    if (isFirst.current) {
      setLocalUser({
        userId,
        userName,
      });
      setSearch('infoChanger', { userId, userName });

      setFromToDate({
        from: dateFormatter(defaultFrom, 'YYYY/MM/DD'),
        to: dateFormatter(defaultTo, 'YYYY/MM/DD'),
      });
    }
    isFirst.current = false;
  }, []);

  const changerOpen = () => {
    console.log('m21を開く');
    console.log(isFirst);
  };

  const searchStart = () => {
    searchFunc();
    setOpenSearchWindow(false);
  };

  const box = {
    items: [
      {
        type: 'calendar',
        key: 'infoMultiple',
        input: {
          label: '変更期間',
          state: { endAt: fromToDate.endAt, startAt: fromToDate.startAt },
          setState: (start, end) => { return setSearch2(start, end); },
          fromToDate,
          setFromToDate,
        },
      },
      {
        type: 'dialog',
        key: 'infoChanger',
        input: {
          label: '変更者',
          state: localUser?.userName,
          setState: (e) => { return setSearch('infoChanger', e); },
          click: changerOpen,
        },
      },
      {
        type: 'text',
        key: 'noneContactKeyword',
        input: {
          label: 'キーワード',
          state: search.infoKeyword,
          setState: (e) => { return setSearch('infoKeyword', e); },
          place: '-',
        },
      },
    ],
  };

  // 各検索モーダルの表示
  const toggleOpenModal = (type) => {
    let func;
    switch (type) {
      // case 'calendar':
      //   func = setOpenModal1(!openModal1);
      //   break;
      case 'dialog':
        func = setOpenModal2(!openModal2);
        break;
      // case 'text':
      //   func = setOpenModal3(!openModal3);
      //   break;
      default:
        break;
    }
    return func;
  };
  const switchType = (data) => {
    const returnType = [];
    switch (data.type) {
      case 'calendar':
        return (
          <Grid container className={classes.button}>
            <Typography className={`${baseClasses.title4} ${classes.title}`}>{data.input.label}</Typography>
            <InfoChangeHistoryCalendarWindow
              open={openModal1}
              setOpen={setOpenModal1}
              values={data.input}
            />
          </Grid>
        );
      case 'dialog':
        return (
          <>
            <Button onClick={() => toggleOpenModal(data.type)} className={classes.button}>
              <Typography className={baseClasses.title4}>{data.input.label}</Typography>
              <Typography className={`${classes.title4} ${classes.values} ${classes.left}`}>{data.input.state === '' ? '-' : data.input.state}</Typography>
            </Button>
            <UserTree
              isOpenTree={openModal2}
              setIsOpenTree={setOpenModal2}
              // selectUser={search.infoChanger?.userId || { userId, userName }}
              selectUser={localUser}
              setSelectUser={(e) => {
                setSearch('infoChanger', e);
                setLocalUser(e);
              }}
              selectDivision={[]}
              setSelectDivision={() => {}}
              handleClearFunction={() => {
                setSearch('infoChanger', { userId: null, userName: '' });
                setLocalUser({ userId: null, userName: '' });
              }}
              isDialog
              isSelectUserWindow
            />
          </>
        );
      case 'text':
        return (
          <Grid container className={classes.button}>
            <Typography className={`${baseClasses.title4} ${classes.title}`}>{data.input.label}</Typography>
            {/* <InfoChangeHistoryTextWindow
              open={openModal3}
              setOpen={setOpenModal3}
              values={data.input}
            /> */}
            <InfoChangeHistoryText values={data.input} />
          </Grid>
        );
      default:
        break;
    }
    return returnType;
  };

  return (
    <Drawer
      anchor="right"
      open={openSearchWindow}
      onClose={() => { setOpenSearchWindow(!openSearchWindow); }}
      className={`${classes.drawer} ${baseClasses.onlySp} ${baseClasses.cancelIndex}`}
    >
      <Close
        className={classes.close}
        onClick={() => { setOpenSearchWindow(!openSearchWindow); }}
      />
      <Grid className={`${baseClasses.drawerHeader} ${classes.title}`}>
        <Typography className={baseClasses.title3}>検索</Typography>
      </Grid>
      <List className={classes.list}>
        {box.items.map((data) => (
          <ListItem className={classes.buttonWrap} key={data.key}>
            {switchType(data)}
          </ListItem>
        ))}
      </List>
      <Grid item className={classes.btn}>
        <Button
          className={classes.reset}
          onClick={() => {
            reset();
            setLocalUser({ userId: 0, userName: '' });
            setFromToDate({ from: '', to: '' });
          }}
          type="reset"
        >
          リセット
        </Button>
        <Button
          className={baseClasses.buttonsPrimary}
          onClick={searchStart}
        >
          検索
        </Button>
      </Grid>
    </Drawer>
  );
}

export default InfoChangeHistorySearchWindowSp;
