import { createSlice } from '@reduxjs/toolkit';

const initialState = false;
export const deviceTypeSlice = createSlice({
  name: 'deviceType',
  initialState: {
    isSp: initialState,
  },
  reducers: {
    setDeviceType: (state, data) => {
      state.isSp = data.payload;
    },
  },
});

export const { setDeviceType } = deviceTypeSlice.actions;

export default deviceTypeSlice.reducer;
