import { useMemo } from 'react';
import { Grid, Icon, makeStyles } from '@material-ui/core';
import { checkExpiredTime } from '../incompleteList';
import { classNames } from '../../../../commonFunction';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  border: {
    borderLeft: '#FCE034 8px solid',
    paddingLeft: 8,
  },
  red: {
    color: '#D83420',
    borderColor: '#D83420',
  },
});

/**
 * DateExpiredWrap
 * @param {{
 * date: string;
 * border?: boolean;
 * }} props
 * @returns ReactNode
 */
export default function DateExpiredWrap(props) {
  const {
    date,
    border = false, // 左枠を表示するか
    children,
  } = props;
  const classes = useStyles();

  const isExpired = useMemo(() => {
    return checkExpiredTime(date);
  }, [date]);

  return (
    <Grid
      className={classNames(
        classes.root,
        border ? classes.border : '',
        isExpired ? classes.red : '',
      )}
    >
      {isExpired ? <Icon>local_fire_department</Icon> : null}
      {children}
    </Grid>
  );
}
