import React from 'react';
import { TableRow, TableCell, Typography, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '& td': {
      padding: 4,
      color: '#333333',
      '& span': {
        padding: 2,
        '& svg': {
          width: 16,
          height: 16,
        },
      },
      '& p': {
        borderLeft: 'solid 1px #C8C8C8',
        paddingLeft: 5,
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
}));

function SelectStoreWindowTableRow(props) {
  const { rows, selectedStore, setSelectedStore, storeList } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const handleChange = (storeObj) => {
    const lastOrder = storeList.length ? Math.max.apply(null, storeList.map((store) => store.responseRegistrationOrder)) : 0; // eslint-disable-line
    setSelectedStore({
      responseRegistrationOrder: lastOrder + 1,
      isNextRegistration: 0,
      isEmergencyRegistration: 0,
      ...storeObj,
    });
  };

  return (
    <>
      {rows.map((row) => {
        return (
          <TableRow className={classes.tableRow} key={row.divisionId}>
            <TableCell style={{ textAlign: 'center', width: 50 }}>
              <Radio
                checked={
                  selectedStore
                  && selectedStore.divisionSubName2 === row.divisionSubName2
                }
                onChange={() => handleChange(row)}
                value={row.divisionSubName2}
              />
            </TableCell>
            <TableCell>
              <Typography className={baseClasses.small}>
                {row.divisionSubName}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={baseClasses.small}>
                {row.divisionSubName2}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export default SelectStoreWindowTableRow;
