import client from '../httpClient';

/**
 * 【resHouse002】問合せ物件登録
 * http://10.22.2.48:8080/docs/#/resHouse/post-inquiry-property
 */
const resHouseRegisterApi = (params) => {
  return client.post('/inquiry-products/', JSON.stringify(params));
};

export default resHouseRegisterApi;
