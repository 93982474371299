import {
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  input: {
    width: '100%',
    '& input': {
      fontSize: 16,
      zoom: 0.81,
      padding: '0 0 7px',
      // textAlign: 'right',
    },
    '& input::placeholder': {
      color: '#333',
      fontSize: 16,
      zoom: 0.81,
      opacity: 1,
      fontWeight: 700,
    },
    // '& *::before, & *::after': { display: 'none' },
  },
});

function InfoChangeHistoryText(props) {
  const { values } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    values.setState(e.target.value);
  };

  return (
    <TextField
      className={classes.input}
      checked={values.state}
      onChange={handleChange}
      onBlur={(e) => { return values.setState(e.target.value); }}
      name={values.label}
      placeholder={values.place}
      value={values.state}
    />
  );
}

export default InfoChangeHistoryText;
