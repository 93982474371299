import React, { useEffect, useState, useCallback } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Select,
  Button,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { clearAccessToken } from '../../../store/common/accessToken';
import { signInProcess } from '../../../commonFunction/authorize';
import commonStyles from '../../styles/index';
import commonTheme from '../../styles/theme';
import { URL_MAPPER } from '../../../constants/urls';
import getDeviceType from '../../getDeviceType';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  panel: {
    width: '100%',
    [commonTheme.breakpoints.up('md')]: {
      height: '33%',
      minHeight: '310px',
      border: '1px #c8c8c8 solid',
      maxWidth: '600px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  container: {
    height: '100vh',
    backgroundColor: '#f8f8f8',
  },
  column: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    height: '100%',
  },
  h1: {
    borderBottom: '1px #c8c8c8 solid',
    padding: '16px',
  },
  title1: {
    textAlign: 'center',
  },
  body: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  btn: {
    marginBottom: '24px',
    border: 'none',
    color: '#fff',
    fontWeight: 700,
    padding: '2% 5%',
    cursor: 'pointer',
  },
  selectWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px #c8c8c8 solid',
    height: '56px',
    width: '343px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '24px',
    '&>::before': {
      display: 'none',
    },
  },
  select: {
    border: 'none',
    '&::after': { display: 'none' },
    '&>option': {
      padding: '5px 16px',
    },
    '& .MuiSelect-selectMenu:focus': { backgroundColor: '#fff' },
  },
});

function LoginCognito(props) {
  const classes = useStyles();
  const common = commonStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divisionList = [];
  const { getLoginUrl, sessionProps } = props;
  // eslint-disable-next-line max-len
  const { signInProcess: signInProcessCallBack } = sessionProps.props;

  const division = getLoginUrl.data;

  // Cognito SSOのerror handling
  const setAlertError = () => {
    dispatch(
      changeAlertMessage({
        msgList: ['お手数ですが、再度やり直してください。'], // 必須
        title: 'ログインに失敗しました', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
        isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
      }),
    );
  };

  const listner = useCallback(({ payload: { event } }) => {
    switch (event) {
      case 'signIn':
      case 'cognitoHostedUI':
        signInProcess(() => {
          signInProcessCallBack();
          navigate(URL_MAPPER[getDeviceType() ? 'sp' : 'customer']);
        });
        break;
      case 'signOut':
        dispatch(clearAccessToken());
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        dispatch(clearAccessToken());
        setAlertError();
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    Hub.listen('auth', listner);
    return Hub.remove('auth', listner);
  }, []);

  // メニューリスト生成
  for (let i = 0; i < division.length; i += 1) {
    divisionList.push(
      <MenuItem
        value={division[i].divisionId}
        key={`key${division[i].divisionId}`}
      >
        {division[i].groupName}
      </MenuItem>,
    );
  }

  const [state, setState] = useState(division[0].divisionId);
  const updateUrl = (e) => setState(e.target.value);

  const onClickSignIn = () => Auth.federatedSignIn();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Paper className={classes.panel}>
        <Grid container className={classes.column}>
          <Grid item className={classes.h1}>
            <Typography className={`${common.title1} ${classes.title1}`}>
              SFAログイン
            </Typography>
          </Grid>
          <Grid container className={classes.body}>
            <div className={classes.selectWrap}>
              <Typography className={common.bass}>事業部</Typography>
              <Select
                className={classes.select}
                onChange={updateUrl}
                value={state}
              >
                {divisionList}
              </Select>
            </div>
            <Button
              onClick={onClickSignIn}
              className={`${common.buttonsPrimary} ${classes.btn}`}
            >
              Sign In
            </Button>
            <Typography variant="body1" className={common.small}>
              認証ができる環境でログインしてください
              <br />
              （社内ポータルやGmailが見れる状態）
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default LoginCognito;
