import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import ContactTimeRow from './parts/contactTimeRow';
import CommonDialog from '../../common/modal';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles({
  content: {
    background: '#fff',
    overflowY: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      padding: 24,
    },
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 102,
      paddingBottom: 160,
      minHeight: 'calc(100vh - 60px)',
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        overflowX: 'hidden',
      },
    },
  },
  table: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      tableLayout: 'fixed',
    },
  },
  tableHead: {
    borderBottom: '2px solid #C8C8C8',
    '& th': {
      [commonTheme.breakpoints.down('sm')]: {
        padding: '0 12px 2px',
        wordBreak: 'keep-all',
        fontSize: 13,
        fontWeight: 700,
        color: '#333',
      },
    },
    '& th:nth-child(1)': {
      textAlign: 'center',
      [commonTheme.breakpoints.up('md')]: {
        width: '12%',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
    },
    '& th:nth-child(2)': {
      textAlign: 'center',
      [commonTheme.breakpoints.up('md')]: {
        width: '12%',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
    },
    '& th:nth-child(3)': {
      paddingLeft: 14,
      [commonTheme.breakpoints.up('md')]: {
        width: '76%',
      },
      [commonTheme.breakpoints.down('sm')]: {
        width: '67%',
      },
    },
  },
  tableLbl: {
    color: '#8C8C8C',
    padding: 0,
    lineHeight: 1.6,
  },
});

export default function ContactTimeWrapper(props) {
  const { tels = [], open, onClose } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const { hasLoading } = useLoading();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  let container;

  const rowsObj = {
    '08:00:00': [],
    '09:00:00': [],
    '10:00:00': [],
    '11:00:00': [],
    '12:00:00': [],
    '13:00:00': [],
    '14:00:00': [],
    '15:00:00': [],
    '16:00:00': [],
    '17:00:00': [],
    '18:00:00': [],
    '19:00:00': [],
    '20:00:00': [],
  };
  tels.forEach(telObj => {
    if (telObj.time in rowsObj) {
      rowsObj[telObj.time].push(telObj);
      return;
    }
    rowsObj['20:00:00'].push(telObj);
  });

  const body = (
    <Grid className={`${classes.content} ${hasLoading('ContactTime') ? baseClasses.loading : ''}`}>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                className={`${baseClasses.title6} ${classes.tableLbl}`}
              >
                時間帯
              </TableCell>
              <TableCell
                className={`${baseClasses.title6} ${classes.tableLbl}`}
              >
                架電率
              </TableCell>
              <TableCell
                className={`${baseClasses.title6} ${classes.tableLbl}`}
              >
                つながった回数 / 総電話回数
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(rowsObj).map(([label, telObjs]) => (
              <ContactTimeRow key={label} tels={telObjs} label={label} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

  if (isSp) {
    container = body;
  } else {
    container = (
      <CommonDialog
        open={open}
        onClose={onClose}
        title="連絡がつきやすい時間帯の統計"
        width={640}
      >
        {body}
      </CommonDialog>
    );
  }

  return <div>{container}</div>;
}
