import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DivisionHistory from '../../../components/division/divisionHistory/divisionHistory';
import userSearchListApi from '../../../apis/divisionMaster/getUserSearchListApi';
import { getUserSearchListApi, initUserSearchListApi } from '../../../store/divisionMaster/getUserSearchListSlice';
import { DIVISION_MASTER_GROUPS } from '../../../constants/userRoleGroup';
import { AREA_CODES } from '../../../constants/apiParameterCode';
import { dateFormatter } from '../../../commonFunction';
import { useLoading } from '../../../hooks';

export default function DivisionHistoryContainer() {
  const dispatch = useDispatch();

  const userSearchList = useSelector((state) => state.getUserSearchList.getUserSearchList);

  // レスポンスヘッダから、ログイン中ユーザに紐づくロールIDを取得
  const responseHeader = useSelector((state) => state.responseHeader);
  const { roleGroupId } = responseHeader;

  // 検索ボタンの活性非活性制御
  const [disabled, setDisabled] = useState(false);

  // 取り込み適用終了日
  const currentDate = new Date();
  const applyingEndDate = dateFormatter(currentDate, 'YYYY/MM/DD');
  // 取り込み適用開始日
  currentDate.setMonth(currentDate.getMonth() - 1);
  const applyingStartDate = dateFormatter(currentDate, 'YYYY/MM/DD');

  // ページ表示用
  const searchDisplayInitial = {
    areaCode: AREA_CODES.KANTO,
    applyingStartDate,
    applyingEndDate,
    userName: '',
    oldPositionCode: null,
    newPositionCode: null,
    oldGradeCode: null,
    newGradeCode: null,
    oldUserRoleGroupId: null,
    newUserRoleGroupId: null,
    oldDivision: '',
    newDivision: '',
  };
  const [searchDisplay, setSearchDisplay] = useState(searchDisplayInitial);

  // コンポーネントリセット用
  const [key, setKey] = useState(0);

  // 検索用のパラメータ
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const searchParamInitial = {
    areaCode: AREA_CODES.KANTO,
    applyingStartDate,
    applyingEndDate,
    offset: page * rowsPerPage,
    limit: rowsPerPage,
  };
  const [searchParam, setSearchParam] = useState(searchParamInitial);

  const { addLoading, removeLoading } = useLoading();

  // 【divisionMaster012】課編成一括更新履歴一覧取得
  // /division-masters/user-search-list
  const getUserSearchListFunction = useCallback(async (params) => {
    addLoading();
    await userSearchListApi(params)
      .then((res) => {
        dispatch(initUserSearchListApi());
        dispatch(getUserSearchListApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        removeLoading();
      });
  }, []);

  // 検索
  const searchFunction = () => {
    if (page === 0) {
      getUserSearchListFunction(searchParam);
    } else {
      setPage(0);
    }
  };

  // リセット
  const resetFunction = () => {
    setSearchDisplay(searchDisplayInitial);
    setSearchParam(searchParamInitial);
    setKey(key + 1);
    setDisabled(false);
  };

  // エリア変更時の処理
  const selectChangeArea = (e) => {
    const { value } = e.target;
    setSearchDisplay({ ...searchDisplay, areaCode: Number(value) });
    setSearchParam({ ...searchParam, areaCode: Number(value) });
  };

  // 初回、ページ変更時
  useEffect(() => {
    setSearchParam({ ...searchParam, offset: page });
    getUserSearchListFunction({ ...searchParam, offset: page * rowsPerPage });
  }, [page]);

  const functions = {
    getUserSearchListFunction,
    searchFunction,
    resetFunction,
    selectChangeArea,
  };

  const data = {
    key,
    userSearchList,
    hasPerMission: DIVISION_MASTER_GROUPS.includes(roleGroupId),
    searchDisplay,
    setSearchDisplay,
    searchParam,
    setSearchParam,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    disabled,
    setDisabled,
  };

  return (
    <DivisionHistory
      functions={functions}
      data={data}
    />
  );
}
