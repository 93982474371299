import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import PageNation from '../eleCommon/pageNation';
import commonStyles from '../styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '9.75px 16px',
    position: 'sticky',
    top: -10,
    zIndex: 1,
    background: '#fff',
    minHeight: 56,
    '& > .MuiButton-root:not(:last-child)': {
      position: 'absolute',
      right: 16,
    },
  },
  blue: {
    color: theme.palette.primaryColor,
    fontWeight: '700',
  },
  title: {
    marginRight: 20,
  },
}));

function Header(props) {
  const {
    getResponses,
    count,
    prevRequestRef,
  } = props;
  const classes = useStyles();
  const commonClasses = commonStyles();

  return (
    <Grid className={classes.header}>
      <Grid>
        <Typography className={commonClasses.title2}>
          反響未処理（WEB）
        </Typography>
      </Grid>
      <PageNation getApi={getResponses} count={count} offset={prevRequestRef.current.offset} />
    </Grid>
  );
}

export default Header;
