import client from '../httpClient';
// paramsが引数に設定されている場合、パラメーターとして追加
const membersGetNotificationsApi = async (params) => {
  let res;

  try {
    if (typeof params === 'string') {
      res = await client.get(`/members/notifications${params}`);
    } else {
      res = await client.get('/members/notifications', { params });
    }
    return res.data;
  } catch (e) {
    console.log(e);
    return {
      total: 0,
      notifications: [],
    };
  }
};

export default membersGetNotificationsApi;
