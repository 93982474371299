import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableRow,
  TableCell,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import Calendar from '../../common/calendar';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';

const useStyles = makeStyles({
  td: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '160%',
    padding: '6px 0',
    textAlign: 'center',
    borderBottom: '1px solid #C8C8C8',
    '& > span': {
      display: 'block',
      borderRadius: 0,
    },
    '&:not(:last-child) > span': {
      borderRight: '1px solid #C8C8C8',
    },
    '& > .MuiGrid-root': {
      border: 'none',
      borderRight: '1px solid #C8C8C8',
      padding: '0 4px',
      '& > label': {
        width: '100%',
        borderBottom: '1px solid #333',
        '& > span': {
          width: '100%',
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: 13,
          padding: 0,
        },
      },
    },
    '&.disabled label': {
      background: '#DEDEDE',
      pointerEvents: 'none',
    },
  },
});

export default function DivisionDate(props) {
  const { data, index, row, lalbelId, isItemSelected, handleClick } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const [editLimitAt, setEditLimitAt] = useState({ assignAt: row.editLimitAt.substr(0, 16) });

  const setParamFunction = (name, val) => {
    data.setParam(prevState => ({
      divisionMasterList: prevState.divisionMasterList.map((item, i) => {
        if (i === index) {
          return { ...item, [name]: `${val.replace(/-/g, '/').replace(/T/g, ' ')}:00` };
        }
        return item;
      }),
    }));
  };

  const alert = () => {
    dispatch(
      changeAlertMessage({
        msgList: ['分は00以外を指定できません', '分の部分を00に置換しました'],
      }),
    );
  };

  useEffect(() => {
    setParamFunction('editLimitAt', editLimitAt);
  }, [editLimitAt]);

  const editLimitData = {
    label: '',
    require: false,
    type: 'time',
    state: editLimitAt,
    setState: setEditLimitAt,
    fixedMinute: true,
    alert,
  };

  return (
    <TableRow
      tabIndex={-1}
      key={row.areaCode}
    >
      <TableCell className={classes.td}>
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, row.areaCode)}
          inputProps={{ 'aria-labelledby': lalbelId }}
        />
      </TableCell>
      <TableCell id={lalbelId} className={classes.td}>
        <span>{row.areaName}</span>
      </TableCell>
      <TableCell className={`${classes.td} ${!data.hasPerMission && 'disabled'}`}>
        <Calendar data={editLimitData} />
      </TableCell>
      <TableCell className={classes.td}>
        <span>{row.updateAt}</span>
      </TableCell>
      <TableCell className={classes.td}>
        <span>{row.updateUserName}</span>
      </TableCell>
    </TableRow>
  );
}
