import React from 'react';
import {
  TableContainer,
  Table,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import SelectStoreWindowTableRow from './selectStoreWindowTableRow';
import CommonDialog from '../../common/modal';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  tableContainer: {
    background: '#fff',
    padding: 8,
    boxSizing: 'border-box',
    border: 'solid 1px #C8C8C8',
  },
  radioButton: {
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& svg': {
      width: 16,
      height: 16,
      color: theme.palette.primaryColor,
    },
  },
}));

function SelectStoreWindow(props) {
  const {
    open,
    setOpen,
    storeList = [],
    setStoreList,
    divisions,
    selectedStore,
    setSelectedStore,
    setNextRegsit,
    order,
    storeChange = false,
  } = props;
  const classes = useStyles();
  const common = commonStyles();

  const addStores = () => {
    if (storeChange) {
      storeChange(order, selectedStore);
    } else {
      const {
        divisionId,
        responseRegistrationOrder,
        isNextRegistration,
        isEmergencyRegistration,
      } = selectedStore;
      setStoreList([
        ...storeList,
        {
          divisionSubName: selectedStore.divisionSubName2,
          divisionId,
          responseRegistrationOrder,
          isEmergencyRegistration,
          isNextRegistration,
        },
      ]);
      // 選択された店舗がなければ、次の登録先店舗にチェック
      if (!storeList.length) setNextRegsit(selectedStore);
      setSelectedStore({});
    }
    setOpen(false);
  };

  const handleClear = () => setOpen(false);

  const isSelected = Object.keys(selectedStore).length !== 0;

  return (
    <CommonDialog
      open={open}
      onClose={() => setOpen(false)}
      title="店舗グループ_店舗選択ウィンドウ"
      width={640}
      buttons={[
        {
          label: 'CANCEL',
          onClick: handleClear,
          className: common.buttonsSecondary,
        },
        {
          label: 'OK',
          onClick: addStores,
          disabled: !isSelected,
        },
      ]}
    >
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableBody>
            <SelectStoreWindowTableRow
              rows={divisions}
              storeList={storeList}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </CommonDialog>
  );
}

export default SelectStoreWindow;
