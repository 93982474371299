import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PersonalTitle from './personalTitle';
import PersonalTable from './personalTable';
import PersonalCreate from './personalCreate';
import Modal from '../../../common/modal';
import { useLoading } from '../../../../hooks';
import commonStyles from '../../../styles';
import { isArray } from '../../../../commonFunction';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px 24px 8px',
    position: 'static',
    width: '1024px',
    height: '52px',
    left: '0px',
    top: '0px',
    borderBottom: '1px solid #C8C8C8',
    '& h2': {
      fontWeight: 'bold',
    },
  },
  contentElement: {
    width: '100%',
    position: 'static',
    height: '322px',
    left: '0px',
    top: '53px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    '&>*': {
      boxSizing: 'border-box',
      width: '100%',
    },
  },
  customTitle3: {
    boxSizing: 'border-box',
    width: '100%',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
  dialogMainPaper: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        height: '70%',
      },
    },
  },
}));

function PersonalIndex(props) {
  const { userDetail, getMokuhyoList, resetMokuhyoList, open, setOpen } = props;
  const classes = useStyles();
  const common = commonStyles();
  const { hasLoading } = useLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(0);
  const initialDivision = useRef(false);

  const showMokuhyoList = () => {
    if (open) {
      getMokuhyoList(userDetail.userId);
    }
    if (isArray(userDetail.divisions)) {
      for (const key of userDetail.divisions) {
        if (key?.isMainDivision) {
          initialDivision.current = key.divisionId;
        }
      }
    }
  };

  // 前に開いていた余分なデータが割り込むときがあるため初期化
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5369
  useEffect(() => {
    showMokuhyoList();
    return () => resetMokuhyoList();
  }, [open, userDetail, reload]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth={1200}
      addStyle={classes.dialogMainPaper}
      title="個人目標設定ウィンドウ"
      bodyClassName={hasLoading('personalGoal') ? common.loading : ''}
    >
      <PersonalTitle setIsOpen={setIsOpen} />
      {isOpen ? (
        // eslint-disable-next-line max-len
        <PersonalCreate setIsOpen={setIsOpen} reload={reload} setReload={setReload} divisionId={initialDivision?.current} />)
        : null}
      <PersonalTable reload={reload} setReload={setReload} />
    </Modal>
  );
}

export default PersonalIndex;
