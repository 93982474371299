import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  txt: {
    fontFamily: 'Roboto',
    color: '#333',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
});

export default function DirectTypeCode(props) {
  const {
    code,
  } = props;

  const classes = useStyles();

  const getAction = () => {
    switch (code) {
      case 1:
        return (
          <span>電話</span>
        );
      case 2:
        return (
          <span>メール</span>
        );
      case 3:
        return (
          <span>LINE</span>
        );
      case 4:
        return (
          <span>案内</span>
        );
      case 5:
        return (
          <span>催促</span>
        );
      case 6:
        return (
          <span>その他</span>
        );
      default:
        return null;
    }
  };

  return (
    <span className={classes.txt}>
      {getAction()}
    </span>
  );
}
