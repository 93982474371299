import client from '../httpClient';

// http://10.22.2.48:8080/docs/#/members/get-members-loan
const hpinfoGetLoanApi = async (params) => {
  try {
    const res = await client.get('/members/loan', { params });
    return res.data;
  } catch (e) {
    return {
      total: 0,
      loans: [],
    };
  }
};

export default hpinfoGetLoanApi;
