import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, List, ListItem } from '@material-ui/core';
// Icons
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Icon from '@material-ui/core/Icon';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CustomerButtonIconSp from './sp/customerButtonIconSp';
// Component
import InfoRegister from '../../common/infoRegister';
import InstructionsWindow from '../../common/taiouRireki/instructionsWindow';
import CustomSimpleDrawer from '../../eleCommon/sp/customSimpleDrawer';

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'inline-block',
    background: 'transparent',
    boxShadow: 'none',
    top: 'auto',
    bottom: '84px',
    left: '50%',
    transform: 'translateX(-50%)',
    margin: 'auto',
    width: 'min-content',
  },
  fullappBar: {
    display: 'inline-block',
    background: 'transparent',
    boxShadow: 'none',
    top: 'auto',
    bottom: 16,
    left: '50%',
    transform: 'translateX(-50%)',
    margin: 'auto',
    width: 'min-content',
  },
  list: {
    display: 'inline-flex',
    padding: '8px 24px',
    background: `linear-gradient(90deg, ${theme.palette.primaryColor} 0%, ${theme.palette.gradLightColor} 100%)`,
    boxShadow: '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    borderRadius: '100px',
    overflow: 'hidden',
  },
  listItem: {
    padding: 0,
  },
  button: {
    padding: '0 16px',
    '& > .MuiButton-label': {
      flexWrap: 'wrap',
      color: '#fff',
      fontSize: '10px',
      fontWeight: '700',
      wordBreak: 'keep-all',
      '& > .MuiButton-startIcon': {
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
        width: '100%',
        '& > .MuiSvgIcon-root': {
          fill: '#fff',
          width: 24,
          height: 24,
        },
        '& > .gridIcon': {
          display: 'flex',
          marginBottom: 5,
          '& > svg, span': {
            fontSize: 20,
            maxHeight: 20,
          },
        },
      },
    },
  },
  gridIcon: {
    '& > svg': {
      width: 13,
      display: 'inline-block',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
    },
  },
}));

const FixedFootNav = React.memo((props) => {
  const {
    customer,
    value,
    input,
    functions,
    onSubmit,
    fullScreen,
  } = props;

  const classes = useStyles();

  const [modalState, setModalState] = useState({
    openInfoRegister: false,
    openInstructionsWindow: false,
    openCustomSimpleDrawer: false,
  });

  const handleOpenModal = useCallback((modalName) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: true,
    }));
  }, []);

  const handleCloseModal = useCallback((modalName) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  }, []);

  const handleOpenContactDrawer = useCallback(() => {
    // 個人の顧客情報を取得（メール）
    functions.getCustomerMailFunction(input.customer.customerId);
    handleOpenModal('openCustomSimpleDrawer');
  }, [input.customer]);

  useEffect(() => {
    return () => {
      setModalState({
        openInfoRegister: false,
        openInstructionsWindow: false,
        openCustomSimpleDrawer: false,
      });
    };
  }, []);

  const items = [
    {
      id: 1,
      title: '接触登録',
      onClick: () => handleOpenModal('openInfoRegister'),
      iconTag: <DateRangeOutlinedIcon />,
    },
    {
      id: 2,
      title: '指示登録',
      onClick: () => handleOpenModal('openInstructionsWindow'),
      iconTag: <TouchAppOutlinedIcon />,
    },
  ];

  const customerButtonIconSp = input.customerMail && (
    <CustomerButtonIconSp
      customer={input.customerMail}
      onClose={() => {
        setModalState((prevState) => ({
          ...prevState,
          openCustomSimpleDrawer: false,
        }));
        input.setCustomerMail(null);
      }}
    />
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={fullScreen ? classes.fullappBar : classes.appBar}>
        <List className={classes.list}>
          {items.map((data) => (
            <ListItem key={data.id} className={classes.listItem}>
              <Button
                className={`${classes.button} ${classes.button2}`}
                startIcon={data.iconTag}
                onClick={data.onClick}
              >
                {data.title}
              </Button>
            </ListItem>
          ))}
          {value === 0 && (
            <ListItem className={classes.listItem}>
              <Button
                className={`${classes.button} ${classes.button2}`}
                startIcon={
                  <span className="gridIcon">
                    <CallIcon />
                    <MailOutlineIcon />
                    <Icon>aod</Icon>
                    <ChatBubbleOutlineIcon />
                  </span>
                }
                onClick={handleOpenContactDrawer}
              >
                顧客に連絡
              </Button>
            </ListItem>
          )}
        </List>
      </AppBar>
      {/* 接触登録 */}
      <InfoRegister
        open={modalState.openInfoRegister}
        onOpen={() => handleOpenModal('openInfoRegister')}
        onClose={() => handleCloseModal('openInfoRegister')}
        guidanceId={null} // guidanceId=null: 新規
        customer={customer}
        getActionHistoryListSp={functions.getActionHistoryList}
        actionHistorySearchSp={functions.actionHistorySearch}
      />
      {/* 指示登録 */}
      <InstructionsWindow
        open={modalState.openInstructionsWindow}
        setOpen={(open) => {
          setModalState((prevState) => ({ ...prevState, openInstructionsWindow: open }));
        }}
        onClose={() => handleCloseModal('openInstructionsWindow')}
        directId={customer.directId}
        newInstructionFlg
        isSp={Boolean(true)}
        customerId={customer.customerId}
        customer={customer}
        divisionId={customer.divisionId}
        userId={customer.userId}
        userFirstName={customer.userFirstName}
        userLastName={customer.userLastName}
        onSubmit={onSubmit}
      />
      {/* 顧客に連絡 */}
      <CustomSimpleDrawer
        open={modalState.openCustomSimpleDrawer}
        setOpen={(open) => {
          setModalState((prevState) => ({ ...prevState, openCustomSimpleDrawer: open }));
        }}
        title="顧客に連絡"
        list={customerButtonIconSp}
      />
    </div>
  );
});

export default FixedFootNav;
