// import React, { useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import commonTheme from '../../../styles/theme';
import { useActionSchedule } from '../../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginRight: 2,
    [commonTheme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTypography-root': {
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
      [commonTheme.breakpoints.up('md')]: {
        fontSize: 10,
      },
      '&::before': {
        content: '""',
        display: 'block',
        background: '#aaa',
        width: 16,
        height: 16,
        borderRadius: 2,
        marginRight: 4,
      },
    },
    '& .MuiCheckbox-root': {
      padding: 3,
    },
  },
  checkBoxMain: {
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  checkBoxWrap: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
      padding: '5px 10px',
    },
    '&:nth-child(odd)': {
      [commonTheme.breakpoints.up('md')]: {
        width: '60%',
      },
    },
    '&:nth-child(even)': {
      [commonTheme.breakpoints.up('md')]: {
        width: '40%',
      },
    },
    '&:nth-child(1) span:before': {
      background: '#64CAF5',
    },
    '&:nth-child(2) span:before': {
      background: theme.palette.primaryColor,
    },
    '&:nth-child(3) span:before': {
      background: '#F2994A',
    },
    '&:nth-child(4) span:before': {
      background: '#FCE034',
    },
    '&:nth-child(5) span:before': {
      background: 'linear-gradient(135deg, #D83420 0%, #D83420 50%, #FCE034 50%, #FCE034 100%)',
    },
    '&:nth-child(6) span:before': {
      background: 'linear-gradient(135deg, #27AE60 0%, #27AE60 50%, #FCE034 50%, #FCE034 100%)',
    },
    '&:nth-child(7) span:before': {
      background: '#C8C8C8',
    },
  },
}));

function SortCheckBox() {
  const {
    searchInput,
  } = useActionSchedule();
  const classes = useStyles();

  const CheckChange = (name, checkStatus) => {
    switch (name) {
      case 'atractingCustomers':
        searchInput.setChekedSearch({
          ...searchInput.chekedSearch,
          atractingCustomers: checkStatus,
        });
        searchInput.searchConditionsRef.current.atractingCustomers = checkStatus;
        searchInput.searchStart();
        break;
      case 'pursuit':
        searchInput.setChekedSearch({ ...searchInput.chekedSearch, pursuit: checkStatus });
        searchInput.searchConditionsRef.current.pursuit = checkStatus;
        searchInput.searchStart();
        break;
      case 'business':
        searchInput.setChekedSearch({
          ...searchInput.chekedSearch,
          business: checkStatus,
        });
        searchInput.searchConditionsRef.current.business = checkStatus;
        searchInput.searchStart();
        break;
      case 'guidance':
        searchInput.setChekedSearch({ ...searchInput.chekedSearch, guidance: checkStatus });
        searchInput.searchConditionsRef.current.guidance = checkStatus;
        searchInput.searchStart();
        break;
      case 'achievementsRegistered':
        searchInput.setChekedSearch({
          ...searchInput.chekedSearch,
          achievementsRegistered: checkStatus,
        });
        searchInput.searchConditionsRef.current.achievementsRegistered = checkStatus;
        searchInput.searchStart();
        break;
      case 'contracted':
        searchInput.setChekedSearch({ ...searchInput.chekedSearch, contracted: checkStatus });
        searchInput.searchConditionsRef.current.contracted = checkStatus;
        searchInput.searchStart();
        break;
      case 'others':
        searchInput.setChekedSearch({ ...searchInput.chekedSearch, others: checkStatus });
        searchInput.searchConditionsRef.current.others = checkStatus;
        searchInput.searchStart();
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    CheckChange(event.target.name, event.target.checked);
  };

  const sortCheckList = [
    {
      key: 'atractingCustomers',
      title: '源泉',
      state: searchInput.chekedSearch.atractingCustomers,
    },
    {
      key: 'pursuit',
      title: '追客',
      state: searchInput.chekedSearch.pursuit,
    },
    {
      key: 'business',
      title: '業務',
      state: searchInput.chekedSearch.business,
    },
    {
      key: 'guidance',
      title: '案内',
      state: searchInput.chekedSearch.guidance,
    },
    {
      key: 'achievementsRegistered',
      title: '実績登録済み',
      state: searchInput.chekedSearch.achievementsRegistered,
    },
    {
      key: 'contracted',
      title: '契約済み',
      state: searchInput.chekedSearch.contracted,
    },
    {
      key: 'others',
      title: 'その他',
      state: searchInput.chekedSearch.others,
    },
  ];

  const createCheckBox = sortCheckList.map((data) => (
    <div key={data.key} className={classes.checkBoxWrap}>
      <FormControlLabel
        className={classes.checkBox}
        size="small"
        control={
          <Checkbox
            checked={data.state}
            onChange={handleChange}
            name={data.key}
            color="primary"
            className={classes.checkBoxMain}
          />
        }
        label={data.title}
      />
    </div>
  ));

  return createCheckBox;
}
export default SortCheckBox;
