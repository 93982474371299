import { TableRow, TableCell } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: 'pointer',
    '&:last-of-type': {
      '& > td': {
        borderBottom: 'none',
      },
    },
    '& > td': {
      [commonTheme.breakpoints.up('md')]: {
        padding: '8px 8px 9px',
      },
      [commonTheme.breakpoints.down('sm')]: {
        padding: '18px 16px',
        background: '#fff',
        fontSize: '16px',
        borderBottomColor: '#F3F3F3',
        '&:first-child': {
          fontWeight: '700',
        },
        '&:last-child': {
          width: '75%',
          fontSize: '13px',
          paddingLeft: '0',
        },
      },
    },
    '&:nth-of-type(even)': {
      background: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));

export default function ResultRow(props) {
  const { row, onClickTr, onClose } = props;
  const classes = useStyles();
  const common = commonStyles();

  return (
    <TableRow
      className={classes.tableRow}
      onClick={() => {
        onClickTr(row);
        onClose();
      }}
    >
      <TableCell>{row.zip}</TableCell>
      <TableCell className={common.onlyPc}>{row.prefecture}</TableCell>
      <TableCell className={common.onlyPc}>{row.city}</TableCell>
      <TableCell>{row.town}</TableCell>
    </TableRow>
  );
}
