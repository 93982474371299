/* eslint-disable max-len */
// GA004 案内登録画面 実装ドキュメント (https://openhouse.backlog.jp/alias/wiki/1144646)
import { useMemo, useCallback } from 'react';

// import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import InfoRegisterWrapperOnlyPost from '../../../components/common/infoRegisterOnlyPost/infoRegisterOnlyPost';

// 個別案内情報API
import { setGuidanceByGuidanceIdApi } from '../../../store/schedules/schedulesGetGuidanceByGuidanceIdSlice';
import schedulesGetGuidanceByGuidanceIdApi from '../../../apis/schedules/schedulesGetGuidanceByGuidanceIdApi';
// 案内情報新規登録
import schedulesPostGuidanceApi from '../../../apis/schedules/schedulesPostGuidanceApi';
// 案内情報更新
import schedulesPutGuidanceByGuidanceIdApi from '../../../apis/schedules/schedulesPutGuidanceByGuidanceIdApi';
// 案内情報削除
import schedulesDeleteGuidanceByGuidanceIdApi from '../../../apis/schedules/schedulesDeleteGuidanceByGuidanceIdApi';

// 案内履歴リストAPI
import { setGuidancesByCustomerIdApi } from '../../../store/schedules/schedulesGetGuidancesByCustomerIdSlice';
import schedulesGetGuidancesByCustomerIdApi from '../../../apis/schedules/schedulesGetGuidancesByCustomerIdApi';
import store from '../../../store';
import { useLoading } from '../../../hooks';

// ヘッダーカウント
import getHeaderNumberApi from '../../../apis/common/commonGetHeaderNumberApi';
import { commonGetHeaderNumberApi } from '../../../store/common/commonGetHeaderNumberSlice';

function initialGuidanceAt(hours = 1) {
  const date = new Date();
  date.setMinutes((Math.floor(date.getMinutes() / 30) * 30));
  date.setHours(date.getHours() + hours);
  date.setSeconds(0);
  return moment(date).format('YYYY/MM/DD HH:mm');
}

function InfoRegisterOnlyPostContainers(props) {
  const {
    customer,
    open,
    onOpen,
    onClose,
    guidanceId,
    onCloseTypesDrawer,
    setClickInfoRegister,
    actionId,
    reacquisition,
  } = props;

  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  // user情報をhttpResponseHeaderから取得 (https://openhouse.backlog.jp/alias/wiki/1132107)
  const { responseHeader } = store.getState();

  // ヘッダーカウント
  const getHeaderCountFunction = useCallback(() => {
    getHeaderNumberApi()
      .then((res) => dispatch(commonGetHeaderNumberApi(res.data)))
      .catch((err) => console.error(err));
  });

  /**
   * @module getGuidancesByCustomerIdFunction - get: 顧客毎の案内履歴リスト
   * @param {int} customerId - 顧客ID
   */
  const getGuidancesByCustomerIdFunction = useCallback(async (customerId) => {
    console.log({ getHistoriesReqParam: customerId });
    addLoading('infoRegisterHistory');
    await schedulesGetGuidancesByCustomerIdApi(customerId)
      .then(async (res) => {
        console.log({ guidancesHistoriesRes: res });
        console.log('案内履歴リストAPI');
        await dispatch(setGuidancesByCustomerIdApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        removeLoading('infoRegisterHistory');
      });
  }, []);

  // get: 個別案内情報
  const getGuidanceInfoByGuidanceIdFunction = useCallback(async (guidanceID) => {
    await schedulesGetGuidanceByGuidanceIdApi(guidanceID)
      .then(async (res) => {
        console.log({ guidanceRes: res });
        console.log('個別案内情報API');
        await dispatch(setGuidanceByGuidanceIdApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // post: 案内情報新規登録
  const postGuidanceFunction = async (data) => {
    console.log({ requestData: data });
    await schedulesPostGuidanceApi(data)
      .then(async () => {
        reacquisition();
        // ヘッダーカウントを再取得
        getHeaderCountFunction();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // put: 案内情報更新 schedule004
  const putGuidanceByGuidanceIdFunction = async (guidanceID, data) => {
    console.log({ schedule004Request: data });
    await schedulesPutGuidanceByGuidanceIdApi(guidanceID, data)
      .then(async () => {
        reacquisition();
        // ヘッダーカウントを再取得
        getHeaderCountFunction();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // delete: 案内情報削除
  const deleteGuidanceByGuidanceIdFunction = async (
    guidanceID,
    deleteReasonCode = 2,
  ) => {
    await schedulesDeleteGuidanceByGuidanceIdApi(guidanceID, deleteReasonCode)
      .then(async (res) => {
        console.log({ deletedRes: res });
        reacquisition();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const initial = useMemo(() => {
    return {
      customerName: customer.customerName ? customer.customerName : `${customer.lastName}${customer.firstName}`,
      guidanceGetUserName: responseHeader.userName, // 案内獲得社員Name
      guidanceGetUserId: responseHeader.userId, // 案内獲得社員ID
      guidanceUserName: responseHeader.userName, // 案内社員Name
      guidanceUserId: responseHeader.userId, // 案内社員ID
      guideGetActionId: actionId || '',
      isReserved: 0,
      guidanceStartAt: initialGuidanceAt(1), // 案内時間From
      guidanceEndAt: initialGuidanceAt(3), // 案内時間To
      customerId: customer.customerId,
      guidanceStatusCode: '',
      isGuidanceRegistered: 0,
      guidanceGetDivisionId: responseHeader.mainDivision.id,
      guidanceDivisionId: responseHeader.mainDivision.id,
      memo: '',
      isPairCheck: 0,
      isFinancialPlanner: 0,
      contactCode: '',
      // porterResultCode: 2,
      suggestProducts: [
        // 物件リスト
      ],
    };
  }, [customer, responseHeader, actionId]);

  // 案内情報の初期値
  // const [register, setRegister] = useState(
  //   {
  //     schedulesGetGuidanceByGuidanceId: {
  //       customerName: customer.customerName ? customer.customerName : `${customer.lastName}${customer.firstName}`,
  //       guidanceGetUserName: responseHeader.userName, // 案内獲得社員Name
  //       guidanceGetUserId: responseHeader.userId, // 案内獲得社員ID
  //       guidanceUserName: responseHeader.userName, // 案内社員Name
  //       guidanceUserId: responseHeader.userId, // 案内社員ID
  //       guideGetActionId: '', // 案内獲得対応履歴ID
  //       isReserved: 0,
  //       guidanceStartAt: dateInitial1, // 案内時間From
  //       guidanceEndAt: dateInitial2, // 案内時間To
  //       customerId: customer.customerId,
  //       guidanceStatusCode: '',
  //       isGuidanceRegistered: 0,
  //       guidanceGetDivisionId: '',
  //       guidanceDivisionId: '',
  //       memo: '',
  //       isPairCheck: 0,
  //       isFinancialPlanner: 0,
  //       contactCode: '',
  //       // porterResultCode: 2,
  //       suggestProducts: [
  //         // 物件リスト
  //       ],
  //     },
  //   },
  // );

  const functions = {
    getGuidanceInfoByGuidanceIdFunction,
    postGuidanceFunction,
    putGuidanceByGuidanceIdFunction,
    deleteGuidanceByGuidanceIdFunction,
  };

  return (
    <Grid>
      {open
      && (
        <InfoRegisterWrapperOnlyPost
          functions={functions}
          registerInitial={initial}
          guidanceId={guidanceId}
          // info={register}
          // setInfo={setRegister}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          setClickInfoRegister={setClickInfoRegister}
          onCloseTypesDrawer={onCloseTypesDrawer}
          getGuidancesByCustomerIdFunction={getGuidancesByCustomerIdFunction}
          customer={customer}
          responseHeader={responseHeader}
        />
      )}
    </Grid>
  );
}

export default InfoRegisterOnlyPostContainers;
