import { useState, useEffect } from 'react';
import claimGetListApi from '../../../apis/claim/claimGetListApi';
import claimPostApi from '../../../apis/claim/claimRegisterApi';
import claimUpdateApi from '../../../apis/claim/claimUpdateApi';
import ClaimRegisterWrapper from '../../../components/common/claimRegister/claimRegister';

function ClaimRegisterContainers(props) {
  const {
    open,
    onClose,
    isEdit,
    setStatus,
    responsibleUserId,
    customerId,
  } = props;
  const [list, setList] = useState([]);

  // POST(登録)
  const registerClaim = async (param) => {
    param.customerId = customerId;
    if (responsibleUserId) param.responsibleUserId = responsibleUserId;
    const res = await claimPostApi(param);
    return res;
  };
  // GET(取得)
  const getClaim = async () => {
    const res = await claimGetListApi(customerId);
    setList(res.data.claims || []);
  };
  // PUT クレーム更新
  const putClaim = async (param) => {
    param.customerId = customerId;
    if (responsibleUserId) param.responsibleUserId = responsibleUserId;
    const res = await claimUpdateApi(param);
    return res;
  };

  useEffect(() => {
    // 変更のみクレーム取得APIを叩く
    if (open && !!customerId && isEdit) {
      getClaim();
    } else if (list.length) {
      setList([]);
    }
  }, [open, customerId, isEdit]);

  return (
    <ClaimRegisterWrapper
      open={open}
      onClose={onClose}
      setStatus={setStatus}
      registerClaim={registerClaim}
      putClaim={putClaim}
      list={list}
    />
  );
}

export default ClaimRegisterContainers;
