import { useEffect, useState } from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  onlyPc: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  onlySp: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  parentList: {
    borderBottom: '1px solid #C8C8C8',
    padding: '3px 9px',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 16px',
      backgroundColor: '#F3F3F3',
      borderBottomColor: '#fff',
      '& .MuiListItemText-root': {
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
  },
  childrenListWrap: {
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  childrenList: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0px 0px 0px 16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      float: 'right',
      width: '90%',
      padding: '2px 12px 2px 9px',
      borderBottom: '1px solid #C8C8C8',
    },
    '& .MuiListItemText-root': {
      [commonTheme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '18px 32px 18px 16px',
        borderBottom: '1px solid #F3F3F3',
      },
      '& .MuiTypography-root': {
        [commonTheme.breakpoints.down('sm')]: {
          fontSize: '16px',
        },
      },
    },
  },
  childrenText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '160%',
  },
  arrowInList: {
    color: theme.palette.primaryColor,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ArrowForwardIosIcon: {
    fontSize: 15,
    color: theme.palette.primaryColor,
  },
  open: {
    [commonTheme.breakpoints.down('sm')]: {
      width: 14,
      height: 14,
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      '&::before': {
        content: '""',
        display: 'block',
        background: '#C8C8C8',
        width: '100%',
        height: 2,
        position: 'absolute',
        top: 6,
        left: 0,
      },
      '&::after': {
        content: '""',
        display: 'block',
        background: '#C8C8C8',
        width: 2,
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 6,
      },
    },
  },
}));

export default function RailwayListFiltered(props) {
  const {
    railways = [],
    clickRailwayFunc,
    setOpenedStations,
    setOpenStations,
    selectedItems,
    opened,
  } = props;

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const [open, setOpen] = useState(false);

  const commonClasses = commonStyles();
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (opened) {
      setOpen(true);
    }
  }, [opened]);

  const selectedRailway = selectedItems.railway;

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.parentList}>
        <ListItemText
          primaryTypographyProps={{ className: commonClasses.title4 }}
          primary={railways.key}
        />
        {open ? (
          <ExpandLess className={classes.arrowInList} />
        ) : (
          <ExpandMore className={classes.arrowInList} />
        )}
      </ListItem>
      <Collapse in={open} unmountOnExit timeout={0}>
        <List className={classes.childrenListWrap}>
          {railways.railways.length ? (
            railways.railways.map((railway) => (
              <ListItem
                key={railway.railwayId}
                button
                className={classes.childrenList}
                onClick={() => {
                  if (isSP) {
                    setOpenedStations(true);
                    setOpenStations(true);
                  }
                  clickRailwayFunc(railway);
                }}
                selected={selectedRailway.railwayId === railway.railwayId}
              >
                <ListItemText
                  primary={railway.railwayName}
                  primaryTypographyProps={{ className: classes.childrenText }}
                />
                <ArrowForwardIosIcon className={`${classes.ArrowForwardIosIcon} ${commonClasses.onlyPc}`} />
                <span className={`${classes.open} ${commonClasses.onlySp}`} />
              </ListItem>
            ))
          ) : (
            // 沿線名がない時
            <ListItem button className={classes.childrenList}>
              <ListItemText
                primary="---"
                primaryTypographyProps={{ className: classes.childrenText }}
              />
            </ListItem>
          )}
        </List>
      </Collapse>
    </>
  );
}
