import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const lineGetIdSlice = createSlice({
  name: 'lineGetId',
  initialState: { lineGetId: initialState },
  reducers: {
    lineGetIdApi: (state, data) => {
      state.lineGetId = data.payload;
    },
  },
});

export const { lineGetIdApi } = lineGetIdSlice.actions;

export default lineGetIdSlice.reducer;
