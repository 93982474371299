import { Grid, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MailAppendFile from '../../mail/parts/appendFile';
import MailTextfield from '../../mail/parts/textfield';
import MailIntfield from '../../mail/parts/textfieldInt';
import MailTextfieldButton from '../../mail/parts/textfieldSiteNumber';
import MailTitleAndBody from '../../mail/parts/titleAndBody';
import commonStyles from '../../styles';
import MailChangeTimer from '../../mail/parts/changeTimerBtn';
import { MARKETING_GROUPS } from '../../../constants/userRoleGroup';
import { randomId } from '../../../commonFunction';
import { validateFormInt1 } from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';

export default function LibraryCreateComponent(props) {
  const { data, setData, edit, isDisable = false, errorCallback } = props;
  const common = commonStyles();
  const responseHeader = useSelector((s) => s.responseHeader);
  const [editorState, setEditorState] = useState(data.mailBody ?? '');
  // NOTE: ステート管理はしないので、keyを入れることで意図的にdefault値を更新させる
  const [mailBodyKey, setMailBodyKey] = useState('');
  const setting = {
    title: {
      label: '名前',
      state: data.libraryName,
      setState: (e) => setData({ ...data, libraryName: e }),
      style: { width: '49.5%' },
      require: true,
      disable: isDisable,
      maxLengthInt: 250,
    },
    memo: {
      label: 'メモ',
      state: data.libraryMemo,
      setState: (e) => setData({ ...data, libraryMemo: e }),
      style: { width: '49.5%' },
      disable: isDisable,
      maxLengthInt: null,
    },
    subject: {
      label: '件名',
      state: data.mailSubject,
      setState: (e) => setData({ ...data, mailSubject: e }),
      style: MARKETING_GROUPS.includes(responseHeader.roleGroupId) && data.libraryId ? { width: '49.5%' } : { width: '100%' },
      require: true,
      disable: isDisable,
      maxLengthInt: 250,
    },
    siteNumber: {
      label: '紹介物件',
      state: data.product,
      setState: (e) => setData({ ...data, product: e }),
      style: { width: '49.5%' },
      disable: isDisable,
    },
    productMemo: {
      label: '物件メモ',
      state: data.productMemo,
      setState: (e) => setData({ ...data, productMemo: e }),
      style: { width: '49.5%' },
      disable: isDisable,
      maxLengthInt: null,
    },
    mailBody: {
      state: data.mailBody,
      setState: (e) => setData({ ...data, mailBody: e }),
      wrapper: 'small',
      toolbar: true,
      disable: isDisable,
    },
    attachments: {
      state: data.attachments,
      setState: (e) => setData({ ...data, attachments: e }),
      disable: isDisable,
    },
    limit: {
      label: '制限通数',
      state: data.limit,
      setState: (e) => setData({ ...data, limit: e }),
      disable: isDisable,
      style: { width: '24%', flexWrap: 'nowrap' },
      convertor: convertFormNumber,
      validator: validateFormInt1({
        minFloat: 0.0,
        maxFloat: 5.0,
      }),
    },
    sendStartDate: {
      type: 'sendTime',
      label: '送信開始時間',
      style: { width: '24.5%' },
      state: data.sendStartDate,
      setState: (e) => {
        setData({
          ...data,
          sendStartDate: e.replace(/\//g, '-'),
        });
      },
      disabled: isDisable,
    },
  };

  // 初期化時
  useEffect(() => {
    setEditorState(setting.mailBody.state);
    setMailBodyKey(randomId());
  }, [data.mdailBodyCount]);

  // 本文変更時
  useEffect(() => {
    setting.mailBody.setState(editorState);
  }, [editorState]);

  const now = new Date(); // 現在の日付と時刻を取得
  let minTime = new Date(now);
  let maxTime = new Date(now);
  maxTime.setDate(maxTime.getDate() + 14);

  // レコメンドメール送信時間
  if (data.libraryDirectoryId === 20667) {
    // 今日の9:00のDateオブジェクトを生成
    const nineAM = new Date(now);
    nineAM.setHours(9, 0, 0, 0);
    // 現在時刻が9:00より前か後かをチェック
    minTime = now >= nineAM ? now : nineAM;
    // 当日の23:59のDateオブジェクトを生成
    maxTime = new Date(now);
    maxTime.setHours(23, 59, 0, 0);
  }
  return (
    <>
      <Typography className={common.title4}>
        ライブラリ情報{edit && edit.libraryId ? ` (${edit.libraryId})` : ''}
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        style={{ padding: '8px 0 24px' }}
      >
        <MailTextfield values={setting.title} errorCallback={errorCallback} />
        <MailTextfield values={setting.memo} />
      </Grid>
      <Typography className={common.title4}>登録内容</Typography>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <MailTextfield values={setting.subject} errorCallback={errorCallback} />
        {MARKETING_GROUPS.includes(responseHeader.roleGroupId) && data.libraryId ? (
          <MailIntfield values={setting.limit} />
        ) : ''}
        {MARKETING_GROUPS.includes(responseHeader.roleGroupId) && data.libraryId ? (
          <MailChangeTimer
            values={setting.sendStartDate}
            minDate={minTime}
            maxDate={maxTime}
          />
        ) : ''}
        <MailTextfieldButton values={setting.siteNumber} />
        <MailTextfield values={setting.productMemo} />
        <Grid
          container
          alignItems="stretch"
          justifyContent="space-between"
        >
          <MailTitleAndBody
            key={mailBodyKey}
            initData={setting.mailBody.state}
            setMailBody={setEditorState}
            disable={isDisable}
          />
          <MailAppendFile
            uploadedList={setting.attachments.state}
            setUploadedList={setting.attachments.setState}
            disable={isDisable}
          />
        </Grid>
      </Grid>
    </>
  );
}
