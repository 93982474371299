import { getEnvVariable } from '../../commonFunction/getEnv';

export const LASTA_STAFF_URL = `${getEnvVariable('LASTA_BASE_URL')}member/staff_request/`;

export const PRIVACY_POLICY_PAGE = 'https://openhouse-group.co.jp/privacy.html';

export const TERMS_OF_SERVICE_PAGE = 'https://oh.openhouse-group.com/sp/terms/';

export const MAIL_SUBJECT_TEMPLATE = 'オープンハウスの会員登録をお願いします。';

export const MAIL_FIRST_TEMPLATE = `
オープンハウスにご興味をお持ちいただきまして、誠にありがとうございます。
下記のリンクをクリックして、会員登録を完了してください。
※まだご登録は完了しておりません。

`;

export const MAIL_LAST_TEMPLATE = `

<<ご連絡>>
このメールはシステムで自動送信しています。

※このメールに記載された記事は許可無く転載することを禁じます。

発行：株式会社オープンハウス
`;

export const SMS_LAST_TEMPLATE = `
オープンハウスの会員登録をお願いします。
`;
