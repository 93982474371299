import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import LocalPrintshopOutlinedIcon from '@material-ui/icons/LocalPrintshopOutlined';
import ReactToPrint from 'react-to-print';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import SearchZipsContainer from '../../../containers/search/zips/searchZips';
import icon from '../img/icon.png';
import bg from '../img/bg.png';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import { PREFECTURES_CODE, CURRENT_SITUATION_TYPE } from '../../../constants';
import { convertForm1 } from '../../../commonFunction/convertors';
import { validateFormString1 } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  wrap: {
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  root: {
    width: '100%',
  },
  addressBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  address1: {
    width: 90,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  address2: {
    width: 80,
    height: 25,
  },
  address3: {
    width: 170,
  },
  address4: {
    [commonTheme.breakpoints.up('md')]: {
      width: 280,
      paddingTop: 6,
      // height: 25,
    },
  },
  address5: {
    width: 120,
  },
  addressLbl: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.up('md')]: {
      marginTop: '-4px',
    },
  },
  formControl: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      paddingBottom: 0,
    },
    '& > div': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  addressButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 300,
  },
  button: {
    color: theme.palette.primaryColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 2px',
    textTransform: 'none',
  },
  buttonIcon: {
    fontSize: 16,
    marginBottom: 2,
  },
  textField: {
    display: 'block',
    height: 21,
    marginTop: '-6px',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
    },
    '& div': {
      margin: 0,
      width: '100%',
      textAlign: 'left',
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& label': {
      color: '#C8C8C8',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      transform: 'translate(0, 2px) scale(1)',
      '&.Mui-focused': {
        opacity: 0,
      },
      '&.MuiInputLabel-shrink': {
        opacity: 0,
      },
    },
  },
  textFieldMulti: {
    '& div': {
      width: '100%',
      padding: 0,
    },
    '& > div': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& label': {
      color: '#C8C8C8',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      transform: 'translate(0, 0) scale(1)',
      '&.Mui-focused': {
        opacity: 0,
      },
      '&.MuiInputLabel-shrink': {
        opacity: 0,
      },
    },
  },
  textFieldMultiTxt: {
    height: 21,
    '& > div': { marginTop: 0 },
    '& input': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: '0 0 2px',
    },
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    marginRight: 16,
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  print: {
    padding: '150px 115px 0',
  },
  printIcon: {
    display: 'block',
    width: 150,
    marginBottom: 30,
  },
  printBg: {
    display: 'block',
    margin: '0 auto 10px',
    width: '100%',
  },
  printAddress: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  printName: {
    fontSize: 60,
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: 1.2,
    margin: '20px 0 50px',
  },
  placeholder: { '& .MuiInputBase-root': { color: '#c8c8c8' } },
}));

function CustomerAddress(props) {
  const { input } = props;

  const {
    current,
    original,
    update,
    rerenderKey,
    isSp,
  } = useUpdate(input.customer);

  const listTableRef = useRef();
  const classes = useStyles();
  const baseClasses = commonStyles();

  let contents;

  // 住居種類の初期値を取得
  // abodeNowBuildingTypeはstringのためstringで比較
  const abodeNowBuildingTypeInitial = Object.keys(CURRENT_SITUATION_TYPE).find((key) => {
    return CURRENT_SITUATION_TYPE[key] === current.abodeNowBuildingType;
  }) || '';

  // 郵便番号検索ウィンドウ（表示用）
  const [addressOpen, setAddressOpen] = useState(false);
  const onAddressOpen = () => setAddressOpen(true);
  const onAddressClose = () => setAddressOpen(false);
  // 郵便番号検索ウィンドウ（入力値）
  const [searchZipsInitialParam, setSearchZipsInitialParam] = useState({
    code: current.zip,
    address: (current.prefecture || '') + (current.address1 || '') + (current.address2 || ''),
  });

  // searchZipsのAPI初期化の際叩かないため
  const searchZipsRef = useRef({});
  // 郵便番号ウィンドウが一回も表示してない場合は、
  // SearchZipsContainerのinitialParamに値を代入しない
  searchZipsRef.current = addressOpen || searchZipsRef.current.code ? searchZipsInitialParam : {
    code: null,
    address: '',
  };

  useEffect(() => {
    if (current.zip !== searchZipsInitialParam.zip) {
      setSearchZipsInitialParam({
        ...searchZipsInitialParam,
        code: current.zip,
        address: (current.prefecture || '') + (current.address1 || '') + (current.address2 || ''),
      });
    }
  }, [current.zip]);
  // 郵便番号検索ウィンドウ（出力値を設定）
  const searchZipsOnChangeFunc = (row) => {
    const newData = {
      zip: row.zip,
      prefecture: row.prefecture,
      address1: row.city,
      address2: row.town,
    };
    isChanged(newData, original) && update(newData, true);
  };

  // PC フォーカスが外れた時に更新
  const onBlur = (e) => {
    const { name, value } = e.target;
    if ((original[name] || '') === value) return;
    // 更新処理
    update({ [name]: value });
  };

  // SP 項目選択時に更新
  const onSelect = (prefecture) => {
    if (original.prefecture === prefecture) return;
    update({ prefecture });
  };

  // SP 項目選択時に更新 住居種類
  const onSelectChangeAbodeNowBuildingType = (abodeNowBuildingType) => {
    // コードを文字列に変換
    const stringVal = (Number(abodeNowBuildingType) !== 0)
      ? CURRENT_SITUATION_TYPE[abodeNowBuildingType] : null;
    if (original.abodeNowBuildingType === stringVal) return;
    update({ abodeNowBuildingType: stringVal });
  };

  // PC 都道府県
  const selectChange = (e) => {
    const { name, value: prefecture } = e.target;
    update({ [name]: prefecture });
  };

  // PC 住居種類
  const selectChangeAbodeNowBuildingType = (e) => {
    const { name, value } = e.target;
    const stringVal = (Number(value) !== 0)
      ? CURRENT_SITUATION_TYPE[value] : null;
    update({ [name]: stringVal });
  };

  const customerSelectList = {
    prefectureItem: {
      type: 'selectPlaceholder',
      // type: 'selectBlank',
      name: 'prefecture',
      obj: { menus: PREFECTURES_CODE, title: '都道府県' },
      state: current.prefecture,
      change: selectChange,
      placeholder: '都道府県',
    },
    abodeNowBuildingTypeItem: {
      type: 'selectPlaceholder',
      name: 'abodeNowBuildingType',
      obj: { menus: CURRENT_SITUATION_TYPE, title: '住居種類' },
      state: abodeNowBuildingTypeInitial,
      change: selectChangeAbodeNowBuildingType,
      placeholder: '住居種類',
    },
  };

  const getBox2 = () => {
    return (
      <Select
        value={current.prefecture}
        defaultValue={current.prefecture}
      >
        <MenuItem disabled value={undefined}>未選択</MenuItem>
        {Object.entries(PREFECTURES_CODE).map(([key, val]) => {
          return (
            <MenuItem
              key={key}
              value={val}
              onClick={() => onSelect(val)}
            >
              {val}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox3 = () => {
    return (
      <Select
        value={CURRENT_SITUATION_TYPE[abodeNowBuildingTypeInitial]}
        defaultValue={CURRENT_SITUATION_TYPE[abodeNowBuildingTypeInitial]}
      >
        {Object.entries(CURRENT_SITUATION_TYPE).map(([key, val]) => {
          return (
            <MenuItem
              key={key}
              value={val}
              onClick={() => onSelectChangeAbodeNowBuildingType(key)}
            >
              {val}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getTextField1 = (field) => {
    return (
      <TextBaseField
        key={rerenderKey}
        defaultValue={current[field]}
        className={`${classes.textField}`}
        name={field}
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormString1({ maxLengthInt: 50 })}
        convertor={convertForm1}
      />
    );
  };
  const getTextField2 = (field) => {
    return (
      <TextBaseField
        key={rerenderKey}
        defaultValue={current[field]}
        className={`${classes.textField}`}
        name={field}
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormString1({ maxLengthInt: 250 })}
        convertor={convertForm1}
      />
    );
  };
  // SP 表示用
  const list = [
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '都道府県',
      val: current.prefecture,
      keys: 'prefecture',
      content: getBox2,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '市区町村',
      val: current.address1,
      keys: 'address1',
      content: () => { return getTextField1('address1'); },
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '以下住所',
      val: current.address2,
      keys: 'address2',
      content: () => { return getTextField2('address2'); },
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: 'マンション、部屋番号',
      val: current.building,
      keys: 'building',
      content: () => { return getTextField1('building'); },
    },
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '住居種類',
      val: current.abodeNowBuildingType,
      keys: 'abodeNowBuildingType',
      content: getBox3,
    },
  ];

  // スタータスで表示を分岐
  const getStatusPc = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Grid className={classes.addressBox}>
              <Typography className={baseClasses.title6}>
                現住所
              </Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid>
            <Grid className={classes.addressBox}>
              <Typography className={baseClasses.title6}>
                現住所
              </Typography>
              <Grid className={classes.addressButton}>
                <Button className={classes.button} onClick={onAddressOpen}>
                  <Typography className={baseClasses.title4}>
                    〒
                  </Typography>
                  <Typography className={classes.buttonTxt}>
                    郵便番号検索
                  </Typography>
                </Button>
                <ReactToPrint
                  // eslint-disable-next-line react/no-unstable-nested-components
                  trigger={() => (
                    <Button className={classes.button}>
                      <Typography className={classes.buttonTxt}>
                        宛名印刷
                      </Typography>
                      <LocalPrintshopOutlinedIcon className={classes.buttonIcon} />
                    </Button>
                  )}
                  content={() => listTableRef.current}
                  documentTitle="宛名"
                />
              </Grid>
            </Grid>
            <Grid className={classes.addressBox}>
              <Grid className={`${classes.address2} ${current.prefecture === null && classes.placeholder}`}>
                {input.component(customerSelectList.prefectureItem)}
              </Grid>
              <Grid className={classes.address3}>
                <TextBaseField
                  key={rerenderKey}
                  defaultValue={current.address1}
                  label={current.address1 ? '' : '市区町村'}
                  className={classes.textField}
                  name="address1"
                  onBlur={onBlur}
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  convertor={convertForm1}
                />
              </Grid>
              <Grid className={classes.address3}>
                <form className={classes.textFieldMulti} noValidate autoComplete="off">
                  <TextBaseField
                    key={rerenderKey}
                    defaultValue={current.address2}
                    label={current.address2 ? '' : '以下住所'}
                    className={classes.textFieldMultiTxt}
                    name="address2"
                    onBlur={onBlur}
                    validator={validateFormString1({ maxLengthInt: 250 })}
                    convertor={convertForm1}
                  />
                </form>
              </Grid>
            </Grid>
            <Grid className={classes.addressBox}>
              <Grid className={classes.address4}>
                <form className={classes.textFieldMulti} noValidate autoComplete="off">
                  <TextBaseField
                    defaultValue={current.building}
                    label="マンション、部屋番号"
                    className={classes.textFieldMultiTxt}
                    name="building"
                    onBlur={onBlur}
                    validator={validateFormString1({ maxLengthInt: 50 })}
                    convertor={convertForm1}
                  />
                </form>
              </Grid>
              <Grid className={`${classes.address5} ${current.abodeNowBuildingType === null && classes.placeholder}`}>
                {input.component(customerSelectList.abodeNowBuildingTypeItem)}
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  const getStatusSp = () => {
    switch (current.customerStatusCode) {
      case 11:
        return (
          <Grid>
            <Grid className={classes.addressBox}>
              <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
                住所
              </Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              住所
            </Typography>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
              >
                <Button
                  className={classes.listBtn}
                  onClick={onAddressOpen}
                >
                  <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                    郵便番号
                  </span>
                  <span
                    className={`${baseClasses.title4} ${classes.listVal}`}
                  >
                    {current.zip}
                  </span>
                </Button>
              </ListItem>
              {list.map((item) => {
                return (
                  <ListItem
                    key={item.lbl}
                    className={classes.listItem}
                  >
                    {input.parts(item)}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        );
    }
  };

  if (isSp) {
    contents = (
      <Grid>
        {getStatusSp()}
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        {getStatusPc()}
      </Grid>
    );
  }

  return (
    <Grid className={classes.wrap}>
      {contents}
      <SearchZipsContainer
        onClickTr={searchZipsOnChangeFunc}
        initialParam={searchZipsRef.current}
        setInitialParam={setSearchZipsInitialParam}
        addressOpen={addressOpen}
        onAddressClose={onAddressClose}
      />
      <Grid style={{ display: 'none' }}>
        <Grid innerRef={listTableRef} className={classes.print}>
          <img src={icon} alt="icon" className={classes.printIcon} />
          <img src={bg} alt="bg" className={classes.printBg} />
          <Typography className={classes.printAddress}>
            〒{current.zip}
          </Typography>
          <Typography className={classes.printAddress}>
            {current.address1}{current.address2}{current.building}
          </Typography>
          <Typography className={classes.printName}>
            {current.lastName} {current.firstName} 様
          </Typography>
          <img src={bg} alt="bg" className={classes.printBg} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(CustomerAddress);
