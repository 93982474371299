import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Link,
  ListItem,
  // ListItemText,
  Icon,
  Checkbox,
  TableRow,
  TableCell,
} from '@material-ui/core';
import ImportantCode from '../../common/instructionReport/parts/importantCode';
import DirectReportStatusCode from '../../common/instructionReport/parts/directReportStatusCode';
import InstructionWindow from '../instructionWindow';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: 'pointer',
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '6px 8px',
    textAlign: 'left',
    borderBottom: '1px solid #8C8C8C',
    whiteSpace: 'nowrap',
    '&::after': {
      content: '""',
      display: 'block',
      background: '#C8C8C8',
      width: 1,
      height: 24,
      position: 'absolute',
      top: 6,
      right: 0,
    },
    '&:nth-child(1)': {
      width: 44,
      textAlign: 'left',
      '&::after': {
        content: 'none',
      },
    },
    '&:nth-child(2)': {
      width: 180,
      textAlign: 'left',
    },
    '&:nth-child(3)': {
      width: 180,
      color: theme.palette.primaryColor,
      fontWeight: '700',
      textAlign: 'left',
    },
    '&:nth-child(4)': {
      width: 33,
    },
    '&:nth-child(8)': {
      textAlign: 'left',
    },
    '&:nth-child(9)': {
      width: 180,
      '&::after': {
        content: 'none',
      },
    },
  },
  checkBox2: {
    padding: 0,
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  expiredIcon: {
    display: 'none',
    fontSize: 12,
    color: '#D83420',
    margin: '0px 0 -1px 2px',
    '&.expired': {
      display: 'inline-block',
    },
  },
}));

export default function EnhancedTable(props) {
  const {
    row,
    isItemSelected,
    labelId,
    date1,
    handleClick,
    tab,
    resetRender,
    setResetRender,
  } = props;

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // 顧客詳細表示モーダル
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };

  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  // 期日
  const getExpired = (date) => {
    if (tab === 0 || tab === 1) {
      const date2 = new Date(date.substr(0, 10));
      if (date1 > date2) {
        return 'expired';
      }
    }
    return false;
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.directId}
      selected={isItemSelected}
      className={`${classes.tableRow} ${getExpired(row.expireAt)}`}
    >
      <TableCell className={classes.tableTd}>
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          onClick={(event) => handleClick(event, row.directId)}
          className={classes.checkBox2}
        />
        <InstructionWindow
          open={open}
          setOpen={setOpen}
          onClose={handleClose}
          customerId={row.customerId}
          tab={tab}
          row={row}
          resetRender={resetRender}
          setResetRender={setResetRender}
        />
      </TableCell>
      <TableCell id={labelId} className={`${classes.tableTd}`} onClick={handleOpen}>
        <Icon className={`${classes.expiredIcon} ${getExpired(row.expireAt)}`}>
          local_fire_department
        </Icon>
        {row.expireAt.substr(0, 16)}
      </TableCell>
      <TableCell className={classes.tableTd}>
        <Link
          key={row.directId}
          href={`${URL_MAPPER.customer}?customerId=${row.customerId}`}
          style={{ display: 'block', color: '#0679BF', fontWeight: 'bold' }}
          target="_blank"
        >
          <ListItem button>
            {row.customerLastName} {row.customerFirstName}
          </ListItem>
        </Link>
      </TableCell>
      <TableCell className={classes.tableTd} onClick={handleOpen}>
        <ImportantCode
          code={row.importantCode}
        />
      </TableCell>
      <TableCell className={classes.tableTd} onClick={handleOpen}>
        <DirectReportStatusCode
          code={row.directReportStatusCode}
        />
      </TableCell>
      <TableCell className={classes.tableTd} onClick={handleOpen}>
        {row.directUserLastName} {row.directUserFirstName}
      </TableCell>
      <TableCell className={classes.tableTd} onClick={handleOpen}>
        {row.directedLastName} {row.directedFirstName}
      </TableCell>
      <TableCell className={classes.tableTd} onClick={handleOpen}>
        {row.content}
      </TableCell>
      <TableCell className={classes.tableTd} onClick={handleOpen}>
        {row.updatedAt.substr(0, 16)}
      </TableCell>
    </TableRow>
  );
}
