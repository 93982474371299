import { createSlice } from '@reduxjs/toolkit';

const initialState = { settingGroups: [] };

export const settingGroupsSlice = createSlice({
  name: 'settingGroups',
  initialState,
  reducers: {
    setSettingGroups: (state, { payload }) => {
      state.settingGroups = payload.sort((a, b) => a.displayOrder - b.displayOrder);
    },
    addSettingGroup: (state, { payload }) => {
      state.settingGroups = [...state.settingGroups, payload];
    },
    deleteSettingGroup: (state, { payload }) => {
      state.settingGroups = state.settingGroups.filter((settingGroup) => {
        return settingGroup.storeGroupId !== payload.storeGroupId;
      });
    },
  },
});

export const {
  setSettingGroups,
  addSettingGroup,
  deleteSettingGroup,
} = settingGroupsSlice.actions;

export default settingGroupsSlice.reducer;
