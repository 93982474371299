import React from 'react';
import { Grid, TableCell, Link, makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import NormalTooltip from '../../styles/tooltip';
import MemberStatusCode from '../../eleCommon/memberStatusCode';
import { toSnakeCasePhoneNumber } from '../../../commonFunction/format';

import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  cell: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: 4,
    display: 'block',
    borderBottom: 'none',
    '& > .MuiGrid-root': {
      minHeight: 20,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #C8C8C8',
    },
    '&:nth-child(1)': {
      position: 'relative',
      borderRight: 'none',
      '&::after': {
        content: '""',
        display: 'block',
        background: '#C8C8C8',
        width: 1,
        height: 56,
        position: 'absolute',
        top: 0,
        right: 0,
      },
      // '& .MuiGrid-root': {
      //   textAlign: 'center!important',
      //   transform: 'translateY(12px)',
      // },
    },
    '&:nth-child(4)': {
      fontWeight: 700,
    },
    '&:nth-child(5)': {
      fontWeight: 700,
    },
    '&:nth-last-child(3)': {
      borderRight: 'none',
    },
  },
  center: {
    textAlign: 'center',
  },
  no: {
    minWidth: 24,
    textAlign: 'center',
    marginLeft: 5,
    display: 'inline-block',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'block',
    fontSize: 16,
    color: theme.palette.primaryColor,
    marginLeft: 4,
  },
  hidden: {
    display: 'none',
  },
}));

function CustomCell(props) {
  const { obj, row, index } = props;

  const classes = useStyles();
  let className = classes.cell;
  switch (obj.type) {
    case 'center':
      className += ` ${classes.center}`;
      break;
    default:
  }

  const onIconClick = (e) => {
    e.stopPropagation();
  };

  const getMemberStatus = (memberStatusCode, memberId) => {
    return (
      <Grid className={classes.box}>
        <MemberStatusCode code={memberStatusCode} />
        <Link
          onClick={onIconClick}
          href={`${URL_MAPPER.web}?memberId=${memberId}`}
          target="_blank"
          className={`${classes.memberStatus} ${!memberId ? classes.hidden : ''}`}
        >
          <LaunchIcon className={classes.icon} />
        </Link>
      </Grid>
    );
  };

  let text = '';
  switch (obj.type) {
    case 'no':
      text = index + 1;
      break;
    case 'status':
      text = getMemberStatus(row[obj.key], row.memberId);
      break;
    case 'date':
      if (row[obj.key]) {
        text = row[obj.key].substr(0, 10);
      }
      break;
    default: {
      // Fix: Warning: Failed prop type: The prop `title` is marked as required
      // in `ForwardRef(Tooltip)`,but its value is `null`.
      text = row[obj.key] ?? '';
      if (/phoneNumber/.test(obj.key)) {
        text = toSnakeCasePhoneNumber(text);
      }
    }
  }
  return (
    <NormalTooltip title={text}>
      <TableCell className={className} size="small">
        <Grid
          style={{
            width: obj.width || 'auto',
            textAlign: obj.textAlign || 'left',
          }}
        >
          {text}
        </Grid>
      </TableCell>
    </NormalTooltip>
  );
}

export default CustomCell;
