import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import CustomerOverview from '../../../../../customerMain/parts/customerOverview';
import CustomerMemo from '../../../../../customerMain/parts/customerMemo';
import CustomerLeaderMemo from '../../../../../customerMain/parts/customerLeaderMemo';
import { useLoading, useStore, useRerender } from '../../../../../../hooks';
import commonStyles from '../../../../../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 102,
    paddingBottom: 160,
    minHeight: '100vh',
  },
  buttonWrap: {
    padding: 16,
  },
  button: {
    fontFamily: 'Roboto',
    color: theme.palette.primaryColor,
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    background: '#fff',
    width: '100%',
    padding: '9px 8px 7px',
  },
}));

export default function CustomerInformation(props) {
  const {
    input,
  } = props;

  const { hasLoading } = useLoading();

  const classes = useStyles();
  const common = commonStyles();

  const { responseHeader } = useStore();
  const { roleIds } = responseHeader;

  const { rerender, rerenderKey } = useRerender();

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      // 画面再描画
      rerender();
    } else {
      isFirstRender.current = false;
    }
  }, [input.customer]);

  return (
    <Grid className={`${classes.root} ${hasLoading('getCustomer') ? common.loading : ''}`} key={rerenderKey}>
      <CustomerOverview input={input} />
      <CustomerMemo input={input} />
      {/* 上長メモ利用 */}
      {roleIds.includes(13) && (
        <CustomerLeaderMemo input={input} />
      )}
    </Grid>
  );
}
