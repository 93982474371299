const DRIVE_GROUP_MAP = {
  label: 'ドライブ',
  rows: [
    {
      objs: [
        { label: 'ドライブURL1', columnNames: ['drive_url_1'] },
        { label: 'ドライブURL2', columnNames: ['drive_url_2'] },
        { label: 'ドライブURL3', columnNames: ['drive_url_3'] },
      ],
    },
  ],
};

const NAME_GROUP_MAP = {
  label: '氏名',
  rows: [
    {
      objs: [
        { label: '主 姓', columnNames: ['last_name'] },
        { label: '主 名', columnNames: ['first_name'] },
        { label: '主 姓カナ', columnNames: ['last_name_kana'] },
        { label: '主 名カナ', columnNames: ['first_name_kana'] },
        { label: '主 国籍', columnNames: ['nationality'] },
        { label: '主 永住権', columnNames: ['is_permanent_residency'] },
        { label: '主 年齢', columnNames: ['age'] },
      ],
    },
    {
      objs: [
        { label: '主 性別', columnNames: ['gender_code'] },
        { label: '主 生年月日', columnNames: ['birthday'] },
      ],
    },
    {
      objs: [
        { label: '副 姓', columnNames: ['sub_last_name'] },
        { label: '副 名', columnNames: ['sub_first_name'] },
        { label: '副 姓カナ', columnNames: ['sub_last_name_kana'] },
        { label: '副 名カナ', columnNames: ['sub_first_name_kana'] },
        { label: '副 国籍', columnNames: ['sub_nationality'] },
        { label: '副 永住権', columnNames: ['is_sub_permanent_residency'] },
        { label: '副 年齢', columnNames: ['sub_age'] },
      ],
    },
    {
      objs: [
        { label: '副 性別', columnNames: ['sub_gender_code'] },
        { label: '副 生年月日', columnNames: ['sub_birthday'] },
        { label: '関係性', columnNames: ['relation'] },
      ],
    },
  ],
};
const CONTACT_ADDRESS_GROUP_MAP = {
  label: '連絡先',
  rows: [
    {
      objs: [
        { label: 'お休み', columnNames: [''] },
        { label: '連絡がつきやすい時間帯', columnNames: [''] },
      ],
    },
    {
      objs: [
        { label: '電話1', columnNames: ['phone_number_1'] },
        { label: '電話2', columnNames: ['phone_number_2'] },
        { label: '電話3', columnNames: ['phone_number_3'] },
        { label: '電話4', columnNames: ['phone_number_4'] },
      ],
    },
    {
      objs: [
        { label: '電話1 補足', columnNames: ['phone_number_memo_1'] },
        { label: '電話2 補足', columnNames: ['phone_number_memo_2'] },
        { label: '電話3 補足', columnNames: ['phone_number_memo_3'] },
        { label: '電話4 補足', columnNames: ['phone_number_memo_4'] },
      ],
    },
    {
      objs: [
        { label: 'メールアドレス1', columnNames: ['mail_1'] },
        { label: 'メールアドレス2', columnNames: ['mail_2'] },
        { label: 'メールアドレス3', columnNames: ['mail_3'] },
        { label: 'メールアドレス4', columnNames: ['mail_4'] },
      ],
    },
    {
      objs: [
        { label: 'メールアドレス1 補足', columnNames: ['mail_memo_1'] },
        { label: 'メールアドレス2 補足', columnNames: ['mail_memo_2'] },
        { label: 'メールアドレス3 補足', columnNames: ['mail_memo_3'] },
        { label: 'メールアドレス4 補足', columnNames: ['mail_memo_4'] },
      ],
    },
    {
      objs: [
        { label: 'メールアドレス1 送信許可', columnNames: ['is_mail_send_1'] },
        { label: 'メールアドレス2 送信許可', columnNames: ['is_mail_send_2'] },
        { label: 'メールアドレス3 送信許可', columnNames: ['is_mail_send_3'] },
        { label: 'メールアドレス4 送信許可', columnNames: ['is_mail_send_4'] },
      ],
    },
    {
      objs: [{ label: 'メール許可範囲', columnNames: ['is_allow_dm'] }],
    },
    {
      objs: [{ label: 'FAX', columnNames: ['fax'] }],
    },
  ],
};
const ADDRESS_GROUP_MAP = {
  label: '住所',
  rows: [
    {
      objs: [
        { label: '郵便番号', columnNames: ['zip'] },
        { label: '都道府県', columnNames: ['prefecture'] },
        { label: '市区町村', columnNames: ['address_1'] },
        { label: '以下住所', columnNames: ['address_2'] },
      ],
    },
  ],
};
const FAMILY_GROUP_MAP = {
  label: '家族構成',
  rows: [
    {
      objs: [
        { label: '大人', columnNames: ['adult_count'] },
        { label: '子供', columnNames: ['child_count'] },
        { label: '子供の年齢1', columnNames: ['child_age'] },
        { label: '子供の年齢2', columnNames: ['other_child_age'] },
        { label: '入居予定人数', columnNames: ['family_total_count'] },
        { label: '入居者の予定構成（誰が）', columnNames: ['family_who'] },
      ],
    },
  ],
};
const WORKSPACE_GROUP_MAP = {
  label: '勤務先情報',
  rows: [
    {
      objs: [
        { label: '主 勤務先', columnNames: ['company'] },
        { label: '主 勤務年数', columnNames: ['company_working_year'] },
        { label: '主 勤務先 沿線', columnNames: ['company_railway_id'] },
        { label: '主 勤務先 最寄り駅', columnNames: ['company_station_id'] },
        { label: '主 勤務場所', columnNames: ['company_address'] },
      ],
    },
    {
      objs: [
        { label: '副 勤務先', columnNames: ['sub_company'] },
        { label: '副 勤務年数', columnNames: ['sub_company_working_year'] },
        { label: '副 勤務先 沿線', columnNames: ['sub_company_railway_id'] },
        {
          label: '副 勤務先 最寄り駅',
          columnNames: ['sub_company_station_id'],
        },
        { label: '副 勤務場所', columnNames: ['sub_company_address'] },
      ],
    },
  ],
};
const BUDGED_GROUP_MAP = {
  label: '予算情報',
  rows: [
    {
      objs: [
        { label: '本人年収（万円）', columnNames: ['annual_income'] },
        { label: '合算者（万円）', columnNames: ['sub_annual_income'] },
        { label: '頭金（万円）', columnNames: ['deposit'] },
        { label: '援助（万円）', columnNames: ['assist_amount'] },
        { label: '月々支払（万円）', columnNames: ['monthly_payment'] },
        { label: 'ボーナス（万円）', columnNames: ['bonus_payment'] },
      ],
    },
  ],
};
const WISH_CONDITIONS_GROUP_MAP = {
  label: '希望',
  rows: [
    {
      objs: [
        { label: '希望地域', columnNames: ['wish_area'] },
        { label: '路線（第1希望）', columnNames: ['wish_railway_id_1'] },
        {
          label: '駅（第1希望）',
          columnNames: ['wish_station_id_from_1', 'wish_station_id_to_1'],
        },
        { label: '学区（第1希望）', columnNames: ['wish_school_district_1'] },
        { label: '路線（第2希望）', columnNames: ['wish_railway_id_2'] },
        {
          label: '駅（第2希望）',
          columnNames: ['wish_station_id_from_2', 'wish_station_id_to_2'],
        },
        { label: '学区（第2希望）', columnNames: ['wish_school_district_2'] },
      ],
    },
    {
      objs: [
        { label: '戸建価格（万円）', columnNames: ['detached_house_budget'] },
        { label: '土地価格（万円）', columnNames: ['land_budget'] },
        { label: '戸建広さ（平米）', columnNames: ['living_space'] },
        { label: '間取り', columnNames: ['floor_plan_code'] },
        { label: '土地広さ（平米）', columnNames: ['wish_land_area'] },
      ],
    },
    {
      objs: [
        {
          label: 'これまで見た物件の種類',
          columnNames: ['checked_product_type'],
        },
        {
          label: 'これまで見た物件の数',
          columnNames: ['checked_product_count'],
        },
        { label: '検討のきっかけ', columnNames: ['reason_know_oh'] },
        { label: '入居予定時期', columnNames: ['wish_move_in_day'] },
      ],
    },
    {
      objs: [
        { label: '重視するポイント', columnNames: ['important_point'] },
        { label: '購入目的', columnNames: ['purchase_purpose'] },
        { label: 'メモ', columnNames: ['memo', 'leader_memo', 'direct_memo'] },
      ],
    },
  ],
};
const FLAG_GROUP_MAP = {
  label: 'フラグ',
  rows: [
    {
      objs: [
        { label: '売却', columnNames: ['is_think_sale'] },
        { label: 'マンション', columnNames: ['is_think_apartment'] },
        {
          label: '一棟物投資',
          columnNames: ['is_think_whole_house_investment'],
        },
        { label: '区分投資', columnNames: ['is_think_division_investment'] },
        { label: '建替', columnNames: ['is_think_rebuilding'] },
      ],
    },
  ],
};
const CURRENT_SITUATION_MPA = {
  label: '現況',
  rows: [
    {
      objs: [
        { label: '種類', columnNames: ['abode_now_building_type'] },
        { label: '間取り', columnNames: ['abode_now_floor_plan_code'] },
        { label: '面積（平米）', columnNames: ['abode_now_living_space'] },
        {
          label: '当時の価格（万円）',
          columnNames: ['abode_now_price_at_time'],
        },
        {
          label: '居住期間（年）',
          columnNames: ['abode_now_residence_period'],
        },
        { label: '家賃（万円）', columnNames: ['abode_now_rent'] },
      ],
    },
    {
      objs: [
        {
          label: '売却希望価格（万円）',
          columnNames: ['abode_now_sale_price'],
        },
        { label: '買い替え有無', columnNames: ['abode_now_replacement_code'] },
        { label: '買い替え種類', columnNames: ['abode_now_replace_type'] },
        { label: '借入残年数', columnNames: ['abode_now_current_maturity'] },
        { label: '借入銀行', columnNames: ['abode_now_bank_name'] },
        {
          label: '借入額（万円）',
          columnNames: ['abode_now_borrowing_amount'],
        },
      ],
    },
    {
      objs: [
        { label: '車種', columnNames: ['abode_now_car_type'] },
        { label: '台数（台）', columnNames: ['abode_now_car_count'] },
        { label: '駐車場希望', columnNames: ['is_car_park'] },
      ],
    },
  ],
};
const RESPONSE_INFO_GROUP_MAP = {
  label: '反響情報',
  rows: [
    {
      objs: [
        { label: '獲得者', columnNames: ['acquisition_user'] },
        { label: '獲得者種', columnNames: ['acquisition_code'] },
        { label: '反響日', columnNames: ['response_at'] },
      ],
    },
    {
      objs: [
        { label: '反響媒体（大）', columnNames: ['large_response_type_id'] },
        { label: '反響媒体（中）', columnNames: ['medium_response_type_id'] },
        { label: '反響媒体（小）', columnNames: ['small_response_type_id'] },
        { label: '反響媒体（細）', columnNames: ['fine_response_type_id'] },
        { label: '当社を知ったきっかけ', columnNames: ['reason_know_oh'] },
      ],
    },
  ],
};
const COMPANY_INFO_MAP = {
  label: '社内情報',
  rows: [
    {
      objs: [
        { label: '担当者（組織）', columnNames: ['user_id', 'division_id'] },
        { label: '状態', columnNames: ['customer_status'] },
        { label: '電話ステータス', columnNames: ['tel_status'] },
        { label: 'メール許可', columnNames: ['email_status'] },
        { label: '概要メモ', columnNames: ['summary'] },
      ],
    },
    {
      objs: [
        { label: 'ランク', columnNames: ['user_level'] },
        { label: 'メモ', columnNames: ['memo'] },
        { label: '上長メモ', columnNames: ['leader_memo'] },
      ],
    },
  ],
};
const LABEL_COLUMNNAME_MAP = [
  DRIVE_GROUP_MAP,
  NAME_GROUP_MAP,
  CONTACT_ADDRESS_GROUP_MAP,
  ADDRESS_GROUP_MAP,
  FAMILY_GROUP_MAP,
  BUDGED_GROUP_MAP,
  WORKSPACE_GROUP_MAP,
  WISH_CONDITIONS_GROUP_MAP,
  FLAG_GROUP_MAP,
  CURRENT_SITUATION_MPA,
  RESPONSE_INFO_GROUP_MAP,
  COMPANY_INFO_MAP,
];

export default LABEL_COLUMNNAME_MAP;

export const CUSTOMER_COLUNM_MAP = {
  holiday_weekday: 'お休みの日',
  other_holiday_weekday: '他のお休みの日',
  convenient_time: '連絡がつきやすい時間帯',
  other_convenient_time: '他の連絡がつきやすい時間帯',
  building: '住所・建物名',
  job_type: '職業種',
  sub_job_type: '副・職業種',
  customer_status_code: '顧客状態',
  tel_status_code: '電話番号可否',
  email_status_code: 'メール送信可否',
  system_email_status_code: 'システムメール送信可否',
  last_mail_sent_at: '最終メール送信日時',
  last_mail_read_at: '最終メール既読日時',
  last_phone_talked_at: '最終電話会話日時',
  last_guide_at: '最終案内日時',
  total_guide_count: '総案内回数',
  latitude: '緯度',
  longitude: '経度',
  last_activity_at: '最終行動日時',
  working_style_code: '働き方',
  sub_working_style_code: '副・働き方',
  claim_cancellation_status_code: 'クレーム解除申請ステータス',
  response_id: '反響ID',
  detached_house_budget_min: '戸建希望額下限',
  detached_house_budget_max: '戸建希望額上限',
  land_budget_min: '土地希望額下限',
  land_budget_max: '土地希望額上限',
  reason: '理由',
  is_contract: '契約済フラグ',
  abode_now_purchase_at: '現況の購入年月日',
  response_class: '反響区分',
  acquisition_user_id: '獲得者ID',
  no_contact_detail_reason_code: '概要メモ', // DB的には「追わない詳細理由」
  reason_not_buy: '購入できない理由',
  search_start_at: '探し開始時期',
  reason_not_search: '現在探さない理由',
  search_area: '探しエリア',
  reason_not_ohd: 'OHDがハマらない理由',
  effect_at: '反響基準日時',
  wish_jis_and_area_code: '希望地域のエリアコードとJISコード',
  is_customer_introduction: '顧客紹介対象',
  updated_at: '更新日',
  generation_number: '反響拠点担当世代番号',
};

LABEL_COLUMNNAME_MAP.forEach((group) => {
  group.rows.forEach((row) => {
    row.objs.forEach((obj) => {
      obj.columnNames.forEach((columnName) => {
        CUSTOMER_COLUNM_MAP[columnName] = obj.label;
      });
    });
  });
});
