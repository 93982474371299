import AllocationSettingContainers from '../../containers/allocationSetting/allocationSettingContainers';

function AllocationSetting() {
  return (
    <div>
      <AllocationSettingContainers />
    </div>
  );
}

export default AllocationSetting;
