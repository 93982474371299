import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid,
} from '@material-ui/core';
import InstructionListTableSp from './instructionListTableSp';
import commonStyles from '../../../styles';
import PullRefComponent from '../../../common/pullRefComponent';
import { setRequestParams } from '../../../../store/direct/directGetListSlice';
import { debounce } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  header: {
    padding: '0 16px',
    position: 'sticky',
    top: 0,
    width: '100%',
    background: '#fff',
    zIndex: 5,
    borderBottom: '1px solid rgba(51, 51, 51, .1)',
  },
  headerTtl: {
    textAlign: 'center',
    fontSize: 24,
    padding: '16px 0 8px',
  },
  appBar: {
    color: '#333',
    background: 'none',
    boxShadow: 'none',
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  tabs: {
    minHeight: 'auto',
  },
  tab: {
    width: '50%',
    minWidth: 'auto',
    minHeight: 'auto',
    padding: '14px 0 2px',
    '&.Mui-selected': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
  },
  count: {
    display: 'block',
    textAlign: 'center',
  },
  main: {
    background: '#F6F6F6',
  },
  tabPanel: {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  scrollEl: {
    height: 'calc(100vh - 172px)',
    overflowY: 'auto',
    '.onlyAlert + .allWrapper &': { height: 'calc(100vh - 200px)' },
  },
}));

function TabPanel(props) {
  const {
    value,
    index,
    id1,
    id2,
    count,
    list,
    resetRender,
    setResetRender,
    // getDirectList,
    ...other
  } = props;
  const [refreshing, setRefreshing] = useState(false);
  const [initialCount, setInitialCount] = useState(0); // 読み込みした回数
  const requestParams = useSelector((state) => state.directList.initialRequestParams);
  const dispatch = useDispatch();

  const element = useRef(null); // スクロール要素
  const classes = useStyles();

  // 更新処理
  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    try {
      // let response = await fetch('');
      console.log('最新の情報を取得');
      setRefreshing(false);
    } catch (err) {
      console.error(err);
    }
  }, [refreshing]);

  // コンテンツのスクロール量を検知し、下スクロールで更新カウントを取得
  const handleScroll = debounce(() => {
    const elH = element.current.clientHeight;
    const maxScroll = element.current.scrollHeight;
    const scrollTopVal = element.current.scrollTop;
    const loadingElH = 64; // ローディングアイコン分の高さ
    const diff = maxScroll - elH;

    if (diff - scrollTopVal < loadingElH && count > list.length) {
      setInitialCount(initialCount + 1);
      // eslint-disable-next-line max-len
      const newRequestParams = { ...requestParams, limit: requestParams.limit + 20 };
      // eslint-disable-next-line max-len
      dispatch(setRequestParams(newRequestParams));
      // getDirectList(newRequestParams);
    }
  });

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <PullRefComponent
        refreshing={refreshing}
        onRefresh={handleRefresh}
      >
        {value === index && (
          <Box p={3}>
            <Grid
              className={classes.scrollEl}
              ref={element}
              onScroll={handleScroll}
            >
              <InstructionListTableSp
                tab={index}
                id1={id1}
                id2={id2}
                list={list}
                initialCount={initialCount}
                resetRender={resetRender}
                setResetRender={setResetRender}
              />
            </Grid>
          </Box>
        )}
      </PullRefComponent>
    </Grid>
  );
}

TabPanel.propTypes = {
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function InstructionListTabs(props) {
  const {
    setSelectTab,
    setResetRender,
    resetRender,
    // getDirectList,
  } = props;

  const classes = useStyles();
  const common = commonStyles();
  const list = useSelector((state) => state.directList.responseParams);
  const countsObj = useSelector((state) => state.directList.counts);

  const [value, setValue] = useState(0);
  // const [isLoadingActive, setIsLoadingActive] = useState(false); // ローディング状態
  // 件数
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const label1 = (<span className={classes.tabTtl}><span className={`${common.title2} ${classes.count}`}>{count1}</span>指示</span>);
  const label2 = (<span className={classes.tabTtl}><span className={`${common.title2} ${classes.count}`}>{count2}</span>報告</span>);

  useEffect(() => {
    setCount1(countsObj.totalDirects);
    setCount2(countsObj.totalReports);
  });

  const handleChange = (event, newValue) => {
    setSelectTab(newValue + 1);
    setValue(newValue);
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <Typography className={`${common.title2} ${classes.headerTtl}`}>指示一覧</Typography>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            className={classes.tabs}
          >
            <Tab label={label1} {...a11yProps(1)} className={classes.tab} />
            <Tab label={label2} {...a11yProps(2)} className={classes.tab} />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid className={classes.main}>
        <TabPanel
          value={value}
          index={0}
          id1={1}
          id2={2}
          count={count1}
          className={classes.tabPanel}
          list={list}
          resetRender={resetRender}
          setResetRender={setResetRender}
          // getDirectList={getDirectList}
        />
        <TabPanel
          value={value}
          index={1}
          id1={3}
          count={count2}
          className={classes.tabPanel}
          list={list}
          resetRender={resetRender}
          setResetRender={setResetRender}
          // getDirectList={getDirectList}
        />
      </Grid>
    </Grid>
  );
}
