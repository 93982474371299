import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import CustomerBatch from '../customerBatch';
import KanniKensaku from '../search/kanniKensaku';
import CustomerTree from '../common/customerTree/customerTree';

import { throttle } from '../../commonFunction';

const useStyles = makeStyles({
  root: {
    height: 'calc(100vh - 57px)',
    overflow: 'hidden',
  },
  main: {
    display: 'flex',
    // width: 1920,
    // minWidth: 1920,
    margin: '0 auto',
    width: '100%',
    overflow: 'hidden',
  },
  sideMenu: {
    width: 240,
    // position: 'fixed',
    // top: 51,
    // left: 0,
    // zIndex: 2,
    flexShrink: 0,
  },
  contents: {
    // marginLeft: 240,
    // width: 1680,
    flexGrow: 1,
    overflow: 'auto',
  },
});

// const MemoedCustomerTree = memo(CustomerTree, () => true);

function CustomerBatchPage() {
  const classes = useStyles();
  const responseHeader = useSelector((state) => state.responseHeader);
  const { userId } = responseHeader;

  const [selectObject, setSelectObject] = useState({
    user: null,
    division: null,
    customer: [],
    uniqueId: null,
  });

  const setSelectObjectByKey = (key) => (value) => {
    setSelectObject((prevObj) => ({ ...prevObj, [key]: value }));
  };

  /**
   * 選択されたユーザー更新
   * CustomerTreeのsetSelectCustomerがクリック時に２回走るのでその対応でthrottle使用
   * @param {{customerId: number; customerName: string;}[]} list
   */
  const throttleSetSelectCustomer = useCallback(throttle((list) => {
    setSelectObjectByKey('customer')(list.length === 0 ? [] : list);
  }, 1000), []);

  useEffect(() => {
    setSelectObjectByKey('user')({ userId });
  }, [userId]);

  // 反響ツリーの高さ調整
  const [treeHeight, setTreeHeight] = useState('');
  const [openAccordion, setOpenAccordion] = useState(true);
  const changeAccordion = (d) => {
    setOpenAccordion(d);
  };
  useEffect(() => {
    const accordionHeight = openAccordion ? 464 : 24;
    const height = 144 + accordionHeight;
    setTreeHeight(`calc(100vh - ${height}px)`);
  }, [openAccordion]);

  return (
    <div className={classes.root}>
      <Grid className={classes.main}>
        <Grid className={classes.sideMenu}>
          <KanniKensaku isAvoidSharedCustomer changeAccordion={changeAccordion} />
          <CustomerTree
            searchForm={false}
            setSelectUser={setSelectObjectByKey('user')}
            setSelectDivision={setSelectObjectByKey('division')}
            setSelectCustomer={throttleSetSelectCustomer}
            setSelectUniqueId={setSelectObjectByKey('uniqueId')}
            targetFlgList={{
              userFlg: true,
              divisionFlg: true,
              customerFlg: true,
            }}
            defaultUserId={selectObject.user?.userId}
            defaultDivisionId={
              !selectObject.user?.userId ? selectObject.division?.divId : undefined
            }
            defaultUniqueId={selectObject.uniqueId}
            displayCustomer
            isAvoidSharedCustomer
            treeHeight={treeHeight}
          />
        </Grid>
        <Grid className={classes.contents}>
          <CustomerBatch selectCustomer={selectObject.customer} />
        </Grid>
      </Grid>
    </div>
  );
}

export default CustomerBatchPage;
