import ChangeManageWindowComponents from '../../../../components/common/customer/changeManagerWindow/changeManageWindow';

function ChangeManageWindowContainer(props) {
  const {
    componentLabel,
    open,
    setOpen,
    useCustomBtn,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    handleSelectFunction,
    clickableUserAndDivision,
  } = props;

  return (
    <ChangeManageWindowComponents
      componentLabel={componentLabel}
      open={open}
      setOpen={setOpen}
      useCustomBtn={useCustomBtn}
      selectUser={selectUser}
      setSelectUser={setSelectUser}
      selectDivision={selectDivision}
      setSelectDivision={setSelectDivision}
      handleSelectFunction={handleSelectFunction}
      clickableUserAndDivision={clickableUserAndDivision}
    />
  );
}

export default ChangeManageWindowContainer;
