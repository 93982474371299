/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesGetGuidancesByCustomerIdSlice = createSlice({
  name: 'schedulesGetGuidancesByCustomerId',
  initialState: { schedulesGetGuidancesByCustomerId: initialState },
  reducers: {
    setGuidancesByCustomerIdApi: (state, data) => {
      state.schedulesGetGuidancesByCustomerId = data.payload;
    },
  },
});

export const { setGuidancesByCustomerIdApi } = schedulesGetGuidancesByCustomerIdSlice.actions;

export default schedulesGetGuidancesByCustomerIdSlice.reducer;
