import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateSetting } from '../../../store/divisionMaster/setting';
import { updateUserList } from '../../../store/divisionMaster/userList';
import getUserListApi from '../../../apis/divisionMaster/getUserListApi';
import getSettingApi from '../../../apis/divisionMaster/getSettingApi';
import postUploadCsvApi from '../../../apis/divisionMaster/postUploadCsvApi';

import DivisionUpdate from '../../../components/division/division/divisionUpdate';

import { useStoreGroupsSettingsAreas } from '../../../components/combinationSetting/common/useStoreGroupsSettingsAreas';

import { useLoading, useStore, useRerender } from '../../../hooks';
import { dateFormatter, getNowDate, throttle } from '../../../commonFunction';
import getUserCsvDownloadApi from '../../../apis/divisionMaster/getUserCsvDownloadApi';
import { downloadCSV } from '../../../commonFunction/csv';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import putSettingApi from '../../../apis/divisionMaster/putSettingApi';

function DivisionUpdateContainer() {
  const { responseHeader } = useStore();
  const { areaList } = useStoreGroupsSettingsAreas();
  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  /**
   * @typedef { import(
   *   "../../../apis/divisionMaster/getSettingApiTypes"
   * ).SettingGetResponse } SettingGetResponse
   * @type { SettingGetResponse }
   */
  const setting = useSelector((state) => state.setting);

  /**
   * @typedef { import(
   *   "../../../apis/divisionMaster/getUserListApiTypes"
   * ).UserListGetResponse } UserListGetResponse
   * @type { UserListGetResponse }
   */
  const userList = useSelector((state) => state.userList);

  const getInitialAreaCode = () => {
    return areaList.length !== 0 ? areaList[0].areaCode : undefined;
  };
  /** @type { [areaCode: number, setAreaCode: (areaCode: number) => void] } */
  const [areaCode, setAreaCode] = useState(getInitialAreaCode());

  /**
   * ページネーションの現在のページの番号
   *
   * @type { [currentPageNumber: number, setCurrentPageNumber: (pageNumber: number) => void] }
   */
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  // tableを強制リロードするためのkey
  const { rerender, rerenderKey } = useRerender();

  /**
   * エリアタブの切り替え
   * タブを切り替えるとareaCodeが取得でき、そのareaCodeでAPIを叩く
   *
   * @typedef { import('react').ChangeEvent<{}> } E
   * @type { (_: E; nextTabAreaCode: number;) => void }
   */
  const onChangeTab = useCallback(async (nextTabAreaCode) => {
    setAreaCode(nextTabAreaCode);
    setCurrentPageNumber(0);
  }, []);

  /**
   * divisionMaster005 - 課編成ユーザ異動一覧取得
   *
   * ページネーションの切り替え
   * ページ送り・戻りごとにリクエストが発生する
   *
   * @typedef { import('react').ChangeEvent<{}> } E
   * @type { (_: E; nextTabAreaCode: number;) => Promise<void> }
   */
  const onPageChange = useCallback(async (_, pageNumber) => {
    const userListGetResponse = await getUserListApi({
      areaCode,
      offset: pageNumber * 20,
      limit: 20,
      isCommitted: 1,
    });
    dispatch(updateUserList(userListGetResponse.data));
    setCurrentPageNumber(pageNumber);
  }, [areaCode, dispatch]);

  // 適用日の初期値
  // 分は次の15, 30, 45, 00に合わせる
  const setInitial = () => {
    let initial = getNowDate('YYYY/MM/DD HH:mm');
    const minute = Number(initial.substring(14));
    if (minute >= 0 && minute < 15) {
      initial = `${initial.substring(0, 13)}:15`;
    } else
    if (minute >= 15 && minute < 30) {
      initial = `${initial.substring(0, 13)}:30`;
    } else
    if (minute >= 30 && minute < 45) {
      initial = `${initial.substring(0, 13)}:45`;
    } else {
      const minuteAfter = moment(initial).add(1, 'hours').format('YYYY/MM/DD HH:mm');
      initial = `${minuteAfter.substring(0, 13)}:00`;
    }
    return initial;
  };

  const [initialApplyingAt, setInitialApplyingAt] = useState(
    { assignAt: setInitial() },
  );

  // 「CSVアップロード画面」モーダル
  const [isOpenUploadCsvModal, setIsOpenUploadCsvModal] = useState(false);

  // 「アップロード確認画面」モーダル
  const [isOpenCsvConfirmModal, setIsOpenCsvConfirmModal] = useState(false);

  const getTableInfo = async () => {
    // divisionMaster001 - 課編成基本情報取得
    const settingGetResponse = await getSettingApi(areaCode);
    dispatch(updateSetting(settingGetResponse));
    /**
     * divisionMaster005 - 課編成ユーザ異動一覧取得
     * 本番データを取得
     */
    const userListGetResponse = await getUserListApi({
      areaCode,
      offset: 0,
      limit: 20,
      isCommitted: 1,
    });
    dispatch(updateUserList(userListGetResponse.data));
  };

  /**
   * 「アップロード」ボタンをクリック
   * ※「ダウンロード」ボタンと横並びのボタン
   *
   * @type { () => void }
   */
  const onClickOpenUploadCsvModal = useCallback(() => {
    setIsOpenUploadCsvModal(true);
  }, []);

  /**
   * 「CSVアップロード画面」モーダルを閉じる
   *
   * @type { () => void }
   */
  const onClickCloseUploadCsvModal = useCallback(() => {
    setIsOpenUploadCsvModal(false);
  }, []);

  /**
   * divisionMaster007 - 課編成ユーザ異動情報CSVアップロード
   * 「CSVアップロード画面」モーダルでCSVファイルを「仮アップロード」する
   *
   * @type { () => Promise<Void> }
   */
  const onClickTempUploadCsv = useCallback(async (file) => {
    // 仮アップロード実行時に適用日を反映させる
    if (setting.data.applyingAt !== initialApplyingAt.assignAt) {
      const newSetting = {
        ...setting,
        data: {
          ...setting.data,
          applyingAt: initialApplyingAt.assignAt + ':00',
        },
      };
      dispatch(updateSetting(newSetting));
    }
    await postUploadCsvApi(file, { isCommitted: 0, areaCode });
    setIsOpenCsvConfirmModal(true);
  }, [initialApplyingAt, areaCode]);

  /**
   * divisionMaster007 - 課編成ユーザ異動情報CSVアップロード
   * 「アップロード確認画面」モーダルでCSVファイルを「本番アップロード」する
   *
   * @type { () => Promise<Void> }
   */
  const onClickLiveUploadedCsv = useCallback(throttle(async () => {
    addLoading();
    setIsOpenCsvConfirmModal(false);
    setIsOpenUploadCsvModal(false);
    await postUploadCsvApi(null, {
      isCommitted: 1,
      areaCode,
      applyingAt: setting.data.applyingAt,
    }).catch(() => {
      removeLoading();
    });
    // divisionMaster003
    await putSettingApi({
      divisionMasterList: [{
        areaCode,
        applyingAt: setting.data.applyingAt,
      }],
    }).catch(() => {
      removeLoading();
    });
    getTableInfo().finally(() => {
      removeLoading();
    });
    rerender();
  }, 1000), [areaCode, setting.data]);
  /**
   * CSVの本番アップロードをキャンセルする
   *
   * @type { () => void }
   */
  const onClickCancelLiveUploadCsv = useCallback(() => {
    setIsOpenCsvConfirmModal(false);
  }, []);

  const onChangeApplyingAt = useCallback((changedApplyingAt) => {
    if (changedApplyingAt) {
      const newApplyingAt = changedApplyingAt.replace(/-/g, '/').replace(/T/g, ' ');
      setInitialApplyingAt({ assignAt: newApplyingAt });
    }
  }, [initialApplyingAt]);

  const alert = () => {
    dispatch(
      changeAlertMessage({
        msgList: ['分は00, 15, 30, 45以外を指定できません'],
      }),
    );
  };

  // TODO: 適用日変更時のつなぎ込みに使う
  const calendarData = {
    id: 'modal_tekiyo_id', // String - labelとinputを紐づけます
    label: '適用日', // String - ラベル
    require: true, // Boolean - 必須かどうか
    type: 'time', // String 'date'|'time'|'multitime'|'multiple'
    state: initialApplyingAt, // state
    // 推進権限以外変更不可
    readonly: !responseHeader.roleIds.includes(10),
    setState: onChangeApplyingAt,
    fixedQuarterMinute: true,
    alert,
  };

  // CSVダウンロード
  const handleClickDownload = useCallback(async (downloadType) => {
    addLoading();
    const downLoadFileName = `user-${dateFormatter(
      new Date(),
      'YYYYMMDD',
    )}.csv`;
    await getUserCsvDownloadApi({ areaCode, downloadType })
      .then((res) => {
        downloadCSV(res.data, downLoadFileName);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        removeLoading();
      });
  }, [areaCode]);

  useEffect(() => {
    // 初回表示時とタブ切り替え時にAPIを叩く
    if (!areaCode) return;
    rerender();
    getTableInfo();
  }, [areaCode, dispatch]);

  useEffect(() => {
    setAreaCode(getInitialAreaCode());
  }, [areaList]);

  return (
    <DivisionUpdate
      rerenderKey={rerenderKey}
      areaCode={areaCode}
      onChangeTab={onChangeTab}
      setting={setting}
      userList={userList}
      currentPageNumber={currentPageNumber}
      onPageChange={onPageChange}
      isOpenUploadCsvModal={isOpenUploadCsvModal}
      onClickOpenUploadCsvModal={onClickOpenUploadCsvModal}
      onClickCloseUploadCsvModal={onClickCloseUploadCsvModal}
      isOpenCsvConfirmModal={isOpenCsvConfirmModal}
      onClickTempUploadCsv={onClickTempUploadCsv}
      onClickLiveUploadedCsv={onClickLiveUploadedCsv}
      onClickCancelLiveUploadCsv={onClickCancelLiveUploadCsv}
      calendarData={calendarData}
      handleClickDownload={handleClickDownload}
    />
  );
}

export default DivisionUpdateContainer;
