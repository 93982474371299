import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import NormalTooltip from '../../styles/tooltip';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: 'rgba(51, 51, 51, 0.04)',
    // height: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  responseBox: {
    width: 132,
    '&:not(:last-child)': {
      borderRight: '1px solid #C8C8C8',
    },
  },
  responseLbl: {
    textAlign: 'center',
  },
  responseTxt: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    fontWeight: '700',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 106,
    margin: '0 auto',
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    paddingTop: 16,
  },
  infoItem: {
    margin: '0 16px',
  },
  infoLbl: {
    textAlign: 'center',
    marginBottom: 4,
  },
  infoVal: {
    textAlign: 'center',
  },
  echoMedium: {
    paddingBottom: 18,
  },
  echoMediumTtl: {
    textAlign: 'center',
    marginBottom: 4,
  },
  echoMediumItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  echoMediumTxt: {
    padding: '0 8px',
    textAlign: 'center',
    borderRight: '1px solid #C8C8C8',
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
});

function CustomerResponse(props) {
  const { input } = props;
  const { customer: current } = input;
  const { effectAt } = current;
  // const { effectAt, webs = [] } = current;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  const responseAt = useMemo(() => {
    return (effectAt || '').substring(0, 10);
  }, [effectAt]);

  // const lastWebAccessDate = useMemo(() => {
  //   const mainMember = webs.find((item) => item.isMain) || webs[0] || {};
  //   return mainMember.lastWebAccessDate ?? '';
  // }, [webs]);

  const list1 = [
    {
      lbl: '顧客ID',
      val: current.customerId,
    },
    {
      lbl: 'Web履歴',
      val: current.lastWebAccessAt || '-',
      // val: lastWebAccessDate || '-',
    },
    {
      lbl: '反響日',
      val: responseAt,
    },
  ];

  const list2 = [
    {
      lbl: '媒体(大)',
      val: current.largeResponseTypeName || '-',
    },
    {
      lbl: '媒体(中)',
      val: current.mediumResponseTypeName || '-',
    },
    {
      lbl: '媒体(小)',
      val: current.smallResponseTypeName || '-',
    },
    {
      lbl: '媒体(細)',
      val: current.fineResponseTypeName || '-',
    },
  ];

  if (isSP) {
    contents = (
      <Grid>
        <Grid className={classes.info}>
          {list1.map((item) => {
            return (
              <Grid
                className={classes.infoItem}
                key={item.lbl}
              >
                <Typography className={`${baseClasses.title6} ${classes.infoLbl}`}>
                  {item.lbl}
                </Typography>
                <Typography className={`${baseClasses.title4} ${classes.infoVal}`}>
                  {item.val}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Grid className={classes.echoMedium}>
          <Typography className={`${baseClasses.title6} ${classes.echoMediumTtl}`}>
            反響媒体
          </Typography>
          <Grid className={classes.echoMediumItem}>
            {list2.map((item) => {
              return (
                <Typography
                  key={item.lbl}
                  className={`${baseClasses.title4} ${classes.echoMediumTxt}`}
                >
                  {item.val}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Grid className={classes.responseBox}>
          <Typography className={`${baseClasses.title6} ${classes.responseLbl}`}>
            反響日
          </Typography>
          <NormalTooltip title={responseAt}>
            <Typography className={classes.responseTxt}>
              {responseAt}
            </Typography>
          </NormalTooltip>
        </Grid>
        {list2.map((item) => {
          return (
            <Grid
              key={item.lbl}
              className={classes.responseBox}
            >
              <Typography className={`${baseClasses.title6} ${classes.responseLbl}`}>
                {item.lbl}
              </Typography>
              <NormalTooltip title={item.val ? item.val : ''}>
                <Typography className={classes.responseTxt}>
                  {item.val}
                </Typography>
              </NormalTooltip>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerResponse);
