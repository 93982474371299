import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const responseResisterMediumSlice = createSlice({
  name: 'responseResisterMedium',
  initialState: { responseResisterMedium: initialState },
  reducers: {
    responseResisterMediumApi: (state, data) => {
      state.responseResisterMedium = data.payload;
    },
  },
});

export const { responseResisterMediumApi } = responseResisterMediumSlice.actions;

export default responseResisterMediumSlice.reducer;
