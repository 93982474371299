import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const commonGetHeaderNumberSlice = createSlice({
  name: 'commonGetHeaderNumber',
  initialState: { commonGetHeaderNumber: initialState },
  reducers: {
    commonGetHeaderNumberApi: (state, data) => {
      state.commonGetHeaderNumber = data.payload;
    },
  },
});

export const { commonGetHeaderNumberApi } = commonGetHeaderNumberSlice.actions;

export default commonGetHeaderNumberSlice.reducer;
