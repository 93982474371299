import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import commonStyles from '../../../styles';
import Calendar from '../../../common/calendar';
import { dateFormatter } from '../../../../commonFunction';

const useStyles = makeStyles({
  contentIpt: {
    width: '100%',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
    boxSizing: 'border-box',
  },
  contentIptDate: {
    width: '100%',
    marginTop: '-2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
    '& span': {
      padding: 3,
    },
    '& div': {
      border: 'none',
      padding: 0,
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .MuiTypography-root': {
        marginRight: '50px',
      },
      '& label': {
        minWidth: 40,
        '& span': {
          [commonTheme.breakpoints.down('sm')]: { fontSize: 13, whiteSpace: 'nowrap' },
        },
      },
      '& makeStyles-label-41': {
        marginRight: 10,
      },
      '&.MuiGrid-container': {
        width: '35%',
        [commonTheme.breakpoints.down('sm')]: {
          width: '40%',
        },
        '& label': {
          width: '100%',
        },
        '&:last-of-type': {
          marginRight: '5%',
          [commonTheme.breakpoints.down('sm')]: {
            marginRight: '0',
          },
        },
      },
    },
    '& h6': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 4,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    width: '15%',
    marginRight: '0',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: '#D83420',
      padding: '0',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
    '& span': {
      margin: '0 8px',
    },
  },
  listTxtDate: {
    margin: '0 16px 0 0',
  },
});

function DateField(props) {
  const {
    date1,
    setDate1,
    date2,
    setDate2,
    values,
    setUpdateData,
    // addFlag,
    // inquiredAt,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  // モーダル用
  const [open, setOpen] = useState(false);

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const dateChange1 = (value) => {
    // 現在の日時より過去の場合、実績を表示する
    const startDate = new Date(value);
    // 開始日を設定
    setDate1({ ...date1, assignAt: value });
    // 終了日を設定
    if (Number.isNaN(startDate.getDate())) {
      // 無効な日付の場合
      setDate2({ ...date2, assignAt: '' });
    } else {
      // 有効な日付の場合、2時間プラス
      startDate.setHours(startDate.getHours() + 2);
      setDate2({ ...date2, assignAt: dateFormatter(startDate, 'YYYY/MM/DD hh:mm') });
    }
  };

  const dateChange2 = (value) => {
    // 現在の日時より過去の場合、実績を表示する
    const startDate = new Date(date1.assignAt);
    const endtDate = new Date(value);
    // 終了日を設定
    setDate2({ ...date2, assignAt: value });
    // 終了日が開始日より過去の場合、2時間マイナスした開始日を設定
    if (startDate > endtDate) {
      const change = new Date(value);
      change.setHours(change.getHours() - 2);// 2時間マイナス
      setDate1({ ...date1, assignAt: dateFormatter(change, 'YYYY/MM/DD hh:mm') });
    }
  };

  const datalist1 = {
    id: 'id1', // String - labelとinputを紐づけます
    label: '', // String - ラベル
    require: false, // Boolean - 必須かどうか
    type: 'time', // String 'date'|'time'|'multitime'|'multiple'
    state: date1, // state
    setState: (ev) => { return dateChange1(ev); }, // setState
    readonly: false,
  };

  const datalist2 = {
    id: 'id2', // String - labelとinputを紐づけます
    label: '', // String - ラベル
    require: false, // Boolean - 必須かどうか
    type: 'time', // String 'date'|'time'|'multitime'|'multiple'
    state: date2, // state
    setState: (ev) => { return dateChange2(ev); }, // setState
    readonly: false,
  };
  // 日時の更新
  useEffect(() => {
    const start = new Date(date1.assignAt);
    const end = new Date(date2.assignAt);
    setUpdateData({
      ...values,
      startAt: dateFormatter(start),
      endAt: dateFormatter(end),
    });
  }, [date1, date2]);

  if (isSP) {
    contents = (
      <Grid>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={() => { setOpen(!open); }}
            >
              <Typography className={classes.listTtl}>
                日時
                <span>＊</span>
              </Typography>
              {/* <Typography className={classes.listTxt}>
                <span className={classes.listTxtDate}>
                  {date1.assignAt.replace(/-/g, '/').replace('T', ' ')}
                </span>
                <span>-</span>
                <span>
                  {date2.assignAt.replace(/-/g, '/').replace('T', ' ')}
                </span>
                {values.state1.assignAt.replace(/-/g, '/').replace(/T/g, ' ')}
                <span>-</span>
                {values.state2.assignAt.replace(/-/g, '/').replace(/T/g, ' ')}
              </Typography> */}
              <Grid className={classes.contentIptDate}>
                <Calendar data={datalist1} />
                <span> - </span>
                <Calendar data={datalist2} />
              </Grid>
            </Button>
            {/* <CustomEditDialog
              open={open}
              setOpen={setOpen}
              content={getBox}
              lbl="日時"
            /> */}
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.contentIpt}>
        <Grid className={classes.contentIptDate}>
          <Typography className={`${baseClasses.title6} ${classes.listTtl}`}>
            日時
            <span>＊</span>
          </Typography>
          <Calendar data={datalist1} />
          <span> - </span>
          <Calendar data={datalist2} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default DateField;
