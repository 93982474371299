import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import MailTitleAndBody from './parts/titleAndBody';
import MailSelect from './parts/mailSelect';
import MailTextfield from './parts/textfield';
import MailAppendFile from './parts/appendFile';
import MailRadio from './parts/mailRadio';
import ChangeTo from './parts/changeTo';
import SmsBody from './parts/smsBody';
import { useLoading } from '../../hooks';
import CommonDialog from '../common/modal';
import ChangeSmsContainer from '../../containers/mail/parts/changeSms';

import { randomString } from '../../commonFunction';
import { isOpen } from '../../store/common/apiMessageSlice';
import { validateJsonSize } from '../../commonFunction/validations';
import LibraryCreate from '../mailLibrary/libraryCreate';
import { MAIL_SENDING, TESTMAIL_SENDING } from '../../constants/loading';

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  wrap: {
    padding: '32px 24px 24px',
    justifyContent: 'space-between',
    borderBottom: '1px #c8c8c8 solid',
    borderTop: '1px #c8c8c8 solid',
  },
  blue: {
    color: theme.palette.primaryColor,
    marginRight: '16px',
  },
  none: { display: 'none' },
}));

export default function AfterCallWindowComponent(props) {
  const {
    open,
    user,
    handleClose,
    meta,
    setMeta,
    setting,
    afterCallFunctions,
    responseHeader,
    libraries,
    mailParam,
    setMailParam,
    editorState,
    setEditorState,
    ownDir, // ログインユーザーのライブラリ
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();
  const { addLoading, removeLoading, hasLoading } = useLoading();
  const isInitLoadRef = useRef(false);

  const [isLibraryOpen, setIsLibraryOpen] = useState({ create: false, edit: false });

  const [isDisabled, setIsDisabled] = useState(false);

  // アップロードした配列のAPIが返ってくる
  const [uploadedList, setUploadedList] = useState([]);
  useEffect(() => {
    setMailParam({ ...mailParam, files: uploadedList });
  }, [uploadedList]);

  const [mailBodyKey, setMailBodyKey] = useState('');
  const setEditorStateWrap = (v) => {
    setEditorState(v);
    setMailBodyKey(randomString());
  };

  // メール送信
  const sendMail = () => {
    afterCallFunctions.sendMail(mailParam);
    handleClose();
  };

  // 送信押下時
  const onClickSendBtn = async () => {
    if (meta.type === 0) {
      // sms
      addLoading(MAIL_SENDING);
      await afterCallFunctions.sendSms({
        phoneNumber: meta.sms,
        smstext: meta.content,
        customerId: user.customerId,
        userId: responseHeader.userId,
        divisionId: responseHeader.mainDivision.id,
        smsCustomerTelId: meta.smsId,
      });
      removeLoading(MAIL_SENDING);
      handleClose();
    } else {
      // mail
      addLoading(MAIL_SENDING);
      const sendParam = mailParam;
      sendParam.mailBody = editorState;
      sendParam.mailSubject = meta.title;
      sendParam.mailFromCode = meta.from;
      sendParam.mailTypeCode = 1;
      sendParam.customerIds = [user.customerId];
      // サイズチェック
      const error = validateJsonSize(sendParam);
      if (error) {
        dispatch(isOpen({
          message: error,
          status: 'error',
        }));
        removeLoading(MAIL_SENDING);
        return;
      }
      sendMail(sendParam);
      setTimeout(() => removeLoading(MAIL_SENDING), 2000);
    }
  };

  // テスト送信押下時
  const testSendHandler = () => {
    addLoading(TESTMAIL_SENDING);
    const sendParam = mailParam;
    sendParam.mailBody = editorState;
    sendParam.mailSubject = meta.title;
    sendParam.mailFromCode = meta.from;
    sendParam.mailTypeCode = 1;
    sendParam.customerId = user.customerId;
    delete sendParam.customerIds;
    // サイズチェック
    const error = validateJsonSize(sendParam);
    if (error) {
      dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      removeLoading(TESTMAIL_SENDING);
      return;
    }
    afterCallFunctions.sendTestMail(sendParam);
    setTimeout(() => removeLoading(TESTMAIL_SENDING), 2000);
  };

  useEffect(() => {
    const idDisabledSms = meta.type === 0 && (meta.sms === '' || null);
    const isDisabledMail = meta.type === 1 && (meta.to === '' || null);
    setIsDisabled(idDisabledSms || isDisabledMail);
  }, [meta, isDisabled]);

  useEffect(async () => {
    // ライブラリ取得（初期読み込み）
    if (!open || isInitLoadRef.current) {
      return;
    }
    const { userId } = responseHeader;
    await Promise.all([
      afterCallFunctions.getLibrarySms(userId),
      afterCallFunctions.getLibraryMail(userId),
      afterCallFunctions.getSmsHistory(user.customerId),
    ]);
    isInitLoadRef.current = true;
  }, [open]);

  // メタを同時に変更しようとするとmetaの初期値を参照するため
  // getSmsHistoryでmetaの更新とタイミングをずらず
  useEffect(async () => {
    if (!isInitLoadRef.current) {
      return;
    }
    // 署名リセット
    setMeta({
      ...meta,
      sign: 0,
    });
    if (meta.from === 3) {
      // infoの時
      afterCallFunctions.resetLastUpdateSignature();
      return;
    }
    // リクエスト
    await afterCallFunctions.getSignature({
      mailFromCode: meta.from,
      customerId: user.customerId,
    });
  }, [meta.from, isInitLoadRef.current]);

  return (
    <>
      <CommonDialog
        title="不通時メッセージ送信"
        open={open}
        onClose={handleClose}
        maxWidth={1160}
        buttons={[{
          label: 'テスト送信',
          className: `${classes.blue} ${meta.type === 1 ? '' : classes.none}`,
          onClick: testSendHandler,
          disabled: isDisabled || hasLoading(TESTMAIL_SENDING),
        }, {
          label: '送信',
          className: common.buttonsPrimary,
          onClick: onClickSendBtn,
          disabled: isDisabled || hasLoading(MAIL_SENDING),
        }]}
        buttonClose
      >
        <Grid container justifyContent="space-between">
          <MailSelect values={setting.type} />
          {meta.type === 1
            ? (<MailRadio
                values={setting.kind}
                libraries={libraries}
                meta={meta}
                setMeta={setMeta}
                setUploadedList={setUploadedList}
                setEditorState={setEditorStateWrap}
                libNameEditable={false}
            />) : (<MailRadio
              values={setting.kindSms}
              libraries={libraries}
              meta={meta}
              setMeta={setMeta}
              setEditorState={setEditorStateWrap}
              libNameEditable={false}
            />)}
          {meta.type === 1
            ? <MailSelect values={setting.from} />
            : null}
        </Grid>
        <Grid container justifyContent="space-between">
          {meta.type === 1
            ? <MailSelect values={setting.info} disable={meta.from === 1 || meta.from === 2} />
            : null}
          {meta.type === 1
            ? (
              <ChangeTo
                values={setting.to}
                user={user}
                updateFunction={afterCallFunctions.updateAddress}
              />
            ) : (
              <ChangeSmsContainer
                values={setting.tosms}
                user={user}
              />
            )}
        </Grid>
        <Grid container justifyContent="space-between">
          {meta.type === 1
            ? <MailTextfield values={setting.title} />
            : null}
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
        >
          {meta.type === 1
            ? (<MailTitleAndBody
                key={mailBodyKey}
                initData={setting.content.state}
                setMailBody={setEditorState}
                isActiveSaveTemplate
                getOwnLibrary={afterCallFunctions.getOwnLibrary}
                isLibraryOpen={isLibraryOpen}
                setIsLibraryOpen={setIsLibraryOpen}
            />) : (<SmsBody
              values={setting.contentSms}
            />)}
          {meta.type === 1
            ? <MailAppendFile uploadedList={uploadedList} setUploadedList={setUploadedList} />
            : null}
        </Grid>
      </CommonDialog>
      {/*  ライブラリを保存 */}
      <LibraryCreate
        open={isLibraryOpen}
        onClose={() => { return setIsLibraryOpen({ ...isLibraryOpen, create: false }); }}
        selectDir={ownDir}
        initialMailTitle={setting.title.state}
        initialMailBody={setting.content.state}
      />
    </>
  );
}
