/**
 * @param {Array} arr - [{score:1}, {score:5}, {score:3}]
 * @param {String} key  - 'score'
 * @param {Boolean} isMinus - false
 * @returns [{score:5}, {score:3}, {score:1}]
 */

export const getSortAssociativeArray = (arr, key, isMinus = false) => {
  return arr.sort((init, val) => {
    if (init[key] === null) return 1;
    if (val[key] === null) return -1;
    if (init[key] > val[key]) return isMinus ? 1 : -1;
    if (val[key] > init[key]) return isMinus ? -1 : 1;
    return 0;
  });
};

export const getSortAssociativeMinusRank = (arr, key, isMinus = false) => {
  return arr.sort((init, val) => {
    if (init[key] === null) return -1;
    if (val[key] === null) return 1;
    if (init[key] > val[key]) return isMinus ? 1 : -1;
    if (val[key] > init[key]) return isMinus ? -1 : 1;
    return 0;
  });
};

export const sortCustomerList = (key, list) => {
  const copyCustomers = [...list];
  let result;
  if (key === '0') {
    result = getSortAssociativeArray(copyCustomers, 'effectAt');
  } else if (key === '1') {
    result = getSortAssociativeArray(copyCustomers, 'effectAt', true);
  } else if (key === '2') {
    result = getSortAssociativeArray(copyCustomers, 'lastActivityAt');
  } else if (key === '3') {
    result = getSortAssociativeArray(copyCustomers, 'lastActivityAt', true);
  } else if (key === '4') {
    result = getSortAssociativeArray(copyCustomers, 'userLevel');
  } else if (key === '5') {
    result = getSortAssociativeMinusRank(copyCustomers, 'userLevel', true);
  }
  return result;
};
