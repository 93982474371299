import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import inputNumber from '../../../../commonFunction/inputNumber';
import { TextBaseField } from '../../../eleCommon/validation';
import { validateFormInt1, validateFormString1 } from '../../../../commonFunction/validations';
import { convertForm1, convertFormNumber } from '../../../../commonFunction/convertors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  family: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
  },
  family1: {
    width: 80,
    marginRight: 16,
  },
  family2: {
    width: 80,
    marginRight: 16,
  },
  family3: {
    width: 82,
    marginRight: 16,
  },
  family4: {
    width: 82,
    marginRight: 180,
  },
  family5: {
    width: 156,
    marginRight: 16,
  },
  family6: {
    width: 420,
  },
  textField: {
    display: 'block',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    position: 'relative',
    padding: 0,
    '&:nth-child(even)': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    marginRight: 16,
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function CustomerDetailFamily(props) {
  const {
    input,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // フォーカスが外れた時
  const onBlur = (e) => {
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  const getBox1 = () => {
    return (
      <TextBaseField
        label="大人"
        defaultValue={input.detail.adultCount}
        className={classes.textField}
        type="number"
        InputLabelProps={{ 'data-shrink': true }}
        inputProps={{ min: '0' }}
        name="adultCount"
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('adultCount')}
      />
    );
  };
  const getBox2 = () => {
    return (
      <TextBaseField
        label="子供"
        defaultValue={input.detail.childCount}
        className={classes.textField}
        type="number"
        InputLabelProps={{ 'data-shrink': true }}
        inputProps={{ min: '0' }}
        name="childCount"
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('childCount')}
      />
    );
  };
  const getBox3 = () => {
    return (
      <TextBaseField
        label="子供の年齢1"
        defaultValue={input.detail.childAge}
        className={classes.textField}
        type="number"
        InputLabelProps={{ 'data-shrink': true }}
        inputProps={{ min: '0' }}
        name="childAge"
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('childAge')}
      />
    );
  };
  const getBox4 = () => {
    return (
      <TextBaseField
        label="子供の年齢2"
        defaultValue={input.detail.otherChildAge}
        className={classes.textField}
        type="number"
        InputLabelProps={{ 'data-shrink': true }}
        inputProps={{ min: '0' }}
        name="otherChildAge"
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('otherChildAge')}
      />
    );
  };
  const getBox5 = () => {
    return (
      <TextBaseField
        label="入居予定人数"
        defaultValue={input.detail.familyTotalCount}
        className={classes.textField}
        type="number"
        InputLabelProps={{ 'data-shrink': true }}
        inputProps={{ min: '0' }}
        name="familyTotalCount"
        onBlur={onBlur}
        placeholder="-"
        validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
        convertor={convertFormNumber}
        errorCallback={input.errorCallback('familyTotalCount')}
      />
    );
  };
  const getBox6 = () => {
    return (
      <TextBaseField
        label="入居者の予定構成（誰が）"
        defaultValue={input.detail.familyWho}
        className={classes.textField}
        type="text"
        name="familyWho"
        onBlur={onBlur}
        placeholder="-"
        InputLabelProps={{ 'data-shrink': true }}
        convertor={convertForm1}
        validator={validateFormString1({ maxLengthInt: 50 })}
        errorCallback={input.errorCallback('familyWho')}
      />
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '大人',
      fontSize: '16px',
      val: input.detail.adultCount,
      content: getBox1,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '子供',
      fontSize: '16px',
      val: input.detail.childCount,
      content: getBox2,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '子供の年齢1',
      fontSize: '16px',
      val: input.detail.childAge,
      content: getBox3,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '子供の年齢2',
      fontSize: '16px',
      val: input.detail.otherChildAge,
      content: getBox4,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '入居予定人数',
      fontSize: '16px',
      val: input.detail.familyTotalCount,
      content: getBox5,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '入居者の予定構成（誰が）',
      val: input.detail.familyWho,
      content: getBox6,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          家族構成
        </Typography>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          家族構成
        </Typography>
        <Grid className={classes.family}>
          <Grid className={classes.family1}>
            {getBox1()}
          </Grid>
          <Grid className={classes.family2}>
            {getBox2()}
          </Grid>
          <Grid className={classes.family3}>
            {getBox3()}
          </Grid>
          <Grid className={classes.family4}>
            {getBox4()}
          </Grid>
          <Grid className={classes.family5}>
            {getBox5()}
          </Grid>
          <Grid className={classes.family6}>
            {getBox6()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
