import { createSlice } from '@reduxjs/toolkit';

// TODO: 以下Component開発者テスト用にinitial作成
const initialState = {
  userId: 1,
  activeDirectoryAuthenticationId: '1',
  userCode: '12',
  status: 1,
  lastName: '田中',
  firstName: '太郎',
  lastNameKana: 'タナカ',
  firstNameKana: 'タロウ',
  mail: '123test@example.com',
  mobilePhoneNumber: '08000008888',
  startAt: '2021/12/31',
  endAt: '2022/3/15',
  isHide: 0,
  genderCode: 0,
  retiredMemo: '退職メモテスト',
  recruitedSeason: 4.1,
  memo: 'メモテスト',
  isQualilfication: 0,
  userDivisionCode: 1,
  divisions: [
    {
      isMainDivision: 1,
      divisionId: 1,
      gradeCode: 1,
      startAt: '2022/1/15',
      endAt: '2022/4/15',
    },
  ],
  systems: [
    {
      systemId: 1,
      roleGroupId: 3,
    },
  ],
  userBusinesses: [
    {
      userBusinessId: 1,
      assignAt: '2022/1/15',
      statusCode: 1,
    },
  ],
  divisionHistories: [
    {
      isMainDivision: 1,
      divisionHistorieId: 1,
      divisionId: 1,
      divisionGroupName: 'テスト',
      userRoleGroupId: 1,
      gradeCode: 1,
    },
  ],
};

export const userDetailSlice = createSlice({
  name: 'userDetail',
  initialState: { userDetail: initialState },
  reducers: {
    getUserDetailApi: (state, data) => {
      state.userDetail = data.payload;
    },
  },
});

export const { getUserDetailApi } = userDetailSlice.actions;

export default userDetailSlice.reducer;
