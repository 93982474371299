/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import StatusCode from './statusCode';

// eslint-disable-next-line import/no-cycle
import InfoRegister from '../../infoRegister';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '25px 0 0',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: 8,
    },
  },
  guidances: {
    [commonTheme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  guidanceList: {
    maxHeight: 456,
    overflowY: 'auto',
  },
  guidanceHeader: {
    padding: '12px 0 4px 4px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '2px solid #C8C8C8',
  },
  guidanceHeaderLbl1: {
    [commonTheme.breakpoints.up('md')]: {
      width: 90,
      color: '#8C8C8C',
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: 90,
      paddingRight: '16px',
    },
  },
  guidanceHeaderLbl2: {
    [commonTheme.breakpoints.up('md')]: {
      width: 100,
      color: '#8C8C8C',
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: 60,
      paddingRight: '16px',
      textAlign: 'left',
    },
  },
  guidanceHeaderLbl3: {
    [commonTheme.breakpoints.up('md')]: {
      width: 190,
      color: '#8C8C8C',
    },
  },
  guidanceItem: {
    padding: 4,
    width: '100%',
    borderBottom: '1px solid #C8C8C8',
    borderRadius: '0',
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  guidanceDateTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
  },
  totalTimeLbl: {
    marginBottom: 4,
    textAlign: 'center',
  },
  guidanceDate: {
    paddingRight: 8,
    [commonTheme.breakpoints.up('md')]: {
      width: 90,
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: 90,
    },
    '& > span': {
      whiteSpace: 'nowrap',
    },
  },
  guidanceStatus: {
    paddingRight: 8,
    [commonTheme.breakpoints.up('md')]: {
      width: 100,
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: 60,
      textAlign: 'left',
    },
  },
  guidanceService: {
    [commonTheme.breakpoints.up('md')]: {
      width: 190,
    },
    '& span': {
      display: 'block',
    },
  },
}));

// 日付関連
const formatDateValue = (startAt, endAt = null) => {
  let startDate = null;
  let startTime = null;
  let endDate = null;
  let endTime = null;
  let diff;

  if (startAt) {
    startDate = startAt.split(/\s/)[0];
    // 時分のみに制限（秒を排除）
    startTime = `${startAt.split(/\s/)[1].split(':')[0]}:${startAt.split(/\s/)[1].split(':')[1]}`;
  }

  if (endAt) {
    endDate = endAt.split(/\s/)[0];
    endTime = `${endAt.split(/\s/)[1].split(':')[0]}:${endAt.split(/\s/)[1].split(':')[1]}`;
  }

  if (startAt && endAt) {
    const start = new Date(startAt);
    const end = new Date(endAt);
    diff = end.getTime() - start.getTime();
    // eslint-disable-next-line operator-assignment
    diff = diff / (60 * 60 * 1000);
    // もしも開始日時よりも終了日時の"時"が小さかった場合==日をまたいでいる()
    if (Number(start.getHours()) > Number(end.getHours()) && Number(diff) < 24) {
      endTime = `${24 + Number(endTime.split(':')[0])}:${endTime.split(':')[1]}`;
    } else if (Number(diff) > 24) {
      endTime = `案内接客時間が24時間を超えています:error dateTime=${endAt}`;
    }
  }

  return {
    startDate,
    startTime,
    endDate,
    endTime,
  };
};

// アカウント情報を整形して表示
const getAccountText = (divisionName, accountName) => {
  return [divisionName, accountName].filter(Boolean).join(' ');
};

const compareDate = (dateTime1, dateTime2) => {
  return Number(dateTime2.replace(/[/: ]/g, '')) - Number(dateTime1.replace(/[/: ]/g, ''));
};

function ContactTotalGuidance(props) {
  const {
    guidances = [],
    customer,
  } = props;

  const [selectedGuidance, setSelectedGuidance] = useState(null);

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title3}>
        案内数合計 {guidances.length}件
      </Typography>
      <Grid className={classes.guidances}>
        <Grid className={classes.guidanceHeader}>
          <Typography className={`${baseClasses.title4} ${classes.guidanceHeaderLbl1}`}>
            日時
          </Typography>
          <Typography className={`${baseClasses.title4} ${classes.guidanceHeaderLbl2}`}>
            反応
          </Typography>
          <Typography className={`${baseClasses.title4} ${classes.guidanceHeaderLbl3}`}>
            接客者/案内者
          </Typography>
        </Grid>
        <Grid className={classes.guidanceList}>
          {guidances.sort((a, b) => compareDate(a.startAt, b.startAt)).map((guidance) => (
            <Button key={guidance.guidanceId} className={classes.guidanceItem} onClick={() => setSelectedGuidance(guidance)}>
              <Typography className={`${classes.guidanceDateTxt} ${classes.guidanceDate}`}>
                <span>{formatDateValue(guidance.startAt).startDate}</span><br />
                <span>{formatDateValue(guidance.startAt).startTime}-{formatDateValue(guidance.startAt, guidance.endAt).endTime}</span>
              </Typography>
              <Typography className={`${classes.guidanceDateTxt} ${classes.guidanceStatus}`}>
                <StatusCode
                  code={guidance.statusCode}
                />
              </Typography>
              <Typography className={`${classes.guidanceDateTxt} ${classes.guidanceService}`}>
                <span>
                  {getAccountText(guidance.serviceContactDivisionName, guidance.serviceContactAccountName)}
                </span>
                <span>
                  {getAccountText(guidance.guideContactDivisionName, guidance.guideContactAccountName)}
                </span>
              </Typography>
            </Button>
          ))}
        </Grid>
      </Grid>
      {selectedGuidance ? (
        <InfoRegister
          open={Boolean(selectedGuidance)}
          onClose={() => setSelectedGuidance(null)}
          customer={customer}
          guidanceId={selectedGuidance.guidanceId}
        />
      ) : null}
    </Grid>
  );
}

export default ContactTotalGuidance;
