import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StarRate } from '@material-ui/icons';

const useStyles = makeStyles({
  txt: {
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  starLbl: {
    pointerEvents: 'none',
    height: '100%',
    minHeight: 23,
  },
});

export default function MemberStatusCode(props) {
  const {
    code,
  } = props;

  const classes = useStyles();

  const getUserLevel = () => {
    const result = code ? (
      <div className={classes.txt}>
        <StarRate size="large" className={classes.starLbl} />
        {code}
      </div>
    ) : (
      <div className={classes.txt}>
        <StarRate size="large" className={classes.starLbl} />
        なし
      </div>
    );
    return result;
  };

  return (
    <>
      {getUserLevel()}
    </>
  );
}
