import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const actGetHistoriesSlice = createSlice({
  name: 'actGetHistories',
  initialState: { actGetHistories: initialState },
  reducers: {
    actGetHistoriesApi: (state, data) => {
      state.actGetHistories = data.payload;
    },
  },
});

export const { actGetHistoriesApi } = actGetHistoriesSlice.actions;

export default actGetHistoriesSlice.reducer;
