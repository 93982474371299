import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const simulatorDownLoadSlice = createSlice({
  name: 'simulatorDownLoad',
  initialState: { simulatorDownLoad: initialState },
  reducers: {
    simulatorDownLoadApi: (state, data) => {
      state.simulatorDownLoad = data.payload;
    },
  },
});

export const { simulatorDownLoadApi } = simulatorDownLoadSlice.actions;

export default simulatorDownLoadSlice.reducer;
