import React, { useEffect, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  USER_LEVEL,
} from '../../../../constants';
import CheckBoxLabel from '../../../eleCommon/checkBoxLabel';

const useStyles = makeStyles({
  root: {
    marginBottom: 8,
  },
  box: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px 4px 8px',
    marginBottom: 8,
    height: 'auto',
    '& label': {
      fontSize: 22,
      display: 'flex',
      alignItems: 'center',
    },
  },
  lbl: {
    width: 102,
  },
  star: {
    position: 'relative',
    width: 118,
    height: 20,
    marginLeft: 4,
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  starLbl: {
    background: '#fff',
    position: 'absolute',
    zIndex: '2',
    top: '-2px',
    left: 0,
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    minHeight: 23,
  },
  starSelect: {
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  txt: {
    fontSize: 13,
    lineHeight: '1.6',
    letterSpacing: '0',
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  caption: {
    position: 'relative',
    background: 'rgba(252, 224, 52, 0.24)',
    fontSize: 12,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px 4px 4px 15px',
    display: 'none',
    '&::before': {
      content: '"※"',
      position: 'absolute',
      top: 4,
      left: 3,
    },
    '&.active': {
      display: 'block',
    },
  },
});

export default function BulkChangeWindowRank(props) {
  const classes = useStyles();

  const {
    getComponent,
    checked,
    setChecked,
    star,
    setStar,
  } = props;

  // 非活性
  const [disabled, setDisabled] = useState(true);

  // チェックボックス
  const set = (value) => {
    setChecked(value);
    if (value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  // 表示制御
  const [active, setActive] = useState(false);

  // ランク
  const userLevelChange = (e) => {
    setStar(Number(e.target.value));
  };
  // 表示制御
  useEffect(() => {
    if (star === 2) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [star]);

  const userLevelBlur = (e) => {
    console.log(e.target.value);
  };

  const rankList = {
    rankItem: {
      type: 'selectBack',
      obj: {
        menus: USER_LEVEL,
      },
      state: star,
      change: userLevelChange,
      set: userLevelBlur,
    },
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.box}>
        <Grid className={classes.lbl}>
          <CheckBoxLabel
            label="ランク"
            initial={checked}
            set={set}
          />
        </Grid>
        <Grid className={`${classes.star} ${disabled && 'disabled'}`}>
          <Rating
            name="size-large"
            value={star}
            size="large"
            className={classes.starLbl}
          />
          <Grid className={classes.starSelect}>
            {getComponent(rankList.rankItem)}
          </Grid>
        </Grid>
        <Typography className={`${classes.txt} ${disabled && 'disabled'}`}>に変更する</Typography>
      </Grid>
      <Typography className={`${classes.caption} ${active && 'active'}`}>
        ランクを「★★」に変更すると、メール許可も「代行もすべてOK」に変わります。<br />
        ただし、メール許可が「ダメ全部NG」のお客様はメール許可の変更はありません。
      </Typography>
    </Grid>
  );
}
