import { useState, useEffect } from 'react';
import mailFunctions from '../../../../containers/mail/mailFunctions';
import MailCreateWindow from '../../createWindow';
import EmailSelectListDrawer from './emailSelectListDrawer';

import { dateFormatter } from '../../../../commonFunction';

export default function MailSelectDrawer(props) {
  const { user, open, setOpen, onClose } = props;

  const [checkObj, setCheckObj] = useState({});

  const handleChange = (event) => {
    setCheckObj({ ...checkObj, [event.target.name]: event.target.checked });
  };

  const getAddressesObj = () => {
    const addressesObj = {};
    const addressKeyArray = Object.keys(checkObj).filter((s) => checkObj[s] === true);
    for (const key of addressKeyArray) {
      if (user[key]) addressesObj[key] = user[key];
    }
    return addressesObj;
  };

  const onCreateMailOpen = () => {
    const param = {
      updateType: 0,
      isMailSend1: checkObj.mail1 ? 1 : 0,
      isMailSend2: checkObj.mail2 ? 1 : 0,
      isMailSend3: checkObj.mail3 ? 1 : 0,
      isMailSend4: checkObj.mail4 ? 1 : 0,
      isNotCheckUpdate: 0,
      getDataAt: dateFormatter(new Date()),
    };
    mailFunctions.updateMailAddress(
      user.customerId,
      param,
      undefined,
      undefined,
      {
        isShowMessage: false,
      },
    );
  };

  const okButton = (
    <MailCreateWindow
      user={user}
      btnlabel={<span>OK</span>}
      defaultAddressesObj={getAddressesObj()}
      onOpen={onCreateMailOpen}
      onClose={onClose}
      isLocalStorage
    />
  );

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setCheckObj({
      mail1: Boolean(user.isMailSend1),
      mail2: Boolean(user.isMailSend2),
      mail3: Boolean(user.isMailSend3),
      mail4: Boolean(user.isMailSend4),
    });
  }, [user.customerId, open]);

  return (
    <EmailSelectListDrawer
      user={user}
      open={open}
      okButton={okButton}
      handleClose={handleClose}
      handleChange={handleChange}
      initial={checkObj}
    />
  );
}
