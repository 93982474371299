// TODO: src\constants\index.jsを読み込みたい
import { BUDGET_SELECT, DIRECT_KIND_CODE, CURRENT_SITUATION_TYPE } from '../../constants';
// components/customerMain/

// 不通時機能 options
export const afterCallSelect = {
  1: '不通時機能 ON',
  2: '不通時機能 OFF',
};

// 永住権 options
export const isPermanentResidencySelect = {
  menus: { 0: 'なし', 1: 'あり' },
  title: '永住権',
};

// ランク options
export const rankSelect = {
  menus: {
    0: '★なし',
    1: '★1',
    2: '★2',
    3: '★3',
    4: '★4',
    5: '★5',
  },
  title: 'ランク',
};

// 働き方 options
export const howToWorkSelect = {
  menus: { 0: '　', 1: '在宅', 2: '出社', 3: '在宅/出社' },
  title: '働き方',
};

// 都道府県 options
export const prefectureSelect = {
  menus: {
    1: '北海道',
    2: '青森県',
    3: '岩手県',
    4: '宮城県',
    5: '秋田県',
    6: '山形県',
    7: '福島県',
    8: '茨城県',
    9: '栃木県',
    10: '群馬県',
    11: '埼玉県',
    12: '千葉県',
    13: '東京都',
    14: '神奈川県',
    15: '新潟県',
    16: '富山県',
    17: '石川県',
    18: '福井県',
    19: '山梨県',
    20: '長野県',
    21: '岐阜県',
    22: '静岡県',
    23: '愛知県',
    24: '三重県',
    25: '滋賀県',
    26: '京都府',
    27: '大阪府',
    28: '兵庫県',
    29: '奈良県',
    30: '和歌山県',
    31: '鳥取県',
    32: '島根県',
    33: '岡山県',
    34: '広島県',
    35: '山口県',
    36: '徳島県',
    37: '香川県',
    38: '愛媛県',
    39: '高知県',
    40: '福岡県',
    41: '佐賀県',
    42: '長崎県',
    43: '熊本県',
    44: '大分県',
    45: '宮崎県',
    46: '鹿児島県',
    47: '沖縄県',
  },
  title: '都道府県',
};

// 顧客メインTOP 並び順 options
export const sortOrderSelect = {
  menus: {
    0: '反響日の新しい順',
    1: '反響日の古い順',
    2: '対応日の新しい順',
    3: '対応日の古い順',
    4: '顧客ランクの高い順',
    5: '顧客ランクの低い順',
  },
};

// 顧客メインTOP 並び順 options
export const sortItemSelect = {
  menus: {
    0: '絞り込みなし',
    1: '★5',
    2: '★4',
    3: '★3',
    4: '★2',
    5: '★1',
    6: '★なし',
    7: '1週間以上対応なし',
    8: '3日以内にWebページ閲覧',
  },
};

// 顧客メインTOP 顧客の担当者 options
export const customerSelect = {
  menus: {
    0: '自分が担当',
  },
  title: '顧客の担当者',
};

// 金額（万円） options
export const priceSelect = {
  menus: BUDGET_SELECT,
  title: '金額（万円）',
};

// メール送信可否 options
export const emailStatusCodeSelect = {
  menus: {
    0: 'ダメ全部NG',
    1: '個別のみOK',
    3: '一斉・個別OK',
    5: '代行も全てOK',
  },
  title: 'メール送信可否',
};

// 顧客状態 options
export const customerStatusCodeSelect = {
  menus: {
    1: '追客中',
    2: '追わない',
    10: '契約',
    11: 'クレーム',
  },
  title: '顧客状態',
};

// 種類 options
export const abodeNowBuildingTypeSelect = {
  menus: CURRENT_SITUATION_TYPE,
  title: '種類',
};

// 間取り options
export const abodeNowFloorPlanSelect = {
  menus: {
    1: '1K',
    2: '1LDK',
    4: '2LDK以下',
    8: '2SLDK・3LDK',
    16: '3SLDK・4LDK',
    32: '5LDK以上',
  },
  title: '間取り',
};
export const hopeFloorPlanSelect = {
  menus: {
    4: '2LDK以下',
    8: '2SLDK・3LDK',
    16: '3SLDK・4LDK',
    32: '5LDK以上',
  },
  title: '希望間取り',
};

// 買い替え有無 options
export const isAbodeNowReplacementSelect = {
  menus: {
    0: '買替無',
    1: '買替有',
  },
  title: '買い替え有無',
};

// 「追わない」とした理由 options
export const nonContactReasonCodeSelect = {
  menus: {
    1: '投資目的',
    2: '他決',
    3: 'マンションのみ',
    4: '購入できない',
    5: '売却前提',
    6: 'しばらく買う気がない',
    7: 'OHDにはまらない',
    8: '連絡不可・カマシ',
  },
  title: '「追わない」とした理由',
};

// サイドメニュー options
export const sideMenuList = {
  menus: {
    1: '対応履歴',
    2: '指示報告',
    3: '問合せ物件',
    4: '接触総数',
    5: '情報変更履歴',
  },
  title: 'サイドメニュー',
};

// 短縮入力 options
export const shorteningList = {
  menus: {
    1: '忙しい',
    2: '仕事中',
    3: 'かけ直し',
    4: 'クレーム',
  },
  title: '短縮入力',
};

// 顧客メイン ヘッドタブ options
export const mainHeadMenuTabsList = {
  menus: { 0: 'すべて', 1: '閲覧履歴' },
};

// タブ（上部）メニュー options
export const profileHeadMenuTabsList = {
  menus: {
    1: '顧客情報',
    2: 'メモ',
    3: '対応履歴',
    4: '指示報告',
    5: '情報変更履歴',
    6: 'Web閲覧履歴',
    7: '連絡がつきやすい時間',
    8: '問合せ物件',
    9: '接触総数',
  },
};

//  options
export const InstructionsSelectList = {
  important: [
    {
      val: '3',
      label: '高',
    },
    {
      val: '2',
      label: '中',
    },
    {
      val: '1',
      label: '低',
    },
  ],
  deadLine: [
    {
      val: '0',
      label: '今日中',
    },
    {
      val: '1',
      label: '明日中',
    },
    {
      val: '2',
      label: '一週間以内',
    },
    {
      val: '3',
      label: '指定日時',
    },
  ],
  directKind: Object.entries(DIRECT_KIND_CODE).map(([val, label]) => ({ val, label })),
  directType: [
    {
      val: '1',
      label: '完了にする',
    },
    {
      val: '2',
      label: '承認待ちにする',
    },
  ],
};
