import React, { useState, useCallback } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, Contacts } from '@material-ui/icons';
import commonStyles from '../../../styles';
import UserTree from '../../../common/userTreeNew';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    margin: 8,
    borderRadius: 4,
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  searchIcon: {
    color: '#000',
    marginRight: 4,
  },
  searchButton: {
    width: '100%',
    textAlign: 'left',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  arrowIcon: {
    marginLeft: 'auto',
    fontSize: 24,
    color: theme.palette.primaryColor,
  },
}));

function SearchBoxSp(props) {
  const { setQuery, requestQuery } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const initialUser = { userId: requestQuery.userId };
  const initialDivision = { divId: requestQuery.divisionId };

  // モーダル用のローカルステート
  const [open, setOpen] = useState(false);
  const [selectUser, setSelectUser] = useState(initialUser);
  const [selectDivision, setSelectDivision] = useState(initialDivision);

  const handleClearFunction = useCallback((isSetInital) => {
    if (isSetInital) {
      // 元の選択状態を維持
      return;
    }
    setQuery({});
  }, []);

  const handleSelectFunction = useCallback(() => {
    const tmp = {
      userId: undefined,
      divisionId: selectDivision.divId,
      displayName: selectDivision.divisionName,
    };
    if (selectUser.userId) {
      tmp.userId = selectUser.userId;
      tmp.displayName = `${tmp.displayName}/${selectUser.userName}`;
    }
    setQuery(tmp);
  }, [selectUser, selectDivision]);

  return (
    <>
      <Grid className={classes.searchContainer}>
        <Button onClick={() => setOpen(true)} className={classes.searchButton}>
          <Contacts className={classes.searchIcon} />
          <Grid>
            <Typography className={baseClasses.title4}>
              {requestQuery.displayName}
            </Typography>
          </Grid>
          <ArrowDropDown className={classes.arrowIcon} />
        </Button>
      </Grid>
      <UserTree
        isOpenTree={open}
        setIsOpenTree={setOpen}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        handleSelectFunction={handleSelectFunction}
        handleClearFunction={handleClearFunction}
        isDialog
        isNotSearch
        clickableUserAndDivision
      />
    </>
  );
}

export default SearchBoxSp;
