import { useRef } from 'react';

import { InputBase, makeStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import commonTheme from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: 'inherit',
  },
  search: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #C8C8C8',
    height: '35px',
    margin: '8px 0',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    '&.isTypeDialog': {
      [commonTheme.breakpoints.up('md')]: {
        margin: '0 0 16px',
        '& input': {
          width: '100%',
        },
      },
    },
  },
  searchIcon: {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    [commonTheme.breakpoints.up('md')]: {
      pointerEvents: 'none',
      padding: theme.spacing(0, 2),
      position: 'absolute',
    },
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
    },
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      width: '20ch',
    },
    inputRoot: {
      color: 'inherit',
    },
  },
}));

export default function SearchInput(props) {
  const classes = useStyles();

  const { list, setSearchResult } = props;

  const searchWordRef = useRef('');
  const searchCountRef = useRef(0);
  const matchedCitiesRef = useRef([]);

  const handleEnterPress = (e) => {
    if (!e.target.value || e.key !== 'Enter') return;
    if (searchWordRef.current === e.target.value) {
      searchCountRef.current = matchedCitiesRef.current.length === searchCountRef.current + 1
        ? 0
        : searchCountRef.current + 1;
    } else {
      searchCountRef.current = 0;
      const reg = new RegExp(e.target.value);
      matchedCitiesRef.current = list.filter((obj) => reg.test(obj.label));
    }
    if (matchedCitiesRef.current.length !== 0) {
      setSearchResult([matchedCitiesRef.current[searchCountRef.current]]);
    }
    searchWordRef.current = e.target.value;
  };
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        placeholder="市区町村"
        inputProps={{ 'aria-label': 'search' }}
        onKeyPress={handleEnterPress}
      />
    </div>
  );
}
