import { useState } from 'react';
import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';
import { MAIL_STATUS_CODE } from '../../../constants';
import UnsentMenuPopper from './unsentMenuPopper';
import UnsentDialogConfirmForPC from './unsentDialogConfirmForPC';
import ChangeScheduledTimeToSend from './changeScheduledTimeToSend';

const useStyles = makeStyles((theme) => ({
  row: {
    '&:nth-of-type(even)': { background: '#F3F3F3' },
    '&:hover': { background: `${theme.palette.primaryColor}29` },
  },
  cell: {
    padding: '8px',
    maxWidth: '600px',
  },
  tooltip: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltipStyle: {
    whiteSpace: 'normal',
    maxWidth: '600px',
  },
}));

export default function UnsentListTableRow(props) {
  const {
    row, cells,
    functions, options, tabValue,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const onMouse = (e) => setAnchorEl(e.currentTarget);
  const onMouseLeave = () => setAnchorEl(null);

  const [openSendMail, setOpenSendMail] = useState(false);
  const [openDeleteMail, setOpenDeleteMail] = useState(false);
  const [openSendTimeDialog, setOpenSendTimeDialog] = useState(false);
  const [sendTime, setSendTime] = useState({ deliveryAt: '', deliveryId: '' });
  const [exclusionMailId, setExclusionMailId] = useState(null);
  // 今すぐ送信で更新したメールはレコードから除外する
  if (row.mailQueueId === exclusionMailId) {
    return null;
  }

  return (
    <TableRow
      className={classes.row}
      onMouseEnter={onMouse}
      onMouseLeave={onMouseLeave}
    >
      {cells.map((c) => {
        let result = row[c.id];
        switch (c.id) {
          case 'lastName':
            result = row[c.id] + row.firstName;
            break;
          case 'mailStatusCode':
            // result = '送信待ち';
            result = MAIL_STATUS_CODE[row.mailStatusCode];
            break;
          case 'deliveryAt':
            result = row.deliveryAt;
            break;
          default:
            break;
        }
        return (
          <TableCell className={classes.cell} key={c.id}>
            {result?.length > 48
              ? (
                <NormalTooltip
                  title={result}
                  className={classes.tooltip}
                  classes={{ tooltip: classes.tooltipStyle }}
                >
                  <Typography className={common.small}>
                    {result}
                  </Typography>
                </NormalTooltip>)
              : <Typography className={common.small}>{result}</Typography>}
          </TableCell>
        );
      })}
      <UnsentMenuPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        row={row}
        // id={id}
        setOpenDialog={setOpenSendMail}
        setOpenDialog2={setOpenDeleteMail}
        setOpenSendTimeDialog={setOpenSendTimeDialog}
        setSendTime={setSendTime}
      />
      <UnsentDialogConfirmForPC
        openDialog={openSendMail}
        setOpenDialog={setOpenSendMail}
        functions={functions}
        targetRow={row}
        setExclusionMailId={setExclusionMailId}
        options={options}
        text={
          <Typography className={`${common.bass} ${classes.contentText}`}>
            今すぐ送信開始します。
            <br />
            送信が多い時間帯は、送信開始まで時間がかかる場合があります。
          </Typography>
        }
      />
      <UnsentDialogConfirmForPC
        openDialog={openDeleteMail}
        setOpenDialog={setOpenDeleteMail}
        functions={functions}
        targetRow={row}
        deleteFlg
        tabValue={tabValue}
        options={options}
        text={
          <Typography className={`${common.bass} ${classes.contentText}`}>
            送信予定のメールを削除します。
            <br />
            よろしいですか？
          </Typography>
        }
      />
      <ChangeScheduledTimeToSend
        openDialog={openSendTimeDialog}
        setOpenDialog={setOpenSendTimeDialog}
        sendTime={sendTime}
        setSendTime={setSendTime}
        targetRow={row}
        options={options}
        functions={functions}
      />
    </TableRow>
  );
}
