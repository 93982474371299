import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesGetContactsCountSlice = createSlice({
  name: 'schedulesGetContactsCount',
  initialState: { schedulesGetContactsCount: initialState },
  reducers: {
    schedulesGetContactsCountApi: (state, data) => {
      state.schedulesGetContactsCount = data.payload;
    },
  },
});

export const { schedulesGetContactsCountApi } = schedulesGetContactsCountSlice.actions;

export default schedulesGetContactsCountSlice.reducer;
