import { useState, useEffect } from 'react';

import customerKeymalisContractsApi from '../../../apis/customer/customerKeymalisContracts';

import ContractInformationWrapper from '../../../components/customerMain/contractInformation/contractInformation';

function ContractInformationContainers(props) {
  const {
    open,
    onClose,
    customerId,
  } = props;

  const [list, setList] = useState([]);

  const getKeymalisContracts = () => {
    customerKeymalisContractsApi(customerId)
      .then((res) => setList(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (open) getKeymalisContracts();
  }, [open, customerId]);

  return (
    <ContractInformationWrapper
      list={list}
      open={open}
      onClose={onClose}
    />
  );
}

export default ContractInformationContainers;
