import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const csvResResisterFBSlice = createSlice({
  name: 'csvResResisterFB',
  initialState: { csvResResisterFB: initialState },
  reducers: {
    csvResResisterFBApi: (state, data) => {
      state.csvResResisterFB = data.payload;
    },
  },
});

export const { csvResResisterFBApi } = csvResResisterFBSlice.actions;

export default csvResResisterFBSlice.reducer;
