import { useSelector, useDispatch } from 'react-redux';

/**
 * Storeから一部のデータを取得する
 * @returns {{
 * useDispatch: TDispatch;
 * useSelector: TSelected;
 * responseHeader: Record<string, *>;
 * }}
 */
export default function useStore() {
  const responseHeader = useSelector((state) => state.responseHeader);

  return {
    useDispatch,
    useSelector,
    responseHeader,
  };
}
