/**
 * @module getInstructionsTime - 受け取ったCodeによって対象日時を返却
 * @param {string} timeCode - "0": 今日中, "1": 明日中, "2": 一週間以内
 */
const getInstructionsTime = (timeCode, type, defaultTime) => {
  // timeCodeによって今日+〇日かを判断
  const getAddDay = {
    0: 0,
    1: 1,
    2: 7,
  };

  if (timeCode < 3) {
    const date = new Date();
    // getAddDayで今日からx日先を設定
    date.setDate(date.getDate() + getAddDay[timeCode]);
    const year = date.getFullYear();
    const month = ('00' + (date.getMonth() + 1)).slice(-2);
    const day = ('00' + date.getDate()).slice(-2);
    if (type === 1) {
      return year + '/' + month + '/' + day + ' 21:00';
    } else {
      return new Date(year + '/' + month + '/' + day + ' 21:00');
    }
  } else {
    return defaultTime;
  }
};

export default getInstructionsTime;
