import SelectStoreWindowContainers from '../../../containers/setStoreGroup/editStoreGroup/selectStoreWindow/selectStoreWindowContainers';

function SelectStoreWindow(props) {
  const { open, setOpen, areaCode, setNextRegsit, storeChange, order, initialStore } = props;
  return (
    <SelectStoreWindowContainers
      open={open}
      setOpen={setOpen}
      areaCode={areaCode}
      setNextRegsit={setNextRegsit}
      storeChange={storeChange}
      order={order}
      initialStore={initialStore}
    />
  );
}

export default SelectStoreWindow;
