import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MailLibraryComponent from '../../components/mailLibrary/mailLibraryComponent';
import librarySearchApi from '../../apis/library/librarySearchApi';
import libraryUpdateApi from '../../apis/library/libraryUpdateApi';
import libraryDeleteApi from '../../apis/library/libraryDeleteApi';
import libraryCopyApi from '../../apis/library/libraryCopyApi';
import libraryShareApi from '../../apis/library/libraryShareApi';

import { getLibraryGetSpecifyDirctory } from '../../store/library/libraryGetSpecifyDirctorySlice';

import { getLibrarySearchParam } from '../../commonFunction/library';

import { MAIL_CODE } from '../../constants';

export default function MailLibraryContainer(props) {
  const {
    getTreeList,
    dblclick,
    viewMode,
    selectDir,
    search,
    setSearch,
  } = props;
  const dispatch = useDispatch();
  // ライブラリツリーを保持
  const tree = useSelector(
    (state) => state.librarydirGetDirectlyTree.librarydirGetDirectlyTree,
  );

  // 特殊ディレクトリを保持
  const [specialDir, setSpecialDir] = useState({});

  // PUT /libraries/management/{libraryId} 【library003】ライブラリ情報更新 / 削除時に使用
  const libraryUpdateFunction = async (libraryID, modifyObj) => {
    await libraryUpdateApi(libraryID, modifyObj).catch((err) => console.log(err));
    await getTreeList();
  };

  // DELETE /libraries/management/{libraryId} 【library009】ライブラリ情報削除
  const libraryDeleteFunction = async (libraryID) => {
    await libraryDeleteApi(libraryID).catch((err) => console.log(err));
    await getTreeList();
  };

  // PUT /libraries/management/{libraryId} 【library003】ライブラリ情報更新 / 削除時に使用
  const libraryCopyFunction = async (modifyObj) => {
    await libraryCopyApi(modifyObj).catch((err) => console.log(err));
    await getTreeList();
  };

  // POST /libraries/share 【library006】ライブラリ情報共有
  const libraryShareFunction = async (modifyObj) => {
    await libraryShareApi(modifyObj)
      .then(async (res) => console.log(res))
      .catch((err) => console.log(err));
    await getTreeList();
  };

  // POST /libraries/search/{libraryDirectoryId} 【library008】ライブラリ情報検索
  const librarySearchFunction = async () => {
    const params = getLibrarySearchParam();
    await librarySearchApi(params)
      .then((res) => dispatch(getLibraryGetSpecifyDirctory(res.data)))
      .catch((err) => console.log(err));
  };

  // サイドバー連携：ライブラリツリー取得時
  useEffect(() => {
    if (!tree?.libraryDirectories?.length || Object.keys(specialDir).length) {
      return;
    }
    // 初回選択時のみ：後でゴミ箱のlibraryDirectoryIdを利用する
    for (const [id] of Object.entries(MAIL_CODE)) {
      const dir = tree.libraryDirectories.find(({ mailCode }) => {
        return mailCode === Number(id);
      });
      setSpecialDir((obj) => ({
        ...obj,
        [id]: dir ? dir.libraryDirectoryId : null,
      }));
    }
  }, [tree]);

  return (
    <MailLibraryComponent
      getTreeList={getTreeList}
      dblclick={dblclick}
      viewMode={viewMode}
      search={search}
      setSearch={setSearch}
      selectDir={selectDir}
      specialDir={specialDir}
      librarySearchFunction={librarySearchFunction}
      libraryUpdateFunction={libraryUpdateFunction}
      libraryDeleteFunction={libraryDeleteFunction}
      libraryCopyFunction={libraryCopyFunction}
      libraryShareFunction={libraryShareFunction}
    />
  );
}
