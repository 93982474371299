// https://iwb.jp/javascript-throttle-debounce-event-thinning-process/
// src\containers\flyerSalesList\flyerSalesList.jsに似たような機能があり
export const throttle = (fn, interval) => {
  let lastTime = Date.now() - interval;
  return (...args) => {
    if ((lastTime + interval) < Date.now()) {
      lastTime = Date.now();
      fn(...args);
    }
  };
};

export const debounce = (fn, interval = 5) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, interval);
  };
};
