import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const schedulesGetContactsTimeSlice = createSlice({
  name: 'schedulesGetContactsTime',
  initialState: { schedulesGetContactsTime: initialState },
  reducers: {
    schedulesGetContactsTimeApi: (state, data) => {
      state.schedulesGetContactsTime = data.payload;
    },
  },
});

export const { schedulesGetContactsTimeApi } = schedulesGetContactsTimeSlice.actions;

export default schedulesGetContactsTimeSlice.reducer;
