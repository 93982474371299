import { cloneElement as reactCloneElement } from 'react';
import { isArray, isObject } from '../../../commonFunction';

/**
 * 同じidを持つか
 * @param {*} input - チェック対象
 * @param {*} item - Treeアイテム
 * @returns {boolean}
 */
function isSameId(input, item) {
  // id該当
  if (input.id && input.id === item.id) {
    // parentId該当
    if (input.parentId) {
      return input.parentId === item.parentId;
    }
    // isMain該当
    // isFirstはcustomerTreeからの場合常にtrue（cf. treeList2.js）
    // 他ツリー（userTreeNew/parts/treeList.js）でも影響がない場合はまとめて修正したい
    if ('isMainDivision' in item && input?.isFirst) {
      return item.isMainDivision;
    }
    return true;
  }
  // id該当しない
  return false;
}

/**
 * 同じuniqueId/id/type/parentIdを持つオブジェクトか
 * @param {*} input - チェック対象
 * @param {*} item - Treeアイテム
 * @returns {boolean}
 */
export function isSameTreeItem(input, item) {
  // uniqueId該当(優先)
  if (input.uniqueId) {
    return input.uniqueId === item.uniqueId;
  } else if (input.type) {
    // typeとid該当
    if (input.type === item.type) {
      return isSameId(input, item);
    }
    return false;
  }
  // id該当
  return isSameId(input, item);
}

function findItemsByItem(input, list, arr) {
  if (!isArray(list)) return;
  list.forEach((item) => {
    if (isSameTreeItem(input, item)) {
      arr.push(item);
    } else {
      findItemsByItem(input, item.children, arr);
    }
  });
}

// default選択肢のIDとTreeListの中から同IDを持つ要素をarrにpushし返す
export function findItemsByDefaultSelected(defaultItems, list) {
  if (!isArray(defaultItems) || !isArray(list)) return [];
  const arr = [];
  defaultItems.forEach((item) => {
    findItemsByItem(item, list, arr);
  });
  return arr;
}

export function splitUniqueId(uniqueId, needOwn = false, isSelectAndOpen = false) {
  let currentKey;
  return uniqueId.split('_').reduce((prev, key) => {
    currentKey = (prev.length ? (prev[prev.length - 1] + '_') : '') + key;
    if (isSelectAndOpen) {
      prev.push(currentKey);
    } else if (currentKey !== uniqueId || needOwn) {
      prev.push(currentKey);
    }
    return prev;
  }, []);
}

// <Grid />, <CreateNewFolder />, <DriveFileRenameIcon />の三つがLoop
/**
 * @param {*} el - <Grid>...</Grid> Element children x 2 CreateNewFolder, DriveFileRenameIcon
 * @param  {...Arr} args - {division for tree}
 * @returns {element} - CreateNewFolder, DriveFileRenameIcon内のtargetが該当のdivision要素となる
 * @param {element} children - [<CreateNewFolder />, <DriveFileRenameIcon />]
 */
export function cloneReactElement(el, ...args) {
  if (!el || typeof el !== 'object') return el;
  const { children, onClick } = el.props;
  return reactCloneElement(el, {
    onClick: (e) => onClick?.(e, ...args), // 本関数でやりたいのは、←の...args これで各々のdivisionを入れている。
  }, isArray(children) ? children.map((child) => cloneReactElement(child, ...args)) : children);
}

function search(data, options, arr) {
  if (data.label && data.label.includes(options.keyword)) {
    if (!options.type || options.type === data.type) {
      arr.push(data);
    }
  }
  data.children?.forEach((item) => {
    search(item, options, arr);
  });
}

export function findItemsBySearch(keyword, list) {
  if (!keyword || !keyword.keyword || !isArray(list)) return [];
  const options = !isObject(keyword) ? { keyword } : keyword;
  const arr = [];
  list.forEach(item => {
    search(item, options, arr);
  });
  return arr;
}

export function isDefaultSelectedChanged(current, old) {
  if (!isArray(current) || !isArray(old) || current.length !== old.length) return true;
  return !current.every((cur) => old.some(o => isSameTreeItem(cur, o)));
}

function flatChildren(target, arr = []) {
  if (!arr.some((a) => isSameTreeItem(target, a))) {
    arr.push(target);
  }
  if (target.children?.length) {
    arr.push(target.children);
    target.children.forEach((item) => {
      flatChildren(item, arr);
    });
  }
  return arr;
}

export function checkedAllChildren(target, original) {
  // 選択不能ターゲットは対象外
  if (!target.selectable) return [];
  const arr = isArray(original) ? [...original, target] : [target];
  if (target.children?.length > 0) {
    target.children.forEach((item) => {
      arr.push(...checkedAllChildren(item));
    });
  }
  return arr;
}

function findParent(target, list, parent = null) {
  if (!isArray(list)) return null;
  let item;
  for (let i = 0; i < list.length; i += 1) {
    item = list[i];
    if (isSameTreeItem(item, target)) return parent;
    const temp = findParent(target, item.children, item);
    if (temp) return temp;
  }
  return null;
}

export function uncheckedAllChildren(target, original, list) {
  const arr = [];
  // targetの全てのアイテム
  const items = flatChildren(target);
  original.forEach((o) => {
    if (!items.some(item => isSameTreeItem(o, item))) {
      arr.push(o);
    }
  });
  // 兄弟Nodeチェック
  const parent = findParent(target, list);
  const noChildrenChecked = !parent?.children.some(item => arr.some(a => isSameTreeItem(a, item)));
  if (parent && noChildrenChecked) {
    const index = arr.findIndex((a) => isSameTreeItem(a, parent));
    if (index !== -1) arr.splice(index, 1);
  }
  return arr;
}

/**
 * @description - 対象要素の下部組織に組織が存在しているか
 * @param {object} - {type: 'division', id: 1732, name: '東京1部', ...}
 * @returns {boolean}
 */
export const isHaveDivision = (obj) => {
  for (const child of obj.children) {
    if (child.type === 'division') return true;
  }
  return false;
};
