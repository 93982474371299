import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../styles';
import {
  CUSTOMER_STATUS_CODE,
} from '../../constants';

const useStyles = makeStyles((theme) => ({
  status: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
    '& .MuiIcon-root': {
      fontSize: 20,
    },
  },
  status1: {
    color: theme.palette.primaryColor,
  },
  status2: {
    color: '#D83420',
  },
  status3: {
    color: '#D83420',
  },
  status4: {
    color: '#27AE60',
  },
  txt: {
    marginLeft: 4,
  },
}));

export default function CustomerStatusCode(props) {
  const {
    code,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const getCustomerStatus = () => {
    switch (code) {
      case 1:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            <DirectionsRunIcon className={classes.statusIcon} />
            <Typography className={`${baseClasses.title4} ${classes.txt}`}>
              {CUSTOMER_STATUS_CODE[code]}
            </Typography>
          </Grid>
        );
      case 2:
        return (
          <Grid className={`${classes.status} ${classes.status2}`}>
            <BlockIcon className={classes.statusIcon} />
            <Typography className={`${baseClasses.title4} ${classes.txt}`}>
              {CUSTOMER_STATUS_CODE[code]}
            </Typography>
          </Grid>
        );
      case 10:
        return (
          <Grid className={`${classes.status} ${classes.status4}`}>
            <Icon className={classes.statusIcon}>verified</Icon>
            <Typography className={`${baseClasses.title4} ${classes.txt}`}>
              {CUSTOMER_STATUS_CODE[code]}
            </Typography>
          </Grid>
        );
      case 11:
        return (
          <Grid className={`${classes.status} ${classes.status3}`}>
            <FlashOnIcon className={classes.statusIcon} />
            <Typography className={`${baseClasses.title4} ${classes.txt}`}>
              {CUSTOMER_STATUS_CODE[code]}
            </Typography>
          </Grid>
        );
      default:
        return (
          <span>該当なし</span>
        );
    }
  };

  return (
    <>
      {getCustomerStatus()}
    </>
  );
}
