import client from '../httpClient';

// 【hpInfo006】物件閲覧履歴取得API
// http://10.22.2.48:8080/docs/#/members/get-members-products-histories
const hpinfoGetProductsHistoriesApi = async (params) => {
  try {
    const res = await client.get('/members/products-histories', { params });
    return res.data;
  } catch (e) {
    console.error(e);
    return {
      total: 0,
      histories: [],
    };
  }
};

export default hpinfoGetProductsHistoriesApi;
