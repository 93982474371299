import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import DayRow from './dayRow';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    position: 'relative',
    boxShadow: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      overflowX: 'visible',
    },
  },
  table: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  tbody: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  label: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:first-child': {
      '& td': {
        paddingTop: 8,
      },
      '& .MuiIconButton-label': {
        marginTop: 2,
      },
    },
  },
  labelTd: {
    position: 'relative',
    padding: '22px 8px 8px 18px',
    borderBottom: 'none',
    '& > span': {
      marginLeft: 20,
    },
    '& > span > span': {
      fontSize: 13,
    },
  },
  labelBtn: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    '& .MuiIconButton-label': {
      display: 'block',
      textAlign: 'left',
      marginTop: 15,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: theme.palette.primaryColor,
      margin: '0 0 0 22px',
    },
  },
  contents: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  contentsTd: {
    padding: 0,
    borderBottom: 'none',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  contentsBox: {
    position: 'relative',
    margin: 0,
  },
  contentsList: {
    padding: 0,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  timeLine: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'block',
    width: '100%',
    height: 1,
    background: '#333',
    position: 'absolute',
    zIndex: 1,
    left: 0,
  },
  timeLineDot: {
    display: 'block',
    width: 7,
    height: 7,
    borderRadius: '50%',
    background: '#333',
    position: 'absolute',
    zIndex: 1,
    top: '-3px',
    left: 0,
  },
}));

function Row(props) {
  const {
    row,
    classes,
    baseClasses,
    setOpenCustomertree,
    countUpParent,
    actionsDay,
    targetDivisionTree,
    timeLineTop,
  } = props;

  const [open, setOpen] = useState(true);

  let divisions = [];

  if (actionsDay) {
    // 組織IDで絞り込み
    divisions = actionsDay.divisions.filter((division) => {
      return row.id === division.divisionId;
    });
  }

  // 案内件数（子組織単位）
  const [num, setNum] = useState(0);
  let numCount = 0;
  const countUpChild = (c) => {
    numCount += c;
  };
  useEffect(() => {
    setNum(numCount);
    countUpParent(numCount);
  }, [targetDivisionTree, divisions]);

  return (
    <>
      <TableRow className={`${classes.label} rndDay-table-label`}>
        <TableCell
          colSpan={27}
          className={`${baseClasses.title3} ${classes.labelTd}`}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={classes.labelBtn}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span>{row.name}<span>（案内 : {num}）</span></span>
        </TableCell>
      </TableRow>
      <TableRow className={`${classes.contents} rndDay-table-contents`}>
        <TableCell colSpan={27} className={`${classes.contentsTd} rndDay-table-contentsTd`}>
          <Collapse in={open} timeout={0} unmountOnExit>
            <Box margin={1} className={classes.contentsBox}>
              <List className={`${classes.contentsList} rndDay-table-contentsList`}>
                {row.users?.map((action) => (
                  <DayRow
                    key={action.userId}
                    action={action}
                    divisions={divisions}
                    setOpenCustomertree={setOpenCustomertree}
                    countUpChild={countUpChild}
                  />
                ))}
                <DayRow
                  action={{ userName: '担当なし' }}
                  divisions={divisions}
                  setOpenCustomertree={setOpenCustomertree}
                  countUpChild={countUpChild}
                  noChargeFlg
                />
              </List>
              <span className={`${classes.timeLine} rndDay-timeLine`} style={{ top: timeLineTop }}>
                <span className={classes.timeLineDot} />
              </span>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function DayTable(props) {
  const { setOpenCustomertree, countUpParent } = props;

  const {
    actionsDay,
    searchInput,
    targetDivisionTree,
    nowDate,
    rerender,
    rerenderKey,
  } = useActionSchedule();

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // 子組織に紐づくユーザーを表示
  const getChildren = (rows) => {
    return rows.map((row) => (
      <Row
        key={`actionScheduleDayRecord-${row.id}`}
        row={row}
        classes={classes}
        baseClasses={baseClasses}
        setOpenCustomertree={setOpenCustomertree}
        countUpParent={countUpParent}
        actionsDay={actionsDay}
        searchInput={searchInput}
        targetDivisionTree={targetDivisionTree}
      />
    ));
  };

  // 時間のライン
  const [timeLineTop, setTimeLineTop] = useState(100);
  useEffect(() => {
    const hours = nowDate.getHours();
    const Minutes = nowDate.getMinutes();
    let topVal;
    if (hours < 9) {
      topVal = 62;
    } else if (hours > 20) {
      topVal = 1012;
    } else {
      topVal = 100 + ((hours - 9) * 76) + (Minutes * (38 / 30));
    }
    setTimeLineTop(topVal);
  }, []);

  // 組織が変更で表示をリセットする
  useEffect(() => {
    rerender();
  }, [targetDivisionTree]);

  return (
    <TableContainer component={Paper} className={classes.tableContainer} key={rerenderKey}>
      <Table aria-label="collapsible table" className={`${classes.table} rndDay-table-table`}>
        {isSp ? (
          <TableBody className={classes.tbody}>
            {/* 組織に紐づくユーザーを表示 */}
            <Row
              row={targetDivisionTree}
              classes={classes}
              baseClasses={baseClasses}
              setOpenCustomertree={setOpenCustomertree}
              countUpParent={countUpParent}
              actionsDay={actionsDay}
              searchInput={searchInput}
              targetDivisionTree={targetDivisionTree}
              timeLineTop={timeLineTop}
            />
          </TableBody>
        ) : (
          <TableBody className={`${classes.tbody} rndDay-table-tbody`}>
            {/* 組織に紐づくユーザーを表示 */}
            <Row
              row={targetDivisionTree}
              classes={classes}
              baseClasses={baseClasses}
              setOpenCustomertree={setOpenCustomertree}
              countUpParent={countUpParent}
              actionsDay={actionsDay}
              searchInput={searchInput}
              targetDivisionTree={targetDivisionTree}
              timeLineTop={timeLineTop}
            />
            {/* 子組織に紐づくユーザーを表示 */}
            {targetDivisionTree.children.length > 0 && (
              getChildren(targetDivisionTree.children)
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
