import { LibraryDirectoryTreeProvider } from './context';
import LibraryDirectoryTreeContainer from '../../../containers/libraryDirectoryTree/libraryDirectoryTreeContainers';

export default function LibraryDirectoryTree(props) {
  const { modalOption } = props;
  if (modalOption && !modalOption.open) return null;
  return (
    <LibraryDirectoryTreeProvider {...props}>
      <LibraryDirectoryTreeContainer />
    </LibraryDirectoryTreeProvider>
  );
}
