// eslint-disable-next-line max-len
const getTreeObject = (obj, word, targetFlgList, userId, selectDivisionId, isDirect) => {
  const targetObj = {};
  const divisionIdArray = [];
  const parentDivisionArray = [];
  let count = 0;
  // 再帰関数 -- 各要素を組織名・ユーザー名でsearchNameに引っかかるか検証
  const getObject = (theObject, searchWord) => {
    let result = null;
    let appendFlg = false;
    // 対象が配列なら、その子要素を再度getObject()で処理
    if (Array.isArray(theObject)) {
      for (let i = 0; i < theObject.length; i += 1) {
        result = getObject(theObject[i], word);
        if (result) {
          break;
        }
      }
    } else {
      // 重複時のためDivisionId onlyでまわす
      if ('divisionId' in theObject && 'subName' in theObject) {
        appendFlg = true;
        divisionIdArray.push(String(theObject.divisionId));
        parentDivisionArray.push({
          divId: theObject.divisionId,
          divisionName: theObject.subName,
        });
      }
      for (const prop in theObject) {
        // SP: リストから組織を選択時、取得したdivisionIdがツリーで引っ掛かった場合、divisionsというキーに対象の組織情報を格納。
        if (prop === 'divisionId' && 'subName' in theObject) {
          if (theObject[prop] === selectDivisionId && userId !== -1) {
            const divisionKey = 'divisions';
            if (!targetObj[divisionKey]) {
              targetObj[divisionKey] = [];
            }
            targetObj[divisionKey].push({
              children: theObject.children,
              users: theObject.users,
              divisionId: theObject.divisionId,
              targetId: theObject.divisionId,
              groupName: theObject.groupName,
              rootName: theObject.subName,
              targetName: theObject[prop],
              type: 'division',
              treeObj: theObject,
              customers: isDirect ? theObject?.customers : [],
            });
            count += 1;
          }
        }
        // 組織の名前とsearchNameを引っかける
        if (prop === 'subName' && targetFlgList.divisionFlg) {
          if (theObject[prop].includes(searchWord)) {
            const divisionKey = 'd' + String(theObject.divisionId);
            if (!targetObj[divisionKey]) {
              targetObj[divisionKey] = [];
            }
            const divList = divisionIdArray.slice(0, divisionIdArray.length);
            targetObj[divisionKey].push({
              targetName: theObject[prop],
              divisionList: divList,
              targetId: theObject.divisionId,
              type: 'division',
              treeObj: theObject,
              customers: isDirect ? theObject?.customers : [],
            });
            count += 1;
          }
        }
        // ユーザー名とsearchNameを引っかける
        if (prop === 'users' && theObject[prop].length > 0) {
          for (const i of theObject[prop]) {
            // SP: リストからユーザーを選択時、対象のuserIdとdivisionIdが該当した場合、usersに対象のユーザー情報を格納。
            if (selectDivisionId && userId && userId !== -1) {
              if (i.divisionId === selectDivisionId && i.userId === userId) {
                const divisionKey = 'users';
                if (!targetObj[divisionKey]) {
                  targetObj[divisionKey] = [];
                }
                targetObj[divisionKey].push({
                  userId: i.userId,
                  divisionId: i.divisionId,
                  // eslint-disable-next-line no-nested-ternary
                  userName: i.lastName ? i.lastName : '' + i.firstName ? i.firstName : '',
                  isMainDivision: i.isMainDivision,
                  customerCount: i.customerCount,
                  customers: i.customers,
                  rootName: i.lastName + i.firstName,
                  type: 'user',
                });
                count += 1;
              }
            }
            if (targetFlgList.userFlg) {
              if (i.lastName?.includes(searchWord)
              || i.firstName?.includes(searchWord)
              || i.userId === userId) {
                if (userId === -1 && selectDivisionId !== i.divisionId) break;
                const userKey = 'u' + String(theObject.divisionId);
                if (!targetObj[userKey]) {
                  targetObj[userKey] = [];
                }
                const targetDivisionList = divisionIdArray.slice(
                  0,
                  divisionIdArray.length,
                );
                const confirmParentDivision = parentDivisionArray.slice(
                  0,
                  parentDivisionArray.length,
                );
                targetObj[userKey].push({
                  userId: i.userId,
                  divisionId: i.divisionId,
                  // eslint-disable-next-line no-nested-ternary
                  userName: i.lastName ? i.lastName : '' + i.firstName ? i.firstName : '',
                  isMainDivision: i.isMainDivision,
                  customerCount: i.customerCount,
                  customers: i.customers,
                  rootName: i.lastName + i.firstName,
                  type: 'user',
                  targetId: i.userId,
                  // eslint-disable-next-line no-nested-ternary
                  targetName: i.lastName ? i.lastName : '' + i.firstName ? i.firstName : '',
                  divisionList: targetDivisionList,
                  treeObj: i,
                  parentDivision:
                  // SP: 親元の組織情報をオブジェクトに保持(検索後、組織名でグルーピングするため以下を追加)
                  confirmParentDivision[confirmParentDivision.length - 1],
                });
                count += 1;
              }
            }
          }
        }
        // 要素がObject or Arrayならその要素で再度getObjectを処理
        if (theObject[prop] instanceof Object) {
          if (prop === 'parentKeyList') break;
          // 再処理
          result = getObject(theObject[prop], word);
          if (result) {
            break;
          }
        }
      }
    }
    if (appendFlg) {
      divisionIdArray.pop();
      parentDivisionArray.pop();
    }
    return result;
  };
  getObject(obj, word, targetFlgList);
  if (Object.keys(targetObj).length > 0) {
    targetObj.count = count;
  }
  return targetObj;
};

export default getTreeObject;
