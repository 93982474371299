import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResponseTypeModal from '../../common/responseType/responseTypeModal';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '4px 8px',
    // width: 330,
    display: 'flex',
  },
  textField: {
    // width: 80,
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: '5px 5px 0',
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, 0.54) transparent',
      position: 'absolute',
      top: 24,
      right: 6,
      pointerEvents: 'none',
    },
    '&:not(:last-child)': {
      marginRight: 4,
    },
    '&>.MuiInputLabel-root': {
      fontSize: 13,
      top: '-16%',
      color: '#333',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 7px) scale(0.85)',
      },
    },
    '& .MuiInput-root:hover': { backgroundColor: `${theme.palette.primaryColor}29` },
    '& .MuiInput-input': {
      height: 20,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: 13,
      pointerEvents: 'none',
    },
  },
}));

function SearchResponse(props) {
  const { initial } = props;

  const classes = useStyles();

  // モーダル用
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    initial.search();
  }, [
    initial.state1,
    initial.state2,
    initial.state3,
    initial.state4,
  ]);

  // reset
  useEffect(() => {
    const newDisplayNames = {};
    let isChanged = false;
    Object.keys(initial.displayName).forEach(key => {
      if (!initial[key] && initial.displayName[key]) {
        newDisplayNames[key] = '';
        isChanged = true;
      } else {
        newDisplayNames[key] = initial.displayName[key];
      }
    });
    if (isChanged) {
      initial.setDisplayName(newDisplayNames);
    }
  }, [initial]);

  const responses = [];
  ['state1', 'state2', 'state3', 'state4'].forEach(key => {
    if (initial[key]) {
      responses.push({
        responseTypeId: initial[key],
        responseTypeName: initial.displayName[key],
      });
    }
  });

  const setResponses = (res) => {
    initial.set(res);
    initial.setDisplayName({
      state1: res[0] ? res[0].responseTypeName : '',
      state2: res[1] ? res[1].responseTypeName : '',
      state3: res[2] ? res[2].responseTypeName : '',
      state4: res[3] ? res[3].responseTypeName : '',
    });
  };

  return (
    <>
      <Grid className={classes.root}>
        <TextField
          className={classes.textField}
          fullWidth
          label={initial.title1}
          value={initial.displayName.state1}
          onClick={handleOpen}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label={initial.title2}
          value={initial.displayName.state2}
          onClick={handleOpen}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label={initial.title3}
          value={initial.displayName.state3}
          onClick={handleOpen}
        />
        <TextField
          className={classes.textField}
          fullWidth
          label={initial.title4}
          value={initial.displayName.state4}
          onClick={handleOpen}
        />
      </Grid>
      <ResponseTypeModal
        open={open}
        onClose={() => setOpen(false)}
        setState={setResponses}
        state={responses}
        isSearch
      />
    </>
  );
}

export default SearchResponse;
