import TodaysGuidanceList from './todaysGuidanceList';
import IncompleteList from './incompleteList';
import UnregistList from './unregistList';
import CorrespondenceList from './correspondenceList';
import SearchBox from './searchBox';

function DashBoardComponent(props) {
  /**
   * @typedef { import(
   *   '../../../apis/act/getActGuidancesApiTypes'
   * ).ActGuidancesGetResponse } ActGuidances
   * @typedef { import(
   *   '../../../apis/act/getIncompleteListApiTypes'
   * ).IncompleteListGetResponse } IncompleteList
   * @typedef { import(
   *   '../../../apis/act/getUnregistListApiTypes'
   * ).UnregistListGetResponse } UnregistList
   * @typedef { import(
   *   '../../../apis/act/getResponsesRecentListApiTypes'
   * ).ResponseRecentListGetResponse } ResponseRecentList
   * @typedef {{
   *   actGuidances: ActGuidances;
   *   incompleteList: IncompleteList;
   *   unregistList: UnregistList;
   *   responsesRecentList: ResponseRecentList
   * }} Props
   * @type { Props }
   */
  const {
    actGuidances,
    incompleteList,
    unregistList,
    responsesRecentList,
    query,
    setQuery,
  } = props;

  // if (
  //   !actGuidances.data
  //   || !incompleteList.data
  //   || !unregistList.data
  //   || !responsesRecentList.data
  // // eslint-disable-next-line react/jsx-no-useless-fragment
  // ) return <></>;

  return (
    <>
      <SearchBox query={query} setQuery={setQuery} />
      <TodaysGuidanceList dataList={actGuidances} />
      <IncompleteList dataList={incompleteList} />
      <UnregistList dataList={unregistList} />
      <CorrespondenceList dataList={responsesRecentList} />
    </>
  );
}

export default DashBoardComponent;
