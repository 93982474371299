import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';

import commonStyles from '../../styles';

import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

import { signOutProcess } from '../../../commonFunction/authorize';

const useStyles = makeStyles(() => ({
  btn: {
    border: 'none',
    fontWeight: 700,
    cursor: 'pointer',
    width: '100%',
  },
  icon: { fontSize: 24 },
}));

function LogoutButton(props) {
  const { className } = props;
  const dispatch = useDispatch();
  const common = commonStyles();
  const classes = useStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const onLogoutSuccessFunction = () => signOutProcess();

  const handleClickLogout = () => {
    dispatch(changeConfirmMessage({
      msgList: ['ログアウトしますか？'], // 必須
      title: '確認', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'キャンセル',
        set: '',
        classes: common.buttonsSecondary,
      }, {
        label: 'ログアウト', // ボタンラベル
        set: onLogoutSuccessFunction, // ボタンをクリックしたときの処理
        classes: common.buttonsPrimary, // ボタンのクラス
        // classes: `${common.buttonsSecondary} ${classes.w100p}`, // 複数クラス指定の場合
      }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
    }));
  };

  if (isSp) {
    return (
      <button
        type="button"
        className={className}
        onClick={handleClickLogout}
      >
        <ExitToApp className={`${classes.icon} ${classes.logoutIcon}`} />
        <span>ログアウト</span>
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={onLogoutSuccessFunction}
      className={`${common.buttonsSecondary} ${classes.btn}`}
    >
      ログアウト
    </button>
  );
}

export default LogoutButton;
