import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import responseGetMediumApi from '../../../apis/response/responseGetMediumApi';
import { responseGetTypeApi } from '../../../store/response/responseGetTypeSlice';
import ResponseTypeWrapper from '../../../components/common/responseType/responseType';
import { useStore } from '../../../hooks';
import { SALES_GROUPS } from '../../../constants/userRoleGroup';

function ResponseTypeContainers(props) {
  const {
    state,
    setState,
    open,
    setOpen,
    defaultResponse,
    resetData,
    set,
    isSearch,
    addResponseType,
    parentOpen,
  } = props;

  const dispatch = useDispatch();
  const { responseHeader } = useStore();
  const { roleGroupId } = responseHeader;

  const responseTypeList = useSelector(
    (globalState) => globalState.responseGetType.responseGetType,
  );

  const getResponseTypes = (params) => {
    responseGetMediumApi(params)
      .then((res) => {
        dispatch(responseGetTypeApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const params = { limit: -1, offset: 0 };
    if (!isSearch && roleGroupId && SALES_GROUPS.includes(roleGroupId)) {
      // 検索系でないかつ営業権限の場合、営業フラグがたっているもののみ表示
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4011
      // https://openhouse.backlog.jp/view/OH_KDSFA-488
      params.isAllowSales = 1;
    }
    getResponseTypes(params);
  }, []);

  return (
    <ResponseTypeWrapper
      data={responseTypeList}
      state={state}
      setState={setState}
      open={open}
      setOpen={setOpen}
      defaultResponse={defaultResponse}
      resetData={resetData}
      set={set}
      addResponseType={addResponseType}
      parentOpen={parentOpen}
    />
  );
}

export default ResponseTypeContainers;
