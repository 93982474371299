import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const autoRegistResponseControlSearchSlice = createSlice({
  name: 'autoRegistResponseControlSearch',
  initialState: { autoRegistResponseControlSearch: initialState },
  reducers: {
    autoRegistResponseControlSearchApi: (state, data) => {
      state.autoRegistResponseControlSearch = data.payload;
    },
  },
});

export const { autoRegistResponseControlSearchApi } = autoRegistResponseControlSearchSlice.actions;

export default autoRegistResponseControlSearchSlice.reducer;
