import React from 'react';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import { classNames } from '../../../commonFunction';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles({
  textFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    backgroundColor: '#fff',
    padding: 8,
    marginBottom: 8,
  },
  label: {
    marginRight: 20,
  },
  validate: {
    borderBottom: '1px solid red',
  },
  textField: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '160%',
      '& input': {
        padding: 3,
      },
    },
  },
});

function DivisionTextField(props) {
  const { initial, errorCallback } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();
  const initialStyle = {
    width: initial.width ? initial.width : '100%',
  };
  const isValid = initial.valid ? initial.valid(initial.state) : false;

  const switchType = (data) => {
    switch (data.type) {
      case 'str':
      case 'number': {
        return (
          <TextBaseField
            label={initial.title}
            type={initial.type}
            onChange={(e) => initial.set(e, initial)}
            className={`${baseClasses.textField} ${classes.textField}`}
            defaultValue={initial.state}
            validator={initial.validator}
            convertor={initial.convertor}
            name={initial.key}
            errorCallback={errorCallback(initial.key)}
          />
        );
      }
      default:
        return <div>対応しない要素です</div>;
    }
  };

  return (
    <Grid
      style={initialStyle}
      className={classNames(
        classes.textFieldContainer,
        isValid ? classes.validate : '',
      )}
    >
      <Typography className={`${baseClasses.title6} ${classes.label}`}>
        {initial.label}{initial.require ? <span style={{ color: '#D83420' }}>＊</span> : ''}
      </Typography>
      {switchType(initial)}
    </Grid>
  );
}

export default DivisionTextField;
