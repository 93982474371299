import { Grid, makeStyles } from '@material-ui/core';
import CommonTreeContainers from '../../../containers/common/commonTree/commonTreeContainers';
import commonTheme from '../../styles/theme';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
      background: '#fff',
      padding: 8,
      border: '#c8c8c8 1px solid',
    },
  },
});
export default function TreeWrapper(props) {
  const {
    users = false,
    setUsers = false,
    selectUser = false,
    setSelectUser = false,
    divisionSelect = false,
    searchForm = false,
    customerTree = false,
    divisionPickable = false,
  } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <CommonTreeContainers
        users={users}
        setUsers={setUsers}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        divisionSelect={divisionSelect}
        searchForm={searchForm}
        customerTree={customerTree}
        divisionPickable={divisionPickable}
      />
    </Grid>
  );
}
