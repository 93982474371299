import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Add, Folder, Person } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import commonStyles from '../../../../styles';
import {
  getTreeItemByDivisionId,
  getPastDivisionArray,
  getAllUsers,
  removeDuplicateArray,
  setSelectCount,
  setSelectCountForDivision,
} from '../../commonFunc/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    padding: '0 0 16px 0',
  },
  parent: {
    position: 'relative',
    padding: 0,
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 1,
  },
  li: {
    padding: '0 0 0 16px',
    display: 'flex',
    background: '#fff',
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    fontSize: 16,
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '&.isCompact': {
      width: '80%',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  checkedUser: {
    color: theme.palette.primaryColor,
  },
  // 戻るボタン用
  button: {
    padding: '18px 16px',
    width: '100%',
    background: '#fff',
    justifyContent: 'flex-start',
    margin: '0 0 16px',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  searchItem: {
    padding: 0,
    // borderTop: '1px solid #C8C8C8',
  },
  countText: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
    color: '#A8A8A8',
  },
  searchWrap: {
    width: '100%',
  },
  searchTitle: {
    padding: 16,
  },
  searchList: {
    padding: 0,
  },
  result: {
    padding: '40px 16px',
    textAlign: 'center',
    color: '#8C8C8C',
    fontSize: 15,
  },
}));

function UserTreeMultipleListSp(props) {
  const common = commonStyles();
  const classes = useStyles();
  const {
    list,
    selectUser,
    setSelectUser,
    displayDivision,
    setDisplayDivision,
    displayDivisionRoot,
    setDisplayDivisionRoot,
    searchObj,
    showOnlyDivisions,
  } = props;

  // 選択状態保持
  const [checked, setChecked] = useState([]);
  // 組織選択状態保持
  const [divChecked, setDivChecked] = useState([]);
  // 検索後、データを保持
  const [sortItems, setSortItems] = useState({});
  const firstFlg = useRef(true);
  const selectCount = useRef({});

  // 選択した組織内のリストを表示。
  const handleSetDirectory = (divisionId, backVal) => {
    // 対象の階層を参照
    const resultDivision = getTreeItemByDivisionId(list, divisionId);
    // let result = [];

    // 階層にchildrenが存在する場合
    if (resultDivision.children) {
      const { children } = resultDivision;
      // ------------------------------
      if (showOnlyDivisions) {
        const onlyDivision = [];
        for (let i = 0; children.length > i; i += 1) {
          children[i]?.type === 'division' ? onlyDivision.push(children[i]) : null;
        }
        setDisplayDivision(onlyDivision);
      } else {
        setDisplayDivision(children);
      }
      !backVal ? setDisplayDivisionRoot([...displayDivisionRoot, resultDivision]) : null;
    }

    // 戻るボタンを押した場合は、以下の処理を行う
    if (backVal) {
      const resultArray = [];
      for (let i = 0; i < displayDivisionRoot.length; i += 1) {
        if (backVal.includes(displayDivisionRoot[i].id)) {
          resultArray.push(displayDivisionRoot[i]);
        }
      }
      setDisplayDivisionRoot(resultArray);
    }
  };

  // 配下全てを選択した時の処理
  const handleSelectDivisions = async () => {
    const division = displayDivisionRoot.slice(-1)[0];
    const [allUsers, userIds, divisionIds, uniques] = getAllUsers([division]);
    if (divChecked.includes(division.id)) {
      setChecked(checked.filter((userId) => (!userIds.includes(userId))));
      selectCount.current = setSelectCountForDivision(uniques, selectCount.current, true);
      // eslint-disable-next-line max-len
      setSelectUser(selectUser.filter((user) => (!userIds.includes(`${user.userId}_${user.userDivisionId}`))));
      if (division.parentId) {
        // eslint-disable-next-line max-len
        const parentsDivisions = getPastDivisionArray(list, getTreeItemByDivisionId(list, division.parentId), true);
        const newDivisionIds = [...divisionIds, ...parentsDivisions.map((d) => d.id)];
        setDivChecked(divChecked.filter((divId) => (!newDivisionIds.includes(divId))));
      } else {
        setDivChecked(divChecked.filter((divId) => (!divisionIds.includes(divId))));
      }
    } else {
      selectCount.current = setSelectCountForDivision(uniques, selectCount.current);
      setSelectUser(removeDuplicateArray([...selectUser, ...allUsers]));
      setChecked(removeDuplicateArray([...checked, ...userIds]));
      setDivChecked(removeDuplicateArray([...divChecked, ...divisionIds]));
    }
  };

  // ユーザーを選択した時の処理
  const handleSelectUser = async (userObj) => {
    const copySelectUser = [...selectUser];
    // 解除
    if (checked.includes(`${userObj.userId}_${userObj.parentId}`)) {
      setChecked(checked.filter((userId) => (userId !== `${userObj.userId}_${userObj.parentId}`)));
      selectCount.current = setSelectCount(userObj.uniqueId, selectCount.current, true);
      const newSelectUser = copySelectUser.filter((user) => (`${user.userId}_${user.userDivisionId}` !== `${userObj.userId}_${userObj.parentId}`));
      setSelectUser(newSelectUser);
      if (userObj.parentId && divChecked.includes(userObj.parentId)) {
        // ユーザー解除した時、その親系全ての組織チェックも解除
        // eslint-disable-next-line max-len
        const parentsDivisions = getPastDivisionArray(list, getTreeItemByDivisionId(list, userObj.parentId), true);
        const removeIds = [];
        // eslint-disable-next-line max-len
        for (let i = 0; parentsDivisions.length > i; i += 1) { removeIds.push(parentsDivisions[i].id); }
        const newDivChecked = [];
        for (let i = 0; divChecked.length > i; i += 1) {
          const d = divChecked[i];
          removeIds.includes(d) ? null : newDivChecked.push(d);
        }
        setDivChecked(newDivChecked);
      }
    // 選択
    } else {
      setChecked([...checked, `${userObj.userId}_${userObj.parentId}`]);
      selectCount.current = setSelectCount(userObj.uniqueId, selectCount.current);
      setSelectUser([...selectUser, {
        userId: userObj.userId,
        userName: userObj.userName,
        userDivisionId: userObj.parentId,
        userDivisionName: userObj.parentName,
      }]);
    }
  };

  // 戻るボタンを押した時の処理
  const getBackList = async () => {
    const divIds = [];
    for (let i = 0; displayDivisionRoot.length > i; i += 1) {
      if (divIds.includes(displayDivisionRoot[i])) {
        break;
      }
      divIds.push(displayDivisionRoot[i].id);
    }
    if (divIds.length > 1) {
      const resetDivision = divIds[divIds.length - 2];
      if (divIds.includes(resetDivision)) {
        await divIds.splice(divIds.indexOf(resetDivision) + 1);
        await setDisplayDivisionRoot(divIds);
        handleSetDirectory(divIds[divIds.length - 1], divIds);
      } else {
        handleSetDirectory(resetDivision);
      }
    } else {
      setDisplayDivision([]);
      setDisplayDivisionRoot([]);
    }
  };

  // ユーザー用のボタン
  const userButton = (item) => {
    return (
      <Grid
        key={`u${item.userId}`}
        className={`${common.small} ${classes.li} ${classes.person}`}
      >
        <FormControlLabel
          className={`${classes.btn} ${
            checked.includes(`${item.userId}_${item.parentId}`) ? classes.checkedUser : null
          }`}
          control={
            <Checkbox
              color="primary"
              value={item.userId}
              checked={checked.includes(`${item.userId}_${item.parentId}`)}
              onChange={() => handleSelectUser(item)}
              className={classes.checkbox}
            />
          }
          label={
            <Grid container alignItems="center">
              <Person className={classes.icon} />
              <Typography className={common.title3}>
                {item.userName}
                {item.isMainDivision === 0 && '（サブ）'}
              </Typography>
            </Grid>
          }
        />
      </Grid>
    );
  };

  const isHaveChildren = (item) => { return (item?.children?.length > 0); };

  // 組織用のボタン
  const diviPickableButton = (item, isSearch) => {
    return (
      <Grid className={classes.li}>
        <Button
          className={`${common.title3} ${classes.btn}`}
          onClick={() => {
            isSearch || !isHaveChildren(item) ? null : handleSetDirectory(item.id);
          }}
        >
          <Folder className={classes.icon} />
          <Typography
            className={common.title3}
          >
            {item.name}
          </Typography>
          <Typography className={classes.countText}>
            {selectCount.current[String(item.id)] ? `...${selectCount.current[String(item.id)]}名選択中` : ''}
          </Typography>
          {isSearch || !isHaveChildren(item) ? null : <Add className={classes.addButtonIcon} />}
        </Button>
      </Grid>
    );
  };

  // ユーザー要素
  const userList = (data) => {
    const userlist = [];
    if ('userId' in data) {
      userlist.push(
        userButton(data),
      );
    } else {
      data.users.map((u) => {
        return userlist.push(
          userButton(u),
        );
      });
    }
    return userlist;
  };

  // 組織要素
  const childList = (division, isSearch) => {
    const childlist = [];
    childlist.push(
      <Grid key={`divi${division.id}`} className={classes.parent}>
        {diviPickableButton(division, isSearch)}
      </Grid>,
    );
    return childlist;
  };

  // 画面に各階層のリストを描画
  const getListItem = (searchArr, initFlg = false, isSearch = false) => {
    let firstInitFlg = initFlg;
    let loopItems = null;
    if (displayDivision.length > 0) {
      loopItems = displayDivision;
    } else if (!searchArr) {
      firstInitFlg = true;
      // loopItems = getFirstList(list);
      loopItems = list;
    }

    // 初期表示
    if (firstInitFlg) {
      return loopItems.map((data) => (
        <div key={data.name} className={classes.parent}>
          {diviPickableButton(data)}
        </div>
      ));
      // 検索を実行後、表示させるリスト
    } else if (searchArr) {
      return (
        searchArr.length > 0 ? (
          <Grid className={classes.searchItem} key={uuid()}>
            {searchArr.map((arr) => {
              return (
                // 組織選択ウィンドウの場合
                showOnlyDivisions ? (
                  <Grid className={classes.searchWrap} key={`s${arr.id}${uuid()}`}>
                    {/* リストの表示 */}
                    <Grid className={classes.searchList}>
                      {childList(arr, isSearch)}
                    </Grid>
                  </Grid>
                ) : (
                  // 担当者選択ウィンドウの場合
                  <Grid className={classes.searchWrap} key={`s${arr.divisionId}${uuid()}`}>
                    {/* 組織名／ユーザー名でグルーピング */}
                    {arr.groupName ? (
                      <>
                        <Typography
                          className={`${common.title3} ${classes.searchTitle}`}
                        >
                          {arr.groupName}
                        </Typography>
                        {/* リストの表示 */}
                        <Grid className={classes.searchList}>
                          {arr.users.map((u) => userList(u))}
                        </Grid>
                      </>
                    ) : (
                      <Grid className={classes.searchList}>
                        {arr.users.map((u) => userList(u))}
                      </Grid>
                    )}
                  </Grid>
                )
              );
            })}
          </Grid>
        ) : <Typography className={classes.result}>0件</Typography>
      );
    } else {
      const resultList = loopItems.map((data) => (
        !showOnlyDivisions && 'userId' in data ? userList(data) : childList(data)
      ));

      return (
        resultList.map((item) => (
          <Grid key={uuid()}>
            {item}
          </Grid>
        ))
      );
    }
  };

  // 初回ツリー描画  & 初期値指定
  useEffect(() => {
    if (list.length > 0 || firstFlg.current) {
      // eslint-disable-next-line no-use-before-define, max-len
      if (selectUser.length > 0) {
        const newChecked = [];
        for (let i = 0; selectUser.length > i; i += 1) {
          newChecked.push(`${selectUser[i].userId}_${selectUser[i].userDivisionId}`);
        }
        setChecked(newChecked);
      }
      setDisplayDivision(list);
      setDisplayDivisionRoot([]);
      firstFlg.current = false;
    }
  }, [list]);

  // 検索社員選択の場合
  const setUsers = () => {
    const allList = [];
    for (let i = 0; searchObj.length > i; i += 1) {
      const item = searchObj[i];
      let isAlready = false;
      for (let ii = 0; allList?.length > ii; ii += 1) {
        if (item.id === allList[ii].divisionId) {
          allList[ii].users.push(item);
          isAlready = true;
          break;
        }
      }
      if (!isAlready) {
        allList.push({
          groupName: item.parentName,
          divisionId: item.parentId,
          users: [item],
        });
      }
    }
    // 表示用
    setSortItems(allList);
  };

  // 検索組織選択の場合
  const setDivisions = () => {
    setSortItems(searchObj);
  };

  // 検索時のEffect
  useEffect(() => {
    if (searchObj.length > 0) {
      showOnlyDivisions ? setDivisions() : setUsers();
    }
  }, [searchObj]);

  return (
    <>
      {displayDivisionRoot.length > 0 && !searchObj && (
        <>
          <Button
            className={classes.button}
            onClick={getBackList}
          >
            <ArrowBackIosIcon className={classes.backIcon} />
            <Typography className={`${classes.text} ${common.title3}`}>{displayDivisionRoot.slice(-1)[0].name}</Typography>
          </Button>
          <Grid
            className={`${common.small} ${classes.li} ${classes.allCheckBtn}`}
          >
            <FormControlLabel
              className={`${classes.btn}`}
              control={
                <Checkbox
                  color="primary"
                  checked={divChecked.includes(displayDivisionRoot.slice(-1)[0].id)}
                  onChange={(e) => handleSelectDivisions(e)}
                  className={classes.checkbox}
                />
              }
              label={
                <Grid container alignItems="center">
                  <Folder className={classes.icon} />
                  <Typography
                    className={common.title3}
                  >
                    フォルダー配下 すべて
                  </Typography>
                </Grid>
              }
            />
          </Grid>
        </>
      )}
      <Grid className={`${classes.root}`}>
        {Array.isArray(sortItems) && searchObj ? (
          // 検索後
          getListItem(sortItems, false, true)
          // 検索前
        ) : (
          <>
            {getListItem()}
          </>
        )}
      </Grid>
    </>
  );
}

export default UserTreeMultipleListSp;
