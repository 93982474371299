// eslint-disable max-len
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GROUP_HISTORY_TH } from '../../constants/index';
import {
  USER_CLASS_SELECT,
  USER_GRADE_SELECT_LIST,
} from '../../constants/users';
import { classNames } from '../../commonFunction';
import CustomOriginalSelect from '../eleCommon/customOriginalSelect';
import HistoryDate from './parts/historyDate';
import { useRerender } from '../../hooks';

const useStyles = makeStyles(() => ({
  commonBorderBottom: {
    borderRight: '1px solid #C8C8C8',
    padding: '3px 10px',
    overflow: 'hidden',
  },
  customTable: {
    border: '1px solid #C8C8C8',
    boxShadow: 'none',
    margin: '4px',
  },
  table: {
    tableLayout: 'fixed',
  },
  customGrey: {
    color: '#8C8C8C',
  },
  selectStr: {
    color: 'black',
    fontSize: '12px',
    '&.Mui-disabled': {
      color: 'black',
    },
  },
  shortCell: {
    width: '11%',
  },
  longCell: {
    width: '15%',
  },
  label: {
    minWidth: 0,
    width: '100%',
    borderBottom: '1px #8C8C8C solid',
    textAlign: 'center',
    display: 'inline-block',
    justifyContent: 'center',
    minHeight: '21px',
    position: 'relative',
  },
  tableCellLabelTxt: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  selectBox: {
    width: '100%',
  },
  isGreen: {
    backgroundColor: '#e8f5e9',
  },
  isBlue: {
    backgroundColor: '#e3f2fd',
  },
  isRed: {
    backgroundColor: '#fff3e0',
  },
  tag: {
    width: '1%',
  },
}));

function UserChangeHistory(props) {
  const {
    selectUser,
    updateDivisionFunction,
  } = props;
  const classes = useStyles();
  const [updateDivision, setUpdateDivision] = useState([]);
  const [changeMode, setChangeMode] = useState(false);
  const [targetIndex, setTargetIndex] = useState(0);

  const userPrf = useSelector((state) => state.user.userDetail);
  const userDivision = useSelector((state) => state.user.userDivisionDetail);

  const { rerender, rerenderKey } = useRerender();

  useEffect(() => {
    rerender();
  }, [userPrf]);

  // selectUser一本 -> 他ユーザー選択時に前データが残る, userDivision一本 -> 値の変更時に変更前が適用
  useEffect(() => {
    setUpdateDivision(userDivision);
  }, [selectUser, userDivision]);

  // BlurFlgが立ったら発火
  useEffect(() => {
    if (changeMode) {
      // FIXME: 下記でAPIたたく 以下historiesのPUTの返り値に修正必要の為コメント
      // updateDivisionFunction(userDivision[targetIndex]);
      setChangeMode(false);
    }
  }, [changeMode, userDivision, updateDivisionFunction, targetIndex]);

  // 変更時にLocalState & GlobalStateを変更
  const changeValue = async (value, index, key, type) => {
    // 組織の変更履歴を空欄にできないようにする
    if (type === 'date' && value === '') return;
    const val = type === 'select' ? value : value.replaceAll('-', '/');
    const copyArray = updateDivision.slice();
    const targetObj = Object.assign({}, copyArray[index]);
    targetObj[key] = type === 'select' ? Number(val) : val;
    copyArray[index] = targetObj;
    await setUpdateDivision(copyArray);
    await updateDivisionFunction(copyArray[index], userPrf.userId);
    setChangeMode(true);
    setTargetIndex(index);
  };

  // TDの項目
  const getCellArray = (row, index) => {
    const {
      startAt,
      endAt,
      divisionName,
      gradeCode,
      positionCode,
    } = row;
    const stateArray = [
      {
        key: 'startAt',
        state: startAt,
        index,
        type: 'date',
        setDate: changeValue,
      },
      {
        key: 'endAt',
        state: endAt,
        index,
        type: 'date',
        setDate: changeValue,
      },
      {
        key: 'divisionName',
        val: divisionName,
        index,
        type: 'text',
      },
      {
        key: 'gradeCode',
        val: gradeCode,
        index,
        type: 'select',
        select: USER_GRADE_SELECT_LIST.menus,
        menuKeys: Object.keys(USER_GRADE_SELECT_LIST.menus),
      },
      {
        key: 'positionCode',
        val: positionCode,
        index,
        type: 'select',
        select: USER_CLASS_SELECT.menus,
        menuKeys: Object.keys(USER_CLASS_SELECT.sort),
      },
    ];
    return stateArray;
  };

  // メインの開始日のリスト
  const startAtArray = updateDivision ? updateDivision.filter(item => item.isMainDivision === 1)
    .map(item => item.startAt) : [];

  return (
    <Grid container key={rerenderKey}>
      {userPrf.divisionAll ? (
        <TableContainer component={Paper} className={classes.customTable}>
          <Table aria-label="simple table" className={classes.table}>
            <TableHead>
              <TableRow className={classes.customRow}>
                {Object.keys(GROUP_HISTORY_TH).map((r) => (
                  <TableCell
                    key={GROUP_HISTORY_TH[r]}
                    align="left"
                    className={classNames(
                      classes.commonBorderBottom,
                      classes.customGrey,
                      Number(r) !== 3 ? classes.longCell : '',
                      Number(r) === 3 ? classes.shortCell : '',
                    )}
                  >
                    {GROUP_HISTORY_TH[r]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {updateDivision ? updateDivision.map((row, index) => (
                <TableRow
                  key={`${String(row.userDivisionId)}${String(index)}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {getCellArray(row, index).map((td) => (
                    <TableCell
                      key={`${String(td.key)}${String(index)}`}
                      align="left"
                      className={classes.commonBorderBottom}
                    >
                      {td.type === 'select' ? (
                        <CustomOriginalSelect
                          data={td}
                          changeValue={changeValue}
                        />
                      ) : null}
                      {td.type === 'date'
                        ? <HistoryDate data={td} row={row} startAtArray={startAtArray} />
                        : null}
                      {td.type === 'text' ? (
                        <Typography style={{ fontSize: '12px' }}>
                          {td.val}
                        </Typography>
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Grid>
  );
}

export default UserChangeHistory;
