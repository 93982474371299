import DIVISION_UPDATE_CONFIRM from '../../../constants/divisionMaster/divisionUpdateConfilm';

export const headCells = DIVISION_UPDATE_CONFIRM.TABLE_COLUMN.map((item) => {
  return {
    id: item.KEY,
    label: item.NAME,
  };
});

export function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`; }
