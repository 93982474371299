import { useDispatch } from 'react-redux';
import { Checkbox, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import commonStyles from '../../styles';
import LibraryRow from './libraryRow';
import SearchCell from './serchCell';

import { setLibrarySearchParamPartial } from '../../../store/libraryPage';

import { getLibrarySearchParam } from '../../../commonFunction/library';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: '8px',
    whiteSpace: 'nowrap',
    '&:not(:last-child)': { borderRight: '1px #c8c8c8 solid' },
  },
  icon: {
    '& .MuiTableSortLabel-icon': { fontSize: '12px' },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.primaryColor,
    },
  },
  searchRow: {
    background: '#F3F3F3',
    borderTop: '2px #8C8C8C solid',
    borderBottom: '2px #8C8C8C solid',
    position: 'sticky',
    top: 41,
    zIndex: 1,
  },
  search: {
    width: '16px',
    verticalAlign: 'middle',
  },
  searchcell: { padding: '4px 8px' },
  container: { maxHeight: 'calc(100vh - 244px)' },
}));

function EnhancedTableHeader(props) {
  const {
    cells,
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    onSelectAllClick,
    librarySearchFunction,
  } = props;
  const classes = useStyles();
  const common = commonStyles();

  const dispatch = useDispatch();

  // TableHead内のセルの値をキーとした並び替え
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.cell}>
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {cells.map((c) => (
          <TableCell
            key={c.id}
            style={c.style}
            sortDirection={orderBy === c.id ? order : false}
            className={classes.cell}
          >
            {c.sort ? (
              <TableSortLabel
                active={orderBy === c.id}
                direction={orderBy === c.id ? order : 'asc'}
                onClick={createSortHandler(c.id)}
                className={classes.icon}
              >
                {c.label}
              </TableSortLabel>
            ) : (
              c.label
            )}
          </TableCell>
        ))}
      </TableRow>
      <TableRow className={classes.searchRow}>
        <TableCell className={classes.cell}>
          <Typography className={common.title4}>
            検索
          </Typography>
        </TableCell>
        {cells.map((c) => {
          const param = getLibrarySearchParam();
          const filterFunc = (val) => {
            const tmp = getLibrarySearchParam();
            if (tmp[c.id] === val) return;
            dispatch(setLibrarySearchParamPartial({ [c.id]: val, offset: 0 }));
            librarySearchFunction();
          };
          return (
            <TableCell key={c.id} className={`${classes.cell} ${classes.searchcell}`}>
              {c.search ? (
                <SearchCell
                  defaultValue={param[c.id]}
                  filterFunc={filterFunc}
                  maxLengthInt={c.maxLengthInt}
                />
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default function LibraryTable(props) {
  const {
    dblclick,
    list,
    setPage,
    choice,
    setChoice,
    choiceDir,
    setChoiceDir,
    order,
    orderBy,
    setOrderAndOrderBy,
    clickedit,
    librarySearchFunction,
  } = props;

  const classes = useStyles();

  const header = [
    { id: 'libraryName', label: 'ライブラリタイトル', search: true, sort: true, style: { minWidth: '150px' }, maxLengthInt: 250 },
    { id: 'libraryMemo', label: 'ライブラリメモ', search: true, sort: true, style: { minWidth: '150px' }, maxLengthInt: null },
    { id: 'mailSubject', label: '件名', search: true, sort: true, style: { minWidth: '150px' }, maxLengthInt: 250 },
    { id: 'updateUserName', label: '更新者', search: false, sort: true, style: { minWidth: '100px' }, maxLengthInt: null },
    { id: 'libraryId', label: '編集', search: false, sort: false, style: { minWidth: '50px' }, maxLengthInt: null },
    { id: 'updateDate', label: '更新日時', search: false, sort: true, style: { minWidth: '50px' }, maxLengthInt: null },
  ];

  // TableHead内のセルの値をキーとした並び替え
  const handleRequestSort = (event, property) => setOrderAndOrderBy(property);

  // TableHead内のセルのチェックボックスクリック時
  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = list.map((n) => n.libraryId);
      setChoice(newSelecteds);
      const newSelectedDirs = list.map((n) => {
        return {
          libraryId: n.libraryId,
          libraryDirectoryId: n.libraryDirectoryId,
        };
      });
      setChoiceDir(newSelectedDirs);
      return;
    }
    setChoice([]);
    setChoiceDir([]);
  };

  return (
    <TableContainer className={`${classes.container}`}>
      <Table stickyHeader>
        <EnhancedTableHeader
          cells={header}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          numSelected={choice.length}
          rowCount={list.length}
          onSelectAllClick={handleSelectAllClick}
          setPage={setPage}
          librarySearchFunction={librarySearchFunction}
        />
        <TableBody>
          {list.map((row) => {
            return (
              <LibraryRow
                dblclick={dblclick}
                key={row.libraryId}
                row={row}
                cells={header}
                choice={choice}
                setChoice={setChoice}
                choiceDir={choiceDir}
                setChoiceDir={setChoiceDir}
                clickedit={clickedit}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
