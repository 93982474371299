import React from 'react';
import { InputLabel, FormControl, makeStyles } from '@material-ui/core';
import CustomSidebarSelect from '../../eleCommon/customSidebarSelect';
import NormalTooltip from '../../styles/tooltip';
import SortSelectMain from './parts/sortSelectMain';
import SortTextField from './parts/sortTextField';
import SortInputButton from './parts/sortInputButton';
import CustomSidebarSelectMulti from '../../eleCommon/customSidebarSelectMulti';
import Calendar from '../../common/calendar';
import { useKanniKensaku } from '../../../containers/search/kanniKensaku/KanniKensakuContext';
import RangeSelect from '../../common/rangeSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  inputControl: {
    paddingTop: 16,
    height: 20,
    '&> label + .MuiInput-formControl': {
      marginTop: 0,
    },
    '&.wishArea .MuiSvgIcon-root': {
      display: 'none',
    },
    '&.stationFrom .MuiSvgIcon-root': {
      display: 'none',
    },
    '&.stationTo .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  InputLabel: {
    fontSize: 13,
    top: '-12%',
    color: '#333',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 7px) scale(0.85)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  width_100: {
    width: '100%',
  },
  inputWrap: {
    'margin-top': 0,
  },
  centerMark: {
    display: 'flex',
    'justify-content': 'flex-end',
    'align-items': 'center',
    '&>p': {
      display: 'inline-block',
    },
  },
}));

function SortSelect(props) {
  const { isCustomSelects } = props;
  const classes = useStyles();
  const { searchConditions, setSearchConditions } = useKanniKensaku();

  const getComponent = (data) => {
    switch (data.type) {
      case 'str':
        return <SortTextField initial={data} />;
      case 'clickBtn':
        return <SortInputButton initial={data} />;
      case 'calendar':
        return <Calendar data={data.inputData} />;
      case 'flg':
      case 'select':
        return (
          <NormalTooltip title={data.tooltip}>
            <FormControl
              fullWidth
              className={`${classes.inputControl} ${data.key}`}
            >
              <InputLabel className={classes.InputLabel}>
                {data.obj.title}
              </InputLabel>
              <CustomSidebarSelect initial={data} />
            </FormControl>
          </NormalTooltip>
        );
      case 'selectMulti':
        return (
          <NormalTooltip title={data.tooltip}>
            <FormControl
              fullWidth
              className={`${classes.inputControl} ${data.key}`}
            >
              <InputLabel className={classes.InputLabel}>
                {data.obj.title}
              </InputLabel>
              <CustomSidebarSelectMulti initial={data} />
            </FormControl>
          </NormalTooltip>
        );
      case 'rangeSelect':
        return (
          <NormalTooltip title={data.tooltip}>
            <RangeSelect
              defaultValue={data.state}
              options={data.obj}
              onChange={data.set}
              equalable
              label={data.label}
              selectClassName={data.classes}
              noUnderline={false}
              hasZero
            />
          </NormalTooltip>
        );
      default:
        // Fix: Failed prop type:
        // Invalid prop `children` supplied to `ForwardRef(Grid)`,
        // expected a ReactNode.
        // return true;
        return '';
    }
  };

  return (
    <SortSelectMain
      className={classes.root}
      searchConditions={searchConditions}
      setSearchConditions={setSearchConditions}
      getComponent={getComponent}
      isCustomSelects={isCustomSelects}
    />
  );
}

export default SortSelect;
