import client from '../httpClient';

// params: isIgnoreExpire = 0 or 1 and userStatusCode = []
const divisionsGetTreeApi = (isUserMaster = false) => {
  const params = {
    isIgnoreExpire: isUserMaster ? 1 : 0,
    userStatusCode: isUserMaster ? [1, 2, 3] : [1],
    isAllUser: isUserMaster ? 1 : 0,
    isDisplaySalesResponseTree: isUserMaster ? 0 : 1,
  };
  return client.get('/divisions/user-tree', { params });
};

export default divisionsGetTreeApi;
