import Search from '../../../components/actionSchedule/search/search';

function SearchConteiner(props) {
  const {
    handleCloseSearch,
  } = props;

  return (
    <Search
      handleCloseSearch={handleCloseSearch}
    />
  );
}

export default SearchConteiner;
