import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import customerGetStatisticsApi from '../../../apis/customer/customerGetStatisticsApi';
import ContactTimeWrapper from '../../../components/common/contactTime/contactTime';

function ContactTimeContainers(props) {
  const {
    open,
    onClose,
    customerId,
  } = props;

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const [tels, setTels] = useState([]);

  // GET customerGetStatisticsApi
  // /customers/statistics/tels/{customerId}
  const customerGetStatisticsFunction = async (cid) => {
    await customerGetStatisticsApi(cid)
      .then(async (res) => {
        setTels(res.data.tels);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 初期値の受け渡し
  useEffect(async () => {
    if ((isSp && customerId) || (customerId && open)) {
      // SPはopenは取得できないため、customerIdのみで通信させる（初期化された時の動く）
      await customerGetStatisticsFunction(customerId);
    }
  }, [open]);

  return (
    <ContactTimeWrapper
      tels={tels}
      open={open}
      onClose={onClose}
    />
  );
}

export default ContactTimeContainers;
