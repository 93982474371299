import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import commonTheme from '../../styles/theme';
import ChanceTableRow from './chanceTableRow';
import CustomTable from './customTable';
import { MEMBERS_REDUCER_KEYS } from '../../../containers/browsingHistory/browsingHistoryReducer';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    overflow: 'hidden',
    [commonTheme.breakpoints.up('md')]: {
      width: 514,
      height: 287,
      border: '1px solid #C8C8C8',
      padding: '8px 0 0',
      marginBottom: 8,
    },
  },
  tableContainer: {
    [commonTheme.breakpoints.up('md')]: {
      overflowY: 'auto',
      height: 250,
      padding: '0 8px',
      width: 'calc(100% - 16px)',
    },
  },
});

const headCells = [
  { id: 'receiveAt', label: '日時', sortable: true },
  { id: 'segment', label: 'デバイス / 詳細' },
];

export default function ChanceTable(props) {
  const {
    data,
    getApiDataFromListApi,
    isSP,
    handleOpenModal = null,
  } = props;

  const classes = useStyles();
  const chanceTablelimit = localStorage.getItem('chanceTablelimit') ? localStorage.getItem('chanceTablelimit') : 20;
  const setChanceTablelimit = useCallback((current) => {
    localStorage.setItem('chanceTablelimit', current);
  }, []);
  return (
    isSP ? (
      <div className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableBody>
              {data.notifications.map((row, i) => {
                return (
                  <ChanceTableRow
                    key={String(i)}
                    row={row}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    ) : (
      <CustomTable
        title="チャンス"
        headCells={headCells}
        total={data.total}
        list={data.notifications}
        contentHeight={handleOpenModal ? 130 : null}
        rowRender={(list) => list.map((row, i) => (<ChanceTableRow
          key={String(i)}
          row={row}
        />))}
        onPageChange={(pageInfo) => {
          getApiDataFromListApi(MEMBERS_REDUCER_KEYS.notifications, pageInfo);
        }}
        propLimit={chanceTablelimit}
        setPropLimit={setChanceTablelimit}
        isNoSlice
        handleOpenModal={handleOpenModal}
      />
    )
  );
}
