import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import commonStyles from '../../styles';
import postSendMails from '../../../apis/sendmail/sendMailsPostApi';
import customerCallApi from '../../../apis/customer/customerApi';
import ChangeManageWindow from '../../common/customer/changeManagerWindow';
import Dialog from '../../common/modal';
import { isOpen } from '../../../store/common/apiMessageSlice';
import { useLoading } from '../../../hooks';
import { MAIL_FROM_CODE } from '../../../constants/apiParameterCode';
import getUri from '../../../commonFunction/getUri';

const useStyles = makeStyles({
  reqired: {
    color: '#D83420',
  },
  Label: {
    fontWeight: 700,
    textTransform: 'none',
  },
  contentIpt: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginTop: 8,
    boxSizing: 'border-box',
  },
  messageContent: {
    marginTop: 8,
  },
  input: {
    width: '100%',
    border: 'none',
    fontSize: 13,
    display: 'inline-block',
    '&:focus': {
      outline: 'none',
    },
  },
  recipientNameArea: {
    width: '100%',
  },
  textarea: {
    resize: 'vertical',
    width: 592,
    height: 240,
    padding: 12,
    border: 'solid 1px #C8C8C8',
    '&:focus': {
      outline: 'none',
    },
  },
});

function ContactToResponsiblePerson(props) {
  const {
    open,
    setOpen,
    customerId,
  } = props;
  const { addLoading, removeLoading, hasLoading } = useLoading();

  const classes = useStyles();
  const baseClasses = commonStyles();
  const [mailSubject, setMailSubject] = useState('SFA担当者に連絡');
  const [mailBody, setMailBody] = useState('');
  // 担当者選択で選んだ担当者一覧を格納
  // **20220701現在はまだこのusersの中にデータは入ってない
  // 組織ツリー ユーザー選択用
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });

  const dispatch = useDispatch();

  const getCustomerData = async () => {
    if (!open) return;
    addLoading('contactUser');
    const response = await customerCallApi([customerId], 0, 0, 1);
    const {
      firstName,
      lastName,
      userLastName,
      userFirstName,
      userId,
      effectAt,
    } = response.data.customers[0];
    // 顧客名
    const customerName = [lastName, firstName].filter(Boolean).join('');
    // ユーザー名
    // null + null の値は 0 になる
    const userFullName = [userLastName, userFirstName].filter(Boolean).join('');
    setMailBody(`下記のお客様についてご連絡いたします。\r\n\r\nお客様 氏名：${customerName}（反響日：${moment(effectAt).format('YYYY年MM月DD日')}）\r\n顧客ID：${customerId} \r\nSFAリンク：${getUri().origin}/sfa/customer?customerId=${customerId}`);
    // setCustomerData(response.data.customers[0]);
    setSelectUser({ userId, userName: userFullName });
    removeLoading('contactUser');
  };

  useEffect(() => {
    getCustomerData();
    // reset
    // 古い情報を表示させないため
    if (!open && !!selectUser.userId) {
      setSelectUser({ userId: 0, userName: '' });
      setSelectDivision({ divId: 0, divisionName: '' });
      setMailBody('');
    }
  }, [open]);

  // （デフォルト値など含め）実際に使う担当者を格納
  const handleClickContact = () => {
    addLoading('contactUser');
    try {
      const postData = {
        mailTypeCode: 1,
        mailFromCode: MAIL_FROM_CODE.MYSELF,
        userIds: [selectUser.userId],
        mailSubject,
        // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4819
        mailBody: mailBody.replace(/\r?\n/g, '<br>'),
      };
      postSendMails(postData)
        .then((res) => {
          const { message, description } = res;
          const msg = { message, description, status: 'success' };
          dispatch(isOpen(msg));
        });
      setOpen(false);
    } finally {
      removeLoading('contactUser');
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const componentLabel = (
    <><span className={classes.Label}>To</span><span className={classes.reqired}>＊</span></>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="担当者へ連絡"
      width={640}
      buttons={{
        label: '担当者へ連絡',
        disabled: !selectUser.userName || !mailSubject || hasLoading('contactUser'),
        onClick: handleClickContact,
      }}
      bodyClassName={hasLoading('contactUser') ? baseClasses.loading : ''}
    >
      <ChangeManageWindow
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        componentLabel={componentLabel}
      />
      <Grid className={classes.contentIpt}>
        <Typography className={baseClasses.title6} style={{ width: '8%' }}>
          <span className={classes.Label}>件名</span><span className={classes.reqired}>＊</span>
        </Typography>
        <Typography className={baseClasses.smallStrong}>
          <input
            type="text"
            value={mailSubject}
            onChange={(e) => setMailSubject(e.target.value)}
            className={`${baseClasses.small} ${classes.input}`}
          />
        </Typography>
      </Grid>
      <Grid className={classes.messageContent}>
        <textarea
          value={mailBody}
          onChange={(e) => setMailBody(e.target.value)}
          className={`${baseClasses.small} ${classes.textarea}`}
        />
      </Grid>
      <Typography className={baseClasses.small}>
        お客様の個人情報を含む内容を送信します。<br />
        個人情報保護法の下、取扱いには十分注意してください。
      </Typography>
    </Dialog>
  );
}

export default ContactToResponsiblePerson;
