import { Grid, Tooltip } from '@material-ui/core';

/**
 * エラーメッセージを表示するツールチップ
 * @param {boolean} [open=false] - 表示/非表示を制御するフラグ
 * @param {Array<string>} [errorMessages=[]] - エラーメッセージの配列
 * @returns
 */
function ErrorTooltip(props) {
  const { children, open = false, errorMessages = [] } = props;

  const message = errorMessages.join('\n');

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      open={open}
      placement="top"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={<Grid style={{ whiteSpace: 'pre-line' }}>{message}</Grid>}
    >
      {children}
    </Tooltip>
  );
}

export default ErrorTooltip;
