import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import NormalTooltip from '../../../styles/tooltip';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  selectEle: {
    width: '250px',
    height: '38px',
    fontSize: '13px',
  },
  subGrid: {
    marginRight: '3%',
    marginLeft: '1%',
  },
  infoIcon: {
    color: theme.palette.primaryColor,
    fontSize: 20,
    lineHeight: '10px',
    marginRight: '3px',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
    marginTop: '1%',
  },
}));

function PersonalTitle(props) {
  const { setIsOpen } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const mokuhyoList = useSelector((state) => state.mokuhyoList.mokuhyoList);

  const handleOpenCreate = () => {
    setIsOpen(true);
  };

  return (
    <Grid container style={{ marginTop: '1%' }}>
      <p className={baseClasses.title3}>
        {mokuhyoList.userLastName} {mokuhyoList.userFirstName}
      </p>
      <Grid className={classes.subGrid}>
        <p className={baseClasses.title3}>の目標履歴</p>
      </Grid>
      <NormalTooltip title="ohayo" style={{ height: '50px' }}>
        <InfoIcon className={classes.infoIcon} />
      </NormalTooltip>
      <Button href="#" className={classes.addInfo} onClick={handleOpenCreate}>
        新規追加
      </Button>
    </Grid>
  );
}

export default PersonalTitle;
