import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import commonStyles from '../../styles';
import { classNames } from '../../../commonFunction';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 104,
    background: '#F3F3F3',
  },
  warningIcon: {
    color: '#D83420',
    fontSize: 18,
    display: 'none',
  },
  danger: {
    color: '#D83420',
    background: 'rgba(216, 52, 32, 0.08)',
    '& svg': {
      display: 'initial',
    },
  },
});

function EmptyAlert(props) {
  const { danger, children } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  return (
    <Grid
      className={classNames(
        classes.container,
        danger ? classes.danger : null,
      )}
    >
      <WarningIcon className={classes.warningIcon} />
      <Typography className={`${baseClasses.strong} ${classes.txt}`}>
        { children || '0本' }
      </Typography>
    </Grid>
  );
}

export default EmptyAlert;
