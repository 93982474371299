import client from '../httpClient';

// user: users/{id} ユーザー取得
const userDetailApi = (userId) => {
  //   const GET_USER_LIST_URL = `/api/v1/users/${String(userId)}`;
  const GET_USER_LIST_URL = `/users/${String(userId)}`;
  return client.get(GET_USER_LIST_URL, String(userId));
};

export default userDetailApi;

// {
//   "message": "message",
//   "description": "description",
//   "data": {
//     "userId": 1,
//     "activeDirectoryAuthenticationId": "1",
//     "userCode": "12",
//     "status": 1,
//     "lastName": "田中",
//     "firstName": "太郎",
//     "lastNameKana": "タナカ",
//     "firstNameKana": "タロウ",
//     "mail": "123test@example.com",
//     "mobilePhoneNumber": "08000008888",
//     "startAt": "2021/12/31",
//     "endAt": "2022/3/15",
//     "isHide": 0,
//     "genderCode": 0,
//     "retiredMemo": "退職メモテスト",
//     "recruitedSeason": 4.1,
//     "memo": "メモテスト",
//     "isQualilfication": 0,
//     "userDivisionCode": 1,
//     "divisions": [
//       {
//         "isMainDivision": 1,
//         "divisionId": 1,
//         "gradeCode": 1,
//         "startAt": "2022/1/15",
//         "endAt": "2022/4/15"
//       }
//     ],
//     "systems": [
//       {
//         "systemId": 1,
//         "roleGroupId": 3
//       }
//     ],
//     "userBusinesses": [
//       {
//         "userBusinessId": 1,
//         "assignAt": "2022/1/15",
//         "isSalesTarget": 1
//       }
//     ],
//     "divisionHistories": [
//       {
//         "isMainDivision": 1,
//         "divisionHistorieId": 1,
//         "divisionId": 1,
//         "divisionGroupName": "テスト",
//         "userRoleGroupId": 1,
//         "gradeCode": 1
//       }
//     ]
//   }
// }
