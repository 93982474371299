import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import commonStyles from '../../../styles';
import { MEMBERS_REDUCER_KEYS } from '../../../../containers/browsingHistory/browsingHistoryReducer';

const useStyles = makeStyles((theme) => ({
  listItem: {
    width: '100%',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  listItemText: {
    '& > span': {
      display: 'flex',
    },
  },
  gutters: {
    margin: '0 auto',
    padding: '0',
    '&.Mui-selected': {
      background: '#fff',
      color: theme.palette.primaryColor,
    },
    '& > .MuiListItemText-root': {
      marginTop: '0px',
      marginBottom: '0px',
      padding: '16px',
      borderBottom: '1px solid #F3F3F3',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  dataWrap: {
    display: 'flex',
    marginRight: 4,
    '& > span': {
      fontWeight: '700',
    },
    '& > * + *': {
      marginLeft: 24,
    },
  },
  date: {
    fontSize: 13,
    fontWeight: '400',
  },
}));

export default function RelatedMemberListSp(props) {
  const { list, setOpen, state, setState } = props;
  const classes = useStyles();
  const baseStyles = commonStyles();

  return (
    <List className={classes.list}>
      {list.map((item) => (
        <ListItem
          button
          key={item.memberId}
          className={`${classes.listItem} ${item.memberId === state.currentMemberId ? baseStyles.colorOhBlue : ''}`}
          classes={{ gutters: classes.gutters }}
          selected={item.val}
          onClick={() => {
            if (item.memberId !== state.currentMemberId) {
              setState({
                type: MEMBERS_REDUCER_KEYS.currentMemberId,
                payload: item.memberId,
              });
            }
            setOpen(false);
          }}
        >
          <ListItemText
            primary={
              <Grid className={classes.flex}>
                <Grid className={classes.dataWrap}>
                  <span>{item.memberId}</span>
                  <span>{`${item.lastName ?? ''} ${item.firstName ?? ''}`}</span>
                </Grid>
                <span className={classes.date}>{item.lastAccessAt}</span>
              </Grid>
            }
            className={classes.listItemText}
          />
        </ListItem>
      ))}
    </List>
  );
}
