import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';

import {
  convertForm1,
  convertFormNumber,
} from '../../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormInt1,
} from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  family: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
  },
  family1: {
    width: 80,
    marginRight: 16,
  },
  family2: {
    width: 80,
    marginRight: 16,
  },
  family3: {
    width: 82,
    marginRight: 16,
  },
  family4: {
    width: 82,
    marginRight: 16,
  },
  family5: {
    width: 78,
    marginRight: 16,
  },
  family6: {
    width: 300,
  },
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      fontWeight: '700',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
}));

export default function ResponseRegisterFamily(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    user,
    detail,
    setDetail,
    errorCallback,
  } = props;

  const initial = detail;

  const onBlur = (e) => {
    if (e.target.value !== detail[e.target.name]) {
      initial[e.target.name] = e.target.value;
      setDetail({ ...detail });
      console.log(detail[e.target.name]);
    }
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>
        家族構成
      </Typography>
      <Grid className={classes.family}>
        <Grid className={classes.family1}>
          <Typography className={baseClasses.title4}>大人</Typography>
          <TextBaseField
            placeholder="-"
            defaultValue={user.adultCount}
            className={classes.textField}
            type="number"
            name="adultCount"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('adultCount')}
          />
        </Grid>
        <Grid className={classes.family2}>
          <Typography className={baseClasses.title4}>子供</Typography>
          <TextBaseField
            placeholder="-"
            defaultValue={user.childCount}
            className={classes.textField}
            type="number"
            name="childCount"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('childCount')}
          />
        </Grid>
        <Grid className={classes.family3}>
          <Typography className={baseClasses.title4}>子供の年齢1</Typography>
          <TextBaseField
            placeholder="-"
            defaultValue={user.childAge}
            className={classes.textField}
            type="number"
            name="childAge"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('childAge')}
          />
        </Grid>
        <Grid className={classes.family4}>
          <Typography className={baseClasses.title4}>子供の年齢2</Typography>
          <TextBaseField
            placeholder="-"
            defaultValue={user.otherChildAge}
            className={classes.textField}
            type="number"
            name="otherChildAge"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('otherChildAge')}
          />
        </Grid>
        <Grid className={classes.family5}>
          <Typography className={baseClasses.title4}>入居予定人数</Typography>
          <TextBaseField
            placeholder="-"
            defaultValue={user.familyTotalCount}
            className={classes.textField}
            type="number"
            name="familyTotalCount"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('familyTotalCount')}
          />
        </Grid>
        <Grid className={classes.family6}>
          <Typography className={baseClasses.title4}>入居者の予定構成（誰が）</Typography>
          <TextBaseField
            placeholder="-"
            defaultValue={user.familyWho}
            className={classes.textField}
            type="text"
            name="familyWho"
            onBlur={onBlur}
            convertor={convertForm1}
            validator={validateFormString1({ maxLengthInt: 50 })}
            errorCallback={errorCallback('familyWho')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
