import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import MemberChangeConfirm from './memberChangeConfirm';
import commonTheme from '../../styles/theme';
import { WEB_MEMBER_FORCED_QUIT_USE } from '../../../constants/userRole';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      width: 320,
    },
  },
  header: {
    [commonTheme.breakpoints.up('md')]: {
      height: 8,
      cursor: 'move',
    },
  },
  content: {
    padding: '0 0 8px',
  },
  button: {
    width: '100%',
    [commonTheme.breakpoints.up('md')]: {
      padding: '11px 16px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
      borderBottom: '1px solid #F3F3F3',
    },
    '& .MuiButton-label': {
      display: 'block',
    },
    '&:not(:last-child)': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid #C8C8C8',
      },
    },
  },
  ttl: {
    fontSize: 15,
    lineHeight: '1.6',
    letterSpacing: '0',
    textAlign: 'left',
  },
  txt: {
    fontSize: 13,
    lineHeight: '1.6',
    letterSpacing: '0',
    textAlign: 'left',
  },
  link: {
    color: theme.palette.primaryColor,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

/**
 * MemberChange
 * @param {{
 * open: boolean;
 * onClose: () => void;
 * onChange?: (params: {memberStatusCode: number; isQuit: number}) => void;
 * statusCode: number;
 * }} props
 * @returns {ReactNode}
 */
export default function MemberChange(props) {
  const {
    open,
    onClose,
    onChange,
    statusCode,
  } = props;

  const { roleIds } = useSelector((state) => state.responseHeader);
  const classes = useStyles();
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const [state, setState] = useState({
    isShowConfirmDialog: false,
    confrimInfo: {},
  });

  const confirmInfoList = [
    {
      memberStatusCode: 2,
      ttl: 'プレミアム会員に変更',
      txt: 'お客様にご連絡メールを送信します。よろしいですか？',
      disabled: statusCode === 2,
      content: (
        <>
          ※ お客様に
          <Link
            onClick={(e) => e.stopPropagation()}
            href="/images/mail_premium.gif"
            target="_blank"
            className={classes.link}
          >
            ご連絡メール(サンプル)
          </Link>を送信します
        </>
      ),
    },
    {
      memberStatusCode: 3,
      ttl: 'プレミアム会員を拒否',
      txt: '今、プレミアム会員のお客様は通常会員になってしまいます。よろしいですか？',
      disabled: statusCode === 3,
      content: '※ お客様へのご連絡メールなし',
    },
    {
      memberStatusCode: 0,
      ttl: '通常会員に戻す',
      txt: '本当にこのお客様を「通常会員」に戻してもよろしいですか？',
      disabled: statusCode === 0,
      content: '※ お客様へのご連絡メールなし',
    },
  ];

  if (roleIds.includes(WEB_MEMBER_FORCED_QUIT_USE)) {
    confirmInfoList.push({
      isQuit: 1,
      ttl: '強制退会',
      txt: <span>強制退会にすると、<br />・お客様は自社HPにアクセスできません。<br />・顧客メインでは「追わない」になります。</span>,
      content: '※ お客様へのご連絡メールなし',
    });
  }

  const onClick = (index) => {
    setState({
      ...state,
      confrimInfo: confirmInfoList[index],
      isShowConfirmDialog: true,
    });
  };

  const onConfirmDialogClose = () => {
    setState({
      ...state,
      isShowConfirmDialog: false,
    });
  };

  const onSubmit = () => {
    const { memberStatusCode, isQuit } = state.confrimInfo;
    onConfirmDialogClose();
    onClose();
    onChange?.({
      memberStatusCode,
      isQuit,
    });
  };

  const body = (
    <Grid className={classes.paper}>
      {!isSP && (
        <Grid
          id="draggable-dialog-title"
          className={classes.header}
        >
          &nbsp;
        </Grid>
      )}
      <Grid className={classes.content}>
        {confirmInfoList.map((item, i) => (
          <Button
            key={String(i)}
            className={classes.button}
            disabled={item.disabled}
            onClick={() => onClick(i)}
          >
            <Typography className={classes.ttl}>
              {item.ttl}
            </Typography>
            <Typography className={classes.txt}>{item.content}</Typography>
          </Button>
        ))}
        <MemberChangeConfirm
          open={state.isShowConfirmDialog}
          onSubmit={onSubmit}
          onClose={onConfirmDialogClose}
          data={state.confrimInfo}
        />
      </Grid>
    </Grid>
  );

  return (
    isSP ? (
      <Grid>{body}</Grid>
    ) : (
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          className={classes.dialog}
        >
          {body}
        </Dialog>
      </div>
    )
  );
}
