export default function personSearchIcon(props) {
  const { classnameprops } = props;
  return (
    <svg className={classnameprops} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.625 12C12.8341 12 14.625 10.2091 14.625 8C14.625 5.79086 12.8341 4 10.625 4C8.41586 4 6.625 5.79086 6.625 8C6.625 10.2091 8.41586 12 10.625 12Z" />
      <path d="M10.975 14.01C8.245 13.91 2.625 15.27 2.625 18V20H12.165C9.695 17.24 10.935 14.11 10.975 14.01Z" />
      <path d="M20.055 18.02C20.415 17.43 20.625 16.74 20.625 16C20.625 13.79 18.835 12 16.625 12C14.415 12 12.625 13.79 12.625 16C12.625 18.21 14.415 20 16.625 20C17.365 20 18.055 19.78 18.645 19.43L21.215 22L22.625 20.59L20.055 18.02ZM16.625 18C15.525 18 14.625 17.1 14.625 16C14.625 14.9 15.525 14 16.625 14C17.725 14 18.625 14.9 18.625 16C18.625 17.1 17.725 18 16.625 18Z" />
    </svg>
  );
}
