import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scheduleId: 0,
};

export const schedulesUpdateSlice = createSlice({
  name: 'schedulesUpdate',
  initialState: { schedulesUpdate: initialState },
  reducers: {
    setSchedulesUpdateApi: (state, data) => {
      state.schedulesUpdate = data.payload;
    },
  },
});

export const { setSchedulesUpdateApi } = schedulesUpdateSlice.actions;

export default schedulesUpdateSlice.reducer;
