import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function TaiouRirekiSearchCheckBox(props) {
  const { item, flg, index, row, clickCheckFunc } = props;

  const [checked, setChecked] = useState(flg);

  const handleChange = (e) => {
    clickCheckFunc(e, item);
    setChecked(e.target.checked);
  };

  const rowCheckLift = () => {
    switch (index) {
      case row.no:
        switch (row.check) {
          case 1:
            setChecked(true);
            break;
          case 2:
            setChecked(false);
            break;
          default:
            // 処理なし
        }
        break;
      default:
        // 処理なし
    }
  };
  useEffect(() => {
    rowCheckLift();
  });

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            color="primary"
          />
        }
        label={item.label}
      />
    </FormGroup>
  );
}
