import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  libraryDirectoryId: 0,
  libraries: [
    {
      libraryDirectoryId: null,
      libraryId: null,
      libraryName: '',
      mailSubject: '',
      mailBody: '',
      libraryMemo: '',
      userId: 0,
    },
  ],
};

export const libraryGetSignitureSlice = createSlice({
  name: 'libraryGetSigniture',
  initialState: { libraryGetSigniture: initialState },
  reducers: {
    libraryGetSigniture: (state, { payload }) => {
      const { libraryDirectoryId, libraries } = payload;
      state.libraryGetSigniture.libraryDirectoryId = libraryDirectoryId;
      state.libraryGetSigniture.libraries = libraries.sort((a, b) => a.libraryId - b.libraryId);
    },
  },
});

export const { libraryGetSigniture } = libraryGetSignitureSlice.actions;

export default libraryGetSignitureSlice.reducer;
