import client from '../httpClient';

/**
 * 【hpInfo010】WEB会員情報削除
 * http://10.22.2.48:8080/docs/#/members/delete-members-memberId
 * @param {{
 * memberId: number;
 * }} params
 * @returns {Promise<{memberid: number}>}
 */
const hpinfoDeleteApi = async (memberId) => {
  try {
    const res = await client.delete(`/members/${memberId}`);
    return res.data;
  } catch (e) {
    return {};
  }
};

export default hpinfoDeleteApi;
