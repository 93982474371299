import {
  Button,
  makeStyles,
  TableCell,
  TableRow,
  // TextField,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Delete } from '@material-ui/icons';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import commonStyles from '../../styles';
import { USER_CLASS_SELECT } from '../../../constants/users';
import { TextBaseField } from '../../eleCommon/validation';
import { validateFormFloat1 } from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';

import { MAN_YEN } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  cell: { padding: '4px 8px', width: '20%' },
  row: { '&:nth-child(odd)': { background: '#F3F3F3' } },
  hover: { background: `${theme.palette.primaryColor}29 !important` },
  opacity: { opacity: 0 },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0)',
    },
    '& input': {
      fontSize: 13,
      textAlign: 'right',
    },
  },
}));

export default function AimTableRow(props) {
  const { row, cells, deleteFunction, onUpdate } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const common = commonStyles();
  const [hover, setHover] = useState(false);
  const [update, setUpdate] = useState({
    positionCode: row.positionCode,
    divisionId: row.divisionId,
    sales: row.sales / MAN_YEN,
    buildingCount: row.buildingCount,
  });

  const handleClick1 = () => {
    console.log('CANCEL');
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const newUpdate = { ...update, [name]: value };
    onUpdate?.(row.keymalisTargetSettingId, newUpdate);
    setUpdate(newUpdate);
  };

  const alignmentDelete = () => {
    deleteFunction(row.keymalisTargetSettingId);
  };

  const confirmOpen = () => {
    dispatch(
      changeConfirmMessage({
        title: '選択項目を削除',
        msgList: ['削除してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: handleClick1,
            classes: common.buttonsSecondary,
          },
          {
            label: 'OK',
            set: alignmentDelete,
            classes: common.buttonsPrimary,
          },
        ],
      }),
    );
  };

  return (
    <TableRow
      className={`${hover ? classes.hover : null} ${classes.row}`}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
    >
      {cells.map((c) => {
        let result = row[c.id];
        switch (c.id) {
          case 'positionCode':
            result = (
              <Typography className={common.title6}>
                {USER_CLASS_SELECT.menus[row[c.id]]}
              </Typography>
            );
            break;
          case 'buildingCount':
            result = (
              <TextBaseField
                type="number"
                name="buildingCount"
                defaultValue={update.buildingCount}
                onBlur={handleBlur}
                className={`${classes.input} ${common.title6}`}
                convertor={convertFormNumber}
                validator={validateFormFloat1({
                  minFloat: 0.0,
                  maxFloat: 99999.0,
                  precisionInt: 4,
                })}
              />
            );
            break;
          case 'sales':
            result = (
              <TextBaseField
                type="number"
                name="sales"
                defaultValue={update.sales}
                onBlur={handleBlur}
                className={`${classes.input} ${common.title6}`}
                convertor={convertFormNumber}
                validator={validateFormFloat1({
                  minFloat: 0.0,
                  maxFloat: 99999.0,
                  precisionInt: 4,
                })}
              />
            );
            break;
          default:
            result = <Typography className={common.title6}>{result}</Typography>;
            break;
        }
        return (
          <TableCell key={c.id} className={classes.cell}>
            {result}
          </TableCell>
        );
      })}
      <TableCell className={classes.cell}>
        <Button
          onClick={confirmOpen}
          className={`${common.buttonsSecondary} ${
            !hover ? classes.opacity : null
          }`}
        >
          <Delete />
          削除
        </Button>
      </TableCell>
    </TableRow>
  );
}
