import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, Select, MenuItem } from '@material-ui/core';

import commonStyles from '../../styles';
import DataElementLayout from './dataElementLayout';
import HolidayTel from '../../common/holidayTel';
import ResponseTypeModal from '../../common/responseType/responseTypeModal';
import SelectRailwaysAndStationsContainer from '../../../containers/search/railwayAndStations/selectRailwayAndStations';
import { JOIN_DATA_TYPES } from '../../../constants/crm';

import { getChildField } from './helpers';

const useStyles = makeStyles(() => ({
  input: {
    opacity: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
    cursor: 'pointer',
  },
  errorLabel: {
    width: 130,
    marginRight: 30,
    color: '#D83420',
  },
  selectBox: {
    padding: 0,
  },
}));

export default function DataGroup(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { register, setFormValue, dataGroup, diffOnly, formData, operation } = props;
  const [parentGroup, setParentGroup] = useState({ content: {}, text: {} });

  const [isRequiredErrorGroup, setIsRequiredErrorGroup] = useState({});

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalStates, setModalStates] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const { groupName, inputGroup } = dataGroup;

  useEffect(() => {
    if (groupName === '反響媒体' && operation === JOIN_DATA_TYPES.RETURN) {
      setIsDisabled(true);
    }
  }, []);

  const judgeArrow = (idx, data) => {
    // 各要素に差異がある場合
    const isDifferent = parentGroup.content[data.variableName]
      && parentGroup.content[data.variableName] !== data.childContent;
    if (groupName === 'お休みの日') return isDifferent;
    if (idx === 0) {
      switch (groupName) {
        case '反響媒体':
          if (operation !== JOIN_DATA_TYPES.RETURN) {
            return !inputGroup.every((o) => parentGroup.content[o.variableName] === o.childContent);
          } else {
            return false;
          }
        case '希望路線1':
        case '希望路線2':
          return !inputGroup.every((o) => parentGroup.content[o.variableName] === o.childContent);
        default:
          return isDifferent;
      }
    }
    return false;
  };

  const handleArrowClick = () => {
    if (groupName === 'お休みの日') {
      return;
    }
    // すべての要素を反映
    const tmp = { content: {}, text: {} };
    inputGroup.forEach((input) => {
      tmp.text[input.variableName] = input.childDisplayName;
      tmp.content[input.variableName] = input.childContent;
      setFormValue(input.variableName, input.childContent);
    });
    setParentGroup(tmp);
  };

  const getParentInput = (data) => {
    if (data.inputType === 'modal') {
      return (
        <span>
          <span>
            {
              (parentGroup.text && parentGroup.text[data.variableName])
              || (parentGroup.content && parentGroup.content[data.variableName])
            }
          </span>
          <input
            {...register(data.variableName)}
            type="text"
            defaultValue={data.parentContent}
            onClick={() => setIsOpenModal(true)}
            className={classes.input}
            disabled={isDisabled}
          />
        </span>
      );
    } else if (data.map) {
      const checkDisabledItem = (key) => {
        if (
          data.lowerLimitVariable
          && parentGroup.content[data.lowerLimitVariable]
        ) {
          return parentGroup.content[data.lowerLimitVariable] > Number(key);
        }
        if (
          data.upperLimitVariable
          && parentGroup.content[data.upperLimitVariable]
        ) {
          return parentGroup.content[data.upperLimitVariable] < Number(key);
        }
        return false;
      };
      const handleSelectChange = (e) => {
        setParentGroup((state) => {
          const tmp = { ...state };
          tmp.content[data.variableName] = Number(e.target.value);
          return tmp;
        });
      };
      return (
        <FormControl>
          <Grid className={`${baseClasses.inputWrap} ${classes.selectBox}`}>
            <Select
              {...register(data.variableName)}
              onChange={handleSelectChange}
              defaultValue={data.parentContent}
              className={baseClasses.small}
            >
              <MenuItem value="" style={{ display: 'none' }} />
              <MenuItem value="null" style={{ display: 'none' }} />
              {Object.keys(data.map).map((k) => (
                <MenuItem value={k} key={k} disabled={checkDisabledItem(k)}>
                  {data.map[k]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </FormControl>
      );
    }
    return null;
  };

  // modal制御
  let modalComponenet;
  let initial;
  let setModalState;
  switch (groupName) {
    case 'お休みの日':
      initial = {};
      dataGroup.inputGroup.forEach((input) => {
        initial[input.variableName] = input.parentContent;
      });
      setModalState = (holidayTel) => {
        if (!holidayTel) return;
        setFormValue('holidayWeekday', holidayTel.holidayWeekday);
        setFormValue('otherHolidayWeekday', holidayTel.otherHolidayWeekday);
        setFormValue('convenientTime', holidayTel.convenientTime);
        setFormValue('otherConvenientTime', holidayTel.otherConvenientTime);
        setParentGroup({
          content: {
            holidayWeekday: holidayTel.holidayWeekday,
            otherHolidayWeekday: holidayTel.otherHolidayWeekday,
            convenientTime: holidayTel.convenientTime,
            otherConvenientTime: holidayTel.otherConvenientTime,
          },
        });
      };
      modalComponenet = (
        <HolidayTel
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          category="weekday"
          onClickSelectButton={setModalState}
          initialParam={initial}
        />
      );
      break;
    case '反響媒体':
      setModalState = (responseTypeList) => {
        if (responseTypeList.length === 0) return;
        setModalStates(responseTypeList);
        setParentGroup({
          text: {
            largeResponseTypeId: responseTypeList[0].responseTypeName,
            mediumResponseTypeId: responseTypeList[1]?.responseTypeName || '',
            smallResponseTypeId: responseTypeList[2]?.responseTypeName || '',
            fineResponseTypeId: responseTypeList[3]?.responseTypeName || '',
          },
          content: {
            largeResponseTypeId: responseTypeList[0].responseTypeId,
            mediumResponseTypeId: responseTypeList[1]?.responseTypeId || '',
            smallResponseTypeId: responseTypeList[2]?.responseTypeId || '',
            fineResponseTypeId: responseTypeList[3]?.responseTypeId || '',
          },
        });
        setFormValue('largeResponseTypeId', responseTypeList[0].responseTypeId);
        setFormValue('mediumResponseTypeId', responseTypeList[1]?.responseTypeId || '');
        setFormValue('smallResponseTypeId', responseTypeList[2]?.responseTypeId || '');
        setFormValue('fineResponseTypeId', responseTypeList[3]?.responseTypeId || '');
      };
      modalComponenet = (
        <ResponseTypeModal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          state={modalStates || []}
          setState={setModalState}
        />
      );
      break;
    case '希望路線1':
    case '希望路線2':
      setModalState = (railwayAndStation) => {
        if (!railwayAndStation) return;
        const prefix = groupName === '希望路線1' ? '1' : '2';
        const tmp = { content: {}, text: {} };
        tmp.text[`wishRailwayId${prefix}`] = railwayAndStation.railway.railwayName;
        tmp.text[`wishStationIdFrom${prefix}`] = railwayAndStation.fromStation.name;
        tmp.text[`wishStationIdTo${prefix}`] = railwayAndStation.toStation.name;
        tmp.content[`wishRailwayId${prefix}`] = railwayAndStation.railway.railwayId;
        tmp.content[`wishStationIdFrom${prefix}`] = railwayAndStation.fromStation.railwayId;
        tmp.content[`wishStationIdTo${prefix}`] = railwayAndStation.toStation.railwayId;

        setParentGroup(tmp);
        setFormValue(`wishRailwayId${prefix}`, railwayAndStation.railway.railwayId);
        setFormValue(`wishStationIdFrom${prefix}`, railwayAndStation.fromStation.stationId);
        setFormValue(`wishStationIdTo${prefix}`, railwayAndStation.toStation.stationId);
      };
      modalComponenet = (
        <SelectRailwaysAndStationsContainer
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          onUpdate={setModalState}
        />
      );
      break;
    default:
      modalComponenet = null;
      setModalState = null;
      break;
  }
  useEffect(() => {
    const tmp = { content: {}, text: {} };
    inputGroup.forEach((obj) => {
      tmp.content[obj.variableName] = obj.parentContent;
      tmp.text[obj.variableName] = obj.parentDisplayName;
    });
    setParentGroup(tmp);
  }, [dataGroup]);

  useEffect(() => {
    // required validation check
    if (formData) {
      const tmp = {};
      inputGroup.forEach((obj) => {
        tmp[obj.variableName] = obj.parentContent === '';
      });
      setIsRequiredErrorGroup(tmp);
    }
  }, [formData]);

  return (
    <>
      {inputGroup.map((input, idx) => (
        !(diffOnly && input.parentContent === input.childContent) ? (
          <DataElementLayout
            label={input.label}
            required={input.required}
            parentInput={getParentInput(input)}
            childInput={getChildField(input)}
            isArrow={judgeArrow(idx, input)}
            handleArrowClick={handleArrowClick}
            isModal={input.inputType === 'modal'}
            isRequiredError={input.required && isRequiredErrorGroup[input.variableName]}
            isDisabled={isDisabled}
          />
        ) : null
      ))}
      {modalComponenet}
    </>
  );
}
