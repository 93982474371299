const getTargetFunction = (obj, divisionId, keywords, isShowOnlyDIvision) => {
  const targetObj = {};
  const divisionIdArray = [];
  let countDiv = 0;
  // 処理実行
  const getTargetDivision = (theArr, searchDivId) => {
    let result = null;
    let appendFlg = false;
    if (theArr instanceof Array) {
      for (let i = 0; i < theArr.length; i += 1) {
        result = getTargetDivision(theArr[i], divisionId);
        if (result) {
          break;
        }
      }
    } else {
      if ('id' in theArr && 'name' in theArr) {
        appendFlg = true;
        divisionIdArray.push(String(theArr.id));
      }
      for (const prop in theArr) {
        if (prop === 'id') {
          if (theArr[prop] === searchDivId) {
            const divisionKey = 'divisions';
            if (!targetObj[divisionKey]) {
              targetObj[divisionKey] = [];
            }
            const divList = divisionIdArray.slice(0, divisionIdArray.length);
            targetObj[divisionKey].push({
              children: theArr.children,
              users: theArr.users,
              divisionId: theArr.id,
              divIds: divList,
              treeObj: theArr, // 組織ツリーのデフォルト設定で使用
              groupName: theArr.name,
              subName: theArr.name,
              type: 'division',
            });
            countDiv += 1;
          }
        } else if (prop === 'userName') {
          if (keywords === '' || isShowOnlyDIvision) {
            break;
          }
          if (theArr[prop].includes(keywords)) {
            const divisionKey = 'users';
            if (!targetObj[divisionKey]) {
              targetObj[divisionKey] = [];
            }
            targetObj[divisionKey].push({
              userId: theArr.userId,
              userDivisionId: theArr.userDivisionId,
              userName: theArr.userName,
              isMainDivision: theArr.isMainDivision,
              type: 'user',
            });
            countDiv += 1;
          }
        }
        if (theArr[prop] instanceof Object || theArr[prop] instanceof Array) {
          result = getTargetDivision(theArr[prop], divisionId);
          if (result) {
            break;
          }
        }
      }
    }
    if (appendFlg) {
      divisionIdArray.pop();
    }
    return result;
  };
  getTargetDivision(obj, divisionId);
  if (Object.keys(targetObj).length > 0) {
    targetObj.count = countDiv;
  }
  return targetObj;
};

export default getTargetFunction;

// const getTargetFunction = (obj, divisionId, keywords, isShowOnlyDIvision) => {
//   const targetObj = {};
//   const divisionIdArray = [];
//   let countDiv = 0;
//   // 処理実行
//   const getTargetDivision = (theArr, searchDivId) => {
//     let result = null;
//     let appendFlg = false;
//     if (theArr instanceof Array) {
//       for (let i = 0; i < theArr.length; i += 1) {
//         result = getTargetDivision(theArr[i], divisionId);
//         if (result) {
//           break;
//         }
//       }
//     } else {
//       if ('divisionId' in theArr && 'subName' in theArr) {
//         appendFlg = true;
//         divisionIdArray.push(String(theArr.divisionId));
//       }
//       for (const prop in theArr) {
//         if (prop === 'divisionId') {
//           if (theArr[prop] === searchDivId) {
//             const divisionKey = 'divisions';
//             if (!targetObj[divisionKey]) {
//               targetObj[divisionKey] = [];
//             }
//             const divList = divisionIdArray.slice(0, divisionIdArray.length);
//             targetObj[divisionKey].push({
//               children: theArr.children,
//               users: theArr.users,
//               divisionId: theArr.divisionId,
//               divIds: divList,
//               treeObj: theArr, // 組織ツリーのデフォルト設定で使用
//               groupName: theArr.groupName,
//               subName: theArr.subName,
//               type: 'division',
//             });
//             countDiv += 1;
//           }
//         } else if (prop === 'userName') {
//           if (keywords === '' || isShowOnlyDIvision) {
//             break;
//           }
//           if (theArr[prop].includes(keywords)) {
//             const divisionKey = 'users';
//             if (!targetObj[divisionKey]) {
//               targetObj[divisionKey] = [];
//             }
//             targetObj[divisionKey].push({
//               userId: theArr.userId,
//               userDivisionId: theArr.userDivisionId,
//               userName: theArr.userName,
//               isMainDivision: theArr.isMainDivision,
//               type: 'user',
//             });
//             countDiv += 1;
//           }
//         }
//         if (theArr[prop] instanceof Object || theArr[prop] instanceof Array) {
//           result = getTargetDivision(theArr[prop], divisionId);
//           if (result) {
//             break;
//           }
//         }
//       }
//     }
//     if (appendFlg) {
//       divisionIdArray.pop();
//     }
//     return result;
//   };
//   getTargetDivision(obj, divisionId);
//   if (Object.keys(targetObj).length > 0) {
//     targetObj.count = countDiv;
//   }
//   return targetObj;
// };

// export default getTargetFunction;
