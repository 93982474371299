import { createContext, useContext } from 'react';
import { useUpdate, isChanged } from '../../../containers/customerMain';

const Context = createContext({});

export function useHolidayTimeContext() {
  return useContext(Context);
}

export function HolidayTimeProvider(props) {
  const { children, customer } = props;
  const { current, original, update, isSp } = useUpdate(customer, [
    'customerId',
    'holidayWeekday',
    'otherHolidayWeekday',
    'convenientTime',
    'otherConvenientTime',
  ]);

  // お休み / TEL時間帯 ウィンドウ
  const selectHolidayTel = {
    holidayWeekday: current.holidayWeekday || '',
    otherHolidayWeekday: current.otherHolidayWeekday || '',
    convenientTime: current.convenientTime || '',
    otherConvenientTime: current.otherConvenientTime || '',
  };

  const clickSelectBoxFunc = (data) => {
    console.log('gotten data:', data);
    const newData = {
      holidayWeekday: data.holidayWeekday,
      otherHolidayWeekday: data.otherHolidayWeekday,
      convenientTime: data.convenientTime,
      otherConvenientTime: data.otherConvenientTime,
    };
    // 更新
    if (isChanged(newData, original)) update(newData, true);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    selectHolidayTel,
    clickSelectBoxFunc,
    current,
    isSp,
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}
