import React from 'react';
import {
  Grid,
  FormGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import CheckBoxLabel from '../../../eleCommon/checkBoxLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  formGroup: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'unset',
    },
    '& label': {
      [commonTheme.breakpoints.down('sm')]: {
        background: '#fff',
        justifyContent: 'space-between',
        borderBottom: '1px solid #F3F3F3',
        padding: '8px 10px 8px 16px',
      },
      '& > span': {
        '&:first-child': {
          [commonTheme.breakpoints.down('sm')]: {
            order: 2,
            margin: 0,
            borderRadius: 0,
          },
        },
        '&:last-child': {
          [commonTheme.breakpoints.down('sm')]: {
            order: 1,
            margin: 0,
            width: '100%',
          },
        },
        '&.Mui-checked + .MuiFormControlLabel-label': {
          [commonTheme.breakpoints.down('sm')]: {
            color: theme.palette.primaryColor,
            fontWeight: 700,
          },
        },
        '& > .MuiIconButton-label': {
          background: '#fff',
          width: 18,
          height: 18,
          borderRadius: 4,
          margin: 6,
        },
      },
    },
  },
}));

function InfoRegisterCheck1(props) {
  const { values } = props;
  const classes = useStyles();

  return (
    <Grid
      className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
    >
      <FormGroup className={classes.formGroup}>
        {values.state.map((data) => (
          <CheckBoxLabel
            key={data.label}
            label={data.label}
            initial={data.checked}
            set={data.set}
          />
        ))}
      </FormGroup>
    </Grid>
  );
}

export default InfoRegisterCheck1;
