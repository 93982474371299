import { createSlice } from '@reduxjs/toolkit';

// TODO: 以下Component開発者テスト用にinitial作成
const initialState = { lists: [] };

export const usersSlice = createSlice({
  name: 'users',
  initialState: { userList: initialState },
  reducers: {
    getUserListApi: (state, data) => {
      state.userList = data.payload;
    },
  },
});

export const { getUserListApi } = usersSlice.actions;

export default usersSlice.reducer;
