import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import OrgSettingMain from './orgSettingMain';
import OrgSettingDelete from './orgSettingDelete';
import OrgSettingTransfer from './orgSettingTransfer';
import {
  USER_CLASS_SELECT,
  USER_GRADE_SELECT_LIST,
  ALL_CUSTOMER_STATUS_CODE,
} from '../../../../constants/users';

const useStyles = makeStyles((theme) => ({
  orgGridRow: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    marginTop: '1%',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    height: '53px',
  },
  textButton: {
    color: theme.palette.primaryColor,
    fontSize: '13px',
    '&:hover': {
      boxShadow: `1px 1px 3px ${theme.palette.primaryColor}`,
      border: `1px solid ${theme.palette.primaryColor}`,
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
  resultMenuGrid: {
    marginLeft: 'auto',
    marginRight: '1.5rem',
    flexDirection: 'row',
    width: 'auto',
    gap: '10px',
  },
  textDisabled: {
    color: '#8C8C8C',
    fontSize: '10px',
  },
  border: {
    borderBottom: '2px solid #C8C8C8',
    paddingBottom: 10,
  },
}));

function OrgSettingRow(props) {
  const {
    createMode,
    setCreateMode,
    userState,
    updateUserFunction,
    deleteUserDivision,
    reloadUserTree,
    updateDivisionFunction,
  } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();

  const [selectDivision, setSelectDivision] = useState({
    divId: 0,
    divisionName: '-',
  });

  const [validate, setValidate] = useState(false);
  // Menu開閉State
  const [deleteState, setDeleteState] = useState(false);
  const [mainState, setMainState] = useState(false);
  const [transferState, setTransferState] = useState(false);
  // 変更用State
  const [localUserState, setLocalUserState] = useState({ divisions: [] });

  useEffect(() => {
    setLocalUserState(userState);
  }, [userState]);

  useEffect(() => {
    if (selectDivision.divId || mainState || deleteState) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [selectDivision]);

  // stateReset
  const resetEdit = () => {
    setDeleteState(false);
    setMainState(false);
    setTransferState(false);
    setLocalUserState(userState);
  };

  // Menu開閉Event
  const clickMenu = (setState, id) => {
    resetEdit();
    setSelectDivision({
      divId: 0,
      divisionName: '-',
    });
    setCreateMode(false);
    setState(id);
  };

  // 編集項目表示
  const selectMenu = (rowData) => {
    const selectWord = [
      { word: '削除', setState: setDeleteState, display: true },
      { word: '編集', setState: setMainState, display: true },
      { word: '異動', setState: setTransferState, display: rowData.isMainDivision === 1 },
    ];

    const clickRow = (w, data) => {
      const uniqueId = `${data.divisionId}-${data.userDivisionId}`;
      clickMenu(w.setState, uniqueId);
      // 行選択時に変更分のdivisionsしかデータを入れないように変更する
      setLocalUserState((s) => {
        const { divisions, ...newUserDetail } = structuredClone(s);
        newUserDetail.divisions = [];
        const selectedDivisions = divisions.filter((d) => d.userDivisionId === data.userDivisionId);
        if (selectedDivisions.length !== 0) {
          const tmp = selectedDivisions[0];
          const {
            userDivisionId,
            divisionId,
            isMainDivision,
            gradeCode,
            positionCode,
            startAt,
            endAt,
          } = tmp;
          const divisionsFrom = [{
            userDivisionId: tmp.userDivisionId,
            divisionId: tmp.divisionId,
            isMainDivision: tmp.isMainDivision,
            customerStatusCodes: [],
          }];
          newUserDetail.divisions.push({
            userDivisionId,
            divisionId,
            isMainDivision,
            gradeCode,
            positionCode,
            startAt,
            endAt,
            divisionsFrom,
          });
        }
        return newUserDetail;
      });
    };

    return (
      <>
        {selectWord.map((w) => w.display && (
          <Grid item style={{ width: '10%' }} key={String(rowData.divisionId) + w.word}>
            {rowData.isMainDivision && w.word === '削除' ? (
              <Typography className={classes.textDisabled}>メインは削除不可</Typography>) : (
                <Button
                  className={classes.textButton}
                  onClick={() => clickRow(w, rowData)}
                >
                  {w.word}
                </Button>
            )}
          </Grid>
        ))}
      </>
    );
  };

  // 保存処理
  const handleSave = async () => {
    resetEdit();
    if (deleteState) {
      const params = {
        isReceiveShare: Number(localUserState.isReceiveShare),
        isSendShare: Number(localUserState.isSendShare),
        customerStatusCode: ALL_CUSTOMER_STATUS_CODE,
      };
      await deleteUserDivision(deleteState.split('-')[1], params);
      reloadUserTree();
      return;
    }
    if (transferState) {
      const copyUserPrf = Object.assign({}, localUserState);
      delete copyUserPrf.divisionHistories;
      // IS_SEND_SHARE['1']
      if (!('isSendShare' in copyUserPrf)) copyUserPrf.isSendShare = 1;
      // IS_RECIEVE_SHARE['1']
      if (!('isReceiveShare' in copyUserPrf))copyUserPrf.isReceiveShare = 1;
      // Updateは決め打ちでDIVISION_UPDATE_TYPE['2']
      copyUserPrf.divisionUpdateTypeCode = 2;
      // FIXME 08/25以下不要
      if (copyUserPrf.systems.length === 0) delete copyUserPrf.systems;
      if (copyUserPrf.userBusinesses.length === 0) delete copyUserPrf.userBusinesses;
      await updateUserFunction(userState.userId, copyUserPrf);
      reloadUserTree();
      return;
    }
    await updateDivisionFunction(localUserState.divisions[0], localUserState.userId);
    reloadUserTree();
  };

  // キャンセル処理
  const handleCancel = () => {
    resetEdit();
    setLocalUserState(userState);
    setSelectDivision({
      divId: 0,
      divisionName: '-',
    });
  };

  // Common - cancel or save
  const resultMenu = () => {
    return (
      <Grid container className={classes.resultMenuGrid}>
        <Grid item>
          <Button
            className={baseClasses.cancelButton}
            onClick={() => handleCancel()}
          >
            キャンセル
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={baseClasses.buttonsPrimary}
            disabled={validate}
            onClick={() => {
              handleSave();
            }}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    return () => {
      setSelectDivision({
        divId: 0,
        divisionName: '-',
      });
    };
  }, []);

  return (
    <>
      {userState.divisions.map((data, index) => (
        <Grid
          container
          style={{ width: '100%' }}
          key={data.divisionId}
          className={`${(userState.divisions.length > 1 && index === 0) ? classes.border : null}`}
        >
          <Grid container className={classes.orgGridRow} key={data.divisionId}>
            <Grid item style={{ width: '25% ' }}>
              <Typography className={baseClasses.title4}>
                {data.isMainDivision ? data.divisionName + ' （メイン）' : data.divisionName}
              </Typography>
            </Grid>
            <Grid item style={{ width: '20% ' }}>
              <Typography className={baseClasses.title4}>
                {USER_CLASS_SELECT.menus[data.positionCode]}
              </Typography>
            </Grid>
            <Grid item style={{ width: '15% ' }}>
              <Typography className={baseClasses.title4}>
                {USER_GRADE_SELECT_LIST.menus[data.gradeCode]}
              </Typography>
            </Grid>
            {mainState === `${data.divisionId}-${data.userDivisionId}`
            || deleteState === `${data.divisionId}-${data.userDivisionId}`
            || transferState === `${data.divisionId}-${data.userDivisionId}` ? (
                resultMenu()) : (selectMenu(data))}
          </Grid>
          {mainState === `${data.divisionId}-${data.userDivisionId}` && !createMode ? (
            <OrgSettingMain
              setLocalUserState={setLocalUserState}
              division={data}
            />
          ) : null}
          {deleteState === `${data.divisionId}-${data.userDivisionId}` && !createMode ? (
            <OrgSettingDelete
              localUserState={localUserState}
              setLocalUserState={setLocalUserState}
              division={data}
            />
          ) : null}
          {transferState === `${data.divisionId}-${data.userDivisionId}` && !createMode ? (
            <OrgSettingTransfer
              setLocalUserState={setLocalUserState}
              division={data}
              userState={userState}
              selectDivision={selectDivision}
              setSelectDivision={setSelectDivision}
            />
          ) : null}
        </Grid>
      ))}
    </>
  );
}

export default OrgSettingRow;
