import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'unset',
    '& .MuiFormControlLabel-root': {
      marginRight: 12,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primaryColor,
      width: 20,
      height: 20,
    },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(6, 121, 191, 0.04)',
    },
    '& .MuiRadio-colorSecondary': {
      padding: 7,
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTypography-body1': {
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
}));

export default function InstructionsWindowRadio(props) {
  const { values } = props;
  const classes = useStyles();
  const [value, setValue] = useState(values.state);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl
      component="fieldset"
      className={classes.formControl}
    >
      <RadioGroup
        name="gender1"
        value={value}
        onChange={(e) => {
          values.setState(e.target.value);
          handleChange(e);
        }}
        className={classes.radioGroup}
      >
        {values.list.map((item) => (
          <FormControlLabel
            key={item.val}
            value={item.val}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
