import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import settingGroupStoreSearchApi from '../../../apis/settingGroupStore/settingGroupStoreSearchApi';

import { useLoading } from '../../../hooks';
import { useStoreGroupsSettingsAreas } from '../common/useStoreGroupsSettingsAreas';
import { COMBINATION_SETTING } from '../../../constants/loading';

const Context = createContext({});

export function useStoreGroupsContext() {
  return useContext(Context);
}

export function StoreGroupsContextProvider(props) {
  const {
    children,
    onChange,
    defaultSelected,
    onCancel,
    currentArea,
  } = props;

  const { areaList } = useStoreGroupsSettingsAreas();
  const { addLoading, removeLoading } = useLoading();

  const [storeGroupsData, setStoreGroupsData] = useState({});

  /**
   * 【settingGroupStore001】店舗グループ設定取得
   */
  const getGroupStoreSearchApi = useCallback(async (areas) => {
    addLoading(COMBINATION_SETTING);
    const res = await Promise.allSettled(
      areas.map((item) => settingGroupStoreSearchApi(item.areaCode)),
    );
    // to object
    const data = {};
    areas.forEach((item, i) => {
      data[item.areaCode] = res[i].value?.data.settings || [];
      // label追加
      data[item.areaCode].forEach((child) => {
        child.label = child.storeGroupName;
        child.uniqueId = `area${child.areaCode}-store${child.storeGroupId}`;
        child.selectable = true;
        child.type = 'store';
        child.id = child.storeGroupId;
      });
    });
    setStoreGroupsData(data);
    removeLoading(COMBINATION_SETTING);
  }, [areaList]);

  useEffect(() => {
    if (areaList.length) {
      getGroupStoreSearchApi(areaList);
    }
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    areaList,
    storeGroupsData,
    onChange,
    defaultSelected,
    onCancel,
    currentArea,
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}
