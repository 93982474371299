import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Rnd from 'react-rnd';
import { makeStyles } from '@material-ui/core/styles';
import {
  ACTION_TYPE,
  ACTION_TYPE_FLYER_SALES,
  USER_LEVEL_NO,
  GUIDANCE_STATUS_CODE,
} from '../../../constants';
import EditableWindow from '../../pages/actRegist/editableWindow';
import ActRegistWindow from '../../pages/actRegist/actRegistWindow';
import InfoRegisterOnlyPost from '../../common/infoRegisterOnlyPost';
import { useActionSchedule } from '../../../containers/actionSchedule/actionScheduleContext';

const useStyles = makeStyles((theme) => ({
  rnd: {
    position: 'relative',
    border: '1px solid #C8C8C8',
    background: '#fff',
    borderRadius: '0 4px 4px 4px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    '&.single': {
      '& > span': {
        whiteSpace: 'normal',
      },
    },
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: '9px 9px 0 0',
      borderStyle: 'solid',
      position: 'absolute',
      top: 0,
      left: 0,
      borderColor: 'transparent',
    },
    '&.actionScheduleCode-1::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-2::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-3::before': {
      borderColor: `${theme.palette.primaryColor} transparent transparent`,
    },
    '&.actionScheduleCode-4::before': {
      borderColor: '#F2994A transparent transparent',
    },
    '&.actionScheduleCode-5::before': {
      borderColor: '#DEDEDE transparent transparent',
    },
    '&.actionScheduleCode-6::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-7::before': {
      borderColor: '#64CAF5 transparent transparent',
    },
    '&.actionScheduleCode-999': {
      background: '#FCE034',
      '&::before': {
        borderColor: 'transparent',
      },
      '&.isResultRegister-1::before': {
        borderColor: '#D83420 transparent transparent',
      },
      '&.customerStatusCode-10::before': {
        borderColor: '#27AE60 transparent transparent',
      },
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '1.3',
    letterSpacing: '0',
    display: 'block',
    padding: '0 0 0 8px',
    whiteSpace: 'nowrap',
    '& span': {
      display: 'block',
      whiteSpace: 'nowrap',
    },
  },
}));

export default function WeekRndSp(props) {
  const {
    item,
    no,
    index,
    count,
    rndList,
    data,
    dates,
  } = props;

  const {
    getSchedulesWeek,
    setReacquisitionFlg,
    functions,
    selectDivision,
  } = useActionSchedule();

  const classes = useStyles();
  // 【schedule010】行動予定詳細のグローバルステート
  const schedulesGetActionDetail = useSelector(
    (state) => state.schedulesGetActionDetail.schedulesGetActionDetail,
  );

  // 画面幅
  const windowWidth = window.innerWidth;

  // 日付に変換
  const startDate = new Date(item.startAt);

  // 開始時刻
  const [startTime] = useState(startDate.getHours() + ':' + String(startDate.getMinutes()).padStart(2, '0'));

  // m19モーダルの開閉
  const [open2, setOpen2] = useState(false);
  const onClose2 = () => {
    setOpen2(false);
    // 再取得
    getSchedulesWeek();
  };

  // m06-4 行動登録画面
  const [editableOpen, setEditableOpen] = useState(false);
  const onCloseEditable = () => {
    setEditableOpen(false);
    // 再取得
    getSchedulesWeek();
  };
  const [openActRegist, setOpenActRegist] = useState(false);
  const onCloseActRegist = () => {
    setOpenActRegist(false);
    // 再取得
    getSchedulesWeek();
  };
  // 目標
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });
  const [actRegistSelectDivision, setActRegistSelectDivision] = useState(selectDivision);

  const onClick = async () => {
    // actionScheduleCodeがnullの場合、m19画面を開く
    if (item.actionScheduleCode === null) {
      setOpen2(true);
    } else {
      // グローバルステート(schedulesGetActionDetail)の更新
      await functions.getSchedulesDetailFunction(item.scheduleId);
      // m06-4を開く
      if (ACTION_TYPE_FLYER_SALES.includes(item.actionScheduleCode)) {
        setEditableOpen(true);
      } else {
        setOpenActRegist(true);
      }
    }
  };

  // 列の幅
  const columnWidth = (windowWidth - 67) / dates.length;

  // ラベルの横幅
  let rndWidth = columnWidth / 2;
  // ラベルの高さ
  let rndHeight = 40;
  const rndHeight1 = 20;
  const rndHeight2 = 12.5;
  // ラベルの開始位置 X
  let startX = no * columnWidth;
  // ラベルの開始位置 Y
  let startY = 0;
  const startY1 = 20;
  const startY2 = 12.5;
  const startY3 = 25;
  switch (rndList.length) {
    case 1:
      rndWidth = columnWidth;
      break;
    case 2:
      switch (rndList[index].odr) {
        case 2:
          startX = (no * columnWidth) + (columnWidth / 2);
          break;
        default:
          break;
      }
      break;
    case 3:
      switch (rndList[index].odr) {
        case 1:
          rndHeight = rndHeight1;
          break;
        case 2:
          startX = (no * columnWidth) + (columnWidth / 2);
          break;
        case 3:
          rndHeight = rndHeight1;
          startY = startY1;
          break;
        default:
          break;
      }
      break;
    case 4:
      switch (rndList[index].odr) {
        case 1:
          rndHeight = rndHeight2;
          break;
        case 2:
          startX = (no * columnWidth) + (columnWidth / 2);
          break;
        case 3:
          rndHeight = rndHeight2;
          startY = startY2;
          break;
        case 4:
          rndHeight = rndHeight2;
          startY = startY3;
          break;
        default:
          break;
      }
      break;
    default:
      rndHeight = rndHeight2;
      // 偶数の場合
      if (rndList[index].odr % 2 === 0) {
        startX = (no * columnWidth) + (columnWidth / 2);
      }
      startY = rndList[index].ro * startY2;
      break;
  }

  const [reset, setReset] = useState(false);
  useEffect(() => {
    setReset(!reset);
  }, [item]);

  return (
    <>
      <Rnd
        key={reset}
        className={`${classes.rnd} actionScheduleCode-${item.actionScheduleCode || '999'} customerStatusCode-${item.customerStatusCode} isResultRegister-${item.isResultRegister} ${count === 1 && 'single'}`}
        // default={{
        //   x: startX,
        //   y: startY,
        //   width: rndWidth,
        //   height: rndHeight,
        // }}
        size={{
          width: rndWidth,
          height: rndHeight,
        }}
        position={{
          x: startX,
          y: startY,
        }}
        dragAxis="none"
        enableResizing={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        bounds="parent"
        minWidth="97"
        onClick={onClick}
      >
        {item.actionScheduleCode ? (
          <span className={classes.txt}>
            <span>
              {ACTION_TYPE[item.actionScheduleCode]}
              {item.memo ? `、${item.memo}` : ''}
            </span>
            <span>
              {startTime}-
              {item.customerName ? ` ${item.customerName} 様、` : '、'}
            </span>
          </span>
        ) : (
          <span className={classes.txt}>
            <span>
              {item.customerStatusCode === 10 ? `(契)${GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}` : GUIDANCE_STATUS_CODE[item.guidanceStatusCode]}
              {item.userLevel ? `、${USER_LEVEL_NO[item.userLevel]}` : '、★なし'}
              {item.isPairCheck ? '、揃い' : ''}
              {item.siteName ? `、${item.siteName}` : ''}
            </span>
            <span>
              {startTime}-
              {item.customerName ? ` ${item.customerName} 様、` : '、'}
            </span>
          </span>
        )}
      </Rnd>
      {/* m06-4 行動登録画面（源泉） */}
      <EditableWindow
        open={editableOpen}
        onClose={onCloseEditable}
        setReacquisitionFlg={setReacquisitionFlg}
        localUpdateData={data.localUpdateData}
        setLocalUpdateData={data.setLocalUpdateData}
        values={schedulesGetActionDetail}
        setOpenCustomertree={data.setOpenCustomertree}
        actRegistFlag={Boolean(false)}
        editableFlag={Boolean(true)}
        schedulesUpdateApiFunction={functions.schedulesUpdateApiFunction}
        schedulesPostApiFunction={functions.schedulesPostApiFunction}
        schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
        actionScheduleCode={item.actionScheduleCode}
      />
      {/* m06-4 行動登録画面（源泉以外） */}
      {openActRegist && (
        <ActRegistWindow
          open={openActRegist}
          onClose={onCloseActRegist}
          setReacquisitionFlg={setReacquisitionFlg}
          values={data.localUpdateData}
          setUpdateData={data.setLocalUpdateData}
          setOpenCustomertree={data.setOpenCustomertree}
          actRegistFlag={Boolean(false)}
          editableFlag={Boolean(true)}
          targetObj={targetObj}
          setTargetObj={setTargetObj}
          archieveObj={archieveObj}
          setArchieveObj={setArchieveObj}
          selectDivision={actRegistSelectDivision}
          setSelectDivision={setActRegistSelectDivision}
          isSource={Boolean(false)}
          isEdit={Boolean(true)}
          createUpdateFunc={(obj) => {
            functions.schedulesUpdateApiFunction(data.localUpdateData.scheduleId, obj);
          }}
          schedulesDeleteApiFunction={functions.schedulesDeleteApiFunction}
        />
      )}
      {/* m19 */}
      <InfoRegisterOnlyPost
        open={open2}
        onOpen={() => { setOpen2(true); }}
        onClose={onClose2}
        // newRegistrationFlgがtrueの場合、infoRegisterにguidanceIdを渡さない===新規登録になる
        guidanceId={item.guidanceId}
        // setClickInfoRegister={setClickInfoRegister}
        customer={item}
        // user={user}
        reacquisition={getSchedulesWeek}
      />
    </>
  );
}
