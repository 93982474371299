import React, { useEffect, useState, useRef } from 'react';
import {
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
// import moment from 'moment';
import commonStyles from '../../styles';
import DataElement from './dataElement';
import DataGroup from './dataGroup';
import AddResHouse from './addReshouse';
import conversionCustomerData from './conversionCustomerData';
import convertFormData from './convertFormData';
import webResUpdateAfterMargedApi from '../../../apis/webRes/webResUpdateAfterMargedApi';
import webResUpdateAfterMargedCustomerApi from '../../../apis/webRes/webResUpdateAfterMargedCustomerApi';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { crmPageActions } from '../../../store/crmPage';
import { JOIN_DATA_TYPES } from '../../../constants/crm';
import { toNumber } from '../../../commonFunction/format';
import { useLoading } from '../../../hooks';
import ConfirmModal from '../../common/modal/confirmModal';
import { ctcParams } from '../../../containers/joinData/customerToCustomerParams';
import { rtcParams } from '../../../containers/joinData/responseToCustomerParams';
import { useMailVerification } from '../../crmMain/useMailVerification';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 1024,
    minWidth: 640,
    background: '#fff',
  },
  header: {
    padding: '24px 24px 8px',
    fontWeight: 700,
  },
  content: {
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    minHeight: 265,
    padding: 24,
  },
  addIcon: {
    width: 16,
    height: 16,
    color: theme.palette.primaryColor,
  },
  buttonTxt: {
    fontFamily: 'Roboto',
    color: theme.palette.primaryColor,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 400px',
  },
  executeButton: {
    width: 74,
    height: 40,
  },
  cancelButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '160%',
    color: theme.palette.primaryColor,
  },
  formControlLabel: {
    '& .MuiTypography-body1': {
      fontSize: '12px',
      lineHeight: 1.6,
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
  },
  littleContent: {
    width: 976,
    backgroundColor: '#F3F3F3',
    border: 'solid 1px #C8C8C8',
    padding: '17px 8px',
    boxSizing: 'border-box',
  },
  resHouseContainer: {
    width: 476,
    backgroundColor: '#F3F3F3',
    border: 'solid 1px #C8C8C8',
    padding: '17px 8px',
    boxSizing: 'border-box',
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },
  title: {
    display: 'flex',
    marginBottom: 20,
  },
  titleContent: {
    marginRight: 415,
  },
  elementLeft: {
    width: 459,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    padding: 8,
    boxSizing: 'border-box',
    marginBottom: 16,
    '& input': {
      padding: 0,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '160%',
    },
  },
  elementRight: {
    width: 459,
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    padding: 8,
    marginBottom: 16,
    '& input': {
      padding: 0,
    },
  },
  label: {
    width: 120,
    marginRight: 30,
  },
  reqired: {
    color: '#D83420',
    marginRight: 'auto',
  },
  arrowBlackIcon: {
    color: theme.palette.primaryColor,
  },
  contentIptDate: {
    width: 459,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    boxSizing: 'border-box',
    marginBottom: 16,
  },
  cancelDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  cancelContent: {
    width: 515,
    padding: 24,
  },
  cancelTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  cancelParagraph: {
    textAlign: 'center',
    marginBottom: 50,
  },
  buttons: {
    display: 'flex',
    padding: '0 120px',
    justifyContent: 'space-between',
    '& .MuiButton-text': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '160%',
      color: theme.palette.primaryColor,
    },
  },
}));

function formatSetData(data, defaultParams, customersDataParent) {
  // APIドキュメントと統一するため
  const setData = {};
  Object.keys(defaultParams).forEach((key) => {
    // 物件:nullと0の場合APIバリデーションエラーになる
    if (key === 'siteId' && !data[key]) return;
    // 画面に表示されてない情報を補足する
    setData[key] = typeof data[key] === 'undefined'
      ? customersDataParent[key]
      : data[key];
  });

  // acquisitionId -> acquisitionUserId
  if (data.acquisitionId) {
    // 獲得者種が社員以外の場合はacquisitionUserIdはnullとしてバックエンドに送信する
    if (data.acquisitionId === '0') {
      setData.acquisitionUserId = null;
    } else {
      setData.acquisitionUserId = Number(data.acquisitionId);
    }
  }
  // acquisitionMemoをセット
  if (data.acquisitionMemo) {
    setData.acquisitionMemo = data.acquisitionMemo;
  }
  // acquisitionCode(獲得者種)を型変換
  if (!data.acquisitionCode) {
    setData.acquisitionCode = null;
  } else {
    setData.acquisitionCode = Number(data.acquisitionCode);
  }

  // バリデーションチェック
  [
    'isThinkApartment',
    'subWorkingStyleCode',
    'isNoCustomer',
    'isCustomerIntroduction',
  ].forEach((key) => {
    if (typeof setData[key] !== 'number') {
      setData[key] = toNumber(setData[key]);
    }
  });
  // 性別
  ['genderCode', 'subGenderCode'].forEach((key) => {
    if (setData[key] === 0) setData[key] = null;
  });

  return setData;
}

function Joindata(props) {
  const {
    customersDataParent,
    customersDataChild,
    operation,
    getDataAt,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = useState(false);
  const [diffOnly, setDiffOnly] = useState(false);
  const [formData, setFormData] = useState();
  const { handleSubmit, register, setValue } = useForm();
  const [nowUserLevel, changeUserLevel] = useState();
  const [nowTelStatus, changeTelStatus] = useState();
  const [nowEmailStatus, changeEmailStatus] = useState(customersDataParent?.emailStatusCode);
  const [fieldData, setFieldData] = useState([]);
  const { addLoading, removeLoading } = useLoading();
  const dispatch = useDispatch();

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const { verifyMail } = useMailVerification(customersDataParent);
  /**
   * データ結合キャンセル
   * またはStoreの結合顧客情報をクリアする
   */
  const onCancel = () => {
    dispatch(crmPageActions.setJoinData());
  };
  const handleCancel = () => {
    dispatch(changeConfirmMessage({
      msgList: ['現在編集中の顧客情報は破棄されます。', 'よろしいですか？'], // 必須
      title: 'キャンセル', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
      buttons: [{
        label: 'CANCEL', // ボタンラベル
        set: () => { return false; }, // ボタンをクリックしたときの処理
        classes: baseClasses.buttonsSecondary, // ボタンのクラス
      }, {
        label: 'OK', // ボタンラベル
        set: onCancel, // ボタンをクリックしたときの処理
        classes: baseClasses.buttonsPrimary, // ボタンのクラス
      }], // エラーではない場合。設定しなければ文字色、背景が赤くなる
    }));
  };

  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4569
  // WEB戻しならばisReResponse= 0、WEB再反ならばisReResponse=1 でリクエスト
  const getIsReResponseNum = (type) => {
    switch (type) {
      case JOIN_DATA_TYPES.RETURN:
        return 0;
      default:
        return 1;
    }
  };

  const isButtonClicked = useRef(false);

  // APIを叩く関数(isNotCheckUpdateが0だと変更がある場合でも上書きされる。)
  const setCustomerData = async () => {
    if (!isButtonClicked.current) {
      isButtonClicked.current = true;
      setOpen(false);
      addLoading();
      // セレクトの文字で帰ってきている部分をIDに変換
      // acquisitionId or acquisitionMemoかの制御を実施
      // 画面で編集したデータがformDataに入る
      const conversionData = conversionCustomerData(formData, fieldData);
      let setData = {};
      if (operation === JOIN_DATA_TYPES.MERGE) {
        // webRes009のbodyの形のobjectを作成
        delete conversionData.customerId;
        const tempData = {
          ...conversionData,
          updateCustomerId: customersDataParent.customerId,
          deleteCustomerId: customersDataChild.customerId,
          responseId: customersDataParent.responseId,
          getDataAt,
          isNotCheckUpdate: 1,
        };

        // 送信データ
        setData = formatSetData(tempData, ctcParams, customersDataParent);
        try {
          await webResUpdateAfterMargedCustomerApi(setData);
          onCancel();
        } catch (e) { /* */ }
      } else {
        // webRes008のbodyの形のobjectを作成
        const { effectAt } = conversionData;
        const isReResponse = getIsReResponseNum(operation);
        const tempData = {
          ...conversionData,
          // responseId: customersDataParent.responseId,
          // リクエストの responseId は ④のwebres015 で呼び出したもの
          // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4569
          responseId: customersDataChild.responseId,
          customerId: customersDataParent.customerId,
          isNotCheckUpdate: 1,
          getDataAt,
          // 反響日
          // http://10.22.2.48:8080/docs/#/responses/post-webRes-update-resimpToCust
          effectAt,
          isReResponse,
        };
        setData = formatSetData(tempData, rtcParams, customersDataParent);
        try {
          await webResUpdateAfterMargedApi(setData);
          onCancel();
        } catch (e) { /* */ }
      }
      removeLoading();
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4569
      // 結合後の処理
      // キーワードで検索
      const searchKeyword = ['lastName', 'firstName'].map((key) => setData[key] ?? '').join('');
      dispatch(crmPageActions.setSearchKeyword({ searchWord: searchKeyword, isForceSearch: true }));
      // 反響未処理を強制リロード
      dispatch(crmPageActions.forceReload({
        responseList: true,
      }));
      isButtonClicked.current = false;
    }
  };

  // 実行を押した際に走る処理 formの入力値を持ってくる。
  const onSubmit = (data) => {
    // 担当者未定の場合は、画面表示時にnullとしているため、この時点でデータ上で0に戻す。
    if (!data.userId) {
      data.userId = 0;
    }
    // 結合以外(e.g. WEB戻し)ではdata.userIdがnullのため、選択した相手の情報を使用
    if (operation !== JOIN_DATA_TYPES.MERGE && !data.userId) {
      data.userId = customersDataParent?.userId || `d${customersDataParent?.divisionId}`;
    }
    // TODO: userIdとdivisionIdの管理にprefixを使用するのをやめる
    // userTreeでprefix:'d'を使っているので改修には時間かかりそう
    if (data?.userId?.startsWith?.('d')) {
      data.divisionId = Number(data.userId.slice(1));
      if (Number.isNaN(data.divisionId)) data.divisionId = undefined;
      data.userId = 0;
    }
    // emailStatusCodeの型によって挙動が変わってしまう為、対応
    //  0  => 更新APIリクエスト時にnull [500エラー]
    // '0' => 更新APIリクエスト時に'0' [正常更新]
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5304
    if (typeof data?.emailStatusCode !== 'string') {
      data.emailStatusCode = String(data.emailStatusCode);
    }
    setFormData(data);
    if (!data.lastName // 姓
      || !data.effectAt // 反響基準日
      || (!data.userId && !data.divisionId) // 担当者ID
      || !data.largeResponseTypeId // 反響媒体（大）
    ) {
      dispatch(changeAlertMessage({ msgList: ['必須項目が未入力です。'] }));
      return;
    }
    // メールチェック
    verifyMail(data, () => {
      setOpen(true);
    });
  };

  const showDiffOnly = () => {
    setDiffOnly(!diffOnly);
  };
  // formを表示するデータを作成
  useEffect(() => {
    if (customersDataChild && customersDataParent) {
      const basicField = convertFormData(
        customersDataParent,
        customersDataChild,
      );
      setFieldData(basicField);
      // 初期化下記無効なForm要素コンポーネント(Calendar)の値
      ['effectAt', 'birthday', 'subBirthday', 'abodeNowPurchaseAt'].forEach((key) => {
        setValue(key, customersDataParent[key]);
      });
    }
  }, [customersDataParent, customersDataChild]);

  const getInputComponent = (d) => {
    return !d.groupName ? (
      <Grid key={d.label}>
        <Typography style={{ marginBottom: 10 }} className={baseClasses.title4}>
          {d.header ? d.header : ''}
        </Typography>
        <DataElement
          register={register}
          key={d.label}
          data={d}
          diffOnly={diffOnly}
          setFormValue={setValue}
          operation={operation}
          customersDataParent={customersDataParent}
          customersDataChild={customersDataChild}
          changeUserLevel={changeUserLevel}
          nowUserLevel={nowUserLevel}
          formData={formData}
          changeTelStatus={changeTelStatus}
          changeEmailStatus={changeEmailStatus}
          nowTelStatus={nowTelStatus}
          nowEmailStatus={nowEmailStatus}
          errorCallback={errorCallback}
        />
      </Grid>
    ) : (
      <Grid key={d.label}>
        <Typography style={{ marginBottom: 10 }} className={baseClasses.title4}>
          {d.header ? d.header : ''}
        </Typography>
        <DataGroup
          register={register}
          setFormValue={setValue}
          dataGroup={d}
          formData={formData}
          diffOnly={diffOnly}
          operation={operation}
        />
      </Grid>
    );
  };

  return (
    <>
      <Grid className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="draggable-dialog-title1" className={classes.header}>
            <Typography className={baseClasses.title3}>
              データ結合ウィンドウ
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography className={baseClasses.bass}>
              名寄せ後に活かす情報は中央のボタンを押して情報を寄せてください。
            </Typography>
            <Grid className={classes.flex}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="top"
                    control={<Checkbox color="primary" />}
                    label="差分のある項目のみ表示"
                    labelPlacement="end"
                    className={classes.formControlLabel}
                    onChange={showDiffOnly}
                  />
                </FormGroup>
              </FormControl>
              <Grid>
                <span className={baseClasses.strong}>{fieldData.length}</span>
                <span className={baseClasses.small}>項目</span>
              </Grid>
            </Grid>
            <Grid style={{ marginBottom: 8 }} className={classes.littleContent}>
              <Grid className={classes.title}>
                <Typography
                  className={`${baseClasses.title4} ${classes.titleContent}`}
                >
                  名寄せ後の情報
                </Typography>
                <Typography className={baseClasses.title4}>
                  名寄せ後、削除情報
                </Typography>
              </Grid>
              {fieldData.map((data) => getInputComponent(data))}
            </Grid>
            <AddResHouse register={register} setFormValue={setValue} />
          </DialogContent>
          <Grid className={classes.footer}>
            <Button onClick={handleCancel} className={classes.cancelButton}>
              キャンセル
            </Button>
            <Button
              type="onsubmit"
              className={`${baseClasses.buttonsPrimary} ${classes.executeButton}`}
              disabled={!customersDataParent || !customersDataChild || errorSet.size !== 0}
            >
              実行
            </Button>
          </Grid>
        </form>
      </Grid>
      <ConfirmModal
        open={open}
        title="データ結合の確認"
        content="データ結合を実行してもよろしいですか？"
        onCancel={() => setOpen(false)}
        onSubmit={setCustomerData}
      />
    </>
  );
}

export default Joindata;
