import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  TextField,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import infoRegisterStyles from './infoRegisterStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '8px 8px 7px',
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  input: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      letterSpacing: 0,
      padding: 0,
    },
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'block',
    padding: '10px 8px 10px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
    textAlign: 'left',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    marginBottom: 16,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
}));

function InfoRegisterText(props) {
  const { values } = props;
  const classes = useStyles();
  const infoRegisterClasses = infoRegisterStyles();

  const [text, setText] = useState('');

  // 初期値はuseEffectで反映
  useEffect(() => {
    setText(values.state);
  }, [values]);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  if (isSp) {
    contents = (
      <Grid>
        <List className={infoRegisterClasses.list}>
          <ListItem className={infoRegisterClasses.listItem}>
            <Typography className={infoRegisterClasses.listTtl}>
              {values.label}
              {values.required && <span>＊</span>}
            </Typography>
            <TextField
              className={infoRegisterClasses.textarea}
              onChange={handleChange}
              onBlur={(e) => { return values.setState(e.target.value); }}
              name={values.label}
              value={text}
              placeholder="-"
            />
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <Typography className={classes.ttl}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <TextField
          className={classes.input}
          onChange={handleChange}
          onBlur={(e) => { return values.setState(e.target.value); }}
          name={values.label}
          value={text}
        />
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default InfoRegisterText;
