import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import Modal from '../../common/modal';
import SendTimeChangeWindow from '../../mail/parts/changeTime';
import {
  validateFormInt1,
} from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import comma from '../../../commonFunction/comma';
import { SEND_MAIL_AREA } from '../../../constants';
import { useLoading } from '../../../hooks';
import { LIBRARY_SEND_WINDOW, MAIL_SENDING, TESTMAIL_SENDING } from '../../../constants/loading';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { initSendmailCounts } from '../../../store/sendmail/sendmailGetCountsSlice';
import handleKeyDown from '../../../commonFunction/preventKeyCode';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: '8px 24px 16px',
    border: '1px solid #C8C8C8',
    boxShadow: 'none',
    borderRadius: 0,
    marginBottom: 8,
  },
  tableRow: {
    borderBottom: '1px solid #C8C8C8',
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  tableHeadCell: {
    color: '#8C8C8C',
    padding: 8,
    borderBottom: '2px solid #C8C8C8',
  },
  tableBodyCell: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    padding: 8,
    borderBottom: 'none',
    '&:first-child': {
      width: 80,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  box: {
    display: 'flex',
    border: '1px solid #C8C8C8',
    background: '#fff',
    width: 292,
  },
  label: {
    padding: 8,
    width: 110,
    '& span': { color: '#D83420' },
  },
  text: {
    paddingRight: 8,
    width: 180,
    '& > div': {
      display: 'block',
    },
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
    },
  },
  sendStartDateButton: {
    width: 180,
    padding: '0 8px',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: 16,
  },
  button: {
    margin: '0 8px',
    padding: '8px 22px',
  },
}));

export default function LibrarySendComponent(props) {
  const {
    open,
    onClose,
    data,
    functions,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();
  const { hasLoading } = useLoading();

  // 通信制限数
  const [limit, setLimit] = useState(null);

  // 送信開始時間
  const [sendStartDate, setTimerStartAt] = useState(null);
  const [sendStartDateOpen, setTimerStartAtOpen] = useState(false);
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate());
  maxDate.setHours(23, 59, 0, 0);

  // 活性・非活性制御
  const [disabledNumber, setDisabledNumber] = useState(true);
  const [disabledSend, setDisabledSend] = useState(true);

  // テスト送信フラグ
  const testSendFlg = useRef(false);

  // 件数確認フラグ
  const checkNumberFlg = useRef(false);

  // 単項目チェック エラー
  const [errorSet, setErrorSet] = useState(new Set());
  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const areaCodesInitial = Object.keys(SEND_MAIL_AREA).map((key, index) => ({
    order: index,
    code: Number(key),
    area: SEND_MAIL_AREA[key],
  }));

  const [areaCodes, setAreaCodes] = useState(areaCodesInitial);

  const values = {
    state: sendStartDate,
    setState: setTimerStartAt,
  };

  const reorder = (startIndex, endIndex) => {
    const result = Array.from(areaCodes);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    for (let index = 0; index < result.length; index += 1) {
      result[index].order = index;
    }

    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!result.destination) {
      return;
    }
    const update = reorder(source.index, destination.index);
    setAreaCodes(update);

    // 送信件数の初期化
    checkNumberFlg.current = false;
    setDisabledSend(true);
    dispatch(initSendmailCounts());
  };

  const onBlur = (e) => {
    setLimit(e.target.value || null);
  };

  const testSendHandler = async () => {
    // テスト送信
    await functions.sendTestMailFunction();
    testSendFlg.current = true;
    if (limit && sendStartDate) {
      // 件数確認ボタンを活性化
      setDisabledNumber(false);
    }
  };

  const checkNumberHandler = async () => {
    // 件数確認
    await functions.getCountsFunction(data.countsParam);
    checkNumberFlg.current = true;
    if (limit && sendStartDate) {
      // 送信ボタンを活性化
      setDisabledSend(false);
    }
  };

  const sendHandler = async () => {
    // 送信
    onClose();
    await functions.sendMailFunction(data.mailParam);
    await functions.reloadLibraries();
  };

  // 確認ウインドウ
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '確認',
      msgList: ['本当に送信してよろしいでしょうか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => {
            sendHandler();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  useEffect(() => {
    // ボタン制御
    if (limit && sendStartDate) {
      if (testSendFlg.current) {
        setDisabledNumber(false);
      }
      if (checkNumberFlg.current) {
        setDisabledSend(false);
      }
    } else {
      setDisabledNumber(true);
      setDisabledSend(true);
    }
    // 件数確認のパラメータ設定（通数制限、送信開始時間）
    data.setCountsParam({
      ...data.countsParam,
      limit,
      sendStartDate,
    });
    // 送信のパラメータ設定（通数制限、送信開始時間）
    data.setMailParam({
      ...data.mailParam,
      limit,
      timerStartAt: sendStartDate,
    });
  }, [limit, sendStartDate]);

  useEffect(() => {
    // 件数確認のパラメータ設定（物件エリア優先順）
    data.setCountsParam({
      ...data.countsParam,
      areaPriority: areaCodes.map(item => item.area),
    });
    // 送信のパラメータ設定（物件エリア優先順）
    data.setMailParam({
      ...data.mailParam,
      areaPriority: areaCodes.map(item => item.area),
    });
  }, [areaCodes]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="物件メール送信ウインドウ"
      maxWidth={640}
      className="librarySend"
      headerClassName="librarySendHeader"
      bodyClassName="librarySendBody"
    >
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.tableHeadCell} ${baseClasses.title4}`}>エリア</TableCell>
              <TableCell className={`${classes.tableHeadCell} ${baseClasses.title4}`}>件数</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="dndTableBody">
              {(provided) => (
                <TableBody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={hasLoading(LIBRARY_SEND_WINDOW) ? baseClasses.loading : ''}
                >
                  {areaCodes.map((row, index) => (
                    <Draggable
                      draggableId={row.area}
                      index={index}
                      key={row.code}
                    >
                      {(draggableProvided, snapshot) => {
                        // ドラッグ時の要素の表示位置調整（zoom: 0.8;とモーダルによる影響）
                        const defaultStyle = draggableProvided.draggableProps.style;
                        let draggingTop = 0;
                        let draggingLeft = 0;
                        if (snapshot.isDragging) {
                          const screenHeight = document.querySelector('.librarySend').clientHeight;
                          const headerHeight = document.querySelector('.librarySendHeader').clientHeight;
                          const bodyHeight = document.querySelector('.librarySendBody').clientHeight;
                          draggingTop = defaultStyle.top
                            - ((screenHeight - headerHeight - bodyHeight) / 2);
                          draggingLeft = 48;
                        }
                        const style = {
                          ...defaultStyle,
                          top: draggingTop,
                          left: draggingLeft,
                        };
                        return (
                          <TableRow
                            className={classes.tableRow}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={style}
                          >
                            <TableCell className={classes.tableBodyCell}>{row.area}</TableCell>
                            <TableCell className={classes.tableBodyCell}>
                              {checkNumberFlg.current ? `送信対象：${comma(data.sendmailCounts[index].count)}件` : '-'}
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Grid className={classes.content}>
        <Grid className={classes.box}>
          <Typography className={`${classes.label} ${baseClasses.title4}`}>
            制限通数<span>＊</span>
          </Typography>
          <TextBaseField
            type="number"
            defaultValue={limit}
            className={`${classes.text} ${baseClasses.title6}`}
            onBlur={onBlur}
            convertor={convertFormNumber}
            validator={validateFormInt1({
              minFloat: 0.0,
              maxFloat: 5.0,
            })}
            errorCallback={errorCallback('limit')}
          />
        </Grid>
        <Grid className={classes.box}>
          <Typography className={`${classes.label} ${baseClasses.title4}`}>
            送信開始時間<span>＊</span>
          </Typography>
          <Button
            className={classes.sendStartDateButton}
            onClick={() => { setTimerStartAtOpen(true); }}
          >
            {sendStartDate}
          </Button>
          <SendTimeChangeWindow
            open={sendStartDateOpen}
            setOpen={setTimerStartAtOpen}
            values={values}
            maxDate={maxDate}
          />
        </Grid>
      </Grid>
      <Grid className={classes.buttons}>
        <Button
          className={`${baseClasses.buttonsSecondary} ${classes.button}`}
          onClick={testSendHandler}
          onKeyDown={handleKeyDown}
          disabled={hasLoading(TESTMAIL_SENDING)}
        >
          テスト送信
        </Button>
        <Button
          className={`${baseClasses.buttonsPrimary} ${classes.button}`}
          onClick={checkNumberHandler}
          disabled={disabledNumber || errorSet.size !== 0 || hasLoading(LIBRARY_SEND_WINDOW)}
        >
          件数確認
        </Button>
        <Button
          className={`${baseClasses.buttonsPrimary} ${classes.button}`}
          onClick={confirmOpen}
          disabled={disabledSend || errorSet.size !== 0 || hasLoading(MAIL_SENDING)}
          onKeyDown={handleKeyDown}
        >
          送信
        </Button>
      </Grid>
    </Modal>
  );
}
