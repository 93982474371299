import { createSlice } from '@reduxjs/toolkit';

// TODO: UpdateにSliceは不要そうですが、念のため作成
const initialState = {};

export const customerResponsibleUpdateSlice = createSlice({
  name: 'customerResponsibleUpdate',
  initialState: { customerResponsibleUpdate: initialState },
  reducers: {
    updateCustomerResponsibleApi: (state, data) => {
      state.customerResponsibleUpdate = data.payload;
    },
  },
});

export const { updateCustomerResponsibleApi } = customerResponsibleUpdateSlice.actions;

export default customerResponsibleUpdateSlice.reducer;
