import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  areaCode: '',
  areaName: '',
  displayOrder: '',
  correction: '',
  storeGroupId: undefined,
  settings: [],
};

export const settingGroupStoreDetailSlice = createSlice({
  name: 'settingGroupStoreDetail',
  initialState: { settingGroupStoreDetail: initialState },
  reducers: {
    setSettingGroupStoreDetail: (state, data) => {
      state.settingGroupStoreDetail = data.payload;
    },
    setSettingGroupStoreSettings: (state, data) => {
      state.settingGroupStoreDetail.settings = data.payload;
    },
    setInitialSettingStoreDetail: (state, data) => {
      state.settingGroupStoreDetail = { ...initialState, ...data.payload };
    },
  },
});

export const {
  setSettingGroupStoreDetail,
  setSettingGroupStoreSettings,
  setInitialSettingStoreDetail,
} = settingGroupStoreDetailSlice.actions;

export default settingGroupStoreDetailSlice.reducer;
