import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import commonTheme from '../styles/theme';
import InstructionList from '../instructionList';
import KanniKensaku from '../search/kanniKensaku';
import CustomerTree from '../common/customerTree/customerTree';
import { throttle } from '../../commonFunction';

const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    [commonTheme.breakpoints.up('md')]: {
      flexGrow: 1,
      // width: 1920,
      // minWidth: 1920,
      overflow: 'hidden',
      height: 'calc(100vh - 52px)',
    },
  },
  main: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sideMenu: {
    width: 240,
  },
  contents: {
    [commonTheme.breakpoints.up('md')]: {
      // width: 1680,
      width: 'calc(100vw - 240px)',
    },
  },
});

function InstructionListPage() {
  const classes = useStyles();
  const targetFlgList = {
    userFlg: true,
    divisionFlg: true,
    customerFlg: true,
  };
  const responseHeader = useSelector((state) => state.responseHeader);
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const [selectObject, setSelectObject] = useState({
    user: responseHeader.userId,
    division: null,
    customer: [],
    uniqueId: null,
  });

  const setSelectObjectByKey = (key) => (value) => {
    setSelectObject((prevObj) => ({ ...prevObj, [key]: value }));
  };

  /**
 * 選択されたユーザー更新
 * CustomerTreeのsetSelectCustomerがクリック時に２回走るのでその対応でthrottle使用
 * @param {{customerId: number; customerName: string;}[]} list
 */
  const throttleSetSelectCustomer = useCallback(throttle((list) => {
    setSelectObjectByKey('customer')(list.length === 0 ? [] : list);
  }, 1000), []);

  useEffect(() => {
    setSelectObjectByKey('user')({ userId: responseHeader.userId });
  }, [responseHeader.userId]);

  // URLパスパラメータによる処理
  useEffect(() => {
    if (window.location.href.match('ka=') || window.location.href.match('area=') || window.location.href.match('tenpo=')) {
      const divisionId = window.location.href.split('=')[1];
      setSelectObjectByKey('division')({ divId: Number(divisionId) });
    } else if (window.location.href.match('member=')) {
      const userId = window.location.href.split('=')[1];
      setSelectObjectByKey('user')({ userId: Number(userId) });
    } else {
      setSelectObjectByKey('user')({ userId: responseHeader.userId });
    }
  }, []);

  // 反響ツリーの高さ調整
  const [treeHeight, setTreeHeight] = useState('');
  const [openAccordion, setOpenAccordion] = useState(true);
  const changeAccordion = (d) => {
    setOpenAccordion(d);
  };
  useEffect(() => {
    const accordionHeight = openAccordion ? 464 : 24;
    const height = 144 + accordionHeight;
    setTreeHeight(`calc(100vh - ${height}px)`);
  }, [openAccordion]);

  return (
    <div className={classes.root}>
      {isSP ? (
        <Grid className={classes.main}>
          <Grid className={classes.contents}>
            <InstructionList selectCustomer={selectObject.customer} />
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.main}>
          <Grid className={classes.sideMenu}>
            <KanniKensaku changeAccordion={changeAccordion} />
            <CustomerTree
              searchForm={false}
              setSelectUser={setSelectObjectByKey('user')}
              setSelectDivision={setSelectObjectByKey('division')}
              setSelectCustomer={throttleSetSelectCustomer}
              setSelectUniqueId={setSelectObjectByKey('uniqueId')}
              targetFlgList={targetFlgList}
              defaultUserId={selectObject.user?.userId}
              defaultDivisionId={
                !selectObject.user?.userId ? selectObject.division?.divId : undefined
              }
              defaultUniqueId={selectObject.uniqueId}
              isDirect
              initialHeader={{ typeCode: 4 }}
              treeHeight={treeHeight}
            />
          </Grid>
          <Grid className={classes.contents}>
            <InstructionList selectCustomer={selectObject.customer} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default InstructionListPage;
