import client from '../httpClient';

/**
 * 【divisionMaster010】自店舗情報取得
 * @param {{
 * divisionSearchCode: 1 | 0;
 * }} params
 * @returns {Promise<{
 * divisions: {divisionId: number; divisionName: string; areaCode: number}[];
 * }>}
 */
const getOwnDivisionApi = async (params) => {
  const res = await client.get('/division-masters/own-division', { params });
  return res.data;
};

export default getOwnDivisionApi;
