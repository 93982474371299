import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import HolidayTel from '../../common/holidayTel';
import NormalTooltip from '../../styles/tooltip';
import { useHolidayTimeContext } from './holidayTimeContext';

const useStyles = makeStyles({
  button: {
    background: '#fff',
    width: '100%',
    textAlign: 'left',
    borderRadius: 0,
    marginBottom: 6,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 0,
      border: '1px solid #C8C8C8',
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [commonTheme.breakpoints.down('sm')]: {
        padding: 16,
        borderBottom: '1px solid #F3F3F3',
      },
      [commonTheme.breakpoints.up('md')]: {
        padding: '6px 8px',
      },
    },
    '&:hover': {
      background: '#fff',
    },
  },
  ttl: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 330,
  },
});

function CustomerHoliday() {
  const { current, selectHolidayTel, clickSelectBoxFunc } = useHolidayTimeContext();

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const baseClasses = commonStyles();

  return (
    <Grid className={classes.root}>
      <Typography className={`${baseClasses.title4} ${baseClasses.onlySp} ${classes.ttl}`}>
        お休み
      </Typography>
      <NormalTooltip title={`${current.holidayWeekday ? current.holidayWeekday + ' ' : ''}${current.otherHolidayWeekday ? current.otherHolidayWeekday : ''}`}>
        <Button
          className={classes.button}
          onClick={handleOpen}
        >
          <Typography className={`${baseClasses.title6} ${baseClasses.onlyPc}`}>
            お休み
          </Typography>
          <Typography
            className={classes.txt}
          >
            {current.holidayWeekday} {current.otherHolidayWeekday}
          </Typography>
        </Button>
      </NormalTooltip>
      <HolidayTel
        initialParam={selectHolidayTel}
        onClickSelectButton={clickSelectBoxFunc}
        open={open}
        onClose={handleClose}
        category="holiday"
      />
    </Grid>
  );
}

export default React.memo(CustomerHoliday);
