import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  responses: [],
};

export const webResGetSlice = createSlice({
  name: 'webResGet',
  initialState: { webResGet: initialState },
  reducers: {
    webResGetApi: (state, data) => {
      state.webResGet.count = data.payload.total;
      state.webResGet.responses = data.payload.responses;
    },
  },
});

export const { webResGetApi } = webResGetSlice.actions;

export default webResGetSlice.reducer;
