import client from '../httpClient';

/**
 * 【claim004】顧客のクレーム一覧取得
 * http://10.22.2.48:8080/docs/#/claim/get-claim-list-customerId
 * @param {number} customerId 顧客ID
 * @returns {Promise<T>}
 */
const claimGetListApi = (customerId) => {
  return client.get(`/claim/list/${customerId}`, { isShowLoading: true });
};

export default claimGetListApi;
