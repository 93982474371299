import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const allocationSettingGetSlice = createSlice({
  name: 'allocationSettingGet',
  initialState: { allocationSettingGet: initialState },
  reducers: {
    allocationSettingGetApi: (state, data) => {
      state.allocationSettingGet = data.payload;
    },
  },
});

export const { allocationSettingGetApi } = allocationSettingGetSlice.actions;

export default allocationSettingGetSlice.reducer;
