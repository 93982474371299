import React from 'react';
import SearchContainers from '../../../containers/actionSchedule/search/searchContainers';

export default function Search(props) {
  const {
    handleCloseSearch,
  } = props;
  return (
    <div>
      <SearchContainers
        handleCloseSearch={handleCloseSearch}
      />
    </div>
  );
}
